<template>
    <EqsCard
        v-bind="{
            width: '100%',
            height: '100%',
            eqsType: 'no-padding'
        }"
    >
        <div class="fill-height d-flex flex-column overflow-y px-3">
            <v-row v-if="getRegistrationText" class="d-flex justify-center">
                <v-col :cols="$reactive.isDesktop() ? 6 : 12" class="pb-0">
                    <EqsAlert
                        class="mb-0 pb-0"
                        v-bind="{
                            eqsType: $entity.errorKyc() ? 'error' : 'warning',
                            text: getRegistrationText
                        }"
                    />
                </v-col>
            </v-row>
            <v-row v-if="!$entity.isPsfp() && $universe.isEquisafe()" class="d-flex justify-center">
                <v-col :cols="$reactive.isDesktop() ? 6 : 12" class="pb-0">
                    <EqsAlert
                        class="mb-0 pb-0"
                        v-bind="{
                            eqsType: 'warning',
                            text: $t('user.profile.risk-aversion', {
                                id: entity.id
                            })
                        }"
                    />
                </v-col>
            </v-row>
            <v-row
                v-if="!isProd() && $entity.isPsfp() && $universe.isEquisafe()"
                class="d-flex justify-center"
            >
                <v-col :cols="$reactive.isDesktop() ? 6 : 12" class="pb-0">
                    <EqsAlert
                        class="mb-0 pb-0"
                        v-bind="{
                            eqsType: 'info',
                            text: $t('user.profile.risk-aversion-edit', {
                                id: entity.id
                            })
                        }"
                    />
                </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
                <v-col :cols="$reactive.isDesktop() ? 6 : 12">
                    <component
                        :is="item.is"
                        v-for="(item, index) in infoViews"
                        :key="index"
                        v-bind="{ entity, loading, ...item }"
                    >
                        <template #content="data">
                            <component
                                :is="item.form"
                                v-bind="{ ...data, entity, isCgp: $entity.isAffiliationCgp() }"
                            />
                        </template>
                    </component>
                </v-col>
            </v-row>
        </div>
    </EqsCard>
</template>

<script>
import BlockchainInformation from "./BlockchainInformation.vue";
import AvatarName from "./content/AvatarName.vue";
import Content from "./content/_Content.vue";

import {
    Docs1SchemaDouble as individualDocs1,
    Docs2SchemaDouble as individualDocs2,
    aboutYouSchemaDouble,
    CgpSchemaDouble,
    ProofOfAddressSchemaDouble
} from "@/components/views/registration/schemas/individual";

import { PeapmeSchemaDouble } from "@/components/views/user/profile/content/peapmeSchemaDouble";

import { legalInfos as companyDocs } from "@/components/views/registration/schemas/company";

import { isProd } from "@/assets/constants";
export default {
    name: "Profile",
    components: {
        AvatarName,
        BlockchainInformation,
        Content,
        YourInfosForm: () => import("./content/YourInfosForm.vue"),
        YourAddressForm: () => import("./content/YourAddressForm.vue"),
        YourDocsForm: () => import("./content/YourDocsForm.vue"),
        YourCgpForm: () => import("./content/YourCgpForm.vue"),
        YourProofOfAddressForm: () => import("./content/YourProofOfAddressForm.vue"),
        YourPhebeNdaForm: () => import("./content/YourPhebeNdaForm.vue"),
        YourPeapmeForm: () => import("./content/YourPeapmeForm.vue"),
        CompanyLegalInfosForm: () => import("./content/CompanyLegalInfosForm.vue"),
        CompanyContactInfosForm: () => import("./content/CompanyContactInfosForm.vue"),
        CompanyGeneralInfos: () => import("./companyGeneralInfos/_CompanyGeneralInfos.vue"),
        CompanyHeadOfficeForm: () => import("./content/CompanyHeadOfficeForm.vue"),
        NonListedLegalInfosForm: () =>
            import(
                "@/components/views/registration/company/legalInfos/NonlistedLegalInfosForm.vue"
            ),
        AssociationLegalInfosForm: () =>
            import(
                "@/components/views/registration/company/legalInfos/AssociationLegalInfosForm.vue"
            ),
        CustodianLegalInfosForm: () =>
            import(
                "@/components/views/registration/company/legalInfos/CustodianLegalInfosForm.vue"
            ),
        CipInvestStatus: () => import("./content/CipInvestStatus")
        // ContentRiskAversion,
    },
    props: {
        entity: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            openPanelN:
                typeof this.$route.query.panel === "string"
                    ? parseInt(this.$route.query.panel)
                    : -1,
            // formData: {},
            countries: [],
            localLoading: false
        };
    },
    computed: {
        getRegistrationText() {
            const { lemonway_state, id } = this.$entity.represented();
            if (lemonway_state === "success") return "";
            else if (this.$entity.errorKyc())
                return this.$t("user.profile.registration-error", { id });
            else if (lemonway_state === "pending")
                return this.$t("user.profile.registration-pending");
            return this.$t("user.profile.registration-incomplete", { id });
        },
        infoViews() {
            const companySchemaDocs = companyDocs(this);
            return this.$entity.represented().is_company
                ? [
                      { is: "AvatarName" },
                      {
                          is: "CompanyGeneralInfos"
                      },
                      {
                          is: "Content",
                          form: "CompanyLegalInfosForm",
                          isEditable: true,
                          content: {
                              schemaDouble: {
                                  nationality: {
                                      isRequired: true
                                  },
                                  incorporation_date: {
                                      isRequired: true
                                  },
                                  legal_type: {
                                      isRequired: true
                                  },
                                  company_idcode: {
                                      isRequired: true
                                  },
                                  industry_type: {
                                      isRequired: true
                                  },
                                  real_estate_type: {
                                      isRequired: false
                                  }
                              },
                              subtitle: this.$t("user.profile.company-legal-info.subtitle")
                          }
                      },
                      {
                          is: "Content",
                          form: "CompanyContactInfosForm",
                          isEditable: true,
                          content: {
                              schemaDouble: {
                                  contact_email: {
                                      isRequired: false
                                  },
                                  phone: {
                                      isRequired: false
                                  },
                                  company_website: {
                                      isRequired: false
                                  }
                              },
                              subtitle: this.$t("user.profile.company-contact-info.subtitle")
                          }
                      },
                      {
                          is: "Content",
                          form: "CompanyHeadOfficeForm",
                          isEditable: false,
                          content: {
                              schemaDouble: {
                                  street: {
                                      isRequired: true
                                  },
                                  zipcode: {
                                      isRequired: true
                                  },
                                  city: {
                                      isRequired: true
                                  },
                                  country: {
                                      isRequired: true
                                  }
                              },
                              subtitle: this.$t("user.profile.company-head-office.subtitle")
                          }
                      },
                      {
                          is: "Content",
                          form: companySchemaDocs.is,
                          isEditable: false,
                          content: {
                              schemaDouble: companySchemaDocs.schemaDouble,
                              subtitle: this.$t("user.profile.company-docs.subtitle")
                          }
                      },
                      this.$entity.isAffiliationCgp()
                          ? {
                                is: "Content",
                                form: "YourCgpForm",
                                isEditable: true,
                                content: {
                                    schemaDouble: {
                                        ...CgpSchemaDouble(this, true)
                                    },
                                    subtitle: this.$t("user.profile.cgp-docs.subtitle")
                                }
                            }
                          : {},
                      this.$universe.isPhebe()
                          ? {
                                is: "Content",
                                form: "YourPhebeNdaForm",
                                isEditable: false,
                                content: {
                                    schemaDouble: {},
                                    subtitle: this.$t("user.profile.nda-phebe.title")
                                }
                            }
                          : {}
                  ]
                : [
                      { is: "AvatarName" },
                      {
                          is: "Content",
                          form: "YourInfosForm",
                          isEditable: true,
                          content: {
                              isAffiliationInvestor: this.$entity.isAffiliationInvestor(),
                              schemaDouble: {
                                  email: {
                                      isRequired: false
                                  },
                                  birth_date: {
                                      isRequired: true
                                  },
                                  birthplace: {
                                      isRequired: true
                                  },
                                  birth_country: {
                                      isRequired: true
                                  },
                                  phone: {
                                      isRequired: true
                                  },
                                  ...aboutYouSchemaDouble(this)
                              },
                              subtitle: this.$t("user.profile.your-infos.subtitle")
                          }
                      },
                      {
                          is: "Content",
                          form: "YourAddressForm",
                          isEditable: true,
                          content: {
                              schemaDouble: {
                                  street: {
                                      isRequired: true
                                  },
                                  zipcode: {
                                      isRequired: true
                                  },
                                  city: {
                                      isRequired: true
                                  },
                                  country: {
                                      isRequired: true
                                  }
                              },
                              subtitle: this.$t("user.profile.my-address.subtitle")
                          }
                      },
                      {
                          is: "Content",
                          form: "YourDocsForm",
                          content: {
                              schemaDouble: {
                                  ...individualDocs1(this),
                                  ...individualDocs2(this)
                              },
                              subtitle: this.$t("user.profile.your-docs.subtitle")
                          }
                      },

                      this.$entity.isAffiliationCgp()
                          ? {
                                is: "Content",
                                form: "YourCgpForm",
                                isEditable: true,
                                content: {
                                    schemaDouble: {
                                        ...CgpSchemaDouble(this, true)
                                    },
                                    subtitle: this.$t("user.profile.cgp-docs.subtitle")
                                }
                            }
                          : {},
                      this.$entity.isFromUniverse("kyoseil") && this.$entity.isAffiliationInvestor()
                          ? {
                                is: "Content",
                                form: "YourProofOfAddressForm",
                                isEditable: true,
                                content: {
                                    schemaDouble: {
                                        ...ProofOfAddressSchemaDouble(this)
                                    },
                                    subtitle: this.$t("documents.category.proof_of_address")
                                }
                            }
                          : {},
                      this.$universe.isPhebe()
                          ? {
                                is: "Content",
                                form: "YourPhebeNdaForm",
                                isEditable: false,
                                content: {
                                    schemaDouble: {},
                                    subtitle: this.$t("user.profile.nda-phebe.title")
                                }
                            }
                          : {},
                      this.$store.getters.universe_id
                          ? {}
                          : this.$entity.isPsfp()
                          ? {
                                is: "CipInvestStatus",
                                statusColor: "#5BD800",
                                status: this.$t("registration.eqs-invest.questionnaire-validate"),
                                textOne: this.$t("registration.eqs-invest.congratulations-1"),
                                textTwo: this.$t("registration.eqs-invest.congratulations-2"),
                                buttonText: this.$t("registration.eqs-invest.projects")
                            }
                          : {
                                is: "CipInvestStatus",
                                statusColor: "#D22B2B",
                                status: this.$t("registration.eqs-invest.complete"),
                                textOne: this.$t("registration.eqs-invest.complete-1"),
                                textTwo: this.$t("registration.eqs-invest.complete-2"),
                                buttonText: this.$t(
                                    "registration.eqs-invest.complete-questionnaire"
                                )
                            },
                      !this.$universe.isMagnitude()
                          ? {
                                is: "Content",
                                form: "YourPeapmeForm",
                                isEditable: true,
                                content: {
                                    schemaDouble: {
                                        ...PeapmeSchemaDouble(this)
                                    },
                                    subtitle: this.$t("user.profile.pea-data.subtitle"),
                                    showPea: true,
                                    showPeapme: false
                                }
                            }
                          : {},
                      !this.$universe.isMagnitude()
                          ? {
                                is: "Content",
                                form: "YourPeapmeForm",
                                isEditable: true,
                                content: {
                                    schemaDouble: {
                                        ...PeapmeSchemaDouble(this)
                                    },
                                    subtitle: this.$t("user.profile.peapme-data.subtitle"),
                                    showPea: false,
                                    showPeapme: true
                                }
                            }
                          : {}
                  ];
        }
    },
    watch: {
        "entity.avatar": {
            deep: true,
            handler(val) {
                this.loadingPicture = false;
            }
        }
    },
    async created() {
        await this.getCountries();
    },
    methods: {
        isProd,
        async getCountries() {
            try {
                this.localLoading = true;
                const res = await this.$api.getCountries();
                this.countries = res.data;
            } catch (e) {
                throw e;
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>
