var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column pa-3"},[_c('v-row',{staticClass:"px-3"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                        elClass: 'total-balance',
                        eqsType: 'title',
                        loading: _vm.loading || _vm.balanceLoading || _vm.sequestreLoading
                    },false),[_c('div',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.$currency.display(_vm.balance + _vm.sequestre),)+" ")])]),_c('EqsTextDisplay',_vm._b({staticClass:"pt-2"},'EqsTextDisplay',{
                        loading: _vm.loading,
                        value: _vm.$t('user.wallet.total-balance')
                    },false))],1),_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsImage',_vm._b({attrs:{"contain":""}},'EqsImage',{
                        aspectRatio: '2.',
                        maxHeight: _vm.topHeightToAdd,
                        src: _vm.$universe.image('/images/wallet.svg')
                    },false))],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('v-row',{staticClass:"d-flex justify-end"},[(_vm.ibanValidated)?_c('EqsBtn',_vm._b({staticClass:"ma-3",attrs:{"el-class":"money-out","color":"info"},on:{"click":() =>
                            _vm.$router
                                .push({ name: 'money-out', params: { id: 'init' } })
                                .catch(() => {})},scopedSlots:_vm._u([{key:"with-icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cash-minus ")]),_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$t("user.wallet.withdraw-funds-btn")))+" ")]},proxy:true}],null,false,2427967795)},'EqsBtn',{
                        loading: _vm.loading || _vm.balanceLoading || _vm.ibanLoading
                    },false)):_vm._e(),(!_vm.ibanValidated)?_c('EqsBtn',_vm._b({staticClass:"ma-3",attrs:{"el-class":"money-out","color":"info"},on:{"click":() =>
                            _vm.$router.push({
                                name: 'bank',
                                params: { id: _vm.iban ? _vm.iban.id.toString() : 'init' }
                            })},scopedSlots:_vm._u([{key:"with-icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cash-minus ")]),_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$t("user.wallet.withdraw-funds-btn")))+" ")]},proxy:true}],null,false,2427967795)},'EqsBtn',{
                        loading: _vm.loading || _vm.balanceLoading || _vm.ibanLoading
                    },false)):_vm._e(),_c('EqsBtn',_vm._b({staticClass:"ma-3",attrs:{"el-class":"money-in","color":"success"},on:{"click":() =>
                            _vm.$router
                                .push({ name: 'money-in', params: { id: 'init' } })
                                .catch(() => {})},scopedSlots:_vm._u([{key:"with-icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cash-plus ")]),_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$t("user.wallet.add-funds-btn")))+" ")]},proxy:true}])},'EqsBtn',{ loading: _vm.loading },false))],1)],1)],1),_c('v-row',{staticClass:"px-3 d-flex align-center wallets-cards",style:(_vm.getTraceStyle)},[_c('v-col',{staticStyle:{"height":"8rem"}},[_c('EqsClickableCard',_vm._b({scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('div',{staticClass:"d-flex fill-height flex-column justify-center"},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                                elClass: 'wallet-balance',
                                eqsType: 'title',
                                loading: _vm.balanceLoading || _vm.sequestreMovementsLoading,
                                value: _vm.$currency.display(_vm.balance)
                            },false))],1)]},proxy:true}])},'EqsClickableCard',{
                    width: '100%',
                    height: '100%',
                    value: !!_vm.transactions && _vm.transactions.isMainAccount,
                    action: _vm.fetchMainAccountTx,
                    loading: _vm.loading || _vm.movementsLoading,
                    subtitle: _vm.$t('user.wallet.main-account.subtitle'),
                    infoTxt: _vm.$t('user.wallet.main-account.info-txt', { universe: _vm.universe })
                },false))],1),_c('v-col',{staticStyle:{"height":"8rem"}},[_c('EqsClickableCard',_vm._b({scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('div',{staticClass:"d-flex fill-height flex-column justify-center"},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                                eqsType: 'title',
                                loading: _vm.sequestreLoading,
                                value: _vm.$currency.display(_vm.sequestre)
                            },false))],1)]},proxy:true}])},'EqsClickableCard',{
                    width: '100%',
                    height: '100%',
                    value: !!_vm.transactions && !_vm.transactions.isMainAccount,
                    action: _vm.fetchSequestreAccountTx,
                    loading: _vm.loading,
                    subtitle: _vm.$t('user.wallet.sequestre-account.subtitle'),
                    infoTxt: _vm.$t('user.wallet.sequestre-account.info-txt')
                },false))],1),_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[(_vm.iban)?_c('CurrentBankAccount',_vm._b({on:{"AddBankAccount":() =>
                        _vm.$router.push({
                            name: 'bank',
                            params: { id: _vm.iban ? _vm.iban.id.toString() : 'init' }
                        })}},'CurrentBankAccount',{ loading: _vm.loading },false)):_vm._e()],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{ loading: _vm.loading },false),[_c('a',{attrs:{"el-class":"info-bank-dialog"},on:{"click":() => (_vm.limitsDialog = true)}},[_vm._v(" "+_vm._s(_vm.$t("user.wallet.tranfers-limit-btn"))+" ")])])],1)],1),_c('v-slide-x-transition',{staticClass:"flex"},[(_vm.transactions)?_c('v-row',{staticClass:"px-3"},[_c('WalletTransactions',_vm._b({},'WalletTransactions',{ ..._vm.transactions, loading: _vm.loading },false))],1):_vm._e()],1),_c('TransfersLimitsDialog',_vm._b({attrs:{"value":_vm.limitsDialog},on:{"update:value":function($event){_vm.limitsDialog=$event}}},'TransfersLimitsDialog',{ title: _vm.$t('user.wallet.tranfers-limit-title-dialog'), loading: _vm.loading },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }