var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[(_vm.localLoading)?_c('EqsLoader',_vm._b({class:`my-${_vm.$reactive.getVal({ mobile: 2, tablet: 3, desktop: 5 })}`,attrs:{"el-class":"payment-loader"},scopedSlots:_vm._u([{key:"loader-message",fn:function(){return [_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                    eqsType: 'subtitle',
                    value: _vm.$t('user.payment.processing-order')
                },false))]},proxy:true}],null,false,1218457178)},'EqsLoader',{
            size: '100%'
        },false)):_vm._e(),_c('v-row',{staticClass:"px-3",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('md')}},[(_vm.order.numberofshares)?_c('EqsNumberShares',{staticStyle:{"min-width":"40%"},attrs:{"el-class":"purchased-shares","value":_vm.order.numberofshares,"editable":false,"is-required":false,"label":_vm.$t('user.payment.number-of-shares-purchasing')}}):_vm._e()],1)],1),(_vm.order.payment_mode !== 'transfer')?_c('v-row',{staticClass:"px-3",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"eqs-form-field d-flex flex-column align-center",attrs:{"cols":_vm.$reactive.getCols('md')}},[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle"}},[_vm._v(" "+_vm._s(_vm.order.status === "success" ? _vm.$t("user.payment.total-payment-payed") : _vm.$t("user.payment.total-payment-to-be-payed"))+" ")]),_c('EqsChip',{staticClass:"pb-4 pt-2 font-weight-bold",attrs:{"color":"primary","text-color":"white","el-class":"total-amount"}},[_vm._v(" "+_vm._s(_vm.$currency.display(_vm.order.total_price))+" ")])],1)],1):_vm._e(),(_vm.order.payment_mode === 'transfer' && _vm.order.status !== 'success')?_c('v-row',{staticClass:"px-3",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"eqs-form-field d-flex flex-column align-center",attrs:{"cols":_vm.$reactive.getCols('md')}},[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle"}},[_vm._v(" "+_vm._s(_vm.$t("user.payment.total-payment-to-be-transfer"))+" ")]),_c('EqsChip',{staticClass:"pb-4 pt-2 font-weight-bold",attrs:{"color":"primary","text-color":"white","el-class":"total-amount"}},[_vm._v(" "+_vm._s(_vm.$currency.display(_vm.order.total_price))+" ")])],1),_c('v-col',{staticClass:"eqs-form-field d-flex flex-column align-center",attrs:{"cols":_vm.$reactive.getCols('xl')}},[_c('EqsAlert',_vm._b({},'EqsAlert',{
                    eqsType: 'warning',
                    text: _vm.$t('payment.wire-instructions', { universe: _vm.universe }),
                    loading: _vm.loading
                },false))],1),_c('v-col',{staticClass:"eqs-form-field d-flex flex-column align-center",attrs:{"cols":_vm.$reactive.getCols('xl')}},[_c('BankwirePayment',_vm._b({staticClass:"my-5"},'BankwirePayment',{ loading: _vm.loading, order: _vm.order, iban: _vm.getIban, formData: _vm.formData },false))],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }