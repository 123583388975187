var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex fill-height fill-width flex-wrap justify-center global-signature-template-container"},[_c('div',{staticClass:"d-flex flex-column global-signature-template-form"},[_c('v-row',{staticClass:"px-3",attrs:{"align-content":"start"}},[_vm._t("form"),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":"12"}},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.selectedDoc,"return-object":""},on:{"update:value":function($event){_vm.selectedDoc=$event}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{on:{"click":_vm.addDoc}},[_c('v-icon',{staticClass:"px-2"},[_vm._v(" mdi-plus ")]),_c('v-list-item-content',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("equisafe-ui.signature-template.add-document"))+" ")])],1),_c('v-divider',{staticClass:"pb-2"})]},proxy:true}])},'EqsSelect',{
                        loading: _vm.loading,
                        items: _vm.items,
                        errorMessages: _vm.noSignatoriesInDocError,
                        clearable: false,
                        label: _vm.$t('equisafe-ui.signature-template.selected-document')
                    },false))],1),(_vm.value.length > 1 || !!_vm.selectedDoc.id)?_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":"12"}},[_c('EqsBtn',_vm._b({},'EqsBtn',{ color: 'error', action: _vm.deleteDoc, loading: _vm.loading },false),[_vm._v(" "+_vm._s(_vm.$t("equisafe-ui.signature-template.actions.delete-btn"))+" ")])],1):_vm._e(),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":"12"}},[_c('EqsBtn',_vm._b({},'EqsBtn',{
                        color: 'success',
                        loading: _vm.loading,
                        action: () => (_vm.dialogSigners = true)
                    },false),[_vm._v(" "+_vm._s(_vm.$t("equisafe-ui.signature-template.actions.select-signatories"))+" ")]),(_vm.selectedDoc.proceduredocumentsigner_set)?_c('InternalSignersDialog',_vm._b({attrs:{"value":_vm.dialogSigners,"proceduredocumentsigner_set":_vm.selectedDoc.proceduredocumentsigner_set},on:{"update:value":function($event){_vm.dialogSigners=$event},"update:proceduredocumentsigner_set":function($event){return _vm.$set(_vm.selectedDoc, "proceduredocumentsigner_set", $event)}}},'InternalSignersDialog',{ items: _vm.signatories },false)):_vm._e()],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":"12"}},[_c('EqsFileInput',_vm._b({attrs:{"value":_vm.baseDocProxy},on:{"update:value":function($event){_vm.baseDocProxy=$event}}},'EqsFileInput',{
                        isRequired: false,
                        label: _vm.$t('equisafe-ui.signature-template.actions.upload-doc'),
                        accept: '.pdf',
                        deleteFunc: _vm.deleteDoc
                    },false))],1)],2)],1),_c('v-slide-x-transition',[(!_vm.isUploadedDoc)?_c('EqsTabs',_vm._b({staticClass:"global-signature-template-tabs",attrs:{"value":_vm.tab},on:{"update:value":function($event){_vm.tab=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c(item.is,_vm._b({tag:"component",staticClass:"global-signature-template",attrs:{"value":_vm.selectedDoc.html},on:{"update:value":function($event){return _vm.$set(_vm.selectedDoc, "html", $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('EqsBtnMenu',_vm._b({staticClass:"px-2 py-2",on:{"selected-item":_vm.addVariable}},'EqsBtnMenu',{
                                color: 'primary',
                                infoTxt: _vm.$t(
                                    'equisafe-ui.signature-template.actions.add-variables-tooltip'
                                ),
                                items: _vm.variables,
                                loading: _vm.loading || _vm.loadingVariables
                            },false),[_vm._v(" "+_vm._s(_vm.$t("equisafe-ui.signature-template.actions.add-variables"))+" ")]),_vm._t("actions",null,null,{
                                loading: _vm.loading || _vm.loadingVariables,
                                variables: _vm.variables,
                                signatories: _vm.signatories,
                                insertContent: _vm.editor.commands.insertContent
                            })]},proxy:true}],null,true)},'component',{
                        populateEditor: _vm.populateEditor,
                        otherExtensions: _vm.otherExtensions,
                        signature_procedure: _vm.signature_procedure,
                        html: _vm.selectedDoc.html,
                        loading: _vm.loading,
                        isRequired: _vm.isRequired,
                        signatories: _vm.selectedDoc.proceduredocumentsigner_set || []
                    },false))]}}],null,false,3050431852)},'EqsTabs',{
                tabs: _vm.tabs,
                eqsType: ['default', 'no-overflow'],
                eqsDataType: 'dense'
            },false)):_c('InternalPdfView',_vm._b({staticClass:"pdf-preview"},'InternalPdfView',{
                signature_procedure: _vm.signature_procedure,
                signatories: _vm.selectedDoc.proceduredocumentsigner_set,
                document: _vm.selectedDoc.base_document
            },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }