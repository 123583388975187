import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            header: {
                height: getVal(currentSize, {
                    mobile: "56px",
                    tablet: "56px",
                    desktop: "64px"
                })
            },
            subheader: {
                height: getVal(currentSize, {
                    mobile: "21px",
                    tablet: "21px",
                    desktop: "27px"
                }),
                "font-size": getVal(currentSize, {
                    mobile: "0.625rem",
                    tablet: "0.625rem",
                    desktop: "0.725rem"
                })
            }
        }
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {}
    };
};
