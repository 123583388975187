var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-lazy',[_c('v-container',{staticClass:"align-start pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',[_c('DataTablePaginated',{attrs:{"loading":_vm.loading || _vm.localLoading,"headers":_vm.headers,"items":_vm.orders,"sort-desc":"","sort-by":"created_at","show-row-pointer":"","filter":_vm.orderFilter},on:{"update":_vm.fetchOrders,"click-row":_vm.selectOrder},scopedSlots:_vm._u([{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.date)+" ")]}},{key:`item.buyer_name`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-start align-center"},[_c('EqsAvatar',_vm._b({staticClass:"pr-4"},'EqsAvatar',{
                                    noBadge: true,
                                    user: item.buyer
                                },false)),_vm._v(" "+_vm._s(item.buyer?.name)+" ")],1)]}},{key:`item.numberofshares`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$currency.display(item.numberofshares, "0,0"))+" ")]}},{key:`item.total_price`,fn:function({ item }){return [_c('span',{attrs:{"el-class":`subscriber-${item.id}-total-amount`}},[_vm._v(" "+_vm._s(_vm.$currency.display(item.total_price))+" ")])]}},{key:`item.payment_mode_txt`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.payment_mode_txt)+" ")]}},{key:`item.status`,fn:function({ item }){return [_c('EqsChip',{attrs:{"eqs-type":_vm.statutes[item.status].eqsType}},[_vm._v(" "+_vm._s(_vm.statutes[item.status].chip)+" ")])]}},{key:`item.actions`,fn:function({ item, index }){return [_c('EqsActions',_vm._b({},'EqsActions',{
                                elClass: `capinc-actions-${index}`,
                                actions: _vm.getActions(item)
                            },false))]}}],null,true)})],1)],1),(_vm.selectedOrder)?_c('OrderModal',{attrs:{"loading":_vm.loading || _vm.localLoading,"open":_vm.orderModalOpen,"order":_vm.selectedOrder},on:{"update:open":function($event){_vm.orderModalOpen=$event},"closeDialog":_vm.closeDialog}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }