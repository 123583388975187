var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"fill-height",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('EqsStepper',_vm._b({staticClass:"fill-height d-flex",staticStyle:{"height":"100%"},attrs:{"value":_vm.step,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":() => _vm.$emit('closeDigitalizeLedger')},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{class:`${_vm.currStep.noMainForm ? '' : 'eqs-main-container'}  margin-auto`},[(item.text)?_c('EqsTextDisplay',{staticClass:"text-center pb-5",attrs:{"value":item.text}}):_vm._e(),_c(item.is,_vm._b({tag:"component",staticClass:"fill-height fill-width"},'component',{
                            formData: _vm.formData,
                            schemaDouble: _vm.schemaDouble,
                            shareledgers: _vm.shareledgers,
                            loading: _vm.localLoading
                        },false))],1)]}}])},'EqsStepper',{
                steps: _vm.steps,
                progress: _vm.progress,
                closeInfoTxt: _vm.$t('shareledger.dialog.stepper.close'),
                checkProgress: _vm.currStep.checkProgress
            },false))]},proxy:true}])},'EqsCard',{
        loading: _vm.loading,
        height: '100%'
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }