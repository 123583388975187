<template>
    <EqsStepper
        :value.sync="step"
        v-bind="{ steps, progress, closeInfoTxt }"
        closable
        @close="comeBackToOrgans"
    >
        <template #default="{ item }">
            <div :class="item.class">
                <component
                    :is="item.is"
                    v-bind="{
                        schemaDouble,
                        formData,
                        organ,
                        loading:
                            loading ||
                            localLoading ||
                            shareledgersLoading ||
                            representativesLoading ||
                            investorsLoading,
                        edit,
                        organId,
                        shareledgers: shareledgersWithDetails,
                        gotLedgersSelection,
                        members,
                        saveNumberOfVotes,
                        ...item
                    }"
                />
            </div>
        </template>
    </EqsStepper>
</template>

<script>
import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";
import { getShareledgerFormMixin } from "@/mixins/shareledgerMixin";
import { mapActions, mapGetters } from "vuex";
import { EqsChooseAction } from "@equisafe-ui-vue";
import { getDiffArrayWith, mergeArraysByKey } from "@equisafe-ui-shared/util";

export default {
    name: "OrganStepper",
    components: {
        OrganCreate: () => import("./OrganCreate"),
        EqsChooseAction,
        OrganChooseLedgers: () => import("./OrganChooseLedgers"),
        OrganChooseMembers: () => import("./OrganChooseMembers"),
        OrganChooseBoard: () => import("./OrganChooseBoard")
    },
    mixins: [stepperFormMixins, getShareledgerFormMixin],
    props: {
        organId: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            formData: {},
            organ: {},
            step: 0,
            schemaDouble: {},
            localLoading: false,
            members: [],
            closeInfoTxt: this.$t("governance.organs.back")
        };
    },
    computed: {
        ...mapGetters([
            "representatives",
            "representativesLoading",
            "investors",
            "investorsLoading"
        ]),
        currStep() {
            return this.steps[this.step];
        },
        edit() {
            return !!this.organId;
        },
        existingRepresentatives() {
            return this.organ.members
                ? this.organ.members.filter(member => !!member.representative)
                : [];
        },
        existingInvestors() {
            return this.organ.members ? this.organ.members.filter(member => !!member.investor) : [];
        },
        shareledgersWithDetails() {
            return this.shareledgers.map(shareledger => {
                const shareledgerWithDetails = {
                    ...this.getShareledgerTypeDetail(shareledger),
                    ...shareledger,
                    share_type_name: this.getShareTypeName(shareledger),
                    numberofshares: shareledger.numberofshares || 0
                };
                return {
                    ...shareledgerWithDetails,
                    number_of_vote: shareledgerWithDetails.number_of_vote || 0,
                    number_of_vote_total: shareledgerWithDetails.number_of_vote
                        ? shareledgerWithDetails.number_of_vote *
                          shareledgerWithDetails.numberofshares
                        : 0
                };
            });
        },
        gotLedgersSelection() {
            return this.organ.vote_type == "share_to_vote";
        },
        steps() {
            let firstSteps = [
                {
                    title: !this.edit
                        ? this.$t("governance.organs.create-title")
                        : this.$t("governance.organs.edit-title"),
                    item: {
                        is: "OrganCreate",
                        class: "eqs-main-container margin-auto"
                    },
                    next: {
                        fn: this.edit ? this.editOrgan : this.addOrgan
                    },
                    back: {
                        fn: this.comeBackToOrgans,
                        txt: this.closeInfoTxt
                    },
                    schemaDouble: {
                        name: {
                            isRequired: true
                        },
                        initials: {
                            isRequired: true
                        }
                    }
                },
                {
                    title: this.$t("governance.organs.choose-vote-type-title"),
                    item: {
                        is: "EqsChooseAction",
                        class: "d-flex justify-center fill-height",
                        choices: [
                            {
                                title: this.$t("governance.organs.share_to_vote.title"),
                                text: this.$t("governance.organs.share_to_vote.text"),
                                img: "/images/organs/card1.svg",
                                action: () => this.chooseVoteType("share_to_vote")
                            },
                            {
                                inProgress: true,
                                title: this.$t("governance.organs.one_by_voter.title"),
                                text: this.$t("governance.organs.one_by_voter.text"),
                                img: "/images/organs/card2.svg",
                                action: () => this.chooseVoteType("one_by_voter")
                            },
                            {
                                title: this.$t("governance.organs.custom.title"),
                                text: this.$t("governance.organs.custom.text"),
                                img: "/images/organs/card3.svg",
                                action: () => this.chooseVoteType("custom")
                            }
                        ]
                    },
                    schemaDouble: {
                        vote_type: {
                            isRequired: true
                        }
                    },
                    noNext: true
                }
            ];
            const chooseLedgersStep = {
                title: this.$t("governance.organs.choose-ledgers-title"),
                item: {
                    is: "OrganChooseLedgers",
                    class: "eqs-main-container margin-auto"
                },
                next: {
                    fn: this.editOrgan
                },
                schemaDouble: {
                    shareledgers: {
                        isRequired: true,
                        dataType: "array_of_id"
                    }
                }
            };
            const chooseVotersStep = {
                title: this.$t("governance.organs.choose-members-title"),
                item: {
                    is: "OrganChooseMembers",
                    class: "eqs-main-container margin-auto"
                },
                next: {
                    fn: this.editOrgan
                },
                schemaDouble: {
                    members: {
                        isRequired: true,
                        dataType: "array"
                    },
                    shareledgers: {
                        isRequired: false,
                        dataType: "array_of_id"
                    }
                }
            };
            const chooseBoardStep = {
                title: this.$t("governance.organs.choose-board-title"),
                item: {
                    is: "OrganChooseBoard",
                    class: "eqs-main-container margin-auto"
                },
                next: {
                    fn: this.finalize
                },
                schemaDouble: {
                    members: {
                        isRequired: true,
                        dataType: "array"
                    },
                    shareledgers: {
                        isRequired: false,
                        dataType: "array_of_id"
                    }
                }
            };
            return !this.gotLedgersSelection
                ? [...firstSteps, chooseVotersStep, chooseBoardStep]
                : [...firstSteps, chooseLedgersStep, chooseBoardStep];
        }
    },
    watch: {
        organId: {
            immediate: true,
            async handler(val) {
                if (!!val) {
                    try {
                        this.organ = await this.getOrgan(val);
                        this.step = this.getLastFormStep(this.organ);
                        await this.fetchVuexModules();
                        this.populateFormData();
                    } catch (e) {
                        this.$ui.error(e, "governance_organ_get");
                    }
                }
            }
        },
        investors(val) {
            if (val.length && this.currStep.item.is === "OrganChooseMembers") {
                this.populateMembers({
                    investors: val
                });
            }
        },
        representatives(val) {
            if (val.length && this.currStep.item.is === "OrganChooseBoard") {
                this.populateMembers({
                    representatives: this.representatives
                });
            }
        },
        currStep: {
            immediate: true,
            handler(val) {
                this.schemaDouble = val && val.schemaDouble ? val.schemaDouble : {};
                switch (val.item.is) {
                    case "OrganChooseMembers":
                        this.populateMembers({
                            investors: this.investors
                        });
                        break;
                    case "OrganChooseBoard":
                        this.populateMembers({
                            representatives: this.representatives
                        });
                        break;
                }
                this.populateFormData();
            }
        }
    },
    created() {
        this.getOrgans();
    },
    methods: {
        populateMembers(data) {
            let { representatives, investors } = data;
            if (representatives) {
                representatives = representatives.map(representative => {
                    return { representative, representative_id: representative.id };
                });
                this.members = mergeArraysByKey(
                    representatives,
                    this.existingRepresentatives,
                    "representative_id"
                );
            } else if (investors) {
                investors = investors.map(investor => {
                    return { investor, investor_id: investor.id };
                });
                this.members = mergeArraysByKey(
                    investors,
                    this.existingInvestors,
                    "investor_id"
                ).map(member => {
                    return { ...member, nb_vote: member.nb_vote || 0 };
                });
            }
        },
        saveNumberOfVotes(index, nb_vote) {
            this.members[index] = {
                ...this.members[index],
                nb_vote
            };
        },
        async fetchVuexModules() {
            const results = await Promise.allSettled([
                this.getShareledgers(),
                this.getInvestors(),
                this.getRepresentatives()
            ]);
            results.forEach(res => {
                if (res.status === "rejected") {
                    console.error({ e: res.reason });
                    throw res.reason.toString();
                }
            });
        },
        populateFormData() {
            const shareledgers = this.organ.shareledgers
                ? this.shareledgersWithDetails.filter(shareledger =>
                      this.organ.shareledgers.find(
                          _shareledger => _shareledger.id === shareledger.id
                      )
                  )
                : [];
            this.buildFormData(this.schemaDouble, {
                ...this.organ,
                members:
                    this.currStep.item.is === "OrganChooseMembers"
                        ? this.existingInvestors
                        : this.existingRepresentatives,
                shareledgers
            });
            this.saveFormData();
        },
        comeBackToOrgans() {
            this.$router.push({
                name: "governance",
                query: { tab: "organs_representatives" }
            });
        },
        async chooseVoteType(voteType) {
            this.formData = { vote_type: voteType };
            await this.editOrgan();
        },
        async finalize() {
            try {
                await this.editOrgan();
                this.comeBackToOrgans();
            } catch (e) {
                console.error({ e });
            }
        },
        async addOrgan() {
            try {
                this.localLoading = true;
                const res = await this.createOrgan(this.formData);
                this.$router.replace({
                    name: "governance-organ-edit",
                    params: {
                        organId: res.id
                    }
                });
            } catch (e) {
                this.handleError(e, "governance_organ_create");
            } finally {
                this.localLoading = false;
            }
        },
        async updateOrganMembers() {
            const membersToDelete = getDiffArrayWith(
                this.currStep.item.is === "OrganChooseMembers"
                    ? this.existingInvestors
                    : this.existingRepresentatives,
                this.formData.members
            );
            if (membersToDelete.length) {
                const results = await Promise.allSettled(
                    membersToDelete.map(member => this.$api.deleteOrganMember(member.url))
                );
                results.forEach(res => {
                    if (res.status === "rejected") {
                        console.error({ res });
                        throw res;
                    }
                });
            }
        },
        async editOrgan() {
            try {
                if (this.errorNoFieldsChanged) {
                    if (this.step < this.steps.length) this.step++;
                    return;
                }
                this.localLoading = true;
                const id = this.organ.id;
                let data = {};
                if (this.formData.members) {
                    await this.updateOrganMembers();
                    data.members = this.formData.members.map(member => {
                        let res = {};
                        if (member.investor) {
                            res.investor_id = member.investor_id;
                            if (!this.gotLedgersSelection) res.nb_vote = member.nb_vote;
                        } else if (member.representative)
                            res.representative_id = member.representative.id;
                        return res;
                    });
                }
                let req = this.formatFormData(this.schemaDouble, data);
                if (this.currStep.item.is !== "OrganChooseLedgers") delete req.shareledgers;
                this.organ = await this.updateOrgan({
                    id,
                    ...req
                });

                this.step = this.getLastFormStep(this.organ);
            } catch (e) {
                this.handleError(e, "governance_organ_update");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        ...mapActions([
            "getOrgans",
            "getOrgan",
            "createOrgan",
            "updateOrgan",
            "getRepresentatives",
            "getInvestors"
        ])
    }
};
</script>

<style lang="scss" scoped></style>
