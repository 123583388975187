var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"eqs-choose-action fill-height fill-width ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-3 d-flex fill-height d-flex justify-center align-center"},_vm._l((_vm.choices),function(choice,i){return _c('v-col',{key:i,staticClass:"d-flex justify-center eqs-choose-action__item mb-3",attrs:{"cols":_vm.$reactive.getCols(_vm.ratio)}},[_c('EqsClickableCard',_vm._b({scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('div',{staticClass:"d-flex fill-height flex-column justify-start align-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"pa-3 d-flex flex-column"},[(choice.inProgress)?_c('EqsChip',{staticClass:"py-3",attrs:{"eqs-type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("payments.in-progress"))+" ")]):_vm._e(),_c('EqsTextDisplay',{staticClass:"eqs-choose-action__item__text text-no-wrap",attrs:{"paragraph":"","value":choice.text}})],1),_c('div',{staticClass:"d-flex justify-center fill-width",staticStyle:{"height":"inherit"}},[_c('img',{staticClass:"eqs-choose-action__item__img pa-3",attrs:{"src":choice.img}})])])]},proxy:true}],null,true)},'EqsClickableCard',{
                    ...choice,
                    elClass: `${_vm.elClass}-${i}`,
                    img: '',
                    width: '100%',
                    height: 'inherit',
                    disabled: choice.inProgress
                },false))],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }