var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-text-area",attrs:{"el-class":_vm.elClass}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
            ..._vm.$attrs,
            label: _vm.label,
            height: _vm.labelHeight,
            editable: _vm.editable,
            value: _vm.label,
            infoTxt: _vm.infoTxt,
            isRequired: _vm.isRequired
        },false),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),_c('EqsEditable',_vm._b({attrs:{"paragraph":""}},'EqsEditable',{ ..._vm.$attrs, editable: _vm.editable, value: _vm.editableData },false),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),_c('EqsSkeleton',_vm._b({},'EqsSkeleton',{ loading: _vm.loading, type: 'paragraph' },false),[_c('v-textarea',_vm._g(_vm._b({class:`${_vm.getClassFromState} rounded-lg`,style:(_vm.getStyle),attrs:{"background-color":_vm.$color.get().inputBackground,"el-class":`${_vm.elClass}-field`,"value":_vm.valueString,"rules":_vm.rulesRequired,"clearable":!_vm.readonly && !_vm.disabled,"hide-details":"auto","outlined":"","filled":"","rounded":"","dense":"","auto-grow":""},on:{"input":_vm.handlerForm}},'v-textarea',{
                        readonly: _vm.readonly,
                        placeholder: _vm.placeholder,
                        disabled: _vm.disabled,
                        ..._vm.$attrs
                    },false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"el-class":`${_vm.elClass}-value`}},[_vm._v(" "+_vm._s(_vm.valueString)+" ")])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }