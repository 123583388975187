// "use strict";

import Color from "color";

function fallback(/* values ... */) {
    var ilen = arguments.length;
    var i = 0;
    var v;

    for (; i < ilen; ++i) {
        v = arguments[i];
        if (v !== undefined) {
            return v;
        }
    }
}

var COLORS = [
    "#FF3784",
    "#36A2EB",
    "#4BC0C0",
    "#F77825",
    "#9966FF",
    "#00A8C6",
    "#379F7A",
    "#CC2738",
    "#8B628A",
    "#8FBE00",
    "#606060"
];

var NEW_COLORS = [
    "#F1D669",
    "#DD9F8A",
    "#91A2CF",
    "#C7D8AA",
    "#A17B77",
    "#9A78A0",
    "#799E9B",
    "#93C8CE",
    "#DB8A8A",
    "#E8A956"
];

export class ChartService {
    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
    constructor() {
        this.srand(Date.now());
    }

    srand(seed) {
        this._seed = seed;
    }

    rand(min, max) {
        var seed = this._seed;
        min = min === undefined ? 0 : min;
        max = max === undefined ? 1 : max;
        this._seed = (seed * 9301 + 49297) % 233280;
        return min + (this._seed / 233280) * (max - min);
    }

    numbers(config) {
        var cfg = config || {};
        var min = fallback(cfg.min, 0);
        var max = fallback(cfg.max, 1);
        var from = fallback(cfg.from, []);
        var count = fallback(cfg.count, 8);
        var decimals = fallback(cfg.decimals, 8);
        var continuity = fallback(cfg.continuity, 1);
        var dfactor = Math.pow(10, decimals) || 0;
        var data = [];
        var i, value;

        for (i = 0; i < count; ++i) {
            value = (from[i] || 0) + this.rand(min, max);
            if (this.rand() <= continuity) {
                data.push(Math.round(dfactor * value) / dfactor);
            } else {
                data.push(null);
            }
        }
        return data;
    }

    color(offset) {
        var count = COLORS.length;
        var index = offset === undefined ? ~~this.rand(0, count) : offset;
        return COLORS[index % count];
    }

    colors(config) {
        var cfg = config || {};
        var color = cfg.color || this.color(0);
        var count = cfg.count !== undefined ? cfg.count : 8;
        var method = cfg.mode ? Color.prototype[cfg.mode] : null;
        var values = [];
        var i, f, v;

        for (i = 0; i < count; ++i) {
            f = i / count;
            if (method) {
                v = method.call(Color(color), f).hex();
            } else {
                v = this.color(i);
            }
            values.push(v);
        }
        return values;
    }

    newColor(offset) {
        let count = NEW_COLORS.length;
        let index = offset === undefined ? ~~this.rand(0, count) : offset;
        return NEW_COLORS[index % count];
    }

    newColors(config) {
        let cfg = config || {};
        let count = cfg.count !== undefined ? cfg.count : 8;
        let values = [];
        let i, v;

        for (i = 0; i < count; ++i) {
            v = this.newColor(i);
            values.push(v);
        }
        return values;
    }

    hashCode(str) {
        var h = 0,
            i = str.length;
        while (i > 0) {
            h = ((h << 5) - h + str.charCodeAt(--i)) | 0;
        }
        return h;
    }

    colorFromHash(data) {
        if (!data) return "";
        const modulo = COLORS.length;
        return COLORS[this.hashCode(data.toString()) % modulo];
    }

    transparentize(color, opacity) {
        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
        return Color(color).alpha(alpha).hex();
    }
}
