<template>
    <v-container fluid class="align-start">
        <v-row class="ma-0 pa-0" justify="end">
            <v-col cols="auto">
                <v-btn class="custom-btn" outlined @click="$emit('invite')">
                    {{ $t("shareledger.white-list.data-table.invite-investors") }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" dense>
            <v-col>
                <DataTablePaginated
                    :loading="loading || localLoading"
                    :headers="investorsTableHeaders"
                    :items="investors"
                    show-row-pointer
                    search="name"
                    sort-by="name"
                    @update="fetchInvestors"
                    @click-row="openInvestorDetails"
                >
                    <template #[`item.name`]="{ item }">
                        <div class="cell d-flex flex-row align-center">
                            <v-row class="cell d-flex flex-row align-center">
                                <v-col cols="3">
                                    <EqsAvatar
                                        :user="item.entity ? item.entity : item"
                                        :no-badge="true"
                                    />
                                </v-col>
                                <v-col cols="9">
                                    <EqsTextDisplay
                                        style="white-space: nowrap"
                                        class="text-left"
                                        :value="item.entity ? item.entity.name : item.name"
                                        eqs-type="subtitle"
                                    />
                                    <EqsTextDisplay
                                        class="text-left"
                                        :value="item.type_display"
                                        eqs-type="caption"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                    <template #[`item.markettype`]="{ item }">
                        <v-chip-group max="0">
                            <EqsChip
                                label
                                :ripple="false"
                                :color="$color.colorFromHash(item.markettype)"
                                text-color="text"
                            >
                                {{ item.markettype }}
                            </EqsChip>
                        </v-chip-group>
                    </template>
                    <template #[`item.shares`]="{ value }">
                        <v-chip-group max="0">
                            <EqsChip
                                v-for="(share, i) in value"
                                :key="i"
                                label
                                :ripple="false"
                                :color="$color.colorFromHash(share ? share.name : '')"
                                text-color="text"
                            >
                                {{ share ? share.name : "" }}
                            </EqsChip>
                        </v-chip-group>
                    </template>

                    <template #[`item.actions`]="{ item }">
                        <v-layout justify-center align-center>
                            <EqsTextDisplay v-if="item.entity && item.entity.invited">
                                {{
                                    item.entity.lemonway_status > 1
                                        ? $t("shareledger.white-list.to-invite-list.join", {
                                              universe: universe.public_name
                                          })
                                        : $t("shareledger.white-list.to-invite-list.invite")
                                }}
                            </EqsTextDisplay>
                            <EqsBtn
                                v-else
                                small
                                color="primary"
                                v-bind="{
                                    loading: localLoading && inviteLoading,
                                    action: () => localSendInvitationInvestor(item)
                                }"
                            >
                                {{ $t("shareledger.white-list.to-invite") }}
                            </EqsBtn>
                        </v-layout>
                    </template>

                    <template #[`item.percent`]="{ item }">
                        <span v-if="!!item.percent">{{
                            $currency.getPercent(item.percent, 1) + " %"
                        }}</span>
                    </template>

                    <template #[`item.amount`]="{ item }">
                        {{
                            $currency.displayWithCurrency(
                                item.amount,
                                $entity.represented().country.alphacode == "US" ? "USD" : "EUR"
                            )
                        }}
                    </template>
                    <template #[`item.onchain`]="{ item }">
                        <EqsChip
                            v-if="item.addinvestor_tx"
                            v-bind="states[item.addinvestor_tx.state]"
                        >
                            {{ states[item.addinvestor_tx.state].txt }}
                        </EqsChip>
                    </template>
                    <!-- <template #other>
            <div class="d-flex justify-end" style="margin-right: 5px">
                <EqsBtn
                    text
                    class="pa-0"
                    el-class="download-shareholders-data"
                    vf
                >
                    <v-icon color="primary"> mdi-file-download-outline </v-icon>
                </EqsBtn>
            </div>
        </template> -->
                </DataTablePaginated>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// item.entity.registration_step
// item.entity.lemonway_state === "success"
// ALLOWED in whitelist issuer, but can be NO KYC (when issuer added him when digitalize is company)

// https://sft-protocol.readthedocs.io/en/latest/kyc.html#adding-investors setInvestorRestriction
// item.add_investor_tx
const kycIssuerStatus = {
    NOKYC: 0, // when null, button ADD
    ALLOWED: 1, // added, !null && !restricted -> is able to receive tokens
    RESTRICTED: 2 // in whitelist, so was whitelist but boolean 'restricted'
};

import DataTablePaginated from "@/components/views/DataTablePaginated";
// import { createWorkbook } from "@/services/excelJs";
import { mapActions, mapGetters } from "vuex";
import { states } from "@/services/blockchain/utils";

export default {
    name: "InvestorWhitelistTable",
    components: { DataTablePaginated },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    emits: ["open:investorDetails", "close:investorDetails", "add"],
    data() {
        return {
            investors: {},
            states,
            localLoading: false,
            inviteLoading: false,
            investorsTableHeaders: [
                // {
                //     text: this.$t("shareledger.white-list.account_number"),
                //     value: "account_number",
                //     filterable: true,
                //     align: "center"
                // },
                {
                    text: this.$t("shareledger.white-list.user-name"),
                    value: "name",
                    sortable: true,
                    align: "center"
                },
                {
                    text: this.$t("shareledger.white-list.shares"),
                    sortable: false,
                    value: "shares",
                    align: "center"
                },
                {
                    text: this.$t("shareledger.white-list.percent"),
                    sortable: false,
                    value: "percent",
                    align: "center"
                },
                {
                    text: this.$t("portfolio.table.amount-label"),
                    sortable: false,
                    value: "amount",
                    align: "right"
                },
                {
                    text: this.$t("shareledger.white-list.onchain"),
                    align: "center",
                    sortable: false,
                    value: "onchain"
                },
                {
                    text: this.$t("shareledger.white-list.to-invite"),
                    align: "center",
                    sortable: false,
                    value: "actions"
                }
            ]
        };
    },
    computed: {
        ...mapGetters(["universe"]),
        topHeightToAdd() {
            return this.$reactive.getVal({
                mobile: 142,
                tablet: 105,
                desktop: 50
            });
        }
    },
    methods: {
        // async downloadShareholdersData() {
        //     try {
        //         this.loadingDownload = true;
        //         const res = await this.$api.readBalanceList({
        //             entity_represented: this.$entity.represented().id,
        //             withAssetMovements: true,
        //             ordering: "created_at"
        //         });
        //         const balanceList = res.data;
        //         if (balanceList.by_shareholder && balanceList.by_shareholder.length) {
        //             await createWorkbook("Shareholder", balanceList, this.$entity.represented());
        //         }
        //     } catch (e) {
        //         this.$ui.error(e, "download_shareholders_list");
        //     } finally {
        //         this.loadingDownload = false;
        //     }
        // },
        async fetchInvestors(queries) {
            this.localLoading = true;
            const investorRes = await this.$api.readInvestorWhiteList({
                company_id: this.$store.getters.currentRole.entity_represented.id,
                ...queries
            });
            this.investors = investorRes.data;
            this.localLoading = false;
        },
        openInvestorDetails(item) {
            if (item) {
                this.$emit("open:investorDetails", item);
            } else {
                this.$emit("close:investorDetails", null);
            }
        },
        async localSendInvitationInvestor(item) {
            this.inviteLoading = true;
            this.$api.invite(item.id).then(() => {
                const index = this.investors.results.findIndex(invest => invest.id === item.id);
                if (index !== -1) {
                    let investor = this.investors.results[index];
                    investor.entity.invited = true;
                    Object.assign(this.investors.results[index], investor);
                    this.inviteLoading = false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    text-transform: none !important;
    color: white !important;
    background-color: var(--v-primary-base) !important;
}
</style>
