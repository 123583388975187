import DEBUG from "@/store/debug.js";

export default async ({ state, commit }) => {
    DEBUG("GET_BLOCKCHAIN_INFO_START");
    if (!state.blockchainInfoLoading) {
        try {
            const res = await window.app.$api.getBlockchainizeState();
            commit("GET_BLOCKCHAIN_INFO", res.data);
        } catch (e) {
            DEBUG("GET_BLOCKCHAIN_INFOR_ERROR");
            console.error({ e });
            throw e;
        } finally {
            commit("SET_BLOCKCHAIN_INFO_LOADING", false);
        }
    }
};
