<template>
    <div class="eqs-main-container margin-auto">
        <GlobalFinalState
            :loading="loading"
            :state="finalState"
            :error-text="errorText"
            v-bind="{ loading, state: finalState, errorText, title: errorTitle }"
        >
            <template #content>
                <EqsTextDisplay
                    v-if="finalState !== 'error'"
                    el-class="success-txt"
                    class="text-center"
                    eqs-type="subtitle"
                    :value="$t('payments.asked.success-txt')"
                />
            </template>
        </GlobalFinalState>
    </div>
</template>

<script>
export default {
    name: "PaymentDone",
    props: {
        state: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        payment: {
            type: Object,
            required: true
        }
    },
    computed: {
        finalState() {
            return this.state === "refused" ? "error" : this.state;
        },
        errorTitle() {
            if (this.payment && this.payment.lemonway_status == "refused") {
                return this.$t("payments.asked.refused.success-title");
            }
            return "";
        },
        errorText() {
            if (this.payment && this.payment.lemonway_status == "refused") {
                return this.$t("payments.asked.refused.success-txt");
            }
            return "";
        }
    }
};
</script>
