<template>
    <v-container fluid>
        <!-- Header : recherche et filtre -->
        <v-container fluid class="ma-0 pa-0">
            <v-row v-if="search || filter" justify="start" align="end" class="ma-0 pa-0">
                <v-col v-if="search" cols="12" md="6">
                    <v-text-field
                        v-model="searchLocal"
                        append-icon="mdi-magnify"
                        :label="$t('pagination.search')"
                        persistent-hint
                        clearable
                        @input="searchChanged"
                        @click:clear="
                            () => {
                                searchLocal = null;
                                updateItems();
                            }
                        "
                    />
                </v-col>
                <v-col v-if="filter" cols="12" md="6">
                    <v-combobox
                        v-model="filterLocal"
                        :items="filter.items"
                        :label="$t('pagination.filter-by')"
                        clearable
                        item-text="text"
                        item-value="value"
                        multiple
                        persistent-hint
                        chips
                        deletable-chips
                        @change="updateFilter"
                    />
                </v-col>
            </v-row>
        </v-container>
        <!-- Body : tableau de données -->
        <v-data-table
            :headers="headers"
            :items="itemsLocal"
            fixed-header
            :server-items-length="paginationCount"
            item-key="id"
            :show-expand="showExpand"
            :options.sync="optionsDataTable"
            :page.sync="page"
            hide-default-footer
            :loading="loading"
            :loading-text="$t('pagination.loading-data')"
            :class="showRowPointer ? 'row-pointer' : ''"
            @update:options="updateItems"
            @click:row="$emit('click-row', ...arguments)"
        >
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <template #no-data>{{ $t("pagination.no-data") }}</template>
            <template #no-results>{{ $t("pagination.no-data") }}</template>
        </v-data-table>
        <!-- Footer : pages et items par page -->
        <v-container v-if="!loading" fluid class="mt-4">
            <v-row justify="end" justify-content="end" align="start">
                <v-col v-if="nOfPages > 1" cols="12" sm="9" md="10" justify="end">
                    <v-pagination v-model="page" :length="nOfPages" :total-visible="7" />
                </v-col>
                <v-col cols="12" sm="3" md="2">
                    <v-select
                        v-model="itemsPerPageProxy"
                        :items="DEFAULT_ITEMS_PER_PAGE_LIST"
                        :label="$t('pagination.element-per-page')"
                        outlined
                        @input="updateItemsPerPage"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
const DEFAULT_PAGE = 1;
const DEFAULT_ITEMS_PER_PAGE = 10;
// All in ms
const DEFAULT_INPUT_DELAY = 700;
const DEFAULT_ITEMS_PER_PAGE_LIST = [DEFAULT_ITEMS_PER_PAGE, 15, 25];
const DEFAULT_EMIT_DELTA = 230; // Sécurité pour éviter le spam requêtes

export default {
    name: "DataTablePaginated",
    components: {},
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        headers: {
            type: Array,
            required: true
        },
        /*
            Réponse serveur avec pagination
        */
        items: {
            type: Object,
            required: false,
            default: null
        },
        sortBy: {
            type: String,
            required: false,
            default: ""
        },
        sortDesc: {
            type: Boolean,
            required: false,
            default: false
        },
        /*
            Sur quel champs s'appuie la recherche
        */
        search: {
            type: String,
            required: false,
            default: null
        },
        /*
            {
                field: str, champs du filtre,
                items: array, choix à afficher
            }
        */
        filter: {
            type: Object,
            required: false,
            default: null
        },
        showExpand: {
            type: Boolean,
            required: false,
            default: false
        },
        manageRoute: {
            type: Boolean,
            required: false,
            default: false
        },
        showRowPointer: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ["update", "click-row"],
    data() {
        return {
            DEFAULT_ITEMS_PER_PAGE_LIST,
            page: DEFAULT_PAGE,
            optionsDataTable: {
                page: DEFAULT_PAGE,
                itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
                sortBy: [this.sortBy],
                sortDesc: [this.sortDesc]
            },
            itemsPerPageProxy: DEFAULT_ITEMS_PER_PAGE,
            paginationCount: DEFAULT_ITEMS_PER_PAGE,
            searchLocal: "",
            searchInputDelay: {
                timeInput: 0,
                timeout: null
            },
            filterLocal: null,
            lastEmitUpdate: 0,
            itemsLocal: []
        };
    },
    computed: {
        nOfPages() {
            let nOfPages = 1;
            try {
                nOfPages = Math.ceil(this.paginationCount / this.optionsDataTable.itemsPerPage);
            } catch {
            } finally {
                if (isNaN(nOfPages) || nOfPages < 1) return 1;
                else return nOfPages;
            }
        }
    },
    watch: {
        items: {
            handler: function (items) {
                if (this.items?.results) {
                    this.paginationCount = items.count;
                    this.itemsLocal = items.results;
                }
            },
            immediate: true
        }
    },
    beforeMount() {
        if (!this.manageRoute) {
            return;
        }
        const queries = this.$route.query;
        if (queries.page) {
            this.page = parseInt(queries.page);
        }
        if (queries.page_size) {
            this.optionsDataTable.itemsPerPage = parseInt(queries.page_size);
            this.itemsPerPageProxy = this.optionsDataTable.itemsPerPage;
        }
        if (queries.sort_by) {
            this.optionsDataTable.sortBy = [decodeURIComponent(queries.sort_by)];
        }
        if (queries.sort_desc) {
            const sortDesc = decodeURIComponent(queries.sort_desc);
            this.optionsDataTable.sortDesc = sortDesc === "false" ? [false] : [true];
        }
        if (this.filter && queries[this.filter.field]) {
            const filterList = decodeURIComponent(queries[this.filter.field])
                .split(",")
                .map(v => this.filter.items.find(item => item.value === v));
            this.filterLocal = filterList;
        }
        if (this.search && queries[this.search]) {
            this.searchLocal = decodeURIComponent(queries[this.search]);
        }
    },
    methods: {
        async emitUpdate() {
            /*
                Requête :
                page : int, page en cours
                page_size : int, items par page
                sort_by : str, tri par un champs
                sort_desc : bool, true = desc, false = asc
                [filter|search]? : str, filter et search

                Response:
                count : int, nombre d'items total en db
                next : str, requete pour la page suivante, null si derniere page
                prev : str, requete pour la page precedente, null si premiere
                results : array, data retournees
            */
            const sortBy = this.optionsDataTable.sortBy?.length
                ? this.optionsDataTable.sortBy[0]
                : "";
            const sortDesc = this.optionsDataTable.sortDesc?.length
                ? this.optionsDataTable.sortDesc[0]
                : "";
            let filterList = "";
            if (this.filterLocal) {
                filterList = this.filterLocal.map(v => v.value).join(",");
            }

            let queries = {};
            if (this.page > 0) queries.page = this.page;
            if (this.optionsDataTable.itemsPerPage !== 10)
                queries.page_size = this.optionsDataTable.itemsPerPage;
            if (sortBy !== "") queries.sort_by = encodeURIComponent(sortBy);
            if (sortDesc !== "") queries.sort_desc = encodeURIComponent(sortDesc);
            if (this.searchLocal && this.searchLocal.length > 0) {
                queries[this.search] = encodeURIComponent(this.searchLocal);
            }
            if (filterList != "") {
                queries[this.filter.field] = encodeURIComponent(filterList);
            }

            let currentQueries = this.$route.query;
            const listQueries = ["page", "page_size", "sort_by", "sort_desc"];
            if (this.search) {
                listQueries.push(this.search);
            }
            if (this.filter) {
                listQueries.push(this.filter.field);
            }
            for (const query of listQueries) {
                delete currentQueries[query];
            }
            if (this.manageRoute) {
                this.$router.replace({ query: { ...currentQueries, ...queries } }).catch(() => {});
            }
            const dateNow = Date.now();
            if (dateNow - this.lastEmitUpdate > DEFAULT_EMIT_DELTA) {
                this.lastEmitUpdate = dateNow;
                this.$emit("update", queries);
            }
        },
        clearSearchTimeout() {
            if (this.searchInputDelay.timeout) {
                clearTimeout(this.searchInputDelay.timeout);
            }
            this.searchInputDelay.timeout = null;
        },
        async searchChanged() {
            if (
                this.searchLocal === null ||
                (this.searchLocal.length < 3 && this.searchLocal.length > 0)
            ) {
                return; // 3 char mini pour lancer la recherche
            }
            const dateNow = Date.now();
            if (dateNow - this.searchInputDelay.timeInput < DEFAULT_INPUT_DELAY) {
                this.clearSearchTimeout();
                this.searchInputDelay.timeout = setTimeout(async () => {
                    await this.forceUpdatePageDefault();
                }, DEFAULT_INPUT_DELAY + 50);
            } else {
                if (this.searchInputDelay.timeout === null) {
                    this.searchInputDelay.timeout = setTimeout(async () => {
                        await this.forceUpdatePageDefault();
                    }, DEFAULT_INPUT_DELAY + 50);
                }
            }
            this.searchInputDelay.timeInput = dateNow;
        },
        async updateFilter() {
            await this.forceUpdatePageDefault();
        },
        async updateItems() {
            this.clearSearchTimeout();
            await this.emitUpdate();
        },
        updateItemsPerPage(val) {
            this.clearSearchTimeout();
            const newItemsPerPage = parseInt(val, 10);
            if (newItemsPerPage === this.optionsDataTable.itemsPerPage) {
                return;
            }
            const currentItem = this.optionsDataTable.itemsPerPage * (this.page - 1);
            const newCurrentPage = Math.floor(currentItem / newItemsPerPage);
            this.optionsDataTable.itemsPerPage = newItemsPerPage;
            this.page = newCurrentPage + 1;
        },
        async forceUpdatePageDefault() {
            this.clearSearchTimeout();
            if (this.page === DEFAULT_PAGE) {
                await this.emitUpdate(); // No page change, force refetch
            } else {
                this.page = DEFAULT_PAGE; // page change call fetch
            }
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.row-pointer) {
    tbody {
        tr {
            cursor: pointer;
        }
    }
}
</style>
