var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({},'EqsCard',{
        width: '100%',
        progress: _vm.editable ? _vm.progress : 0,
        success: _vm.hasChanged,
        validForm: _vm.validForm,
        eqsType: 'container'
    },false),[_c('div',{staticClass:"d-flex flex-column mb-6"},[_c('v-row',{staticClass:"px-0"},[_c('v-col',{staticClass:"eqs-form-field custom-bigger-title",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                        value: _vm.subtitle,
                        eqsType: 'subtitle'
                    },false))],1)],1),_c('v-form',{staticClass:"pl-6 ma-0",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_vm._t("content",null,null,{
                    schemaDouble: _vm.schemaDouble,
                    isAffiliationInvestor: _vm.content.isAffiliationInvestor,
                    editable: _vm.editable,
                    formData: _vm.formData,
                    loading: _vm.loading || _vm.localLoading,
                    showPea: _vm.content.showPea,
                    showPeapme: _vm.content.showPeapme
                })],2),(_vm.entity.lemonway_state && _vm.isEditable)?_c('EqsCrudActionBtns',_vm._b({staticClass:"mt-2",attrs:{"editable":_vm.editable,"has-changed":_vm.hasChanged},on:{"update:editable":function($event){_vm.editable=$event},"update:hasChanged":function($event){_vm.hasChanged=$event},"update:has-changed":function($event){_vm.hasChanged=$event}}},'EqsCrudActionBtns',{ validForm: _vm.validForm, cancel: _vm.cancel, save: _vm.save },false)):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }