<template>
    <div class="d-flex flex-column" :style="getStyle">
        <div class="d-flex flex-column justify-center" :style="`padding-left: ${getSoftCapPos}`">
            <div class="d-flex flex-column align-start" style="color: #336aec; font-weight: Bold">
                <span>{{ $t("capital-increase.soft-cap") }}</span>
                <span>{{ $currency.abreviate(softCap) }}</span>
            </div>
            <div id="soft-cap-bar" />
        </div>
        <div id="progress-bar">
            <div id="progress-header">
                <slot name="header" />
            </div>
            <div id="progress-text">
                <div class="progress-text-content left">
                    <slot name="action" />
                    {{ percentRaised }}%
                </div>
                <div class="progress-text-content right">
                    <slot name="right" />
                </div>
            </div>
            <progress :value="value" :max="max" />
        </div>
        <div class="d-flex flex-column align-start">
            <div id="hard-cap-bar" :style="`margin-left: 99%;`" />
            <div
                class="d-flex flex-column align-end"
                :style="`margin-left: ${$reactive.getVal({
                    mobile: '70%',
                    tablet: '88%',
                    desktop: '90%'
                })}; color: #6abf73; font-weight: Bold`"
            >
                <span class="text-end">{{ $t("capital-increase.hard-cap") }}</span>
                <span>{{ $currency.abreviate(max) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { calcPercent } from "@equisafe-ui-shared/util";

export default {
    name: "MoneyRaised",
    props: {
        value: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        softCap: {
            type: Number
        }
    },
    data() {
        return {
            calcPercent,
            status: {
                pending: {
                    colordeg: "125deg",
                    color1: "#f17334",
                    color2: "#fcbe2f"
                },
                success: {
                    colordeg: "306deg",
                    color1: "#1dcbff",
                    color2: "#336aec"
                },
                finished: {
                    colordeg: "139deg",
                    color1: "#19998e",
                    color2: "#6abf73"
                }
            }
        };
    },
    computed: {
        getStyle() {
            const status = this.getStatus();
            return {
                "--color1": status.color1,
                "--color2": status.color2,
                "--colordeg": status.colordeg,
                "--success-color": this.status.success.color2,
                "--finished-color": this.status.finished.color2
            };
        },
        getSoftCapPos() {
            const pos = calcPercent(this.softCap, this.max);
            return `${pos >= 92 ? 92 : pos}%;`;
        },
        percentRaised() {
            return this.calcPercent(this.value, this.max);
        }
        // get
    },
    methods: {
        getStatus() {
            if (this.value >= this.max) return this.status.finished;
            return this.percentRaised >= 100 ? this.status.success : this.status.pending;
        }
    }
};
</script>

<style lang="scss" scoped>
#progress-bar {
    width: 100%;
    position: relative;
}

#soft-cap-bar {
    background: transparent linear-gradient(306deg, #1dcbff 0%, #336aec 100%) 0% 0% no-repeat
        padding-box;
    border-radius: 8px;
    opacity: 1;
    width: 2px;
    height: 18px;
    margin-bottom: 2px;
}

#hard-cap-bar {
    background: transparent linear-gradient(139deg, #19998e 0%, #6abf73 100%) 0% 0% no-repeat
        padding-box;
    border-radius: 8px;
    opacity: 1;
    width: 3px;
    height: 24px;
    margin-top: 2px;
}

progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 32px;
    border-radius: 8px;
    border: 0px;
    overflow: hidden;
    color: #153055;
    transition: all 5s;
}

progress::-webkit-progress-bar {
    background-color: #ddd;
}

progress::-moz-progress-bar {
    background-color: #ddd;
}

progress::-webkit-progress-value {
    background: transparent linear-gradient(var(--colordeg), var(--color1) 0%, var(--color2) 100%)
        0% 0% no-repeat padding-box;
    border-radius: 8px;
    transition: all 0.5s;
}

progress::-moz-progress-bar {
    background: transparent linear-gradient(var(--colordeg), var(--color1) 0%, var(--color2) 100%)
        0% 0% no-repeat padding-box;
    border-radius: 8px;
    transition: all 0.5s;
}

#progress-header {
    font-size: 11px;
    margin-left: 20px;
    color: #5e6066;
}

#progress-text {
    display: flex;
    justify-content: space-between;
    position: absolute;
    height: 32px;
    width: 100%;
}

.progress-text-content {
    vertical-align: middle;
    line-height: 32px;
    width: fit-content;
    color: white;
    margin: 0 20px;
    font-size: 14px;
    z-index: 3;
}

.progress-text-content.right {
    mix-blend-mode: difference;
}
</style>
