import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {}
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            dense: true
        }
    };
};
