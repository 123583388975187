var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"value":_vm.dialogProxy,"closable":"","persistent":""},on:{"update:value":function($event){_vm.dialogProxy=$event}}},'EqsDialog',{
        loading: _vm.loading || _vm.localLoading,
        cancel: {
            fn: () => _vm.$router.push({ name: 'projects', params: _vm.$route.params })
        },
        confirm: {
            fn: () =>
                _vm.$router.push({
                    name: 'risk-aversion',
                    params: _vm.$route.params,
                    query: _vm.$route.query
                }),
            txt: _vm.$t('risk-aversion.dialog.confirm')
        },
        title: _vm.$t('risk-aversion.dialog.title')
    },false),[_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', 'dialog')}},[_c('EqsTextDisplay',_vm._b({attrs:{"paragraph":""}},'EqsTextDisplay',{ value: _vm.$t('risk-aversion.dialog.body') },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', 'dialog')}},[_c('EqsSwitch',_vm._b({on:{"change":event => _vm.updateUserPref(event)}},'EqsSwitch',{
                        value: _vm.$entity.represented().cip_advice,
                        text: _vm.$t('risk-aversion.dialog.switch'),
                        loading: _vm.loading || _vm.localLoading
                    },false))],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }