import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, data) => {
    DEBUG("INVITE_INVESTORS_START");
    try {
        commit("SET_INVESTORS_LOADING", true);
        const investorRes = await window.app.$api.inviteMultipleInvestors(data);
        DEBUG("INVITE_INVESTORS_SUCCESS");
        commit("INVITED_INVESTORS", investorRes.data);
        return investorRes.data;
    } catch (e) {
        DEBUG("INVITE_INVESTORS_ERROR");
        throw e;
    } finally {
        commit("SET_INVESTORS_LOADING", false);
    }
};
