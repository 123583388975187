var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{staticClass:"d-flex fill-width",attrs:{"name":"eqs-slide-from-right"}},[(_vm.isRoot)?_c('div',{staticClass:"d-flex flex-column"},[_c('EqsTabs',_vm._b({attrs:{"value":_vm.tab},on:{"update:value":function($event){_vm.tab=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c(item.is,_vm._b({tag:"component",attrs:{"selected":_vm.selected},on:{"update:selected":function($event){_vm.selected=$event},"open-tx-info":_vm.openTxInfo}},'component',{
                        loading: _vm.loading,
                        ...item
                    },false))]}}],null,false,3485014635)},'EqsTabs',{ tabs: _vm.tabs },false)),_c('GlobalExplorerDialog',_vm._b({attrs:{"value":_vm.qrDialog},on:{"update:value":function($event){_vm.qrDialog=$event}}},'GlobalExplorerDialog',{ link: _vm.qrcodeLink, tx: _vm.qrTx },false))],1):_c('router-view',_vm._b({on:{"close":() =>
                _vm.$router.push({
                    name: 'history',
                    params: { id: _vm.$entity.represented().id }
                })}},'router-view',{ loading: _vm.loading },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }