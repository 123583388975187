// import displayError from "@/store/displayError";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, obj) => {
    commit("CONFIRM_FORGOT_PASSWORD_START");
    try {
        await window.app.$api.forgotPasswordSubmit(obj);
        commit("CONFIRM_FORGOT_PASSWORD_SUCCESS");
    } catch (error) {
        DEBUG("CONFIRM_FORGOT_PASSWORD_ERROR");
        if (error && error.data) window.app.$ui.error(error.data);
        throw error;
    }
};
