import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ state, dispatch }, organId) => {
    DEBUG("GET_ORGAN_START");
    if (!organId) throw new Error("no organId");
    let organ = state.organs.find(_organ => _organ.id === organId);
    if (!organ) {
        try {
            await dispatch("getOrgans");
            organ = state.organs.find(_organ => _organ.id === organId);
        } catch (e) {
            throw e;
        }
    }
    if (organ) DEBUG("GET_ORGAN_SUCCESS");
    else {
        DEBUG("GET_ORGAN_ERROR");
        throw new Error("organ not found");
    }

    return organ || {};
};
