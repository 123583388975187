<template>
    <v-container fluid class="container-project">
        <v-skeleton-loader
            transition="scale-transition"
            :loading="localLoading"
            type="image"
            class="limit-width"
        >
            <v-container fluid class="pa-0 ma-0">
                <v-row align="start" justify="space-between">
                    <v-col cols="12" md="8">
                        <BannerRefont v-bind="{ capitalIncrease, loading: localLoading, items }" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <TotalLeverageCard v-bind="{ capitalIncrease, loading: localLoading }" />
                    </v-col>
                </v-row>
            </v-container>
        </v-skeleton-loader>
        <v-skeleton-loader :loading="localLoading" type="image" class="limit-width mt-4">
            <v-tabs
                ref="tab-detail"
                v-model="tab"
                centered
                show-arrows
                grow
                class="card-style border-radius-only-top elevation-1"
                @change="scrollToTab"
            >
                <v-tabs-slider color="primary" />

                <v-tab
                    v-for="item in items"
                    :key="item.title"
                    class="text-capitalize font-weight-bold"
                    color="primary"
                >
                    {{ item.title }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="card-style border-radius-only-bottom elevation-1">
                <v-tab-item v-for="item in items" :key="item.title">
                    <component
                        :is="item.component"
                        v-if="item.show"
                        v-bind="{ ...item.binds, loading: localLoading }"
                    />
                    <p v-else paragraph v-bind="{ eqsType: 'default' }" class="pa-4">
                        {{ item.noShowTxt }}
                    </p>
                </v-tab-item>
            </v-tabs-items>
        </v-skeleton-loader>
        <v-skeleton-loader :loading="localLoading" class="limit-width" type="image">
            <div />
        </v-skeleton-loader>
        <v-skeleton-loader :loading="localLoading" class="limit-width" type="image">
            <div />
        </v-skeleton-loader>
        <CipWarning
            v-if="capitalIncrease?.cip"
            :is-cip="capitalIncrease?.cip"
            class="limit-width card-style mt-4"
        />
    </v-container>
</template>

<script>
import GlobalTextEditorDisplay from "@/components/global/globalTextEditorDisplay/GlobalTextEditorDisplay.vue";
import capitalize from "lodash/capitalize";
import { mapActions } from "vuex";

import Documents from "./Documents";
import Members from "./Members";
import Reporting from "./reporting/Reporting";
import CipWarning from "./CipWarning";
import BannerRefont from "./refont/BannerRefont.vue";
import TotalLeverageCard from "./refont/TotalLeverageCard.vue";
import InvestmentTermRefont from "./refont/InvestmentTermRefont.vue";
import PresentationRefont from "./refont/PresentationRefont.vue";

export default {
    name: "ProjectDetailRefont",
    components: {
        Documents,
        Members,
        GlobalTextEditorDisplay,
        BannerRefont,
        TotalLeverageCard,
        InvestmentTermRefont,
        CipWarning,
        Reporting
    },
    props: {
        projectId: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        close: {
            type: Function,
            required: false,
            default: null
        },
        previewMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            capitalize,
            partner: [],
            team: [],
            advisor: [],
            docs: {},
            failedChecks: [],
            userNotLoggedIn: false,
            restricted: true,
            localLoading: true,
            capitalIncrease: {},
            entityOrders: [],
            value: "",
            categories: [
                {
                    text: this.$t("capital-increase.admin.offering.legal-name"),
                    value: "legal"
                },
                {
                    text: this.$t("capital-increase.admin.offering.finance"),
                    value: "finance"
                },
                {
                    text: this.$t("capital-increase.admin.offering.tech"),
                    value: "tech"
                },
                {
                    text: this.$t("capital-increase.admin.offering.marketing"),
                    value: "marketing"
                },
                {
                    text: this.$t("label.other"),
                    value: "other"
                }
            ],
            tab: 0,
            items: [
                {
                    title: this.$t("projects.detail.tab_presentation"),
                    component: null,
                    binds: {},
                    show: false,
                    noShowTxt: ""
                },
                {
                    title: this.$t("projects.detail.tab_dataroom"),
                    component: null,
                    binds: {},
                    show: false,
                    noShowTxt: ""
                },
                {
                    title: this.$t("projects.detail.tab_team"),
                    component: null,
                    binds: {},
                    show: false,
                    noShowTxt: ""
                },
                {
                    title: this.$t("projects.detail.tab_reporting"),
                    component: null,
                    binds: {},
                    show: false,
                    noShowTxt: ""
                }
            ]
        };
    },
    computed: {
        crowdfunding() {
            return this.$route.name === "corner-project-detail";
        }
    },
    watch: {
        projectId: {
            immediate: true,
            async handler(val) {
                if (!val) return;
                try {
                    this.localLoading = true;
                    this.tabsReady = false;
                    this.capitalIncrease = await this.getCapitalIncrease(val);
                    this.getExtraInfos(this.capitalIncrease);
                    this.entityOrders = await this.fetchOrders();
                    await this.updateTabs();
                    this.tabsReady = true;
                    this.localLoading = false;
                } catch (e) {
                    console.error({ e });
                    this.$router.push({
                        name: "projects",
                        params: { id: this.$entity.represented().id }
                    });
                }
            }
        }
    },
    methods: {
        ...mapActions(["getProject", "fetchMyProject"]),
        updateTabs() {
            if (this.capitalIncrease) {
                if (this.capitalIncrease.notion_description) {
                    this.value = { type: "iframe", value: this.capitalIncrease.notion_description };
                } else if (this.capitalIncrease.long_description) {
                    this.value = { type: "tiptap", value: this.capitalIncrease.long_description };
                } else {
                    this.value = { type: "string", value: this.$t("projects.detail.no_data") };
                }
            }
            this.items[0].component = PresentationRefont;
            this.items[0].binds = {
                orders: this.entityOrders,
                capitalIncrease: this.capitalIncrease,
                restricted: this.restricted,
                userNotLoggedIn: this.userNotLoggedIn,
                failedChecks: this.failedChecks,
                value: this.value
            };
            this.items[0].show = true;

            this.items[1].component = Documents;
            this.items[1].binds = {
                docs: this.docs,
                categories: this.categories
            };
            this.items[1].noShowTxt = this.$t("projects.detail.no_data");
            this.items[1].show = this.docs?.categories?.length > 0;

            this.items[2].component = Members;
            this.items[2].binds = {
                team: this.team,
                advisor: this.advisor,
                partner: this.partner
            };
            this.items[2].noShowTxt = this.$t("projects.detail.no_data");
            this.items[2].show = this.team.length || this.advisor.length || this.partner.length;

            this.items[3].component = Reporting;
            this.items[3].binds = {
                capincId: this.capitalIncrease.id
            };
            this.items[3].noShowTxt = this.$t("projects.detail.no_data");
            this.items[3].show = true;
        },
        async fetchOrders() {
            try {
                this.loadingOrders = true;
                const res = await this.$api.getOrders({
                    buyer_id: this.$entity.represented().id,
                    capinc_id: this.projectId,
                    page: 1,
                    page_size: 1000,
                    sort_desc: true,
                    sort_by: "created_at"
                });
                const orders = res.data.results;
                return orders;
            } catch (e) {
                this.$ui.error(e, "my_transactions_order_transactions");
                throw e;
            }
        },
        async getCapitalIncreaseLimit(id) {
            let limits = null;
            try {
                const res = await this.$api.getInvestorLimits(id);
                limits = res.data;
            } catch (e) {
                this.$ui.error(e, "capital_increase_user_get_limits");
                throw e;
            }
            return limits;
        },
        async getCapitalIncrease(id) {
            let capinc = null;
            try {
                if (this.previewMode) {
                    capinc = await this.fetchMyProject({
                        projectId: id,
                        entityId: this.$entity.represented().id
                    });
                } else {
                    capinc = await this.getProject({ projectId: id, isCip: this.crowdfunding });
                }
                capinc.company = capinc.shareledger.company;
            } catch (e) {
                this.$ui.error(e, "capital_increase_user_get");
                throw e;
            }
            return capinc;
        },
        getExtraInfos(capitalIncrease) {
            this.partner = capitalIncrease.member_set.filter(
                member => member.category === "partner"
            );
            this.team = capitalIncrease.member_set.filter(member => member.category === "team");
            this.advisor = capitalIncrease.member_set.filter(
                member => member.category === "advisor"
            );
            this.docs = this.extractDocs(capitalIncrease);
        },
        extractDocs(capinc) {
            const out_docs = {
                categories: [],
                legal: []
            };
            const baseDocs = ["doc_dirs", "doc_kbis", "doc_pv", "doc_status"];
            baseDocs.forEach(e => {
                if (capinc[e]) {
                    if (!out_docs.categories.includes("legal")) {
                        out_docs.categories.push("legal");
                        out_docs["legal"] = [];
                    }
                    out_docs.legal.push(capinc[e]);
                }
            });
            capinc.document_set.forEach(doc => {
                if (!out_docs.categories.includes(doc.category)) {
                    out_docs.categories.push(doc.category);
                    out_docs[doc.category] = [];
                }
                out_docs[doc.category].push(doc.document);
            });
            return out_docs;
        },
        scrollToTab() {
            this.$nextTick(() =>
                this.$refs["tab-detail"].$el.scrollIntoView({ behavior: "smooth" })
            );
        }
    }
};
</script>

<style lang="scss">
.limit-width {
    margin: 0 auto;
    padding: 0;
    max-width: 1200px !important;
    min-width: 280px;

    @media only screen and (max-width: 960px) {
        padding: 0;
        margin: 0;
        width: 100%;
    }
}

.wrapper-header-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    @media only screen and (max-width: 1150px) {
        flex-direction: column;
    }
}

.no-elevation {
    --card-box-shadow: 0 !important;
    --card-border-radius: 0 !important;
}

.white-text {
    font-weight: 600;
    color: rgb(255, 255, 255);
}

.white-title {
    font-weight: Bold;
    color: rgb(255, 255, 255);
}

.card-style {
    background: white;
    border-radius: 6px;
}

.container-project {
    min-height: 100vh;
    padding: 0;

    @media only screen and (max-width: 960px) {
        padding: 0 !important;
        margin: 0 !important;
        width: 100% !important;
    }
}

.border-left {
    border-left: solid #bfbfbf 1px;
}

@media only screen and (max-width: 800px) {
    .border-left {
        border-left: none;
    }
}

.border-radius-only-bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.border-radius-only-top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.eqs-card-project-details {
    background: white;
    border-radius: 6px;
    padding: 32px 52px;

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
    }

    hr {
        margin-top: 22px;
        margin-bottom: 22px;
    }

    h2 {
        font-family: "Montserrat-Medium";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #17375f;
    }

    h3 {
        font-family: "Montserrat-Bold";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        color: #17375f;
    }

    .order-number-wrapper {
        p {
            font-family: "Montserrat-Bold";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 30px;
            color: #17375f;
        }
    }

    @media only screen and (max-width: 800px) {
        padding: 32px 20px;

        h1 {
            font-size: 21px;
        }
    }
}
</style>
