var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',_vm._l((_vm.summaryInfos),function(item,index){return _c('v-col',{key:index,staticClass:"pt-0",attrs:{"sm":"6","cols":"12"}},[_c('EqsCard',_vm._b({staticClass:"summary-card",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":item.title,"loading":_vm.loading}})]},proxy:true}],null,true)},'EqsCard',{
                title: item.title,
                loading: _vm.loading || _vm.localLoading,
                height: '100%'
            },false),[(item.items)?_c('v-flex',{attrs:{"layout":"","column":"","align-center":"","justify-space-around":""}},_vm._l((item.items),function(info,index2){return _c('div',{key:index2},[(info.section)?_c('EqsTextDisplay',_vm._b({staticClass:"section",attrs:{"pt-2":"","eqs-type":"body"}},'EqsTextDisplay',{ value: info.section },false)):_vm._e(),(!!info.total && info.total.length > 0)?_c('v-flex',{staticClass:"column align-center",attrs:{"layout":""}},[(_vm.displayInfo(info, 'EUR'))?_c('EqsTextDisplay',_vm._b({staticClass:"subsection text-xs-center my-3",attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{
                                value:
                                    'EUR: ' +
                                    _vm.$currency.displayWithCurrency(info.money_eur, 'EUR')
                            },false)):_vm._e(),(_vm.displayInfo(info, 'USD'))?_c('EqsTextDisplay',_vm._b({staticClass:"subsection text-xs-center my-3",attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{
                                value:
                                    'USD: ' +
                                    _vm.$currency.displayWithCurrency(info.money_usd, 'USD')
                            },false)):_vm._e()],1):(!!info.total && !info.total.length)?_c('EqsTextDisplay',_vm._b({staticClass:"subsection text-xs-center my-3"},'EqsTextDisplay',{ value: _vm.$t('portfolio.summary.no-data-to-display') },false)):(info.money == 0)?_c('EqsTextDisplay',_vm._b({staticClass:"money text-xs-center mb-2",attrs:{"pt-2":"","pb-3":"","eqs-type":"title"}},'EqsTextDisplay',{ value: '- €' },false)):(info.percentage)?_c('EqsTextDisplay',_vm._b({staticClass:"money text-xs-center mb-2",attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{
                            value: 'Total: ' + info.percentage.toFixed(2) + ' %'
                        },false)):(info.percentage == 0)?_c('EqsTextDisplay',_vm._b({staticClass:"money text-xs-center mb-2",attrs:{"pt-2":"","pb-3":"","eqs-type":"title"}},'EqsTextDisplay',{ value: '- %' },false)):_vm._e(),(info.txt)?_c('EqsTextDisplay',_vm._b({staticClass:"text-xs-center mb-5"},'EqsTextDisplay',{ value: info.txt },false)):_vm._e(),(info.items)?_c('v-flex',{class:[
                            info.row
                                ? 'layout row align-center justify-start'
                                : 'layout column align-center'
                        ],attrs:{"layout":""}},_vm._l((info.items),function(item2,index3){return _c('v-flex',{key:index3,class:item2.row
                                    ? 'layout row align-center justify-space-betwen'
                                    : 'layout column align-start'},[(item2.subsection)?_c('EqsTextDisplay',_vm._b({staticClass:"subsection mx-2 mb-2",attrs:{"layout":"","no-wrap":"","eqs-type":"subtitle"}},'EqsTextDisplay',{ value: item2.subsection },false)):_vm._e(),(item2.percent)?_c('EqsTextDisplay',_vm._b({staticClass:"percent mx-3",attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{ value: item2.percent },false)):_vm._e()],1)}),1):_vm._e()],1)}),0):_vm._e(),(item.chart)?_c('v-flex',[(item.chart.config)?_c('EqsPie',{staticStyle:{"height":"inherit","width":"100%"},attrs:{"config":item.chart.config}}):_vm._e()],1):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }