var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column fill-width"},[_c('v-row',{staticClass:"px-3",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"cols":4}},[_c('EqsAvatar',{attrs:{"user":_vm.txDetails.sender}}),_c('EqsTextDisplay',_vm._b({staticClass:"pt-2",attrs:{"el-class":"tx-details-sender"}},'EqsTextDisplay',{
                    value: _vm.$entity.entityName(_vm.txDetails.sender)
                },false))],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":4}},[_c('img',{staticStyle:{"height":"6rem"},attrs:{"src":"/images/kite-icon.svg"}})]),_c('v-col',{staticClass:"d-flex flex-column justify-center align-center",attrs:{"cols":4}},[_c('EqsAvatar',{attrs:{"user":_vm.txDetails.receiver,"label":_vm.$entity.entityName(_vm.txDetails.receiver)}}),_c('EqsTextDisplay',_vm._b({staticClass:"pt-2",attrs:{"el-class":"tx-details-receiver"}},'EqsTextDisplay',{
                    value: _vm.$entity.entityName(_vm.txDetails.receiver)
                },false))],1)],1),_c('v-row',{staticClass:"px-3",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":4}},[_c('EqsChip',{attrs:{"color":_vm.$color.colorFromHash(_vm.txDetails.operation),"el-class":"tx-details-operation"}},[_vm._v(" "+_vm._s(_vm.movement_operations[_vm.txDetails.operation].txt)+" ")])],1)],1),_c('v-row',{staticClass:"px-3",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":4}},[_c('EqsText',_vm._b({attrs:{"el-class":"tx-details-ledger"}},'EqsText',{
                    editable: false,
                    label: _vm.$t('label.shareledger'),
                    value: `${_vm.txDetails.shareledger.name} (${
                        _vm.share_types[_vm.txDetails.shareledger.share_type].name
                    })`
                },false))],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":4}},[_c('EqsNumber',_vm._b({attrs:{"el-class":"tx-details-numberofshares"}},'EqsNumber',{
                    editable: false,
                    isCurrency: false,
                    isFloat: false,
                    label: _vm.$t('shareledger.numberofshares'),
                    value: _vm.txDetails.numberofshares
                },false))],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":4}},[_c('EqsNumber',_vm._b({attrs:{"el-class":"tx-details-pricepershare"}},'EqsNumber',{
                    editable: false,
                    label: _vm.$t('shareledger.price-per-share'),
                    value: _vm.txDetails.pricepershare
                },false))],1)],1),_c('v-row',{staticClass:"px-3",attrs:{"align":"center","justify":"center"}},[(_vm.txDetails.comment)?_c('v-col',{staticClass:"d-flex justify-start"},[_c('EqsTextArea',_vm._b({},'EqsTextArea',{
                    editable: false,
                    label: _vm.$t('payments.label.comment'),
                    value: _vm.txDetails.comment
                },false))],1):_vm._e()],1),_c('v-row',{staticClass:"px-3",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"auto"}},[(_vm.txChip)?_c('GlobalTransactionChip',{attrs:{"tx":_vm.tx,"deploy":false}}):_c('GlobalCopyBadge',{attrs:{"value":_vm.tx.txhash}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }