import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, procedureId) => {
    DEBUG("DELETE_PROCEDURE_START");
    try {
        commit("SET_PROCEDURES_LOADING", true);
        await window.app.$api.deleteProcedure(procedureId);
        commit("DELETE_PROCEDURE", procedureId);
        DEBUG("DELETE_PROCEDURE_SUCCESS");
    } catch (e) {
        DEBUG("DELETE_PROCEDURE_ERROR");
        window.app.$ui.error(e, "delete_procedure");
        throw e;
    } finally {
        commit("SET_PROCEDURES_LOADING", false);
    }
};
