<template>
    <v-container
        class="card-style elevation-1 ma-0 py-2 px-8"
        :class="{ arthaud: $universe.isArthaud() }"
    >
        <v-row class="ma-0 pa-0 mb-2 mt-4" align="start">
            <v-col cols="0" sm="3" class="pa-0 d-xs-none d-sm-block text-center">
                <img
                    class="profile-img-banner"
                    :src="capitalIncrease.logo ? capitalIncrease.logo.upload : ''"
                />
            </v-col>
            <v-col cols="12" sm="9" class="pl-6">
                <h1 class="title-banner">
                    {{ capitalIncrease.order_title }}
                </h1>
                <div v-if="$universe.isArthaud()" class="location-banner ma-0 pa-0">
                    <v-icon x-large>mdi-map-marker-outline</v-icon><span>France</span>
                </div>
                <p class="mt-8 description-banner">
                    {{ capitalIncrease.short_description }}
                </p>
            </v-col>
        </v-row>
        <v-row v-if="capitalIncrease.website_url" class="ma-0 pa-0">
            <v-col class="pa-4 ma-0">
                <a :href="capitalIncrease.website_url" target="_blank" class="website-link">{{
                    capitalIncrease.website_url
                }}</a>
            </v-col>
        </v-row>
        <v-row justify="space-between" align="end" class="ma-0 pa-0">
            <v-col cols="12" md="6" class="pa-0 pb-4">
                <div v-if="!$universe.isArthaud()" class="tags-block">
                    <span
                        v-for="tag in formattedCapincTags"
                        :key="tag"
                        class="tag-banner mr-2 mb-2"
                    >
                        {{ tag }}
                    </span>
                </div>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 pb-4 text-right">
                <v-chip class="mr-4 status-style" :color="capincStatus.color" label outlined>
                    <v-icon left>
                        {{ capincStatus.icon }}
                    </v-icon>
                    {{ capincStatus.text }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row v-if="!capitalIncrease" class="justify-center align-center">
            <EqsTextDisplay
                class="text-center"
                v-bind="{
                    eqsType: 'title',
                    value: $t('capital-increase.user.detail.access-capital-denied')
                }"
            />
        </v-row>
        <v-row class="ma-0 pa-0 mb-4">
            <v-col class="pa-0">
                <v-img
                    class="background-img-banner"
                    height="280"
                    :src="capitalIncrease.image ? capitalIncrease.image.upload : ''"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isCapincEnded } from "@/components/views/projects/utils";

export default {
    name: "BannerRefont",
    props: {
        capitalIncrease: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            tab: null,
            tags: []
        };
    },
    computed: {
        capincStatus() {
            let phase = isCapincEnded(this.capitalIncrease)
                ? "delivery"
                : this.capitalIncrease.phase;
            if (!this.$time.isPast(this.capitalIncrease.start_date)) {
                phase = "pending";
            }
            switch (phase) {
                case "fundraising":
                    return {
                        color: "green",
                        text: this.$t("capital-increase.user.detail.phase.fundraising"),
                        icon: "mdi-clock-time-four-outline"
                    };
                case "pending":
                    return {
                        color: "warning",
                        text: this.$t("capital-increase.user.detail.phase.upcomming"),
                        icon: "mdi-clock-time-eight-outline"
                    };
                case "delivery":
                    return {
                        color: "blue",
                        text: this.$t("capital-increase.user.detail.phase.delivery"),
                        icon: "mdi-check-circle-outline"
                    };
                default:
                    return {
                        color: "red",
                        text: this.$t("capital-increase.user.detail.phase.closed"),
                        icon: "mdi-close-circle-outline"
                    };
            }
        },
        formattedCapincTags() {
            if (!this.capitalIncrease.tags) {
                return [];
            }
            let tags = this.capitalIncrease.tags.map(v => "#" + v);
            return tags;
        },
        isPea() {
            const envelopes = this.capitalIncrease?.capincpaymentoptions?.envelopes;
            return envelopes && envelopes.includes("pea");
        },
        isPeapme() {
            const envelopes = this.capitalIncrease?.capincpaymentoptions?.envelopes;
            return envelopes && envelopes.includes("pea-pme");
        },
        getPeaText() {
            if (this.isPea) return "PEA";
            else if (this.isPeapme) return "PEA-PME";
            else return "";
        }
    },
    watch: {
        tab: {
            immediate: true,
            async handler(val) {
                this.$emit("increase", val);
            }
        }
    },
    beforeMount() {
        // tax arr
    }
};
</script>

<style lang="scss" scoped>
.arthaud {
    .title-banner {
        font-family: IvyPresto Display !important;
        font-size: 2.1rem !important;
    }

    .description-banner {
        font-family: IvyPresto Display !important;
        font-size: 1.2rem !important;
    }

    .location-banner {
        font-family: IvyPresto Display !important;
        font-size: 1.2rem !important;
        text-transform: uppercase;
    }
}

.invest-btn {
    font-size: 1.8rem !important;
    font-weight: bolder;
    text-transform: none !important;
    color: white !important;
    background-color: var(--v-primary-base) !important;
}

.status-style {
    font-size: 1.15rem !important;
}

.tags-block {
    overflow-x: auto;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.background-img-banner {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 12px;
}

.profile-img-banner {
    width: 100%;
    border-radius: 12px;
    transition: all 0.66s ease;

    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.title-banner {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.66rem;
    letter-spacing: 0.03em;
    color: #212121;
}

.tag-banner {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #17375f;
}

.status-card {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    padding: 2px 8px;
    background: white;
    border-radius: 6px;
    font-size: 1.33rem;
    color: #000000 !important;

    align-items: center;

    > span {
        font-weight: bold;
    }

    .icon-el {
        margin-left: 0.5rem;
        width: 48px;
        height: 48px;
    }
}

.website-link {
    color: rgb(61, 81, 112);
    font-family: "Montserrat-Medium";
    text-decoration: none;
    font-weight: bolder;

    &:hover {
        text-decoration: underline;
    }
}
</style>
