<template>
    <v-timeline class="eqs-timeline" v-bind="{ ...$attrs, dense }">
        <v-timeline-item
            v-for="(item, i) in processedItems"
            :key="i"
            small
            v-bind="{ ...$attrs, ...item }"
        >
            <EqsTextDisplay v-if="item.txt" v-bind="{ loading, value: item.txt }" />
            <EqsTextDisplay
                v-if="item.message"
                :class="item.color ? `${item.color}--text` : ''"
                v-bind="{ loading, value: item.message, eqsType: 'caption' }"
            />
            <EqsBtn v-if="item.btn" v-bind="{ ...item.btn, color: 'primary' }">
                {{ item.btn.txt }}
            </EqsBtn>
            <slot :item="item.item" />
        </v-timeline-item>
    </v-timeline>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsTimeline.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsTimeline",
    components: {
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue"),
        EqsBtn: () => import("../../atoms/EqsBtn/EqsBtn.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-timeline"
        },
        value: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        processedItems() {
            const matchingIndex = !!this.value
                ? this.items.findIndex(item => item.id === this.value)
                : -1;
            if (matchingIndex === -1) return this.items;
            let error = false;
            return this.items.map((item, index) => {
                let style = {};
                let message = item.message;
                let btn = null;

                if (item.error) {
                    style = {
                        color: "error",
                        icon: "mdi-close"
                    };
                } else if (index <= matchingIndex) {
                    style = {
                        color: "success",
                        icon: "mdi-check"
                    };
                    if (item.doneMessage) message = item.doneMessage;
                    if (item.doneBtn) btn = item.doneBtn;
                } else if (index === matchingIndex + 1 && !error) {
                    style = {
                        color: "warning",
                        icon: "mdi-camera-timer"
                    };
                    btn = item.btn;
                } else {
                    style = { color: "grey" };
                    if (item.idleMessage) message = item.idleMessage;
                    if (item.idleBtn) btn = item.idleBtn;
                }
                error = !!item.error;
                return { ...item, ...style, message, btn };
            });
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsTimeline.scss";
</style>
