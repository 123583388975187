var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"fill-height px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.first_name},on:{"update:value":function($event){return _vm.$set(_vm.formData, "first_name", $event)}}},'EqsText',{
                    elClass: 'first_name',
                    label: this.$t('registration.first-name'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.first_name
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.last_name},on:{"update:value":function($event){return _vm.$set(_vm.formData, "last_name", $event)}}},'EqsText',{
                    elClass: 'last_name',
                    label: this.$t('registration.last-name'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.last_name
                },false))],1)],1),_c('v-row',{staticClass:"fill-height px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsEmail',_vm._b({attrs:{"value":_vm.formData.email},on:{"update:value":function($event){return _vm.$set(_vm.formData, "email", $event)}}},'EqsEmail',{
                    elClass: 'email',
                    isRequired: false,
                    label: _vm.$t('registration.email'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    rules: [_vm.uniqueEmailR],
                    ..._vm.schemaDouble.email
                },false))],1)],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsAutocomplete',_vm._b({attrs:{"value":_vm.formData.nationality},on:{"update:value":function($event){return _vm.$set(_vm.formData, "nationality", $event)}}},'EqsAutocomplete',{
                items: _vm.countries,
                placeholder: 'ex: France',
                itemText: 'name',
                itemValue: 'url',
                label: _vm.$t('registration.nationality'),
                'el-class': 'nationality',
                loading: _vm.loading || _vm.countriesLoading,
                ..._vm.schemaDouble.nationality
            },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }