import { i18n } from "@/services/plugins/i18n.js";

export const vote_types = {
    share_to_vote: {
        text: i18n.t("governance.vote_type.share_to_vote")
    },
    one_by_voter: {
        text: i18n.t("governance.vote_type.one_by_voter")
    },
    custom: {
        text: i18n.t("governance.vote_type.custom")
    }
};

export const decision_types = {
    download: {
        text: i18n.t("governance.decision_type.download")
    },
    vote: {
        text: i18n.t("governance.decision_type.vote")
    },
    signature: {
        text: i18n.t("governance.decision_type.signature")
    }
};

export const decisions_state = {
    setup: {
        text: i18n.t("governance.decision_state.setup"),
        eqsType: "warning"
    },
    published: {
        text: i18n.t("governance.decision_state.published"),
        eqsType: "info"
    },
    started: {
        text: i18n.t("governance.decision_state.started"),
        eqsType: "info"
    },
    finished: {
        text: i18n.t("governance.decision_state.finished"),
        eqsType: "success"
    },
    locking: {
        text: i18n.t("governance.decision_state.locking"),
        eqsType: "warning",
        icon: "mdi-anchor"
    },
    locked: {
        text: i18n.t("governance.decision_state.locked"),
        eqsType: "success",
        icon: "mdi-anchor"
    }
};

export const representative_roles = [
    {
        text: "Président",
        value: "Président"
    },
    {
        text: "Gérant",
        value: "Gérant"
    },
    {
        text: "Directeur Général",
        value: "Directeur Général"
    },
    {
        text: "Directeur Général Délégué",
        value: "Directeur Général Délégué"
    },
    {
        text: "Président Directeur Général (PDG)",
        value: "Président Directeur Général (PDG)"
    },
    {
        text: i18n.t("label.other"),
        value: "other"
    }
];
