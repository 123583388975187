var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"d-flex flex-column fill-height",staticStyle:{"width":"100%"}},'EqsCard',{ loading: _vm.loading, height: '100%' },false),[_c('EqsDataTable',_vm._b({attrs:{"items":_vm.capData,"group-by":"investor.type_display","footer-props":{
            itemsPerPageOptions: [30, 60]
        },"items-per-page":30},scopedSlots:_vm._u([{key:"group.header",fn:function({ group, toggle, isOpen, items }){return [_c('td',{staticClass:"cell"},[_c('EqsBtn',_vm._b({attrs:{"fab":""}},'EqsBtn',{
                        action: toggle
                    },false),[(isOpen)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)],1),_c('td',{staticClass:"cell"},[_vm._v(" "+_vm._s(group)+" ")]),_c('td',{staticClass:"cell"}),_c('td',{staticClass:"cell"},[_vm._v(" "+_vm._s(_vm.totalActions(items))+" ")]),_c('td',{staticClass:"cell"},[_vm._v(_vm._s(_vm.totalCapitalPerCentage(items))+" %")]),_c('td',{staticClass:"cell"})]}},{key:"item.percentage",fn:function({ item }){return [_vm._v(" "+_vm._s(item.percentage)+" % ")]}},{key:"item.price",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency(item.shareledger.lastpricepershare))+" ")]}}])},'EqsDataTable',{
            loading: _vm.localLoading,
            itemsPerPage: -1,
            headers: _vm.headers,
            searchable: {
                placeholder: _vm.$t('captable.search-placeholder')
            }
            // Possibility Add function to open dialog and select group-by
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }