var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({},'EqsCard',{
        width: '100%',
        height: '100%',
        eqsType: 'no-padding'
    },false),[_c('div',{staticClass:"fill-height d-flex flex-column overflow-y px-3"},[(_vm.getRegistrationText)?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":_vm.$reactive.isDesktop() ? 6 : 12}},[_c('EqsAlert',_vm._b({staticClass:"mb-0 pb-0"},'EqsAlert',{
                        eqsType: _vm.$entity.errorKyc() ? 'error' : 'warning',
                        text: _vm.getRegistrationText
                    },false))],1)],1):_vm._e(),(!_vm.$entity.isPsfp() && _vm.$universe.isEquisafe())?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":_vm.$reactive.isDesktop() ? 6 : 12}},[_c('EqsAlert',_vm._b({staticClass:"mb-0 pb-0"},'EqsAlert',{
                        eqsType: 'warning',
                        text: _vm.$t('user.profile.risk-aversion', {
                            id: _vm.entity.id
                        })
                    },false))],1)],1):_vm._e(),(!_vm.isProd() && _vm.$entity.isPsfp() && _vm.$universe.isEquisafe())?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":_vm.$reactive.isDesktop() ? 6 : 12}},[_c('EqsAlert',_vm._b({staticClass:"mb-0 pb-0"},'EqsAlert',{
                        eqsType: 'info',
                        text: _vm.$t('user.profile.risk-aversion-edit', {
                            id: _vm.entity.id
                        })
                    },false))],1)],1):_vm._e(),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":_vm.$reactive.isDesktop() ? 6 : 12}},_vm._l((_vm.infoViews),function(item,index){return _c(item.is,_vm._b({key:index,tag:"component",scopedSlots:_vm._u([{key:"content",fn:function(data){return [_c(item.form,_vm._b({tag:"component"},'component',{ ...data, entity: _vm.entity, isCgp: _vm.$entity.isAffiliationCgp() },false))]}}],null,true)},'component',{ entity: _vm.entity, loading: _vm.loading, ...item },false))}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }