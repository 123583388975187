<template>
    <div>
        <TwoFAForm
            ref="twofaform"
            v-bind="{ loading, validOtp }"
            @on-change="() => (validOtp = false)"
            @on-complete="handleOnComplete"
            @on-complete.native.prevent="handleOnComplete"
        />
        <v-row class="my-5" no-gutters justify="space-around">
            <EqsBtn
                id="skip2fa"
                el-class="skip2fa"
                color="error"
                v-bind="{ action: skip2FA, disabled: loading }"
                @update:loading="val => (loading = val)"
            >
                {{ $t("twofa.skip-btn") }}
            </EqsBtn>
            <EqsBtn
                id="validate2fa"
                color="primary"
                v-bind="{ action: validate2FA, disabled: loading }"
                @update:loading="val => (loading = val)"
            >
                {{ $t("btn.validate") }}
            </EqsBtn>
        </v-row>
    </div>
</template>

<script>
import TwoFAForm from "./TwoFAForm";

export default {
    name: "TwoFA",
    components: { TwoFAForm },
    data() {
        return {
            loading: false,
            validOtp: false,
            twofaToken: ""
        };
    },
    created() {
        this.$dialog.info({
            title: this.$t("twofa.authentification"),
            subtitle: this.$t("twofa.authentification-subtitle"),
            text: this.$t("twofa.msg", ["''", "''", "'\n\n'", "''"]),
            confirm: {
                txt: this.$t("btn.continue")
            }
        });
    },

    methods: {
        handleOnComplete(value) {
            this.validOtp = true;
            this.twofaToken = value;
        },
        handleClearInput() {
            this.validOtp = false;
            this.twofaToken = "";
            this.$refs.twofaform.resetOTPInput();
        },
        async validate2FA() {
            try {
                await this.$api.setTwoFA({
                    token: this.twofaToken
                });
                await this.$entity.me();
                this.$ui.success(this.$t("twofa.set-up.success"));
                this.$router.push(this.$kyc.getRoute(this.$entity.represented()));
            } catch (e) {
                this.handleClearInput();
                this.$ui.error(this.$t("twofa.set-up.error"));
                throw e;
            } finally {
                this.twofaToken = "";
            }
        },
        async skip2FA() {
            try {
                const res = await this.$api.setTwoFA({
                    skip_2fa: true
                });
                this.$ui.success(this.$t("twofa.set-up.skip"));
                await this.$entity.me();
                this.$router.push(this.$kyc.getRoute(this.$entity.represented()));
            } catch (e) {
                this.$ui.error(this.$t("twofa.set-up.skip-fail"));
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
