export default [
    "#f1ece6",
    "#e0edf9",
    "#efe8d7",
    "#e3f6e6",
    "#e7d5d0",
    "#baf9c1",
    "#fbebbf",
    "#e0d6e9",
    "#ecd3fd",
    "#d0bff7",
    "#ebc8ef",
    "#eed6f6",
    "#dff1d0",
    "#f1e6dc",
    "#e8f2e5",
    "#f6f5ec",
    "#e6faf3",
    "#e6d5d6",
    "#e1cbf4",
    "#e8f4f6",
    "#dcbbfd",
    "#f1cde4",
    "#f0e1e8",
    "#fdf3dc",
    "#e9d0e7",
    "#c2f0e4",
    "#ccd9f0",
    "#dbebe7",
    "#c7f7ca",
    "#f7fddb",
    "#f5f6e5",
    "#d8daf7",
    "#e9cbf3",
    "#f6e6df",
    "#c9e3ea",
    "#f0f3e1",
    "#beecf8",
    "#ecdfd6",
    "#edf5f2",
    "#f7f5d2",
    "#fbd0da",
    "#feceeb",
    "#d8e7ee",
    "#deddfe",
    "#f6c8fc",
    "#eaf3f5",
    "#daf1e0",
    "#f6cec4",
    "#e2ccee",
    "#f0f6e6",
    "#e1faf7",
    "#f1e4fa",
    "#f4d2e1",
    "#d7f6eb",
    "#f6d6bd",
    "#e2f9e7",
    "#eac5f4",
    "#c8f9eb",
    "#e1ebf8",
    "#c7ecd3",
    "#dddaf0",
    "#e9d5df",
    "#f3e1ec",
    "#fcf5d6",
    "#c6c3f7",
    "#e8f4f5",
    "#f7fada",
    "#e3f1d0",
    "#f8e7e4",
    "#d8c6fb",
    "#e6e5f5",
    "#dcf5f6",
    "#e4e9d0",
    "#edfce7",
    "#dbe7cc",
    "#c5eed3",
    "#f5e7f0",
    "#f8e6bf",
    "#f4cbf1",
    "#f8dee3",
    "#f8d8eb",
    "#f5f2da",
    "#fdc6ec",
    "#eedce3",
    "#fadce4",
    "#d5f8e9",
    "#cbf6cf",
    "#f9ede2",
    "#fdf4e6",
    "#f8dede",
    "#efd0db",
    "#d3f5f1",
    "#e4f9ee",
    "#cef3e9",
    "#f0f7ea",
    "#e4f3eb",
    "#e7f3cd",
    "#dedefc",
    "#cffbc2",
    "#f3ebf1"
];
