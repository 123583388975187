import store from "@/store/store";

import { companyNotSetted } from "@/services/entity";

export const notRegisteredIsAllowedRoutes = ["/boutique"];

export const noAuthRoutes = [
    "/auth",
    "/auth/register",
    "/auth/register-email",
    "/auth/confirm",
    "/auth/forgot",
    "/auth/2fa",
    ...notRegisteredIsAllowedRoutes
];

export const noAutoLogin = [
    "/auth/register",
    "/auth/register-email",
    "/auth/register/confirm",
    ...notRegisteredIsAllowedRoutes
];

export function guardUniverseRestrictedRoutes(vm, entityRepresented, to) {
    if (
        vm.$universe &&
        vm.$universe.restrictedRoutes() &&
        vm.$universe.restrictedRoutes().includes(to.name)
    ) {
        return vm.$universe.getDefaultRoute(entityRepresented);
    }
    return null;
}

export async function checkRegistration(vm, entityRepresented, myEntity, to) {
    let route = null;
    try {
        // enforce user to be at least on state upload
        if (!["upload", "upload-failure", "pending", "success"].includes(myEntity.lemonway_state)) {
            // if role is not my entity, change role to complete registration
            if (myEntity.id !== entityRepresented.id)
                await vm.$role.updateMyCurrentRole(myEntity.id);
            route = `/registration/${myEntity.id}`;
            route += myEntity.lemonway_state === "pending" ? "/verify" : "";
        }
        if (!route || to.fullPath === route) {
            return null;
        }
        return route;
    } catch (e) {
        throw e;
    }
}

export function checkCompanyType(to, needSetup) {
    if (["/create-company", "/setup-company"].some(route => to.fullPath.includes(route)))
        return null;
    return needSetup ? "/setup-company" : "/create-company";
}

export function isNoRegisterRoute(entityRepresented, to) {
    let noRegistrationRoutes = [...noAuthRoutes, "/auth/logout"];
    if (entityRepresented && entityRepresented.is_company)
        noRegistrationRoutes.push(`/profile/${entityRepresented.id}`);

    const res = noRegistrationRoutes.some(route => to.fullPath.includes(route));
    return res;
}

export function guardRegistration(myEntity, entityRepresented, to) {
    if (isNoRegisterRoute(entityRepresented, to)) return null;
    if (!entityRepresented || companyNotSetted(entityRepresented))
        return checkCompanyType(to, companyNotSetted(entityRepresented));
    if (
        // (entityRepresented.is_company &&
        //   entityRepresented.lemonway_state !== "success") ||
        myEntity.lemonway_state !== "success"
    ) {
        return checkRegistration(window.app, entityRepresented, myEntity, to);
    }
}

export function guard2FA(vm, hasSetup2FA, myEntity, to) {
    if (
        !hasSetup2FA &&
        !["/auth/logout", "auth/2fa/setup"].some(route => to.fullPath.includes(route))
    ) {
        return "/auth/2fa/setup";
    } else if (hasSetup2FA && to.fullPath.includes("/auth/2fa/setup")) {
        return vm.$universe.getDefaultRoute(myEntity);
    }
    return null;
}

export function guardAuthRoutes(store, isAuthenticated, to) {
    const routeRequiresAuth = !noAuthRoutes.some(route => to.path.includes(route));
    if (isAuthenticated) return null;
    else if (routeRequiresAuth) {
        store.commit("MSG_ERROR", window.app.$t("error.account-disconnected"));
        return "/auth";
    }
    return null;
}

export async function autoAuthentication(store, isAuthenticated, to) {
    const avoidAutoLogin = noAutoLogin.some(route => to.path.includes(route));
    const avoidAutoLoginOnLoginCypress = window.Cypress && to.name === "auth";
    if (!isAuthenticated && !avoidAutoLogin && !avoidAutoLoginOnLoginCypress) {
        try {
            await store.dispatch("getSession");
            isAuthenticated = store.getters.isAuthenticated;
        } catch (e) {
            console.warn(`error trying to get session : `, e);
            throw e;
        }
    }
    return isAuthenticated;
}

let lastPath = null;

export const globalGuards = async (to, from, next) => {
    let myEntity;
    let entityRepresented;
    let hasSetup2FA;
    let { isAuthenticated, isNotAuthenticated, isNetworkFailure, lastRoute } = store.getters;

    store.commit("setLastRoute", to.fullPath);

    if (isNetworkFailure || isNotAuthenticated) {
        if (isNotAuthenticated) store.commit("SET_IS_NOT_AUTHENTICATED", false);
        next();
    } else {
        try {
            isAuthenticated = await autoAuthentication(store, isAuthenticated, to);
            // avoid infinite loop
            if (to.fullPath === lastRoute) next();

            if (store.getters.currentRole) {
                entityRepresented = store.getters.currentRole.entity_represented;
                myEntity = store.getters.myEntity;
                hasSetup2FA = myEntity.has_setup_2fa;
            }

            if (process.env.NODE_ENV === "development")
                console.log({
                    to,
                    from,
                    hasSetup2FA,
                    myEntity,
                    entityRepresented,
                    isAuthenticated
                });
            // TODO cleanup with loop into array of guards, stop when get redirection
            let redirection = guardAuthRoutes(store, isAuthenticated, to);
            if (isAuthenticated && !redirection) {
                redirection = guard2FA(window.app, hasSetup2FA, myEntity, to);
                if (!redirection)
                    redirection = await guardRegistration(myEntity, entityRepresented, to);
            }
            if (!redirection) {
                redirection = guardUniverseRestrictedRoutes(window.app, entityRepresented, to);
            }

            // Pour modifier ses infos avec un lien unique, on redirige avec l'id
            // CF feature Kyoseil cgp pour relancer la signature
            if (to.fullPath === "/registration/") {
                redirection = `/registration/${entityRepresented?.id}`;
            }
            if (redirection) {
                lastPath = redirection;
                return next({
                    path: redirection
                });
            }
        } catch (e) {
            if (!(e && e.message === "auto-auth-failed")) {
                window.app.$store.commit("SET_IS_NETWORK_FAILURE", true);
            }
        } finally {
            next();
        }
    }
};
