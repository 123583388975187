var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"ma-0 pa-0",attrs:{"closable":"","title":`${_vm.$t('capital-increase.admin.detail.capital-increase-title')} ${
        _vm.capitalIncrease ? _vm.capitalIncrease.order_title : ''
    }`,"el-class":_vm.capitalIncrease ? _vm.capitalIncrease.order_title : 'capinc-detail-empty'},on:{"close":function($event){return _vm.$emit('close')}}},'EqsCard',{ loading: _vm.loading || _vm.myProjectsLoading },false),[_c('v-row',{staticClass:"px-3"},[_c('v-col',[(_vm.capitalIncrease)?_c('div',{attrs:{"transition":"fade"}},[_c('ciCard',_vm._b({},'ciCard',{
                        capitalIncrease: _vm.capitalIncrease
                    },false)),_c('ciFundraising',{attrs:{"capital-increase":_vm.capitalIncrease,"send-shares":_vm.sendShares},on:{"update:capitalIncrease":function($event){_vm.capitalIncrease=$event},"update:capital-increase":function($event){_vm.capitalIncrease=$event},"update:sendShares":function($event){_vm.sendShares=$event},"update:send-shares":function($event){_vm.sendShares=$event}}})],1):_vm._e()])],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols(_vm.isRoot ? 'full' : 'half')}},[(_vm.capitalIncrease)?_c('div',{attrs:{"transition":"fade"}},[(_vm.capitalIncrease.public)?_c('ciSubscribers',_vm._b({},'ciSubscribers',{
                        capitalIncrease: _vm.capitalIncrease,
                        loading: _vm.loading
                    },false)):_vm._e()],1):_vm._e()]),_c('v-slide-x-transition',[(!_vm.isRoot)?_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('router-view',_vm._b({attrs:{"registration-infos":""},on:{"close":() =>
                            _vm.$router.push({
                                name: 'fundraising-details',
                                params: _vm.$router.params
                            })}},'router-view',{
                        loading: _vm.loading || _vm.myProjectsLoading
                    },false))],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }