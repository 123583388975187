<template>
    <div :class="`eqs-alert ${elClass}-container`" :style="getStyle">
        <v-alert
            :value.sync="valueProxy"
            v-bind="{
                ...$attrs,
                text: accent && !loading,
                outlined: loading,
                ...getData,
                disabled: loading,
                dismissible,
                border: 'left',
                color: loading ? $color.colors.lighterGrey : ''
            }"
            v-on="$listeners"
        >
            <EqsSkeleton v-bind="{ loading, type: 'paragraph', minWidth: '200px' }">
                <EqsTextDisplay v-if="text" paragraph v-bind="{ value: text }" />
                <slot v-else name="default" />
            </EqsSkeleton>
        </v-alert>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsAlert.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsAlert",
    components: {
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue"),
        EqsSkeleton: () => import("../../atoms/EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: ""
        },
        accent: {
            type: Boolean,
            default: true
        },
        value: {
            type: Boolean,
            default: true
        },
        text: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        },
        dismissible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsAlert.scss";
</style>
