<template>
    <div class="eqs-main-container margin-auto">
        <div class="d-flex flex-column fill-height">
            <v-row class="fill-height px-3">
                <v-col
                    :cols="$reactive.isDesktop() ? 6 : 12"
                    :order="$reactive.isDesktop() ? 1 : 2"
                >
                    <v-row class="px-3">
                        <v-col class="eqs-form-field pt-0 d-flex flex-column">
                            <EqsTextDisplay
                                v-bind="{
                                    loading
                                }"
                            >
                                <div class="font-weight-bold">
                                    {{ isOnlyInvestorsTxt(order.shareledger) }}
                                </div>
                            </EqsTextDisplay>
                            <EqsTextDisplay
                                class="pt-3"
                                paragraph
                                v-bind="{
                                    loading,
                                    value: $t('secondary-market.sell-shares.finalize.text-1')
                                }"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="px-3">
                        <v-col class="eqs-form-field">
                            <EqsSelect
                                :value.sync="order.shareledger.name"
                                :items="shareledgers"
                                item-text="shareledger.name"
                                return-object
                                disabled
                                readonly
                                :editable="false"
                                v-bind="{
                                    elClass: 'shareledger_name',
                                    label: $t('sellShares.label.shareledger'),
                                    loading
                                }"
                            />
                        </v-col>
                    </v-row>

                    <v-row class="px-3">
                        <v-col class="eqs-form-field">
                            <EqsNumberShares
                                :value="order.quantity"
                                :editable="false"
                                v-bind="{
                                    elClass: 'quantity',
                                    label: $t('sellShares.label.numberOfShares'),
                                    loading
                                }"
                            />
                        </v-col>
                    </v-row>

                    <v-row class="px-3">
                        <v-col class="eqs-form-field">
                            <EqsText
                                :value="share_types[order.shareledger.share_type].name"
                                :editable="false"
                                return-object
                                v-bind="{
                                    elClass: 'shares_type',
                                    label: $t('sellShares.label.shareType'),
                                    loading
                                }"
                            />
                        </v-col>
                    </v-row>

                    <v-row class="px-3">
                        <v-col class="eqs-form-field">
                            <EqsNumber
                                :value="order.price"
                                :is-currency="true"
                                :editable="false"
                                v-bind="{
                                    elClass: 'price',
                                    label: $t('sellShares.label.pricePerShare'),
                                    loading
                                }"
                            />
                        </v-col>
                    </v-row>

                    <v-row class="px-3">
                        <v-col class="eqs-form-field">
                            <EqsNumber
                                :value="totalSell"
                                :is-currency="true"
                                :editable="false"
                                v-bind="{
                                    elClass: 'total_sell',
                                    label: $t('sellShares.label.total-sell'),
                                    loading
                                }"
                            />
                        </v-col>
                    </v-row>

                    <v-row class="px-3">
                        <v-col class="eqs-form-field">
                            <EqsNumber
                                :value="totalFees"
                                :is-currency="true"
                                :editable="false"
                                v-bind="{
                                    elClass: 'total_fees',
                                    label: $t('sellShares.label.total-fees'),
                                    loading
                                }"
                            />
                        </v-col>
                    </v-row>

                    <v-row v-if="order.docs.length" class="px-3">
                        <v-col class="eqs-form-field">
                            <AdditionalDocsCrud
                                :docs="order.docs"
                                :editable="false"
                                v-bind="{
                                    label: $t(
                                        'secondary-market.sell-shares.finalize.documents-crud-label'
                                    ),
                                    loading,
                                    formData: order,
                                    schemaDouble: {}
                                }"
                            />
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="
                            signature_procedure.proceduredocument_set &&
                            signature_procedure.proceduredocument_set.length
                        "
                        class="px-3"
                    >
                        <v-col class="eqs-form-field">
                            <EqsFileInput
                                :value="
                                    signature_procedure.proceduredocument_set.map(
                                        doc => doc.document
                                    )
                                "
                                :editable="false"
                                multiple
                                v-bind="{
                                    label: $t(
                                        'secondary-market.sell-shares.finalize.docstosign-label'
                                    ),
                                    loading
                                }"
                            />
                        </v-col>
                    </v-row>
                </v-col>

                <v-col
                    :cols="$reactive.isDesktop() ? 6 : 12"
                    :order="$reactive.isDesktop() ? 2 : 1"
                >
                    <div class="d-flex justify-center align-center fill-height">
                        <img
                            :height="$reactive.getElementHeight()"
                            style="width: 100%"
                            src="/images/Vecteur_Vente.svg"
                        />
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import AdditionalDocsCrud from "./additionalDocsCrud/_AdditionalDocsCrud";
import { share_types } from "@/services/shareledger/shareType";
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "Finalize",
    components: {
        AdditionalDocsCrud
    },
    mixins: [formMixin],
    props: {
        order: {
            type: Object,
            required: true
        },
        signature_procedure: {
            type: Object,
            required: true
        },
        shareledgers: {
            type: Array,
            required: true
        },
        sellerFees: {
            type: Number,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            share_types
        };
    },
    computed: {
        totalSell() {
            return !!this.order.shareledger ? this.calculateTotalSell() : 0;
        },
        totalFees() {
            return !!this.order.shareledger ? this.calculateTotalSell() * this.sellerFees : 0;
        }
    },
    methods: {
        calculateTotalSell() {
            return this.order.quantity * this.order.price;
        },
        isOnlyInvestorsTxt(shareledger) {
            const { share_only_investor_transfer } = shareledger;
            return share_only_investor_transfer
                ? this.$t("secondary-market.sell-shares.finalize.text-1-only-investors")
                : this.$t("secondary-market.sell-shares.finalize.text-1-any-person");
        }
    }
};
</script>
