<template>
    <div class="fill-height">
        <v-row style="height: 100%">
            <v-col class="d-flex flex-column fill-height" :cols="getCols()">
                <v-row class="px-4">
                    <EqsTextDisplay eqs-type="title" :value="$t('validationOrder.title')" />
                </v-row>
                <v-row class="mb-8 px-4">
                    <EqsTextDisplay
                        :value="
                            $t('validationOrder.subtitle', {
                                ...order,
                                shareledger_type: share_types[order.shareledger_type].name
                            })
                        "
                        :loading="loading"
                        :color="gray"
                    />
                </v-row>

                <v-row class="mb-8 px-4">
                    <EqsTextDisplay :value="$t('validationOrder.text', order)" :loading="loading" />
                </v-row>

                <v-row class="px-4">
                    <v-col :cols="$reactive.getCols('full')">
                        <EqsTextDisplay
                            :label="$t('validationOrder.parameter')"
                            :editable="false"
                            :value="$t('validationOrder.parameter')"
                            v-bind="{
                                eqsType: 'subtitle'
                            }"
                        />
                    </v-col>
                </v-row>

                <v-row class="px-4">
                    <v-col :cols="$reactive.getCols('half')">
                        <EqsText
                            :label="$t('validationOrder.quantity')"
                            :editable="false"
                            :value="`${order.quantity} ${share_types[order.shareledger_type].name}`"
                            :loading="loading"
                        />
                    </v-col>

                    <v-col :cols="$reactive.getCols('half')">
                        <EqsText
                            :label="$t('validationOrder.percentage')"
                            :editable="false"
                            :value="''"
                            :loading="loading"
                        />
                    </v-col>
                </v-row>

                <v-row class="px-4">
                    <v-col :cols="$reactive.getCols('half')">
                        <EqsText
                            :label="$t('validationOrder.amount')"
                            :editable="false"
                            :value="$currency.display(order.amount)"
                            :loading="loading"
                        />
                    </v-col>
                    <v-col :cols="$reactive.getCols('half')">
                        <EqsText
                            :label="$t('validationOrder.price')"
                            :editable="false"
                            :value="$currency.display(order.price)"
                            :loading="loading"
                        />
                    </v-col>
                </v-row>

                <v-row class="px-4">
                    <v-col :cols="$reactive.getCols('full')">
                        <EqsTextDisplay
                            :label="$t('validationOrder.investors')"
                            :editable="false"
                            :value="$t('validationOrder.investors')"
                            v-bind="{
                                eqsType: 'subtitle'
                            }"
                        />
                    </v-col>
                </v-row>

                <InvestorWhitelistTable
                    v-bind="{
                        loading,
                        currentInvests: this.getInvestors(),
                        currentHeaders: investorsTableHeaders
                    }"
                    @open:investorDetails="investor => openInvestorDetails(investor)"
                    @close:investorDetails="() => closeInvestorDetails()"
                />

                <v-row class="px-4">
                    <v-col :cols="$reactive.getCols('full')">
                        <EqsTextDisplay
                            :label="$t('validationOrder.documents')"
                            :editable="false"
                            :value="$t('validationOrder.documents')"
                            v-bind="{
                                eqsType: 'subtitle'
                            }"
                        />
                    </v-col>
                </v-row>
                <v-row class="px-4">
                    <v-col :cols="$reactive.getCols('half')">
                        <EqsFileInput
                            :label="''"
                            :editable="false"
                            multiple
                            :value.sync="files"
                            :loading="loading"
                        />
                    </v-col>
                </v-row>

                <v-row class="px-4">
                    <v-col :cols="$reactive.getCols('third')">
                        <EqsBtn :action="accept" color="primary" :loading="loading">
                            {{ $t("validationOrder.validate") }}
                        </EqsBtn>
                    </v-col>

                    <v-col :cols="$reactive.getCols('third')">
                        <EqsBtn :action="refuse" :loading="loading">
                            {{ $t("validationOrder.refuse") }}
                        </EqsBtn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-if="displayInvestorDetails"
                class="d-flex flex-column fill-height"
                :cols="getCols()"
            >
                <InvestorDetailPanels
                    :investor="investorDetails"
                    @close="
                        () => {
                            closeInvestorDetails();
                        }
                    "
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import InvestorDetailPanels from "@/components/views/shareledger/investorWhitelist/InvestorDetailPanels";
import InvestorWhitelistTable from "@/components/views/shareledger/investorWhitelist/InvestorWhitelistTable";
import { share_types } from "@/services/shareledger/shareType";

export default {
    name: "ValidationOrder",
    components: {
        InvestorDetailPanels,
        InvestorWhitelistTable
    },
    props: {
        accept: {
            type: Function,
            required: true
        },
        refuse: {
            type: Function,
            required: true
        },
        order: {
            type: Object,
            required: true
        },
        files: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            share_types,
            displayInvestorDetails: false,
            investorDetails: null,
            investorsTableHeaders: [
                {
                    text: this.$t("shareledger.white-list.account_number"),
                    value: "account_number",
                    filterable: true,
                    align: "center"
                },
                {
                    text: this.$t("shareledger.white-list.user-name"),
                    value: "entity.name",
                    filterable: true,
                    align: "center"
                },
                {
                    text: "",
                    value: "markettype",
                    filterable: true,
                    align: "center"
                },
                {
                    text: "",
                    value: "kyc",
                    filterable: true,
                    align: "center"
                }
            ]
        };
    },
    methods: {
        getCols() {
            let cols = 12;
            if (this.displayInvestorDetails) return 6;
            return cols;
        },
        openInvestorDetails(investor) {
            if (!this.displayInvestorDetails) this.displayInvestorDetails = true;
            this.investorDetails = investor;
        },
        closeInvestorDetails() {
            this.displayInvestorDetails = false;
            this.investorDetails = null;
        },
        getInvestors() {
            let seller = { ...this.order.investor_seller };
            seller.markettype = "Vendeur";
            seller.url = "Vendeur";
            let buyer = { ...this.order.investor_buyer };
            buyer.markettype = "Acheteur";
            buyer.url = "Acheteur";
            return [seller, buyer];
        }
    }
};
</script>
