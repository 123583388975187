var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height"},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"d-flex flex-column fill-height",attrs:{"cols":_vm.getCols()}},[_c('v-row',{staticClass:"px-4"},[_c('EqsTextDisplay',{attrs:{"eqs-type":"title","value":_vm.$t('validationOrder.title')}})],1),_c('v-row',{staticClass:"mb-8 px-4"},[_c('EqsTextDisplay',{attrs:{"value":_vm.$t('validationOrder.subtitle', {
                            ..._vm.order,
                            shareledger_type: _vm.share_types[_vm.order.shareledger_type].name
                        }),"loading":_vm.loading,"color":_vm.gray}})],1),_c('v-row',{staticClass:"mb-8 px-4"},[_c('EqsTextDisplay',{attrs:{"value":_vm.$t('validationOrder.text', _vm.order),"loading":_vm.loading}})],1),_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextDisplay',_vm._b({attrs:{"label":_vm.$t('validationOrder.parameter'),"editable":false,"value":_vm.$t('validationOrder.parameter')}},'EqsTextDisplay',{
                            eqsType: 'subtitle'
                        },false))],1)],1),_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',{attrs:{"label":_vm.$t('validationOrder.quantity'),"editable":false,"value":`${_vm.order.quantity} ${_vm.share_types[_vm.order.shareledger_type].name}`,"loading":_vm.loading}})],1),_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',{attrs:{"label":_vm.$t('validationOrder.percentage'),"editable":false,"value":'',"loading":_vm.loading}})],1)],1),_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',{attrs:{"label":_vm.$t('validationOrder.amount'),"editable":false,"value":_vm.$currency.display(_vm.order.amount),"loading":_vm.loading}})],1),_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',{attrs:{"label":_vm.$t('validationOrder.price'),"editable":false,"value":_vm.$currency.display(_vm.order.price),"loading":_vm.loading}})],1)],1),_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextDisplay',_vm._b({attrs:{"label":_vm.$t('validationOrder.investors'),"editable":false,"value":_vm.$t('validationOrder.investors')}},'EqsTextDisplay',{
                            eqsType: 'subtitle'
                        },false))],1)],1),_c('InvestorWhitelistTable',_vm._b({on:{"open:investorDetails":investor => _vm.openInvestorDetails(investor),"close:investorDetails":() => _vm.closeInvestorDetails()}},'InvestorWhitelistTable',{
                    loading: _vm.loading,
                    currentInvests: this.getInvestors(),
                    currentHeaders: _vm.investorsTableHeaders
                },false)),_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextDisplay',_vm._b({attrs:{"label":_vm.$t('validationOrder.documents'),"editable":false,"value":_vm.$t('validationOrder.documents')}},'EqsTextDisplay',{
                            eqsType: 'subtitle'
                        },false))],1)],1),_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsFileInput',{attrs:{"label":'',"editable":false,"multiple":"","value":_vm.files,"loading":_vm.loading},on:{"update:value":function($event){_vm.files=$event}}})],1)],1),_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('third')}},[_c('EqsBtn',{attrs:{"action":_vm.accept,"color":"primary","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("validationOrder.validate"))+" ")])],1),_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('third')}},[_c('EqsBtn',{attrs:{"action":_vm.refuse,"loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("validationOrder.refuse"))+" ")])],1)],1)],1),(_vm.displayInvestorDetails)?_c('v-col',{staticClass:"d-flex flex-column fill-height",attrs:{"cols":_vm.getCols()}},[_c('InvestorDetailPanels',{attrs:{"investor":_vm.investorDetails},on:{"close":() => {
                        _vm.closeInvestorDetails();
                    }}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }