import DEBUG from "@/store/debug.js";

const state = {
    countriesLoading: true,
    countries: []
};

const mutations = {
    SET_COUNTRIES(state, countries) {
        state.countries = countries;
    },
    SET_COUNTRIES_LOADING(state, bool) {
        state.countriesLoading = bool;
    },
    RESET_STORE(state) {
        const s = {
            countriesLoading: true,
            countries: []
        };
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};

const actions = {
    async getCountries({ state, commit }) {
        DEBUG("GET_COUNTRIES_START");
        if (state.countries.length <= 0) {
            // console.log('STATE.COUNTRIES: ', state.countries);
            try {
                commit("SET_COUNTRIES_LOADING", true);
                const countriesRes = await window.app.$api.getCountries();
                DEBUG("GET_COUNTRIES_SUCCESS");
                commit("SET_COUNTRIES", countriesRes.data);
            } catch (e) {
                DEBUG("GET_COUNTRIES_ERROR");
                throw e;
            } finally {
                commit("SET_COUNTRIES_LOADING", false);
            }
        }
        return state.countries;
    }
};

const getters = {
    countriesLoading: state => state.countriesLoading,
    countries: state => state.countries
};

export default {
    state,
    mutations,
    getters,
    actions
};
