<template>
    <v-container fluid>
        <h2 class="text-h2 mb-8">Assemblées Générales</h2>
        <v-data-table
            :headers="headers"
            :items="agList"
            disable-filtering
            disable-pagination
            :hide-default-footer="true"
            item-key="name"
            :loading="agLoading"
            class="row-pointer"
            @click:row="handleRowClick"
        >
            <template #[`item.date`]="{ item }">
                {{ formatDate(item.date) }}
            </template>
            <template #[`item.organ`]="{ item }">
                {{ organText(item.organ.organ_type) }}
            </template>
            <template #[`item.decision`]="{ item }">
                {{ modeText(item.decision) }}
            </template>
            <template #[`item.state`]="{ item }">
                <v-chip :color="stateData[item.state][0]">{{ stateData[item.state][1] }}</v-chip>
            </template>
            <template #[`item.name`]="{ item }">
                {{ item.name }}
            </template>
            <template #[`item.action`]>
                <v-btn small color="primary">Voir details</v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import { getOrganText, getModeText } from "../helpers";

export default {
    name: "AgList",
    props: {
        id: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    emits: ["ag-selected"],
    data() {
        return {
            agLoading: true,
            agList: [],
            headers: [
                {
                    value: "date",
                    text: "Date",
                    sortable: true,
                    align: "left"
                },
                {
                    value: "organ",
                    text: "Organe",
                    sortable: true,
                    align: "left"
                },
                {
                    value: "decision",
                    text: "Mode de décision",
                    sortable: true,
                    align: "left"
                },
                {
                    value: "state",
                    text: "Status",
                    sortable: true,
                    align: "left"
                },
                {
                    value: "name",
                    text: "Nom",
                    sortable: true,
                    align: "left"
                },
                {
                    value: "action",
                    text: "Actions",
                    sortable: false,
                    align: "left"
                }
            ]
        };
    },
    computed: {
        stateData() {
            return {
                published: ["infoLight", "En cours"],
                finished: ["successLight", "Terminé"],
                locked: ["successLight", "Notarisé"],
                locking: ["successLight", "En cours de notarisation"]
            };
        }
    },
    beforeMount() {
        if (this.agList.length === 0) {
            this.getAgList();
        }
    },
    methods: {
        organText(organType) {
            return getOrganText(organType);
        },
        modeText(organMode) {
            return getModeText(organMode);
        },
        handleRowClick(item, vRow, e) {
            this.$emit("ag-selected", item);
        },
        formatDate(date) {
            const dateObject = new Date(date);
            return dateObject.toLocaleDateString();
        },
        async getAgList() {
            const filters = { state: ["published", "finished", "locking", "locked"] };
            const res = await this.$api.getAg(null, filters);
            this.agList = res.data;
            this.agLoading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.row-pointer) {
    tbody {
        tr {
            cursor: pointer;
        }
    }
}
</style>
