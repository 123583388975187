import DEBUG from "@/store/debug.js";

export default async ({ state, commit, dispatch }, decisionId) => {
    DEBUG("FETCH_DECISION_START");
    if (!decisionId) throw new Error("no decisionId");
    if (!state.decisions.length) {
        try {
            await dispatch("getDecisions");
        } catch (e) {
            throw e;
        }
    }
    try {
        const res = await window.app.$api.getDecision(decisionId);
        if (state.decisions.find(decision => decision.id === res.data.id))
            commit("UPDATE_DECISION", res.data);
        else commit("ADD_DECISION", res.data);
        DEBUG("FETCH_DECISION_SUCCESS");
    } catch (e) {
        DEBUG("FETCH_DECISION_ERROR");
        throw e;
    }
};
