// import Auth from '@/services/authProvider.js'
// import displayError from '@/store/displayError'
// import DEBUG from '@/store/debug.js'

export default async ({ commit }) => {
    // DEBUG('DELETE_ACCOUNT_START')
    // try {
    //   await Auth.deleteUser()
    //   DEBUG('DELETE_ACCOUNT_SUCCESS')
    // } catch (error) {
    //   DEBUG('DELETE_ACCOUNT_ERROR')
    //   displayError(error, true)
    // }
};
