import getProcedures from "./actions/get_procedures";
import getProcedure from "./actions/get_procedure";
import fetchProcedure from "./actions/fetch_procedure";
import fetchProcedureLocal from "./actions/fetch_procedure_local";
import deleteProcedure from "./actions/delete_procedure";

export default {
    getProcedures,
    getProcedure,
    fetchProcedure,
    fetchProcedureLocal,
    deleteProcedure
};
