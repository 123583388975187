var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-block-spacing"},[_c('EqsTextDisplay',_vm._b({staticClass:"main-title",attrs:{"eqs-type":"title"}},'EqsTextDisplay',{ value: _vm.$t('dashboard.dashboard-title') },false)),_c('UserStats',_vm._b({staticClass:"pa-0 ma-0"},'UserStats',{
            loading: _vm.localLoading,
            stats: { balance: _vm.balance, sequestre: _vm.sequestre, number_of_projects: _vm.projectsFunded.length },
            userId: _vm.id
        },false)),(_vm.projectsToCome?.length)?_c('EqsDataIterator',_vm._b({staticClass:"auto-height",attrs:{"el-class":"projects-list","hide-default-footer":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',{staticStyle:{"color":"grey !important"}},[_vm._v(_vm._s(_vm.$t("dashboard.projects-tocome")))])])]},proxy:true},{key:"item",fn:function({ item, index }){return [_c('OfferCard',_vm._b({on:{"open":() => _vm.openProject(item)}},'OfferCard',{
                    item,
                    height: _vm.offerCardHeight,
                    loading: _vm.localLoading,
                    elClass: `project-${index}`
                },false))]}}],null,false,2793698901)},'EqsDataIterator',{
            items: _vm.projectsToCome,
            loading: _vm.localLoading
        },false)):_vm._e(),(_vm.projectsCurrent?.length)?_c('EqsDataIterator',_vm._b({staticClass:"auto-height",attrs:{"el-class":"projects-list","hide-default-footer":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',{staticStyle:{"color":"grey !important"}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.projects-current"))+" ")])])]},proxy:true},{key:"item",fn:function({ item, index }){return [_c('OfferCard',_vm._b({on:{"open":() => _vm.openProject(item)}},'OfferCard',{
                    item,
                    height: _vm.offerCardHeight,
                    loading: _vm.localLoading,
                    elClass: `project-${index}`
                },false))]}}],null,false,571147395)},'EqsDataIterator',{
            items: _vm.projectsCurrent,
            loading: _vm.localLoading
        },false)):_c('h1',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.projects-none"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }