<template>
    <EqsCard
        class="fill-height"
        v-bind="{ height: '100%', loading: loading || localLoading }"
        v-on="$listeners"
    >
        <template #main>
            <EqsStepper
                :value.sync="step"
                v-bind="{
                    steps,
                    progress,
                    closeInfoTxt: $t('secondary-market.buy-stepper.dialog-signatory.cancel-btn')
                }"
                style="height: 100%"
                @close="comeBackToSecondaryMarket"
            >
                <template #default="{ item }">
                    <component
                        :is="item.is"
                        v-bind="{
                            formData,
                            buyer,
                            signer,
                            operationId,
                            stepperState,
                            documents,
                            order,
                            quantity,
                            total,
                            schemaDouble
                        }"
                        @error="
                            e => {
                                catchError();
                            }
                        "
                        @signatureRefused="signatureRefused"
                    />
                </template>
            </EqsStepper>
        </template>
    </EqsCard>
</template>

<script>
import BuyerInfo from "./BuyerInfo.vue";
import FinalStep from "./FinalStep.vue";

import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";
import { procedureSignerMixin } from "@/mixins/yousignMixin";

export default {
    name: "SignStepper",
    components: {
        BuyerInfo,
        FinalStep
    },
    mixins: [stepperFormMixins, procedureSignerMixin],

    props: {
        orderId: {
            type: String,
            default: ""
        },
        documents: {
            type: Array,
            default: null
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            buyer: {},
            signatureId: "",
            operationId: "",
            step: -1,
            quantity: 0,
            steps: [],
            schemaDouble: {},
            formData: {},
            order: {},
            stepperState: "success",
            localLoading: false
        };
    },
    computed: {
        total() {
            return this.quantity * this.order.price;
        }
    },
    watch: {
        step: {
            immediate: true,
            handler(val) {
                if (val < 0) return;
                let step = this.steps[val];
                if (step) {
                    this.schemaDouble = step.schemaDouble;
                    if (this.schemaDouble) {
                        this.buildFormData(this.schemaDouble, this.oldFormData);
                    }
                }
            }
        }
    },
    async created() {
        this.checkEntityCanSign({
            fn: this.comeBackToSecondaryMarket,
            txt: this.$t("secondary-market.buy-stepper.dialog-signatory.cancel-btn")
        });
        this.initSteps();
        this.localLoading = true;
        try {
            this.localLoading = true;
            await this.fetchData();
            this.step = 0;
            this.steps = this.getSteps();
        } catch (e) {
            console.error({ e });
            this.localLoading = false;
            this.catchError();
        } finally {
            this.localLoading = false;
        }
    },
    methods: {
        async fetchData() {
            try {
                const res = await this.$api.readRelatedSecondaryMarketBuyOrder(this.orderId);
                this.buyer = res.data.buyer;
                this.signatureId = res.data.signature_procedure.id;
                this.operationId = res.data.operation.id;
                this.quantity = res.data.quantity;
                this.order = res.data.sellOrder;
            } catch (e) {
                // console.error(e, "sign_stepper_get_order");
                console.error({ e });
                this.$ui.error(e, "read_related_secondary_market_buy_order");
                throw e;
            }
        },
        comeBackToSecondaryMarket() {
            this.$router.push({
                name: "secondary-market",
                params: { id: this.$entity.represented().id },
                query: { tab: "sells" }
            });
        },
        comeBackToBuyerInfo() {
            this.oldFormData = this.formData;
            this.step -= 1;
        },
        nextStep() {
            this.step += 1;
        },
        signatureRefused() {
            this.stepperState = "warning";
            this.nextStep();
        },
        initSteps() {
            //In case fetchData return error, add finalStep to display error
            this.steps = [
                {
                    title: this.$t("buyStepper.finalize"),
                    item: {
                        is: "FinalStep"
                    },
                    noBack: true,
                    schemaDouble: {},
                    next: {
                        fn: () => {
                            this.$router
                                .push({
                                    name: "secondary-market",
                                    params: { id: this.$entity.represented().id }
                                })
                                .catch(() => {});
                        },
                        txt:
                            this.stepperState == "error"
                                ? this.$t("buyStepper.finalize-next-error")
                                : this.$t("buyStepper.finalize-next")
                    }
                }
            ];
        },
        getSteps() {
            return [
                {
                    title: this.$t("signStepper.buyerInfo.title"),
                    item: {
                        is: "BuyerInfo"
                    },
                    back: {
                        fn: this.comeBackToSecondaryMarket,
                        txt: this.$t("label.cancel")
                    },
                    next: {
                        fn: this.goToSignature
                    },
                    schemaDouble: {}
                },
                {
                    title: this.$t("signStepper.sign"),
                    item: {
                        is: "GlobalYousignIFrame"
                    },
                    noPadding: true,
                    back: {
                        fn: this.comeBackToBuyerInfo
                    },
                    next: {
                        fn: this.nextStep
                    },
                    schemaDouble: {
                        signature_done: {
                            dataType: "bool",
                            isRequired: true
                        }
                    }
                },
                {
                    title: this.$t("buyStepper.finalize"),
                    item: {
                        is: "FinalStep"
                    },
                    noBack: true,
                    schemaDouble: {},
                    next: {
                        fn: () => {
                            this.$router
                                .push({
                                    name: "secondary-market",
                                    params: { id: this.$entity.represented().id }
                                })
                                .catch(() => {});
                        },
                        txt:
                            this.stepperState == "error"
                                ? this.$t("buyStepper.finalize-next-error")
                                : this.$t("buyStepper.finalize-next")
                    }
                }
            ];
        },
        async goToSignature() {
            const confirm = await this.modalConfirmOrder();
            if (!confirm) {
                return;
            }
            this.nextStep();
        },
        async modalConfirmOrder() {
            return new Promise(resolve => {
                this.$dialog.confirm({
                    title: this.$t("signStepper.confirm-dialog.title"),
                    text: this.$t("signStepper.confirm-dialog.text"),
                    confirm: {
                        fn: () => resolve(true)
                    },
                    cancel: {
                        fn: () => resolve(false)
                    }
                });
            });
        },
        catchError() {
            this.stepperState = "error";
            this.gotToLastStep();
        },
        gotToLastStep() {
            this.step = this.steps.length - 1;
        }
    }
};
</script>
