import getInvestors from "./actions/get_investors";
import getInvestor from "./actions/get_investor";
import fetchInvestor from "./actions/fetch_investor";
import deleteInvestor from "./actions/delete_investor";
import createInvestor from "./actions/create_investor";
import updateInvestor from "./actions/update_investor";
import sendInvitationInvestor from "./actions/send_invitation_investor";
import inviteInvestors from "./actions/invite_investors";
import getInvestorOptions from "./actions/get_investor_options";

export default {
    getInvestors,
    getInvestor,
    fetchInvestor,
    deleteInvestor,
    createInvestor,
    updateInvestor,
    sendInvitationInvestor,
    inviteInvestors,
    getInvestorOptions
};
