<template>
    <EqsCard
        :title="company.name"
        :avatar="company.avatar"
        :is-company="true"
        :loading="loading"
        closable
        @close="() => $emit('update:company', null)"
    >
        <EqsPanels v-if="!loading" v-bind="{ panels, value: [] }">
            <template #default="{ item }">
                <component
                    :is="item.is"
                    v-if="item.is != 'EqsLine'"
                    profile
                    v-bind="{
                        ...item.props,
                        countries: [],
                        loading: localLoading,
                        formData: company
                    }"
                />
                <EqsLine
                    v-else-if="
                        item.props.data &&
                        item.props.data.labels &&
                        item.props.data.labels.length > 0
                    "
                    :config="item.props.data"
                    :format-dataset-labels="data => formatDataValue(data, item)"
                />
                <v-flex v-else class="text-xs-center mb-2 no-data-text mt-2">
                    {{ $t("portfolio.no-data-to-display") }}
                </v-flex>
            </template>
        </EqsPanels>
    </EqsCard>
</template>

<script>
import MyHoldings from "./MyHoldings";
import SellBuy from "./SellBuy";
import {
    // globalKycMixin,
    entityDetailsMixin
} from "@/mixins/kycMixin";
import Payments from "@/components/views/portfolio/Payments";
import Analytics from "@/components/views/portfolio/Analytics";
import PortfolioDataroom from "@/components/views/portfolio/PortfolioDataroom";

export default {
    name: "CompanyDetail",
    components: {
        MyHoldings,
        SellBuy,
        Payments,
        Analytics,
        PortfolioDataroom
    },
    mixins: [entityDetailsMixin],
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            companyEntity: null,
            loading: true,
            localLoading: true,
            venalData: {},
            navData: {},
            venalDataPanel: {},
            navDataPanel: {},
            countries: []
        };
    },
    computed: {
        panels() {
            let res = [
                {
                    title: this.$t("portfolio.reccap"),
                    item: {
                        is: "Analytics",
                        props: {
                            data: this.company,
                            getCurrency: this.getCurrency
                        }
                    }
                }
            ];
            const companyData = this.entityDetailsSchema(this, this.company);
            res.push({
                title: this.$t("registration.company.company-infos"),
                item: {
                    is: companyData.is,
                    props: {
                        schemaDouble: { ...companyData.schemaDouble }
                    }
                }
            });
            res.push({
                title: this.$t("capital-increase.admin.detail.information-dataroom"),
                item: {
                    is: "PortfolioDataroom",
                    props: {
                        company: this.company
                    }
                }
            });
            res.push({
                title: this.$t("portfolio.my-holdings"),
                item: {
                    is: "MyHoldings",
                    props: {
                        assets: this.company.assets
                    }
                }
            });
            res.push({
                title: this.$t("portfolio.payments"),
                item: {
                    is: "Payments",
                    props: {
                        assets: this.company.dividends,
                        getCurrency: this.getCurrency
                    }
                }
            });

            if (this.venalData.length)
                res.push({
                    title: this.$t("portfolio.asset-venal-value.title"),
                    item: {
                        is: "EqsLine",
                        props: {
                            assets: this.company.assets,
                            type: "venalValue",
                            data: this.venalDataPanel
                        }
                    }
                });
            if (this.navData.length)
                res.push({
                    title: this.$t("portfolio.nav.title"),
                    item: {
                        is: "EqsLine",
                        props: {
                            assets: this.company.assets,
                            type: "nav",
                            data: this.navDataPanel
                        }
                    }
                });
            if (this.shareledgerWithShareTransfer.length)
                res.push({
                    title: this.$t("portfolio.sell-buy.title"),
                    item: {
                        is: "SellBuy",
                        props: {
                            data: this.company,
                            shareledgerWithShareTransfer: this.shareledgerWithShareTransfer,
                            getCurrency: this.getCurrency
                        }
                    }
                });
            return res;
        },
        shareledgerWithShareTransfer() {
            return this.company.assets.filter(asset => asset.shareledger.share_transfer);
        }
    },
    watch: {
        async company(val) {
            this.loading = true;
            if (val) {
                await this.fetchCompanyInfo();
                await this.initShareledgerData();
            }
            this.loading = false;
        }
    },
    async created() {
        this.loading = true;
        await this.fetchCompanyInfo();
        await this.initShareledgerData();
        this.loading = false;
    },
    methods: {
        async fetchCompanyInfo() {
            try {
                const res = await this.$api.getEntity(this.company.id);
                this.companyEntity = res.data;
            } catch (e) {
                this.$ui.error(e, "my_portfolio_company_details");
            }
        },
        async initShareledgerData() {
            try {
                const resNav = await this.$api.getCompanyAnalytics("nav", this.companyEntity);
                this.navData = resNav.data;
                const resVenal = await this.$api.getCompanyAnalytics(
                    "marketValue",
                    this.companyEntity
                );
                this.venalData = resVenal.data;
            } catch (e) {
                this.$ui.error(e, "my_portfolio_company_details");
            }
            this.navDataPanel = this.formatedData(this.navData, "nav");
            this.venalDataPanel = this.formatedData(this.venalData, "venalValue");
        },
        formatedData(data, dataType) {
            //REVERSE DATA ARRAY -> TO SORT BY CHRONOLOGIC DATE
            data = data.reverse();
            let labels = [];
            let dataSet = {
                label: dataType,
                backgroundColor: "#0D3F67",
                data: []
            };
            data.forEach(element => {
                labels.push(this.convertDate(element.value_datetime));
                dataSet.data.push(parseFloat(element.value_data));
            });
            return {
                labels: labels,
                datasets: [dataSet]
            };
        },
        convertDate(date) {
            return this.$time.formated(date);
        },
        formatDataValue(val, panel) {
            const type = panel.props.type;
            const currency = panel.props.assets[type].value_data_currency;
            if (val) return this.$currency.displayWithCurrency(val, currency);
        },
        getCurrency() {
            if (
                !this.companyEntity ||
                !this.companyEntity.country ||
                this.companyEntity.country.alphacode != "US"
            )
                return "EUR";
            else return "USD";
        }
    }
};
</script>

<style lang="scss" scoped>
.no-data-text {
    font: Bold 17px/21px Proxima Nova;
    font-size: calc(100% + 0.15vw);
    letter-spacing: 0;
    color: #5a5a5a;
}
</style>
