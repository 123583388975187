var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"closable":"","persistent":""},on:{"close":() => (_vm.dialogProxy = false)},model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},'EqsDialog',{
        title: _vm.$t('governance.decisions.detail-dialog.title'),
        confirm: {
            txt: _vm.$t('btn.close')
        }
    },false),[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                    value: _vm.$t('governance.decisions.detail-dialog.general-parameters'),
                    eqsType: 'subtitle'
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsText',_vm._b({},'EqsText',{
                    label: _vm.$t('governance.decisions.detail-dialog.name'),
                    editable: false,
                    value: _vm.item.name
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                    value: _vm.$t('governance.decisions.header.organe'),
                    editable: false
                },false),[_c('EqsChip',_vm._b({},'EqsChip',{
                        color: _vm.$color.colorFromHash(_vm.item.organ.name),
                        textColor: 'text'
                    },false),[_c('div',[_vm._v(_vm._s(_vm.item.organ.name))])])],1)],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsText',_vm._b({},'EqsText',{
                    label: _vm.$t('governance.decisions.detail-dialog.number'),
                    editable: false,
                    value: !!_vm.item.number_globally
                        ? _vm.item.number_globally.toString()
                        : _vm.$t('label.undefined')
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                    value: _vm.$t('governance.decisions.detail-dialog.details'),
                    eqsType: 'subtitle'
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                    value: _vm.$t('governance.organs.details.activity.headers.state'),
                    editable: false
                },false),[_c('EqsChip',_vm._b({},'EqsChip',_vm.decisions_state[_vm.item.state],false),[_c('div',[_vm._v(_vm._s(_vm.decisions_state[_vm.item.state].text))])])],1)],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                    value: _vm.$t('governance.decisions.header.mode'),
                    editable: false
                },false),[_c('EqsChip',_vm._b({},'EqsChip',{
                        color: _vm.$color.colorFromHash(_vm.item.type),
                        textColor: 'text'
                    },false),[_c('div',[_vm._v(_vm._s(_vm.decision_types[_vm.item.type].text))])])],1)],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsDate',{attrs:{"value":_vm.item.date,"editable":false,"label":_vm.$t('governance.decisions.stepper.step.upload-decision.form.date.label')}})],1),(_vm.item.convocation_documents.length)?_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsList',_vm._b({scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('EqsFileActions',_vm._b({},'EqsFileActions',{
                            item: item.doc,
                            index,
                            editable: false
                        },false))]}}],null,false,3867861314)},'EqsList',{
                    editable: false,
                    label: _vm.$t(
                        'governance.decisions.stepper.step.upload-decision.form.convocation-docs.title'
                    ),
                    items: _vm.item.convocation_documents.map(item => {
                        return {
                            doc: item,
                            subtitle: _vm.documentTypesObj[item.category.toLowerCase()].text,
                            title: item.name
                        };
                    })
                },false))],1):_vm._e(),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                    value: _vm.$t('governance.decisions.detail-dialog.anchored'),
                    eqsType: 'subtitle'
                },false))],1)],1),(_vm.item.proof)?_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsDate',{attrs:{"value":_vm.item.locked_at,"editable":false,"label":_vm.$t('governance.decisions.detail-dialog.anchor-date')}})],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsText',{attrs:{"value":_vm.item.hash,"editable":false,"label":_vm.$t('governance.decisions.detail-dialog.tx-id')}})],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsFileInput',{attrs:{"value":_vm.item.proof,"editable":false,"label":_vm.$t('governance.decisions.detail-dialog.proof-doc')}})],1)],1):_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsAlert',_vm._b({},'EqsAlert',{
                    eqsType: 'warning',
                    text: _vm.$t('governance.decisions.lock-dialog.alert')
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsBtn',{attrs:{"color":"error","action":() => _vm.lockItem(_vm.item)}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-lock-open-variant ")]),_vm._v(" "+_vm._s(_vm.$t("governance.decision.lock-btn"))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }