<template>
    <div class="max-height-fit fill-height d-flex flex-column overflow-y">
        <EqsDataTable
            class="fill-width"
            v-bind="{
                headers: sellOffersHeaders,
                loading: localLoading || buyOfferLoading,
                noDataCustom: {
                    title: $t('secondary-market.follow-sells.sells-offer-table.no-data-title'),
                    text: $t('secondary-market.follow-sells.sells-offer-table.no-data-text'),
                    img: '/images/secondaryMarket/sell_offer.svg'
                }
            }"
            selectable
            single-select
            :selected.sync="selectedOffer"
            :items="mySellOffers"
        >
            <template #item.company="{ item }">
                <div class="d-flex flex-row align-center">
                    <EqsAvatar
                        class="pr-3"
                        v-bind="{
                            user: item.shareledger.company,
                            eqsType: 'list',
                            noBadge: true
                        }"
                    />
                    {{ item.shareledger.company.name }}
                </div>
            </template>
            <template #item.shareledger.name="{ value, index }">
                <div :el-class="`sell-offer-shareledger-name-${index}`">
                    {{ value }}
                </div>
            </template>
            <template #item.initial_quantity="{ value, index }">
                <div :el-class="`sell-offer-initial_quantity-${index}`">
                    {{ $currency.display(value, "0,0") }}
                </div>
            </template>
            <template #item.quantity="{ value, index }">
                <div :el-class="`sell-offer-quantity-${index}`">
                    {{ $currency.display(value, "0,0") }}
                </div>
            </template>
            <template #item.price="{ item, index }">
                <div :el-class="`sell-offer-price-${index}`">
                    {{ $currency.displayWithCurrency(item.price, item.price_currency) }}
                </div>
            </template>
            <template #item.total="{ item }">
                <div>
                    {{
                        $currency.displayWithCurrency(
                            item.quantity * item.price,
                            item.price_currency
                        )
                    }}
                </div>
            </template>
            <template #item.progress="{ item }">
                <EqsCircularProgress :value="getProgress(item)" />
            </template>
            <template #item.status="{ item }">
                <EqsChip v-bind="sell_order_statutes[item.status]">
                    {{ sell_order_statutes[item.status].txt }}
                </EqsChip>
            </template>
            <template #item.action="{ item }">
                <EqsActions :actions="getActions(item, 'stop')" />
            </template>
        </EqsDataTable>
        <EqsTextDisplay
            v-if="buyOffer.length"
            eqs-type="subtitle"
            :value="$t('secondary-market.follow-sells.purchase-offer-table-subtitle')"
        />
        <EqsDataTable
            v-if="buyOffer.length"
            class="fill-width"
            :headers="buyOffersHeaders"
            :loading="buyOfferLoading"
            :items="buyOffer"
        >
            <template #item.buyer.name="{ item }">
                <div class="d-flex flex-row align-center">
                    <EqsAvatar
                        class="pr-3"
                        v-bind="{
                            user: item.buyer,
                            eqsType: 'list',
                            noBadge: true
                        }"
                    />
                    {{ item.buyer.name }}
                </div>
            </template>
            <template #item.total="{ item }">
                {{
                    $currency.displayWithCurrency(
                        item.quantity * selectedOffer[0].price,
                        selectedOffer[0].price_currency
                    )
                }}
            </template>
            <template #item.signature_procedure.status_display="{ item }">
                <EqsChip v-bind="buy_order_statutes[item.status]">
                    {{ buy_order_statutes[item.status].txt }}
                </EqsChip>
            </template>
            <template #item.action="{ item }">
                <EqsActions :actions="getActions(item, 'sign')" />
            </template>
        </EqsDataTable>
    </div>
</template>

<script>
import { sell_order_statutes, buy_order_statutes } from "@/util/secondaryMarket.js";

export default {
    name: "FollowSells",
    data() {
        return {
            sell_order_statutes,
            buy_order_statutes,
            localLoading: false,
            buyOfferLoading: false,
            mySellOffers: [],
            selectedOffer: [],
            buyOffer: [],
            sellOffersHeaders: [
                {
                    text: this.$t("market-details.tabs.company"),
                    value: "company",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.name"),
                    value: "shareledger.name",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.initialquantity"),
                    value: "initial_quantity",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.remainingquantity"),
                    value: "quantity",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.share-price"),
                    value: "price",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.total"),
                    value: "total",
                    sortable: false
                },
                {
                    value: "progress",
                    sortable: false
                },
                {
                    text: this.$t("secondary-market.tables.my-offers.status"),
                    value: "status",
                    sortable: false
                },
                {
                    text: this.$t("secondary-market.tables.my-offers.action"),
                    value: "action",
                    sortable: false
                }
            ],
            buyOffersHeaders: [
                {
                    text: this.$t("secondary-market.tables.buy-offers.name"),
                    value: "buyer.name",
                    sortable: true
                },
                {
                    text: this.$t("secondary-market.tables.buy-offers.quantity"),
                    value: "quantity",
                    sortable: true
                },
                {
                    text: this.$t("secondary-market.tables.buy-offers.total"),
                    value: "total",
                    sortable: false
                },
                {
                    text: this.$t("secondary-market.tables.buy-offers.status"),
                    value: "signature_procedure.status_display",
                    sortable: true
                },
                {
                    text: this.$t("secondary-market.tables.buy-offers.action"),
                    value: "action",
                    sortable: false
                }
            ]
        };
    },
    watch: {
        selectedOffer: {
            async handler() {
                if (this.selectedOffer.length > 0 && !this.buyOfferLoading)
                    await this.fetchBuyOffer();
                else if (!this.selectedOffer.length) this.buyOffer = [];
            }
        }
    },
    async created() {
        await this.fetchMySellOffer();
    },
    methods: {
        getProgress(item) {
            return ((item.initial_quantity - item.quantity) / item.initial_quantity) * 100;
        },
        async fetchMySellOffer() {
            this.localLoading = true;
            try {
                const res = await this.$api.readMySecondaryMarketSellOrderList();
                this.mySellOffers = res.data;
                this.selectedOffer = [this.mySellOffers[0]];
            } catch (e) {
                console.error(e, "secondary_market_get_my_sells_offers");
                this.$ui.error(e, "secondary_market_get_my_sells_offers");
            } finally {
                this.localLoading = false;
            }
        },
        async fetchBuyOffer() {
            if (this.selectedOffer.length <= 0 || !this.selectedOffer[0]) return;
            this.buyOfferLoading = true;
            try {
                const res = await this.$api.getSecondaryMarketBuyOrderListBySellOrderId(
                    this.selectedOffer[0].id
                );
                this.buyOffer = res.data;
            } catch (e) {
                console.error(e, "secondary_market_get_related_buys_offers");
                this.$ui.error(e, "secondary_market_get_related_buys_offers");
            } finally {
                this.buyOfferLoading = false;
            }
        },
        stopProcedure(order) {
            this.$dialog.warn({
                title: this.$t("secondary.delete-order"),
                text: this.$t("secondary.delete-order-warning", {}),
                confirm: {
                    fn: () => this.stopOrder(order.id)
                },
                cancel: {}
            });
        },
        async stopOrder(id) {
            await this.$api.cancelSellOrder(id);
            await this.fetchMySellOffer();
        },
        getActions(item, type) {
            let actions = [];
            if (
                type === "stop" &&
                !["Canceled", "Completed", "Complete", "Rejected_By_Seller"].includes(item.status)
            ) {
                actions.push({
                    txt: this.$t("document-to-sign.documents-list.tooltip.cancel"),
                    icon: "mdi-cancel",
                    fn: () => this.stopProcedure(item),
                    color: "warning"
                });
            } else if (type === "sign" && item.status === "Pending_Seller_Signature") {
                actions.push({
                    txt: this.$t("document-to-sign.documents-list.tooltip.sign"),
                    icon: "mdi-border-color",
                    fn: () => this.openSignStepper(item),
                    color: "primary"
                });
            }

            return actions;
        },
        openSignStepper(item) {
            this.$router
                .push({
                    name: "secondary-market-sign-stepper",
                    params: {
                        orderId: item.id
                    }
                })
                .catch(() => {});
        }
    }
};
</script>
