<template>
    <v-breadcrumbs :items="crumbsItems" divider="/" class="breadcrumbs" :loading="loading">
        <template #item="{ item }">
            <v-breadcrumbs-item
                v-if="item.to.name !== $route.name"
                :to="item.to"
                :exact="item.exact"
            >
                <div class="link-with-icon">
                    <v-icon :color="$color.colors.eqsBlue" class="mr-1 my-icon" size="x-large">
                        {{ getIcon(item.to) }}
                    </v-icon>
                    <span>{{ getText(item.to) }}</span>
                </div>
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    name: "Breadcrumbs",
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        rootPage: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            crumbsItems: []
        };
    },
    watch: {
        $route: {
            handler: function (to, from) {
                // Back to root
                if (to.name === this.rootPage) {
                    this.init();
                } else {
                    // In case of reload, reconstruct path with to

                    this.init();
                    const matchedRoutes = to.matched;
                    // Skip root (auto init), and last (treated after)
                    for (let i = 1; i < matchedRoutes.length - 1; i++) {
                        let params = {};
                        if (matchedRoutes[i].path.includes(":bc_id")) {
                            params.bc_id = this.$route.params.bc_id;
                        }
                        if (matchedRoutes[i].path.includes(":cgp_id")) {
                            params.cgp_id = this.$route.params.cgp_id;
                        }
                        if (matchedRoutes[i].path.includes(":investor_id")) {
                            params.investor_id = this.$route.params.investor_id;
                        }
                        this.crumbsItems.push({
                            exact: true,
                            to: {
                                name: matchedRoutes[i].name,
                                params: { ...params }
                            }
                        });
                    }

                    const checkIfOldPage = this.crumbsItems.findIndex(e => to.name === e.to.name);

                    // Add entry
                    if (checkIfOldPage === -1) {
                        this.crumbsItems.push({
                            exact: true,
                            to: {
                                name: to.name,
                                params: to.params,
                                query: to.query
                            }
                        });
                    }
                    // Remove entry
                    else {
                        this.crumbsItems = this.crumbsItems.slice(0, checkIfOldPage + 1);
                    }
                }
            },
            immediate: true
        }
    },
    methods: {
        getText(to) {
            let text = "";
            switch (to.name) {
                case "cgp_investor":
                case "bc_investor_of_cgp":
                case "admin_investor_of_cgp_of_bc":
                    text = this.$t("universe.kyoseil.dashboard.investor");
                    break;
                case "bc_cgp":
                case "admin_cgp_of_bc":
                    text = this.$t("universe.kyoseil.dashboard.investors");
                    break;
                case "admin_bc":
                    text = this.$t("universe.kyoseil.dashboard.cgps");
                    break;
                default:
                    text = "";
                    break;
            }
            return text;
        },
        getIcon(to) {
            let icon = "";
            switch (to.name) {
                case "cgp_investor":
                case "bc_investor_of_cgp":
                case "admin_investor_of_cgp_of_bc":
                    icon = "mdi-account-multiple-outline";
                    break;
                case "bc_cgp":
                case "admin_cgp_of_bc":
                    icon = "mdi-account-multiple-outline";
                    break;
                case "admin_bc":
                    icon = "mdi-briefcase-outline";
                    break;
                default:
                    icon = "mdi-home-outline";
                    break;
            }
            return icon;
        },
        init(to) {
            this.crumbsItems = [
                {
                    exact: true,
                    to: {
                        name: this.rootPage,
                        params: {},
                        query: {},
                        icon: ""
                    }
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
.link-with-icon {
    > span {
        vertical-align: middle;
    }

    .my-icon {
        vertical-align: middle;
        margin-left: 2px;
        transition: transform 0.3s ease;
    }

    &:hover .my-icon {
        transform: scale(1.1);
    }
}
:deep(.v-breadcrumbs__item) {
    // color: red !important;
}

/* .icon-size {
    font-size: 21px !important;
} */

:deep(.v-breadcrumbs__divider) {
    // color: purple !important;
}

:deep(.v-breadcrumbs__item--disabled) {
    // color: green !important;
}
</style>
