<template>
    <div
        :class="`eqs-data-table ${elClass}-container`"
        :style="{ ...getStyle, ...additionalStyle }"
    >
        <v-data-table
            v-if="!noData"
            v-model="selectedProxy"
            v-bind="{
                itemsPerPage: -1,
                ...$attrs,
                ...getData,
                loading,
                items,
                height: '100%',
                search,
                headers,
                hideDefaultFooter,
                customFilter: selectedFilters.length ? customFilter : defaultFilter,
                selectable,
                itemClass: getSelectedColor,
                showSelect: selectable && !loading
            }"
            fixed-header
            v-on="$listeners"
        >
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <template #top>
                <div v-if="searchable || add || $slots['header']" class="d-flex flex-column">
                    <v-row class="d-flex align-center py-0">
                        <slot name="top" />
                        <v-col v-if="searchable" :cols="getSearchCols" class="py-0">
                            <EqsSearchBar
                                :value.sync="search"
                                v-bind="{
                                    ...searchable,
                                    loading,
                                    elClass: `${elClass}-filter`
                                }"
                            />
                        </v-col>
                        <v-col
                            v-if="searchable && searchable.filters"
                            :cols="$reactive.isXs() ? 12 : 12 / numElemHeader"
                            :class="$reactive.isXs() ? 'py-2' : 'py-0'"
                        >
                            <EqsSelect
                                :value.sync="selectedFilters"
                                multiple
                                v-bind="{
                                    items: filters,
                                    placeholder: $t('data-table.select-filters'),
                                    elClass: `${elClass}-filter`
                                }"
                                :hint="
                                    ($reactive.isXs() || numElemHeader > 2) && moreThanOneFilterTxt
                                        ? moreThanOneFilterTxt
                                        : ''
                                "
                                persistent-hint
                                prepend-icon="mdi-filter"
                                clearable
                                single-line
                            >
                                <template #selection="{ item, index }" style="height: inherit">
                                    <EqsChip
                                        v-if="index === 0"
                                        class="pa-1"
                                        :color="$color.colorFromHash(item.value)"
                                        close
                                        text-color="text"
                                        style="max-width: inherit"
                                        @click:close="removeFilter(index)"
                                    >
                                        {{ item.text }}
                                    </EqsChip>
                                    <span
                                        v-if="index === 1 && !$reactive.isXs() && numElemHeader < 3"
                                        class="grey--text caption pa-1"
                                    >
                                        {{ moreThanOneFilterTxt }}
                                    </span>
                                </template>
                            </EqsSelect>
                        </v-col>
                        <v-spacer />
                        <v-col
                            v-if="add || other"
                            :cols="
                                $reactive.isXs() && !searchable && !$slots['header'] && add && other
                                    ? 12
                                    : 'auto'
                            "
                            class="py-2"
                        >
                            <div class="d-flex justify-end">
                                <slot name="other" />
                                <EqsBtn
                                    v-if="add"
                                    :el-class="`${elClass}-add-item`"
                                    color="primary"
                                    :eqs-type="
                                        $reactive.isXs() || !searchable ? 'default' : 'block'
                                    "
                                    v-bind="{
                                        action: add.fn,
                                        loading
                                    }"
                                >
                                    <template #with-icon>
                                        <v-icon left> mdi-plus </v-icon>
                                        {{ capitalizeFirstLetter(add.txt) }}
                                    </template>
                                </EqsBtn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </v-data-table>
        <InternalNoData v-else v-bind="{ noDataCustom, add, elClass }" />
        <v-input
            v-bind="{
                ...$attrs,
                value: selectable ? selectedProxy : items,
                rules: rulesRequired
            }"
            dense
            hide-details="auto"
        />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsDataTable.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { getObjectValueByPath } from "../../../../../shared/util";
import { capitalizeFirstLetter } from "../../../../../shared/util";
import { requiredR } from "../../../rules";

export default {
    name: "EqsDataTable",
    components: {
        EqsSearchBar: () => import("../../molecules/EqsSearchBar/EqsSearchBar.vue"),
        EqsSelect: () => import("../../molecules/EqsSelect/EqsSelect.vue"),
        EqsBtn: () => import("../../atoms/EqsBtn/EqsBtn.vue"),
        EqsChip: () => import("../../atoms/EqsChip/EqsChip.vue"),
        InternalNoData: () => import("./_internal/InternalNoData.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-data-table"
        },
        items: {
            type: Array,
            default: () => []
        },
        headers: {
            type: Array,
            required: true
        },
        selected: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ""
        },
        add: {
            type: Object,
            default: null
        },
        searchable: {
            type: Object,
            default: null
        },
        selectable: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        topHeightToAdd: {
            type: Number,
            default: 0
        },
        hideDefaultFooter: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        noDataCustom: {
            type: Object,
            default: null
        },
        color: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            search: "",
            selectedFilters: [],
            capitalizeFirstLetter
        };
    },
    computed: {
        rulesRequired() {
            const rules = !!this.rules ? [...this.rules] : [];
            if (this.isRequired) rules.push(requiredR);
            return rules;
        },
        selectedProxy: {
            get() {
                return this.selected;
            },
            set(val) {
                this.$emit("update:selected", val);
            }
        },
        other() {
            return !!this.$slots["other"];
        },
        noData() {
            return !this.loading && !this.items.length && this.noDataCustom;
        },
        filters() {
            if (!this.searchable || !this.searchable.filters) return [];
            return this.headers.filter(header => this.searchable.filters.includes(header.value));
        },
        additionalStyle() {
            let otherStyle = {};
            if (this.color) {
                otherStyle["--stepper-background-color"] = this.color;
            }
            return {
                ...otherStyle
            };
        },
        numElemHeader() {
            let num = 0;
            if (this.searchable) {
                num++;
                if (this.searchable.filters) num++;
            }
            if (this.add || this.other) num++;
            return num;
        },
        moreThanOneFilterTxt() {
            if (!this.selectedFilters.length || this.selectedFilters.length < 2) return "";
            return `(+${this.selectedFilters.length - 1} ${
                this.selectedFilters.length - 1 > 1
                    ? this.$t("data-table.others")
                    : this.$t("data-table.other")
            })`;
        },
        getSearchCols() {
            if (this.$reactive.isXs()) {
                return (this.other || this.add) && !(this.add && this.other) ? 8 : 12;
            }
            if (this.searchable && this.searchable.filters) return 6;
            if (this.add && this.other) return 6;
            return !this.add || !this.other ? 8 : 12;
        }
    },
    methods: {
        removeFilter(index) {
            this.$delete(this.selectedFilters, index);
        },
        getSelectedColor(item) {
            if (!!this.selectedProxy && this.selectedProxy.find(selected => selected == item)) {
                return "text lighten-5";
            }
        },
        defaultFilter(value, search, item) {
            return (
                value != null &&
                search != null &&
                typeof value !== "boolean" &&
                value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
            );
        },
        customFilter(value, search, item) {
            const searchFor = this.selectedFilters.map(keyPath =>
                getObjectValueByPath(item, keyPath)
            );
            return searchFor.find(val => !!this.defaultFilter(val, search, item));
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsDataTable.scss";
</style>
