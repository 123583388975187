<template>
    <span v-if="localData.hasData" class="ma-0 pa-0">
        <a
            :href="localData.link"
            :title="localData.title"
            :target="localData.target"
            class="cl-icon-with-text pa-1"
        >
            <span>{{ localData.text }}</span>
            <v-icon dense class="custom-right-icon"> mdi-open-in-new </v-icon>
        </a>
    </span>
    <span v-else>-</span>
</template>

<script>
export default {
    name: "ContactLink",
    props: {
        type: {
            type: String,
            required: true
        },
        data: {
            type: String,
            default: ""
        }
    },
    computed: {
        localData() {
            if (!this.data || this.data.length === 0) return { hasData: false };
            let tmpData = { hasData: true };
            tmpData.text = this.data;
            if (this.type === "email") {
                tmpData.title = this.$t("universe.kyoseil.dashboard.actions.send-email");
                tmpData.link = "mailto:" + this.data;
                tmpData.target = "";
            } else if (this.type === "phone") {
                tmpData.title = this.$t("universe.kyoseil.dashboard.actions.call");
                tmpData.link = "phoneto:" + this.data;
                tmpData.target = "";
            } else if (this.type === "address") {
                tmpData.title = this.$t("universe.kyoseil.dashboard.actions.gmaps-link");
                tmpData.link = "https://www.google.com/maps/search/?api=1&query=" + this.data;
                tmpData.target = "_blank";
            } else if (this.type === "link") {
                tmpData.title = this.$t("universe.kyoseil.dashboard.actions.web-link");
                tmpData.link = this.data;
                tmpData.target = "_blank";
            }
            return tmpData;
        }
    }
};
</script>

<style lang="scss" scoped>
.cl-icon-with-text {
    > span {
        display: inline;
        vertical-align: bottom;
    }

    .custom-right-icon {
        vertical-align: bottom;
        margin-left: 6px;
        transition: transform 0.2s ease;
    }

    .custom-right-icon:hover {
        transform: scale(1.2);
    }
}
</style>
