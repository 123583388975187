<template>
    <v-dialog
        :value.sync="dialogProxy"
        max-width="1000px"
        content-class="dialog-on-top"
        @click:outside="closeDialog()"
    >
        <v-card style="min-width: 400px; max-height: 90vh">
            <v-toolbar color="white" flat>
                <v-toolbar-title>
                    <div class="icon-with-text">
                        <v-icon v-if="investor.is_company" class="custom-left-icon">
                            mdi-domain
                        </v-icon>
                        <span>{{
                            `${investor.name}${$entity.isStaff() ? " - " + investor.id : ""}`
                        }}</span>
                    </div>
                </v-toolbar-title>
                <v-spacer />
                <v-btn icon large @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <template #extension>
                    <v-tabs v-model="tabSelected" centered show-arrows grow>
                        <v-tab v-for="(itemTabHeader, i) in tabsHeader" :key="i">
                            {{ itemTabHeader.label }}
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>
            <v-tabs-items v-model="tabSelected" class="pa-6">
                <v-tab-item v-for="(itemTabItem, i) in tabsHeader" :key="`${investor.id}.${i}`">
                    <template #default>
                        <v-lazy>
                            <keep-alive max="32">
                                <component
                                    :is="itemTabItem.component"
                                    v-bind="{
                                        investor,
                                        loading: loading || localLoading
                                    }"
                                />
                            </keep-alive>
                        </v-lazy>
                    </template>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "InvestorModal",
    components: {
        InvestorInfoTab: () => import("./tabs/InvestorInfoTab"),
        InvestorDocumentTab: () => import("./tabs/InvestorDocumentTab"),
        InvestorOrderTab: () => import("./tabs/InvestorOrderTab"),
        InvestorActionTab: () => import("./tabs/InvestorActionTab")
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        open: {
            type: Boolean,
            required: true
        },
        investor: {
            type: Object,
            required: true
        },
        showDocuments: {
            type: Boolean,
            required: false,
            default: true
        },
        showOrders: {
            type: Boolean,
            required: false,
            default: true
        },
        showActions: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    emits: ["closeDialog"],
    data() {
        return {
            localLoading: false,
            tabSelected: 0
        };
    },
    computed: {
        tabsHeader() {
            let headers = [
                {
                    label: "Informations",
                    component: "InvestorInfoTab"
                }
            ];
            if (this.showDocuments) {
                headers.push({
                    label: "Documents",
                    component: "InvestorDocumentTab"
                });
            }
            if (this.showOrders) {
                headers.push({
                    label: "Ordres",
                    component: "InvestorOrderTab"
                });
            }
            if (this.showActions) {
                headers.push({
                    label: "Actions",
                    component: "InvestorActionTab"
                });
            }

            return headers;
        },
        dialogProxy: {
            get() {
                return this.open;
            },
            set(val) {
                if (!val) this.closeDialog();
            }
        }
    },
    methods: {
        closeDialog() {
            this.tabSelected = 0;
            this.$emit("closeDialog");
        }
    }
};
</script>
<style scoped lang="scss">
:deep(.dialog-on-top) {
    align-self: start !important;
}
:deep(.v-toolbar__extension) {
    padding: 0 !important;
    margin: 0 !important;
}
:deep(.v-tab) {
    text-transform: none !important;
}
.icon-with-text {
    > span {
        vertical-align: middle;
    }

    .custom-left-icon {
        vertical-align: middle;
        margin-right: 6px;
    }
}
</style>
