<template>
    <div class="d-flex fill-width">
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsSelect
                    editable
                    :label="$t('label.search-by')"
                    :value.sync="query"
                    :items="entityModel.queries"
                    item-value="id"
                    item-text="name"
                    :hint="queryItem && queryItem.hint ? queryItem.hint : ''"
                    persistent-hint
                    v-bind="{ elClass: `query-${accountType}`, ...$attrs }"
                    @change="() => (search = '')"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsAutocomplete
                    :loading="loading"
                    :loading-items="loadingLocal"
                    :value.sync="valueProxy"
                    :search-input.sync="search"
                    prepend-icon="account_circle"
                    :items="entities"
                    return-object
                    item-value="id"
                    :item-text="query"
                    :hint="getHint"
                    persistent-hint
                    chips
                    v-bind="{ ...$attrs, elClass: `choose-${accountType}` }"
                    v-on="$listeners"
                >
                    <template #selection="data">
                        <EqsChip
                            v-if="data.item"
                            :el-class="`choose-${accountType}-selected`"
                            color="primary"
                            v-bind="{ txt: data.item.name, entity: data.item }"
                        >
                            <div
                                class="d-flex justify-center align-center"
                                style="margin-left: -14px"
                            >
                                <EqsAvatar
                                    v-if="data.item"
                                    :user="data.item"
                                    color="white"
                                    class="fill-height choose-entity-avatar"
                                    size="100%"
                                />
                                <img
                                    v-else-if="image && !getData.imgRight"
                                    class="eqs-chip-left-img m"
                                    :src="image"
                                    width="auto"
                                    height="100%"
                                />
                                <span class="ml-3">{{ data.item.name }}</span>
                            </div>
                        </EqsChip>
                    </template>
                    <template #item="{ parent, item }">
                        <EqsAvatar
                            v-if="item"
                            :avatar-obj="item.avatar"
                            :is-company="item.is_company"
                            color="white"
                            type="list"
                            alt="avatar"
                            position="center"
                        />
                        <v-list-item-content v-if="item">
                            <div
                                v-for="key in entityModel.queries"
                                :key="key.id"
                                :class="getKeyClass(key.id)"
                            >
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <div
                                    v-if="isQuery(key.id)"
                                    v-html="parent.genFilteredText(item[key.id])"
                                />
                                <div v-else>
                                    {{ item[key.id] }}
                                </div>
                            </div>
                        </v-list-item-content>
                        <v-list-item-action v-if="item">
                            <div v-if="item.lemonway_state !== 'success'">
                                {{ $t("molecule.form.pending") }}
                            </div>
                        </v-list-item-action>
                    </template>
                    <template #no-data>
                        <v-flex v-if="!loadingLocal" layout row justify-center>
                            <EqsBtn
                                el-class="dialog-invite-entity"
                                type="variable-md"
                                color="blue"
                                outlined
                                :action="inviteEntity"
                            >
                                {{
                                    `${$t("label.invite")}: '${search || entityModel.singularName}'`
                                }}
                            </EqsBtn>
                        </v-flex>
                        <v-flex v-else layout row justify-center>
                            <EqsLoader
                                eqs-anim
                                class="flex"
                                style="width: auto; height: auto; max-width: 52px; max-height: 52px"
                                size="100%"
                            />
                        </v-flex>
                    </template>
                </EqsAutocomplete>
            </v-col>
        </v-row>
        <GlobalInviteEntityDialog
            :value.sync="dialog"
            v-bind="{
                accountType,
                entity,
                inviteData,
                byRole,
                elClass: 'global-choose-entity'
            }"
            class="mt-0 mb-4"
            @invited="populateInvitedEntity"
        />
    </div>
</template>

<script>
import { InviteEntity } from "@/services/entity";
export default {
    name: "GlobalChooseEntity",
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
        accountType: {
            type: String,
            required: true
        },
        byRole: {
            type: Boolean,
            default: false
        },
        inviteData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            entities: [],
            loadingLocal: false,
            search: null,
            dialog: false,
            query: "name",
            entity: {},
            count: 0
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        queryItem() {
            return this.entityModel
                ? this.entityModel.queries.find(item => item.id === this.query)
                : null;
        },
        entityModel() {
            return InviteEntity(this)[this.accountType];
        },
        isCompany() {
            return this.accountType === "company";
        },
        getHint() {
            if (_.isEmpty(this.valueProxy)) return "";
            if (!this.valueProxy.is_company)
                return this.valueProxy.email
                    ? `${this.$t("label.email")}: ${this.valueProxy.email}`
                    : "";
            else if (this.valueProxy.company_idcode)
                return this.valueProxy.company_idcode
                    ? `${this.$t("label.company-id")}: ${this.valueProxy.company_idcode}`
                    : "";
            return "";
        }
    },
    watch: {
        search(val) {
            if (this.byRole) return;
            this.loadingLocal = false;
            if (_.isEmpty(this.valueProxy)) this.entities = [];
            if (!val || val.length < 3) return;
            this.loadingLocal = true;
            this.fetchEntitiesDebounced();
        },
        accountType(val) {
            this.entities = [];
            this.search = "";
            this.query = this.entityModel.queries[0].id;
        }
    },
    async created() {
        if (!_.isEmpty(this.value) || this.byRole) await this.fetchEntities();
    },
    methods: {
        fetchEntitiesDebounced() {
            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(async () => {
                if (!this.search || this.search.length < 3) return;
                await this.fetchEntities();
                this.loadingLocal = false;
            }, 500); /* 500ms throttle */
        },
        isQuery(key) {
            return this.query === key;
        },
        inviteEntity() {
            let entity = {};
            entity[this.query] = this.search;
            this.entity = entity;
            this.dialog = true;
        },
        getKeyClass(key) {
            let res = "pr-2";
            if (!this.isQuery(key)) res += " caption font-weight-light";
            return res;
        },
        async populateInvitedEntity(entity) {
            let res = null;
            try {
                this.loadingLocal = true;
                if (this.byRole) {
                    this.query = "name";
                    res = await this.$api.roleList({
                        entity_represented_id: this.$entity.represented().id,
                        role_name: this.accountType
                    });
                    this.entities = res.data.map(item => item.user);
                } else {
                    this.query = entity.is_company ? "company_idcode" : "email";
                    res = await this.$api.getEntitiesInvite(entity);
                    this.entities = res.data;
                }
            } catch (e) {
            } finally {
                this.$set(this, "valueProxy", entity);
                this.loadingLocal = false;
            }
        },
        async fetchEntities() {
            try {
                this.loadingLocal = true;
                let res = null;
                if (this.byRole) {
                    res = await this.$api.roleList({
                        entity_represented_id: this.$entity.represented().id,
                        role_name: this.accountType
                    });
                    this.entities = res.data.map(item => item.user);
                } else {
                    let queryInvite = {
                        is_company: this.isCompany
                    };
                    if (this.search) {
                        queryInvite[this.query] = this.search;
                    }
                    res = await this.$api.getEntitiesInvite(queryInvite);
                    this.entities = res.data;
                }
            } catch (e) {
                this.$ui.error(e, "entities_list");
            } finally {
                this.loadingLocal = false;
            }
        }
    }
};
</script>

<style lang="scss">
.v-autocomplete__content .v-list__tile {
    height: auto;
}
.choose-entity-avatar {
    .eqs-badge {
        height: inherit;
    }
}
</style>
