<template>
    <div class="eqs-main-container margin-auto">
        <v-container class="d-flex flex-column pa-0">
            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('half')">
                    <EqsCard
                        v-bind="{
                            subtitle: $t('signStepper.buyerInfo.information-purchaser-subtitle'),
                            loading
                        }"
                    >
                        <component
                            :is="schema.is"
                            profile
                            :editable="false"
                            v-bind="{
                                ...$props,
                                ...$attrs,
                                schemaDouble: schema.schemaDouble,
                                formData: {
                                    ...buyer,
                                    kyc
                                },
                                countries: []
                            }"
                        />
                    </EqsCard>
                </v-col>
                <v-col :cols="$reactive.getCols('half')">
                    <EqsCard
                        v-bind="{
                            subtitle: $t('signStepper.buyerInfo.information-offer-subtitle'),
                            loading
                        }"
                    >
                        <div class="d-flex flex-column">
                            <v-row class="px-3">
                                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                                    <EqsText
                                        :value="order.shareledger.name"
                                        :editable="false"
                                        v-bind="{
                                            label: $t('label.shareledger'),
                                            loading
                                        }"
                                    />
                                </v-col>
                                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                                    <EqsText
                                        :value="share_types[order.shareledger.share_type].name"
                                        :editable="false"
                                        v-bind="{
                                            label: $t('sellShares.label.shareType'),
                                            loading
                                        }"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="px-3">
                                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                                    <EqsNumber
                                        :is-currency="false"
                                        :is-float="false"
                                        :editable="false"
                                        v-bind="{
                                            label: $t('buyStepper.step.pruchase-amount.quantity')
                                        }"
                                        :value="quantity"
                                    />
                                </v-col>
                                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                                    <EqsNumber
                                        :editable="false"
                                        :value="order.price"
                                        :currency-type="order.price_currency"
                                        v-bind="{
                                            label: $t(
                                                'buyStepper.step.pruchase-amount.share-price'
                                            ),
                                            loading
                                        }"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="px-3">
                                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                                    <EqsNumber
                                        :editable="false"
                                        :value="total"
                                        :currency-type="order.price_currency"
                                        v-bind="{
                                            label: $t('buyStepper.step.pruchase-amount.total'),
                                            loading,
                                            eqsSize: 'lg'
                                        }"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </EqsCard>
                </v-col>
                <v-col v-if="documents.length > 0" :cols="$reactive.getCols('half')">
                    <EqsCard
                        v-bind="{
                            subtitle: $t('signStepper.buyerInfo.uploaded-documents-subtitle'),
                            loading
                        }"
                    >
                        <EqsFileInput :editable="false" :value="documents" :multiple="true" />
                    </EqsCard>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { entityDetailsMixin } from "@/mixins/kycMixin";
import { share_types } from "@/services/shareledger/shareType";

export default {
    name: "BuyerInfo",
    mixins: [formMixin, entityDetailsMixin],
    props: {
        buyer: {
            type: Object,
            required: true
        },
        operationId: {
            type: String,
            required: true
        },
        order: {
            type: Object,
            required: true
        },
        quantity: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            documents: [],
            localLoading: false,
            share_types,
            kyc: {}
        };
    },
    computed: {
        schema() {
            let schema = this.entityDetailsSchema(this, this.buyer);
            schema.schemaDouble.kyc = { isRequired: false };

            return schema;
        }
    },
    async created() {
        this.localLoading = false;
        try {
            const res = await this.$api.getDataroom(this.operationId);
            this.documents = res.data;
            this.addSubtitle();

            this.kyc = this.$kyc.lemonwayStatus(this.buyer.lemonway_status, this);
        } catch (e) {
            console.error({ e });
            this.$emit("error", e);
        }
        this.localLoading = true;
    },
    methods: {
        addSubtitle() {
            if (this.documents.length > 0)
                this.documents.map(doc => (doc["subtitle"] = doc.infos + ": " + doc.comment));
        }
    }
};
</script>
