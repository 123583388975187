import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            btn: {
                "min-width": getVal(currentSize, {
                    mobile: "25px",
                    tablet: "50px",
                    desktop: "50px"
                }),
                padding: getVal(currentSize, {
                    mobile: "0 0.4rem",
                    tablet: "0 0.6rem",
                    desktop: "0 0.8rem"
                })
            },
            "margin-left": getVal(currentSize, {
                mobile: "0.4rem",
                tablet: "0.6rem",
                desktop: "0.8rem"
            })
        }
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {}
    };
};
