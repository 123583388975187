import { textSizing } from "../../../templates/text";
import { getVal } from "../../../services/reactive";
import { gradient_colors } from "../../../../../shared/variables/colors";

const common_vars = (background, currentSize) => {
    return {
        background,
        container: {
            "min-width": getVal(currentSize, {
                mobile: "120px",
                tablet: "140px",
                desktop: "160px"
            }),
            "min-height": getVal(currentSize, {
                mobile: "80px",
                tablet: "100px",
                desktop: "110px"
            })
        },
        label: {
            "x-margin": getVal(currentSize, {
                mobile: "16px",
                tablet: "24px",
                desktop: "24px"
            })
        }
    };
};

export const cssTypes = (currentSize = {}) => {
    return {
        default: common_vars(gradient_colors.blue, currentSize),
        blue: common_vars(gradient_colors.blue, currentSize),
        orange: common_vars(gradient_colors.orange, currentSize),
        green: common_vars(gradient_colors.green, currentSize)
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: textSizing(currentSize),
        blue: textSizing(currentSize),
        orange: textSizing(currentSize),
        green: textSizing(currentSize)
    };
};
