<template>
    <div class="d-flex flex-column fill-height">
        <v-row class="px-3">
            <v-col class="eqs-form-field">
                <EqsText
                    :label="$t('addDocTable.dialog.type')"
                    :value.sync="formData.type"
                    v-bind="{ elClass: 'type', loading, editable }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col class="eqs-form-field">
                <EqsTextArea
                    :label="$t('addDocTable.dialog.description')"
                    :value.sync="formData.description"
                    :is-required="false"
                    v-bind="{ elClass: 'description', loading, editable, rows: 2 }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col class="eqs-form-field">
                <EqsCheckbox
                    :value.sync="formData.required"
                    :is-required="false"
                    :text="$t('addDocTable.dialog.required')"
                    v-bind="{ elClass: 'required', loading, editable }"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
export default {
    name: "Form",
    mixins: [formMixin]
};
</script>
