<template>
    <EqsCard
        class="fill-height"
        v-bind="{
            loading: loading || loadingBlockchainState,
            height: '100%'
        }"
    >
        <template #main>
            <EqsStepper
                style="height: 100%"
                :value="step"
                v-bind="{
                    steps,
                    progress
                }"
            >
                <template #default="{ item }">
                    <div class="eqs-main-container margin-auto px-2">
                        <EqsTextDisplay
                            v-if="item.text"
                            :value="item.text"
                            class="text-center pb-5"
                        />

                        <component
                            :is="item.is"
                            class="fill-height fill-width"
                            v-bind="{
                                item,
                                formData,
                                schemaDouble,
                                txComplete: true,
                                companyBlockchainSetted,
                                loading: loadingBlockchain || !!tx.hash
                            }"
                        />
                        <v-slide-y-transition hide-on-leave>
                            <EqsAlert
                                v-if="companyBlockchainSetted && loadingBlockchain"
                                class="mt-5"
                            >
                                <div class="paragraph">
                                    {{
                                        $t(
                                            "shareledger.dialog.stepper.blockchainize-your-company.loading-message"
                                        )
                                    }}
                                </div>
                            </EqsAlert>
                        </v-slide-y-transition>
                        <v-slide-y-transition hide-on-leave>
                            <GlobalBlockchainLoader
                                v-if="companyBlockchainSetted && loadingBlockchain"
                                v-bind="{ height: '42vh' }"
                            />
                        </v-slide-y-transition>
                        <v-slide-y-transition hide-on-leave>
                            <EqsAlert v-if="contractWithError" eqs-type="error" class="mt-5">
                                <div class="paragraph">
                                    {{
                                        $t(
                                            "shareledger.dialog.stepper.blockchainize-your-company.error-message",
                                            { universeMail }
                                        )
                                    }}
                                </div>
                            </EqsAlert>
                        </v-slide-y-transition>
                        <v-input
                            v-if="contractWithError"
                            v-bind="{ errorMessages: ['dummy'] }"
                            style="display: none"
                        />
                    </div>
                </template>
            </EqsStepper>
        </template>
    </EqsCard>
</template>

<script>
import DigitalizeYourCompany from "./DigitalizeYourCompany.vue";
import { progressMixin, buildFormDataMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "Digitalize",
    components: {
        DigitalizeYourCompany,
        GlobalBlockchainLoader: () =>
            import("@/components/global/globalBlockchainLoader/GlobalBlockchainLoader")
    },
    mixins: [progressMixin, buildFormDataMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            step: 0,
            formData: {},
            schemaDouble: {},
            loadingBlockchain: false,
            tx: {
                isCompleted: false,
                hash: ""
            },
            loadingBlockchainState: true,
            steps: [
                {
                    title: this.$t("shareledger.dialog.stepper.blockchainize-your-company.title"),
                    noBack: true,
                    next: {
                        fn: this.digitalizeYourCompany,
                        txt: this.$t("shareledger.digitalize.digitalize-btn")
                    },
                    item: {
                        is: "DigitalizeYourCompany",
                        step: "ISSUING_ENTITY",
                        text: this.$t("shareledger.dialog.stepper.blockchainize-your-company.txt")
                    },
                    schemaDouble: {
                        blockchain: {
                            isRequired: true
                        }
                    }
                }
            ]
        };
    },
    computed: {
        universeMail() {
            return this.$store.getters.universe.contact_email;
        },
        currStep() {
            return this.steps[this.step];
        },
        companyBlockchainSetted() {
            return !!this.$entity.represented().blockchain;
        },
        contractWithError() {
            if (
                this.$blockchain.blockchainizeState().issuingentity &&
                this.$blockchain.blockchainizeState().issuingentity.error
            ) {
                return this.$blockchain.blockchainizeState().issuingentity;
            }
            return false;
        }
    },
    watch: {
        currStep: {
            immediate: true,
            async handler(val) {
                this.schemaDouble = val.schemaDouble;
                this.buildFormData(this.schemaDouble);
            }
        }
    },
    async created() {
        // be sure that FSM on entity is fetched
        this.loadingBlockchainState = true;
        await this.$role.updateMyCurrentRole(this.$entity.represented().id);
        this.loadingBlockchainState = false;
        if (this.companyBlockchainSetted)
            this.$set(this.formData, "blockchain", this.$entity.represented().blockchain);
        this.loadingBlockchain = true;
        await this.$blockchain.pollBlockchainizeState_v2(false, true);
        this.loadingBlockchain = false;
    },
    async beforeDestroy() {
        await this.$blockchain.pollBlockchainizeState_v2(true);
    },
    methods: {
        async digitalizeYourCompany() {
            try {
                this.loadingBlockchain = true;
                // some entities will have already their blockchain setted
                if (!this.companyBlockchainSetted)
                    await this.$entity.updateEntity({
                        blockchain_id: this.formData.blockchain.id
                    });

                const res = await this.$blockchain.deployIssuingEntity();
                if (res.status !== 200) {
                    // TODO
                }

                await this.$blockchain.pollBlockchainizeState_v2();
                await this.$role.updateMyCurrentRole(this.$entity.represented().id);

                //change popup to digitalize popup
                this.$dialog.info({
                    title: this.$t("shareledger.digitalize.complete-dialog.title"),
                    text: this.$t("shareledger.digitalize.complete-dialog.text", {
                        companyName: this.$entity.represented().name
                    }),
                    cancel: {
                        txt: this.$t("shareledger.digitalize.complete-dialog.profile-btn"),
                        fn: () =>
                            this.$router.push({
                                name: "profile",
                                params: { id: this.$entity.represented().id }
                            })
                    },
                    confirm: {
                        txt: this.$t(
                            "shareledger.digitalize.complete-dialog.digitalize-shareledger-btn"
                        ),
                        fn: () =>
                            this.$router.push({
                                name: "digitalize-shareledger",
                                params: {
                                    companyId: this.$entity.represented().id
                                }
                            })
                    }
                });
            } catch (e) {
                if (e.status === 403)
                    this.$dialog.confirm({
                        title: this.$t(
                            "shareledger.dialog.stepper.blockchainize.not-allowed.dialog-title"
                        ),
                        text: this.$t(
                            "shareledger.dialog.stepper.blockchainize.not-allowed.dialog-txt",
                            {
                                name: this.$entity.myEntity().name,
                                company: this.$entity.represented().name,
                                universeMail
                            }
                        ),
                        confirm: {
                            fn: () =>
                                this.$router.push({
                                    name: "profile",
                                    params: {
                                        id: this.$entity.represented().id
                                    }
                                }),
                            txt: this.$t("btn.continue")
                        }
                    });

                if (e.status === 500 && e.data instanceof Array && e.data[0] === "Not enough Tezis")
                    this.$ui.error(
                        "Not enough Tezis on provisionment account, please contact Equisafe"
                    );
                else this.$ui.error(e, "company_blockchainize");
            } finally {
                this.loadingBlockchain = false;
            }
        }
    }
};
</script>

<!--todo: removed css, double check here-->
