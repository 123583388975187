var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column fill-height"},[_c('v-row',{staticClass:"flex-grow-0 pa-6",style:(`background-image: url('${_vm.cover}'); background-size: cover`)},[_c('v-col',{attrs:{"cols":2,"offset":1}},[_c('EqsAvatar',_vm._b({attrs:{"eqs-type":"xl","src":_vm.avatar,"no-badge":"","user":{ name: _vm.companyName, is_company: true }}},'EqsAvatar',{ loading: _vm.loading },false))],1)],1),_c('v-row',{staticClass:"align-self-stretch",attrs:{"no-gutters":""}},[_c('EqsCard',{staticClass:"d-flex flex-column fill-height",attrs:{"height":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":7}},[_c('Header',_vm._b({},'Header',{
                            companyName: _vm.companyName,
                            tags: _vm.tags,
                            stats: _vm.stats,
                            description: _vm.description,
                            executives: _vm.executives,
                            loading: _vm.loading
                        },false)),_c('v-container',[_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":""}},[_c('EqsTextDisplay',{attrs:{"value":"Revenus trimestriel","eqs-type":"subtitle"}})],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":6}},[_c('EqsCard',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('EqsTextDisplay',_vm._b({staticClass:"pa-3",attrs:{"value":`LTM revenus: ${_vm.$currency.displayWithCurrency(
                                                _vm.ltmRevenues
                                            )}`,"eqs-type":"subtitle"}},'EqsTextDisplay',{ loading: _vm.loading },false))]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":6}},[_c('EqsCard',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('EqsTextDisplay',_vm._b({staticClass:"pa-3",attrs:{"value":`Revenus annuel: ${_vm.$currency.displayWithCurrency(
                                                _vm.yearlyRevenues
                                            )}`,"eqs-type":"subtitle"}},'EqsTextDisplay',{ loading: _vm.loading },false))]},proxy:true}])})],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":6}},[_c('EqsBtn',_vm._b({attrs:{"block":"","color":"primary"}},'EqsBtn',{ loading: _vm.loading },false),[_vm._v(" Accèder à la DataRoom ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":4}},[_c('v-row',[_c('v-col',{attrs:{"cols":10}},[_c('GeneralInfo',_vm._b({},'GeneralInfo',{
                                    incorporationDate: _vm.incorporationDate,
                                    location: _vm.location,
                                    siren: _vm.siren,
                                    legalForm: _vm.legalForm,
                                    email: _vm.email,
                                    headquarters: _vm.headquarters,
                                    tables: _vm.tables,
                                    loading: _vm.loading
                                },false))],1)],1),_c('div',{staticClass:"pt-6"},[_c('FinancingHistory',_vm._b({attrs:{"pie":_vm.financingHistoryPie,"table":_vm.financingHistoryTable}},'FinancingHistory',{ loading: _vm.loading },false))],1),_c('div',{staticClass:"pt-6"},[_c('CapTable',_vm._b({attrs:{"items":_vm.captable}},'CapTable',{ loading: _vm.loading },false))],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }