<template>
    <EqsLabel
        class="global-text-editor"
        v-bind="{
            ...$attrs,
            eqsSize,
            isRequired,
            height: labelHeight,
            editable,
            value: label,
            infoTxt
        }"
        :style="loading ? 'width:auto;' : ''"
    >
        <EqsSkeleton class="global-text-editor__container" type="article" v-bind="{ loading }">
            <!-- <div class="global-text-editor"> -->
            <v-input
                v-bind="{
                    ...$attrs,
                    value,
                    rules: rulesRequired
                }"
                dense
                hide-details="auto"
            >
                <v-card
                    class="global-text-editor__card fill-height"
                    :elevation="0"
                    :loading="loading || !editor"
                >
                    <InternalToolbar v-if="editor" v-bind="{ editor, actions }">
                        <template #actions>
                            <slot name="actions" v-bind="{ editor }" />
                        </template>
                    </InternalToolbar>
                    <editor-content
                        :class="`global-text-editor__card__content ${elClass}`"
                        v-bind="{ editor }"
                        v-on="$listeners"
                    />
                </v-card>
            </v-input>
            <InternalImageSelector v-if="editor" :value.sync="dialogImg" v-bind="{ editor }" />
        </EqsSkeleton>
    </EqsLabel>
</template>

<script>
import { inputFormMixin } from "@equisafe-ui-vue/src/mixins/inputMixin";

import { Editor, EditorContent } from "@tiptap/vue-2";

import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import TextAlign from "@tiptap/extension-text-align";

import InternalToolbar from "./_internal/InternalToolbar";
import InternalImageSelector from "./_internal/InternalImageSelector";

export default {
    name: "GlobalTextEditor",
    components: {
        EditorContent,
        InternalToolbar,
        InternalImageSelector
    },
    mixins: [inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        populateEditor: {
            type: Function,
            default: null
        },
        otherExtensions: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editor: null,
            dialogImg: false,
            emptyHtml: "<p></p>",
            extensions: [
                StarterKit,
                Image,
                Underline,
                Link,
                TextAlign.configure({
                    types: ["heading", "paragraph"]
                })
            ],
            actions: [
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.bold"),
                    icon: "mdi-format-bold",
                    action: editor => editor.chain().focus().toggleBold().run(),
                    active: {
                        key: "bold"
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.italic"),
                    icon: "mdi-format-italic",
                    action: editor => editor.chain().focus().toggleItalic().run(),
                    active: {
                        key: "italic"
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.underline"),
                    icon: "mdi-format-underline",
                    action: editor => editor.chain().focus().toggleUnderline().run(),
                    active: {
                        key: "underline"
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.paragraph"),
                    icon: "mdi-format-paragraph",
                    action: editor => editor.chain().focus().setParagraph().run(),
                    active: {
                        key: "paragraph"
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.h1"),
                    icon: "mdi-format-header-1",
                    action: editor => editor.chain().focus().toggleHeading({ level: 1 }).run(),
                    active: {
                        key: "heading",
                        props: {
                            level: 1
                        }
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.h2"),
                    icon: "mdi-format-header-2",
                    action: editor => editor.chain().focus().toggleHeading({ level: 2 }).run(),
                    active: {
                        key: "heading",
                        props: {
                            level: 2
                        }
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.h3"),
                    icon: "mdi-format-header-3",
                    action: editor => editor.chain().focus().toggleHeading({ level: 3 }).run(),
                    active: {
                        key: "heading",
                        props: {
                            level: 3
                        }
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.h4"),
                    icon: "mdi-format-header-4",
                    action: editor => editor.chain().focus().toggleHeading({ level: 4 }).run(),
                    active: {
                        key: "heading",
                        props: {
                            level: 4
                        }
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.h5"),
                    icon: "mdi-format-header-5",
                    action: editor => editor.chain().focus().toggleHeading({ level: 5 }).run(),
                    active: {
                        key: "heading",
                        props: {
                            level: 5
                        }
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.h6"),
                    icon: "mdi-format-header-6",
                    action: editor => editor.chain().focus().toggleHeading({ level: 6 }).run(),
                    active: {
                        key: "heading",
                        props: {
                            level: 6
                        }
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.alignLeft"),
                    icon: "mdi-format-align-left",
                    action: editor => editor.chain().focus().setTextAlign("left").run(),
                    active: {
                        props: { textAlign: "left" }
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.alignCenter"),
                    icon: "mdi-format-align-center",
                    action: editor => editor.chain().focus().setTextAlign("center").run(),
                    active: {
                        props: { textAlign: "center" }
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.alignRight"),
                    icon: "mdi-format-align-right",
                    action: editor => editor.chain().focus().setTextAlign("right").run(),
                    active: {
                        props: { textAlign: "right" }
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.alignJustify"),
                    icon: "mdi-format-align-justify",
                    action: editor => editor.chain().focus().setTextAlign("justify").run(),
                    active: {
                        props: { textAlign: "justify" }
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.bulletlist"),
                    icon: "mdi-format-list-bulleted",
                    action: editor => editor.chain().focus().toggleBulletList().run(),
                    active: {
                        key: "bulletList"
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.orderedlist"),
                    icon: "mdi-format-list-numbered",
                    action: editor => editor.chain().focus().toggleOrderedList().run(),
                    active: {
                        key: "orderedList"
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.blockquote"),
                    icon: "mdi-format-quote-close",
                    action: editor => editor.chain().focus().toggleBlockquote().run(),
                    active: {
                        key: "blockquote"
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.link"),
                    icon: "mdi-link",
                    action: () => this.toggleLink(),
                    active: {
                        key: "link"
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.image"),
                    icon: "mdi-image",
                    action: () => {
                        this.dialogImg = true;
                    },
                    active: {
                        key: "image"
                    }
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.HorizontalRule"),
                    icon: "mdi-minus",
                    action: editor => editor.chain().focus().setHorizontalRule().run()
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.undo"),
                    icon: "mdi-undo",
                    action: editor => editor.chain().focus().undo().run()
                },
                {
                    tooltip: this.$t("equisafe-ui.text-editor.actions.redo"),
                    icon: "mdi-redo",
                    action: editor => editor.chain().focus().redo().run()
                }
            ]
        };
    },

    watch: {
        value(value) {
            const isSame = this.editor.getHTML() === value;
            if (isSame) {
                return;
            }
            this.editor.commands.setContent(this.value, false);
        },
        otherExtensions: {
            immediate: true,
            handler(val) {
                this.loadEditor(val);
            }
        }
    },

    beforeDestroy() {
        this.editor.destroy();
    },

    methods: {
        loadEditor(otherExtensions) {
            this.editor = new Editor({
                extensions: [...this.extensions, ...otherExtensions],
                content: this.value,
                onUpdate: () => {
                    // HTML
                    const value = this.editor.getHTML();
                    this.$emit("update:value", !this.isEmptyHtml(value) ? value : "");
                }
            });
            if (this.populateEditor) this.populateEditor(this.editor);
        },
        isEmptyHtml(value) {
            const len = value.length;
            let i = 0;
            for (i; i < len; i++) {
                const c = value.charAt(i);
                if (c === ">" && value.charAt(i + 1) !== "<") break;
            }
            return !i || i === len - 1;
        },
        toggleLink() {
            if (!this.editor.isActive("link")) {
                const url = window.prompt("URL");
                this.editor.chain().focus().setLink({ href: url }).run();
            } else {
                this.editor.chain().focus().unsetLink().run();
            }
        }
    }
};
</script>

<style lang="scss">
@import "./proseMirror.scss";
.global-text-editor {
    width: fit-content;
    height: 100%;
    &__card {
        width: 21cm;
        height: 100%;
        display: flex !important;
        margin: 0;
        flex-direction: column !important;
        &__content {
            overflow-y: auto;
            flex: 1;
            height: inherit;
        }
    }
}
.global-text-editor .v-input {
    height: 100% !important;
}
.global-text-editor .v-input__control {
    height: 100% !important;
}
.global-text-editor .v-input__slot {
    height: inherit;
}
</style>
