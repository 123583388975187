var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',[_c('EqsNumberShares',{attrs:{"label":_vm.$t('sellBuy.label.available-actions'),"value":_vm.getAvailableShares(),"is-required":false,"editable":false}}),_c('EqsNumberShares',{attrs:{"label":_vm.$t('sellBuy.label.sequestre-actions'),"value":_vm.getSequestreShares(),"is-required":false,"editable":false}}),_c('EqsNumber',{attrs:{"label":_vm.$t('sellBuy.label.market-value'),"value":_vm.data.assets[0].venalValue || _vm.data.assets[0].value,"is-required":false,"editable":false,"currency-type":_vm.getCurrency()}}),(_vm.shareledgerWithShareTransfer.length)?_c('EqsBtn',_vm._b({attrs:{"color":"primary"}},'EqsBtn',{
                elClass: 'sell-shares',
                action: () =>
                    _vm.$router.push({
                        name: 'sell-shares',
                        params: {
                            companyId: _vm.data.id,
                            shareledgers: _vm.shareledgerWithShareTransfer,
                            companyData: _vm.data
                        }
                    })
            },false),[_vm._v(" "+_vm._s(_vm.$t("sellBuy.label.sell-my-shares"))+" ")]):_c('EqsTextDisplay',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("portfolio.sell-shares.no-shareledger-sellable"))+" ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }