var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"mb-8"},[_c('div',[_c('v-chip',{attrs:{"color":"purple darken-1","text-color":"white","label":""}},[_vm._v(" "+_vm._s(_vm.$t("documents.category.ifu-full"))+" ")])],1),_c('EqsFileInput',_vm._b({attrs:{"multiple":""}},'EqsFileInput',{
                editable: false,
                value: _vm.docsIfu,
                loading: _vm.localLoading,
                label: null
            },false))],1),_vm._l((_vm.capincs),function(capinc,index){return _c('div',{key:index,staticClass:"mb-8"},[_c('div',[_c('v-chip',{attrs:{"color":"teal darken-1","text-color":"white","label":""}},[_vm._v(" "+_vm._s(_vm.$t("portfolio.label-project"))+" - "+_vm._s(capinc.name)+" ")])],1),_vm._l((capinc.orders),function(order,indexOrder){return _c('EqsFileInput',_vm._b({key:indexOrder,attrs:{"multiple":""}},'EqsFileInput',{
                editable: false,
                value: order.docs,
                loading: _vm.localLoading,
                label: `${_vm.$t('portfolio.label-order')} ${order.id}`
            },false))})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }