<template>
    <div class="d-flex flex-column align-center mt-4 max-height-fit">
        <v-slide-y-transition hide-on-leave>
            <EqsRadio
                v-if="!companyBlockchainSetted"
                class="pl-4"
                :value.sync="formData.blockchain"
                v-bind="{
                    label: $t('registration.kyc.company.chose'),
                    options: blockchains,
                    loading: loading || localLoading,
                    disabled: companyBlockchainSetted,
                    elClass: 'blockchain-type'
                }"
            >
                <template #label="{ item }">
                    <div class="d-flex">
                        <EqsAvatar class="mr-3" v-bind="{ src: item.icon }" />
                        <v-list-item-title>
                            <EqsTextDisplay>
                                {{ capitalize(item.txt) }}
                            </EqsTextDisplay>
                        </v-list-item-title>
                    </div>
                </template>
            </EqsRadio>
        </v-slide-y-transition>
        <v-slide-y-transition hide-on-leave>
            <EqsLabel
                v-if="companyBlockchainSetted && formData.blockchain"
                v-bind="{ value: $t('registration.kyc.company.chosen') }"
            >
                <div class="d-flex">
                    <EqsAvatar
                        class="mr-3"
                        v-bind="{ src: getBlockchainIcon(formData.blockchain) }"
                    />
                    <EqsTextDisplay
                        :value="capitalize(formData.blockchain.name)"
                        eqs-type="subtitle"
                        class="my-3"
                    />
                </div>
            </EqsLabel>
        </v-slide-y-transition>
    </div>
</template>

<script>
import capitalize from "lodash/capitalize";
export default {
    name: "PutCompanyBlockchain",
    props: {
        formData: {
            type: Object,
            required: true
        },
        companyBlockchainSetted: {
            type: Boolean,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            localLoading: true,
            blockchains: [],
            capitalize
        };
    },
    async beforeMount() {
        await this.getBlockchains();
    },
    methods: {
        getBlockchainIcon(bc) {
            let icon = "";
            if (bc.name.includes("tz") || bc.name.includes("tezos")) {
                icon = "/icons/tezos_icon.png";
            } else if (bc.name.includes("eth")) {
                icon = "/icons/ethereum_icon.png";
            } else {
                icon = "/images/equisafe-logo-blue.svg";
            }
            return icon;
        },
        async getBlockchains() {
            try {
                this.localLoading = true;
                const res = await this.$api.getBlockchainList();
                this.blockchains = res.data.map(v => {
                    return {
                        value: v,
                        txt: v.name,
                        icon: this.getBlockchainIcon(v)
                    };
                });
            } catch (e) {
                this.$ui.error(e, "blockchain_list_get");
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>
