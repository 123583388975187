<template>
    <EqsStepper
        :value="0"
        :steps="[
            { title: 'test signature template', item: {}, ...stepProps },
            { title: 'test signature template 2', item: {}, ...stepProps }
        ]"
        :eqs-type="['default', 'no-overflow']"
        eqs-data-type="default"
        color="transparent"
    >
        <div class="d-flex fill-height fill-width">
            <GlobalSignatureTemplate
                :value.sync="formData.proceduredocument_set"
                v-bind="{ signature_procedure, loading: loading || proceduresLoading }"
                class="mx-3 fill-height"
            >
                <template #form>
                    <v-col class="eqs-form-field">
                        <EqsSelect
                            label="select the procedure"
                            :value.sync="signature_procedure"
                            return-object
                            v-bind="{ items: procedures, itemText: 'name' }"
                        />
                    </v-col>
                </template>
            </GlobalSignatureTemplate>
        </div>
    </EqsStepper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { procedureEditMixin } from "@/mixins/yousignMixin";
export default {
    name: "TestSignatureTemplate",
    mixins: [procedureEditMixin],
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            signature_procedure: {},
            formData: {
                proceduredocument_set: []
            }
        };
    },
    watch: {
        procedures: {
            immediate: true,
            handler(val) {
                if (val && val.length) this.signature_procedure = val[0];
            }
        }
    },
    computed: {
        ...mapGetters(["procedures", "proceduresLoading"]),
        stepProps() {
            let res = { next: { fn: this.createProcedureDocs } };
            return res;
        }
    },
    async created() {
        this.getProcedures();
    },
    methods: {
        ...mapActions(["getProcedures"])
    }
};
</script>

<style lang="scss" scoped></style>
