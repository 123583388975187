import CreateCompany from "@/components/views/user/company/CreateCompany.vue";
import Registration from "@/components/views/registration/_Registration.vue";
import VerifyKyc from "@/components/views/registration/kycTest/VerifyKyc.vue";
import { i18n } from "@/services/plugins/i18n.js";

import multiguard from "vue-router-multiguard";
import {
    requiresNotRegistered,
    requiresIndividual,
    requiresCompanyNotSetup,
    requiresCompany,
    requiresWalletCreated
} from "../routeGuards";

export default [
    {
        path: "/create-company",
        name: "create-company",
        beforeEnter: multiguard([requiresIndividual]),
        meta: {
            context: i18n.t("routes.actions.add-company")
        },
        component: CreateCompany
    },
    {
        path: "/setup-company",
        beforeEnter: multiguard([requiresCompany, requiresCompanyNotSetup]),
        name: "setup-company",
        meta: {
            context: i18n.t("routes.actions.set-company")
        },
        component: CreateCompany
    },
    {
        path: "/registration/:id",
        component: Registration,
        name: "registration",
        // beforeEnter: multiguard([requiresNotRegistered]),
        props: true,
        meta: {
            context: i18n.t("routes.registration.title")
        },
        children: [
            {
                beforeEnter: multiguard([requiresWalletCreated]),
                path: "verify",
                name: "verify",
                meta: {
                    context: i18n.t("routes.registration.verify-title")
                },
                component: VerifyKyc
            }
        ]
    }
];
