<template>
    <div class="d-flex flex-column">
        <v-row class="fill-height px-3">
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.first_name"
                    v-bind="{
                        elClass: 'first_name',
                        label: this.$t('registration.first-name'),
                        editable,
                        loading,
                        ...schemaDouble.first_name
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.last_name"
                    v-bind="{
                        elClass: 'last_name',
                        label: this.$t('registration.last-name'),
                        editable,
                        loading,
                        ...schemaDouble.last_name
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="fill-height px-3">
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsEmail
                    :value.sync="formData.email"
                    v-bind="{
                        elClass: 'email',
                        isRequired: false,
                        label: $t('registration.email'),
                        editable,
                        loading,
                        rules: [uniqueEmailR],
                        ...schemaDouble.email
                    }"
                />
            </v-col>
        </v-row>
        <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
            <EqsAutocomplete
                :value.sync="formData.nationality"
                v-bind="{
                    items: countries,
                    placeholder: 'ex: France',
                    itemText: 'name',
                    itemValue: 'url',
                    label: $t('registration.nationality'),
                    'el-class': 'nationality',
                    loading: loading || countriesLoading,
                    ...schemaDouble.nationality
                }"
            />
        </v-col>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { mapActions, mapGetters } from "vuex";
export default {
    name: "IndividualInviteForm",
    mixins: [formMixin],
    props: {
        uniqueEmailR: {
            type: Function,
            required: true
        }
    },
    created() {
        this.getCountries();
    },
    methods: mapActions(["getCountries"]),
    computed: mapGetters(["countries", "countriesLoading"])
};
</script>
