<template>
    <div class="d-flex flex-column">
        <v-row class="" dense>
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsText
                    :label="$t('molecule.list.label-name-last-name')"
                    :rules="[protectedCharR]"
                    :value.sync="formData.name"
                    el-class="new-person-name"
                    v-bind="{ loading, editable }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsText
                    :label="$t('label.role')"
                    :rules="[protectedCharR]"
                    :value.sync="formData.role"
                    el-class="new-person-role"
                    v-bind="{ loading, editable }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsUrl
                    :label="$t('label.linkedin')"
                    :value.sync="formData.linkedin"
                    prepend-url="www.linkedin.com/"
                    el-class="new-person-linkedin"
                    v-bind="{ loading, editable, isRequired: false }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsAvatarUpload
                    :value.sync="formData.photo"
                    el-class="new-person-avatar-upload"
                    v-bind="{ loading, editable }"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { protectedCharR } from "@equisafe-ui-vue/src/rules";

export default {
    name: "InternalPeopleForm",
    props: {
        formData: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            protectedCharR
        };
    }
};
</script>
