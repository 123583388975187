import { i18n } from "@/services/plugins/i18n.js";

export const sepa = ctx => {
    let res = [
        {
            titleAbove: ctx.title,
            name: "doc_bank",
            class: "pb-4 pt-2",
            extensions: ["pdf"],
            "el-class": "doc-bank",
            label: ctx.$t("registration.rib"),
            is: "EqsUpload"
        },
        {
            label: ctx.$t("user.wallet.bank.holder"),
            name: "holder",
            placeholder: ctx.$t("user.wallet.bank.holder-placeholder"),
            "el-class": "bank-holder",
            is: "EqsText"
        },
        {
            label: ctx.$t("user.wallet.bank.iban.label"),
            name: "iban",
            placeholder: ctx.$t("user.wallet.bank.iban-placeholder"),
            "el-class": "bank-iban",
            is: "EqsText"
        },
        {
            label: ctx.$t("user.wallet.bank.bic"),
            name: "bic",
            placeholder: ctx.$t("user.wallet.bank.bic-placeholder"),
            "el-class": "bank-bic",
            is: "EqsText"
        },
        {
            titleAbove: !ctx.display ? ctx.$t("user.wallet.bank.optional-info") : "",
            label: ctx.$t("user.wallet.bank.dom1"),
            name: "dom1",
            isRequired: false,
            placeholder: ctx.$t("user.wallet.bank.dom1-placeholder"),
            "el-class": "bank-dom1",
            is: "EqsText"
        },
        {
            label: ctx.$t("user.wallet.bank.dom2"),
            name: "dom2",
            isRequired: false,
            placeholder: ctx.$t("user.wallet.bank.dom2-placeholder"),
            "el-class": "bank-dom2",
            is: "EqsText"
        }
    ];
    return res;
};

export const sepaDouble = {
    doc_bank: {
        dataType: "file",
        isRequired: true
    },
    holder: {
        isRequired: true
    },
    iban: {
        isRequired: true
    },
    bic: {
        isRequired: true
    },
    dom1: {
        isRequired: false
    },
    dom2: {
        isRequired: false
    }
};

export const internationalDouble = {
    type: {
        isRequired: true
    },
    doc_bank: {
        dataType: "file",
        isRequired: true
    },
    holder: {
        isRequired: true
    },
    iban: {
        isRequired: true
    },
    holder_country: {
        dataType: "url",
        isRequired: true
    },
    bank_country: {
        dataType: "url",
        isRequired: true
    },
    bic: {
        isRequired: true
    },
    name: {
        isRequired: false
    }
};

export const international = ctx => {
    let res = [
        {
            titleAbove: ctx.title,
            name: "doc_bank",
            class: "pb-4 pt-2",
            extensions: ["pdf"],
            "el-class": "doc-bank",
            label: ctx.$t("registration.rib"),
            is: "EqsUpload"
        },
        {
            label: ctx.$t("user.wallet.bank.holder"),
            name: "holder",
            placeholder: ctx.$t("user.wallet.bank.holder-placeholder"),
            "el-class": "bank-holder",
            is: "EqsText"
        },
        {
            label: ctx.$t("label.account-type"),
            items: [
                { name: "IBAN", value: "1" },
                { name: "BBAN/RIB", value: "2" },
                { name: ctx.$t("label.other"), value: "0" }
            ],
            "item-text": "name",
            "item-value": "value",
            displayFindObj: true,
            name: "type",
            placeholder: "Ex: IBAN",
            "el-class": "account_type",
            is: "EqsSelect"
        },
        {
            label: ctx.$t("user.wallet.bank.account-number"),
            name: "iban",
            placeholder: ctx.$t("user.wallet.bank.account-number-placeholder"),
            "el-class": "bank-account-number",
            is: "EqsText"
        },
        {
            label: ctx.$t("user.wallet.bank.holder-country"),
            name: "holder_country",
            placeholder: ctx.$t("user.wallet.bank.holder-country-placeholder"),
            items: ctx.countries,
            "item-text": "name",
            "item-value": "url",
            displayFindObj: true,
            "el-class": "holder_country",
            is: "EqsAutocomplete"
        },
        {
            label: ctx.$t("user.wallet.bank.bank-country"),
            name: "bank_country",
            placeholder: ctx.$t("user.wallet.bank.bank-country-placeholder"),
            items: ctx.countries.filter(country => !country.is_sepa),
            "item-text": "name",
            "item-value": "url",
            displayFindObj: true,
            "el-class": "bank_country",
            is: "EqsAutocomplete"
        },
        {
            label: ctx.$t("user.wallet.bank.bic"),
            name: "bic",
            placeholder: ctx.$t("user.wallet.bank.bic-placeholder"),
            "el-class": "bank-bic",
            is: "EqsText"
        },
        {
            titleAbove: !ctx.display ? ctx.$t("user.wallet.bank.optional-info") : "",
            label: ctx.$t("user.wallet.bank.bank-name"),
            name: "dom1",
            isRequired: false,
            placeholder: ctx.$t("user.wallet.bank.bank-name-placeholder"),
            "el-class": "bank-name",
            is: "EqsText"
        }
    ];
    return res;
};

export const states = ctx => {
    return {
        no_account: {
            id: "no_account",
            title: ctx.$t("user.wallet.bank.no_account.title")
        },
        no_account_pending: {
            id: "no_account_pending",
            message: ctx.$t("user.wallet.bank.no_account_pending.message"),
            title: ctx.$t("user.wallet.bank.no_account.title")
        },
        no_account_success: {
            id: "no_account_success",
            title: ctx.$t("user.wallet.bank.no_account.title"),
            dialog: {
                title: ctx.$t("user.wallet.bank.no_account_success.dialog.title"),
                message: ctx.$t("user.wallet.bank.no_account_success.dialog.message")
            }
        },
        new_account: {
            id: "new_account",
            title: ctx.$t("user.wallet.bank.new_account.title")
        },
        new_account_pending: {
            id: "new_account_pending",
            message: ctx.$t("user.wallet.bank.new_account_pending.message"),
            title: ctx.$t("user.wallet.bank.new_account.title")
        },
        new_account_success: {
            id: "new_account_success",
            title: ctx.$t("user.wallet.bank.new_account.title"),
            dialog: {
                title: ctx.$t("user.wallet.bank.new_account_success.dialog.title"),
                message: ctx.$t("user.wallet.bank.new_account_success.dialog.message")
            }
        }
    };
};

export const getState = ctx => {
    let state = ctx.id === "init" ? "no_account" : "new_account";
    // if (!ctx.formData.is_sepa)
    // state = "new_account"
    if (ctx.loadingUpdateIban) state = `${state}_pending`;
    else if (ctx.success) state = `${state}_success`;
    return states(ctx)[state];
};

export const ibanStates = {
    pending: {
        id: "pending",
        color: "info",
        txt: i18n.t("user.wallet.bank.iban.pending")
    },
    error: {
        id: "error",
        color: "error",
        txt: i18n.t("user.wallet.bank.iban.error")
    },
    validated: {
        id: "success",
        color: "success",
        txt: i18n.t("user.wallet.bank.iban.success")
    }
};

// LEMONWAY_STATUTES = (
//         (1, 'None'),
//         (2, 'Internal'),
//         (3, 'Not used'),
//         (4, 'Waiting to be verified'),
//         (5, 'Activated'),
//         (6, 'Rejected by the bank'),
//         (7, 'Rejected, no owner'),
//         (8, 'Deactivated'),
//         (9, 'Rejected')
//     )

export const getIbanStatus = iban => {
    if (!iban) return null;
    let status = "error";
    if (iban.lemonway_status === 5) status = "validated";
    else if (iban.lemonway_status === 4) status = "pending";
    return ibanStates[status];
};
