<template>
    <v-container fluid>
        <div class="mb-8">
            <div>
                <v-chip color="purple darken-1" text-color="white" label>
                    {{ $t("documents.category.ifu-full") }}
                </v-chip>
            </div>
            <EqsFileInput
                multiple
                v-bind="{
                    editable: false,
                    value: docsIfu,
                    loading: localLoading,
                    label: null
                }"
            />
        </div>
        <div v-for="(capinc, index) in capincs" :key="index" class="mb-8">
            <div>
                <v-chip color="teal darken-1" text-color="white" label>
                    {{ $t("portfolio.label-project") }} - {{ capinc.name }}
                </v-chip>
            </div>
            <EqsFileInput
                v-for="(order, indexOrder) in capinc.orders"
                :key="indexOrder"
                multiple
                v-bind="{
                    editable: false,
                    value: order.docs,
                    loading: localLoading,
                    label: `${$t('portfolio.label-order')} ${order.id}`
                }"
            />
        </div>
    </v-container>
</template>

<script>
import { getLabelFromCategory } from "@/util/documents";

export default {
    name: "PortfolioDataroom",
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            localLoading: false,
            capincs: [],
            docsIfu: []
        };
    },
    beforeMount() {
        this.fetchDocs();
    },
    methods: {
        async fetchDocs() {
            this.localLoading = true;
            const queries = {
                company_id: this.company.id
            };
            try {
                const res = await this.$api.request(
                    "get",
                    `api/dataroom/docs_from_company/`,
                    queries
                );
                const data = res.data;

                if (data?.ifus) {
                    this.docsIfu = data.ifus.map(ifu => {
                        return {
                            ...ifu.doc,
                            name: `${this.$t("portfolio.label-year")} ${ifu.year}`
                        };
                    });
                }

                let capincs = {};
                if (data?.orders) {
                    data.orders.forEach(order => {
                        let docs = [
                            order.investment_certificate,
                            order.invoice,
                            order.peapme_certificate,
                            order.ir_certificate
                        ].filter(e => e); // On enlève les null
                        // On rajoute les BS signés
                        const docs_set = order?.signed_docs?.proceduredocument_set;
                        if (docs_set) {
                            docs_set.forEach(doc => {
                                if (doc?.signed_document) {
                                    docs.push(doc.signed_document);
                                }
                            });
                        }
                        // On met un nom en fonction de la catégorie
                        docs = docs.map(doc => {
                            return { ...doc, name: getLabelFromCategory(doc.category) };
                        });
                        const orderToAdd = {
                            id: order.order_id,
                            docs
                        };
                        if (!capincs[order.capinc_name]) {
                            capincs[order.capinc_name] = {
                                name: order.capinc_name,
                                orders: []
                            };
                        }
                        capincs[order.capinc_name].orders.push(orderToAdd);
                    });
                }
                this.capincs = Object.values(capincs);
            } catch (e) {
                console.error("fetchDocs", e);
                this.$ui.error(
                    "Erreur lors de la récupération des documents. Réessayez dans quelques instants."
                );
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
