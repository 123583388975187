var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-main-container margin-auto"},[_c('v-row',{staticClass:"fill-height px-3"},[_c('EqsDialog',_vm._b({attrs:{"persistent":"","value":_vm.dialogProxy,"loading":_vm.loading,"closable":true},on:{"update:value":function($event){_vm.dialogProxy=$event}}},'EqsDialog',{
                title: _vm.title,
                cancel: {},
                confirm: {
                    fn: async () => _vm.sendInvitation()
                }
            },false),[_c('v-col',{attrs:{"cols":12}},[_c('EqsText',{attrs:{"label":_vm.$t('portfolio.company-name'),"value":_vm.formData.company_name},on:{"update:value":function($event){return _vm.$set(_vm.formData, "company_name", $event)}}})],1),_c('v-col',{attrs:{"cols":12}},[_c('EqsEmail',{attrs:{"label":_vm.$t('portfolio.owner-email'),"value":_vm.formData.email},on:{"update:value":function($event){return _vm.$set(_vm.formData, "email", $event)}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }