var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"fill-height",attrs:{"closable":"","title":_vm.entityProxy.name,"avatar":_vm.getAvatar,"is-company":_vm.entityProxy.is_company || !!(_vm.entityProxy.entity && _vm.entityProxy.entity.is_company)},on:{"close":_vm.close}},'EqsCard',{ loading: _vm.loading, elClass: 'entity-details-card', height: '100%' },false),[(!_vm.loading)?_c('EntityDetailsPanels',_vm._b({},'EntityDetailsPanels',{
            entity: _vm.getAllEntityInfos,
            openPanelN: _vm.openPanelN,
            registrationInfos: _vm.registrationInfos,
            procedureDocuments: _vm.procedureDocuments,
            paymentOrderDetails: _vm.paymentOrderDetails,
            proofOfFund: _vm.proofOfFund
        },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }