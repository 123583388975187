import { i18n } from "@/services/plugins/i18n.js";

export const operations = {
    sell_order: {
        text: i18n.t("operations.sell_order")
    },
    buy_order: {
        text: i18n.t("operations.buy_order")
    },
    cession: {
        text: i18n.t("operations.cession")
    }
};

export const types = {
    general_assembly: {
        txt: i18n.t("documents.type.general_assembly")
    },
    capital_increase: {
        txt: i18n.t("documents.type.capital_increase")
    },
    cession: {
        txt: i18n.t("documents.type.cession")
    },
    asset_movement: {
        txt: i18n.t("documents.type.asset_movement")
    },
    payment: {
        txt: i18n.t("documents.type.payment")
    },
    information: {
        txt: i18n.t("documents.type.information")
    },
    other: {
        txt: i18n.t("documents.type.other")
    }
};

export const operationTypes = Object.entries(types).map(entry => {
    const [key, val] = entry;
    return { id: key, ...val };
});

export const movement_operations = {
    cancellation: {
        txt: i18n.t("movements.operation_types.cancellation")
    },
    book_entry: {
        txt: i18n.t("movements.operation_types.book_entry")
    },
    cession: {
        txt: i18n.t("movements.operation_types.cession")
    },
    emission: {
        txt: i18n.t("movements.operation_types.emission")
    },
    increase: {
        txt: i18n.t("movements.operation_types.increase")
    },
    decrease: {
        txt: i18n.t("movements.operation_types.decrease")
    }
};
