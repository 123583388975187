<template>
    <EqsCard v-bind="{ loading, ...$attrs }">
        <EqsDataTable
            :selected.sync="selectedOrganProxy"
            v-bind="{
                headers,
                items: organs.map(organ => {
                    return {
                        ...organ,
                        members: organ.members.filter(member => member.investor),
                        board: organ.members.filter(member => member.representative)
                    };
                }),
                ...$attrs,
                loading: organsLoading || decisionsLoading
            }"
            sort-by="name"
            :sort-desc="[true]"
            :single-select="true"
            @click:row="item => (selectedOrganProxy = [item])"
        >
            <template #top>
                <v-col cols="auto">
                    <EqsTextDisplay
                        :value="$t('governance.organs.title')"
                        v-bind="{ eqsType: 'section' }"
                    />
                </v-col>
            </template>
            <template #item.members="{ value }">
                <EqsMultiAvatars
                    v-bind="{
                        loading,
                        users: value.map(mb => mb.investor.entity)
                    }"
                />
            </template>
            <template #item.board="{ value }">
                <EqsMultiAvatars
                    v-bind="{
                        loading,
                        users: value.map(mb => {
                            const entity = mb.representative.investor.entity;
                            return {
                                ...entity,
                                name: `${mb.representative.role} (${entity.name})`
                            };
                        })
                    }"
                />
            </template>
            <template #item.vote_type="{ value }">
                <EqsChip
                    v-if="value"
                    v-bind="{ color: $color.colorFromHash(value), textColor: 'text' }"
                >
                    {{ vote_types[value].text }}
                </EqsChip>
            </template>
            <template #item.actions="{ item }">
                <EqsActions v-bind="{ actions: getActions(item) }" />
            </template>
        </EqsDataTable>
    </EqsCard>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { vote_types } from "@/util/governance.js";

export default {
    name: "Organs",
    props: {
        editable: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        selectedOrgan: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            vote_types
        };
    },
    computed: {
        ...mapGetters(["organs", "organsLoading", "decisions", "decisionsLoading"]),
        selectedOrganProxy: {
            get() {
                return [this.selectedOrgan];
            },
            set(val) {
                this.$emit("update:selectedOrgan", val[0]);
            }
        },
        headers() {
            return [
                {
                    text: this.$t("governance.organs.header.title"),
                    value: "name",
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t("governance.organs.header.members"),
                    value: "members",
                    sortable: false,
                    filterable: false
                },
                {
                    text: this.$t("governance.organs.header.representatives"),
                    value: "board",
                    sortable: false,
                    filterable: false
                },
                {
                    text: this.$t("governance.organs.header.vote"),
                    value: "vote_type",
                    sortable: false,
                    filterable: false
                },
                {
                    text: this.$t("label.actions"),
                    value: "actions",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ];
        }
    },
    created() {
        this.getOrgans();
        this.getDecisions();
    },
    methods: {
        ...mapActions(["getOrgans", "getDecisions", "deleteOrgan"]),
        async deleteItem(item) {
            try {
                await this.deleteOrgan(item);
            } catch (e) {
                this.$ui.error(e, "governance_organ_delete");
            }
        },
        getActions(item) {
            let res = [
                {
                    txt: this.$t("governance.organs.detail-btn"),
                    icon: "mdi-eye-outline",
                    fn: () =>
                        this.$router.push({
                            name: "governance-organ-detail",
                            params: { organId: item.id }
                        }),
                    color: "primary"
                }
            ];
            if (!this.decisions.find(decision => decision.organ.id === item.id))
                res.push(
                    ...[
                        {
                            txt: this.$t("governance.organs.edit-btn"),
                            icon: "mdi-pencil-outline",
                            fn: () =>
                                this.$router.push({
                                    name: "governance-organ-edit",
                                    params: {
                                        organId: item.id
                                    }
                                }),
                            color: "primary"
                        },
                        {
                            txt: this.$t("governance.organs.delete-btn"),
                            icon: "mdi-delete-outline",
                            fn: () => this.deleteItem(item),
                            color: "error"
                        }
                    ]
                );
            return res;
        }
    }
};
</script>
