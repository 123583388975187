import init from "./init";
import { arrayUnique } from "@equisafe-ui-shared/util";

const populateInvestorsWithShares = investors => {
    return investors.slice(0).filter(item => !!item.shares && !!item.shares.length);
};
export default {
    SET_INVESTORS(state, investors) {
        state.investors = investors;
        state.investorsWithShares = populateInvestorsWithShares(state.investors);
        state.investorsInit = false;
    },
    ADD_INVESTOR(state, investor) {
        state.investors = [investor, ...state.investors];
        state.investorsWithShares = populateInvestorsWithShares(state.investors);
    },
    UPDATE_INVESTOR(state, investor) {
        const index = state.investors.findIndex(_investor => _investor.id === investor.id);
        Object.assign(state.investors[index], investor);
        state.investorsWithShares = populateInvestorsWithShares(state.investors);
    },
    INVITED_INVESTORS(state, invitedInvestors) {
        this.SET_INVESTORS(state, arrayUnique(state.investors.concat(invitedInvestors), "id"));
    },
    DELETE_INVESTOR(state, investorId) {
        const index = state.investors.findIndex(investor => investor.id === investorId);
        state.investors.splice(index, 1);
        state.investorsWithShares = populateInvestorsWithShares(state.investors);
    },
    SET_INVESTORS_LOADING(state, bool) {
        state.investorsLoading = bool;
    },
    SET_INVESTOR_OPTIONS(state, options) {
        state.investorOptions = options;
        state.investorOptionsInit = false;
    },
    SET_INVESTOR_OPTIONS_LOADING(state, bool) {
        state.investorOptionsLoading = bool;
    },
    SET_INVESTOR_INIT(state, bool) {
        state.investorsInit = bool;
    },
    RESET_STORE_INVESTORS(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
