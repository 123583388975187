var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"fill-height",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('EqsStepper',_vm._b({staticStyle:{"height":"100%"},attrs:{"value":_vm.step,"steps":_vm.steps,"loading":_vm.localLoading},on:{"update:value":function($event){_vm.step=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c(item.is,_vm._b({tag:"component",attrs:{"state":_vm.stepperState,"form-data":_vm.formData,"all-data":_vm.allData,"csv-error":_vm.csvError,"loading":_vm.loading || _vm.localLoading,"action":_vm.action},on:{"update:formData":function($event){_vm.formData=$event},"update:form-data":function($event){_vm.formData=$event},"update:allData":function($event){_vm.allData=$event},"update:all-data":function($event){_vm.allData=$event},"update:csvError":function($event){_vm.csvError=$event},"update:csv-error":function($event){_vm.csvError=$event},"error":e => {
                            _vm.catchError();
                        }}},'component',{
                        schemaDouble: _vm.schemaDouble,
                        checkValideData: _vm.checkValideData,
                        parseCSV: _vm.parseCSV
                    },false))]}}])},'EqsStepper',{
                progress: _vm.progress
            },false))]},proxy:true}])},'EqsCard',{ height: '100%', loading: _vm.loading || _vm.localLoading },false))
}
var staticRenderFns = []

export { render, staticRenderFns }