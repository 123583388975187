import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_IBAN_START");
    if (state.ibanInit && !state.ibanLoading) {
        try {
            commit("SET_IBAN_LOADING", true);
            const res = await window.app.$api.getMyIBANs();
            let currentIban = null;
            const pendingIban = res.data.find(iban => iban.lemonway_status === 4);
            if (pendingIban) {
                currentIban = { ...pendingIban, pending: true };
            } else {
                const successIban = res.data.find(iban => iban.lemonway_status === 5);
                if (successIban) {
                    currentIban = successIban;
                } else {
                    currentIban = res.data[0];
                }
            }
            if (currentIban) {
                let res = null;
                if (currentIban.is_sepa) res = await window.app.$api.getMyIBANSepa(currentIban.id);
                else res = await window.app.$api.getMyIBANExt(currentIban.id);
                DEBUG("GET_IBAN_SUCCESS");
                commit("SET_IBAN", res.data);
            } else commit("SET_IBAN", null);
        } catch (e) {
            DEBUG("GET_IBAN_ERROR");
            window.app.$ui.error(e, "account_iban");
            throw e;
        } finally {
            commit("SET_IBAN_LOADING", false);
        }
    } else {
        DEBUG("GET_IBAN_BYPASSED");
        return state.iban;
    }
};
