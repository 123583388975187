var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({attrs:{"el-class":"manage-operators"}},'EqsCard',{ loading: _vm.localLoading, width: '100%' },false),[_c('v-layout',{staticClass:"overflow-y",attrs:{"column":"","justify-start":""}},[_c('div',{staticClass:"box-team"},[_c('v-layout',{staticClass:"box-members",attrs:{"column":""}},[_c('header',[_c('p',{staticClass:"first-title"},[_vm._v(" "+_vm._s(_vm.$t("crud.list-active"))+" ")]),_c('p',{staticClass:"second-title"},[_vm._v(" "+_vm._s(_vm.$t("crud.list-role"))+" ")])]),_vm._l((_vm.roles),function(role,key){return _c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:key,staticClass:"team-wrapper",class:{
                        'selected-role': _vm.selectedRole && role.id == _vm.selectedRole.id
                    },staticStyle:{"cursor":"pointer"},attrs:{"justify":"center","el-class":role.user.name},on:{"click":function($event){return _vm.showRoleUpdate(role)}}},[_c('span',{staticClass:"first-title layout align-center"},[_vm._v(" "+_vm._s(_vm._f("truncate")(role.user.name,20))+" "),_c('IsKycOk',{attrs:{"value":role.user && role.user.lemonway_state === 'success'}})],1),(role.user.is_active)?_c('div',{staticClass:"role-list-tag",class:role.htmlClass},[_vm._v(" "+_vm._s(_vm.rigthsByRole[role.role_name].role)+" ")]):_c('div',{staticClass:"role-list-tag",class:role.htmlClass},[_vm._v(" "+_vm._s(_vm.$t("crud.pending"))+" ")])])}),(!_vm.localLoading)?_c('footer',{staticClass:"box-add-member",on:{"click":_vm.showRoleCreate}},[_c('v-btn',{attrs:{"color":"primary","fab":"","el-class":"add-member"}},[_c('img',{attrs:{"src":"/icons/add.svg"}})]),_c('EqsTextDisplay',{staticClass:"mx-2"},[_c('p',{staticClass:"first-title"},[_vm._v(" "+_vm._s(_vm.$t("crud.list-add"))+" ")])])],1):_vm._e()],2),(_vm.open === _vm.$options.state.OPEN_ROLE_UPDATE)?_c('RoleUpdate',_vm._b({staticClass:"role-update-box",attrs:{"show-help":_vm.showHelp,"selected-role":_vm.selectedRole},on:{"update:showHelp":function($event){_vm.showHelp=$event},"update:show-help":function($event){_vm.showHelp=$event},"update:selectedRole":function($event){_vm.selectedRole=$event},"update:selected-role":function($event){_vm.selectedRole=$event}}},'RoleUpdate',{
                    rigthsByRole: _vm.rigthsByRole,
                    deleteRole: _vm.deleteRole,
                    updateRole: _vm.updateRole,
                    canChangeRole: _vm.canChangeRole
                },false)):(_vm.open === _vm.$options.state.OPEN_ROLE_CREATE)?_c('RoleCreate',_vm._b({staticClass:"role-update-box",on:{"createdRole":_vm.createdRole,"select:role_name":function($event){return _vm.changeShowRoleSelect($event)}}},'RoleCreate',{ users: _vm.users, showHelp: _vm.showHelp },false)):_vm._e(),(_vm.open)?_c('RoleHelp',_vm._b({staticClass:"role-help-box",attrs:{"role_name":!!_vm.selectedRole ? _vm.selectedRole.role_name : 'admin'}},'RoleHelp',{ rigthsByRole: _vm.rigthsByRole, showHelp: _vm.showHelp, showRoleSelect: _vm.showRoleSelect },false)):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }