<template>
    <div class="eqs-panels" :el-class="elClass">
        <v-expansion-panels
            v-bind="{ ...$attrs, ...getData }"
            :style="getStyle"
            :value.sync="valueProxy"
            v-on="$listeners"
        >
            <v-expansion-panel
                v-for="(panel, index) in panels"
                :key="index"
                class="eqs-panels__container"
                :title="panel.title"
            >
                <v-expansion-panel-header :class="`${elClass}-open-${index}`">
                    <EqsTextDisplay eqs-type="subtitle" :value="panel.title" />
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <slot v-bind="{ item: panel.item }" />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsPanels.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsPanels",
    components: {
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-panels"
        },
        value: {
            type: [Number, Array],
            required: false,
            default: null
        },
        panels: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsPanels.scss";
</style>
