import init from "./init";

export default {
    SET_UNIVERSE(state, universe) {
        state.universe = universe;
        if (!state.universe.contact_email || state.universe.contact_email.length === 0) {
            state.universe.contact_email = "contact@equisafe.io";
        }
        if (!state.universe.public_name || state.universe.public_name.length === 0) {
            state.universe.public_name = state.universe.name;
        }
        if (!state.universe.cgu_url) {
            state.universe.cgu_url = "#";
        }
    },
    RESET_STORE_UNIVERSE(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    SET_AFFILIATION_RESET_CACHE(state, value) {
        state.affiliation_reset_cache = value;
    },
    SET_AFFILIATION_REFRESH_NEXT_PAGE(state, value) {
        state.affiliation_refresh_next_page = value;
    }
};
