<template>
    <v-flex class="box-roles">
        <h5 class="title-roles">
            {{ $t("crud.help-title") }}
        </h5>
        <p class="sub-title-roles">
            {{ $t("crud.help-text") }}
        </p>
        <h5 class="admin-roles">
            {{ showRights.role }}
        </h5>
        <p v-for="(val, index) in showRights.rights" :key="index" class="user-role">
            <checkmark v-if="val.right" class="img-checkmark" />
            <red-checkmark v-else class="img-checkmark" />
            {{ val.trad }}
        </p>
    </v-flex>
</template>

<script>
import Checkmark from "./svg-icon/CheckSvgIcon";
import RedCheckmark from "./svg-icon/RedCrossCheckmarks";

export default {
    name: "RoleHelp",
    components: { Checkmark, RedCheckmark },
    props: {
        role_name: {
            type: String,
            default: "admin"
        },
        showHelp: {
            type: String
        },
        showRoleSelect: {
            type: String
        },
        rigthsByRole: {
            type: Object,
            required: true
        }
    },
    computed: {
        roleHelp() {
            return this.showHelp || this.showRoleSelect || this.role_name;
        },
        showRights() {
            return this.rigthsByRole[this.roleHelp];
        }
    }
};
</script>

<style>
.title-roles {
    font-weight: 900;
    margin-top: 20px;
}

.sub-title-roles {
    padding-top: 15px;
    font-weight: lighter;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 17px;
}

.admin-roles {
    padding-bottom: 25px;
    font-size: 18px;
    /* text-transform: capitalize; */
}

.box-roles {
    display: grid;
    grid-template-columns: 300px;
}

.img-checkmark {
    width: 20px;
    height: 14px;
    margin-right: 57px;
    transform: scale(1);
    margin-bottom: -5px;
}

.user-role {
    font-size: 14px;
    font-weight: 900;
}
</style>
