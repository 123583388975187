<template>
    <EqsCard
        closable
        class="fill-height"
        v-bind="{
            loading: loading || organsLoading,
            height: '100%',
            title: $t('governance.organs.details.title', { name: organ.name })
        }"
        @close="backToGovernance"
    >
        <div class="d-flex flex-column fill-height overflow-y" style="overflow-x: hidden">
            <v-row class="px-0">
                <v-col cols="12">
                    <DecisionsList v-bind="{ organ }" />
                </v-col>
                <v-col cols="12">
                    <Office v-bind="{ organ }" />
                </v-col>
                <v-col>
                    <Voting
                        v-bind="{
                            organ
                        }"
                    />
                </v-col>
                <v-col v-if="organ.vote_type !== 'share_to_vote'" cols="12">
                    <NonVoting v-bind="{ organ }" />
                </v-col>
                <v-col cols="12">
                    <OtherInfo v-bind="{ organ }" />
                </v-col>
            </v-row>
        </div>
    </EqsCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DecisionsList from "./DecisionsList";
import Voting from "./Voting";
import NonVoting from "./NonVoting";
import Office from "./Office";
import OtherInfo from "./OtherInfo";

export default {
    name: "OrganDetail",
    components: {
        DecisionsList,
        Voting,
        NonVoting,
        Office,
        OtherInfo
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        organId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            organ: {}
        };
    },
    watch: {
        organId: {
            immediate: true,
            async handler(val) {
                if (val) this.organ = await this.getOrgan(val);
            }
        }
    },
    computed: {
        ...mapGetters(["organsLoading"])
    },
    methods: {
        ...mapActions(["getOrgan"]),
        backToGovernance() {
            this.$router.push({
                name: "governance",
                params: this.$router.params,
                query: {
                    tab: "organs_representatives"
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
