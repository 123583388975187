var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsStepper',_vm._b({staticClass:"fill-height",attrs:{"height":"100%","value":_vm.step,"loading":_vm.loading || _vm.localLoading,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":_vm.comeBackToProjects},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"eqs-main-container margin-auto"},[_c(item.is,_vm._b({tag:"component",attrs:{"editable":"","schema-double":_vm.schemaDouble},on:{"update:schemaDouble":function($event){_vm.schemaDouble=$event},"update:schema-double":function($event){_vm.schemaDouble=$event}}},'component',{
                    formData: _vm.formData,
                    loading: _vm.loading || _vm.localLoading,
                    isCompany: _vm.isCompany,
                    cipForm: _vm.cipForm,
                    state: _vm.state
                },false))],1)]}}])},'EqsStepper',{ steps: _vm.steps, progress: _vm.progress, closeInfoTxt: _vm.closeInfoTxt },false))
}
var staticRenderFns = []

export { render, staticRenderFns }