<template>
    <!-- <v-row>
      <v-col>
        <EqsTextDisplay
          eqs-type="subtitle"
          :value="$t('market-details.price-share-title')"
        />
      </v-col>
      <v-col>
        <EqsTextDisplay
          eqs-type="subtitle"
          :value="$t('market-details.status-title')"
        />
      </v-col>
      <v-col>
        <EqsTextDisplay
          eqs-type="subtitle"
          :value="$t('market-details.description-title')"
        />
      </v-col>
    </v-row> -->
    <v-row>
        <v-col :cols="$reactive.isDesktop() ? 4 : 12">
            <EqsLine
                :config="prices"
                :specific="true"
                :options="{ responsive: true, maintainAspectRatio: false }"
            />
        </v-col>
        <v-col :cols="$reactive.isDesktop() ? 8 : 12">
            <EqsDataTable
                v-bind="{
                    headers,
                    items: orders
                }"
            >
                <template #item.name="{ item, index }">
                    <div :el-class="`market-shareledger_name-${index}`">
                        {{ item.details.data.shareledger.name }}
                    </div>
                </template>
                <template #item.quantity="{ item, index }">
                    <div :el-class="`market-numberofshares-${index}`">
                        {{ item.details.data.quantity }}
                    </div>
                </template>
                <template #item.sharePrice="{ item, index }">
                    <div :el-class="`market-pricepershare-${index}`">
                        {{
                            $currency.displayWithCurrency(
                                item.details.data.price,
                                item.details.data.price_currency
                            )
                        }}
                    </div>
                </template>
                <template #item.total="{ item, index }">
                    <div :el-class="`market-total_sell-${index}`">
                        {{ displayTotal(item) }}
                    </div>
                </template>
                <template #item.action="{ item, index }">
                    <div class="d-flex align-center justify-center">
                        <EqsBtn
                            color="primary"
                            :el-class="`make-offer-${index}`"
                            :action="
                                () => {
                                    proceedBuyStepper(item);
                                }
                            "
                        >
                            {{ $t("market-details.tabs.btn-buy") }}
                        </EqsBtn>
                    </div>
                </template>
            </EqsDataTable>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "MarketDetails",
    props: {
        orders: {
            type: Array,
            required: true
        },
        prices: {
            required: false
        }
        // loading: {
        //   type: Boolean,
        //   required: true,
        // },
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t("market-details.tabs.name"),
                    value: "name",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.quantity"),
                    value: "quantity",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.share-price"),
                    value: "sharePrice",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.total"),
                    value: "total",
                    sortable: true
                },
                {
                    value: "action",
                    sortable: false,
                    align: "center"
                }
            ]
        };
    },
    methods: {
        displayTotal(item) {
            const total = item.details.data.quantity * item.details.data.price;
            const currency = item.details.data.price_currency;
            return this.$currency.displayWithCurrency(total, currency);
        },
        proceedBuyStepper(item) {
            this.$router
                .push({
                    name: "secondary-market-buy-stepper",
                    params: {
                        buyType: "secondaryMarket",
                        orderId: item.details.sellOrderId
                    }
                })
                .catch(() => {});
        }
    }
};
</script>
