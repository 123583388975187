<template>
    <div class="d-flex flex-column eqs-medium-container">
        <EqsTextDisplay
            v-bind="{
                eqsType: 'subtitle',
                value: $t('entity.custodian.representative-title')
            }"
            class="py-3"
        />
        <v-row class="fill-height px-3">
            <v-col :cols="$reactive.getCols('lg')" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.first_name"
                    v-bind="{
                        elClass: 'first_name',
                        label: this.$t('entity.custodian.first-name'),
                        editable,
                        loading,
                        ...schemaDouble.first_name
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('lg')" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.last_name"
                    v-bind="{
                        elClass: 'last_name',
                        label: this.$t('entity.custodian.last-name'),
                        editable,
                        loading,
                        ...schemaDouble.last_name
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('lg')" class="eqs-form-field">
                <EqsEmail
                    :value.sync="formData.email"
                    v-bind="{
                        elClass: 'email',
                        isRequired: false,
                        label: $t('entity.custodian.email'),
                        editable,
                        loading,
                        ...schemaDouble.email
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="fill-height px-3">
            <EqsTextDisplay
                v-bind="{
                    eqsType: 'subtitle',
                    value: $t('entity.custodian.company-title')
                }"
                class="py-3"
            />
            <v-col :cols="$reactive.getCols('xl')" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.company_name"
                    v-bind="{
                        elClass: 'company_name',
                        label: this.$t('rules.company-name'),
                        editable,
                        loading,
                        ...schemaDouble.company_name
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('xl')" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.company_idcode"
                    v-bind="{
                        elClass: 'company_idcode',
                        label: this.$t('registration.company.company-identificiation'),
                        editable,
                        loading,
                        rules: [companyIdcodeR],
                        ...schemaDouble.company_idcode
                    }"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { companyIdcodeR } from "@/rules";
export default {
    name: "CompanyInviteForm",
    mixins: [formMixin],
    data() {
        return {
            companyIdcodeR
        };
    }
};
</script>
