var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({},'EqsCard',{
        loading: _vm.loading,
        subtitle: _vm.$t('governance.organs.details.decisions-list.title')
    },false),[_c('EqsDataTable',_vm._b({staticClass:"px-3",scopedSlots:_vm._u([{key:"item.date",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$time.formated(value))+" ")]}},{key:"item.state",fn:function({ value }){return [_c('EqsChip',_vm._b({},'EqsChip',_vm.decisions_state[value],false),[_vm._v(" "+_vm._s(_vm.decisions_state[value].text)+" ")])]}}])},'EqsDataTable',{
            items: _vm.organDecisions,
            loading: _vm.decisionsLoading,
            headers: _vm.headers,
            itemsPerPage: 5
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }