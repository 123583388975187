import init from "./init";

export default {
    GET_SETLIMIT(state, setlimit) {
        state.setlimit = setlimit;
    },
    GET_SETCOUNTRIES(state, setcountries) {
        state.setcountries = setcountries;
    },
    GET_REMOVECOUNTRIES(state, removecountries) {
        state.removecountries = removecountries;
    },
    GET_ISSUINGENTITY(state, issuingentity) {
        state.issuingentity = issuingentity;
    },
    GET_BLOCKCHAIN_INFO(state, blockchainInfo) {
        state.setlimit = blockchainInfo.setlimit_set.pop();
        state.setcountries = blockchainInfo.setcountries_set.pop();
        state.removecountries = blockchainInfo.removecountries_set.pop();
        state.issuingentity = blockchainInfo.issuingentity;
        state.kycissuer = blockchainInfo.kycissuer;
        state.blockchainize_state = blockchainInfo.blockchainize_state;
    },
    SET_BLOCKCHAIN_INFO_LOADING(state, loading) {
        state.blockchainInfoLoading = loading;
    },
    SET_SETLIMIT_LOADING(state, loading) {
        state.setlimitLoading = loading;
    },
    SET_SETCOUNTRIES_LOADING(state, loading) {
        state.setcountriesLoading = loading;
    },
    SET_REMOVECOUNTRIES_LOADING(state, loading) {
        state.removeCountriesLoading = loading;
    },
    SET_ISSUINGENTITY_LOADING(state, loading) {
        state.issuingEntityLoading = loading;
    },
    RESET_STORE_BLOCKCHAIN(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    SET_TRANSACTIONS(state, transactions) {
        if (transactions && transactions.length > 0) {
            state.transactions = [...transactions];
        } else {
            state.transactions = [];
        }
        state.transactionsInit = false;
    },
    SET_TRANSACTIONS_LOADING(state, bool) {
        state.transactionsLoading = bool;
    }
};
