import init from "./init";

export default {
    SET_SHARELEDGERS(state, shareledgers) {
        state.shareledgers = shareledgers;
        state.shareledgersInit = false;
    },
    ADD_SHARELEDGER(state, shareledger) {
        state.shareledgers = [shareledger, ...state.shareledgers];
    },
    UPDATE_SHARELEDGER(state, shareledger) {
        const index = state.shareledgers.findIndex(
            _shareledger => _shareledger.id === shareledger.id
        );
        Object.assign(state.shareledgers[index], shareledger);
    },
    DELETE_SHARELEDGER(state, shareledgerId) {
        const index = state.shareledgers.findIndex(shareledger => shareledger.id === shareledgerId);
        state.shareledgers.splice(index, 1);
    },
    SET_SHARELEDGERS_LOADING(state, bool) {
        state.shareledgersLoading = bool;
    },
    RESET_STORE_SHARELEDGERS(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
