var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-main-container margin-auto"},[_c('GlobalFinalState',_vm._b({scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('EqsTextDisplay',_vm._b({staticClass:"text-center mb-3",attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{ elClass: 'success-txt' },false),[_vm._v(" "+_vm._s(_vm.titleTxt)+" ")]),_c('EqsTextDisplay',{staticClass:"mb-3",attrs:{"paragraph":""}},[_vm._v(" "+_vm._s(_vm.contentTxt)+" ")]),(_vm.state == 'success' && !!_vm.doc)?_c('EqsFileInput',_vm._b({staticClass:"mb-3",attrs:{"value":_vm.doc},on:{"update:value":function($event){_vm.doc=$event}}},'EqsFileInput',{
                    deleteFile: false,
                    editable: false,
                    loading: _vm.loading,
                    label: _vm.$t('user.wallet.money-out.success.file-label')
                },false)):_vm._e()]},proxy:true}])},'GlobalFinalState',{
            loading: _vm.loading,
            state: _vm.state,
            errorText: _vm.errorText
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }