export const lemonwayStatusError = (ctx, status, reason) => {
    let ret = null;
    if (reason) {
        if (reason.includes("341")) return ctx.$t("error.file-lemonway-duplicated");
        else return reason;
    }
    const _status = parseInt(status);
    switch (_status) {
        case 3:
            ret = ctx.$t("error.file-lemonway-rejected");
            break;
        case 4:
            ret = ctx.$t("error.file-lemonway-unreadable");
            break;
        case 5:
            ret = ctx.$t("error.file-lemonway-expired");
            break;
        case 6:
            ret = ctx.$t("error.file-lemonway-wrong-type");
            break;
        case 7:
            ret = ctx.$t("error.file-lemonway-wrong-name");
            break;
        default:
            ret = ctx.$t("error.file-lemonway-rejected");
    }
    return ret;
};

export const lemonwayStatusItem = (ctx, status, reason) => {
    let ret = {};
    const _status = parseInt(status);
    if (_status === 0)
        ret = {
            status: "warning",
            eqsType: "warning",
            text:
                `${ctx.$t("label.status")}: ${ctx.$t("label.on_hold")} ` +
                (reason ? reason : `(${ctx.$t("register.kyc.document.on-hold-process")})`)
        };
    else if (_status === 1)
        ret = {
            status: "pending",
            eqsType: "warning",
            text: `${ctx.$t("label.status")}: ${ctx.$t("label.pending")} (${ctx.$t(
                "register.kyc.document.pending-process"
            )})`
        };
    else if (_status === 2)
        ret = {
            status: "success",
            eqsType: "success",
            text: `${ctx.$t("label.status")}: ${ctx.$t("label.accepted")}`
        };
    else if (status > 2 || reason)
        ret = {
            status: "error",
            eqsType: "error",
            text: `${ctx.$t("label.status")}: ${ctx.$t("error.error")}`
        };
    return {
        ...ret
    };
};

export const getLWStatusFile = (ctx, file) => {
    let res = null;
    let message = "";
    if (file && (file.lemonway_status || file.lemonway_reason || file.comment)) {
        res = lemonwayStatusItem(ctx, file.lemonway_status, file.lemonway_reason);
    }
    if (!res) return null;
    if (res.status === "error") {
        message = ` (${lemonwayStatusError(
            ctx,
            file.lemonway_status,
            [file.lemonway_reason, file.comment].filter(msg => !!msg).join(" - ")
        )})`;
    } else if (res.status === "success") {
        if (file.lemonway_validity)
            message = ` (${ctx.$t("molecule.form.expiration-date")}: ${ctx.$time.formated(
                file.lemonway_validity
            )})`;
    }
    if (message) res.text += message;
    return res;
};
