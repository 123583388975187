<template>
    <v-card flat class="d-flex flex-column eqs-windows" v-bind="{ height: '100%', width: '100%' }">
        <v-row v-if="$slots.top">
            <slot name="top" />
        </v-row>
        <v-window
            v-model="valueProxy"
            :class="['eqs-windows__container d-flex', getData.class]"
            v-bind="{ ...getData, ...$attrs }"
            :style="getStyle"
            v-on="$listeners"
        >
            <v-window-item
                v-for="(item, i) in items"
                :key="i"
                class="eqs-windows__container__item"
                :el-class="`eqs-windows-item-${i}`"
            >
                <slot v-bind="{ item, index: i }" />
            </v-window-item>
        </v-window>
        <InternalWindowsControl :value.sync="valueProxy" v-bind="{ items, cycle }" />
    </v-card>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsWindows.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsWindows",
    components: {
        InternalWindowsControl: () => import("./_internal/InternalWindowsControl.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-windows"
        },
        items: {
            type: Array,
            required: true
        },
        value: {
            type: Number,
            required: true
        },
        cycle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsWindows.scss";
</style>
