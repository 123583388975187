import { getHttpErrMsg } from "@/log";

export default async ({ commit, state }, investorId) => {
    let investorData = state.diDocuments[investorId];
    if (investorData) {
        if (investorData.expire_at > Date.now()) {
            return investorData.data;
        }
    }
    try {
        const res = await window.app.$api.request(
            "get",
            `api/investors-dashboard/${investorId}/documents/`
        );
        commit("SET_DI_DOCUMENTS", res.data);
        return res.data;
    } catch (e) {
        console.log("getDiDocuments", e);
        window.app.$ui.error(
            getHttpErrMsg(e, "Erreur lors de la récupération des documents de l'investisseur")
        );
    }
};
