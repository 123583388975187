import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, dispatch }, data) => {
    DEBUG("ADD_PROJECT_START");
    try {
        commit("SET_PROJECTS_LOADING", true);
        const projectRes = await window.app.$api.getCapitalIncrease(data.projectId, {
            cip: data.isCip
        });
        DEBUG("ADD_PROJECT_SUCCESS");
        commit("ADD_PROJECT", projectRes.data);
        return projectRes.data;
    } catch (e) {
        DEBUG("ADD_PROJECT_ERROR");
        throw e;
    } finally {
        commit("SET_PROJECTS_LOADING", false);
    }
};
