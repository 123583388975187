<template>
    <v-flex>
        <div class="eqs-switch d-inline-flex" :el-class="elClass" :style="getStyle">
            <EqsLabel
                v-bind="{
                    ...$attrs,
                    isRequired,
                    height: labelHeight,
                    editable,
                    value: label,
                    infoTxt
                }"
            >
                <div class="centered">
                    <v-switch
                        v-model="valueProxy"
                        v-bind="{
                            ...$attrs,
                            ...$props,
                            ...getData,
                            label: text,
                            loading,
                            disabled: disabled || loading
                        }"
                        :el-class="`${elClass}-value`"
                        class="ma-0"
                        v-on="$listeners"
                    >
                        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                    </v-switch>
                </div>
            </EqsLabel>
        </div>
    </v-flex>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsSwitch.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";

export default {
    name: "EqsSwitch",
    components: {
        EqsLabel: () => import("../EqsLabel/EqsLabel.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: "eqs-switch"
        },
        value: {
            type: null,
            required: true
        },
        text: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsSwitch.scss";
.centered {
    display: flex;
    justify-content: center;
}
</style>
