<template>
    <v-row class="d-flex align-stretch">
        <!-- Amount available -->
        <v-col class="d-flex flex-column pl-0 pr-6">
            <v-card
                :loading="loading"
                class="rounded-lg card-full-h d-flex flex-column"
                raised
                :elevation="1"
            >
                <v-card-title class="no-wrap">{{ $t("dashboard.amount-available") }}</v-card-title>
                <v-card-text class="stats-data">{{ $currency.display(stats.balance) }}</v-card-text>
                <v-spacer />
                <v-card-actions class="bg-card-links pa-0 mt-2">
                    <v-btn text class="fill-height fill-width link-green" :to="'/wallet/' + userId">
                        {{ $t("dashboard.my-trades") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>

        <!-- Amount invested -->
        <v-col class="d-flex flex-column pl-0 pr-6">
            <v-card
                :loading="loading"
                class="rounded-lg card-full-h d-flex flex-column"
                raised
                :elevation="1"
            >
                <v-card-title class="no-wrap">{{ $t("dashboard.amount-invested") }}</v-card-title>
                <v-card-text class="stats-data"
                    >{{ $currency.display(totalInvestments) }}
                </v-card-text>
                <v-spacer />
                <v-card-actions class="bg-card-links pa-0 mt-2">
                    <v-btn
                        text
                        class="fill-height fill-width link-green"
                        :to="'/portfolio/' + userId"
                    >
                        {{ $t("dashboard.my-invest") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>

        <!-- Number of projects -->
        <v-col class="d-flex flex-column pl-0 pr-6">
            <v-card
                :loading="loading"
                class="rounded-lg card-full-h d-flex flex-column"
                raised
                :elevation="1"
            >
                <v-card-title class="no-wrap">{{ $t("dashboard.projects") }}</v-card-title>
                <v-card-text class="stats-data">{{ stats.number_of_projects }}</v-card-text>
                <v-spacer />
                <v-card-actions class="bg-card-links pa-0 mt-2">
                    <v-btn
                        text
                        class="fill-height fill-width link-green"
                        :to="'/projects/' + userId"
                    >
                        {{ $t("dashboard.my-projects") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "UserStats",
    props: {
        userId: {
            type: String,
            required: true
        },
        stats: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            totalInvestments: 0
        };
    },
    async created() {
        const res = await this.$api.getBalancePortfolio({
            entity_id: this.userId
        });
        let total = 0;
        res.data.forEach(company => {
            total += Math.abs(company.investment_sum_eur);
        });
        this.totalInvestments = total;
    }
};
</script>

<style scoped>
/*
  Needed to get all cards in cols same height
*/
.card-full-h {
    --card-height: 100% !important;
    height: 100% !important;
}

.stats-data {
    color: #3d4beb !important;
    font-size: 1.8rem;
    font-weight: bolder;
}

.bg-card-links {
    background-color: rgb(248, 248, 248);
}

.link-green {
    color: rgb(37, 146, 64) !important;
    text-transform: none !important;
    font-weight: bolder;
    justify-content: left;
}
.link-green:hover {
    color: rgb(37, 146, 64);
    font-weight: bolder;
}
</style>
