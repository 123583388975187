/* eslint-disable vue/max-len */
import Auth from "@/components/views/auth/_Auth.vue";
import EmailConfirmation from "@/components/views/auth/register/EmailConfirmation.vue";
import RegisterEmail from "@/components/views/auth/register/RegisterEmail.vue";
import Register from "@/components/views/auth/register/Register.vue";
import SendMailForgotPassword from "@/components/views/auth/forgot-password/SendMailForgotPassword.vue";
import ConfirmForgotPassword from "@/components/views/auth/forgot-password/ConfirmForgotPassword.vue";
import TwoFA from "@/components/views/auth/TwoFA.vue";

import multiguard from "vue-router-multiguard";
import { requiresAuth, requiresUnAuth } from "../routeGuards";
import { i18n } from "@/services/plugins/i18n.js";

const authData = {
    appEqsDataType: ["no-menu", "no-nav-bar", "no-padding"]
};

export default [
    {
        path: "/auth",
        component: Auth,
        name: "auth",
        meta: {
            ...authData
        },
        children: [
            {
                path: "2fa/setup",
                component: TwoFA,
                name: "2fa",
                meta: {
                    ...authData,
                    title: i18n.t("twofa.setup")
                },
                beforeEnter: multiguard([requiresAuth])
            },
            {
                path: "register",
                component: Register,
                name: "register",
                meta: {
                    ...authData,
                    title: i18n.t("auth.register.title")
                },
                beforeEnter: multiguard([requiresUnAuth])
            },
            {
                path: "register-email/:email",
                component: RegisterEmail,
                name: "register-email",
                props: true,
                meta: {
                    ...authData,
                    title: i18n.t("auth.register-email.title")
                },
                beforeEnter: multiguard([requiresUnAuth])
            },
            {
                path: "register/confirm",
                component: EmailConfirmation,
                name: "register-confirm",
                meta: authData,
                beforeEnter: multiguard([requiresUnAuth])
            },
            {
                path: "forgot/password",
                component: SendMailForgotPassword,
                name: "forgot-password",
                meta: {
                    ...authData,
                    title: i18n.t("forgot-password.subtitle")
                },
                beforeEnter: multiguard([requiresUnAuth])
            },
            {
                path: "confirm/forgot/password",
                component: ConfirmForgotPassword,
                name: "confirm-forgot-password",
                meta: {
                    ...authData,
                    title: i18n.t("forgot-password.new-password")
                },
                beforeEnter: multiguard([requiresUnAuth])
            }
        ]
    }
];
