<template>
    <EqsDialog
        v-model="dialogProxy"
        closable
        v-bind="{
            loading,
            confirm: {
                fn: () => (dialogProxy = false),
                txt: $t('btn.close')
            },
            title
        }"
        @close="() => (dialogProxy = false)"
    >
        <v-flex class="height-fit overflow-y no-overflow-x">
            <!-- <EqsFormGenerator class="px-3" v-bind="{ schema, value:iban, loading }" /> -->
            <component
                :is="iban.is_sepa ? 'SepaForm' : 'InternationalForm'"
                v-bind="{
                    editable: false,
                    formData: iban,
                    loading,
                    schemaDouble,
                    formType: 'dialog'
                }"
            />
        </v-flex>
    </EqsDialog>
</template>

<script>
import { sepaDouble, internationalDouble } from "@/util/bankSchema.js";
import SepaForm from "./SepaForm";
import InternationalForm from "./InternationalForm";

export default {
    name: "BankAccountDialog",
    components: {
        SepaForm,
        InternationalForm
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        iban: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            schemaDouble: {}
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) this.$emit("close");
                this.$emit("update:value", val);
            }
        }
    },
    watch: {
        iban: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.getIbanInfos(val);
                }
            }
        }
    },
    methods: {
        getIbanInfos(val) {
            try {
                this.schemaDouble = val.is_sepa ? sepaDouble : internationalDouble;
            } catch (e) {
                console.error({ e });
            }
        }
    }
};
</script>
