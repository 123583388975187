import DEBUG from "@/store/debug.js";

export default async ({ state, commit, dispatch }, investorId) => {
    DEBUG("FETCH_INVESTOR_START");
    if (!investorId) throw new Error("no investorId");
    if (!state.investors.length) {
        try {
            await dispatch("getInvestors");
        } catch (e) {
            throw e;
        }
    }
    try {
        const res = await window.app.$api.getInvestor(investorId);
        if (state.investors.find(investor => investor.id === res.data.id))
            commit("UPDATE_INVESTOR", res.data);
        else commit("ADD_INVESTOR", res.data);
        DEBUG("FETCH_INVESTOR_SUCCESS");
    } catch (e) {
        DEBUG("FETCH_INVESTOR_ERROR");
        throw e;
    }
};
