import { i18n } from "@/services/plugins/i18n.js";

const all = {
    amount: {
        dataType: "int",
        isRequired: false
    },
    attribution_frequency: {
        attribution_frequency: {
            dataType: "int",
            isRequired: true
        },
        attribution_frequency_is_year: {
            dataType: "bool",
            isRequired: false
        }
    },
    can_exercice_multiple: {
        dataType: "int",
        isRequired: true
    },
    cliffing_period: {
        cliffing_period: {
            dataType: "int",
            isRequired: true
        },
        cliffing_period_is_year: {
            dataType: "bool",
            isRequired: false
        }
    },
    conversion_ratio: {
        dataType: "int",
        isRequired: true
    },
    conversion_ledger: {
        isRequired: true
    },
    pool_size: {
        dataType: "int",
        isRequired: false
    },
    discount: {
        dataType: "float",
        isRequired: true
    },
    emission_duration: {
        emission_duration: {
            dataType: "int",
            isRequired: true
        },
        emission_duration_is_year: {
            dataType: "bool",
            isRequired: false
        }
    },
    exercice_duration: {
        exercice_duration: {
            dataType: "int",
            isRequired: true
        },
        exercice_duration_is_year: {
            dataType: "bool",
            isRequired: false
        }
    },
    exercice_price: {
        dataType: "int",
        isRequired: true
    },
    due_date: {
        isRequired: true
    },
    lastpricepershare: {
        dataType: "float",
        isRequired: true
    },
    max_valorisation: {
        dataType: "float",
        isRequired: false
    },
    min_valorisation: {
        dataType: "float",
        isRequired: false
    },
    numberofshares: {
        dataType: "int",
        isRequired: false
    },
    share_only_investor_transfer: {
        dataType: "bool",
        isRequired: false
    },
    share_transfer: {
        dataType: "bool",
        isRequired: false
    },
    signature_date: {
        isRequired: true
    },
    souscription_price: {
        dataType: "int",
        isRequired: true
    },
    number_of_vote: {
        dataType: "int",
        isRequired: true
    },
    nominal_value: {
        dataType: "float",
        isRequired: true
    },
    transferability: {
        dataType: "bool",
        isRequired: false
    },
    vesting_period: {
        vesting_period: {
            dataType: "int",
            isRequired: true
        },
        vesting_period_is_year: {
            dataType: "bool",
            isRequired: false
        }
    }
};

const general = {
    numberofshares: all.numberofshares,
    lastpricepershare: all.lastpricepershare,
    share_transfer: all.share_transfer,
    share_only_investor_transfer: all.share_only_investor_transfer
};

const bsa = {
    signature_date: all.signature_date,
    conversion_ledger: all.conversion_ledger,
    numberofshares: all.numberofshares,
    souscription_price: all.souscription_price,
    ...all.emission_duration,
    can_exercice_multiple: all.can_exercice_multiple,
    ...all.exercice_duration,
    exercice_price: all.exercice_price,
    conversion_ratio: all.conversion_ratio,
    ...all.cliffing_period,
    ...all.vesting_period,
    pool_size: all.pool_size,
    ...all.attribution_frequency,
    transferability: all.transferability
};

const shares = {
    number_of_vote: all.number_of_vote,
    nominal_value: all.nominal_value,
    lastpricepershare: all.lastpricepershare,
    share_transfer: all.share_transfer,
    share_only_investor_transfer: all.share_only_investor_transfer
};

const bspce = {
    signature_date: all.signature_date,
    pool_size: all.pool_size,
    conversion_ledger: all.conversion_ledger,
    ...all.emission_duration,
    can_exercice_multiple: all.can_exercice_multiple,
    ...all.exercice_duration,
    exercice_price: all.exercice_price,
    conversion_ratio: all.conversion_ratio,
    ...all.cliffing_period,
    ...all.vesting_period,
    ...all.attribution_frequency
};

const bsa_air = {
    due_date: all.due_date,
    conversion_ledger: all.conversion_ledger,
    discount: all.discount,
    min_valorisation: all.min_valorisation,
    max_valorisation: all.max_valorisation,
    transferability: all.transferability
};

const tokens = {};

const carbo = {};

const bonds = {
    lastpricepershare: all.lastpricepershare,
    coupon_in_fine_or_each_year: {
        dataType: "bool",
        isRequired: true
    },
    nominal_repayment: {
        dataType: "bool",
        isRequired: true
    },
    interest_type: {
        dataType: "bool",
        isRequired: true
    },
    interest_rate: {
        dataType: "int",
        isRequired: true
    },
    early_repayment: {
        dataType: "bool",
        isRequired: true
    },
    exercice_duration: {
        dataType: "int",
        isRequired: true
    },
    exercice_duration_is_year: {
        dataType: "bool",
        isRequired: false
    }
};

const aga = {};

export const share_types = {
    shares: {
        name: i18n.t("shareledger.dialog.stepper.tokenize.label-type-shares"),
        is: "ShareSpecification",
        schemaDouble: shares
    },
    cca: {
        name: i18n.t("shareledger.dialog.stepper.tokenize.label-type-cca"),
        is: "ShareSpecification",
        schemaDouble: shares
    },
    units: {
        name: i18n.t("shareledger.dialog.stepper.tokenize.label-type-units"),
        is: "ShareSpecification",
        schemaDouble: shares
    },
    bonds: {
        name: i18n.t("shareledger.dialog.stepper.tokenize.label-type-bonds"),
        is: "BondsSpecification",
        schemaDouble: bonds
    },
    bsa: {
        name: i18n.t("shareledger.dialog.stepper.tokenize.label-type-bsa"),
        is: "BSASpecification",
        schemaDouble: bsa
    },
    bspce: {
        name: i18n.t("shareledger.dialog.stepper.tokenize.label-type-bspce"),
        is: "BSPCESpecification",
        schemaDouble: bspce
    },
    bsa_air: {
        name: i18n.t("shareledger.dialog.stepper.tokenize.label-type-bsa_air"),
        is: "BSAAIRSpecification",
        schemaDouble: bsa_air
    },
    aga: {
        name: i18n.t("shareledger.dialog.stepper.tokenize.label-type-aga"),
        is: "AGASpecification",
        schemaDouble: aga
    },
    tokens: {
        name: i18n.t("shareledger.dialog.stepper.tokenize.label-type-tokens"),
        is: "TokensSpecification",
        schemaDouble: tokens
    },
    carbo: {
        name: i18n.t("shareledger.dialog.stepper.tokenize.label-type-carbo"),
        is: "CarboSpecification",
        schemaDouble: carbo
    }
};

export default Object.entries(share_types).map(entry => {
    const [key, val] = entry;
    return { id: key, ...val };
});
