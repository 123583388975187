<template>
    <v-row v-if="showStats" dense>
        <v-col cols="auto">
            <v-skeleton-loader
                :loading="localLoading"
                :boilerplate="localLoading"
                type="image"
                min-height="140"
                max-height="140"
                min-width="286"
                max-width="286"
            >
                <template #default>
                    <StatsCardComponent
                        v-if="stats"
                        v-bind="{
                            mainTitle: $t('universe.kyoseil.cgp-dashboard.card-1-title'),
                            icon: 'mdi-safe',
                            isMoney: true,
                            value: stats.total_price.one_week_ago,
                            trend: stats.total_price.percentageChange > 0 ? 'UP' : 'DOWN',
                            colorBackground: '#ECFAF7',
                            colorIcon: '#13C296',
                            percentageIncrease: stats.total_price.percentageChange
                        }"
                    />
                </template>
            </v-skeleton-loader>
        </v-col>
        <v-col cols="auto">
            <v-skeleton-loader
                :loading="localLoading"
                :boilerplate="localLoading"
                type="image"
                min-height="140"
                max-height="140"
                min-width="286"
                max-width="286"
            >
                <template #default>
                    <StatsCardComponent
                        v-if="stats"
                        v-bind="{
                            mainTitle: $t('universe.kyoseil.cgp-dashboard.card-2-title'),
                            icon: 'mdi-account-multiple-outline',
                            isMoney: false,
                            value: stats.total_clients.one_week_ago,
                            trend: stats.total_clients.percentageChange > 0 ? 'UP' : 'DOWN',
                            colorBackground: '#EEF1FC',
                            colorIcon: '#3056D3',
                            percentageIncrease: stats.total_clients.percentageChange
                        }"
                    />
                </template>
            </v-skeleton-loader>
        </v-col>
    </v-row>
</template>

<script>
import StatsCardComponent from "./StatsCardComponent.vue";
import { calculateIncreasePercentage } from "@/components/views/user/clients/tools.js";

export default {
    name: "PerformanceStats",
    components: { StatsCardComponent },
    inject: ["_entity"],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            stats: null,
            localLoading: true,
            showStats: false
        };
    },
    mounted() {
        if (this.stats === null || Object.key(this.stats).length === 0) {
            this.fetchStats();
        }
    },
    methods: {
        computeStats(data) {
            if (!data || Object.keys(data).length === 0) {
                this.stats = {
                    total_price: {
                        percentageChange: NaN,
                        one_week_ago: 0,
                        two_weeks_ago: 0
                    },
                    total_clients: {
                        percentageChange: NaN,
                        one_week_ago: 0,
                        two_weeks_ago: 0
                    }
                };
            } else {
                this.stats = {
                    total_price: {
                        percentageChange: calculateIncreasePercentage(
                            data.total_price.two_weeks_ago,
                            data.total_price.one_week_ago
                        ),
                        ...data.total_price
                    },
                    total_clients: {
                        percentageChange: calculateIncreasePercentage(
                            data.total_clients.two_weeks_ago,
                            data.total_clients.one_week_ago
                        ),
                        ...data.total_clients
                    }
                };
            }
        },
        async fetchStats() {
            this.localLoading = true;
            let response = null;
            try {
                if (this.type === "view_cgp") {
                    this.showStats = true;
                    if (this.id === "") {
                        response = await this.$api.getStatsAllClients();
                    } else {
                        response = await this.$api.getStatsCGP(this.id);
                    }
                } else if (this.type === "view_bc") {
                    this.showStats = true;
                    if (this.$entity.isAffiliationAdmin()) {
                        response = await this.$api.getStatsAllBP();
                    } else {
                        if (this.id === "") {
                            response = await this.$api.getStatsBP(this._entity.id);
                        } else {
                            response = await this.$api.getStatsBP(this.id);
                        }
                    }
                } else if (this.type === "view_admin") {
                    this.showStats = true;
                    response = await this.$api.getStatsAllBP();
                } else {
                    this.showStats = false;
                }
            } catch (e) {
                this.$ui.error(e, "stats_dashboard");
            }
            if (this.showStats) {
                this.computeStats(response?.data);
            }
            this.localLoading = false;
        }
    }
};
</script>

<style lang="scss" scoped></style>
