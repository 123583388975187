<template>
    <div class="d-flex flex-column fill-height">
        <v-row
            class="flex-grow-0 pa-6"
            :style="`background-image: url('${cover}'); background-size: cover`"
        >
            <v-col :cols="2" :offset="1">
                <EqsAvatar
                    eqs-type="xl"
                    :src="avatar"
                    no-badge
                    :user="{ name: companyName, is_company: true }"
                    v-bind="{ loading }"
                />
            </v-col>
        </v-row>

        <v-row no-gutters class="align-self-stretch">
            <EqsCard class="d-flex flex-column fill-height" height="100%">
                <v-row>
                    <v-col :cols="7">
                        <Header
                            v-bind="{
                                companyName,
                                tags,
                                stats,
                                description,
                                executives,
                                loading
                            }"
                        />

                        <v-container>
                            <v-row no-gutters class="mt-6">
                                <EqsTextDisplay value="Revenus trimestriel" eqs-type="subtitle" />
                            </v-row>

                            <v-row class="mt-3">
                                <v-col :cols="6">
                                    <EqsCard>
                                        <template #subtitle>
                                            <EqsTextDisplay
                                                class="pa-3"
                                                :value="`LTM revenus: ${$currency.displayWithCurrency(
                                                    ltmRevenues
                                                )}`"
                                                eqs-type="subtitle"
                                                v-bind="{ loading }"
                                            />
                                        </template>
                                    </EqsCard>
                                </v-col>

                                <v-col :cols="6">
                                    <EqsCard>
                                        <template #subtitle>
                                            <EqsTextDisplay
                                                class="pa-3"
                                                :value="`Revenus annuel: ${$currency.displayWithCurrency(
                                                    yearlyRevenues
                                                )}`"
                                                eqs-type="subtitle"
                                                v-bind="{ loading }"
                                            />
                                        </template>
                                    </EqsCard>
                                </v-col>
                            </v-row>

                            <v-row class="mt-3">
                                <v-col :cols="6">
                                    <EqsBtn block color="primary" v-bind="{ loading }">
                                        Accèder à la DataRoom
                                    </EqsBtn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>

                    <v-col :cols="4">
                        <v-row>
                            <v-col :cols="10">
                                <GeneralInfo
                                    v-bind="{
                                        incorporationDate,
                                        location,
                                        siren,
                                        legalForm,
                                        email,
                                        headquarters,
                                        tables,
                                        loading
                                    }"
                                />
                            </v-col>
                        </v-row>

                        <div class="pt-6">
                            <FinancingHistory
                                :pie="financingHistoryPie"
                                :table="financingHistoryTable"
                                v-bind="{ loading }"
                            />
                        </div>

                        <div class="pt-6">
                            <CapTable :items="captable" v-bind="{ loading }" />
                        </div>
                    </v-col>
                </v-row>
            </EqsCard>
        </v-row>
    </div>
</template>

<script>
import Header from "./Header.vue";
import GeneralInfo from "./GeneralInfo.vue";
import FinancingHistory from "./FinancingHistory.vue";
import CapTable from "./CapTable.vue";

export default {
    name: "CompanyDetails",
    components: {
        Header,
        GeneralInfo,
        FinancingHistory,
        CapTable
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        const stats = [
            { text: "EBITA", value: "5%" },
            { text: "TRI cible", value: "15%" },
            { text: "TRI réel", value: "25%" },
            { text: "Valorisation de la société", value: "50M€" }
        ];

        const description = [
            "GamersOrigin est un club d’esport professionnel français qui a été à l’origine créé en ",
            "mars 2011 par Guillaume Merlini en tant que fansite dédié à Guillaume Merlini en tant que ",
            "fansite dédié à la série Diablo. Par la suite le site s’est développé et a ouvert ",
            "plusieurs WebTVs avant de devenir un club d’esport1 en 2014.\n",
            "GamersOrigin évolue depuis 2017 principalement sur League of Legends dans la Ligue ",
            "Française de League of Legends qu’elle a remporté au segment de printemps 20201."
        ].join("");

        const executives = [
            {
                id: "027fd948-1fb3-480f-87f1-9cf99f4f794a",
                name: "Bilal El Alamy",
                role: "Founder & CEO",
                location: "Paris, France"
            },
            {
                id: "cfdb9156-9f88-4eac-91f0-ada66a65c84c",
                name: "Bilal El Alamy",
                role: "Founder & CEO",
                location: "Paris, France"
            }
        ];

        const tables = [
            {
                name: "Valeurs",
                items: [
                    {
                        text: "Valeur marchande",
                        info: "...",
                        value: 500000.0
                    },
                    {
                        text: "Valeur liquidative",
                        info: "...",
                        value: 500000.0
                    },
                    {
                        text: "Valeur venale",
                        info: "...",
                        value: 500000.0
                    }
                ]
            },
            {
                name: "Dividende",
                items: [
                    {
                        text: "Q1",
                        info: "...",
                        value: 500000.0
                    },
                    {
                        text: "Q2",
                        info: "...",
                        value: 500000.0
                    },
                    {
                        text: "Q3",
                        info: "...",
                        value: 500000.0
                    }
                ]
            },
            {
                name: "Récapitulatif",
                items: [
                    {
                        text: "Capital investi",
                        info: "...",
                        value: 500000.0
                    },
                    {
                        text: "Obligations",
                        info: "...",
                        value: 500000.0
                    },
                    {
                        text: "Compte courant d'associé",
                        info: "...",
                        value: 200000.0
                    }
                ]
            }
        ];

        const financingHistoryPie = [
            {
                label: "Fondateurs",
                value: 400
            },
            {
                label: "Managers",
                value: 200
            },
            {
                label: "Investisseurs Seed",
                value: 200
            },
            {
                label: "Investisseurs Série A",
                value: 200
            }
        ];

        const financingHistoryTable = [
            {
                date: "1er janvier 2020",
                dateInfo: "...",
                raised: "50M€",
                share_price: 500000.0,
                equity: 0.05,
                investors: 1500
            },
            {
                date: "2 janvier 2020",
                dateInfo: "...",
                raised: "100M€",
                share_price: 500000.0,
                equity: 0.05,
                investors: 1000
            },
            {
                date: "5 février 2020",
                dateInfo: "...",
                raised: "100M€",
                share_price: 500000.0,
                equity: 0.05,
                investors: 1000
            },
            {
                total: true,
                raised: "250M€",
                share_price: 200000.0,
                equity: 0.15,
                investors: 5000
            }
        ];

        const captable = [
            {
                name: "BSPCE",
                info: "...",
                price: 500000.0,
                quantity: 800
            },
            {
                name: "AO",
                info: "...",
                price: 500000.0,
                quantity: 100
            }
        ];

        return {
            companyName: "GamersOrigin",
            avatar: "https://pbs.twimg.com/profile_images/1389148540216266752/PD0Qfk4j_400x400.jpg",
            cover: "https://pbs.twimg.com/profile_banners/255108312/1610404858/1500x500",
            tags: ["eSport", "Jeux Vidéos", "innovation"],
            stats,
            description,
            executives,
            ltmRevenues: 5454534.0,
            yearlyRevenues: 56565.0,
            incorporationDate: "8 septembre 1917",
            location: "Paris, France",
            siren: "968374374648383393",
            legalForm: "SARL",
            email: "eGamersOrigin@equisafe.io",
            headquarters: "3 rue Bellini, 92800 Puteaux",
            tables,
            financingHistoryPie,
            financingHistoryTable,
            captable
        };
    },
    computed: {
        valuesTotal() {
            return {
                text: "Total",
                total: true,
                value: this.values.reduce((total, { value = 0 }) => total + value, 0)
            };
        },
        dividendsTotal() {
            return {
                text: "Total",
                total: true,
                value: this.dividends.reduce((total, { value = 0 }) => total + value, 0)
            };
        },
        summaryTotal() {
            return {
                text: "Total",
                total: true,
                value: this.summary.reduce((total, { value = 0 }) => total + value, 0)
            };
        }
    }
};
</script>

<style lang="scss" scoped></style>
