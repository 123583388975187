var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsStepper',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"eqs-main-container margin-auto"},[_c(item.is,_vm._b({tag:"component",attrs:{"editable":""}},'component',{
                    schemaDouble: _vm.schemaDouble,
                    formData: _vm.formData,
                    loading: _vm.loading,
                    edit: !!_vm.representativeId,
                    roles: _vm.representative_roles
                },false))],1)]}}])},'EqsStepper',{ value: 0, steps: _vm.steps, progress: _vm.progress },false))
}
var staticRenderFns = []

export { render, staticRenderFns }