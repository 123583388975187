import Capitalisation from "@/components/views/shareledger/capitalisation/_Capitalisation.vue";
import DigitalizeLedger from "@/components/views/shareledger/capitalisation/digitalizeLedger/_DigitalizeLedger.vue";
import Operations from "@/components/views/shareledger/operations/_Operations.vue";
import Digitalize from "@/components/views/shareledger/capitalisation/digitalize/_Digitalize.vue";
import EntityDetailsDialog from "@/components/views/shareledger/dialogs/details/EntityDetailsDialog.vue";
import ShareledgerDetailsDialog from "@/components/views/shareledger/dialogs/details/ShareledgerDetailsDialog.vue";
import TransactionDetailsDialog from "@/components/views/shareledger/dialogs/details/TransactionDetailsDialog.vue";
import AddMovement from "@/components/views/shareledger/operations/movements/AddMovement";
import AddPayment from "@/components/views/shareledger/operations/payments/AddPayment";
import AskedPayment from "@/components/views/shareledger/operations/payments/asked/AskedPayment";
import InvestorWhitelist from "@/components/views/shareledger/investorWhitelist/_InvestorWhitelist";
import CapitalIncrease from "@/components/views/capitalIncrease/admin/_CapitalIncrease.vue";
import CapitalIncreaseForm from "@/components/views/capitalIncrease/admin/form/_Form.vue";
import CapitalIncreaseDetail from "@/components/views/capitalIncrease/admin/detail/_Detail.vue";
import { i18n } from "@/services/plugins/i18n.js";

import multiguard from "vue-router-multiguard";
import {
    informUserIfNotRegistered,
    requiresCompany,
    requiresDigitalizedShareledger,
    requiresDigitalizedCompany,
    requiresCompanyOnBlockchain,
    requiresCompanyNotOnBlockchain,
    requiresCompanyToBeNotFromUSA,
    informUserUpgradePlan
} from "../routeGuards";
import AddNewPayment from "@/components/views/shareledger/operations/payments/AddNewPayment";
import AddDownloadPayment from "@/components/views/shareledger/operations/payments/AddDownloadPayment";
import AddInvestorStepper from "@/components/views/shareledger/investorWhitelist/AddInvestorStepper/_AddInvestorStepper";
import ValidationStepper from "@/components/views/secondaryMarket/validation/ValidationStepper";

export default [
    {
        path: "/digitalize/:companyId",
        component: Digitalize,
        name: "digitalize",
        beforeEnter: multiguard([
            requiresCompany,
            requiresCompanyNotOnBlockchain,
            informUserUpgradePlan
        ]),
        meta: {
            context: i18n.t("app.navigation-drawer.title.digitalize")
        },
        props: true
    },
    {
        path: "/capitalisation/:companyId",
        component: Capitalisation,
        props: true,
        name: "capitalisation",
        beforeEnter: multiguard([
            requiresCompany,
            requiresDigitalizedCompany,
            informUserUpgradePlan
        ]),
        meta: {
            context: i18n.t("app.navigation-drawer.title.capitalisation")
        },
        children: [
            {
                path: "digitalize-shareledger",
                component: DigitalizeLedger,
                name: "digitalize-shareledger",
                beforeEnter: multiguard([
                    requiresCompany,
                    requiresDigitalizedCompany,
                    informUserUpgradePlan
                ]),
                meta: {
                    context: i18n.t("app.navigation-drawer.title.shareledger-digitalize")
                },
                props: true
            },
            {
                path: "shareholder-details/:entityId(.+)",
                props: true,
                name: "shareholder-details",
                component: EntityDetailsDialog
            },
            {
                path: "shareledger-details/:shareledgerId(.+)",
                props: true,
                name: "shareledger-details",
                component: ShareledgerDetailsDialog
            },
            {
                path: "transaction-details/:transactionId(.+)",
                props: true,
                name: "transaction-details",
                component: TransactionDetailsDialog
            }
        ]
    },
    {
        name: "operations",
        path: "/operations/:companyId",
        component: Operations,
        props: true,
        meta: {
            context: i18n.t("app.navigation-drawer.title.operations")
        },
        beforeEnter: multiguard([
            requiresCompany,
            requiresDigitalizedShareledger,
            informUserUpgradePlan
        ]),
        children: [
            {
                path: "transaction-details/:transactionId(.+)",
                props: true,
                component: TransactionDetailsDialog
            },
            {
                name: "add-movement-operation",
                path: "movement",
                props: true,
                component: AddMovement,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.add-movement-operation")
                }
            },
            {
                name: "add-payment-operation",
                path: "payment",
                props: true,
                component: AddPayment,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.add-payment-operation")
                }
            },
            {
                name: "add-new-payment-operation",
                path: "payment/add",
                props: true,
                component: AddNewPayment,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.add-payment-operation")
                }
            },

            {
                name: "add-download-payment-operation",
                path: "payment/download",
                props: true,
                component: AddDownloadPayment,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.add-payment-operation")
                }
            }
        ]
    },
    {
        name: "asked-payment",
        path: "/payments/asked/:id",
        props: true,
        component: AskedPayment,
        meta: {
            context: i18n.t("app.navigation-drawer.title.payments")
        }
    },
    {
        name: "validation-stepper",
        path: "/order/validation/:id",
        props: true,
        component: ValidationStepper,
        meta: {
            context: i18n.t("app.navigation-drawer.title.validationorder")
        }
    },
    {
        path: "/fundraising/:companyId",
        component: CapitalIncrease,
        name: "fundraising",
        meta: {
            context: i18n.t("app.navigation-drawer.title.fundraising")
        },
        props: true,
        beforeEnter: multiguard([
            requiresCompany,
            requiresDigitalizedCompany,
            informUserUpgradePlan
        ]),
        children: [
            {
                beforeEnter: multiguard([requiresCompanyToBeNotFromUSA, informUserUpgradePlan]),
                path: "add",
                props: true,
                component: CapitalIncreaseForm,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.fundraising-parameters")
                },
                name: "fundraising-add"
            },
            {
                path: "edit/:capitalIncreaseId(.+)",
                props: true,
                component: CapitalIncreaseForm,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.fundraising-parameters")
                },
                name: "fundraising-edit"
            },
            {
                path: "detail/:capitalIncreaseId(.+)",
                props: true,
                name: "fundraising-details",
                component: CapitalIncreaseDetail, // data.selectedSubscriber
                children: [
                    {
                        path: "entity-details/:entityId(.+)",
                        name: "fundraising-details-entity",
                        props: true,
                        component: EntityDetailsDialog
                    }
                ]
            }
        ]
    },
    {
        path: "/investors/:companyId",
        component: InvestorWhitelist,
        props: true,
        name: "investors",
        meta: {
            context: i18n.t("app.navigation-drawer.title.investors")
        },
        beforeEnter: multiguard([requiresCompany, informUserUpgradePlan]),
        children: [
            {
                name: "add-new-investor",
                path: "/investors/:companyId/add",
                props: true,
                component: AddInvestorStepper,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.add-investors")
                }
            }
        ]
    }

    // {
    //   name: "add-investor-operation",
    //   path: "/investors/add/:companyId",
    //   props: true,
    //   component: AddInvestor,
    //   meta: {
    //     context: i18n.t("app.navigation-drawer.title.add-investor-operation"),
    //   },
    // },
];
