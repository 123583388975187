<template>
    <div class="d-flex fill-height">
        <v-row>
            <v-col
                v-for="member in members"
                :key="member.id"
                :cols="$reactive.isDesktop() ? 6 : 12"
            >
                <MemberCard
                    v-bind="{
                        member,
                        loading: localLoading
                    }"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import MemberCard from "./MemberCard";
export default {
    name: "Team",
    components: {
        MemberCard
    },
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            members: [],
            localLoading: false
        };
    },
    async created() {
        try {
            this.localLoading = true;
            const res = await this.$api.getSecondaryMarketPrefMembers(this.company.id);
            this.members = res.data;
        } catch (e) {
            console.error("GET_MEMBERS", e);
            this.$ui.error(this.$t("secondary-market.teams.members-get-error"));
        }
        this.localLoading = false;
    }
};
</script>
