<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row justify="end" class="ma-0 pa-0">
            <v-col v-if="$entity.isAffiliationAccountActive()" cols="auto">
                <v-btn
                    v-if="showAdd"
                    class="custom-btn custom-btn-blue"
                    outlined
                    @click="modalOpen"
                >
                    <v-icon>mdi-plus</v-icon>
                    {{ modalTitle }}
                </v-btn>

                <MoreActionsMenu
                    v-if="showMoreActions"
                    v-bind="{
                        type,
                        idEntity: idEntity,
                        currentInfoEntity: currentInfoEntity
                    }"
                />
            </v-col>
            <v-col v-else cols="auto">
                <v-alert dense border="left" type="warning" prominent>
                    <span style="color: black">
                        {{ $t("universe.kyoseil.actions-buttons.account-not-active") }}
                    </span>
                </v-alert>
            </v-col>
        </v-row>
        <AddEntityModal
            v-bind="{
                isOpen: isModalOpen,
                type: type,
                title: modalTitle
            }"
            @add-modal-close="modalClose()"
            @add-modal-post="modalPost()"
        />
    </v-container>
</template>

<script>
import AddEntityModal from "../modals/AddEntityModal.vue";
import MoreActionsMenu from "./MoreActionsMenu.vue";
import { isRootPage } from "@/components/views/user/clients/tools.js";

export default {
    name: "ActionsButtons",
    components: { AddEntityModal, MoreActionsMenu },
    inject: ["_entity"],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        idEntity: {
            type: String,
            required: false,
            default: ""
        },
        currentInfoEntity: {
            type: Object,
            required: false,
            default: null
        }
    },
    emits: ["entity-add"],
    data() {
        return {
            localLoading: true,

            isModalOpen: false,
            modalTitle: ""
        };
    },
    computed: {
        showAdd() {
            if (!this.idEntity || this.idEntity === "") return false;
            if (!isRootPage(this.$route)) return false;
            if (this.type === "view_cgp" && this.$entity.isAffiliationAdmin()) return false;
            return true;
        },

        showMoreActions() {
            if (!this.idEntity || this.idEntity === "") return false;
            if (isRootPage(this.$route)) return false;
            return true;
        }
    },
    mounted() {
        if (this.type === "view_cgp") {
            this.modalTitle = this.$t("universe.kyoseil.actions-buttons.investor");
        } else if (this.type === "view_bc") {
            this.modalTitle = this.$t("universe.kyoseil.actions-buttons.wm");
        } else if (this.type === "view_admin") {
            this.modalTitle = this.$t("universe.kyoseil.actions-buttons.bc");
        }
    },
    methods: {
        modalOpen() {
            this.isModalOpen = true;
        },
        modalClose() {
            this.isModalOpen = false;
        },
        modalPost(resp) {
            this.$emit("entity-add");
        }
    }
};
</script>

<style lang="scss">
.custom-btn {
    text-transform: none !important;

    .v-icon {
        margin-right: 6px;
    }
}
.custom-btn-blue {
    color: white !important;
    background-color: var(--v-primary-base) !important;
}
</style>
