<template>
    <EqsTabs v-bind="{ tabs, color: 'transparent' }" :value.sync="tab">
        <template #default="{ item }">
            <component
                :is="item.is"
                v-bind="{
                    loading,
                    entity
                }"
            />
        </template>
    </EqsTabs>
</template>

<script>
import Preferences from "./Preferences.vue";
import { mapGetters } from "vuex";
export default {
    name: "Settings",
    components: {
        Preferences,
        Restrictions: () =>
            import("@/components/views/shareledger/restrictions/RestrictionsDashboard")
    },
    props: {
        entity: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        tabs() {
            const accountSettings = {
                id: "preferences",
                title: this.$t("settings.your-account-settings-title"),
                item: {
                    is: "Preferences"
                }
            };
            const companySettings = {
                id: "restrictions",
                title: this.$t("settings.company-restrictions-title"),
                item: {
                    is: "Restrictions"
                }
            };
            return this.entity.is_company && this.entity.blockchainize_state === "FINISHED"
                ? [accountSettings, companySettings]
                : [accountSettings];
        },
        ...mapGetters(["blockchainizeState"])
    }
};
</script>

<style lang="scss" scoped></style>
