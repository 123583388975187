<template>
    <EqsDataTable
        :selected.sync="valueProxy"
        :single-select="false"
        :items="investors"
        item-key="entity.id"
        selectable
        :searchable="{
            placeholder: $t('shareledger.white-list.data-table.search-for-investors')
        }"
        v-bind="{
            loading: loading || investorsLoading,
            headers: investorsTableHeaders,
            investors,
            ...$attrs
        }"
        :footer-props="{
            itemsPerPageOptions: [30, 60]
        }"
        :items-per-page="30"
    >
        <template #item.entity.name="{ item }">
            <div class="cell d-flex flex-row align-center">
                <v-row class="cell d-flex flex-row align-center">
                    <v-col cols="3">
                        <EqsAvatar :user="item.entity ? item.entity : item" :no-badge="true" />
                    </v-col>
                    <v-col cols="9">
                        <EqsTextDisplay
                            style="white-space: nowrap"
                            class="text-left"
                            :value="item.entity ? item.entity.name : item.name"
                            eqs-type="subtitle"
                        />
                        <EqsTextDisplay
                            class="text-left"
                            :value="item.type_display"
                            eqs-type="caption"
                        />
                    </v-col>
                </v-row>
            </div>
        </template>
    </EqsDataTable>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "GlobalSelectInvestorTable",
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        value: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            investorsTableHeaders: [
                {
                    text: this.$t("shareledger.white-list.user-name"),
                    value: "entity.name",
                    filterable: true,
                    align: "center"
                }
            ]
        };
    },
    created() {
        this.getInvestors();
    },
    methods: mapActions(["getInvestors"]),
    computed: {
        ...mapGetters(["investors", "investorsLoading"]),
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    }
};
</script>

<style></style>
