import { render, staticRenderFns } from "./InvestorWhitelistTable.vue?vue&type=template&id=c75f8856&scoped=true"
import script from "./InvestorWhitelistTable.vue?vue&type=script&lang=js"
export * from "./InvestorWhitelistTable.vue?vue&type=script&lang=js"
import style0 from "./InvestorWhitelistTable.vue?vue&type=style&index=0&id=c75f8856&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c75f8856",
  null
  
)

export default component.exports