<template>
    <div class="d-flex flex-column">
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                <EqsSelect
                    :value.sync="formData.type"
                    v-bind="{
                        items: [
                            { text: 'IBAN', value: 1 },
                            { text: 'BBAN/RIB', value: 2 },
                            { text: $t('label.other'), value: 0 }
                        ],
                        placeholder: 'Ex: IBAN',
                        elClass: 'account_type',
                        label: $t('label.account-type'),
                        editable,
                        loading,
                        ...schemaDouble.type
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                <EqsFileInput
                    :value.sync="formData.doc_bank"
                    v-bind="{
                        accept: '.pdf',
                        elClass: 'doc-bank',
                        label: $t('registration.rib'),
                        editable,
                        loading,
                        ...schemaDouble.doc_bank
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.holder"
                    v-bind="{
                        elClass: 'bank-holder',
                        placeholder: $t('user.wallet.bank.holder-placeholder'),
                        label: $t('user.wallet.bank.holder'),
                        editable,
                        loading,
                        ...schemaDouble.holder
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.iban"
                    v-bind="{
                        elClass: 'bank-account-number',
                        label: $t('user.wallet.bank.account-number'),
                        placeholder: $t('user.wallet.bank.account-number-placeholder'),
                        editable,
                        loading,
                        ...schemaDouble.iban
                    }"
                />
            </v-col>
        </v-row>

        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                <EqsAutocomplete
                    :value.sync="formData.holder_country"
                    return-object
                    v-bind="{
                        items: countries,
                        itemText: 'name',
                        placeholder: $t('user.wallet.bank.holder-country-placeholder'),
                        elClass: 'holder_country',
                        label: $t('user.wallet.bank.holder-country'),
                        editable,
                        loading: loading || countriesLoading,
                        ...schemaDouble.holder_country
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                <EqsAutocomplete
                    :value.sync="formData.bank_country"
                    return-object
                    v-bind="{
                        items: countries,
                        itemText: 'name',
                        placeholder: $t('user.wallet.bank.bank-country-placeholder'),
                        elClass: 'bank_country',
                        label: $t('user.wallet.bank.bank-country'),
                        editable,
                        loading: loading || countriesLoading,
                        ...schemaDouble.bank_country
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.bic"
                    v-bind="{
                        elClass: 'bank-bic',
                        placeholder: $t('user.wallet.bank.bic-placeholder'),
                        label: $t('user.wallet.bank.bic'),
                        editable,
                        loading,
                        ...schemaDouble.bic
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.name"
                    v-bind="{
                        elClass: 'bank-name',
                        placeholder: $t('user.wallet.bank.bank-name-placeholder'),
                        isRequired: false,
                        label: $t('user.wallet.bank.bank-name'),
                        editable,
                        loading,
                        ...schemaDouble.name
                    }"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "InternationalForm",
    mixins: [formMixin],
    computed: mapGetters(["countries", "countriesLoading"]),
    methods: mapActions(["getCountries"]),
    created() {
        this.getCountries();
    }
};
</script>

<style lang="scss" scoped></style>
