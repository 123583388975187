import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";
import { delayedPromise, mergeArraysByKey } from "@equisafe-ui-shared/util";

export default async ({ commit }, toUpdate) => {
    DEBUG("UPDATE_NOTIFICATIONS_START");
    try {
        const { notifications, options } = toUpdate;
        const notificationsIdList = notifications.map(notif => {
            return { id: notif.id };
        });
        const notificationsRes = await window.app.$api.updateNotifications({
            notifications: notificationsIdList,
            ...options
        });
        /**await delayedPromise(2000, {
      data: notifications.map(notif => {
        const { shown, } = options;
        notif.shown = shown;
        return notif;
      }),
    });*/
        DEBUG("UPDATE_NOTIFICATIONS_SUCCESS");
        // const mergedNotifs = mergeArraysByKey(
        //     store.getters.notifications,
        //     notificationsRes.data,
        //     "id"
        // );
        commit("SET_NUMBER_OF_NEW_NOTIFICATIONS", notificationsRes.data.count);
        commit("UPDATE_NOTIFICATIONS", notificationsIdList);
    } catch (e) {
        console.error({ e });
        DEBUG("UPDATE_NOTIFICATIONS_ERROR");
        throw e;
    }
};
