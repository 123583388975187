<template>
    <EqsDialog
        :value.sync="dialogProxy"
        closable
        v-bind="{
            title: $t('universe.kyoseil.disable-modal.deactivate')
        }"
    >
        <v-container class="container-info-modal">
            <div>
                <p>
                    {{
                        $t("universe.kyoseil.disable-modal.warning", {
                            name: currentInfoEntity?.name || ""
                        })
                    }}
                </p>
            </div>
            <v-row class="d-flex justify-center mt-6">
                <v-btn
                    class="custom-button-modal mr-4"
                    outlined
                    color="primary"
                    @click="closeDialog()"
                    >{{ $t("universe.kyoseil.adding-modal.cancel") }}</v-btn
                >
                <v-btn
                    class="custom-button-modal text-white"
                    color="red"
                    @click="deactivateAcc()"
                    >{{ $t("universe.kyoseil.disable-modal.deactivate") }}</v-btn
                >
            </v-row>
        </v-container>
    </EqsDialog>
</template>

<script>
export default {
    name: "AccountActivationModal",
    props: {
        open: {
            type: Boolean,
            required: true
        },
        currentInfoEntity: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            offeringID: "",
            validID: false,
            offeringDirty: false,
            selectClientType: ""
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.open;
            },
            set(val) {
                if (!val) this.closeDialog();
            }
        }
    },
    methods: {
        closeDialog() {
            this.$emit("closeDialog");
            this.$emit("update:open", false);
        },
        deactivateAcc() {
            this.$emit("deactivate");
            this.$emit("closeDialog");
        }
    }
};
</script>

<style>
.container-info-modal {
    text-align: start;
}

.custom-button-modal {
    width: 45%;
    height: 44px !important;
    text-transform: none !important;
}

.label-text {
    font-family: "Montserrat-Regular";
    font-style: normal;
    font-size: 16px;
    color: #0d0d2b;
}

.title-section {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0d0d2b;
}

.custom-action-btn-client {
    width: 125px;
    height: 44px !important;
}
</style>
