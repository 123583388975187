<template>
    <EqsDialog
        v-model="dialogProxy"
        v-bind="{
            confirm: {
                fn: openTxExplorerPage,
                txt: $t('molecule.dialog.go-to-explorer')
            },
            cancel: {}
        }"
        @close="() => (dialogProxy = false)"
    >
        <div class="d-flex flex-column fill-height">
            <VueQrcode v-if="link" :value="link" class="vue-qr-code" :options="{ width: '100%' }" />
            <GlobalCopyBadge v-if="tx" class="mx-7" :value="tx" :truncate="45" />
        </div>
    </EqsDialog>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
    name: "ExplorerDialog",
    components: {
        VueQrcode
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        tx: {
            type: String,
            default: ""
        },
        link: {
            type: String,
            default: ""
        }
    },
    computed: {
        dialogProxy: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) this.$emit("close");
                this.$emit("update:value", val);
            }
        }
    },
    methods: {
        openTxExplorerPage() {
            window.open(this.link);
        }
    }
};
</script>

<style lang="scss" scoped>
.vue-qr-code {
    width: 100% !important;
    height: auto !important;
}
</style>
