<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2>SEPA</h2>
                <div v-if="showSepaStart">
                    <v-btn
                        :disabled="localLoading || billingRequestId !== null"
                        @click.stop="startSepa"
                    >
                        Lancer la procedure
                    </v-btn>
                    <p v-if="sepaLink">
                        Link : <a :href="sepaLink" target="_blank">Redirection flow</a>
                    </p>
                </div>
                <div>
                    <v-btn :disabled="localLoading" class="mt-4" @click.stop="checkStatus">
                        Check status
                    </v-btn>
                    <p v-if="sepaStatus">Status billing request: {{ sepaStatus }}</p>
                    <p v-if="paymentStatus">Status payment: {{ paymentStatus }}</p>
                </div>
            </v-col>
        </v-row>
        <v-input v-model="sepaDone" :rules="ruleSepaDone" type="hidden" />
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "SepaAutoConfirmation",
    mixins: [formMixin],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            billingRequestId: null,
            sepaDone: false,
            sepaLink: null,
            sepaStatus: null,
            paymentStatus: null,
            localLoading: false
        };
    },
    computed: {
        ruleSepaDone() {
            return [value => !!value && value === true];
        },
        showSepaStart() {
            if (!this.sepaStatus) {
                return true;
            } else if (this.sepaStatus === "fulfilled") {
                return false;
            }
            return true;
        }
    },
    beforeMount() {
        const data = this.order?.payment_data?.sepaautodata;
        if (data?.billing_request) {
            this.billingRequestId = data.billing_request.id;
            this.sepaStatus = data.billing_request.status;
            this.sepaLink = data.billing_request?.flow?.url;
            this.paymentStatus = data.billing_request?.payment?.status;
        }
    },
    methods: {
        async startSepa() {
            this.localLoading = true;
            try {
                const res = await this.$api.request("post", "api/gocardless/start/", {
                    orderId: this.order.id
                });
                this.sepaLink = res.data.flow.url;
                this.billingRequestId = res.data.id;
                window.open(this.sepaLink, "_blank");
            } catch (e) {
                console.error("Cant create sepa: ", e);
                this.$ui.error(`Impossible de lancer la procedure (${e?.statusText})`);
            } finally {
                this.localLoading = false;
            }
        },
        async checkStatus() {
            this.localLoading = true;
            try {
                const res = await this.$api.request(
                    "get",
                    `api/gocardless/${this.billingRequestId}/`
                );
                this.sepaStatus = res.data.status;
                this.paymentStatus = res.data.payment?.status;
                if (this.paymentStatus === "paid_out") {
                    this.sepaDone = true;
                }
            } catch (e) {
                console.error("Cant check status: ", e);
                this.$ui.error(`Impossible de checker (${e?.statusText})`);
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>
