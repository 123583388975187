import isEmpty from "lodash/isEmpty";
import { mapGetters, mapActions } from "vuex";
import { getDiffArrayWith } from "@equisafe-ui-shared/util";
import GlobalSignatureTemplate from "@/components/global/globalSignatureTemplate/GlobalSignatureTemplate.vue";

export const procedureEditMixin = {
    data() {
        return {
            signature_procedure: {}
        };
    },
    components: {
        GlobalSignatureTemplate
    },
    computed: {
        hasDocsToSign() {
            return (
                (!!this.formData.proceduredocument_set &&
                    this.formData.proceduredocument_set.length > 0) ||
                (!!this.signature_procedure.proceduredocument_set &&
                    this.signature_procedure.proceduredocument_set.length > 0)
            );
        }
    },
    methods: {
        ...mapActions(["fetchProcedure"]),
        async getProcedure(id, localEnv) {
            try {
                this.signature_procedure = await this.fetchProcedure(id, localEnv);
            } catch (e) {
                this.$ui.error(e, "get_procedure");
            }
        },
        getDocsToSign() {
            return this.signature_procedure && this.signature_procedure.proceduredocument_set
                ? this.signature_procedure.proceduredocument_set
                : [];
        },
        getProcedureIdFromURL(procedureURL) {
            return procedureURL.split("/").slice(-2, -1).pop();
        },
        async addProcedureDoc(doc) {
            try {
                if (!isEmpty(this.signature_procedure)) {
                    let data = {
                        signature_procedure: this.signature_procedure.url
                    };
                    data[doc.base_document ? "base_document_id" : "html"] = doc.base_document
                        ? doc.base_document.id
                        : doc.html;
                    const res = await this.$api.createProcedureDoc(data);
                    return this.$api.setSignersIntoDoc(
                        res.data.id,
                        doc.proceduredocumentsigner_set.map(signer => {
                            return {
                                signer_id: signer.id,
                                procedure_document_id: res.data.id
                            };
                        })
                    );
                }
            } catch (e) {
                this.$ui.error(e, "create_procedure_doc");
                throw e;
            }
        },
        async updateProcedureDoc(doc) {
            try {
                const oldDoc =
                    this.signature_procedure.proceduredocument_set.find(
                        oldDoc => oldDoc.id === doc.id
                    ) || {};
                if (doc.base_document) {
                    if (!oldDoc.base_document || oldDoc.base_document.id !== doc.base_document.id)
                        await this.$api.updateProcedureDoc(doc.id, {
                            base_document_id: doc.base_document.id
                        });
                }
                await this.$api.updateProcedureDoc(doc.id, { html: doc.html });
                const signerToAdd =
                    getDiffArrayWith(
                        doc.proceduredocumentsigner_set,
                        oldDoc.proceduredocumentsigner_set
                    ) || [];
                const signerToRemove =
                    getDiffArrayWith(
                        oldDoc.proceduredocumentsigner_set,
                        doc.proceduredocumentsigner_set
                    ) || [];
                if (signerToAdd.length || signerToRemove.length)
                    return this.$api.setSignersIntoDoc(
                        doc.id,
                        doc.proceduredocumentsigner_set.map(signer => {
                            return {
                                signer_id: signer.id,
                                procedure_document_id: doc.id
                            };
                        })
                    );
            } catch (e) {
                console.error(`error in updateproceduredoc`);
                console.error(e);
                throw e;
            }
        },
        async deleteProcedureDoc(doc) {
            try {
                if (doc.id) {
                    await this.$api.deleteProcedureDoc(doc.id);
                    await this.getProcedure(this.signature_procedure.id);
                }
            } catch (e) {
                this.$ui.error(e, "remove_procedure_doc");
            }
        },
        async handleProcedureDoc(doc) {
            if (!doc.html && !doc.base_document) {
                return;
            } else if (!doc.id) {
                return this.addProcedureDoc(doc);
            }
            return this.updateProcedureDoc(doc);
        },
        async updateProcedureDocs() {
            try {
                const results = await Promise.allSettled(
                    this.formData.proceduredocument_set.map(async doc =>
                        this.handleProcedureDoc(doc)
                    )
                );
                results.forEach((res, index) => {
                    if (res.status === "rejected") {
                        throw res;
                    }
                });
                await this.getProcedure(this.signature_procedure.id);
            } catch (e) {
                this.$ui.error(e, "create_procedure_doc");
                throw e;
            }
        },
        checkEntityCanSign(cancel) {
            if (!cancel) {
                console.error("You need a cancel object with {fn, txt} to use checkEntityCanSign");
            } else {
                const entity = this.$entity.represented();
                if (entity.account_type !== "individual" && !entity.id_signatory) {
                    this.$dialog.warn({
                        title: this.$t("secondary-market.sign-stepper.dialog-signatory.title"),
                        text: this.$t("secondary-market.sign-stepper.dialog-signatory.text"),
                        confirm: {
                            txt: this.$t(
                                "secondary-market.sign-stepper.dialog-signatory.confirm-btn"
                            ),
                            fn: () => {
                                this.$router.push({
                                    name: "manage-team",
                                    params: {
                                        id: entity.id
                                    }
                                });
                            }
                        },
                        cancel
                    });
                }
            }
        }
    }
};

export const procedureSignerMixin = {
    methods: {
        ...mapActions(["getProcedure", "getProcedures", "fetchProcedure", "fetchProcedureLocal"]),
        checkEntityCanSign(cancel) {
            if (!cancel) {
                console.error("You need a cancel object with {fn, txt} to use checkEntityCanSign");
            } else {
                const entity = this.$entity.represented();
                if (entity.account_type !== "individual" && !entity.id_signatory) {
                    this.$dialog.warn({
                        title: this.$t("secondary-market.sign-stepper.dialog-signatory.title"),
                        text: this.$t("secondary-market.sign-stepper.dialog-signatory.text"),
                        confirm: {
                            txt: this.$t(
                                "secondary-market.sign-stepper.dialog-signatory.confirm-btn"
                            ),
                            fn: () => {
                                this.$router.push({
                                    name: "manage-team",
                                    params: {
                                        id: entity.id
                                    }
                                });
                            }
                        },
                        cancel
                    });
                }
            }
        },
        signatureRefused() {
            this.stepperState = "warning";
            this.step++;
        },
        getProcedureIdFromURL(procedureURL) {
            return procedureURL.split("/").slice(-2, -1).pop();
        }
    },
    created() {
        // if (!this.procedureId) {
        //   console.error('You need procedureId for procedureSignerMixin to work')
        // }
    },
    watch: {
        procedureId: {
            immediate: true,
            handler(val) {
                if (val) this.getProcedure(val);
            }
        },
        signatureId: {
            immediate: true,
            handler(val) {
                if (val) this.getProcedures();
            }
        }
    },
    computed: {
        ...mapGetters(["procedures", "proceduresLoading"]),
        signature_procedure() {
            if (this.signatureId || this.procedureId) {
                return this.procedures.find(_signature_procedure => {
                    const id = this.signatureId || this.procedureId;
                    return _signature_procedure.id === id;
                });
            }
            return {};
        },
        signer() {
            if (isEmpty(this.signature_procedure)) return {};
            return this.signature_procedure.signer_set.find(
                signer => signer.entity_id === this.$entity.represented().id
            );
        },
        signatureDone() {
            if (!this.signer) return false;
            return ["finished", "done"].includes(this.signer.status);
        }
    }
};
