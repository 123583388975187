import { i18n } from "@/services/plugins/i18n.js";

export const types = {
    real_estate: {
        txt: i18n.t("user.company.industry-types.real-estate")
    },
    investment_vehicles: {
        txt: i18n.t("user.company.industry-types.investment-vehicles")
    },
    technology: {
        txt: i18n.t("user.company.industry-types.technology")
    },
    financial: {
        txt: i18n.t("user.company.industry-types.financial")
    },
    telecom: {
        txt: i18n.t("user.company.industry-types.telecom")
    },
    service: {
        txt: i18n.t("user.company.industry-types.service")
    },
    manufacturing: {
        txt: i18n.t("user.company.industry-types.manufacturing")
    },
    it: {
        txt: i18n.t("user.company.industry-types.it")
    },
    hospitality: {
        txt: i18n.t("user.company.industry-types.hospitality")
    },
    art: {
        txt: i18n.t("user.company.industry-types.art")
    },
    fashion: {
        txt: i18n.t("user.company.industry-types.fashion")
    },
    entertainment: {
        txt: i18n.t("user.company.industry-types.entertainment")
    },
    advertising: {
        txt: i18n.t("user.company.industry-types.advertising")
    },
    agriculture: {
        txt: i18n.t("user.company.industry-types.agriculture")
    },
    education: {
        txt: i18n.t("user.company.industry-types.education")
    },
    music: {
        txt: i18n.t("user.company.industry-types.music")
    }
};

export const industryTypes = Object.entries(types).map(entry => {
    const [key, val] = entry;
    return { id: key, ...val };
});

export const realEstate = {
    rental: {
        txt: i18n.t("user.company.real-estate-type.rental")
    },
    commercial: {
        txt: i18n.t("user.company.real-estate-type.commercial")
    },
    capitalisation: {
        txt: i18n.t("user.company.real-estate-type.capitalisation")
    },
    distribution: {
        txt: i18n.t("user.company.real-estate-type.distribution")
    }
};

export const realEstateTypes = Object.entries(realEstate).map(entry => {
    const [key, val] = entry;
    return { id: key, ...val };
});
