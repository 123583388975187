<template>
    <div class="eqs-confirm-dialog" :el-class="elClass">
        <EqsDialog
            v-if="type"
            :value.sync="value"
            persistent
            v-bind="{
                ...type,
                eqsType: ['default', type.eqsType],
                eqsDataType: ['default', type.eqsType],
                ...props
            }"
        />
        <div style="display: none" :data-test="`${elClass}-${value}`" />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsConfirmDialog.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import events from "../../../events";

const TYPES = {
    info: {
        eqsType: "info",
        elClass: "info-popup"
    },
    warn: {
        eqsType: "warn",
        elClass: "warning-popup"
    },
    confirm: {
        eqsType: "confirm",
        elClass: "confirm-popup"
    },
    alert: {
        eqsType: "alert",
        elClass: "alert-popup"
    }
};

import EqsDialog from "../../molecules/EqsDialog/EqsDialog.vue";

export default {
    name: "EqsConfirmDialog",
    components: {
        EqsDialog
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-confirm-dialog"
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            type: null,
            value: false
        };
    },
    watch: {
        value(newValue, oldValue) {
            if (!newValue && oldValue) {
                this.type = null;
                this.props = null;
            }
        }
    },
    created() {
        events.$on("dialog", this.dialog);
    },
    destroyed() {
        events.$off("dialog", this.dialog);
    },
    methods: {
        dialog(type, props) {
            this.type = TYPES[type];
            this.props = props;
            this.value = true;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsConfirmDialog.scss";
</style>
