<template>
    <div
        class="d-flex fill-height fill-width flex-wrap justify-center global-signature-template-container"
    >
        <div class="d-flex flex-column global-signature-template-form">
            <v-row class="px-3" align-content="start">
                <slot name="form" />
                <v-col class="eqs-form-field" cols="12">
                    <EqsSelect
                        :value.sync="selectedDoc"
                        return-object
                        v-bind="{
                            loading,
                            items,
                            errorMessages: noSignatoriesInDocError,
                            clearable: false,
                            label: $t('equisafe-ui.signature-template.selected-document')
                        }"
                    >
                        <template #prepend-item>
                            <v-list-item @click="addDoc">
                                <v-icon class="px-2"> mdi-plus </v-icon>
                                <v-list-item-content class="ml-2">
                                    {{ $t("equisafe-ui.signature-template.add-document") }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="pb-2" />
                        </template>
                    </EqsSelect>
                </v-col>
                <v-col v-if="value.length > 1 || !!selectedDoc.id" class="eqs-form-field" cols="12">
                    <EqsBtn v-bind="{ color: 'error', action: deleteDoc, loading }">
                        {{ $t("equisafe-ui.signature-template.actions.delete-btn") }}
                    </EqsBtn>
                </v-col>
                <v-col class="eqs-form-field" cols="12">
                    <EqsBtn
                        v-bind="{
                            color: 'success',
                            loading,
                            action: () => (dialogSigners = true)
                        }"
                    >
                        {{ $t("equisafe-ui.signature-template.actions.select-signatories") }}
                    </EqsBtn>
                    <InternalSignersDialog
                        v-if="selectedDoc.proceduredocumentsigner_set"
                        :value.sync="dialogSigners"
                        :proceduredocumentsigner_set.sync="selectedDoc.proceduredocumentsigner_set"
                        v-bind="{ items: signatories }"
                    />
                </v-col>
                <v-col class="eqs-form-field" cols="12">
                    <EqsFileInput
                        :value.sync="baseDocProxy"
                        v-bind="{
                            isRequired: false,
                            label: $t('equisafe-ui.signature-template.actions.upload-doc'),
                            accept: '.pdf',
                            deleteFunc: deleteDoc
                        }"
                    />
                </v-col>
            </v-row>
        </div>
        <v-slide-x-transition>
            <EqsTabs
                v-if="!isUploadedDoc"
                :value.sync="tab"
                class="global-signature-template-tabs"
                v-bind="{
                    tabs,
                    eqsType: ['default', 'no-overflow'],
                    eqsDataType: 'dense'
                }"
            >
                <template #default="{ item }">
                    <component
                        :is="item.is"
                        class="global-signature-template"
                        :value.sync="selectedDoc.html"
                        v-bind="{
                            populateEditor,
                            otherExtensions,
                            signature_procedure,
                            html: selectedDoc.html,
                            loading,
                            isRequired,
                            signatories: selectedDoc.proceduredocumentsigner_set || []
                        }"
                    >
                        <template #actions>
                            <EqsBtnMenu
                                v-bind="{
                                    color: 'primary',
                                    infoTxt: $t(
                                        'equisafe-ui.signature-template.actions.add-variables-tooltip'
                                    ),
                                    items: variables,
                                    loading: loading || loadingVariables
                                }"
                                class="px-2 py-2"
                                @selected-item="addVariable"
                            >
                                {{ $t("equisafe-ui.signature-template.actions.add-variables") }}
                            </EqsBtnMenu>
                            <slot
                                name="actions"
                                v-bind="{
                                    loading: loading || loadingVariables,
                                    variables,
                                    signatories,
                                    insertContent: editor.commands.insertContent
                                }"
                            />
                        </template>
                    </component>
                </template>
            </EqsTabs>
            <InternalPdfView
                v-else
                class="pdf-preview"
                v-bind="{
                    signature_procedure,
                    signatories: selectedDoc.proceduredocumentsigner_set,
                    document: selectedDoc.base_document
                }"
            />
        </v-slide-x-transition>
    </div>
</template>

<script>
import GlobalTextEditor from "../globalTextEditor/GlobalTextEditor";
import { VueRenderer } from "@tiptap/vue-2";
import Mention from "@tiptap/extension-mention";
import InternalVariableList from "./_internal/InternalVariableList";
import InternalPdfView from "./_internal/InternalPdfView";
import InternalSignersDialog from "./_internal/InternalSignersDialog";
import tippy from "tippy.js";

// https://www.tiptap.dev/guide/custom-extensions/#attributes
const Variable = Mention.extend({
    addAttributes() {
        // Return an object with attribute configuration
        return {
            ...this.parent?.(),
            eqsvalue: {
                parseHTML: element => element.getAttribute("eqsvalue")
            },
            "data-mention": {
                parseHTML: element => element.getAttribute("data-mention")
            }
        };
    }
});

export default {
    name: "GlobalSignatureTemplate",
    components: {
        GlobalTextEditor,
        InternalPdfView,
        InternalSignersDialog
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        signature_procedure: {
            type: Object,
            required: true
        },
        variable: {
            type: Boolean,
            default: true
        },
        deleteFunc: {
            type: Function,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            editor: null,
            mentions: null,
            variables: [],
            loadingVariables: false,
            tab: null,
            selectedDoc: {},
            dialogSigners: false,
            tabs: [
                {
                    id: "edit",
                    title: this.$t("equisafe-ui.signature-template.edition"),
                    item: {
                        is: "GlobalTextEditor"
                    }
                },
                {
                    id: "preview",
                    title: this.$t("equisafe-ui.signature-template.oreview"),
                    item: {
                        is: "InternalPdfView"
                    }
                }
            ]
        };
    },
    computed: {
        items() {
            return this.value.map((val, index) => {
                val.text = `${this.$t("label.document")} ${index + 1}`;
                return val;
            });
        },
        signatories() {
            if (this.signature_procedure && this.signature_procedure.signer_set)
                return this.signature_procedure.signer_set.map(this.updateSigner);
            return [];
        },
        noSignatoriesInDocError() {
            for (let doc of this.value) {
                if (!doc.proceduredocumentsigner_set || !doc.proceduredocumentsigner_set.length)
                    return this.$t("equisafe-ui.signature-template.no-signatory-in-doc-error");
            }
            return "";
        },
        otherExtensions() {
            return [
                Variable.configure({
                    HTMLAttributes: {
                        class: "variable"
                    },
                    suggestion: {
                        char: "#",
                        items: component => {
                            const { query } = component;
                            return !query
                                ? this.variables
                                : this.variables
                                      .filter(item =>
                                          item.txt.toLowerCase().startsWith(query.toLowerCase())
                                      )
                                      .slice(0, 10);
                        },
                        render: () => {
                            let component;
                            let popup;

                            return {
                                onStart: props => {
                                    component = new VueRenderer(InternalVariableList, {
                                        parent: this,
                                        propsData: props,
                                        editor: props.editor
                                    });
                                    popup = tippy("body", {
                                        getReferenceClientRect: props.clientRect,
                                        appendTo: () => document.body,
                                        content: component.element,
                                        showOnCreate: true,
                                        interactive: true,
                                        trigger: "manual",
                                        placement: "bottom-start"
                                    });
                                },
                                onUpdate(props) {
                                    component.updateProps(props);
                                    popup[0].setProps({
                                        getReferenceClientRect: props.clientRect
                                    });
                                },
                                onKeyDown(props) {
                                    if (props.event.key === "Escape") {
                                        popup[0].hide();
                                        return true;
                                    }
                                    return component.ref?.onKeyDown(props);
                                },
                                onExit() {
                                    popup[0].destroy();
                                    component.destroy();
                                }
                            };
                        }
                    }
                })
            ];
        },
        isUploadedDoc() {
            return this.selectedDoc && this.selectedDoc.base_document;
        },
        baseDocProxy: {
            get() {
                const base_doc = this.selectedDoc.base_document;
                return base_doc ? { ...base_doc } : null;
            },
            set(val) {
                this.$set(this.selectedDoc, "base_document", val);
            }
        }
    },
    watch: {
        signature_procedure: {
            immediate: true,
            async handler(val) {
                if (!val || !val.id) return;
                if (val.proceduredocument_set && !val.proceduredocument_set.length) {
                    this.$emit("update:value", [
                        { html: "", proceduredocumentsigner_set: this.signatories }
                    ]);
                } else if (val.proceduredocument_set) {
                    this.$emit(
                        "update:value",
                        val.proceduredocument_set.map(doc => {
                            return {
                                ...doc,
                                proceduredocumentsigner_set: doc.proceduredocumentsigner_set.map(
                                    doc_signer => this.updateSigner(doc_signer.signer)
                                )
                            };
                        })
                    );
                }
                this.$nextTick(() => {
                    this.selectedDoc = this.items[0];
                });
                if (this.variable) await this.fetchVariableBoxes(val);
            }
        }
    },
    methods: {
        populateEditor(editor) {
            this.editor = editor;
        },
        addDoc() {
            this.$emit("update:value", [
                ...this.value,
                {
                    html: "",
                    proceduredocumentsigner_set: this.signatories
                }
            ]);
            this.$nextTick(() => {
                this.selectedDoc = this.items[this.items.length - 1];
            });
        },
        async deleteDoc(id) {
            try {
                if (this.deleteFunc) await this.deleteFunc(this.selectedDoc);
                else await this.deleteProcedureDoc(this.selectedDoc);
                await this.$api.deleteDocument(id || this.selectedDoc.base_document.id);
                if (!this.selectedDoc.id) {
                    const index = this.items.findIndex(item => this.selectedDoc.text === item.text);
                    let newVal = JSON.parse(JSON.stringify(this.value));
                    if (index > -1) {
                        newVal.splice(index, 1);
                        this.$emit("update:value", newVal);
                    }
                }
                this.$nextTick(() => {
                    if (!this.items.length) this.addDoc();
                    else {
                        this.selectedDoc = this.items[this.items.length - 1];
                    }
                });
            } catch (e) {
                throw e;
            }
        },
        addVariable(item) {
            const txt = item.txt;
            const value = item.value;
            const variable = `<span class="variable" eqsvalue="${value}" data-mention="${txt}" contenteditable="false" data-id="${txt}"></span>`;
            this.editor.commands.insertContent(variable);
        },
        updateSigner(signer) {
            if (!signer) return null;
            const roles = {
                buyer: this.$t("procedure.role.buyer"),
                seller: this.$t("procedure.role.seller"),
                emitter: this.$t("procedure.role.emitter"),
                receiver: this.$t("procedure.role.receiver")
            };
            return {
                txt: roles[signer.role] || signer.entity.name,
                ...signer
            };
        },
        async fetchVariableBoxes(procedure) {
            try {
                this.loadingVariables = true;
                this.variables = [];
                let res = await this.$api.getVariableBoxes(procedure.id);
                const variableBoxes = {
                    "capincorder.cca_amount": "CCA montant",
                    "capincorder.cca_amount_inverse": "CCA montant inverse",
                    "capincorder.capincorderpaymentdata.payment_data.cryptodata.crypto_selected.protocol":
                        "Crypto: protocole",
                    "capincorder.capincorderpaymentdata.payment_data.cryptodata.crypto_selected.name":
                        "Crypto: nom",
                    "capincorder.capincorderpaymentdata.payment_data.cryptodata.amount":
                        "Crypto: quantité",
                    "capincorder.capincorderpaymentdata.payment_data.cryptodata.address":
                        "Crypto: adresse source",
                    "capincorder.capincorderpaymentdata.payment_data.cryptodata.rate_eur":
                        "Crypto: taux (vers EUR)",
                    "capincorder.capincorderpaymentdata.payment_data.cryptodata.crypto_selected.address":
                        "Crypto: adresse destination",
                    "signer.entity.name": this.$t("procedure.variable.signer-entity-name"),
                    "signer.entity.signatory_name": this.$t(
                        "procedure.variable.signer-entity-signatory-name"
                    ),
                    "signer.entity.complete_identity": this.$t(
                        "procedure.variable.signer-entity-complete-identity"
                    ),
                    "signer.entity.wallet_id": this.$t(
                        "procedure.variable.signer-entity-wallet-id"
                    ),
                    "buyorder.quantity": this.$t("procedure.variable.buyorder-quantity"),
                    "sellorder.price": this.$t("procedure.variable.sellorder-price"),
                    "capincorder.numberofshares": this.$t(
                        "procedure.variable.capincorder-numberofshares"
                    ),
                    "capincorder.total_price": this.$t("procedure.variable.capincorder-price")
                };
                let variables = res.data.filter(variable => {
                    if (!variable.context) return true;
                    if (this.signature_procedure.template_type === "odm") {
                        return variable.context === "secondary_market";
                    } else if (this.signature_procedure.template_type === "capital_increase") {
                        return variable.context === "capinc";
                    } else {
                        return true;
                    }
                });
                for (let variable of variables) {
                    let txt = variable.display;
                    if (variable.value in variableBoxes) {
                        txt = variableBoxes[variable.value];
                    }
                    if (variable.per_signer) {
                        for (let signer of this.signatories) {
                            const txt_signer = `${signer.txt} ${txt}`;
                            const value = `${variable.value} ${
                                signer.entity ? signer.entity.id.split("-")[0] : signer.role
                            }`;
                            const entity_id = signer.entity ? signer.entity.id : null;
                            this.variables.push({
                                ...variable,
                                txt: txt_signer,
                                value,
                                entity_id
                            });
                        }
                    } else {
                        this.variables.push({ ...variable, txt });
                    }
                }
            } catch (e) {
                this.$ui.error(e, "signature_placement_fetch_pdf");
            } finally {
                this.loadingVariables = false;
            }
        }
    }
};
</script>

<style lang="scss">
.global-signature-template-container {
    height: inherit;
    display: flex !important;
    .global-signature-template-form {
        max-width: 300px;
    }
    .global-signature-template-tabs {
        height: 100%;
        // flex: 1;
        width: 22cm;
        .global-signature-template {
            height: 100%;
            .variable {
                color: var(--v-primary-base);
                background-color: var(--v-primary-lighten4);
                border-radius: 0.3rem;
                padding: 0.1rem 0.3rem;
            }
        }
    }
    .pdf-preview {
        height: 100%;
        // flex: 1;
        width: 22cm;
        .eqs-skeleton__container__content {
            object {
                width: inherit;
            }
        }
    }
}
</style>
