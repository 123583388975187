<template>
    <div class="max-height-fit fill-height">
        <EqsDataTable
            class="max-height-fit fill-width"
            v-bind="{
                headers,
                items: orders,
                loading: localLoading,
                noDataCustom: {
                    title: $t('secondary-market.follow-buys.no-data-title'),
                    text: $t('secondary-market.follow-buys.no-data-text'),
                    img: '/images/secondaryMarket/euro.svg'
                }
            }"
        >
            <template #item.company="{ item }">
                <div class="d-flex flex-row align-center">
                    <EqsAvatar
                        class="pr-3"
                        v-bind="{
                            user: item,
                            eqsType: 'list',
                            noBadge: true
                        }"
                    />
                    {{ item.company }}
                </div>
            </template>
            <template #item.name="{ item, index }">
                <div :el-class="`purchase-offer-shareledger_name-${index}`">
                    {{ item.name }}
                </div>
            </template>

            <template #item.quantity="{ item, index }">
                <div :el-class="`purchase-offer-numberofshares-${index}`">
                    {{ item.quantity }}
                </div>
            </template>
            <template #item.price="{ item, index }">
                <div :el-class="`purchase-offer-pricepershare-${index}`">
                    {{ $currency.displayWithCurrency(item.price, item.currency) }}
                </div>
            </template>
            <template #item.total="{ item, index }">
                <div :el-class="`purchase-offer-total_buy-${index}`">
                    {{ displayTotal(item) }}
                </div>
            </template>
            <template #item.buyerStatus="{ item }">
                <EqsChip v-bind="buy_order_statutes[item.status]">
                    {{ buy_order_statutes[item.status].txt }}
                </EqsChip>
            </template>
            <template #item.action="{ item }">
                <EqsActions v-if="getActions(item).length" v-bind="{ actions: getActions(item) }" />
            </template>
        </EqsDataTable>
    </div>
</template>

<script>
import { buy_order_statutes } from "@/util/secondaryMarket.js";

export default {
    name: "FollowBuys",
    props: {},
    data() {
        return {
            buy_order_statutes,
            orders: [],
            headers: [
                {
                    text: this.$t("market-details.tabs.company"),
                    value: "company",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.name"),
                    value: "name",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.quantity"),
                    value: "quantity",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.share-price"),
                    value: "price",
                    sortable: true
                },
                {
                    text: this.$t("market-details.tabs.total"),
                    value: "total",
                    sortable: false
                },
                {
                    text: this.$t("market-details.tabs.status"),
                    value: "buyerStatus",
                    sortable: false
                },
                {
                    text: this.$t("market-details.tabs.action"),
                    value: "action",
                    sortable: false
                }
            ]
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        displayTotal(item) {
            const total = item.quantity * item.price;
            return this.$currency.displayWithCurrency(total, item.currency);
        },
        confirmDeleteOrder(item) {
            this.$dialog.warn({
                title: this.$t("secondary.delete-order"),
                text: this.$t("secondary.delete-order-warning", {}),
                confirm: {
                    fn: () => this.stopOrder(item)
                },
                cancel: {}
            });
        },
        async stopOrder(item) {
            await this.$api.cancelBuyOrder(item.id);
            await this.fetchData();
        },
        actionPossible(item, type) {
            if (type == "signature") {
                return item.status == "Pending_Buyer_Signature";
            }
            return !["Canceled", "Completed", "Complete", "Rejected_By_Seller"].includes(
                item.status
            );
        },
        async fetchData() {
            this.localLoading = true;
            try {
                const res = await this.$api.readMySecondaryMarketBuyOrderList();
                this.orders = res.data.map(x => {
                    let val = {
                        is_company: true,
                        avatar: x.avatar,
                        company: x.company,
                        name: x.name,
                        currency: x.currency,
                        sellOrderId: x.sellOrder_id,
                        signatureId: x.signature_id,
                        price: x.price,
                        quantity: x.quantity,
                        id: x.id,
                        status: x.status,
                        buyerSignatureUrl: x.buyer_signature_url
                    };
                    return val;
                });
            } catch (e) {
                this.$ui.error(e, "secondary_market_fetch_your_buy_offers");
            } finally {
                this.localLoading = false;
            }
        },
        proceedBuyStepper(item) {
            this.$router
                .push({
                    name: "secondary-market-buy-stepper",
                    params: {
                        buyType: "secondaryMarket",
                        orderId: item.details.sellOrderId,
                        orderObject: item
                    }
                })
                .catch(() => {});
        },
        proceedSignature(item) {
            this.$dialog.confirm({
                title: this.$t("secondary-market.follow-buys.dialog-signature.title"),
                text: this.$t("secondary-market.follow-buys.dialog-signature.text"),
                confirm: {
                    fn: () => {
                        this.$router
                            .push({
                                name: "secondary-market-buy-stepper",
                                params: {
                                    buyType: "secondaryMarket",
                                    orderId: item.sellOrderId,
                                    state: "recovery",
                                    quantity: item.quantity,
                                    operation: item.opertionUrl,
                                    signatureId: item.signatureId,
                                    yousignUrl: item.buyerSignatureUrl
                                }
                            })
                            .catch(() => {});
                    }
                },
                cancel: {}
            });
        },
        getActions(item) {
            let actions = [];
            if (this.actionPossible(item)) {
                actions.push({
                    txt: this.$t("document-to-sign.documents-list.tooltip.cancel"),
                    icon: "mdi-cancel",
                    fn: () => this.confirmDeleteOrder(item),
                    color: "warning"
                });
            }
            if (this.actionPossible(item, "signature")) {
                actions.push({
                    txt: this.$t("secondary-market.follow-sells.finalize-signature"),
                    icon: "mdi-border-color",
                    fn: () => this.proceedSignature(item),
                    color: "primary"
                });
            }
            return actions;
        }
    }
};
</script>
