import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            bar: {
                height: "48px",
                "margin-bottom": getVal(currentSize, {
                    mobile: "12px",
                    tablet: "16px",
                    desktop: "20px"
                })
            },
            window: {
                "overflow-y": "auto"
            }
        },
        icons: {
            bar: {
                height: "72px"
            }
        },
        "no-overflow": {
            window: {
                "overflow-y": "none"
            }
        },
        transparent: {},
        dense: {}
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {},
        icons: {
            iconsAndText: true
        },
        transparent: {
            backgroundColor: "transparent"
        },
        "no-overflow": {},
        dense: {
            class: "pt-1"
        }
    };
};
