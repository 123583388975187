import cloneDeep from "lodash/cloneDeep";
import init from "./init";

export default {
    SET_THROUGH_ROLES(state, value) {
        if (!value) {
            state.useThroughRoles = {
                useIt: false,
                throughRole: null,
                currentRole: null
            };
        } else {
            state.useThroughRoles.useIt = true;
            state.useThroughRoles.throughRole = value.throughRole;
            state.useThroughRoles.currentRole = value.currentRole;
        }
    },
    ADD_CGP_ROLES(state, role) {
        const ret = state.cgpRoles.findIndex(e => e.id === role.id);
        if (ret === -1) {
            state.cgpRoles.push(cloneDeep(role));
        } else {
            state.cgpRoles[ret] = cloneDeep(role);
        }
    },
    SET_MY_ROLES(state, roles) {
        state.myRoles = cloneDeep(roles);
    },
    SET_MY_ROLE(state, role) {
        state.myRoles = state.myRoles.map(_role => {
            if (_role.id === role.id) return role;
            else return _role;
        });
    },
    SET_MY_CURRENT_ROLE(state, currentRole) {
        state.currentRole = cloneDeep(currentRole);
    },
    RESET_STORE_ROLES(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
