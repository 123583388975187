<template>
    <EqsStepper v-bind="{ value: 0, steps, progress }">
        <template #default="{ item }">
            <div class="eqs-main-container margin-auto">
                <component
                    :is="item.is"
                    editable
                    v-bind="{
                        schemaDouble,
                        formData,
                        loading,
                        edit: !!representativeId,
                        roles: representative_roles
                    }"
                />
            </div>
        </template>
    </EqsStepper>
</template>

<script>
import RepresentativeForm from "./RepresentativeForm";
import {
    progressMixin,
    buildFormDataMixin,
    formatFormDataMixin,
    formErrorMixin
} from "@equisafe-ui-vue/src/mixins/formMixin";
import { representative_roles } from "@/util/governance";
import { mapActions } from "vuex";
export default {
    name: "RepresentativeStepper",
    components: {
        RepresentativeForm
    },
    mixins: [progressMixin, buildFormDataMixin, formatFormDataMixin, formErrorMixin],
    props: {
        representativeId: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            formData: {},
            representative: {},
            representative_roles,
            schemaDouble: {
                investor: {
                    dataType: "id",
                    isRequired: true
                },
                role: {
                    isRequired: true
                },
                roleOther: {
                    isRequired: false
                },
                nomination_act: {
                    dataType: "file",
                    isRequired: false
                },
                start_mandat_date: {
                    isRequired: false
                },
                end_mandat_date: {
                    isRequired: false
                },
                annual_compensation: {
                    isRequired: false
                }
            }
        };
    },
    computed: {
        steps() {
            let step = {
                title: this.representativeId
                    ? this.$t("governance.representatives.edit-title")
                    : this.$t("governance.representatives.add-title"),
                item: {
                    is: "RepresentativeForm"
                },
                next: {
                    fn: this.representativeId ? this.editRepresentative : this.addRepresentative
                },
                back: {
                    fn: this.comeBackToRepresentatives
                }
            };
            // if (!this.noChangesError) {
            //   step.noBack = false;
            //   step.back = {
            //     fn: this.resetFormData,
            //     txt: this.$t("label.cancel"),
            //   };
            // }
            return [step];
        }
    },
    watch: {
        "formData.role": {
            immediate: true,
            handler(val) {
                if (val && !this.representative_roles.some(role => role.value === val)) {
                    this.formData.roleOther = val;
                    this.formData.role = "other";
                }

                if (this.formData.role) this.schemaDouble.roleOther.isRequired = true;
                else this.schemaDouble.roleOther.isRequired = false;
            }
        },
        representativeId: {
            immediate: true,
            async handler(val) {
                if (!!val) {
                    this.representative = await this.getRepresentative(val);
                    this.buildFormData(this.schemaDouble, this.representative);
                }
            }
        }
    },
    created() {
        this.getRepresentatives();
    },
    methods: {
        comeBackToRepresentatives() {
            this.$router.push({
                name: "governance",
                query: { tab: "organs_representatives" }
            });
        },
        async addRepresentative() {
            try {
                const formattedFormData = this.formatFormData(this.schemaDouble);
                if (formattedFormData.nomination_act_id === null)
                    delete formattedFormData.nomination_act_id;
                if (formattedFormData.role === "other")
                    formattedFormData.role = this.formData.roleOther;
                if (formattedFormData.roleOther) delete formattedFormData.roleOther;

                await this.createRepresentative(formattedFormData);
                if (this.$route.query.route_redirection)
                    this.$router.push(this.$route.query.route_redirection);
                else this.comeBackToRepresentatives();
            } catch (e) {
                this.handleError(e, "governance_representative_create");
            }
        },
        async editRepresentative() {
            try {
                const formattedFormData = this.formatFormData(this.schemaDouble);
                if (formattedFormData.nomination_act_id === null)
                    delete formattedFormData.nomination_act_id;
                if (formattedFormData.role === "other")
                    formattedFormData.role = this.formData.roleOther;
                if (formattedFormData.roleOther) delete formattedFormData.roleOther;

                await this.updateRepresentative({
                    id: this.representativeId,
                    ...formattedFormData
                });
                this.comeBackToRepresentatives();
            } catch (e) {
                this.handleError(e, "governance_representative_update");
            }
        },
        ...mapActions([
            "getRepresentatives",
            "getRepresentative",
            "createRepresentative",
            "updateRepresentative"
        ])
    }
};
</script>

<style lang="scss" scoped></style>
