var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"fill-height",attrs:{"title":_vm.$t('document-to-sign.title-procedure-details'),"closable":""},on:{"close":() => _vm.$emit('close')}},'EqsCard',{
        height: '100%'
    },false),[_c('div',{staticClass:"fill-height overflow-y"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"overflow-x":"hidden"}},[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":_vm.$t('document-to-sign.procedure-details.label.global-info')}}),_c('v-row',{staticClass:"px-3",attrs:{"align-content":"start"}},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsText',_vm._b({},'EqsText',{
                            editable: false,
                            label: _vm.$t('document-to-sign.procedure-details.label.procedure'),
                            value: _vm.signatureProcedure.name
                        },false))],1),(_vm.signatureProcedure.description)?_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextArea',_vm._b({},'EqsTextArea',{
                            editable: false,
                            label: _vm.$t('document-to-sign.procedure-details.label.description'),
                            value: _vm.signatureProcedure.description
                        },false))],1):_vm._e(),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                            editable: false,
                            value: _vm.$t('document-to-sign.procedure-details.label.status')
                        },false),[(_vm.signatureProcedure.status)?_c('EqsChip',_vm._b({},'EqsChip',_vm.procedureStatus[_vm.signatureProcedure.status],false),[_vm._v(" "+_vm._s(_vm.procedureStatus[_vm.signatureProcedure.status].text)+" ")]):_c('EqsChip',{attrs:{"eqs-type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("document-to-sign.procedure-waiting-signature-placement"))+" ")])],1)],1)],1),(_vm.isSigner())?_c('EqsTextDisplay',{staticClass:"pt-3",attrs:{"eqs-type":"subtitle","value":_vm.$t('document-to-sign.procedure-details.label.details')}}):_vm._e(),_c('v-row',{staticClass:"px-3"},[(_vm.signatureProcedure.proceduredocument_set.length && !_vm.signedDocs.length)?_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsFileInput',_vm._b({attrs:{"multiple":""}},'EqsFileInput',{
                            label: _vm.$t(
                                'document-to-sign.procedure-details.label.documents-list'
                            ),
                            value: _vm.signatureProcedure.proceduredocument_set.map(
                                doc => doc.document
                            ),
                            editable: false
                        },false))],1):(_vm.signedDocs.length)?_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsFileInput',_vm._b({attrs:{"multiple":""}},'EqsFileInput',{
                            label: _vm.$t(
                                'document-to-sign.procedure-details.label.signed-documents-list'
                            ),
                            value: _vm.signedDocs,
                            editable: false
                        },false))],1):_vm._e(),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                            value: _vm.$t('document-to-sign.procedure-details.label.signers-list'),
                            editable: false
                        },false),[_c('EqsDataTable',_vm._b({scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [(item.entity)?_c('td',[_vm._v(" "+_vm._s(item.entity.name)+" ")]):_vm._e()]}},{key:"item.status_display",fn:function({ item }){return [_c('td',[_vm._v(_vm._s(_vm.signerStatus[item.status_display].text))])]}}])},'EqsDataTable',{
                                headers: _vm.headers,
                                hideDefaultFooter: _vm.signatureProcedure.signer_set.length < 5,
                                items: _vm.signatureProcedure.signer_set
                            },false))],1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }