<template>
    <EqsDialog
        v-model="dialogProxy"
        closable
        persistent
        v-bind="{
            title: entityModel.name,
            attachToVApp: true,
            elClass,
            confirm: {
                fn: confirmAdd,
                txt: this.$t('molecule.form.activator-txt', {
                    entity: entityModel.name
                })
            },
            cancel: {
                fn: () => {
                    entityModel.entity = {};
                }
            },
            loading,
            text: entityModel.text,
            progress
        }"
        @close="() => (dialogProxy = false)"
    >
        <div v-if="entityModel" class="d-flex flex-column">
            <EqsAlert
                v-if="error"
                class="mb-3 mt-3 mx-4"
                :el-class="`${accountType}-error`"
                v-bind="{ eqsType: 'error', text: $t('rules.invite-form-error') }"
            />
            <component
                :is="entityModel.is"
                editable
                v-bind="{
                    formData,
                    loading,
                    schemaDouble
                }"
            />
        </div>
    </EqsDialog>
</template>

<script>
import { InviteEntity } from "@/services/entity";
import { formErrorMixin, progressMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "GlobalInviteEntityDialog",
    components: {
        IndividualInviteForm: () =>
            import("@/components/views/user/forms/IndividualInviteForm.vue"),
        CompanyInviteForm: () => import("@/components/views/user/forms/CompanyInviteForm.vue"),
        CustodianInviteForm: () => import("@/components/views/user/forms/CustodianInviteForm.vue")
    },
    mixins: [formErrorMixin, progressMixin],
    props: {
        accountType: {
            type: String,
            required: true
        },
        elClass: {
            type: String,
            default: ""
        },
        entity: {
            type: Object,
            default: () => {}
        },
        value: {
            type: Boolean,
            required: true
        },
        inviteData: {
            type: Object,
            default: () => {}
        },
        byRole: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            InviteEntity,
            entityModel: null,
            loading: false,
            formData: {},
            error: false,
            schema: [],
            schemaDouble: {}
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) {
                    this.$emit("close");
                }
                this.$emit("update:value", val);
            }
        }
    },
    watch: {
        accountType: {
            immediate: true,
            handler() {
                this.setSchema({});
            }
        },
        entity: {
            immediate: true,
            handler(val) {
                this.setSchema(val);
            }
        }
    },
    methods: {
        setSchema(entity) {
            this.entityModel = this.InviteEntity(this)[this.accountType];
            _.merge(this.formData, this.entityModel.entity, entity);
            this.schemaDouble = this.entityModel.schemaDouble;
        },
        async confirmAdd() {
            this.loading = true;
            try {
                const entity = _.isEmpty(this.inviteData)
                    ? this.formData
                    : { ...this.formData, ...this.inviteData };
                const res = await this.$api[this.byRole ? "entityInviteRole" : "entityInvite"](
                    entity
                );
                this.$emit("invited", res.data);
                this.$ui.success(
                    this.$t("molecule.form.successfully-invite", {
                        entityName: this.$entity.entityName(this.formData),
                        entityModel: this.entityModel.name
                    })
                );
                this.formData = {};
            } catch (e) {
                this.handleError(e, "invite_entity");
                throw e;
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
