<template>
    <div class="d-flex flex-column" :el-class="`buy-status-${status.id}`">
        <v-row class="px-3" justify="center">
            <v-col :cols="$reactive.getCols('md')">
                <EqsTextDisplay
                    class="text-center"
                    :class="`${status.color}--text`"
                    v-bind="{ eqsType: 'title' }"
                >
                    {{ status.title }}
                </EqsTextDisplay>
            </v-col>
        </v-row>
        <v-row v-if="status.display" justify="center">
            <Payment class="fill-width" v-bind="{ order, formData, schemaDouble }" />
        </v-row>
        <v-row class="px-3 py-5" justify="center">
            <v-col :cols="$reactive.getCols('lg')">
                <EqsTextDisplay
                    paragraph
                    class="text-center"
                    v-bind="{ eqsType: 'subtitle', value: status.message }"
                />
            </v-col>
        </v-row>
        <v-row v-if="order.lemonway_reason" class="px-3 py-5" justify="center">
            <v-col :cols="$reactive.getCols('lg')">
                <EqsTextDisplay
                    paragraph
                    v-bind="{
                        eqsType: 'subtitle',
                        value: `${$t('user.buy.order-status-reason-failure')}\n${
                            order.lemonway_reason
                        }`
                    }"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import Payment from "@/components/views/user/buy/payment/_Payment.vue";
export default {
    name: "OrderStatus",
    components: {
        Payment
    },
    mixins: [formMixin],
    props: {
        status: {
            type: Object,
            required: true
        },
        order: {
            type: Object,
            required: true
        }
    }
};
</script>
