<template>
    <v-row
        no-gutters
        justify="end"
        align="center"
        :class="`eqs-crud-action-btns ${elClass}-crud-action-btns`"
    >
        <EqsBtn
            v-if="!editableProxy"
            class="mx-2"
            v-bind="{
                color: 'primary',
                loading,
                'el-class': `${elClass}-edit`,
                action: () => {
                    editableProxy = true;
                    $emit('edit');
                }
            }"
            outlined
        >
            {{ $t("btn.edit") }}
        </EqsBtn>
        <v-row v-else no-gutters justify="end" align="center">
            <EqsBtn
                class="mx-2"
                v-bind="{
                    color: 'primary',
                    loading: loading || localLoading,
                    'el-class': `${elClass}-cancel`,
                    action: cancel
                }"
                outlined
                @update:loading="val => (localLoading = val)"
                @action:finished="reset"
            >
                {{ $t("btn.cancel") }}
            </EqsBtn>
            <EqsBtn
                class="mx-2"
                v-bind="{
                    color: 'success',
                    loading: loading || localLoading,
                    'el-class': `${elClass}-save`,
                    action: save,
                    disabled: !hasChanged || !validForm
                }"
                @update:loading="val => (localLoading = val)"
                @action:finished="reset"
            >
                {{ $t("btn.save") }}
            </EqsBtn>
        </v-row>
        <EqsBtn
            v-if="deleteFunc && !editable"
            class="mx-2"
            v-bind="{
                color: 'error',
                loading,
                'el-class': `${elClass}-delete`,
                action: deleteFunc
            }"
            @action:finished="reset"
        >
            {{ $t("btn.delete") }}
        </EqsBtn>
    </v-row>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsCrudActionBtns.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import EqsBtn from "../../atoms/EqsBtn/EqsBtn.vue";

export default {
    name: "EqsCrudActionBtns",
    components: {
        EqsBtn
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-crud-action-btns"
        },
        cancel: {
            type: Function,
            required: true
        },
        save: {
            type: Function,
            required: true
        },
        hasChanged: {
            type: Boolean,
            required: true
        },
        validForm: {
            type: Boolean,
            required: true
        },
        editable: {
            type: Boolean,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        deleteFunc: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            localLoading: false
        };
    },
    computed: {
        editableProxy: {
            get() {
                return this.editable;
            },
            set(val) {
                this.$emit("update:editable", val);
            }
        },
        hasChangedProxy: {
            get() {
                return this.hasChanged;
            },
            set(val) {
                this.$emit("update:hasChanged", val);
            }
        }
    },
    methods: {
        reset() {
            this.editableProxy = false;
            this.hasChangedProxy = false;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsCrudActionBtns.scss";
</style>
