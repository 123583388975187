import DEBUG from "@/store/debug.js";

export default async ({ state, commit, dispatch }, notificationId) => {
    DEBUG("FETCH_NOTIFICATION_START");
    if (!notificationId) throw new Error("no notificationId");
    if (!state.notifications.length) {
        try {
            await dispatch("getNotifications");
        } catch (e) {
            throw e;
        }
    }
    try {
        const res = await window.app.$api.getNotification(notificationId);
        const index = state.notifications.findIndex(
            _notification => _notification.id === res.data.id
        );
        if (index >= 0) {
            state.notifications[index] = res.data;
            commit("SET_NOTIFICATIONS", state.notifications);
        } else commit("SET_NOTIFICATIONS", [res.data, ...state.notifications]);
        DEBUG("FETCH_NOTIFICATION_SUCCESS");
    } catch (e) {
        console.error({ e });
        DEBUG("FETCH_NOTIFICATION_ERROR");
        throw e;
    }
};
