<template>
    <EqsStepper
        :value.sync="step"
        v-bind="{
            steps,
            progress,
            closeInfoTxt
        }"
        closable
        @close="comeBackToDecisions"
    >
        <template #default="{ item }">
            <div :class="item.class">
                <component
                    :is="item.is"
                    editable
                    v-bind="{
                        loading: loading || localLoading || decisionsLoading || organsLoading,
                        formData,
                        schemaDouble,
                        decision,
                        organs,
                        decisionId,
                        ...item
                    }"
                />
            </div>
        </template>
    </EqsStepper>
</template>

<script>
import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";
import { EqsChooseAction } from "@equisafe-ui-vue";
import { getNameWithExtension } from "@/util/documents";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "DecisionStepper",
    components: {
        DecisionCreate: () => import("./DecisionCreate"),
        EqsChooseAction,
        DecisionContent: () => import("./DecisionContent")
    },
    mixins: [stepperFormMixins],
    props: {
        decisionId: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            step: 0,
            schemaDouble: {},
            formData: {},
            localLoading: false,
            actionType: null,
            decision: {},
            closeInfoTxt: this.$t("governance.decisions.back")
        };
    },
    computed: {
        ...mapGetters(["decisionsLoading", "decisions", "organsLoading", "organs"]),
        steps() {
            let schemaDoubleDecisionContent = {
                date: {
                    isRequired: true
                }
                // mode: {
                //   isRequired: false,
                // },
                // street: {
                //   isRequired: false,
                // },
                // zipcode: {
                //   isRequired: false,
                // },
                // country: {
                //   isRequired: false,
                // },
            };

            switch (this.decision.type) {
                case "download":
                    schemaDoubleDecisionContent.convocation_documents = {
                        dataType: "array",
                        isRequired: true
                    };
                    break;
                case "signature":
                    schemaDoubleDecisionContent.PV_documents = {
                        dataType: "array_of_url",
                        isRequired: true
                    };
                case "vote":
                    schemaDoubleDecisionContent.deliberation_documents = {
                        dataType: "array_of_url",
                        isRequired: true
                    };
            }

            return [
                {
                    title: !this.decisionId
                        ? this.$t("governance.decisions.stepper.title.create-new")
                        : this.$t("governance.decisions.stepper.title.edit-title"),
                    item: {
                        is: "DecisionCreate",
                        class: "eqs-main-container margin-auto"
                    },
                    schemaDouble: {
                        organ: {
                            isRequired: true,
                            dataType: "id"
                        },
                        name: {
                            isRequired: true
                        }
                    },
                    next: {
                        fn: !!this.decisionId ? this.editDecision : this.addDecision
                    },
                    back: {
                        fn: this.comeBackToDecisions,
                        txt: this.closeInfoTxt
                    }
                },
                {
                    title: this.$t("governance.decisions.stepper.title.choose-type"),
                    item: {
                        is: "EqsChooseAction",
                        class: "d-flex justify-center fill-height",
                        choices: [
                            {
                                title: this.$t("governance.decisions.actions.signer.title"),
                                text: this.$t("governance.decisions.action.sign.text"),
                                img: "/images/investors/card1.svg",
                                action: () => this.chooseDecisionType("signature"),
                                inProgress: true
                            },
                            {
                                title: this.$t("governance.decisions.actions.vote.title"),
                                text: this.$t("governance.decisions.action.vote.text"),
                                img: "/images/investors/card2.svg",
                                action: () => this.chooseDecisionType("vote"),
                                inProgress: true
                            },
                            {
                                title: this.$t("governance.decisions.actions.download.title"),
                                text: this.$t("governance.decisions.action.download.text"),
                                img: "/images/investors/card3.svg",
                                action: () => this.chooseDecisionType("download")
                            }
                        ]
                    },
                    schemaDouble: {
                        type: {
                            isRequired: true
                        }
                    },
                    noNext: true
                },
                {
                    title: this.$t("governance.decisions.stepper.title.decision-content"),
                    item: {
                        is: "DecisionContent",
                        class: "eqs-main-container margin-auto"
                    },
                    next: {
                        fn: this.finalize,
                        txt: this.$t("governance.decisions.stepper.btn.finish")
                    },
                    schemaDouble: schemaDoubleDecisionContent
                }
            ];
        }
    },
    watch: {
        decisionId: {
            immediate: true,
            async handler(val) {
                if (!!val) {
                    try {
                        this.decision = await this.getDecision(val);
                        this.step = this.getLastFormStep(this.decision);
                        this.populateFormData();
                    } catch (e) {
                        this.$ui.error(e, "governance_decision_get");
                    }
                }
            }
        },
        step: {
            immediate: true,
            handler(val) {
                const currStep = this.steps[val];
                this.schemaDouble = currStep && currStep.schemaDouble ? currStep.schemaDouble : {};
                this.populateFormData();
            }
        }
    },
    created() {
        this.getDecisions();
        this.getOrgans();
    },
    methods: {
        ...mapActions([
            "createDecision",
            "updateDecision",
            "getDecision",
            "getDecisions",
            "getOrgans"
        ]),
        populateFormData() {
            const convocation_documents = this.decision.convocation_documents
                ? this.decision.convocation_documents.map(doc => {
                      return {
                          name: doc.name.split(".").shift(),
                          category: doc.category.toLowerCase(),
                          doc
                      };
                  })
                : [];
            this.buildFormData(this.schemaDouble, {
                ...this.decision,
                convocation_documents
            });
            this.saveFormData();
        },
        comeBackToDecisions() {
            this.$router.push({
                name: "governance",
                query: { tab: "decisions" }
            });
        },
        async chooseDecisionType(type) {
            this.formData = { type };
            await this.editDecision();
        },
        async addDecision() {
            try {
                this.localLoading = true;
                let data = this.formatFormData(this.schemaDouble);
                const decision = await this.createDecision(data);
                this.$router.replace({
                    name: "governance-decision-edit",
                    params: {
                        decisionId: decision.id
                    }
                });
            } catch (e) {
                this.handleError(e, "governance_decision_create");
            } finally {
                this.localLoading = false;
            }
        },
        async editDecision() {
            try {
                if (this.errorNoFieldsChanged) {
                    if (this.step < this.steps.length) this.step++;
                    return;
                }
                this.localLoading = true;
                const id = this.decisionId;
                const data = await this.handleDocuments(data);
                this.decistion = await this.updateDecision({
                    id,
                    ...this.formatFormData(this.schemaDouble, data)
                });
                this.step = this.getLastFormStep(this.decision);
            } catch (e) {
                this.handleError(e, "governance_decision_update");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async finalize() {
            try {
                await this.editDecision();
                this.comeBackToDecisions();
            } catch (e) {
                console.error({ e });
            }
        },
        async handleDocuments() {
            let data = {};
            if (this.formData.convocation_documents && this.formData.convocation_documents.length) {
                const results = await Promise.allSettled(
                    this.formData.convocation_documents.map(item => {
                        const { name, category, doc } = item;
                        return this.$api.patchDocument(doc.id, {
                            name: getNameWithExtension(name, doc),
                            category: category.toUpperCase()
                        });
                    })
                );
                results.forEach(res => {
                    if (res.status === "rejected") {
                        console.error({ res });
                        throw res;
                    }
                });
                data.convocation_documents = this.formData.convocation_documents.map(
                    item => item.doc.id
                );
            }
            return data;
        }
    }
};
</script>

<style lang="scss" scoped></style>
