var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-main-container margin-auto fill-height"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"px-4",attrs:{"cols":1}},[_c('EqsAvatar',{staticClass:"mx-4",attrs:{"user":_vm.payment.credited_entity,"loading":_vm.loading}})],1),_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('full') - 1}},[_c('v-row',{staticClass:"px-4"},[_c('EqsTextDisplay',{attrs:{"eqs-type":"title","value":_vm.payment.credited_entity ? _vm.payment.credited_entity.name : '',"loading":_vm.loading}})],1),_c('v-row',{staticClass:"px-4"},[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":_vm.$t('payments.asked-txt', {
                            operation_type: _vm.payment_types[_vm.payment.operation_type]
                                ? _vm.payment_types[_vm.payment.operation_type].txt
                                : _vm.payment.operation_type
                        }),"loading":_vm.loading}})],1)],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsTextDisplay',_vm._b({attrs:{"label":_vm.$t('payments.asked.label-amount'),"editable":false,"value":_vm.$t('payments.asked.label-amount')}},'EqsTextDisplay',{
                    eqsType: 'subtitle'
                },false)),_c('EqsTextDisplay',_vm._b({attrs:{"label":_vm.$t('payments.asked.label-amount'),"editable":false,"value":_vm.$currency.displayWithCurrency(_vm.payment.amount, 'EUR'),"loading":_vm.loading}},'EqsTextDisplay',{
                    eqsType: 'title'
                },false))],1),_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',{attrs:{"label":_vm.$t('payments.asked.label-operation-type'),"editable":false,"value":_vm.payment_types[_vm.payment.operation_type]
                        ? _vm.payment_types[_vm.payment.operation_type].txt
                        : _vm.payment.operation_type,"loading":_vm.loading}})],1),_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',{attrs:{"label":_vm.$t('payments.asked.label-date'),"editable":false,"value":_vm.payment.created_at ? _vm.$time.formated(_vm.payment.created_at, true) : '',"loading":_vm.loading}})],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextArea',{attrs:{"label":_vm.$t('payments.asked.label-comment'),"editable":false,"value":_vm.payment.comment,"loading":_vm.loading}})],1),(_vm.files && _vm.files.length)?_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsFileInput',{attrs:{"label":_vm.$t('payments.asked.label-docs'),"editable":false,"multiple":"","value":_vm.files,"loading":_vm.loading},on:{"update:value":function($event){_vm.files=$event}}})],1):_vm._e()],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsBtn',{staticClass:"mr-5",attrs:{"action":_vm.accept,"color":"primary","loading":_vm.loading,"el-class":"pay"}},[_vm._v(" "+_vm._s(_vm.$t("payments.asked.accept.accept-btn"))+" ")]),_c('EqsBtn',{attrs:{"action":_vm.refuse,"loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("payments.asked.accept.refuse-btn"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }