<template>
    <v-container fluid>
        <v-form ref="form" v-model="formIsValid" lazy-validation>
            <div class="post-header">
                <v-text-field
                    v-model="post.title"
                    :label="$t('projects.reporting.form.title')"
                    required
                    :rules="postTitleR"
                />
                <v-select
                    v-model="post.label"
                    :items="labelItems"
                    chips
                    attach
                    required
                    :rules="postTypeR"
                    :label="$t('projects.reporting.form.type')"
                >
                    <template #selection="{ item }">
                        <v-chip
                            :color="getPostLabelColor(item.value)"
                            text-color="white"
                            label
                            class="ma-2"
                        >
                            {{ getPostLabelText(item.value) }}
                        </v-chip>
                    </template>
                </v-select>
            </div>
            <draggable
                v-model="fieldsAdd"
                group="fields"
                handle=".handle"
                @start="drag = true"
                @end="drag = false"
                @change="updateListOnDrag"
            >
                <div v-for="(field, index) in fieldsAdd" :key="field.counter" class="ma-0 pa-0">
                    <v-row justify="center" align="center" dense no-gutters>
                        <v-col cols="1" class="text-left handle">
                            <v-icon color="#414141" class="drag-icon" size="4rem">
                                mdi-drag-vertical
                            </v-icon>
                            <!-- <v-tooltip bottom color="info">
                            <template #activator="{ on, attrs }">
                            </template>
                            <span>Déplacer</span>
                        </v-tooltip> -->
                        </v-col>
                        <v-col cols="10" class="py-2 px-2">
                            <v-component
                                :is="field.is"
                                :field.sync="field.data[field.type]"
                                class="field"
                            />
                        </v-col>
                        <v-col cols="1" class="text-right">
                            <v-tooltip bottom color="error">
                                <template #activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        class="close-icon"
                                        color="#414141"
                                        size="1.5rem"
                                        v-on="on"
                                        @click.stop="removeField(field.counter)"
                                        >mdi-close</v-icon
                                    >
                                </template>
                                <span>{{ $t("projects.reporting.crud.delete") }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-divider v-if="index < fieldsAdd.length - 1" class="my-6" />
                </div>
            </draggable>
        </v-form>
        <div class="ma-2 mt-10 text-right">
            <span class="d-block mb-4 text-subtitle-1">{{
                $t("projects.reporting.form.use-btn-to-make-report")
            }}</span>
            <v-btn
                v-for="(addFieldItem, index) in addFieldList"
                :key="index"
                class="mr-2 mb-2 btn-no-uppercase"
                rounded
                elevation="2"
                @click.stop="addField(addFieldItem)"
            >
                <template #default>
                    <v-icon color="#212121">mdi-plus-thick</v-icon>
                    <span class="mr-3">{{ addFieldItem.text }}</span>
                </template>
            </v-btn>
        </div>
    </v-container>
</template>

<script>
import draggable from "vuedraggable";

import FormCta from "./forms/FormCta.vue";
import FormYoutubeLink from "./forms/FormYoutubeLink.vue";
import FormText from "./forms/FormText.vue";
import FormDocument from "./forms/FormDocument.vue";

import { getPostLabelColor, getPostLabelText, getButtonText, postTitleR, postTypeR } from "./utils";

export default {
    name: "PostForm",
    components: {
        draggable,
        FormCta,
        FormYoutubeLink,
        FormText,
        FormDocument
    },
    props: {
        post: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    emits: ["form-is-valid"],
    data() {
        return {
            fieldCounter: 0,
            postTitleR,
            postTypeR,
            localLoading: false,
            formIsValid: false,
            labelItems: [
                { text: getPostLabelText(this, "newsletter"), value: "newsletter" },
                { text: getPostLabelText(this, "report"), value: "report" },
                { text: getPostLabelText(this, "video"), value: "video" },
                { text: getPostLabelText(this, "other"), value: "other" }
            ],
            addFieldList: [
                { text: getButtonText(this, "text"), type: "text", is: FormText },
                { text: getButtonText(this, "document"), type: "document", is: FormDocument },
                { text: getButtonText(this, "cta"), type: "cta", is: FormCta },
                {
                    text: getButtonText(this, "youtube_link"),
                    type: "youtube_link",
                    is: FormYoutubeLink
                }
            ],
            fieldsAdd: []
        };
    },
    computed: {},
    watch: {
        formIsValid: {
            handler: function (val) {
                if (this.fieldsAdd.length === 0) {
                    this.$emit("form-is-valid", false);
                    return;
                }
                this.$emit("form-is-valid", val);
            },
            immediate: true
        }
    },
    beforeMount() {
        if (!this.post.reporting) {
            // Si on est en ajout, en ajoute les champs vides
            Object.assign(this.post, {
                title: null,
                label: null,
                fields: []
            });
        } else {
            // Sinon, on ajoute les champs
            this.post.fields.sort((a, b) => a.order - b.order);
            for (const field of this.post.fields) {
                const fieldType = this.addFieldList.find(e => field[e.type]);
                if (fieldType) {
                    this.addField(fieldType, field[fieldType.type]);
                }
            }
        }
    },
    methods: {
        getPostLabelColor,
        getPostLabelText(label) {
            return getPostLabelText(this, label);
        },
        updateListOnDrag() {
            // Update l'ordre du tableau
            Object.assign(this.post, { fields: this.fieldsAdd });
        },
        addField(field, data = {}) {
            /*
                On remet le type pour envoyer directement le bon champs au formulaire
                Le formulaire pourrait aussi savoir quoi regarder, mais au moins c'est automatique
            */
            let fieldToAdd = {
                is: field.is,
                type: field.type,
                counter: this.fieldCounter,
                data: {}
            };
            this.fieldCounter++;
            if (data.field) {
                delete data.field;
            }
            fieldToAdd.data[field.type] = data;
            this.fieldsAdd.push(fieldToAdd);
            Object.assign(this.post, { fields: this.fieldsAdd });
            if (this.$refs.form) {
                this.$nextTick(() => this.$refs.form.validate());
            }
            if (
                this.formIsValid &&
                this.fieldsAdd.length === 1 &&
                this.fieldsAdd[0].type === "document"
            ) {
                // Cas spécial comme les docs sont required = false
                // Le form est bien valid, mais ne trigger pas le parent
                this.$emit("form-is-valid", true);
            }
        },
        removeField(fieldCounter) {
            this.$dialog.alert({
                title: this.$t("projects.reporting.crud.confirm-delete-field-title"),
                text: this.$t("projects.reporting.crud.confirm-delete-field-text"),
                confirm: {
                    fn: async () => {
                        await this.removeFieldAndCheckDocuments(fieldCounter);
                    },
                    txt: this.$t("projects.reporting.crud.confirm")
                },
                cancel: {
                    fn: () => {}
                }
            });
        },
        async removeFieldAndCheckDocuments(fieldCounter) {
            const fieldIndex = this.fieldsAdd.findIndex(e => e.counter === fieldCounter);
            if (fieldIndex === -1) {
                return;
            }
            let field = this.fieldsAdd[fieldIndex];
            if (field.type === "document") {
                const documents = field.data.document.documents;
                if (documents) {
                    for (const doc of documents) {
                        if (doc && doc.id) {
                            this.$api.deleteDocument(doc.id);
                        }
                    }
                }
            }
            this.fieldsAdd.splice(fieldIndex, 1);
            if (this.fieldsAdd.length === 0) {
                this.formIsValid = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.post-header {
    margin-bottom: 4px;
    max-width: 500px;
}

.btn-no-uppercase {
    text-transform: none;
}
.drag-icon {
    margin-left: -2rem;
    cursor: default;
    &:hover {
        cursor: move;
    }
    &:active {
        cursor: grab;
    }
}
.handle {
    border: none;
    background: none;
    position: unset;
    width: auto;
    height: 100%;
}
.field {
}
.close-icon {
    &:hover {
        cursor: pointer;
    }
}
</style>
