<template>
    <EqsCard
        class="fill-height"
        v-bind="{ height: '100%', loading: loading || proceduresLoading }"
        v-on="$listeners"
    >
        <template #main>
            <EqsStepper
                :value.sync="step"
                closable
                v-bind="{
                    steps,
                    closeInfoTxt
                }"
                style="height: 100%"
                @close="backToSignatures"
            >
                <template #default="{ item }">
                    <component
                        :is="item.is"
                        retriable
                        v-bind="{
                            stepperState,
                            formData,
                            schemaDouble,
                            signer
                        }"
                        @signatureRefused="signatureRefused"
                        @error="
                            e => {
                                catchError();
                            }
                        "
                    />
                </template>
            </EqsStepper>
        </template>
    </EqsCard>
</template>

<script>
import { PRODUCTION_ENVIRONMENT } from "@/assets/constants";

import FinalStep from "./FinalStep.vue";

import {
    formErrorMixin,
    buildFormDataMixin,
    formatFormDataMixin
} from "@equisafe-ui-vue/src/mixins/formMixin";

import { procedureSignerMixin } from "@/mixins/yousignMixin";

export default {
    name: "SimpleSignStepper",
    components: {
        FinalStep
    },
    mixins: [formErrorMixin, buildFormDataMixin, formatFormDataMixin, procedureSignerMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        procedureId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            closeInfoTxt: this.$t("signature.simple-sign-stepper.dialog-signatory.cancel-btn"),
            steps: [],
            step: -1,
            stepperState: "success",
            schemaDouble: {},
            formData: {}
        };
    },
    watch: {
        step: {
            immediate: true,
            handler(val) {
                if (val < 0) return;
                let step = this.steps[val];
                if (step) {
                    this.schemaDouble = step.schemaDouble;
                    if (this.schemaDouble) {
                        this.buildFormData(this.schemaDouble, this.oldFormData);
                    }
                }
            }
        }
    },
    async created() {
        this.initSteps();
        this.checkEntityCanSign({
            fn: this.backToSignatures,
            txt: this.closeInfoTxt
        });
        this.step = 0;
    },
    methods: {
        initSteps() {
            this.steps = [
                {
                    title: this.$t("simple-sign-stepper.sign-step.title"),
                    item: {
                        is: "GlobalYousignIFrame"
                    },
                    noPadding: true,
                    back: {
                        fn: this.backToSignatures,
                        txt: this.closeInfoTxt
                    },
                    next: {
                        fn: this.fetchSignatureState
                    },
                    schemaDouble: {
                        signature_done: {
                            dataType: "bool",
                            isRequired: true
                        }
                    }
                },
                {
                    title: this.$t("simple-sign-stepper.sign-step.finalize"),
                    item: {
                        is: "FinalStep"
                    },
                    noBack: true,
                    schemaDouble: {},
                    next: {
                        fn: this.backToSignatures,
                        txt: this.closeInfoTxt
                    }
                }
            ];
        },
        backToSignatures() {
            this.$router.push({
                name: "signatures",
                params: {
                    id: this.$entity.represented().id
                }
            });
        },
        async fetchSignatureState() {
            try {
                if (!this.signatureDone) {
                    if (!PRODUCTION_ENVIRONMENT) {
                        await this.fetchProcedureLocal(this.procedureId);
                    } else {
                        await this.fetchProcedure(this.procedureId);
                    }
                    await this.$nextTick();
                }
                if (!this.signatureDone) this.$ui.warning(this.$t("user.buy.signature-pending"));
                else this.step++;
            } catch (e) {
                this.$ui.error(e, "buy_trigger_buy_process");
            }
        }
    }
};
</script>
