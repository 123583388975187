<template>
    <v-container fluid>
        <h2 class="text-h5">{{ $t("risk-aversion.cip-cgu.title") }}</h2>
        <v-row>
            <v-col>
                Equisafe Invest est une plateforme agréée par l’Autorité des Marchés Financier (AMF)
                en tant que Prestataire de Service de Financement Participatif (PSFP) sous le numéro
                FP-2024-4, proposant des projets d’investissements immobiliers et PME, sous la forme
                d’investissements en actions ou en obligations. La communication d’une information à
                jour, exacte, complète et sincère est une condition indispensable à la fourniture
                d’un service de Prestataire de Service de Financement Participatif. Equisafe Invest
                recueille vos informations afin de connaitre votre profil investisseur et
                d’identifier votre connaissance des opérations d’investissement. Ces informations
                resteront confidentielles et ne feront l’objet d’aucune diffusion à un tiers. En
                fonction des réponses apportées à ce questionnaire d’adéquation, nous vous
                informerons de votre statut d’investisseur : averti ou non-averti. Cette
                qualification est strictement encadrée par le règlement (UE) 2020/1503 du Parlement
                Européen et du Conseil du 7 octobre 2020.
                <br /><br />
                Cette évaluation est réalisée sur la seule base de vos réponses et de manière
                entièrement automatisée.
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <EqsBtn
                    class="ma-2"
                    outlined
                    color="success"
                    v-bind="{
                        action: () =>
                            window.open(
                                'https://www.equisafe-invest.io/cgu-equisafe-invest',
                                '_blank'
                            )
                    }"
                >
                    <span class="no-text-transform">
                        {{ $t("risk-aversion.dialog.cgu") }}
                    </span>
                </EqsBtn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <EqsCheckbox
                    :value.sync="formData.accept_cgu"
                    class="font-weight-bold mt-4 mb-4"
                    v-bind="{
                        ...schemaDouble.accept_cgu
                    }"
                >
                    <template #label>
                        <div class="ml-4 font-weight-bold">
                            <p class="pt-8">
                                J’accepte les conditions générales d’utilisation d'Equisafe Invest
                            </p>
                            <p>
                                Et je déclare solennellement ne pas résider aux États-Unis
                                d’Amérique ni au Canada, et confirme également ne posséder ni la
                                nationalité américaine, ni la nationalité canadienne
                            </p>
                        </div>
                    </template>
                </EqsCheckbox>
            </v-col>
        </v-row>
        <v-dialog
            v-model="dialogProxy"
            closable
            transition="dialog-bottom-transition"
            max-width="600"
        >
            <v-card class="pa-4">
                <v-card-title class="text-h5" style="word-break: break-word">
                    Nous souhaitons en savoir plus sur votre profil investisseur !</v-card-title
                >
                <v-card-text>
                    Afin de se conformer à la
                    <span class="font-weight-bold">
                        nouvelle règlementation de Prestataire de Services de Financement
                        Participatif
                    </span>
                    (Règlement 2020/1503 du Parlement Européen et du Conseil du 7 octobre 2020
                    relatif aux prestataires européens de services de financement participatif pour
                    les entrepreneurs, et modifiant le règlement (UE) 2017/1129 et la directive (UE)
                    2019/1937).
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="dialogProxy = false"> J'ai compris </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
export default {
    name: "IntroPage",
    mixins: [formMixin],
    data() {
        return {
            window,
            dialogProxy: true
        };
    },
    methods: {
        openCguUniverse() {
            window.open(this.$store.getters.universe.cgu_url, "_blank");
        }
    }
};
</script>

<style lang="scss" scoped></style>
