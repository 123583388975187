<template>
    <v-container fluid class="root-step-container">
        <h2 class="text-h5 mb-4">Questionnaire de qualité investisseur</h2>
        <EqsRadio
            :value.sync="formData.p4q1"
            row
            class="mb-2"
            v-bind="{
                label: labelQ1,
                ...schemaDouble.p4q1
            }"
        />
        <EqsRadio
            :value.sync="formData.p4q2"
            row
            class="mb-2"
            v-bind="{
                label: labelQ2,
                ...schemaDouble.p4q2
            }"
        />
        <EqsRadio
            :value.sync="formData.p4q3"
            row
            class="mb-2"
            v-bind="{
                label: labelQ3,
                ...schemaDouble.p4q3
            }"
        />
        <v-spacer />
        <EqsCheckbox
            :value.sync="formData.confirm_infos_reliable"
            class="font-weight-bold mt-4 mb-4"
            v-bind="{
                ...schemaDouble.confirm_infos_reliable
            }"
        >
            <template #label>
                Je confirme que les informations fournies sont fiables, à jour, et reflètent mon
                expérience, mes objectifs, ma situation financière, et ma compréhension des risques.
            </template>
        </EqsCheckbox>
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "Form4",
    mixins: [formMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        isCompany: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {
        labelQ1() {
            return this.isCompany
                ? "Le montant de vos capitaux propres est-il supérieur à 100 000 EUR ?"
                : "Possédez-vous un revenu personnel brut d’au moins 60 000 EUR ou un portefeuille d’instruments financiers, défini comme englobant les dépôts en espèces et les immobilisations financières, dont la valeur dépasse 100 000 EUR ?";
        },
        labelQ2() {
            return this.isCompany
                ? "Avez-vous réalisé un chiffre d’affaires net d’au moins 2 000 000 EUR ?"
                : "Avez-vous exercé ou occupé pendant au moins un an, dans le secteur financier, une activité professionnelle requérant une connaissance des transactions ou des services envisagés ?";
        },
        labelQ3() {
            return this.isCompany
                ? "Le total de votre bilan est-il supérieur à 1 000 000 EUR ?"
                : "Avez-vous effectué en moyenne dix transactions d’une taille significative par trimestre au cours des quatre trimestres précédents sur les marchés de capitaux ?";
        }
    }
};
</script>

<style lang="scss" scoped></style>
