<template>
    <EqsDialog
        v-model="dialogProxy"
        closable
        persistent
        v-bind="{
            title: $t('governance.decisions.lock-dialog.title', { name: item.name }),
            confirm: {
                fn: () => lockItem(item)
            },
            img: '/icons/anchor.svg',
            cancel: {}
        }"
        @close="() => (dialogProxy = false)"
    >
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsAlert
                    v-bind="{
                        eqsType: 'warning',
                        text: $t('governance.decisions.lock-dialog.alert')
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsLabel
                    v-bind="{
                        value: $t('governance.decisions.header.organe'),
                        editable: false
                    }"
                >
                    <EqsChip
                        v-bind="{
                            color: $color.colorFromHash(item.organ.name),
                            textColor: 'text'
                        }"
                    >
                        <div>{{ item.organ.name }}</div>
                    </EqsChip>
                </EqsLabel>
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsLabel
                    v-bind="{
                        value: $t('governance.decisions.header.mode'),
                        editable: false
                    }"
                >
                    <EqsChip
                        v-bind="{
                            color: $color.colorFromHash(item.type),
                            textColor: 'text'
                        }"
                    >
                        <div>{{ decision_types[item.type].text }}</div>
                    </EqsChip>
                </EqsLabel>
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsDate
                    :value="item.date"
                    :editable="false"
                    :label="$t('governance.decisions.stepper.step.upload-decision.form.date.label')"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsList
                    v-bind="{
                        editable: false,
                        label: $t(
                            'governance.decisions.stepper.step.upload-decision.form.convocation-docs.title'
                        ),
                        items: item.convocation_documents.map(item => {
                            return {
                                doc: item,
                                subtitle: documentTypesObj[item.category.toLowerCase()].text,
                                title: item.name
                            };
                        })
                    }"
                >
                    <template #item="{ item, index }">
                        <EqsFileActions
                            v-bind="{
                                item: item.doc,
                                index,
                                editable: false
                            }"
                        />
                    </template>
                </EqsList>
            </v-col>
        </v-row>
    </EqsDialog>
</template>

<script>
import { decision_types } from "@/util/governance";
import { documentTypesObj } from "@/util/documents";

export default {
    name: "LockDialog",
    props: {
        lockItem: {
            type: Function,
            required: true
        },
        value: {
            type: Boolean,
            required: true
        },
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            decision_types,
            documentTypesObj
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
