import Vue from "vue";
import Vuetify from "vuetify"; // loads all components
import "vuetify/dist/vuetify.min.css"; // all the css for components
import "@mdi/font/css/materialdesignicons.css";
import { i18n } from "@/services/plugins/i18n.js";
import { TabPane, Tabs } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(Vuetify);
Vue.use(Tabs);
Vue.use(TabPane);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        dark: false,
        themes: {
            light: {
                background: "#fff",
                primary: "#3434FF",
                text: "#151D28",
                selectColor: "#F1F2F4",
                // is same color as primary ?
                accent: "#3434FF",
                info: "#3A7AF2",
                infoLight: "#9FB9F8",
                success: "#2E9E49",
                successLight: "#9CD6A9",
                error: "#E73B2F",
                errorLight: "#EE9D97",
                warning: "#FAB409",
                warningLight: "#FDE8AC",
                darkGrey: "#707070",
                lightOrange: "#ffedd8",
                lightGrey: "#b9bbbe",
                grey: "#a6a7a8",
                labelColor: "#495057",
                red: "#fd0000"
            }
        }
    },
    lang: {
        t: (key, ...params) => {
            return i18n.t(key, params);
        }
    },
    icons: {
        iconfont: "mdiSvg"
    }
});
