var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("risk-aversion.cip-cgu.title")))]),_c('v-row',[_c('v-col',[_vm._v(" Equisafe Invest est une plateforme agréée par l’Autorité des Marchés Financier (AMF) en tant que Prestataire de Service de Financement Participatif (PSFP) sous le numéro FP-2024-4, proposant des projets d’investissements immobiliers et PME, sous la forme d’investissements en actions ou en obligations. La communication d’une information à jour, exacte, complète et sincère est une condition indispensable à la fourniture d’un service de Prestataire de Service de Financement Participatif. Equisafe Invest recueille vos informations afin de connaitre votre profil investisseur et d’identifier votre connaissance des opérations d’investissement. Ces informations resteront confidentielles et ne feront l’objet d’aucune diffusion à un tiers. En fonction des réponses apportées à ce questionnaire d’adéquation, nous vous informerons de votre statut d’investisseur : averti ou non-averti. Cette qualification est strictement encadrée par le règlement (UE) 2020/1503 du Parlement Européen et du Conseil du 7 octobre 2020. "),_c('br'),_c('br'),_vm._v(" Cette évaluation est réalisée sur la seule base de vos réponses et de manière entièrement automatisée. ")])],1),_c('v-row',[_c('v-col',[_c('EqsBtn',_vm._b({staticClass:"ma-2",attrs:{"outlined":"","color":"success"}},'EqsBtn',{
                    action: () =>
                        _vm.window.open(
                            'https://www.equisafe-invest.io/cgu-equisafe-invest',
                            '_blank'
                        )
                },false),[_c('span',{staticClass:"no-text-transform"},[_vm._v(" "+_vm._s(_vm.$t("risk-aversion.dialog.cgu"))+" ")])])],1)],1),_c('v-row',[_c('v-col',[_c('EqsCheckbox',_vm._b({staticClass:"font-weight-bold mt-4 mb-4",attrs:{"value":_vm.formData.accept_cgu},on:{"update:value":function($event){return _vm.$set(_vm.formData, "accept_cgu", $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"ml-4 font-weight-bold"},[_c('p',{staticClass:"pt-8"},[_vm._v(" J’accepte les conditions générales d’utilisation d'Equisafe Invest ")]),_c('p',[_vm._v(" Et je déclare solennellement ne pas résider aux États-Unis d’Amérique ni au Canada, et confirme également ne posséder ni la nationalité américaine, ni la nationalité canadienne ")])])]},proxy:true}])},'EqsCheckbox',{
                    ..._vm.schemaDouble.accept_cgu
                },false))],1)],1),_c('v-dialog',{attrs:{"closable":"","transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',{staticClass:"text-h5",staticStyle:{"word-break":"break-word"}},[_vm._v(" Nous souhaitons en savoir plus sur votre profil investisseur !")]),_c('v-card-text',[_vm._v(" Afin de se conformer à la "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" nouvelle règlementation de Prestataire de Services de Financement Participatif ")]),_vm._v(" (Règlement 2020/1503 du Parlement Européen et du Conseil du 7 octobre 2020 relatif aux prestataires européens de services de financement participatif pour les entrepreneurs, et modifiant le règlement (UE) 2017/1129 et la directive (UE) 2019/1937). ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogProxy = false}}},[_vm._v(" J'ai compris ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }