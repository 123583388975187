<template>
    <div>
        <div v-for="(order, index) of orders" :key="index">
            <v-divider inset />
            <div :ref="order.id" class="eqs-card-project-details">
                <h1>
                    <router-link
                        v-if="order.payment_data"
                        :to="{
                            name: 'buy',
                            params: { orderId: order.id.toString() },
                            query: {
                                capinc_id: $route.params.projectId.toString(),
                                cip: $route.name === 'corner-project-detail'
                            }
                        }"
                    >
                        {{
                            $t("capital-increase.user.detail.order.number", {
                                index: orders.length - index
                            })
                        }}
                        <v-icon dense class="custom-right-icon"> mdi-open-in-new </v-icon>
                    </router-link>
                    <span v-else>{{
                        $t("capital-increase.user.detail.order.number", {
                            index: orders.length - index
                        })
                    }}</span>
                </h1>

                <v-row>
                    <v-col cols="12" md="6">
                        <div class="d-flex">
                            <div class="mr-8">
                                <h2 class="mt-4">
                                    {{ $t("capital-increase.user.detail.order.total-price") }}
                                </h2>
                                <h2 v-if="showFees(order)" class="mt-4">
                                    {{ $t("capital-increase.user.detail.order.buyer-fees") }}
                                </h2>
                                <h2 class="mt-4">
                                    {{ $t("capital-increase.user.detail.order.numberofshares") }}
                                </h2>
                                <h2 class="mt-4">
                                    {{
                                        $t(
                                            "capital-increase.user.detail.order.date-of-subscription"
                                        )
                                    }}
                                </h2>
                                <h2 v-if="order.payment_data" class="mt-4">
                                    {{ $t("user.buy.payment-data.title") }}
                                </h2>
                                <h2 v-if="order.envelope" class="mt-4">
                                    {{ $t("user.buy.steps.sub-card.envelope") }}
                                </h2>
                                <h2 v-if="order.tax_arrangement" class="mt-4">
                                    {{ $t("user.buy.steps.sub-card.tax-arrangement") }}
                                </h2>
                            </div>
                            <div class="order-number-wrapper">
                                <div class="mt-4">
                                    <NumberDisplayRefont
                                        :currency-type="capitalIncrease.currency"
                                        :value="order.total_price"
                                    />
                                </div>
                                <div v-if="showFees(order)" class="mt-4">
                                    <NumberDisplayRefont
                                        :currency-type="capitalIncrease.currency"
                                        :value="order.buyer_fees ? order.buyer_fees : 0"
                                    />
                                </div>
                                <div class="mt-4">
                                    <NumberDisplayRefont
                                        :is-currency="false"
                                        :is-float="false"
                                        :value="order.numberofshares"
                                    />
                                </div>
                                <h3 class="mt-4">
                                    {{ new Date(order.created_at).toLocaleDateString() }}
                                </h3>
                                <h3 v-if="order.payment_data" class="mt-4">
                                    {{ getOrderPayment(order) }}
                                </h3>
                                <h3 v-if="order.envelope" class="mt-4">
                                    {{ getOrderEnvelope(order) }}
                                </h3>
                                <h3 v-if="order.tax_arrangement" class="mt-4">
                                    {{ getOrderTaxArrangement(order) }}
                                </h3>
                            </div>
                        </div>
                        <div v-if="status">
                            <EqsLabel
                                v-bind="{
                                    editable: false,
                                    value: $t('capital-increase.user.detail.order.status')
                                }"
                            >
                                <EqsChip v-bind="status">
                                    {{ status.txt }}
                                </EqsChip>
                            </EqsLabel>
                        </div>
                        <v-divider />
                        <div>
                            <v-col class="limit-doc-cols">
                                <EqsFileInput
                                    multiple
                                    v-bind="{
                                        editable: false,
                                        value: signedDocs(order),
                                        loading: loading || localLoading,
                                        label: $tc(
                                            'capital-increase.user.detail.order.signed-doc',
                                            signedDocs(order).length
                                        )
                                    }"
                                />
                            </v-col>
                            <v-col v-if="orderInvoice(order).length > 0" class="limit-doc-cols">
                                <EqsFileInput
                                    multiple
                                    v-bind="{
                                        editable: false,
                                        value: orderInvoice(order),
                                        loading: loading || localLoading,
                                        label: $tc(
                                            'capital-increase.user.detail.order.invoice',
                                            orderInvoice(order).length
                                        )
                                    }"
                                />
                            </v-col>
                            <v-col v-if="certificates(order).length > 0" class="limit-doc-cols">
                                <EqsFileInput
                                    multiple
                                    v-bind="{
                                        editable: false,
                                        value: certificates(order),
                                        loading: loading || localLoading,
                                        label: $tc(
                                            'capital-increase.user.detail.order.certificat-doc',
                                            certificates(order).length
                                        )
                                    }"
                                />
                            </v-col>
                            <v-col
                                v-if="peapmeCertificates(order).length > 0"
                                class="limit-doc-cols"
                            >
                                <EqsFileInput
                                    multiple
                                    v-bind="{
                                        editable: false,
                                        value: peapmeCertificates(order),
                                        loading: loading || localLoading,
                                        label: $tc(
                                            'capital-increase.user.detail.order.pea-pme-eligibility-certificate',
                                            peapmeCertificates(order).length
                                        )
                                    }"
                                />
                            </v-col>
                            <v-col v-if="irCertificates(order).length > 0" class="limit-doc-cols">
                                <EqsFileInput
                                    multiple
                                    v-bind="{
                                        editable: false,
                                        value: irCertificates(order),
                                        loading: loading || localLoading,
                                        label: $tc(
                                            'capital-increase.user.detail.order.irpm-certificate',
                                            irCertificates(order).length
                                        )
                                    }"
                                />
                            </v-col>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <EqsTimeline
                            v-bind="{ items: orderTimeline(order), value: order.status }"
                        />
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import { statutes } from "@/util/payment";
import NumberDisplayRefont from "./NumberDisplayRefont.vue";
import {
    getSelectedPayment,
    getShortTaxLabelFromId,
    getShortEnvelopeLabelFromId,
    getPaymentLabelFromId
} from "@/components/views/user/buy/steps/util";

export default {
    name: "OrderDetailRefont",
    components: { NumberDisplayRefont },
    props: {
        capitalIncrease: {
            type: Object,
            required: true
        },
        orders: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            localLoading: false,
            procedures: {},
            ordersDocs: {}
        };
    },
    computed: {
        multipleOrders() {
            return this.orders.length > 1;
        },
        getCols() {
            return this.$reactive.getVal({
                mobile: 6,
                tablet: 4,
                desktop: 4
            });
        },
        status() {
            if (!this.order) return "";
            return statutes[this.order.status] || null;
        }
    },
    watch: {
        orders: {
            immediate: true,
            async handler(val) {
                this.orderDocs = {};
                if (!val.length) return;
                else {
                    this.localLoading = true;
                    await this.getAllProcedures();
                    val.forEach(order => {
                        const procedure = this.procedures[order.signature_procedure_id];
                        const docs = {
                            signed: this.getSignedDocs(procedure),
                            certificates: this.getCertificates(order),
                            orderInvoice: this.getOrderInvoice(order),
                            peapmeCertificates: this.getPeapmeCertificates(order),
                            irCertificates: this.getIrCertificates(order)
                        };
                        this.ordersDocs[order.id] = docs;
                    });
                    this.localLoading = false;
                }
            }
        },
        loading: {
            immediate: true,
            handler(val) {
                if (!val) {
                    this.scrollToOrderDetails();
                }
            }
        },
        localLoading: {
            immediate: true,
            handler(val) {
                if (!val) {
                    this.scrollToOrderDetails();
                }
            }
        }
    },
    methods: {
        showFees(order) {
            return order.buyer_fees && order.buyer_fees > 0;
        },
        getOrderPayment(order) {
            const payment = getSelectedPayment(order);
            if (payment) {
                return getPaymentLabelFromId(this, payment.name);
            }
            return "-";
        },
        getOrderEnvelope(order) {
            if (order?.envelope?.choice && order.envelope.choice.length) {
                return getShortEnvelopeLabelFromId(this, order.envelope.choice);
            } else {
                return "-";
            }
        },
        getOrderTaxArrangement(order) {
            if (order?.tax_arrangement?.choice && order.tax_arrangement.choice.length) {
                return getShortTaxLabelFromId(this, order.tax_arrangement.choice);
            } else {
                return "-";
            }
        },
        scrollToOrderDetails() {
            if (this.loading || this.localLoading) return;
            const scrollToOrder = this.$route.query.orderDetails;
            if (scrollToOrder !== null && scrollToOrder !== undefined) {
                this.$nextTick(() =>
                    this.$refs[scrollToOrder][0].scrollIntoView({ behavior: "smooth" })
                );
            }
        },
        async getAllProcedures() {
            this.procedures = {};
            let promises = [];
            this.orders.forEach(order => {
                if (order.signature_procedure_id) {
                    promises.push(this.$api.getProcedure(order.signature_procedure_id));
                }
            });
            const results = await Promise.allSettled(promises);
            results.forEach(res => {
                if (res.status === "rejected") {
                    console.log("Procedure rejected:", res);
                } else {
                    const procedure = res.value.data;
                    this.procedures[procedure.id] = procedure;
                }
            });
        },
        signedDocs(order) {
            const orderDocs = this.ordersDocs[order.id];
            if (!orderDocs) return [];
            else return orderDocs.signed;
        },
        certificates(order) {
            const orderDocs = this.ordersDocs[order.id];
            if (!orderDocs) return [];
            else return orderDocs.certificates;
        },
        orderInvoice(order) {
            const orderDocs = this.ordersDocs[order.id];
            if (!orderDocs) return [];
            else return orderDocs.orderInvoice;
        },
        peapmeCertificates(order) {
            const orderDocs = this.ordersDocs[order.id];
            if (!orderDocs) return [];
            else return orderDocs.peapmeCertificates;
        },
        irCertificates(order) {
            const orderDocs = this.ordersDocs[order.id];
            if (!orderDocs) return [];
            else return orderDocs.irCertificates;
        },
        getSignedDocs(procedure) {
            return procedure &&
                procedure.proceduredocument_set &&
                procedure.proceduredocument_set.length &&
                procedure.proceduredocument_set[0].signed_document
                ? procedure.proceduredocument_set.map(doc => doc.signed_document)
                : [];
        },
        getCertificates(order) {
            return order && order.investment_certificate_doc
                ? [order.investment_certificate_doc]
                : [];
        },
        getOrderInvoice(order) {
            return order && order.order_invoice_doc ? [order.order_invoice_doc] : [];
        },
        getPeapmeCertificates(order) {
            return order && order.peapme_certificate_doc ? [order.peapme_certificate_doc] : [];
        },
        getIrCertificates(order) {
            return order && order.ir_certificate_doc ? [order.ir_certificate_doc] : [];
        },
        orderTimeline(order) {
            const goToOrder = () =>
                this.$router.push({
                    name: "buy",
                    params: { orderId: order.id.toString() },
                    query: {
                        capinc_id: order.capinc_id,
                        cip: this.$route.name === "corner-project-detail"
                    }
                });
            const selectedPayment = getSelectedPayment(order);
            const statutesBase = [
                statutes.pending,
                {
                    ...statutes.signed,
                    btn: { txt: this.$t("user.buy.signed-btn"), action: goToOrder }
                },
                {
                    ...statutes.sequestered,
                    btn:
                        selectedPayment?.name === "walletdata"
                            ? { txt: this.$t("user.buy.sequestred-btn"), action: goToOrder }
                            : null
                }
            ];
            let timeline = [];
            switch (order.status) {
                case "error":
                    timeline = [...statutesBase, statutes.error];
                    break;
                case "cancel":
                    timeline = [...statutesBase, statutes.cancel];
                    break;
                case "refused":
                    timeline = [...statutesBase, statutes.refused];
                    break;
                default:
                    timeline = [
                        ...statutesBase,
                        statutes.validated,
                        {
                            ...statutes.success,
                            doneBtn: {
                                txt: this.$t("user.buy.success-btn"),
                                action: () => this.$router.push({ name: "portfolio" })
                            }
                        }
                    ];
                    break;
            }
            return timeline;
        }
    }
};
</script>

<style lang="scss" scoped>
.limit-doc-cols {
    max-width: 450px;
}
</style>
