export default {
    balance: state => state.balance,
    balanceLoading: state => state.balanceLoading,
    sequestre: state => state.sequestre,
    sequestreLoading: state => state.sequestreLoading,
    movements: state => state.movements,
    movementsLoading: state => state.movementsLoading,
    sequestreMovements: state => state.sequestreMovements,
    sequestreMovementsLoading: state => state.sequestreMovementsLoading,
    iban: state => state.iban,
    ibanLoading: state => state.ibanLoading,
    viban: state => state.viban,
    vibanLoading: state => state.vibanLoading
};
