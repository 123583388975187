import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }, data) => {
    DEBUG("GET_MY_PROJECTS_START");
    if (state.myProjectsInit && !state.myProjectsLoading) {
        try {
            commit("SET_MY_PROJECTS_LOADING", true);
            const myProjectsRes = await window.app.$api.getTinyCapitalIncreases({
                entity_id: store.getters.currentRole.entity_represented.id
            });
            DEBUG("GET_MY_PROJECTS_SUCCESS");
            commit("SET_MY_PROJECTS", myProjectsRes.data);
        } catch (e) {
            DEBUG("GET_MY_PROJECTS_ERROR");
            window.app.$ui.error(e, "capital_increase_admin_history");
            throw e;
        } finally {
            commit("SET_MY_PROJECTS_LOADING", false);
        }
    } else {
        DEBUG("GET_MY_PROJECTS_BYPASSED");
    }
};
