<template>
    <div>
        <v-menu
            v-if="users.length"
            :open-on-click="!$reactive.isDesktop()"
            :open-on-hover="$reactive.isDesktop()"
            right
            rounded="lg"
            max-width="100vw"
            max-height="100vh"
            class="eqs-multi-avatars-menu"
        >
            <template #activator="{ on }">
                <div :class="`eqs-multi-avatars ${elClass}-container d-flex no-wrap`" v-on="on">
                    <div
                        v-for="(user, index) in truncatedUsers"
                        :key="index"
                        :class="`eqs-multi-avatars__item ${
                            index ? 'eqs-multi-avatars__next-item' : ''
                        }`"
                    >
                        <EqsAvatar v-bind="{ user, ...$attrs, noBadge: true, loading }" />
                    </div>
                    <div
                        v-if="others.length > 1"
                        class="eqs-multi-avatars__next-item eqs-multi-avatars__item"
                    >
                        <EqsAvatar
                            v-bind="{
                                text: `+${others.length}`,
                                ...$attrs,
                                noBadge: true,
                                loading
                            }"
                        />
                    </div>
                </div>
            </template>
            <EqsCard closable class="eqs-multi-avatars-list elevation-3">
                <EqsList
                    class="px-2 pt-3"
                    v-bind="{ items: users, eqsType: 'avatar-two-line', loading }"
                >
                    <template #item="{ item }">
                        <EqsAvatar class="mr-3" no-badge v-bind="{ user: item, eqsType: 'list' }" />
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                </EqsList>
            </EqsCard>
        </v-menu>
        <EqsAvatar v-else-if="loading" v-bind="{ loading: true, text: '+0' }" />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsMultiAvatars.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import EqsAvatar from "../../atoms/EqsAvatar/EqsAvatar.vue";
import EqsList from "../EqsList/EqsList.vue";
import EqsCard from "../EqsCard/EqsCard.vue";

export default {
    name: "EqsMultiAvatars",
    components: {
        EqsAvatar,
        EqsList,
        EqsCard
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-multi-avatars"
        },
        users: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        truncatedUsers() {
            return this.users.slice(0, this.users.length <= 4 ? 4 : 3);
        },
        others() {
            return this.users.slice(3);
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsMultiAvatars.scss";
</style>
