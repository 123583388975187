<template>
    <EqsStepper
        class="fill-height"
        height="100%"
        :value.sync="step"
        :steps="steps"
        :progress="progress"
    >
        <template #default="{ item }">
            <component
                :is="item.is"
                :state="stepperState"
                :loading="loading || localLoading || balanceLoading"
                v-bind="{ errorText, formData, schemaDouble, balance, action }"
                @error="
                    e => {
                        catchError();
                    }
                "
            />
        </template>
    </EqsStepper>
</template>

<script>
import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";

import AddPaymentForm from "@/components/views/shareledger/operations/payments/AddPaymentForm";
import Done from "@/components/views/shareledger/operations/payments/Done";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "AddNewPayment",
    components: {
        AddPaymentForm,
        Done
    },
    mixins: [stepperFormMixins],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            action: "to_send",
            operation: {},
            step: 0,
            stepperState: "success",
            errorText: "",
            schemaDouble: {},
            formData: {},
            oldFormData: {
                // TODO here need to get url from investor instead of url from entity
                debited_entity: this.$entity.represented(),
                operation_date: new Date().toISOString().substr(0, 10)
            },
            localLoading: false,
            actionsTxts: {
                ask: {
                    title: this.$t("payments.ask.title"),
                    addBtn: this.$t("payments.ask.add-btn"),
                    done: this.$t("payments.ask.done")
                },
                upload: {
                    title: this.$t("payments.upload.title"),
                    addBtn: this.$t("payments.upload.add-btn"),
                    done: this.$t("payments.upload.done")
                },
                download: {
                    title: this.$t("payments.download.title"),
                    addBtn: this.$t("payments.download.add-btn"),
                    done: this.$t("payments.download.done")
                },
                to_send: {
                    title: this.$t("payments.to_send.title"),
                    addBtn: this.$t("payments.to_send.add-btn"),
                    done: this.$t("payments.to_send.done")
                }
            }
        };
    },
    computed: {
        ...mapGetters(["balance", "balanceLoading"]),
        steps() {
            let schemaDouble = {
                operation_date: {
                    dataType: "date",
                    isRequired: true
                },
                amount: {
                    dataType: "float",
                    isRequired: true
                },
                operation_type: {
                    isRequired: true
                },
                comment: {
                    isRequired: false
                },
                docs: {
                    dataType: "multi-file",
                    isRequired: false
                }
            };

            if (this.action == "to_send")
                schemaDouble["credited_entity"] = {
                    dataType: "object",
                    isRequired: true
                };
            else if (this.action == "ask")
                schemaDouble["debited_entity"] = {
                    dataType: "object",
                    isRequired: true
                };

            return [
                {
                    title: this.actionsTxts[this.action].title,
                    item: {
                        is: "AddPaymentForm"
                    },
                    back: {
                        fn: () =>
                            this.$router.push({
                                name: "operations",
                                query: {
                                    tab: "payments"
                                }
                            }),
                        txt: this.$t("label.cancel")
                    },
                    next: {
                        fn: this.createPaymentOperation,
                        txt: this.actionsTxts[this.action].addBtn
                    },
                    schemaDouble
                },

                {
                    title: this.actionsTxts[this.action].done,
                    item: {
                        is: "Done"
                    },
                    noBack: true,
                    noForm: true,
                    next: {
                        fn: () =>
                            this.$router.push({
                                name: "operations",
                                query: {
                                    tab: "payments"
                                }
                            }),
                        txt:
                            this.stepperState === "success"
                                ? this.$t("payments.done.success")
                                : this.$t("payments.done.error")
                    }
                }
            ];
        }
    },
    watch: {
        "$route.query.action": function () {
            this.action = this.$route.query.action;
        },
        step: {
            immediate: true,
            handler(val) {
                this.schemaDouble = this.steps[this.step].schemaDouble;
                if (this.schemaDouble) this.buildFormData(this.schemaDouble, this.oldFormData);
            }
        },
        steps() {
            this.schemaDouble = this.steps[this.step].schemaDouble;
            this.oldFormData = this.formData;
            if (this.schemaDouble) this.buildFormData(this.schemaDouble, this.oldFormData);
        }
    },
    async created() {
        this.getBalance();
        this.action = this.$route.query.action;
    },
    methods: {
        ...mapActions(["getBalance"]),
        prevStep() {
            this.oldFormData = this.formData;
            this.step -= 1;
        },
        catchError() {
            this.stepperState = "error";
            this.goToLastStep();
        },
        async createPaymentOperation() {
            try {
                this.errorText = "";
                this.localLoading = true;

                let data = this.formatFormData(this.schemaDouble);
                if (!data.amount) {
                    data.amount = 0;
                }
                if (!data.emitter_fees) {
                    data.emitter_fees = 0;
                }
                if (!data.receiver_fees) {
                    data.receiver_fees = 0;
                }
                data.action = this.action;
                delete data.credited_entity;
                delete data.debited_entity;

                if (data.action == "ask") {
                    data.debited_entity_id = this.formData.debited_entity.entity.id;
                } else {
                    data.credited_entity_id = this.formData.credited_entity.entity.id;
                }
                await this.$api.createTransfer(data);
                this.goToLastStep();
            } catch (e) {
                console.error({ e });
                this.errorText = this.handleError(e, "operations_add_movement");
                if (this.errorText) this.catchError();
            } finally {
                this.localLoading = false;
            }
        },
        goToLastStep() {
            this.step = this.steps.length - 1;
        }
    }
};
</script>
