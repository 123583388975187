export const updateRoutesByUniverse = (ctx, routes) => {
    let newRoutes = ctx.$universe.restrictedRoutes()
        ? routes.filter(route => !ctx.$universe.restrictedRoutes().includes(route.value))
        : routes;

    // Filter by Kyoseil role
    const entity = ctx.$store.getters.currentRole.entity_represented;
    if (ctx.$universe.isKyoseil() && !ctx.$entity.isUniverseAdmin()) {
        newRoutes = newRoutes.filter(route => {
            if (
                !ctx.$entity.isAffiliationAdmin(true) &&
                (ctx.$entity.isAffiliationCgp() ||
                    ctx.$entity.isAffiliationBc() ||
                    ctx.$entity.isAffiliationInvestor())
            ) {
                return (
                    !["wallet", "portfolio"].includes(route.value) &&
                    ![ctx.$t("app.navigation-drawer.subheader.account")].includes(
                        route.subheader
                    ) &&
                    (["affiliation", "projects", "history", "document_to_sign"].includes(
                        route.value
                    ) ||
                        [
                            ctx.$t("app.navigation-drawer.subheader.projects"),
                            ctx.$t("app.navigation-drawer.subheader.activity-documents")
                        ].includes(route.subheader))
                );
            }
            return true;
        });
    }
    // Filter pour Neotrees
    if (ctx.$universe.isNeotrees() && !ctx.$entity.isUniverseAdmin()) {
        newRoutes = newRoutes.filter(route => {
            return (
                ![
                    "wallet",
                    "document_to_sign",
                    "digitalize",
                    "capitalisation",
                    "investors",
                    "operations"
                ].includes(route.value) &&
                ![
                    ctx.$t("app.navigation-drawer.subheader.securities-investors"),
                    ctx.$t("app.navigation-drawer.title.capitalisation"),
                    ctx.$t("app.navigation-drawer.subheader.activity-documents")
                ].includes(route.subheader)
            );
        });
    }

    if (ctx.$entity.hasAffiliationRole()) {
        newRoutes.push({
            title: ctx.$t("universe.kyoseil.navbar.menu"),
            to: `/affiliation`,
            icon: "mdi-briefcase-plus"
        });
    }

    if (ctx.$entity.isUniverseAdmin()) {
        newRoutes.push({
            title: "Dashboard des investisseurs",
            to: `/investors-dashboard`,
            icon: "mdi-account-box-multiple"
        });
    }

    if (ctx.$universe.name() === "myclubdeal" || ctx.$universe.isExternKyc()) {
        const walletIndex = newRoutes.findIndex(route => route.value === "wallet");
        if (walletIndex !== -1) {
            newRoutes.splice(walletIndex, 0, {
                subheader: ctx.$t("app.navigation-drawer.title.wallet")
            });
        }
    }

    newRoutes.splice(1, 0, {
        title: ctx.$universe.isEquisafe()
            ? ctx.$t("dashboard.dashboard-title")
            : ctx.$t("universe.homenav"),
        to: ctx.$universe.isEquisafe() ? `/dashboard/${entity.id}` : `/home/${entity.id}`,
        icon: "mdi-home"
    });

    return newRoutes;
};

export const company = (ctx, entity) => {
    let companyMenu = [
        { subheader: ctx.$t("app.navigation-drawer.subheader.account") },
        {
            title: ctx.$t("app.navigation-drawer.title.investments"),
            to: `/portfolio/${entity.id}`,
            value: "portfolio",
            icon: "mdi-finance"
        },
        {
            title: ctx.$t("app.navigation-drawer.title.wallet"),
            to: `/wallet/${entity.id}`,
            value: "wallet",
            icon: "mdi-wallet"
        },
        // deactivate for prod
        // {
        //     title: ctx.$t("app.navigation-drawer.title.ag"),
        //     to: `/${entity.id}/ag`,
        //     value: "ag",
        //     icon: "mdi-account-group-outline"
        // },
        { subheader: ctx.$t("app.navigation-drawer.subheader.projects") },
        {
            title: ctx.$t("app.navigation-drawer.title.projects"),
            to: `/projects/${entity.id}`,
            value: "projects",
            icon: "mdi-shopping"
        },
        {
            title: ctx.$t("app.navigation-drawer.title.crowdfunding"),
            to: `/crowdfunding/${entity.id}`,
            value: "corner-detail",
            icon: "mdi-shopping-outline"
        },
        {
            subheader: ctx.$t("app.navigation-drawer.subheader.securities-investors")
        }
    ];
    if (!ctx.$blockchain.companyOnBlockchain(entity))
        companyMenu.push({
            title: ctx.$t("app.navigation-drawer.title.digitalize"),
            to: `/digitalize/${entity.id}`,
            value: "digitalize",
            icon: "mdi-star-shooting"
        });
    else {
        companyMenu.push({
            title: ctx.$t("app.navigation-drawer.title.capitalisation"),
            to: `/capitalisation/${entity.id}`,
            disabled: entity.blockchainize_state !== "FINISHED",
            value: "capitalisation",
            icon: "mdi-chart-pie"
        });
    }

    return updateRoutesByUniverse(
        ctx,
        companyMenu.concat([
            {
                title: ctx.$t("app.navigation-drawer.title.investors"),
                to: `/investors/${entity.id}`,
                disabled: entity.blockchainize_state !== "FINISHED",
                value: "investors",
                icon: "mdi-account-box-multiple-outline"
            },
            {
                title: ctx.$t("app.navigation-drawer.title.operations"),
                to: `/operations/${entity.id}`,
                disabled: !entity.has_digitalized_shareledger,
                value: "operations",
                icon: "mdi-bank-transfer"
            },
            {
                subheader: ctx.$t("app.navigation-drawer.subheader.activity-documents")
            },
            {
                title: ctx.$t("app.navigation-drawer.title.signatures"),
                to: `/signatures/${entity.id}`,
                value: "document_to_sign",
                icon: "mdi-draw"
            },
            {
                title: ctx.$t("app.navigation-drawer.title.history"),
                to: `/history/${entity.id}`,
                value: "history",
                icon: "mdi-view-list"
            }
            // {
            //     title: ctx.$t("app.navigation-drawer.title.reporting"),
            //     to: `/valuation/${entity.id}`,
            //     value: "valuation",
            //     icon: "mdi-chart-box-plus-outline"
            // }
        ])
    );
};

export const individual = (ctx, entity) => {
    let routes = [
        { subheader: ctx.$t("app.navigation-drawer.subheader.account") },
        {
            title: ctx.$t("app.navigation-drawer.title.investments"),
            to: `/portfolio/${entity.id}`,
            value: "portfolio",
            icon: "mdi-finance"
        },
        {
            title: ctx.$t("app.navigation-drawer.title.wallet"),
            to: `/wallet/${entity.id}`,
            value: "wallet",
            icon: "mdi-wallet"
        },
        // deactivate for prod
        // {
        //     title: ctx.$t("app.navigation-drawer.title.ag"),
        //     to: `/${entity.id}/ag`,
        //     value: "ag",
        //     icon: "mdi-account-group-outline"
        // },
        { subheader: ctx.$t("app.navigation-drawer.subheader.projects") },
        {
            title: ctx.$t("app.navigation-drawer.title.projects"),
            to: `/projects/${entity.id}`,
            value: "projects",
            icon: "mdi-shopping"
        },
        {
            title: ctx.$t("app.navigation-drawer.title.crowdfunding"),
            to: `/crowdfunding/${entity.id}`,
            value: "corner-detail",
            icon: "mdi-shopping-outline"
        },
        {
            subheader: ctx.$t("app.navigation-drawer.subheader.activity-documents")
        },
        {
            title: ctx.$t("app.navigation-drawer.title.signatures"),
            to: `/signatures/${entity.id}`,
            value: "document_to_sign",
            icon: "mdi-draw"
        },
        {
            title: ctx.$t("app.navigation-drawer.title.history"),
            to: `/history/${entity.id}`,
            value: "history",
            icon: "mdi-view-list"
        }
    ];

    return updateRoutesByUniverse(ctx, routes);
};
