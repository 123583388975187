import { textSizing } from "../../../templates/text";
import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            "input-label": {
                "margin-left": "20px",
                "margin-bottom": "10px",
                "icon-size": "16px",
                "asterisk-size": "8px",
                "locked-size": getVal(currentSize, {
                    mobile: "0.6rem",
                    tablet: "0.7rem",
                    desktop: "0.8rem"
                })
            }
        }
    };
};

export const dataTypes = (currentSize = {}, ctx = {}) => {
    return {
        default: {
            ...textSizing(currentSize, ctx.eqsSize),
            asterisk: {
                "offset-y": "10px",
                "offset-x": "4px",
                overlap: true,
                icon: "mdi-asterisk"
            },
            locked: {
                "offset-y": "10px",
                "offset-x": "4px",
                overlap: true,
                icon: "mdi-lock"
            }
        }
    };
};
