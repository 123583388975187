<template>
    <div class="eqs-skeleton" :el-class="elClass" :style="{ ...getStyle, ...additionalStyle }">
        <v-fade-transition style="width: 100%" hide-on-leave class="eqs-skeleton__container">
            <v-skeleton-loader
                v-show="encapsulate || !loading"
                class="eqs-skeleton__container__content"
                :boilerplate="!loading"
                v-bind="{ ...$attrs, loading: !encapsulate || loading }"
                v-on="$listeners"
            >
                <slot />
            </v-skeleton-loader>
        </v-fade-transition>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsSkeleton.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsSkeleton",
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-skeleton"
        },
        width: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            encapsulate: false
        };
    },
    computed: {
        additionalStyle() {
            return this.width
                ? {
                      "--skeleton-card-width": this.width
                  }
                : {};
        }
    },
    created() {
        this.encapsulate = !!this.$slots["default"];
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsSkeleton.scss";
</style>
