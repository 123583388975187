import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ state, dispatch }, representativeId) => {
    DEBUG("GET_REPRESENTATIVE_START");
    let representative = state.representatives.find(
        _representative => _representative.id === representativeId
    );
    if (!representative) {
        try {
            await dispatch("getRepresentatives");
            representative = state.representatives.find(
                _representative => _representative.id === representativeId
            );
        } catch (e) {
            throw e;
        }
    }
    if (representative) DEBUG("GET_REPRESENTATIVE_SUCCESS");
    else DEBUG("GET_REPRESENTATIVE_ERROR");
    return representative || {};
};
