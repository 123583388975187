<template>
    <div class="d-flex fill-height">
        <v-row class="fill-height fill-width pa-0 ma-0">
            <v-col
                v-if="isRoot || (isDetail && $reactive.isDesktop())"
                :cols="$reactive.isDesktop() && isDetail ? 6 : 12"
                class="pa-0 fill-height d-flex flex-column"
            >
                <EqsTabs v-bind="{ tabs, loading }" :value.sync="tab">
                    <template #default="{ item }">
                        <component
                            :is="item.is"
                            class="d-flex fill-height"
                            editable
                            :selected-organ.sync="selectedOrgan"
                            v-bind="{
                                loading,
                                ...item
                            }"
                        />
                    </template>
                </EqsTabs>
            </v-col>
            <v-col
                v-if="!isRoot || isDetail"
                :cols="$reactive.isDesktop() && isDetail ? 6 : 12"
                class="fill-height pa-0"
            >
                <router-view v-bind="{ loading }" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Decision from "@/components/views/governance/decision/_Decision";
import OrgansAndRepresentatives from "./OrgansAndRepresentatives.vue";

export default {
    name: "Governance",
    components: {
        OrgansAndRepresentatives,
        Decision
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tab: null,
            selectedOrgan: null,
            selectedRepresentative: null,
            selectedDecision: null,
            tabs: [
                {
                    id: "decisions",
                    title: this.$t("governance.decisions.title"),
                    item: {
                        is: "Decision",
                        add: {
                            fn: this.addDecision,
                            txt: this.$t("governance.decisions.add")
                        },
                        searchable: {
                            placeholder: this.$t("governance.decisions.search")
                        },
                        noDataCustom: {
                            title: this.$t("governance.decisions.no-data-title"),
                            text: this.$t("governance.decisions.no-data-text"),
                            img: "/images/governance/decision.svg"
                        }
                    }
                },
                {
                    id: "organs_representatives",
                    title: this.$t("governance.organs_representatives.title"),
                    item: {
                        is: "OrgansAndRepresentatives"
                    }
                }
            ]
        };
    },
    computed: {
        isRoot() {
            return this.$route.name === "governance";
        },
        isDetail() {
            return this.$route.name.includes("detail");
        }
    },
    watch: {
        isRoot(val) {
            if (val) {
                this.selectedOrgan = null;
                this.selectedRepresentative = null;
                this.selectedDecision = null;
            }
        },
        selectedOrgan(val) {
            if (val)
                this.$router.push({
                    name: "governance-organ-detail",
                    params: { organId: val.id }
                });
        }
    },
    methods: {
        addDecision() {
            this.$router.push({
                name: "governance-decision-add",
                params: this.$route.params
            });
        }
    }
};
</script>
