import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, organ) => {
    DEBUG("CREATE_ORGAN_START");
    try {
        commit("SET_ORGANS_LOADING", true);
        const organRes = await window.app.$api.createOrgan(organ);
        DEBUG("CREATE_ORGAN_SUCCESS");
        commit("ADD_ORGAN", organRes.data);
        return organRes.data;
    } catch (e) {
        DEBUG("CREATE_ORGAN_ERROR");
        throw e;
    } finally {
        commit("SET_ORGANS_LOADING", false);
    }
};
