var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({on:{"close":() => (_vm.dialogProxy = false)},model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},'EqsDialog',{
        confirm: {
            fn: _vm.openTxExplorerPage,
            txt: _vm.$t('molecule.dialog.go-to-explorer')
        },
        cancel: {}
    },false),[_c('div',{staticClass:"d-flex flex-column fill-height"},[(_vm.link)?_c('VueQrcode',{staticClass:"vue-qr-code",attrs:{"value":_vm.link,"options":{ width: '100%' }}}):_vm._e(),(_vm.tx)?_c('GlobalCopyBadge',{staticClass:"mx-7",attrs:{"value":_vm.tx,"truncate":45}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }