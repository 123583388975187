import { capitalize, difference, flat } from "@equisafe-ui-shared/util";
import { entityDocs } from "@/services/entity";
import {
    contactInfos as individualContactInfos,
    complianceInfos as individualComplianceInfos
} from "@/components/views/registration/schemas/individual";
import {
    contactInfos as companyContactInfos,
    legalInfos as companyLegalInfos
} from "@/components/views/registration/schemas/company";

import {
    formErrorMixin,
    progressMixin,
    formChangedMixin
} from "@equisafe-ui-vue/src/mixins/formMixin";

import { crudActionsMixin } from "@equisafe-ui-vue/src/mixins/inputMixin";

export const formKycMixin = {
    methods: {
        formatFormData(formData) {
            return Object.keys(formData).reduce((acc, key) => {
                let subkey = `${key}_id`;
                if (entityDocs.includes(key)) acc[subkey] = formData[key] && formData[key].id;
                else if (key === "shareholder_set") {
                    const shareholders = formData[key];
                    for (const sh_key of Object.keys(shareholders)) {
                        if (sh_key.startsWith("doc_idshareholder")) {
                            let sh_subkey = `${sh_key}_id`;
                            acc[sh_subkey] = shareholders[sh_key] && shareholders[sh_key].id;
                        }
                    }
                } else
                    switch (key) {
                        case "region":
                            acc[key] = formData[key] && formData[key].url;
                            break;
                        case "nationality":
                            acc[key] = formData[key] && formData[key].url;
                            break;
                        case "birth_country":
                            acc[key] = formData[key] && formData[key].url;
                            break;
                        case "fiscal_residency":
                            acc[key] = formData[key] && formData[key].url;
                            break;
                        case "first_name":
                            acc[key] = capitalize(formData[key]);
                            break;
                        case "last_name":
                            acc[key] = capitalize(formData[key]);
                            break;
                        default:
                            acc[key] = formData[key];
                    }
                return acc;
            }, {});
        },
        buildFormData(schema) {
            Object.entries(schema).forEach(entry => {
                const key = entry[0];
                const item = entry[1];
                switch (item.dataType) {
                    case "file":
                        this.$set(this.formData, key, this.entity[key] || undefined);
                        break;
                    case "multi-file":
                        let numFile = 0;
                        for (const category of item.categories) {
                            if (numFile === item.numSelect) break;
                            this.$set(
                                this.formData,
                                category.key,
                                this.entity[category.key] || undefined
                            );
                            if (this.entity[category.key]) numFile++;
                        }
                        break;
                    case "bool":
                        this.$set(this.formData, key, this.entity[key] || false);
                        break;
                    default:
                        this.$set(this.formData, key, this.entity[key] || "");
                }
            });
            return this.formData;
        }
    }
};

export const editProfileMixin = {
    mixins: [formKycMixin, formErrorMixin, progressMixin, formChangedMixin, crudActionsMixin],
    props: {
        entity: {
            type: Object,
            required: true
        }
    },
    watch: {
        entity: {
            immediate: true,
            handler(val) {
                this.buildFormData(this.schemaDouble, val);
                this.saveFormData();
            }
        }
    },
    data() {
        return {
            editable: false,
            validForm: false,
            formData: {},
            localLoading: false
        };
    },
    methods: {
        cancel() {
            this.resetFormData();
        },
        async save() {
            try {
                this.localLoading = true;
                await this.$entity.updateEntity(this.formatFormData(this.formData));
                this.saveFormData();
            } catch (e) {
                this.handleError(e, "entity_update_individual");
                this.editable = true;
            } finally {
                this.localLoading = false;
            }
        }
    }
};

export const entityDetailsMixin = {
    components: {
        IndividualInfoPublicForm: () =>
            import("@/components/views/registration/individual/info/PublicForm"),
        CompanyInfoForm: () => import("@/components/views/registration/company/info/_Info"),
        IndividualComplianceInfosForm: () =>
            import("@/components/views/registration/individual/complianceInfos/_ComplianceInfos"),
        NonListedLegalInfosForm: () =>
            import(
                "@/components/views/registration/company/legalInfos/NonlistedLegalInfosForm.vue"
            ),
        AssociationLegalInfosForm: () =>
            import(
                "@/components/views/registration/company/legalInfos/AssociationLegalInfosForm.vue"
            ),
        CustodianLegalInfosForm: () =>
            import("@/components/views/registration/company/legalInfos/CustodianLegalInfosForm.vue")
    },
    methods: {
        entityDetailsSchema(ctx, entity) {
            return ctx.$entity.isCompany(entity)
                ? companyContactInfos(ctx)
                : individualContactInfos(ctx);
        },
        entityComplianceSchema(ctx, entity) {
            // console.log({
            //   entityKYC: JSON.parse(JSON.stringify(entity)),
            // });
            return ctx.$entity.isCompany(entity)
                ? companyLegalInfos(ctx)
                : individualComplianceInfos(ctx);
        }
    }
};

export const kycGetRegionMixin = {
    data() {
        return {
            regionList: null
        };
    },
    watch: {
        "formData.country": {
            immediate: true,
            async handler(newCountry, oldCountry) {
                if (this.entity.is_company) return;
                else if (
                    this.editable &&
                    newCountry &&
                    oldCountry &&
                    newCountry.name !== oldCountry.name
                )
                    this.formData.region = null;
                this.getRegionList();
            }
        },
        "formData.nationality": {
            immediate: true,
            async handler(newNationality, oldNationality) {
                if (!this.entity.is_company) return;
                else if (
                    this.editable &&
                    newNationality &&
                    oldNationality &&
                    newNationality.name !== oldNationality.name
                )
                    this.formData.region = null;
                this.getRegionList();
            }
        }
    },
    methods: {
        async getRegionList() {
            if (!this.formData.country && !this.formData.nationality) return;
            try {
                const res = await this.$api.getRegions({
                    numcode: this.formData.country
                        ? this.formData.country.numcode
                        : this.formData.nationality.numcode,
                    ordering: "name"
                });
                this.regionList = res.data;
            } catch (e) {
                console.error({
                    e
                });
                throw e;
            }
        }
    }
};
