import DEBUG from "@/store/debug.js";

export default async ({ state, commit, dispatch }, shareledgerId) => {
    DEBUG("FETCH_SHARELEDGER_START");
    if (!shareledgerId) throw new Error("no shareledgerId");
    if (!state.shareledgers.length) {
        try {
            await dispatch("getShareledgers");
        } catch (e) {
            throw e;
        }
    }
    try {
        const res = await window.app.$api.getShareledger(shareledgerId);
        if (state.shareledgers.find(shareledger => shareledger.id === res.data.id))
            commit("UPDATE_SHARELEDGER", res.data);
        else commit("ADD_SHARELEDGER", res.data);
        DEBUG("FETCH_SHARELEDGER_SUCCESS");
    } catch (e) {
        DEBUG("FETCH_SHARELEDGER_ERROR");
        throw e;
    }
};
