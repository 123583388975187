<template>
    <EqsStepper
        class="fill-height"
        height="100%"
        :value.sync="step"
        :steps="steps"
        :progress="progress"
    >
        <template #default="{ item }">
            <component
                :is="item.is"
                :state="stepperState"
                :loading="loading || localLoading"
                v-bind="{ formData, schemaDouble, resetShareledgerForm, errorText }"
                @error="
                    e => {
                        catchError();
                    }
                "
            />
            <v-scroll-y-transition hide-on-leave>
                <GlobalBlockchainLoader v-if="localLoading" v-bind="{ height: '24vh' }" />
            </v-scroll-y-transition>
        </template>
    </EqsStepper>
</template>

<script>
import AddMovementForm from "./AddMovementForm";
import Done from "./Done";

import {
    progressMixin,
    buildFormDataMixin,
    formatFormDataMixin
} from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "AddMovement",
    components: {
        AddMovementForm,
        Done,
        GlobalBlockchainLoader: () =>
            import("@/components/global/globalBlockchainLoader/GlobalBlockchainLoader")
    },
    mixins: [progressMixin, buildFormDataMixin, formatFormDataMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            operation: {},
            step: 0,
            errorText: "",
            stepperState: "success",
            schemaDouble: {},
            formData: {},
            oldFormData: {
                blockchain: true
            },
            localLoading: false
        };
    },
    computed: {
        shareledgerSchemaDouble() {
            const isSca = ["Souscription", "Cession", "Annulation"].includes(
                this.formData.asset_op_type
            );
            const scaFields = ["quantity", "price"];
            let res = {
                asset_op_type: {
                    isRequired: true
                }
            };
            if (isSca)
                scaFields.forEach(field => (res[field] = { dataType: "float", isRequired: true }));
            else if (this.formData.asset_op_type === "Division")
                res.division = { dataType: "float", isRequired: true };
            else if (this.formData.asset_op_type === "Multiplication")
                res.multiplication = { dataType: "float", isRequired: true };

            if (this.formData.blockchain && isSca) {
                if (["Souscription", "Cession"].includes(this.formData.asset_op_type))
                    res.receiver = { dataType: "id", isRequired: true };

                if (["Cession", "Annulation"].includes(this.formData.asset_op_type))
                    res.emitter = { dataType: "id", isRequired: true };
            }
            return res;
        },
        mainSchemaDouble() {
            // const infoFields = ["email", "first_name", "last_name"];
            return {
                blockchain: {
                    dataType: "bool",
                    isRequired: false
                },
                shareledger: {
                    dataType: "url",
                    isRequired: true
                },
                comment: {
                    isRequired: false
                },
                docs: {
                    dataType: "multi-file",
                    isRequired: false
                },
                operation_date: { isRequired: false },
                division: { dataType: "float", isRequired: false },
                multiplication: { dataType: "float", isRequired: false }
            };
        },
        steps() {
            return [
                {
                    title: this.$t("movements.add-title"),
                    item: {
                        is: "AddMovementForm"
                    },
                    back: {
                        fn: () =>
                            this.$router
                                .push({
                                    name: "operations",
                                    query: {
                                        tab: "movements"
                                    }
                                })
                                .catch(() => {}),
                        txt: this.$t("label.cancel")
                    },
                    next: {
                        fn: async () => await this.createAssetOperation(),
                        txt: this.$t("movements.add-btn")
                    }
                },
                {
                    title: this.$t("movements.done.title"),
                    item: {
                        is: "Done"
                    },
                    noBack: true,
                    next: {
                        fn: () =>
                            this.$router
                                .push({
                                    name: "operations",
                                    query: {
                                        tab: "movements"
                                    }
                                })
                                .catch(() => {}),
                        txt:
                            this.stepperState === "success"
                                ? this.$t("movements.done.success")
                                : this.$t("movements.done.error")
                    },
                    schemaDouble: {}
                }
            ];
        }
    },
    watch: {
        "formData.asset_op_type": {
            immediate: true,
            handler(val) {
                this.schemaDouble = {
                    ...this.mainSchemaDouble,
                    ...this.shareledgerSchemaDouble
                };
                this.buildFormData(this.schemaDouble, {
                    asset_op_type: this.formData.asset_op_type,
                    shareledger: this.formData.shareledger,
                    operation_date: new Date().toISOString().substr(0, 10),
                    blockchain: true
                });
            }
        }
    },
    methods: {
        resetShareledgerForm() {
            this.buildFormData(this.shareledgerSchemaDouble, {
                asset_op_type: this.formData.asset_op_type,
                shareledger: this.formData.shareledger,
                operation_date: new Date().toISOString().substr(0, 10),
                blockchain: true
            });
        },
        async createAssetOperation() {
            try {
                this.errorText = "";
                this.localLoading = true;
                let data = this.formatFormData(this.schemaDouble);
                if (!data.quantity) {
                    data.quantity = 0;
                }
                if (!data.multiplication) {
                    data.multiplication = 0;
                }
                if (!data.division) {
                    data.division = 0;
                }
                if (!data["members"]) {
                    let members = [];
                    // in case of Annulation need to switch emitter with receiver to avoid to modify the form
                    if (this.formData.asset_op_type == "Annulation") {
                        this.formData.receiver = this.formData.emitter;
                        delete this.formData.emitter;
                        delete data.emitter_id;
                    }
                    if (this.formData.receiver) {
                        delete data.receiver_id;
                        data.receiver = this.formData.receiver.entity.id;
                        members.push({
                            entity: this.formData.receiver.entity.id,
                            role: "receiver",
                            email: this.formData["receiver_email"],
                            first_name: this.formData["receiver_first_name"],
                            last_name: this.formData["receiver_last_name"]
                        });
                    }

                    if (this.formData.emitter) {
                        delete data.emitter_id;
                        data.emitter = this.formData.emitter.entity.id;
                        members.push({
                            entity: this.formData.emitter.entity.id,
                            role: "emitter",
                            email: this.formData["emitter_email"],
                            first_name: this.formData["emitter_first_name"],
                            last_name: this.formData["emitter_last_name"]
                        });
                    }
                    data["members"] = members;
                }

                data.state = "held";

                await this.$api.createAssetOperation(data);
                this.goToLastStep();
            } catch (e) {
                this.errorText = this.$ui.error(e, "operations_add_movement");
                this.catchError();
            } finally {
                this.localLoading = false;
            }
        },
        catchError() {
            this.stepperState = "error";
            this.goToLastStep();
        },
        goToLastStep() {
            this.step = this.steps.length - 1;
        }
    }
};
</script>
