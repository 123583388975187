<template>
    <EqsList
        borders
        three-line
        no-data-skeleton
        v-bind="{
            loading,
            items: accounts
        }"
        avatar
    >
        <template #item="{ item }">
            <EqsAvatar class="mr-3" v-bind="{ src: item.blockchain.img }" />
            <v-list-item-content
                :el-class="`blockchain-${item.selected ? 'selected' : 'not-selected'}`"
            >
                <v-list-item-title :class="item.selected ? 'selected-title' : ''">
                    {{ item.blockchain.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    <GlobalCopyBadge
                        v-if="item.address"
                        class="pr-5 pt-1 font-size-medium"
                        :value="item.address"
                        :truncate="100"
                    />
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.selected" style="font-style: italic; color: black">
                    {{ $t("user.profile.current-profile.block-info") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.selected">
                    Balance :
                    {{
                        selectedAccountDetails
                            ? `${selectedAccountDetails.balance} ${item.blockchain.currencyName}${
                                  selectedAccountDetails.balance >= 2 ? "s" : ""
                              }`
                            : "unknown"
                    }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </EqsList>
</template>
<script>
export default {
    name: "BlockchainInformation",
    props: {
        entity: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            accounts: [],
            selectedAccountDetails: null,
            loading: true
        };
    },
    watch: {
        entity(entity) {
            this.setBlockchainInfos();
        }
    },
    async created() {
        await this.setBlockchainInfos();
    },
    methods: {
        async getBlockchainAccounts() {
            let blockchains = [];
            try {
                const response = await this.$api.request("get", "/api/blockchain-account/");
                blockchains = response.data;
            } catch (e) {
                this.$ui.error(e, "blockchain_accounts");
            } finally {
                this.loading = false;
            }
            return blockchains;
        },
        async getAccountDetails(url) {
            try {
                const response = await this.$api.request("get", url + "details/");
                const details = response.data;
                return details;
            } catch (e) {
                console.error(e);
            }
        },
        async setBlockchainInfos() {
            const selectedBlockchain = this.entity.blockchain;
            let blockchainAccounts = await this.getBlockchainAccounts();
            let selectedAccount = null;
            this.accounts = blockchainAccounts.map(account => {
                const blockchainName = account.blockchain.name.toLowerCase();
                if (blockchainName.includes("tz") || blockchainName.includes("tezos")) {
                    account.blockchain.img = "/icons/tezos_icon.png";
                    account.blockchain.currencyName = "Tezie";
                } else if (blockchainName.includes("eth")) {
                    account.blockchain.img = "/icons/ethereum_icon.png";
                    account.blockchain.currencyName = "Ether";
                } else {
                    account.blockchain.img = "/images/equisafe-logo-blue.svg";
                    account.blockchain.currencyName = "";
                }
                if (selectedBlockchain && account.blockchain.url === selectedBlockchain.url) {
                    account.selected = true;
                    selectedAccount = account;
                }
                return account;
            });
            if (selectedAccount)
                this.selectedAccountDetails = await this.getAccountDetails(selectedAccount.url);
        }
    }
};
</script>

<style scoped>
.selected-title {
    font-weight: bold;
}
</style>
