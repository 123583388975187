var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"root-step-container",attrs:{"fluid":""}},[_c('h2',{staticClass:"text-h5 mb-4"},[_vm._v("Questionnaire de qualité investisseur")]),_c('EqsRadio',_vm._b({staticClass:"mb-2",attrs:{"value":_vm.formData.p4q1,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p4q1", $event)}}},'EqsRadio',{
            label: _vm.labelQ1,
            ..._vm.schemaDouble.p4q1
        },false)),_c('EqsRadio',_vm._b({staticClass:"mb-2",attrs:{"value":_vm.formData.p4q2,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p4q2", $event)}}},'EqsRadio',{
            label: _vm.labelQ2,
            ..._vm.schemaDouble.p4q2
        },false)),_c('EqsRadio',_vm._b({staticClass:"mb-2",attrs:{"value":_vm.formData.p4q3,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p4q3", $event)}}},'EqsRadio',{
            label: _vm.labelQ3,
            ..._vm.schemaDouble.p4q3
        },false)),_c('v-spacer'),_c('EqsCheckbox',_vm._b({staticClass:"font-weight-bold mt-4 mb-4",attrs:{"value":_vm.formData.confirm_infos_reliable},on:{"update:value":function($event){return _vm.$set(_vm.formData, "confirm_infos_reliable", $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Je confirme que les informations fournies sont fiables, à jour, et reflètent mon expérience, mes objectifs, ma situation financière, et ma compréhension des risques. ")]},proxy:true}])},'EqsCheckbox',{
            ..._vm.schemaDouble.confirm_infos_reliable
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }