import DEBUG from "@/store/debug.js";

export default async ({ commit, dispatch }) => {
    DEBUG("FETCH_BALANCE_START");
    try {
        commit("SET_BALANCE_INIT", true);
        await dispatch("getBalance");
        DEBUG("FETCH_BALANCE_SUCCESS");
    } catch (e) {
        DEBUG("FETCH_BALANCE_ERROR");
        throw e;
    }
};
