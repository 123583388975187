import { i18n } from "@/services/plugins/i18n.js";
import { isProd, isFormation, isLocal } from "@/assets/constants";

const CustodianInviteModel = ctx => {
    return [
        {
            titleAbove: ctx.$t("entity.custodian.representative-title"),
            is: "EqsEmail",
            label: i18n.t("entity.custodian.email"),
            "el-class": "email",
            name: "email"
        },
        {
            is: "EqsText",
            label: i18n.t("entity.custodian.first-name"),
            "el-class": "first_name",
            name: "first_name"
        },
        {
            is: "EqsText",
            label: i18n.t("entity.custodian.last-name"),
            "el-class": "last_name",
            name: "last_name"
        },
        {
            titleAbove: ctx.$t("entity.custodian.company-title"),
            is: "EqsText",
            label: i18n.t("rules.company-name"),
            "el-class": "company_name",
            name: "company_name"
        },
        {
            is: "EqsText",
            label: i18n.t("crud.placeholder-id"),
            "el-class": "company_idcode",
            name: "company_idcode"
        }
    ];
};

const CompanyInviteByInvestorModel = ctx => {
    return [
        {
            is: "EqsInputText",
            label: i18n.t("rules.company-name"),
            "el-class": "company_name",
            name: "company_name"
        },
        {
            is: "EqsInputEmail",
            label: i18n.t("registration.email-company-representative"),
            "el-class": "email",
            name: "email"
        }
    ];
};

const IndividualQueryModel = (ctx, accountType) => {
    return [
        {
            id: "name",
            name: i18n.t("label.name"),
            hint: i18n.t("entity.search-by", { accountType }) + ` ${i18n.t("label.name")}`
        },
        {
            id: "email",
            name: i18n.t("label.email"),
            hint: i18n.t("entity.search-by", { accountType }) + ` ${i18n.t("label.email")}`
        }
    ];
};

const CompanyQueryModel = (ctx, accountType) => {
    return [
        ...IndividualQueryModel(ctx, accountType),
        {
            id: "company_idcode",
            name: i18n.t("label.company-id"),
            hint: i18n.t("entity.search-by", { accountType }) + ` ${i18n.t("label.company-id")}`
        }
    ];
};

export const ShareholdersTypes = [
    {
        label: i18n.t("shareledger.dialog.stepper.invite-send-token.company"),
        value: "company"
    },
    {
        label: i18n.t("shareledger.dialog.stepper.invite-send-token.individual"),
        value: "individual"
    }
];

export var CompanyInviteByInvestor = ctx => {
    return {
        company: {
            type: "company",
            name: i18n.t("rules.company"),
            label: i18n.t("entity.company.search-label"),
            text: i18n.t("rules.a-company-by-investor"),
            singularName: i18n.t("rules.company-txt-search"),
            schema: CompanyInviteByInvestorModel(ctx),
            queries: CompanyQueryModel(ctx, i18n.t("rules.company-txt-search")),
            entity: {
                is_company: true
            }
        }
    };
};

export var InviteEntity = ctx => {
    return {
        individual: {
            type: "individual",
            name: i18n.t("shareledger.dialog.stepper.invite-send-token.individual"),
            text: i18n.t("rules.an-individu-peron-investing"),
            label: i18n.t("entity.individual.search-label"),
            singularName: i18n.t("rules.individual-txt-search"),
            is: "IndividualInviteForm",
            schemaDouble: {
                first_name: {
                    isRequired: true
                },
                last_name: {
                    isRequired: true
                },
                email: {
                    isRequired: true
                },
                nationality: {
                    isRequired: true
                }
            },
            queries: IndividualQueryModel(ctx, i18n.t("rules.individual-txt-search")),
            entity: {
                is_company: false
            }
        },
        company: {
            type: "company",
            name: i18n.t("rules.company"),
            label: i18n.t("entity.company.search-label"),
            text: i18n.t("rules.a-company"),
            singularName: i18n.t("rules.company-txt-search"),
            is: "CompanyInviteForm",
            schemaDouble: {
                company_name: {
                    isRequired: true
                },
                company_idcode: {
                    isRequired: true
                },
                email: {
                    isRequired: true
                },
                nationality: {
                    isRequired: true
                }
            },
            queries: CompanyQueryModel(ctx, i18n.t("rules.company-txt-search")),
            entity: {
                is_company: true
            }
        },
        custodian: {
            type: "company",
            name: i18n.t("rules.custodian"),
            text: i18n.t("rules.custodian-txt-invite"),
            singularName: i18n.t("rules.custodian-txt-search"),
            is: "CustodianInviteForm",
            schema: CustodianInviteModel(ctx),
            schemaDouble: {
                first_name: {
                    isRequired: true
                },
                last_name: {
                    isRequired: true
                },
                email: {
                    isRequired: true
                },
                company_name: {
                    isRequired: true
                },
                company_idcode: {
                    isRequired: true
                }
            },
            queries: CompanyQueryModel(ctx, i18n.t("rules.custodian-txt-search")),
            entity: {
                role_name: "custodian"
            }
        }
    };
};

export function kycItemEntity(entity) {
    const iskycok = entity.lemonway_state === "success";
    if (entity.is_company) {
        return iskycok
            ? {
                  name: i18n.t("registration.kyc.kyb-passed"),
                  color: "success"
              }
            : {
                  name: i18n.t("registration.kyc.no-kyb"),
                  color: "error"
              };
    } else {
        return iskycok
            ? {
                  name: i18n.t("registration.kyc.kyc-passed"),
                  color: "success"
              }
            : {
                  name: i18n.t("shareledger.dialog.stepper.invite-send-token.no-kyc"),
                  color: "error"
              };
    }
}

export function kycItem(investor) {
    return kycItemEntity(investor.entity);
}

export function companyNotSetted(entity) {
    return entity.is_company && !entity.account_type;
}

export var companiesType = () => [
    {
        id: "company_nonlisted",
        name: i18n.t("rules.company"),
        description: i18n.t("rules.you-are-a-company"),
        btnTxt: i18n.t("rules.i-am-private-company"),
        color: "#388E3C",
        registration: {
            title: i18n.t("registration.kyc.kyb-provider.title"),
            text: i18n.t("registration.kyc.kyb-provider.msg")
        }
    },
    {
        id: "company_association",
        name: i18n.t("rules.association"),
        description: i18n.t("rules.you-are-an-association"),
        btnTxt: i18n.t("rules.i-am-an-association"),
        color: "#FFA000",
        registration: {
            title: i18n.t("registration.kyc.kyb-provider.title"),
            text: i18n.t("registration.kyc.kyb-provider.msg")
        }
    }
];

export var individualType = context => {
    const universe = context.universe;
    return {
        id: "individual",
        name: context.$t("shareledger.dialog.stepper.invite-send-token.individual"),
        color: "#039BE5",
        registration: {
            title: context.$t("registration.kyc.kyc-provider.title"),
            text: context.$t("registration.kyc.kyc-provider.msg", { universe })
        }
    };
};

export const othersType = () => [
    {
        id: "custodian",
        name: i18n.t("rules.custodian"),
        color: "#00ACC1",
        registration: {
            title: i18n.t("registration.kyCustodian-provider.title"),
            text: i18n.t("registration.kyCustodian-provider.msg")
        }
    }
];

export const entitiesType = context => [
    individualType(context),
    ...companiesType(),
    ...othersType()
];

export const entityDocs = [
    "avatar",
    "doc_phebe_nda",
    "proof_of_address_doc",
    "doc_fiche_connaissance_partenaire_kyoseil",
    "doc_questionnaire_qualite_kyoseil",
    "doc_procedure_lcbft_kyoseil",
    "doc_infos_prealable_kyoseil",
    "cgp_doc_rcpro",
    "cgp_doc_orias",
    "doc_notice_of_assessment",
    "doc_idcard",
    "doc_address",
    "doc_passport",
    "doc_payslip",
    "doc_license",
    "doc_last_report_general_assembly",
    "doc_statutes",
    "doc_official_gazette_publication",
    "doc_remit",
    "doc_kbis",
    "doc_tax_reports",
    "doc_cerfa_2035_f",
    "doc_declaration_beneficial_owner_registry_commercial_court",
    "doc_letter_signed_by_legal_representative",
    "doc_bank",
    "doc_health_insurance_card",
    "doc_residence_permit",
    "doc_idshareholder_1",
    "doc_idshareholder_2",
    "doc_idshareholder_3",
    "doc_idshareholder_4",
    "doc_representative_id",
    "doc_incapability_decision"
];

export function accountTypeItem(account_type, context) {
    return entitiesType(context).find(_entity => _entity.id === account_type);
}

export class EntityService {
    constructor(api, role, store) {
        if (!api) {
            throw new Error("Missing `api` option");
        }
        this.api = api;
        if (!store) {
            throw new Error("Missing `store` option");
        }
        this.store = store;
        this.role = role;
    }

    async createCompany(companyData) {
        try {
            const res = await this.api.createEntity({
                ...companyData,
                is_company: true
            });
            return res.data;
        } catch (e) {
            window.app.$ui.error(e, "entity_create_company");
        }
    }

    async me() {
        const res = await this.api.me();
        const me = res.data;
        if (!me) return null;
        await this.role.updateMyCurrentRole(me.id);
        return this.store.commit("SET_MY_ENTITY", me);
    }

    async updateEntity(data, successMsg = true) {
        try {
            const updatedEntity = await this.store.dispatch("putEntity", data);
            if (successMsg) window.app.$ui.success(i18n.t("user.edit-profile.edit-success"));
            return updatedEntity;
        } catch (error) {
            window.app.$ui.error(i18n.t("user.edit-profile.edit-failed"));
            throw error;
        }
    }
    myEntity() {
        return this.store.getters.myEntity;
    }
    represented() {
        if (!this.store.getters.currentRole) {
            console.error("No current role ! init logout");
            window.app.$api.logout();
            return {};
        }
        return this.store.getters.currentRole.entity_represented
            ? this.store.getters.currentRole.entity_represented
            : {};
    }
    isStaff() {
        return (this.represented().is_staff || this.myEntity().is_staff) ?? false;
    }

    // Feature Universe
    isUniverseAdmin() {
        const entity = this.represented();
        return entity.is_universe_admin;
    }

    isFromUniverse(universe_slug) {
        const entity = this.represented();
        if (entity?.universe?.name) {
            return entity.universe.name.localeCompare(universe_slug) === 0;
        } else {
            return false;
        }
    }

    // Feature Affiliation
    isAffiliationAdmin(checkCeo = false) {
        const entity = this.represented();
        if (entity?.is_affiliation_admin) {
            return true;
        }
        if (checkCeo && entity?.is_company) {
            const entityCeo = this.myEntity();
            if (entityCeo?.is_affiliation_admin) {
                return true;
            }
        }
        return false;
    }

    isAffiliationBc() {
        const entity = this.represented();
        if (entity.attributes?.length > 0) {
            if (entity.attributes.find(attr => attr.entity_type === "business_contributor")) {
                return true;
            }
        }
        return false;
    }

    isAffiliationCgp() {
        const entity = this.represented();
        if (entity.attributes?.length > 0) {
            if (entity.attributes.find(attr => attr.entity_type === "cgp")) {
                return true;
            }
        }
        return false;
    }

    hasAffiliationRole() {
        if (this.isAffiliationAdmin() || this.isAffiliationBc() || this.isAffiliationCgp()) {
            return true;
        }
        return false;
    }

    isAffiliationInvestor() {
        if (this.hasAffiliationRole()) {
            return false;
        }
        // Investor au sens affiliation, uniquement si through role actif
        return this.store.getters.useThroughRoles?.useIt;
    }

    isAffiliationAccountActive() {
        if (this.isAffiliationAdmin()) {
            return true;
        }
        const entity = this.represented();
        if (entity.attributes?.length > 0) {
            return entity.attributes[0].validation_status === "Accepted";
        }
        return false;
    }

    // Feature PSFP
    isPsfp() {
        const e = this.represented();
        if (!e) {
            return false;
        }
        return e?.psfp_form?.is_completed === true;
    }
    isPsfpWarned() {
        const e = this.represented();
        if (!e) {
            return false;
        }
        return e?.psfp_form?.is_warned === true;
    }

    entitySeller(item, operation) {
        if (["book_entry", "emission"].includes(operation)) {
            return null;
        }
        return item.investor_source ? item.investor_source : item.entity;
    }

    entityBuyer(item, operation) {
        if (operation === "cancellation") {
            return null;
        }
        return item.investor_destination ? item.investor_destination : item.entity;
    }

    entityName(entity) {
        if (!entity) return "-";
        if (entity.name) return entity.name;
        else if (entity.company_name) {
            return entity.company_name;
        } else if (entity.first_name && entity.last_name)
            return `${entity.first_name} ${entity.last_name}`;
        return "-";
    }

    isCompanyNotSetted(entity = null) {
        if (!entity) entity = this.represented();
        return companyNotSetted(entity);
    }

    isCompany(entity = null) {
        if (!entity) entity = this.represented();
        return (
            entity.is_company ||
            [
                "company_nonlisted",
                "company_association",
                "company_administration",
                "custodian"
            ].includes(entity.account_type)
        );
    }

    errorKyc() {
        const { lemonway_state, lemonway_status } = this.represented();
        return lemonway_state === "upload-failure" || [2, 3, 8].includes(lemonway_status);
    }

    async updateLemonwayState() {
        try {
            const entityId = this.represented().id;
            if (this.represented().lemonway_state && this.represented().name) {
                const res = await this.api.getEntityVerificationResult(entityId);
                const { lemonway_state, lemonway_reason, lemonway_status } = res.data;
                if (
                    this.represented().lemonway_state !== lemonway_state ||
                    this.represented().lemonway_reason !== lemonway_reason ||
                    this.represented().lemonway_status !== lemonway_status
                )
                    await this.role.updateMyCurrentRole(entityId);
            }
        } catch (e) {
            throw e;
        }
    }
}
