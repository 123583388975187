<template>
    <EqsDialog
        :value.sync="dialogProxy"
        :closable="!!post && !editionProxy"
        persistent
        el-class="post-modal"
        :title="post?.title || $t('projects.reporting.form.title-add')"
        @click:outside="closeModal('outisde')"
        @close="closeModal('button')"
    >
        <v-container fluid>
            <v-row v-if="isEntityCapincAdmin($entity.represented(), capincId)">
                <v-col v-if="!editionProxy" class="text-right">
                    <v-btn
                        outlined
                        color="red"
                        class="custom-btn"
                        :disabled="loading"
                        @click.stop="deletePostConfirm"
                    >
                        <span class="mr-2">{{ $t("projects.reporting.crud.delete") }}</span>
                        <template v-if="!loading">
                            <v-icon color="red">mdi-delete-circle</v-icon>
                        </template>
                        <template v-else>
                            <v-progress-circular indeterminate color="primary" />
                        </template>
                    </v-btn>
                    <v-btn
                        outlined
                        color="orange"
                        class="custom-btn ml-4"
                        :disabled="loading"
                        @click.stop="switchToEdition"
                    >
                        <span class="mr-2">{{ $t("projects.reporting.crud.edit") }}</span>
                        <template v-if="!loading">
                            <v-icon color="orange">mdi-file-edit</v-icon>
                        </template>
                        <template v-else>
                            <v-progress-circular indeterminate color="primary" />
                        </template>
                    </v-btn>
                    <v-btn
                        v-if="!post.mail_sent"
                        outlined
                        color="blue"
                        class="custom-btn ml-4"
                        :disabled="localLoading"
                        @click.stop="sendMail"
                    >
                        <span class="mr-2">{{ $t("projects.reporting.post.notify-by-mail") }}</span>
                        <template v-if="!localLoading">
                            <v-icon color="blue">mdi-send</v-icon>
                        </template>
                        <template v-else>
                            <v-progress-circular indeterminate color="primary" />
                        </template>
                    </v-btn>
                </v-col>
                <v-col v-else class="text-right">
                    <v-btn
                        outlined
                        class="custom-btn"
                        color="red"
                        :disabled="loading"
                        @click.stop="switchToReader"
                    >
                        <span class="mr-2">{{ $t("projects.reporting.crud.cancel") }}</span>
                        <template v-if="!loading">
                            <v-icon color="red">mdi-cancel</v-icon>
                        </template>
                        <template v-else>
                            <v-progress-circular indeterminate color="primary" />
                        </template>
                    </v-btn>
                    <v-btn
                        outlined
                        class="custom-btn ml-4"
                        color="success"
                        :disabled="loading || !formIsValid"
                        @click.stop="savePost"
                    >
                        <span class="mr-2">{{ $t("projects.reporting.crud.save") }}</span>
                        <template v-if="!loading">
                            <v-icon color="success" border="success">mdi-content-save</v-icon>
                        </template>
                        <template v-else>
                            <v-progress-circular indeterminate color="primary" />
                        </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="!editionProxy" dense align="center">
                <v-col class="text-right text-body-1 my-4" style="font-style: italic">
                    {{ postDateTxt }}
                    <v-chip
                        :color="getPostLabelColor(post.label)"
                        text-color="white"
                        label
                        class="ml-2"
                    >
                        {{ getPostLabelText(post.label) }}
                    </v-chip>
                </v-col>
            </v-row>
            <v-row v-if="!editionProxy" dense no-gutters>
                <v-col>
                    <div v-for="(item, index) in fields" :key="index">
                        <component :is="item.component" v-bind="item.bind" class="mb-10" />
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="editionProxy" dense no-gutters>
                <v-col>
                    <PostForm
                        v-if="postDataProxy"
                        :post.sync="postDataProxy"
                        :loading="loading || localLoading"
                        @form-is-valid="val => (formIsValid = val)"
                    />
                </v-col>
            </v-row>
        </v-container>
    </EqsDialog>
</template>

<script>
import { isEntityCapincAdmin, getPostLabelColor, getPostLabelText } from "./utils";

import PostForm from "./PostForm";
import FieldText from "./fields/FieldText.vue";
import FieldYoutubeLink from "./fields/FieldYoutubeLink.vue";
import FieldCta from "./fields/FieldCta.vue";
import FieldImage from "./fields/FieldImage.vue";
import FieldDocument from "./fields/FieldDocument.vue";

export default {
    name: "PostModal",
    components: { FieldText, FieldYoutubeLink, FieldCta, FieldImage, FieldDocument, PostForm },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        capincId: {
            type: String,
            required: true
        },
        post: {
            type: Object,
            required: false,
            default: null
        },
        edition: {
            type: Boolean,
            required: false,
            default: false
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    emits: ["udate-post", "refresh"],
    data() {
        return {
            localLoading: false,
            formIsValid: false,
            postDataProxy: null,
            openDeleteModal: false,
            editionProxy: Proxy,
            fieldMap: {
                text: FieldText,
                youtube_link: FieldYoutubeLink,
                cta: FieldCta,
                image: FieldImage,
                document: FieldDocument
            }
        };
    },
    computed: {
        fields() {
            let fields = this.post.fields;
            fields = fields.map(field => {
                let fieldComponent = null;
                let fieldKey = null;
                for (const [key, component] of Object.entries(this.fieldMap)) {
                    if (field[key]) {
                        fieldComponent = component;
                        fieldKey = key;
                        break;
                    }
                }
                return {
                    component: fieldComponent,
                    order: field.order,
                    bind: {
                        field: field[fieldKey]
                    }
                };
            });
            fields.sort((a, b) => a.order - b.order);
            return fields;
        },
        postDateTxt() {
            let txt = "";
            if (this.$time.isBefore(this.post.date, this.post.updated_at)) {
                txt = `${this.$t("projects.reporting.post.published-date")} ${this.$time.formated(
                    this.post.date
                )} - ${this.$t("projects.reporting.post.edit-date")} ${this.$time.formated(
                    this.post.updated_at
                )}`;
            } else {
                txt = `${this.$t("projects.reporting.post.published-date")} ${this.$time.formated(
                    this.post.date
                )}`;
            }
            return txt;
        },
        dialogProxy: {
            get() {
                return this.open;
            },
            set(val) {
                if (!val && this.open) {
                    this.$emit("close-dialog");
                }
            }
        }
    },
    beforeMount() {
        this.editionProxy = this.edition;
        this.updateEditionData();
        if (!this.editionProxy && this.post) {
            // Pas en édition seulement
            this.markAsSeen();
        }
    },
    methods: {
        isEntityCapincAdmin,
        getPostLabelColor,
        getPostLabelText(label) {
            return getPostLabelText(this, label);
        },
        closeModal() {
            if (!!this.post && !this.editionProxy) {
                this.dialogProxy = false;
            }
        },
        updateEditionData() {
            if (this.editionProxy && this.post) {
                // Dict temporaire pour la modification de post, pour ne pas ecraser le parent
                // this.postDataProxy = JSON.parse(JSON.stringify(this.post));
                this.postDataProxy = {
                    reporting: this.post.reporting,
                    title: this.post.title,
                    label: this.post.label,
                    fields: this.post.fields
                };
            }
            if (this.editionProxy && !this.post) {
                // Création
                this.postDataProxy = {
                    title: null,
                    label: null,
                    fields: []
                };
            }
        },
        switchToEdition() {
            this.editionProxy = true;
            this.updateEditionData();
        },
        async switchToReader() {
            if (!this.post) {
                // On supprime les docs envoyés au serveur
                for (const field of this.postDataProxy.fields) {
                    if (field.type === "document") {
                        const documents = field.data.document.documents;
                        if (documents) {
                            for (const doc of documents) {
                                if (doc && doc.id) {
                                    this.$api.deleteDocument(doc.id);
                                }
                            }
                        }
                    }
                }
                this.dialogProxy = false;
            } else {
                this.editionProxy = false;
                this.postDataProxy = {
                    title: null,
                    label: null,
                    fields: []
                };
            }
        },
        markAsSeen() {
            if (!this.post?.seen) {
                try {
                    this.$api.request(
                        "patch",
                        `api/reporting/${this.capincId}/post/${this.post.id}/seen/`
                    );
                    this.post.seen = true;
                } catch (e) {
                    console.error("Cant set post as seen: ", e);
                }
            }
        },
        async savePost() {
            if (!this.formIsValid) {
                this.$ui.error(this.$t("projects.reporting.crud.check-form"));
                return;
            }
            // On utilise postDataProxy, qui est pour POST ou PATCH
            let postDataToSave = JSON.parse(JSON.stringify(this.postDataProxy));
            if (postDataToSave.fields?.length) {
                postDataToSave.fields = postDataToSave.fields.map(e => e.data);
                for (const fieldOrder in postDataToSave.fields) {
                    postDataToSave.fields[fieldOrder].order = fieldOrder;
                    if (postDataToSave.fields[fieldOrder].document) {
                        // Clean les champs nuls dûs à l'ajout auto d'un champs doc
                        // Sinon erreur serializer
                        postDataToSave.fields[fieldOrder].document.documents =
                            postDataToSave.fields[fieldOrder].document.documents.filter(e => !!e);
                    }
                }
            } else {
                postDataToSave.fields = [];
            }
            if (!postDataToSave.reporting) {
                // Création
                postDataToSave.reporting = this.capincId;
                this.localLoading = true;
                try {
                    const res = await this.$api.request(
                        "post",
                        `api/reporting/${this.capincId}/post/`,
                        postDataToSave
                    );
                    this.$emit("refresh");
                    this.dialogProxy = false;
                } catch (e) {
                    console.error("Cant create post: ", e);
                    this.$ui.error(
                        `${this.$t("projects.reporting.errors.cannot-create-report")} (${
                            e.statusText
                        })`
                    );
                } finally {
                    this.localLoading = false;
                }
            } else {
                this.localLoading = true;
                try {
                    const res = await this.$api.request(
                        "patch",
                        `api/reporting/${this.capincId}/post/${this.post.id}/`,
                        postDataToSave
                    );
                    this.$emit("update-post", res.data);
                    this.editionProxy = false;
                } catch (e) {
                    console.error("Cant update post: ", e);
                    this.$ui.error(
                        `${this.$t("projects.reporting.errors.cannot-edit-report")} (${
                            e.statusText
                        })`
                    );
                } finally {
                    this.localLoading = false;
                }
            }
        },
        async deletePost(postId) {
            this.localLoading = true;
            try {
                const res = await this.$api.request(
                    "delete",
                    `api/reporting/${this.capincId}/post/${postId}/`
                );
                this.$emit("refresh");
                this.dialogProxy = false;
            } catch (e) {
                console.error("Cant delete post: ", e);
                this.$ui.error(
                    `${this.$t("projects.reporting.errors.cannot-delete-report")} (${e.statusText})`
                );
            } finally {
                this.localLoading = false;
            }
        },
        deletePostConfirm() {
            this.$dialog.alert({
                title: this.$t("projects.reporting.crud.confirm-delete-report"),
                text: this.$t("projects.reporting.crud.confirm-delete-report-text"),
                confirm: {
                    fn: async () => {
                        await this.deletePost(this.post.id);
                    },
                    txt: this.$t("projects.reporting.crud.confirm")
                },
                cancel: {
                    fn: () => {}
                }
            });
        },
        async sendMail() {
            this.localLoading = true;
            try {
                await this.$api.request(
                    "get",
                    `api/reporting/${this.capincId}/post/${this.post.id}/send-mail-validate/`
                );
                const res = await this.$api.request(
                    "patch",
                    `api/reporting/${this.capincId}/post/${this.post.id}/send-mail/`
                );
                this.$emit("update-post", res.data);
            } catch (e) {
                console.error("Cant send mail: ", e);
                this.$ui.error(
                    `${this.$t("projects.reporting.crud.send-mails")} (${e.statusText})`
                );
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    text-transform: none !important;

    .v-icon {
        margin-right: 6px;
    }
}

:deep(.post-modal-content) {
    @media (max-width: 600px) {
        max-width: 100% !important;
        width: 100% !important;
        margin: 0.66rem;
    }
    @media (max-width: 1264px) and (min-width: 600px) {
        max-width: 800px !important;
        min-width: 520px !important;
        margin: 1rem !important;
        width: 100% !important;
    }
    @media (min-width: 1264px) {
        max-width: 800px !important;
        width: 800px !important;
    }
}
</style>
