<template>
    <div
        v-if="items.length"
        class="eqs-navigation-drawer"
        :style="{ ...getStyle, ...navDrawerStyle }"
    >
        <v-navigation-drawer
            v-model="valueProxy"
            app
            enable-resize-watcher
            :mini-variant.sync="miniProxy"
            :mobile-breakpoint="0"
            :width="getWidth"
            :expand-on-hover="!isMobile && !loading"
            :permanent="!isMobile"
            :class="`${elClass}-drawer`"
            :color="$color.colors.menuColor"
            v-bind="{ ...getData, ...$attrs, miniVariantWidth }"
        >
            <div class="main-navbar">
                <nav class="navbar">
                    <v-row class="navbar-container d-flex flex-row">
                        <v-col v-if="isMobile" class="navbar-container" cols="2">
                            <v-spacer />
                        </v-col>
                        <v-col
                            :class="`pa-2 navbar-container`"
                            align-self="center"
                            :cols="isMobile ? 8 : 'auto'"
                            style="cursor: pointer"
                            @click="bannerRedirect"
                        >
                            <EqsLoader v-bind="{ loading }">
                                <v-slide-x-transition hide-on-leave leave-absolute>
                                    <img
                                        v-if="miniProxy"
                                        height="inherit"
                                        style="width: inherit"
                                        class="navbar-mini-logo"
                                        :src="bannerLogo"
                                    />
                                    <img
                                        v-else
                                        height="inherit"
                                        style="width: 100%; object-fit: contain"
                                        class="navbar-full-logo px-2"
                                        :src="bannerLogoFull"
                                    />
                                </v-slide-x-transition>
                            </EqsLoader>
                        </v-col>
                        <v-col v-if="isMobile" class="navbar-container" cols="2">
                            <div class="navbar-item px-0">
                                <v-btn
                                    icon
                                    width="48px"
                                    height="48px"
                                    :color="$color.colors.eqsBlue"
                                    @click="() => $emit('close')"
                                >
                                    <v-icon :color="$color.colors.eqsBlue"> mdi-arrow-left </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </nav>
            </div>
            <v-col v-if="items[0].subheader" class="eqs-navigation-drawer__subheader__item mb-2">
                <v-divider />
                <v-subheader class="mx-5 px-1">
                    <v-fade-transition hide-on-leave>
                        <span v-if="showSubheader && !miniProxy && !loading" class="ml-5">
                            {{ items[0].subheader }}
                        </span>
                    </v-fade-transition>
                </v-subheader>
                <v-divider />
            </v-col>
            <v-list dense class="py-0" :disabled="loading">
                <v-hover v-for="(item, index) in items" :key="item.title" v-slot="{ hover }">
                    <div class="ma-0 pa-0">
                        <v-list-item
                            v-if="index > 0"
                            :link="!item.subheader"
                            :color="$color.colors.eqsBlue"
                            active-class="eqs-navigation-drawer-active"
                            :to="item.to"
                            :input-value="!item.subheader && hover"
                            :disabled="item.disabled"
                            :inactive="item.disabled"
                            :class="
                                item.subheader
                                    ? 'eqs-navigation-drawer__subheader'
                                    : `eqs-navigation-drawer__item ${item.value}`
                            "
                            @click="
                                () => {
                                    if (isMobile && !item.disabled) $emit('close');
                                }
                            "
                        >
                            <v-col
                                v-if="item.subheader && index > 1"
                                class="eqs-navigation-drawer__subheader__item"
                            >
                                <v-divider />
                                <v-subheader class="mx-5">
                                    <v-fade-transition hide-on-leave>
                                        <span v-if="showSubheader && !miniProxy && !loading">
                                            {{ item.subheader }}
                                        </span>
                                    </v-fade-transition>
                                </v-subheader>
                                <v-divider />
                            </v-col>
                            <v-list-item-icon class="eqs-navigation-drawer__item__icon">
                                <EqsSkeleton v-bind="{ loading }" type="image">
                                    <v-icon
                                        :color="
                                            item.active || hover
                                                ? $color.colors.eqsBlue
                                                : $color.colors.lightGrey
                                        "
                                    >
                                        {{ item.icon }}
                                    </v-icon>
                                </EqsSkeleton>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title
                                    :style="item.disabled ? `color:${$color.colors.lightGrey}` : ''"
                                >
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-hover>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsNavigationDrawer.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { delayedPromise } from "../../../../../shared/util";

export default {
    name: "EqsNavigationDrawer",
    components: {
        EqsLoader: () => import("../../atoms/EqsLoader/EqsLoader.vue"),
        EqsSkeleton: () => import("../../atoms/EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate],

    props: {
        elClass: {
            type: String,
            default: "eqs-navigation-drawer"
        },
        value: {
            type: Boolean,
            default: true
        },
        items: {
            type: Array,
            required: true
        },
        mini: {
            type: Boolean,
            default: true
        },
        bannerLogo: {
            type: String,
            default: "/images/logo.svg"
        },
        bannerLogoFull: {
            type: String,
            default: "/images/logo_name.svg"
        },
        bannerLogoRedirect: {
            type: String,
            default: ""
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        miniVariantWidth: {
            type: [String, Number],
            default: 76
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            showSubheader: false
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        miniProxy: {
            get() {
                return this.isMobile ? false : this.mini;
            },
            set(val) {
                this.$emit("update:mini", this.loading ? true : val);
            }
        },
        getWidth() {
            return this.$reactive.getVal({
                mobile: "100vw",
                tablet: "16rem",
                desktop: "18rem"
            });
        },
        navDrawerStyle() {
            return {
                "--icon-margin-right": this.miniProxy ? "0px" : "32px"
            };
        }
    },
    watch: {
        miniProxy: {
            immediate: true,
            async handler(val) {
                if (!val) this.showSubheader = await delayedPromise(200, true);
                else this.showSubheader = false;
            }
        },
        isMobile: {
            immediate: true,
            handler(val) {
                if (val) this.valueProxy = false;
            }
        }
    },
    methods: {
        bannerRedirect() {
            if (this.bannerLogoRedirect) {
                this.$router.push(this.bannerLogoRedirect).catch(() => {});
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsNavigationDrawer.scss";
</style>
