var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-lazy',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-3 height-fit pt-2"},[_vm._l((_vm.dashboardData.cards),function(card,index){return _c('v-col',{key:index,attrs:{"cols":_vm.getColsCards}},[_c('EqsClickableCard',_vm._b({scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('div',{staticClass:"d-flex fill-height flex-column justify-center text-right full-width"},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                                    elClass: 'wallet-balance',
                                    eqsType: 'title',
                                    value: _vm.getAmount(card)
                                },false))],1)]},proxy:true}],null,true)},'EqsClickableCard',{
                        width: '100%',
                        height: '100%',
                        loading: _vm.loading,
                        eqsType: 'notClickable',
                        ...card
                    },false))],1)}),_c('v-spacer')],2),_c('v-row',{staticClass:"px-3 d-flex",staticStyle:{"height":"80%"}},[_c('v-col',{staticClass:"fill-height",attrs:{"cols":_vm.$reactive.isDesktop() ? '8' : '12'}},[_c('ShareholdersList',_vm._b({},'ShareholdersList',{
                        loading: _vm.loading,
                        headers: _vm.headers,
                        items: _vm.dashboardData.table.items
                    },false))],1),_c('v-col',{attrs:{"cols":_vm.$reactive.isDesktop() ? '4' : '12'}},[(_vm.dashboardData.chart)?_c('EqsPie',_vm._b({staticStyle:{"height":"inherit","width":"100%"}},'EqsPie',{ config: _vm.dashboardData.chart },false)):_vm._e(),_c('EqsSwitch',_vm._b({staticStyle:{"text-align":"center"},attrs:{"el-class":"share-transfer","value":_vm.diluted},on:{"update:value":function($event){_vm.diluted=$event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('EqsTextDisplay',[_vm._v(" "+_vm._s(_vm.$t("capitalisation.general-view.diluted"))+" ")])]},proxy:true}])},'EqsSwitch',{ loading: _vm.loading },false))],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }