<template>
    <v-row>
        <v-col>
            <EqsNumberShares
                :label="$t('sellBuy.label.available-actions')"
                :value="getAvailableShares()"
                :is-required="false"
                :editable="false"
            />
            <EqsNumberShares
                :label="$t('sellBuy.label.sequestre-actions')"
                :value="getSequestreShares()"
                :is-required="false"
                :editable="false"
            />
            <EqsNumber
                :label="$t('sellBuy.label.market-value')"
                :value="data.assets[0].venalValue || data.assets[0].value"
                :is-required="false"
                :editable="false"
                :currency-type="getCurrency()"
            />
            <EqsBtn
                v-if="shareledgerWithShareTransfer.length"
                color="primary"
                v-bind="{
                    elClass: 'sell-shares',
                    action: () =>
                        $router.push({
                            name: 'sell-shares',
                            params: {
                                companyId: data.id,
                                shareledgers: shareledgerWithShareTransfer,
                                companyData: data
                            }
                        })
                }"
            >
                {{ $t("sellBuy.label.sell-my-shares") }}
            </EqsBtn>
            <EqsTextDisplay v-else>
                <div class="font-weight-bold">
                    {{ $t("portfolio.sell-shares.no-shareledger-sellable") }}
                </div>
            </EqsTextDisplay>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "SellBuy",
    props: {
        data: {
            type: Object,
            required: true
        },
        shareledgerWithShareTransfer: {
            type: Array,
            required: true
        },
        getCurrency: {
            type: Function,
            required: true
        }
    },
    methods: {
        getAvailableShares() {
            let total = 0;
            this.data.assets.map(asset => {
                total += asset.balance;
            });
            return total.toString();
        },
        getSequestreShares() {
            let total = 0;
            this.data.assets.map(asset => {
                total += asset.sequestre;
            });
            return total.toString();
        }
    }
};
</script>
