<template>
    <div class="eqs-main-container margin-auto">
        <v-row class="fill-height px-3">
            <EqsDialog
                persistent
                :value.sync="dialogProxy"
                v-bind="{
                    title,
                    cancel: {},
                    confirm: {
                        fn: async () => sendInvitation()
                    }
                }"
                :loading="loading"
                :closable="true"
            >
                <v-col :cols="12">
                    <EqsText
                        :label="$t('portfolio.company-name')"
                        :value.sync="formData.company_name"
                    />
                </v-col>
                <v-col :cols="12">
                    <EqsEmail :label="$t('portfolio.owner-email')" :value.sync="formData.email" />
                </v-col>
            </EqsDialog>
        </v-row>
    </div>
</template>

<script>
import { formErrorMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { CompanyInviteByInvestor } from "@/services/entity";

export default {
    name: "GlobalCompanyInviteByInvestorDialog",
    mixins: [formErrorMixin],
    props: {
        activatorTxt: {
            type: String,
            default: ""
        },
        open: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            title: this.$t("portfolio.label-invite-company"),
            loading: false,
            error: false,
            formData: {},
            validForm: false
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.open;
            },
            set(val) {
                if (!val) this.closeDialog();
            }
        }
    },
    methods: {
        closeDialog() {
            this.loading = false;
            this.formData = {};
            this.$emit("closeDialog");
            this.$emit("update:open", false);
        },
        async sendInvitation() {
            this.loading = true;
            try {
                await this.$api.companyInviteByInvestor(this.formData);
                this.$ui.success(
                    this.$t("molecule.form.successfully-invite", {
                        entityName: this.formData.company_name,
                        entityModel: this.$t("portfolio.table.company-label")
                    })
                );
            } catch (e) {
                this.handleError(e, "invite_entity");
                throw e;
            } finally {
                this.loading = false;
                this.closeDialog();
            }
        }
    }
};
</script>
