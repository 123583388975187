<template>
    <div class="eqs-circular-progress" :el-class="elClass">
        <v-progress-circular :rotate="90" :value="valueProxy" :color="getColor" :size="40">
            {{ valueProxy }}
        </v-progress-circular>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsCircularProgress.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsCircularProgress",
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-circular-progress"
        },
        value: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return parseInt(this.value);
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        getColor() {
            if (this.valueProxy == 0) return "error";
            else if (this.valueProxy < 100) return "primary";
            return "success";
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsCircularProgress.scss";
</style>
