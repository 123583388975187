<template>
    <v-lazy>
        <v-container fluid>
            <InternalNoData
                v-if="orders.count !== null && orders.count === 0"
                el-class="payment-transactions"
                :no-data-custom="{
                    title: $t('projecs.dashboard.orders.orders-no-data-title'),
                    text: $t('projecs.dashboard.orders.orders-no-data-txt'),
                    img: $universe.image('/images/projects/orders_empty.svg')
                }"
            />
            <DataTablePaginated
                v-else
                :loading="loading || localLoading"
                :headers="headers"
                :items="orders"
                sort-by="updated_at"
                sort-desc
                @update="fetchOrders"
            >
                <template #[`item.capinc.order_title`]="{ value }">
                    {{ value }}
                </template>
                <template #[`item.updated_at`]="{ value }">
                    {{ $time.formated(value) }}
                </template>
                <template #[`item.action`]="{ item }">
                    <EqsActions v-bind="{ actions: getActions(item) }" />
                </template>
                <template #[`item.numberofshares`]="{ value }">
                    {{ $currency.display(value, "0,0") }}
                </template>
                <template #[`item.status`]="{ value }">
                    <EqsChip v-bind="getStatus(value)">
                        {{ getStatus(value).chip }}
                    </EqsChip>
                </template>
            </DataTablePaginated>
        </v-container>
    </v-lazy>
</template>

<script>
import DataTablePaginated from "@/components/views/DataTablePaginated";
import { statutes } from "@/util/payment";
import { getSelectedPayment } from "@/components/views/user/buy/steps/util";

export default {
    name: "OrdersDashboard",
    components: {
        DataTablePaginated,
        InternalNoData: () =>
            import(
                "@equisafe-ui-vue/src/components/organisms/EqsDataTable/_internal/InternalNoData.vue"
            )
    },
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        crowdfunding: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            statutes,
            localLoading: false,
            queriesCache: {},
            orders: {},
            headers: [
                {
                    text: this.$t("label.name"),
                    value: "capinc_name",
                    sortable: false
                },
                {
                    text: "Date",
                    value: "updated_at",
                    sortable: true
                },
                {
                    text: this.$t("transaction.shares"),
                    value: "numberofshares",
                    sortable: false
                },
                {
                    text: this.$t("label.status"),
                    value: "status",
                    sortable: true
                },
                {
                    text: this.$t("transaction.action"),
                    value: "action",
                    align: "start",
                    sortable: false
                }
            ]
        };
    },
    watch: {
        $route: {
            handler(to, from) {
                this.fetchOrders({});
            }
        }
    },
    methods: {
        openOrder(order) {
            let path = {
                name: "project-detail",
                params: {
                    id: this.$entity.represented().id,
                    projectId: order.capinc_id
                },
                query: {
                    orderDetails: order.id.toString()
                }
            };
            if (this.crowdfunding) {
                path.params.crowdfunding = true;
                path.name = "corner-project-detail";
            }
            this.$router.push(path);
        },
        async fetchOrders(queries) {
            this.queriesCache = { ...queries };
            try {
                this.localLoading = true;
                let res = null;
                res = await this.$api.getOrders({
                    buyer_id: this.$entity.represented().id,
                    cip: this.crowdfunding,
                    ...queries
                });

                this.orders = res.data;
            } catch (e) {
                this.$ui.error(e, "my_transactions_order_transactions");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async deleteOrder(item) {
            if (this.localLoading) return;
            try {
                this.localLoading = true;
                const index = this.orders.results.findIndex(order => item.id === order.id);
                if (index >= 0) {
                    await this.$api.deleteCapincOrder(item.id, {
                        cip: this.crowdfunding,
                        public: true
                    });
                    await this.fetchOrders(this.queriesCache);
                }
            } catch (e) {
                this.$ui.error(e, "capital_increase_user_delete_order");
            } finally {
                this.localLoading = false;
            }
        },
        getStatus(status) {
            return status ? this.statutes[status] : this.statutes.pending;
        },
        openSignStepper(item) {
            this.$router.push({
                name: "buy",
                query: { capinc_id: item.capinc_id },
                params: {
                    orderId: item.id
                }
            });
        },
        finishOrder(item) {
            this.$router.push({
                name: "buy",
                query: {
                    capinc_id: item.capinc_id,
                    cip: this.$route.name === "corner-detail"
                },
                params: {
                    orderId: item.id.toString()
                }
            });
        },
        getActions(item) {
            let actions = [];
            if (this.openOrder) {
                actions.push({
                    txt: this.$t("transaction.orders-see-details"),
                    icon: "mdi-eye-outline",
                    fn: () => this.openOrder(item),
                    color: "primary"
                });
            }
            const payment = getSelectedPayment(item);
            if (
                item.status == "pending" ||
                (item.status == "signed" && payment && payment.name === "walletdata")
            )
                actions.push({
                    txt: this.$t("document-to-sign.documents-list.tooltip.finalize"),
                    icon: "mdi-border-color",
                    fn: () => this.finishOrder(item)
                });
            if (item.status == "pending" || item.status == "signed")
                actions.push({
                    txt: this.$t("transaction.delete-order"),
                    icon: "mdi-delete-outline",
                    color: "error",
                    fn: () => this.deleteOrder(item)
                });
            return actions;
        }
    }
};
</script>

<style lang="scss" scoped></style>
