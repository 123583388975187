// import Subscription from "@/components/views/user/subscription/_Subscription.vue";
// import PayPlan from "@/components/views/user/subscription/payPlan/_PayPlan.vue";
// import CompanyRates from "@/components/views/user/company/CompanyRates";

import TestEditor from "@/components/global/globalTextEditor/TestEditor.vue";
import TestTextDisplay from "@/components/global/globalTextEditorDisplay/TestTextDisplay.vue";
import TestSignatureTemplate from "@/components/global/globalSignatureTemplate/TestSignatureTemplate.vue";

const devRoutes = () => {
    if (process.env.NODE_ENV === "production") {
        return [];
    }
    return [
        {
            path: "/test-editor",
            component: TestEditor
        },
        {
            path: "/test-text-display",
            component: TestTextDisplay
        },
        {
            path: "/test-signature-template",
            component: TestSignatureTemplate
        }
    ];
};

export default devRoutes();
