import { yesOrNo } from "@/util";

/*
    Helpers pour les types d'inputs
*/
const yesNoQ = (ctx, required = true) => {
    return {
        isRequired: required,
        dataType: "yes_or_no",
        options: yesOrNo(ctx)
    };
};
const multiSelectQ = (ctx, required = true) => {
    return {
        isRequired: required,
        dataType: "array"
    };
};
const intQ = (ctx, required = true) => {
    return {
        isRequired: required,
        dataType: "int"
    };
};
const percentQ = (ctx, required = true) => {
    return {
        isRequired: required,
        dataType: "float"
    };
};
const boolQ = (ctx, required = true) => {
    return {
        isRequired: required,
        dataType: "bool"
    };
};

const textQ = (ctx, required = true) => {
    return {
        isRequired: required,
        dataType: "string"
    };
};

const choiceQ = (ctx, required = true) => {
    return {
        isRequired: required,
        dataType: "string"
    };
};

/*
    Formulaires
*/

export const introPage = ctx => {
    return {
        accept_cgu: boolQ(ctx)
    };
};

export const form1 = ctx => {
    return {
        p1q1: multiSelectQ(ctx),
        p1q2: multiSelectQ(ctx),
        p1q3: yesNoQ(ctx),
        p1q4: multiSelectQ(ctx, false),
        p1q5: intQ(ctx, false),
        p1q6: choiceQ(ctx, false),
        p1q7: choiceQ(ctx, false),
        p1q8: yesNoQ(ctx),
        p1q9: yesNoQ(ctx),
        p1q10: textQ(ctx, false),
        p1q11: choiceQ(ctx)
    };
};

export const form2 = ctx => {
    return {
        p2q1: yesNoQ(ctx),
        p2q2: multiSelectQ(ctx, false),
        p2q3: choiceQ(ctx),
        p2q4: choiceQ(ctx),
        p2q5: choiceQ(ctx),
        p2q6: yesNoQ(ctx),
        p2q7: percentQ(ctx, false),
        p2q8: percentQ(ctx, false),
        p2q9: intQ(ctx, false),
        p2q10: choiceQ(ctx)
    };
};

export const form3 = ctx => {
    return {
        p3q1: yesNoQ(ctx),
        p3q2: yesNoQ(ctx),
        p3q3: yesNoQ(ctx),
        p3q4: yesNoQ(ctx),
        p3q5: yesNoQ(ctx),
        p3q6: yesNoQ(ctx),
        p3q7: yesNoQ(ctx),
        p3q8: yesNoQ(ctx),
        p3q9: yesNoQ(ctx)
    };
};

export const form4 = ctx => {
    return {
        p4q1: yesNoQ(ctx),
        p4q2: yesNoQ(ctx),
        p4q3: yesNoQ(ctx),
        confirm_infos_reliable: boolQ(ctx)
    };
};

export const form4bis = ctx => {
    return {
        p4bq1: intQ(ctx),
        p4bq2: intQ(ctx),
        p4bq3: intQ(ctx),
        confirm_unwarned: boolQ(ctx)
    };
};

export const form4ter = ctx => {
    return {
        confirm_risks: boolQ(ctx)
    };
};
