var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"root-step-container",attrs:{"fluid":""}},[_c('h2',{staticClass:"text-h5 mb-4"},[_vm._v("Objectifs d’investissement et situation financière")]),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p2q1,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p2q1", $event)}}},'EqsRadio',{
            label: 'Avez-vous des attentes spécifiques en matière de durabilité de votre investissement ?',
            ..._vm.schemaDouble.p2q1
        },false)),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.p2q1 === 'yes'),expression:"formData.p2q1 === 'yes'"}],staticClass:"pa-0 ma-0"},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p2q2,"multiple":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p2q2", $event)}}},'EqsSelect',{
                label: 'Si oui, lesquelles ?',
                items: _vm.q2Items,
                ..._vm.schemaDouble.p2q2
            },false))],1),_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p2q3},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p2q3", $event)}}},'EqsSelect',{
            label: 'Quelle perte de montants investis pouvez-vous supporter financièrement ?',
            items: _vm.q3Items,
            ..._vm.schemaDouble.p2q3
        },false)),_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p2q4},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p2q4", $event)}}},'EqsSelect',{
            label: 'Quelle est l’origine principale de vos capitaux ?',
            items: _vm.q4Items,
            ..._vm.schemaDouble.p2q4
        },false)),_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p2q5},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p2q5", $event)}}},'EqsSelect',{
            label: 'Pour quelle raison souhaitez-vous investir sur la plateforme ?',
            items: _vm.q5Items,
            ..._vm.schemaDouble.p2q5
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p2q6,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p2q6", $event)}}},'EqsRadio',{
            label: 'Votre situation financière actuelle vous permet-elle d’épargner une partie de vos revenus ?',
            ..._vm.schemaDouble.p2q6
        },false)),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.p2q6 === 'yes'),expression:"formData.p2q6 === 'yes'"}],staticClass:"pa-0 ma-0"},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.formData.p2q7,"is-currency":false,"is-percentage":true},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p2q7", $event)}}},'EqsNumber',{
                label: 'Quelle quote-part de votre patrimoine financier est liquide et disponible ?',
                editable: true,
                placeholder: 'Ex: 5%',
                ..._vm.schemaDouble.p2q7
            },false))],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.p2q6 === 'yes'),expression:"formData.p2q6 === 'yes'"}],staticClass:"pa-0 ma-0"},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.formData.p2q8,"is-currency":false,"is-percentage":true},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p2q8", $event)}}},'EqsNumber',{
                label: 'Quelle est la part de vos remboursements d’emprunt dans votre revenu ?',
                editable: true,
                placeholder: 'Ex: 10%',
                ..._vm.schemaDouble.p2q8
            },false))],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.p2q6 === 'yes'),expression:"formData.p2q6 === 'yes'"}],staticClass:"pa-0 ma-0"},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.formData.p2q9,"is-currency":false,"is-float":false},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p2q9", $event)}}},'EqsNumber',{
                label: 'Quels ont été vos revenus totaux sur l’année N-1?',
                editable: true,
                placeholder: 'Ex: 100 000',
                ..._vm.schemaDouble.p2q9
            },false))],1),_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p2q10},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p2q10", $event)}}},'EqsSelect',{
            label: 'Quelle est la durée souhaitée de votre investissement sur notre plateforme ?',
            items: _vm.q10Items,
            ..._vm.schemaDouble.p2q10
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }