import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }, queries = {}) => {
    DEBUG("GET_NOTIFICATIONS_START");
    if (state.notificationsInit && !state.notificationsLoading) {
        try {
            commit("SET_NOTIFICATIONS_LOADING", true);
            const notificationsRes = await window.app.$api.getNotifications({
                entity_represented_id: store.getters.currentRole.entity_represented.id,
                ...queries
            });
            const numActive = await window.app.$api.getNotificationNumActive();
            DEBUG("GET_NOTIFICATIONS_SUCCESS");
            commit("SET_NOTIFICATIONS", notificationsRes.data.results);
            commit("SET_NUMBER_OF_NEW_NOTIFICATIONS", numActive.data.count);
        } catch (e) {
            DEBUG("GET_NOTIFICATIONS_ERROR");
            window.app.$ui.error(e, "entity_get_notifications");
        } finally {
            commit("SET_NOTIFICATIONS_LOADING", false);
        }
    } else {
        DEBUG("GET_NOTIFICATIONS_BYPASSED");
    }
};
