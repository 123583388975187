<template>
    <v-lazy>
        <v-container fluid class="align-start pa-0 ma-0">
            <v-row class="ma-0 pa-0" justify="end">
                <v-col cols="auto">
                    <v-btn class="custom-btn" outlined @click="add">
                        {{ $t("payments.add") }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0" dense>
                <v-col :cols="getPaymentsListCols">
                    <DataTablePaginated
                        :loading="loading || localLoading"
                        :headers="headers"
                        :items="items"
                        sort-desc
                        show-row-pointer
                        sort-by="operation_date"
                        search="credited_entity_name"
                        :filter="{ field: 'operation_type', items: paymentCategories }"
                        @update="fetchData"
                        @click-row="selectRow"
                    >
                        <template #[`item.action`]="{ value }">
                            <EqsChip v-if="value" :color="$color.colorFromHash(value)">
                                {{ payment_actions[value] ? payment_actions[value].txt : value }}
                            </EqsChip>
                        </template>
                        <template #[`item.operation_date`]="{ value }">
                            {{ $time.formated(value) }}
                        </template>
                        <template #[`item.amount`]="{ item }">
                            {{
                                $currency.displayWithCurrency(
                                    item.amount,
                                    $entity.represented().country.alphacode == "US" ? "USD" : "EUR"
                                )
                            }}
                        </template>

                        <template #[`item.from`]="{ item }">
                            {{ item.from }}
                        </template>
                        <template #[`item.credited_entity_name`]="{ item }">
                            <div class="cell d-flex flex-row align-center">
                                <EqsAvatar
                                    v-if="item.credited_entity"
                                    class="pr-1"
                                    v-bind="{
                                        user: item.credited_entity,
                                        noBadge: true
                                    }"
                                />
                                {{ item.credited_entity?.name || "-" }}
                            </div>
                        </template>
                        <template #[`item.debited_entity_name`]="{ item }">
                            <div class="cell d-flex flex-row align-center">
                                <EqsAvatar
                                    v-if="item.debited_entity"
                                    class="pr-1"
                                    v-bind="{
                                        user: item.debited_entity,
                                        noBadge: true
                                    }"
                                />
                                {{ item.debited_entity?.name || "-" }}
                            </div>
                        </template>
                        <template #[`item.operation_type`]="{ value }">
                            <EqsChip :color="$color.colorFromHash(value)">
                                {{ payment_types[value] ? payment_types[value].txt : value }}
                            </EqsChip>
                        </template>
                        <template #[`item.lemonway_status`]="{ value }">
                            <EqsChip v-bind="statutes[value]">
                                {{ statutes[value] ? statutes[value].txt : value }}
                            </EqsChip>
                        </template>
                    </DataTablePaginated>
                </v-col>
                <v-col v-if="selectedPayment" :cols="$reactive.isDesktop() ? 5 : 12">
                    <PaymentDetail
                        v-bind="{ payment: selectedPayment, loading }"
                        @close="() => (selectedPayment = null)"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-lazy>
</template>

<script>
import DataTablePaginated from "@/components/views/DataTablePaginated";

import { statutes, payment_types, payment_actions } from "@/util/payment.js";

export default {
    name: "Payments",
    components: {
        PaymentDetail: () => import("./PaymentDetails"),
        DataTablePaginated
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            payment_types,
            payment_actions,
            selectedPayment: null,
            statutes,
            items: {},
            headers: [
                {
                    text: this.$t("label.action"),
                    value: "action",
                    sortable: true
                },
                {
                    text: this.$t("label.date"),
                    value: "operation_date",
                    sortable: true
                },
                {
                    text: this.$t("label.amount"),
                    value: "amount",
                    sortable: true
                },

                {
                    text: this.$t("payments.debitor"),
                    value: "debited_entity_name",
                    sortable: true
                },
                {
                    text: this.$t("payments.creditor"),
                    value: "credited_entity_name",
                    sortable: true
                },
                {
                    text: this.$t("label.type"),
                    value: "operation_type",
                    sortable: true
                },
                {
                    text: this.$t("label.status"),
                    value: "lemonway_status",
                    sortable: true
                }
            ],
            localLoading: false
        };
    },
    computed: {
        getPaymentsListCols() {
            let cols = 12;
            if (this.selectedPayment) cols = this.$reactive.isDesktop() ? 7 : 0;
            return cols;
        },
        paymentCategories() {
            let cats = Object.entries(payment_types)
                .map(item => {
                    return { text: item[1].txt, value: item[0] };
                })
                .sort((a, b) => a.text.localeCompare(b.text));
            return cats;
        }
    },
    methods: {
        selectRow(item, row, e) {
            if (this.selectedPayment && item.id === this.selectedPayment.id) {
                this.closePayment();
                return;
            }
            this.selectedPayment = item;
            this.openPayment(item);
        },
        openPayment(payment) {
            this.selectedPayment = payment;
        },
        closePayment() {
            this.selectedPayment = null;
        },
        add() {
            this.$router
                .push({
                    name: "add-payment-operation",
                    params: {
                        ...this.$route.params,
                        action: "to_send"
                    }
                })
                .catch(() => {});
        },
        async fetchData(queries) {
            try {
                this.localLoading = true;
                let res = await this.$api.getTransfers(queries);
                res.data.results = res.data.results.map(item => {
                    return {
                        ...item,
                        actionnaire:
                            item.debited_entity &&
                            this.$entity.represented() &&
                            this.$entity.represented().id === item.debited_entity.id
                                ? item.credited_entity
                                : item.debited_entity
                    };
                });
                this.items = res.data;
            } catch (e) {
                // TODO
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    text-transform: none !important;
    color: white !important;
    background-color: var(--v-primary-base) !important;
}
</style>
