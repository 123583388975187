var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDataTable',_vm._b({attrs:{"hide-default-footer":"","sort-by":"nb_vote","sort-desc":[true]},scopedSlots:_vm._u([{key:"item.investor.entity",fn:function({ value }){return [_c('EqsAvatar',_vm._b({attrs:{"no-badge":""}},'EqsAvatar',{ user: value, eqsType: 'list' },false))]}},{key:"item.matchingLedgers",fn:function({ value }){return [_c('v-chip-group',{attrs:{"max":"0"}},_vm._l((value),function(share,i){return _c('EqsChip',{key:i,attrs:{"label":"","ripple":false,"color":_vm.$color.colorFromHash(share ? share.name : ''),"text-color":"text"}},[_vm._v(" "+_vm._s(share ? share.name : "")+" ")])}),1)]}},{key:"item.nb_vote",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$currency.display(value, "0,0"))+" ")]}},{key:"item.percent",fn:function({ item }){return [_vm._v(" "+_vm._s(item.percent + " %")+" ")]}}])},'EqsDataTable',{
        loading: _vm.loading || _vm.investorsLoading,
        items: _vm.voters,
        headers: _vm.headers,
        itemsPerPage: -1,
        ..._vm.$attrs
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }