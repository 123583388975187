import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ state, dispatch }, decisionId) => {
    DEBUG("GET_DECISION_START");
    if (!decisionId) throw new Error("no decisionId");
    let decision = state.decisions.find(_decision => _decision.id === decisionId);
    if (!decision) {
        try {
            await dispatch("getDecisions");
            decision = state.decisions.find(_decision => _decision.id === decisionId);
        } catch (e) {
            throw e;
        }
    }
    if (decision) DEBUG("GET_DECISION_SUCCESS");
    else {
        DEBUG("GET_DECISION_ERROR");
        throw new Error("decision not found");
    }

    return decision || {};
};
