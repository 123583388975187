<template>
    <EqsCard
        closable
        class="fill-height"
        :title="entityProxy.name"
        :avatar="getAvatar"
        :is-company="
            entityProxy.is_company || !!(entityProxy.entity && entityProxy.entity.is_company)
        "
        v-bind="{ loading, elClass: 'entity-details-card', height: '100%' }"
        @close="close"
    >
        <EntityDetailsPanels
            v-if="!loading"
            v-bind="{
                entity: getAllEntityInfos,
                openPanelN,
                registrationInfos,
                procedureDocuments,
                paymentOrderDetails,
                proofOfFund
            }"
        />
    </EqsCard>
</template>

<script>
import EntityDetailsPanels from "@/components/views/shareledger/dialogs/details/EntityDetailsPanels.vue";

export default {
    name: "EntityDetailsDialog",
    components: {
        EntityDetailsPanels
    },
    props: {
        entity: {
            type: Object,
            default: () => {}
        },
        rootURL: {
            type: String,
            default: ""
        },
        openPanelN: {
            type: Number,
            required: false,
            default: null
        },
        entityId: {
            type: String,
            default: null
        },
        registrationInfos: {
            type: Boolean,
            default: false
        },
        procedureDocuments: {
            type: Array,
            required: false,
            default: null
        },
        paymentOrderDetails: {
            type: Object,
            required: false,
            default: null
        },
        proofOfFund: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            entityProxy: {}
        };
    },
    computed: {
        getAllEntityInfos() {
            if (this.entityProxy && this.entityProxy.entity) {
                return { ...this.entityProxy, ...this.entityProxy.entity };
            }
            return this.entityProxy;
        },
        getAvatar() {
            if (this.entityProxy && this.entityProxy.entity && this.entityProxy.entity.avatar)
                return this.entityProxy.entity.avatar;
            return this.entityProxy && this.entityProxy.avatar ? this.entityProxy.avatar : null;
        }
    },
    watch: {
        entityId: {
            immediate: true,
            async handler(id) {
                await this.fetchEntity(id);
            }
        },
        entity: {
            immediate: true,
            handler(val) {
                if (val) this.entityProxy = val;
            }
        }
    },
    methods: {
        close() {
            this.$emit("update:entity", null);
            if (this.rootURL) this.$router.replace(this.rootURL);
            else this.$emit("close");
        },
        async fetchEntity() {
            try {
                this.loading = true;
                if (_.isEmpty(this.entity) && this.entityId) {
                    let res = null;
                    if (this.registrationInfos) res = await this.$api.getEntityInfos(this.entityId);
                    else res = await this.$api.getEntity(this.entityId);
                    const entity = res.data;
                    this.entityProxy = entity;
                    this.$emit("update:entity", entity);
                }
            } catch (e) {
                this.$ui.error(e, "entity_infos");
                throw e;
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
