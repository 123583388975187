var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.nonVoters.length)?_c('EqsCard',_vm._b({staticClass:"px-3",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('EqsDataTable',_vm._b({staticClass:"px-3",attrs:{"hide-default-footer":"","sort-by":"investor.name","sort-desc":[true]},scopedSlots:_vm._u([{key:"item.investor.entity",fn:function({ value }){return [_c('EqsAvatar',_vm._b({attrs:{"no-badge":""}},'EqsAvatar',{ user: value, eqsType: 'list' },false))]}}],null,false,3801980492)},'EqsDataTable',{
                items: _vm.nonVoters,
                headers: _vm.headers,
                itemsPerPage: -1
            },false))]},proxy:true}],null,false,3335542214)},'EqsCard',{
        subtitle: _vm.$t('governance.organs.details.non-voting.title'),
        loading: _vm.loading
    },false)):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }