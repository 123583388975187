import { render, staticRenderFns } from "./_Representatives.vue?vue&type=template&id=1caa5176&scoped=true"
import script from "./_Representatives.vue?vue&type=script&lang=js"
export * from "./_Representatives.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1caa5176",
  null
  
)

export default component.exports