<template>
    <v-container fluid>
        <div class="status-title-icon mb-0">
            <div class="ma-0 pa-0 mr-4 icon-bg">
                <v-icon class="custom-left-icon" size="40"> mdi-email-fast-outline </v-icon>
            </div>
            <span class="text-h5" style="color: var(--v-primary-base)">{{
                $t("user.buy.send-bank-pea.send-docs-advisor")
            }}</span>
        </div>
        <v-alert v-if="mailSent" border="left" outlined text type="success" class="my-4">
            <span style="color: black">
                {{ $t("user.buy.send-bank-pea.already-sent") }}
            </span>
        </v-alert>
        <div>
            <p v-html="`👉 ${$t('user.buy.send-bank-pea.info-1')}`" />
            <p>{{ $t("user.buy.send-bank-pea.info-2") }}</p>
            <p class="font-weight-bold">📝 {{ $t("user.buy.send-bank-pea.info-3") }}</p>
        </div>
        <v-row>
            <v-col cols="12" md="6">
                <EqsFileInput
                    v-for="(doc, index) in peaDocs"
                    :key="index"
                    v-bind="{
                        loading: localLoading,
                        editable: false,
                        ...doc
                    }"
                />
            </v-col>
            <v-col cols="12" md="6">
                <div>
                    <EqsEmail
                        :value.sync="email"
                        persistent-hint
                        v-bind="{
                            isRequired: false,
                            dataType: 'string',
                            successMessages: mailSent
                                ? [$t('user.buy.send-bank-pea.send-docs-advisor-success-hint')]
                                : null,
                            label: $t('user.buy.send-bank-pea.send-docs-advisor-label'),
                            hint: $t('user.buy.send-bank-pea.send-docs-advisor-hint')
                        }"
                    />
                    <div class="mt-4 custom-center-button">
                        <EqsBtn
                            v-if="!mailSent"
                            outlined
                            v-bind="{
                                action: sendMailBank,
                                color: 'primary',
                                disabled: disableSend
                            }"
                        >
                            {{ $t("action.send") }}
                        </EqsBtn>
                        <EqsBtn
                            v-if="mailSent"
                            outlined
                            v-bind="{
                                action: confirmResend,
                                color: 'primary'
                            }"
                        >
                            {{ $t("action.resend") }}
                        </EqsBtn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <EqsDialog
            :value.sync="resendDialog"
            v-bind="{
                title: $t('action.confirm-sending')
            }"
            closable
            @click:outside="closeDialog()"
        >
            <v-container fluid>
                <v-row justify="space-between" align="center">
                    <v-col>
                        {{ $t("user.buy.send-bank-pea.confirm-resend") }}
                    </v-col>
                </v-row>
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="6" style="text-align: center">
                        <v-btn color="error" @click.stop="confirmDialolg(false)">{{
                            $t("action.cancel")
                        }}</v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" style="text-align: center">
                        <v-btn color="success" @click.stop="confirmDialolg(true)">{{
                            $t("action.send")
                        }}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </EqsDialog>
    </v-container>
</template>

<script>
import { emailR } from "@equisafe-ui-vue/src/rules";
import { getSelectedPayment } from "../util";

export default {
    name: "SendBankPeapme",
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    emits: ["mail-sent"],
    data() {
        return {
            localLoading: true,
            docs: {},
            mailSent: false,
            email: null,
            resendDialog: false
        };
    },
    computed: {
        disableSend() {
            if (this.mailSent) return true;
            if (!this.email || this.email.length === 0) return true;
            return emailR(this.email) !== true;
        },
        peaDocs() {
            const bs = Object.entries(this.docs)
                .filter(doc => {
                    const [key, _value] = doc;
                    return key.includes("signed_doc_");
                })
                .map((signedDoc, index) => {
                    const [_key, value] = signedDoc;
                    return {
                        label: this.$t("user.buy.send-bank-pea.bs", { index: index + 1 }),
                        value
                    };
                });
            const selectedPayment = getSelectedPayment(this.order);
            return [
                {
                    label:
                        selectedPayment.name === "sepadata"
                            ? this.$t("user.buy.send-bank-pea.sepa")
                            : this.$t("user.buy.send-bank-pea.rib"),
                    value: this.docs.doc_viban
                },
                {
                    label: this.$t("user.buy.send-bank-pea.pv"),
                    value: this.docs.doc_pv
                },
                {
                    label: this.$t("user.buy.send-bank-pea.status"),
                    value: this.docs.doc_status
                },
                {
                    label: this.$t("user.buy.send-bank-pea.kbis"),
                    value: this.docs.doc_kbis
                },
                ...bs
            ];
        }
    },
    async created() {
        try {
            this.localLoading = true;
            this.mailSent = this.order.mail_bank_sent;
            if (this.mailSent) {
                this.$emit("mail-sent", true);
            }
            const res = await this.$api.getDocsPeaOrder(this.order.id);
            this.docs = res.data;

            let email = null;
            const envelope = this.order.envelope.choice;
            if (envelope.localeCompare("pea") === 0) {
                email = this.order.buyer.peapmedata.email_pea;
            } else if (envelope.localeCompare("pea-pme") === 0) {
                email = this.order.buyer.peapmedata.email_peapme;
            }
            this.email = email;
        } catch (e) {
            this.$ui.error(e, "buy_get_pea_docs");
        } finally {
            this.localLoading = false;
        }
    },
    methods: {
        confirmDialolg(val) {
            if (val) {
                this.sendMailBank();
            }
            this.resendDialog = false;
        },
        closeDialog() {
            this.resendDialog = false;
        },
        confirmResend() {
            this.resendDialog = true;
        },
        async sendMailBank() {
            try {
                await this.$api.sendMailPeaOrder(this.order.id, {
                    email: this.email
                });
                this.$ui.success(this.$t("user.buy.send-bank-pea.send-docs-advisor-success"));
                this.mailSent = true;

                this.$emit("mail-sent", true);
            } catch (e) {
                this.$ui.error(e, "buy_send_pea_mail");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.custom-center-button .v-input) {
    margin-left: auto;
    margin-right: auto;
}
</style>
