import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_ORGANS_START");
    if (state.organsInit && !state.organsLoading) {
        try {
            commit("SET_ORGANS_LOADING", true);
            const organsRes = await window.app.$api.getOrgans(
                store.getters.currentRole.entity_represented.id
            );
            const organs = organsRes.data;
            DEBUG("GET_ORGANS_SUCCESS");
            commit("SET_ORGANS", organs);
        } catch (e) {
            DEBUG("GET_ORGANS_ERROR");
            throw e;
        } finally {
            commit("SET_ORGANS_LOADING", false);
        }
    } else {
        DEBUG("GET_ORGANS_BYPASSED");
    }
};
