<template>
    <div>
        <EqsDataTable
            :add="{
                txt: $t('secondary-market.add-order'),
                fn: () => (importDialog = true)
            }"
            v-bind="{
                headers,
                searchable: {
                    placeholder: $t('secondary-market.search-businesses')
                },
                loading: localLoading,
                items: allCompany
            }"
        >
            <template #item.name="{ item }">
                <div class="d-flex flex-row align-center">
                    <EqsAvatar
                        class="pr-3"
                        v-bind="{
                            user: item,
                            eqsType: 'list',
                            noBadge: true
                        }"
                    />
                    <div>{{ item.name }}</div>
                </div>
            </template>
            <template #item.marketValue="{ item }">
                <div>
                    {{ $currency.displayWithCurrency(item.min, "EUR") }}
                    {{ $t("movements.headers.to") }}
                    {{ $currency.displayWithCurrency(item.max, "EUR") }}
                </div>
            </template>
            <template #item.actions="{ item, index }">
                <EqsBtn
                    small
                    text
                    :el-class="`see-offer-details-${index}`"
                    v-bind="{
                        infoTxt: $t('secondary-market.buy-shares.action-btn-tooltip')
                    }"
                    @click="
                        () => {
                            $router
                                .push({
                                    name: 'secondary-market-details',
                                    params: {
                                        id: item.id,
                                        company: item
                                    }
                                })
                                .catch(() => {});
                        }
                    "
                >
                    <v-icon>mdi-text-box-outline</v-icon>
                </EqsBtn>
            </template>
        </EqsDataTable>
        <ImportDialog :value.sync="importDialog" />
    </div>
</template>

<script>
import findIndex from "lodash/findIndex";
import ImportDialog from "./ImportDialog";

export default {
    name: "BuyShares",
    components: {
        ImportDialog
    },
    data() {
        return {
            importDialog: false,
            businesses: [],
            businessDetails: null,
            allCompany: [],
            localLoading: false,
            headers: [
                {
                    text: this.$t("secondary-market.header.company"),
                    value: "name",
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t("secondary-market.header.market-value"),
                    value: "marketValue",
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t("secondary-market.header.action"),
                    value: "actions",
                    sortable: false,
                    filterable: false
                }
            ]
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        proceedBuyStepper(item) {
            this.$router
                .push({
                    name: "secondary-market-buy-stepper",
                    params: {
                        buyType: "secondaryMarket",
                        orderId: item.details.sellOrderId,
                        orderObject: item
                    }
                })
                .catch(() => {});
        },
        async fetchData() {
            this.localLoading = true;
            try {
                if (this.$entity.represented()) {
                    await this.$role.updateMyCurrentRole(this.$entity.represented().id);
                }

                const res = await this.$api.readSecondaryMarketSellOrderList();
                this.businesses = res.data.map(x => {
                    let val = {
                        businesses:
                            x.shareledger.type +
                            " " +
                            x.shareledger.company.name +
                            " " +
                            x.shareledger.name,
                        numberInvestors: x.quantity,
                        indicativeValuation: x.price,
                        change: "10%",
                        details: {
                            name: x.shareledger.company.name,
                            summary: "shareledger.description",
                            description: "",
                            team: "",
                            investors: "",
                            shareholder: "",
                            documents: x.docs,
                            restrictions: "",
                            quantity: x.quantity,
                            indicativeValuation: x.price,
                            data: x,
                            sellOrderId: x.id,
                            url: x.url
                        }
                    };
                    return val;
                });
            } catch (e) {
                this.$ui.error(e, "secondary_market_fetch_sell_offers");
            } finally {
                this.localLoading = false;
                if (this.businesses.length > 0) this.orderByCompany();
            }
        },
        orderByCompany() {
            //Array by company
            //Each company has orders key with order
            this.businesses.forEach(element => {
                let company = element.details.data.shareledger.company;
                let index = findIndex(this.allCompany, e => {
                    return e.id == company.id;
                });
                if (index == -1) {
                    company["orders"] = [element];
                    company.min = element.indicativeValuation;
                    company.max = element.indicativeValuation;
                    this.allCompany.push(company);
                } else {
                    this.allCompany[index].orders.push(element);
                    if (this.allCompany[index].min) {
                        this.allCompany[index].min = Math.min(
                            this.allCompany[index].min,
                            element.indicativeValuation
                        );
                    } else {
                        this.allCompany[index].min = element.indicativeValuation;
                    }
                    if (this.allCompany[index].max) {
                        this.allCompany[index].max = Math.max(
                            this.allCompany[index].max,
                            element.indicativeValuation
                        );
                    } else {
                        this.allCompany[index].max = element.indicativeValuation;
                    }
                }
            });
        }
    }
};
</script>
