<template>
    <div :class="`eqs-text-display ${elClass}`" :style="getStyle">
        <EqsSkeleton v-bind="{ loading, type: 'text' }">
            <div v-if="getSanitizedHtml" :class="getTextClasses" v-html="getSanitizedHtml" />
            <span v-else-if="value" :class="getTextClasses"> {{ value }} </span>
            <div v-else :class="getTextClasses">
                <slot />
            </div>
        </EqsSkeleton>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsTextDisplay.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsTextDisplay",
    components: {
        EqsSkeleton: () => import("../EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        },
        paragraph: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        getSanitizedHtml() {
            if (this.loading || !this.value || !this.value.includes("<")) return "";
            return this.$text.sanitize(this.value);
        },
        getTextClasses() {
            return [this.getData.textSizing, this.paragraph ? "paragraph" : ""];
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsTextDisplay.scss";
</style>
