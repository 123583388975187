<template>
    <EqsStepper
        :value.sync="step"
        v-bind="{ steps, progress }"
        closable
        @close="comeBackToInvestors"
    >
        <template #default="{ item }">
            <div :class="item.class">
                <component
                    :is="item.is"
                    v-bind="{
                        schemaDouble,
                        formData,
                        loading: loading || localLoading,
                        ...item
                    }"
                />
            </div>
        </template>
    </EqsStepper>
</template>

<script>
import {
    progressMixin,
    buildFormDataMixin,
    formatFormDataMixin,
    formChangedMixin,
    formErrorMixin
} from "@equisafe-ui-vue/src/mixins/formMixin";
import { mapActions } from "vuex";
import { EqsChooseAction } from "@equisafe-ui-vue";
export default {
    name: "AddInvestorStepper",
    components: {
        EqsChooseAction,
        AddInvestorForm: () => import("./AddInvestorForm"),
        AddInvestorsCSVFile: () => import("./AddInvestorsCSVFile")
    },
    mixins: [
        progressMixin,
        buildFormDataMixin,
        formatFormDataMixin,
        formChangedMixin,
        formErrorMixin
    ],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            formData: {},
            step: 0,
            schemaDouble: {},
            localLoading: false
        };
    },
    computed: {
        steps() {
            const firstStep = {
                title: this.$t("investors.stepper.choose-associate-invitation"),
                item: {
                    is: "EqsChooseAction",
                    class: "d-flex justify-center fill-height",
                    choices: [
                        {
                            title: this.$t("investors.user.cardtitle"),
                            text: this.$t("investors.user.cardsubtitle"),
                            img: "/images/investors/card1.svg",
                            action: () => this.chooseInviteType("user")
                        },
                        {
                            title: this.$t("investors.company.cardtitle"),
                            text: this.$t("investors.company.cardsubtitle"),
                            img: "/images/investors/card2.svg",
                            action: () => this.chooseInviteType("company")
                        },
                        //TODO, put back action and remove 'notClickable' when the feature is ready
                        {
                            title: this.$t("investors.csv.cardtitle"),
                            text: this.$t("investors.csv.cardsubtitle"),
                            //inProgress: true,
                            img: "/images/investors/card3.svg",
                            action: () => this.chooseInviteType("csv")
                        }
                    ]
                },
                back: {
                    fn: this.comeBackToInvestors
                },
                schemaDouble: {
                    invite_type: {
                        isRequired: true
                    }
                },
                noNext: true
            };

            let inviteInvestorSchemaDouble = {
                type: {
                    dataType: "string",
                    isRequired: true
                },
                email: {
                    dataType: "string",
                    isRequired: true
                },
                address: {
                    dataType: "string",
                    isRequired: false
                },
                zipcode: {
                    dataType: "string",
                    isRequired: false
                },
                city: {
                    dataType: "string",
                    isRequired: false
                },
                country: {
                    dataType: "string",
                    isRequired: false
                },
                nationality: {
                    isRequired: true
                }
            };
            Object.assign(
                inviteInvestorSchemaDouble,
                this.formData.invite_type === "user"
                    ? {
                          last_name: {
                              dataType: "string",
                              isRequired: true
                          },
                          first_name: {
                              dataType: "string",
                              isRequired: true
                          }
                      }
                    : {
                          company_name: {
                              dataType: "string",
                              isRequired: true
                          },
                          company_idcode: {
                              dataType: "string",
                              isRequired: true
                          }
                      }
            );

            const inviteInvestor = {
                title:
                    this.formData.invite_type === "user"
                        ? this.$t("investors.stepper.invite-person")
                        : this.$t("investors.stepper.invite-company"),
                item: {
                    is: "AddInvestorForm"
                },
                next: {
                    fn: this.finalize
                },
                schemaDouble: inviteInvestorSchemaDouble
            };

            const inviteInvestorsCsv = {
                title: !this.formData.invite_type
                    ? this.$t("label.invite")
                    : this.$t("investors.stepper.invite-several-associates"),
                item: {
                    is: "AddInvestorsCSVFile"
                },
                next: {
                    fn: this.parseCSV
                },
                schemaDouble: {
                    doc: {
                        isRequired: true,
                        dataType: "file"
                    }
                }
            };
            return this.formData.invite_type === "csv" || !this.formData.invite_type
                ? [firstStep, inviteInvestorsCsv]
                : [firstStep, inviteInvestor];
        }
    },
    watch: {
        step: {
            immediate: true,
            handler(val) {
                const currStep = this.steps[val];
                this.schemaDouble = currStep && currStep.schemaDouble ? currStep.schemaDouble : {};
                this.buildFormData(this.schemaDouble, this.investor);
            }
        }
    },
    methods: {
        comeBackToInvestors() {
            this.$router.push({
                name: "investors",
                params: this.$route.params
            });
        },
        async chooseInviteType(voteType) {
            this.formData = { invite_type: voteType };
            this.step++;
        },
        async finalize() {
            try {
                this.localLoading = true;
                await this.addInvestor();
                this.comeBackToInvestors();
            } catch (e) {
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async parseCSV() {
            this.localLoading = true;
            try {
                await this.$api.inviteMultipleInvestors({
                    document_id: this.formData.doc.id
                });
                this.comeBackToInvestors();
            } catch (e) {
                console.log(e);
                this.handleError(e, "investor_create");
                this.$ui.error(e, "investor_create");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async addInvestor() {
            try {
                const data = {
                    is_company_invited: this.formData.invite_type === "company"
                };
                await this.createInvestor(this.formatFormData(this.schemaDouble, data));
            } catch (e) {
                this.handleError(e, "investor_create");
                throw e;
            }
        },
        ...mapActions(["createInvestor", "inviteInvestors"])
    }
};
</script>

<style lang="scss" scoped></style>
