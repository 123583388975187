var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._g(_vm._b({staticClass:"fill-height",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('EqsStepper',_vm._b({staticStyle:{"height":"100%","background-color":"#f3f2ff"},attrs:{"value":_vm.step,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":_vm.goToCapinc},scopedSlots:_vm._u([{key:"default",fn:function({ item, loading: isLoading }){return [_c('div',{class:item.is != 'GlobalYousignIFrame'
                            ? 'eqs-main-container margin-auto enforce-font'
                            : 'fill-height fill-width pa-0 enforce-font'},[_c(item.is,_vm._b({tag:"component",attrs:{"retriable":"","schema-double":_vm.schemaDouble},on:{"update:schemaDouble":function($event){_vm.schemaDouble=$event},"update:schema-double":function($event){_vm.schemaDouble=$event},"signatureRefused":_vm.signatureRefused}},'component',{
                            loading: isLoading,
                            order: _vm.order,
                            status: _vm.status,
                            balance: _vm.balance,
                            signer: _vm.signer,
                            formData: _vm.formData
                        },false))],1)]}}])},'EqsStepper',{
                steps: _vm.steps,
                loading: _vm.loading || _vm.localLoading || _vm.balanceLoading,
                closeInfoTxt: _vm.$t('label.cancel'),
                progress: _vm.progress
            },false))]},proxy:true}])},'EqsCard',{ height: '100%', loading: _vm.loadingOrder || _vm.localLoading },false),_vm.$listeners),[_c('v-dialog',{attrs:{"closable":"","transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Capacité à supporter les pertes ")]),_c('v-card-text',[_vm._v(" Nous avons détecté une volonté d’investir une somme supérieure à 1,000€ ou 5% de votre patrimoine net. Nous vous invitons à refaire le test de capacité à supporter les pertes. ")]),_c('v-divider'),_c('v-checkbox',{staticClass:"font-weight-bold",attrs:{"label":"J’ai compris les risques que mon investissement impliquaient et je confirme ma volonté de poursuivre mon investissement."},model:{value:(_vm.dialogCheck),callback:function ($$v) {_vm.dialogCheck=$$v},expression:"dialogCheck"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":() => {
                            _vm.dialogProxy = false;
                            _vm.$router.push({
                                name: 'risk-aversion',
                                params: { id: _vm.$entity.represented().id }
                            });
                        }}},[_vm._v(" Repasser le test ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.dialogCheck},on:{"click":() => {
                            _vm.dialogProxy = false;
                            _vm.createOrder();
                        }}},[_vm._v(" Ne pas repasser le test ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }