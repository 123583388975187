<template>
    <v-form v-model="validForm" class="d-flex flex-column">
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsSelect
                    :value="formData.type"
                    v-bind="{
                        label: $t('investorDetails.select-type'),
                        items: listType,
                        itemValue: 'value',
                        itemText: 'display_name',
                        loading: investorsLoading,
                        editable
                    }"
                    @update:value="updateType"
                />
            </v-col>
            <EqsCrudActionBtns
                :class="editable ? 'pt-5 mt-3' : ''"
                :editable.sync="editable"
                :has-changed.sync="hasChanged"
                v-bind="{
                    validForm,
                    cancel: resetFormData,
                    save
                }"
            />
        </v-row>
    </v-form>
</template>

<script>
import { formChangedMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { entityDetailsMixin } from "@/mixins/kycMixin";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "InvestorDetails",
    mixins: [formChangedMixin, entityDetailsMixin],
    props: {
        investor: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            listType: [],
            editable: false,
            validForm: true,
            hasChanged: false,
            formData: {},
            schemaDouble: {
                type: {
                    isRequired: true
                }
            }
        };
    },
    computed: {
        schema() {
            return this.entityDetailsSchema(this, this.investor);
        },
        ...mapGetters(["investorsLoading"])
    },
    watch: {
        errorNoFieldsChanged(val) {
            if (!val && this.editable) this.hasChanged = true;
        }
    },
    async created() {
        // CALL API TO GET LIST TYPE
        let res = await this.$api.getInvestorOptions();
        this.listType = res.data.actions.POST.type.choices;
        // SET FORM DATA WHITH INVESTOR TYPE
        if (this.investor.type) {
            this.formData.type = this.investor.type;
        } else {
            this.formData.type = this.listType[0].value;
        }
        if (this.investor.type_display) {
            this.formData.type_display = this.investor.type_display;
        } else {
            this.formData.type = this.listType[0].display_name;
        }
        this.saveFormData();
    },
    methods: {
        ...mapActions(["updateInvestor"]),
        updateType(val) {
            const type_display = this.listType.find(
                investorType => investorType.value == val
            ).display_name;
            this.formData = { type: val, type_display };
        },
        async save() {
            const id = this.investor.id;
            try {
                await this.updateInvestor({ id, type: this.formData.type });
                this.$ui.success(this.$t("investorDetails.success-update"));
                this.saveFormData();
            } catch (e) {
                this.$ui.error($t("investorDetails.error-update"));
            } finally {
            }
        }
    }
};
</script>
