const init = () => ({
    isLoadingRoute: false,
    isLoadingWebSocket: false,
    failedRoute: null,
    lastRoute: null
});

export default {
    state: init(),
    mutations: {
        setLoadingRoute(state, bool) {
            state.isLoadingRoute = bool;
        },
        setLoadingWebSocket(state, bool) {
            state.isLoadingWebSocket = bool;
        },
        setFailedRoute(state, route) {
            state.failedRoute = route;
        },
        setLastRoute(state, route) {
            state.lastRoute = route;
        },
        RESET_STORE_LOADING(state) {
            const s = init();
            Object.keys(s).forEach(key => {
                state[key] = s[key];
            });
        }
    },
    getters: {
        loadingRoute: state => {
            return state.isLoadingRoute;
        },
        loadingWebSocket: state => {
            return state.isLoadingWebSocket;
        },
        failedRoute: state => {
            return state.failedRoute;
        },
        lastRoute: state => {
            return state.lastRoute;
        }
    }
};
