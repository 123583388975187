<template>
    <v-row class="eqs-view-with-image d-flex fill-height overflow-y ma-0" :el-class="elClass">
        <v-col :cols="$reactive.getCols('half')" :order="$reactive.isDesktop() ? 0 : 1">
            <slot name="default" v-bind="{ loading }" />
        </v-col>

        <v-col
            v-if="img"
            :cols="$reactive.getCols('half')"
            :order="$reactive.isDesktop() ? 1 : 0"
            class="d-flex justify-center align-center"
        >
            <EqsImage
                contain
                v-bind="{
                    src: img,
                    maxWidth: $reactive.isDesktop()
                        ? 'inherit'
                        : $reactive.getElementHeight('main-image'),
                    maxHeight: $reactive.getElementHeight('main-image')
                }"
            />
        </v-col>
    </v-row>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsViewWithImage.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import EqsImage from "../../atoms/EqsImage/EqsImage.vue";

export default {
    name: "EqsViewWithImage",
    components: {
        EqsImage
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-view-with-image"
        },
        img: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsViewWithImage.scss";
</style>
