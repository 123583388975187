var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('v-container',{attrs:{"ma-0":""}},[(!!_vm.iban || _vm.display)?_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('EqsLabel',{attrs:{"value":_vm.$t('user.wallet.bank-account.label'),"editable":false}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('EqsTextDisplay',_vm._b({attrs:{"el-class":`bank-account-${_vm.lastNumbers}`}},'EqsTextDisplay',{ loading: _vm.localLoading || _vm.ibanLoading },false),[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.bankAccountTxt)+" ")])]),(!_vm.display && _vm.iban)?_c('EqsTextDisplay',_vm._b({staticClass:"px-1"},'EqsTextDisplay',{ loading: _vm.localLoading || _vm.ibanLoading },false),[_c('a',{attrs:{"el-class":"info-bank-dialog"},on:{"click":() => (_vm.accountDialog = true)}},[_vm._v(" "+_vm._s(_vm.$t("user.wallet.bank-account.see-details"))+" ")])]):_vm._e(),(
                                    !_vm.display &&
                                    !_vm.bankDetailsOnly &&
                                    _vm.status &&
                                    ['error', 'success'].includes(_vm.status.id)
                                )?_c('EqsTextDisplay',_vm._b({staticClass:"px-1"},'EqsTextDisplay',{ loading: _vm.localLoading || _vm.ibanLoading },false),[_c('a',{attrs:{"el-class":"replace-bank"},on:{"click":() => _vm.$emit('AddBankAccount')}},[_vm._v(" "+_vm._s(_vm.$t("user.wallet.bank-account.replace"))+" ")])]):_vm._e()],1)])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-0"},[(_vm.status)?_c('EqsChip',{staticClass:"mb-3",attrs:{"el-class":`iban-status-${_vm.status.id}`,"eqs-type":_vm.status.color}},[_vm._v(" "+_vm._s(_vm.status.txt)+" ")]):_vm._e()],1)],1),(!_vm.display && !_vm.iban)?_c('EqsBtn',{staticClass:"ma-3",attrs:{"loading":_vm.ibanLoading,"el-class":"link-bank","color":"warning","block":""},on:{"click":() => _vm.$emit('AddBankAccount')},scopedSlots:_vm._u([{key:"with-icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-bank-plus ")]),_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$t("user.wallet.add-bank-account")))+" ")]},proxy:true}],null,false,3103401676)}):_vm._e(),(!_vm.display && _vm.iban)?_c('BankAccountDialog',_vm._b({attrs:{"value":_vm.accountDialog},on:{"update:value":function($event){_vm.accountDialog=$event}}},'BankAccountDialog',{ iban: _vm.iban, title: _vm.$t('user.wallet.bank.detail-form-title') },false)):_vm._e()],1)]},proxy:true}])},'EqsCard',{
        eqsType: 'container',
        loading: _vm.loading,
        elClass: 'bank-account',
        color: 'transparent'
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }