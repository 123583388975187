<template>
    <div>
        <EqsAlert :text="$t('auth.email-confirmation.txt')" />
        <EqsCard v-bind="{ loading }" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            confirmInfos: null,
            loading: true
        };
    },
    async created() {
        const { user_id, timestamp, signature } = this.$route.query;
        this.confirmInfos = {
            user_id,
            timestamp,
            signature
        };
        try {
            await this.confirmEmail();
        } catch (e) {
        } finally {
            this.loading = false;
        }
    },
    methods: {
        async confirmEmail() {
            try {
                await this.$api.verifyRegistration(this.confirmInfos);
                await this.$ui.success(this.$t("auth.verify-email"));
            } catch (e) {
                this.$ui.error(e, "verify_registration");
            } finally {
                this.$router.push({ name: "auth" });
            }
        }
    }
};
</script>
