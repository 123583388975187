import Dinero from "dinero.js";
import Numeral from "numeral";
require("numeral/locales/fr");
import _ from "lodash";

export const currencies = {
    euro: {
        icon: "euro",
        mdIcon: "mdi-currency-eur",
        type: "EUR"
    },
    usd: {
        icon: "usd",
        mdIcon: "mdi-currency-usd",
        type: "USD"
    }
};

export const languages = {
    en: {
        iso639: "en",
        iso3166: "en-US"
    },
    fr: {
        iso639: "fr",
        iso3166: "fr-FR"
    }
};

function removeDecimalIfNeeded(val) {
    const emptyDecimal = [".00", ",00", " 00", ".0", ",0", " 0"];
    const match = emptyDecimal.find(str => val.includes(str));
    if (match) return _.replace(val, match, "");
    return val;
}

function percentOf(number, percent) {
    return (number * percent) / 100;
}

export class CurrencyService {
    constructor(language) {
        if (!language) {
            throw new Error("Missing `language` option");
        }
        this.selectedCurrency = currencies.euro;
        this.setLocale(language.current());
        Dinero.defaultCurrency = this.selectedCurrency.type;
        // keep in fr because need to adapt currencies depending of country,
        // here en give $ instead of €
        Numeral.locale("fr");
    }
    setLocale(language) {
        const locale = languages[language];
        Dinero.globalLocale = locale.iso3166;
        // Numeral.locale(locale.iso639);
    }
    dineroAmount(amount) {
        const converted = amount ? parseInt(parseFloat((amount * 100).toFixed(2))) : 0;
        return Dinero({
            amount: converted
        });
    }
    display(value, toFormat = "$0,0.00") {
        if (_.isNil(value)) {
            return value;
        }
        return this.dineroAmount(value).toFormat(toFormat);
    }
    getPercent(value, total) {
        return parseFloat(parseFloat((value / total) * 100).toFixed(2));
    }
    convertPercent(amount, percent) {
        const converted = percentOf(amount, percent);
        return converted;
    }
    convertAddPercent(amount, percent) {
        const amountFloat = parseFloat(amount);
        const percentFloat = parseFloat(percent);
        const resPercent = this.convertPercent(amountFloat, percentFloat);
        const res = resPercent + amountFloat;
        return res;
    }
    divide(amount, divisor) {
        return this.dineroAmount(amount).divide(divisor).toUnit();
    }
    add(amount1, amount2) {
        return this.dineroAmount(amount1).add(this.dineroAmount(amount2)).toUnit();
    }
    percent(coef, format = "0%") {
        return Numeral(coef).format(format);
    }
    abreviate(amount, isCurrency = true, currency = "EUR", toFormat = "0.0") {
        var converted = amount ? parseInt(parseFloat(amount).toFixed(2)) : 0;
        converted = Numeral(converted).format(`(${toFormat} a${isCurrency ? "$" : ""})`);
        if (currency == "USD") converted = converted.replace("€", "$");
        return removeDecimalIfNeeded(converted);
    }
    getValue(str) {
        return Numeral(str).value();
    }
    get(currency) {
        if (currency === "USD") return currencies.usd;
        return this.selectedCurrency;
    }
    displayWithCurrency(amount, currency, toFormat = "$0,0.00") {
        const selectedCurrency = _.find(currencies, ["type", currency]) || currencies.euro;
        const converted = amount ? parseInt(parseFloat((amount * 100).toFixed(2))) : 0;
        let amountStr = Dinero({
            amount: converted,
            currency: selectedCurrency.type
        }).toFormat(toFormat);

        // Remove US from US$
        return amountStr.replace("US", "");
    }
    // async convertToOtherCurrency(from, to, amount, exchange_rate) {
    //   // To convert need rates object format like:
    //   // rates = {
    //   //   'CURENCY1': decimal value,
    //   //   'CURENCY2': decimal value,
    //   // }

    //   const rates = {
    //     rates: exchange_rate,
    //   };

    //   return Dinero({ amount: amount, })
    //     .convert(to, { endpoint: new Promise((resolve) => resolve(rates)), })
    //     .then((val) => {
    //       return val.getAmount();
    //     });
    // }
}
