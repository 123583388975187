var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"confirmForgotPasswordForm",staticClass:"d-flex flex-column",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field px-0",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsPassword',_vm._b({attrs:{"id":"password","label":_vm.$t('forgot-password.label-password'),"value":_vm.formData.password,"el-class":"password-forgot-password"},on:{"update:value":function($event){return _vm.$set(_vm.formData, "password", $event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.confirmForgotPassword.apply(null, arguments)}}},'EqsPassword',{ hint: _vm.$t('forgot-password.password-hint'), loading: _vm.loading },false))],1),_c('v-col',{staticClass:"eqs-form-field px-0",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsPassword',_vm._b({attrs:{"id":"passwordConfirmation","label":_vm.$t('forgot-password.label-password-confirm'),"value":_vm.passwordConfirmation,"el-class":"confirm-forgot-password"},on:{"update:value":function($event){_vm.passwordConfirmation=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.confirmForgotPassword.apply(null, arguments)}}},'EqsPassword',{
                    rules: [_vm.identicalR],
                    hint: _vm.$t('forgot-password.password-hint'),
                    loading: _vm.loading
                },false))],1),(_vm.twofaInput)?_c('v-col',{staticClass:"eqs-form-field px-0",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsText',_vm._b({attrs:{"id":"twoFAToken","name":"token","type":"number","value":_vm.token,"persistent-hint":""},on:{"update:value":function($event){_vm.token=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.confirmForgotPassword.apply(null, arguments)}}},'EqsText',{
                    loading: _vm.loading,
                    counter: 6,
                    label: _vm.$t('login.two-fa.label'),
                    hint: _vm.$t('login.two-fa.hint'),
                    placeholder: _vm.$t('login.two-fa.placeholder')
                },false))],1):_vm._e(),_c('v-col',{staticClass:"eqs-form-field px-0"},[_c('EqsBtn',_vm._b({attrs:{"id":"btnConfirmForgotPassword","color":"primary","block":"","el-class":"btn-confirm-forgot-password"}},'EqsBtn',{
                    action: _vm.confirmForgotPassword,
                    loading: _vm.loading,
                    disabled: !_vm.validForm
                },false),[_vm._v(" "+_vm._s(_vm.$t("forgot-password.create-new"))+" ")])],1),_c('v-col',{staticClass:"eqs-form-field px-0",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('a',{staticClass:"text-link",attrs:{"el-class":"btn-login"},on:{"click":function($event){return _vm.$router.push('/auth')}}},[_vm._v(_vm._s(_vm.$t("auth.register.sign-in")))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }