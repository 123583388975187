<template>
    <div class="eqs-notifications">
        <v-menu
            ref="menu"
            offset-y
            nudge-bottom="2rem"
            origin="top bottom"
            :transition="!opened ? 'scroll-y-reverse-transition' : 'scroll-y-transition'"
            rounded="xl"
            content-class="eqs-notifications-menu"
            :left="!isMobile"
            :right="isMobile"
            :disabled="loading"
            :min-width="isMobile ? '100vw' : 'auto'"
            @input="updateMenu"
        >
            <template #activator="{ on }">
                <v-card
                    flat
                    :class="`eqs-notifications__container d-flex flex-row ${elClass}-activator`"
                    :color="$color.colors.menuColor"
                    v-on="on"
                >
                    <v-col align-self="center">
                        <EqsSkeleton v-bind="{ loading, type: 'button' }">
                            <v-badge
                                content=""
                                :value="$notifications.numberOfNewNotifications() > 0"
                                color="red"
                                overlap
                                dot
                                class="positive-z-index"
                            >
                                <v-icon
                                    :color="opened ? 'primary' : $color.colors.lightGrey"
                                    :size="isMobile ? '26px' : '30px'"
                                >
                                    mdi-bell
                                </v-icon>
                            </v-badge>
                        </EqsSkeleton>
                    </v-col>
                    <v-divider vertical :class="{ 'ml-1 mr-auto': numActive > 9 }" />
                </v-card>
            </template>
            <v-list
                three-line
                class="eqs-notifications-menu__list d-flex flex-column"
                :style="`width: ${isMobile ? '100%' : '350px'};`"
            >
                <v-subheader class="d-flex">
                    <v-row>
                        <v-col>
                            <EqsTextDisplay
                                eqs-type="subtitle"
                                v-bind="{
                                    value: $t('equisafe-ui.notifications.subtitle')
                                }"
                            />
                        </v-col>
                        <v-col v-if="items.length" class="d-flex justify-end align-center">
                            <router-link
                                style="text-decoration: none"
                                :to="{
                                    name: 'notifications',
                                    params: { id: $entity.represented().id || '' }
                                }"
                            >
                                {{ $t("equisafe-ui.notifications.see-list") }}
                            </router-link>
                        </v-col>
                    </v-row>
                </v-subheader>
                <v-divider class="mx-3" />
                <v-list-item-title v-if="!items.length" class="mx-5 my-3">
                    <EqsTextDisplay
                        class="ma-3"
                        eqs-type="caption"
                        v-bind="{ value: $t('equisafe-ui.notifications.no-notif') }"
                    />
                </v-list-item-title>
                <div class="eqs-notifications-menu__list__content d-flex flex-column">
                    <template v-for="(item, index) in items">
                        <v-list-item :key="index" :class="`${elClass}-${index}`">
                            <EqsAvatar
                                v-bind="{
                                    user: item.created_by,
                                    eqsType: 'list',
                                    noBadge: true
                                }"
                                class="mr-5"
                            />
                            <v-list-item-content>
                                <v-list-item-title class="d-flex justify-space-between wrap">
                                    <v-row class="fill-width">
                                        <v-col cols="8">
                                            <EqsTextDisplay
                                                class="text-wrap"
                                                style="word-break: break-word"
                                                eqs-type="subtitle"
                                                v-bind="{
                                                    value: item.created_by
                                                        ? item.created_by.name
                                                        : ''
                                                }"
                                            />
                                        </v-col>
                                        <v-col cols="4">
                                            <div class="d-flex justify-end align-center">
                                                <EqsTextDisplay
                                                    eqs-type="caption"
                                                    v-bind="{
                                                        value: $time.formated(item.updated_at)
                                                    }"
                                                />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ item.message }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="!item.shown" class="pa-0 ma-0">
                                    <div class="d-flex justify-end">
                                        <v-badge dot inline color="primary" />
                                    </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    :class="`d-flex pt-${
                                        item.shown || item.confirm || item.cancel ? '3' : '0'
                                    } ${elClass}-${index}-actions`"
                                >
                                    <EqsTextDisplay
                                        v-if="item.action_txt"
                                        eqs-type="caption"
                                        v-bind="{ value: item.action_txt }"
                                    />
                                    <EqsBtn
                                        v-if="item.cancel"
                                        color="primary"
                                        class="mr-3"
                                        outlined
                                        small
                                        v-bind="{
                                            action: () => cancel(item),
                                            elClass: `${elClass}-${index}-cancel`
                                        }"
                                    >
                                        {{ item.cancel.text }}
                                    </EqsBtn>
                                    <EqsBtn
                                        v-if="item.confirm"
                                        class="mr-3"
                                        color="primary"
                                        small
                                        v-bind="{
                                            action: () => confirm(item),
                                            elClass: `${elClass}-${index}-confirm`
                                        }"
                                    >
                                        {{ item.confirm.text }}
                                    </EqsBtn>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index < items.length - 1" :key="`${index}-divider`" />
                    </template>
                </div>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "InternalNotifications",
    components: {
        EqsBtn: () => import("../../../atoms/EqsBtn/EqsBtn.vue"),
        EqsSkeleton: () => import("../../../atoms/EqsSkeleton/EqsSkeleton.vue"),
        EqsAvatar: () => import("../../../atoms/EqsAvatar/EqsAvatar.vue"),
        EqsTextDisplay: () => import("../../../atoms/EqsTextDisplay/EqsTextDisplay.vue")
    },
    props: {
        elClass: {
            type: String,
            default: "eqs-notifications"
        },
        items: {
            type: Array,
            default: () => []
        },
        numActive: {
            type: Number,
            default: 0
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            opened: false
        };
    },
    computed: {
        isOnNotificationsRoute() {
            return this.$route && this.$route.name === "notifications";
        }
    },
    methods: {
        updateMenu(val) {
            this.opened = val;
            // here emit event from EqsNavigationBar
            this.$parent.$parent.$emit("notificationsMenu", val);
        },
        cancel(item) {
            item.cancel.fn();
            this.$refs.menu.isActive = false;
        },
        confirm(item) {
            item.confirm.fn();
            this.$refs.menu.isActive = false;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../../shared/styles/components/organisms/InternalNotifications.scss";

.positive-z-index {
    z-index: 999;
}
</style>
