var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsStepper',_vm._b({staticClass:"fill-height",attrs:{"height":"100%","value":_vm.step,"loading":_vm.loading || _vm.localLoading,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":_vm.backToSignatures},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c(item.is,_vm._b({tag:"component",attrs:{"editable":"","value":_vm.formData.proceduredocument_set,"loading":_vm.loading || _vm.localLoading},on:{"update:value":function($event){return _vm.$set(_vm.formData, "proceduredocument_set", $event)},"signBoxesInitiated":_vm.saveFormData},scopedSlots:_vm._u([{key:"actions",fn:function({
                    loading: templateDataLoading,
                    variables,
                    signatories,
                    insertContent
                }){return [_c('EqsBtn',_vm._b({staticClass:"px-2 py-2"},'EqsBtn',{
                        color: 'primary lighten-1',
                        loading: templateDataLoading || _vm.loading,
                        infoTxt: _vm.$t('signatures.procedure.custom-template.info-txt'),
                        action: () => (_vm.dialogSigners = true)
                    },false),[_vm._v(" "+_vm._s(_vm.$t("signatures.procedure.custom-template.action-txt"))+" ")]),_c('SignersVariablesDialog',_vm._b({attrs:{"value":_vm.dialogSigners},on:{"update:value":function($event){_vm.dialogSigners=$event}}},'SignersVariablesDialog',{
                        items: signatories,
                        variables,
                        insertContent,
                        loading: templateDataLoading || _vm.loading
                    },false))]}}],null,true)},'component',{
                signature_procedure: _vm.signature_procedure,
                schemaDouble: _vm.schemaDouble,
                state: _vm.state,
                formData: _vm.formData,
                deleteFunc: _vm.deleteProcedureDoc
            },false))]}}])},'EqsStepper',{ steps: _vm.steps, progress: _vm.progress, closeInfoTxt: _vm.closeInfoTxt },false))
}
var staticRenderFns = []

export { render, staticRenderFns }