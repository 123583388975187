export const PeapmeSchemaDouble = ctx => {
    return {
        peapmedata: {
            dataType: "object",
            isRequired: false
        }
    };
};

export const bankList = [
    "ABN Amro",
    "Allianz Banque",
    "Arkea Banque",
    "Axa Banque",
    "Banque Chaix",
    "Banque Courtois",
    "Banque Hottinguer",
    "Banque Indosuez",
    "Banque Laydernier",
    "Banque Leonardo",
    "Banque Martin Maurel",
    "Banque Michel Inchauspé (BAMI)",
    "Banque Palatine",
    "Banque Populaire",
    "Banque populaire Alsace Lorraine Champagne",
    "Banque populaire Aquitaine Centre Atlantique",
    "Banque populaire Auvergne Rhone Alpes",
    "Banque populaire Bourgogne Franche-Comté",
    "Banque populaire du Nord",
    "Banque populaire du Sud",
    "Banque Populaire Grand Ouest",
    "Banque populaire Méditérranée",
    "Banque populaire Occitane",
    "Banque populaire Rives de Paris",
    "Banque Populaire Val de France",
    "Banque Postale",
    "Banque Privée Européenne (BPE)",
    "Banque Privée Oudart",
    "Banque Saint Olive",
    "Banque Transatlantique",
    "Bcapital (BNP Paribas)",
    "BforBank",
    "BNP Banque Privée",
    "BNP Paribas",
    "BNP Paribas Antilles-Guyane",
    "BNP Paribas Nouvelle-Calédonie",
    "BNP Paribas Personal Finance",
    "BNP Paribas Réunion",
    "Boursedirect",
    "Boursorama",
    "BPE",
    "BPGO Anjou Banque Privée",
    "Bred",
    "BRED - Banque populaire",
    "CACEIS",
    "Caisse agricole Crédit Mutuel",
    "Caisse d’épargne - Alsace",
    "Caisse d’épargne - Aquitaine Poitou-Charentes",
    "Caisse d’épargne - Auvergne et du Limousin",
    "Caisse d’épargne - Bourgogne Franche-Comté",
    "Caisse d’épargne - Bretagne-Pays de Loire",
    "Caisse d’épargne - Côte d’Azur",
    "Caisse d’épargne - Hauts de France",
    "Caisse d’épargne - Ile-de-France",
    "Caisse d’épargne - Languedoc Roussillon",
    "Caisse d’épargne - Loire DrÙme Ardëche",
    "Caisse d’épargne - Loire-Centre",
    "Caisse d’épargne - Lorraine Champagne-Ardenne",
    "Caisse d’épargne - Midi-Pyrénées",
    "Caisse d’épargne - Normandie",
    "Caisse d’épargne - Picardie",
    "Caisse d’épargne - Rhône Alpes",
    "Caisse D’epargne Provence Alpes Corse",
    "Caisse des Dépôts",
    "Cholet Dupont",
    "CIC Banque privée",
    "CIC Banque Transatlantique",
    "CIC Est",
    "CIC Lyonnaise de Banque",
    "CIC Nord Ouest",
    "CIC Ouest",
    "CIC Sud Ouest",
    "Claresco",
    "CM CIC Securities",
    "CMNE Roubaix",
    "Cogefi",
    "Coral Consors",
    "Crédit agricole",
    "Crédit Agricole - Alpes-Provence",
    "Crédit Agricole - Alsace Vosges",
    "Crédit Agricole - Anjou et Maine",
    "Crédit Agricole - Aquitaine",
    "Crédit Agricole - Atlantique Vendée",
    "Crédit Agricole - Brie Picardie",
    "Crédit Agricole - Centre France",
    "Crédit Agricole - Centre Loire",
    "Crédit Agricole - Centre Ouest",
    "Crédit Agricole - Centre-Est",
    "Crédit Agricole - Champagne-Bourgogne",
    "Crédit Agricole - Charente-Maritime Deux-Sëvres",
    "Crédit Agricole - Charente-Périgord",
    "Crédit Agricole - Corse",
    "Crédit Agricole - CÙtes-d’Armor",
    "Crédit Agricole - Finistëre",
    "Crédit Agricole - Franche-Comté",
    "Crédit Agricole - Guadeloupe",
    "Crédit Agricole - Ille-et-Vilaine",
    "Crédit Agricole - Languedoc",
    "Crédit Agricole - Loire Haute-Loire",
    "Crédit Agricole - Lorraine",
    "Crédit Agricole - Martinique et Guyane",
    "Crédit Agricole - Morbihan",
    "Crédit Agricole - Nord de France",
    "Crédit Agricole - Nord Est",
    "Crédit Agricole - Nord Midi-Pyrénées",
    "Crédit Agricole - Normandie",
    "Crédit Agricole - Normandie-Seine",
    "Crédit Agricole - Paris et Ile-de-France",
    "Crédit Agricole - Provence-Côte d’Azur",
    "Crédit Agricole - Pyrénées-Gascogne",
    "Crédit Agricole - Réunion",
    "Crédit Agricole - Savoie",
    "Crédit Agricole - Sud RhÙne-Alpes",
    "Crédit Agricole - Sud-Méditerranée",
    "Crédit Agricole - Toulouse",
    "Crédit Agricole - Touraine et Poitou",
    "Crédit Agricole - Val de France",
    "Crédit coopératif",
    "Crédit du Nord",
    "Crédit industriel et commercial - CIC",
    "Crédit Lyonnais",
    "Crédit Mutuel Bretagne",
    "Crédit Mutuel Enseignant (CME)",
    "Crédit Mutuel-CIC (CM-CIC)",
    "Crédit Mutuel-CIC securities (CM-CIC securities)",
    "Delubac &amp; cie",
    "DNCA",
    "Easy Bourse",
    "Edmond de Rothschild",
    "eLCL",
    "Financière de l’Echiquier",
    "Financière Meeschaert",
    "Finaveo",
    "Fortuneo",
    "Hello Bank",
    "HSBC",
    "HSBC Privée",
    "La Banque Postale",
    "LCL",
    "LCL Banque Privée",
    "LCL Gestion de Fortune",
    "MangoPay",
    "Milleis Banque",
    "Monabanq",
    "Montségur Finance",
    "Natixis",
    "Neuflize OBC",
    "Nortia",
    "Oddo",
    "Oddo Banque Privée",
    "Portzamparc",
    "Qonto",
    "RBC",
    "Revolut",
    "Saxo Banque",
    "Socfim",
    "Société Générale",
    "Société Générale Banque Privée",
    "Société marseillaise de crédit",
    "SwissLife Banque Privée",
    "UBS",
    "UBS Gestion de fortune",
    "Wormser Frères"
];

// bank_pea: {
//     dataType: "string",
//     isRequired: false
// },
// no_pea: {
//     dataType: "string",
//     isRequired: false
// },
// iban_pea: {
//     dataType: "string",
//     isRequired: false
// },
// bic_pea: {
//     dataType: "string",
//     isRequired: false
// },
// rib_pea: {
//     dataType: "file",
//     isRequired: false
// },
// bank_peapme: {
//     dataType: "string",
//     isRequired: false
// },
// no_peapme: {
//     dataType: "string",
//     isRequired: false
// },
// iban_peapme: {
//     dataType: "string",
//     isRequired: false
// },
// bic_peapme: {
//     dataType: "string",
//     isRequired: false
// },
// rib_peapme: {
//     dataType: "file",
//     isRequired: false
// }
