<template>
    <!-- <v-container fluid class="days-left" :style="getStyle">
        <div id="progress-text" pa-0 ma-0>
            <div class="progress-text-content text-center">
                {{ daysTxt }}
            </div>
        </div>
        <progress :value="daysFromStart" :max="max" />
    </v-container> -->
    <v-container fluid :style="getStyle">
        <v-progress-linear color="#A0A0A0" :value="valueCalculated" rounded height="18">
            <template #default>
                <span class="progress-text">
                    {{ daysTxt }}
                </span>
            </template>
        </v-progress-linear>
    </v-container>
</template>

<script>
export default {
    name: "DaysLeft",
    props: {
        startDate: {
            type: String,
            required: true
        },
        endDate: {
            type: String,
            required: true
        },
        forceEnded: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            status: {
                pending: {
                    colordeg: "125deg",
                    color1: "#f17334",
                    color2: "#fcbe2f",
                    txtcolor: "white"
                },
                started: {
                    colordeg: "306deg",
                    color1: "#33cbff",
                    color2: "#336aec",
                    txtcolor: "#0D47A1"
                },
                finished: {
                    txtcolor: "#4CAF50"
                }
            }
        };
    },
    computed: {
        valueCalculated() {
            const currentDays = parseFloat(this.daysFromStart);
            const maxDays = parseFloat(this.max);
            const percentDone = parseInt((currentDays / maxDays) * 100.0);
            if (percentDone >= 99) return 100;
            if (percentDone <= 1) return 1;
            return percentDone;
        },
        max() {
            return this.$time.differenceInDays(this.endDate, this.startDate);
        },
        daysFromStart() {
            if (this.forceEnded) return this.max;
            return this.$time.differenceInDays(Date.now(), this.startDate);
        },
        daysTxt() {
            if (this.forceEnded) return this.$t("label.finished");
            if (!this.$time.isPast(this.startDate)) {
                return `${this.$t("label.starts")} ${this.$time.fromNowHR(this.startDate)}`;
            } else {
                return !this.finished
                    ? `${this.$t("label.ends-in")} ${this.daysLeft}`
                    : this.$t("label.finished");
            }
        },
        getStyle() {
            const status = this.getStatus();
            return {
                "--color1": status.color1,
                "--color2": status.color2,
                "--colordeg": status.colordeg,
                "--txt-color": status.txtcolor
            };
        },
        pending() {
            return this.daysFromStart < 0;
        },
        finished() {
            return this.daysFromStart >= this.max;
        },
        daysLeft() {
            let res = this.$time.fromNowHR(this.endDate);
            return res;
        }
    },
    methods: {
        getStatus() {
            if (this.pending) return this.status.pending;
            return !this.finished ? this.status.started : this.status.finished;
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.v-progress-linear) {
    border-radius: 16px !important;
}
:deep(.v-progress-linear__determinate) {
    background: linear-gradient(-225deg, #b9b6e5 0%, #7de2fc 100%);
}
</style>
