<template>
    <EqsCard
        class="d-flex flex-column fill-height"
        :title="company ? company.name : ''"
        v-bind="{
            loading: loading || localLoading,
            height: '100%',
            closeInfoTxt: $t('secondary-market-details.come-back')
        }"
        closable
        eqs-type="overflow"
        @close="() => $router.push({ name: 'secondary-market', params: $route.params })"
    >
        <template #default>
            <v-row class="justify-left d-flex">
                <v-col :cols="$reactive.isDesktop() ? 6 : 12">
                    <CompanyCard
                        v-bind="{
                            company
                        }"
                    />
                </v-col>
                <v-col
                    v-if="youtubeLink"
                    :cols="$reactive.isDesktop() ? 6 : 12"
                    style="min-height: 420px"
                >
                    <iframe
                        style="
                            border-radius: 0.625rem;
                            box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11),
                                0 1px 2px rgba(90, 97, 105, 0.12), 0 1px 1px rgba(90, 97, 105, 0.06),
                                0 1px 2px rgba(90, 97, 105, 0.1);
                        "
                        width="100%"
                        height="100%"
                        :src="youtubeLink"
                        frameborder="0"
                        allow="autoplay; encrypted-media"
                        allowfullscreen
                    />
                </v-col>
            </v-row>
            <v-row class="px-3">
                <EqsTabs v-bind="{ tabs }" :value.sync="tab">
                    <template #default="{ item }">
                        <component
                            :is="item.is"
                            v-bind="{
                                ...item.props
                            }"
                        />
                    </template>
                </EqsTabs>
            </v-row>
        </template>
    </EqsCard>
</template>

<script>
import CompanyCard from "./CompanyCard";
import MarketDetails from "./MarketDetails";
import Team from "./Team";
import MarketDocuments from "./MarketDocuments";

export default {
    name: "SecondaryMarketDetails",
    components: {
        CompanyCard,
        MarketDetails,
        Team,
        MarketDocuments
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tab: "details",
            company: {},
            tabs: [],
            localLoading: false,
            businesses: [],
            allCopmany: [],
            prices: {},
            youtubeLink: ""
        };
    },
    async created() {
        this.localLoading = true;
        // TODO uncomment once api call ready
        try {
            if (!this.$route.params.company) await this.fetchData(this.$route.params.id);
            else this.company = this.$route.params.company;

            await this.fetchGraphData();
            this.getTabs();

            const resYoutube = await this.$api.getSecondaryMarketPrefVideo(this.company.id);
            if (resYoutube && resYoutube.data && resYoutube.data.length > 0) {
                this.youtubeLink = resYoutube.data[0].video;
            } else {
                this.youtubeLink = null;
            }
        } catch (e) {
            //TODO error gestion
            console.error({ e });
        } finally {
            this.localLoading = false;
        }
    },
    methods: {
        async fetchData() {
            try {
                const res = await this.$api.readSecondaryMarketSellOrderList();
                this.businesses = res.data.map(x => {
                    let val = {
                        businesses:
                            x.shareledger.type +
                            " " +
                            x.shareledger.company.name +
                            " " +
                            x.shareledger.name,
                        numberInvestors: x.quantity,
                        indicativeValuation: x.price,
                        change: "10%",
                        details: {
                            name: x.shareledger.company.name,
                            summary: "shareledger.description",
                            description: "",
                            team: "",
                            investors: "",
                            shareholder: "",
                            documents: "",
                            restrictions: "",
                            quantity: x.quantity,
                            indicativeValuation: x.price,
                            data: x,
                            sellOrderId: x.id,
                            url: x.url
                        }
                    };
                    return val;
                });
            } catch (e) {
                this.$ui.error(e, "secondary_market_fetch_sell_offers");
            } finally {
                if (this.businesses.length > 0) this.orderByCompany();
            }
        },
        async fetchGraphData() {
            this.prices = {};
            let datasets = [];
            let labels = [];
            const resa = await this.$api.getPrices(this.company.id);
            for (let t of resa.data) {
                let name = t.name;
                let dataSet = {
                    label: name,
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: this.$color.colorFromHash(name),
                    data: []
                };

                let d = t.prices.reverse();
                d.forEach(element => {
                    labels.push(this.convertDate(element.date));
                    dataSet.data.push(parseFloat(element.val));
                });
                datasets.push(dataSet);
            }
            this.prices = {
                labels: labels,
                datasets: datasets
            };
            console.log(this.prices);
        },
        orderByCompany() {
            this.businesses.forEach(element => {
                let company = element.details.data.shareledger.company;
                if (company.id == this.$route.params.id) {
                    if (!this.company.orders) {
                        this.company = company;
                        this.company.orders = [];
                    }

                    this.company.orders.push(element);
                }
            });
        },
        convertDate(date) {
            return this.$time.formated(date);
        },
        getTabs() {
            this.tabs = [
                {
                    id: "details",
                    title: this.$t("secondary-market-details.market.title"),
                    item: {
                        is: "MarketDetails",
                        props: {
                            orders: this.company.orders,
                            prices: this.prices
                        }
                    }
                },
                {
                    id: "team",
                    title: this.$t("secondary-market-details.team.title"),
                    item: {
                        is: "Team",
                        props: {
                            company: this.company
                        }
                    }
                },
                {
                    id: "docs",
                    title: this.$t("secondary-market-details.documents.title"),
                    item: {
                        is: "MarketDocuments",
                        props: {
                            company: this.company
                        }
                    }
                }
            ];
        }
    }
};
</script>
