<template>
    <v-container fluid>
        <DataTablePaginated
            :items="items"
            search="message"
            sort-by="created_at"
            sort-desc
            :headers="headers"
            manage-route
            :loading="loading || localLoading"
            @update="fetchNotifs"
        >
            <template #[`item.shown`]="{ item }">
                <div v-if="!item.shown" class="d-flex justify-center">
                    <v-badge dot inline color="primary" />
                </div>
            </template>
            <template #[`item.created_at`]="{ value }">
                {{ $time.formated(value) }}
            </template>
            <template #[`item.created_by.name`]="{ item, index }">
                <v-list-item :el-class="`eqs-notifications-table-${index}`" class="pa-0">
                    <EqsAvatar
                        v-bind="{ user: item.created_by, eqsType: 'list', noBadge: true }"
                        class="pr-5"
                    />
                    <v-list-item-content class="pa-0 pl-3">
                        <v-list-item-title class="pt-3">
                            <EqsTextDisplay
                                eqs-type="subtitle"
                                v-bind="{
                                    value: item.created_by ? item.created_by.name : ''
                                }"
                            />
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-wrap">
                            {{ item.message }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="pt-3 text-wrap">
                            <EqsTextDisplay
                                v-if="item.action_txt"
                                eqs-type="caption"
                                v-bind="{ value: item.action_txt }"
                            />
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template #[`item.actions`]="{ item, index }">
                <v-row class="d-flex justify-center">
                    <v-col>
                        <EqsBtn
                            v-if="item.cancel"
                            color="primary"
                            outlined
                            small
                            v-bind="{
                                action: item.cancel.fn,
                                elClass: `eqs-notifications-table-${index}-cancel`
                            }"
                        >
                            {{ item.cancel.text }}
                        </EqsBtn>
                    </v-col>
                    <v-col>
                        <EqsBtn
                            v-if="item.confirm"
                            color="primary"
                            small
                            v-bind="{
                                action: item.confirm.fn,
                                elClass: `eqs-notifications-table-${index}-confirm`
                            }"
                        >
                            {{ item.confirm.text }}
                        </EqsBtn>
                    </v-col>
                </v-row>
            </template>
        </DataTablePaginated>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DataTablePaginated from "@/components/views/DataTablePaginated";

export default {
    name: "Notifications",
    components: { DataTablePaginated },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            items: { results: [] },
            localLoading: false,
            headers: [
                {
                    value: "shown",
                    // text: this.$t("label.active"),
                    filterable: false,
                    sortable: false,
                    align: "center"
                },
                {
                    value: "created_at",
                    text: this.$t("label.date"),
                    filterable: false,
                    sortable: true
                },
                {
                    value: "created_by.name",
                    sortable: false,
                    filterable: false
                },
                {
                    text: this.$t("label.actions"),
                    value: "actions",
                    filterable: false,
                    sortable: false,
                    align: "center"
                }
            ]
        };
    },
    computed: mapGetters(["notificationsLoading"]),
    methods: {
        async fetchNotifs(queries) {
            this.localLoading = true;
            const res = await this.$api.getNotifications({
                entity_represented_id: this.$store.getters.currentRole.entity_represented.id,
                ...queries
            });
            this.items = res.data;
            this.localLoading = false;

            if (this.items.results?.length) {
                const toUpdate = {
                    notifications: this.items.results.map(notif => {
                        return { id: notif.id };
                    }),
                    options: {
                        shown: true
                    }
                };
                try {
                    const res = this.$api.updateNotifications(toUpdate);
                    this.$notifications.setNumberOfNewNotifications(res?.data?.count || 0);
                } catch (e) {
                    console.error("Error setting notification as shown", e);
                }
            }
        }
    }
};
</script>
