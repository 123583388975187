export { default as share_type } from "./shareType";

export class ShareledgerService {
    constructor(api) {
        if (!api) throw new Error("Missing `api` option");

        this.api = api;
    }

    async getBalances(companyId, by = "") {
        let balances = null;
        try {
            const res = await this.api.readBalanceList({
                company_id: companyId,
                by: by
            });
            balances = res.data;
        } catch (e) {
            console.error(e);
            window.app.$ui.error(e, "company_shareledgers_dashboard");
        }

        return balances;
    }

    async getCompanyShareledgersDashboard(companyId) {
        // todo: filter just like in Wallet.vue ?
        let shareledgers = [];
        let shareholders = [];
        let res = await Promise.all([
            await this.getBalances(companyId, "shareholder"),
            await this.getBalances(companyId, "shareledger")
        ]);

        if (res[0]) shareholders = res[0].by_shareholder;
        if (res[1]) shareledgers = res[1].by_shareledger;

        const shareledgersWithSecurityTokenContracts = Object.values(shareledgers)
            .filter(ledger => ledger.state === "done")
            .map(ledger => {
                ledger.shareholders = ledger.shareholders.filter(shareholder => {
                    return "balance" in shareholder && shareholder.balance > 0;
                });
                return ledger;
            });

        return {
            shareledgers: shareledgersWithSecurityTokenContracts,
            shareholders
        };
    }
}
