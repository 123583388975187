<template>
    <EqsStepper
        class="fill-height"
        height="100%"
        :value.sync="step"
        :loading="loading || localLoading"
        v-bind="{ steps, progress, closeInfoTxt }"
        closable
        @close="comeBackToProjects"
    >
        <template #default="{ item }">
            <div class="eqs-main-container margin-auto">
                <component
                    :is="item.is"
                    editable
                    :schema-double.sync="schemaDouble"
                    v-bind="{
                        formData,
                        loading: loading || localLoading,
                        isCompany,
                        cipForm,
                        state
                    }"
                />
            </div>
        </template>
    </EqsStepper>
</template>

<script>
import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";
import * as schemas from "./schemas";

import IntroPage from "./IntroPage";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import Form4 from "./Form4";
import Form4bis from "./Form4bis";
import EndPage from "./EndPage";

export default {
    name: "RiskAversion",
    components: {
        IntroPage,
        Form1,
        Form2,
        Form3,
        Form4,
        Form4bis,
        EndPage
    },
    mixins: [stepperFormMixins],
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        cornerId: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            cipForm: {},
            localLoading: false,
            step: 0,
            formData: {},
            closeInfoTxt: this.cornerId
                ? this.$t("risk-aversion.come-back-to-corner")
                : this.$t("risk-aversion.come-back-to-projects")
        };
    },
    computed: {
        entity() {
            return this.$entity.represented();
        },
        isCompany() {
            return this.entity.is_company;
        },
        state() {
            return this.$entity.isPsfp() ? "success" : "error";
        },
        actionsLastStep() {
            if (this.state === "success") {
                if (this.cornerId) {
                    return {
                        noBack: true,
                        next: {
                            txt: this.$t("risk-aversion.finalization.next-go-to-corners"),
                            fn: () => this.comeBackToProjects()
                        }
                    };
                }
                const projectId = this.$route.query.projectId;
                return {
                    noBack: true,
                    next: {
                        txt: projectId
                            ? this.$t("risk-aversion.finalization.next-continue-to-project")
                            : this.$t("risk-aversion.finalization.next-go-to-projects"),
                        fn: () => this.comeBackToProjects()
                    }
                };
            }
            return {
                next: {
                    txt: this.$t("risk-aversion.finalization.next-redo-quiz"),
                    fn: () => this.step--
                },
                back: {
                    txt: this.closeInfoTxt,
                    fn: () => this.comeBackToProjects()
                }
            };
        },
        steps() {
            let steps = [];
            steps.push({
                title: "CGU",
                item: {
                    is: "IntroPage"
                },
                schemaDouble: schemas.introPage(this),
                next: {
                    fn: this.updatePsfpForm
                },
                back: {
                    txt: this.closeInfoTxt,
                    fn: this.comeBackToProjects
                }
            });
            steps.push({
                title: "Questionnaire investisseur",
                item: {
                    is: "Form4"
                },
                schemaDouble: schemas.form4(this),
                next: {
                    fn: () => this.updatePsfpForm(true)
                },
                back: {
                    txt: "Retour",
                    fn: () => this.step--
                }
            });
            if (this.showSimulation()) {
                steps.push({
                    title: "Connaissances de l’Investisseur",
                    item: {
                        is: "Form1"
                    },
                    schemaDouble: schemas.form1(this),
                    next: {
                        fn: this.updatePsfpForm
                    },
                    back: {
                        txt: "Retour",
                        fn: () => this.step--
                    }
                });
                steps.push({
                    title: "Objectifs et situation financière",
                    item: {
                        is: "Form2"
                    },
                    schemaDouble: schemas.form2(this),
                    next: {
                        fn: this.updatePsfpForm
                    },
                    back: {
                        txt: "Retour",
                        fn: () => this.step--
                    }
                });
                steps.push({
                    title: "Compréhension des risques",
                    item: {
                        is: "Form3"
                    },
                    schemaDouble: schemas.form3(this),
                    next: {
                        fn: this.updatePsfpForm
                    },
                    back: {
                        txt: "Retour",
                        fn: () => this.step--
                    }
                });

                steps.push({
                    title: "Simulation",
                    item: {
                        is: "Form4bis"
                    },
                    schemaDouble: schemas.form4bis(this),
                    next: {
                        fn: () => this.updatePsfpForm(true)
                    },
                    back: {
                        txt: "Retour",
                        fn: () => this.step--
                    }
                });
            }
            steps.push({
                title: "Résultats",
                item: {
                    is: "EndPage"
                },
                next: {
                    txt: "Commencer a investir",
                    fn: this.comeBackToProjects
                },
                back: {
                    txt: "Retour",
                    fn: () => this.step--
                }
            });
            return steps;
        }
    },
    watch: {
        step: {
            immediate: true,
            async handler(step) {
                if (this.steps[step].schemaDouble) {
                    this.schemaDouble = this.steps[step].schemaDouble;
                    this.buildFormData(this.schemaDouble, this.cipForm);
                    this.saveFormData();
                }
            }
        }
    },
    async created() {
        try {
            this.localLoading = true;
            let res = await this.$api.getPsfpForm();
            if (res.data.length === 0) {
                res = await this.$api.createPsfpForm();
                this.cipForm = res.data;
            } else {
                this.cipForm = res.data[0];
            }
            this.step = this.getLastFormStep(this.cipForm);
            if (this.showSimulation && this.steps[this.step].item.is === "EndPage") {
                // On peut mettre a jour sa simulation
                this.step -= 1;
            }
            this.buildFormData(this.schemaDouble, this.cipForm);
            this.saveFormData();
        } catch (e) {
            this.$ui.error(e, "risk_aversion_get_personal_form");
        } finally {
            this.localLoading = false;
        }
    },
    methods: {
        showSimulation() {
            return typeof this.cipForm.p4q1 === "boolean" && !this.cipForm.is_warned;
        },
        comeBackToProjects() {
            this.$router
                .push({ name: "corner-detail", params: this.$route.params })
                .catch(() => {});
        },
        async updatePsfpForm(end = false) {
            try {
                this.localLoading = true;
                const tmpFormData = this.formatFormData(this.schemaDouble);
                let res = await this.$api.updatePsfpForm(this.cipForm.id, tmpFormData);
                this.cipForm = { ...res.data };
                if (end === true) {
                    await this.$role.updateMyCurrentRole(this.$entity.represented().id);
                }
                this.step++;
            } catch (e) {
                this.handleError(e, "risk_aversion_update_quiz_form");
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.root-step-container) > * {
    margin-bottom: 8px;
}
</style>
