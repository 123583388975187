<template>
    <div>
        <v-flex v-if="display" :style="`height:${size}`">
            <v-flex v-for="(item, index) in items" :key="index" class="ml-3 mr-3 mt-0 mb-0">
                <v-tooltip v-if="item.url" open-on-hover open-on-focus light bottom>
                    <template #activator="{ on }">
                        <v-img
                            slot="activator"
                            :src="platforms[item.platform].icon"
                            :width="size"
                            style="cursor: pointer"
                            v-bind="{ ...$attrs, color }"
                            @click="window.open(item.url, '_blank')"
                            v-on="{ ...$listeners, on }"
                        />
                    </template>
                    {{ item.url }}
                </v-tooltip>
            </v-flex>
        </v-flex>
    </div>
</template>

<script>
export default {
    name: "GlobalPlatformsLinks",
    props: {
        size: {
            type: String,
            default: "24px"
        },
        color: {
            type: String,
            default: "black"
        },
        display: {
            type: Boolean,
            default: true
        },
        items: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            window,
            platforms: {
                linkedin: {
                    icon: "/icons/linkedin-logo.svg"
                }
            }
        };
    }
};
</script>
