<template>
    <div :class="`eqs-file-input ${elClass}-container`" :style="getStyle">
        <EqsLabel
            :el-class="elClass"
            v-bind="{
                value: label,
                labelHeight,
                editable,
                infoTxt,
                isRequired
            }"
        >
            <EqsSkeleton
                v-if="editable && !(!multiple && valueProxy)"
                v-bind="{ loading, type: 'sentences' }"
            >
                <EqsUpload
                    :value.sync="valueProxy"
                    v-bind="{
                        ...$attrs,
                        errorMessages: errorText || errorMessages,
                        errorCount,
                        accept,
                        sizeMax,
                        multiple,
                        loading: localLoading,
                        disabled: disabled || localLoading,
                        placeholder: getPlaceholder,
                        isRequired,
                        others,
                        elClass
                    }"
                    @update:errorMessages="val => (errorText = val)"
                    @isLoading="isLoading => (localLoading = isLoading)"
                >
                    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                        <slot :name="name" v-bind="slotData" />
                    </template>
                    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                </EqsUpload>
            </EqsSkeleton>
            <EqsList
                v-if="valueProxy && valueProxy.length"
                :class="`eqs-file-input__list ${elClass}-list`"
                :items.sync="valueProxy"
                v-bind="{
                    errorMessages: errorText || errorMessages,
                    errorCount,
                    rules: rulesRequired,
                    loading: localLoading || loading
                }"
                @update:items="() => (localLoading = false)"
            >
                <template #item="props">
                    <EqsFileActions
                        v-bind="{
                            ...props,
                            elClass,
                            editable,
                            deleteFunc: deleteFunction,
                            deleteFile
                        }"
                        @isLoading="isLoading => (localLoading = isLoading)"
                    />
                </template>
                <template #item-append="{ item, index }">
                    <EqsAlert
                        v-if="item.lw && editable"
                        v-bind="{ ...item.lw, elClass: `${elClass}-${index}-status` }"
                        outlined
                        :small="$reactive.isMobile()"
                        label
                        class="font-weight-bold"
                    />
                </template>
            </EqsList>
            <EqsTextDisplay v-else-if="!editable" v-bind="{ value: '-' }" />
        </EqsLabel>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsFileInput.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin, filesUploadMixin } from "../../../mixins/inputMixin";
import { getLWStatusFile } from "../../../globals/lemonway";

import isEmpty from "lodash/isEmpty";

export default {
    name: "EqsFileInput",
    components: {
        EqsLabel: () => import("../../atoms/EqsLabel/EqsLabel.vue"),
        EqsSkeleton: () => import("../../atoms/EqsSkeleton/EqsSkeleton.vue"),
        EqsList: () => import("../EqsList/EqsList.vue"),
        EqsUpload: () => import("../../atoms/EqsUpload/EqsUpload.vue"),
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue"),
        EqsFileActions: () => import("../EqsFileActions/EqsFileActions.vue"),
        EqsAlert: () => import("../EqsAlert/EqsAlert.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin, filesUploadMixin],
    props: {
        elClass: {
            type: String,
            default: ""
        },
        multiple: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        others: {
            type: Object,
            default: () => {}
        },
        accept: {
            type: String,
            default: "image/png,image/jpeg,image/jpg,.pdf,.xls,.xlsx,.heic,.heif"
        },
        deleteFunc: {
            type: Function,
            default: null
        },
        deleteFile: {
            type: Boolean,
            default: true
        },
        errorMessages: {
            type: [String, Array],
            default: ""
        },
        errorCount: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            localLoading: false,
            errorText: ""
        };
    },
    computed: {
        getPlaceholder() {
            if (this.multiple && this.valueProxy && this.valueProxy.length)
                return this.$t("equisafe-ui.file-input.placeholder-multiple-append");
            return (
                this.placeholder ||
                (!this.multiple
                    ? this.$t("equisafe-ui.file-input.placeholder-singular")
                    : this.$t("equisafe-ui.file-input.placeholder-multiple"))
            );
        },
        deleteFunction() {
            return this.deleteFunc || this.$api.deleteDocument;
        },
        valueProxy: {
            get() {
                let res = null;
                if (this.multiple) res = this.value;
                else res = !!this.value ? [this.value] : null;
                return !!res
                    ? res.map(item => {
                          item.title = `- ${item.name}`;
                          // TODO Need to export logic of Lemonway
                          if (!item.operation) item.lw = getLWStatusFile(this, item);
                          return item;
                      })
                    : res;
            },
            set(val) {
                if (isEmpty(val) && !(val instanceof File)) {
                    this.$emit("update:value", this.multiple ? val : null);
                } else {
                    const res = this.multiple || !val[0] ? val : val[0];
                    this.$emit("update:value", res);
                }
            }
        }
    },
    created() {
        if (this.validateValueProp()) {
            this.errorText = this.sizeError(this.valueProxy);
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsFileInput.scss";
</style>
