import { requiredR } from "../rules";
import { toType, anyFieldChanged } from "../../../shared/util";
import { MAX_SIZE_FILE } from "../../../shared/constants";

import isEmpty from "lodash/isEmpty";

export const inputFormMixin = {
    props: {
        rules: {
            type: Array,
            default: () => []
        },
        /*
      This field basicRules is used for decorator components
      who needs to set their own rules
    */
        basicRules: {
            type: Array,
            default: () => []
        },
        labelHeight: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        infoTxt: {
            type: String,
            default: ""
        },
        isRequired: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        eqsSize: {
            type: String,
            default: "md"
        },
        locked: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handlerForm(val) {
            this.$emit("update:value", val || "");
        },
        requiredR
    },
    computed: {
        /*
      This computed value 'valueString' is used since vuetify,
      in case of clearance of a value, sets the
      value to null instead of an empty string.
      This is used as a proxy to force value to be typed as
      a string.
    */
        valueString() {
            const typeValue = typeof this.value;
            if (typeValue === "number") {
                return this.value;
            } else if (typeValue !== "string" && typeValue !== "undefined" && this.value !== null) {
                throw Error("wrong type defined in valueString");
            }
            return this.value || "";
        },
        editableData() {
            return String(this.valueString);
        },
        rulesRequired() {
            const rules = [...this.basicRules, ...this.rules];
            if (this.isRequired) rules.push(this.requiredR);
            return rules;
        }
    }
};

export const filesUploadMixin = {
    props: {
        sizeMax: {
            type: Number,
            default: MAX_SIZE_FILE
        },
        value: {
            type: [Object, Array, File],
            default: null
        }
    },
    computed: {
        /* here need to handle the case of v-file-input that always
    return an array as a value, in case it's not multiple we want
    the file as an object and otherwise as an array
    */
        valueProxy: {
            get() {
                let res = null;
                if (this.multiple) res = this.value;
                else res = !!this.value ? [this.value] : null;
                return !!res
                    ? res.map(item => {
                          item.title = `- ${item.name}`;
                          return item;
                      })
                    : res;
            },
            set(val) {
                if (isEmpty(val)) {
                    this.$emit("update:value", this.multiple ? val : null);
                } else {
                    const res = this.multiple || !val[0] ? val : val[0];
                    this.$emit("update:value", res);
                }
            }
        }
    },
    methods: {
        sizeError(files) {
            const error = files.some(file => file.size > this.sizeMax);
            return error
                ? `${this.$t("file-input.size-limit")} :
          ${(parseInt(this.sizeMax) / (1024 * 1024)).toFixed(2)} Mo`
                : "";
        },
        validateValueProp() {
            const typeOfValue = toType(this.value);
            if (typeOfValue === "array" && !this.multiple) {
                console.error("value is of type Array and multiple prop is set at false !");
                return false;
            } else if (typeOfValue === "object" && this.multiple) {
                console.error("value is of type Object and multiple prop is set at true !");
                return false;
            }
            return isEmpty(this.value) ? false : true;
        }
    }
};

export const crudActionsMixin = {
    watch: {
        formData: {
            handler(val) {
                if (val && this.editable && !this.loading) {
                    this.hasChanged = this.anyFieldChanged(this.item, val, this.valToCmp);
                }
            },
            deep: true
        }
    },
    methods: {
        anyFieldChanged
    }
};
