<template>
    <div v-if="isAdmin && localStatus?.status === 'waiting'" class="wrapper-onvalidation">
        <div
            class="wrapper-on-validation-success wrapper-hover"
            @click.stop="validateCGP('Accepted')"
        >
            <v-icon color="success">mdi-check</v-icon>
        </div>
        <div
            class="wrapper-on-validation-error wrapper-hover"
            @click.stop="validateCGP('Rejected')"
        >
            <v-icon color="error">mdi-close</v-icon>
        </div>
    </div>
    <v-chip v-else outlined>
        <div v-if="apiLoading">
            <v-progress-circular indeterminate />
        </div>
        <div v-else>
            <div v-if="localStatus" class="icon-with-text">
                <v-icon :color="localStatus.color" class="custom-left-icon">{{
                    localStatus.icon
                }}</v-icon>
                <span>{{ localStatus.text }}</span>
            </div>
        </div>
    </v-chip>
</template>

<script>
import { accountActivationStatus } from "@/components/views/user/clients/tools.js";

export default {
    name: "StatusComponent",
    props: {
        // eslint-disable-next-line vue/require-default-prop
        target: {
            type: Object,
            required: true
        },
        isAdmin: {
            type: Boolean,
            required: true
        }
    },
    emits: "updated",
    data() {
        return {
            localStatus: null,
            apiLoading: false
        };
    },
    mounted() {
        this.localStatus = accountActivationStatus(this.target.attributes[0].validation_status);
    },
    methods: {
        async validateCGP(wantedStatus) {
            if (this.apiLoading === true) {
                return;
            }
            this.apiLoading = true;
            try {
                const resp = await this.$api.patchEntityAttributeStatus(
                    this.target.attributes[0].id,
                    wantedStatus
                );
                this.localStatus = accountActivationStatus(resp.data.validation_status);
                this.$emit("updated", this.localStatus);
            } catch (error) {
                this.$ui.error(`${error.statusText}`);
            }
            this.$store.commit("SET_AFFILIATION_REFRESH_NEXT_PAGE", true);
            this.apiLoading = false;
        }
    }
};
</script>

<style lang="scss">
.icon-with-text {
    > span {
        vertical-align: middle;
    }

    .custom-left-icon {
        vertical-align: middle;
        margin-right: 6px;
    }
}

.wrapper-on-validation-error {
    border: 1px solid rgb(220, 53, 69);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.wrapper-onvalidation {
    display: flex;
    gap: 10px;
}
.wrapper-on-validation-success {
    border: 1px solid #2e9e49;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
}
.wrapper-hover {
    transition: border-radius 0.3s ease;
    &:hover {
        border-radius: 50%;
    }
}
</style>
