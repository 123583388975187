<template>
    <v-container fluid>
        <h3 class="text-h4 mb-6">Informations relative à l'assemblée</h3>
        <v-row>
            <v-col cols="2" class="font-weight-black">Date</v-col>
            <v-col class="text-right">{{ agItem.date }}</v-col>
            <v-spacer />
            <v-col cols="2" class="font-weight-black">Organe</v-col>
            <v-col class="text-right">{{ organTypeText }}</v-col>
        </v-row>
        <v-row>
            <v-col cols="2" class="font-weight-black">Adresse</v-col>
            <v-col class="text-right">{{ agItem.address }}</v-col>
            <v-spacer />
            <v-col cols="2" class="font-weight-black">Mode de décision</v-col>
            <v-col class="text-right">{{ agModeText }}</v-col>
        </v-row>
        <v-row>
            <v-col cols="2" class="font-weight-black">Président</v-col>
            <v-col class="text-right">Manu Blink</v-col>
            <v-spacer />
            <v-col cols="2" class="font-weight-black">Statut</v-col>
            <v-col class="text-right">En cours</v-col>
        </v-row>
        <v-row>
            <v-col cols="2" />
            <v-col />
            <v-spacer />
            <v-col cols="2" class="font-weight-black">Présence</v-col>
            <v-col class="text-right">
                <v-chip :color="presenceStyle[agItem.member.presence].color" label outlined>
                    {{ presenceStyle[agItem.member.presence].text }}
                </v-chip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { getOrganText, getModeText } from "../helpers";

export default {
    name: "AgDetailInformation",
    components: {},
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        agItem: {
            type: Object,
            required: true
        }
    },
    computed: {
        presenceStyle() {
            return {
                present: {
                    color: "success",
                    text: "Confirmé"
                },
                represented: {
                    color: "primary",
                    text: "Représenté"
                },
                absent: {
                    color: "error",
                    text: "Absent"
                },
                waiting: {
                    color: "warning",
                    text: "Non précisé"
                }
            };
        },
        organTypeText() {
            return getOrganText(this.agItem.organ.organ_type);
        },
        agModeText() {
            return getModeText(this.agItem.mode);
        }
    }
};
</script>

<style lang="scss" scoped>
.ag-timeline {
    margin: auto;
    min-width: 33%;
    max-width: 600px;
}
</style>
