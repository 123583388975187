<template>
    <EqsCard>
        <v-container class="pa-6">
            <v-row>
                <v-col :cols="12">
                    <EqsTextDisplay value="Informations Générales" eqs-type="title" />
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col :cols="12" class="d-flex">
                    <EqsTextDisplay value="Date d'incorporation" eqs-type="bodyBold" />
                    <EqsTextDisplay :value="`: ${incorporationDate}`" />
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col :cols="12" class="d-flex">
                    <EqsTextDisplay value="Lieu d'activité" eqs-type="bodyBold" />
                    <EqsTextDisplay :value="`: ${location}`" />
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col :cols="12" class="d-flex">
                    <EqsTextDisplay value="Numéro SIREN" eqs-type="bodyBold" />
                    <EqsTextDisplay :value="`: ${siren}`" />
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col :cols="12" class="d-flex">
                    <EqsTextDisplay value="Forme légale" eqs-type="bodyBold" />
                    <EqsTextDisplay :value="`: ${legalForm}`" />
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col :cols="12" class="d-flex">
                    <EqsTextDisplay value="Email" eqs-type="bodyBold" />
                    <EqsTextDisplay :value="`: ${email}`" />
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col :cols="12" class="d-flex">
                    <EqsTextDisplay value="Siège de l'entreprise" eqs-type="bodyBold" />
                    <EqsTextDisplay :value="`: ${headquarters}`" />
                </v-col>
            </v-row>

            <div v-for="table in tables" :key="table.name" class="pt-3">
                <v-row class="mt-6" no-gutters>
                    <v-col :cols="8">
                        <EqsTextDisplay :value="table.name" eqs-type="bodyBold" />
                    </v-col>

                    <v-col :cols="4">
                        <EqsTextDisplay value="Prix" eqs-type="bodyBold" />
                    </v-col>
                </v-row>

                <v-divider class="my-1" />

                <v-row v-for="item in table.items" :key="item.text" no-gutters>
                    <v-col :cols="8">
                        <EqsLabel
                            style="width: fit-content"
                            :editable="false"
                            :value="item.text"
                            :info-txt="item.info"
                        />
                    </v-col>

                    <v-col :cols="4">
                        <EqsTextDisplay
                            :value="$currency.displayWithCurrency(item.value)"
                            eqs-type="bodyBold"
                        />
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col :cols="8">
                        <EqsTextDisplay value="Total" eqs-type="bodyBold" />
                    </v-col>

                    <v-col :cols="4">
                        <EqsTextDisplay
                            :value="
                                $currency.displayWithCurrency(
                                    table.items.reduce((total, { value = 0 }) => total + value, 0)
                                )
                            "
                            eqs-type="bodyBold"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </EqsCard>
</template>

<script>
export default {
    name: "CompanyDetailsGeneralInfo",
    props: {
        incorporationDate: {
            type: String,
            required: true
        },
        location: {
            type: String,
            required: true
        },
        siren: {
            type: String,
            required: true
        },
        legalForm: {
            type: String,
            required: true
        },
        email: {
            type: String,
            required: true
        },
        headquarters: {
            type: String,
            required: true
        },
        tables: {
            required: true,
            validator: val =>
                Array.isArray(val) &&
                val.every(
                    v =>
                        v?.name &&
                        v?.items &&
                        Array.isArray(v.items) &&
                        v.items.every(i => i?.text && !isNaN(i?.value))
                )
        },
        loading: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
