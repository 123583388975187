import { render, staticRenderFns } from "./_MyWallet.vue?vue&type=template&id=0d244843&scoped=true"
import script from "./_MyWallet.vue?vue&type=script&lang=js"
export * from "./_MyWallet.vue?vue&type=script&lang=js"
import style0 from "./_MyWallet.vue?vue&type=style&index=0&id=0d244843&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d244843",
  null
  
)

export default component.exports