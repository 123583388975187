export const cssTypes = ctx => {
    return {
        default: {}
    };
};

export const dataTypes = ctx => {
    return {
        default: {}
    };
};
