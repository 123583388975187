<template>
    <div class="d-flex justify-left align-center fill-height">
        <EqsTabs v-bind="{ tabs }" :value.sync="tab" data-type="default">
            <template #default="{ item }">
                <component :is="item.is" />
            </template>
        </EqsTabs>
    </div>
</template>

<script>
import BuyShares from "./BuyShares";
import FollowBuys from "./FollowBuys";
import FollowSells from "./FollowSells";

export default {
    name: "SecondaryMarket",
    components: {
        FollowBuys,
        BuyShares,
        FollowSells
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tab: null,
            tabs: [
                {
                    id: "buy",
                    title: this.$t("secondary-market.tabs.buy-shares"),
                    item: {
                        is: "BuyShares"
                    }
                },
                {
                    id: "sells",
                    title: this.$t("secondary-market.tabs.follow-sells"),
                    item: {
                        is: "FollowSells"
                    }
                },
                {
                    id: "buys",
                    title: this.$t("secondary-market.tabs.follow-buys"),
                    item: {
                        is: "FollowBuys"
                    }
                }
            ]
        };
    }
};
</script>
