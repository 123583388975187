import { i18n } from "@/services/plugins/i18n.js";

const genericServerMessage = i18n.t("error.generic-server-message");

const errorsFormater = {
    // login / logout / register

    send_mail_forgot_password: {
        serverError: i18n.t("error.cannot-send-mail"),
        clientError: {}
    },
    verify_registration: {
        serverError: i18n.t("error.verify-registration"),
        clientError: {
            400: i18n.t("error.verify-registration-400")
        }
    },
    login_get_token: {
        serverError: i18n.t("error.login-get-token"),
        clientError: {}
    },
    login_request: {
        serverError: i18n.t("error.login-request"),
        clientError: {
            401: i18n.t("error.login-request-401-wrong-credentials"),
            403: i18n.t("error.login-request-403-verify-account")
        }
    },
    twofa_get_secret: {
        serverError: i18n.t("error.2fa-get-secret")
    },

    // balance
    account_balance: {
        serverError: i18n.t("error.account-balance"),
        clientError: {}
    },
    // my portfolio
    portfolio_assets: {
        serverError: i18n.t("error.portfolio-assets"),
        clientError: {}
    },
    // company dashboard
    company_capitalisation_ledger_dashboard: {
        serverError: i18n.t("error.company-capitalisation-ledger-dashboard"),
        clientError: {}
    },
    company_shareledgers_dashboard: {
        serverError: i18n.t("error.company-shareledgers-dashboard"),
        clientError: {}
    },
    shareholder_transfer_history: {
        serverError: i18n.t("error.shareholder-transfer-history"),
        clientError: {}
    },
    shareledger_details_history: {
        serverError: i18n.t("error.shareledger-details-history"),
        clientError: {}
    },
    shareledger_creation: {
        serverError: i18n.t("error.shareledger-creation"),
        clientError: {}
    },
    asset_movement_history: {
        serverError: i18n.t("error.asset-movement-history"),
        clientError: {}
    },
    asset_movement_history_download: {
        serverError: i18n.t("error.asset-movement-history-download"),
        clientError: {}
    },
    download_shareholders_list: {
        serverError: i18n.t("error.download-shareholders-list"),
        clientError: {}
    },
    company_blockchainize: {
        serverError: i18n.t("error.company-blockchainize"),
        clientError: {
            400: i18n.t("error.company-blockchainize-insufficient-funds"),
            403: i18n.t("error.company-blockchainize-not-allowed")
        }
    },
    company_set_coutries_restrictions: {
        serverError: i18n.t("error.company-set-coutries-restrictions"),
        clientError: {}
    },
    company_set_investor_limits: {
        serverError: i18n.t("error.company-set-investor-limits"),
        clientError: {}
    },
    // shareledger creation
    shareledger_create: {
        serverError: i18n.t("error.shareledger-create"),
        clientError: {}
    },
    shareledger_detail_update: {
        serverError: i18n.t("error.shareledger-detail-update"),
        clientError: {}
    },
    shareledger_asset_movements: {
        serverError: i18n.t("error.shareledger-asset-movement"),
        clientError: {}
    },
    shareledger_delete_investor_asset_movement: {
        serverError: i18n.t("error.shareledger-delete-investor-asset-movement"),
        clientError: {}
    },
    shareledger_update_nb_of_shares_asset_movement: {
        serverError: i18n.t("error.shareledger-delete-investor-asset-movement"),
        clientError: {}
    },
    shareledger_add_investor: {
        serverError: i18n.t("error.shareledger-add-investor"),
        clientError: {}
    },
    shareledger_invite_investor: {
        serverError: i18n.t("error.shareledger-invite-investor"),
        clientError: {
            422: i18n.t("error.shareledger-invite-investor-wrong-type")
        }
    },
    shareledger_get_kycissuer: {
        serverError: i18n.t("error.shareledger-get-kycissuer"),
        clientError: {}
    },
    shareledger_stepper_get_shareledger: {
        serverError: i18n.t("error.shareledger-stepper-get-shareledger"),
        clientError: {}
    },
    // my transactions
    my_transactions_bank_account: {
        serverError: i18n.t("error.my-transactions-bank-account"),
        clientError: {}
    },
    my_transactions_contract_transactions: {
        serverError: i18n.t("error.my-transactions-contract-transactions"),
        clientError: {}
    },
    my_transactions_order_transactions: {
        serverError: i18n.t("error.my-transactions-order-transactions"),
        clientError: {}
    },
    // my portfolio
    my_portfolio_company_details: {
        serverError: i18n.t("error.shareledger-my-portfolio-company-details"),
        clientError: {
            404: i18n.t("error.shareledger-my-portfolio-company-details-clienterror")
        }
    },
    // roles / manage team
    get_user_role_list: {
        serverError: i18n.t("error.get-user-role-list"),
        clientError: {}
    },
    manage_team_get_roles: {
        serverError: i18n.t("error.manage-team-get-roles"),
        clientError: {}
    },
    manage_team_update_role: {
        serverError: i18n.t("error.manage-team-update-role"),
        clientError: {}
    },
    manage_team_add_member: {
        serverError: i18n.t("error.manage-team-add-member"),
        clientError: {}
    },
    manage_team_remove_member: {
        serverError: i18n.t("error.cannot-remove-member-from-this-team"),
        clientError: {}
    },
    // universe
    get_universe: {
        serverError: i18n.t("error.get-universe"),
        clientError: {}
    },
    // entity infos / creation / update / kyc / kyb
    change_role: {
        serverError: i18n.t("error.change-role"),
        clientError: {}
    },
    get_my_entity: {
        serverError: i18n.t("error.get-my-entity"),
        clientError: {}
    },
    get_my_roles: {
        serverError: i18n.t("error.get-my-roles"),
        clientError: {}
    },
    get_my_role: {
        serverError: i18n.t("error.get-my-role"),
        clientError: {}
    },
    entity_delete_account: {
        serverError: i18n.t("error.failed-to-delete-your-account"),
        clientError: {}
    },
    entity_get_notifications: {
        serverError: i18n.t("error.failed-to-get-your-notifications"),
        clientError: {}
    },
    entity_update_profile_picture: {
        serverError: i18n.t("error.failed-to-update-your-profile-picture"),
        clientError: {}
    },
    entity_infos: {
        serverError: i18n.t("error.entity-infos"),
        clientError: {}
    },
    entity_create_company: {
        serverError: i18n.t("error.entity-create-company"),
        clientError: {}
    },
    entity_update_company: {
        serverError: i18n.t("error.entity-update-company"),
        // '',
        clientError: {}
    },
    entity_update_individual: {
        serverError: i18n.t("error.entity-update-individual"),
        clientError: {}
    },
    kyb_wallet: {
        serverError: i18n.t("error.kyc-wallet"),
        clientError: {}
    },
    kyc_wallet: {
        serverError: i18n.t("error.kyc-wallet"),
        clientError: {}
    },
    account_iban: {
        serverError: i18n.t("error.account-iban"),
        clientError: {}
    },
    invite_entity: {
        serverError: i18n.t("error.invite-entity"),
        clientError: {}
    },
    entities_list: {
        serverError: i18n.t("error.entities-list"),
        clientError: {}
    },
    blockchain_accounts: {
        serverError: i18n.t("error.blockchain_accounts"),
        clientError: {}
    },
    blockchain_list_get: {
        serverError: i18n.t("error.blockchain_list_get"),
        clientError: {}
    },
    blockchain_list_get: {
        serverError: i18n.t("error.blockchain_list_get"),
        clientError: {}
    },
    investor_accreditation_res: {
        serverError: i18n.t("error.investor_accreditation_res"),
        clientError: {}
    },

    // investor registrar
    investor_registrar_get_kycissuer: {
        serverError: i18n.t("error.investor-registrar-get-kycissuer"),
        clientError: {}
    },
    investor_registrar_get_investors: {
        serverError: i18n.t("error.investor-registrar-get-investors"),
        clientError: {}
    },
    investor_registrar_remove_investor_kyc_registrar: {
        serverError: i18n.t("error.investor-registrar-remove-investor-kyc-registrar"),
        clientError: {}
    },
    investor_registrar_change_status_investor: {
        serverError: i18n.t("error.investor-registrar-change-status-investor"),
        clientError: {}
    },
    investor_registrar_add_investor: {
        serverError: i18n.t("error.investor-registrar-add-investor"),
        clientError: {
            400: i18n.t("error.investor-registrar-add-investor-already-exists"),
            422: i18n.t("error.investor-registrar-add-investor-wrong-type")
        }
    },
    investor_registrar_add_investor_kyc: {
        serverError: i18n.t("error.investor-registrar-add-investor-kyc"),
        clientError: {}
    },

    // company restrictions
    company_restrictions_get_investors_limit: {
        serverError: i18n.t("error.company-restrictions-get-investors-limit"),
        clientError: {}
    },
    company_restrictions_patch_investors_limit: {
        serverError: i18n.t("error.company-restrictions-patch-investors-limit"),
        clientError: {}
    },

    company_restrictions_get_countries: {
        serverError: i18n.t("error.company-restrictions-get-countries"),
        clientError: {}
    },
    company_restrictions_patch_countries: {
        serverError: i18n.t("error.company-restrictions-patch-countries"),
        clientError: {}
    },

    company_get_shareledgers: {
        serverError: i18n.t("error.company-restrictions-get-shareledgers"),
        clientError: {}
    },

    company_restrictions_patch_shareledgers: {
        serverError: i18n.t("error.company-restrictions-patch-shareledgers"),
        clientError: {}
    },

    // capital increase admin

    capital_increase_admin_history: {
        serverError: i18n.t("error.capital_increase_admin_history"),
        clientError: {}
    },
    capital_increase_admin_get_shareledgers: {
        serverError: i18n.t("error.capital-increase-admin-get-shareledgers"),
        clientError: {}
    },
    capital_increase_admin_shareledger_create: {
        serverError: i18n.t("error.capital-increase-admin-shareledger-create"),
        clientError: {
            400: i18n.t("error.capital-increase-admin-shareledger-create-client-error")
        }
    },
    capital_increase_admin_get_last: {
        serverError: i18n.t("error.capital-increase-admin-get-last"),
        clientError: {}
    },
    capital_increase_admin_get: {
        serverError: i18n.t("error.capital-increase-admin-get"),
        clientError: {}
    },
    capital_increase_admin_delete: {
        serverError: i18n.t("error.capital-increase-admin-delete"),
        clientError: {}
    },
    capital_increase_admin_finish: {
        serverError: i18n.t("error.capital_increase_admin_finish"),
        clientError: {}
    },
    capital_increase_admin_cancel: {
        serverError: i18n.t("error.capital_increase_admin_cancel"),
        clientError: {}
    },
    capital_increase_admin_params_create: {
        serverError: i18n.t("error.capital-increase-admin-params-create"),
        clientError: {
            403: i18n.t("error.capital-increase-admin-params-create-not-allowed")
        }
    },
    capital_increase_admin_params_update: {
        serverError: i18n.t("error.capital-increase-admin-params-update"),
        clientError: {
            403: i18n.t("error.capital-increase-admin-params-update-not-allowed")
        }
    },
    capital_increase_admin_offering_update: {
        serverError: i18n.t("error.capital-increase-admin-offering-update"),
        clientError: {}
    },
    capital_increase_offering_create_member: {
        serverError: i18n.t("error.capital-increase-offering-create-member"),
        clientError: {}
    },
    capital_increase_offering_remove_member: {
        serverError: i18n.t("error.capital-increase-offering-remove-member"),
        clientError: {}
    },
    capital_increase_admin_offering_add_doc: {
        serverError: i18n.t("error.capital-increase-admin-offering-add-doc"),
        clientError: {}
    },
    capital_increase_admin_offering_update_doc_category: {
        serverError: i18n.t("error.capital-increase-admin-offering-update-category"),
        clientError: {}
    },
    capital_increase_admin_get_orders: {
        serverError: i18n.t("error.capital-increase-admin-get-orders"),
        clientError: {}
    },
    capital_increase_admin_get_docs_to_sign: {
        serverError: i18n.t("error.capital-increase-admin-get-docs-to-sign"),
        clientError: {}
    },
    capital_increase_admin_add_doc_to_sign: {
        serverError: i18n.t("error.capital-increase-admin-add-doc-to-sign"),
        clientError: {}
    },
    capital_increase_get_subscribers: {
        serverError: i18n.t("error.capital-increase-get-subscribers"),
        clientError: {}
    },
    capital_increase_accept_subscriber: {
        serverError: i18n.t("error.capital_increase_accept_subscriber"),
        clientError: {}
    },
    capital_increase_refuse_subscriber: {
        serverError: i18n.t("error.capital_increase_refuse_subscriber"),
        clientError: {}
    },
    capital_increase_admin_make_public: {
        serverError: i18n.t("error.capital-increase-admin-make-public"),
        clientError: {}
    },
    capital_increase_admin_make_finished: {
        serverError: i18n.t("error.capital-increase-admin-make-finished"),
        clientError: {
            400: "Blockchain error"
        }
    },
    capital_increase_admin_invite: {
        serverError: i18n.t("error.capital-increase-admin-invite"),
        clientError: {}
    },
    // capital increase user
    capital_increase_user_get_offerings: {
        serverError: i18n.t("error.capital-increase-user-get-offerings"),
        clientError: {}
    },
    capital_increase_user_get: {
        serverError: i18n.t("error.capital-increase-user-get"),
        clientError: {
            400: i18n.t("error.capital-increase-user-get-400-error"),
            403: i18n.t("error.capital-increase-user-get-403-error"),
            404: i18n.t("error.capital-increase-user-get-404-error")
        }
    },
    capital_increase_user_delete_order: {
        serverError: i18n.t("error.capital_increase_user_delete_order"),
        clientError: {}
    },
    capital_increase_user_get_limits: {
        serverError: i18n.t("error.capital-increase-user-get-limits"),
        clientError: {}
    },
    capital_increase_user_get_countries: {
        serverError: i18n.t("error.capital-increase-user-get-countries"),
        clientError: {}
    },
    capital_increase_user_delete_order: {
        serverError: i18n.t("error.capital_increase_user_delete_order"),
        clientError: {}
    },
    // buy
    buy_get_pea_docs: {
        serverError: i18n.t("error.buy-get-pea-docs"),
        clientError: {}
    },
    buy_send_pea_mail: {
        serverError: i18n.t("error.buy-send-pea-mail"),
        clientError: {}
    },
    buy_create_order: {
        serverError: i18n.t("error.buy-create-order"),
        clientError: {}
    },
    buy_get_docs_to_sign: {
        serverError: i18n.t("error.buy-get-docs-to-sign"),
        clientError: {}
    },
    buy_sign_docs: {
        serverError: i18n.t("error.buy-sign-docs"),
        clientError: {}
    },
    buy_trigger_buy_process: {
        serverError: i18n.t("error.buy-trigger-buy-process"),
        clientError: {
            // todo: handle more errors on the same statusCode
            400: i18n.t("error.buy-trigger-buy-process-total-price")
        }
    },
    buy_fetch_order: {
        serverError: i18n.t("error.buy_fetch_order"),
        clientError: {
            404: i18n.t("error.buy_fetch_order_404")
        }
    },
    // data room
    data_room_get_investors: {
        serverError: i18n.t("error.data-room-get-investors"),
        clientError: {}
    },
    data_room_get_docs: {
        serverError: i18n.t("error.data-room-get-docs"),
        clientError: {}
    },
    // Questionnaires
    questionnaire_options: {
        serverError: i18n.t("error.questionnaire-options"),
        clientError: {}
    },
    questionnaire_send: {
        serverError: i18n.t("error.questionnaire-send"),
        clientError: {}
    },
    // Documents
    document_delete: {
        serverError: i18n.t("error.document-delete"),
        clientError: {
            404: i18n.t("error.document-delete-404")
        }
    },
    document_modification: {
        serverError: i18n.t("error.document_modification"),
        clientError: {}
    },
    // bank
    register_bank_account: {
        serverError: i18n.t("error.register_bank_account"),
        clientError: {}
    },
    // operations
    operations_add_movement: {
        serverError: i18n.t("error.operations_add_movement"),
        clientError: {}
    },
    // governance
    // representative
    governance_representative_delete: {
        serverError: i18n.t("error.governance_representative_delete"),
        clientError: {}
    },
    governance_representative_get: {
        serverError: i18n.t("error.governance_representative_get"),
        clientError: {}
    },
    governance_representative_create: {
        serverError: i18n.t("error.governance_representative_create"),
        clientError: {}
    },
    governance_representative_update: {
        serverError: i18n.t("error.governance_representative_update"),
        clientError: {}
    },
    // organ
    governance_organ_delete: {
        serverError: i18n.t("error.governance_organ_delete"),
        clientError: {}
    },
    governance_organ_get: {
        serverError: i18n.t("error.governance_organ_get"),
        clientError: {}
    },
    governance_organ_create: {
        serverError: i18n.t("error.governance_organ_create"),
        clientError: {}
    },
    governance_organ_update: {
        serverError: i18n.t("error.governance_organ_update"),
        clientError: {}
    },
    // decision
    governance_decision_delete: {
        serverError: i18n.t("error.governance_decision_delete"),
        clientError: {}
    },
    governance_decision_lock: {
        serverError: i18n.t("error.governance_decision_lock"),
        clientError: {}
    },
    governance_decision_get: {
        serverError: i18n.t("error.governance_decision_get"),
        clientError: {}
    },
    governance_decision_create: {
        serverError: i18n.t("error.governance_decision_create"),
        clientError: {}
    },
    governance_decision_update: {
        serverError: i18n.t("error.governance_decision_update"),
        clientError: {}
    },
    // yousign
    get_procedure_preview: {
        serverError: i18n.t("error.get_procedure_preview"),
        clientError: {}
    },
    get_procedure: {
        serverError: i18n.t("error.get_procedure"),
        clientError: {}
    },
    delete_procedure: {
        serverError: i18n.t("error.delete_procedure"),
        clientError: {}
    },
    create_procedure_doc: {
        serverError: i18n.t("error.create_procedure_doc"),
        clientError: {}
    },
    remove_procedure_doc: {
        serverError: i18n.t("error.remove_procedure_doc"),
        clientError: {}
    },
    download_signature_proof: {
        serverError: i18n.t("error.download_signature_proof"),
        clientError: {
            404: i18n.t("error.download_signature_proof_404")
        }
    },
    // secondary market
    secondary_market_fetch_sell_offer: {
        serverError: i18n.t("error.secondary_market_fetch_sell_offer"),
        clientError: {}
    },
    secondary_market_fetch_sell_offers: {
        serverError: i18n.t("error.secondary_market_fetch_sell_offers"),
        clientError: {
            403: i18n.t("error.secondary_market_fetch_sell_offers_403")
        }
    },
    secondary_market_buy: {
        serverError: i18n.t("error.secondary_market_buy"),
        clientError: {}
    },
    secondary_market_fetch_your_buy_offers: {
        serverError: i18n.t("error.secondary_market_fetch_your_buy_offers"),
        clientError: {}
    },
    // risk aversion
    risk_aversion_get_personal_form: {
        serverError: i18n.t("error.risk_aversion_get_personal_form"),
        clientError: {}
    },
    risk_aversion_update_personal_form: {
        serverError: i18n.t("error.risk_aversion_update_personal_form"),
        clientError: {}
    },
    risk_aversion_update_quiz_form: {
        serverError: i18n.t("error.risk_aversion_update_quiz_form"),
        clientError: {}
    }
};

const exceptionGetErrorFromServer = [
    "shareledger_send_token",
    "asset_transfer",
    "capital_increase_admin_make_finished",
    "login_request",
    "operations_add_movement"
];

export function getStatusCodeError(e, ctxtError) {
    if (!e.status)
        return {
            statusText: i18n.t("error.generic-undefined-error")
        };
    let ret = {
        status: e.status,
        statusText: e.status.toString() + ` ${e.statusText} :`,
        data: e.data
    };
    if (!ctxtError) {
        console.warn("Error with no context: ", e);
        return ret;
    }
    if (exceptionGetErrorFromServer.includes(ctxtError) && !!e.data) {
        ret.data = Array.isArray(e.data) ? e.data[0] : e.data;
        return ret;
    }
    const errorFromCtxt = errorsFormater[ctxtError];
    if (!errorFromCtxt) {
        console.error(`Error with context "${ctxtError}" but entry doesn't exist in formater: `, e);
        return ret;
    }
    if (e.status >= 500 && e.status <= 511)
        ret.data = errorFromCtxt.serverError + genericServerMessage;
    else if (e.status >= 400 && e.status < 500) {
        const clientErr = errorFromCtxt.clientError[e.status.toString()];
        if (!clientErr) {
            console.error(
                `Error with context "${ctxtError}" has no formated message for HTTP status "${e.status}": `,
                e
            );
            return ret;
        }
        ret.data = clientErr;
    }
    return ret;
}
