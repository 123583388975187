<template>
    <div class="d-flex">
        <EqsNumber
            class="wallet-balance"
            :label="$t('user.wallet.wallet-balance')"
            v-bind="{
                value: balance,
                loading: loading || balanceLoading,
                editable: false,
                eqsSize: 'lg',
                infoTxt: $t('user.wallet.info-txt', { universe })
            }"
            el-class="wallet-balance"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "WalletBalance",
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        universe() {
            return this.$store.getters.universe.public_name;
        },
        ...mapGetters(["balance", "balanceLoading"])
    },
    created() {
        this.getBalance();
    },
    methods: {
        ...mapActions(["getBalance"])
    }
};
</script>

<style lang="scss" scoped>
.wallet-balance {
    ::v-deep .input-label {
        text-align: center !important;

        &__spacing {
            display: contents;
        }
    }

    ::v-deep .disabled-value {
        margin: auto;
    }
}
</style>
