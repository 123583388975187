<template>
    <EqsViewWithImage
        img="/images/governance/mandats.svg"
        v-bind="{ loading: loading || representativesLoading }"
    >
        <template #default="{ loading }">
            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                    <GlobalChooseInvestor
                        :value.sync="formData.investor"
                        v-bind="{
                            loading,
                            label: edit
                                ? $t('governance.representatives.representative')
                                : $t('governance.representatives.choose-representative'),
                            disabled: edit,
                            editable,
                            errorMessages: uniqueRepresentative,
                            ...schemaDouble.investor
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                    <EqsSelect
                        :value.sync="formData.role"
                        :items="roles"
                        v-bind="{
                            loading,
                            label: $t('governance.representatives.quality'),
                            placeholder: roles[0].text,
                            editable,
                            ...schemaDouble.role
                        }"
                    />
                </v-col>
                <v-slide-y-transition hide-on-leave>
                    <v-col
                        v-if="formData.role === 'other'"
                        :cols="$reactive.getCols('full', formType)"
                        class="eqs-form-field"
                    >
                        <EqsText
                            :value.sync="formData.roleOther"
                            v-bind="{
                                loading,
                                label: $t('governance.representatives.quality-other'),
                                placeholder: $t('governance.representatives.quality-placeholder'),
                                editable,
                                ...schemaDouble.roleOther
                            }"
                        />
                    </v-col>
                </v-slide-y-transition>
                <v-row class="px-3">
                    <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                        <EqsDate
                            :value.sync="formData.start_mandat_date"
                            v-bind="{
                                loading,
                                editable,
                                label: $t('governance.representatives.start_mandat_date'),
                                ...schemaDouble.start_mandat_date
                            }"
                        />
                    </v-col>
                    <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                        <EqsDate
                            :value.sync="formData.end_mandat_date"
                            v-bind="{
                                loading,
                                editable,
                                label: $t('governance.representatives.end_mandat_date'),
                                ...endOfTermMinMax,
                                ...schemaDouble.end_mandat_date
                            }"
                        />
                    </v-col>
                </v-row>
                <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                    <EqsNumber
                        :value.sync="formData.annual_compensation"
                        v-bind="{
                            loading,
                            editable,
                            label: $t('governance.representatives.annual_compensation'),
                            placeholder: $t(
                                'governance.representatives.annual_compensation-placeholder'
                            ),
                            ...schemaDouble.annual_compensation
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                    <EqsFileInput
                        :value.sync="formData.nomination_act"
                        v-bind="{
                            loading,
                            editable,
                            label: $t('governance.representatives.nomination'),
                            ...schemaDouble.nomination_act
                        }"
                    />
                </v-col>
            </v-row>
        </template>
    </EqsViewWithImage>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { mapGetters } from "vuex";
export default {
    name: "RepresentativeForm",
    mixins: [formMixin],
    props: {
        edit: {
            type: Boolean,
            default: true
        },
        roles: {
            type: Array,
            required: true
        }
    },
    methods: {},
    computed: {
        ...mapGetters(["representatives", "representativesLoading"]),
        endOfTermMinMax() {
            const minDate = this.formData.start_mandat_date;
            if (!minDate) return {};
            return {
                min: this.$time.formatISO(this.$time.addDays(new Date(minDate).getTime(), 1)),
                max: this.$time.formatISO(this.$time.addYears(Date.now(), 99))
            };
        },
        uniqueRepresentative() {
            if (
                !this.edit &&
                this.formData.investor &&
                this.representatives.find(
                    representative =>
                        representative.investor.id === this.formData.investor.id &&
                        representative.role === this.formData.role
                )
            )
                return this.$t("governance.representatives.error-same-role", {
                    role: this.formData.role,
                    name: this.formData.investor.name
                });
            return "";
        }
    }
};
</script>

<style lang="scss" scoped></style>
