var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-notifications"},[_c('v-menu',{ref:"menu",attrs:{"offset-y":"","nudge-bottom":"2rem","origin":"top bottom","transition":!_vm.opened ? 'scroll-y-reverse-transition' : 'scroll-y-transition',"rounded":"xl","content-class":"eqs-notifications-menu","left":!_vm.isMobile,"right":_vm.isMobile,"disabled":_vm.loading,"min-width":_vm.isMobile ? '100vw' : 'auto'},on:{"input":_vm.updateMenu},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card',_vm._g({class:`eqs-notifications__container d-flex flex-row ${_vm.elClass}-activator`,attrs:{"flat":"","color":_vm.$color.colors.menuColor}},on),[_c('v-col',{attrs:{"align-self":"center"}},[_c('EqsSkeleton',_vm._b({},'EqsSkeleton',{ loading: _vm.loading, type: 'button' },false),[_c('v-badge',{staticClass:"positive-z-index",attrs:{"content":"","value":_vm.$notifications.numberOfNewNotifications() > 0,"color":"red","overlap":"","dot":""}},[_c('v-icon',{attrs:{"color":_vm.opened ? 'primary' : _vm.$color.colors.lightGrey,"size":_vm.isMobile ? '26px' : '30px'}},[_vm._v(" mdi-bell ")])],1)],1)],1),_c('v-divider',{class:{ 'ml-1 mr-auto': _vm.numActive > 9 },attrs:{"vertical":""}})],1)]}}])},[_c('v-list',{staticClass:"eqs-notifications-menu__list d-flex flex-column",style:(`width: ${_vm.isMobile ? '100%' : '350px'};`),attrs:{"three-line":""}},[_c('v-subheader',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',[_c('EqsTextDisplay',_vm._b({attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{
                                value: _vm.$t('equisafe-ui.notifications.subtitle')
                            },false))],1),(_vm.items.length)?_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                                name: 'notifications',
                                params: { id: _vm.$entity.represented().id || '' }
                            }}},[_vm._v(" "+_vm._s(_vm.$t("equisafe-ui.notifications.see-list"))+" ")])],1):_vm._e()],1)],1),_c('v-divider',{staticClass:"mx-3"}),(!_vm.items.length)?_c('v-list-item-title',{staticClass:"mx-5 my-3"},[_c('EqsTextDisplay',_vm._b({staticClass:"ma-3",attrs:{"eqs-type":"caption"}},'EqsTextDisplay',{ value: _vm.$t('equisafe-ui.notifications.no-notif') },false))],1):_vm._e(),_c('div',{staticClass:"eqs-notifications-menu__list__content d-flex flex-column"},[_vm._l((_vm.items),function(item,index){return [_c('v-list-item',{key:index,class:`${_vm.elClass}-${index}`},[_c('EqsAvatar',_vm._b({staticClass:"mr-5"},'EqsAvatar',{
                                user: item.created_by,
                                eqsType: 'list',
                                noBadge: true
                            },false)),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-space-between wrap"},[_c('v-row',{staticClass:"fill-width"},[_c('v-col',{attrs:{"cols":"8"}},[_c('EqsTextDisplay',_vm._b({staticClass:"text-wrap",staticStyle:{"word-break":"break-word"},attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{
                                                value: item.created_by
                                                    ? item.created_by.name
                                                    : ''
                                            },false))],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex justify-end align-center"},[_c('EqsTextDisplay',_vm._b({attrs:{"eqs-type":"caption"}},'EqsTextDisplay',{
                                                    value: _vm.$time.formated(item.updated_at)
                                                },false))],1)])],1)],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.message)+" ")]),(!item.shown)?_c('v-list-item-subtitle',{staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-badge',{attrs:{"dot":"","inline":"","color":"primary"}})],1)]):_vm._e(),_c('v-list-item-subtitle',{class:`d-flex pt-${
                                    item.shown || item.confirm || item.cancel ? '3' : '0'
                                } ${_vm.elClass}-${index}-actions`},[(item.action_txt)?_c('EqsTextDisplay',_vm._b({attrs:{"eqs-type":"caption"}},'EqsTextDisplay',{ value: item.action_txt },false)):_vm._e(),(item.cancel)?_c('EqsBtn',_vm._b({staticClass:"mr-3",attrs:{"color":"primary","outlined":"","small":""}},'EqsBtn',{
                                        action: () => _vm.cancel(item),
                                        elClass: `${_vm.elClass}-${index}-cancel`
                                    },false),[_vm._v(" "+_vm._s(item.cancel.text)+" ")]):_vm._e(),(item.confirm)?_c('EqsBtn',_vm._b({staticClass:"mr-3",attrs:{"color":"primary","small":""}},'EqsBtn',{
                                        action: () => _vm.confirm(item),
                                        elClass: `${_vm.elClass}-${index}-confirm`
                                    },false),[_vm._v(" "+_vm._s(item.confirm.text)+" ")]):_vm._e()],1)],1)],1),(index < _vm.items.length - 1)?_c('v-divider',{key:`${index}-divider`}):_vm._e()]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }