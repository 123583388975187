<template>
    <EqsCard
        v-bind="{
            subtitle: $t('governance.organs.details.other-info.title'),
            loading
        }"
    >
        <div class="d-flex flex-column">
            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <EqsText
                        v-bind="{
                            label: $t('governance.organs.details.other-info.name'),
                            editable: false,
                            value: organ.name
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <EqsText
                        v-bind="{
                            label: $t('governance.organs.details.other-info.initials'),
                            editable: false,
                            value: organ.initials
                        }"
                    />
                </v-col>
                <v-col
                    v-if="organ.vote_type"
                    :cols="$reactive.getCols('half')"
                    class="eqs-form-field"
                >
                    <EqsText
                        v-bind="{
                            label: $t('governance.organs.details.other-info.vote_type'),
                            editable: false,
                            value: vote_types[organ.vote_type].text
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <EqsDate
                        v-bind="{
                            label: $t('governance.organs.details.other-info.creation.label'),
                            editable: false,
                            value: organ.created_at
                        }"
                    />
                </v-col>
                <v-col
                    v-if="organ.shareledgers && organ.shareledgers.length"
                    :cols="$reactive.getCols('full')"
                    class="eqs-form-field"
                >
                    <EqsLabel
                        v-bind="{
                            value: $t('governance.organs.details.other-info.ledgers'),
                            editable: false
                        }"
                    >
                        <div class="d-flex">
                            <EqsChip
                                v-for="(share, index) in organ.shareledgers"
                                :key="index"
                                text-color="text"
                                :color="$color.colorFromHash(share.name)"
                                class="mx-1"
                            >
                                {{ share.name }}
                            </EqsChip>
                        </div>
                    </EqsLabel>
                </v-col>
            </v-row>
        </div>
    </EqsCard>
</template>

<script>
import { vote_types } from "@/util/governance.js";
export default {
    name: "OtherInfo",
    props: {
        organ: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            vote_types
        };
    }
};
</script>
