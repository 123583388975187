<template>
    <ArthaudCard
        v-if="$universe.isArthaud()"
        v-bind="{ item, selected, clickable, height, width }"
        @open="() => $emit('open')"
    >
        <template #actions>
            <slot name="actions" />
        </template>
    </ArthaudCard>
    <v-hover v-else style="height: 100%">
        <EqsCard
            slot-scope="{ hover }"
            :class="`elevation-${hover && clickable ? 12 : 2} item-card`"
            style="border-radius: 20px"
            v-bind="{
                loading,
                height,
                width,
                elClass
            }"
            @click="() => $emit('open')"
        >
            <template #main>
                <v-row no-gutters class="d-flex" style="height: 40%; max-height: 40%">
                    <EqsImage
                        :src="item.image ? item.image.upload : ''"
                        aspect-ratio="2"
                        height="100%"
                    >
                        <template #default>
                            <v-expand-transition>
                                <div
                                    class="transition-fast-out-fast-in primary-info v-card--reveal d-flex fill-width"
                                >
                                    <v-row no-gutters>
                                        <v-spacer />
                                        <v-col
                                            v-if="!$universe.isKyoseil()"
                                            class="flex text-center card-hard-cap max-height-fit height-fit px-2"
                                            cols="8"
                                        >
                                            <div class="card-hard-cap__text">
                                                {{ $t("capital-increase.hard-cap") }}
                                                {{
                                                    $currency.abreviate(
                                                        item.hard_cap,
                                                        true,
                                                        item.currency
                                                    )
                                                }}
                                            </div>
                                        </v-col>
                                        <v-spacer />
                                    </v-row>
                                </div>
                            </v-expand-transition>
                        </template>
                    </EqsImage>
                </v-row>
                <v-row no-gutters class="d-flex secondary-info flex-column pa-1 fill-height">
                    <div class="d-flex flex-column fill-height">
                        <EqsTextDisplay
                            class="text-center fill-width mb-2"
                            v-bind="{ eqsType: 'subtitle' }"
                        >
                            <div class="secondary-info__title">
                                <p>
                                    {{ item.order_title }}
                                </p>
                            </div>
                        </EqsTextDisplay>
                        <EqsTextDisplay class="mb-2 ml-3">
                            <div class="secondary-info__small_description">
                                <p>
                                    {{ item.short_description }}
                                </p>
                            </div>
                        </EqsTextDisplay>

                        <div
                            v-if="!$universe.isKyoseil()"
                            class="secondary-info__child d-flex flex-column fill-height justify-end"
                        >
                            <DaysLeft
                                class="pb-2"
                                v-bind="{
                                    startDate: item.start_date,
                                    endDate: item.end_date,
                                    forceEnded: isCapincEnded(item)
                                }"
                            />
                            <MoneyRaisedMini
                                v-if="$time.isPast(item.start_date)"
                                class="pb-2"
                                v-bind="{
                                    value: parseFloat(
                                        item.sum_all_deal && item.money_raised_all_deal
                                            ? item.money_raised_all_deal
                                            : item.money_raised
                                    ),
                                    currency: item.currency,
                                    hardCap: parseFloat(item.hard_cap),
                                    softCap: parseFloat(item.soft_cap),
                                    started: $time.isPast(item.start_date)
                                }"
                            />
                        </div>
                        <slot name="actions" />
                    </div>
                </v-row>
            </template>
        </EqsCard>
    </v-hover>
</template>

<script>
import DaysLeft from "@/components/molecules/progress/DaysLeft.vue";
import ArthaudCard from "./ArthaudCard.vue";
import MoneyRaisedMini from "@/components/molecules/progress/MoneyRaisedMini.vue";
import { isCapincEnded } from "@/components/views/projects/utils.js";

export default {
    name: "OfferCard",
    components: {
        ArthaudCard,
        DaysLeft,
        MoneyRaisedMini
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        elClass: {
            type: String,
            default: "project-item"
        },
        loading: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        clickable: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: "auto"
        },
        height: {
            type: String,
            required: true
        }
    },
    computed: {
        isPea() {
            const taxArrangements = this.item?.capincpaymentoptions?.envelopes;
            return taxArrangements && taxArrangements.includes("pea");
        },
        isPeapme() {
            const taxArrangements = this.item?.capincpaymentoptions?.envelopes;
            return taxArrangements && taxArrangements.includes("pea-pme");
        },
        getPeaText() {
            if (this.isPea) return "PEA";
            else if (this.isPeapme) return "PEA-PME";
            else return "";
        }
    },
    methods: {
        isCapincEnded,
        selectCard() {
            this.$emit("openDetail", !this.selected ? this.item.id : "offerings");
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/text.scss";

.pea-chips {
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    min-width: 100%;

    & > * {
        margin-right: 0.2rem;
        margin-left: 0.2rem;
    }
}

.item-card {
    cursor: pointer;
    // width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .primary-info {
        height: 100%;
        width: 100%;
        background-color: rgba(21, 49, 85, 0.2);

        > * {
            width: 33.33%;
        }

        color: #fafafa;

        // text-transform: uppercase;
        .card-hard-cap {
            background-color: rgba(21, 42, 71, 0.5);
            border-radius: 0px 0px 10px 10px;

            &__text {
                opacity: 1;
            }
        }

        .flag {
            background-color: white;
            border-radius: 50%;
            // height: 26px;
            // max-height: 26px;
            // width: 26px;
            // max-width: 26px;
            margin-top: 10px;
            margin-right: 10px;
            height: 4rem;
            max-height: 4rem;
            width: 4rem;
            max-width: 4rem;

            .cip {
                width: 100%;
                height: 100%;
            }

            .locked {
                width: 3rem;
                height: 3rem;
            }
        }
    }

    .flag-container {
        display: flex;
    }

    .secondary-info {
        flex-grow: 0;
        max-height: fit-content;

        &__child {
            overflow: hidden;
        }

        &__title {
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                max-height: inherit;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                margin-bottom: 0px;
            }
        }

        &__small_description {
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                max-height: inherit;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                margin-bottom: 0px;
            }
        }
    }
}
</style>
