<script>
import { Doughnut } from "vue-chartjs";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import isEmpty from "lodash/isEmpty";

export default {
    name: "EqsPie",
    extends: Doughnut,
    props: {
        config: {
            type: Object,
            required: true
        },
        displayLegend: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        config(val) {
            if (!isEmpty(val)) this.render();
        }
    },
    mounted() {
        if (!isEmpty(this.config)) this.render();
    },
    methods: {
        render() {
            const formatedConfig = this.formatData(this.config);
            this.renderChart(formatedConfig.data, {
                ...formatedConfig.options,
                responsive: true,
                maintainAspectRatio: false
            });
        },
        formatData(config) {
            return {
                type: "doughnut",
                data: {
                    labels: config.labels,
                    datasets: [
                        {
                            backgroundColor: this.$color.newColors({
                                color: this.$color.newColor(config.colorIndex),
                                count: config.labels.length,
                                mode: "darken"
                            }),
                            hoverBorderColor: "white",
                            data: config.data,
                            datalabels: {
                                infos: config.infos,
                                labels: {
                                    index: {
                                        align: "end",
                                        anchor: "end",
                                        ...config.index
                                    },
                                    name: {
                                        align: "top",
                                        font: { size: 10 },
                                        ...config.name
                                    },
                                    value: {
                                        align: "bottom",
                                        backgroundColor: function (ctx) {
                                            return ctx.active ? "white" : null;
                                        },
                                        borderColor: "white",
                                        borderWidth: 2,
                                        borderRadius: 4,
                                        color: function (ctx) {
                                            return ctx.active
                                                ? ctx.dataset.backgroundColor
                                                : "white";
                                        },
                                        padding: 2,
                                        ...config.value
                                    }
                                }
                            }
                        }
                    ]
                },
                options: {
                    infos: config.infos,
                    cutoutPercentage: 21,
                    padding: 64,
                    aspectRatio: 1,
                    legend: {
                        display: this.displayLegend,
                        align: "start"
                    },
                    tooltips: {
                        custom: tooltipModel => {
                            if (tooltipModel.body) {
                                tooltipModel.body[0].lines[0] += "%";
                                tooltipModel.width += 10;
                            }
                        },
                        ...config.tooltips
                    },
                    plugins: {
                        datalabels: {
                            color: "white",

                            font: { weight: "bold" },
                            offset: 0,
                            padding: 0,
                            ...config.datalabels
                        }
                    }
                }
            };
        }
    }
};
</script>
