import store from "@/store/store";
import { isProd, ACTIVATE_DEBUGGER_EVENTS_VUE_JS } from "@/assets/constants";

export default function (...args) {
    if (isProd()) {
        return;
    }
    if (ACTIVATE_DEBUGGER_EVENTS_VUE_JS) {
        return store.commit(...args);
    }
}
