var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half', _vm.formType)}},[_c('EqsFileInput',_vm._b({attrs:{"value":_vm.formData.doc_bank},on:{"update:value":function($event){return _vm.$set(_vm.formData, "doc_bank", $event)}}},'EqsFileInput',{
                    accept: '.pdf',
                    elClass: 'doc-bank',
                    label: _vm.$t('registration.rib'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.doc_bank
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half', _vm.formType)}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.holder},on:{"update:value":function($event){return _vm.$set(_vm.formData, "holder", $event)}}},'EqsText',{
                    elClass: 'bank-holder',
                    placeholder: _vm.$t('user.wallet.bank.holder-placeholder'),
                    label: _vm.$t('user.wallet.bank.holder'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.holder
                },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.iban},on:{"update:value":function($event){return _vm.$set(_vm.formData, "iban", $event)}}},'EqsText',{
                    elClass: 'bank-iban',
                    label: _vm.$t('user.wallet.bank.iban.label'),
                    placeholder: _vm.$t('user.wallet.bank.iban-placeholder'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.iban
                },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.bic},on:{"update:value":function($event){return _vm.$set(_vm.formData, "bic", $event)}}},'EqsText',{
                    elClass: 'bank-bic',
                    placeholder: _vm.$t('user.wallet.bank.bic-placeholder'),
                    label: _vm.$t('user.wallet.bank.bic'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.bic
                },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.dom1},on:{"update:value":function($event){return _vm.$set(_vm.formData, "dom1", $event)}}},'EqsText',{
                    elClass: 'bank-dom1',
                    placeholder: _vm.$t('user.wallet.bank.dom1-placeholder'),
                    label: _vm.$t('user.wallet.bank.dom1'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.dom1
                },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.dom2},on:{"update:value":function($event){return _vm.$set(_vm.formData, "dom2", $event)}}},'EqsText',{
                    elClass: 'bank-dom2',
                    placeholder: _vm.$t('user.wallet.bank.dom2-placeholder'),
                    label: _vm.$t('user.wallet.bank.dom2'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.dom2
                },false))],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }