import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            footer: {
                height: getVal(currentSize, {
                    mobile: "95px",
                    tablet: "59px",
                    desktop: "59px"
                })
            }
        },
        "no-overflow": {
            content: {
                "overflow-y": "none"
            }
        }
    };
};

const calcHeight = (currentSize, ctx) => {
    let height = getVal(currentSize, {
        mobile: 95,
        tablet: 59,
        desktop: 59
    });
    const rowHeight = 60;
    if (ctx.searchable || ctx.add) {
        height += rowHeight;
        if (currentSize.id === "xs" && ctx.searchable && ctx.add) height += rowHeight;
    }
    if (ctx.topHeightToAdd) height += ctx.topHeightToAdd;
    return `${height}px`;
};

export const dataTypes = (currentSize = {}, ctx = {}) => {
    return {
        default: {
            heightToRemove: calcHeight(currentSize, ctx)
        },
        "no-overflow": {}
    };
};
