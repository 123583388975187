<template>
    <div class="eqs-snackbar" :el-class="elClass">
        <v-snackbar
            v-model="valueProxy"
            v-bind="{ ...$attrs, timeout: -1 }"
            :style="cssProps"
            :class="getFadeMode"
            top
            :el-class="`${elClass}-${$attrs.color}`"
            min-width="200px"
            content-class="eqs-snackbar__content"
            max-height="80vh"
            v-on="$listeners"
        >
            <v-row no-wrap class="eqs-snackbar__container">
                <v-col cols="11" class="pt-0 pb-0 d-flex flex-column justify-center">
                    <p el-class="notify-message" class="font-weight-bold mb-0">
                        {{ message }}
                        <a
                            v-if="linkCallback"
                            class="text-link"
                            style="color: white; font-weight: bold"
                            @click="linkCallback() && $emit('update:value', false)"
                        >
                            {{ linkMessage }}
                        </a>
                    </p>
                </v-col>
                <v-col cols="1" class="pa-0 d-flex justify-center" align-self="center">
                    <EqsBtn
                        icon
                        :el-class="`${elClass}-close`"
                        @click="$emit('update:value', false)"
                    >
                        <v-icon color="white"> mdi-close </v-icon>
                    </EqsBtn>
                </v-col>
            </v-row>
        </v-snackbar>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsSnackbar.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsSnackbar",
    components: {
        EqsBtn: () => import("../../atoms/EqsBtn/EqsBtn.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-snackbar"
        },
        message: {
            type: String,
            required: true
        },
        linkCallback: {
            type: Function,
            required: false
        },
        linkMessage: {
            type: String,
            required: false,
            default: ""
        },
        timeout: {
            type: Number,
            default: 5000
        },
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            fadeOut: false,
            hover: false,
            wasHover: false,
            counter: {},
            fadeOutSec: 3000,
            fadeInSec: 1200
        };
    },
    computed: {
        getFadeMode() {
            if (this.hover || this.wasHover)
                return this.hover ? "fade-opacity-full" : "fade-opacity-empty";
            return this.value && !this.fadeOut ? "fade-in" : "fade-out";
        },
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        timeLeftSec() {
            return this.timeout / 1000;
        },
        cssProps() {
            return {
                "--fade-in-time": `${this.fadeInSec / 1000}s`,
                "--fade-out-time": `${this.fadeOutSec / 1000}s`
            };
        }
    },
    watch: {
        value(val) {
            this.$nextTick(() => {
                val ? this.setTimer() : this.resetTimer();
            });
        }
    },
    methods: {
        resetTimer() {
            clearInterval(this.counter.interval);
            this.counter.interval = null;
            this.hover = false;
            this.wasHover = false;
        },
        setTimer() {
            this.resetTimer();
            this.counter = {
                value: 0,
                timeLeft: this.timeLeftSec
            };
            this.fadeOut = false;
            this.counter.incrementVal = 100 / this.counter.timeLeft;
            this.counter.interval = setInterval(() => {
                if (this.counter.timeLeft <= (this.fadeOutSec - 100) / 1000 && !this.fadeOut) {
                    this.fadeOut = true;
                }
                if (this.counter.timeLeft <= 0) {
                    this.$emit("update:value", false);
                    this.fadeOut = false;
                }
                if (!this.hover) {
                    this.counter.value += this.counter.incrementVal;
                    this.counter.timeLeft--;
                }
            }, 1000);
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsSnackbar.scss";
</style>
