import events from "../events";

export default {
    install(Vue, options = {}) {
        const dialog = Object.freeze({
            info: attrs => events.$emit("dialog", "info", attrs),
            confirm: attrs => events.$emit("dialog", "confirm", attrs),
            alert: attrs => events.$emit("dialog", "alert", attrs),
            warn: attrs => events.$emit("dialog", "warn", attrs)
        });
        Object.defineProperty(Vue.prototype, "$dialog", {
            get() {
                return dialog;
            }
        });
    }
};
