var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({},'EqsCard',{ loading: _vm.loading, ..._vm.$attrs },false),[_c('EqsDataTable',_vm._b({staticClass:"fill-width",attrs:{"sort-by":"investor.entity.name","sort-desc":[true]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-col',{attrs:{"cols":"auto"}},[_c('EqsTextDisplay',_vm._b({attrs:{"value":_vm.$t('governance.representatives.title')}},'EqsTextDisplay',{ eqsType: 'section' },false))],1)]},proxy:true},{key:"item.investor.entity",fn:function({ value }){return [_c('EqsAvatar',_vm._b({attrs:{"no-badge":""}},'EqsAvatar',{ user: value, eqsType: 'list' },false))]}},{key:"item.role",fn:function({ item }){return [_c('EqsChip',_vm._b({},'EqsChip',{
                    color: _vm.$color.colorFromHash(item.role),
                    textColor: 'text'
                },false),[_vm._v(" "+_vm._s(item.role)+" ")])]}},{key:"item.actions",fn:function({ item }){return [_c('EqsActions',_vm._b({},'EqsActions',{ actions: _vm.getActions(item) },false))]}}])},'EqsDataTable',{
            headers: _vm.headers,
            items: _vm.representatives,
            ..._vm.$attrs,
            loading: _vm.representativesLoading
        },false)),(_vm.editable)?_c('RepresentativeDetail',_vm._b({attrs:{"value":_vm.seeDetail},on:{"update:value":function($event){_vm.seeDetail=$event},"close":() => {
                _vm.representative = {};
            }}},'RepresentativeDetail',{ representative: _vm.representative },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }