<template>
    <v-row justify="end">
        <v-col cols="auto">
            <v-btn
                v-if="showEntityInfos"
                outlined
                class="custom-btn"
                @click.stop="openEntityInfosModal()"
            >
                <v-icon>mdi-eye-outline</v-icon>
                {{ $t("universe.kyoseil.drop-down-component.account-detail") }}
            </v-btn>
            <v-btn
                v-if="showInvestorInfos"
                outlined
                class="custom-btn"
                @click.stop="openEntityInfosModal()"
            >
                <v-icon>mdi-eye-outline</v-icon>
                {{ $t("universe.kyoseil.drop-down-component.account-detail") }}
            </v-btn>
        </v-col>
        <v-col cols="auto">
            <v-btn
                v-if="showAccountActivation"
                outlined
                class="custom-btn custom-btn-blue"
                @click.stop="accountActivationData.action"
            >
                <v-icon>{{ accountActivationData.icon }}</v-icon>
                {{ accountActivationData.title }}
            </v-btn>
            <v-btn
                v-if="showImpersonate"
                outlined
                class="custom-btn custom-btn-blue impersonate-btn"
                @click.stop="impersonate()"
            >
                <template v-if="impersonateLoading">
                    <v-progress-circular indeterminate />
                </template>
                <template v-else>
                    <v-icon>mdi-swap-horizontal</v-icon>
                    {{ $t("universe.kyoseil.client-deal.impersonate") }}
                </template>
            </v-btn>
        </v-col>
        <InvestorModal
            v-if="currentInfoEntity"
            :loading="false"
            :open.sync="investorModalOpen"
            :investor="currentInfoEntity"
            :show-orders="investorModalShowOrders"
            @closeDialog="closeEntityInfosModal"
        />
        <AccountActivationModal
            v-if="accountActivationData"
            :open.sync="selectClientDialogDisableState"
            v-bind="{
                currentInfoEntity: currentInfoEntity
            }"
            @deactivate="
                () => {
                    validateCGP('Rejected');
                }
            "
            @closeDialog="
                () => {
                    selectClientDialogDisableState = false;
                }
            "
        />
    </v-row>
</template>

<script>
import { switchRoleGlobal } from "@/App.js";
import AccountActivationModal from "../modals/AccountActivationModal.vue";
import InvestorModal from "@/components/views/user/investorsDashboard/InvestorModal";

export default {
    name: "MoreActionsMenu",
    components: { AccountActivationModal, InvestorModal },
    inject: ["_entity"],
    props: {
        idEntity: {
            type: String,
            required: true
        },
        currentInfoEntity: {
            type: Object,
            required: false,
            default: null
        },
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            impersonateLoading: false,
            selectClientDialogDisableState: false,
            accountActivationData: {},
            showAccountActivation: false,
            investorModalShowOrders: false,
            investorModalOpen: false
        };
    },
    computed: {
        showImpersonate() {
            // Only CGP can do it
            if (!this.idEntity || this.idEntity === "") return false;
            if (!["view_investor"].includes(this.type)) return false;
            if (!this.$entity.isAffiliationCgp()) return false;
            return true;
        },
        showEntityInfos() {
            if (!this.idEntity || this.idEntity === "" || !this.currentInfoEntity) return false;
            if (["view_investor"].includes(this.type)) return false;
            return true;
        },
        showInvestorInfos() {
            if (!this.idEntity || this.idEntity === "" || !this.currentInfoEntity) return false;
            if (this.$entity.isAffiliationBc()) return false;
            if (!["view_investor"].includes(this.type)) return false;
            return true;
        }
    },
    watch: {
        currentInfoEntity: {
            handler: function (oldValue, newValue) {
                if (this.currentInfoEntity && this.$entity.isAffiliationAdmin()) {
                    this.setMoreActionButtonValue();
                }
            },
            immediate: true
        }
    },
    methods: {
        async impersonate() {
            this.impersonateLoading = true;
            const newCgpRole = await this.$role.getCgpRole(this.idEntity, false);
            this.$store.commit("SET_THROUGH_ROLES", {
                throughRole: this.$store.getters.currentRole.id,
                currentRole: newCgpRole.id
            });
            await switchRoleGlobal(
                this,
                newCgpRole.entity_represented.id,
                null,
                newCgpRole.entity_represented
            );
        },
        async validateCGP(wantedStatus) {
            try {
                await this.$api.patchEntityAttributeStatus(
                    this.currentInfoEntity.attributes[0].id,
                    wantedStatus
                );
                wantedStatus === "Accepted"
                    ? this.$ui.success(
                          `${this.currentInfoEntity.name} ${this.$t(
                              "universe.kyoseil.status.success"
                          )}`
                      )
                    : this.$ui.success(
                          `${this.currentInfoEntity.name} ${this.$t(
                              "universe.kyoseil.status.rejected"
                          )}`
                      );
            } catch (error) {
                this.$ui.error(`${error.statusText}`);
            }
            this.$store.commit("SET_AFFILIATION_RESET_CACHE", true);
        },

        setMoreActionButtonValue() {
            if (!this.currentInfoEntity || !this.currentInfoEntity.attributes) {
                // Investor dont have attributes fields, or error
                return;
            }
            const status = this.currentInfoEntity.attributes[0]?.validation_status;
            if (status === "Accepted") {
                this.accountActivationData = {};
                this.accountActivationData.title = this.$t(
                    "universe.kyoseil.drop-down-component.deactivate"
                );
                this.accountActivationData.icon = "mdi-close";
                this.accountActivationData.action = () => {
                    this.selectClientDialogDisableState = true;
                };
                this.showAccountActivation = true;
            } else if (status === "Rejected") {
                this.accountActivationData = {};
                this.accountActivationData.title = this.$t(
                    "universe.kyoseil.drop-down-component.reactivate"
                );
                this.accountActivationData.icon = "mdi-play";
                this.accountActivationData.action = () => {
                    this.validateCGP("Accepted");
                };
                this.showAccountActivation = true;
            } else {
                this.showAccountActivation = false;
            }
        },
        closeEntityInfosModal() {
            this.investorModalOpen = false;
        },
        openEntityInfosModal() {
            if (this.type === "view_investor") {
                this.investorModalShowOrders = true;
            } else {
                this.investorModalShowOrders = false;
            }
            this.investorModalOpen = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.impersonate-btn {
    min-width: 184px !important;
}
</style>
