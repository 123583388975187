import { render, staticRenderFns } from "./GlobalDocWithCategoryList.vue?vue&type=template&id=cc7333f4&scoped=true"
import script from "./GlobalDocWithCategoryList.vue?vue&type=script&lang=js"
export * from "./GlobalDocWithCategoryList.vue?vue&type=script&lang=js"
import style0 from "./GlobalDocWithCategoryList.vue?vue&type=style&index=0&id=cc7333f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc7333f4",
  null
  
)

export default component.exports