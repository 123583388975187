var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-lazy',[_c('v-container',{staticClass:"align-start pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"custom-btn",attrs:{"outlined":""},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.$t("movements.add"))+" ")])],1)],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',[_c('DataTablePaginated',{attrs:{"loading":_vm.loading || _vm.localLoading,"headers":_vm.headers,"items":_vm.items,"sort-desc":"","search":"shareledger_name"},on:{"update":_vm.fetchMovements},scopedSlots:_vm._u([{key:`item.date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$time.formated(item.date))+" ")]}},{key:`item.operation`,fn:function({ item }){return [_c('EqsChip',{attrs:{"color":_vm.$color.colorFromHash(item.operation),"el-class":"tx-details-operation"}},[_vm._v(" "+_vm._s(_vm.movement_operations[item.operation].txt)+" ")])]}},{key:`item.pricepershare`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency(item.pricepershare, _vm.getCurrency(item)))+" ")]}},{key:`item.numberofshares`,fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$currency.display(value, "0,0"))+" ")]}},{key:`item.movement_number`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.movement_number ? item.movement_number : "-")+" ")]}},{key:`item.shareledger_name`,fn:function({ value }){return [_vm._v(" "+_vm._s(value)+" ")]}},{key:`item.entity_source_name`,fn:function({ item }){return [_c('div',{staticClass:"cell d-flex flex-row align-center"},[(item.entity_source)?_c('EqsAvatar',_vm._b({staticClass:"pr-1"},'EqsAvatar',{
                                    user: item.entity_source,
                                    noBadge: true
                                },false)):_vm._e(),_vm._v(" "+_vm._s(item.entity_source?.name || "-")+" ")],1)]}},{key:`item.entity_destination_name`,fn:function({ item }){return [_c('div',{staticClass:"cell d-flex flex-row align-center"},[(item.entity_destination)?_c('EqsAvatar',_vm._b({staticClass:"pr-1"},'EqsAvatar',{
                                    user: item.entity_destination,
                                    noBadge: true
                                },false)):_vm._e(),_vm._v(" "+_vm._s(item.entity_destination?.name || "-")+" ")],1)]}},{key:`item.state`,fn:function({ value }){return [_c('EqsChip',_vm._b({},'EqsChip',_vm.states[value],false),[_vm._v(" "+_vm._s(_vm.states[value].txt)+" ")])]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }