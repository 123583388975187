<template>
    <EqsCrud
        :items.sync="docsProxy"
        v-bind="{
            label,
            add: item => item,
            update: item => item,
            elClass: 'additional-docs',
            deleteFunc: deletable ? () => {} : null,
            formInit,
            loading,
            editable
        }"
    >
        <template #edit="editProps">
            <Form v-bind="{ ...$props, ...editProps }" />
        </template>
        <template #create="createProps">
            <Form v-bind="{ ...$props, ...createProps }" />
        </template>
    </EqsCrud>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import Form from "./Form.vue";

export default {
    name: "AdditionalDocsCrud",
    components: {
        Form
    },
    mixins: [formMixin],
    props: {
        label: {
            type: String,
            required: true
        },
        deletable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            formInit: {
                type: "",
                description: "",
                required: false
            }
        };
    },
    computed: {
        docsProxy: {
            get() {
                return this.formData.docs.map(doc => {
                    return {
                        ...doc,
                        title: doc.type
                    };
                });
            },
            set(val) {
                this.$set(this.formData, "docs", val);
            }
        }
    }
};
</script>
