export const MAX_SIZE_FILE = 15 * 1024 * 1024;
export const MAX_SIZE_IMG = 3 * 1024 * 1024;

// sizes ref here: https://vuetifyjs.com/en/customization/breakpoints/

export const sizes = {
    xs: {
        id: "xs",
        width: 600,
        height: 812,
        type: "mobile"
    },
    sm: {
        id: "sm",
        width: 960,
        height: 1112,
        type: "tablet"
    },
    md: {
        id: "md",
        width: 1264,
        height: 900,
        type: "desktop"
    },
    lg: {
        id: "lg",
        width: 1904,
        height: 1080,
        type: "desktop"
    },
    xl: {
        id: "xl",
        width: Number.MAX_SAFE_INTEGER,
        height: 2160,
        type: "desktop"
    }
};

function getSizeTypes(sizes) {
    let types = [];
    for (const size of Object.values(sizes)) {
        if (!!size && !types.includes(size.type)) types.push(size.type);
    }
    return types;
}

export const sizesKeys = Object.keys(sizes);
export const sizeTypes = getSizeTypes(sizes);
