import { convertObjToCssVar, toType } from "../../../shared/util";
import _ from "lodash";

export function isArrayStrOrStr(val) {
    var eqsType = toType(val);
    if (eqsType === "string") return true;
    else if (eqsType === "array") {
        if (_.every(val, val => typeof val === "string")) return true;
    }
    return false;
}

export const cssGradient = {
    computed: {
        cssGradient() {
            const gradient = this.getData.cssGradient;
            return !!gradient
                ? {
                      "--deg": gradient.deg,
                      "--col1": gradient.col1,
                      "--col2": gradient.col2
                  }
                : {};
        }
    }
};

export const bgColor = {
    props: {
        color: {
            type: String,
            default: ""
        }
    },
    computed: {
        bgColor() {
            let res = {};
            if (this.color) res["--background-color"] = this.color;
            return res;
        }
    }
};

export const eqsUITemplate = {
    props: {
        eqsType: {
            default: "default",
            validator: val => {
                if (_.isEmpty(val)) console.error(`"eqsType" shouldn't be empty`);
                if (!isArrayStrOrStr(val)) {
                    console.error(
                        `"eqsType" should be either a string or an array of string and it's an "${toType(
                            val
                        )}":\n${val}`
                    );
                    return false;
                }
                return !_.isEmpty(val) ? true : false;
            }
        },
        eqsDataType: {
            default: "",
            validator: val => {
                if (!val) return true;
                if (!isArrayStrOrStr(val)) {
                    console.error(
                        `"eqsDataType" should be either a string or an array of string and it's an "${toType(
                            val
                        )}":\n ${val}`
                    );
                    return false;
                }
                if (_.isEmpty(val)) {
                    console.error(`"eqsType" shouldn't have an empty array`);
                }
                return !_.isEmpty(val) ? true : false;
            }
        }
    },
    data() {
        return {
            typeIsArray: false,
            dataTypeIsArray: false
        };
    },
    computed: {
        getData() {
            let data = {};
            if (!this.checkTypes(this.eqsDataType, true)) {
                return data;
            }
            const dataTypes = this.dataTypes(this.$reactive.getSize(), this);
            if (!_.isEmpty(dataTypes) && _.isString(this.eqsType) && _.isString(this.eqsDataType)) {
                const res = dataTypes[this.eqsDataType || this.eqsType];
                data = res;
            } else if (this.eqsDataType) {
                data = this.dataTypeIsArray
                    ? this.mergeObjectsFromKeys(dataTypes, this.eqsDataType)
                    : dataTypes[this.eqsDataType];
            }
            return data ? data : {};
        },
        getStyle() {
            if (!this.checkTypes(this.eqsType)) return;
            const cssTypes = this.cssTypes(this.$reactive.getSize(), this);
            const cssType = this.typeIsArray
                ? this.mergeObjectsFromKeys(cssTypes, this.eqsType)
                : cssTypes[this.eqsType];
            const cssVar = convertObjToCssVar(cssType);
            return cssVar ? cssVar : {};
        }
    },
    methods: {
        mergeObjectsFromKeys(objects, keys) {
            let res = {};
            const arrayVals = Object.entries(objects).map(obj => {
                if (keys.includes(obj[0])) return obj[1];
            });
            _.merge(res, ...arrayVals);
            return res;
        },
        checkTypes(val, eqsDataType = false) {
            const kind = eqsDataType ? "eqsDataType" : "eqsType";
            if (eqsDataType) {
                if (!val && this.dataTypes) return true;
                if (!this.dataTypes) return false;
            }
            if (!eqsDataType && !val) {
                console.error(`error in "${this.$options.name}", you have no "cssTypes" defined in your component,\
        please create one, import it and reference it in your data as cssTypes`);
            }
            const isArrayString = _.isArray(val) && _.every(val, String);
            this[eqsDataType ? "dataTypeIsArray" : "typeIsArray"] = isArrayString;
            const keys = Object.keys(
                eqsDataType
                    ? this.dataTypes(this.$reactive.getSize(), {})
                    : this.cssTypes(this.$reactive.getSize(), {})
            );
            if (isArrayString) {
                for (const key of val) {
                    if (!keys.includes(key)) {
                        console.error(
                            `error in "${this.$options.name}", "${kind}" have wrong property "${key}"`
                        );
                        return false;
                    }
                }
            } else {
                if (!keys.includes(val)) {
                    console.error(
                        `error in "${this.$options.name}", "${kind}" have wrong property "${val}"`
                    );
                    return false;
                }
            }
            return true;
        }
    }
};
