import init from "./init";
import { getIbanStatus } from "@/util/bankSchema.js";

export default {
    SET_MOVEMENTS(state, movements) {
        state.movements = movements;
        state.movementsInit = false;
    },
    SET_MOVEMENTS_LOADING(state, bool) {
        state.movementsLoading = bool;
    },
    SET_SEQUESTRE_MOVEMENTS(state, sequestreMovements) {
        state.sequestreMovements = sequestreMovements;
        state.sequestreMovementsInit = false;
    },
    SET_SEQUESTRE_MOVEMENTS_LOADING(state, bool) {
        state.sequestreMovementsLoading = bool;
    },
    SET_BALANCE(state, res) {
        const { balance } = res;
        state.balance = balance;
        state.balanceInit = false;
        // if balance changed, so it should be reinit
        state.movementsInit = true;
    },
    SET_BALANCE_INIT(state, bool) {
        state.balanceInit = bool;
    },
    SET_BALANCE_LOADING(state, bool) {
        state.balanceLoading = bool;
    },
    SET_SEQUESTRE(state, res) {
        const { sequestre } = res;
        state.sequestre = sequestre;
        state.sequestreInit = false;
        // if sequestre changed so it should be reinit
        state.sequestreMovementsInit = true;
    },
    SET_SEQUESTRE_LOADING(state, bool) {
        state.sequestreLoading = bool;
    },
    SET_IBAN(state, iban) {
        const status = getIbanStatus(iban);
        state.iban = iban
            ? {
                  ...iban,
                  status
              }
            : null;
        state.ibanInit = false;
    },
    SET_IBAN_INIT(state, bool) {
        state.ibanInit = bool;
    },
    SET_IBAN_LOADING(state, bool) {
        state.ibanLoading = bool;
    },
    SET_VIBAN(state, viban) {
        state.viban = viban;
        state.vibanInit = false;
    },
    SET_VIBAN_LOADING(state, bool) {
        state.vibanLoading = bool;
    },
    RESET_STORE_WALLET(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
