var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({},'EqsCard',{ loading: _vm.loading, ..._vm.$attrs },false),[_c('EqsDataTable',_vm._b({attrs:{"selected":_vm.selectedOrganProxy,"sort-by":"name","sort-desc":[true],"single-select":true},on:{"update:selected":function($event){_vm.selectedOrganProxy=$event},"click:row":item => (_vm.selectedOrganProxy = [item])},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-col',{attrs:{"cols":"auto"}},[_c('EqsTextDisplay',_vm._b({attrs:{"value":_vm.$t('governance.organs.title')}},'EqsTextDisplay',{ eqsType: 'section' },false))],1)]},proxy:true},{key:"item.members",fn:function({ value }){return [_c('EqsMultiAvatars',_vm._b({},'EqsMultiAvatars',{
                    loading: _vm.loading,
                    users: value.map(mb => mb.investor.entity)
                },false))]}},{key:"item.board",fn:function({ value }){return [_c('EqsMultiAvatars',_vm._b({},'EqsMultiAvatars',{
                    loading: _vm.loading,
                    users: value.map(mb => {
                        const entity = mb.representative.investor.entity;
                        return {
                            ...entity,
                            name: `${mb.representative.role} (${entity.name})`
                        };
                    })
                },false))]}},{key:"item.vote_type",fn:function({ value }){return [(value)?_c('EqsChip',_vm._b({},'EqsChip',{ color: _vm.$color.colorFromHash(value), textColor: 'text' },false),[_vm._v(" "+_vm._s(_vm.vote_types[value].text)+" ")]):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [_c('EqsActions',_vm._b({},'EqsActions',{ actions: _vm.getActions(item) },false))]}}])},'EqsDataTable',{
            headers: _vm.headers,
            items: _vm.organs.map(organ => {
                return {
                    ...organ,
                    members: organ.members.filter(member => member.investor),
                    board: organ.members.filter(member => member.representative)
                };
            }),
            ..._vm.$attrs,
            loading: _vm.organsLoading || _vm.decisionsLoading
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }