var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',{attrs:{"title":_vm.company.name,"avatar":_vm.company.avatar,"is-company":true,"loading":_vm.loading,"closable":""},on:{"close":() => _vm.$emit('update:company', null)}},[(!_vm.loading)?_c('EqsPanels',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [(item.is != 'EqsLine')?_c(item.is,_vm._b({tag:"component",attrs:{"profile":""}},'component',{
                    ...item.props,
                    countries: [],
                    loading: _vm.localLoading,
                    formData: _vm.company
                },false)):(
                    item.props.data &&
                    item.props.data.labels &&
                    item.props.data.labels.length > 0
                )?_c('EqsLine',{attrs:{"config":item.props.data,"format-dataset-labels":data => _vm.formatDataValue(data, item)}}):_c('v-flex',{staticClass:"text-xs-center mb-2 no-data-text mt-2"},[_vm._v(" "+_vm._s(_vm.$t("portfolio.no-data-to-display"))+" ")])]}}],null,false,1576694874)},'EqsPanels',{ panels: _vm.panels, value: [] },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }