<template>
    <div>
        <v-row>
            <v-col :cols="12">
                <EqsTextDisplay value="Table de capitalisation" eqs-type="subtitle" />
            </v-col>
        </v-row>

        <v-row class="mt-3" no-gutters>
            <v-col :cols="3">
                <EqsTextDisplay value="Récapitulatif" eqs-type="bodyBold" />
            </v-col>

            <v-col :cols="3">
                <EqsTextDisplay value="Prix" eqs-type="bodyBold" />
            </v-col>

            <v-col :cols="3">
                <EqsTextDisplay value="Quantité" eqs-type="bodyBold" />
            </v-col>

            <v-col :cols="3">
                <EqsTextDisplay value="Total value" eqs-type="bodyBold" />
            </v-col>
        </v-row>

        <v-divider class="my-1" />

        <div v-if="!loading">
            <v-row v-for="item in items" :key="item.date" no-gutters>
                <v-col :cols="3">
                    <EqsLabel
                        style="width: fit-content"
                        :editable="false"
                        :value="item.name"
                        :info-txt="item.info"
                        v-bind="{ loading }"
                    />
                </v-col>

                <v-col :cols="3">
                    <EqsTextDisplay
                        :value="$currency.displayWithCurrency(item.price)"
                        eqs-type="bodyBold"
                        v-bind="{ loading }"
                    />
                </v-col>

                <v-col :cols="3">
                    <EqsTextDisplay
                        :value="item.quantity.toString()"
                        eqs-type="bodyBold"
                        v-bind="{ loading }"
                    />
                </v-col>

                <v-col :cols="3">
                    <EqsTextDisplay
                        :value="$currency.displayWithCurrency(item.price * item.quantity)"
                        eqs-type="bodyBold"
                        v-bind="{ loading }"
                    />
                </v-col>
            </v-row>
        </div>

        <v-row no-gutters>
            <v-col :cols="3">
                <EqsTextDisplay value="Total" eqs-type="bodyBold" v-bind="{ loading }" />
            </v-col>

            <v-col :cols="3">
                <EqsTextDisplay value="" eqs-type="bodyBold" v-bind="{ loading }" />
            </v-col>

            <v-col :cols="3">
                <EqsTextDisplay
                    :value="$currency.displayWithCurrency(totalQuantity)"
                    eqs-type="bodyBold"
                    v-bind="{ loading }"
                />
            </v-col>

            <v-col :cols="3">
                <EqsTextDisplay
                    :value="$currency.displayWithCurrency(totalValue)"
                    eqs-type="bodyBold"
                    v-bind="{ loading }"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "CompanyDetailsCapTable",
    props: {
        items: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        totalQuantity() {
            return this.items.reduce((total, { quantity = 0 }) => total + quantity, 0);
        },
        totalValue() {
            return this.items.reduce(
                (total, { price = 0, quantity = 0 }) => total + price * quantity,
                0
            );
        }
    }
};
</script>

<style lang="scss" scoped></style>
