import Vue from "vue";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";

import App from "@/App.vue";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import { EqsLib } from "@/eqsLib/_eqsLib";
import { isProd } from "@/assets/constants";

import vuetify from "./services/plugins/vuetify";

import VueRouter from "vue-router";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

const Eqs = new EqsLib(Vue);

const errorHandler = async (error, vm, info) => {
    if (isNavigationFailure(error, NavigationFailureType.duplicated)) return;
    else if (
        isNavigationFailure(error, NavigationFailureType.redirected) ||
        isNavigationFailure(error, NavigationFailureType.aborted) ||
        isNavigationFailure(error, NavigationFailureType.cancelled)
    ) {
        if (!isProd())
            console.log({
                isNavigationFailure: error
            });
        return;
    } else
        console.error({
            error,
            vm,
            info
        });
};

Vue.config.errorHandler = (error, vm, info) => errorHandler(error, vm, info);

window.addEventListener("error", event => {
    event.preventDefault();
    errorHandler(event.error || event);
});

window.addEventListener("unhandledrejection", event => {
    event.preventDefault();
    errorHandler(event.reason);
});

Vue.config.silent = false;
Vue.config.devtools = true;
Vue.config.productionTip = false;
if (window && (!window.location.port || parseInt(window.location.port) < 8000)) {
    Sentry.init({
        dsn: "https://8c48b1eee60d4dd2b22ceb13c31d989b@o315231.ingest.sentry.io/1795286",
        environment: "JS.Browser",
        integrations: [
            new Integrations.Vue({
                Vue,
                attachProps: true
            })
        ],
        ignoreErrors: ["Non-Error exception captured"],
        beforeSend(event, hint) {
            const error = hint.originalException;
            if (!error) return event;
            else if (
                isNavigationFailure(error, NavigationFailureType.duplicated) ||
                isNavigationFailure(error, NavigationFailureType.redirected) ||
                isNavigationFailure(error, NavigationFailureType.aborted) ||
                isNavigationFailure(error, NavigationFailureType.cancelled)
            )
                return null;
            return event;
        }
    });
}

/*
 ** This global filter is used to limit the size of a string
 */

Vue.filter("truncate", function (value, limit = 20) {
    if (!value) return "";
    if (value.length > limit) {
        value = value.substring(0, limit - 3) + "...";
    }
    return value;
});

const services = Eqs.setup();

const app = new Vue({
    ...services,
    vuetify,
    render: h => h(App)
}).$mount("#app");

window.app = app;
