<template>
    <div class="ma-0 pa-0">
        <div class="text-subtitle">{{ $t("projects.reporting.crud.document.title") }}</div>
        <EqsFileInput
            v-for="(doc, index) in files"
            :key="index"
            :value="doc"
            v-bind="{
                editable: true,
                deleteFile: true,
                isRequired: false
            }"
            @update:value="val => newFile(val, index)"
        />
    </div>
</template>

<script>
export default {
    name: "FormDocument",
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            files: [null]
        };
    },
    beforeMount() {
        if (this.field?.documents?.length) {
            this.files = [...this.field.documents, null];
        }
    },
    methods: {
        newFile(val, index) {
            // on remplace en declenchant le reactive, d'ou le splice
            if (!val) {
                // On supprime
                this.files.splice(index, 1);
                Object.assign(this.field, { documents: this.files });
            } else {
                // On ajoute
                this.files.splice(index, 1, val);
                Object.assign(this.field, { documents: this.files });
                this.addFileInput();
            }
        },
        addFileInput() {
            // Push null car EqsFileInput attend un objet, null si pas de doc
            this.files.push(null);
        }
    }
};
</script>

<style lang="scss" scoped>
.btn-no-uppercase {
    text-transform: none;
    font-size: 0.8rem;
}
</style>
