var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDataTable',_vm._b({attrs:{"footer-props":{
        itemsPerPageOptions: [30, 60]
    },"items-per-page":30},scopedSlots:_vm._u([{key:"item.shares",fn:function({ value }){return [_c('div',{staticClass:"d-flex"},_vm._l((value),function(share,index){return _c('EqsChip',{key:index,staticClass:"mx-1",attrs:{"text-color":"text","color":_vm.$color.colorFromHash(share.name)},scopedSlots:_vm._u([{key:"alt",fn:function(){return [_vm._v(" "+_vm._s(`${_vm.$currency.display(share.numberofshares, "0,0")} ${ share.symbol ? share.symbol.toUpperCase() : "" }`)+" ")]},proxy:true}],null,true)})}),1)]}},{key:"item.percent",fn:function({ value }){return [_vm._v(" "+_vm._s(!!value ? _vm.$currency.percent(value, "0.00%") : "-")+" ")]}},{key:"item.amount",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency( value ? Math.abs(value) : 0.0, _vm.$entity.represented().country.alphacode == "US" ? "USD" : "EUR" ))+" ")]}},{key:"other",fn:function(){return [_c('div',{staticClass:"d-flex justify-end",staticStyle:{"margin-right":"5px"}},[_c('EqsBtn',_vm._b({staticClass:"pa-0",attrs:{"text":"","el-class":"download-operations-data"}},'EqsBtn',{
                    infoTxt: _vm.$t('shareledger.asset-movement-history.download-btn'),
                    action: _vm.downloadOperationsData
                },false),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-file-download-outline ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function({ item, index }){return [_c('EqsActions',_vm._b({},'EqsActions',{
                actions: _vm.getActions(item),
                elClass: `item-${index}-actions`
            },false))]}}])},'EqsDataTable',{
        searchable: {
            placeholder: _vm.$t('capitalisation.general-view.search-investor')
        },
        ..._vm.$attrs
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }