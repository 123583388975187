<template>
    <v-btn
        v-if="isInvestor"
        class="custom-btn font-weight-bold"
        outlined
        color="primary"
        @click="backToCgp"
    >
        <v-icon color="primary" class="mr-4">mdi-arrow-left-bold-circle</v-icon>
        {{ $t("universe.kyoseil.navbar.cta-back-to-cgp") }}
    </v-btn>
    <v-btn
        v-else-if="isEntityAffiliation && isKycOk"
        class="custom-btn font-weight-bold mr-8"
        outlined
        color="primary"
        @click="goToAffiliations"
    >
        <v-icon color="primary">mdi-briefcase-plus</v-icon>
        <span class="hide-when-little-screen">{{ $t("universe.kyoseil.navbar.cta-info") }}</span>
    </v-btn>
</template>

<script>
import { switchRoleGlobal } from "@/App.js";

export default {
    name: "BtnNavBarKyoseil",
    components: {},
    inject: ["_entity"],
    data() {
        return {
            isInvestor: false,
            isEntityAffiliation: false,
            isKycOk: false
        };
    },

    watch: {
        "$store.getters.currentRole": {
            handler: function (val) {
                if (val.role_name === "cgp") {
                    this.isInvestor = true;
                    this.isEntityAffiliation = false;
                } else {
                    this.isInvestor = false;
                    this.isEntityAffiliation = this.$entity.hasAffiliationRole();
                }
                this.isKycOk = ["upload", "pending", "upload-failure", "success"].includes(
                    this._entity?.lemonway_state
                );
            },
            immediate: true
        }
    },
    methods: {
        async goToAffiliations() {
            if (this.$route.name !== "affiliation") {
                await this.$router.push({ name: "affiliation" });
            }
        },
        backToCgp() {
            const myRoles = this.$store.getters.myRoles;
            const currentRole = this.$store.getters.currentRole;
            let newRole = myRoles.find(role => {
                return role.entity_represented.id === currentRole.user.id;
            });
            this.$store.commit("SET_THROUGH_ROLES", null);
            switchRoleGlobal(
                this,
                newRole.entity_represented.id,
                { name: "affiliation" },
                newRole.entity_represented
            );
        }
    }
};
</script>

<style scoped lang="scss">
.custom-btn {
    margin: 0;
    text-transform: none;
    align-self: center;
}
.hide-when-little-screen {
    margin-left: 4px;
    @media only screen and (max-width: 960px) {
        margin-left: 0;
        display: none;
    }
}
</style>
