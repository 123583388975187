import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, decision) => {
    DEBUG("DELETE_DECISION_START");
    try {
        commit("SET_DECISIONS_LOADING", true);
        // console.log('DECISION: ', decision);
        await window.app.$api.deleteDecision(decision.id);
        commit("DELETE_DECISION", decision.id);
        DEBUG("DELETE_DECISION_SUCCESS");
    } catch (e) {
        DEBUG("DELETE_DECISION_ERROR");
        throw e;
    } finally {
        commit("SET_DECISIONS_LOADING", false);
    }
};
