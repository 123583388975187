var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`eqs-select-required-docs ${_vm.elClass}-container`,style:(_vm.getStyle)},[_c('EqsLabel',_vm._b({attrs:{"el-class":`${_vm.elClass}-label`}},'EqsLabel',{
            value: _vm.label,
            labelHeight: _vm.labelHeight,
            editable: _vm.editable,
            infoTxt: _vm.infoTxt,
            isRequired: _vm.isRequired
        },false),[_c('EqsList',_vm._b({staticClass:"eqs-select-required-docs__list",attrs:{"el-class":`${_vm.elClass}-list`,"items":_vm.items,"two-lines":""},on:{"update:items":function($event){_vm.items=$event}},scopedSlots:_vm._u([{key:"add",fn:function({ addItem }){return [_c('v-row',{staticClass:"eqs-select-required-docs__list__add",attrs:{"justify":"center","align":"center","no-gutters":""}},[(_vm.categories.length > 1)?_c('v-col',{staticClass:"mx-2 py-3",attrs:{"cols":"12"}},[_c('EqsSelect',_vm._b({attrs:{"return-object":"","clearable":false,"value":_vm.newDoc.category,"placeholder":_vm.$t('molecule.form.require-doc.label-chose')},on:{"update:value":function($event){return _vm.$set(_vm.newDoc, "category", $event)}}},'EqsSelect',{
                                items: _vm.categoriesLeft,
                                loading: _vm.loading || _vm.localLoading,
                                isRequired: _vm.isRequired,
                                elClass: `${_vm.elClass}-select-category`
                            },false))],1):_vm._e(),(_vm.newDoc.category && _vm.newDoc.category.multiple)?_c('v-col',{class:`mx-2 py-3 ${_vm.$reactive.isDesktop() ? 'mt-2' : ''}`,attrs:{"cols":"12"}},[_c('EqsCheckbox',_vm._b({staticClass:"mt-0 pt-0",attrs:{"el-class":"select-multiple","value":_vm.multiple,"is-required":false},on:{"update:value":function($event){_vm.multiple=$event}}},'EqsCheckbox',{
                                text: _vm.newDoc.category.checkboxTxt,
                                loading: _vm.loading || _vm.localLoading
                            },false))],1):_vm._e(),(_vm.newDoc.category && _vm.newDoc.category.multiple && _vm.multiple)?_c('v-row',{class:`d-flex px-3 justify-start ${
                            _vm.$reactive.isMobile() ? 'flex-wrap' : ''
                        }`,attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"py-3",attrs:{"cols":`${_vm.$reactive.isMobile() ? 12 : 6}`}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                                    value:
                                        _vm.newDoc.category.multiple && _vm.multiple
                                            ? _vm.newDoc.category.labelRecto
                                            : _vm.newDoc.category.label,
                                    isRequired: _vm.isRequired
                                },false),[_c('EqsUpload',_vm._b({attrs:{"el-class":"upload-new-doc","auto-upload":false,"value":_vm.newDoc.frontFile},on:{"update:value":function($event){return _vm.$set(_vm.newDoc, "frontFile", $event)}}},'EqsUpload',{
                                        ..._vm.uploadProps,
                                        multiple: false,
                                        loading: _vm.loading,
                                        placeholder: _vm.$t(
                                            'equisafe-ui.file-input.placeholder-singular'
                                        ),
                                        others: _vm.newDoc.category.others,
                                        loading: _vm.localLoading || _vm.loading
                                    },false))],1)],1),_c('v-col',{staticClass:"py-3",attrs:{"cols":`${_vm.$reactive.isMobile() ? 12 : 6}`}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                                    value: _vm.newDoc.category.labelVerso,
                                    isRequired: _vm.isRequired
                                },false),[_c('EqsUpload',_vm._b({attrs:{"el-class":"upload-new-doc-back","value":_vm.newDoc.backFile,"auto-upload":false},on:{"update:value":function($event){return _vm.$set(_vm.newDoc, "backFile", $event)}}},'EqsUpload',{
                                        ..._vm.uploadProps,
                                        multiple: false,
                                        loading: _vm.loading,
                                        placeholder: _vm.$t(
                                            'equisafe-ui.file-input.placeholder-singular'
                                        ),
                                        others: _vm.newDoc.category.others,
                                        isRequired: _vm.isRequired,
                                        loading: _vm.localLoading || _vm.loading
                                    },false))],1)],1)],1):(_vm.newDoc.category)?_c('v-col',{staticClass:"mx-2 py-3",attrs:{"cols":"12"}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                                value:
                                    _vm.newDoc.category.multiple && _vm.multiple
                                        ? _vm.newDoc.category.labelRecto
                                        : _vm.newDoc.category.label,
                                isRequired: _vm.isRequired
                            },false),[_c('EqsUpload',_vm._b({attrs:{"el-class":"upload-new-doc","value":_vm.newDoc.file},on:{"update:value":[function($event){return _vm.$set(_vm.newDoc, "file", $event)},doc => _vm.addDoc(doc, addItem)],"isLoading":isLoading => (_vm.localLoading = isLoading)}},'EqsUpload',{
                                    ..._vm.uploadProps,
                                    multiple: false,
                                    upload: _vm.uploadFunc,
                                    loading: _vm.loading,
                                    placeholder: _vm.$t(
                                        'equisafe-ui.file-input.placeholder-singular'
                                    ),
                                    others: _vm.newDoc.category.others,
                                    isRequired: _vm.isRequired,
                                    loading: _vm.localLoading || _vm.loading
                                },false))],1)],1):_vm._e()],1)]}},{key:"item",fn:function(props){return [_c('EqsFileActions',_vm._b({},'EqsFileActions',{
                        ...props,
                        elClass: `${_vm.elClass}-${props.index}`,
                        editable: _vm.editable,
                        removeItem: _vm.removeDoc,
                        deleteFunc: _vm.deleteFunction
                    },false))]}},{key:"item-append",fn:function({ item, index }){return [(item.lw && _vm.editable)?_c('EqsAlert',_vm._b({staticClass:"font-weight-bold",attrs:{"outlined":"","small":_vm.$reactive.isMobile(),"label":""}},'EqsAlert',{ ...item.lw, elClass: `${_vm.elClass}-${index}-status` },false)):_vm._e()]}}])},'EqsList',{
                errorMessages: _vm.selectRemainingDocs,
                loading: _vm.localLoading || _vm.loading,
                editable: _vm.editable && !!_vm.categoriesLeft.length && !_vm.allDocsSelected
            },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }