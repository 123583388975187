<template>
    <p>{{ field }}</p>
</template>

<script>
export default {
    name: "FieldImage",
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped></style>
