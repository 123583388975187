export const docs_kyc_mandatory = (ctx, cancelable = false) => [
    {
        key: "doc_idcard",
        text: ctx.$t("registration.id-card.front-back.txt"),
        cancelable,
        label: ctx.$t("registration.id-card.front-back.label"),
        labelRecto: ctx.$t("registration.id-card.front-back.label-recto"),
        labelVerso: ctx.$t("registration.id-card.front-back.label-verso"),
        checkboxTxt: ctx.$t("registration.id-card.checkbox"),
        others: {
            lemonway_type: 0,
            key: "doc_idcard"
        },
        multiple: true
    },
    {
        key: "doc_passport",
        text: ctx.$t("registration.passport"),
        cancelable,
        label: ctx.$t("registration.passport"),
        others: {
            key: "doc_passport",
            lemonway_type:
                !!ctx.entity && !!ctx.entity.nationality && ctx.entity.nationality.is_eu ? 3 : 4
        }
    }
];

export const docs_kyc = (ctx, cancelable = false) => [
    ...docs_kyc_mandatory(ctx, cancelable),
    {
        key: "doc_license",
        text: ctx.$t("registration.driver-licence.front-back.txt"),
        label: ctx.$t("registration.driver-licence.front-back.label"),
        labelRecto: ctx.$t("registration.driver-licence.front-back.label-recto"),
        labelVerso: ctx.$t("registration.driver-licence.front-back.label-verso"),
        checkboxTxt: ctx.$t("registration.driver-licence.checkbox"),
        others: {
            lemonway_type: 11,
            key: "doc_license"
        },
        multiple: true
    },
    {
        key: "doc_notice_of_assessment",
        text: ctx.$t("registration.notice-of-assessment.txt"),
        cancelable,
        label: ctx.$t("registration.notice-of-assessment.label"),
        others: {
            lemonway_type: 6,
            key: "doc_notice_of_assessment"
        }
    },
    {
        key: "doc_residence_permit",
        text: ctx.$t("registration.residence-permit.txt"),
        cancelable,
        label: ctx.$t("registration.residence-permit.label"),
        others: {
            lemonway_type: 5,
            key: "doc_residence_permit"
        }
    }
];
