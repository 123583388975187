export class PollService {
    constructor() {
        this.polls = {};
    }
    stop(key) {
        const poll = this.polls[key];
        // if (!poll)
        // console.error(`cannot stop poll process with key '${key}', not found !`);
        if (poll) {
            clearTimeout(poll.id);
            poll.stop = true;
        }
    }
    isRunning(key) {
        if (this.polls[key] && this.polls[key].stop) {
            // force remove the poller, removal in executepoll might not have occured already
            this.polls[key] = null;
        }
        if (!!this.polls[key]) console.warn(`poll ${key} is already running`);
        return !!this.polls[key];
    }
    async asyncCall(key, fn, args, validate, interval, timeout) {
        const poll = async ({ pollCtrl, fn, args, validate, interval, endTime }) => {
            const executePoll = async (resolve, reject) => {
                try {
                    //console.log('polling ',key);
                    const result = await fn(args);
                    if (validate(result) || pollCtrl.stop) {
                        this.polls[key] = null;
                        return resolve(result);
                    } else if (Number(new Date()) > endTime) {
                        this.polls[key] = null;
                        return reject(new Error("timed out for " + fn + ": " + arguments));
                    } else {
                        pollCtrl.id = setTimeout(executePoll, interval, resolve, reject);
                    }
                } catch (e) {
                    this.polls[key] = null;
                    reject(e);
                }
            };
            return new Promise(executePoll);
        };

        if (!key) console.error("key is mandatory !");
        else if (this.isRunning(key))
            console.error(`a process with the key '${key}' is already running !
    This poll will not be triggered, please stop the process before relaunching a new one or choose an other key`);
        else {
            this.polls[key] = {
                stop: false,
                id: ""
            };
            var pollCtrl = this.polls[key];
            const endTime = Number(new Date()) + (timeout || 2000);
            try {
                const res = await poll({
                    pollCtrl,
                    fn,
                    args,
                    validate,
                    interval,
                    endTime
                });
                return res;
            } catch (e) {
                throw e;
            }
        }
    }
}
