var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-avatar-upload",attrs:{"el-class":_vm.elClass}},[_c('EqsLoader',_vm._b({},'EqsLoader',{ loading: _vm.localLoading, size: _vm.getData.size },false),[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[(!!_vm.label)?_c('EqsTextDisplay',{attrs:{"eqs-type":"label","value":_vm.label}}):_vm._e(),_c('v-input',{attrs:{"value":"","error-messages":_vm.errorMessages,"dense":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"ma-0 pa-0"},[_c('EqsAvatar',_vm._b({staticClass:"eqs-avatar-upload__avatar",attrs:{"gradient":hover
                                    ? 'rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)'
                                    : ''},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('EqsUpload',_vm._b({staticClass:"eqs-avatar-upload__input",attrs:{"value":_vm.valueProxy,"error-messages":_vm.errorMessages,"accept":"image/*","background-color":"transparent","prepend-icon":"","hide-details":""},on:{"update:value":function($event){_vm.valueProxy=$event},"update:errorMessages":function($event){_vm.errorMessages=$event},"update:error-messages":function($event){_vm.errorMessages=$event},"isLoading":val => (_vm.localLoading = val)},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true}],null,true)},'EqsUpload',{
                                        ..._vm.$props,
                                        ..._vm.$attrs,
                                        label: '',
                                        placeholder: ''
                                    },false))]},proxy:true}],null,true)},'EqsAvatar',{
                                eqsType: 'upload',
                                user: _vm.user,
                                loading: _vm.loading,
                                src: _vm.valueProxy.upload
                            },false))],1)]}}])})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }