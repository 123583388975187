<template>
    <v-container v-if="$entity.isUniverseAdmin()" fluid>
        <v-row dense>
            <v-col>
                <p>
                    Retrouvez sur cet espace la liste de vos investisseurs, leurs informations,
                    documents et ordres.
                </p>
            </v-col>
            <!-- <v-col>
                <v-btn :loading="localLoading" @click="() => fetchData(queriesCache)">
                    Refresh
                </v-btn>
            </v-col> -->
        </v-row>
        <v-row dense>
            <v-col>
                <DataTablePaginated
                    :loading="loading || localLoading"
                    :headers="headers"
                    :items="items"
                    sort-by="created_at"
                    search="name"
                    sort-desc
                    show-row-pointer
                    manage-route
                    :filter="{ field: 'lemonway_state', items: kycStatusCategories }"
                    @update="fetchData"
                    @click-row="openInvestor"
                >
                    <template #[`item.name`]="{ item }">
                        <div class="icon-with-text">
                            <v-icon v-if="item.is_company" class="custom-left-icon">
                                mdi-domain
                            </v-icon>
                            <span>{{ item.name ?? "N/A" }}</span>
                        </div>
                    </template>
                    <template #[`item.created_at`]="{ value }">
                        {{ $time.formated(value) }}
                    </template>
                    <template #[`item.lemonway_state`]="{ item }">
                        <KycStatus :loading="loading || localLoading" :entity="item" />
                    </template>
                    <template #[`item.amount_invested`]="{ value }">
                        {{ $currency.displayWithCurrency(value, "EUR") }}
                    </template>
                    <template #[`item.wallet_principal`]="{ value }">
                        {{ $currency.displayWithCurrency(value, "EUR") }}
                    </template>
                    <template #[`item.action`]="{ item }">
                        <div class="action-text" @click.stop="openInvestor(item)">
                            <span>{{ $t("universe.kyoseil.tab.see-more") }}</span>
                            <v-icon class="arrow-icon">mdi-arrow-right</v-icon>
                        </div>
                    </template>
                </DataTablePaginated>
            </v-col>
        </v-row>
        <InvestorModal
            v-if="selectedInvestor"
            :loading="loading || localLoading"
            :open.sync="investorModalOpen"
            :investor="selectedInvestor"
            @closeDialog="closeDialog"
        />
    </v-container>
    <v-container v-else>
        <v-alert dense border="left" type="warning" prominent class="mt-12">
            <span style="color: black">
                Vous devez être admin de la marque blanche pour accéder à cet espace.
            </span>
        </v-alert>
    </v-container>
</template>

<script>
import DataTablePaginated from "@/components/views/DataTablePaginated";
import KycStatus from "../clients/dashboard/components/KycStatus";
import { validationStatusKyc } from "@/components/views/user/clients/tools.js";
import InvestorModal from "./InvestorModal";
import { getHttpErrMsg } from "@/log";

export default {
    name: "InvestorsDashboard",
    components: {
        DataTablePaginated,
        KycStatus,
        InvestorModal
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            localLoading: false,
            queriesCache: {},
            items: {},
            search: "",
            searchKey: "name",
            searchLabel: "",
            selectedInvestor: null,
            investorModalOpen: false,
            kycStatusList: [
                "failure",
                "upload",
                "upload-failure",
                "pending",
                "success",
                "not_opened"
            ]
        };
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t("universe.kyoseil.tab.name"),
                    value: "name",
                    sortable: true,
                    filterable: this.searchKey === "name",
                    align: "left"
                },
                {
                    text: "Date d'inscription",
                    value: "created_at",
                    sortable: true,
                    filterable: false,
                    align: "left"
                },
                {
                    text: this.$t("universe.kyoseil.tab.status-kyc"),
                    value: "lemonway_state",
                    sortable: true,
                    filterable: false,
                    align: "left"
                },
                {
                    text: this.$t("universe.kyoseil.tab.value"),
                    value: "amount_invested",
                    sortable: true,
                    filterable: false,
                    align: "left"
                },
                {
                    text: this.$t("dashboard.amount-available"),
                    value: "wallet_principal",
                    sortable: true,
                    filterable: false,
                    align: "left"
                },
                {
                    text: this.$t("universe.kyoseil.tab.action"),
                    value: "action",
                    sortable: false,
                    filterable: false,
                    align: "left"
                }
            ];
        },
        kycStatusCategories() {
            let kycList = Object.entries(this.kycStatusList)
                .map(item => {
                    return { text: validationStatusKyc(item[1]).text, value: item[1] };
                })
                .sort((a, b) => b.text.localeCompare(a.text));
            return kycList;
        }
    },

    methods: {
        validationStatusKyc,
        async fetchData(queries) {
            this.queriesCache = { ...queries };
            this.localLoading = true;
            try {
                const res = await this.$api.request("get", "api/investors-dashboard/", queries);
                this.items = res.data;
            } catch (e) {
                this.$ui.error(
                    getHttpErrMsg(e, "Erreur lors de la récupération de la liste des investisseurs")
                );
            } finally {
                this.localLoading = false;
            }
        },
        openInvestor(item) {
            this.selectedInvestor = item;
            this.investorModalOpen = true;
        },
        closeDialog() {
            this.investorModalOpen = false;
            this.selectedInvestor = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.action-text {
    > span {
        vertical-align: middle;
    }

    .arrow-icon {
        vertical-align: middle;
        margin-left: 4px;
        transition: transform 0.3s ease;
    }

    &:hover .arrow-icon {
        transform: translateX(1rem);
    }
}

.icon-with-text {
    > span {
        vertical-align: middle;
    }

    .custom-left-icon {
        vertical-align: middle;
        margin-right: 6px;
    }
}
</style>
