<template>
    <v-container fluid class="ma-0 pa-0">
        <el-tabs
            v-model="valueProxy"
            v-bind="{
                ...$attrs,
                ...getData,
                'el-class': elClass,
                height: '100%'
            }"
            :class="`fill-height ${getData.class}`"
        >
            <el-tab-pane
                v-for="(tab, index) in tabs"
                :id="`eqs-tab-${tab.id || index}`"
                :key="`eqs-tab-${tab.id || index}`"
                :label="tab.title"
                :name="tab.id"
            >
                <template #label>
                    <EqsBadge
                        v-bind="badgeProps(tab)"
                        :style="tab.badge ? 'padding-right:20px' : ''"
                    >
                        {{ tab.title }}
                    </EqsBadge>
                </template>

                <slot v-bind="{ item: tab.item, id: tab.id, loading }" />
            </el-tab-pane>
        </el-tabs>
    </v-container>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsTabs.js";
import { eqsUITemplate, bgColor } from "../../../mixins/styleMixin";

export default {
    name: "EqsTabs",
    components: {
        EqsBadge: () => import("../../atoms/EqsBadge/EqsBadge.vue")
    },
    mixins: [eqsUITemplate, bgColor],
    props: {
        elClass: {
            type: String,
            default: "eqs-tabs"
        },
        tabs: {
            validator: value => value instanceof Array && value.every(tab => "id" in tab),
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        severalTabs() {
            return this.tabs && this.tabs.length > 1;
        },
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    },
    watch: {
        "$route.query.tab": {
            immediate: true,
            handler(val) {
                if (val === undefined) return;
                if (val === this.value) return;

                this.$emit("update:value", val);
            }
        },
        value: {
            immediate: true,
            async handler(val, old) {
                if (!this.$route) return;
                if (old === undefined && this.$route.query.tab !== undefined) return;
                else if (val === null || !this.tabs.some(tab => tab.id === val)) {
                    if (
                        this.$route.query.tab &&
                        this.tabs.some(tab => tab.id === this.$route.query.tab)
                    )
                        val = this.$route.query.tab;
                    else val = this.tabs[0].id;

                    this.$emit("update:value", val);
                } else if (this.$route.query.tab === val) return;

                await this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        tab: val
                    }
                });
            }
        }
    },
    methods: {
        badgeProps(tab) {
            let badge = {
                color: "primary",
                offsetY: "7px",
                offsetX: "2px",
                value: false
            };
            if (tab.badge) {
                Object.assign(badge, tab.badge);
                badge.value = true;
            }
            return badge;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsTabs.scss";
</style>
