<template>
    <div class="eqs-url">
        <EqsText
            v-bind="{ ...$attrs, errorMessages: errorMsg }"
            :value.sync="valueProxy"
            :basic-rules="$options.rules"
            :placeholder="prepend"
            :hint="getHint"
            persistent-hint
            v-on="$listeners"
            @paste="pasteUrl"
        />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsUrl.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { validUrlR, max100R } from "../../../rules";
import startsWith from "lodash/startsWith";

export default {
    name: "EqsUrl",
    rules: [validUrlR, max100R],
    components: {
        EqsText: () => import("../EqsText/EqsText.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        prependUrl: {
            type: String,
            default: ""
        },
        value: {
            validator: prop => typeof prop === "string" || prop === null || prop === undefined,
            required: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            clipboard: null,
            errorMsg: "",
            prepend: "https://" + this.prependUrl
        };
    },
    computed: {
        getHint() {
            return !this.value ? `${this.prepend}${!this.prependUrl ? "example.com" : ""}` : "";
        },
        valueProxy: {
            get() {
                return this.value;
            },
            set(url) {
                if (!!url && url.length > 1) this.errorMsg = this.checkHttpError(url);
                if (this.errorMsg) this.handleHttpError(url);
                else if (url === this.prepend) {
                    this.$nextTick(() => {
                        this.$emit("update:value", "");
                    });
                } else this.formatUrl(url);
            }
        }
    },
    created() {
        if (!!this.value) this.errorMsg = this.checkHttpError(this.value);
    },
    methods: {
        handleHttpError(url) {
            if (url !== this.clipboard) this.errorMsg = "";
            else {
                this.$nextTick(() => {
                    this.$emit("update:value", "");
                    this.clipboard = "";
                });
            }
        },
        checkHttpError(value) {
            if (!value) return "";
            const isHttp = value.startsWith("http://");
            return isHttp ? window.app.$t("rules.is-not-https") : "";
        },
        getFormatedUrl(url) {
            if (!url || startsWith(url, this.prepend)) {
                return url;
            }
            const res =
                url.length > this.prepend.length - 1 || url.length === 1
                    ? this.prepend + url
                    : this.prepend;
            return res;
        },
        formatUrl(url) {
            this.$nextTick(() => {
                this.$emit(
                    "update:value",
                    this.getFormatedUrl(this.clipboard ? this.clipboard : url)
                );
                this.clipboard = null;
            });
        },
        pasteUrl(event) {
            const text = event.clipboardData.getData("Text");
            this.clipboard = text;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsUrl.scss";
</style>
