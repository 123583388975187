var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"closable":""},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},'EqsDialog',{
        title: _vm.$t('equisafe-ui.text-editor.internal-image-selector.title'),
        confirm: null,
        cancel: {}
    },false),[_c('div',{staticClass:"d-flex flex column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsFileInput',_vm._b({attrs:{"value":_vm.img,"auto-upload":false,"accept":"image/*"},on:{"update:value":[function($event){_vm.img=$event},_vm.addImage]}},'EqsFileInput',{
                        sizeMax: _vm.sizeMax,
                        label: _vm.$t(
                            'equisafe-ui.text-editor.internal-image-selector.file-input-label'
                        )
                    },false))],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }