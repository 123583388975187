<template>
    <div class="eqs-upload" :style="getStyle">
        <EqsSkeleton v-bind="{ loading, type: 'sentences' }">
            <v-file-input
                v-model="files"
                outlined
                rounded
                filled
                dense
                :el-class="``"
                hide-details="auto"
                prepend-icon=""
                v-bind="{
                    ...getData,
                    ...$attrs,
                    placeholder,
                    clearable: !autoUpload,
                    multiple,
                    loading: localLoading || loading,
                    rules: rulesRequired,
                    disabled: disabled || localLoading || !editable,
                    backgroundColor: $color.colors.inputBackground,
                    accept
                }"
                :class="`rounded-lg ${elClass}-file-input`"
                :error-messages="errorText || errorMessages"
                @change="onAddFiles"
                @click:clear="() => $emit('update:value', multiple ? [] : null)"
                v-on="$listeners"
            >
                <template #selection="{ index, file }">
                    <div
                        v-if="index === 0 && autoUpload"
                        class="v-file-input__text v-file-input__text--placeholder"
                    >
                        {{ placeholder }}
                    </div>
                    <div v-else-if="!autoUpload">
                        <EqsTextDisplay v-bind="{ value: file.name }" />
                    </div>
                </template>
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                    <slot :name="name" v-bind="slotData" />
                </template>
                <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            </v-file-input>
        </EqsSkeleton>
        <v-input :value="value" :rules="[isDocLoading]" hide-details="auto" />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsUpload.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin, filesUploadMixin } from "../../../mixins/inputMixin";
import assignIn from "lodash/assignIn";
import isEmpty from "lodash/isEmpty";

export default {
    name: "EqsUpload",
    components: {
        EqsSkeleton: () => import("../EqsSkeleton/EqsSkeleton.vue"),
        EqsTextDisplay: () => import("../EqsTextDisplay/EqsTextDisplay.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin, filesUploadMixin],
    props: {
        elClass: {
            type: String,
            default: ""
        },
        errorMessages: {
            type: [Array, String],
            default: ""
        },
        others: {
            type: [Object, Function],
            default: () => {}
        },
        upload: {
            type: Function,
            default: null
        },
        autoUpload: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        multiple: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: "image/png,image/jpeg,image/jpg,.pdf,.heic,.heif"
        },
        value: {
            validator: prop =>
                typeof prop === "File" ||
                typeof prop === "object" ||
                prop === null ||
                prop === undefined,
            required: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            localLoading: false,
            errorText: "",
            files: []
        };
    },
    computed: {
        uploadFunc() {
            return this.upload || this.$api.postDocument;
        }
    },
    created() {
        if (this.validateValueProp()) {
            this.errorText = this.sizeError(this.valueProxy);
            this.$emit("update:errorMessages", this.errorText);
        }
    },
    methods: {
        isDocLoading() {
            if (this.localLoading || this.loading)
                return this.$t("equisafe-ui.upload.error-doc-is-uploading");
            return true;
        },
        requiredR(val) {
            return this.valueProxy && this.valueProxy.length ? true : this.$t("rules.required");
        },
        async uploadDocument(file, others) {
            if (!file) {
                console.error("Document empty.");
            } else {
                const formData = new FormData();
                formData.append("upload", file);
                formData.append("type", file.type);
                formData.append("name", file.name);
                if (!isEmpty(others))
                    for (var key in others) {
                        formData.append(key, others[key]);
                    }
                try {
                    let res = null;
                    const fileObject = assignIn(others, file);
                    const func = this.uploadFunc;
                    res = await func(formData, fileObject);
                    return res.data;
                } catch (e) {
                    console.error(`error in uploadDocument:`, { e });
                    const error = this.getErrorFromUpload(e);
                    this.$ui.error(error);
                    throw error;
                }
            }
        },
        getErrorFromUpload(e) {
            let error = this.$t("equisafe-ui.upload.unknow-error-download");
            if (!e || !e.data) return error;
            if ("data" in e && e.data.upload && e.data.upload.length) error = e.data.upload[0];
            else if ("data" in e && e.data.name && e.data.name.length) error = e.data.name[0];
            else if ("data" in e && e.data.type && e.data.type.length) error = e.data.type[0];
            return error;
        },
        async onAddFiles(filesInput) {
            let files = null;
            if (this.multiple) files = filesInput;
            else files = !!filesInput ? [filesInput] : [];
            this.errorText = files.length > 0 ? this.sizeError(files) : "";
            let uploadedFiles = [];
            if (files.length > 0 && !this.sizeError(files)) {
                if (this.autoUpload) {
                    this.localLoading = true;
                    this.$emit("isLoading", this.localLoading);
                    const results = await Promise.allSettled(
                        files.map(async file => {
                            try {
                                const uploadedDoc = await this.uploadDocument(file, this.others);
                                uploadedFiles.push(uploadedDoc);
                            } catch (e) {
                                throw e;
                            }
                        })
                    );
                    results.forEach((res, index) => {
                        if (res.status === "rejected") {
                            this.errorText = res.reason.toString();
                        }
                    });

                    this.localLoading = false;
                    this.$emit("isLoading", this.localLoading);
                } else {
                    uploadedFiles = files;
                }
                this.$emit("update:errorMessages", this.errorText);
                this.$set(
                    this,
                    "valueProxy",
                    this.multiple ? [...this.valueProxy, ...uploadedFiles] : uploadedFiles
                );
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsUpload.scss";
</style>
