var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-slide-x-transition',{staticClass:"fill-width fill-height"},[(_vm.$route.name === 'registration')?_c('EqsStepper',_vm._b({attrs:{"value":_vm.step},on:{"update:value":function($event){_vm.step=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{class:item.is != 'GlobalYousignIFrame'
                        ? 'eqs-main-container margin-auto enforce-font'
                        : 'fill-height fill-width pa-0 enforce-font'},[_c('v-row',{staticClass:"px-3"},[(_vm.error || _vm.entity.lemonway_state === 'failure' || _vm.lemonwayError)?_c('v-col',[_c('EqsAlert',_vm._b({attrs:{"el-class":`${_vm.entity.account_type}-error`}},'EqsAlert',{
                                eqsType: 'error',
                                text: _vm.getErrorTxt,
                                loading: _vm.localLoading || _vm.loading
                            },false))],1):_vm._e()],1),(item)?_c(item.is,_vm._b({tag:"component",attrs:{"editable":"","retriable":""}},'component',{
                        formData: _vm.formData,
                        loading: _vm.localLoading || _vm.loading,
                        schemaDouble: _vm.schemaDouble,
                        countries: _vm.countries,
                        entity: _vm.entity,
                        upload: _vm.upload,
                        signer: _vm.signer,
                        lemonwayError: _vm.lemonwayError,
                        ...item
                    },false)):_vm._e()],1)]}}],null,false,3227130996)},'EqsStepper',{ steps: _vm.steps, progress: _vm.progress, loading: _vm.localLoading },false)):_c('router-view',_vm._b({},'router-view',{ loading: _vm.loading, verifyData: _vm.verifyData },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }