<template>
    <EqsCard
        style="width: 100%"
        class="d-flex flex-column fill-height"
        v-bind="{ loading, height: '100%' }"
    >
        <EqsDataTable
            v-bind="{
                loading: localLoading,
                itemsPerPage: -1,
                headers,
                searchable: {
                    placeholder: $t('captable.search-placeholder')
                }
                // Possibility Add function to open dialog and select group-by
            }"
            :items="capData"
            group-by="investor.type_display"
            :footer-props="{
                itemsPerPageOptions: [30, 60]
            }"
            :items-per-page="30"
        >
            <template #group.header="{ group, toggle, isOpen, items }">
                <td class="cell">
                    <EqsBtn
                        fab
                        v-bind="{
                            action: toggle
                        }"
                    >
                        <v-icon v-if="isOpen"> mdi-chevron-up </v-icon>
                        <v-icon v-else> mdi-chevron-down </v-icon>
                    </EqsBtn>
                </td>
                <td class="cell">
                    {{ group }}
                </td>
                <td class="cell" />
                <td class="cell">
                    {{ totalActions(items) }}
                </td>
                <td class="cell">{{ totalCapitalPerCentage(items) }} %</td>
                <td class="cell" />
            </template>
            <template #item.percentage="{ item }"> {{ item.percentage }} % </template>
            <template #item.price="{ item }">
                {{ $currency.displayWithCurrency(item.shareledger.lastpricepershare) }}
            </template>
        </EqsDataTable>
    </EqsCard>
</template>

<script>
export default {
    name: "Captable",
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            capData: [],
            localLoading: false,
            headers: [
                {
                    text: "",
                    value: "",
                    sortable: false
                },
                {
                    text: this.$t("captable.header.investor-name"),
                    value: "investor.name"
                },
                {
                    text: this.$t("captable.header.shareledger-name"),
                    value: "shareledger.name"
                },
                {
                    text: this.$t("captable.header.balance"),
                    value: "balance"
                },
                {
                    text: this.$t("captable.header.percentage"),
                    value: "percentage"
                },
                {
                    text: this.$t("captable.header.price"),
                    value: "price"
                }
            ]
        };
    },
    async created() {
        this.localLoading = true;
        const res = await this.$api.getCaptable();
        this.capData = res.data;
        this.localLoading = false;
    },
    methods: {
        getTags(items) {
            if (items.length == 0) return;
            return items[0].tag;
        },
        totalActions(items) {
            if (items.length == 0) return;
            let total = 0;
            items.map(item => {
                total += item.balance;
            });
            return total;
        },
        totalCapitalPerCentage(items) {
            if (items.length == 0) return;
            let total = 0;
            items.map(item => {
                total += item.percentage;
            });
            return total;
        }
    }
};
</script>
