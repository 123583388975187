var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"eqs-app",style:(_vm.getStyle),attrs:{"id":"app"}},[_c('v-expand-transition',[(_vm.getData.showMenu)?_c('EqsNavigationDrawer',_vm._b({attrs:{"value":_vm.navDrawer,"mini":_vm.mini},on:{"update:value":function($event){_vm.navDrawer=$event},"update:mini":function($event){_vm.mini=$event},"close":() => (_vm.navDrawer = false)}},'EqsNavigationDrawer',{
                items: _vm.routes,
                miniVariantWidth: _vm.miniWidth,
                isMobile: _vm.isMobile,
                loading: _vm.loading,
                ..._vm.$attrs
            },false)):_vm._e()],1),_c('v-expand-x-transition',[(_vm.getData.showNavBar)?_c('EqsNavigationBar',_vm._b({on:{"open":() => (_vm.navDrawer = true),"notificationsMenu":val => _vm.$emit('notifications', val),"toggleChat":() => _vm.$emit('toggleChat')},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'EqsNavigationBar',{
                mini: _vm.mini,
                value: true,
                isMobile: _vm.isMobile,
                notifications: _vm.notifications,
                user: _vm.user,
                userMenu: _vm.userMenu,
                navDrawer: _vm.navDrawer,
                loading: _vm.loading,
                context: _vm.context,
                loadingRoute: _vm.loadingRoute,
                loadingProfiles: _vm.loadingProfiles,
                loadingNotifications: _vm.loadingNotifications,
                showMenu: _vm.getData.showMenu === false ? false : true
            },false),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2):_vm._e()],1),_c('EqsNotify'),_c('EqsConfirmDialog'),_vm._t("modal-client"),_c('v-main',[_c('div',{staticClass:"bg-blur-shape top-left",style:(_vm.cssVars)}),_c('div',{staticClass:"bg-blur-shape bottom-right",style:(_vm.cssVars)}),_c('v-container',{class:`${_vm.getData.container.class} eqs-app__container pa-0`,style:(`background-color:${_vm.$color.colors.backgroundColor}; overflow-x:hidden;`),attrs:{"fluid":""}},[_c('div',{class:`d-flex eqs-app__container__content ${_vm.layout}`,staticStyle:{"height":"100%","width":"100%"}},[_vm._t("default")],2)])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }