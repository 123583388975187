<template>
    <EqsDialog
        v-model="dialogProxy"
        closable
        v-bind="{
            closable: true,
            confirm: {
                fn: () => (dialogProxy = false),
                txt: $t('btn.close')
            },
            title
        }"
        @close="() => (dialogProxy = false)"
    >
        <div class="d-flex flex-column height-fit overflow-y no-overflow-x">
            <EqsTextDisplay
                class="mb-3"
                eqs-type="subtitle"
                v-bind="{ value: $t('user.wallet.limits.money-in-title') }"
            />
            <v-row no-gutters>
                <v-col>
                    <EqsNumber
                        v-bind="{
                            editable: false,
                            label: this.$t('user.wallet.limits.daily-money'),
                            value: limits.moneyinDailyMoney
                        }"
                    />
                </v-col>
                <v-col>
                    <EqsNumber
                        v-bind="{
                            editable: false,
                            isFloat: false,
                            isCurrency: false,
                            label: this.$t('user.wallet.limits.daily-number'),
                            labelHeight: '38px',
                            value: limits.moneyinDailyNumberOf
                        }"
                    />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <EqsNumber
                        v-bind="{
                            editable: false,
                            label: this.$t('user.wallet.limits.monthly-money'),
                            value: limits.moneyinMonthlyMoney
                        }"
                    />
                </v-col>
                <v-col>
                    <EqsNumber
                        v-bind="{
                            editable: false,
                            label: this.$t('user.wallet.limits.annually-money'),
                            value: limits.moneyinAnnuallyMoney
                        }"
                    />
                </v-col>
            </v-row>
            <EqsTextDisplay
                class="my-3"
                eqs-type="subtitle"
                v-bind="{ value: $t('user.wallet.limits.money-out-title') }"
            />
            <v-row no-gutters>
                <v-col>
                    <EqsNumber
                        v-bind="{
                            editable: false,
                            label: this.$t('user.wallet.limits.daily-money'),
                            value: limits.moneyoutDailyMoney
                        }"
                    />
                </v-col>
                <v-col>
                    <EqsNumber
                        v-bind="{
                            editable: false,
                            label: this.$t('user.wallet.limits.daily-number'),
                            isFloat: false,
                            isCurrency: false,
                            labelHeight: '38px',
                            value: limits.moneyoutDailyNumberOf
                        }"
                    />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <EqsNumber
                        v-bind="{
                            editable: false,
                            label: this.$t('user.wallet.limits.monthly-money'),
                            value: limits.moneyoutMonthlyMoney
                        }"
                    />
                </v-col>
                <v-col>
                    <EqsNumber
                        v-bind="{
                            editable: false,
                            label: this.$t('user.wallet.limits.annually-money'),
                            value: limits.moneyoutAnnuallyMoney
                        }"
                    />
                </v-col>
            </v-row>
        </div>
    </EqsDialog>
</template>

<script>
import { limitsSchema as schema, limitsValues as limits } from "./transfers_limits";

export default {
    name: "TransfersLimitsDialog",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            schema: schema(this),
            limits
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) this.$emit("close");
                this.$emit("update:value", val);
            }
        }
    }
};
</script>
