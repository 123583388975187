<template>
    <EqsCard v-bind="{ loading: localLoading, width: '100%' }" el-class="manage-operators">
        <v-layout column justify-start class="overflow-y">
            <div class="box-team">
                <v-layout class="box-members" column>
                    <header>
                        <p class="first-title">
                            {{ $t("crud.list-active") }}
                        </p>
                        <p class="second-title">
                            {{ $t("crud.list-role") }}
                        </p>
                    </header>
                    <div
                        v-for="(role, key) in roles"
                        :key="key"
                        v-ripple
                        class="team-wrapper"
                        :class="{
                            'selected-role': selectedRole && role.id == selectedRole.id
                        }"
                        style="cursor: pointer"
                        justify="center"
                        :el-class="role.user.name"
                        @click="showRoleUpdate(role)"
                    >
                        <span class="first-title layout align-center">
                            {{ role.user.name | truncate(20) }}
                            <IsKycOk :value="role.user && role.user.lemonway_state === 'success'" />
                        </span>
                        <div
                            v-if="role.user.is_active"
                            class="role-list-tag"
                            :class="role.htmlClass"
                        >
                            {{ rigthsByRole[role.role_name].role }}
                        </div>
                        <div v-else class="role-list-tag" :class="role.htmlClass">
                            {{ $t("crud.pending") }}
                        </div>
                    </div>
                    <footer v-if="!localLoading" class="box-add-member" @click="showRoleCreate">
                        <v-btn color="primary" fab el-class="add-member">
                            <img src="/icons/add.svg" />
                        </v-btn>
                        <EqsTextDisplay class="mx-2">
                            <p class="first-title">
                                {{ $t("crud.list-add") }}
                            </p>
                        </EqsTextDisplay>
                    </footer>
                </v-layout>

                <RoleUpdate
                    v-if="open === $options.state.OPEN_ROLE_UPDATE"
                    :show-help.sync="showHelp"
                    :selected-role.sync="selectedRole"
                    v-bind="{
                        rigthsByRole,
                        deleteRole,
                        updateRole,
                        canChangeRole
                    }"
                    class="role-update-box"
                />

                <RoleCreate
                    v-else-if="open === $options.state.OPEN_ROLE_CREATE"
                    v-bind="{ users, showHelp }"
                    class="role-update-box"
                    @createdRole="createdRole"
                    @select:role_name="changeShowRoleSelect($event)"
                />

                <RoleHelp
                    v-if="open"
                    v-bind="{ rigthsByRole, showHelp, showRoleSelect }"
                    :role_name="!!selectedRole ? selectedRole.role_name : 'admin'"
                    class="role-help-box"
                />
            </div>
        </v-layout>
    </EqsCard>
</template>

<script>
import RoleHelp from "@/components/views/manageTeam/RoleHelp.vue";
import RoleCreate from "@/components/views/manageTeam/RoleCreate.vue";
import RoleUpdate from "@/components/views/manageTeam/RoleUpdate.vue";
import IsKycOk from "@/components/molecules/IsKycOk.vue";

const OPEN_NONE = 0;
const OPEN_ROLE_UPDATE = 1;
const OPEN_ROLE_CREATE = 2;

const roleToColorTag = {
    admin: "pink",
    accountant: "green",
    notary: "orange",
    lawyer: "blue",
    custodian: "indigo"
};

export default {
    name: "RoleList",
    components: {
        RoleCreate,
        RoleUpdate,
        RoleHelp,
        IsKycOk
    },
    state: {
        OPEN_NONE: 0,
        OPEN_ROLE_UPDATE: 1,
        OPEN_ROLE_CREATE: 2
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            open: OPEN_NONE,
            roles: [],
            selectedRole: null,
            showHelp: "",
            showRoleSelect: "",
            localLoading: true,
            rigthsByRole: {
                admin: {
                    role: this.$t("crud.admin"),
                    rights: this.getRights(true, true, true, true, true, true, true)
                },
                accountant: {
                    role: this.$t("crud.admin"),
                    rights: this.getRights(false, false, false, false, true, true, true)
                },
                notary: {
                    role: this.$t("crud.admin"),
                    rights: this.getRights(false, false, false, false, true, true, true)
                },
                lawyer: {
                    role: this.$t("crud.admin"),
                    rights: this.getRights(true, true, true, false, true, true, true)
                },
                custodian: {
                    role: this.$t("crud.admin"),
                    rights: this.getRights(false, false, false, false, false, false, false)
                }
            }
        };
    },
    computed: {
        users() {
            return this.roles.map(role => role.user);
        },
        nbAdmins() {
            return this.roles.reduce((acc, item) => acc + Number(item.role_name === "admin"), 0);
        },
        roleIsNotMine() {
            if (!this.selectedRole || !this.selectedRole.user) return true;
            return this.selectedRole.user.id !== this.$entity.represented().id;
        },
        canChangeRole() {
            if (!this.selectedRole) return false;
            return (
                this.roleIsNotMine &&
                this.$permissions.isAdmin &&
                (this.selectedRole.role_name !== "admin" || this.nbAdmins > 1)
            );
        }
    },
    async created() {
        await this.refreshRoles();
        if (this.roles.length) this.showRoleUpdate(this.roles[0]);
    },
    methods: {
        getRights(
            createCompany,
            editInformation,
            createTransaction,
            deleteCompany,
            uploadFiles,
            downloadFiles,
            deleteFiles
        ) {
            return [
                {
                    trad: this.$t("crud.Create-company"),
                    right: createCompany
                },
                {
                    trad: this.$t("crud.Edit-information"),
                    right: editInformation
                },
                {
                    trad: this.$t("crud.Create-transaction"),
                    right: createTransaction
                },
                {
                    trad: this.$t("crud.Delete-company"),
                    right: deleteCompany
                },
                {
                    trad: this.$t("crud.Upload-files"),
                    right: uploadFiles
                },
                {
                    trad: this.$t("crud.Download-files"),
                    right: downloadFiles
                },
                {
                    trad: this.$t("crud.Delete-files"),
                    right: deleteFiles
                }
            ];
        },
        changeShowRoleSelect(e) {
            this.showRoleSelect = e;
        },
        async refreshRoles() {
            try {
                this.localLoading = true;
                const res = await this.$api.roleList({
                    entity_represented_id: this.$store.getters.currentRole.entity_represented.id
                });
                this.roles = res.data.map(role => {
                    role.htmlClass = role.user.is_active
                        ? `role-tag ${roleToColorTag[role.role_name]}-tag`
                        : "role-tag silver-tag";
                    return role;
                });
            } catch (e) {
                this.$ui.error(e, "manage_team_get_roles");
            } finally {
                this.localLoading = false;
            }
        },
        async deleteRole() {
            try {
                await this.$api.roleDelete(this.selectedRole.id);
                if (this.selectedRole.is_signatory)
                    await this.$role.updateMyCurrentRole(this.$entity.represented().id);
                await this.refreshRoles();
                this.$ui.success(
                    this.$t("ui.message.success.role-remove-from-team", {
                        roleUser: this.selectedRole.user.name
                    })
                );
                this.open = OPEN_NONE;
                this.showRoleUpdate(this.roles[this.roles.length - 1]);
            } catch (e) {
                this.$ui.error(e, "manage_team_delete_member");
            }
        },
        showRoleUpdate(role) {
            this.open = OPEN_ROLE_UPDATE;
            this.selectedRole = role;
            this.showRoleSelect = "";
            this.showHelp = "";
        },
        showRoleCreate() {
            this.open = OPEN_ROLE_CREATE;
            this.showRoleSelect = null;
            this.showHelp = "";
            this.selectedRole = "";
        },

        async createdRole(role) {
            try {
                await this.refreshRoles();
                this.$ui.success(
                    this.$t("ui.message.role.added-to-team", {
                        entity: this.$entity.entityName(role)
                    })
                );
                this.open = OPEN_NONE;
                this.showRoleUpdate(this.roles[0]);
            } catch (e) {
                this.$ui.error(e, "manage_team_add_member");
            }
        },
        async updateRole(role) {
            try {
                await this.$api.roleUpdate(role.id, {
                    role_name: role.role_name,
                    is_signatory: role.is_signatory
                });
                await this.refreshRoles();
                this.showRoleUpdate(this.roles.find(role => role.id === this.selectedRole.id));
            } catch (e) {
                this.$ui.error(e, "manage_team_update_role");
            }
        }
    }
};
</script>

<style lang="scss">
.manage-team-title {
    p {
        margin-bottom: 0;
        font-size: 23px;
    }
    height: 80px;
    max-height: 80px;
    padding-left: 45.5px;
    border-bottom: 0.5px solid var(--v-border-base);
}

.box-team {
    display: inline-grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 250px 2fr 1fr;
}

.role-tag {
    font-size: 11px;
    border-radius: 15px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    display: inline;
    height: 22.5px;
}

.role-list-tag {
    margin: 20px;
    float: right;
}

.box-members {
    margin-left: 35.5px;
    border-right: 0.5px solid var(--v-border-base);

    > * {
        height: 63px;
    }
    > div {
        &:hover {
            background-color: var(--v-lightgrey-lighten2);
        }
    }
    > div,
    > header {
        border-bottom: 0.5px solid var(--v-border-base);
    }
    > div,
    > header {
        padding-left: 10px;
        transition: background-color 0.5s;

        > .first-title {
            font-size: 11px;
            max-width: 95px;
            font-weight: 900;
            float: left;
            margin-top: 20px;
        }

        > .second-title {
            font-size: 12px;
            font-weight: 900;
            margin-top: 20px;
            margin-right: 20px;
            float: right;
        }
    }
    .team-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .selected-role {
        background-color: var(--v-lightgrey-lighten1);
        &:hover {
            background-color: var(--v-lightgrey-lighten1);
        }
    }
}

.pink-tag {
    border: 1px solid var(--v-pink-base);
    color: var(--v-pink-base);
}

.green-tag {
    border: 1px solid var(--v-greencool-base);
    color: var(--v-greencool-base);
}

.orange-tag {
    border: 1px solid var(--v-orange-base);
    color: var(--v-orange-base);
}

.blue-tag {
    border: 1px solid var(--v-bluesky-base);
    color: var(--v-bluesky-base);
}

.indigo-tag {
    border: 1px solid var(--v-indigo-base);
    color: var(--v-indigo-base);
}

.silver-tag {
    border: 1px solid var(--v-silver-base);
    background: var(--v-silver-base);
    color: var(--v-white-base);
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
}

.text-add {
    margin-top: 20px;
}

.box-add-member {
    padding-top: 10px;
    cursor: pointer;
    > * {
        display: inline-block;
    }
}

.role-update-box {
    border-left: 0.5px solid var(--v-border-base);
    border-right: 0.5px solid var(--v-border-base);
    padding-left: 40px;
    padding-right: 40px;
    min-width: 265px;
}

.role-help-box {
    padding-left: 40px;
    padding-right: 40px;
}
</style>
