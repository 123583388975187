<template>
    <EqsCard
        :title="$t('document-to-sign.title-procedure-details')"
        closable
        v-bind="{
            height: '100%'
        }"
        class="fill-height"
        @close="() => $emit('close')"
    >
        <div class="fill-height overflow-y">
            <div class="d-flex flex-column" style="overflow-x: hidden">
                <EqsTextDisplay
                    eqs-type="subtitle"
                    :value="$t('document-to-sign.procedure-details.label.global-info')"
                />
                <v-row class="px-3" align-content="start">
                    <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                        <EqsText
                            v-bind="{
                                editable: false,
                                label: $t('document-to-sign.procedure-details.label.procedure'),
                                value: signatureProcedure.name
                            }"
                        />
                    </v-col>
                    <v-col
                        v-if="signatureProcedure.description"
                        :cols="$reactive.getCols('full')"
                        class="eqs-form-field"
                    >
                        <EqsTextArea
                            v-bind="{
                                editable: false,
                                label: $t('document-to-sign.procedure-details.label.description'),
                                value: signatureProcedure.description
                            }"
                        />
                    </v-col>
                    <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                        <EqsLabel
                            v-bind="{
                                editable: false,
                                value: $t('document-to-sign.procedure-details.label.status')
                            }"
                        >
                            <EqsChip
                                v-if="signatureProcedure.status"
                                v-bind="procedureStatus[signatureProcedure.status]"
                            >
                                {{ procedureStatus[signatureProcedure.status].text }}
                            </EqsChip>
                            <!--
                                TODO ajouté signé le ou refusé le etc
                                <EqsTextDisplay v-if="item.status === 'finished' || signerSigned(item)">
                                <span class="pt-2" style="display: inline-flex; width: max-content">
                                    {{ `${$t("label.on")} ${$time.formated(item.finished_at)}` }}
                                </span>
                            </EqsTextDisplay> -->
                            <EqsChip v-else eqs-type="warning">
                                {{ $t("document-to-sign.procedure-waiting-signature-placement") }}
                            </EqsChip>
                        </EqsLabel>
                    </v-col>
                </v-row>
                <EqsTextDisplay
                    v-if="isSigner()"
                    class="pt-3"
                    eqs-type="subtitle"
                    :value="$t('document-to-sign.procedure-details.label.details')"
                />
                <!-- <v-row v-if="isSigner()" class="px-3">
                    <v-col
                        v-if="getProcedureSigner().status === 'done'"
                        :cols="$reactive.getCols('full')"
                        class="eqs-form-field"
                    >
                        <EqsBtn v-bind="{ action: downloadSignatureProof, color: 'primary' }">
                            <template #with-icon>
                                <v-icon> mdi-download-outline </v-icon>
                                {{
                                    $t(
                                        "document-to-sign.procedure-details.download-signature-proof"
                                    )
                                }}
                            </template>
                        </EqsBtn>
                    </v-col>

                    <v-col v-else :cols="$reactive.getCols('full')" class="eqs-form-field">
                        <EqsTextDisplay
                            class="pl-3"
                            :value="
                                $t(
                                    'document-to-sign.procedure-details.download-signature-proof-not-finished'
                                )
                            "
                        />
                    </v-col>
                </v-row> -->
                <v-row class="px-3">
                    <v-col
                        v-if="signatureProcedure.proceduredocument_set.length && !signedDocs.length"
                        :cols="$reactive.getCols('full')"
                        class="eqs-form-field"
                    >
                        <EqsFileInput
                            multiple
                            v-bind="{
                                label: $t(
                                    'document-to-sign.procedure-details.label.documents-list'
                                ),
                                value: signatureProcedure.proceduredocument_set.map(
                                    doc => doc.document
                                ),
                                editable: false
                            }"
                        />
                    </v-col>
                    <v-col
                        v-else-if="signedDocs.length"
                        :cols="$reactive.getCols('full')"
                        class="eqs-form-field"
                    >
                        <EqsFileInput
                            multiple
                            v-bind="{
                                label: $t(
                                    'document-to-sign.procedure-details.label.signed-documents-list'
                                ),
                                value: signedDocs,
                                editable: false
                            }"
                        />
                    </v-col>
                    <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                        <EqsLabel
                            v-bind="{
                                value: $t('document-to-sign.procedure-details.label.signers-list'),
                                editable: false
                            }"
                        >
                            <EqsDataTable
                                v-bind="{
                                    headers,
                                    hideDefaultFooter: signatureProcedure.signer_set.length < 5,
                                    items: signatureProcedure.signer_set
                                }"
                            >
                                <template #item.name="{ item }">
                                    <td v-if="item.entity">
                                        {{ item.entity.name }}
                                    </td>
                                </template>
                                <template #item.status_display="{ item }">
                                    <td>{{ signerStatus[item.status_display].text }}</td>
                                </template>
                            </EqsDataTable>
                        </EqsLabel>
                    </v-col>
                </v-row>
            </div>
        </div>
    </EqsCard>
</template>

<script>
import find from "lodash/find";
import { procedureStatus, signerStatus } from "@/util/documents";

export default {
    name: "ProcedureDetails",
    props: {
        signatureProcedure: {
            type: Object,
            required: true
        },
        isCompany: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            procedureStatus,
            signerStatus,
            headers: [
                {
                    text: this.$t("document-to-sign.procedure-details.label.name"),
                    value: "name",
                    sortable: true,
                    searchable: true
                },
                {
                    text: this.$t("label.status"),
                    value: "status_display",
                    sortable: true,
                    searchable: true
                }
            ]
        };
    },
    computed: {
        signedDocs() {
            return this.signatureProcedure.proceduredocument_set
                ? this.signatureProcedure.proceduredocument_set
                      .filter(doc => !!doc.signed_document)
                      .map(doc => doc.signed_document)
                : [];
        }
    },
    methods: {
        getProcedureSigner() {
            return find(this.signatureProcedure.signer_set, {
                entity_id: this.$entity.represented().id
            });
        },
        isSigner() {
            let signer = this.getProcedureSigner();
            return !!signer ? true : false;
        },
        async downloadSignatureProof() {
            try {
                let signer = this.getProcedureSigner();
                if (!!signer) {
                    await this.$api.getSignatureMemberProof(signer);
                }
            } catch (e) {
                this.$ui.error(e, "download_signature_proof");
            }
        }
    }
};
</script>
