import DEBUG from "@/store/debug.js";

export default async ({ state, dispatch }, procedureId) => {
    DEBUG("GET_PROCEDURE_START");
    if (!procedureId) throw new Error("no procedureId");
    let procedure = state.procedures.find(_procedure => _procedure.id === procedureId);
    if (!procedure) {
        try {
            const procedures = await dispatch("getProcedures");
            procedure = procedures.find(_procedure => _procedure.id === procedureId);
        } catch (e) {
            DEBUG("GET_PROCEDURE_ERROR");
            console.error({ e });
            throw e;
        }
    }
    if (procedure) DEBUG("GET_PROCEDURE_SUCCESS");
    else {
        DEBUG("GET_PROCEDURE_ERROR");
        console.error("procedure not found");
        throw new Error("procedure not found");
    }
    return procedure || {};
};
