<template>
    <div class="eqs-autocomplete d-flex">
        <EqsLabel
            v-bind="{
                ...$attrs,
                locked,
                isRequired,
                height: labelHeight,
                editable,
                value: label,
                infoTxt
            }"
        >
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <EqsEditable
                v-bind="{
                    ...$attrs,
                    editable: editable && !locked,
                    value: editableData
                }"
            >
                <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                <EqsSkeleton v-bind="{ loading, type: 'sentences' }">
                    <v-autocomplete
                        v-model="valueProxy"
                        v-bind="{
                            ...$props,
                            ...$attrs,
                            ...getData,
                            label: '',
                            loading: loadingItems
                        }"
                        :background-color="$color.colors.inputBackground"
                        :el-class="`${elClass}-field`"
                        :menu-props="{
                            auto: true,
                            contentClass: `${elClass}-list-container`
                        }"
                        :class="`rounded-lg ${elClass}-input`"
                        :rules="rulesRequired"
                        :style="getStyle"
                        outlined
                        filled
                        rounded
                        hide-details="auto"
                        v-on="$listeners"
                    >
                        <template
                            v-for="(_, name) in $scopedSlots"
                            :slot="name"
                            slot-scope="slotData"
                        >
                            <slot :name="name" v-bind="slotData" />
                        </template>
                        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                    </v-autocomplete>
                </EqsSkeleton>
            </EqsEditable>
        </EqsLabel>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsAutocomplete.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";
import { toType } from "../../../../../shared/util";

export default {
    name: "EqsAutocomplete",
    components: {
        EqsLabel: () => import("../../atoms/EqsLabel/EqsLabel.vue"),
        EqsEditable: () => import("../../atoms/EqsEditable/EqsEditable.vue"),
        EqsSkeleton: () => import("../../atoms/EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: "eqs-autocomplete"
        },
        items: {
            type: Array,
            required: true
        },
        value: {
            type: null,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingItems: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: "text"
        },
        itemValue: {
            type: String,
            default: "value"
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        filter: {
            type: Function,
            default: (item, queryText, itemText) => {
                const itemTextNoAccent = itemText
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                const queryTextNoAccent = queryText
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                return itemTextNoAccent.indexOf(queryTextNoAccent) > -1;
            }
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value || "";
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        editableData() {
            if (!this.valueProxy) return "";
            const val = this.returnObject
                ? this.valueProxy
                : this.items.find(
                      item =>
                          item[this.itemValue] ===
                          (this.returnObject ? this.valueProxy[this.itemValue] : this.valueProxy)
                  );
            const typeOfValue = toType(val);
            if (typeOfValue === "object") return val[this.itemText];
            return val || "";
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsAutocomplete.scss";
</style>
