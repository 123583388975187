var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-main-container margin-auto"},[_c('EqsViewWithImage',_vm._b({staticClass:"mx-3"},'EqsViewWithImage',{ img: _vm.$universe.image('/images/Money-out.svg') },false),[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsTextDisplay',{staticClass:"mb-3",attrs:{"eqs-type":"subtitle"}},[_vm._v(" "+_vm._s(_vm.$t("user.wallet.money-out.withdraw-demamd"))+" ")]),_c('EqsTextDisplay',{staticClass:"mb-3",attrs:{"paragraph":""}},[_vm._v(" "+_vm._s(_vm.$t("user.wallet.money-out.withdraw-demamd-text"))+" ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"eqs-form-field"},[_vm._t("iban")],2)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('WalletBalance',_vm._b({staticClass:"mb-3"},'WalletBalance',{ loading: _vm.loading },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsNumber',_vm._b({attrs:{"label":_vm.$t('user.wallet.money-out.amount-to-withdraw'),"el-class":"money-out-amount","rules":[_vm.greaterThanZeroR, _vm.limitsMoneyoutDaily, _vm.maxAmount, _vm.minAmount],"value":_vm.formData.moneyVal},on:{"update:value":function($event){return _vm.$set(_vm.formData, "moneyVal", $event)}}},'EqsNumber',{ loading: _vm.isLoading },false))],1)],1),(_vm.formData.fee)?_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsNumber',_vm._b({attrs:{"label":_vm.$t('user.wallet.money-out.transaction-fee'),"el-class":"tx-fee","readonly":""}},'EqsNumber',{
                        editable: false,
                        value: _vm.formData.fee,
                        loading: _vm.isLoading
                    },false))],1)],1):_vm._e(),(!_vm.isLoading)?_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsTextDisplay',_vm._b({attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{
                        value: _vm.$t('user.wallet.money-out.total-withdrawal'),
                        loading: _vm.loading
                    },false)),_c('EqsChip',_vm._b({staticClass:"pt-3 font-weight-bold",attrs:{"color":"primary","text-color":"white","el-class":"total-amount"}},'EqsChip',{ loading: _vm.loading },false),[_vm._v(" "+_vm._s(_vm.$currency.display(_vm.formData.totalWithdrawal))+" ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }