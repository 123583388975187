var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title-add-member"},[_c('p',[_vm._v(_vm._s(_vm.$t("crud.add")))])]),_c('div',{staticClass:"add-team"},[_c('v-form',{ref:"addTeamMember",attrs:{"id":"form-add-member"},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('div',{staticClass:"input-member"},[_c(_vm.form.is,_vm._b({tag:"component",attrs:{"editable":""}},'component',{
                        formData: _vm.formData,
                        loading: _vm.loading,
                        schemaDouble: _vm.schemaDouble,
                        uniqueEmailR: _vm.uniqueEmailR
                    },false)),_c('div',{on:{"mouseleave":function($event){return _vm.$emit('update:showHelp', '')}}},[_c('RoleSelect',{attrs:{"disabled":false,"hovered":_vm.showHelp,"value":_vm.formData.role_name},on:{"update:value":function($event){return _vm.$set(_vm.formData, "role_name", $event)},"update:showHelp":function($event){return _vm.$emit('update:showHelp', $event)},"select:role_name":_vm.selectRoleName,"update:hovered":function($event){return _vm.$emit('update:showHelp', $event)}}})],1),_c('div',{staticClass:"input-is-signatory"},[_c('EqsCheckbox',_vm._b({attrs:{"is-required":false,"label":_vm.$t('crud.is-signatory.label'),"text":_vm.$t('crud.is-signatory.text'),"value":_vm.formData.is_signatory},on:{"update:value":function($event){return _vm.$set(_vm.formData, "is_signatory", $event)}}},'EqsCheckbox',{ loading: _vm.loading },false))],1),_c('div',{staticClass:"width-fit"},[_c('EqsBtn',_vm._b({attrs:{"id":"btnRoleCreate","color":"primary"}},'EqsBtn',{
                            action: _vm.emitCreateRole,
                            disabled: !_vm.validForm,
                            loading: _vm.loading,
                            elClass: 'manage-create-btn'
                        },false),[_vm._v(" "+_vm._s(_vm.$t("crud.send"))+" ")])],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }