import cloneDeep from "lodash/cloneDeep";
import init from "./init";

export default {
    SET_MY_ENTITY(state, entity) {
        state.myEntity = cloneDeep(entity);
    },
    SET_IS_AUTHENTICATED(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
    },
    SET_BLOCKCHAINIZE_STATE(state, blockchainizeState) {
        state.blockchainizeState = cloneDeep(blockchainizeState);
    },
    SET_IS_NETWORK_FAILURE(state, isNetworkFailure) {
        state.isNetworkFailure = isNetworkFailure;
    },
    SET_IS_NOT_AUTHENTICATED(state, isNotAuthenticated) {
        state.isNotAuthenticated = isNotAuthenticated;
    },
    RESET_STORE_ENTITIES(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
