<template>
    <v-row class="fill-height auth" no-gutters>
        <v-col
            class="auth__content pa-5 d-flex flex-column align-center"
            :class="{ 'auth__content--no-background': $universe.isArthaud() }"
        >
            <EqsAlert v-if="emailConfirmed" :text="$t('auth.email-confirmed', { universe })" />
            <v-row
                no-gutters
                class="d-flex flex-column"
                :style="`width: ${$reactive.getVal({
                    mobile: '100%',
                    tablet: '80%',
                    desktop: '70%'
                })}`"
            >
                <v-row
                    no-gutters
                    class="auth__content__eqs pr-5"
                    :style="`height: ${$reactive.getVal({
                        mobile: '3.5rem',
                        tablet: '6rem',
                        desktop: '8rem'
                    })}`"
                >
                    <EqsSkeleton v-bind="{ loading, type: 'image' }">
                        <img
                            style="width: 100%; object-fit: contain"
                            :src="$universe.image('/images/logo_name.svg')"
                        />
                    </EqsSkeleton>
                </v-row>
                <v-row no-gutters class="auth__content__title my-5">
                    <EqsTextDisplay
                        v-if="!$universe.isNeotrees()"
                        class="flex auth__content__title"
                        :value="$t('auth.register.title') || $t('auth.login.title')"
                        v-bind="{ loading }"
                        eqs-type="title"
                    />
                    <v-container v-else fluid class="ma-0 pa-0">
                        <p>
                            Créez et accéder à votre espace personnel pour suivre vos
                            investissements dans vos projets de contribution carbone volontaire.
                        </p>
                        <p>
                            Notre outil permet de vous assurer <strong>l’unicité</strong> et de la
                            <strong>non-transférabilité</strong> de vos crédits carbone.
                        </p>
                        <p>
                            Les crédits carbone que nous délivrons sont uniques, il ne peuvent être
                            contrefaits, revendus ni transférés. Nous utilisons la
                            <strong>blockchain</strong> pour garantir des transactions
                            <strong>sécurisées et transparentes</strong>.
                        </p>
                    </v-container>
                </v-row>
                <v-row no-gutters justify="center">
                    <!-- TODO: replace with custom component that handle transition,
                        with anim inverted when come back to parent route -->
                    <v-slide-x-transition
                        hide-on-leave
                        leave-absolute
                        group
                        class="flex"
                        style="max-width: 400px"
                    >
                        <v-col v-if="!isRoot" key="auth-others" class="auth-view pa-0">
                            <EqsTextDisplay
                                v-if="$universe.isKyoseil()"
                                class="flex auth__content__title"
                                :value="$t('auth.login.subheader-kyoseil')"
                                v-bind="{ loading }"
                                eqs-type="subtitle"
                            />
                            <v-slide-x-transition hide-on-leave>
                                <router-view
                                    key="auth-view"
                                    class="auth-view"
                                    v-bind="{ loading }"
                                />
                            </v-slide-x-transition>
                        </v-col>
                        <v-col v-else key="auth-login" class="pa-0">
                            <Login key="auth-login-component" v-bind="{ loading }" />
                        </v-col>
                    </v-slide-x-transition>
                </v-row>
                <v-row no-gutters class="auth__content__language" justify="end">
                    <EqsSelectLanguage
                        v-bind="{
                            loading,
                            value: $language.current(),
                            items: $language.allLanguages()
                        }"
                        @update:value="switchLanguage"
                    />
                </v-row>
            </v-row>
        </v-col>
        <v-col
            v-if="$reactive.isDesktop()"
            class="auth__logo"
            :class="{ 'auth__logo--no-background': $universe.isArthaud() }"
            cols="6"
        >
            <EqsSkeleton class="auth__logo__container" v-bind="{ loading, type: 'image' }">
                <v-row no-gutters class="auth__logo__container__position">
                    <img
                        v-if="$universe.isEquisafe()"
                        :src="$universe.image('/images/auth/auth_side.svg')"
                    />
                    <v-img v-else :src="$universe.image('/images/auth/auth_side.png')" />
                </v-row>
            </EqsSkeleton>
        </v-col>
    </v-row>
</template>

<script>
import { sleep } from "@equisafe-ui-shared/util";

export default {
    name: "Auth",
    components: {
        Login: () => import("./login/Login")
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            otherLanguages: [],
            transition: "",
            emailConfirmed: false
        };
    },
    computed: {
        universe() {
            return this.$store.getters.universe.public_name;
        },
        isRoot() {
            return this.$route.name === "auth";
        },
        TwoFa() {
            return this.$route.name === "2fa";
        },
        authCardProps() {
            let props = {
                type: this.isRoot ? "auth" : "auth-child",
                closable: !this.isRoot && !this.TwoFa
            };
            if (this.TwoFa) {
                props.type = ["auth-child", "two-fa"];
                props.dataType = "auth-child";
            }
            return props;
        }
    },
    watch: {
        $route: {
            immediate: true,
            async handler(to, from) {
                this.$store.commit("setLoadingRoute", false);
                // need to set delay for message because of redirect
                if (to.query.success === "You_have_been_logged_out") {
                    this.$nextTick(async () => {
                        await sleep(500);
                        this.$ui.success(this.$t("login.success-logout"));
                    });
                }
                const toDepth = to.path.split("/").length;
                const fromDepth = from ? from.path.split("/").length : 0;
                this.transition =
                    toDepth < fromDepth ? "v-scroll-x-transition" : "v-scroll-x-reverse-transition";
                if (from) this.emailConfirmed = from.name === "register-confirm";
            }
        },
        authCardProps() {
            let props = {
                type: this.isRoot ? "auth" : "auth-child",
                closable: !this.isRoot && !this.TwoFa
            };
            if (this.TwoFa) {
                props.type = ["auth-child", "two-fa"];
                props.dataType = "auth-child";
            }
            return props;
        }
    },
    async beforeMount() {
        this.otherLanguages = [...this.$language.others()];
    },
    async beforeCreate() {
        if (this.$entity.represented().id && this.$route.name !== "logout") {
            this.$router.replace(this.$universe.getDefaultRoute(this.$entity.represented()));
        }
    },
    methods: {
        async switchLanguage(newLanguage) {
            try {
                this.$currency.setLocale(newLanguage);
                await this.$language.changeTo(newLanguage, false);
            } catch (e) {
                console.error({ e });
            } finally {
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/eqsUi/shared/styles/_helpers.scss";

.auth {
    &__content {
        background: #ffffff 0% 0% no-repeat padding-box;

        &--no-background {
            background: none;
        }

        &__eqs {
            display: flex;
            justify-content: center;
            width: 100%;

            .eqs-skeleton {
                height: 100%;

                .eqs-skeleton__container__content {
                    img {
                        height: inherit;
                        width: inherit;
                    }
                }
            }
        }

        &__title {
            max-height: fit-content;
        }

        &__language {
            max-height: fit-content;
        }
    }

    &__logo {
        background: transparent linear-gradient(180deg, #ffffff 0%, #e3e3e3 100%) 0% 0% no-repeat
            padding-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100vh;

        &--no-background {
            background: none;
        }

        &__container {
            .v-skeleton-loader__bone {
                height: 100vh !important;
            }

            &__position {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 100vh;
                overflow: hidden;

                img {
                    width: 150%;
                    max-height: 100%;
                    position: relative;
                    bottom: -50%;
                    object-fit: contain;
                }
            }
        }
    }
}
</style>
