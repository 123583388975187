<template>
    <div class="d-flex flex-column fill-width">
        <v-row class="px-3" align="center" justify="center">
            <v-col :cols="4" class="d-flex flex-column justify-center align-center">
                <EqsAvatar :user="txDetails.sender" />
                <EqsTextDisplay
                    class="pt-2"
                    el-class="tx-details-sender"
                    v-bind="{
                        value: $entity.entityName(txDetails.sender)
                    }"
                />
            </v-col>
            <v-col :cols="4" class="d-flex justify-center">
                <img style="height: 6rem" src="/images/kite-icon.svg" />
            </v-col>
            <v-col :cols="4" class="d-flex flex-column justify-center align-center">
                <EqsAvatar
                    :user="txDetails.receiver"
                    :label="$entity.entityName(txDetails.receiver)"
                />
                <EqsTextDisplay
                    class="pt-2"
                    el-class="tx-details-receiver"
                    v-bind="{
                        value: $entity.entityName(txDetails.receiver)
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3" align="center" justify="center">
            <v-col :cols="4" class="d-flex justify-center">
                <EqsChip
                    :color="$color.colorFromHash(txDetails.operation)"
                    el-class="tx-details-operation"
                >
                    {{ movement_operations[txDetails.operation].txt }}
                </EqsChip>
            </v-col>
        </v-row>
        <v-row class="px-3" align="center" justify="center">
            <v-col :cols="4" class="d-flex justify-center">
                <EqsText
                    el-class="tx-details-ledger"
                    v-bind="{
                        editable: false,
                        label: $t('label.shareledger'),
                        value: `${txDetails.shareledger.name} (${
                            share_types[txDetails.shareledger.share_type].name
                        })`
                    }"
                />
            </v-col>
            <v-col :cols="4" class="d-flex justify-center">
                <EqsNumber
                    el-class="tx-details-numberofshares"
                    v-bind="{
                        editable: false,
                        isCurrency: false,
                        isFloat: false,
                        label: $t('shareledger.numberofshares'),
                        value: txDetails.numberofshares
                    }"
                />
            </v-col>
            <v-col :cols="4" class="d-flex justify-center">
                <EqsNumber
                    el-class="tx-details-pricepershare"
                    v-bind="{
                        editable: false,
                        label: $t('shareledger.price-per-share'),
                        value: txDetails.pricepershare
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3" align="center" justify="center">
            <v-col v-if="txDetails.comment" class="d-flex justify-start">
                <EqsTextArea
                    v-bind="{
                        editable: false,
                        label: $t('payments.label.comment'),
                        value: txDetails.comment
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3" align="center" justify="center">
            <v-col cols="auto" class="d-flex justify-center">
                <GlobalTransactionChip v-if="txChip" :tx="tx" :deploy="false" />
                <GlobalCopyBadge v-else :value="tx.txhash" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { capitalizeForChar } from "@equisafe-ui-shared/util";
import { movement_operations } from "@/util/operations";
import { share_types } from "@/services/shareledger/shareType";

export default {
    name: "TransactionDetailsCard",
    props: {
        tx: {
            type: Object,
            required: true
        },
        txChip: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: true,
            capitalizeForChar,
            movement_operations,
            share_types
        };
    },
    computed: {
        txDetails() {
            const details = {
                ...this.tx
            };
            details.sender = this.tx.investor_source
                ? this.tx.investor_source
                : this.tx.entity_source;
            details.receiver = this.tx.investor_destination
                ? this.tx.investor_destination
                : this.tx.entity_destination;
            return details;
        }
    },
    methods: {
        getAvatar(entity) {
            if (entity) {
                return entity.avatar
                    ? entity.avatar
                    : entity.investor
                    ? entity.investor.avatar
                    : entity.investor;
            }
            return null;
        }
    }
};
</script>
