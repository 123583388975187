<template>
    <v-container fluid>
        <SendBankPeapme :order="order" @mail-sent="mailSent" />
        <v-input v-model="formData._local_valid" :rules="ruleLocalValid" type="hidden" />
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import SendBankPeapme from "../components/SendBankPeapme";

export default {
    name: "SendBankDocs",
    components: { SendBankPeapme },
    mixins: [formMixin],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {
        ruleLocalValid() {
            return [value => !!value && value === true];
        }
    },
    methods: {
        mailSent(val) {
            if (val) {
                this.$set(this.formData, "mail_bank_sent", true);
                this.$set(this.formData, "_local_valid", true);
            } else {
                this.$set(this.formData, "mail_bank_sent", false);
                this.$set(this.formData, "_local_valid", null);
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
