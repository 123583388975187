<template>
    <EqsStepper
        :value="0"
        :steps="[
            { title: 'test simple doc edition', item: {} },
            { title: 'test simple doc edition 2', item: {} }
        ]"
        :eqs-type="['default', 'no-overflow']"
        eqs-data-type="default"
    >
        <GlobalTextEditor :value.sync="value" class="fill-height mx-auto" />
    </EqsStepper>
</template>

<script>
import GlobalTextEditor from "./GlobalTextEditor";
export default {
    name: "TestEditor",
    components: {
        GlobalTextEditor
    },
    data() {
        return {
            value: ""
        };
    }
};
</script>

<style lang="scss" scoped></style>
