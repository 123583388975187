<template>
    <div class="d-flex flex-column pa-3">
        <v-row class="px-3">
            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('half')" class="d-flex flex-column justify-center">
                    <EqsTextDisplay
                        v-bind="{
                            elClass: 'total-balance',
                            eqsType: 'title',
                            loading: loading || balanceLoading || sequestreLoading
                        }"
                    >
                        <div class="font-weight-black">
                            {{ $currency.display(balance + sequestre), }}
                        </div>
                    </EqsTextDisplay>
                    <EqsTextDisplay
                        class="pt-2"
                        v-bind="{
                            loading,
                            value: $t('user.wallet.total-balance')
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.getCols('half')">
                    <EqsImage
                        contain
                        v-bind="{
                            aspectRatio: '2.',
                            maxHeight: topHeightToAdd,
                            src: $universe.image('/images/wallet.svg')
                        }"
                    />
                </v-col>
            </v-row>
            <v-col :cols="$reactive.getCols('half')" class="d-flex align-center">
                <v-row class="d-flex justify-end">
                    <EqsBtn
                        v-if="ibanValidated"
                        class="ma-3"
                        el-class="money-out"
                        color="info"
                        v-bind="{
                            loading: loading || balanceLoading || ibanLoading
                        }"
                        @click="
                            () =>
                                $router
                                    .push({ name: 'money-out', params: { id: 'init' } })
                                    .catch(() => {})
                        "
                    >
                        <template #with-icon>
                            <v-icon left> mdi-cash-minus </v-icon>
                            {{ capitalizeFirstLetter($t("user.wallet.withdraw-funds-btn")) }}
                        </template>
                    </EqsBtn>
                    <EqsBtn
                        v-if="!ibanValidated"
                        class="ma-3"
                        el-class="money-out"
                        color="info"
                        v-bind="{
                            loading: loading || balanceLoading || ibanLoading
                        }"
                        @click="
                            () =>
                                $router.push({
                                    name: 'bank',
                                    params: { id: iban ? iban.id.toString() : 'init' }
                                })
                        "
                    >
                        <template #with-icon>
                            <v-icon left> mdi-cash-minus </v-icon>
                            {{ capitalizeFirstLetter($t("user.wallet.withdraw-funds-btn")) }}
                        </template>
                    </EqsBtn>
                    <EqsBtn
                        class="ma-3"
                        el-class="money-in"
                        color="success"
                        v-bind="{ loading }"
                        @click="
                            () =>
                                $router
                                    .push({ name: 'money-in', params: { id: 'init' } })
                                    .catch(() => {})
                        "
                    >
                        <template #with-icon>
                            <v-icon left> mdi-cash-plus </v-icon>
                            {{ capitalizeFirstLetter($t("user.wallet.add-funds-btn")) }}
                        </template>
                    </EqsBtn>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="px-3 d-flex align-center wallets-cards" :style="getTraceStyle">
            <v-col style="height: 8rem">
                <EqsClickableCard
                    v-bind="{
                        width: '100%',
                        height: '100%',
                        value: !!transactions && transactions.isMainAccount,
                        action: fetchMainAccountTx,
                        loading: loading || movementsLoading,
                        subtitle: $t('user.wallet.main-account.subtitle'),
                        infoTxt: $t('user.wallet.main-account.info-txt', { universe })
                    }"
                >
                    <template #text>
                        <div class="d-flex fill-height flex-column justify-center">
                            <EqsTextDisplay
                                v-bind="{
                                    elClass: 'wallet-balance',
                                    eqsType: 'title',
                                    loading: balanceLoading || sequestreMovementsLoading,
                                    value: $currency.display(balance)
                                }"
                            />
                        </div>
                    </template>
                </EqsClickableCard>
            </v-col>
            <v-col style="height: 8rem">
                <EqsClickableCard
                    v-bind="{
                        width: '100%',
                        height: '100%',
                        value: !!transactions && !transactions.isMainAccount,
                        action: fetchSequestreAccountTx,
                        loading,
                        subtitle: $t('user.wallet.sequestre-account.subtitle'),
                        infoTxt: $t('user.wallet.sequestre-account.info-txt')
                    }"
                >
                    <template #text>
                        <div class="d-flex fill-height flex-column justify-center">
                            <EqsTextDisplay
                                v-bind="{
                                    eqsType: 'title',
                                    loading: sequestreLoading,
                                    value: $currency.display(sequestre)
                                }"
                            />
                        </div>
                    </template>
                </EqsClickableCard>
            </v-col>
            <v-col :cols="$reactive.getCols('half')">
                <CurrentBankAccount
                    v-if="iban"
                    v-bind="{ loading }"
                    @AddBankAccount="
                        () =>
                            $router.push({
                                name: 'bank',
                                params: { id: iban ? iban.id.toString() : 'init' }
                            })
                    "
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col>
                <EqsTextDisplay v-bind="{ loading }">
                    <a el-class="info-bank-dialog" @click="() => (limitsDialog = true)">
                        {{ $t("user.wallet.tranfers-limit-btn") }}
                    </a>
                </EqsTextDisplay>
            </v-col>
        </v-row>
        <v-slide-x-transition class="flex">
            <v-row v-if="transactions" class="px-3">
                <WalletTransactions v-bind="{ ...transactions, loading }" />
            </v-row>
        </v-slide-x-transition>
        <TransfersLimitsDialog
            :value.sync="limitsDialog"
            v-bind="{ title: $t('user.wallet.tranfers-limit-title-dialog'), loading }"
        />
    </div>
</template>

<script>
import CurrentBankAccount from "./CurrentBankAccount";
import WalletTransactions from "./WalletTransactions";
import TransfersLimitsDialog from "./TransfersLimitsDialog.vue";
import { capitalizeFirstLetter } from "@equisafe-ui-shared/util";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "Wallet",
    components: {
        CurrentBankAccount,
        TransfersLimitsDialog,
        WalletTransactions
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            limitsDialog: false,
            capitalizeFirstLetter,
            transactions: null
        };
    },
    computed: {
        ...mapGetters([
            "balance",
            "balanceLoading",
            "sequestre",
            "sequestreLoading",
            "movements",
            "movementsLoading",
            "sequestreMovements",
            "sequestreMovementsLoading",
            "ibanLoading",
            "iban"
        ]),
        ibanValidated() {
            return this.iban && this.iban.status && this.iban.status.id === "success";
        },
        topHeightToAdd() {
            return this.$reactive.getVal({
                xs: 96,
                sm: 112,
                md: 128,
                lg: 144,
                xl: 160
            });
        },
        getTraceStyle() {
            return this.loading || this.balanceLoading
                ? {
                      "min-height": "12rem",
                      "--trace-size": "0%"
                  }
                : { "min-height": "12rem", "--trace-size": "80%" };
        },
        errorMoneyOut() {
            if (this.balanceLoading || (this.ibanValidated && this.balance)) return "";
            if (!this.iban) return this.$t("user.wallet.money-out.error-no-iban");
            else if (!this.ibanValidated)
                return this.$t("user.wallet.money-out.error-iban-not-validated");
            else if (!this.balance) return this.$t("user.wallet.money-out.error-no-funds");
            return "";
        },
        universe() {
            return this.$store.getters.universe.public_name;
        }
    },
    watch: {
        balance: {
            immediate: true,
            handler() {
                this.fetchMainAccountTx();
            }
        }
    },
    created() {
        this.getBalance();
        this.getSequestre();
        this.getIban();
    },
    methods: {
        ...mapActions([
            "getBalance",
            "getSequestre",
            "getMovements",
            "getSequestreMovements",
            "getIban"
        ]),
        getCaption(operation) {
            if (operation) {
                return operation.toUpperCase();
            }
            return operation;
        },
        async fetchMainAccountTx() {
            try {
                const movements = await this.getMovements();
                this.mainAccountTx = this.getMetaDataFromTxs(movements).map(t => {
                    if (t.p2ptransfer) {
                        if (!t.caption && !t.p2ptransfer.caption) {
                            t.p2ptransfer.caption = this.getCaption(t.p2ptransfer.operation_type);
                            t.caption = this.getCaption(t.p2ptransfer.operation_type);
                        }
                    }

                    return t;
                });
                const sequestre = await this.getSequestreMovements();
                const allMovements = movements.concat(
                    sequestre.map(tx => {
                        tx.color = "warning";
                        tx.add = tx.to_escrow ? false : true;
                        return tx;
                    })
                );
                this.transactions = {
                    isMainAccount: true,
                    items: allMovements
                };
            } catch (e) {
                this.$ui.error(e, "my_transactions_movements_transactions");
                throw e;
            }
        },
        async fetchSequestreAccountTx() {
            const sequestre = await this.getSequestreMovements();
            this.transactions = {
                isMainAccount: false,
                items: sequestre.map(tx => {
                    if (!tx.to_escrow) {
                        tx.color = "error";
                        tx.add = false;
                    } else {
                        tx.color = "success";
                        tx.add = true;
                    }
                    return tx;
                })
            };
        },
        getMetaDataFromTxs(txs) {
            return txs.map(tx => {
                if (tx.moneyin) {
                    tx.color = "success";
                    tx.add = true;
                } else if (tx.moneyout) {
                    tx.color = "error";
                } else {
                    if (
                        tx.credited_entity &&
                        tx.credited_entity.id === this.$entity.represented().id
                    ) {
                        tx.client = tx.debited_entity;
                        tx.color = "success";
                        tx.add = true;
                    } else {
                        tx.client = tx.credited_entity;
                        tx.color = "error";
                    }
                }
                return tx;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.wallets-cards {
    background: no-repeat left/var(--trace-size) url("/public/images/trace_wallet.svg");
}
</style>
