import { textSizing } from "../../../templates/text";
import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            card: {
                "border-radius": "0.625rem",
                "background-color": "#fff",
                "box-shadow":
                    "0 2px 0 rgba(90,97,105,.11),0 1px 2px rgba(90,97,105,.12),0 1px 1px rgba(90,97,105,.06),0 1px 2px rgba(90,97,105,.1)",
                "overflow-y": "hidden",
                header: {
                    padding: getVal(currentSize, {
                        xs: "8px",
                        sm: "10px",
                        md: "12px",
                        lg: "14px",
                        xl: "16px"
                    })
                },
                content: {
                    padding: getVal(currentSize, {
                        xs: "12px",
                        sm: "12px",
                        md: "12px",
                        lg: "14px",
                        xl: "16px"
                    })
                }
            }
        },
        container: {
            "overflow-y": "hidden",
            card: {
                content: {
                    padding: getVal(currentSize, {
                        xs: "12px",
                        sm: "12px",
                        md: "12px",
                        lg: "14px",
                        xl: "16px"
                    })
                }
            }
        },
        "no-padding": {
            card: {
                content: {
                    padding: "0px"
                }
            }
        },
        overflow: {
            card: {
                "overflow-y": "auto"
            }
        }
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            ...textSizing(currentSize)
        },
        container: {
            ...textSizing(currentSize)
        },
        "no-padding": {
            ...textSizing(currentSize)
        },
        overflow: {
            ...textSizing(currentSize)
        }
    };
};
