<template>
    <EqsChip
        :type="`transaction-chip${deployed || deploying ? '-deploy' : ''}`"
        v-bind="{
            disabled: !deployed,
            loading: deploying,
            attachToVApp: true,
            image: blockchain ? blockchain.icon : null
        }"
        :text-color="tx && tx.error ? 'error' : tx && tx.state === 'done' ? 'success' : 'black'"
        @click="openTx"
    >
        <v-flex layout row justify-center mx-3>
            <div v-if="tx && tx.error" :el-class="`${elClass}-error`">
                {{ name }}
                error during {{ tx.state }}
                <br />
                {{ tx.error.substring(0, 100) + "..." }}
            </div>
            <span v-else-if="deployed" :el-class="`${elClass}-is-deployed`">
                {{ name }}
                {{ deploy ? this.$t("molecule.chip.deployed") : this.$t("molecule.chip.called") }}
            </span>
            <span v-else-if="deploying" :el-class="`${elClass}-deploying`">
                {{ name }}
                {{ deploy ? this.$t("molecule.chip.deploying") : this.$t("molecule.chip.calling") }}
            </span>
            <span v-else :el-class="`${elClass}-not-deployed`">
                {{ name }}
                {{
                    deploy
                        ? this.$t("molecule.chip.not-deployed")
                        : this.$t("molecule.chip.not-called")
                }}
            </span>
        </v-flex>
        <GlobalCopyBadge
            v-if="tx && tx.txhash"
            style="margin-right: 12px; margin-left: 12px"
            :value="tx.txhash"
            :truncate="12"
            width="18px"
        />
        <GlobalExplorerDialog :value.sync="qrDialog" v-bind="{ link: qrcodeLink }" />
    </EqsChip>
</template>

<script>
import { getBlockchain } from "@/services/blockchain/utils";
import { pathInclude } from "@equisafe-ui-shared/util";

export default {
    name: "GlobalTransactionChip",
    inheritAttrs: false,
    props: {
        tx: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: () => {
                return window.app.$t("molecule.chip.transaction");
            }
        },
        elClass: {
            type: String,
            default: "transaction-chip"
        },
        deploy: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            qrDialog: false,
            qrcodeLink: ""
        };
    },
    computed: {
        deployed() {
            return this.tx && this.tx.url && this.tx.state === "done";
        },
        deploying() {
            // todo: might distinguish between different states (deploying / watching) here, ok for now
            return this.tx && this.tx.url && this.tx.state !== "done" && this.tx.state !== "held";
        },
        blockchain() {
            let blckchain = {};
            if (this.tx && this.tx.blockchain) {
                blckchain = getBlockchain(this.tx.blockchain);
            }
            return blckchain;
        }
    },
    methods: {
        openTx(event) {
            if (!this.deployed || pathInclude(event, "copy-badge-icon")) return;
            this.qrDialog = true;
            this.qrcodeLink = `${this.blockchain.explorer}${this.tx.txhash}`;
        }
    }
};
</script>
<style lang="scss"></style>
