<template>
    <div class="internal-toolbar">
        <v-toolbar dense :elevation="0">
            <div class="d-flex flex-wrap">
                <EqsBtn
                    v-for="(action, index) in actions"
                    :key="index"
                    :class="`internal-toolbar__btn`"
                    :input-value="
                        (action.active && editor.isActive(...Object.values(action.active))) || false
                    "
                    v-bind="{
                        action: () => action.action(editor),
                        infoTxt: action.tooltip
                    }"
                >
                    <v-icon>
                        {{ action.icon }}
                    </v-icon>
                </EqsBtn>
                <slot name="actions" />
            </div>
        </v-toolbar>
    </div>
</template>

<script>
export default {
    name: "InternalToolbar",
    props: {
        editor: {
            type: Object,
            required: true
        },
        actions: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style lang="scss">
.internal-toolbar {
    .v-toolbar {
        display: flex;
        height: auto !important;
        width: 100%;
        padding: 5px;
        background-color: #f5f5f5 !important;
        border-color: #f5f5f5 !important;
        border-radius: 8px 0px !important;
    }
    .v-toolbar__content {
        height: auto !important;
    }
}
</style>
