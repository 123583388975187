var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._g(_vm._b({staticClass:"fill-height",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('EqsStepper',_vm._b({attrs:{"value":_vm.step,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":_vm.comeBackToLastRoute},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{class:item.class},[_c(item.is,_vm._b({tag:"component",attrs:{"final-state":_vm.finalState,"state":_vm.stepperState,"editable":"","value":_vm.formData.proceduredocument_set},on:{"update:value":function($event){return _vm.$set(_vm.formData, "proceduredocument_set", $event)},"error":e => {
                                _vm.catchError();
                            }}},'component',{
                            formData: _vm.formData,
                            order: _vm.order,
                            signature_procedure: _vm.signature_procedure,
                            shareledgers: _vm.shareledgers,
                            sellerFees: _vm.sellerFees,
                            schemaDouble: _vm.schemaDouble,
                            loading: _vm.localLoading,
                            isRequired: false
                        },false))],1)]}}])},'EqsStepper',{
                closeInfoTxt: _vm.closeInfoTxt,
                steps: _vm.steps,
                progress: _vm.progress
            },false))]},proxy:true}])},'EqsCard',{ height: '100%', loading: _vm.loading || _vm.loadingCreated },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }