import DEBUG from "@/store/debug.js";

export default async ({ state, commit, dispatch }, procedureId) => {
    DEBUG("FETCH_PROCEDURE_LOCAL_START");
    if (!procedureId) throw new Error("no procedureId");
    if (!state.procedures.length) {
        try {
            await dispatch("getProcedures");
        } catch (e) {
            throw e;
        }
    }
    try {
        const res = await window.app.$api.getProcedure(procedureId, true);
        if (state.procedures.find(procedure => procedure.id === res.data.id))
            commit("UPDATE_PROCEDURE", res.data);
        else commit("ADD_PROCEDURE", res.data);
        DEBUG("FETCH_PROCEDURE_LOCAL_SUCCESS");
        return res.data;
    } catch (e) {
        DEBUG("FETCH_PROCEDURE_LOCAL_ERROR");
        throw e;
    }
};
