<template>
    <div :class="`${elClass} eqs-dialog`" :style="getStyle">
        <v-dialog
            ref="eqs-dialog"
            :value.sync="valueProxy"
            :el-class="`${elClass}-value`"
            v-bind="{
                ...getData,
                attach: attachToVApp ? '#app' : `.${elClass}`,
                overlayColor: $color.colors.almostWhite,
                overlayOpacity: 0.3,
                ...$attrs,
                contentClass: `${elClass}-content eqs-dialog`
            }"
            v-on="$listeners"
        >
            <template #activator="slotData">
                <slot name="activator" v-bind="slotData" />
            </template>
            <v-form
                ref="dialogform"
                v-model="validForm"
                class="d-flex eqs-dialog__form"
                lazy-validation
                :disabled="localLoading || loading"
            >
                <v-card
                    v-bind="{ loading }"
                    class="eqs-dialog__container d-flex flex-column"
                    :el-class="elClass"
                >
                    <slot name="main" />
                    <v-card-title v-if="title" :class="closable ? `px-0` : ''">
                        <v-row
                            class="d-flex flex-nowrap align-stretch eqs-dialog__container__header"
                            no-gutters
                        >
                            <v-col cols="2" />
                            <v-col cols="8" :class="`text-center pa-0 ${getData.textTitleClass}`">
                                <div class="d-flex flex-column align-center">
                                    <EqsLoader
                                        v-if="getData.img || img"
                                        v-bind="{
                                            eqsDataType: getData.loaderType || 'default',
                                            loading: localLoading || loading,
                                            size:
                                                getData.logo && getData.logo.width
                                                    ? getData.logo.width
                                                    : '48px'
                                        }"
                                    >
                                        <v-img
                                            class="pb-1"
                                            v-bind="getData.logo"
                                            :src="getData.img || img"
                                            height="auto"
                                            contain
                                        />
                                    </EqsLoader>
                                    <EqsTextDisplay eqs-type="title">
                                        <div class="break-word" :el-class="`${elClass}-title`">
                                            {{ title }}
                                        </div>
                                    </EqsTextDisplay>
                                </div>
                            </v-col>
                            <v-col
                                cols="2"
                                class="pa-0 pr-2 d-flex justify-end"
                                align-self="center"
                            >
                                <EqsBtn
                                    v-if="closable"
                                    text
                                    el-class="notify-close"
                                    v-bind="{
                                        infoTxt: closeInfoTxt || $t('molecule.dialog.close-txt')
                                    }"
                                    @click="closeDialog"
                                >
                                    <v-icon> mdi-close </v-icon>
                                </EqsBtn>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card-subtitle v-if="subtitle" :class="subtitle && title ? 'pt-3' : ''">
                        <EqsTextDisplay eqs-type="subtitle">
                            <div :class="`${getData.textSubtitleClass} break-word`">
                                {{ subtitle }}
                            </div>
                        </EqsTextDisplay>
                    </v-card-subtitle>
                    <v-slide-x-transition>
                        <v-progress-linear
                            v-if="progress"
                            :value="progress"
                            :color="progressColor"
                        />
                    </v-slide-x-transition>
                    <v-card-text
                        :class="`${getData.textBodyClass} eqs-dialog__container__content ${getData.cardTextClass}`"
                        :el-class="`${elClass}-text`"
                    >
                        <div v-if="text" class="eqs-dialog__container__content__text text-justify">
                            <EqsTextDisplay paragraph v-bind="{ value: text }" />
                        </div>
                        <slot name="default" v-bind="{ loading: loading || localLoading }" />
                    </v-card-text>
                    <v-card-actions
                        v-if="confirm || cancel"
                        class="justify-center align-center flex-wrap pt-0"
                    >
                        <EqsBtn
                            v-if="cancel"
                            :el-class="`${elClass}-cancel`"
                            class="mx-4 my-2"
                            outlined
                            v-bind="{
                                ...cancel,
                                color: cancel.color || getData.color || 'primary',
                                loading,
                                disabled: localLoading,
                                action: cancel && cancel.fn ? cancel.fn : closeDialog
                            }"
                            @error="val => (error = val)"
                            @update:loading="val => (localLoading = val)"
                        >
                            {{ cancel.txt || $t("label.cancel") }}
                        </EqsBtn>
                        <EqsBtn
                            v-if="confirm"
                            :el-class="`${elClass}-confirm`"
                            class="mx-4 my-2"
                            depressed
                            v-bind="{
                                ...confirm,
                                'text-color': 'white',
                                color: confirm.color || getData.color || 'primary',
                                loading,
                                disabled: !validForm || localLoading,
                                action: () =>
                                    validate(confirm && confirm.fn ? confirm.fn : closeDialog)
                            }"
                            @error="val => (error = val)"
                            @update:loading="val => (localLoading = val)"
                        >
                            {{ confirm.txt || $t("label.confirm") }}
                        </EqsBtn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsDialog.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsDialog",
    components: {
        EqsBtn: () => import("../../atoms/EqsBtn/EqsBtn.vue"),
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue"),
        EqsLoader: () => import("../../atoms/EqsLoader/EqsLoader.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-dialog"
        },
        attachToVApp: {
            type: Boolean,
            default: false
        },
        value: {
            type: Boolean,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        confirm: {
            type: Object,
            default: null
        },
        cancel: {
            type: Object,
            default: null
        },
        img: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        subtitle: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        closable: {
            type: Boolean,
            default: false
        },
        closeInfoTxt: {
            type: String,
            default: ""
        },
        progress: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            error: "",
            localLoading: false,
            validForm: false
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) this.$emit("close");
                this.$emit("update:value", val);
            }
        },
        progressColor() {
            if (!this.validForm) return "error";
            return this.progress === 100 ? "success" : "primary";
        }
    },
    watch: {
        localLoading(newValue, oldValue) {
            if (!newValue && oldValue && !this.error) this.closeDialog();
            this.error = "";
        }
    },
    methods: {
        async validate(cb) {
            if (this.$refs.dialogform.validate() && this.validForm) {
                await cb();
            }
        },
        openDialog() {
            this.$emit("update:value", false);
            this.$refs["eqs-dialog"].isActive = false;
        },
        closeDialog() {
            this.$refs.dialogform.resetValidation();
            this.$emit("update:value", false);
            this.$refs["eqs-dialog"].isActive = false;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsDialog.scss";
</style>
