<template>
    <v-container fluid>
        <v-simple-table class="crypto-custom-table mb-6">
            <template #default>
                <tbody>
                    <tr>
                        <td>{{ $t("user.buy.steps.status.crypto.text-4") }}</td>
                        <td>
                            {{ cryptoData.crypto_selected.crypto.protocol }}
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t("user.buy.steps.status.crypto.text-5") }}</td>
                        <td>
                            {{ cryptoData.crypto_selected.crypto.symbol.split("-")[0] }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t("user.buy.steps.status.crypto.rate") }}
                            {{ $t("user.buy.steps.status.crypto.text-6") }}
                            {{ cryptoData.crypto_selected.crypto.symbol.split("-")[0] }}
                        </td>
                        <td>{{ getEurToCryptoRate(cryptoData.rate_eur) }}</td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t("user.buy.steps.status.crypto.rate") }}
                            {{ cryptoData.crypto_selected.crypto.symbol.split("-")[0] }}
                            {{ $t("user.buy.steps.status.crypto.text-7") }}
                        </td>
                        <td>{{ cryptoData.rate_eur }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t("user.buy.steps.status.crypto.text-9") }}</td>
                        <td>0x{{ cryptoData.address }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-simple-table class="crypto-custom-table mb-6">
            <template #default>
                <tbody>
                    <tr>
                        <td class="font-weight-bold">
                            {{ $t("user.buy.steps.status.crypto.text-8") }}
                        </td>
                        <td>
                            {{ amountToSend }}
                            {{ cryptoData.crypto_selected.crypto.symbol.split("-")[0] }}
                            <v-tooltip
                                v-model="showTooltipQuantity"
                                open-on-click
                                :open-on-focus="false"
                                :open-on-hover="false"
                                top
                            >
                                <template #activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                            @click.stop="
                                                () => {
                                                    copyToClipboard(amountToSend, 'quantity');
                                                }
                                            "
                                        >
                                            mdi-content-copy
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t("helpers.copied-to-clipboard") }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">
                            {{ $t("user.buy.steps.status.crypto.text-10") }}
                        </td>
                        <td>
                            0x{{ cryptoData.crypto_selected.address }}
                            <v-tooltip
                                v-model="showTooltipAddress"
                                open-on-click
                                :open-on-focus="false"
                                :open-on-hover="false"
                                top
                            >
                                <template #activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon
                                            @click.stop="
                                                () => {
                                                    copyToClipboard(
                                                        `0x${cryptoData.crypto_selected.address}`,
                                                        'address'
                                                    );
                                                }
                                            "
                                        >
                                            mdi-content-copy
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t("helpers.copied-to-clipboard") }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div v-if="cryptoLink !== null">
            Transaction: <a :href="cryptoLink" target="_blank">{{ cryptoLink }}</a>
        </div>
    </v-container>
</template>

<script>
import { getCryptoAmountStr, getEurToCryptoRate } from "../util";

export default {
    name: "CryptoRecapTable",
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showTooltipQuantity: false,
            showTooltipAddress: false
        };
    },
    computed: {
        cryptoLink() {
            return this.cryptoData?.tx_link;
        },
        cryptoData() {
            return this.order?.payment_data?.cryptodata;
        },
        amountToSend() {
            return getCryptoAmountStr(
                this.order.total_price,
                this.cryptoData.rate_eur,
                this.cryptoData.crypto_selected.crypto.decimals
            );
        }
    },
    methods: {
        getEurToCryptoRate,
        copyToClipboard(text, item) {
            navigator.clipboard.writeText(text);
            if (item === "quantity") {
                if (this.showTooltipQuantity === false) {
                    this.showTooltipQuantity = true;
                    setTimeout(() => (this.showTooltipQuantity = false), 666);
                }
            } else if (item === "address") {
                if (this.showTooltipAddress === false) {
                    this.showTooltipAddress = true;
                    setTimeout(() => (this.showTooltipAddress = false), 666);
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.crypto-custom-table {
    tr td:nth-child(1) {
        text-align: left;
    }
    tr td:nth-child(2) {
        text-align: right;
        font-weight: bold;
    }
}
</style>
