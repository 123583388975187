var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('transition',{attrs:{"name":"eqs-slide-from-right"}},[(_vm.$route.name === 'wallet')?_c('div',{staticClass:"fill-width"},[_c('Wallet',_vm._b({},'Wallet',{ loading: _vm.loading },false))],1):_c('router-view',_vm._b({staticClass:"eqs-card-1 wallet-side-card pa-0 fill-width",on:{"close":() =>
                    _vm.$router
                        .push({
                            name: 'wallet',
                            params: { entityId: _vm.$entity.represented().id }
                        })
                        .catch(() => {})}},'router-view',{ loading: _vm.loading },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }