import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state, dispatch }) => {
    DEBUG("GET_REPRESENTATIVES_START");
    if (state.representativesInit && !state.representativesLoading) {
        try {
            commit("SET_REPRESENTATIVES_LOADING", true);
            await dispatch("getInvestors");
            const representativesRes = await window.app.$api.getRepresentatives(
                store.getters.currentRole.entity_represented.id
            );
            const representatives = representativesRes.data.map(representative => {
                const investor = store.getters.investors.find(
                    _investor => _investor.id === representative.investor_id
                );
                Object.assign(representative.investor, investor);
                return representative;
            });
            DEBUG("GET_REPRESENTATIVES_SUCCESS");
            commit("SET_REPRESENTATIVES", representatives);
        } catch (e) {
            DEBUG("GET_REPRESENTATIVES_ERROR");
            window.app.$ui.error(e, "governance_representative_get");
            throw e;
        } finally {
            commit("SET_REPRESENTATIVES_LOADING", false);
        }
    } else {
        DEBUG("GET_REPRESENTATIVES_BYPASSED");
    }
};
