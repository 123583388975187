import { render, staticRenderFns } from "./_DashboardLedgers.vue?vue&type=template&id=2dc4a2a2&scoped=true"
import script from "./_DashboardLedgers.vue?vue&type=script&lang=js"
export * from "./_DashboardLedgers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc4a2a2",
  null
  
)

export default component.exports