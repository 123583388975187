import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_SEQUESTRE_START");
    if (state.sequestreInit && !state.sequestreLoading) {
        try {
            commit("SET_SEQUESTRE_LOADING", true);
            // TODO replace here with new call
            const sequestreRes = await window.app.$api.getMySequestre();
            const sequestre = sequestreRes.data;
            DEBUG("GET_SEQUESTRE_SUCCESS");
            commit("SET_SEQUESTRE", sequestre);
        } catch (e) {
            DEBUG("GET_SEQUESTRE_ERROR");
            window.app.$ui.error(e, "account_balance");
            throw e;
        } finally {
            commit("SET_SEQUESTRE_LOADING", false);
        }
    } else {
        DEBUG("GET_SEQUESTRE_BYPASSED");
        return state.sequestre;
    }
};
