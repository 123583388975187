import DEBUG from "@/store/debug.js";

export default async ({ state, commit }, setlimitId) => {
    DEBUG("GET_SETLIMIT_START");
    if (!setlimitId) throw new Error("no setlimitId");
    if (!state.setlimitLoading) {
        try {
            commit("SET_SETLIMIT_LOADING", true);
            const res = await window.app.$api.getTransaction(setlimitId);
            commit("GET_SETLIMIT", res.data);
        } catch (e) {
            DEBUG("GET_SETLIMIT_ERROR");
            console.error({ e });
            throw e;
        } finally {
            commit("SET_SETLIMIT_LOADING", false);
        }
    }
};
