var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"align-start",class:!_vm.isRoot ? 'fill-height' : '',attrs:{"fluid":""}},[(_vm.isRoot && _vm.isEntityCompany)?_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"custom-btn",attrs:{"outlined":""},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.$t("document-to-sign.add"))+" ")])],1)],1):_vm._e(),(_vm.isRoot)?_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[(_vm.getDocumentListCols)?_c('v-col',{staticClass:"py-0 fill-height",attrs:{"cols":_vm.getDocumentListCols}},[_c('DataTablePaginated',{attrs:{"loading":_vm.loading || _vm.localLoading,"headers":_vm.headers,"items":_vm.procedures,"sort-by":"updated_at","sort-desc":"","search":"name","show-row-pointer":"","filter":{ field: 'status', items: _vm.statusCategories }},on:{"update":_vm.fetchSignatures,"click-row":_vm.selectRow},scopedSlots:_vm._u([{key:`item.updated_at`,fn:function({ value }){return [_vm._v(" "+_vm._s(value ? _vm.$time.formated(value) : "-")+" ")]}},{key:`item.name`,fn:function({ item }){return [_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},(_vm.isEntityCompany)?{key:`item.n_of_signers`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.n_of_signers)+" ")]}}:null,{key:`item.status`,fn:function({ value }){return [(value)?_c('EqsChip',_vm._b({},'EqsChip',_vm.procedureStatus[value],false),[_vm._v(" "+_vm._s(_vm.procedureStatus[value].text)+" ")]):_c('span',[_vm._v("-")])]}},{key:`item.status_mine`,fn:function({ item, value }){return [(
                            (value === 'pending' || value === 'processing') &&
                            _vm.signerAndNotSigned(item)
                        )?_c('EqsBtn',_vm._b({staticClass:"d-inline-block",attrs:{"small":""}},'EqsBtn',{
                            action: () =>
                                _vm.$router.push({
                                    name: 'simple-sign-stepper',
                                    params: { procedureId: item.id }
                                }),
                            color: 'primary'
                        },false),[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v(" mdi-pencil-outline ")]),_vm._v(" "+_vm._s(_vm.$t("document-to-sign.sign-btn"))+" ")],1):(value)?_c('EqsChip',_vm._b({},'EqsChip',_vm.getSignerStatus(value),false),[_vm._v(" "+_vm._s(_vm.getSignerStatus(value).text)+" ")]):_c('span',[_vm._v("-")])]}},(_vm.isEntityCompany)?{key:`item.action`,fn:function({ item }){return [_c('EqsActions',_vm._b({},'EqsActions',{ actions: _vm.getActions(item) },false))]}}:null],null,true)})],1):_vm._e(),(_vm.displayProcedureDetails || _vm.displaySignature)?_c('v-col',{staticClass:"py-0 fill-height",attrs:{"cols":_vm.$reactive.isDesktop() ? 5 : 12}},[(_vm.displayProcedureDetails)?_c('ProcedureDetails',{attrs:{"signature-procedure":_vm.procedureDetails,"is-company":_vm.isEntityCompany},on:{"close":_vm.closeProcedureDetails}}):_vm._e()],1):_vm._e()],1):_c('router-view',_vm._b({},'router-view',{ loading: _vm.loading || _vm.localLoading },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }