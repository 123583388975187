<template>
    <v-container v-if="isRoot" fluid class="ma-0 pa-0">
        <EqsTabs
            v-if="isRoot"
            :tabs="tabs"
            :value.sync="tab"
            data-type="default"
            v-bind="{ tabs, elClass: 'operations-tabs' }"
        >
            <template #default="{ item }">
                <component :is="item.is" v-bind="{ loading }" />
            </template>
        </EqsTabs>
    </v-container>
    <router-view v-else :loading="loading" />
</template>

<script>
import Movements from "@/components/views/shareledger/operations/movements/_Movements";
import Payments from "@/components/views/shareledger/operations/payments/_Payments";
import History from "@/components/views/capitalIncrease/admin/History.vue";

export default {
    name: "Operations",
    components: {
        Movements,
        Payments,
        History
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tab: "movements"
        };
    },
    computed: {
        isRoot() {
            return this.$route.name === "operations";
        },
        tabs() {
            return [
                {
                    id: "movements",
                    title: this.$t("movements.title"),
                    item: {
                        is: "Movements"
                    }
                },
                {
                    id: "payments",
                    title: this.$t("payments.title"),
                    item: {
                        is: "Payments"
                    }
                },
                {
                    id: "fundraising",
                    title: this.$t("app.navigation-drawer.title.fundraising"),
                    item: {
                        is: () => import("@/components/views/capitalIncrease/admin/History.vue")
                    }
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped></style>
