<!--
    This template describe the new home dashboard

 -->

<template>
    <div class="main-block-spacing">
        <!-- Main title -->
        <EqsTextDisplay
            class="main-title"
            eqs-type="title"
            v-bind="{ value: $t('dashboard.dashboard-title') }"
        />

        <UserStats
            v-bind="{
                loading: localLoading,
                stats: { balance, sequestre, number_of_projects: projectsFunded.length },
                userId: id
            }"
            class="pa-0 ma-0"
        />
        <EqsDataIterator
            v-if="projectsToCome?.length"
            class="auto-height"
            el-class="projects-list"
            :hide-default-footer="true"
            v-bind="{
                items: projectsToCome,
                loading: localLoading
            }"
        >
            <template #header>
                <v-col cols="auto">
                    <h1 style="color: grey !important">{{ $t("dashboard.projects-tocome") }}</h1>
                </v-col>
            </template>
            <template #item="{ item, index }">
                <OfferCard
                    v-bind="{
                        item,
                        height: offerCardHeight,
                        loading: localLoading,
                        elClass: `project-${index}`
                    }"
                    @open="() => openProject(item)"
                />
            </template>
        </EqsDataIterator>
        <EqsDataIterator
            v-if="projectsCurrent?.length"
            class="auto-height"
            el-class="projects-list"
            :hide-default-footer="true"
            v-bind="{
                items: projectsCurrent,
                loading: localLoading
            }"
        >
            <template #header>
                <v-col cols="auto">
                    <h1 style="color: grey !important">
                        {{ $t("dashboard.projects-current") }}
                    </h1>
                </v-col>
            </template>
            <template #item="{ item, index }">
                <OfferCard
                    v-bind="{
                        item,
                        height: offerCardHeight,
                        loading: localLoading,
                        elClass: `project-${index}`
                    }"
                    @open="() => openProject(item)"
                />
            </template>
        </EqsDataIterator>
        <h1 v-else class="mt-4">
            {{ $t("dashboard.projects-none") }}
        </h1>
    </div>
</template>

<script>
import UserStats from "./UserStats.vue";
import OfferCard from "@/components/molecules/card/OfferCard";
import { mapActions, mapGetters } from "vuex";
import { isCapincEnded, isCapincToCome } from "@/components/views/projects/utils";

export default {
    name: "Dashboard",
    components: {
        UserStats,
        OfferCard
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            localLoading: true,
            stats: {},
            projectsFunded: []
        };
    },
    computed: {
        ...mapGetters(["balance", "balanceLoading", "sequestre", "sequestreLoading", "projects"]),
        offerCardHeight() {
            return this.$reactive.getVal({
                xs: "20rem",
                sm: "20rem",
                md: "22rem",
                lg: "22rem",
                xl: "22rem"
            });
        },
        projectsCurrent() {
            let allProjects = [];
            allProjects.push(...this.projects);
            return allProjects
                .filter(project => !isCapincEnded(project) && !isCapincToCome(project))
                .sort(
                    (a, b) =>
                        this.$time.getUnixTime(a.start_date) <= this.$time.getUnixTime(b.start_date)
                );
        },
        projectsToCome() {
            let allProjects = [];
            allProjects.push(...this.projects);
            return allProjects
                .filter(project => isCapincToCome(project))
                .sort(
                    (a, b) =>
                        this.$time.getUnixTime(a.start_date) <= this.$time.getUnixTime(b.start_date)
                );
        }
    },
    async created() {
        this.getProjects();
        this.getProjectsCip();
        this.getBalance();
        this.getSequestre();
    },
    mounted() {
        this.localLoading = false;
    },
    methods: {
        ...mapActions(["getBalance", "getSequestre", "getProjects", "getProjectsCip"]),
        openProject(item) {
            if (item.cip && !this.$entity.isPsfp()) {
                this.$router.push({
                    name: "risk-aversion",
                    params: this.$route.params,
                    query: { projectId: item.id }
                });
            } else if (item.cip) {
                this.$router.push({
                    name: "corner-project-detail",
                    params: { projectId: item.id, id: this.$route.params.id }
                });
            } else {
                this.$router.push({
                    name: "project-detail",
                    params: { projectId: item.id }
                });
            }
        }
    }
};
</script>

<style scoped>
.main-title {
    margin-bottom: 2em;
}
.main-block-spacing {
    padding: 3em 6% 1em;
    max-width: 2000px;
}
</style>
