import events from "../events";
import _ from "lodash";

function formatError(e) {
    let error = e.statusText;
    if (!_.isEmpty(e.data)) {
        if (_.isArray(e.data))
            for (let value of e.data) {
                error += `\n${value}`;
            }
        else if (_.isString(e.data)) error += `\n${e.data}`;
        else if (_.isObjectLike(e.data)) {
            if (e.data.detail) error += `\n${e.data.detail}`;
            else if (e.data.msg) error += `\n${e.data.msg}`;
            else
                for (let [key, value] of Object.entries(e.data)) {
                    error += `\n${key}: ${value[0]}`;
                }
        }
    }
    return error;
}

function getError(e, from, options) {
    if (_.isNil(e)) {
        console.error(`undefined error from "${from}" !`);
        return null;
    }
    if (!_.isObjectLike(e)) return _.isString(e) ? e : e.toString();
    console.error({
        e
    });
    const convertError =
        !!options && !!options.getStatusCodeError ? options.getStatusCodeError(e, from) : e;
    return formatError(convertError);
}

export default {
    install(Vue, options) {
        const ui = Object.freeze({
            success: message => events.$emit("alert", "success", message),
            info: message => events.$emit("alert", "info", message),
            warning: message => events.$emit("alert", "warning", message),
            error: (e, from = null, linkCallback = null, linkMessage = "") => {
                const error = getError(e, from, options);
                events.$emit("alert", "error", error, linkCallback, linkMessage);
                return error;
            }
        });
        Object.defineProperty(Vue.prototype, "$ui", {
            get() {
                return ui;
            }
        });
    }
};
