import getCorner from "./actions/get_corner";
import getCorners from "./actions/get_corners";

import getProjects from "./actions/get_projects";
import getProjectsCip from "./actions/get_projects_cip";
import getProject from "./actions/get_project";
import addProject from "./actions/add_project";

import getMyProjects from "./actions/get_my_projects";
import getMyProject from "./actions/get_my_project";
import addMyProject from "./actions/add_my_project";
import addMyProjectToList from "./actions/add_my_project_to_list";
import deleteMyProject from "./actions/delete_my_project";
import fetchMyProject from "./actions/fetch_my_project";
import updateMyProject from "./actions/update_my_project";

export default {
    getCorner,
    getCorners,
    getProjects,
    getProjectsCip,
    getProject,
    addProject,
    getMyProjects,
    getMyProject,
    addMyProject,
    addMyProjectToList,
    deleteMyProject,
    fetchMyProject,
    updateMyProject
};
