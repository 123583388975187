<template>
    <EqsStepper
        :value.sync="step"
        v-bind="{ steps, loading: loading || localLoading || !iban, progress }"
        style="height: 100%"
    >
        <template #default="{ item, loading: _loading, validForm }">
            <div class="eqs-main-container margin-auto">
                <component
                    :is="item.is"
                    :step.sync="step"
                    :form-data.sync="formData"
                    v-bind="{
                        loading: _loading || ibanLoading,
                        order,
                        validForm,
                        status,
                        state: finalState,
                        doc: documentProof,
                        loading
                    }"
                >
                    <template #iban>
                        <CurrentBankAccount
                            v-bind="{ loading: localLoading, bankDetailsOnly: true }"
                            class="mb-3"
                        />
                    </template>
                </component>
            </div>
        </template>
    </EqsStepper>
</template>

<script>
import WithdrawAmount from "./WithdrawAmount";
import WithdrawSummary from "./WithdrawSummary";
import WithdrawFinalStep from "./WithdrawFinalStep";
import CurrentBankAccount from "../CurrentBankAccount.vue";
import { statutes, schemaDoubleStep1 } from "@/util/moneyOut.js";
import { progressMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "MoneyOut",
    components: {
        CurrentBankAccount,
        WithdrawAmount,
        WithdrawSummary,
        WithdrawFinalStep
    },
    mixins: [progressMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            statutes,
            localLoading: false,
            step: 0,
            formData: {},
            fees: {},
            order: {},
            schemaDouble: {},
            finalState: "success",
            documentProof: null
        };
    },
    computed: {
        ...mapGetters(["ibanLoading", "iban"]),
        steps() {
            let steps = [
                {
                    title: this.$t("user.wallet.money-out.withdraw-amount"),
                    item: {
                        is: "WithdrawAmount"
                    },
                    back: {
                        fn: () => this.$emit("close"),
                        txt: this.$t("label.cancel")
                    }
                },
                {
                    title: this.$t("user.wallet.money-out.withdraw-summary"),
                    item: {
                        is: "WithdrawSummary"
                    },

                    next: {
                        fn: this.moneyInConfirm,
                        txt: this.$t("user.wallet.money-out.confirm-withdrawal")
                    }
                }
            ];
            let orderStatus = {
                title: this.$t("user.buy.order-status"),
                item: {
                    is: "WithdrawFinalStep"
                },
                next: {
                    fn: () => this.$emit("close"),
                    txt: this.$t("label.my-wallet")
                },
                noBack: true
            };
            steps.push(orderStatus);
            return steps;
        },
        status() {
            return this.order && this.order.status
                ? this.statutes[this.order.status]
                : this.statutes["pending"];
        }
    },
    watch: {
        "formData.moneyVal"(val) {
            this.formData.totalWithdrawal = val - this.formData.fee;
        },
        iban: {
            immediate: true,
            async handler(val) {
                if (val) {
                    await this.getFees();
                    this.initFormData();
                }
            }
        },
        step: {
            immediate: true,
            handler(val) {
                if (val === 0) this.schemaDouble = schemaDoubleStep1;
                else {
                    this.schemaDouble = {};
                }
            }
        }
    },
    created() {
        this.getIban();
    },
    methods: {
        ...mapActions(["getIban"]),
        async getFees() {
            this.localLoading = true;
            try {
                const res = await this.$api.getMoneyOutFees();
                this.fees = res.data;
            } catch (e) {
                console.error({ e });
            } finally {
                this.localLoading = false;
            }
        },
        initFormData() {
            this.formData = {
                moneyVal: 0,
                fee: this.fees[this.iban.is_sepa ? "eee" : "world"].fixed
            };
        },
        async moneyInConfirm() {
            try {
                this.localLoading = true;
                const res = await this.$api.createMoneyOut(this.formData.totalWithdrawal);
                this.documentProof = res.data.document_proof_MoneyOut;
                this.$set(this, "order", {
                    ...this.order,
                    ...res.data,
                    total_price: this.formData.totalWithdrawal,
                    status: res.data.lemonway_status
                });
                this.step++;
            } catch (e) {
                this.$ui.error(e, "buy_trigger_buy_process");
                this.finalState = "error";
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>
