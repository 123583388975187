<template>
    <v-lazy>
        <v-container fluid>
            <v-row class="px-3 height-fit pt-2">
                <v-col
                    v-for="(card, index) in dashboardData.cards"
                    :key="index"
                    :cols="getColsCards"
                >
                    <EqsClickableCard
                        v-bind="{
                            width: '100%',
                            height: '100%',
                            loading,
                            eqsType: 'notClickable',
                            ...card
                        }"
                    >
                        <template #text>
                            <div
                                class="d-flex fill-height flex-column justify-center text-right full-width"
                            >
                                <EqsTextDisplay
                                    v-bind="{
                                        elClass: 'wallet-balance',
                                        eqsType: 'title',
                                        value: getAmount(card)
                                    }"
                                />
                            </div>
                        </template>
                    </EqsClickableCard>
                </v-col>
                <v-spacer />
            </v-row>
            <v-row class="px-3 d-flex" style="height: 80%">
                <v-col :cols="$reactive.isDesktop() ? '8' : '12'" class="fill-height">
                    <ShareholdersList
                        v-bind="{
                            loading,
                            headers,
                            items: dashboardData.table.items
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.isDesktop() ? '4' : '12'">
                    <EqsPie
                        v-if="dashboardData.chart"
                        v-bind="{ config: dashboardData.chart }"
                        style="height: inherit; width: 100%"
                    />
                    <EqsSwitch
                        el-class="share-transfer"
                        style="text-align: center"
                        v-bind="{ loading }"
                        :value.sync="diluted"
                    >
                        <template #label>
                            <EqsTextDisplay>
                                {{ $t("capitalisation.general-view.diluted") }}
                            </EqsTextDisplay>
                        </template>
                    </EqsSwitch>
                </v-col>
            </v-row>
        </v-container>
    </v-lazy>
</template>

<script>
import ShareholdersList from "../ShareholdersList.vue";
import { cloneDeep } from "lodash";

export default {
    name: "Dashboard1",
    components: {
        ShareholdersList
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        dashboardData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            diluted: false,
            headers: [
                {
                    text: this.$t("label.name"),
                    value: "entity.name"
                },
                {
                    text: this.$t("label.number-of-shares"),
                    value: "shares",
                    filterable: false,
                    sortable: false
                },
                {
                    text: this.$t("capitalisation.general-view.interest"),
                    value: "percent",
                    filterable: false
                },
                {
                    text: this.$t("capitalisation.general-view.investment"),
                    value: "amount",
                    filterable: false
                }
            ]
        };
    },
    computed: {
        getColsCards() {
            return this.$reactive.getVal({
                xs: "6",
                sm: "6",
                md: "3",
                lg: "2",
                xl: "2"
            });
        },
        shareledger() {
            console.log(this.dashboardData);
            return null;
        }
    },
    methods: {
        getAmount(item) {
            if (item.abreviate && item.isCurrency) {
                return this.$currency.abreviate(
                    item.amount,
                    true,
                    this.$entity.represented().country.alphacode == "US" ? "USD" : "EUR",
                    "0.00"
                );
            }

            return item.isCurrency
                ? this.$currency.displayWithCurrency(
                      item.amount,
                      this.$entity.represented().country.alphacode == "US" ? "USD" : "EUR"
                  )
                : item.amount.toString();
        }
    }
};
</script>

<style lang="scss" scoped></style>
