var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-main-container margin-auto"},[_c('EqsViewWithImage',_vm._b({staticClass:"mx-3"},'EqsViewWithImage',{ img: _vm.$universe.image('/images/identification.svg') },false),[_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{ eqsType: 'title', value: _vm.$t('not-registered.title') },false))],1)],1),(_vm.failedRoute && _vm.failedRoute.meta && _vm.failedRoute.meta.context)?_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('EqsTextDisplay',_vm._b({attrs:{"paragraph":""}},'EqsTextDisplay',{
                        value: _vm.$t('not-registered.subtitle.text-1', {
                            section: _vm.failedRoute.meta.context
                        })
                    },false))],1)],1):_vm._e(),_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('EqsTextDisplay',{attrs:{"paragraph":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("not-registered.subtitle.text-2"))+" "),_c('a',{on:{"click":() =>
                                    _vm.$router.push(
                                        _vm.needToCompleteRegistration
                                            ? `/registration/${_vm.$entity.represented().id}`
                                            : `/registration/${_vm.$entity.represented().id}/verify`
                                    )}},[_vm._v(" "+_vm._s(_vm.$t("not-registered.subtitle.click-here")))]),_vm._v(". ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }