<template>
    <v-card flat class="d-flex flex-column eqs-carousel" v-bind="{ height: '100%', width: '100%' }">
        <v-carousel
            v-model="value"
            v-bind="{ ...getData, ...$attrs, 'el-class': elClass }"
            :style="getStyle"
            :show-arrows="false"
            :hide-delimiters="true"
            class="eqs-carousel__container"
            v-on="$listeners"
        >
            <v-carousel-item
                v-for="(item, i) in items"
                :key="i"
                :src="item[imgKey]"
                lazy
                class="eqs-carousel__item"
            />
        </v-carousel>
        <InternalWindowsControl :value.sync="value" v-bind="{ items, cycle }" />
    </v-card>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsCarousel.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsCarousel",
    components: {
        InternalWindowsControl: () => import("../EqsWindows/_internal/InternalWindowsControl.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-carousel"
        },
        items: {
            type: Array,
            required: true
        },
        imgKey: {
            type: String,
            default: "src"
        },
        cycle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            value: 0
        };
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsCarousel.scss";
</style>
