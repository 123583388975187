import { render, staticRenderFns } from "./_Dashboard.vue?vue&type=template&id=11bc656d&scoped=true"
import script from "./_Dashboard.vue?vue&type=script&lang=js"
export * from "./_Dashboard.vue?vue&type=script&lang=js"
import style0 from "./_Dashboard.vue?vue&type=style&index=0&id=11bc656d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11bc656d",
  null
  
)

export default component.exports