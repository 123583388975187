import cloneDeep from "lodash/cloneDeep";
import { Style, GREY, BLUE } from "../Style";
import { i18n } from "@/services/plugins/i18n.js";

export const tableList = [
    {
        key: "date",
        value: "Date",
        width: 10
    },
    {
        key: "orderNumber",
        value: i18n.t("excel-js.order-number")
    },
    {
        value: "Shareledger",
        children: [
            {
                key: "sharledgerName",
                value: i18n.t("label.name"),
                width: 20
            },
            {
                key: "shareledgerAddress",
                value: "Nº",
                width: 30
            }
        ]
    },
    {
        key: "txHash",
        value: "TxHash",
        width: 30
    },
    {
        value: "Owner",
        children: [
            {
                key: "nameOwner",
                value: i18n.t("label.name"),
                width: 20
            },
            {
                key: "accountOwner",
                value: i18n.t("excel-js.account"),
                width: 30
            }
        ]
    },
    {
        key: "numberOfShares",
        value: i18n.t("label.number-of-shares"),
        width: 20
    },
    {
        key: "totalPrice",
        value: i18n.t("excel-js.total-price"),
        width: 20
    },
    {
        key: "orderNature",
        value: i18n.t("excel-js.order-nature"),
        width: 20
    },
    {
        value: "Receiver",
        children: [
            {
                key: "nameReceiver",
                value: i18n.t("label.name"),
                width: 20
            },
            {
                key: "accountReceiver",
                value: i18n.t("excel-js.account"),
                width: 30
            }
        ]
    },
    {
        key: "observation",
        value: "Observation",
        width: 40
    }
];

export const ledgerInfo = [
    {
        key: "Ledger",
        value: i18n.t("label.shareledger"),
        children: [
            {
                key: "Name",
                value: i18n.t("label.name"),
                width: 20
            },
            {
                key: "TotalShares",
                value: i18n.t("excel-js.total-shares"),
                width: 30
            },
            {
                key: "ContractAddress",
                value: i18n.t("excel-js.contract-address"),
                width: 30
            }
        ]
    }
];

export const shareholderTable = [
    {
        key: "numero",
        value: "N°",
        width: 10
    },
    {
        key: "txHash",
        value: "txHash"
    },
    {
        key: "Date",
        value: "Date"
    },
    {
        key: "Operator",
        value: i18n.t("excel-js.operator")
    },
    {
        key: "TitleNumber",
        value: "TitleNumber",
        children: [
            {
                key: "Credit",
                value: i18n.t("excel-js.credit"),
                width: 20
            },
            {
                key: "Debit",
                value: i18n.t("excel-js.debit"),
                width: 30
            },
            {
                key: "Solde",
                value: i18n.t("excel-js.solde"),
                width: 30
            }
        ]
    },
    {
        key: "observation",
        value: i18n.t("excel-js.observation"),
        width: 40
    }
];

export const headerWithHeaderStyle = tableToCopy =>
    Style.apply(cloneDeep(tableToCopy), Style.header);

const flatChildren = table =>
    table.reduce((acc, item) => {
        if (item.children) {
            acc = [...acc, ...flatChildren(item.children)];
        } else {
            acc.push(item);
        }
        return acc;
    }, []);

export const headerWithContentStyle = tableToCopy =>
    Style.apply(flatChildren(cloneDeep(tableToCopy)), Style.content);
