<template>
    <EqsCard
        v-bind="{
            loading
        }"
    >
        <v-alert v-if="!$universe.isNeotrees()" border="left" dense text type="info">{{
            $t("capital-increase.user.detail.warning-investment")
        }}</v-alert>

        <v-row class="mb-8">
            <v-col class="custom-table" lg="6" cols="12">
                <v-simple-table>
                    <template #default>
                        <tbody>
                            <tr
                                v-if="
                                    capitalIncrease &&
                                    capitalIncrease.shareledger &&
                                    capitalIncrease.shareledger.share_type !== 'tokens' &&
                                    capitalIncrease.pre_money_valuation
                                "
                            >
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.pre-money-valuation"
                                        )
                                    }}
                                </td>
                                <td>
                                    <NumberDisplayRefont
                                        :currency-type="capitalIncrease.currency"
                                        :value="capitalIncrease.pre_money_valuation"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.minimum-fundraising-amount"
                                        )
                                    }}
                                </td>
                                <td>
                                    <NumberDisplayRefont
                                        :currency-type="capitalIncrease.currency"
                                        :value="capitalIncrease.soft_cap"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.maximum-fundraising-amount"
                                        )
                                    }}
                                </td>
                                <td>
                                    <NumberDisplayRefont
                                        :currency-type="capitalIncrease.currency"
                                        :value="capitalIncrease.hard_cap"
                                    />
                                </td>
                            </tr>
                            <tr v-if="capitalIncrease.end_date && capitalIncrease.start_date">
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.public-sale-duration"
                                        )
                                    }}
                                </td>
                                <td>
                                    {{
                                        $time.duration(
                                            capitalIncrease.end_date,
                                            capitalIncrease.start_date
                                        )
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        capitalIncrease?.capincpaymentoptions?.fee_options
                                            ?.is_included === true
                                            ? $t(
                                                  "capital-increase.user.detail.investment-term.minimum-investment-tax-included"
                                              )
                                            : $t(
                                                  "capital-increase.user.detail.investment-term.minimum-investment"
                                              )
                                    }}
                                </td>
                                <td>
                                    <NumberDisplayRefont
                                        :currency-type="capitalIncrease.currency"
                                        :value="capitalIncrease.min_investment"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.maximum-investment"
                                        )
                                    }}
                                </td>
                                <td>
                                    <NumberDisplayRefont
                                        :currency-type="capitalIncrease.currency"
                                        :value="capitalIncrease.max_investment"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col
                v-if="capitalIncrease && capitalIncrease.shareledger"
                class="custom-table second-table"
                lg="6"
                cols="12"
            >
                <v-simple-table>
                    <template #default>
                        <tbody>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.share-type"
                                        )
                                    }}
                                </td>
                                <td>{{ getShareledgerType }}</td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.share-name"
                                        )
                                    }}
                                </td>
                                <td>{{ capitalIncrease.shareledger.name }}</td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.share-price"
                                        )
                                    }}
                                </td>
                                <td>{{ sharePriceFormatted }}</td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.stock-transferable"
                                        )
                                    }}
                                </td>
                                <td>
                                    {{
                                        capitalIncrease.shareledger.share_transfer
                                            ? $t("label.yes")
                                            : $t("label.no")
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.stock-tradable"
                                        )
                                    }}
                                </td>
                                <td>
                                    {{
                                        capitalIncrease.shareledger.share_only_investor_transfer
                                            ? $t("label.yes")
                                            : $t("label.no")
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <!-- <v-row v-if="capitalIncrease?.shareledger?.share_type === 'bonds'" class="justify-center">
            <v-col class="custom-table" lg="6" cols="12">
                <v-simple-table>
                    <template #default>
                        <tbody>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.bonds.interest-rate"
                                        )
                                    }}
                                </td>
                                <td>
                                    {{
                                        capitalIncrease.shareledger.bonds_detail.interest_rate +
                                        " %"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ $t("shareledger.dialog.stepper.tokenize.bonds.interest") }}
                                </td>
                                <td>
                                    {{
                                        capitalIncrease.shareledger.bonds_detail.interest_type ===
                                        "fix"
                                            ? $t("shareledger.dialog.stepper.tokenize.bonds.fix")
                                            : $t(
                                                  "shareledger.dialog.stepper.tokenize.bonds.variable"
                                              )
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "capital-increase.user.detail.investment-term.bonds.exercice-duration"
                                        )
                                    }}
                                </td>
                                <td>
                                    {{
                                        capitalIncrease.shareledger.bonds_detail.exercice_duration +
                                        $t(
                                            "capital-increase.user.detail.investment-term.bonds.exercice-duration-month"
                                        )
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        $t("shareledger.dialog.stepper.tokenize.bonds.label-coupon")
                                    }}
                                </td>
                                <td>
                                    {{
                                        capitalIncrease.shareledger.bonds_detail
                                            .coupon_in_fine_or_each_year === "in_fine"
                                            ? $t(
                                                  "shareledger.dialog.stepper.tokenize.bonds.in-fine"
                                              )
                                            : $t(
                                                  "shareledger.dialog.stepper.tokenize.bonds.every-year"
                                              )
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{
                                        $t(
                                            "shareledger.dialog.stepper.tokenize.bonds.label-nominal-repayment"
                                        )
                                    }}
                                </td>
                                <td>
                                    {{
                                        capitalIncrease.shareledger.bonds_detail
                                            .nominal_repayment === "in_fine"
                                            ? $t(
                                                  "shareledger.dialog.stepper.tokenize.bonds.in-fine"
                                              )
                                            : $t(
                                                  "shareledger.dialog.stepper.tokenize.bonds.every-year"
                                              )
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row> -->
    </EqsCard>
</template>

<script>
import { share_type } from "@/services/shareledger";
import NumberDisplayRefont from "./NumberDisplayRefont.vue";
import { getShareledgerTypeStr } from "@/components/views/user/buy/steps/util";

export default {
    name: "InvestmentTermRefont",
    components: { NumberDisplayRefont },
    props: {
        capitalIncrease: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            share_type
        };
    },
    computed: {
        sharePriceFormatted() {
            const sharePriceFloat = parseFloat(this.capitalIncrease.share_price);
            const sharePriceStr = sharePriceFloat.toString().replace(".", ",");
            const symbol = this.capitalIncrease.currency === "EUR" ? "€" : "$";
            return `${sharePriceStr} ${symbol}`;
        },
        getShareledgerType() {
            return getShareledgerTypeStr(this, this.capitalIncrease.shareledger);
        },
        setlimit_set() {
            return this.$entity.represented().setlimit_set;
        },
        getCols() {
            return this.$reactive.getVal({
                mobile: 6,
                tablet: 4,
                desktop: 4
            });
        }
        // docsToSign() {
        //     if (!this.capitalIncrease || !this.capitalIncrease.proceduredocument_set) return [];
        //     let docs = [];
        //     for (const doc of this.capitalIncrease.proceduredocument_set)
        //         if (doc.document) docs.push(doc.document);
        //     return docs;
        // }
    }
};
</script>
<style lang="scss">
.custom-table {
    th,
    td {
        // text-align: left;
        padding: 10px 6px;
        font-weight: bolder;
        font-size: 1rem;
    }

    tr > td:nth-child(odd) {
        min-width: 60%;
    }
}
</style>
