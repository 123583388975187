<template>
    <EqsCard class="fill-height" v-bind="{ height: '100%', loading: loading || localLoading }">
        <template #main>
            <EqsStepper
                style="height: 100%"
                :value.sync="step"
                :steps="steps"
                :loading="localLoading"
                v-bind="{
                    progress
                }"
            >
                <template #default="{ item }">
                    <component
                        :is="item.is"
                        :state="stepperState"
                        :form-data.sync="formData"
                        :all-data.sync="allData"
                        :csv-error.sync="csvError"
                        :loading="loading || localLoading"
                        :action="action"
                        v-bind="{
                            schemaDouble,
                            checkValideData,
                            parseCSV
                        }"
                        @error="
                            e => {
                                catchError();
                            }
                        "
                    />
                </template>
            </EqsStepper>
        </template>
    </EqsCard>
</template>

<script>
import {
    progressMixin,
    buildFormDataMixin,
    formatFormDataMixin
} from "@equisafe-ui-vue/src/mixins/formMixin";
import AddPaymentCSVFile from "@/components/views/shareledger/operations/payments/AddPaymentCSVFile";
import Done from "@/components/views/shareledger/operations/payments/Done";

export default {
    name: "AddDownloadPayment",
    components: {
        AddPaymentCSVFile,
        Done
    },
    mixins: [progressMixin, buildFormDataMixin, formatFormDataMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            action: "download",
            operation: {},
            steps: [],
            step: -1,
            stepperState: "success",
            schemaDouble: {},
            formData: {},
            localLoading: false,
            allData: [],
            csvError: [],
            actionsText: {
                download: {
                    title: this.$t("payments.download.title"),
                    done: this.$t("payments.download.done")
                },
                ask: {
                    title: this.$t("payments.ask.title"),
                    done: this.$t("payments.ask.done")
                },
                upload: {
                    title: this.$t("payments.upload.title"),
                    done: this.$t("payments.upload.done")
                }
            }
        };
    },
    watch: {
        "$route.query.action": function () {
            this.action = this.$route.query.action;
        }
    },
    async created() {
        this.localLoading = true;
        this.steps = this.getSteps();
        this.step = 0;
        this.schemaDouble = this.steps[this.step].schemaDouble;
        this.buildFormData(this.schemaDouble, {});
        this.localLoading = false;
    },
    methods: {
        getSteps() {
            return [
                {
                    title: this.actionsText[this.action].title,
                    item: {
                        is: "AddPaymentCSVFile"
                    },
                    back: {
                        fn: () =>
                            this.$router.push({
                                name: "operations",
                                query: {
                                    tab: 1
                                }
                            }),
                        txt: this.$t("label.cancel")
                    },
                    next: {
                        fn: this.confirmCSVEntries,
                        txt: this.$t("payments.payment-csv-file.confirm-btn")
                    },
                    schemaDouble: {
                        doc: {
                            isRequired: true,
                            dataType: "file"
                        },
                        selectedData: {
                            isRequired: true,
                            dataType: "array"
                        },
                        validateData: {
                            isRequired: true,
                            dataType: "input"
                        }
                    }
                },
                {
                    title: this.actionsText[this.action].done,
                    item: {
                        is: "Done"
                    },
                    noBack: true,
                    next: {
                        fn: () =>
                            this.$router.push({
                                name: "operations",
                                query: {
                                    tab: 1
                                }
                            }),
                        txt:
                            this.stepperState === "success"
                                ? this.$t("payments.done.success")
                                : this.$t("payments.done.error")
                    }
                }
            ];
        },
        goToLastStep() {
            this.step = this.steps.length - 1;
        },
        async confirmCSVEntries() {
            this.localLoading = true;
            const res = this.checkValideData();
            if (res.length != 0) {
                this.localLoading = false;
                return;
            }
            const data = this.formData.selectedData.map(x => {
                let res = x;
                res.credited_entity = res.credited_entity.url;
                res.debited_entity = res.debited_entity.url;
                res.action = "download";
                return res;
            });
            try {
                const res = await this.$api.createTransfer(data);
                this.goToLastStep();
            } catch (e) {
                console.error(e, "operations_add_movement");
                this.catchError();
            } finally {
                this.localLoading = false;
            }
        },
        checkValideData() {
            let totalValidate = 0;
            let error = [];
            if (this.formData.selectedData.length <= 0)
                return [this.$t("payment.add-csv-file.error-select-empty")];
            this.formData.selectedData.forEach(elem => {
                if (
                    !!elem.amount &&
                    elem.amount > 0 &&
                    !!elem.credited_entity &&
                    !!elem.debited_entity &&
                    !!elem.operation_date &&
                    !!elem.operation_type
                ) {
                    totalValidate += 1;
                } else if (elem.amount <= 0) {
                    error = [this.$t("payment.add-csv-file.error-amount", { 0: elem.amount })];
                }
            });
            if (totalValidate == this.formData.selectedData.length) {
                error = [];
            } else if (error.length == 0) {
                error = [this.$t("payment.add-csv-file.error-select")];
            }
            return error;
        },
        async parseCSV() {
            this.localLoading = true;
            if (!this.formData.doc) {
                this.allData = [];
                this.formData.selectedData = [];
                this.csvError = [];
                this.localLoading = false;
                return;
            }
            let data = {
                action: "download",
                document_id: this.formData.doc.id
            };
            let res = null;
            try {
                res = await this.$api.parseCSVTransaction(data);
                if (res.data.length > 0) {
                    this.cleanData(res.data);
                    this.csvError = [];
                } else {
                    this.csvError = [this.$t("payment.add-csv-file.error-empty-csv")];
                }
            } catch (e) {
                console.error("Upload CSV error: ", e);
            }
            this.localLoading = false;
        },
        cleanData(data) {
            let i = 0;
            const entity = this.$entity.represented();
            data.forEach(element => {
                let res = { ...element[2] };
                res["uikey"] = i++;
                res["isValid"] = element[0].is_valid;
                if (!res.credited_entity) res.credited_entity = entity;
                if (!res.debited_entity) res.debited_entity = entity;
                this.allData.push(res);
                if (res["isValid"] == true) this.formData.selectedData.push(res);
            });
        }
    }
};
</script>
