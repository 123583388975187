var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsStepper',_vm._b({attrs:{"value":_vm.step,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":_vm.comeBackToDecisions},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{class:item.class},[_c(item.is,_vm._b({tag:"component",attrs:{"editable":""}},'component',{
                    loading: _vm.loading || _vm.localLoading || _vm.decisionsLoading || _vm.organsLoading,
                    formData: _vm.formData,
                    schemaDouble: _vm.schemaDouble,
                    decision: _vm.decision,
                    organs: _vm.organs,
                    decisionId: _vm.decisionId,
                    ...item
                },false))],1)]}}])},'EqsStepper',{
        steps: _vm.steps,
        progress: _vm.progress,
        closeInfoTxt: _vm.closeInfoTxt
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }