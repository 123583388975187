<template>
    <div class="eqs-notify" :el-class="elClass">
        <EqsSnackbar
            ref="notify"
            app
            :value.sync="open"
            v-bind="{ ...type, message, linkCallback, linkMessage }"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsNotify.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import events from "../../../events";

const TYPES = {
    info: {
        // elClass: "info-notify",
        color: "info",
        timeout: 6000
    },
    success: {
        // elClass: "success-notify",
        color: "success",
        timeout: 6000
    },
    warning: {
        // elClass: "warning-notify",
        color: "warning",
        timeout: 10000
    },
    error: {
        // elClass: "error-notify",
        color: "error",
        timeout: 10000
    }
};

export default {
    name: "EqsNotify",
    components: {
        EqsSnackbar: () => import("../EqsSnackbar/EqsSnackbar.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-notify"
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            open: false,
            type: {},
            message: "",
            linkCallback: null,
            linkMessage: ""
        };
    },
    created() {
        events.$on("alert", this.notify);
    },
    destroyed() {
        events.$off("alert", this.notify);
    },
    methods: {
        notify(type, message, linkCallback, linkMessage) {
            if (!message) return;
            this.message = message;
            this.linkCallback = linkCallback;
            this.linkMessage = linkMessage;
            this.type = TYPES[type];
            if (this.open) {
                this.$refs.notify.setTimer();
            }
            this.open = true;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsNotify.scss";
</style>
