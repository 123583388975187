import DEBUG from "@/store/debug.js";

export default async ({ state, commit, dispatch }, data) => {
    DEBUG("FETCH_MY_PROJECT_START");
    let myProject = null;
    if (!data.projectId) throw new Error("no myProjectId");
    if (!state.myProjects.length) {
        try {
            await dispatch("getMyProjects");
        } catch (e) {
            throw e;
        }
    }
    try {
        commit("SET_MY_PROJECTS_LOADING", true);
        const res = await window.app.$api.getCapitalIncrease(data.projectId, {
            entity_id: data.entityId
        });
        myProject = res.data;
        if (state.myProjects.find(project => project.id === myProject.id))
            commit("UPDATE_MY_PROJECT", myProject);
        else commit("ADD_MY_PROJECT", myProject);
        DEBUG("FETCH_MY_PROJECT_SUCCESS");
    } catch (e) {
        DEBUG("FETCH_MY_PROJECT_ERROR");
        throw e;
    } finally {
        commit("SET_MY_PROJECTS_LOADING", false);
    }
    return myProject || {};
};
