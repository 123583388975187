<template>
    <EqsDataTable
        v-bind="{
            itemKey: 'id',
            items: assets,
            headers,
            column: false
        }"
    >
        <template #item.name="{ item }">
            <td :el-class="`${item.name}-name`">
                {{ `${item.name} (${item.symbol})` }}
            </td>
        </template>
        <template #item.numberofshares="{ item }">
            <td :el-class="`${item.name}-shares`">
                {{ $currency.display(item.numberofshares, "0,0") }}
            </td>
        </template>
        <template #item.percentage="{ item }">
            <td :el-class="`${item.name}-percent`">{{ item.percentage }} %</td>
        </template>
        <template #item.value="{ item }">
            <td :el-class="`${item.name}-amount`">
                {{
                    $currency.displayWithCurrency(
                        item.value,
                        $entity.represented().country.alphacode == "US" ? "USD" : "EUR"
                    )
                }}
            </td>
        </template>
    </EqsDataTable>
</template>

<script>
export default {
    props: {
        assets: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            headers: [
                {
                    text: "",
                    value: "name"
                },
                {
                    text: this.$t("portfolio.holdings.shares"),
                    value: "numberofshares"
                },
                {
                    text: this.$t("portfolio.holdings.percentage"),
                    value: "percentage"
                },
                {
                    text: this.$t("portfolio.holdings.value-mini"),
                    value: "value"
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped></style>
