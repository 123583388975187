import { i18n } from "@/services/plugins/i18n.js";

export const sell_order_statutes = {
    Pending: {
        txt: i18n.t("secondary-market.follow-sells.table.status-Pending"),
        eqsType: "warning"
    },
    Sent: {
        txt: i18n.t("secondary-market.follow-sells.table.status-Sent"),
        eqsType: "info"
    },
    Partially_Done: {
        txt: i18n.t("secondary-market.follow-sells.table.status-Partially_Done"),
        eqsType: "info"
    },
    Canceled: {
        txt: i18n.t("secondary-market.follow-sells.table.status-Canceled"),
        eqsType: "error"
    },
    Completed: {
        txt: i18n.t("secondary-market.follow-sells.table.status-Completed"),
        eqsType: "success"
    }
};

export const buy_order_statutes = {
    Pending: {
        txt: i18n.t("secondary-market.buyOrder.status.Pending"),
        eqsType: "warning"
    },
    Sent: {
        txt: i18n.t("secondary-market.buyOrder.status.Sent"),
        eqsType: "info"
    },
    Sequestre_Cash: {
        txt: i18n.t("secondary-market.buyOrder.status.Sequestre_Cash"),
        eqsType: "info"
    },
    Pending_Buyer_Signature: {
        txt: i18n.t("secondary-market.buyOrder.status.Pending_Buyer_Signature"),
        eqsType: "info"
    },
    Pending_Seller_Signature: {
        txt: i18n.t("secondary-market.buyOrder.status.Pending_Seller_Signature"),
        eqsType: "info"
    },
    Pending_Issuer_Validation: {
        txt: i18n.t("secondary-market.buyOrder.status.Pending_Issuer_Validation"),
        eqsType: "info"
    },
    Pending_Cash_Transfer: {
        txt: i18n.t("secondary-market.buyOrder.status.Pending_Cash_Transfer"),
        eqsType: "info"
    },
    Pending_Asset_Transfer: {
        txt: i18n.t("secondary-market.buyOrder.status.Pending_Asset_Transfer"),
        eqsType: "info"
    },
    Complete: {
        txt: i18n.t("secondary-market.buyOrder.status.Complete"),
        eqsType: "success"
    },
    Canceled: {
        txt: i18n.t("secondary-market.buyOrder.status.Canceled"),
        eqsType: "error"
    },
    Rejected_By_Seller: {
        txt: i18n.t("secondary-market.buyOrder.status.Rejected_By_Seller"),
        eqsType: "error"
    },
    Rejected_By_Issuer: {
        txt: i18n.t("secondary-market.buyOrder.status.Rejected_By_Issuer"),
        eqsType: "error"
    }
};
