import isEmpty from "lodash/isEmpty";
import { rolesProps } from "@/services/role";

export const getTextForEntity = (ctx, entity) => {
    let registration = null;
    if (entity.lemonway_state !== "success") {
        registration = entity.is_company ? ctx.$t("registration.company-creation") : "KYC";
    }
    if (!entity.name || entity.name === "None None" || entity.name === "None") {
        return `${registration}: ${ctx.$t("label.pending")}`;
    }
    return registration ? `${registration}: ${entity.name}` : entity.name;
};

export const switchRole = async (ctx, role) => {
    try {
        ctx.$store.commit("setLoadingRoute", true);
        await ctx.updateMyRole(role.entity_represented.id);
        ctx.$store.dispatch("resetRoleStore");
        ctx.$router
            .push({
                name: "profile",
                params: {
                    id: role.entity_represented.id
                }
            })
            .catch(() => {});
    } catch (e) {
        ctx.$ui.error(e, "change_role");
        ctx.$store.commit("setLoadingRoute", false);
    }
};

export const switchRoleGlobal = async (ctx, roleId, route, entity) => {
    try {
        ctx.$store.commit("setLoadingRoute", true);
        await updateMyRoleGlobal(roleId, entity, ctx, !route);
        ctx.$store.dispatch("resetRoleStore");
        // Was useless ? redundant route push hide by the catch
        if (!!route) {
            await ctx.$router.push(route).catch(e => {
                // console.warn("switchRoleGlobal", e);
            });
        }
    } catch (e) {
        ctx.$ui.error(e, "change_role");
        ctx.$store.commit("setLoadingRoute", false);
    }
};

export const updateMyRoleGlobal = async (id, entity, ctx, useRoute = true) => {
    try {
        await ctx.$role.updateMyCurrentRole(id);
    } catch (e) {
        console.error({ e });
    } finally {
        if (useRoute) {
            let routeToGo = "";
            if (!entity) {
                routeToGo = `/profile/${id}`;
            } else {
                routeToGo = getPathForEntityGlobal(entity, ctx);
            }
            if (ctx.$route.fullPath !== routeToGo) {
                await ctx.$router.push(routeToGo).catch(e => {
                    // console.warn("updateMyRoleGlobal", e);
                });
            }
        }
    }
};

const getPathForEntityGlobal = (entity, ctx) => {
    return entity.lemonway_state === "success"
        ? `/profile/${entity.id}`
        : ctx.$kyc.getRoute(entity);
};

export const getRolesForEntity = (ctx, entity, roles) => {
    if (isEmpty(entity) || !roles || !roles.length) return [];
    return roles
        .map(role => {
            const _role = {
                ...role.entity_represented,
                role: role.role_name,
                name: getTextForEntity(ctx, role.entity_represented),
                "el-class": `profile-${entity.name ? entity.name.toLowerCase() : "pending"}`,
                action: () => switchRole(ctx, role),
                ...rolesProps[role.role_name]
            };
            return _role;
        })
        .sort((a, b) => {
            const statusOrder = {
                success: -3,
                upload: -2,
                "upload-failure": -1,
                error: 0,
                failure: 0
            };
            const aStatus = statusOrder[a.lemonway_state] || 1;
            const bStatus = statusOrder[b.lemonway_state] || 1;

            if (aStatus !== bStatus) {
                return aStatus - bStatus;
            } else {
                return a.name.localeCompare(b.name);
            }
        });
};

export const userMenu = (ctx, entity, roles) => {
    let menu = [];
    const myEntityLWState = ctx.$entity.myEntity().lemonway_state;
    const entityRepresentedLWState = ctx.$entity.represented().lemonway_state;
    if (myEntityLWState !== "failure" || entityRepresentedLWState === "success") {
        menu.push(
            {
                title: ctx.$t("app.bar.dropdown-profile.title.profile"),
                to: `/profile/${entity.id}`,
                value: "profile",
                icon: "mdi-account-outline"
            },
            {
                title: ctx.$t("settings.title"),
                to: `/settings/${entity.id}`,
                value: "settings",
                icon: "mdi-cog-outline"
            }
        );
        if (
            entityRepresentedLWState !== "failure" &&
            ctx.$store.getters.useThroughRoles.useIt === false &&
            !ctx.$universe.isNeotrees()
        ) {
            menu.push({
                title: ctx.$t("app.bar.dropdown-profile.title.manage-team"),
                to: `/manage/team/${entity.id}`,
                value: "manage-team",
                icon: "mdi-account-supervisor-outline"
            });
        }
        if (entity.id === ctx.$entity.myEntity().id && !ctx.$universe.isNeotrees()) {
            menu.push({
                title: entity.account_type
                    ? ctx.$t("routes.actions.add-company")
                    : ctx.$t("routes.actions.set-company"),
                icon: "mdi-all-inclusive",
                value: entity.account_type ? "create-company" : "setup-company",
                to: entity.account_type ? `/create-company` : "/setup-company",
                "el-class": entity.account_type ? "action-add-company" : "action-set-company"
            });
        }
    }
    if (entityRepresentedLWState !== "success") {
        // here if entity has no lemonway_state (no wallet created) or get any status that is not 'pending' or 'success'
        // it will redirect to registration form, else it will redirect to status page
        if (ctx.$entity.errorKyc() || entityRepresentedLWState === "upload")
            menu.push({
                title: ctx.$entity.errorKyc()
                    ? ctx.$t("app.bar.dropdown-profile.title.resume-registration")
                    : ctx.$t("app.bar.dropdown-profile.title.complete-registration"),
                icon: "mdi-account-arrow-left",
                to: `/registration/${entity.id}`,
                value: "registration"
            });
        else
            menu.push({
                title: ctx.$t("app.bar.dropdown-profile.title.verify-registration"),
                icon: "mdi-account-check",
                to: `/registration/${entity.id}/verify`,
                value: "verify"
            });
    } else {
        //TODO set back this when tarification feature working
        // if (entity.is_company)
        //   menu.push({
        //     title: ctx.$t("app.bar.dropdown-profile.title.subscribtion"),
        //     icon: "mdi-badge-account-horizontal",
        //     to: `/subscribtion/${entity.id}`,
        //     disabled: window.location.hostname == "app.equisafe.io",
        //     value: "subscription",
        //   });
    }

    if (!!roles && roles.length > 1 && ctx.$store.getters.useThroughRoles.useIt === false) {
        menu.push({
            title: ctx.$t("app.bar.dropdown-profile.title.switch-profile"),
            icon: "mdi-arrow-top-left-bottom-right-bold",
            roles: getRolesForEntity(ctx, entity, roles)
        });
    }
    menu.push({
        title: ctx.$t("app.bar.dropdown-profile.title.logout"),
        action: ctx.logout,
        icon: "mdi-logout-variant",
        value: "logout",
        color: ctx.$color.colors.lighterRed,
        bgColor: ctx.$color.colors.almostWhite
    });
    return menu;
};

export const appStates = ctx => {
    return {
        login_register: {
            header: {},
            container: {
                class: "flex layout column justify-center align-center"
            },
            content: {
                // class: 'pa-3'
            }
        },
        registration: {
            header: {},
            container: {
                class: "flex layout column justify-center align-center"
            },
            content: {}
        },
        default: {
            header: {},
            container: {
                style: {
                    "background-color": "transparent"
                }
            },
            content: {
                // todo put back
                style: {
                    height: "100%",
                    width: "100%"
                }
            }
        },
        not_registered_allowed: {
            header: {
                notRegisteredAllowed: true
            },
            container: {
                style: {
                    "background-color": "transparent"
                }
            },
            content: {
                // todo put back
                style: {
                    height: "100%"
                }
            }
        }
    };
};
