export default () => ({
    decisions: [],
    decisionsLoading: false,
    decisionsInit: true,
    representatives: [],
    representativesLoading: false,
    representativesInit: true,
    organs: [],
    organsLoading: false,
    organsInit: true
});
