var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-upload",style:(_vm.getStyle)},[_c('EqsSkeleton',_vm._b({},'EqsSkeleton',{ loading: _vm.loading, type: 'sentences' },false),[_c('v-file-input',_vm._g(_vm._b({class:`rounded-lg ${_vm.elClass}-file-input`,attrs:{"outlined":"","rounded":"","filled":"","dense":"","el-class":``,"hide-details":"auto","prepend-icon":"","error-messages":_vm.errorText || _vm.errorMessages},on:{"change":_vm.onAddFiles,"click:clear":() => _vm.$emit('update:value', _vm.multiple ? [] : null)},scopedSlots:_vm._u([{key:"selection",fn:function({ index, file }){return [(index === 0 && _vm.autoUpload)?_c('div',{staticClass:"v-file-input__text v-file-input__text--placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):(!_vm.autoUpload)?_c('div',[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{ value: file.name },false))],1):_vm._e()]}},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}},'v-file-input',{
                ..._vm.getData,
                ..._vm.$attrs,
                placeholder: _vm.placeholder,
                clearable: !_vm.autoUpload,
                multiple: _vm.multiple,
                loading: _vm.localLoading || _vm.loading,
                rules: _vm.rulesRequired,
                disabled: _vm.disabled || _vm.localLoading || !_vm.editable,
                backgroundColor: _vm.$color.colors.inputBackground,
                accept: _vm.accept
            },false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1),_c('v-input',{attrs:{"value":_vm.value,"rules":[_vm.isDocLoading],"hide-details":"auto"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }