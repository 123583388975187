import DEBUG from "@/store/debug.js";

export default async ({ commit, dispatch }) => {
    DEBUG("FETCH_IBAN_START");
    try {
        commit("SET_IBAN_INIT", true);
        await dispatch("getIban");
        DEBUG("FETCH_IBAN_SUCCESS");
    } catch (e) {
        DEBUG("FETCH_IBAN_ERROR");
        throw e;
    }
};
