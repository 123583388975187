import * as blockchainUtils from "./utils";
import chunk from "lodash/chunk";
import { DIGITALIZE_INTERVAL, DIGITALIZE_TIMEOUT } from "@/assets/constants";
import { TX_EXPLORER_INTERVAL, TX_EXPLORER_TIMEOUT } from "@/assets/constants";

export class BlockchainService {
    constructor(api, store, entity, roleService, pollService) {
        if (!api) throw new Error("The api object should be defined");
        this.api = api;
        this.store = store;
        this.entity = entity;
        this.role = roleService;
        this.pollService = pollService;
        this.pollIds = {};
    }

    currentBlockchain() {
        // returns 'fake', 'tezos' or 'ethereum'
        if (this.entity.represented().blockchain.provider_class.includes("ethereum")) {
            return "ethereum";
        } else if (this.entity.represented().blockchain.provider_class.includes("tezos")) {
            return "tezos";
        }
        return "fake";
    }

    async poll(name, fn, start = true) {
        if (!start) {
            console.warn(`stopping poll for ${name}`);
            clearInterval(this.pollIds[name]);
            delete this.pollIds[name];
            return;
        }
        if (this.pollIds[name]) {
            console.warn(`poll for ${name} is already running`);
            return;
        }
        await fn();
        this.pollIds[name] = setInterval(async () => {
            await fn();
        }, 5000);
    }

    async pollBlockchainizeState_v2(stop = false, init = false) {
        if (stop) {
            return this.pollService.stop("blockchainize-state");
        } else {
            return this.pollService.asyncCall(
                "blockchainize-state",
                () => this.updateBlockchainizeState(),
                {},
                data => {
                    let blockchainize_not_in_process = init ? ["INIT", "FINISHED"] : ["FINISHED"];
                    return blockchainize_not_in_process.includes(data.blockchainize_state);
                },
                DIGITALIZE_INTERVAL,
                DIGITALIZE_TIMEOUT
            );
        }
    }

    async transactionExplorer(id) {
        console.log(`in txexplorer for ${id}`);
        const res = await window.app.$api.getTransactionExplorer(id);
        console.log(`in transactionExplorer for ${id}`);
        console.log({ restxexplorer: res });
        return res.data;
    }

    async pollTransactionExplorer(id, stop = false, init = false) {
        console.log(`in polltxexplorer`);
        if (stop) {
            return this.pollService.stop(`transaction-explorer-${id}`);
        } else {
            return this.pollService.asyncCall(
                `transaction-explorer-${id}`,
                () => this.transactionExplorer(id),
                {},
                data => {
                    let state = data.state;
                    return state !== "watch" && state !== "watching";
                },
                TX_EXPLORER_INTERVAL,
                TX_EXPLORER_TIMEOUT
            );
        }
    }

    async updateBlockchainizeState() {
        const res = await this.api.getBlockchainizeState();
        const blockchainizeState = res.data;
        this.store.commit("SET_BLOCKCHAINIZE_STATE", blockchainizeState);
        return blockchainizeState;
    }

    initBlockchainizeState() {
        this.store.commit("SET_BLOCKCHAINIZE_STATE", {});
    }

    blockchainizeState() {
        return this.store.getters.blockchainizeState;
    }

    deployIssuingEntity() {
        return this.api.deployIssuingEntity();
    }

    redeployContract(contract) {
        if (!contract.error) {
            console.error("trying to redeploy a contract that has no error");
            return;
        }
        return this.api.redeployContract(contract);
    }

    companyOnBlockchain(company) {
        return ["CALLED_SETREGISTRARCONSTRAINT", "SETTING_INVESTOR_LIMITS", "FINISHED"].includes(
            company.blockchainize_state
        );
    }

    async setCountries(data) {
        const { countryCodes, investorMinRating, investorsLimit } = data;
        const constraints = [];
        for (let i = 0; i < countryCodes.length; i++) {
            constraints.push({
                numcode: countryCodes[i],
                rating: investorMinRating[i],
                limit: investorsLimit[i]
            });
        }
        // slice in chunk of 100 to avoid gas limit errors (on ethereum)
        const chunkConstraints = chunk(constraints, 100);
        const results = await Promise.allSettled(
            chunkConstraints.map(async _chunk => {
                await this.api.setCountries({
                    constraints: _chunk,
                    state: "deploy"
                });
            })
        );
        results.forEach(res => {
            if (res.status === "rejected") {
                console.error({
                    res
                });
                throw e;
            }
        });
    }

    async removeCountries(countryCodes) {
        // countryCodes : [ "024", "250" ]
        if (this.currentBlockchain() === "ethereum") {
            // if blockchain is ethereum, make multiple call per country (sft limitation)
            const results = await Promise.allSettled(
                countryCodes.map(async code => {
                    await this.api.removeCountries({
                        country_codes: [code],
                        state: "deploy"
                    });
                })
            );
            results.forEach(res => {
                if (res.status === "rejected") {
                    console.error({
                        res
                    });
                    throw e;
                }
            });
        } else {
            try {
                await this.api.removeCountries({
                    country_codes: countryCodes,
                    state: "deploy"
                });
            } catch (e) {
                console.error({
                    e
                });
                throw e;
            }
        }
    }

    async setInvestorLimits(limits) {
        await this.api.setInvestorLimits({
            limit_global: limits[0], // ex: 9,
            limit_rating: limits.slice(1), // ex:  [1, 0, 0, 0, 0, 0, 0],
            state: "deploy"
        });
    }

    async transfer(data) {
        let assetMovement = null;
        try {
            const investor_source_id = data.source;
            const investor_destination_id = data.destination;
            const shareledger_id = data.shareledger;
            const numberofshares = parseInt(data.numberofshares);
            const description = data.description;
            const pricepershare = data.pricepershare;
            const docs = data.docs;

            const res = await this.api.createCession({
                investor_source_id,
                investor_destination_id,
                shareledger_id,
                numberofshares,
                operation: "cession",
                description,
                pricepershare,
                docs,
                state: "deploy"
            });
            assetMovement = res.data;
        } catch (e) {
            throw e;
        } finally {
            return assetMovement;
        }
    }

    mint(assetMovementUrl) {
        return this.api.updateAssetMovement(assetMovementUrl, {
            state: "deploy"
        });
    }

    async updateInvestor(data) {
        const res = await this.api.updateInvestor(data);

        if (res.status < 200 || res.status > 299) {
            if (res.data[0]) {
                window.app.$ui.error(res.data[0]);
            } else {
                window.app.$ui.error("Unknown transaction failure !");
            }
            return;
        }

        const updatedInvestor = res.data;
        return updatedInvestor;
    }

    async deploySecurityToken(data) {
        let res = null;
        try {
            res = await this.api.deploySecurityToken({
                ...data,
                state: "deploy"
            });
            if (res.status < 200 || res.status > 299) {
                if (res.data && res.data[0]) {
                    window.app.$ui.error(res.data[0]);
                } else {
                    window.app.$ui.error("Unknown transaction failure !");
                }
                throw res;
            }
            return res;
        } catch (e) {
            throw e;
        }
    }

    utils() {
        return blockchainUtils;
    }

    txInProcess(tx, toRebroadcast = false) {
        if (!tx) return false;
        else if (tx.error) return false;
        else if (tx.state === "done") return false;
        else if (tx.state === "held" && !toRebroadcast) return false;
        return true;
    }
    txComplete(tx, toRebroadcast = false) {
        if (!tx) return false;
        else if (tx.state === "done") return true;
        else if (tx.state === "held" && toRebroadcast) return true;
        return false;
    }
    txWatching(tx) {
        if (!tx) return false;
        else if (tx.state === "watch") return true;
        else if (tx.state === "watching") return true;
        return false;
    }

    async getTransactions() {
        try {
            return await this.store.dispatch("getTransactions");
        } catch (e) {
            if (e !== undefined) {
                window.app.$ui.error(e, "my_transactions_movements_transactions");
                throw e;
            }
        }
    }
    transactions() {
        return this.store.getters.transactions;
    }
}
