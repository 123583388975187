<template>
    <div id="drop-down-menu" class="eqs-dropdown-profile">
        <v-menu
            offset-y
            bottom
            origin="top bottom"
            :content-class="`${elClass}-menu`"
            :transition="!opened ? 'scroll-y-reverse-transition' : 'scroll-y-transition'"
            rounded
            :close-on-content-click="false"
            :max-height="isMobile ? '80vh' : '60vh'"
            :min-width="isMobile ? '100vw' : ''"
            :disabled="loading"
            width="100%"
            :value="opened"
            @input="
                val => {
                    opened = val;
                    if (val) openRoles = true;
                }
            "
        >
            <template #activator="{ on }">
                <v-card
                    flat
                    :class="`eqs-dropdown-profile__container d-flex flex-row ${elClass}-activator-btn`"
                    :color="$color.colors.menuColor"
                    v-on="on"
                >
                    <v-col class="d-flex pa-0" align="center" align-self="center">
                        <EqsAvatar class="pl-2" v-bind="{ user, loading }" />
                        <v-scroll-x-transition>
                            <div v-if="!isMobile" class="d-flex align-center justify-center pl-2">
                                <EqsTextDisplay v-bind="{ value: user.name, loading }" />
                            </div>
                        </v-scroll-x-transition>
                        <v-scroll-x-transition>
                            <div
                                v-if="!loading"
                                style="align-self: center"
                                class="eqs-dropdown-profile__container__menu-icon"
                            >
                                <v-icon>
                                    {{ opened ? "mdi-menu-up" : "mdi-menu-down" }}
                                </v-icon>
                            </div>
                        </v-scroll-x-transition>
                    </v-col>
                </v-card>
            </template>
            <v-list dense class="eqs-dropdown-profile__list">
                <v-hover v-for="(item, index) in items" :key="index" v-slot="{ hover }">
                    <div class="ma-0 pa-0">
                        <v-list-item
                            v-if="!item.roles"
                            :class="`${item.value}-menu`"
                            link
                            :color="item.color || $color.colors.eqsBlue"
                            active-class="eqs-dropdown-profile-drawer-active"
                            :style="
                                !item.active && !hover && !!item.bgColor
                                    ? `background-color:${item.bgColor}`
                                    : ''
                            "
                            :input-value="!item.subheader && hover"
                            :to="item.to"
                            @click="
                                () => {
                                    if (item.action) item.action();
                                    opened = false;
                                }
                            "
                        >
                            <v-list-item-icon v-if="item.icon">
                                <v-icon
                                    small
                                    :color="
                                        item.active || hover
                                            ? item.color || $color.colors.eqsBlue
                                            : $color.colors.lightGrey
                                    "
                                >
                                    {{ item.icon }}
                                </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-group v-else v-model="openRoles" no-action>
                            <template #activator>
                                <v-list-item-icon v-if="item.icon">
                                    <v-icon
                                        small
                                        :color="
                                            item.active || hover
                                                ? item.color || $color.colors.eqsBlue
                                                : $color.colors.lightGrey
                                        "
                                    >
                                        {{ item.icon }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-capitalize font-weight-bold">
                                        {{ item.title }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-hover
                                v-for="(role, subIndex) in item.roles.filter(
                                    role => role.id !== user.id
                                )"
                                :key="subIndex"
                                v-slot="{ hover: subHover }"
                            >
                                <div class="ma-0 pa-0">
                                    <v-list-item
                                        link
                                        two-line
                                        :color="role.color || $color.colors.eqsBlue"
                                        active-class="eqs-dropdown-profile-drawer-active"
                                        :style="
                                            !role.active && !subHover && !!role.bgColor
                                                ? `background-color:${role.bgColor}`
                                                : ''
                                        "
                                        :class="`px-5 ${role.name}-menu`"
                                        :input-value="!role.subheader && subHover"
                                        @click="
                                            () => {
                                                role.action();
                                                opened = false;
                                            }
                                        "
                                    >
                                        <EqsAvatar
                                            v-bind="{ user: role, eqsType: 'list' }"
                                            class="mr-5"
                                        />
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-if="role.name"
                                                class="font-weight-bold text-capitalize ml-2"
                                            >
                                                {{ role.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <EqsChip small :color="role.color" outlined>
                                                    {{ role.role }}
                                                </EqsChip>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </div>
                            </v-hover>
                        </v-list-group>
                    </div>
                </v-hover>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "InternalDropdownProfile",
    components: {
        EqsAvatar: () => import("../../../atoms/EqsAvatar/EqsAvatar.vue"),
        EqsChip: () => import("../../../atoms/EqsChip/EqsChip.vue"),
        EqsTextDisplay: () => import("../../../atoms/EqsTextDisplay/EqsTextDisplay.vue")
    },
    props: {
        elClass: {
            type: String,
            default: "eqs-dropdown-profile"
        },
        items: {
            type: Array,
            required: true
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            opened: false,
            openRoles: true
        };
    }
};
</script>

<style lang="scss">
@import "../../../../../../shared/styles/components/organisms/InternalDropdownProfile.scss";
</style>
