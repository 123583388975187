<template>
    <EqsCrud
        :items.sync="docsProxy"
        v-bind="{
            ...$attrs,
            add: addDoc,
            update: updateDoc,
            deleteFunc: removeDoc,
            formInit: newDoc,
            loading,
            elClass
        }"
    >
        <template #group-tile="{ item }">
            <v-row justify="start" align-content="start">
                <v-col cols="auto" class="py-0">
                    <EqsTextDisplay v-bind="{ value: item.document.name }" />
                </v-col>
                <v-col cols="auto" class="py-0">
                    <EqsTextDisplay class="text--secondary" v-bind="{ value: getTrad(item) }" />
                </v-col>
            </v-row>
        </template>
        <template #edit="editProps">
            <InternalDocForm v-bind="{ ...editProps, categories, elClass }" />
        </template>
        <template #create="createProps">
            <InternalDocForm editable v-bind="{ ...createProps, categories, elClass }" />
        </template>
    </EqsCrud>
</template>

<script>
import InternalDocForm from "./_internal/InternalDocForm.vue";
import find from "lodash/find";

export default {
    name: "GlobalDocWithCategoryList",
    components: {
        InternalDocForm
    },
    props: {
        docs: {
            type: Array,
            default: () => []
        },
        categories: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        add: {
            type: Function,
            default: null
        },
        update: {
            type: Function,
            default: null
        },
        deleteFunc: {
            type: Function,
            default: null
        },
        elClass: {
            type: String,
            default: "document-list"
        }
    },
    data() {
        return {
            newDoc: {
                category: null,
                document: null
            },
            validForm: false
        };
    },
    computed: {
        docsProxy: {
            get() {
                return this.docs;
            },
            set(val) {
                this.$emit("update:docs", val);
            }
        }
    },
    methods: {
        async addDoc(item) {
            try {
                this.newPeopleLoading = true;
                if (this.add) {
                    const res = await this.add(item);
                    return res.data;
                }
                return item;
            } catch (e) {
                this.$ui.error(e, "create_doc");
                throw e;
            } finally {
                this.newPeopleLoading = false;
            }
        },
        async updateDoc(item) {
            try {
                this.newPeopleLoading = true;
                if (this.update) {
                    const res = await this.update(item);
                    return res.data;
                }
                return item;
            } catch (e) {
                this.$ui.error(e, "update_doc");
                throw e;
            } finally {
                this.newPeopleLoading = false;
            }
        },
        async removeDoc(item) {
            try {
                this.newPeopleLoading = true;
                if (this.deleteFunc) await this.deleteFunc(item);
            } catch (e) {
                this.$ui.error(e, "remove_doc");
                throw e;
            } finally {
                this.newPeopleLoading = false;
            }
        },
        getTrad(item) {
            const trad = find(this.categories, category => category.value == item.category);
            return trad.text || item.category;
        }
    }
};
</script>

<style lang="scss" scoped>
.people-text {
    // text-transform: uppercase;
    font-weight: 600;
}
</style>
