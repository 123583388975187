var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsApp',_vm._b({on:{"notifications":_vm.updateNotifs},scopedSlots:_vm._u([{key:"modal-client",fn:function(){return [_c('SelectClientDialog',{attrs:{"open":_vm.selectClientDialogState},on:{"update:open":function($event){_vm.selectClientDialogState=$event},"closeDialog":() => {
                    _vm.selectClientDialogState = false;
                }}})]},proxy:true},{key:"navigation-bar-action",fn:function({ loading: loadingApp }){return [_c('v-col',{staticClass:"eqs-navigation-bar__col d-flex align-center mr-2 px-2",attrs:{"order":"1","cols":"auto"}},[(_vm.$universe.isKyoseil() || _vm.$entity.isAffiliationInvestor())?_c('BtnNavBarKyoseil'):(_vm.navBarActionBtn)?_c('EqsBtn',_vm._b({scopedSlots:_vm._u([{key:"with-icon",fn:function(){return [(_vm.navBarActionBtn.btnIcon)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.navBarActionBtn.btnIcon)+" ")]):_c('v-img',{attrs:{"width":"32px","height":"32px","src":_vm.navBarActionBtn.btnImg,"contain":""}}),_vm._v(" "+_vm._s(_vm.navBarActionBtn.text || "")+" ")]},proxy:true}],null,true)},'EqsBtn',{ ..._vm.navBarActionBtn, loading: loadingApp },false)):_vm._e()],1)]}}])},'EqsApp',{
        eqsType: _vm.eqsDataType.includes('no-nav-bar') ? 'no-nav-bar' : 'default',
        eqsDataType: _vm.eqsDataType,
        context: _vm.context,
        contextFn: _vm.contextFn,
        routes: _vm.routes,
        appStates: _vm.appStates,
        notifications: {
            items: _vm.$notifications.lastNotifications(),
            numActive: _vm.$notifications.numActive()
        },
        user: _vm.user,
        userMenu: _vm.userMenu,
        loading: _vm.loading,
        loadingRoute: _vm.loadingRoute,
        loadingProfiles: _vm.loadingWebSocket,
        loadingNotifications: _vm.notificationsLoading,
        bannerLogo: _vm.$universe.image('/images/logo.svg'),
        bannerLogoFull: _vm.$universe.image('/images/logo_name.svg'),
        bannerLogoRedirect: _vm.$universe.getDefaultRoute(_vm.$entity.represented())
    },false),[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',_vm._b({},'router-view',{
                entity: _vm.entity,
                roles: _vm.myRoles,
                loading: _vm.loading
            },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }