import { getVal } from "../../../services/reactive";
import { spacingCSS } from "../../../templates/spacing";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            ...spacingCSS(currentSize),
            header: {
                height: getVal(currentSize, {
                    mobile: "48px",
                    tablet: "48px",
                    desktop: "72px"
                }),
                step: {
                    "min-width": getVal(currentSize, {
                        mobile: "auto",
                        tablet: "auto",
                        desktop: "fit-content"
                    }),
                    icon: {
                        "margin-bottom": getVal(currentSize, {
                            mobile: "0",
                            tablet: "0",
                            desktop: "0.75rem"
                        })
                    }
                }
            }
        },
        "no-overflow": {
            window: {
                "overflow-y": "hidden"
            }
        }
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            backBtn: getVal(currentSize, {
                mobile: {
                    "x-small": true
                },
                tablet: {
                    small: true
                },
                desktop: {
                    small: true
                }
            }),
            nextBtn: getVal(currentSize, {
                mobile: {
                    small: true
                },
                tablet: {
                    medium: true
                },
                desktop: {
                    medium: true
                }
            })
        },
        "no-overflow": {}
    };
};
