export default {
    install(Vue, options = {}) {
        const store = options.store;
        if (!store) {
            throw new Error("Missing `store` option");
        }

        const permissions = {};
        [
            "isAdmin",
            "isCgp",
            "isAccountant",
            "isCustodian",
            "isNotary",
            "isLawyer",
            "canCreateCompany",
            "canDeleteCompany",
            "canEditInformation",
            "canCreateTransaction",
            "canDownloadFiles",
            "canUploadFiles",
            "canDeleteFiles"
        ].forEach(getter => {
            if (typeof store.getters[getter] === "undefined") {
                throw new Error(`Getter '${getter}' not found in the store`);
            }
            Object.defineProperty(permissions, getter, {
                get() {
                    return store.getters[getter];
                }
            });
        });

        Object.defineProperty(Vue.prototype, "$permissions", {
            get() {
                return permissions;
            }
        });
    }
};
