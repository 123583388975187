var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('EqsCrud',_vm._b({attrs:{"items":_vm.peoplesProxy,"two-line":""},on:{"update:items":function($event){_vm.peoplesProxy=$event}},scopedSlots:_vm._u([{key:"group-tile",fn:function({ item }){return [_c('v-row',{attrs:{"justify":"start","align-content":"start"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('EqsAvatar',_vm._b({},'EqsAvatar',{
                            loading: _vm.loading,
                            src: !!item.photo ? item.photo.upload : ''
                        },false))],1),_c('v-col',{staticClass:"py-0 d-flex flex-column align-start justify-center",attrs:{"cols":"auto"}},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{ value: item.name },false)),_c('EqsTextDisplay',_vm._b({staticClass:"text--secondary"},'EqsTextDisplay',{ value: item.role },false))],1),(item.linkedin)?_c('v-col',{staticClass:"d-flex justify-start align-center"},[_c('GlobalPlatformsLinks',{attrs:{"items":_vm.getPlatforms(item),"small":"","size":"18px"}})],1):_vm._e()],1)]}},{key:"item",fn:function({ item }){return [_c('v-row',{attrs:{"justify":"start","align-content":"start"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('EqsAvatar',_vm._b({},'EqsAvatar',{
                            loading: _vm.loading,
                            src: !!item.photo ? item.photo.upload : ''
                        },false))],1),_c('v-col',{staticClass:"py-0 d-flex flex-column align-start justify-center",attrs:{"cols":"auto"}},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{ value: item.name },false)),_c('EqsTextDisplay',_vm._b({staticClass:"text--secondary"},'EqsTextDisplay',{ value: item.role },false))],1),(item.linkedin)?_c('v-col',{staticClass:"d-flex justify-start align-center"},[_c('GlobalPlatformsLinks',{attrs:{"items":_vm.getPlatforms(item),"small":"","size":"18px"}})],1):_vm._e()],1)]}},{key:"edit",fn:function(editProps){return [_c('InternalPeopleForm',_vm._b({},'InternalPeopleForm',editProps,false))]}},{key:"create",fn:function(createProps){return [_c('InternalPeopleForm',_vm._b({attrs:{"editable":""}},'InternalPeopleForm',createProps,false))]}}])},'EqsCrud',{
            ..._vm.$attrs,
            eqsListType: 'avatar-two-line',
            add: _vm.addPerson,
            update: _vm.updatePerson,
            deleteFunc: _vm.removePerson,
            formInit: _vm.newPerson,
            loading: _vm.loading
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }