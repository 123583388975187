export default () => ({
    setlimit: {},
    setcountries: {},
    removecountries: {},
    issuingentity: {},
    kycissuer: {},
    transactions: [],
    transactionsInit: true,
    transactionsLoading: false,
    blockchainize_state: "",
    setlimitLoading: false,
    setcountriesLoading: false,
    removeCountriesLoading: false,
    issuingEntityLoading: false,
    kycIssuerLoading: false,
    blockchainInfoLoading: false
});
