import { render, staticRenderFns } from "./SendBankDocs.vue?vue&type=template&id=3137d75d&scoped=true"
import script from "./SendBankDocs.vue?vue&type=script&lang=js"
export * from "./SendBankDocs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3137d75d",
  null
  
)

export default component.exports