import store from "@/store/store.js";

export default async ({ commit, dispatch }) => {
    try {
        const myRoles = store.getters.myRoles;
        const myRolesFiltered = myRoles.filter(role => role.role_name !== "cgp");
        commit("SET_MY_ROLES", myRolesFiltered);
    } catch (error) {
        console.error(`error in clean_impersonate_roles : ${error}`);
        throw error;
    }
};
