var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"value":_vm.dialogProxy,"closable":""},on:{"update:value":function($event){_vm.dialogProxy=$event}}},'EqsDialog',{
        title: 'Ajouter un client'
    },false),[_c('v-container',{staticClass:"bg-surface-variant"},[_c('p',[_vm._v("Choisissez parmis les options présentées ci-dessous :")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-4"},[_c('div',{class:`card-client-selection ${
                        _vm.selectClientType === 'moral' && 'cart-client-selection-select'
                    }`,on:{"click":function($event){return _vm.selectClient('moral')}}},[_c('div',{staticClass:"d-flex flex-row justify-center"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-briefcase")]),_c('v-sheet',{staticClass:"title-client-type"},[_vm._v(" One of three columns ")])],1),_c('p',[_vm._v(" Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem consectetur adipiscing elit. ")])])]),_c('v-col',[_c('div',{class:`card-client-selection ${
                        _vm.selectClientType === 'physic' && 'cart-client-selection-select'
                    }`,on:{"click":function($event){return _vm.selectClient('physic')}}},[_c('div',{staticClass:"d-flex flex-row justify-center"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-account")]),_c('v-sheet',{staticClass:"title-client-type"},[_vm._v(" One of three columns ")])],1),_c('p',[_vm._v(" Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem consectetur adipiscing elit. ")])])])],1),_c('v-row',{staticClass:"d-flex justify-center mt-6"},[_c('v-btn',{staticClass:"custom-button-modal mr-4",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Annuler")]),_c('v-btn',{staticClass:"custom-button-modal",attrs:{"color":"primary"},on:{"click":_vm.selectKycRoute}},[_vm._v("Continuer")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }