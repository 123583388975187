var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half', _vm.formType)}},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.type},on:{"update:value":function($event){return _vm.$set(_vm.formData, "type", $event)}}},'EqsSelect',{
                    items: [
                        { text: 'IBAN', value: 1 },
                        { text: 'BBAN/RIB', value: 2 },
                        { text: _vm.$t('label.other'), value: 0 }
                    ],
                    placeholder: 'Ex: IBAN',
                    elClass: 'account_type',
                    label: _vm.$t('label.account-type'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.type
                },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half', _vm.formType)}},[_c('EqsFileInput',_vm._b({attrs:{"value":_vm.formData.doc_bank},on:{"update:value":function($event){return _vm.$set(_vm.formData, "doc_bank", $event)}}},'EqsFileInput',{
                    accept: '.pdf',
                    elClass: 'doc-bank',
                    label: _vm.$t('registration.rib'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.doc_bank
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half', _vm.formType)}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.holder},on:{"update:value":function($event){return _vm.$set(_vm.formData, "holder", $event)}}},'EqsText',{
                    elClass: 'bank-holder',
                    placeholder: _vm.$t('user.wallet.bank.holder-placeholder'),
                    label: _vm.$t('user.wallet.bank.holder'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.holder
                },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.iban},on:{"update:value":function($event){return _vm.$set(_vm.formData, "iban", $event)}}},'EqsText',{
                    elClass: 'bank-account-number',
                    label: _vm.$t('user.wallet.bank.account-number'),
                    placeholder: _vm.$t('user.wallet.bank.account-number-placeholder'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.iban
                },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half', _vm.formType)}},[_c('EqsAutocomplete',_vm._b({attrs:{"value":_vm.formData.holder_country,"return-object":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "holder_country", $event)}}},'EqsAutocomplete',{
                    items: _vm.countries,
                    itemText: 'name',
                    placeholder: _vm.$t('user.wallet.bank.holder-country-placeholder'),
                    elClass: 'holder_country',
                    label: _vm.$t('user.wallet.bank.holder-country'),
                    editable: _vm.editable,
                    loading: _vm.loading || _vm.countriesLoading,
                    ..._vm.schemaDouble.holder_country
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half', _vm.formType)}},[_c('EqsAutocomplete',_vm._b({attrs:{"value":_vm.formData.bank_country,"return-object":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "bank_country", $event)}}},'EqsAutocomplete',{
                    items: _vm.countries,
                    itemText: 'name',
                    placeholder: _vm.$t('user.wallet.bank.bank-country-placeholder'),
                    elClass: 'bank_country',
                    label: _vm.$t('user.wallet.bank.bank-country'),
                    editable: _vm.editable,
                    loading: _vm.loading || _vm.countriesLoading,
                    ..._vm.schemaDouble.bank_country
                },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half', _vm.formType)}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.bic},on:{"update:value":function($event){return _vm.$set(_vm.formData, "bic", $event)}}},'EqsText',{
                    elClass: 'bank-bic',
                    placeholder: _vm.$t('user.wallet.bank.bic-placeholder'),
                    label: _vm.$t('user.wallet.bank.bic'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.bic
                },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.name},on:{"update:value":function($event){return _vm.$set(_vm.formData, "name", $event)}}},'EqsText',{
                    elClass: 'bank-name',
                    placeholder: _vm.$t('user.wallet.bank.bank-name-placeholder'),
                    isRequired: false,
                    label: _vm.$t('user.wallet.bank.bank-name'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.name
                },false))],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }