<template>
    <div class="text-center">
        <iframe
            width="560"
            height="315"
            :src="field.link"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        />
    </div>
</template>

<script>
export default {
    name: "FieldYoutubeLink",
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped></style>
