<template>
    <v-fade-transition hide-on-leave>
        <slot v-if="editable" />
        <div
            v-else-if="!multiple || !value"
            :class="`eqs-editable font-weight-bold ${getData.textBodyClass} ${
                clickable ? 'pointer' : 'cursor-default'
            }`"
            :style="{ ...getStyle, ...styleFromContext }"
            :el-class="elClass"
            @click="$emit('open')"
        >
            <slot name="prepend" />
            <div :class="`disabled-value ellipses ${paragraph ? 'paragraph' : ''}`">
                {{ value ? value : "-" }}
            </div>
        </div>
        <div v-else>
            <div
                v-for="(text, index) of value"
                :key="index"
                :class="`eqs-editable font-weight-bold ${getData.textBodyClass} ${
                    clickable ? 'pointer' : 'cursor-default'
                }`"
                :style="{ ...getStyle, ...styleFromContext }"
            >
                {{ `- ${text}` }}
            </div>
        </div>
    </v-fade-transition>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsEditable.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsEditable",
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-editable"
        },
        value: {
            type: null,
            required: true
        },
        editable: {
            type: Boolean,
            required: true
        },
        clickable: {
            type: Boolean,
            default: false
        },
        eqsSize: {
            type: String,
            default: "md"
        },
        paragraph: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        styleFromContext() {
            return !this.editable
                ? {
                      "margin-left": 0
                  }
                : {};
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsEditable.scss";
</style>
