import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            card: {
                "border-radius": "8px"
            }
        },
        notClickable: {
            card: {
                "border-radius": "none"
            }
        }
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {},
        notClickable: {
            notClickable: true
        }
    };
};
