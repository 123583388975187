var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-center mt-4 max-height-fit"},[_c('v-slide-y-transition',{attrs:{"hide-on-leave":""}},[(!_vm.companyBlockchainSetted)?_c('EqsRadio',_vm._b({staticClass:"pl-4",attrs:{"value":_vm.formData.blockchain},on:{"update:value":function($event){return _vm.$set(_vm.formData, "blockchain", $event)}},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('EqsAvatar',_vm._b({staticClass:"mr-3"},'EqsAvatar',{ src: item.icon },false)),_c('v-list-item-title',[_c('EqsTextDisplay',[_vm._v(" "+_vm._s(_vm.capitalize(item.txt))+" ")])],1)],1)]}}],null,false,1324578450)},'EqsRadio',{
                label: _vm.$t('registration.kyc.company.chose'),
                options: _vm.blockchains,
                loading: _vm.loading || _vm.localLoading,
                disabled: _vm.companyBlockchainSetted,
                elClass: 'blockchain-type'
            },false)):_vm._e()],1),_c('v-slide-y-transition',{attrs:{"hide-on-leave":""}},[(_vm.companyBlockchainSetted && _vm.formData.blockchain)?_c('EqsLabel',_vm._b({},'EqsLabel',{ value: _vm.$t('registration.kyc.company.chosen') },false),[_c('div',{staticClass:"d-flex"},[_c('EqsAvatar',_vm._b({staticClass:"mr-3"},'EqsAvatar',{ src: _vm.getBlockchainIcon(_vm.formData.blockchain) },false)),_c('EqsTextDisplay',{staticClass:"my-3",attrs:{"value":_vm.capitalize(_vm.formData.blockchain.name),"eqs-type":"subtitle"}})],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }