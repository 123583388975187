<template>
    <div class="eqs-chip" :el-class="elClass" :style="getStyle">
        <EqsSkeleton v-bind="{ loading, type: 'chip' }">
            <v-chip v-bind="{ ...$attrs, ...getData }" v-on="$listeners">
                <v-icon
                    v-if="icon || getData.icon"
                    left
                    :size="
                        $reactive.getVal({
                            mobile: '1.1rem',
                            tablet: '1.15rem',
                            desktop: '1.2rem'
                        })
                    "
                >
                    {{ icon || getData.icon }}
                </v-icon>
                <div class="text-truncate">
                    <slot name="default" />
                </div>
                <div class="text-truncate">
                    <slot name="alt" />
                </div>
            </v-chip>
        </EqsSkeleton>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsChip.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsChip",
    components: {
        EqsSkeleton: () => import("../EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-chip"
        },
        loading: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsChip.scss";
</style>
