import DEBUG from "@/store/debug.js";

export default async ({ state, commit }, setCountriesId) => {
    if (!setCountriesId) throw new Error("no setCountriesId");
    if (!state.setcountriesLoading) {
        try {
            commit("SET_SETCOUNTRIES_LOADING", true);
            const res = await window.app.$api.getTransaction(setCountriesId);
            commit("GET_SETCOUNTRIES", res.data);
        } catch (e) {
            DEBUG("GET_SETCOUNTRIES_ERROR");
            console.error({ e });
            throw e;
        } finally {
            commit("SET_SETCOUNTRIES_LOADING", false);
        }
    }
};
