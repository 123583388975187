export class SecondaryMarketService {
    constructor(api) {
        if (!api) {
            throw new Error("Missing `api` option");
        }
        this.api = api;
    }

    readSecondaryMarketSellOrderList() {
        return this.api.readSecondaryMarketSellOrderList();
    }

    readSecondaryMarketSellOrder(id) {
        return this.api.readSecondaryMarketSellOrder(id);
    }

    createAssetMovementBuyOrder(data) {
        return this.api.createAssetMovementBuyOrder(data).data;
    }

    createAssetMovementSellOrder(data) {
        return this.api.createAssetMovementSellOrder(data);
    }
}
