<template>
    <div
        :class="`eqs-stepper d-flex flex-column ${elClass}-container`"
        :style="{ ...getStyle, ...stepperStyle, ...bgColor }"
    >
        <v-stepper
            class="d-flex flex-column"
            v-bind="{ ...$attrs }"
            alt-labels
            :value.sync="valueProxy"
            v-on="$listeners"
        >
            <slot name="steps">
                <v-stepper-header v-if="!singleStep" class="eqs-stepper__header">
                    <v-row class="d-flex flex-nowrap align-stretch" no-gutters>
                        <v-col cols="11" class="d-flex">
                            <v-stepper-step
                                v-for="(step, index) in steps"
                                :key="`${step.title}-step`"
                                :class="`eqs-stepper__header__step ${elClass}-step-${index}`"
                                :complete="index < valueProxy - 1"
                                :color="index < valueProxy - 1 ? 'success' : 'primary'"
                                :step="index + 1"
                                :rules="[() => !(index === value && !validForm)]"
                            >
                                <div
                                    :style="`font-size: 80%;`"
                                    class="text-center mr-0 ml-0 eqs-stepper__header__step__label"
                                >
                                    {{ step.title }}
                                </div>
                            </v-stepper-step>
                        </v-col>
                        <v-col cols="1" class="pa-0 d-flex justify-end align-center">
                            <EqsBtn
                                v-if="closable"
                                class="pr-2"
                                text
                                :class="`${elClass}-close`"
                                v-bind="{ infoTxt: closeInfoTxt }"
                                @click="() => $emit('close')"
                            >
                                <v-icon> mdi-close </v-icon>
                            </EqsBtn>
                        </v-col>
                    </v-row>
                </v-stepper-header>
                <v-row
                    :class="`d-flex flex-nowrap align-stretch pb-0 ${
                        singleStep || !$reactive.isDesktop() ? '' : 'mt-3'
                    }`"
                    style="height:fit-content; !important"
                    no-gutters
                >
                    <v-col cols="2" class="height-fit" />
                    <v-col class="height-fit">
                        <EqsTextDisplay
                            v-if="item.title"
                            :value="item.title"
                            eqs-type="subtitle"
                            :class="`text-center flex pb-${$reactive.isMobile() ? '2' : '3'} ${
                                singleStep ? 'pt-3' : ''
                            }`"
                        />
                    </v-col>
                    <v-col cols="2" class="pa-0 d-flex justify-end height-fit" align-self="center">
                        <EqsBtn
                            v-if="closable && singleStep"
                            text
                            :data-cy="`${elClass}-close`"
                            v-bind="{ infoTxt: closeInfoTxt }"
                            @click="() => $emit('close')"
                        >
                            <v-icon> mdi-close </v-icon>
                        </EqsBtn>
                    </v-col>
                </v-row>
                <v-slide-x-transition>
                    <v-divider v-if="!progress" style="width: 100%" />
                    <v-progress-linear v-else :value="progress" :color="progressColor" />
                </v-slide-x-transition>
            </slot>
            <v-form
                ref="stepperForm"
                v-model="validForm"
                class="eqs-stepper__steps d-flex flex-column"
            >
                <v-stepper-items>
                    <v-slide-x-transition>
                        <v-stepper-content
                            v-if="!transition"
                            :class="`${elClass}-content`"
                            :step="valueProxy"
                        >
                            <div
                                :class="`eqs-stepper__steps__content ${
                                    item.noPadding ? 'pa-0' : ''
                                }`"
                            >
                                <slot
                                    v-bind="{
                                        loading,
                                        index: valueProxy,
                                        item: item.item,
                                        validForm
                                    }"
                                />
                            </div>
                        </v-stepper-content>
                    </v-slide-x-transition>
                </v-stepper-items>
            </v-form>
            <v-container fluid class="ma-0 pa-0">
                <v-row wrap class="eqs-stepper__btns">
                    <v-col class="d-flex mx-3" align-self="center" style="min-width: fit-content">
                        <EqsBtn
                            v-if="!item.noBack"
                            v-bind="{
                                action:
                                    item.back && item.back.fn
                                        ? item.back.fn
                                        : () => (valueProxy = value - 1),
                                loading,
                                ...getData.backBtn
                            }"
                            small
                            color="primary"
                            outlined
                            :el-class="`${elClass}-back`"
                        >
                            <template #with-icon>
                                <div class="text-truncate">
                                    <v-icon left small> mdi-arrow-left </v-icon>
                                    {{
                                        capitalizeFirstLetter(
                                            item.back && item.back.txt
                                                ? item.back.txt
                                                : $t("btn.back")
                                        )
                                    }}
                                </div>
                            </template>
                        </EqsBtn>
                    </v-col>

                    <v-col
                        cols="4"
                        class="d-flex mx-3"
                        style="min-width: fit-content"
                        align-self="center"
                    >
                        <EqsBtn
                            v-if="!item.noNext"
                            :el-class="`${elClass}-next`"
                            block
                            style="width: 100%"
                            :color="getBtnColor"
                            v-bind="{
                                action: () =>
                                    validate(
                                        item.next && item.next.fn
                                            ? item.next.fn
                                            : () => {
                                                  valueProxy = value + 1;
                                              }
                                    ),
                                loading,
                                errorMessages,
                                ...getData.nextBtn
                            }"
                        >
                            <div class="text-truncate">
                                {{ item.next && item.next.txt ? item.next.txt : $t("btn.next") }}
                            </div>
                        </EqsBtn>
                    </v-col>
                    <v-spacer class="mx-3 pa-3" />
                </v-row>
            </v-container>
        </v-stepper>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsStepper.js";
import { eqsUITemplate, bgColor } from "../../../mixins/styleMixin";
import { delayedPromise, capitalizeFirstLetter } from "../../../../../shared/util";

export default {
    name: "EqsStepper",
    components: {
        EqsBtn: () => import("../../atoms/EqsBtn/EqsBtn.vue"),
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue")
    },
    mixins: [eqsUITemplate, bgColor],
    props: {
        elClass: {
            type: String,
            default: "eqs-stepper"
        },
        value: {
            type: Number,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        steps: {
            type: Array,
            required: true
        },
        checkProgress: {
            type: Boolean,
            default: true
        },
        progress: {
            type: Number,
            default: 0,
            validator: val => {
                if (val < 0 || val > 100) {
                    console.error(
                        "progress value should be a percentage betweeen 0 and 100 and it is: ",
                        val
                    );
                    return false;
                }
                return true;
            }
        },
        closable: {
            type: Boolean,
            default: false
        },
        closeInfoTxt: {
            type: String,
            default: () => window.app.$t("molecule.card.close-txt")
        },
        errorMessages: {
            type: [String, Array],
            default: ""
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            validForm: true,
            transition: false,
            capitalizeFirstLetter
        };
    },
    computed: {
        progressColor() {
            if (this.checkProgress && !this.validForm) return "error";
            return this.progress === 100 ? "success" : "primary";
        },
        singleStep() {
            return this.steps.length === 1;
        },
        item() {
            return this.steps[this.value] || {};
        },
        stepperStyle() {
            let otherStyle = {};
            if (this.singleStep) otherStyle["--main-container-padding"] = "0px";
            return {
                ...otherStyle,
                "--header-step-max-width": `${100 / this.steps.length}%`
            };
        },
        valueProxy: {
            get() {
                return this.value + 1;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        getBtnColor() {
            if (!this.validForm) return "error";
            return this.progress === 100 ? "success" : "primary";
        }
    },
    watch: {
        value: {
            immediate: true,
            async handler(oldVal, newVal) {
                this.transition = `v-scroll-x${oldVal < newVal ? "-reverse" : ""}-transition`;
                this.transition = await delayedPromise(800, "");
            }
        },
        $color: {
            immediate: true,
            async handler(newColor) {
                document.documentElement.style.setProperty(
                    "--background-color",
                    newColor.colors.backgroundColor
                );
            }
        }
    },
    methods: {
        async validate(cb) {
            if (this.$refs.stepperForm.validate() && this.validForm) {
                await cb();
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsStepper.scss";
</style>
