<template>
    <EqsSkeleton class="internal-pdf-view" :loading="loadingPreview" type="image">
        <!-- <img :src="base64" class="internal-pdf-view__image"> -->
        <object class="fill-width" style="height: 100%" :data="pdfUrl" type="application/pdf">
            <embed :src="pdfUrl" type="application/pdf" />
        </object>
    </EqsSkeleton>
</template>

<script>
export default {
    name: "InternalPdfView",
    props: {
        signature_procedure: {
            type: Object,
            required: true
        },
        signatories: {
            type: Array,
            required: true
        },
        html: {
            type: String,
            default: ""
        },
        document: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            pdfUrl: "",
            loadingPreview: false
        };
    },
    watch: {
        "$route.query.tab": {
            immediate: true,
            handler(val) {
                if (val === "preview" && this.signature_procedure.id && !this.loadingPreview)
                    this.getPreview();
                else this.pdfUrl = "";
            }
        },
        signature_procedure(val) {
            if (val.id && !this.loadingPreview) this.getPreview();
        },
        html() {
            this.pdfUrl = "";
        },
        signatories(val) {
            if (val && this.signature_procedure.id && !this.loadingPreview) this.getPreview();
        },
        document: {
            immediate: true,
            handler(val) {
                if (val) this.getPreview();
            }
        }
    },
    methods: {
        async getPreview() {
            try {
                this.loadingPreview = true;
                let data = {
                    signatories: this.signatories
                };
                data[this.document ? "document_id" : "html"] = this.document
                    ? this.document.id
                    : this.html;
                if (this.html.length === 0) {
                    // pas de preview si html vide
                    this.pdfUrl = "";
                    return;
                }
                // signer_id -> id de l'entity (soit nom de l'investor ou alors key buyer)
                const res = await this.$api.getProcedurePreview(this.signature_procedure.id, data);
                this.pdfUrl = res;
            } catch (e) {
                console.error({ e });
                this.pdfUrl = "";
                this.$ui.error(e, "get_procedure_preview");
            } finally {
                this.loadingPreview = false;
            }
        }
    }
};
</script>

<style lang="scss">
.internal-pdf-view {
    height: 100%;
    .eqs-skeleton__container__content {
        height: 100%;
        &__image {
            height: inherit;
            overflow-y: auto;
        }
    }
}
</style>
