var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.category},on:{"update:value":function($event){return _vm.$set(_vm.formData, "category", $event)}}},'EqsSelect',{
                    label: _vm.$t('global.global-doc-with-category-list.select-category-label'),
                    elClass: `${_vm.elClass}-category-${_vm.index}`,
                    loading: _vm.loading,
                    editable: _vm.editable,
                    items: _vm.categories
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsFileInput',_vm._b({attrs:{"value":_vm.formData.document},on:{"update:value":function($event){return _vm.$set(_vm.formData, "document", $event)}}},'EqsFileInput',{
                    elClass: `${_vm.elClass}-doc-${_vm.index}`,
                    label: _vm.$t('global.global-doc-with-category-list.select-file-label'),
                    deleteFile: false,
                    editable: _vm.editable,
                    loading: _vm.loading
                },false))],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }