import History from "@/components/views/user/history/_History.vue";
import EntityDetailsDialog from "@/components/views/shareledger/dialogs/details/EntityDetailsDialog.vue";
import { i18n } from "@/services/plugins/i18n.js";

export default [
    {
        name: "history",
        path: "/history/:id",
        component: History,
        meta: {
            context: i18n.t("app.navigation-drawer.title.history")
        },
        props: true,
        children: [
            {
                name: "entity-details",
                path: "entity-details/:entityId(.+)",
                props: true,
                component: EntityDetailsDialog
            }
        ]
    }
];
