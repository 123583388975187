<template>
    <v-container fluid class="root-step-container">
        <h2 class="text-h5 mb-4">Compréhension des risques</h2>
        <EqsRadio
            :value.sync="formData.p3q1"
            row
            v-bind="{
                label: 'Prévoyez-vous d’investir moins de 10% de vos fonds propres sur un plateforme de financement participatif ?',
                ...schemaDouble.p3q1
            }"
        />
        <EqsRadio
            :value.sync="formData.p3q2"
            row
            v-bind="{
                label: 'Considérez-vous disposer d’une connaissance suffisamment approfondie des risques liés au financement participatif avant d’investir sur notre plateforme?',
                ...schemaDouble.p3q2
            }"
        />
        <EqsRadio
            :value.sync="formData.p3q3"
            row
            v-bind="{
                label: 'Un investissement dans des titres entraîne-t-il systématiquement une plus-value ?',
                ...schemaDouble.p3q3
            }"
        />
        <EqsRadio
            :value.sync="formData.p3q4"
            row
            v-bind="{
                label: 'Une action dans une société non-cotée peut-elle être facilement cédée ?',
                ...schemaDouble.p3q4
            }"
        />
        <EqsRadio
            :value.sync="formData.p3q5"
            row
            v-bind="{
                label: 'En investissant dans des prêts aux entreprises, vous pouvez ne pas obtenir le rendement publié par la plateforme, par exemple en cas de défaillance de l’entreprise ?',
                ...schemaDouble.p3q5
            }"
        />
        <EqsRadio
            :value.sync="formData.p3q6"
            row
            v-bind="{
                label: 'Investir à la fois dans des prêts ou dans des actions peut entraîner un risque du capital investi ?',
                ...schemaDouble.p3q6
            }"
        />
        <EqsRadio
            :value.sync="formData.p3q7"
            row
            v-bind="{
                label: 'Il existe un risque de liquidité qui ne permettra pas toujours de céder ces instruments financiers au moment souhaité ?',
                ...schemaDouble.p3q7
            }"
        />
        <EqsRadio
            :value.sync="formData.p3q8"
            row
            v-bind="{
                label: 'Êtes-vous financièrement capable de faire face au risque de perte totale du capital ?',
                ...schemaDouble.p3q8
            }"
        />
        <EqsRadio
            :value.sync="formData.p3q9"
            row
            v-bind="{
                label: 'Êtes-vous prêt à patienter plus de cinq années avant de pouvoir récupérer tout ou partie de votre investissement initial ? ',
                ...schemaDouble.p3q9
            }"
        />
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "Form3",
    mixins: [formMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped></style>
