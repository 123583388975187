import Excel from "exceljs";
import { createWorksheetAssetMovement } from "./assetMovement";
import { createShareholderWorksheet } from "./shareholder/createShareholderWorksheet.js";
import FileSaver from "file-saver";

export async function createWorkbook(type, listData, dataCompany) {
    try {
        const workbook = new Excel.Workbook();
        if (type === "AssetMovement") {
            createWorksheetAssetMovement(workbook, listData, dataCompany);
        } else {
            createShareholderWorksheet(workbook, listData, dataCompany);
        }
        return workbook.xlsx
            .writeBuffer()
            .then(buffer => FileSaver.saveAs(new Blob([buffer]), dataCompany.name + ".xlsx"))
            .catch(err => console.error("Error writing excel export", err));
    } catch (e) {
        console.error("Error happened when creating the workbook\n", e);
    }
}
