<template>
    <div class="eqs-main-container margin-auto">
        <GlobalFinalState :loading="loading" :state="state" :error-text="errorText">
            <template #content>
                <EqsTextDisplay
                    el-class="success-txt"
                    class="text-center"
                    eqs-type="subtitle"
                    :value="successText"
                />
            </template>
        </GlobalFinalState>
    </div>
</template>

<script>
export default {
    name: "Done",
    props: {
        state: {
            type: String,
            required: true
        },
        errorText: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        successText() {
            return this.$t("movements.success-txt", {});
        }
    }
};
</script>
