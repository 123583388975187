var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{staticClass:"d-flex fill-height fill-width",attrs:{"name":"eqs-slide-from-right"}},[(_vm.isRoot)?_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('EqsTabs',_vm._b({attrs:{"value":_vm.tab},on:{"update:value":function($event){_vm.tab=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c(item.is,_vm._b({tag:"component"},'component',{
                        loading:
                            _vm.loading || _vm.investorsLoading || _vm.shareledgersLoading || _vm.localLoading,
                        dashboardData: _vm.dashboardsData[item.section],
                        investorsWithShares: _vm.investorsWithShares
                    },false))]}}],null,false,1701159181)},'EqsTabs',{
                tabs: _vm.tabs,
                eqsType: 'default'
            },false))],1):_c('router-view',_vm._b({attrs:{"selected-shareledger":_vm.selectedShareledger,"entity":_vm.selectedShareholder},on:{"close":_vm.closeDialog,"closeDigitalizeLedger":_vm.closeDigitalizeLedger}},'router-view',{
            fetch: _vm.fetch,
            shareledgers: _vm.dashboardsData.parameters.shareledgers,
            loading: _vm.loading || _vm.investorsLoading || _vm.shareledgersLoading || _vm.localLoading
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }