import { eqsLogo } from "./eqs-logo";
import { positionComponent } from "../Position";

export function worksheetEqsLogo(workbook, worksheet) {
    const image = workbook.addImage({
        base64: eqsLogo,
        extension: "png"
    });

    worksheet.mergeCells(positionComponent.eqsIcon);
    worksheet.addImage(image, {
        tl: {
            col: 0.2,
            row: 0.44
        },
        ext: {
            width: 250,
            height: 70
        },
        editAs: undefined
    });
}
