<template>
    <div class="pa-0 ma-0">
        <v-card>
            <v-card-title class="d-flex flex-column align-start mb-4">
                <div class="document-title" :class="{ 'arthaud-font': $universe.isArthaud() }">
                    {{ $t("capital-increase.user.detail.user-document-title") }}
                </div>
                <div class="title-line" />
            </v-card-title>
            <v-card-text>
                <v-simple-table>
                    <template #default>
                        <thead>
                            <tr>
                                <th class="text-subtitle-1">
                                    {{ $t("capital-increase.user.detail.user-document-name") }}
                                </th>
                                <th class="text-subtitle-1">Date</th>
                                <th class="text-subtitle-1">Type</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(document, index) in getAllDocuments()" :key="index">
                                <td>{{ document.name }}</td>
                                <td>{{ formatDate(document.created_at) }}</td>
                                <td>
                                    <v-chip
                                        :color="getColorFromCategory(document.category)"
                                        text-color="white"
                                        label
                                    >
                                        {{ getCategory(document.category) }}
                                    </v-chip>
                                </td>
                                <td>
                                    <v-icon color="#2b2b2b" @click="downloadDocument(document)">
                                        mdi-download-outline
                                    </v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <!-- <EqsCard
            v-else
            class="no-elevation"
            v-bind="{
                loading
            }"
        >
            <v-row class="px-2 pb-3">
                <v-col>
                    <EqsTextDisplay
                        v-bind="{
                            value: $t('capital-increase.user.detail.user-document-title'),
                            eqsType: 'section'
                        }"
                    />
                </v-col>
            </v-row>
            <v-row class="px-2">
                <v-col
                    v-for="category in docs.categories"
                    :key="category"
                    :cols="$reactive.getCols('half')"
                >
                    <EqsTextDisplay v-bind="{ eqsType: 'label', value: getCategory(category) }" />
                    <EqsFileInput multiple :editable="false" :value="docs[category]" />
                </v-col>
            </v-row>
        </EqsCard> -->
        <EqsAlert v-if="noUrl" v-bind="{ loading }">
            URL de téléchargement manquante pour ce document : {{ noUrl.name }}.
        </EqsAlert>
    </div>
</template>

<script>
import find from "lodash/find";
import { getColorFromCategory } from "@/util/documents";
export default {
    name: "Documents",
    props: {
        docs: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        categories: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            noUrl: null,
            fixtureDocuments: [
                {
                    name: "Legal document",
                    created_at: "2023-12-19T11:54:18.645881+01:00",
                    category: "legal"
                },
                {
                    name: "Tech document",
                    created_at: "2023-12-19T11:54:18.645881+01:00",
                    category: "tech"
                },
                {
                    name: "Marketing document",
                    created_at: "2023-12-19T11:54:18.645881+01:00",
                    category: "marketing"
                },
                {
                    name: "Other document",
                    created_at: "2023-12-19T11:54:18.645881+01:00",
                    category: "other"
                }
            ]
        };
    },
    methods: {
        getColorFromCategory,
        getCategory(category) {
            const trad = find(this.categories, elem => elem.value == category);
            return trad.text || category;
        },
        getAllDocuments() {
            const allDocuments = [];
            this.docs.categories.forEach(category => {
                const documents = this.docs[category].map(doc => {
                    return {
                        ...doc,
                        category
                    };
                });
                allDocuments.push(...documents);
            });
            //allDocuments.push(...this.fixtureDocuments);
            return allDocuments;
        },
        formatDate(dateString) {
            const options = { year: "numeric", month: "long", day: "numeric" };
            const date = new Date(dateString);
            return date.toLocaleDateString("fr-FR", options);
        },
        downloadDocument(document) {
            if (document.upload) {
                this.noUrl = null;
                window.open(document.upload, "_blank");
            } else {
                this.noUrl = document;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.arthaud-font {
    font-family: IvyPresto Display !important;
    font-size: 2.5rem !important;
}
.document-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 63px;
    letter-spacing: 0em;
    text-align: left;
}

.title-line {
    width: 70px;
    height: 2px;
    background-color: #b89c62;
    margin-top: 10px;
}
</style>
