<template>
    <div class="eqs-tags" :el-class="elClass" :style="getStyle">
        <EqsLabel
            v-if="!display"
            v-bind="{
                ...$attrs,
                label,
                height: labelHeight,
                editable,
                value: label,
                infoTxt,
                isRequired
            }"
        >
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <EqsEditable v-bind="{ ...$attrs, editable, value: editableData }">
                <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                <EqsSkeleton v-bind="{ loading, type: 'sentences' }">
                    <v-combobox
                        v-model="valueProxy"
                        multiple
                        :no-filter="true"
                        chips
                        append-icon
                        :placeholder="$t('molecule.tags.placeholder-tag')"
                        :search-input.sync="search"
                        v-bind="{
                            ...$attrs,
                            ...getData,
                            rules: [],
                            deletableChips: editable,
                            clearable: editable,
                            readonly: !editable,
                            editable
                        }"
                        v-on="$listeners"
                        @paste="updateTags"
                        @blur="updateTags"
                    />
                </EqsSkeleton>
            </EqsEditable>
        </EqsLabel>
        <v-row v-else-if="tags && tags.length">
            <v-col v-for="(item, index) in tags" :key="index">
                <EqsTextDisplay v-bind="{ elClass: `${elClass}-item-${index}` }">
                    <slot name="tag" v-bind="{ item, index }" />
                </EqsTextDisplay>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsTags.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";
import { protectedCharR } from "../../../rules";

export default {
    name: "EqsTags",
    components: {
        EqsLabel: () => import("../../atoms/EqsLabel/EqsLabel.vue"),
        EqsEditable: () => import("../../atoms/EqsEditable/EqsEditable.vue"),
        EqsSkeleton: () => import("../../atoms/EqsSkeleton/EqsSkeleton.vue"),
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    rules: [protectedCharR],
    props: {
        elClass: {
            type: String,
            default: "eqs-tags"
        },
        value: {
            // type: [String, Array],
            validator: prop =>
                typeof prop === "string" ||
                prop === null ||
                prop === undefined ||
                Array.isArray(prop),
            required: true
        },
        editable: {
            type: Boolean,
            default: true
        },
        display: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            search: "",
            select: [],
            protectedCharR,
            update: false
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value || "";
            },
            set(val) {
                this.$emit("update:value", val || "");
            }
        },
        tags() {
            if (!this.value || !this.value.length) return [];
            return this.value.map(tag => {
                if (tag.startsWith("#")) return tag;
                return "#" + tag;
            });
        },
        // bypass this computed from inputFormMixin
        valueString() {
            return null;
        }
    },
    watch: {
        search(val) {
            this.$nextTick(() => {
                if (val && val.includes(",")) {
                    const res = val.split(",").filter(tag => /\S/.test(tag));
                    this.$emit("update:value", [...this.valueProxy, ...res]);
                    this.update = !this.update;
                    this.search = "";
                }
            });
        }
    },
    methods: {
        updateTags() {
            // this.$nextTick(() => {
            this.update = true;

            // this.$nextTick(() => {
            //   this.search = ''
            // })
            // })
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsTags.scss";
</style>
