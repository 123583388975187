import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {},
        info: {},
        success: {},
        warning: {},
        error: {}
    };
};

export const dataTypes = (currentSize = {}) => {
    const info = {
        type: "info"
    };
    return {
        default: info,
        info,
        success: {
            type: "success"
        },
        warning: {
            type: "warning"
        },
        error: {
            type: "error"
        }
    };
};
