export const iconColors = {
    greenPrimary: "green-primary",
    greenSecondary: "green-secondary",
    black: "black",
    darkSecondary: "dark-secondary",
    grayPrimary: "gray-primary",
    graySecondary: "gray-secondary",
    lightPrimary: "light-primary",
    white: "white",
    redPrimary: "red-primary",
    redSecondary: "red-secondary",
    yellowPrimary: "yellow-primary",
    yellowSecondary: "yellow-secondary",
    bluePrimary: "blue-primary",
    blueSecondary: "blue-secondary"
};
export const colors = {
    primary: "color-primary",
    secondary: "color-secondary",
    light: "color-light",
    dark: "color-dark",
    info: "color-info",
    success: "color-success",
    warning: "color-warning",
    danger: "color-danger",
    inputBackground: "#fafbfc",
    mainBackground: "#f5f6f8",
    eqsBlue: "#0D3F67",
    lightGrey: "#cacedb",
    lighterGrey: "#F5F5F5",
    almostWhite: "#fbfbfb",
    radiantGreen: "#14E67D",
    lighterRed: "#c4183c",
    infoGreen: "#38CB60",
    warnOrange: "#FB8005",
    confirmBlue: "#2F60A2",
    alertRed: "#FF6B6B",
    menuColor: "#F8F8F8",
    roundedBtn: ""
};

export const colorsValues = Object.values(colors);
export const iconColorsValues = Object.values(iconColors);
export default colors;

export const rand_colors_0 = [
    "#FF3784",
    "#36A2EB",
    "#4BC0C0",
    "#F77825",
    "#9966FF",
    "#00A8C6",
    "#379F7A",
    "#CC2738",
    "#8B628A",
    "#8FBE00"
];

// legacy set of colors, could be used to avoid color collision if needed
export const rand_colors_1 = [
    "#B28DFF",
    "#DD9F8A",
    "#91A2CF",
    "#C7D8AA",
    "#A17B77",
    "#9A78A0",
    "#799E9B",
    "#93C8CE",
    "#DB8A8A",
    "#E8A956"
];

export const gradient_colors = {
    orange: {
        deg: "125deg",
        color1: "#f17334",
        color2: "#fcbe2f"
    },
    blue: {
        deg: "306deg",
        color1: "#1dcbff",
        color2: "#336aec"
    },
    green: {
        deg: "139deg",
        color1: "#19998e",
        color2: "#6abf73"
    }
};
