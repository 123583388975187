var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"root-step-container",attrs:{"fluid":""}},[_c('h2',{staticClass:"text-h5 mb-4"},[_vm._v("Compréhension des risques")]),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p3q1,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p3q1", $event)}}},'EqsRadio',{
            label: 'Prévoyez-vous d’investir moins de 10% de vos fonds propres sur un plateforme de financement participatif ?',
            ..._vm.schemaDouble.p3q1
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p3q2,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p3q2", $event)}}},'EqsRadio',{
            label: 'Considérez-vous disposer d’une connaissance suffisamment approfondie des risques liés au financement participatif avant d’investir sur notre plateforme?',
            ..._vm.schemaDouble.p3q2
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p3q3,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p3q3", $event)}}},'EqsRadio',{
            label: 'Un investissement dans des titres entraîne-t-il systématiquement une plus-value ?',
            ..._vm.schemaDouble.p3q3
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p3q4,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p3q4", $event)}}},'EqsRadio',{
            label: 'Une action dans une société non-cotée peut-elle être facilement cédée ?',
            ..._vm.schemaDouble.p3q4
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p3q5,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p3q5", $event)}}},'EqsRadio',{
            label: 'En investissant dans des prêts aux entreprises, vous pouvez ne pas obtenir le rendement publié par la plateforme, par exemple en cas de défaillance de l’entreprise ?',
            ..._vm.schemaDouble.p3q5
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p3q6,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p3q6", $event)}}},'EqsRadio',{
            label: 'Investir à la fois dans des prêts ou dans des actions peut entraîner un risque du capital investi ?',
            ..._vm.schemaDouble.p3q6
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p3q7,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p3q7", $event)}}},'EqsRadio',{
            label: 'Il existe un risque de liquidité qui ne permettra pas toujours de céder ces instruments financiers au moment souhaité ?',
            ..._vm.schemaDouble.p3q7
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p3q8,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p3q8", $event)}}},'EqsRadio',{
            label: 'Êtes-vous financièrement capable de faire face au risque de perte totale du capital ?',
            ..._vm.schemaDouble.p3q8
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p3q9,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p3q9", $event)}}},'EqsRadio',{
            label: 'Êtes-vous prêt à patienter plus de cinq années avant de pouvoir récupérer tout ou partie de votre investissement initial ? ',
            ..._vm.schemaDouble.p3q9
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }