<template>
    <div
        :class="`eqs-data-iterator ${elClass}-container`"
        :style="{ ...getStyle, ...additionalStyle }"
    >
        <v-data-iterator
            v-if="!noData"
            v-model="selectedProxy"
            v-bind="{
                ...$attrs,
                ...getData,
                loading,
                items,
                height: '100%',
                search
            }"
            fixed-header
            v-on="$listeners"
        >
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <template #header>
                <div v-if="searchable || add || $slots['header']" class="-flex flex-column">
                    <v-row class="d-flex align-center">
                        <slot name="header" />
                        <v-col v-if="searchable" :cols="$reactive.isXs() || !add ? 12 : 6">
                            <EqsSearchBar
                                :value.sync="search"
                                v-bind="{ ...searchable, loading }"
                            />
                        </v-col>
                        <v-spacer />
                        <v-col
                            v-if="add"
                            :cols="$reactive.isXs() && !searchable && !$slots['header'] ? 12 : 4"
                        >
                            <div class="d-flex justify-end">
                                <EqsBtn
                                    :el-class="`${elClass}-add-item`"
                                    color="primary"
                                    :eqs-type="
                                        $reactive.isXs() || !searchable ? 'default' : 'block'
                                    "
                                    v-bind="{
                                        action: add.fn,
                                        loading
                                    }"
                                >
                                    <template #with-icon>
                                        <v-icon left> mdi-plus </v-icon>
                                        {{ capitalizeFirstLetter(add.txt) }}
                                    </template>
                                </EqsBtn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template #default="data">
                <div class="eqs-data-iterator__content">
                    <v-row class="ma-0 pa-6" style="max-width: 100%">
                        <v-col
                            v-for="(item, index) in data.items"
                            :key="index"
                            :cols="$reactive.getVal(itemCols)"
                        >
                            <slot
                                name="item"
                                v-bind="{
                                    item,
                                    index,
                                    isSelected: data.isSelected[index],
                                    isExpanded: data.isExpanded[index],
                                    expand: data.expand,
                                    select: data.select
                                }"
                            />
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template #no-data>
                <div class="eqs-data-iterator__content eqs-data-iterator__empty">
                    <EqsTextDisplay
                        v-if="!$slots['no-data']"
                        v-bind="{ eqsType: 'subtitle', value: noDataText }"
                    />
                    <slot v-else name="no-data" />
                </div>
            </template>
            <template #no-results>
                <div class="eqs-data-iterator__content eqs-data-iterator__empty">
                    <EqsTextDisplay
                        v-if="!$slots['no-results']"
                        v-bind="{ eqsType: 'subtitle', value: noResultText }"
                    />
                    <slot name="no-result" />
                </div>
            </template>
            <template #loading>
                <v-row class="eqs-data-iterator__content overflow-y">
                    <v-col v-for="index in twoRows" :key="index" :cols="$reactive.getVal(itemCols)">
                        <EqsCard loading />
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
        <InternalNoData v-else v-bind="{ noDataCustom, add, elClass }" />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsDataIterator.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { capitalizeFirstLetter } from "../../../../../shared/util";

export default {
    name: "EqsDataIterator",
    components: {
        EqsSearchBar: () => import("../../molecules/EqsSearchBar/EqsSearchBar.vue"),
        EqsCard: () => import("../../molecules/EqsCard/EqsCard.vue"),
        EqsBtn: () => import("../../atoms/EqsBtn/EqsBtn.vue"),
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue"),
        InternalNoData: () => import("../EqsDataTable/_internal/InternalNoData.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-data-iterator"
        },
        items: {
            type: Array,
            default: () => []
        },
        selected: {
            type: Array,
            default: () => []
        },
        noDataText: {
            type: String,
            default: () => window.app.$t("label.no-data")
        },
        noResultText: {
            type: String,
            default: () => window.app.$t("label.no-result")
        },
        itemCols: {
            type: Object,
            default: () => {
                return {
                    xs: 12,
                    sm: 12,
                    md: 6,
                    lg: 3,
                    xl: 2
                };
            }
        },
        title: {
            type: String,
            default: ""
        },
        add: {
            type: Object,
            default: null
        },
        searchable: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        topHeightToAdd: {
            type: Number,
            default: 0
        },
        noDataCustom: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            search: "",
            capitalizeFirstLetter
        };
    },
    computed: {
        noData() {
            return !this.loading && !this.items.length && this.noDataCustom;
        },
        selectedProxy: {
            get() {
                return this.selected;
            },
            set(val) {
                this.$emit("update:selected", val);
            }
        },
        twoRows() {
            return (12 / this.$reactive.getVal(this.itemCols)) * 2;
        },
        additionalStyle() {
            let cssVars = {
                "--height-to-remove": this.getData.heightToRemove
            };
            const haveHeader = this.add || this.searchable || !!this.$slots["header"];
            cssVars[haveHeader ? "--second-div-fill" : "--first-div-fill"] = "fill";
            cssVars[haveHeader ? "--second-div-fill-webkit" : "--first-div-fill-webkit"] =
                "-webkit-fill-available";
            return cssVars;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsDataIterator.scss";
</style>
