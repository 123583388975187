<template>
    <v-container
        v-if="
            !$entity.isAffiliationCgp() ||
            ($entity.isAffiliationCgp() && _entity.lemonway_status === 6)
        "
        fluid
    >
        <Breadcrumbs v-if="!isRootPage" :loading="loading" root-page="affiliation" />
        <!-- <transition name="fade" mode="out-in"> -->

        <KeepAlive v-if="!resetCache" max="12">
            <router-view :key="$route.path" :loading="loading" />
        </KeepAlive>
        <router-view v-else :key="$route.path" :loading="loading" />

        <!-- </transition> -->
    </v-container>
    <v-container v-else>
        <v-alert dense border="left" type="warning" prominent class="mt-12">
            <span style="color: black">
                {{ $t("universe.kyoseil.cgp-no-kyc-affiliate") }}
            </span>
        </v-alert>
    </v-container>
</template>

<script>
import { isRootPage } from "@/components/views/user/clients/tools.js";
import Breadcrumbs from "./Breadcrumbs";

export default {
    name: "Affiliations",
    components: {
        Breadcrumbs
    },
    inject: ["_entity"],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            resetCache: false
        };
    },
    computed: {
        isRootPage() {
            return isRootPage(this.$route);
        }
    },
    watch: {
        "$store.getters.affiliation_reset_cache": {
            handler: function (newValue, oldValue) {
                if (newValue) {
                    this.resetCache = true;
                    this.$store.commit("SET_AFFILIATION_RESET_CACHE", false);
                }
            }
        },
        "$route.fullPath": {
            handler: function () {
                if (this.$store.getters.affiliation_refresh_next_page) {
                    this.$store.commit("SET_AFFILIATION_REFRESH_NEXT_PAGE", false);
                    this.$store.commit("SET_AFFILIATION_RESET_CACHE", true);
                }
            }
        }
    },
    updated() {
        this.resetCache = false;
    },
    methods: {}
};
</script>

.
<style lang="scss" scoped>
.v-tab {
    text-transform: none;
}
</style>
