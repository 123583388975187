<template>
    <v-container fluid>
        <v-row class="d-flex flex-column align-center">
            <v-col :cols="$reactive.isMobile() ? 12 : 6">
                <EqsTextDisplay
                    eqs-type="subtitle"
                    :value="$t('user.payment-confirmation.subtitle-1')"
                    class="py-3"
                />
                <EqsTextDisplay :value="$t('user.payment-confirmation.text-1')" paragraph />
            </v-col>
            <v-col :cols="$reactive.isMobile() ? 12 : 6">
                <EqsTextDisplay
                    eqs-type="subtitle"
                    :value="$t('user.payment-confirmation.subtitle-2')"
                    class="py-3"
                />
                <EqsTextDisplay
                    v-if="order.capinc.buyer_fee_subscription_percentage > 0"
                    paragraph
                    class="py-3"
                >
                    <span
                        v-html="
                            $t('user.payment-confirmation.text-3', {
                                share_type: shareTypeTxt,
                                shareledger_name: order.capinc.shareledger.name,
                                numberOfShares: order.numberofshares,
                                fees: order.buyer_fees,
                                pricePerShare: order.object.share_price,
                                totalAmount: order.total_price
                            })
                        "
                    />
                    <v-input :error-messages="walletRule" />
                </EqsTextDisplay>
                <EqsTextDisplay v-else paragraph class="py-3">
                    <span
                        v-html="
                            $t('user.payment-confirmation.text-2', {
                                share_type: shareTypeTxt,
                                shareledger_name: order.capinc.shareledger.name,
                                numberOfShares: order.numberofshares,
                                pricePerShare: order.object.share_price,
                                totalAmount: order.total_price
                            })
                        "
                    />
                    <v-input :error-messages="walletRule" />
                </EqsTextDisplay>

                <v-row v-if="walletRule" class="px-3" justify="center">
                    <v-col :cols="$reactive.getCols('md')" class="eqs-form-field">
                        <EqsBtn
                            data-cy="money-in"
                            color="success"
                            block
                            v-bind="{ loading, action: changeRoute }"
                        >
                            <v-icon left> mdi-cash-plus </v-icon>
                            {{ $t("user.wallet.add-funds-btn") }}
                        </EqsBtn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-if="signer && signer.procedure_documents && signer.procedure_documents.length"
                :cols="$reactive.isMobile() ? 12 : 6"
            >
                <EqsTextDisplay
                    eqs-type="subtitle"
                    :value="$t('user.payment-confirmation.subtitle-3')"
                    class="py-3"
                />
                <EqsFileInput
                    multiple
                    v-bind="{
                        value: signer.procedure_documents.map(doc => doc.signed_document),
                        editable: false
                    }"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { getShareledgerTypeStr } from "../util";

export default {
    name: "WalletConfirmation",
    mixins: [formMixin],
    props: {
        balance: {
            type: Number,
            required: false,
            default: 0
        },
        order: {
            type: Object,
            required: true
        },
        signer: {
            type: Object,
            default: null
        }
    },
    computed: {
        walletRule() {
            // Check wallet que si paiement wallet
            if (!this.order.payment_data.walletdata) {
                return null;
            }
            if (this.order.total_price <= this.balance || this.loading) {
                return null;
            }
            let moneyToAdd = this.$currency.display(this.order.total_price - this.balance);
            // this.$t("user.buy.wallet-rule-pea")
            return this.$t("user.buy.wallet-rule", {
                value: moneyToAdd
            });
        },
        shareTypeTxt() {
            return getShareledgerTypeStr(this, this.order.capinc.shareledger);
        }
    },
    methods: {
        changeRoute() {
            return this.$router.push({
                name: "money-in",
                params: { entity: this.$entity.represented().id, id: "init" },
                query: {
                    moneyVal: this.order.total_price
                }
            });
        }
    }
};
</script>
