var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',{attrs:{"title":_vm.investor.name,"loading":_vm.loading,"closable":"","height":"100%"},on:{"close":() => _vm.$emit('close')}},[(!_vm.loading)?_c('EqsPanels',{staticClass:"fill-height overflow-y",attrs:{"value":0,"panels":_vm.panels},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [(item.is != 'EqsBar')?_c(item.is,_vm._b({tag:"component",attrs:{"profile":""}},'component',{
                    countries: [],
                    ...item.props,
                    loading: _vm.localLoading,
                    schemaDouble: {},
                    formData: _vm.investor.entity ? _vm.investor.entity : _vm.investor
                },false)):_vm._e()]}}],null,false,877328)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }