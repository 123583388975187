<template>
    <div class="eqs-main-container margin-auto">
        <div class="d-flex flex-column fill-height">
            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full')">
                    <EqsTextDisplay
                        class="py-2"
                        eqs-type="title"
                        :value="actionsText[action].title"
                        :loading="loading"
                    />

                    <EqsTextDisplay :value="actionsText[action].subtitle" :loading="loading" />
                </v-col>
            </v-row>

            <v-row v-if="action !== 'ask'" class="px-3">
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <v-slide-x-transition hide-on-leave>
                        <EqsDate
                            el-class="operation-date"
                            :label="$t('payments.label.operation-date')"
                            :value.sync="formData.operation_date"
                            :loading="loading"
                            v-bind="{ ...schemaDouble.operation_date }"
                        />
                    </v-slide-x-transition>
                </v-col>
            </v-row>

            <v-row v-if="action !== 'ask'" class="px-3">
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <EqsNumber
                        el-class="amount"
                        :label="$t('payments.label.amount')"
                        :value.sync="formData.amount"
                        :is-currency="true"
                        :rules="[greaterThanZeroR, walletRule]"
                        :loading="loading"
                        v-bind="{ ...schemaDouble.amount }"
                    />
                </v-col>

                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <GlobalChooseInvestor
                        el-class="investor"
                        :label="$t('payments.label.investor')"
                        :value.sync="formData.credited_entity"
                        v-bind="{ ...schemaDouble.credited_entity, loading }"
                    />
                </v-col>
            </v-row>
            <v-row v-if="action === 'ask'" class="px-3">
                <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                    <GlobalChooseInvestor
                        el-class="investor"
                        :label="$t('payments.label.investor')"
                        :value.sync="formData.debited_entity"
                        v-bind="{ ...schemaDouble.debited_entity, loading }"
                    />
                </v-col>
            </v-row>
            <v-row v-if="action === 'ask'" class="px-3">
                <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                    <EqsNumber
                        el-class="amount"
                        :label="$t('payments.label.amount')"
                        :value.sync="formData.amount"
                        :is-currency="true"
                        :rules="[greaterThanZeroR]"
                        :loading="loading"
                        v-bind="{ ...schemaDouble.amount }"
                    />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                    <EqsSelect
                        el-class="type"
                        :label="$t('payments.label.type')"
                        :placeholder="''"
                        :value.sync="formData.operation_type"
                        :items="types"
                        item-text="txt"
                        item-value="id"
                        :loading="loading"
                        v-bind="{ ...schemaDouble.operation_type }"
                    />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full')">
                    <EqsTextArea
                        :label="$t('payments.label.comment')"
                        :value.sync="formData.comment"
                        v-bind="{
                            loading,
                            ...schemaDouble.comment
                        }"
                        el-class="description-transfer"
                    />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                    <EqsFileInput
                        el-class="docs"
                        :label="$t('movements.label.docs')"
                        :value.sync="formData.docs"
                        multiple
                        :loading="loading"
                        v-bind="{ ...schemaDouble.docs }"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { greaterThanZeroR } from "@equisafe-ui-vue/src/rules";
import { mapActions, mapGetters } from "vuex";
import { types } from "@/util/payment.js";

export default {
    name: "AddPaymentForm",
    mixins: [formMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        balance: {
            required: false
        },
        action: {
            required: true
        }
    },
    data() {
        return {
            actionsText: {
                download: {
                    title: this.$t("payments.download.title"),
                    subtitle: this.$t("payments.download.subtitle")
                },
                ask: {
                    title: this.$t("payments.ask.title"),
                    subtitle: this.$t("payments.ask.subtitle")
                },
                upload: {
                    title: this.$t("payments.upload.title"),
                    subtitle: this.$t("payments.upload.subtitle")
                },
                to_send: {
                    title: this.$t("payments.to_send.title"),
                    subtitle: this.$t("payments.to_send.add-btn")
                }
            },
            types,
            greaterThanZeroR
        };
    },
    created() {
        this.getInvestors();
    },
    computed: {
        walletRule() {
            return (
                !!(this.action == "download" || this.formData.amount <= this.balance) ||
                this.$t("buyStepper.step.pruchase-amount.error.balance") +
                    " : " +
                    this.$currency.display(this.balance)
            );
        },
        ...mapGetters(["investors", "investorsLoading"])
    },
    methods: mapActions(["getInvestors"])
};
</script>
