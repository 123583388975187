<template>
    <div class="mx-12 my-8">
        <GlobalTextEditorDisplay v-bind="{ value: field.content }" />
    </div>
</template>

<script>
import GlobalTextEditorDisplay from "@/components/global/globalTextEditorDisplay/GlobalTextEditorDisplay.vue";

export default {
    name: "FieldText",
    components: { GlobalTextEditorDisplay },
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped></style>
