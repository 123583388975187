<template>
    <div class="eqs-select-language" :el-class="elClass" :style="getStyle">
        <EqsSelect
            :label="$t('user.profile.current-profile.language-preference')"
            :value.sync="valueProxy"
            v-bind="{
                ...$attrs,
                items,
                isRequired: false,
                clearable: false
            }"
            v-on="$listeners"
        >
            <template #item="{ item }">
                <img v-if="item.src" width="18rem" height="18rem" class="mr-2" :src="item.src" />
                {{ item.text }}
            </template>
            <template #selection="{ item }">
                <img v-if="item.src" width="20rem" height="20rem" class="mr-2" :src="item.src" />
                {{ item.text }}
            </template>
        </EqsSelect>
    </div>
</template>

<script>
import { getRootURL } from "../../../../../shared/util";
import { cssTypes, dataTypes } from "./EqsSelectLanguage.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsSelectLanguage",
    components: {
        EqsSelect: () => import("../../molecules/EqsSelect/EqsSelect.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-select-language"
        },
        value: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: false
        },
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        rootURL() {
            return getRootURL();
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsSelectLanguage.scss";
</style>
