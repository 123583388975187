<template>
    <v-container fluid>
        <h2 class="text-h5 mb-4">Simulation de capacité d’investissement</h2>
        <v-alert v-if="showUpdateSimulation" dense text border="left" type="info">
            <span style="color: black"> Vous pouvez mettre à jour votre simulation </span>
        </v-alert>

        <v-alert border="left" prominent text type="warning" class="mt-4">
            <span style="color: black">
                Les données fournies doivent l'être au 31 décembre de l'année précédente, ou une
                date plus récente si les données au 31 décembre de l'année précédente ne reflètent
                pas correctement votre situation financière. Si vous choisissez une date plus
                récente pour l'une des rubriques, vous devrez fournir toutes les informations du
                questionnaire à cette date.
            </span>
        </v-alert>
        <p class="font-weight-bold">
            Afin de valider votre profil, vous devez remplir la simulation de la capacité à
            supporter des pertes à l'aide du formulaire ci-dessous
        </p>

        <v-row align="center" align-content="center" justify="center">
            <v-col cols="12" sm="6">
                <EqsNumber
                    :value.sync="formData.p4bq1"
                    :is-currency="false"
                    :is-float="false"
                    v-bind="{
                        label: labelQ1,
                        editable: true,
                        placeholder: 'Ex: 4500',
                        ...schemaDouble.p4bq1
                    }"
                >
                    <template #append-outer>
                        <v-tooltip open-on-hover right>
                            <template #activator="{ on, attrs }">
                                <span icon v-bind="attrs" v-on="on">
                                    <v-icon> mdi-information </v-icon>
                                </span>
                            </template>
                            <div class="tooltip-cst">
                                Il s'agit du montant total de tous les revenus perçus : salaires,
                                les revenus d'investissement, les revenus locatifs, les allocations,
                                etc.
                            </div>
                        </v-tooltip>
                    </template>
                </EqsNumber>
                <EqsNumber
                    :value.sync="formData.p4bq2"
                    :is-currency="false"
                    :is-float="false"
                    v-bind="{
                        label: labelQ2,
                        editable: true,
                        placeholder: 'Ex: 15000',
                        ...schemaDouble.p4bq2
                    }"
                >
                    <template #append-outer>
                        <v-tooltip open-on-hover right>
                            <template #activator="{ on, attrs }">
                                <span icon v-bind="attrs" v-on="on">
                                    <v-icon> mdi-information </v-icon>
                                </span>
                            </template>
                            <div class="tooltip-cst">
                                Il s'agit de la valeur totale de tous les biens et ressources
                                financières, (hors immobiliers) : l'argent liquide, des comptes
                                bancaires, des investissements (actions, obligations, etc.).
                            </div>
                        </v-tooltip>
                    </template>
                </EqsNumber>
                <EqsNumber
                    :value.sync="formData.p4bq3"
                    :is-currency="false"
                    :is-float="false"
                    v-bind="{
                        label: labelQ3,
                        editable: true,
                        placeholder: 'Ex: 2000',
                        ...schemaDouble.p4bq3
                    }"
                >
                    <template #append-outer>
                        <v-tooltip open-on-hover right>
                            <template #activator="{ on, attrs }">
                                <span icon v-bind="attrs" v-on="on">
                                    <v-icon> mdi-information </v-icon>
                                </span>
                            </template>
                            <div class="tooltip-cst">
                                Il s’agit des prêts avec taux d'intérêts, les obligations et les
                                dépenses financières régulières sur l'année.
                            </div>
                        </v-tooltip>
                    </template>
                </EqsNumber>
            </v-col>
            <v-col cols="12" sm="6" class="pl-10">
                <p>Capacité d'investissement estimée</p>
                <p class="font-weight-bold">
                    {{ $currency.displayWithCurrency(investCapacity, "-") }} / deal
                </p>
            </v-col>
        </v-row>

        <EqsCheckbox
            :value.sync="formData.confirm_unwarned"
            :is-required="true"
            class="font-weight-bold mt-4 mb-4"
            v-bind="{
                editable: true,
                ...schemaDouble.confirm_unwarned
            }"
        >
            <template #label>
                {{ labelQ4 }}
            </template>
        </EqsCheckbox>
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "Form4bis",
    mixins: [formMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        isCompany: {
            type: Boolean,
            required: true
        },
        cipForm: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {
        investCapacity() {
            if (
                this.formData.p4bq1 !== null &&
                this.formData.p4bq2 !== null &&
                this.formData.p4bq3 !== null
            ) {
                const income = parseInt(this.formData.p4bq1);
                const shares = parseInt(this.formData.p4bq2);
                const debts = parseInt(this.formData.p4bq3);
                return Math.floor((income + shares - debts) * 0.1);
            }
            return null;
        },
        showUpdateSimulation() {
            return (
                this.cipForm.p4bq1 !== null &&
                this.cipForm.p4bq2 !== null &&
                this.cipForm.p4bq3 !== null
            );
        },
        labelQ1() {
            return true
                ? "Le montant total des revenus annuels net (en euros)"
                : "Le montant total des revenus annuels net (en euros)";
        },
        labelQ2() {
            return true
                ? "Le montant de vos actifs (en euros)"
                : "Le montant de vos actifs (en euros)";
        },
        labelQ3() {
            return true
                ? "Le montant de vos dettes (en euros)"
                : "Le montant de vos dettes (en euros)";
        },
        labelQ4() {
            return true
                ? "Je suis conscient d’être un investisseur non averti, en confirmant ce test de connaissances, j’accepte de simuler ma capacité à supporter des pertes."
                : "Je suis conscient d’être un investisseur non averti, en confirmant ce test de connaissances, j’accepte de simuler ma capacité à supporter des pertes.";
        }
    }
};
</script>

<style lang="scss" scoped>
.tooltip-cst {
    max-width: 300px;
    opacity: 1 !important;
}
</style>
