<template>
    <EqsDialog
        :value.sync="dialogProxy"
        closable
        persistent
        v-bind="{
            loading: loading || localLoading,
            cancel: {
                fn: () => $router.push({ name: 'projects', params: $route.params })
            },
            confirm: {
                fn: () =>
                    $router.push({
                        name: 'risk-aversion',
                        params: $route.params,
                        query: $route.query
                    }),
                txt: $t('risk-aversion.dialog.confirm')
            },
            title: $t('risk-aversion.dialog.title')
        }"
    >
        <div class="d-flex flex-column">
            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full', 'dialog')" class="eqs-form-field">
                    <EqsTextDisplay paragraph v-bind="{ value: $t('risk-aversion.dialog.body') }" />
                </v-col>
                <v-col :cols="$reactive.getCols('full', 'dialog')" class="eqs-form-field">
                    <EqsSwitch
                        v-bind="{
                            value: $entity.represented().cip_advice,
                            text: $t('risk-aversion.dialog.switch'),
                            loading: loading || localLoading
                        }"
                        @change="event => updateUserPref(event)"
                    />
                </v-col>
            </v-row>
        </div>
    </EqsDialog>
</template>

<script>
export default {
    name: "RiskAversionDialog",
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            localLoading: false
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) this.cancel();
                this.$emit("update:value", val);
            }
        }
    },
    methods: {
        cancel() {
            this.$router.push({ name: "projects", params: this.$route.params });
        },
        confirm() {
            this.$router.push({
                name: "risk-aversion",
                params: this.$route.params,
                query: this.$route.query
            });
        },
        async updateUserPref(event) {
            try {
                this.localLoading = true;
                await this.$api.updatePreferences({
                    name: "cip_advice",
                    value: !!event
                });
            } catch (e) {
                this.$ui.error(e, "update_preferences");
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
