<template>
    <v-container fluid class="pa-0 ma-0">
        <div
            v-if="universe === 'myclubdeal' && !crowdfunding"
            style="
                height: 150px;
                background-image: linear-gradient(to right, #405cff, #575eae);
                border-radius: 50px;
            "
            @click="openPublic()"
        >
            <!-- Que pour myclubdeal : gros cta en haut -->
            <h1
                style="
                    cursor: pointer;
                    color: white !important;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 150px;
                "
            >
                {{ $t("projecs.dashboard.offerings.go-myclubdeal") }}
            </h1>
        </div>
        <div v-if="!universe">
            <!-- Que pour deals privés hors univers -->
            <v-alert
                v-if="crowdfunding"
                class="my-4 pa-4 alert-link"
                icon="mdi-eye-lock-open-outline"
                prominent
                type="warning"
                elevation="2"
                dense
                shaped
                color="#17375F"
            >
                <span v-html="$t('crowdfunding.warning')" />
            </v-alert>
            <v-alert
                v-else
                class="my-4 pa-4 alert-link"
                icon="mdi-eye-lock"
                prominent
                type="info"
                elevation="2"
                dense
                shaped
                color="#17375F"
            >
                <span v-html="$t('projects.private-legend')" />
            </v-alert>
        </div>
        <EqsDataIterator
            class="auto-height"
            el-class="projects-list"
            :add="
                !$universe.isNeotrees() && !crowdfunding
                    ? {
                          txt: $t('projecs.dashboard.offerings.import-project'),
                          fn: () => (importDialog = true)
                      }
                    : null
            "
            :no-data-custom="{
                title: $t('projecs.dashboard.offerings.projects-no-data-title'),
                text: crowdfunding
                    ? $t('projecs.dashboard.offerings.projects-no-data-txt-crowd')
                    : $t('projecs.dashboard.offerings.projects-no-data-txt'),
                img: $universe.image('/images/projects/projects_empty.svg')
            }"
            v-bind="{
                items: currentOfferings,
                loading,
                ...dataIteratorProps
            }"
        >
            <template #header>
                <v-col cols="auto">
                    <EqsTextDisplay
                        v-if="currentOfferings.length"
                        :value="$t('projecs.dashboard.offerings.title-ongoing')"
                        v-bind="{ eqsType: 'title', loading }"
                    />
                </v-col>
            </template>
            <template #item="{ item, index }">
                <OfferCard
                    v-bind="{
                        item,
                        loading,
                        height: offerCardHeight,
                        elClass: `project-${index}`
                    }"
                    @open="() => openOffer(item)"
                />
            </template>
        </EqsDataIterator>
        <EqsDataIterator
            v-if="commingOfferings?.length !== 0"
            class="auto-height"
            el-class="projects-list"
            v-bind="{
                items: commingOfferings,
                loading,
                ...dataIteratorProps
            }"
        >
            <template #header>
                <v-col cols="auto">
                    <EqsTextDisplay
                        v-if="commingOfferings.length"
                        :value="$t('dashboard.projects-tocome')"
                        v-bind="{ eqsType: 'title', loading }"
                    />
                </v-col>
            </template>
            <template #item="{ item, index }">
                <OfferCard
                    v-bind="{
                        item,
                        loading,
                        height: offerCardHeight,
                        elClass: `project-${index}`
                    }"
                    @open="() => openOffer(item)"
                />
            </template>
        </EqsDataIterator>
        <v-slide-y-transition>
            <EqsDataIterator
                v-if="fundedOfferings && fundedOfferings.length"
                class="auto-height"
                el-class="projects-list-funded"
                v-bind="{
                    items: fundedOfferings,
                    loading,
                    ...dataIteratorProps
                }"
            >
                <template #header>
                    <v-col :cols="12">
                        <EqsTextDisplay
                            :value="$t('projecs.dashboard.offerings.title-financed')"
                            v-bind="{ eqsType: 'title' }"
                        />
                    </v-col>
                </template>
                <template #item="{ item, index }">
                    <OfferCard
                        v-bind="{
                            item,
                            loading,
                            height: offerCardHeight,
                            elClass: `project-${index}`
                        }"
                        @open="() => openOffer(item)"
                    />
                </template>
            </EqsDataIterator>
        </v-slide-y-transition>
        <CrowdfundingFooter v-if="crowdfunding" class="mt-6" />
        <ImportDialog v-if="!crowdfunding" :value.sync="importDialog" v-bind="{ fetchOffering }" />
        <RiskAversionDialog v-if="crowdfunding" :value.sync="aversionDialog" v-bind="{ loading }" />
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { getFrontUrl } from "@/util";

import OfferCard from "@/components/molecules/card/OfferCard";
import ImportDialog from "./ImportDialog";
import RiskAversionDialog from "./RiskAversionDialog";
import { isCapincEnded, isCapincToCome } from "../utils.js";
import CrowdfundingFooter from "./CrowdfundingFooter.vue";

export default {
    name: "OfferingsDashboard",
    components: {
        OfferCard,
        ImportDialog,
        RiskAversionDialog,
        CrowdfundingFooter
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        crowdfunding: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            universe: this.$store.getters.universe_id,
            importDialog: false,
            aversionDialog: false,
            currentOfferings: [],
            commingOfferings: [],
            fundedOfferings: [],
            dataIteratorProps: {
                itemsPerPage: -1,
                itemCols: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                    xl: 3
                },
                eqsType: ["default"],
                eqsDataType: "default",
                hideDefaultFooter: true
            }
        };
    },
    computed: {
        ...mapGetters(["projects", "projectsLoading", "projectsCipLoading"]),
        offerCardHeight() {
            if (this.$universe.isKyoseil()) {
                return this.$reactive.getVal({
                    xs: "15rem",
                    sm: "15rem",
                    md: "15rem",
                    lg: "15rem",
                    xl: "15rem"
                });
            } else {
                return this.$reactive.getVal({
                    xs: "22rem",
                    sm: "22rem",
                    md: "22rem",
                    lg: "22rem",
                    xl: "22rem"
                });
            }
        }
    },
    watch: {
        "$store.getters.projects": {
            handler(val) {
                this.updateProjectList(val);
            },
            immediate: true
        },
        $route: {
            handler(to, from) {
                this.updateProjectList(this.$store.getters.projects);
            }
        }
    },
    methods: {
        ...mapActions(["addProject"]),
        updateProjectList(projectsList) {
            if (projectsList && projectsList.length) {
                let currentOfferings = [];
                let fundedOfferings = [];
                let commingOfferings = [];
                for (const offer of projectsList) {
                    if (isCapincEnded(offer)) {
                        fundedOfferings.push(offer);
                    } else if (isCapincToCome(offer)) {
                        commingOfferings.push(offer);
                    } else {
                        currentOfferings.push(offer);
                    }
                }
                this.currentOfferings = currentOfferings.filter(v => v.cip === this.crowdfunding);
                this.fundedOfferings = fundedOfferings.filter(v => v.cip === this.crowdfunding);
                this.commingOfferings = commingOfferings.filter(v => v.cip === this.crowdfunding);
            }
        },
        openPublic() {
            let url = getFrontUrl() + "crowdfunding";
            window.open(url, "_blank");
        },
        openOffer(item) {
            if (item.cip) {
                if (!this.$entity.isPsfp()) {
                    this.$router.push({
                        name: "risk-aversion",
                        params: this.$route.params,
                        query: { projectId: item.id }
                    });
                } else {
                    this.$router.push({
                        name: "corner-project-detail",
                        params: { projectId: item.id }
                    });
                }
            } else {
                this.$router.push({
                    name: "project-detail",
                    params: { projectId: item.id }
                });
            }
        },
        async fetchOffering(id) {
            if (this.$store.getters.projects.find(offering => offering.id === id))
                return "ALREADY_IMPORTED";
            try {
                await this.$api.importCapitalIncrease({
                    capinc_id: id
                });
                const project = await this.addProject({ projectId: id, isCip: this.crowdfunding });
                if (project.cip && !this.$entity.isPsfp()) {
                    this.$router.push({
                        name: "risk-aversion",
                        params: this.$route.params,
                        query: { projectId: id }
                    });
                }
            } catch (e) {
                this.$ui.error(e, "capital_increase_user_get");
                throw e;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.offering-empty {
    > .eqs-card__header {
        height: 40px !important;
    }
}

.alert-link {
    :deep(a) {
        color: var(--v-warningLight-base);
    }
}
</style>
