import DEBUG from "@/store/debug.js";
import { isFormation } from "@/assets/constants";

/**
 * Gets entity related information, if server replies with 403 then our
 * cookie isn't valid anymore.
 */
export default async ({ commit, dispatch, state }) => {
    DEBUG("GET_SESSION_START");

    try {
        // get my user account
        await dispatch("getMyEntity");
        // get all the roles of my user
        await dispatch("getMyRoles");
        // set last role used as currentRole and reset store
        await dispatch("cleanImpersonateRoles");
        await dispatch("setMyCurrentRole");

        // TODO remove that when done ticket #1624 quickfix update kyc status when loading and changing entity in formation and prod
        if (isFormation() && window.app.$entity.represented().lemonway_state !== "success")
            await window.app.$entity.updateLemonwayState();
        //
        commit("SET_IS_AUTHENTICATED", true);
    } catch (error) {
        // show error only if it's not an auto login that fail because session doesn't exist
        if (error && error.status === 403) {
            throw Error("auto-auth-failed");
        } else {
            DEBUG("GET_SESSION_ERROR");
            window.app.$ui.error(error, "get_my_entity");
            throw error;
        }
    }
};
