<template>
    <div
        class="d-flex flex-column fill-height fill-width overflow-y no-overflow-x"
        :el-class="`verify-kyc-${status}`"
    >
        <div v-if="getData.video" class="d-flex flex-column fill-height" style="padding: 10px">
            <EqsTextDisplay
                paragraph
                :value="getData.msg"
                class="semi-bold margin-auto text-center pa-3 max-height-fit"
            />
            <iframe
                style="
                    border-radius: 1.625rem;
                    max-width: 700px;
                    margin: auto;
                    height: 400px;
                    box-shadow: 2px 1px 11px rgb(0 0 0 / 50%);
                "
                width="100%"
                height="auto"
                :src="getData.video"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
            />
        </div>
        <EqsWindows
            v-else-if="!getData.continue"
            :value.sync="step"
            :cycle="false"
            v-bind="{ items: verifyData.steps }"
        >
            <template #top>
                <div class="eqs-main-container margin-auto">
                    <v-col
                        justify="center"
                        align="center"
                        class="d-flex justify-center align-center"
                    >
                        <EqsTextDisplay
                            paragraph
                            :value="getData.msg"
                            v-bind="{ loading: loading || localLoading }"
                            class="semi-bold margin-auto"
                        />
                    </v-col>
                </div>
            </template>
            <template #default="{ item }">
                <div
                    class="eqs-main-container margin-auto d-flex flex-column"
                    style="height: inherit"
                >
                    <EqsImage
                        contain
                        class="d-flex"
                        style="height: 80%"
                        v-bind="{ src: item.img, loading: loading || localLoading }"
                    />
                    <div class="d-flex text-center max-height-fit">
                        <EqsTextDisplay
                            paragraph
                            :value="item.txt"
                            v-bind="{ loading: loading || localLoading }"
                            class="semi-bold margin-auto mt-3"
                        />
                    </div>
                    <v-layout column justify-end align-center>
                        <v-flex v-if="item.actionBtn" class="max-height-fit" mb-3>
                            <EqsBtn
                                color="primary"
                                :action="item.actionBtn"
                                v-bind="{ loading: loading || localLoading }"
                            >
                                {{ item.txtBtn }}
                            </EqsBtn>
                        </v-flex>
                    </v-layout>
                </div>
            </template>
        </EqsWindows>

        <div v-else class="eqs-main-container margin-auto">
            <EqsViewWithImage
                class="mx-3"
                v-bind="{ img: $universe.image('/images/registration-verify.svg') }"
            >
                <v-row class="px-3">
                    <v-col :cols="12">
                        <EqsTextDisplay
                            paragraph
                            :value="getData.msg"
                            v-bind="{ loading: loading || localLoading }"
                            class="semi-bold margin-auto"
                        />
                    </v-col>
                </v-row>
                <v-row v-if="formatedErrors" class="px-3">
                    <v-col v-for="(error, index) in formatedErrors" :key="index" :cols="12">
                        <EqsAlert
                            v-bind="{
                                eqsType: 'error',
                                text: error,
                                loading: loading || localLoading
                            }"
                        />
                    </v-col>
                </v-row>
                <v-row v-if="getData.continue.action" class="px-3">
                    <v-col>
                        <EqsBtn
                            el-class="verify-continue-btn"
                            color="primary"
                            :action="getData.continue.action"
                            v-bind="{ loading: loading || localLoading }"
                        >
                            {{ getData.continue.txt }}
                        </EqsBtn>
                    </v-col>
                </v-row>
            </EqsViewWithImage>
        </div>
    </div>
</template>

<script>
import { KYC_PROCESSING_TIMEOUT, KYC_INTERVAL, PRODUCTION_ENVIRONMENT } from "@/assets/constants";
import { getLabelFromCategory } from "@/util/documents";
import { lemonwayStatusItem, lemonwayStatusError } from "@equisafe-ui-vue/src/globals/lemonway.js";
const verifyRegistrationPoller = "verify-registration";

export default {
    name: "VerifyKyc",
    props: {
        kycType: {
            type: String,
            default: ""
        },
        verifyData: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            kycResult: null,
            status: "",
            step: 0,
            lastIdVerified: null,
            formatedErrors: null,
            localLoading: true
        };
    },
    computed: {
        getData() {
            return this.status ? this.verifyData[this.status] : {};
        }
    },
    watch: {
        "$route.params.id": {
            immediate: true,
            async handler(entityId) {
                this.status = "pending";
                if (entityId) await this.getEntityRegistrationState(entityId);
            }
        }
    },
    beforeDestroy() {
        this.$poll.stop(verifyRegistrationPoller);
    },
    methods: {
        async getEntityVerificationResult(entityId) {
            let result = null;
            try {
                result = await this.$api.getEntityVerificationResult(entityId);
                // await this.$role.updateMyCurrentRole(entityId);
            } catch (e) {
                throw e;
            } finally {
                return {
                    result,
                    entityId
                };
            }
        },
        checkRegistrationState(verif) {
            if (this.localLoading) this.localLoading = false;
            return (
                verif.result && verif.result.data && verif.result.data.lemonway_state !== "pending"
            );
        },
        async verifyKyc(entityId) {
            try {
                const res = await this.$poll.asyncCall(
                    verifyRegistrationPoller,
                    this.getEntityVerificationResult,
                    entityId,
                    verif => this.checkRegistrationState(verif),
                    KYC_INTERVAL,
                    KYC_PROCESSING_TIMEOUT
                );
                return res;
            } catch (e) {
                if (!PRODUCTION_ENVIRONMENT)
                    this.$ui.error(this.$t("ui-error.registration-processing-timeout"));
                throw e;
            }
        },
        async verifyKyb(entityId) {
            let result = null;
            try {
                result = await this.$api.getEntityVerificationResult(entityId);
            } catch (e) {
                throw e;
            } finally {
                this.localLoading = false;
                return result.data.lemonway_state !== "pending"
                    ? {
                          result,
                          entityId
                      }
                    : null;
            }
        },
        getFormatedErrors(kycRes) {
            let formatedErrors = [];
            if (kycRes.lemonway_reason) {
                formatedErrors.push(
                    `${this.$t("rules.lemonway-reason")}: ${kycRes.lemonway_reason}`
                );
            }
            const getDocsErrors = kycRes.docs
                .filter(doc => {
                    const res = lemonwayStatusItem(this, doc.lemonway_status, doc.lemonway_reason);
                    return res.status === "error";
                })
                .map(errDoc => {
                    const lwError = lemonwayStatusError(
                        this,
                        errDoc.lemonway_status,
                        [errDoc.lemonway_reason, errDoc.comment].filter(msg => !!msg).join(" - ")
                    );
                    const res = `${this.$t("label.error-for")} '${getLabelFromCategory(
                        errDoc.category,
                        errDoc.lemonway_type
                    )}' :\n${lwError}`;
                    return res;
                });
            if (getDocsErrors && getDocsErrors.length) {
                formatedErrors.push(...getDocsErrors);
            }
            return formatedErrors;
        },
        async getEntityRegistrationState(entityId) {
            let registrationRes = null;
            const verifyFn =
                this.$entity.represented().is_company && this.$dev.isProd()
                    ? this.verifyKyb
                    : this.verifyKyc;
            registrationRes = await verifyFn(entityId);
            if (registrationRes) {
                try {
                    // update role to have entity with right status when status changed from pending
                    await this.$role.updateMyCurrentRole(registrationRes.entityId);
                    const formatedErrors = this.getFormatedErrors(registrationRes.result.data);
                    if (!!formatedErrors && formatedErrors.length) {
                        this.status = "error";
                        this.formatedErrors = formatedErrors;
                    } else {
                        this.status =
                            registrationRes.result.data.lemonway_state === "success"
                                ? "success"
                                : "pending";
                    }
                } catch (e) {
                    console.error({ e });
                }
            }
        }
    }
};
</script>
