<template>
    <EqsStepper class="fill-height" height="100%" :value.sync="step" :steps="steps">
        <template #default="{ item }">
            <component
                :is="item.is"
                :payment="payment"
                :files="files"
                :loading="loading || localLoading || balanceLoading"
                :accept="accept"
                :refuse="refuse"
                v-bind="{ state: payment.lemonway_status || '' }"
            />
        </template>
    </EqsStepper>
</template>

<script>
import AcceptPayment from "./AcceptPayment";
import PaymentDone from "./PaymentDone";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "AskedPayment",
    components: {
        AcceptPayment,
        PaymentDone
    },
    props: {
        id: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            payment: {},
            files: [],
            step: 0,
            localLoading: false
        };
    },
    computed: {
        ...mapGetters(["balance", "balanceLoading"]),
        steps() {
            return [
                {
                    title: this.$t("payments.asked.accept.title"),
                    item: {
                        is: "AcceptPayment"
                    },
                    noNext: true
                },
                {
                    title: this.$t("payments.asked.done.title"),
                    item: {
                        is: "PaymentDone"
                    },
                    noBack: true,

                    next: {
                        fn: () =>
                            this.$router
                                .push({
                                    name: "wallet"
                                })
                                .catch(() => {}),
                        txt: this.$t("payments.asked.done.btn")
                    }
                }
            ];
        }
    },
    watch: {
        id: {
            immediate: true,
            async handler() {
                await this.fetchData();
            }
        }
    },
    created() {
        this.getBalance();
    },
    methods: {
        ...mapActions(["getBalance"]),
        async fetchData() {
            try {
                this.localLoading = true;
                const res = await this.$api.getTransfer(this.id);
                this.payment = res.data;
                const f = await this.$api.getTransferDocs(this.id);
                this.files = f.data;
            } catch (e) {
                this.$ui.error(e, "asked_payment_get_transfer");
            } finally {
                this.localLoading = false;
            }
        },
        goToLastStep() {
            this.step = this.steps.length - 1;
        },

        async accept() {
            try {
                if (this.balance < this.payment.amount) {
                    this.$dialog.confirm({
                        title: this.$t("payments.asked.moneyin.title"),
                        text: this.$t("payments.asked.moneyin.msg"),
                        closable: true,
                        confirm: {
                            fn: () =>
                                this.$router.push({
                                    name: "money-in",
                                    params: {
                                        entity: this.$entity.represented().id,
                                        id: "init"
                                    },
                                    query: { route_redirection: this.$route.path }
                                }),
                            txt: this.$t("btn.continue")
                        }
                    });
                } else {
                    const res = await this.$api.acceptTransfer(this.id);
                    this.payment = res.data;
                    this.goToLastStep();
                }
            } catch (e) {
                this.$ui.error(e, "asked_payment_accept");
            }
        },
        async refuse() {
            try {
                const res = await this.$api.refuseTransfer(this.id);
                this.payment = res.data;
                this.goToLastStep();
            } catch (e) {
                this.$ui.error(e, "asked_payment_refuse");
            }
        }
    }
};
</script>
