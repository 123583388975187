var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('EqsDataTable',_vm._b({attrs:{"add":{
            txt: _vm.$t('secondary-market.add-order'),
            fn: () => (_vm.importDialog = true)
        }},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('EqsAvatar',_vm._b({staticClass:"pr-3"},'EqsAvatar',{
                        user: item,
                        eqsType: 'list',
                        noBadge: true
                    },false)),_c('div',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.marketValue",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency(item.min, "EUR"))+" "+_vm._s(_vm.$t("movements.headers.to"))+" "+_vm._s(_vm.$currency.displayWithCurrency(item.max, "EUR"))+" ")])]}},{key:"item.actions",fn:function({ item, index }){return [_c('EqsBtn',_vm._b({attrs:{"small":"","text":"","el-class":`see-offer-details-${index}`},on:{"click":() => {
                        _vm.$router
                            .push({
                                name: 'secondary-market-details',
                                params: {
                                    id: item.id,
                                    company: item
                                }
                            })
                            .catch(() => {});
                    }}},'EqsBtn',{
                    infoTxt: _vm.$t('secondary-market.buy-shares.action-btn-tooltip')
                },false),[_c('v-icon',[_vm._v("mdi-text-box-outline")])],1)]}}])},'EqsDataTable',{
            headers: _vm.headers,
            searchable: {
                placeholder: _vm.$t('secondary-market.search-businesses')
            },
            loading: _vm.localLoading,
            items: _vm.allCompany
        },false)),_c('ImportDialog',{attrs:{"value":_vm.importDialog},on:{"update:value":function($event){_vm.importDialog=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }