var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`eqs-list ${_vm.elClass}-container`,style:(_vm.getStyle)},[_c('EqsLabel',_vm._b({},'EqsLabel',{
            height: _vm.labelHeight,
            editable: _vm.editable,
            value: _vm.label,
            infoTxt: _vm.infoTxt
        },false),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),_c('EqsEditable',_vm._b({},'EqsEditable',{ editable: true, value: '' },false),[_c('v-input',_vm._b({attrs:{"dense":"","hide-details":"auto"}},'v-input',{ ..._vm.$attrs, value: _vm.items },false),[_c('v-list',_vm._g(_vm._b({staticClass:"pa-0"},'v-list',{ ..._vm.$attrs, disabled: _vm.disabled, group: _vm.group },false),_vm.$listeners),[(_vm.editable)?[_vm._t("add",null,null,{ addItem: _vm.addItem })]:_vm._e(),_c('v-card',{attrs:{"tile":"","elevation":"0"}},[_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[_c('EqsSkeleton',_vm._b({},'EqsSkeleton',{ loading: _vm.loading, type: _vm.getData.skeleton },false),[(!item.items && !item.group && !_vm.group)?_c('div',[_c('v-list-item',{style:(`background-color:${item.bgColor}`),attrs:{"color":item.color || _vm.$color.colors.eqsBlue,"active-class":"eqs-list__item-active","el-class":`${_vm.elClass}-item-${index}`}},[(item.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.iconColor}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1):_vm._e(),(
                                                !!_vm.$scopedSlots['item-content'] ||
                                                item.title ||
                                                item.subtitle
                                            )?_c('v-list-item-content',[(item.title)?_c('v-list-item-title',[(!_vm.$reactive.isMobile())?_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]):_c('EqsTextHoverTooltip',{attrs:{"text":item.title}})],1):_vm._e(),(item.subtitle)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.subtitle)+" ")]):_vm._e(),_vm._t("item-content",null,null,{ item, index })],2):_vm._e(),_vm._t("item",null,null,{ item, index, removeItem: _vm.removeItem })],2),_vm._t("item-append",null,null,{ item, index, removeItem: _vm.removeItem })],2):_c('v-list-group',{attrs:{"no-action":"","el-class":`${_vm.elClass}-item-${index}`,"color":item.color || _vm.$color.colors.eqsBlue},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(item.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":item.iconColor}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1):_vm._e(),_c('v-list-item-content',[(item.title)?_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),_vm._t("group-tile",null,null,{ item, index })],2)]},proxy:true}],null,true)},[(item.group || _vm.group)?_c('div',[_vm._t("group-content",null,null,{
                                                item,
                                                index,
                                                removeItem: _vm.removeItem,
                                                updateItem: _vm.updateItem
                                            })],2):_c('div',_vm._l((_vm.items),function(subItem,subIndex){return _c('div',{key:subIndex},[_c('v-list-item',{style:(`background-color:${item.bgColor}`),attrs:{"link":!!item.to || !item.href,"color":subItem.color || _vm.$color.colors.eqsBlue,"active-class":"eqs-list__item-active"}},[(subItem.icon)?_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(subItem.icon)+" ")])],1):_vm._e(),_c('v-list-item-content',[(subItem.title)?_c('v-list-item-title',[_c('EqsTextHoverTooltip',{attrs:{"text":subItem.title}})],1):_vm._e()],1)],1)],1)}),0)]),(_vm.border && index + 1 < _vm.items.length)?_c('v-divider',{key:index}):_vm._e()],1)],1)}),(_vm.noDataSkeleton && _vm.noData)?_c('EqsSkeleton',_vm._b({attrs:{"type":_vm.getData.skeletonNoData}},'EqsSkeleton',{ loading: _vm.loading },false)):_vm._e()],2)],2)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }