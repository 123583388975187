<template>
    <v-container fluid>
        <AgList v-show="isRoot" v-bind="{ id, loading: agLoading }" @ag-selected="updateAg" />
        <router-view
            v-show="!isRoot"
            v-bind="{
                item: agSelected
            }"
        />
    </v-container>
</template>

<script>
import AgList from "./AgList/AgList";

export default {
    name: "AgDashboard",
    components: { AgList },
    props: {
        id: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            agSelected: null,
            agLoading: true
        };
    },
    computed: {
        isRoot() {
            return this.$route.name === "ag";
        }
    },
    methods: {
        updateAg(v) {
            this.agSelected = v;
            this.$router.push({
                name: "ag-detail",
                params: { agId: this.agSelected.id }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
