<template>
    <EqsTextDisplay>
        <span v-html="$t('user.profile.current-profile.delete-user', { universeMail, universe })" />
    </EqsTextDisplay>
</template>

<script>
export default {
    name: "DeleteUser",
    computed: {
        universe() {
            return this.$store.getters.universe.public_name;
        },
        universeMail() {
            return this.$store.getters.universe.contact_email;
        }
    }
};
</script>
