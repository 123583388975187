export const limitsSchema = ctx => {
    let res = [
        {
            titleAbove: ctx.$t("user.wallet.limits.money-in-title")
        },
        {
            children: [
                {
                    label: ctx.$t("user.wallet.limits.daily-money"),
                    name: "moneyinDailyMoney",
                    "el-class": "moneyinDailyMoney",
                    is: "EqsNumber"
                },
                {
                    label: ctx.$t("user.wallet.limits.daily-number"),
                    name: "moneyinDailyNumberOf",
                    "el-class": "moneyinDailyNumberOf",
                    class: "pr-3 pb-3",
                    labelHeight: "32px",
                    is: "EqsText"
                }
            ]
        },
        {
            children: [
                {
                    label: ctx.$t("user.wallet.limits.monthly-money"),
                    name: "moneyinMonthlyMoney",
                    "el-class": "moneyinMonthlyMoney",
                    is: "EqsNumber"
                },
                {
                    label: ctx.$t("user.wallet.limits.annually-money"),
                    name: "moneyinAnnuallyMoney",
                    "el-class": "moneyinAnnuallyMoney",
                    is: "EqsNumber"
                }
            ]
        },
        {
            titleAbove: ctx.$t("user.wallet.limits.money-out-title")
        },
        {
            children: [
                {
                    label: ctx.$t("user.wallet.limits.daily-money"),
                    name: "moneyoutDailyMoney",
                    "el-class": "moneyoutDailyMoney",
                    is: "EqsNumber"
                },
                {
                    label: ctx.$t("user.wallet.limits.daily-number"),
                    name: "moneyoutDailyNumberOf",
                    "el-class": "moneyoutDailyNumberOf",
                    class: "pr-3 pb-3",
                    labelHeight: "32px",
                    is: "EqsText"
                }
            ]
        },
        {
            children: [
                {
                    label: ctx.$t("user.wallet.limits.monthly-money"),
                    name: "moneyoutMonthlyMoney",
                    "el-class": "moneyoutMonthlyMoney",
                    is: "EqsNumber"
                },
                {
                    label: ctx.$t("user.wallet.limits.annually-money"),
                    name: "moneyoutAnnuallyMoney",
                    "el-class": "moneyoutAnnuallyMoney",
                    is: "EqsNumber"
                }
            ]
        }
    ];
    return res;
};

export const limitsValues = {
    moneyinDailyMoney: 250000,
    moneyinDailyNumberOf: 50,
    moneyinMonthlyMoney: 2500000,
    moneyinAnnuallyMoney: 25000000,
    moneyoutDailyMoney: 250000,
    moneyoutDailyNumberOf: 10,
    moneyoutMonthlyMoney: 2500000,
    moneyoutAnnuallyMoney: 25000000
};
