<template>
    <EqsDataTable hide-default-footer v-bind="{ itemKey, items, headers, column: false, loading }">
        <template #item.name="{ item }">
            <span :el-class="`ownership-${item.name.split(' ')[0]}`">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <GlobalCopyBadge
                    v-model="item.entity.ethaddress"
                    class="text-sm lightgrey--text"
                    color="darkgrey"
                    :truncate="20"
                />
            </span>
        </template>
        <template #item.email="{ item }">
            {{ item.entity.email }}
        </template>
        <template #item.numberofshares="{ item }">
            <span :el-class="`ownership-${item.name.split(' ')[0]}-shares`">
                {{ $currency.display(item.balance, "0,0") }}
            </span>
        </template>
    </EqsDataTable>
</template>

<script>
import GlobalCopyBadge from "@/components/molecules/badge/GlobalCopyBadge";
export default {
    name: "ShareledgerDetailsOwnership",
    components: { GlobalCopyBadge },
    props: {
        items: {
            type: Array,
            required: true
        },
        headers: {
            type: Array,
            required: true
        },
        itemKey: {
            type: String,
            default: "id"
        },
        loading: {
            type: Boolean,
            required: true
        }
    }
};
</script>
