<template>
    <transition name="eqs-slide-from-right" class="d-flex fill-height fill-width">
        <EqsTabs
            v-if="isRoot()"
            class="project-dashboard"
            :value.sync="tab"
            v-bind="{ tabs, color: 'transparent' }"
        >
            <template #default="{ item }">
                <component
                    :is="item.is"
                    v-if="item.is"
                    v-bind="{
                        loading: loading || projectsLoading || projectsCipLoading,
                        crowdfunding
                    }"
                />
            </template>
        </EqsTabs>
        <router-view
            v-else
            :key="$route.fullPath"
            v-bind="{
                loading: loading || projectsLoading || projectsCipLoading
            }"
            @close="closeDialog"
        />
    </transition>
</template>

<script>
import OfferingsDashboard from "./OfferingsDashboard";
import OrdersDashboard from "./OrdersDashboard";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ProjectDashboard",
    components: {
        OfferingsDashboard,
        OrdersDashboard
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            loadingOrders: true,
            pendingOrdersLength: null,
            tab: "offerings"
        };
    },
    computed: {
        ...mapGetters(["projectsLoading", "projectsCipLoading"]),
        crowdfunding() {
            return this.$route.name === "corner-detail";
        },
        tabs() {
            let orders = {
                id: "orders",
                title: this.$t("transaction.orders"),
                item: {
                    is: "OrdersDashboard"
                }
            };
            if (this.pendingOrdersLength) {
                orders.badge = {
                    content: this.pendingOrdersLength,
                    color: "warningLight"
                };
            }
            return [
                {
                    id: "offerings",
                    title: this.$t("routes.offerings"),
                    item: {
                        is: "OfferingsDashboard"
                    }
                },
                orders
            ];
        }
    },
    watch: {
        $route: {
            async handler(to, from) {
                if (this.crowdfunding) {
                    await this.getProjectsCip();
                } else {
                    await this.getProjects();
                }
                this.getPendingOrdersLength();
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions(["getProjects", "getProjectsCip"]),
        async getPendingOrdersLength() {
            const res = await this.$api.getPendingOrdersLength({
                cip: this.crowdfunding
            });
            this.pendingOrdersLength = res.data.pending_orders_length;
        },
        isRoot() {
            return this.$route.name === "projects" || this.$route.name === "corner-detail";
        },
        closeDialog() {
            this.$router.push({
                name: this.crowdfunding ? "corner-detail" : "projects",
                params: { id: this.$router.params.id }
            });
        }
    }
};
</script>

<style lang="scss">
.project-dashboard {
    .v-badge__wrapper {
        > span {
            color: var(--v-text-base) !important;
        }
    }
}
</style>
