import { Style } from "./Style";
import { Position, positionComponent } from "./Position";
import { worksheetEqsLogo } from "./eqsIcon";

export class Xls extends Position {
    constructor(_ws, position) {
        super(position);
        this.ws = _ws;
    }

    merge(area) {
        if (!area) {
            this.ws.mergeCells(this._tmpPosition);
        } else {
            this.ws.mergeCells(area);
        }
        return this;
    }

    cellFactory(value, style, width) {
        return {
            value,
            style,
            width
        };
    }

    componentTitle(titleName) {
        this.hardApply(
            this.cellFactory(titleName, Style.titleWs),
            this.areaToPoint(positionComponent.titleWs)[0]
        ).merge(positionComponent.titleWs);
        return this;
    }

    componentLogo(wb) {
        worksheetEqsLogo(wb, this.ws);
        return this;
    }

    hardApply(cell, position) {
        Object.assign(this.ws.getCell(position), cell);
        return this;
    }

    apply(cell, iCol = 0, iRow = 0) {
        const selectedCell = this.ws.getCell(this.toString(iCol, iRow));
        Object.assign(selectedCell, cell);
        if (cell.width) this.ws.getColumn(this._col + iCol).width = cell.width;
        return this;
    }
}
