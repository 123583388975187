var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column eqs-medium-container"},[_c('EqsTextDisplay',_vm._b({staticClass:"py-3"},'EqsTextDisplay',{
            eqsType: 'subtitle',
            value: _vm.$t('entity.custodian.representative-title')
        },false)),_c('v-row',{staticClass:"fill-height px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('lg')}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.first_name},on:{"update:value":function($event){return _vm.$set(_vm.formData, "first_name", $event)}}},'EqsText',{
                    elClass: 'first_name',
                    label: this.$t('entity.custodian.first-name'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.first_name
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('lg')}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.last_name},on:{"update:value":function($event){return _vm.$set(_vm.formData, "last_name", $event)}}},'EqsText',{
                    elClass: 'last_name',
                    label: this.$t('entity.custodian.last-name'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.last_name
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('lg')}},[_c('EqsEmail',_vm._b({attrs:{"value":_vm.formData.email},on:{"update:value":function($event){return _vm.$set(_vm.formData, "email", $event)}}},'EqsEmail',{
                    elClass: 'email',
                    isRequired: false,
                    label: _vm.$t('entity.custodian.email'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.email
                },false))],1)],1),_c('v-row',{staticClass:"fill-height px-3"},[_c('EqsTextDisplay',_vm._b({staticClass:"py-3"},'EqsTextDisplay',{
                eqsType: 'subtitle',
                value: _vm.$t('entity.custodian.company-title')
            },false)),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('xl')}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.company_name},on:{"update:value":function($event){return _vm.$set(_vm.formData, "company_name", $event)}}},'EqsText',{
                    elClass: 'company_name',
                    label: this.$t('rules.company-name'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.company_name
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('xl')}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.company_idcode},on:{"update:value":function($event){return _vm.$set(_vm.formData, "company_idcode", $event)}}},'EqsText',{
                    elClass: 'company_idcode',
                    label: this.$t('registration.company.company-identificiation'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    rules: [_vm.companyIdcodeR],
                    ..._vm.schemaDouble.company_idcode
                },false))],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }