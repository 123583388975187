<template>
    <v-container fluid>
        <h2 class="text-h5 mb-8">Votre questionnaire est validé !</h2>
        <v-row justify="center">
            <v-spacer />
            <v-col class="text-center">
                <v-icon color="success" class="mb-8" size="128"> mdi-check-circle-outline </v-icon>
            </v-col>
            <v-spacer />
        </v-row>
        <p>
            Félicitations ! Vous avez réussi votre test d'avertissement aux risques dans notre
            application de financement participatif.
        </p>
        <p>
            Vous pouvez désormais investir dans les produits financiers disponibles sur notre
            plateforme.
        </p>
        <p>
            N'hésitez pas à nous contacter si vous avez des questions ou besoin d'assistance. Bonne
            chance dans vos investissements !
        </p>
    </v-container>
</template>

<script>
export default {
    name: "EndPage",
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped></style>
