var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-height-fit fill-height d-flex flex-column overflow-y"},[_c('EqsDataTable',_vm._b({staticClass:"fill-width",attrs:{"selectable":"","single-select":"","selected":_vm.selectedOffer,"items":_vm.mySellOffers},on:{"update:selected":function($event){_vm.selectedOffer=$event}},scopedSlots:_vm._u([{key:"item.company",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('EqsAvatar',_vm._b({staticClass:"pr-3"},'EqsAvatar',{
                        user: item.shareledger.company,
                        eqsType: 'list',
                        noBadge: true
                    },false)),_vm._v(" "+_vm._s(item.shareledger.company.name)+" ")],1)]}},{key:"item.shareledger.name",fn:function({ value, index }){return [_c('div',{attrs:{"el-class":`sell-offer-shareledger-name-${index}`}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.initial_quantity",fn:function({ value, index }){return [_c('div',{attrs:{"el-class":`sell-offer-initial_quantity-${index}`}},[_vm._v(" "+_vm._s(_vm.$currency.display(value, "0,0"))+" ")])]}},{key:"item.quantity",fn:function({ value, index }){return [_c('div',{attrs:{"el-class":`sell-offer-quantity-${index}`}},[_vm._v(" "+_vm._s(_vm.$currency.display(value, "0,0"))+" ")])]}},{key:"item.price",fn:function({ item, index }){return [_c('div',{attrs:{"el-class":`sell-offer-price-${index}`}},[_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency(item.price, item.price_currency))+" ")])]}},{key:"item.total",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency( item.quantity * item.price, item.price_currency ))+" ")])]}},{key:"item.progress",fn:function({ item }){return [_c('EqsCircularProgress',{attrs:{"value":_vm.getProgress(item)}})]}},{key:"item.status",fn:function({ item }){return [_c('EqsChip',_vm._b({},'EqsChip',_vm.sell_order_statutes[item.status],false),[_vm._v(" "+_vm._s(_vm.sell_order_statutes[item.status].txt)+" ")])]}},{key:"item.action",fn:function({ item }){return [_c('EqsActions',{attrs:{"actions":_vm.getActions(item, 'stop')}})]}}])},'EqsDataTable',{
            headers: _vm.sellOffersHeaders,
            loading: _vm.localLoading || _vm.buyOfferLoading,
            noDataCustom: {
                title: _vm.$t('secondary-market.follow-sells.sells-offer-table.no-data-title'),
                text: _vm.$t('secondary-market.follow-sells.sells-offer-table.no-data-text'),
                img: '/images/secondaryMarket/sell_offer.svg'
            }
        },false)),(_vm.buyOffer.length)?_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":_vm.$t('secondary-market.follow-sells.purchase-offer-table-subtitle')}}):_vm._e(),(_vm.buyOffer.length)?_c('EqsDataTable',{staticClass:"fill-width",attrs:{"headers":_vm.buyOffersHeaders,"loading":_vm.buyOfferLoading,"items":_vm.buyOffer},scopedSlots:_vm._u([{key:"item.buyer.name",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('EqsAvatar',_vm._b({staticClass:"pr-3"},'EqsAvatar',{
                        user: item.buyer,
                        eqsType: 'list',
                        noBadge: true
                    },false)),_vm._v(" "+_vm._s(item.buyer.name)+" ")],1)]}},{key:"item.total",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency( item.quantity * _vm.selectedOffer[0].price, _vm.selectedOffer[0].price_currency ))+" ")]}},{key:"item.signature_procedure.status_display",fn:function({ item }){return [_c('EqsChip',_vm._b({},'EqsChip',_vm.buy_order_statutes[item.status],false),[_vm._v(" "+_vm._s(_vm.buy_order_statutes[item.status].txt)+" ")])]}},{key:"item.action",fn:function({ item }){return [_c('EqsActions',{attrs:{"actions":_vm.getActions(item, 'sign')}})]}}],null,false,918725083)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }