<template>
    <EqsCard
        :title="investor.name"
        :loading="loading"
        closable
        height="100%"
        @close="() => $emit('close')"
    >
        <EqsPanels v-if="!loading" class="fill-height overflow-y" :value="0" :panels="panels">
            <template #default="{ item }">
                <component
                    :is="item.is"
                    v-if="item.is != 'EqsBar'"
                    profile
                    v-bind="{
                        countries: [],
                        ...item.props,
                        loading: localLoading,
                        schemaDouble: {},
                        formData: investor.entity ? investor.entity : investor
                    }"
                />
            </template>
        </EqsPanels>
    </EqsCard>
</template>

<script>
import {
    // globalKycMixin,
    entityDetailsMixin
} from "@/mixins/kycMixin";
import Payments from "@/components/views/portfolio/Payments";
import InvestorDetails from "@/components/views/shareledger/investorWhitelist/InvestorDetails";
import InvestorHoldings from "@/components/views/shareledger/investorWhitelist/InvestorHoldings";

export default {
    name: "InvestorDetailPanels",
    components: {
        InvestorHoldings,
        Payments,
        InvestorDetails
    },
    mixins: [entityDetailsMixin],
    props: {
        investor: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            investorEntity: {},
            loading: true,
            localLoading: true,
            assets: [],
            dividends: []
        };
    },
    computed: {
        panels() {
            return [
                {
                    title: this.$t("shareledger.white-list.details.investor-infos"),
                    item: this.entityDetailsSchema(
                        this,
                        this.investor.entity ? this.investor.entity : this.investor
                    )
                },
                {
                    title: this.$t("shareledger.white-list.details.investor-holdings"),
                    item: {
                        is: "InvestorHoldings",
                        props: {
                            assets: this.investor.shares
                        }
                    }
                },
                {
                    title: this.$t("shareledger.white-list.details.investor-payments"),
                    item: {
                        is: "Payments",
                        props: {
                            assets: this.investor.payments,
                            getCurrency: this.getCurrency
                        }
                    }
                },
                {
                    title: this.$t("shareledger.white-list.details.investor-details"),
                    item: {
                        is: "InvestorDetails",
                        props: {
                            investor: this.investor
                        }
                    }
                }
            ];
        }
    },
    watch: {
        async company(val) {
            if (val) {
                await this.fetchCompanyInfo();
                await this.initShareledgerData();
            }
        }
    },
    async created() {
        this.loading = true;
        await this.fetchCompanyInfo();
        await this.initShareledgerData();
        this.loading = false;
    },
    methods: {
        async fetchCompanyInfo() {
            try {
                //const res = await this.$api.getEntity(this.investor.entity.id);
                //this.investorEntity = res.data;
            } catch (e) {
                this.$ui.error(e, "my_portfolio_company_details");
            }
        },
        async initShareledgerData() {
            try {
            } catch (e) {
                this.$ui.error(e, "my_portfolio_company_details");
            }
        },
        formatedData(data, dataType) {
            let labels = [];
            let dataSet = {
                label: dataType,
                backgroundColor: "#0D3F67",
                data: []
            };
            data.forEach(element => {
                labels.push(this.convertDate(element.value_datetime));
                dataSet.data.push(parseFloat(element.value_data));
            });
            return {
                labels: labels,
                datasets: [dataSet]
            };
        },
        convertDate(date) {
            return this.$time.formated(date);
        },
        formatDataValue(val, panel) {
            const type = panel.props.type;
            const currency = panel.props.assets[type].value_data_currency;
            if (val) return this.$currency.displayWithCurrency(val, currency);
        },
        getCurrency() {
            return this.$entity.represented().country.alphacode == "US" ? "USD" : "EUR";
        }
    }
};
</script>

<style lang="scss" scoped>
.no-data-text {
    font: Bold 17px/21px Proxima Nova;
    font-size: calc(100% + 0.15vw);
    letter-spacing: 0;
    color: #5a5a5a;
}
</style>
