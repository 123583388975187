<template>
    <v-radio-group
        :value="value"
        class="radio-group"
        column
        :disabled="disabled"
        @change="$emit('update:value', $event)"
        @mouseleave="$emit('update:hovered', '')"
    >
        <div
            v-for="(choice, index) in options"
            :key="index"
            v-ripple
            class="role-select-input"
            :el-class="choice.elClass"
            @mouseover="$emit('update:hovered', choice.value)"
            @click="$emit('select:role_name', choice.value)"
        >
            <v-radio
                :label="choice.label"
                color="primary"
                :value="choice.value"
                :disabled="disabled"
                @change="$emit('select:role_name', choice.value)"
            />
            <label
                class="text-radio"
                for="labelInput"
                style="cursor: pointer"
                @mouseover="$emit('update:hovered', choice.value)"
                >{{ choice.labelDescription }}</label
            >
        </div>
    </v-radio-group>
</template>

<script>
export default {
    props: {
        hovered: {
            // hovered
            type: String,
            default: "admin"
        },
        value: {
            // selected //choice of role
            type: String,
            default: "admin"
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            options: [
                {
                    label: this.$t("crud.admin"),
                    labelDescription: this.$t("crud.admin-description"),
                    value: "admin",
                    elClass: "admin"
                },
                {
                    label: this.$t("crud.accountant"),
                    labelDescription: this.$t("crud.accountant-description"),
                    value: "accountant",
                    elClass: "accountant"
                },
                {
                    label: this.$t("crud.custodian2"),
                    labelDescription: this.$t("crud.custodian-description"),
                    value: "custodian",
                    elClass: "custodian"
                },
                {
                    label: this.$t("crud.notary"),
                    labelDescription: this.$t("crud.notary-description"),
                    value: "notary",
                    elClass: "notary"
                },
                {
                    label: this.$t("crud.lawyer"),
                    labelDescription: this.$t("crud.lawyer-description"),
                    value: "lawyer",
                    elClass: "lawyer"
                }
            ],
            changeValueDisableFoAdmin: false
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("udpate:value", val);
                this.$emit("udpate:value", val);
                this.$emit("change", val);
                this.$emit("update:hovered", val);
            }
        }
    }
};
</script>

<style lang="scss">
.title-choose-user-role {
    font-size: 16px;
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 25px;
}

.role-select-input {
    cursor: pointer;
    margin-bottom: 10px;
    &:hover {
        background-color: var(--v-lightgrey-lighten2);
    }
}
</style>
