<template>
    <div :class="`eqs-select-required-docs ${elClass}-container`" :style="getStyle">
        <EqsLabel
            :el-class="`${elClass}-label`"
            v-bind="{
                value: label,
                labelHeight,
                editable,
                infoTxt,
                isRequired
            }"
        >
            <EqsList
                class="eqs-select-required-docs__list"
                :el-class="`${elClass}-list`"
                :items.sync="items"
                two-lines
                v-bind="{
                    errorMessages: selectRemainingDocs,
                    loading: localLoading || loading,
                    editable: editable && !!categoriesLeft.length && !allDocsSelected
                }"
            >
                <template #add="{ addItem }">
                    <v-row
                        class="eqs-select-required-docs__list__add"
                        justify="center"
                        align="center"
                        no-gutters
                    >
                        <v-col v-if="categories.length > 1" cols="12" class="mx-2 py-3">
                            <EqsSelect
                                return-object
                                :clearable="false"
                                :value.sync="newDoc.category"
                                :placeholder="$t('molecule.form.require-doc.label-chose')"
                                v-bind="{
                                    items: categoriesLeft,
                                    loading: loading || localLoading,
                                    isRequired,
                                    elClass: `${elClass}-select-category`
                                }"
                            />
                        </v-col>
                        <v-col
                            v-if="newDoc.category && newDoc.category.multiple"
                            cols="12"
                            :class="`mx-2 py-3 ${$reactive.isDesktop() ? 'mt-2' : ''}`"
                        >
                            <EqsCheckbox
                                el-class="select-multiple"
                                class="mt-0 pt-0"
                                :value.sync="multiple"
                                :is-required="false"
                                v-bind="{
                                    text: newDoc.category.checkboxTxt,
                                    loading: loading || localLoading
                                }"
                            />
                        </v-col>
                        <v-row
                            v-if="newDoc.category && newDoc.category.multiple && multiple"
                            justify="space-around"
                            :class="`d-flex px-3 justify-start ${
                                $reactive.isMobile() ? 'flex-wrap' : ''
                            }`"
                        >
                            <v-col :cols="`${$reactive.isMobile() ? 12 : 6}`" class="py-3">
                                <EqsLabel
                                    v-bind="{
                                        value:
                                            newDoc.category.multiple && multiple
                                                ? newDoc.category.labelRecto
                                                : newDoc.category.label,
                                        isRequired
                                    }"
                                >
                                    <EqsUpload
                                        el-class="upload-new-doc"
                                        v-bind="{
                                            ...uploadProps,
                                            multiple: false,
                                            loading,
                                            placeholder: $t(
                                                'equisafe-ui.file-input.placeholder-singular'
                                            ),
                                            others: newDoc.category.others,
                                            loading: localLoading || loading
                                        }"
                                        :auto-upload="false"
                                        :value.sync="newDoc.frontFile"
                                    />
                                </EqsLabel>
                            </v-col>
                            <v-col :cols="`${$reactive.isMobile() ? 12 : 6}`" class="py-3">
                                <EqsLabel
                                    v-bind="{
                                        value: newDoc.category.labelVerso,
                                        isRequired
                                    }"
                                >
                                    <EqsUpload
                                        el-class="upload-new-doc-back"
                                        v-bind="{
                                            ...uploadProps,
                                            multiple: false,
                                            loading,
                                            placeholder: $t(
                                                'equisafe-ui.file-input.placeholder-singular'
                                            ),
                                            others: newDoc.category.others,
                                            isRequired,
                                            loading: localLoading || loading
                                        }"
                                        :value.sync="newDoc.backFile"
                                        :auto-upload="false"
                                    />
                                </EqsLabel>
                            </v-col>
                        </v-row>
                        <v-col v-else-if="newDoc.category" cols="12" class="mx-2 py-3">
                            <EqsLabel
                                v-bind="{
                                    value:
                                        newDoc.category.multiple && multiple
                                            ? newDoc.category.labelRecto
                                            : newDoc.category.label,
                                    isRequired
                                }"
                            >
                                <EqsUpload
                                    el-class="upload-new-doc"
                                    v-bind="{
                                        ...uploadProps,
                                        multiple: false,
                                        upload: uploadFunc,
                                        loading,
                                        placeholder: $t(
                                            'equisafe-ui.file-input.placeholder-singular'
                                        ),
                                        others: newDoc.category.others,
                                        isRequired,
                                        loading: localLoading || loading
                                    }"
                                    :value.sync="newDoc.file"
                                    @update:value="doc => addDoc(doc, addItem)"
                                    @isLoading="isLoading => (localLoading = isLoading)"
                                />
                            </EqsLabel>
                        </v-col>
                    </v-row>
                </template>
                <template #item="props">
                    <EqsFileActions
                        v-bind="{
                            ...props,
                            elClass: `${elClass}-${props.index}`,
                            editable,
                            removeItem: removeDoc,
                            deleteFunc: deleteFunction
                        }"
                    />
                </template>
                <template #item-append="{ item, index }">
                    <EqsAlert
                        v-if="item.lw && editable"
                        v-bind="{ ...item.lw, elClass: `${elClass}-${index}-status` }"
                        outlined
                        :small="$reactive.isMobile()"
                        label
                        class="font-weight-bold"
                    />
                </template>
            </EqsList>
        </EqsLabel>
    </div>
</template>

<script>
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

import { cssTypes, dataTypes } from "./EqsSelectRequiredDocs.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";
import { getLWStatusFile } from "../../../globals/lemonway";
import { uploadMultiDocument } from "../../../../../shared/util";

export default {
    name: "EqsSelectRequiredDocs",
    components: {
        EqsSelect: () => import("../../molecules/EqsSelect/EqsSelect.vue"),
        EqsCheckbox: () => import("../../atoms/EqsCheckbox/EqsCheckbox.vue"),
        EqsUpload: () => import("../../atoms/EqsUpload/EqsUpload.vue"),
        EqsList: () => import("../../molecules/EqsList/EqsList.vue"),
        EqsFileActions: () => import("../../molecules/EqsFileActions/EqsFileActions.vue"),
        EqsLabel: () => import("../../atoms/EqsLabel/EqsLabel.vue"),
        EqsAlert: () => import("../../molecules/EqsAlert/EqsAlert.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        formData: {
            type: Object,
            required: true
        },
        categories: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        elClass: {
            type: String,
            default: "eqs-select-required-docs"
        },
        numSelect: {
            type: Number,
            default: 2
        },
        deleteFunc: {
            type: Function,
            default: null
        },
        upload: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            newDoc: {
                file: null,
                frontFile: null,
                backFile: null,
                category: null
            },
            localLoading: false,
            multiple: false
        };
    },
    computed: {
        uploadProps() {
            return !!this.newDoc.file && !!this.newDoc.category ? this.newDoc.category : {};
        },
        deleteFunction() {
            return this.deleteFunc || this.$api.deleteDocument;
        },
        uploadFunc() {
            return this.upload || this.$api.postDocument;
        },
        allDocsSelected() {
            return this.items.length === this.numSelect;
        },
        selectRemainingDocs() {
            if (this.loading || !this.items.length || !this.editable) return "";
            let error = "";
            if (!this.allDocsSelected) error = this.$t("rules.select-remaining-docs");
            else if (this.items.find(item => !!item.lw && item.lw.status === "error")) {
                error = this.$t("rules.select-remaining-docs-lw");
            }
            this.$emit("update:value", !error);
            return error;
        },
        items: {
            get() {
                let docs = this.getPopulatedDocs();
                return docs.length
                    ? docs.splice(0, this.numSelect).map(item => {
                          item.title = `- ${item.file.name}`;
                          item.subtitle = item.category.text;
                          item.lw = getLWStatusFile(this, item.file);
                          return { ...item.file, ...item };
                      })
                    : [];
            },
            set(docs) {
                docs.forEach(doc => {
                    this.$set(this.formData, doc.category.key, doc.file);
                });
            }
        },
        categoriesLeft() {
            if (!this.editable || !this.items) return [];
            let categoriesLeft = differenceWith(
                this.categories,
                this.getPopulatedDocs().map(item => item.category),
                isEqual
            );
            return categoriesLeft;
        }
    },
    watch: {
        // categoriesLeft: {
        //   immediate: true,
        //   deep: true,
        //   handler(val) {
        //     if (val && val.length) this.newDoc.category = val[0];
        //   },
        // },
        newDoc: {
            deep: true,
            async handler(value) {
                if (!!value.frontFile && !!value.backFile && this.multiple)
                    await this.saveMultiDoc(value);
            }
        }
    },
    methods: {
        async saveMultiDoc(docs) {
            try {
                //NEED TO RECREATE A FILE LIST FOR MULTI UPLOAD
                this.localLoading = true;
                const uploadedDoc = await uploadMultiDocument(
                    this.uploadFunc,
                    [docs.frontFile, docs.backFile],
                    docs.category.others
                );
                let _items = _.cloneDeep(this.items);
                _items.push({
                    file: uploadedDoc,
                    category: docs.category
                });
                this.$set(this, "items", _items);
                this.resetNewDoc();
                this.$ui.success(this.$t("molecule.form.require-doc.snackbar-multi-success"));
                this.multiple = false;
            } catch (e) {
                console.error({ e });
                this.$ui.error(this.$t("molecule.form.require-doc.snackbar-multi-error"));
            } finally {
                this.localLoading = false;
            }
        },
        resetNewDoc() {
            const matchDoc = this.categoriesLeft.find(
                item => item.link === this.newDoc.category.link
            );
            this.newDoc = {
                category: matchDoc ? matchDoc : this.categoriesLeft[0],
                file: null,
                frontFile: null,
                backFile: null
            };
        },
        removeDoc(index) {
            const docToRemove = this.items[index];
            this.$set(this.formData, docToRemove.category.key, null);
        },
        addDoc(doc, addItem) {
            this.newDoc.file = doc;
            addItem(cloneDeep(this.newDoc));
            this.$nextTick(() => {
                this.resetNewDoc();
            });
        },
        getPopulatedDocs() {
            let populatedDocs = [];
            const categoriesKeys = this.categories.map(category => category.key);
            categoriesKeys.forEach((key, index) => {
                if (!!this.formData[key]) {
                    populatedDocs.push({
                        file: this.formData[key],
                        category: this.categories[index]
                    });
                }
            });
            return populatedDocs;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsSelectRequiredDocs.scss";
</style>
