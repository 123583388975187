var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"closable":"","persistent":""},on:{"close":_vm.resetFormData},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},'EqsDialog',{
        title: _vm.$t('equisafe-ui.signature-template.internal-signatories-dialog.title'),
        confirm: {
            fn: _vm.selectSigners
        },
        cancel: {
            fn: _vm.resetFormData
        }
    },false),[_c('EqsAlert',[_vm._v(" "+_vm._s(_vm.$t("equisafe-ui.signature-template.internal-signatories-dialog.info"))+" ")]),_c('EqsDataTable',_vm._b({attrs:{"selected":_vm.formData,"single-select":false,"item-key":"txt","selectable":"","items-per-page":-1,"hide-default-footer":""},on:{"update:selected":function($event){_vm.formData=$event}},scopedSlots:_vm._u([{key:"item.txt",fn:function({ value }){return [_vm._v(" "+_vm._s(value)+" ")]}}])},'EqsDataTable',{ items: _vm.items, headers: _vm.headers, isRequired: true },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }