var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-number"},[_c('EqsText',_vm._g(_vm._b({attrs:{"type":_vm.isFocused ? 'number' : 'text'},on:{"blur":function($event){_vm.isFocused = false},"focus":function($event){_vm.isFocused = true}},model:{value:(_vm.displayValue),callback:function ($$v) {_vm.displayValue=$$v},expression:"displayValue"}},'EqsText',{
            ..._vm.$attrs,
            ..._vm.getData,
            editable: _vm.editable,
            disabled: _vm.disabled,
            readonly: _vm.readonly
        },false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }