import { textSizing } from "../../../templates/text";

export const cssTypes = currentSize => {
    return {
        default: {
            "margin-left": "14px"
        }
    };
};

export const dataTypes = (currentSize = {}, ctx = {}) => {
    return {
        default: {
            ...textSizing(currentSize, ctx.eqsSize)
        }
    };
};
