var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsViewWithImage',_vm._b({attrs:{"img":"/images/governance/mandats.svg"},scopedSlots:_vm._u([{key:"default",fn:function({ loading }){return [_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('GlobalChooseInvestor',_vm._b({attrs:{"value":_vm.formData.investor},on:{"update:value":function($event){return _vm.$set(_vm.formData, "investor", $event)}}},'GlobalChooseInvestor',{
                        loading,
                        label: _vm.edit
                            ? _vm.$t('governance.representatives.representative')
                            : _vm.$t('governance.representatives.choose-representative'),
                        disabled: _vm.edit,
                        editable: _vm.editable,
                        errorMessages: _vm.uniqueRepresentative,
                        ..._vm.schemaDouble.investor
                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.role,"items":_vm.roles},on:{"update:value":function($event){return _vm.$set(_vm.formData, "role", $event)}}},'EqsSelect',{
                        loading,
                        label: _vm.$t('governance.representatives.quality'),
                        placeholder: _vm.roles[0].text,
                        editable: _vm.editable,
                        ..._vm.schemaDouble.role
                    },false))],1),_c('v-slide-y-transition',{attrs:{"hide-on-leave":""}},[(_vm.formData.role === 'other')?_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.roleOther},on:{"update:value":function($event){return _vm.$set(_vm.formData, "roleOther", $event)}}},'EqsText',{
                            loading,
                            label: _vm.$t('governance.representatives.quality-other'),
                            placeholder: _vm.$t('governance.representatives.quality-placeholder'),
                            editable: _vm.editable,
                            ..._vm.schemaDouble.roleOther
                        },false))],1):_vm._e()],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half', _vm.formType)}},[_c('EqsDate',_vm._b({attrs:{"value":_vm.formData.start_mandat_date},on:{"update:value":function($event){return _vm.$set(_vm.formData, "start_mandat_date", $event)}}},'EqsDate',{
                            loading,
                            editable: _vm.editable,
                            label: _vm.$t('governance.representatives.start_mandat_date'),
                            ..._vm.schemaDouble.start_mandat_date
                        },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half', _vm.formType)}},[_c('EqsDate',_vm._b({attrs:{"value":_vm.formData.end_mandat_date},on:{"update:value":function($event){return _vm.$set(_vm.formData, "end_mandat_date", $event)}}},'EqsDate',{
                            loading,
                            editable: _vm.editable,
                            label: _vm.$t('governance.representatives.end_mandat_date'),
                            ..._vm.endOfTermMinMax,
                            ..._vm.schemaDouble.end_mandat_date
                        },false))],1)],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.formData.annual_compensation},on:{"update:value":function($event){return _vm.$set(_vm.formData, "annual_compensation", $event)}}},'EqsNumber',{
                        loading,
                        editable: _vm.editable,
                        label: _vm.$t('governance.representatives.annual_compensation'),
                        placeholder: _vm.$t(
                            'governance.representatives.annual_compensation-placeholder'
                        ),
                        ..._vm.schemaDouble.annual_compensation
                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full', _vm.formType)}},[_c('EqsFileInput',_vm._b({attrs:{"value":_vm.formData.nomination_act},on:{"update:value":function($event){return _vm.$set(_vm.formData, "nomination_act", $event)}}},'EqsFileInput',{
                        loading,
                        editable: _vm.editable,
                        label: _vm.$t('governance.representatives.nomination'),
                        ..._vm.schemaDouble.nomination_act
                    },false))],1)],1)]}}])},'EqsViewWithImage',{ loading: _vm.loading || _vm.representativesLoading },false))
}
var staticRenderFns = []

export { render, staticRenderFns }