var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[(_vm.universe === 'myclubdeal' && !_vm.crowdfunding)?_c('div',{staticStyle:{"height":"150px","background-image":"linear-gradient(to right, #405cff, #575eae)","border-radius":"50px"},on:{"click":function($event){return _vm.openPublic()}}},[_c('h1',{staticStyle:{"cursor":"pointer","color":"white !important","text-align":"center","vertical-align":"middle","line-height":"150px"}},[_vm._v(" "+_vm._s(_vm.$t("projecs.dashboard.offerings.go-myclubdeal"))+" ")])]):_vm._e(),(!_vm.universe)?_c('div',[(_vm.crowdfunding)?_c('v-alert',{staticClass:"my-4 pa-4 alert-link",attrs:{"icon":"mdi-eye-lock-open-outline","prominent":"","type":"warning","elevation":"2","dense":"","shaped":"","color":"#17375F"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('crowdfunding.warning'))}})]):_c('v-alert',{staticClass:"my-4 pa-4 alert-link",attrs:{"icon":"mdi-eye-lock","prominent":"","type":"info","elevation":"2","dense":"","shaped":"","color":"#17375F"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('projects.private-legend'))}})])],1):_vm._e(),_c('EqsDataIterator',_vm._b({staticClass:"auto-height",attrs:{"el-class":"projects-list","add":!_vm.$universe.isNeotrees() && !_vm.crowdfunding
                ? {
                      txt: _vm.$t('projecs.dashboard.offerings.import-project'),
                      fn: () => (_vm.importDialog = true)
                  }
                : null,"no-data-custom":{
            title: _vm.$t('projecs.dashboard.offerings.projects-no-data-title'),
            text: _vm.crowdfunding
                ? _vm.$t('projecs.dashboard.offerings.projects-no-data-txt-crowd')
                : _vm.$t('projecs.dashboard.offerings.projects-no-data-txt'),
            img: _vm.$universe.image('/images/projects/projects_empty.svg')
        }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{attrs:{"cols":"auto"}},[(_vm.currentOfferings.length)?_c('EqsTextDisplay',_vm._b({attrs:{"value":_vm.$t('projecs.dashboard.offerings.title-ongoing')}},'EqsTextDisplay',{ eqsType: 'title', loading: _vm.loading },false)):_vm._e()],1)]},proxy:true},{key:"item",fn:function({ item, index }){return [_c('OfferCard',_vm._b({on:{"open":() => _vm.openOffer(item)}},'OfferCard',{
                    item,
                    loading: _vm.loading,
                    height: _vm.offerCardHeight,
                    elClass: `project-${index}`
                },false))]}}])},'EqsDataIterator',{
            items: _vm.currentOfferings,
            loading: _vm.loading,
            ..._vm.dataIteratorProps
        },false)),(_vm.commingOfferings?.length !== 0)?_c('EqsDataIterator',_vm._b({staticClass:"auto-height",attrs:{"el-class":"projects-list"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{attrs:{"cols":"auto"}},[(_vm.commingOfferings.length)?_c('EqsTextDisplay',_vm._b({attrs:{"value":_vm.$t('dashboard.projects-tocome')}},'EqsTextDisplay',{ eqsType: 'title', loading: _vm.loading },false)):_vm._e()],1)]},proxy:true},{key:"item",fn:function({ item, index }){return [_c('OfferCard',_vm._b({on:{"open":() => _vm.openOffer(item)}},'OfferCard',{
                    item,
                    loading: _vm.loading,
                    height: _vm.offerCardHeight,
                    elClass: `project-${index}`
                },false))]}}],null,false,747493915)},'EqsDataIterator',{
            items: _vm.commingOfferings,
            loading: _vm.loading,
            ..._vm.dataIteratorProps
        },false)):_vm._e(),_c('v-slide-y-transition',[(_vm.fundedOfferings && _vm.fundedOfferings.length)?_c('EqsDataIterator',_vm._b({staticClass:"auto-height",attrs:{"el-class":"projects-list-funded"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{attrs:{"cols":12}},[_c('EqsTextDisplay',_vm._b({attrs:{"value":_vm.$t('projecs.dashboard.offerings.title-financed')}},'EqsTextDisplay',{ eqsType: 'title' },false))],1)]},proxy:true},{key:"item",fn:function({ item, index }){return [_c('OfferCard',_vm._b({on:{"open":() => _vm.openOffer(item)}},'OfferCard',{
                        item,
                        loading: _vm.loading,
                        height: _vm.offerCardHeight,
                        elClass: `project-${index}`
                    },false))]}}],null,false,1189122779)},'EqsDataIterator',{
                items: _vm.fundedOfferings,
                loading: _vm.loading,
                ..._vm.dataIteratorProps
            },false)):_vm._e()],1),(_vm.crowdfunding)?_c('CrowdfundingFooter',{staticClass:"mt-6"}):_vm._e(),(!_vm.crowdfunding)?_c('ImportDialog',_vm._b({attrs:{"value":_vm.importDialog},on:{"update:value":function($event){_vm.importDialog=$event}}},'ImportDialog',{ fetchOffering: _vm.fetchOffering },false)):_vm._e(),(_vm.crowdfunding)?_c('RiskAversionDialog',_vm._b({attrs:{"value":_vm.aversionDialog},on:{"update:value":function($event){_vm.aversionDialog=$event}}},'RiskAversionDialog',{ loading: _vm.loading },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }