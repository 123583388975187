<template>
    <div class="eqs-avatar" :el-class="elClass" :style="getStyle">
        <EqsBadge
            :class="`d-flex justify-center align-center`"
            :value="!!user && !loading && !noBadge"
            v-bind="userProps"
            overlap
            avatar
        >
            <EqsSkeleton v-bind="{ loading, type: 'avatar' }">
                <v-avatar
                    :rounded="!!user && user.is_company"
                    :color="
                        avatar ? $color.colors.lighterGrey : $color.colorFromHashAvatar(initials)
                    "
                    v-bind="{ ...$attrs, ...getData }"
                    v-on="$listeners"
                >
                    <v-img
                        v-bind="{
                            src: avatar,
                            ...getData,
                            gradient,
                            contain: !!user && user.is_company
                        }"
                    >
                        <template v-if="!avatar" #placeholder>
                            <span
                                class="white--text"
                                :gradient="gradient"
                                :style="`
                  font-size: ${getFontSize};
                  height: 100%;
                  width: 100%;
                  justify-content: center;
                  display: flex;
                  align-items: center;
                `"
                            >
                                {{ initials }}
                            </span>
                        </template>
                        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                    </v-img>
                </v-avatar>
            </EqsSkeleton>
        </EqsBadge>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsAvatar.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import isObject from "lodash/isObject";

export default {
    name: "EqsAvatar",
    components: {
        EqsSkeleton: () => import("../EqsSkeleton/EqsSkeleton.vue"),
        EqsBadge: () => import("../EqsBadge/EqsBadge.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-avatar"
        },
        src: {
            type: String,
            default: ""
        },
        user: {
            type: [Object, null, undefined],
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        noBadge: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        gradient: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            delay: 0
        };
    },
    computed: {
        getFontSize() {
            if (!this.initials) return "";
            let size = 0.6;
            if (this.initials.length > 2) size = 0.4;
            if (this.initials.length >= 4) size = 0.2;
            if (this.initials.length)
                size += this.$reactive.getVal({
                    mobile: 0.4,
                    tablet: 0.5,
                    desktop: 0.6
                });
            return `${size}rem`;
        },
        avatar() {
            if (this.src) return this.src;
            if (this.user && isObject(this.user.avatar) && this.user.avatar.upload)
                return this.user.avatar.upload;
            if (!this.getPlaceholder && this.initials === "") {
                return this.getDefaultImage;
            } else {
                return this.getPlaceholder;
            }
        },
        initials() {
            if (this.text) return this.text;
            if (this.user && this.user.name) {
                let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

                let initials = [...this.user.name.matchAll(rgx)] || [];

                initials = (
                    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
                ).toUpperCase();
                return initials;
            }
            return "";
        },
        getPlaceholder() {
            if (this.placeholder) return this.placeholder;
            return null;
        },
        getDefaultImage() {
            return `/assets/${this.user && this.user.is_company ? "company" : "individual"}.svg`;
        },
        userProps() {
            if (this.getData.badge) return this.getData.badge;
            let icon = "mdi-alert-circle-outline";
            let color = "warning";
            if (this.user) {
                let { lemonway_state, lemonway_status } = this.user.entity || this.user;
                if ([2, 3, 8].includes(lemonway_status)) lemonway_state = "error";
                switch (lemonway_state) {
                    case "success":
                        icon = "mdi-account-check-outline";
                        color = "success";
                        break;
                    case "failure":
                        icon = "mdi-alert-outline";
                        color = "error";
                        break;
                    case "upload-failure":
                        icon = "mdi-alert-outline";
                        color = "error";
                        break;
                    case "error":
                        icon = "mdi-alert-outline";
                        color = "error";
                        break;
                    default:
                        icon = "mdi-alert-circle-outline";
                        color = "warning";
                        break;
                }
            }

            return {
                icon,
                size: this.$reactive.getVal({
                    mobile: "16px",
                    tablet: "16px",
                    desktop: "18px"
                }),
                color
            };
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsAvatar.scss";
</style>
