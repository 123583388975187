// utils/storybook.js

import replace from "lodash/replace";
import isEmpty from "lodash/isEmpty";
import assignIn from "lodash/assignIn";
import differenceWith from "lodash/differenceWith";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import transform from "lodash/transform";
import forOwn from "lodash/forOwn";

export function arrayUnique(array, base = null) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) a.splice(j--, 1);
            else if (base && a[i][base] === a[j][base]) a.splice(j--, 1);
        }
    }
    return a;
}

export function difference(object, base) {
    function changes(object, base) {
        return transform(object, function (result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] =
                    isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}

export function flat(array, childrenKeys = ["children"]) {
    const flatten = arr => {
        let i = 0;
        while (i < arr.length) {
            let childs = null;
            childrenKeys.forEach(key => {
                if (Array.isArray(arr[i][key])) {
                    childs = arr[i][key];
                }
            });
            if (childs) {
                arr.splice(i, 1, ...childs);
            } else {
                i++;
            }
        }
        return arr;
    };
    return flatten(array);
}

export function flatObject(ob, separator = "_", prependKey = null) {
    var toReturn = {};
    for (var i in ob) {
        if (!Object.prototype.hasOwnProperty.call(ob, i)) continue;

        if (typeof ob[i] == "object" && ob[i] !== null) {
            var flatObj = flatObject(ob[i], separator, prependKey);
            for (var x in flatObj) {
                if (!Object.prototype.hasOwnProperty.call(flatObj, x)) continue;
                const key = prependKey ? prependKey + i + separator + x : i + separator + x;
                toReturn[replace(key, prependKey + separator, separator)] = flatObj[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}

export function convertObjToCssVar(obj) {
    return flatObject(obj, "-", "--");
}

export const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item
        };
    }, initialValue);
};

export function delayedPromise(delay = 2000, value = {}) {
    return new Promise(resolve => setTimeout(resolve, delay, value));
}

export function toType(obj) {
    return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase();
}

export function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : string;
}

export function capitalize(value) {
    if (!value) return "";
    value = value.toString();
    return value.replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
}

export function capitalizeForChar(value, char = "_") {
    if (!value) return "";
    value = value.toString();
    let subStr = value.split(char).map(str => str.charAt(0).toUpperCase() + str.slice(1));
    return subStr.join(" ");
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function calcPercent(val, total) {
    return ((val / total) * 100).toFixed(2);
}

export function orderingQuery(sortBy, descending) {
    return descending === true ? `-${sortBy}` : sortBy;
}

export function roundTwo(number) {
    return Math.round(number * 100) / 100;
}

export function percentage(number, total) {
    return ((number / total) * 100).toFixed(2);
}

export function copyToClipboard(id) {
    const copyText = document.getElementById(id);
    copyText.setAttribute("type", "text");
    copyText.select();
    document.execCommand("copy");
    copyText.setAttribute("type", "hidden");
    window.getSelection().removeAllRanges();
}

export function mouseEvent(el, etype) {
    if (el) {
        var clickEvent = new MouseEvent(etype, {
            view: window,
            bubbles: true,
            cancelable: false
        });
        el.dispatchEvent(clickEvent);
    }
}

export function pathInclude(event, className) {
    const path = event.path || (!!event.composedPath && event.composedPath());
    return path && !!path.find(_path => _path.className && _path.className.includes(className));
}

export function poll(fn, timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 2000);
    interval = interval || 100;

    var checkCondition = function (resolve, reject) {
        // If the condition is met, we're done!
        var result = fn();
        if (result) {
            resolve(result);
        }
        // If the condition isn't met but the timeout hasn't elapsed, go again
        else if (Number(new Date()) < endTime) {
            setTimeout(checkCondition, interval, resolve, reject);
        }
        // Didn't match and too much time, reject!
        else {
            reject(new Error("timed out for " + fn + ": " + arguments));
        }
    };

    return new Promise(checkCondition);
}

export function wait(interval = 1000) {
    return new Promise(resolve => {
        if (process.env.NODE_ENV === "development") console.log(`waiting ${interval} ms...`);
        return setTimeout(resolve, interval);
    });
}

// cc https://dev.to/jakubkoci/polling-with-async-await-25p4
export async function asyncPoll(fn, fnArgs, fnCondition, interval, timeout, stop = false) {
    var endTime = Number(new Date()) + (timeout || 2000);
    let result = null;
    try {
        result = await fn(fnArgs);
    } catch (e) {
        throw e;
    }
    while (!fnCondition(result) && !stop) {
        await wait(interval);
        try {
            result = await fn(fnArgs);
        } catch (e) {
            throw e;
        }
        if (Number(new Date()) > endTime) {
            throw new Error("timed out for " + fn + ": " + arguments);
        }
    }
    return result;
}

export const deleteDocument = async (deleteFunc, document) => {
    if (!document) console.error("document is empty");
    else {
        try {
            const res = await deleteFunc(document.id);
            return res.data;
        } catch (e) {
            console.error("error in deleteDocument: ", { e });
            window.app.$ui.error(e, "document_delete");
        }
    }
};

export const uploadMultiDocument = async (uploadFunc, docs, others) => {
    if (!docs || !docs.length) {
        console.error("Documents is empty");
    } else {
        let getDataTransfer = () => new DataTransfer();
        const fileList = getDataTransfer();
        docs.forEach(doc => {
            fileList.items.add(doc);
        });

        const files = fileList.files;
        const formData = new FormData();
        // To post all documents and concat them
        // You need to append `files_to_concat` for each different document
        forOwn(files, (file, key) => {
            formData.append("files_to_concat", file);
        });
        formData.append("name", files[0].name);
        formData.append("type", files[0].type);
        if (!isEmpty(others))
            for (var key in others) {
                formData.append(key, others[key]);
            }
        try {
            const fileObject = assignIn(others, docs[0]);
            const res = await uploadFunc(formData, fileObject);
            return res.data;
        } catch (e) {
            console.error(`error in uploadDocument:`, { e });
            if ("data" in e && e.data.upload && e.data.upload.length)
                window.app.$ui.error(e.data.upload[0]);
            else if ("data" in e && e.data.name && e.data.name.length)
                window.app.$ui.error(e.data.name[0]);
            else if ("data" in e && e.data.type && e.data.type.length)
                window.app.$ui.error(e.data.type[0]);
        }
    }
};

export const getDiffArrayWith = (item, itemToCmp) =>
    differenceWith(item, itemToCmp, (a, b) => isEqual(a, b));

export const anyFieldChanged = (itemToCmp, item, valToComp) => {
    return !valToComp.every(val => {
        if (isObject(itemToCmp[val])) return isEqual(itemToCmp[val], item[val]);
        else if (isArray(itemToCmp[val])) {
            return (
                isEmpty(getDiffArrayWith(item[val], itemToCmp[val])) &&
                isEmpty(getDiffArrayWith(itemToCmp[val], item[val]))
            );
        }
        return isEqual(itemToCmp[val], item[val]);
    });
};

export const arrayOrStringEmpty = data => {
    if (!data) return true;
    return Array.isArray(data) && !data.length;
};

export function getRootURL() {
    return parseInt(window.location.port) > 8000
        ? window.location.protocol + "//" + window.location.hostname + ":8000/"
        : window.location.protocol + "//" + window.location.host + "/";
}

export function mergeArraysByKey(arr1, arr2, key) {
    const merged = [
        ...arr1
            .concat(arr2)
            .reduce((m, o) => m.set(o[key], Object.assign(m.get(o[key]) || {}, o)), new Map())
            .values()
    ];
    return merged;
}

export function getNestedValue(obj, path, fallback = null) {
    const last = path.length - 1;

    if (last < 0) return obj === undefined ? fallback : obj;

    for (let i = 0; i < last; i++) {
        if (obj == null) {
            return fallback;
        }
        obj = obj[path[i]];
    }

    if (obj == null) return fallback;

    return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export function getObjectValueByPath(obj, path, fallback = null) {
    // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
    if (obj == null || !path || typeof path !== "string") return fallback;
    if (obj[path] !== undefined) return obj[path];
    path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    path = path.replace(/^\./, ""); // strip a leading dot
    return getNestedValue(obj, path.split("."), fallback);
}
