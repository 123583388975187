<template>
    <div class="d-flex flex-column">
        <PutCompanyBlockchain
            :blockchain.sync="formData.blockchain"
            v-bind="{ companyBlockchainSetted, loading, formData }"
        />
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

import PutCompanyBlockchain from "@/components/views/registration/PutCompanyBlockchain.vue";

export default {
    name: "DigitalizeYourCompany",
    components: {
        PutCompanyBlockchain
    },
    mixins: [formMixin],
    props: {
        companyBlockchainSetted: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        issuingEntity() {
            return this.$blockchain.blockchainizeState().issuingentity;
        },
        kycIssuer() {
            return this.$blockchain.blockchainizeState().kycissuer;
        },
        setRegistrarConstraint() {
            if (
                this.issuingEntity &&
                this.issuingEntity.registrarconstraint_set &&
                this.issuingEntity.registrarconstraint_set.length
            ) {
                return this.issuingEntity.registrarconstraint_set[0];
            }
            return {};
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped></style>
