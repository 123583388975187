<template>
    <div :class="`eqs-list ${elClass}-container`" :style="getStyle">
        <EqsLabel
            v-bind="{
                height: labelHeight,
                editable,
                value: label,
                infoTxt
            }"
        >
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <EqsEditable v-bind="{ editable: true, value: '' }">
                <v-input v-bind="{ ...$attrs, value: items }" dense hide-details="auto">
                    <v-list v-bind="{ ...$attrs, disabled, group }" class="pa-0" v-on="$listeners">
                        <template v-if="editable">
                            <slot name="add" v-bind="{ addItem }" />
                        </template>
                        <v-card tile elevation="0">
                            <div v-for="(item, index) in items" :key="index">
                                <EqsSkeleton v-bind="{ loading, type: getData.skeleton }">
                                    <div v-if="!item.items && !item.group && !group">
                                        <v-list-item
                                            :color="item.color || $color.colors.eqsBlue"
                                            active-class="eqs-list__item-active"
                                            :style="`background-color:${item.bgColor}`"
                                            :el-class="`${elClass}-item-${index}`"
                                        >
                                            <v-list-item-icon v-if="item.icon">
                                                <v-icon :color="item.iconColor">
                                                    {{ item.icon }}
                                                </v-icon>
                                            </v-list-item-icon>

                                            <v-list-item-content
                                                v-if="
                                                    !!$scopedSlots['item-content'] ||
                                                    item.title ||
                                                    item.subtitle
                                                "
                                            >
                                                <v-list-item-title v-if="item.title">
                                                    <span v-if="!$reactive.isMobile()">
                                                        {{ item.title }}
                                                    </span>
                                                    <EqsTextHoverTooltip
                                                        v-else
                                                        :text="item.title"
                                                    />
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-if="item.subtitle">
                                                    {{ item.subtitle }}
                                                </v-list-item-subtitle>
                                                <slot
                                                    name="item-content"
                                                    v-bind="{ item, index }"
                                                />
                                            </v-list-item-content>
                                            <slot
                                                name="item"
                                                v-bind="{ item, index, removeItem }"
                                            />
                                        </v-list-item>
                                        <slot
                                            name="item-append"
                                            v-bind="{ item, index, removeItem }"
                                        />
                                    </div>
                                    <v-list-group
                                        v-else
                                        no-action
                                        :el-class="`${elClass}-item-${index}`"
                                        :color="item.color || $color.colors.eqsBlue"
                                    >
                                        <template #activator>
                                            <v-list-item-icon v-if="item.icon">
                                                <v-icon small :color="item.iconColor">
                                                    {{ item.icon }}
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title v-if="item.title">
                                                    {{ item.title }}
                                                </v-list-item-title>
                                                <slot name="group-tile" v-bind="{ item, index }" />
                                            </v-list-item-content>
                                        </template>
                                        <div v-if="item.group || group">
                                            <slot
                                                name="group-content"
                                                v-bind="{
                                                    item,
                                                    index,
                                                    removeItem,
                                                    updateItem
                                                }"
                                            />
                                        </div>
                                        <div v-else>
                                            <div
                                                v-for="(subItem, subIndex) in items"
                                                :key="subIndex"
                                            >
                                                <v-list-item
                                                    :link="!!item.to || !item.href"
                                                    :color="subItem.color || $color.colors.eqsBlue"
                                                    active-class="eqs-list__item-active"
                                                    :style="`background-color:${item.bgColor}`"
                                                >
                                                    <v-list-item-icon v-if="subItem.icon">
                                                        <v-icon>
                                                            {{ subItem.icon }}
                                                        </v-icon>
                                                    </v-list-item-icon>

                                                    <v-list-item-content>
                                                        <v-list-item-title v-if="subItem.title">
                                                            <EqsTextHoverTooltip
                                                                :text="subItem.title"
                                                            />
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </div>
                                    </v-list-group>
                                    <v-divider
                                        v-if="border && index + 1 < items.length"
                                        :key="index"
                                    />
                                </EqsSkeleton>
                            </div>
                            <EqsSkeleton
                                v-if="noDataSkeleton && noData"
                                v-bind="{ loading }"
                                :type="getData.skeletonNoData"
                            />
                        </v-card>
                    </v-list>
                </v-input>
            </EqsEditable>
        </EqsLabel>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsList.js";
import { inputFormMixin } from "../../../mixins/inputMixin";
import { eqsUITemplate } from "../../../mixins/styleMixin";

import EqsEditable from "../../atoms/EqsEditable/EqsEditable.vue";
import EqsLabel from "../../atoms/EqsLabel/EqsLabel.vue";
import cloneDeep from "lodash/cloneDeep";

export default {
    name: "EqsList",
    components: {
        EqsEditable,
        EqsLabel,
        EqsTextHoverTooltip: () =>
            import("../../atoms/EqsTextHoverTooltip/EqsTextHoverTooltip.vue"),
        EqsSkeleton: () => import("../../atoms/EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: "eqs-list"
        },
        items: {
            type: Array,
            default: () => []
        },
        border: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        group: {
            type: Boolean,
            default: false
        },
        // if false, doesn't place any skeleton if there is no data
        noDataSkeleton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        noData() {
            return this.items && !this.items.length;
        }
    },
    methods: {
        updateItem(item, index) {
            let data = cloneDeep(this.items);
            data[index] = item;
            this.$emit("update:items", data);
        },
        addItem(item) {
            if (item) {
                const data = cloneDeep(this.items);
                data.push(item);
                this.$emit("update:items", data);
            }
        },
        removeItem(index) {
            const data = cloneDeep(this.items);
            data.splice(index, 1);
            this.$emit("update:items", data);
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsList.scss";
</style>
