<template>
    <EqsSelect
        :loading-items="shareledgersLoading"
        :value.sync="valueProxy"
        prepend-icon="mdi-book-account-outline"
        :items="shareledgers"
        return-object
        item-value="id"
        item-text="name"
        :hint="getHint"
        persistent-hint
        v-bind="{ ...$attrs, elClass: 'choose-shareledger' }"
        v-on="$listeners"
    >
        <template #item="{ item }">
            <span>
                {{ `${item.name} (${item.symbol})` }}
            </span>
        </template>
        <template #selection="{ item }">
            <span>
                {{ `${item.name} (${item.symbol})` }}
            </span>
        </template>
    </EqsSelect>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { share_types } from "@/services/shareledger/shareType";
import isEmpty from "lodash/isEmpty";
export default {
    name: "GlobalChooseShareledger",
    props: {
        value: {
            type: Object,
            default: () => {}
        }
    },
    created() {
        this.getShareledgers();
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        getHint() {
            let res = "";
            if (!isEmpty(this.valueProxy)) {
                return `${this.$currency.display(this.valueProxy.numberofshares, "0,0")} ${
                    share_types[this.valueProxy.share_type].name
                }`;
            }
            return res;
        },
        ...mapGetters(["shareledgers", "shareledgersLoading"])
    },
    methods: {
        ...mapActions(["getShareledgers"])
    }
};
</script>

<style lang="scss" scoped></style>
