import { getVal } from "../services/reactive.js";

export const logoSizing = currentSize => {
    return {
        width: getVal(currentSize, {
            xs: "4rem",
            sm: "5rem",
            md: "6rem",
            lg: "7rem",
            xl: "8rem"
        })
    };
};
