<template>
    <img v-if="value" class="shareledger-kyc-checked" src="/icons/check-circle-2.svg" />
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped>
.shareledger-kyc-checked {
    margin-left: 6px;
}
</style>
