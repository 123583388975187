var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"closable":"","persistent":""},on:{"close":() => (_vm.dialogProxy = false)},model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},'EqsDialog',{
        title: _vm.$t('governance.decisions.lock-dialog.title', { name: _vm.item.name }),
        confirm: {
            fn: () => _vm.lockItem(_vm.item)
        },
        img: '/icons/anchor.svg',
        cancel: {}
    },false),[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsAlert',_vm._b({},'EqsAlert',{
                    eqsType: 'warning',
                    text: _vm.$t('governance.decisions.lock-dialog.alert')
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                    value: _vm.$t('governance.decisions.header.organe'),
                    editable: false
                },false),[_c('EqsChip',_vm._b({},'EqsChip',{
                        color: _vm.$color.colorFromHash(_vm.item.organ.name),
                        textColor: 'text'
                    },false),[_c('div',[_vm._v(_vm._s(_vm.item.organ.name))])])],1)],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                    value: _vm.$t('governance.decisions.header.mode'),
                    editable: false
                },false),[_c('EqsChip',_vm._b({},'EqsChip',{
                        color: _vm.$color.colorFromHash(_vm.item.type),
                        textColor: 'text'
                    },false),[_c('div',[_vm._v(_vm._s(_vm.decision_types[_vm.item.type].text))])])],1)],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsDate',{attrs:{"value":_vm.item.date,"editable":false,"label":_vm.$t('governance.decisions.stepper.step.upload-decision.form.date.label')}})],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsList',_vm._b({scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('EqsFileActions',_vm._b({},'EqsFileActions',{
                            item: item.doc,
                            index,
                            editable: false
                        },false))]}}])},'EqsList',{
                    editable: false,
                    label: _vm.$t(
                        'governance.decisions.stepper.step.upload-decision.form.convocation-docs.title'
                    ),
                    items: _vm.item.convocation_documents.map(item => {
                        return {
                            doc: item,
                            subtitle: _vm.documentTypesObj[item.category.toLowerCase()].text,
                            title: item.name
                        };
                    })
                },false))],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }