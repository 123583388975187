<template>
    <v-breadcrumbs :items="crumbsItems" divider="/" class="breadcrumbs">
        <template #item="{ item }">
            <v-breadcrumbs-item :to="item.to" :exact="item.exact">
                {{ item.text }}
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    name: "Breadcrumbs",
    computed: {
        crumbsItems() {
            let pathArray = this.$route.path.split("/"); // Split path for parsing
            pathArray.shift(); // Skip blank
            const entityRepresendted = pathArray.shift(); // Get entity id first
            let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
                // Manage entity id always first
                let itemPath = path;
                if (idx == 0) {
                    itemPath = entityRepresendted + "/" + itemPath;
                }
                // Create breadcrumbs list
                breadcrumbArray.push({
                    exact: true,
                    path: itemPath,
                    to: idx > 0 ? "/" + breadcrumbArray[idx - 1].path + "/" + path : "/" + itemPath,
                    text: this.$route.matched[idx].meta.breadCrumb || path
                });
                return breadcrumbArray;
            }, []);
            return breadcrumbs;
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.v-breadcrumbs__item) {
    // color: red !important;
}

:deep(.v-breadcrumbs__divider) {
    // color: purple !important;
}

:deep(.v-breadcrumbs__item--disabled) {
    // color: green !important;
}
</style>
