<template>
    <EqsPanels :value="openPanelN" v-bind="{ panels }">
        <template #default="{ item }">
            <component
                :is="item.is"
                v-if="item.is"
                profile
                v-bind="{
                    ...item.props,
                    schemaDouble: item.schemaDouble,
                    formData: entity,
                    loading: loading || loadingCountries,
                    countries,
                    formType: 'medium',
                    registrationInfos
                }"
            />
        </template>
    </EqsPanels>
</template>

<script>
import { entityDetailsMixin } from "@/mixins/kycMixin";

export default {
    name: "EntityDetailsPanels",
    components: {
        TransferHistory: () => import("./TransferHistory"),
        PaymentOrderDetails: () => import("./PaymentOrderDetails"),
        ShareholderSharesPie: () =>
            import("@/components/views/shareledger/ShareholderSharesPie.vue"),
        SignedDocsCapincOrdersAdmin: () => import("./SignedDocsCapincOrdersAdmin")
    },
    mixins: [entityDetailsMixin],
    props: {
        entity: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        openPanelN: {
            type: Number,
            required: false,
            default: null
        },
        registrationInfos: {
            type: Boolean,
            default: false
        },
        procedureDocuments: {
            type: Array,
            required: false,
            default: null
        },
        paymentOrderDetails: {
            type: Object,
            required: false,
            default: null
        },
        proofOfFund: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            countries: [],
            loadingCountries: true,
            docsTableHeaders: [
                {
                    text: this.$t("shareledger.dialog.detail.document-name"),
                    value: "name"
                },
                {
                    text: this.$t("label.status").toUpperCase(),
                    value: "investor_source"
                }
            ]
        };
    },
    computed: {
        panels() {
            if (!this.entity) return [];
            let entityDetailsPanel = {
                title: this.$entity.isCompany(this.entity)
                    ? this.$t("registration.company.company-infos")
                    : this.$t("registration.individual-infos"),
                item: this.entityDetailsSchema(this, this.entity)
            };
            let _panels = [entityDetailsPanel];
            if (this.registrationInfos) {
                _panels.push({
                    title: this.$entity.isCompany(this.entity)
                        ? this.$t("registration.company.company-compliance-infos")
                        : this.$t("registration.individual-compliance-infos"),
                    item: this.entityComplianceSchema(this, this.entity)
                });
                if (this.procedureDocuments) {
                    _panels.push({
                        title: this.$t("registration.signed-docs"),
                        item: {
                            is: "SignedDocsCapincOrdersAdmin",
                            props: {
                                entity: this.entity,
                                procedureDocuments: this.procedureDocuments
                            }
                        }
                    });
                }
            } else if (this.entity.shareledgers) {
                _panels.push(
                    {
                        title: this.$t("shareledger.dialog.detail.shares-ownership"),
                        item: {
                            is: "ShareholderSharesPie",
                            props: {
                                shareholder: this.entity
                            }
                        }
                    },
                    {
                        title: this.$t("shareledger.dialog.detail.transfer-history"),
                        item: {
                            is: "TransferHistory",
                            props: {
                                entityId: this.entity.id
                            }
                        }
                    }
                );
            }
            if (this.paymentOrderDetails) {
                _panels.push({
                    title: this.$t("shareledger.dialog.detail.payment-order-details"),
                    item: {
                        is: "PaymentOrderDetails",
                        props: {
                            entityId: this.entity.id,
                            paymentOrderDetails: this.paymentOrderDetails,
                            proofOfFund: this.proofOfFund
                        }
                    }
                });
            }
            return _panels;
        }
    },
    async created() {
        try {
            const res = await this.$api.getCountries();
            this.countries = res.data;
        } catch (e) {
            console.error({ e });
            throw e;
        } finally {
            this.loadingCountries = false;
        }
    }
};
</script>
