import store from "@/store/store";

function getData() {
    const { myEntity, isAuthenticated } = store.getters;
    const entityRepresented =
        store.getters.currentRole && store.getters.currentRole.entity_represented
            ? store.getters.currentRole.entity_represented
            : {};
    return {
        me: myEntity,
        auth: isAuthenticated,
        represented: entityRepresented,
        id: entityRepresented.id
    };
}

function applyRule(rules, to, from, next) {
    const rulePassed = rules.rule();
    if (!rulePassed) {
        console.warn("access route error!");
        store.commit("setLoadingRoute", false);
        store.commit("setFailedRoute", to);
        if (rules.error) rules.error();
        if (rules.redirect) {
            next({
                path: rules.redirect
            });
        }
    } else next();
}

export function informUserIfNotRegistered(to, from, next) {
    const { represented, id } = getData();

    const rules = {
        redirect: `/not-registered/${id}`,
        rule: () => {
            if (!represented) return false;
            // Autorise l'investissement pour les investisseurs des cgps
            // TODO : ameliorer le check, mais c'est secure cote back dans tous les cas
            if (represented && represented.universe === "kyoseil") {
                if (store.getters.useThroughRoles.useIt) {
                    return true;
                }
            }
            return represented.lemonway_state === "success";
        }
    };
    return applyRule(rules, to, from, next);
}

export function requiresAuth(to, from, next) {
    const { auth } = getData();
    const rules = {
        redirect: "/auth",
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-be-logged-in")),
        rule: () => auth === true
    };
    return applyRule(rules, to, from, next);
}

export function requiresUnAuth(to, from, next) {
    const { id, auth } = getData();
    const rules = {
        redirect: `/profile/${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-be-logged-out")),
        rule: () => auth === false || !id || to.fullPath.includes("/auth")
    };
    return applyRule(rules, to, from, next);
}

export function requiresWalletCreated(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/registration/${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-have-wallet-created")),
        rule: () =>
            ["upload", "pending", "upload-failure", "success"].includes(represented.lemonway_state)
    };
    return applyRule(rules, to, from, next);
}

export function requiresRegistered(to, from, next) {
    const { represented } = getData();
    const rules = {
        redirect: null,
        error: () =>
            window.app.$ui.error(window.app.$t("rules.you-must-have-registration-validated")),
        rule: () => represented.lemonway_state === "success"
    };
    return applyRule(rules, to, from, next);
}

export function requiresCompanyRegistered(to, from, next) {
    const { represented, id } = getData();
    let isRegistered = true;
    if (represented.is_company) isRegistered = represented.lemonway_state === "success";
    const rules = {
        redirect: `/registration/${id}/verify`,
        error: () => window.app.$ui.error(window.app.$t("rules.your-company-must-be-registered")),
        error: null,
        rule: () => isRegistered
    };
    return applyRule(rules, to, from, next);
}

export function requiresMyEntityRegistered(to, from, next) {
    const { me, represented } = getData();
    const id = me ? me.id : null;
    const rules = {
        redirect: `/registration/${id}/verify`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-be-registered")),
        error: null,
        rule: () => {
            if (!me) return false;
            // Autorise l'investissement pour les investisseurs des cgps
            // TODO : ameliorer le check, mais c'est secure cote back dans tous les cas
            if (represented && represented.universe === "kyoseil") {
                if (store.getters.useThroughRoles.useIt) {
                    return true;
                }
            }
            return me.lemonway_state === "success";
        }
    };
    return applyRule(rules, to, from, next);
}

export function requiresNotRegistered(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/profile/${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-already-registered")),
        rule: () => represented.lemonway_state !== "success"
    };
    return applyRule(rules, to, from, next);
}

export function requiresCompanyNotSetup(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/profile/${id}`,
        error: () =>
            window.app.$ui.error(window.app.$t("rules.your-company-is-already-configurated")),
        rule: () => !represented.account_type
    };
    return applyRule(rules, to, from, next);
}

export function requiresLemonwayProcessingOrSuccess(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/registration/${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-apply-register")),
        rule: () => ["pending", "upload-failure", "success"].includes(represented.lemonway_state)
    };
    return applyRule(rules, to, from, next);
}

export function requiresIndividual(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/profile/${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-be-individual")),
        rule: () => !represented.is_company && id && represented.account_type === "individual"
    };
    return applyRule(rules, to, from, next);
}

export function requiresCustodian(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/profile/${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-be-custodian")),
        rule: () => represented.account_type === "custodian" && id
    };
    return applyRule(rules, to, from, next);
}

export function requiresCompany(to, from, next) {
    const { represented, id } = getData();
    const isCompany =
        (!!represented.account_type && represented.account_type.includes("company")) ||
        window.app.$entity.isCompanyNotSetted(represented);
    const rules = {
        redirect: `/profile/${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-be-a-company")),
        rule: () => id && isCompany
    };
    return applyRule(rules, to, from, next);
}

export function requiresInvestorRight(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/profile/${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-dont-have-the-right")),
        rule: () => represented.account_type !== "custodian"
    };
    return applyRule(rules, to, from, next);
}

export function requiresDigitalizedShareledger(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/profile/${id}`,
        error: () =>
            window.app.$ui.error(window.app.$t("rules.you-must-have-digitalize-shareledger")),
        rule: () => represented.has_digitalized_shareledger
    };
    return applyRule(rules, to, from, next);
}

export function requiresCompanyOnBlockchain(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/profile/${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-have-digitalize-company")),
        rule: () => {
            return window.app.$blockchain.companyOnBlockchain(represented);
        }
    };
    return applyRule(rules, to, from, next);
}

export function requiresCompanyNotOnBlockchain(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/profile/${id}`,
        error: () =>
            window.app.$ui.error(window.app.$t("rules.you-must-have-not-digitalize-company")),
        rule: () => {
            return !window.app.$blockchain.companyOnBlockchain(represented);
        }
    };
    return applyRule(rules, to, from, next);
}

export function requiresDigitalizedCompany(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/profile/:${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-have-digitalize-company")),
        rule: () => represented.blockchainize_state === "FINISHED"
    };
    return applyRule(rules, to, from, next);
}

export function requiresCompanyToBeNotFromUSA(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/profile/${id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.you-must-be-not-from-usa")),
        rule: () => {
            if (represented.is_company === true && represented.country.alphacode === "US")
                return false;
            return true;
        }
    };
    return applyRule(rules, to, from, next);
}

export function requiresAffiliationRole(to, from, next) {
    const rules = {
        redirect: `/profile/${store.getters.currentRole.entity_represented.id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.not-allowed")),
        rule: () => {
            return window.app.$entity.hasAffiliationRole();
        }
    };
    return applyRule(rules, to, from, next);
}

export function requiresUniverseAdmin(to, from, next) {
    const rules = {
        redirect: `/profile/${store.getters.currentRole.entity_represented.id}`,
        error: () => window.app.$ui.error(window.app.$t("rules.not-allowed")),
        rule: () => {
            return window.app.$entity.isUniverseAdmin();
        }
    };
    return applyRule(rules, to, from, next);
}

export async function capincIsAvailable(to, from, next) {
    // const { id } = getData();
    // const capincId = to.query.capinc_id;
    // let capincIsFinished = true;
    // let error = false;
    // try {
    //     const res = await window.app.$api.getCapitalIncrease(capincId);
    //     capincIsFinished = res.data.finished;
    // } catch (e) {
    //     console.error(e, "capincIsAvailable RouteGuards");
    //     error = true;
    // }
    // const rules = {
    //     redirect: `/profile/${id}`,
    //     error: () =>
    //         window.app.$ui.error(
    //             !!error
    //                 ? window.app.$t("rules.capinc-not-found")
    //                 : window.app.$t("rules.capinc-is-finished")
    //         ),
    //     rule: () => {
    //         if (!!capincIsFinished) return false;
    //         return true;
    //     }
    // };
    const rules = { rule: () => true };
    return applyRule(rules, to, from, next);
}

export function informUserUpgradePlan(to, from, next) {
    const { represented, id } = getData();
    const rules = {
        redirect: `/upgrade-plan/${id}`,
        //TODO set back this when tarification feature working
        // rule: () => represented.is_free_plan === false,
        rule: () => true
    };
    return applyRule(rules, to, from, next);
}
