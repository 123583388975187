<template>
    <div class="text-center">
        <a :href="field.link" target="_blank">
            <v-btn rounded color="primary" dark style="text-transform: none">
                {{ field.name }}
            </v-btn>
        </a>
    </div>
</template>

<script>
export default {
    name: "FieldCta",
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped></style>
