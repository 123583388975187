<template>
    <div class="d-flex flex-column">
        <v-form v-model="validForm">
            <EqsCrudActionBtns
                class="mt-2"
                :has-changed.sync="hasChanged"
                :editable.sync="editable"
                v-bind="{ validForm, cancel, save }"
            />
            <YourPeapmeForm
                v-bind="{
                    schemaDouble,
                    editable,
                    formData,
                    showPea,
                    showPeapme,
                    loading: loading || localLoading
                }"
            />
        </v-form>
    </div>
</template>

<script>
import { formKycMixin } from "@/mixins/kycMixin";
import {
    formErrorMixin,
    progressMixin,
    formChangedMixin
} from "@equisafe-ui-vue/src/mixins/formMixin";
import YourPeapmeForm from "@/components/views/user/profile/content/YourPeapmeForm";

export default {
    name: "Content",
    components: { YourPeapmeForm },
    mixins: [formKycMixin, formErrorMixin, progressMixin, formChangedMixin],
    props: {
        entity: {
            type: Object,
            required: true
        },
        content: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        showPea: {
            type: Boolean,
            required: false,
            default: true
        },
        showPeapme: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    emits: ["update-entity-peapme"],
    data() {
        return {
            editable: false,
            validForm: false,
            formData: {},
            subtitle: "",
            localLoading: false,
            hasChanged: true
        };
    },
    watch: {
        entity: {
            immediate: true,
            handler(val) {
                this.formData = {};
                this.buildFormData(this.schemaDouble, val);
                this.saveFormData();
            }
        },
        content: {
            immediate: true,
            handler(val) {
                const { schemaDouble, subtitle } = val;
                this.schemaDouble = schemaDouble;
                this.subtitle = subtitle;
                this.formData = {};
                this.buildFormData(this.schemaDouble, this.entity);
                this.saveFormData();
            }
        }
    },
    methods: {
        cancel() {
            this.resetFormData();
        },
        async save() {
            try {
                this.localLoading = true;
                const res = await this.$entity.updateEntity(this.formatFormData(this.formData));
                this.saveFormData();
                this.$emit("update-entity-peapme", res);
            } catch (e) {
                this.handleError(e, "entity_update_individual");
                this.editable = true;
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
