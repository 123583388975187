<template>
    <div class="d-flex flex-column">
        <ShareledgerSpecification
            creation
            v-bind="{
                ...$props,
                editable: !shareledger || !shareledger.addtoken_tx
            }"
        />
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import ShareledgerSpecification from "./ShareledgerSpecification.vue";

export default {
    name: "TokenizeYourShares",
    components: {
        ShareledgerSpecification
    },
    mixins: [formMixin],
    props: {
        shareledger: {
            type: Object,
            default: null
        },
        shareledgers: {
            type: Array,
            required: true
        }
    }
};
</script>
