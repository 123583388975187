import DEBUG from "@/store/debug.js";

export default async ({ state, dispatch }, myProjectId) => {
    DEBUG("GET_MY_PROJECT_START");
    if (!myProjectId) throw new Error("no myProjectId");
    let myProject = state.myProjects.find(_myProject => _myProject.id === myProjectId);
    if (!myProject?.shareledger) {
        try {
            myProject = await dispatch("addMyProjectToList", { projectId: myProjectId });
        } catch (e) {
            DEBUG("GET_MY_PROJECT_ERROR");
            window.app.$ui.error(e, "capital_increase_admin_get");
            throw e;
        }
    }
    if (myProject) {
        DEBUG("GET_MY_PROJECT_SUCCESS");
    } else {
        DEBUG("GET_MY_PROJECT_ERROR");
        throw new Error("myProject not found");
    }
    return myProject || {};
};
