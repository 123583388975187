<template>
    <EqsCard
        v-bind="{
            loading,
            subtitle: $t('governance.organs.details.decisions-list.title')
        }"
    >
        <EqsDataTable
            class="px-3"
            v-bind="{
                items: organDecisions,
                loading: decisionsLoading,
                headers,
                itemsPerPage: 5
            }"
        >
            <template #item.date="{ value }">
                {{ $time.formated(value) }}
            </template>
            <template #item.state="{ value }">
                <EqsChip v-bind="decisions_state[value]">
                    {{ decisions_state[value].text }}
                </EqsChip>
            </template>
        </EqsDataTable>
    </EqsCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { decisions_state } from "@/util/governance";

export default {
    name: "DecisionsList",
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        organ: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            decisions_state,
            headers: [
                {
                    text: this.$t("governance.organs.details.activity.headers.date"),
                    value: "date",
                    sortable: false
                },
                {
                    text: this.$t("governance.organs.details.activity.headers.name"),
                    value: "name",
                    sortable: false
                },
                {
                    text: this.$t("governance.organs.details.activity.headers.state"),
                    value: "state",
                    sortable: false
                }
            ]
        };
    },
    async created() {
        this.getDecisions();
    },
    methods: mapActions(["getDecisions"]),
    computed: {
        organDecisions() {
            return this.decisions.filter(decision => decision.organ.id === this.organ.id);
        },
        ...mapGetters(["decisions", "decisionsLoading"])
    }
};
</script>
