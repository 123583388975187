<template>
    <div class="eqs-badge" :el-class="elClass" :style="{ ...getStyle, ...additionalStyle }">
        <v-badge v-bind="{ ...$attrs, ...getData }" v-on="$listeners">
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
        </v-badge>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsBadge.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsBadge",
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-badge"
        },
        size: {
            type: String,
            default: "20px"
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        additionalStyle() {
            let res = {};
            if (this.size)
                res = {
                    "--badge-size": this.size
                };
            return res;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsBadge.scss";
</style>
