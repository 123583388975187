import store from "@/store/store.js";
// TODO: remettre dans les mutations
export default async ({ commit, dispatch }, entityRepresented) => {
    try {
        let currentRole = {};
        if (!entityRepresented) {
            const last_role_id = store.getters.myEntity.role_id;
            // assign default role as current role
            currentRole = store.getters.myRoles.find(role => {
                return last_role_id === role.id;
            });
            if (!currentRole && store.getters.useThroughRoles.useIt) {
                currentRole = store.getters.cgpRoles.find(role => {
                    return last_role_id === role.id;
                });
            }
            console.log({ last_role_id });
        } else {
            currentRole = store.getters.myRoles.find(
                role => role.entity_represented.id === entityRepresented.id
            );
        }
        /*
            Depuis l'opti des rôles, il manque des infos dans l'endpoint roles
            Quand on change de role, on get toutes les données si besoin
            On check un champs qui n'est pas présent dans le TinyEntityRoleSerializer
        */
        if (!currentRole.country) {
            const res = await dispatch("getMyRole", currentRole.id); // Get full role data
            commit("SET_MY_ROLE", res); // Cache value
            currentRole = res; // Return full data
        }
        commit("SET_MY_CURRENT_ROLE", currentRole);
        await dispatch("resetRoleStore");
    } catch (error) {
        console.error(`error in set_my_current_role : ${error}`);
        throw error;
    }
};
