<template>
    <div class="flex">
        <EqsLabel v-if="label" v-bind="{ value: label }" />
        <v-badge
            v-if="truncatedInputText"
            class="copy-badge flex layout no-wrap"
            overlap
            color="none"
        >
            <template v-slot:badge>
                <v-tooltip v-model="show" right z-index="2000">
                    <template #activator="{ active }">
                        <img
                            v-bind="{ width }"
                            class="copy-badge-icon"
                            :src="'/icons/copy.svg'"
                            style="cursor: pointer"
                            v-on="active"
                            @click="copy"
                        />
                    </template>
                    <span>{{ $t("molecule.badge.copied") }}</span>
                </v-tooltip>
            </template>
            <span class="ellipses-one-line" :style="`color:${color}`">
                {{ truncatedInputText }}
                <input :id="`a${_uid}`" type="hidden" :value="value" />
            </span>
        </v-badge>
    </div>
</template>

<script>
import { copyToClipboard } from "@equisafe-ui-shared/util";

export default {
    props: {
        color: {
            type: String,
            default: "primary"
        },
        truncate: {
            type: Number,
            default: 0
        },
        width: {
            type: String,
            default: "16px"
        },
        value: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        labelHeight: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            show: false
        };
    },
    computed: {
        truncatedInputText() {
            if (this.value) {
                if (!this.truncate || this.value.length <= this.truncate) {
                    return this.value;
                } else {
                    return `${this.value.slice(0, this.truncate)}...`;
                }
            } else {
                return "";
            }
        }
    },
    methods: {
        async copy() {
            if (!this.value) return;
            copyToClipboard(`a${this._uid}`);
            this.show = true;
            setTimeout(() => {
                this.show = false;
            }, 1000);
        }
    }
};
</script>

<style lang="scss">
.copy-badge {
    max-width: 90%;
    .v-badge__badge {
        background-color: none !important;
        right: -35px !important;
        top: -6px !important;
    }
    i {
        font-size: 12px !important;
    }
}
</style>
