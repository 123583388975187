<template>
    <div class="ma-0 pa-0">
        <div v-if="showPea" class="pa-0 ma-0">
            <p class="text-h6">{{ $t("user.profile.pea-data.form") }}</p>
            <v-combobox
                v-model="peapmedata.bank_pea"
                :label="$t('user.profile.pea-data.bank')"
                :items="bankList"
                outlined
                clearable
                required
                :rules="rulesBank"
                :disabled="!editable"
            />
            <v-text-field
                v-model="peapmedata.no_pea"
                :label="$t('user.profile.pea-data.number')"
                outlined
                clearable
                required
                :rules="rulesNumber"
                :disabled="!editable"
            />
            <v-text-field
                v-model="peapmedata.iban_pea"
                :label="$t('user.profile.pea-data.iban')"
                outlined
                clearable
                required
                :rules="rulesIban"
                :disabled="!editable"
            />
            <v-text-field
                v-model="peapmedata.bic_pea"
                :label="$t('user.profile.pea-data.bic')"
                outlined
                clearable
                required
                :rules="rulesBic"
                :disabled="!editable"
            />
            <v-text-field
                v-model="peapmedata.email_pea"
                :label="$t('user.profile.pea-data.email')"
                outlined
                clearable
                required
                :rules="rulesEmail"
                :disabled="!editable"
            />
            <EqsFileInput
                :value.sync="peapmedata.rib_pea"
                v-bind="{
                    elClass: `rib-pea`,
                    label: $t('user.profile.pea-data.rib'),
                    deleteFile: true,
                    editable,
                    loading,
                    upload,
                    dataType: 'file',
                    others: {
                        key: 'rib_pea'
                    },
                    isRequired: true
                }"
            />
        </div>
        <v-divider v-if="showPea && showPeapme" class="mt-8" />
        <div v-if="showPeapme" class="pa-0 ma-0">
            <p class="text-h6">{{ $t("user.profile.peapme-data.form") }}</p>
            <v-combobox
                v-model="peapmedata.bank_peapme"
                :label="$t('user.profile.peapme-data.bank')"
                :items="bankList"
                outlined
                clearable
                :rules="rulesBank"
                :disabled="!editable"
            />
            <v-text-field
                v-model="peapmedata.no_peapme"
                :label="$t('user.profile.peapme-data.number')"
                outlined
                clearable
                :rules="rulesNumber"
                :disabled="!editable"
            />
            <v-text-field
                v-model="peapmedata.iban_peapme"
                :label="$t('user.profile.peapme-data.iban')"
                outlined
                clearable
                :rules="rulesIban"
                :disabled="!editable"
            />
            <v-text-field
                v-model="peapmedata.bic_peapme"
                :label="$t('user.profile.peapme-data.bic')"
                outlined
                clearable
                :rules="rulesBic"
                :disabled="!editable"
            />
            <v-text-field
                v-model="peapmedata.email_peapme"
                :label="$t('user.profile.peapme-data.email')"
                outlined
                clearable
                :rules="rulesEmail"
                :disabled="!editable"
            />
            <EqsFileInput
                :value.sync="peapmedata.rib_peapme"
                v-bind="{
                    elClass: `rib-peapme`,
                    label: $t('user.profile.peapme-data.rib'),
                    deleteFile: true,
                    editable,
                    loading,
                    upload,
                    dataType: 'file',
                    others: {
                        key: 'rib_peapme'
                    },
                    isRequired: false
                }"
            />
        </div>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { inputFormMixin } from "@equisafe-ui-vue/src/mixins/inputMixin";
import { bankList } from "@/components/views/user/profile/content/peapmeSchemaDouble";

import { requiredR, bicR, ibanR, bankR, peaNumberR, emailR } from "@equisafe-ui-vue/src/rules";

export default {
    name: "YourPeapmeForm",
    mixins: [formMixin, inputFormMixin],
    props: {
        profile: {
            type: Boolean,
            default: false
        },
        upload: {
            type: Function,
            default: null
        },
        showPea: {
            type: Boolean,
            required: false,
            default: true
        },
        showPeapme: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            bankList,
            peapmedata: {
                bank_pea: "",
                no_pea: "",
                iban_pea: "",
                email_pea: "",
                bic_pea: "",
                rib_pea: null,
                rib_pea_id: "",
                bank_peapme: "",
                no_peapme: "",
                iban_peapme: "",
                email_peapme: "",
                bic_peapme: "",
                rib_peapme: null,
                rib_peapme_id: ""
            }
        };
    },
    computed: {
        rulesBank() {
            return [requiredR, bankR];
        },
        rulesNumber() {
            return [requiredR, peaNumberR];
        },
        rulesIban() {
            return [requiredR, ibanR];
        },
        rulesBic() {
            return [requiredR, bicR];
        },
        rulesEmail() {
            return [emailR];
        }
    },
    watch: {
        peapmedata: {
            handler(val) {
                let tmpObj = { ...this.peapmedata };

                if (tmpObj.rib_pea) {
                    tmpObj.rib_pea_id = tmpObj.rib_pea.id;
                } else {
                    delete tmpObj.rib_pea_id;
                }
                if (tmpObj.rib_peapme) {
                    tmpObj.rib_peapme_id = tmpObj.rib_peapme.id;
                } else {
                    delete tmpObj.rib_peapme_id;
                }
                const tmpObj2 = Object.fromEntries(
                    Object.entries(tmpObj).map(([k, v]) => [k, v === null ? "" : v])
                );
                this.$set(this.formData, "peapmedata", tmpObj2);
            },
            deep: true
        }
    },
    beforeMount() {
        const peapmedata = this.$entity?.represented()?.peapmedata;
        if (peapmedata) {
            this.peapmedata = { ...peapmedata };
        }
    },
    methods: {}
};
</script>
