<template>
    <EqsCard
        v-if="nonVoters.length"
        v-bind="{
            subtitle: $t('governance.organs.details.non-voting.title'),
            loading
        }"
        class="px-3"
    >
        <template #main>
            <EqsDataTable
                hide-default-footer
                class="px-3"
                v-bind="{
                    items: nonVoters,
                    headers,
                    itemsPerPage: -1
                }"
                sort-by="investor.name"
                :sort-desc="[true]"
            >
                <template #item.investor.entity="{ value }">
                    <EqsAvatar no-badge v-bind="{ user: value, eqsType: 'list' }" />
                </template>
            </EqsDataTable>
        </template>
    </EqsCard>
</template>

<script>
export default {
    name: "NonVoting",
    props: {
        organ: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            headers: [
                {
                    value: "investor.entity",
                    sortable: false
                },
                {
                    text: this.$t("governance.organs.details.non-voting.headers.name"),
                    value: "investor.name",
                    sortable: false
                }
            ]
        };
    },
    computed: {
        nonVoters() {
            return this.organ.members
                ? this.organ.members.filter(mb => !mb.is_voter && mb.investor)
                : [];
        }
    },
    methods: {}
};
</script>
