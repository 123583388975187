<template>
    <div class="eqs-text-area" :el-class="elClass">
        <EqsLabel
            v-bind="{
                ...$attrs,
                label,
                height: labelHeight,
                editable,
                value: label,
                infoTxt,
                isRequired
            }"
        >
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <EqsEditable paragraph v-bind="{ ...$attrs, editable, value: editableData }">
                <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                <EqsSkeleton v-bind="{ loading, type: 'paragraph' }">
                    <v-textarea
                        v-bind="{
                            readonly,
                            placeholder,
                            disabled,
                            ...$attrs
                        }"
                        :background-color="$color.get().inputBackground"
                        :el-class="`${elClass}-field`"
                        :value="valueString"
                        :rules="rulesRequired"
                        :style="getStyle"
                        :class="`${getClassFromState} rounded-lg`"
                        :clearable="!readonly && !disabled"
                        hide-details="auto"
                        outlined
                        filled
                        rounded
                        dense
                        auto-grow
                        v-on="$listeners"
                        @input="handlerForm"
                    >
                        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                    </v-textarea>
                </EqsSkeleton>
            </EqsEditable>
            <div v-show="false" :el-class="`${elClass}-value`">
                {{ valueString }}
            </div>
        </EqsLabel>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsTextArea.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";

export default {
    name: "EqsTextArea",
    components: {
        EqsLabel: () => import("../EqsLabel/EqsLabel.vue"),
        EqsEditable: () => import("../EqsEditable/EqsEditable.vue"),
        EqsSkeleton: () => import("../EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: "eqs-text-area"
        },
        value: {
            validator: prop => typeof prop === "string" || prop === null || prop === undefined,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        getClassFromState() {
            let res = "eqs-text-area__input";
            if (this.readonly) res += " read-only";
            return res;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsTextArea.scss";
</style>
