// import { difference, flat } from "@equisafe-ui-shared/util";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { lemonwayStatusItem } from "../globals/lemonway.js";
import { anyFieldChanged } from "../../../shared/util";

export const formMixin = {
    props: {
        formData: {
            type: Object,
            required: true
        },
        savedFormData: {
            type: Object,
            default: () => {}
        },
        schemaDouble: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        formType: {
            type: String,
            default: "main"
        }
    }
};

export const formErrorMixin = {
    data() {
        return {
            error: false,
            oldFormData: {},
            watchersErrors: {},
            lemonwayError: false
        };
    },
    created() {
        if (!this.formData)
            console.error("formData is required if you want to use formErrorMixin !");
    },
    beforeDestroy() {
        this.resetWatchersError();
    },
    methods: {
        isLWError(file) {
            const res = lemonwayStatusItem(this, file.lemonway_status, file.lemonway_reason);
            if (res.status === "error" || res.status === "warning") {
                this.lemonwayError = true;
                return true;
            }
            return false;
        },
        resetError(val, key) {
            let item = this.schemaDouble[key];
            if (!item) return;
            if (!val || val !== this.watchersErrors[key].value) item["error-messages"] = [];
            else {
                item["error-messages"] = this.watchersErrors[key].error;
            }
        },
        resetWatchersError() {
            if (!!this.watchersErrors) {
                for (const [key, value] of Object.entries(this.watchersErrors)) {
                    this.resetError("", key);
                    value.destroyWatcher();
                    value.value = null;
                    value.error = null;
                }
            }
        },
        handleError(e, errorKey) {
            this.error = true;
            this.resetWatchersError();
            /*
             ** above call useless for _Bank at least
             ** dont remove in case mixin use somewhere forgotten
             ** protected the new resetWatchersError against empty calls anyway
             */
            if (e.data) {
                for (const [key, value] of Object.entries(e.data)) {
                    let item = this.schemaDouble[key];
                    if (!!item) {
                        item["error-messages"] = value;
                        this.watchersErrors[key] = {
                            destroyWatcher: this.$watch(`formData.${key}`, val =>
                                this.resetError(val, key)
                            ),
                            value: cloneDeep(this.formData[key]),
                            error: value
                        };
                    }
                    /*
                     ** here is where we would force recompute if need be to display error-messages
                     ** but errors should be displayed by default if schemaDouble change and is expend in every form field
                     ** The issue with forcing recompute is that it caches the schemaDouble modification until manual reload
                     ** So we cant leave the component and come back with the same schemaDouble even if we deep save it in beforeMount/beforeDestroy
                     */
                }
            } else {
                return window.app.$ui.error(e, errorKey);
            }
        }
    }
};

export const progressMixin = {
    data() {
        return {
            progress: 0,
            schemaDouble: {}
        };
    },
    computed: {
        requiredFields() {
            return this.getSchemaDoubleRequired(this.schemaDouble);
        }
    },
    watch: {
        formData: {
            immediate: true,
            handler(val) {
                if (!!val) this.getProgress(val);
            },
            deep: true
        },
        step: {
            handler() {
                // nextTick to be sure schemaDouble has been updated
                this.$nextTick(() => {
                    this.getProgress(this.formData);
                });
            }
        }
    },
    created() {
        if (!this.formData)
            console.error("formData is required if you want to use progressMixin !");
    },
    methods: {
        getSchemaDoubleRequired(schemaDouble) {
            if (isEmpty(schemaDouble)) return [];
            let required = [];
            for (const [key, value] of Object.entries(schemaDouble)) {
                if (!!value && value.isRequired) {
                    required.push({ key, ...value });
                }
            }
            return required;
        },
        requiredFilled(form, field) {
            let res = false;
            switch (field.dataType) {
                case "multi-file":
                    let numFiles = 0;
                    for (const category of field.categories) {
                        const matching = form[category.key];
                        if (matching) {
                            if (this.isLWError && this.isLWError(matching)) break;
                            numFiles++;
                        }
                    }
                    res = numFiles >= field.numSelect;
                    break;
                case "file":
                    const doc = form[field.key];
                    if (doc && this.isLWError && this.isLWError(doc)) return false;
                    res = !!doc;
                    break;
                case "object":
                    res = !isEmpty(form[field.key]);
                    break;
                case "array":
                    res = !isEmpty(form[field.key]);
                    break;
                case "array_of_id":
                    res = !isEmpty(form[field.key]);
                    break;
                case "array_of_url":
                    res = !isEmpty(form[field.key]);
                    break;
                case "yes_or_no":
                    res = ["yes", "no", true, false].includes(form[field.key]);
                    break;
                case "float":
                    if (form[field.key] === 0.0) {
                        res = true;
                    } else {
                        res = !!form[field.key];
                    }
                    break;
                case "int":
                    if (form[field.key] === 0) {
                        res = true;
                    } else {
                        res = !!form[field.key];
                    }
                    break;
                default:
                    res = !!form[field.key];
                    break;
            }
            return res;
        },
        getProgress(formData) {
            let res = 0;
            if (formData) {
                const numRequiredFilled = this.requiredFields.filter(field =>
                    this.requiredFilled(this.formData, field)
                ).length;
                res = this.$currency.getPercent(numRequiredFilled, this.requiredFields.length);
            }
            this.progress = res;
        }
    }
};

export const lastFormStepMixin = {
    mixins: [progressMixin],
    methods: {
        getLastFormStep(data, stopIndex = -1) {
            let stepN = 0;
            for (const step of this.steps) {
                const requiredFields = this.getSchemaDoubleRequired(step.schemaDouble);
                if (
                    (!step.bypass &&
                        !requiredFields.every(field => {
                            /*
Astuce: pour bypass la validation du form avec des objects
_local_valid est un champs spéciale dans le schemaDouble
Il permet d'avoir la fonctionnalité du "Valider" en rouge en gérant
manuellement la validité du formulaire.
Par exemple dans le tunnel de souscription
*/
                            if (field.key === "_local_valid") {
                                return true;
                            }
                            const res = this.requiredFilled(data, field);
                            if (!res)
                                console.log(
                                    `getLastFormStep: Required field "${field.key}" for step "${step.title}" not filled. Val for this field is:`,
                                    data[field.key]
                                );
                            return res;
                        })) ||
                    (stopIndex >= 0 && stepN == stopIndex)
                )
                    break;
                stepN++;
            }
            /*
                Si le premier step est une page d'info sans formulaire, on force son affichage
                seulement si l'étape d'après n'est pas validée.
                Par exemple pour le PSFP
            */
            const requiredFields = this.getSchemaDoubleRequired(this.steps[0].schemaDouble);
            if (requiredFields.length === 0 && stepN <= 1) {
                return 0;
            }
            return stepN < this.steps.length ? stepN : stepN - 1;
        }
    },
    created() {
        if (!Array.isArray(this.steps)) {
            console.error(`The component '${this.$parent.$options.name}' need to have a variable 'steps' \
      of Array type to be hable to use 'lastFormStepMixin'`);
        }
    }
};

export const buildFormDataMixin = {
    methods: {
        buildFormData(schema, data = {}) {
            Object.entries(schema).forEach(entry => {
                const key = entry[0];
                const item = entry[1];
                switch (item.dataType) {
                    case "id":
                        this.$set(this.formData, key, data[key] || null);
                        break;
                    case "url":
                        this.$set(this.formData, key, data[key] || null);
                        break;
                    case "file":
                        this.$set(this.formData, key, data[key] || null);
                        break;
                    case "object":
                        this.$set(this.formData, key, data[key] || {});
                        break;
                    case "multi-file":
                        this.$set(this.formData, key, data[key] || []);
                        break;
                    case "array":
                        this.$set(this.formData, key, data[key] || []);
                        break;
                    case "array_of_id":
                        this.$set(this.formData, key, data[key] || []);
                        break;
                    case "array_of_url":
                        this.$set(this.formData, key, data[key] || []);
                        break;
                    case "bool":
                        this.$set(this.formData, key, data[key] || false);
                        break;
                    case "float":
                        const floatParsed = parseFloat(data[key]);
                        this.$set(this.formData, key, floatParsed === 0.0 ? "0.0" : floatParsed);
                        break;
                    case "int":
                        const intParsed = parseInt(data[key]);
                        this.$set(this.formData, key, intParsed === 0 ? "0" : intParsed);
                        break;
                    case "yes_or_no":
                        let res = "";
                        if (data[key] === true) res = "yes";
                        else if (data[key] === false) res = "no";
                        this.$set(this.formData, key, res);
                        break;
                    default:
                        this.$set(this.formData, key, data[key] || "");
                }
            });
        }
    }
};

export const formatFormDataMixin = {
    methods: {
        formatFormData(schema, data) {
            let res = {};
            Object.entries(schema).forEach(entry => {
                const key = entry[0];
                const item = entry[1];
                if (!item.bypass) {
                    switch (item.dataType) {
                        case "file":
                            res[`${key}_id`] =
                                this.formData[key] && this.formData[key].id
                                    ? this.formData[key].id
                                    : null;
                            break;
                        case "id":
                            res[`${key}_id`] =
                                this.formData[key] && this.formData[key].id
                                    ? this.formData[key].id
                                    : null;
                            break;
                        case "url":
                            res[key] =
                                this.formData[key] && this.formData[key].url
                                    ? this.formData[key].url
                                    : null;
                            break;
                        case "multi-file":
                            res[key] =
                                this.formData[key] && this.formData[key].length
                                    ? this.formData[key].map(file => file.url)
                                    : [];
                            break;
                        case "bool":
                            res[key] = this.formData[key] || false;
                            break;
                        case "object":
                            if (!isEmpty(this.formData[key])) res[key] = this.formData[key];
                            break;
                        case "array_of_id":
                            if (this.formData[key].length)
                                res[key] = this.formData[key].map(item => item.id);
                            break;
                        case "array_of_url":
                            if (this.formData[key].length)
                                res[key] = this.formData[key].map(item => item.url);
                            break;
                        case "float":
                            res[key] = parseFloat(this.formData[key]);
                            break;
                        case "int":
                            res[key] = parseInt(this.formData[key]);
                            break;
                        case "yes_or_no":
                            if (this.formData[key] === "yes") res[key] = true;
                            else if (this.formData[key] === "no") res[key] = false;
                            break;
                        default:
                            if (this.formData[key] === "") res[key] = null;
                            else res[key] = this.formData[key];
                    }
                }
            });
            return (res = { ...res, ...data });
        }
    }
};

export const formChangedMixin = {
    data() {
        return {
            savedFormData: {},
            editable: false,
            hasChanged: false,
            noChangesError: ""
        };
    },
    computed: {
        errorNoFieldsChanged() {
            if (!this.schemaDouble) return false;
            return !this.anyFieldChanged(
                this.formData,
                this.savedFormData,
                Object.keys(this.schemaDouble)
            );
        }
    },
    watch: {
        errorNoFieldsChanged(val) {
            if (!val) {
                if (this.editable) this.hasChanged = true;
                this.noChangesError = "";
            } else this.hasChanged = false;
        }
    },
    methods: {
        saveFormData() {
            this.savedFormData = cloneDeep(this.formData);
        },
        resetFormData() {
            this.formData = cloneDeep(this.savedFormData);
        },
        anyFieldChanged,
        anyFieldChangedForKeys(keys) {
            return this.anyFieldChanged(this.formData, this.savedFormData, keys);
        }
    }
};

export const stepperFormMixins = {
    mixins: [
        formErrorMixin,
        progressMixin,
        lastFormStepMixin,
        buildFormDataMixin,
        formatFormDataMixin,
        formChangedMixin
    ]
};
