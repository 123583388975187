<template>
    <v-container v-if="!localLoading" fluid>
        <h2 class="text-h2 mb-8">{{ agItem.name }}</h2>
        <Breadcrumbs />
        <v-divider />
        <v-btn small color="primary" @click="DEV_goToPresenceSheet">
            DEV: Vue feuille de présence
        </v-btn>
        <v-container fluid class="ag-container">
            <v-row justify="center">
                <v-col cols="3" class="pr-6 pl-6 hidden-md-and-down">
                    <v-img
                        class="fill-height"
                        :aspect-ratio="1"
                        lazy-src="https://picsum.photos/id/11/10/6"
                        src="https://picsum.photos/id/11/500/300"
                    />
                </v-col>
                <v-col>
                    <v-tabs v-model="tabSelected" centered>
                        <v-tab
                            v-for="(itemTabHeader, i) in tabItems"
                            :key="i"
                            class="mx-6"
                            :class="i === 0 ? 'ml-12' : ''"
                            :href="`#tab-${i}`"
                        >
                            {{ itemTabHeader.label }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabSelected" class="pa-6">
                        <v-tab-item
                            v-for="(itemTabItem, i) in tabItems"
                            :key="i"
                            :value="`tab-${i}`"
                        >
                            <template #default>
                                <component
                                    :is="itemTabItem.component"
                                    v-bind="{
                                        agItem,
                                        loading: localLoading
                                    }"
                                />
                            </template>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid>
            <v-row dense class="ma-4">
                <v-col class="text-right">
                    <v-btn> Normal </v-btn>
                    <v-btn> Normal </v-btn>
                </v-col>
            </v-row>
            <v-row dense class="ma-4">
                <v-col class="text-left">
                    <v-btn @click="backToList">Retour</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import Breadcrumbs from "../Breadcrumbs";

import AgDetailInformation from "./AgDetailInformation";
import AgDetailDocument from "./AgDetailDocument";
import AgDetailMember from "./AgDetailMember";

export default {
    name: "AgDetail",
    components: { AgDetailInformation, AgDetailDocument, AgDetailMember, Breadcrumbs },
    props: {
        item: {
            type: Object,
            required: false,
            nullable: true,
            default: null
        }
    },
    data() {
        return {
            localLoading: true,
            agItem: null,
            tabSelected: "tab-0"
        };
    },
    computed: {
        tabItems() {
            return [
                { label: "Informations", component: AgDetailInformation },
                { label: "Documentation", component: AgDetailDocument },
                { label: "Invités", component: AgDetailMember }
            ];
        }
    },
    beforeMount() {
        if (!this.item) {
            this.getAgDetail();
        } else {
            this.agItem = this.item;
            if (!this.agItem.members) {
                this.getAgMembers();
            } else {
                this.localLoading = false;
            }
        }
    },
    methods: {
        backToList() {
            this.$router.go(-1);
        },
        async getAgDetail() {
            if (this.$route.params.agId) {
                const res = await this.$api.getAg(this.$route.params.agId);
                this.agItem = res.data;
                this.getAgMembers();
                this.localLoading = false;
            }
        },
        async getAgMembers() {
            this.agItem.members = [];
            const res = await this.$api.getAgMembers(this.agItem.id);
            // Remove self
            // res.data = res.data.filter(e => e.id !== this.agItem.member.id);
            for (let i = 0; i < res.data.length; i++) {
                res.data[i].signed = i % 2 != 0;
                if (res.data[i].id === this.agItem.member.id) {
                    res.data[i].self = true;
                } else {
                    res.data[i].self = false;
                }
            }
            Object.assign(this.agItem, { members: res.data });
            this.localLoading = false;
        },
        DEV_goToPresenceSheet() {
            this.$router.push({
                name: "ag-presence-sheet",
                params: { agId: this.agItem.id }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.v-tab {
    text-transform: capitalize;
}

:deep(.v-tabs-bar),
.theme--light.v-card,
.theme--light.v-tabs-items {
    // background-color: transparent !important;
}

.ag-container {
    // height: 500px;
}
</style>
