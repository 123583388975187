<template>
    <EqsSkeleton class="global-text-editor" type="article" v-bind="{ loading }">
        <div class="ProseMirror" v-html="getSanitizedHtml" />
    </EqsSkeleton>
</template>

<script>
export default {
    name: "GlobalTextEditorDisplay",
    props: {
        value: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        getSanitizedHtml() {
            if (this.loading) return "";
            const val = this.$text.sanitize(this.value);
            return val;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../globalTextEditor/proseMirror.scss";
.global-text-editor {
    .ProseMirror {
        background-color: transparent;
        padding: 0px;
    }
}
</style>
