var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"fill-height",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('EqsStepper',_vm._b({staticStyle:{"height":"100%"},attrs:{"value":_vm.step},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"eqs-main-container margin-auto px-2"},[(item.text)?_c('EqsTextDisplay',{staticClass:"text-center pb-5",attrs:{"value":item.text}}):_vm._e(),_c(item.is,_vm._b({tag:"component",staticClass:"fill-height fill-width"},'component',{
                            item,
                            formData: _vm.formData,
                            schemaDouble: _vm.schemaDouble,
                            txComplete: true,
                            companyBlockchainSetted: _vm.companyBlockchainSetted,
                            loading: _vm.loadingBlockchain || !!_vm.tx.hash
                        },false)),_c('v-slide-y-transition',{attrs:{"hide-on-leave":""}},[(_vm.companyBlockchainSetted && _vm.loadingBlockchain)?_c('EqsAlert',{staticClass:"mt-5"},[_c('div',{staticClass:"paragraph"},[_vm._v(" "+_vm._s(_vm.$t( "shareledger.dialog.stepper.blockchainize-your-company.loading-message" ))+" ")])]):_vm._e()],1),_c('v-slide-y-transition',{attrs:{"hide-on-leave":""}},[(_vm.companyBlockchainSetted && _vm.loadingBlockchain)?_c('GlobalBlockchainLoader',_vm._b({},'GlobalBlockchainLoader',{ height: '42vh' },false)):_vm._e()],1),_c('v-slide-y-transition',{attrs:{"hide-on-leave":""}},[(_vm.contractWithError)?_c('EqsAlert',{staticClass:"mt-5",attrs:{"eqs-type":"error"}},[_c('div',{staticClass:"paragraph"},[_vm._v(" "+_vm._s(_vm.$t( "shareledger.dialog.stepper.blockchainize-your-company.error-message", { universeMail: _vm.universeMail } ))+" ")])]):_vm._e()],1),(_vm.contractWithError)?_c('v-input',_vm._b({staticStyle:{"display":"none"}},'v-input',{ errorMessages: ['dummy'] },false)):_vm._e()],1)]}}])},'EqsStepper',{
                steps: _vm.steps,
                progress: _vm.progress
            },false))]},proxy:true}])},'EqsCard',{
        loading: _vm.loading || _vm.loadingBlockchainState,
        height: '100%'
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }