import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, investor) => {
    DEBUG("UPDATE_INVESTOR_START");
    try {
        commit("SET_INVESTORS_LOADING", true);
        const investorRes = await window.app.$api.patchInvestor(investor.id, investor);
        DEBUG("UPDATE_INVESTOR_SUCCESS");
        commit("UPDATE_INVESTOR", investorRes.data);
    } catch (e) {
        DEBUG("UPDATE_INVESTOR_ERROR");
        throw e;
    } finally {
        commit("SET_INVESTORS_LOADING", false);
    }
};
