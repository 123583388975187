<template>
    <div class="mt-5 text-center">
        {{ $t("ui-error.soon") }}
    </div>
</template>

<script>
export default {
    name: "Soon",
    data() {
        return {};
    },
    methods: {}
};
</script>
