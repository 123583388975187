<template>
    <span class="full-width">
        <EqsAutocomplete
            :loading-items="investorsLoading"
            :value.sync="valueProxy"
            :items="filteredInvestors"
            return-object
            item-value="id"
            item-text="name"
            :hint="getHint"
            persistent-hint
            v-bind="{ ...$attrs, elClass: 'choose-investor' }"
            v-on="$listeners"
        >
            <template #prepend-item>
                <v-list-item @click="addInvestor">
                    <v-icon class="px-2"> mdi-plus </v-icon>
                    <v-list-item-content class="ml-2">
                        {{ $t("molecule.form.invite-investor") }}
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="pb-2" />
            </template>
            <template #selection="{ item }">
                <EqsAvatar
                    v-if="item && item.entity"
                    v-bind="{ user: item.entity, eqsType: 'list' }"
                    color="white"
                    size="80%"
                    no-badge
                />
                <v-list-item-content v-if="item" :class="item.entity ? 'ml-2' : ''">
                    {{ item.name }}
                </v-list-item-content>
            </template>
            <template #item="{ parent, item }">
                <EqsAvatar
                    v-if="item && item.entity"
                    v-bind="{ user: item.entity, eqsType: 'list' }"
                    color="white"
                    size="100%"
                    no-badge
                />
                <v-list-item-content v-if="item" :class="item.entity ? 'ml-2' : ''">
                    <div v-html="parent.genFilteredText(item.name)" />
                </v-list-item-content>
                <v-list-item-action v-if="item && item.entity">
                    <div v-if="item.entity.lemonway_state !== 'success'">
                        {{ $t("molecule.form.pending") }}
                    </div>
                </v-list-item-action>
            </template>
        </EqsAutocomplete>
        <GlobalInviteInvestorDialog
            ref="inviteInvestorDialog"
            :group="false"
            :value.sync="inviteDialog"
            :loading="investorsLoading"
        />
    </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import isEmpty from "lodash/isEmpty";

export default {
    name: "GlobalChooseInvestor",
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        filter: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            inviteDialog: false
        };
    },
    created() {
        this.getInvestors();
    },
    computed: {
        ...mapGetters(["investors", "investorsLoading"]),
        filteredInvestors() {
            return this.filter ? this.investors.filter(this.filter) : this.investors;
        },
        getHint() {
            if (isEmpty(this.valueProxy)) return "";
            if (!this.valueProxy.entity.is_company)
                return this.valueProxy.email
                    ? `${this.$t("label.email")}: ${this.valueProxy.email}`
                    : "";
            else if (this.valueProxy.company_idcode)
                return this.valueProxy.company_idcode
                    ? `${this.$t("label.company-id")}: ${this.valueProxy.company_idcode}`
                    : "";
            return "";
        },
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    },
    watch: {
        inviteDialog(val) {
            if (
                val ||
                !this.$refs.inviteInvestorDialog ||
                !this.$refs.inviteInvestorDialog.invitedInvestorId
            )
                return;

            const investorId = this.$refs.inviteInvestorDialog.invitedInvestorId;
            const investor = this.filteredInvestors.find(investor => investor.id === investorId);
            if (investor) this.valueProxy = investor;
        }
    },
    methods: {
        ...mapActions(["getInvestors"]),
        addInvestor() {
            this.inviteDialog = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.v-autocomplete__content .v-list__tile {
    height: auto;
}
.choose-investor-avatar {
    .eqs-badge {
        height: inherit;
    }
}
</style>
