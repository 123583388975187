var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column fill-height fill-width overflow-y pa-0"},[_c('Organs',_vm._g(_vm._b({attrs:{"editable":"","hide-default-footer":""}},'Organs',{
            height: '100%',
            loading: _vm.loading,
            add: {
                fn: _vm.addOrgan,
                txt: _vm.$t('governance.organs.add')
            },
            noDataCustom: {
                title: _vm.$t('governance.organs.no-data-title'),
                text: _vm.$t('governance.organs.no-data-text'),
                img: '/images/governance/organe.svg'
            },
            ..._vm.$attrs
        },false),_vm.$listeners)),_c('Representatives',_vm._g(_vm._b({attrs:{"editable":""}},'Representatives',{
            height: '100%',
            loading: _vm.loading,
            add: {
                fn: _vm.addRepresentative,
                txt: _vm.$t('governance.representatives.add')
            },
            searchable: {
                placeholder: _vm.$t('governance.representatives.search')
            },
            noDataCustom: {
                title: _vm.$t('governance.representatives.no-data-title'),
                text: _vm.$t('governance.representatives.no-data-text'),
                img: '/images/governance/mandats.svg'
            },
            itemsPerPage: 10,
            ..._vm.$attrs
        },false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }