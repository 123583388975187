<template>
    <div class="eqs-password">
        <EqsText
            v-bind="$attrs"
            :basic-rules="$options.rules"
            :append-icon="passwordView ? 'mdi-eye' : 'mdi-eye-off'"
            :type="passwordView ? 'text' : 'password'"
            editable
            counter
            v-on="$listeners"
            @click:append="passwordView = !passwordView"
        >
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
        </EqsText>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsPassword.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { passwordR, max100R } from "../../../rules";

export default {
    name: "EqsPassword",
    rules: [passwordR, max100R],
    components: {
        EqsText: () => import("../EqsText/EqsText.vue")
    },
    mixins: [eqsUITemplate],
    data() {
        return {
            cssTypes,
            dataTypes,
            passwordView: false
        };
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsPassword.scss";
</style>
