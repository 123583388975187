import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, dispatch }, representative) => {
    DEBUG("UPDATE_REPRESENTATIVE_START");
    try {
        commit("SET_REPRESENTATIVES_LOADING", true);
        const representativeRes = await window.app.$api.updateRepresentative(
            representative.id,
            representative
        );
        let _representative = representativeRes.data;
        const investor = await dispatch("getInvestor", _representative.investor_id);
        Object.assign(_representative.investor, investor);
        DEBUG("UPDATE_REPRESENTATIVE_SUCCESS");
        commit("UPDATE_REPRESENTATIVE", _representative);
    } catch (e) {
        DEBUG("UPDATE_REPRESENTATIVE_ERROR");
        throw e;
    } finally {
        commit("SET_REPRESENTATIVES_LOADING", false);
    }
};
