import DOMPurify from "dompurify";

export class TextService {
    constructor() {
        const TEMPORARY_ATTRIBUTE = "data-temp-href-target";
        DOMPurify.addHook("beforeSanitizeAttributes", function (node) {
            if (node.tagName === "A") {
                if (!node.hasAttribute("target")) {
                    node.setAttribute("target", "_self");
                }

                if (node.hasAttribute("target")) {
                    node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute("target"));
                }
            }
        });

        DOMPurify.addHook("afterSanitizeAttributes", function (node) {
            if (node.tagName === "A" && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
                node.setAttribute("target", node.getAttribute(TEMPORARY_ATTRIBUTE));
                node.removeAttribute(TEMPORARY_ATTRIBUTE);
                if (node.getAttribute("target") === "_blank") {
                    node.setAttribute("rel", "noopener");
                }
            }
        });
        this.DOMPurify = DOMPurify;
    }
    sanitize(html) {
        return this.DOMPurify.sanitize(html);
    }
}
