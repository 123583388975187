import init from "./init";
import Vue from "vue";

export default {
    SET_PROCEDURES(state, procedures) {
        state.procedures = procedures;
        state.proceduresInit = false;
    },
    ADD_PROCEDURE(state, procedure) {
        state.procedures = [procedure, ...state.procedures];
    },
    UPDATE_PROCEDURE(state, procedure) {
        const index = state.procedures.findIndex(_procedure => _procedure.id === procedure.id);
        Vue.set(state.procedures, index, procedure);
    },
    DELETE_PROCEDURE(state, procedureId) {
        const index = state.procedures.findIndex(procedure => procedure.id === procedureId);
        if (index > -1) state.procedures.splice(index, 1);
    },
    SET_PROCEDURES_LOADING(state, bool) {
        state.proceduresLoading = bool;
    },
    RESET_STORE_PROCEDURES(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
