import { timeService } from "@equisafe-ui-vue/src/services/time";

export function isCapincEnded(capinc) {
    return (
        capinc.finished ||
        timeService.isPast(capinc.end_date) ||
        ["delivery", "cancellation"].includes(capinc.phase)
    );
}

export function isCapincToCome(capinc) {
    return (
        !isCapincEnded(capinc) &&
        (capinc.phase === "pending" || !timeService.isPast(capinc.start_date))
    );
}
