<template>
    <div class="flex fill-height">
        <v-row
            justify="space-around"
            :class="`d-flex mx-2 justify-start ${$reactive.isMobile() ? 'flex-wrap' : ''}`"
        >
            <v-col cols="12" lg="6">
                <MyAssets
                    v-bind="{ companies, loading, localLoading }"
                    :company.sync="selectedCompany"
                    class="eqs-card-1"
                />
            </v-col>
            <v-col cols="12" lg="6">
                <Summary
                    v-if="!selectedCompany"
                    v-bind="{
                        companies,
                        valuation,
                        loading: localLoading
                    }"
                    class="eqs-card-2"
                />
                <CompanyDetail v-else :company.sync="selectedCompany" class="eqs-card-2" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import MyAssets from "./MyAssets";
import Summary from "./Summary";
import CompanyDetail from "./CompanyDetail";

import find from "lodash/find";
export default {
    name: "Portolio",
    components: {
        MyAssets,
        Summary,
        CompanyDetail
    },
    props: {
        id: {
            type: String
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            balances: [],
            companies: [],
            selectedCompany: null,
            localLoading: true,
            valuation: {}
        };
    },
    async created() {
        this.localLoading = true;
        await this.getBalances(this.$entity.represented());
        await this.refreshPortfolio();
        await this.addTotalAssetsToCompany();
        this.localLoading = false;
    },
    methods: {
        async getBalances(entity) {
            if (_.isEmpty(entity)) return;
            try {
                const res = await this.$api.getBalancePortfolio({
                    entity_id: entity.id
                });
                this.companies = res.data;
                const resValuation = await this.$api.getValuation();
                this.valuation = resValuation.data || null;
            } catch (e) {
                this.$ui.error(e, "portfolio_assets");
            }
        },
        async addTotalAssetsToCompany() {
            try {
                const resTotalAssetOwned = await this.$api.getTotalAssetOwned();
                this.companies.forEach(company => {
                    company["totalAllShareledgers"] = find(resTotalAssetOwned.data, {
                        id_company: company.id
                    });
                });
            } catch (e) {
                this.$ui.error(e, "portfolio_assets");
            }
        },

        async getCompanies(companies) {
            /**
      assets.forEach(asset => {
        let company = null;
        delete asset.shareholder;
        if (
          !(company = companies.find(
            _company => _company.id === asset.shareledger.company.id
          ))
        ) {
          company = _.cloneDeep(asset.shareledger.company);
          delete asset.shareledger.company;
          asset.share_type=asset.shareledger.share_type;
          company.assets = [this.populateAssetInfos(asset)];
          companies.push(company);
        } else {
          delete asset.company;
          company.assets.push(this.populateAssetInfos(asset));
        }
      });
       */

            return Promise.all(
                companies.map(async company => {
                    company.value = company.assets.reduce((acc, cur) => acc + cur.value, 0);
                    company.nav = await this.$api.getLastCompanyAnalytics("nav", company);
                    company.venalValue = await this.$api.getLastCompanyAnalytics(
                        "marketValue",
                        company
                    );
                    company.assets.nav = company.nav;
                    company.assets.venalValue = company.venalValue;
                    return company;
                })
            );
        },
        async refreshPortfolio() {
            this.companies = await this.getCompanies(this.companies);
        }
    }
};
</script>
