import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, myProjectId) => {
    DEBUG("DELETE_MY_PROJECT_START");
    if (!myProjectId) throw new Error("no myProjectId");
    try {
        commit("SET_MY_PROJECTS_LOADING", true);
        await window.app.$api.deleteCapitalIncrease(myProjectId);
        commit("DELETE_MY_PROJECT", myProjectId);
        DEBUG("DELETE_MY_PROJECT_SUCCESS");
    } catch (e) {
        DEBUG("DELETE_MY_PROJECT_ERROR");
        throw e;
    } finally {
        commit("SET_MY_PROJECTS_LOADING", false);
    }
};
