<template>
    <div class="eqs-crud">
        <EqsList
            v-bind="{
                ...$props,
                ...$attrs,
                eqsType: eqsListType,
                editable,
                loading,
                elClass: `${elClass}-list`,
                disabled: localLoading,
                ...getData
            }"
            :style="getStyle"
            :items.sync="itemsProxy"
        >
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />

            <template #group-content="props">
                <InternalCrudEdit
                    :loading.sync="localLoading"
                    v-bind="{
                        ...props,
                        editable,
                        update,
                        deleteFunc,
                        elClass: `${elClass}-edit-${props.index}`,
                        valToCmp: Object.keys(formInit)
                    }"
                >
                    <template #edit="{ formData, loading: editLoading, editable: isEditable }">
                        <slot
                            name="edit"
                            v-bind="{
                                formData,
                                index: props.index,
                                loading: editLoading || loading,
                                editable: isEditable
                            }"
                        />
                    </template>
                </InternalCrudEdit>
            </template>
            <template #add="props">
                <InternalCrudCreate
                    :loading="localLoading || loading"
                    v-bind="{ ...props, add, formInit, elClass: `${elClass}-create` }"
                    @update:loading="val => (localLoading = val)"
                >
                    <template #create="{ formData, loading: createLoading }">
                        <slot
                            name="create"
                            v-bind="{
                                formData,
                                index: -1,
                                loading: createLoading || loading
                            }"
                        />
                    </template>
                </InternalCrudCreate>
            </template>
        </EqsList>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsCrud.js";
import { inputFormMixin } from "../../../mixins/inputMixin";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import EqsList from "../../molecules/EqsList/EqsList.vue";

export default {
    name: "EqsCrud",
    components: {
        EqsList,
        InternalCrudCreate: () => import("./_internal/InternalCrudCreate.vue"),
        InternalCrudEdit: () => import("./_internal/InternalCrudEdit.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: "eqs-crud"
        },
        items: {
            type: Array,
            required: true
        },
        add: {
            type: Function,
            required: true
        },
        update: {
            type: Function,
            required: true
        },
        deleteFunc: {
            type: Function,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        formInit: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: true
        },
        eqsListType: {
            type: String,
            default: "default"
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            localLoading: false
        };
    },
    computed: {
        itemsProxy: {
            get() {
                return this.items;
            },
            set(val) {
                this.$emit("update:items", val);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsCrud.scss";
</style>
