<template>
    <div>
        <EqsTextDisplay paragraph>
            <span>
                {{ $t("auth.register-email.email-sent-txt") }}
                <span class="font-weight-bold">{{ email }}</span
                >.
            </span>
            <br />
            <br />
            <div>
                {{ $t("auth.register-email.finalize-txt") }}
            </div>
        </EqsTextDisplay>
        <div class="my-5">
            <a class="text-link" el-class="btn-login" @click="$router.push('/auth')">{{
                $t("auth.register.sign-in")
            }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "RegisterEmail",
    props: {
        email: {
            type: String,
            required: true
        }
    }
};
</script>
