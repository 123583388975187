var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{staticClass:"d-flex flex-column",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.type},on:{"update:value":_vm.updateType}},'EqsSelect',{
                    label: _vm.$t('investorDetails.select-type'),
                    items: _vm.listType,
                    itemValue: 'value',
                    itemText: 'display_name',
                    loading: _vm.investorsLoading,
                    editable: _vm.editable
                },false))],1),_c('EqsCrudActionBtns',_vm._b({class:_vm.editable ? 'pt-5 mt-3' : '',attrs:{"editable":_vm.editable,"has-changed":_vm.hasChanged},on:{"update:editable":function($event){_vm.editable=$event},"update:hasChanged":function($event){_vm.hasChanged=$event},"update:has-changed":function($event){_vm.hasChanged=$event}}},'EqsCrudActionBtns',{
                validForm: _vm.validForm,
                cancel: _vm.resetFormData,
                save: _vm.save
            },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }