<template>
    <v-lazy>
        <v-container fluid class="align-start pa-0 ma-0">
            <v-row class="ma-0 pa-0" justify="end">
                <v-col cols="auto">
                    <v-btn class="custom-btn" outlined @click="add">
                        {{ $t("movements.add") }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0" dense>
                <v-col>
                    <DataTablePaginated
                        :loading="loading || localLoading"
                        :headers="headers"
                        :items="items"
                        sort-desc
                        search="shareledger_name"
                        @update="fetchMovements"
                    >
                        <template #[`item.date`]="{ item }">
                            {{ $time.formated(item.date) }}
                        </template>
                        <template #[`item.operation`]="{ item }">
                            <EqsChip
                                :color="$color.colorFromHash(item.operation)"
                                el-class="tx-details-operation"
                            >
                                {{ movement_operations[item.operation].txt }}
                            </EqsChip>
                        </template>
                        <template #[`item.pricepershare`]="{ item }">
                            {{
                                $currency.displayWithCurrency(item.pricepershare, getCurrency(item))
                            }}
                        </template>
                        <template #[`item.numberofshares`]="{ value }">
                            {{ $currency.display(value, "0,0") }}
                        </template>
                        <template #[`item.movement_number`]="{ item }">
                            {{ item.movement_number ? item.movement_number : "-" }}
                        </template>
                        <template #[`item.shareledger_name`]="{ value }">
                            {{ value }}
                        </template>
                        <template #[`item.entity_source_name`]="{ item }">
                            <div class="cell d-flex flex-row align-center">
                                <EqsAvatar
                                    v-if="item.entity_source"
                                    class="pr-1"
                                    v-bind="{
                                        user: item.entity_source,
                                        noBadge: true
                                    }"
                                />
                                {{ item.entity_source?.name || "-" }}
                            </div>
                        </template>
                        <template #[`item.entity_destination_name`]="{ item }">
                            <div class="cell d-flex flex-row align-center">
                                <EqsAvatar
                                    v-if="item.entity_destination"
                                    class="pr-1"
                                    v-bind="{
                                        user: item.entity_destination,
                                        noBadge: true
                                    }"
                                />
                                {{ item.entity_destination?.name || "-" }}
                            </div>
                        </template>
                        <template #[`item.state`]="{ value }">
                            <EqsChip v-bind="states[value]">
                                {{ states[value].txt }}
                            </EqsChip>
                        </template>
                    </DataTablePaginated>
                </v-col>
            </v-row>
        </v-container>
    </v-lazy>
</template>

<script>
import DataTablePaginated from "@/components/views/DataTablePaginated";

import capitalize from "lodash/capitalize";
import { movement_operations } from "@/util/operations";
import { share_types } from "@/services/shareledger/shareType";
import { states } from "@/services/blockchain/utils";

export default {
    name: "Movements",
    components: { DataTablePaginated },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        shareledgerId: {
            type: String,
            required: false,
            default: null
        },
        specificHeaders: {
            type: Array,
            required: false,
            default: null
        }
    },
    data() {
        return {
            items: {},
            states,
            share_types,
            movement_operations,
            headers: this.specificHeaders
                ? this.specificHeaders
                : [
                      {
                          text: capitalize(this.$t("movements.number")),
                          filterable: false,
                          sortable: true,
                          value: "movement_number"
                      },
                      {
                          text: capitalize(this.$t("movements.operation")),
                          filterable: false,
                          sortable: true,
                          value: "operation"
                      },
                      {
                          text: capitalize(this.$t("movements.date")),
                          value: "date",
                          filterable: false,
                          sortable: true
                      },

                      {
                          text: capitalize(this.$t("movements.quantity")),
                          value: "numberofshares",
                          filterable: true,
                          sortable: true
                      },
                      {
                          text: capitalize(this.$t("label.pricepershare")),
                          value: "pricepershare",
                          filterable: true,
                          sortable: true
                      },
                      {
                          text: capitalize(this.$t("movements.titres")),
                          value: "shareledger_name",
                          filterable: true,
                          sortable: true
                      },
                      {
                          text: capitalize(this.$t("movements.from")),
                          value: "entity_source_name",
                          filterable: true,
                          sortable: true
                      },
                      {
                          text: capitalize(this.$t("movements.to")),
                          value: "entity_destination_name",
                          filterable: true,
                          sortable: true
                      },
                      {
                          text: capitalize(this.$t("label.status")),
                          value: "state",
                          filterable: false,
                          sortable: true
                      }
                  ],
            localLoading: false
        };
    },
    watch: {
        async shareledgerId() {
            await this.fetchMovements({});
        }
    },
    methods: {
        add() {
            this.$router
                .push({
                    name: "add-movement-operation",
                    params: this.$route.params
                })
                .catch(() => {});
        },
        async fetchMovements(queries) {
            try {
                this.localLoading = true;
                const params = this.shareledgerId ? { shareledger__id: this.shareledgerId } : {};
                const res = await this.$api.readTinyAssetMovementList({
                    ...params,
                    ...queries
                });
                res.data.results = res.data.results.map((tx, index) => {
                    const date = !!tx.confirmed_datetime
                        ? tx.confirmed_datetime
                        : tx.operation_datetime;
                    return {
                        date_key: !!tx.confirmed_datetime
                            ? "confirmed_datetime"
                            : "operation_datetime",
                        date,
                        id: index,
                        ...tx
                    };
                });
                this.items = res.data;
            } catch (e) {
                console.error(e, "movements_read_operations");
                this.$ui.error(e, "readAssetOperations");
            } finally {
                this.localLoading = false;
            }
        },
        getCurrency(item) {
            if (item?.shareledger?.company?.country?.alphacode == "US") return "USD";
            return "EUR";
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    text-transform: none !important;
    color: white !important;
    background-color: var(--v-primary-base) !important;
}
</style>
