<template>
    <EqsCard
        closable
        class="ma-0 pa-0"
        :title="`${$t('capital-increase.admin.detail.capital-increase-title')} ${
            capitalIncrease ? capitalIncrease.order_title : ''
        }`"
        v-bind="{ loading: loading || myProjectsLoading }"
        :el-class="capitalIncrease ? capitalIncrease.order_title : 'capinc-detail-empty'"
        @close="$emit('close')"
    >
        <v-row class="px-3">
            <v-col>
                <div v-if="capitalIncrease" transition="fade">
                    <ciCard
                        v-bind="{
                            capitalIncrease
                        }"
                    />
                    <ciFundraising
                        :capital-increase.sync="capitalIncrease"
                        :send-shares.sync="sendShares"
                    />
                </div>
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols(isRoot ? 'full' : 'half')" class="eqs-form-field">
                <div v-if="capitalIncrease" transition="fade">
                    <ciSubscribers
                        v-if="capitalIncrease.public"
                        v-bind="{
                            capitalIncrease,
                            loading
                        }"
                    />
                </div>
            </v-col>
            <v-slide-x-transition>
                <v-col v-if="!isRoot" :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <router-view
                        registration-infos
                        v-bind="{
                            loading: loading || myProjectsLoading
                        }"
                        @close="
                            () =>
                                $router.push({
                                    name: 'fundraising-details',
                                    params: $router.params
                                })
                        "
                    />
                </v-col>
            </v-slide-x-transition>
        </v-row>
    </EqsCard>
</template>

<script>
import ciCard from "./ciCard.vue";
import ciFundraising from "./ciFundraising.vue";
import ciSubscribers from "./ciSubscribers.vue";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "Detail",
    components: {
        ciCard,
        ciFundraising,
        ciSubscribers
    },
    props: {
        capitalIncreaseId: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            capitalIncrease: null,
            sendShares: false,
            tab: "subscribers"
        };
    },
    computed: {
        ...mapGetters(["myProjectsLoading"]),
        isRoot() {
            return this.$route.name === "fundraising-details";
        }
    },
    async created() {
        this.capitalIncrease = await this.getMyProject(this.capitalIncreaseId);
    },
    beforeDestroy() {
        if (this.changedOrders)
            this.fetchMyProject({
                projectId: this.capitalIncreaseId,
                entityId: this.$entity.represented().id
            });
    },
    methods: {
        ...mapActions(["getMyProject", "fetchMyProject"])
    }
};
</script>

<style lang="scss">
.capital-increase-dashboard {
    padding: 1em;
    display: grid;
    grid-template-areas: "eqsCard5 eqsCard6" "eqsCard7 eqsCard7";
    grid-template-columns: calc(45%) calc(55.5% - 15px);
    grid-template-rows: calc(80%) calc(100%);
    grid-gap: 10px;
    width: 100%;
    height: 100%;
}

.signed-title {
    text-align: left;
    letter-spacing: 0;
    color: #153055;
    text-decoration: underline;
}

.subtitle {
    text-align: left;
    letter-spacing: 0;
    line-height: 200%;
    color: #5e6066;
}

.text {
    text-align: left;
    letter-spacing: 0;
    color: #5e6066;
    opacity: 1;
}
</style>
