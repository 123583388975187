<template>
    <EqsCard>
        <v-container class="pa-3 ma-0">
            <v-row>
                <v-col
                    :cols="$reactive.isMobile() ? 12 : 4"
                    class="d-flex justify-center align-center pt-0"
                >
                    <img
                        v-if="!!company.avatar && !!company.avatar.upload"
                        :style="`width: ${$reactive.isDesktop() ? 'inherit' : '50%'}`"
                        :src="company.avatar.upload"
                    />
                    <EqsAvatar
                        v-else
                        class="pr-3"
                        :style="`width: ${$reactive.isDesktop() ? 'inherit' : '50%'}`"
                        v-bind="{
                            user: company,
                            eqsType: 'xl',
                            noBadge: true
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.isMobile() ? 12 : 8">
                    <v-row v-if="!!company.company_website" no-gutters class="mr-2 mb-2">
                        <v-col class="pa-0">
                            <EqsTextDisplay :value="$t('company-card.website')" eqs-type="label" />
                            <EqsTextDisplay :value="company.company_website" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mr-2 mb-2">
                        <EqsTextDisplay
                            :value="$t('company-card.incorporation-date') + ' : '"
                            eqs-type="label"
                            class="mr-2"
                        />
                        <EqsTextDisplay :value="$time.formated(company.incorporation_date)" />
                    </v-row>

                    <v-row no-gutters align="center" class="mb-2">
                        <img
                            width="16rem"
                            height="16rem"
                            class="mr-2"
                            :src="$language.countryFlag(company.country)"
                        />
                        <EqsTextDisplay :value="company.city + ', ' + company.country.name" />
                    </v-row>

                    <v-row no-gutters class="mr-2 mb-2">
                        <EqsTextDisplay
                            :value="$t('registration.company.company-identificiation') + ' :'"
                            eqs-type="label"
                            class="mr-2"
                        />
                        <EqsTextDisplay class="mr-2" :value="company.company_idcode" />
                    </v-row>

                    <v-row no-gutters class="mr-2 mb-2">
                        <EqsTextDisplay
                            :value="$t('registration.company.form-legal') + ' :'"
                            eqs-type="label"
                            class="mr-2"
                        />
                        <EqsTextDisplay :value="company.legal_type" />
                    </v-row>

                    <v-row no-gutters class="mr-2">
                        <EqsTextDisplay
                            :value="$t('company-card.company-address') + ' :'"
                            eqs-type="label"
                            class="mr-2"
                        />
                        <EqsTextDisplay :value="company.street" />
                    </v-row>

                    <v-row no-gutters class="mr-2">
                        <EqsTextDisplay
                            v-if="company.contact_email"
                            :value="$t('company-card.company-email') + ' :'"
                            eqs-type="label"
                            class="mr-2"
                        />
                        <EqsTextDisplay
                            v-if="company.contact_email"
                            :value="company.contact_email"
                        />
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </EqsCard>
</template>

<script>
export default {
    name: "CompanyCard",
    props: {
        company: {
            type: Object,
            required: true
        }
    }
};
</script>
