var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-form',{model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('EqsCrudActionBtns',_vm._b({staticClass:"mt-2",attrs:{"has-changed":_vm.hasChanged,"editable":_vm.editable},on:{"update:hasChanged":function($event){_vm.hasChanged=$event},"update:has-changed":function($event){_vm.hasChanged=$event},"update:editable":function($event){_vm.editable=$event}}},'EqsCrudActionBtns',{ validForm: _vm.validForm, cancel: _vm.cancel, save: _vm.save },false)),_c('YourPeapmeForm',_vm._b({},'YourPeapmeForm',{
                schemaDouble: _vm.schemaDouble,
                editable: _vm.editable,
                formData: _vm.formData,
                showPea: _vm.showPea,
                showPeapme: _vm.showPeapme,
                loading: _vm.loading || _vm.localLoading
            },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }