var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-navigation-bar",attrs:{"el-class":_vm.elClass}},[_c('v-slide-x-reverse-transition',[_c('v-app-bar',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.isMobile && _vm.navDrawer) || !_vm.user),expression:"!(isMobile && navDrawer) || !user"}],style:(_vm.getStyle),attrs:{"app":"","flat":"","width":"auto","outlined":"","color":_vm.$color.colors.menuColor}},[_c('v-row',{staticClass:"d-flex align-end",staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-expand-transition',[(!_vm.user && !_vm.loadingRoute)?_c('v-row',{staticStyle:{"height":"inherit"},attrs:{"align":"center","justify":"center"}},[_c('EqsBtn',_vm._b({attrs:{"color":"primary","to":"/auth"}},'EqsBtn',{ loading: _vm.loading },false),[_vm._v(" "+_vm._s(_vm.$t("app.bar.sign-in"))+" ")])],1):_vm._e(),(_vm.$appBar.state.actionBar)?_c('v-row',{staticClass:"fill-height d-flex align-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":_vm.$color.colors.eqsBlue},on:{"click":() => _vm.$appBar.activateActionBar(false)}},[_c('v-icon',{attrs:{"color":_vm.$color.colors.eqsBlue}},[_vm._v(" mdi-close ")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('portal-target',{attrs:{"name":"extended"}})],1)],1):(_vm.user)?_c('v-row',{staticClass:"eqs-navigation-bar__container d-flex flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{class:`eqs-navigation-bar__col d-flex justify-start align-center ${
                                !_vm.isMobile ? 'ml-2 pa-2' : 'pa-0'
                            }`,staticStyle:{"overflow":"hidden"},attrs:{"order":_vm.isMobile ? '2' : 'first'}},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                                    value: _vm.context,
                                    eqsType: 'subtitle',
                                    loading: _vm.loading
                                },false))],1),(_vm.isMobile)?_c('v-col',{staticClass:"eqs-navigation-bar__menu d-flex pa-0",staticStyle:{"align-items":"center"},attrs:{"order":"last","cols":"auto"}},[_c('EqsLoader',_vm._b({},'EqsLoader',{ loading: _vm.loading, size: '48px' },false),[(_vm.showMenu)?_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.$emit('open')}}}):_c('div')],1)],1):_vm._e(),_vm._t("navigation-bar-action",null,null,{ loading: _vm.loading }),_c('v-col',{staticClass:"eqs-navigation-bar__col eqs-navigation-bar__menu px-0",attrs:{"order":_vm.isMobile ? '3' : 'first',"cols":"auto"}}),_c('v-col',{staticClass:"eqs-navigation-bar__col eqs-navigation-bar__menu px-0",attrs:{"order":_vm.isMobile ? 'first' : '3',"cols":"auto"}},[_c('InternalNotifications',_vm._b({},'InternalNotifications',{
                                    ..._vm.notifications,
                                    isMobile: _vm.isMobile,
                                    loading: _vm.loading || _vm.loadingNotifications
                                },false))],1),_c('v-col',{staticClass:"eqs-navigation-bar__col eqs-navigation-bar__menu px-0",attrs:{"order":_vm.isMobile ? '1' : 'last',"cols":"auto"}},[_c('InternalDropdownProfile',_vm._b({staticClass:"d-flex"},'InternalDropdownProfile',{
                                    items: _vm.userMenu,
                                    user: _vm.user,
                                    isMobile: _vm.isMobile,
                                    loading: _vm.loading || _vm.loadingProfiles
                                },false))],1)],2):_vm._e()],1),(_vm.loadingRoute || _vm.$appBar.state.loading)?_c('v-expand-transition',[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }