<template>
    <div
        class="eqs-loader d-flex justify-center align-center"
        :el-class="elClass"
        :style="getStyle"
    >
        <v-scale-transition hide-on-leave>
            <div v-if="!encapsulate || loading" class="d-flex flex-column text-center">
                <InternalAnim
                    class="eqs-loader__anim"
                    v-bind="{ ...$attrs, settings, size }"
                    v-on="$listeners"
                />
                <slot name="loader-message" />
            </div>
        </v-scale-transition>
        <v-scale-transition hide-on-leave>
            <div
                :style="loading ? 'display: none !important;' : ''"
                :class="!loading ? 'eqs-loader__content d-flex' : ''"
            >
                <slot name="default" />
            </div>
        </v-scale-transition>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsLoader.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsLoader",
    components: {
        InternalAnim: () => import("./_internal/InternalAnim.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-loader"
        },
        loading: {
            type: Boolean,
            default: true
        },
        size: {
            type: [String, Number],
            default: "100%"
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            encapsulate: false
        };
    },
    computed: {
        settings() {
            return {
                gradientColorEllipse: {
                    firstPart: {
                        offset: "0%",
                        ...this.getData.outer
                    },
                    secondPart: {
                        offset: "50%",
                        ...this.getData.center
                    },
                    thirdPart: {
                        offset: "100%",
                        ...this.getData.outer
                    }
                },
                rotationEllipse: ["36", "72", "108", "144", "180"],
                strokeWidth: 3,
                rayonX: 100,
                speedChangeOfRayonYForEachEllipse: "12s",
                speedRotationOfAllEllipse: "10s"
            };
        }
    },
    created() {
        this.encapsulate = !!this.$slots["default"];
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsLoader.scss";
</style>
