<template>
    <EqsCard
        v-bind="{
            loading,
            width: '100%',
            height: '100%',
            eqsType: 'container',
            disabled: !organs.length,
            ...$attrs
        }"
    >
        <EqsDataTable
            v-bind="{
                headers,
                items: decisions,
                ...$attrs,
                loading: decisionsLoading || organsLoading
            }"
            sort-by="date"
            :sort-desc="[true]"
        >
            <template #item.locked="{ item }">
                <EqsBtn
                    v-if="
                        ['setup', 'published', 'started', 'finished', 'locked'].includes(
                            item.state
                        ) && item.convocation_documents.length
                    "
                    :disabled="decisionsLoading || organsLoading"
                    v-bind="lockedProps(item)"
                >
                    <v-icon>
                        {{ item.state !== "locked" ? "mdi-lock-open-variant" : "mdi-lock" }}
                    </v-icon>
                </EqsBtn>
                <EqsChip v-else-if="item.state === 'locking'" v-bind="{ color: 'warningLight' }">
                    <v-icon color="warning"> mdi-clock-outline </v-icon>
                </EqsChip>
                <a
                    v-if="item.proof"
                    :class="`download-file-${item.proof.id}`"
                    style="display: none"
                    :href="`${item.proof.upload.split('/download/')[0]}/download_file/`"
                    download
                    >{{ item.proof.name }}</a
                >
            </template>
            <template #item.date="{ item }">
                <div v-if="item.date">
                    {{ $time.formated(item.date) }}
                </div>
            </template>
            <template #item.organ="{ value }">
                <EqsChip
                    v-bind="{
                        color: $color.colorFromHash(value.name),
                        textColor: 'text'
                    }"
                >
                    <div>{{ value.name }}</div>
                </EqsChip>
            </template>
            <template #item.type="{ value }">
                <EqsChip
                    v-if="value"
                    v-bind="{ color: $color.colorFromHash(value), textColor: 'text' }"
                >
                    <div>{{ decision_types[value].text }}</div>
                </EqsChip>
            </template>
            <template #item.state="{ value }">
                <EqsChip v-bind="decisions_state[value]">
                    {{ decisions_state[value].text }}
                </EqsChip>
            </template>
            <template #item.action="{ item }">
                <EqsActions v-bind="{ actions: getActions(item) }" />
            </template>
        </EqsDataTable>
        <LockDialog
            v-if="selectedDecision.convocation_documents"
            :value.sync="lockDialog"
            v-bind="{ lockItem, item: selectedDecision }"
        />
        <DetailDialog
            v-if="selectedDecision.organ"
            :value.sync="detailDialog"
            v-bind="{ lockItem, item: selectedDecision }"
        />
    </EqsCard>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { decision_types } from "@/util/governance";
import { decisions_state } from "@/util/governance";
import LockDialog from "./LockDialog";
import DetailDialog from "./DetailDialog";
import { downloadFile, showDocument } from "@/util/documents";

export default {
    name: "Decision",
    components: {
        LockDialog,
        DetailDialog
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localLoading: false,
            decision_types,
            decisions_state,
            selectedDecision: {},
            downloadFile,
            showDocument,
            headers: [
                {
                    value: "locked",
                    sortable: true,
                    filterable: false
                },
                {
                    text: this.$t("governance.decisions.header.date"),
                    value: "date",
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t("governance.decisions.header.num"),
                    value: "number_globally",
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t("governance.decisions.header.name"),
                    value: "name",
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t("governance.decisions.header.organe"),
                    value: "organ",
                    sortable: true,
                    filterable: true
                },
                // {
                //   text: this.$t("governance.decisions.header.mode"),
                //   value: "type",
                //   sortable: true,
                //   filterable: false,
                // },
                {
                    text: this.$t("governance.organs.details.activity.headers.state"),
                    value: "state",
                    sortable: false
                },
                {
                    text: this.$t("label.actions"),
                    value: "action",
                    align: "end",
                    sortable: false,
                    filterable: false
                }
            ],
            lockDialog: false,
            detailDialog: false
        };
    },
    created() {
        this.getDecisions();
        this.getOrgans();
    },
    computed: {
        ...mapGetters(["decisions", "decisionsLoading", "organs", "organsLoading"])
    },
    methods: {
        ...mapActions(["getDecisions", "deleteDecision", "getOrgans", "fetchDecision"]),
        async deleteItem(item) {
            try {
                await this.deleteDecision(item);
            } catch (e) {
                this.$ui.error(e, "governance_decision_delete");
            }
        },
        lockedProps(item) {
            // TODO put back downloadFile func when fixed on prod
            const notLocked = item.state !== "locked";
            return {
                icon: true,
                color: notLocked ? "error" : "success",
                loading: item.loading,
                infoTxt: notLocked
                    ? this.$t("governance.decision.lock-btn")
                    : this.$t("governance.decision.locked-proof-btn"),
                action: () => (notLocked ? this.activateDialog(item) : showDocument(item))
                // : downloadFile(`download-file-${item.proof.id}`),
            };
        },
        activateDialog(item) {
            this.selectedDecision = item;
            this.lockDialog = true;
            console.log({ item });
        },
        getActions(item) {
            return item.state === "setup"
                ? [
                      {
                          txt: this.$t("governance.decision.edit-btn"),
                          icon: "mdi-pencil-outline",
                          fn: () =>
                              this.$router.push({
                                  name: "governance-decision-edit",
                                  params: {
                                      decisionId: item.id
                                  }
                              }),
                          color: "primary"
                      },
                      {
                          txt: this.$t("governance.decision.detail-btn"),
                          icon: "mdi-eye-outline",
                          fn: () => {
                              this.detailDialog = true;
                              this.selectedDecision = item;
                          },
                          color: "primary"
                      },
                      {
                          txt: this.$t("governance.decision.delete-btn"),
                          icon: "mdi-delete-outline",
                          fn: () => this.deleteItem(item),
                          color: "error"
                      }
                  ]
                : [
                      {
                          txt: this.$t("governance.decision.detail-btn"),
                          icon: "mdi-eye-outline",
                          fn: () => {
                              this.detailDialog = true;
                              this.selectedDecision = item;
                          },
                          color: "primary"
                      }
                  ];
        },
        async lockItem(item) {
            try {
                await this.$api.lockDecision(item.id);
                await this.fetchDecision(item.id);
            } catch (e) {
                this.$ui.error(e, "governance_decision_lock");
            }
        }
    }
};
</script>
