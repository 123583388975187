<template>
    <div class="eqs-main-container margin-auto">
        <div class="d-flex flex-column fill-height">
            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full')">
                    <EqsTextDisplay
                        class="py-2"
                        eqs-type="title"
                        :value="$t('movements.sub-title')"
                        :loading="loading"
                    />

                    <EqsTextDisplay
                        paragraph
                        :value="$t('movements.first-paraph')"
                        :loading="loading"
                    />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <GlobalChooseShareledger
                        :value.sync="formData.shareledger"
                        v-bind="{
                            label: $t('movements.label.shareledger'),
                            loading,
                            ...schemaDouble.shareledger
                        }"
                        @change="resetShareledgerForm"
                    />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full')">
                    <EqsTextDisplay
                        class="py-2"
                        eqs-type="title"
                        :value="$t('movements.sub-title-2')"
                        :loading="loading"
                    />

                    <EqsTextDisplay
                        paragraph
                        :value="$t('movements.second-paraph')"
                        :loading="loading"
                    />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <EqsSelect
                        el-class="asset-op-type"
                        :label="$t('movements.label.asset-op-type')"
                        :placeholder="op_types[0].text"
                        :value.sync="formData.asset_op_type"
                        :items="op_types"
                        item-text="text"
                        item-value="id"
                        v-bind="{ ...schemaDouble.asset_op_type, loading }"
                        @change="resetShareledgerForm"
                    />
                </v-col>

                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <v-slide-x-transition hide-on-leave>
                        <EqsDate
                            el-class="operation-date"
                            :label="$t('movements.label.operation-date')"
                            :value.sync="formData.operation_date"
                            :loading="loading"
                            v-bind="{ ...schemaDouble.operation_date }"
                        />
                    </v-slide-x-transition>
                </v-col>
            </v-row>

            <v-slide-x-transition hide-on-leave>
                <v-row v-if="showEmitterInfo" class="px-3">
                    <v-col :cols="$reactive.getCols('third', formType)" class="eqs-form-field">
                        <EqsEmail
                            el-class="emitter-email"
                            :label="formTexts.emitterEmail"
                            :value.sync="formData.emitter_email"
                            :loading="loading"
                            v-bind="{ ...schemaDouble.emitter_email }"
                        />
                    </v-col>

                    <v-col :cols="$reactive.getCols('third', formType)" class="eqs-form-field">
                        <EqsText
                            el-class="emitter-first-name"
                            :label="formTexts.emitterEmail"
                            placeholder="Jean"
                            :value.sync="formData.emitter_first_name"
                            :loading="loading"
                            v-bind="{ ...schemaDouble.emitter_first_name }"
                        />
                    </v-col>

                    <v-col :cols="$reactive.getCols('third', formType)" class="eqs-form-field">
                        <EqsText
                            el-class="emitter-last-name"
                            :label="formTexts.emitterLastName"
                            placeholder="Dupont"
                            :value.sync="formData.emitter_last_name"
                            :loading="loading"
                            v-bind="{ ...schemaDouble.emitter_last_name }"
                        />
                    </v-col>
                </v-row>
            </v-slide-x-transition>

            <v-slide-x-transition hide-on-leave>
                <v-row v-if="showReceiverInfo" class="px-3">
                    <v-col :cols="$reactive.getCols('third', formType)" class="eqs-form-field">
                        <EqsEmail
                            el-class="receiver-email"
                            :label="formTexts.receiverEmail"
                            :value.sync="formData.receiver_email"
                            :loading="loading"
                            v-bind="{ ...schemaDouble.receiver_email }"
                        />
                    </v-col>

                    <v-col :cols="$reactive.getCols('third', formType)" class="eqs-form-field">
                        <EqsText
                            el-class="receiver-first-name"
                            :label="formTexts.receiverFirstName"
                            placeholder="Jean"
                            :value.sync="formData.receiver_first_name"
                            :loading="loading"
                            v-bind="{ ...schemaDouble.receiver_first_name }"
                        />
                    </v-col>

                    <v-col :cols="$reactive.getCols('third', formType)" class="eqs-form-field">
                        <EqsText
                            el-class="receiver-last-name"
                            :label="formTexts.receiverLastName"
                            placeholder="Dupont"
                            :value.sync="formData.receiver_last_name"
                            :loading="loading"
                            v-bind="{ ...schemaDouble.receiver_last_name }"
                        />
                    </v-col>
                </v-row>
            </v-slide-x-transition>

            <v-slide-x-transition hide-on-leave>
                <v-row v-if="showReceiver || showEmitter" class="px-3">
                    <v-slide-x-transition hide-on-leave>
                        <v-col
                            v-if="showEmitter"
                            :cols="$reactive.getCols('half', formType)"
                            class="eqs-form-field"
                        >
                            <GlobalChooseInvestor
                                :value.sync="formData.emitter"
                                v-bind="{
                                    label: formTexts.emitter,
                                    loading,
                                    filter: filterShareholders,

                                    ...schemaDouble.emitter
                                }"
                            />
                        </v-col>
                    </v-slide-x-transition>
                    <v-col
                        v-if="showReceiver"
                        :cols="$reactive.getCols('half', formType)"
                        class="eqs-form-field"
                    >
                        <GlobalChooseInvestor
                            :value.sync="formData.receiver"
                            v-bind="{
                                label: formTexts.receiver,
                                loading,
                                filter: filterBuyers,
                                ...schemaDouble.receiver
                            }"
                        />
                    </v-col>
                </v-row>
            </v-slide-x-transition>

            <v-slide-x-transition hide-on-leave>
                <v-row v-if="showQuantityPrice" class="px-3">
                    <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                        <EqsNumberShares
                            el-class="quantity"
                            :label="$t('movements.label.quantity')"
                            :value.sync="formData.quantity"
                            :loading="loading"
                            persistent-hint
                            v-bind="{
                                hint: availableShares,
                                numberOfSharesLeft,
                                ...schemaDouble.quantity
                            }"
                        />
                    </v-col>

                    <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                        <EqsNumber
                            el-class="price"
                            :label="formTexts.price"
                            :value.sync="formData.price"
                            is-currency
                            persistent-hint
                            v-bind="{ ...schemaDouble.price, hint: lastPrice, loading }"
                        />
                    </v-col>
                </v-row>
            </v-slide-x-transition>

            <v-slide-x-transition>
                <v-row v-if="showDivision || showMultiplication" class="px-3">
                    <v-col
                        v-if="showDivision"
                        :cols="$reactive.getCols('half', formType)"
                        class="eqs-form-field"
                    >
                        <EqsNumber
                            el-class="division"
                            :label="$t('movements.label.division')"
                            :value.sync="formData.division"
                            :is-currency="false"
                            :is-float="false"
                            :rules="[greaterThanZeroR]"
                            :loading="loading"
                            v-bind="{ ...schemaDouble.division }"
                        />
                    </v-col>

                    <v-col
                        v-if="showMultiplication"
                        :cols="$reactive.getCols('half', formType)"
                        class="eqs-form-field"
                    >
                        <EqsNumber
                            el-class="multiplication"
                            :label="$t('movements.label.multiplication')"
                            :value.sync="formData.multiplication"
                            :is-currency="false"
                            :is-float="false"
                            :rules="[greaterThanZeroR]"
                            :loading="loading"
                            v-bind="{ ...schemaDouble.multiplication }"
                        />
                    </v-col>
                </v-row>
            </v-slide-x-transition>

            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full')">
                    <EqsTextArea
                        :label="$t('payments.label.comment')"
                        :value.sync="formData.comment"
                        v-bind="{
                            loading,
                            ...schemaDouble.comment
                        }"
                        el-class="description-transfer"
                    />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                    <EqsFileInput
                        el-class="docs"
                        :label="$t('movements.label.docs')"
                        :value.sync="formData.docs"
                        multiple
                        :loading="loading"
                        v-bind="{ ...schemaDouble.docs }"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { greaterThanZeroR } from "@equisafe-ui-vue/src/rules";
import { share_types } from "@/services/shareledger/shareType";
import { op_types, asset_op_types } from "@/util/payment.js";

export default {
    name: "AddMovementForm",
    mixins: [formMixin],
    props: {
        resetShareledgerForm: {
            type: Function,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            op_types,
            greaterThanZeroR
        };
    },
    computed: {
        formTexts() {
            if (!this.formData.asset_op_type) return {};
            return asset_op_types[this.formData.asset_op_type];
        },
        emitterLedger() {
            return this.formData.shareledger &&
                this.formData.shareledger.name &&
                this.formData.emitter
                ? this.formData.emitter.shares.find(
                      share => share.name === this.formData.shareledger.name
                  )
                : null;
        },
        numberOfSharesLeft() {
            if (this.formData.asset_op_type == "Souscription") {
                return this.formData.shareledger && this.formData.shareledger.pool_size > 0
                    ? this.formData.shareledger.pool_size - this.formData.shareledger.numberofshares
                    : Number.MAX_SAFE_INTEGER;
            }
            return this.emitterLedger ? this.emitterLedger.numberofshares : 0;
        },
        availableShares() {
            if (!this.formData.shareledger) return "";
            return this.numberOfSharesLeft !== Number.MAX_SAFE_INTEGER
                ? this.$t("movements.label.numberofshares.hint", {
                      numberofshares: this.$currency.display(this.numberOfSharesLeft, "0,0"),
                      shareType: share_types[this.formData.shareledger.share_type].name
                  })
                : "";
        },
        lastPrice() {
            if (!this.formData.shareledger) return "";
            return this.$t("movements.label.price.hint", {
                lastpricepershare: this.$currency.display(
                    this.formData.shareledger.lastpricepershare
                )
            });
        },
        showEmitter() {
            return (
                this.formData.blockchain &&
                ["Cession", "Annulation"].includes(this.formData.asset_op_type)
            );
        },
        showReceiver() {
            return (
                this.formData.blockchain &&
                ["Souscription", "Cession"].includes(this.formData.asset_op_type)
            );
        },
        showEmitterInfo() {
            return !this.formData.blockchain && this.formData.asset_op_type === "Cession";
        },
        showReceiverInfo() {
            return (
                !this.formData.blockchain &&
                ["Souscription", "Cession", "Annulation"].includes(this.formData.asset_op_type)
            );
        },
        showQuantityPrice() {
            return ["Souscription", "Cession", "Annulation"].includes(this.formData.asset_op_type);
        },
        showDivision() {
            return this.formData.asset_op_type === "Division";
        },
        showMultiplication() {
            return this.formData.asset_op_type === "Multiplication";
        }
    },
    methods: {
        filterBuyers(buyer) {
            return !(
                this.showEmitter &&
                this.formData.emitter &&
                this.formData.emitter.id === buyer.id
            );
        },
        filterShareholders(shareholder) {
            let matchingShare = null;
            if (this.formData.shareledger && shareholder.shares && shareholder.shares.length)
                matchingShare = shareholder.shares.find(
                    share => share.name === this.formData.shareledger.name
                );
            return matchingShare && matchingShare.numberofshares > 0;
        }
    }
};
</script>
