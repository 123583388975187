var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-column align-center"},[_c('v-col',{attrs:{"cols":_vm.$reactive.isMobile() ? 12 : 6}},[_c('EqsTextDisplay',{staticClass:"py-3",attrs:{"eqs-type":"subtitle","value":_vm.$t('user.payment-confirmation.subtitle-1')}}),_c('EqsTextDisplay',{attrs:{"value":_vm.$t('user.payment-confirmation.text-1'),"paragraph":""}})],1),_c('v-col',{attrs:{"cols":_vm.$reactive.isMobile() ? 12 : 6}},[_c('EqsTextDisplay',{staticClass:"py-3",attrs:{"eqs-type":"subtitle","value":_vm.$t('user.payment-confirmation.subtitle-2')}}),(_vm.order.capinc.buyer_fee_subscription_percentage > 0)?_c('EqsTextDisplay',{staticClass:"py-3",attrs:{"paragraph":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('user.payment-confirmation.text-3', {
                            share_type: _vm.shareTypeTxt,
                            shareledger_name: _vm.order.capinc.shareledger.name,
                            numberOfShares: _vm.order.numberofshares,
                            fees: _vm.order.buyer_fees,
                            pricePerShare: _vm.order.object.share_price,
                            totalAmount: _vm.order.total_price
                        })
                    )}}),_c('v-input',{attrs:{"error-messages":_vm.walletRule}})],1):_c('EqsTextDisplay',{staticClass:"py-3",attrs:{"paragraph":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('user.payment-confirmation.text-2', {
                            share_type: _vm.shareTypeTxt,
                            shareledger_name: _vm.order.capinc.shareledger.name,
                            numberOfShares: _vm.order.numberofshares,
                            pricePerShare: _vm.order.object.share_price,
                            totalAmount: _vm.order.total_price
                        })
                    )}}),_c('v-input',{attrs:{"error-messages":_vm.walletRule}})],1),(_vm.walletRule)?_c('v-row',{staticClass:"px-3",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('md')}},[_c('EqsBtn',_vm._b({attrs:{"data-cy":"money-in","color":"success","block":""}},'EqsBtn',{ loading: _vm.loading, action: _vm.changeRoute },false),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cash-plus ")]),_vm._v(" "+_vm._s(_vm.$t("user.wallet.add-funds-btn"))+" ")],1)],1)],1):_vm._e()],1),(_vm.signer && _vm.signer.procedure_documents && _vm.signer.procedure_documents.length)?_c('v-col',{attrs:{"cols":_vm.$reactive.isMobile() ? 12 : 6}},[_c('EqsTextDisplay',{staticClass:"py-3",attrs:{"eqs-type":"subtitle","value":_vm.$t('user.payment-confirmation.subtitle-3')}}),_c('EqsFileInput',_vm._b({attrs:{"multiple":""}},'EqsFileInput',{
                    value: _vm.signer.procedure_documents.map(doc => doc.signed_document),
                    editable: false
                },false))],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }