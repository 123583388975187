<template>
    <div class="eqs-date">
        <v-menu
            ref="menu"
            v-model="menu"
            transition="scale-transition"
            offset-y
            min-width="290px"
            v-bind="{
                disabled,
                nudgeRight: 40,
                closeOnContentClick: false
            }"
            :content-class="`${elClass}-eqsDate`"
        >
            <template v-slot:activator="{ on, attrs }">
                <EqsText
                    v-if="$reactive.isDesktop() && !pickOnly"
                    v-bind="{ ...$props, ...$attrs, loading }"
                    :el-class="`${elClass}-input`"
                    :placeholder="plholder"
                    :hint="$t('molecule.date.between-hint', { min: hintMin, max: hintMax })"
                    autocomplete="off"
                    clearable
                    :role="''"
                    :value.sync="dateText"
                    @click:append="open"
                    @click:clear="clear"
                />

                <EqsText
                    v-else
                    v-bind="{ ...$props, ...attrs, loading }"
                    :el-class="`${elClass}-input`"
                    autocomplete="off"
                    readonly
                    clearable
                    :value="computedFormatedDate"
                    v-on="on"
                    @click:append="open"
                    @click:clear="clear"
                />
            </template>
            <v-date-picker
                ref="picker"
                v-model="date"
                :locale="$language.current()"
                v-bind="{ max, min }"
                @change="save"
            />
        </v-menu>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsDate.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";
// lazy loading here is breaking this component, guess it's because it's in a shadow dom
import EqsText from "../../atoms/EqsText/EqsText.vue";

export default {
    name: "EqsDate",
    components: {
        EqsText
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        },
        value: {
            validator: prop => typeof prop === "string" || prop === null || prop === undefined,
            required: true
        },
        appendIcon: {
            type: String,
            default: "mdi-calendar"
        },
        max: {
            type: String,
            default: new Date().toISOString().substr(0, 10)
        },
        min: {
            type: String,
            default: "1920-01-01"
        },
        withTime: {
            type: Boolean,
            default: false
        },
        pickOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            date: null,
            dateText: null,
            menu: false
        };
    },
    computed: {
        computedFormatedDate() {
            const res = this.date ? this.$time.formated(this.date) : "";
            return res;
        },
        minMaxIsSameYear() {
            if (this.min && this.max) {
                const splitYearMin = this.min.split("-")[0];
                const splitYearMax = this.max.split("-")[0];
                return splitYearMin === splitYearMax;
            }
            return false;
        },
        plholder() {
            const date = new Date();
            const minDate = new Date(this.min);

            return this.$time.formated(minDate > date ? minDate : date);
        },
        hintMin() {
            return this.$time.formated(this.min);
        },
        hintMax() {
            return this.$time.formated(this.max);
        },
        idxs() {
            return this.$language.current() === "en"
                ? { month: 0, day: 1, year: 2 }
                : { month: 1, day: 0, year: 2 };
        }
    },
    watch: {
        async dateText(next, prev) {
            await this.$nextTick();

            if (next === null || !next.length) return;
            if (next.length > 10) return this.$set(this, "dateText", next.slice(0, 10));

            for (let i = 0; i < next.length; i++) {
                if (next[i] !== "/" && isNaN(next[i]))
                    return this.$set(this, "dateText", next.slice(0, i));
            }

            let parts = next.split("/");
            if (parts[0].length > 2) {
                if (parts.length === 1) parts.push(parts[0].slice(2));
                parts[0] = parts[0].slice(0, 2);
                return this.$set(this, "dateText", parts.join("/"));
            }

            if (parts.length > 1) {
                if (parts[0].length === 0 || parts[0] == 0)
                    return this.$set(this, "dateText", parts[0]);
                else if (parts[0].length === 1) {
                    parts[0] = `0${parts[0]}`;
                    return this.$set(this, "dateText", parts.join("/"));
                } else if (parts[1].length > 2) {
                    if (parts.length === 2) parts.push(parts[1].slice(2));
                    parts[1] = parts[1].slice(0, 2);
                    return this.$set(this, "dateText", parts.join("/"));
                }
            }

            if (parts.length > 2) {
                if (parts[1].length === 0 || parts[1] == 0)
                    return this.$set(this, "dateText", parts.slice(0, 2).join("/"));
                else if (parts[1].length === 1) {
                    parts[1] = `0${parts[1]}`;
                    return this.$set(this, "dateText", parts.join("/"));
                } else if (parts[2].length > 4) {
                    parts[2] = parts[2].slice(0, 4);
                    return this.$set(this, "dateText", parts.join("/"));
                }
            }

            if (parts.length > 3) return this.$set(this, "dateText", parts.slice(0, 3).join("/"));

            const idxs = this.idxs;
            const dayMax = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            const day = parts[idxs.day] || 0;
            const month = parts[idxs.month] || 0;
            const year = parts[idxs.year] || 0;

            if (month > 12) {
                parts[idxs.month] = 12;
                return this.$set(this, "dateText", parts.join("/"));
            } else if (month && day > dayMax[month - 1]) {
                parts[idxs.day] = dayMax[month - 1];
                return this.$set(this, "dateText", parts.join("/"));
            } else if (year.length === 4 && month == 2) {
                const daysFeb = new Date(year, 2, 0).getDate();
                if (day > daysFeb) {
                    parts[idxs.day] = daysFeb;
                    return this.$set(this, "dateText", parts.join("/"));
                }
            }

            if (year.length === 4) {
                const date = new Date(year, month - 1, day);
                const minDate = new Date(this.withTime ? this.min.substr(0, 10) : this.min);
                const maxDate = new Date(this.withTime ? this.max.substr(0, 10) : this.max);

                minDate.setHours(0, 0, 0, 0);
                maxDate.setHours(0, 0, 0, 0);

                if (date < minDate) {
                    parts[idxs.year] = minDate.getFullYear();
                    parts[idxs.month] = minDate.toLocaleDateString(undefined, {
                        month: "2-digit"
                    });
                    parts[idxs.day] = minDate.toLocaleDateString(undefined, {
                        day: "2-digit"
                    });
                    return this.$set(this, "dateText", parts.join("/"));
                }

                if (date > maxDate) {
                    parts[idxs.year] = maxDate.getFullYear();
                    parts[idxs.month] = maxDate.toLocaleDateString(undefined, {
                        month: "2-digit"
                    });
                    parts[idxs.day] = maxDate.toLocaleDateString(undefined, {
                        day: "2-digit"
                    });
                    return this.$set(this, "dateText", parts.join("/"));
                }

                this.save(`${year}-${month}-${day}`);
            }
        },
        value: {
            immediate: true,
            handler(val) {
                this.date = this.withTime && val ? val.substr(0, 10) : val;
                if (!this.date) return null;
                const idxs = this.idxs;
                const parts = ["", "", ""];
                parts[idxs.year] = this.date.slice(0, 4);
                parts[idxs.month] = this.date.slice(5, 7);
                parts[idxs.day] = this.date.slice(8, 10);
                this.dateText = parts.join("/");
            }
        },
        menu(val) {
            val &&
                !this.value &&
                setTimeout(
                    () => (this.$refs.picker.activePicker = this.minMaxIsSameYear ? "DAY" : "YEAR")
                );
        }
    },
    methods: {
        open() {
            this.menu = true;
        },
        save(date) {
            let _date = date;
            if (date && this.withTime) {
                _date = new Date(date);
                _date = _date.toISOString();
            }
            this.$emit("update:value", _date);
            if (this.$refs.menu && this.$refs.menu.save) this.$refs.menu.save(date);
        },
        clear() {
            this.$emit("update:value", null);
            this.date = null;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsDate.scss";
</style>
