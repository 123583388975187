import init from "./init";
import router from "@/router/router";
import { i18n } from "@/services/plugins/i18n.js";
import { payment_types } from "@/util/payment.js";

/*
  Gets the notifications of the currentRole.entity_represented
*/
const populateNotificationsInfos = notifs => {
    const notifications = notifs.map(item => {
        let res = {
            ...item
        };

        switch (item.type) {
            case "facture":
                if (!item.accepted) {
                    res.message = i18n.t("notifications.invoice.refused-msg");
                    res.confirm = {};
                    res.confirm.text = i18n.t("notifications.invoice.pay");
                    res.confirm.fn = () => {
                        router.push({
                            name: "pay-plan",
                            params: {
                                id: "init",
                                bill_id: item.route_params.id
                            }
                        });
                    };
                } else {
                    res.action_txt = i18n.t("notifications.invoice.accepted-txt");
                }
                break;
            case "signature_secondary":
                let role = item.route_params ? item.route_params.role : "";

                if (!!role && role === "seller" && !item.accepted) {
                    res.confirm = {};
                    res.confirm.text = i18n.t("notifications.sign-docs");
                    res.confirm.fn = () => {
                        router.push({
                            name: "secondary-market-sign-stepper",
                            params: {
                                orderId: item.route_params.buyOrderId
                            }
                        });
                    };
                } else {
                    res.action_txt = i18n.t("notifications.doc-signed");
                }
                break;
            case "signature_secondary":
                if (!item.accepted) {
                    res.confirm = {};
                    res.confirm.text = i18n.t("notifications.sign-docs");
                    res.confirm.fn = () => {
                        router.push({
                            name: "simple-sign-stepper",
                            params: {
                                procedureId: item.route_params.id
                            }
                        });
                    };
                } else {
                    res.action_txt = i18n.t("notifications.doc-signed");
                }
                break;
            case "validationOrder":
                if (!item.accepted) {
                    res.confirm = {};
                    res.confirm.text = i18n.t("notifications.validationOrder.see-operation");
                    res.confirm.fn = () => {
                        router.push({
                            name: "validation-stepper",
                            params: {
                                id: item.route_params.id
                            }
                        });
                    };
                } else {
                    if (res.status == "accepted") {
                        res.action_txt = i18n.t("notifications.validationOrder.order-accepted");
                    } else {
                        res.action_txt = i18n.t("notifications.validationOrder.order-refused");
                    }
                }
                break;
            case "payments":
                let type = item.route_params ? item.route_params.type : "";
                let operation = item.route_params ? item.route_params.operation : "";

                if (type === "ask") {
                    res.message = i18n.t("payments.asked-txt", {
                        operation_type: payment_types[operation].txt
                    });
                    if (!item.accepted) {
                        res.confirm = {};
                        res.confirm.text = i18n.t("payments.asked.accept.see-btn");
                        res.confirm.fn = () => {
                            router.push({
                                name: "asked-payment",
                                params: {
                                    id: item.route_params.id
                                }
                            });
                        };
                    } else {
                        res.action_txt =
                            res.status !== "success"
                                ? i18n.t("payments.notification.error")
                                : i18n.t("payments.notification.success");
                    }
                } else {
                    res.message = i18n.t("payments.fromt-txt", {
                        operation_type: payment_types[operation].txt
                    });
                }
                break;
            case "project_order_signed":
                if (!item.accepted) {
                    res.confirm = {
                        text: i18n.t("notifications.pay-order"),
                        fn: () => {
                            router.push({
                                name: "buy",
                                params: {
                                    orderId: item.route_params.order_id
                                },
                                query: { capinc_id: item.route_params.capinc_id }
                            });
                        }
                    };
                }
                break;
            case "project_order_success":
                res.confirm = {
                    text: i18n.t("notifications.access-shares"),
                    fn: () => {
                        router.push({
                            name: "portfolio",
                            params: {
                                id: item.route_params.entity_id
                            }
                        });
                    }
                };
                break;
            case "capinc_invite":
                res.confirm = {
                    text: i18n.t("notifications.access-offer"),
                    fn: () => {
                        router.push({
                            name: "project-detail",
                            params: {
                                projectId: item.route_params.project_id
                            }
                        });
                    }
                };
                break;
            default:
                break;
        }
        return res;
    });

    return notifications;
};

export default {
    SET_NOTIFICATIONS(state, notifs) {
        state.notifications = populateNotificationsInfos(notifs);
        state.lastNotifications = state.notifications.slice(0, 5);
        state.notificationsInit = false;
    },
    UPDATE_NOTIFICATIONS(state, notifs) {
        for (const id of notifs) {
            const notif = state.notifications.find(v => v.id === id);
            if (notif) {
                notif.shown = true;
            }
        }
        state.lastNotifications = state.notifications.slice(0, 5);
        state.notificationsInit = false;
    },
    SET_NOTIFICATIONS_LOADING(state, bool) {
        state.notificationsLoading = bool;
    },
    SET_NOTIFICATION_INIT(state, bool) {
        state.notificationsInit = bool;
    },
    SET_NUMBER_OF_NEW_NOTIFICATIONS(state, value) {
        state.numberOfNewNotifications = value;
    },
    RESET_STORE_NOTIFICATIONS(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};

// notifs = [
//   {
//     type: 'capinc.order',
//     created_by: {
//         //...Entity
//     },
//     message: 'A message that correspond to a key translated in backend',
//     actions: [
//       {
//         type: "confirm",
//         txt: 'A message that correspond to a key translated in backend',
//         route: {
//           name: "buy",
//           params: {
//             orderId: order.id
//           },
//           query: { capinc_id: order.capinc.id }
//         }
//       },
//       {
//         type: "cancel",
//         txt: 'A message that correspond to a key translated in backend',
//         api: {
//           method: "PATCH",
//           url: "/api/order/dummy_id/cancel",
//           data: { capinc_id: "capinc_dummy_id" }
//         }
//       }
//     ]
//   },
//   {
//     type: 'capinc.order',
//     created_by: {
//         //...Entity
//     },
//     message: 'Invited you to invest in the fundraising for "Green bull"',
//     action_txt: 'Your investment for 23 133 shares is ongoing',
//   },
//   {
//     type: 'investor.invited',
//     created_by: {
//         //...Entity
//     },
//     message: 'Invited you to become an Investor in it\'s company',
//   }
// ];
