<template>
    <div class="eqs-email">
        <EqsText v-bind="$attrs" :basic-rules="$options.rules" v-on="$listeners" />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsEmail.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { emailR, max100R } from "../../../rules";

export default {
    name: "EqsEmail",
    rules: [emailR, max100R],
    components: {
        EqsText: () => import("../EqsText/EqsText.vue")
    },
    mixins: [eqsUITemplate],
    data() {
        return {
            cssTypes,
            dataTypes
        };
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsEmail.scss";
</style>
