<template>
    <v-img
        class="eqs-image"
        :el-class="elClass"
        v-bind="$attrs"
        :src="loading ? '' : !error && src ? src : placeholder"
        v-on="$listeners"
        @error="onError"
    >
        <template #placeholder>
            <v-layout fill-height align-center justify-center ma-0>
                <EqsSkeleton v-if="loading && !eqsAnim" v-bind="{ loading, type: 'image' }" />
                <EqsLoader
                    v-else-if="loading"
                    key="loader"
                    eqs-anim
                    contain
                    size="100%"
                    data-type="blue"
                />
                <slot name="placeholder" />
            </v-layout>
        </template>
        <template #default>
            <EqsSkeleton v-if="loading && !eqsAnim" v-bind="{ loading, type: 'image' }" />
            <EqsLoader
                v-else-if="loading"
                key="loader"
                eqs-anim
                contain
                size="100%"
                data-type="blue"
            />
            <slot v-show="!loading" />
        </template>
        <slot />
    </v-img>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsImage.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsImage",
    components: {
        EqsLoader: () => import("../EqsLoader/EqsLoader.vue"),
        EqsSkeleton: () => import("../EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-image"
        },
        src: {
            validator: prop => typeof prop === "string" || prop === null || prop === undefined,
            required: true
        },
        // todo change placeholder for img placeholder and not avatar
        placeholder: {
            type: String,
            default: "/icons/user-empty.svg"
        },
        loading: {
            type: Boolean,
            default: false
        },
        eqsAnim: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            error: false
        };
    },
    methods: {
        onError(event) {
            this.error = true;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsImage.scss";
</style>
