import { i18n } from "@/services/plugins/i18n.js";

export const blockchains = {
    ethereum: {
        explorer: "https://etherscan.io/",
        icon: "/icons/ethereum_icon.png"
    },
    tezos: {
        explorer: "https://tzstats.com/",
        icon: "/icons/tezos_icon.png"
    },
    fake: {
        explorer: "https://fake-blockchain/",
        icon: "/images/logo.svg"
    }
};

export const txStates = {
    creation: {
        color: "primary",
        text: i18n.t("tx.creation")
    },
    confirmation: {
        color: "success",
        text: i18n.t("tx.confirmation")
    },
    error: {
        color: "error",
        text: i18n.t("tx.error")
    }
};

export const states = {
    held: {
        txt: i18n.t("tx.held-txt"),
        eqsType: "warning"
    },
    deploy: {
        txt: i18n.t("tx.deploy-txt"),
        eqsType: "warning"
    },
    deploying: {
        txt: i18n.t("tx.deploy-txt"),
        eqsType: "warning"
    },
    watch: {
        txt: i18n.t("tx.watch-txt"),
        eqsType: "warning"
    },
    watching: {
        txt: i18n.t("tx.watch-txt"),
        eqsType: "warning"
    },
    postdeploy: {
        txt: i18n.t("tx.postdeploy-txt"),
        eqsType: "warning"
    },
    postdeploying: {
        txt: i18n.t("tx.postdeploy-txt"),
        eqsType: "warning"
    },
    done: {
        txt: i18n.t("tx.done-txt"),
        eqsType: "success"
    }
};

export function getBlockchain(blockchain) {
    for (const property in blockchains) {
        if (blockchain.provider_class.includes(property)) return blockchains[property];
    }
}

export function getSlBlockchain(shareledger) {
    return getBlockchain(shareledger.company.blockchain);
}

export function isAlreadyAddedInKycRegistrar(entity, kycissuer) {
    if (!entity.investorupdate_set.length) return false;
    const investorMatchKycissuer = entity.investorupdate_set.find(tx => {
        return tx.contract_address === kycissuer.contract_address && tx.state === "done";
    });
    return !!investorMatchKycissuer;
}

/* ---------------- UTILS ---------------- */

/**
 * Returns country name as a string
 * @param {Number} countryCode
 */

export function getCountryFromCode(countryCode, allCountries) {
    let countryResult = allCountries.find(country => country["numcode"] == countryCode);
    if (!countryResult) throw `Country with code: ${countryCode} does not exit`;
    return countryResult.name;
}

export function getRatingString(rating) {
    const ratingToString = {
        1: "RETAIL",
        2: "ACCREDITED"
    };
    return ratingToString[rating];
}
