<template>
    <EqsDialog
        v-model="dialogProxy"
        closable
        persistent
        v-bind="{
            loading: loading || investorsLoading,
            cancel: {},
            progress,
            confirm: !!inviteType
                ? inviteType.confirm
                : {
                      fn: () => (dialogProxy = false),
                      txt: $t('label.invite')
                  },
            title
        }"
        @close="() => (dialogProxy = false)"
    >
        <div class="d-flex flex-column">
            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                    <EqsRadio
                        :value.sync="inviteType"
                        v-bind="{
                            loading,
                            options,
                            label: $t('shareledger.white-list.invite.category-select.label')
                        }"
                        el-class="invite-investor-invite-type"
                        @change="() => (formData = {})"
                    >
                        <template #label="{ item }">
                            <v-row no-gutters class="d-flex flex-column">
                                <EqsTextDisplay>
                                    <div class="font-weight-bold">
                                        {{ item.title }}
                                    </div>
                                </EqsTextDisplay>
                                <EqsTextDisplay :value="item.text" />
                            </v-row>
                        </template>
                    </EqsRadio>
                </v-col>
            </v-row>
            <v-expand-transition>
                <component
                    :is="inviteType.is"
                    v-if="inviteType"
                    editable
                    v-bind="{
                        formData,
                        schemaDouble,
                        loading,
                        uniqueEmailR,
                        uniqueCompanyIdCodeR
                    }"
                />
            </v-expand-transition>
        </div>
    </EqsDialog>
</template>

<script>
import { InviteEntity } from "@/services/entity";
import { formErrorMixin, progressMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "GlobalInviteInvestorDialog",
    components: {
        IndividualInviteForm: () =>
            import("@/components/views/user/forms/IndividualInviteForm.vue"),
        CompanyInviteForm: () => import("@/components/views/user/forms/CompanyInviteForm.vue"),
        GroupCSVInvite: () => import("./GroupCSVInvite")
    },
    mixins: [formErrorMixin, progressMixin],
    props: {
        value: {
            type: Boolean,
            default: false
        },
        group: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            inviteType: null,
            csvFile: null,
            progress: 0,
            formData: {},
            invitedInvestorId: null
        };
    },
    computed: {
        ...mapGetters(["investors", "investorsLoading"]),
        title() {
            return this.group
                ? this.$t("shareledger.white-list.invite-users.dialog-title-group")
                : this.$t("shareledger.white-list.invite-users.dialog-title");
        },
        options() {
            let options = [
                {
                    title: this.$t("label.individual"),
                    text: this.$t("shareledger.white-list.individual-person-invest"),
                    value: {
                        is: "IndividualInviteForm",
                        id: "individual",
                        confirm: {
                            fn: this.confirmAdd,
                            txt: this.$t("shareledger.white-list.individual-invite")
                        }
                    }
                },
                {
                    title: this.$t("label.company"),
                    text: this.$t("shareledger.white-list.company-person-invest"),
                    value: {
                        is: "CompanyInviteForm",
                        id: "company",
                        confirm: {
                            fn: this.confirmAdd,
                            txt: this.$t("shareledger.white-list.company-invite")
                        }
                    }
                }
            ];

            if (this.group)
                options.push({
                    title: this.$t("shareledger.white-list.invite-group"),
                    text: this.$t("shareledger.white-list.invite-users-text"),
                    value: {
                        confirm: {
                            fn: this.addUsersCSV,
                            txt: this.$t("shareledger.white-list.users-invite")
                        },
                        is: "GroupCSVInvite",
                        id: "group"
                    }
                });

            return options;
        },
        dialogProxy: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) this.$emit("close");
                this.$emit("update:value", val);
            }
        },
        investorsEmails() {
            return this.investors
                .filter(investor => investor.email)
                .map(investor => investor.email);
        },
        investorsCompanyIdCode() {
            return this.investors
                .filter(investor => investor.company_idcode)
                .map(investor => investor.company_idcode);
        }
    },
    watch: {
        "inviteType.id"(val) {
            if (!val) this.schemaDouble = {};
            else if (val === "group")
                this.schemaDouble = {
                    csv: {
                        isRequired: true
                    }
                };
            else this.schemaDouble = InviteEntity(this)[val].schemaDouble;
        }
    },
    created() {
        this.getInvestors();
    },
    methods: {
        ...mapActions(["getInvestors", "createInvestor", "inviteInvestors"]),
        uniqueEmailR(val) {
            if (val && this.investorsEmails.includes(val))
                return this.$t("shareledger.white-list.error-unique-email");
            return true;
        },
        uniqueCompanyIdCodeR(val) {
            if (val && this.investorsCompanyIdCode.includes(val))
                return this.$t("shareledger.white-list.error-unique-company-idcode");
            return true;
        },
        async addUsersCSV() {
            try {
                await this.inviteInvestors({
                    document_id: this.formData.csv.id
                });
                this.formData = {};
            } catch (e) {
                // TODO here back have wrong error format for csv so need to adapt in frontend
                this.handleError(e.data ? { data: { csv: e.data } } : e);
                throw e;
            }
        },
        async confirmAdd() {
            try {
                const invitedInvestor = await this.createInvestor({
                    ...this.formData,
                    is_company_invited: this.inviteType && this.inviteType.is === "company",
                    company: this.$entity.represented().url
                });

                this.$ui.success(
                    this.$t("ui.message.success.investor-successfully-invited", {
                        entity: `"${this.$entity.entityName(this.formData)}"`
                    })
                );

                this.invitedInvestorId = invitedInvestor.id;
                this.formData = {};
            } catch (e) {
                this.handleError(e, "investor_registrar_add_investor");
                throw e;
            }
        },
        confirmTextLabel(item) {
            return this.$t("shareledger.white-list.add-item", {
                itemType: item.type
            });
        }
    }
};
</script>

<style lang="scss"></style>
