export default () => ({
    corners: [],
    cornersInit: true,
    projects: [],
    projectsLoading: false,
    projectsInit: true,
    projectsCipInit: true,
    projectsCipLoading: false,
    myProjects: [],
    myProjectsLoading: false,
    myProjectsInit: true
});
