<template>
    <div class="eqs-main-container margin-auto">
        <GlobalFinalState
            v-bind="{
                state: stepperState,
                loading,
                errorText
            }"
        >
            <template #content>
                <v-container v-if="stepperState == 'success'">
                    <v-col :cols="12">
                        <EqsTextDisplay
                            class="text-center"
                            eqs-type="subtitle"
                            :value="$t('signStepper.finalStep.success')"
                        />
                    </v-col>
                </v-container>
                <v-container v-else-if="stepperState == 'warning'">
                    <v-col :cols="12">
                        <EqsTextDisplay
                            class="text-center"
                            :value="$t('simple-sign-stepper.finalStep.refused')"
                            eqs-type="subtitle"
                        />
                    </v-col>
                </v-container>
            </template>
        </GlobalFinalState>
    </div>
</template>

<script>
export default {
    name: "FinalStep",
    props: {
        stepperState: {
            type: String,
            required: true
        },
        errorText: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>
