// import DEBUG from "@/store/debug.js";
import store from "@/store/store";

export default async ({ commit, state }) => {
    if (state.vibanInit && !state.vibanLoading) {
        try {
            commit("SET_VIBAN_LOADING", true);
            const res = await window.app.$api.getMyVIBAN(
                store.getters.currentRole.entity_represented.id
            );
            commit("SET_VIBAN", res.data);
        } catch (e) {
            window.app.$ui.error(e, "account_iban");
            throw e;
        } finally {
            commit("SET_VIBAN_LOADING", false);
        }
    } else {
        return state.viban;
    }
};
