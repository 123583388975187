import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_MOVEMENTS_START");
    if (state.movementsInit && !state.movementsLoading) {
        try {
            commit("SET_MOVEMENTS_LOADING", true);
            const movementsRes = await window.app.$api.getMovements();
            const movements = movementsRes.data;
            DEBUG("GET_MOVEMENTS_SUCCESS");
            commit("SET_MOVEMENTS", movements);
            return movements;
        } catch (e) {
            DEBUG("GET_MOVEMENTS_ERROR");
            window.app.$ui.error(e, "my_transactions_movements_transactions");
            throw e;
        } finally {
            commit("SET_MOVEMENTS_LOADING", false);
        }
    } else {
        DEBUG("GET_MOVEMENTS_BYPASSED");
        return state.movements;
    }
};
