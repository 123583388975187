import Vue from "vue";
import Vuex from "vuex";
import entities from "./entities";
import messages from "./messages";
import notifications from "./notifications";
import roles from "./roles";
import shareledgers from "./shareledgers";
import procedures from "./procedures";
import governance from "./governance";
import investors from "./investors";
import loading from "./loading";
import countries from "./country";
import universe from "./universe";
import blockchain from "./blockchain";
import projects from "./projects";
import wallet from "./wallet";
import dashboardInvestor from "./dashboardInvestor";

Vue.use(Vuex);

function generateDebugMutations(actions) {
    const keysActions = Object.keys(actions);
    return keysActions.reduce((acc, key) => {
        const mutationName = key.replace(/([A-Z])/g, "_$1").toUpperCase();
        const newMutations = {
            [`${mutationName}_START`]: () => {},
            [`${mutationName}_SUCCESS`]: () => {},
            [`${mutationName}_ERROR`]: () => {},
            [`${mutationName}_BYPASSED`]: () => {}
        };
        return {
            ...acc,
            ...newMutations
        };
    }, {});
}

const modules = {
    entities,
    messages,
    notifications,
    roles,
    shareledgers,
    procedures,
    governance,
    investors,
    loading,
    countries,
    universe,
    blockchain,
    projects,
    wallet,
    dashboardInvestor
};

function debugMutations() {
    const actions = Object.values(modules).reduce(
        (acc, module) => ({
            ...acc,
            ...module.actions
        }),
        {}
    );
    return generateDebugMutations(actions);
}

export default new Vuex.Store({
    state: {},
    mutations: debugMutations(),
    actions: {},
    modules
});
