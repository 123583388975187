import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {},
        block: {}
    };
};

function size(currentSize) {
    const size = { xs: "small", sm: "small", lg: "medium", xl: "medium" }[currentSize.id];
    return size ? { [size]: true } : {};
}

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            ...size(currentSize)
        },
        block: {
            block: true,
            skeleton: {
                eqsType: "btn-block"
            },
            ...size(currentSize)
        }
    };
};
