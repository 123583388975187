import cloneDeep from "lodash/cloneDeep";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }, force = false) => {
    DEBUG("GET_CORNERS_START");
    let corners = null;
    if (state.cornersInit || force) {
        try {
            const res = await window.app.$api.getCorners();
            corners = res.data;
            DEBUG("GET_CORNERS_SUCCESS");
            commit("SET_CORNERS", corners);
        } catch (e) {
            DEBUG("GET_CORNERS_ERROR");
            window.app.$ui.error(e, "corners_get_list");
            throw e;
        } finally {
            commit("SET_CORNERS_INIT", false);
        }
    } else {
        DEBUG("GET_CORNERS_BYPASSED");
        corners = state.corners;
    }
    return cloneDeep(corners);
};
