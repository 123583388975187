import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            notifications: {
                "icon-font-size": getVal(currentSize, {
                    mobile: "26px",
                    tablet: "28px",
                    desktop: "30px"
                })
            }
        }
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {}
    };
};
