<template>
    <EqsDataTable :searchable="{}" v-bind="{ headers, items, loading }">
        <template #item.avatar>
            <EqsAvatar />
        </template>
        <template #item.value="{ item }">
            {{ $currency.displayWithCurrency(item.value) }}
        </template>
        <template #item.investment="{ item }">
            {{ $currency.displayWithCurrency(item.investment) }}
        </template>
        <template #item.titles="{ item }">
            <div class="d-flex">
                <EqsChip v-for="title in item.titles" :key="title" class="pr-4" eqs-type="info">
                    {{ title }}
                </EqsChip>
            </div>
        </template>
        <template #item.price_per_share="{ item }">
            {{ $currency.displayWithCurrency(item.price_per_share) }}
        </template>
        <template #item.last_price_per_share="{ item }">
            {{ $currency.displayWithCurrency(item.last_price_per_share) }}
        </template>
        <template #item.details="{ item }">
            <EqsBtn block color="primary" @click="openDetails(item.id)">
                Voir la fiche détails
            </EqsBtn>
        </template>
    </EqsDataTable>
</template>

<script>
const mockItems = [
    {
        id: "0307ccd9-ddba-49d9-8a9a-3d03adb8f764",
        name: "Equisafe",
        value: 20000000.0,
        investment: 20000.0,
        titles: ["BSPCE", "AO"],
        number_of_shares: 50,
        price_per_share: 5000.0,
        last_price_per_share: 500000.0
    },
    {
        id: "ae4ea88c-4bd7-4df8-adac-d37ab9d37f4b",
        name: "SuperTripper",
        value: 10568000.0,
        investment: 30568.0,
        titles: ["AO"],
        number_of_shares: 150,
        price_per_share: 15000.0,
        last_price_per_share: 500000.0
    },
    {
        id: "9ea32a77-d290-4efa-8f83-d8203278955c",
        name: "GamersOrigin",
        value: 80040000.0,
        investment: 40000.0,
        titles: ["AO"],
        number_of_shares: 200,
        price_per_share: 105000.0,
        last_price_per_share: 500000.0
    }
];

export default {
    name: "Companies",
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            items: mockItems,
            headers: [
                {
                    text: "",
                    value: "avatar",
                    sortable: false,
                    width: "64px"
                },
                {
                    text: "Société",
                    // text: this.$t("portfolio.companies.headers.company"),
                    value: "name",
                    sortable: true
                },
                {
                    text: "Valorisation d'achat",
                    // text: this.$t("portfolio.companies.headers.value"),
                    value: "value",
                    sortable: true
                },
                {
                    text: "Investissement",
                    // text: this.$t("portfolio.companies.headers.investment"),
                    value: "investment",
                    sortable: true
                },
                {
                    text: "Titres",
                    // text: this.$t("portfolio.companies.headers.titles"),
                    value: "titles",
                    sortable: true
                },
                {
                    text: "Nombre de titres",
                    // text: this.$t("portfolio.companies.headers.number_of_shares"),
                    value: "number_of_shares",
                    sortable: true
                },
                {
                    text: "Prix d'achat du titre",
                    // text: this.$t("portfolio.companies.headers.price_per_share"),
                    value: "price_per_share",
                    sortable: true
                },
                {
                    text: "Prix du titre à la dernière levée",
                    // text: this.$t("portfolio.companies.headers.last_price_per_share"),
                    value: "last_price_per_share",
                    sortable: true
                },
                {
                    text: "",
                    value: "details",
                    sortable: false,
                    width: "200px"
                }
            ]
        };
    },
    methods: {
        openDetails(id) {
            this.$router.push({
                name: "company-details",
                params: { id }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
