var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`eqs-stepper d-flex flex-column ${_vm.elClass}-container`,style:({ ..._vm.getStyle, ..._vm.stepperStyle, ..._vm.bgColor })},[_c('v-stepper',_vm._g(_vm._b({staticClass:"d-flex flex-column",attrs:{"alt-labels":"","value":_vm.valueProxy},on:{"update:value":function($event){_vm.valueProxy=$event}}},'v-stepper',{ ..._vm.$attrs },false),_vm.$listeners),[_vm._t("steps",function(){return [(!_vm.singleStep)?_c('v-stepper-header',{staticClass:"eqs-stepper__header"},[_c('v-row',{staticClass:"d-flex flex-nowrap align-stretch",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"11"}},_vm._l((_vm.steps),function(step,index){return _c('v-stepper-step',{key:`${step.title}-step`,class:`eqs-stepper__header__step ${_vm.elClass}-step-${index}`,attrs:{"complete":index < _vm.valueProxy - 1,"color":index < _vm.valueProxy - 1 ? 'success' : 'primary',"step":index + 1,"rules":[() => !(index === _vm.value && !_vm.validForm)]}},[_c('div',{staticClass:"text-center mr-0 ml-0 eqs-stepper__header__step__label",style:(`font-size: 80%;`)},[_vm._v(" "+_vm._s(step.title)+" ")])])}),1),_c('v-col',{staticClass:"pa-0 d-flex justify-end align-center",attrs:{"cols":"1"}},[(_vm.closable)?_c('EqsBtn',_vm._b({staticClass:"pr-2",class:`${_vm.elClass}-close`,attrs:{"text":""},on:{"click":() => _vm.$emit('close')}},'EqsBtn',{ infoTxt: _vm.closeInfoTxt },false),[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()],1)],1)],1):_vm._e(),_c('v-row',{class:`d-flex flex-nowrap align-stretch pb-0 ${
                    _vm.singleStep || !_vm.$reactive.isDesktop() ? '' : 'mt-3'
                }`,staticStyle:{"height":"fit-content"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"height-fit",attrs:{"cols":"2"}}),_c('v-col',{staticClass:"height-fit"},[(_vm.item.title)?_c('EqsTextDisplay',{class:`text-center flex pb-${_vm.$reactive.isMobile() ? '2' : '3'} ${
                            _vm.singleStep ? 'pt-3' : ''
                        }`,attrs:{"value":_vm.item.title,"eqs-type":"subtitle"}}):_vm._e()],1),_c('v-col',{staticClass:"pa-0 d-flex justify-end height-fit",attrs:{"cols":"2","align-self":"center"}},[(_vm.closable && _vm.singleStep)?_c('EqsBtn',_vm._b({attrs:{"text":"","data-cy":`${_vm.elClass}-close`},on:{"click":() => _vm.$emit('close')}},'EqsBtn',{ infoTxt: _vm.closeInfoTxt },false),[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()],1)],1),_c('v-slide-x-transition',[(!_vm.progress)?_c('v-divider',{staticStyle:{"width":"100%"}}):_c('v-progress-linear',{attrs:{"value":_vm.progress,"color":_vm.progressColor}})],1)]}),_c('v-form',{ref:"stepperForm",staticClass:"eqs-stepper__steps d-flex flex-column",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-stepper-items',[_c('v-slide-x-transition',[(!_vm.transition)?_c('v-stepper-content',{class:`${_vm.elClass}-content`,attrs:{"step":_vm.valueProxy}},[_c('div',{class:`eqs-stepper__steps__content ${
                                _vm.item.noPadding ? 'pa-0' : ''
                            }`},[_vm._t("default",null,null,{
                                    loading: _vm.loading,
                                    index: _vm.valueProxy,
                                    item: _vm.item.item,
                                    validForm: _vm.validForm
                                })],2)]):_vm._e()],1)],1)],1),_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"eqs-stepper__btns",attrs:{"wrap":""}},[_c('v-col',{staticClass:"d-flex mx-3",staticStyle:{"min-width":"fit-content"},attrs:{"align-self":"center"}},[(!_vm.item.noBack)?_c('EqsBtn',_vm._b({attrs:{"small":"","color":"primary","outlined":"","el-class":`${_vm.elClass}-back`},scopedSlots:_vm._u([{key:"with-icon",fn:function(){return [_c('div',{staticClass:"text-truncate"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter( _vm.item.back && _vm.item.back.txt ? _vm.item.back.txt : _vm.$t("btn.back") ))+" ")],1)]},proxy:true}],null,false,3915198410)},'EqsBtn',{
                            action:
                                _vm.item.back && _vm.item.back.fn
                                    ? _vm.item.back.fn
                                    : () => (_vm.valueProxy = _vm.value - 1),
                            loading: _vm.loading,
                            ..._vm.getData.backBtn
                        },false)):_vm._e()],1),_c('v-col',{staticClass:"d-flex mx-3",staticStyle:{"min-width":"fit-content"},attrs:{"cols":"4","align-self":"center"}},[(!_vm.item.noNext)?_c('EqsBtn',_vm._b({staticStyle:{"width":"100%"},attrs:{"el-class":`${_vm.elClass}-next`,"block":"","color":_vm.getBtnColor}},'EqsBtn',{
                            action: () =>
                                _vm.validate(
                                    _vm.item.next && _vm.item.next.fn
                                        ? _vm.item.next.fn
                                        : () => {
                                              _vm.valueProxy = _vm.value + 1;
                                          }
                                ),
                            loading: _vm.loading,
                            errorMessages: _vm.errorMessages,
                            ..._vm.getData.nextBtn
                        },false),[_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.item.next && _vm.item.next.txt ? _vm.item.next.txt : _vm.$t("btn.next"))+" ")])]):_vm._e()],1),_c('v-spacer',{staticClass:"mx-3 pa-3"})],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }