var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsStepper',_vm._b({staticStyle:{"height":"100%"},attrs:{"value":_vm.step},on:{"update:value":function($event){_vm.step=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item, loading: _loading, validForm }){return [_c('div',{staticClass:"eqs-main-container margin-auto"},[_c(item.is,_vm._b({tag:"component",attrs:{"step":_vm.step,"form-data":_vm.formData},on:{"update:step":function($event){_vm.step=$event},"update:formData":function($event){_vm.formData=$event},"update:form-data":function($event){_vm.formData=$event}},scopedSlots:_vm._u([{key:"iban",fn:function(){return [_c('CurrentBankAccount',_vm._b({staticClass:"mb-3"},'CurrentBankAccount',{ loading: _vm.localLoading, bankDetailsOnly: true },false))]},proxy:true}],null,true)},'component',{
                    loading: _loading || _vm.ibanLoading,
                    order: _vm.order,
                    validForm,
                    status: _vm.status,
                    state: _vm.finalState,
                    doc: _vm.documentProof,
                    loading: _vm.loading
                },false))],1)]}}])},'EqsStepper',{ steps: _vm.steps, loading: _vm.loading || _vm.localLoading || !_vm.iban, progress: _vm.progress },false))
}
var staticRenderFns = []

export { render, staticRenderFns }