var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-main-container margin-auto"},[_c('GlobalFinalState',_vm._b({scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.stepperState == 'success')?_c('v-container',[_c('v-col',{attrs:{"cols":12}},[_c('EqsTextDisplay',{staticClass:"text-center",attrs:{"eqs-type":"subtitle","value":_vm.$t('signStepper.finalStep.success')}})],1),_c('v-col',{attrs:{"cols":12}},[_c('EqsTextDisplay',{staticClass:"text-center",attrs:{"paragraph":"","value":_vm.$t('signStepper.finalStep.succes-explanatory-text', {
                                numberofshares: _vm.$currency.display(_vm.quantity, '0,0'),
                                buyerName: _vm.buyer.name,
                                total: _vm.$currency.display(_vm.total),
                                company: _vm.order.shareledger.company.name
                            })}})],1)],1):(_vm.stepperState == 'warning')?_c('v-container',[_c('v-col',{attrs:{"cols":12}},[_c('EqsTextDisplay',{staticClass:"text-center",attrs:{"value":_vm.$t('signStepper.finalStep.refused'),"eqs-type":"subtitle"}})],1)],1):_vm._e()]},proxy:true}])},'GlobalFinalState',{
            state: _vm.stepperState,
            loading: _vm.loading,
            errorText: _vm.errorText
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }