var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`${_vm.elClass} eqs-dialog`,style:(_vm.getStyle)},[_c('v-dialog',_vm._g(_vm._b({ref:"eqs-dialog",attrs:{"value":_vm.valueProxy,"el-class":`${_vm.elClass}-value`},on:{"update:value":function($event){_vm.valueProxy=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(slotData){return [_vm._t("activator",null,null,slotData)]}}],null,true)},'v-dialog',{
            ..._vm.getData,
            attach: _vm.attachToVApp ? '#app' : `.${_vm.elClass}`,
            overlayColor: _vm.$color.colors.almostWhite,
            overlayOpacity: 0.3,
            ..._vm.$attrs,
            contentClass: `${_vm.elClass}-content eqs-dialog`
        },false),_vm.$listeners),[_c('v-form',{ref:"dialogform",staticClass:"d-flex eqs-dialog__form",attrs:{"lazy-validation":"","disabled":_vm.localLoading || _vm.loading},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-card',_vm._b({staticClass:"eqs-dialog__container d-flex flex-column",attrs:{"el-class":_vm.elClass}},'v-card',{ loading: _vm.loading },false),[_vm._t("main"),(_vm.title)?_c('v-card-title',{class:_vm.closable ? `px-0` : ''},[_c('v-row',{staticClass:"d-flex flex-nowrap align-stretch eqs-dialog__container__header",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{class:`text-center pa-0 ${_vm.getData.textTitleClass}`,attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex flex-column align-center"},[(_vm.getData.img || _vm.img)?_c('EqsLoader',_vm._b({},'EqsLoader',{
                                        eqsDataType: _vm.getData.loaderType || 'default',
                                        loading: _vm.localLoading || _vm.loading,
                                        size:
                                            _vm.getData.logo && _vm.getData.logo.width
                                                ? _vm.getData.logo.width
                                                : '48px'
                                    },false),[_c('v-img',_vm._b({staticClass:"pb-1",attrs:{"src":_vm.getData.img || _vm.img,"height":"auto","contain":""}},'v-img',_vm.getData.logo,false))],1):_vm._e(),_c('EqsTextDisplay',{attrs:{"eqs-type":"title"}},[_c('div',{staticClass:"break-word",attrs:{"el-class":`${_vm.elClass}-title`}},[_vm._v(" "+_vm._s(_vm.title)+" ")])])],1)]),_c('v-col',{staticClass:"pa-0 pr-2 d-flex justify-end",attrs:{"cols":"2","align-self":"center"}},[(_vm.closable)?_c('EqsBtn',_vm._b({attrs:{"text":"","el-class":"notify-close"},on:{"click":_vm.closeDialog}},'EqsBtn',{
                                    infoTxt: _vm.closeInfoTxt || _vm.$t('molecule.dialog.close-txt')
                                },false),[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.subtitle)?_c('v-card-subtitle',{class:_vm.subtitle && _vm.title ? 'pt-3' : ''},[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle"}},[_c('div',{class:`${_vm.getData.textSubtitleClass} break-word`},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])])],1):_vm._e(),_c('v-slide-x-transition',[(_vm.progress)?_c('v-progress-linear',{attrs:{"value":_vm.progress,"color":_vm.progressColor}}):_vm._e()],1),_c('v-card-text',{class:`${_vm.getData.textBodyClass} eqs-dialog__container__content ${_vm.getData.cardTextClass}`,attrs:{"el-class":`${_vm.elClass}-text`}},[(_vm.text)?_c('div',{staticClass:"eqs-dialog__container__content__text text-justify"},[_c('EqsTextDisplay',_vm._b({attrs:{"paragraph":""}},'EqsTextDisplay',{ value: _vm.text },false))],1):_vm._e(),_vm._t("default",null,null,{ loading: _vm.loading || _vm.localLoading })],2),(_vm.confirm || _vm.cancel)?_c('v-card-actions',{staticClass:"justify-center align-center flex-wrap pt-0"},[(_vm.cancel)?_c('EqsBtn',_vm._b({staticClass:"mx-4 my-2",attrs:{"el-class":`${_vm.elClass}-cancel`,"outlined":""},on:{"error":val => (_vm.error = val),"update:loading":val => (_vm.localLoading = val)}},'EqsBtn',{
                            ..._vm.cancel,
                            color: _vm.cancel.color || _vm.getData.color || 'primary',
                            loading: _vm.loading,
                            disabled: _vm.localLoading,
                            action: _vm.cancel && _vm.cancel.fn ? _vm.cancel.fn : _vm.closeDialog
                        },false),[_vm._v(" "+_vm._s(_vm.cancel.txt || _vm.$t("label.cancel"))+" ")]):_vm._e(),(_vm.confirm)?_c('EqsBtn',_vm._b({staticClass:"mx-4 my-2",attrs:{"el-class":`${_vm.elClass}-confirm`,"depressed":""},on:{"error":val => (_vm.error = val),"update:loading":val => (_vm.localLoading = val)}},'EqsBtn',{
                            ..._vm.confirm,
                            'text-color': 'white',
                            color: _vm.confirm.color || _vm.getData.color || 'primary',
                            loading: _vm.loading,
                            disabled: !_vm.validForm || _vm.localLoading,
                            action: () =>
                                _vm.validate(_vm.confirm && _vm.confirm.fn ? _vm.confirm.fn : _vm.closeDialog)
                        },false),[_vm._v(" "+_vm._s(_vm.confirm.txt || _vm.$t("label.confirm"))+" ")]):_vm._e()],1):_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }