var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({},'EqsCard',{
          loading: _vm.loading,
          width: '100%',
          height: '100%',
          eqsType: 'container'
      },false),[_c('v-row',{staticClass:"fill-height d-flex justify-center px-3 overflow-y"},[_c('v-col',{attrs:{"cols":_vm.$reactive.isDesktop() ? 6 : 12}},[_c('v-row',{staticClass:"px-3 pb-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsSelectLanguage',_vm._b({staticClass:"mb-5",on:{"update:value":_vm.switchLanguage}},'EqsSelectLanguage',{
                              loading: _vm.loading,
                              value: _vm.$language.current(),
                              items: _vm.$language.allLanguages()
                          },false)),_c('EqsTextDisplay',{staticClass:"mb-5 pt-3",attrs:{"eqs-type":"subtitle","value":_vm.$t('user.profile.preferences.email-notification.label')}}),_c('EqsTextDisplay',{staticClass:"mb-3",attrs:{"value":_vm.$t('user.profile.preferences.email-notification.info')}}),_c('EqsSwitch',_vm._b({staticClass:"my-3",on:{"change":event => _vm.updateEmail(event, 'no_email_invitation')}},'EqsSwitch',{
                              loading: _vm.localLoading,
                              value: !_vm.entity.no_email_invitation,
                              isRequired: false,
                              label: _vm.$t('user.profile.invitation-no-email-label'),
                              text: _vm.$t('user.profile.invitation-no-email-text')
                          },false)),_c('EqsSwitch',_vm._b({staticClass:"my-3",on:{"change":event => _vm.updateEmail(event, 'no_email_KYC')}},'EqsSwitch',{
                              loading: _vm.localLoading,
                              value: !_vm.entity.no_email_KYC,
                              isRequired: false,
                              label: _vm.$t('user.profile.kyc-no-email-label'),
                              text: _vm.$t('user.profile.kyc-no-email-text', { universe: _vm.universe })
                          },false)),_c('EqsSwitch',_vm._b({staticClass:"my-3",on:{"change":event => _vm.updateEmail(event, 'no_email_share')}},'EqsSwitch',{
                              loading: _vm.localLoading,
                              value: !_vm.entity.no_email_share,
                              isRequired: false,
                              label: _vm.$t('user.profile.share-no-email-label'),
                              text: _vm.$t('user.profile.share-no-email-text')
                          },false)),(_vm.entity.is_company)?_c('div',[_c('EqsTextDisplay',{staticClass:"my-5",attrs:{"eqs-type":"subtitle","value":_vm.$t('user.profile.preferences.communication.label')}}),_c('EqsTextDisplay',{staticClass:"mb-3",attrs:{"value":_vm.$t('user.profile.preferences.communication.info')}}),_c('EqsSwitch',_vm._b({staticClass:"my-3",on:{"change":event => _vm.updatePreferences(event, 'corrected_tri_enabled')}},'EqsSwitch',{
                                  loading: _vm.localLoading,
                                  value: _vm.allPreferences.corrected_tri_enabled,
                                  isRequired: false,
                                  label: _vm.$t('user.profile.preferences.TRI-label'),
                                  text: _vm.$t('user.profile.preferences.TRI-text')
                              },false)),_c('EqsSwitch',_vm._b({staticClass:"my-3",on:{"change":event => _vm.updatePreferences(event, 'hide_transfert_type_enabled')}},'EqsSwitch',{
                                  loading: _vm.localLoading,
                                  value: _vm.allPreferences.hide_transfert_type_enabled,
                                  isRequired: false,
                                  label: _vm.$t('user.profile.preferences.transfer-label'),
                                  text: _vm.$t('user.profile.preferences.transfer-text')
                              },false))],1):_c('div',{staticClass:"d-flex flex-column"},[_c('EqsTextDisplay',{staticClass:"mb-5 pt-3",attrs:{"eqs-type":"subtitle","value":_vm.$t('user.profile.preferences.security.label')}}),_c('EqsTextDisplay',{staticClass:"mb-3",attrs:{"value":_vm.$t('user.profile.preferences.security.info')}}),_c('ChangePassword',_vm._b({staticClass:"my-5"},'ChangePassword',{ entity: _vm.entity, loading: _vm.loading },false)),_c('TwoFA',_vm._b({staticClass:"my-5"},'TwoFA',{ entity: _vm.entity, loading: _vm.loading },false))],1),_c('DeleteUser',{staticClass:"my-3"})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }