<template>
    <EqsDataTable
        ref="history"
        hide-default-footer
        v-bind="{
            itemKey,
            loading: loading || localLoading,
            items,
            headers,
            column: false
        }"
    >
        <template #item.created_at="{ item }">
            {{ $time.formated(item.confirmed_datetime) }}
        </template>
        <template #item.investor_source="{ item }">
            {{ item.investor_source_name }}
        </template>
        <template #item.investor_destination="{ item }">
            {{ item.investor_destination_name }}
        </template>
        <template #item.numberofshares="{ item }">
            {{ $currency.display(item.numberofshares, "0,0") }}
        </template>
        <template #item.pricepershare="{ item }">
            {{ $currency.display(item.pricepershare * item.numberofshares) }}
        </template>
    </EqsDataTable>
</template>

<script>
export default {
    name: "ShareledgerDetailsHistory",
    props: {
        headers: {
            type: Array,
            required: true
        },
        itemKey: {
            type: String,
            default: "id"
        },
        shareledgerId: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            items: [],
            localLoading: false
        };
    },
    watch: {
        shareledgerId() {
            this.$refs.history.udpate();
        }
    },
    async created() {
        await this.fetch();
    },
    methods: {
        async fetch(filters = null) {
            try {
                this.localLoading = true;
                const res = await this.$api.readTinyAssetMovementList({
                    shareledgerId: this.shareledgerId,
                    with_valid_transaction: true
                });
                this.items = res.data.results;
            } catch (e) {
                this.$ui.error(e, "shareledger_details_history");
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>
