export const doItems = function _doItems(...args) {
    let items = [];
    for (const arg of args) {
        items.push({
            text: arg,
            value: arg
        });
    }
    return items;
};
