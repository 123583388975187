<template>
    <v-container fluid>
        <v-tabs
            v-if="tabsItems.length > 1"
            v-model="tabSelected"
            centered
            show-arrows
            grow
            @change="tabChanged"
        >
            <v-tab
                v-for="(itemTabHeader, i) in tabsItems"
                :key="i"
                class="mx-6"
                :class="i === 0 ? 'ml-12' : ''"
                :href="`#${itemTabHeader.view}`"
            >
                {{ itemTabHeader.label }}
            </v-tab>
        </v-tabs>
        <transition name="eqs-slide-from-right" class="d-flex fill-height fill-width">
            <v-tabs-items v-model="tabSelected" class="pa-6">
                <v-tab-item
                    v-for="(itemTabItem, i) in tabsItems"
                    :key="i"
                    :value="`${itemTabItem.view}`"
                >
                    <template #default>
                        <component
                            :is="itemTabItem.component"
                            v-bind="{
                                type: itemTabItem.view,
                                isParent: true
                            }"
                        />
                    </template>
                </v-tab-item>
            </v-tabs-items>
        </transition>
    </v-container>
</template>

<script>
import Dashboard from "./Dashboard";

export default {
    name: "Affiliations",
    components: {
        Dashboard
    },
    inject: ["_entity"],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tabSelected: null,
            tabsItems: []
        };
    },
    activated() {
        // Si il n'y a pas de query tab dans l'url (pour le bookmark et le cache et la vue par defaut)
        let defaultTab = null;
        if (!this.$route.query.tab) {
            // Un tab est déjà sélectionné
            if (!this.tabSelected) {
                if (this.$entity.isAffiliationAdmin()) {
                    defaultTab = "view_admin";
                } else if (this.$entity.isAffiliationBc()) {
                    defaultTab = "view_bc";
                } else if (this.$entity.isAffiliationCgp()) {
                    defaultTab = "view_cgp";
                }
            } else {
                defaultTab = this.tabSelected;
            }
            if (defaultTab && this.tabsItems.length > 1) {
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        tab: defaultTab
                    }
                });
            }
        } else {
            defaultTab = this.$route.query.tab;
        }
        if (!this.tabSelected) {
            this.tabSelected = defaultTab;
        }
        if (!this.tabsItems.length) {
            this.createTabs();
        }
    },
    methods: {
        createTabs() {
            let tmpTabItems = [];
            if (this.$entity.isAffiliationAdmin()) {
                tmpTabItems.unshift({
                    view: "view_admin",
                    label: this.$t("universe.kyoseil.dashboard.bcs"),
                    component: "Dashboard"
                });
            }
            if (this.$entity.isAffiliationAdmin() || this.$entity.isAffiliationBc()) {
                tmpTabItems.unshift({
                    view: "view_bc",
                    label: this.$t("universe.kyoseil.dashboard.cgps"),
                    component: "Dashboard"
                });
            }
            if (this.$entity.isAffiliationCgp() || this.$entity.isAffiliationAdmin()) {
                tmpTabItems.unshift({
                    view: "view_cgp",
                    label: this.$t("universe.kyoseil.dashboard.investors"),
                    component: "Dashboard"
                });
            }
            this.tabsItems = tmpTabItems;
        },
        tabChanged(newTab, oldTab) {
            if (this.$route.query.tab === newTab || newTab === oldTab) return;
            this.$router.replace({
                ...this.$route,
                query: {
                    ...this.$route.query,
                    tab: newTab
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.v-tab {
    text-transform: none;
}
</style>
