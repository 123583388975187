<template>
    <div class="eqs-label mt-2" :style="{ ...getStyle, ...styleFromContext }">
        <div v-if="value" class="label fill-width" layout row no-wrap align-center>
            <v-tooltip open-on-hover open-on-focus :disabled="!infoTxt" right max-width="18rem">
                <template #activator="{ on }">
                    <v-row
                        class="label__spacing flex-nowrap"
                        align-content="space-between"
                        align="center"
                        v-on="on"
                    >
                        <EqsBadge
                            :value="(isRequired && value && editable) || (locked && editable)"
                            :class="`eqs-label__${!locked ? 'asterisk' : 'locked'}`"
                            color="transparent"
                            v-bind="!locked ? getData.asterisk : getData.locked"
                        >
                            <label
                                v-if="value"
                                :class="`font-weight-${editable ? 'bold' : 'medium'} ${
                                    getData.textLabelClass
                                } ${
                                    eqsSize === 'lg' ? getData.textSubtitleClass : ''
                                } ${elClass}-label`"
                                :style="height ? `min-height: ${height}` : null"
                            >
                                <span>{{ value }}</span>
                            </label>
                        </EqsBadge>
                        <div
                            :style="height ? `min-height: ${height}` : null"
                            class="mx-5 flex text-end"
                        >
                            <slot name="append-label" />
                        </div>
                        <v-icon v-if="infoTxt" class="mx-2" color="primary">
                            mdi-information
                        </v-icon>
                    </v-row>
                </template>
                <span v-if="infoTxt">{{ infoTxt }}</span>
            </v-tooltip>
        </div>
        <slot />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsLabel.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsLabel",
    components: {
        EqsBadge: () => import("../EqsBadge/EqsBadge.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        infoTxt: {
            type: String,
            default: ""
        },
        editable: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        eqsSize: {
            type: String,
            default: "md"
        },
        locked: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        styleFromContext() {
            return !this.editable
                ? {
                      "--input-label-margin-left": 0
                  }
                : {
                      "--input-label-margin-left": "0px"
                  };
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsLabel.scss";
</style>
