<template>
    <div class="ma-0 pa-0">
        <div class="text-subtitle">{{ $t("projects.reporting.crud.cta.title") }}</div>
        <v-text-field
            v-model="field.name"
            :label="$t('projects.reporting.crud.cta.name')"
            required
            :rules="fieldCtaNameR"
        />
        <v-text-field
            v-model="field.link"
            :label="$t('projects.reporting.crud.cta.link')"
            required
            :rules="fieldCtaLinkR"
        />
    </div>
</template>

<script>
import { fieldCtaNameR, fieldCtaLinkR } from "../utils";

export default {
    name: "FormCta",
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fieldCtaNameR,
            fieldCtaLinkR
        };
    }
};
</script>

<style lang="scss" scoped></style>
