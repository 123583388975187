import cloneDeep from "lodash/cloneDeep";
import init from "./init";

const MINUTE = 1000 * 60;

export default {
    SET_DI_INFORMATIONS(state, data) {
        state.diInformations[data.id] = {
            data: cloneDeep(data),
            expire_at: Date.now() + 15 * MINUTE
        };
    },
    SET_DI_DOCUMENTS(state, data) {
        state.diDocuments[data.id] = {
            data: cloneDeep(data),
            expire_at: Date.now() + 15 * MINUTE
        };
    },
    SET_DI_ORDERS(state, data) {
        state.diOrders[data.id] = {
            data: cloneDeep(data),
            expire_at: Date.now() + 15 * MINUTE
        };
    },
    RESET_STORE_DI(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
