import { Position, positionComponent } from "../Position";
import { companyInformation } from "./companyInformation";
import { createTableHeader, createTableContent } from "./createTable";
import { Xls } from "../Xls";
import { Style } from "../Style";
import { i18n } from "@/services/plugins/i18n.js";

export function createWorksheetAssetMovement(workbook, shareledgerData, dataCompany) {
    const frozenPosition = new Position(positionComponent.tableHeader);
    const worksheet = workbook.addWorksheet("Asset Movement", Style.worksheet);
    Style.addFooterPrint(worksheet);
    worksheet.views = [
        {
            state: "frozen",
            xSplit: 0,
            ySplit: frozenPosition._row,
            activeCell: "A1"
        }
    ];

    new Xls(worksheet, "A1")
        .componentLogo(workbook)
        .componentTitle(i18n.t("excel-js.shareledger-movement-history"));

    companyInformation(worksheet, dataCompany);
    createTableHeader(worksheet);
    createTableContent(worksheet, shareledgerData);
}
