<template>
    <div class="eqs-colored-card" :el-class="elClass" :style="getStyle">
        <EqsSkeleton
            class="d-flex"
            v-bind="{
                loading,
                'min-height': getStyle['--container-min-height'],
                height: loading ? getStyle['--container-min-height'] : '',
                type: 'image'
            }"
        >
            <div class="eqs-colored-card-container d-flex flex-column">
                <div :class="`eqs-colored-card__header ${getData.textLabelClass}`">
                    {{ label }}
                </div>
                <div
                    :class="`eqs-colored-card__content ${getData.textSubtitleClass} d-flex justify-center align-center`"
                >
                    <div v-if="txt">
                        {{ txt }}
                    </div>
                    <slot v-else name="default" />
                </div>
            </div>
        </EqsSkeleton>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsColoredCard.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsColoredCard",
    components: {
        EqsSkeleton: () => import("../EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-colored-card"
        },
        label: {
            type: String,
            required: true
        },
        txt: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            defautl: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsColoredCard.scss";
</style>
