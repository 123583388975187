// classes taken from https://vuetifyjs.com/en/styles/text-and-typography/
// ref -> https://material.io/design/typography/the-type-system.html

import { getVal } from "../services/reactive.js";

export const textSizing = (currentSize, mode = "md") => {
    let classes = {
        textHeaderClass: "text-header",
        textTitleClass: "text-title",
        textSubtitleClass: "text-subtitle",
        textSectionClass: "text-section",
        textBtnClass: "text-btn",
        textLabelClass: "text-label",
        textBodyClass: "text-body",
        textBodyBoldClass: "text-body-bold",
        textPlacehodlerClass: "text-placeholder",
        textPClass: "text-p",
        textCaptionClass: "text-caption",
        textFootClass: "text-foot"
    };
    for (const [key, value] of Object.entries(classes)) {
        classes[key] = `${value} ${getVal(currentSize, {
            mobile: `${value}__font-mobile`,
            tablet: `${value}__font-tablet`,
            desktop: `${value}__font-desktop`
        })}`;
    }
    return classes;
};

export const textSizingByType = currentSize => {
    const _textSizing = textSizing(currentSize);
    return {
        default: {
            textSizing: _textSizing.textBodyClass
        },
        header: {
            textSizing: _textSizing.textHeaderClass
        },
        title: {
            textSizing: _textSizing.textTitleClass
        },
        subtitle: {
            textSizing: _textSizing.textSubtitleClass
        },
        section: {
            textSizing: _textSizing.textSectionClass
        },
        btn: {
            textSizing: _textSizing.textBtnClass
        },
        label: {
            textSizing: _textSizing.textLabelClass
        },
        body: {
            textSizing: _textSizing.textBodyClass
        },
        bodyBold: {
            textSizing: _textSizing.textBodyBoldClass
        },
        placeholder: {
            textSizing: _textSizing.textPlaceholderClass
        },
        p: {
            textSizing: _textSizing.textPClass
        },
        caption: {
            textSizing: _textSizing.textCaptionClass
        },
        foot: {
            textSizing: _textSizing.textFootClass
        }
    };
};

export const textCSSByType = (currentSize, ctx) => {
    const body = {
        text: {
            "min-width":
                !ctx || ctx.loading
                    ? getVal(currentSize, {
                          xs: "3rem",
                          sm: "4rem",
                          md: "6rem",
                          lg: "8rem",
                          xl: "10rem"
                      })
                    : ""
        }
    };
    return {
        default: body,
        header: {
            "min-width":
                !ctx || ctx.loading
                    ? getVal(currentSize, {
                          xs: "8rem",
                          sm: "9rem",
                          md: "11rem",
                          lg: "13rem",
                          xl: "15rem"
                      })
                    : ""
        },
        title: {
            "min-width":
                !ctx || ctx.loading
                    ? getVal(currentSize, {
                          xs: "7rem",
                          sm: "8rem",
                          md: "10rem",
                          lg: "12rem",
                          xl: "14rem"
                      })
                    : ""
        },
        subtitle: {
            "min-width":
                !ctx || ctx.loading
                    ? getVal(currentSize, {
                          xs: "5rem",
                          sm: "6rem",
                          md: "8rem",
                          lg: "10rem",
                          xl: "12rem"
                      })
                    : ""
        },
        section: {
            "min-width":
                !ctx || ctx.loading
                    ? getVal(currentSize, {
                          xs: "4rem",
                          sm: "5rem",
                          md: "7rem",
                          lg: "9rem",
                          xl: "11rem"
                      })
                    : ""
        },
        btn: {},
        label: {},
        body: body,
        bodyBold: body,
        placeholder: {},
        p: {},
        caption: {},
        foot: {}
    };
};
