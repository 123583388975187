<template>
    <div>
        <v-form
            ref="sendMailForgotPasswordForm"
            v-model="validForm"
            @submit.prevent="sendMailForgotPassword"
        >
            <EqsEmail
                id="email"
                el-class="email-forgot"
                class="mb-5"
                v-bind="{ loading }"
                :label="$t('forgot-password.label-email')"
                :value.sync="email"
            />
            <v-col
                :cols="
                    $reactive.getVal({
                        mobile: 10,
                        tablet: 8,
                        desktop: 8
                    })
                "
                class="ma-auto"
            >
                <EqsBtn
                    id="btnSendMailForgotPassword"
                    color="primary"
                    block
                    v-bind="{
                        action: sendMailForgotPassword,
                        disabled: !validForm || loading
                    }"
                    el-class="btn-register"
                    @update:loading="val => (loading = val)"
                >
                    {{ $t("forgot-password.button") }}
                </EqsBtn>
            </v-col>
        </v-form>
        <div class="my-5">
            <a class="text-link" el-class="btn-login" @click="$router.push('/auth')">{{
                $t("auth.register.sign-in")
            }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "SendMailForgotPassword",
    data() {
        return {
            email: "",
            show: false,
            validForm: false,
            loading: false
        };
    },
    methods: {
        async sendMailForgotPassword() {
            if (this.$refs.sendMailForgotPasswordForm.validate()) {
                try {
                    await this.$api.passwordReset(this.email);
                    this.$ui.success(this.$t("ui.message.success.forgot-password"));
                } catch (e) {
                    this.$ui.error(e, "send_mail_forgot_password");
                }
            }
        }
    }
};
</script>
