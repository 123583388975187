var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsViewWithImage',_vm._b({},'EqsViewWithImage',{ img: '/images/Vecteur_Vente.svg' },false),[_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextDisplay',_vm._b({attrs:{"paragraph":""}},'EqsTextDisplay',{ loading: _vm.loading },false),[_vm._v(" "+_vm._s(_vm.$t("secondary-market.sell-shares.shares-form.text-1"))+" ")])],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.shareledger,"items":_vm.shareledgers,"item-text":"shareledger.name","persistent-hint":"","return-object":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "shareledger", $event)}}},'EqsSelect',{
                        label: _vm.$t('sellShares.label.shareledger'),
                        loading: _vm.loading,
                        clearable: false,
                        hint: _vm.isOnlyInvestorsTxt(_vm.formData.shareledger)
                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsNumberShares',_vm._b({attrs:{"value":_vm.formData.quantity,"rules":[_vm.maxShares],"persistent-hint":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "quantity", $event)}}},'EqsNumberShares',{
                        elClass: 'quantity',
                        label: _vm.$t('sellShares.label.numberOfShares'),
                        loading: _vm.loading,
                        hint: _vm.$t('sellShares.label.hint', {
                            number: _vm.formData.shareledger.balance
                        })
                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsNumberShares',_vm._b({attrs:{"value":_vm.formData.min_quantity,"rules":[_vm.minQuantity]},on:{"update:value":function($event){return _vm.$set(_vm.formData, "min_quantity", $event)}}},'EqsNumberShares',{
                        elClass: 'min_quantity',
                        label: _vm.$t('sellShares.label.minQuantity'),
                        loading: _vm.loading
                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsNumberShares',_vm._b({attrs:{"value":_vm.formData.max_quantity,"rules":[_vm.maxQuantity]},on:{"update:value":function($event){return _vm.$set(_vm.formData, "max_quantity", $event)}}},'EqsNumberShares',{
                        elClass: 'max_quantity',
                        label: _vm.$t('sellShares.label.maxQuantity'),
                        loading: _vm.loading
                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.formData.price,"is-currency":"","rules":[_vm.greaterThanZeroR]},on:{"update:value":function($event){return _vm.$set(_vm.formData, "price", $event)}}},'EqsNumber',{
                        elClass: 'price',
                        label: _vm.$t('sellShares.label.pricePerShare'),
                        loading: _vm.loading,
                        hint: _vm.$t('sellShares.label.pricehint', {
                            number: this.$currency.display(
                                this.formData.shareledger.shareledger.lastpricepershare
                            )
                        })
                    },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('third')}},[_c('EqsText',_vm._b({attrs:{"value":_vm.share_types[_vm.formData.shareledger.share_type].name,"editable":false,"return-object":""}},'EqsText',{
                        label: _vm.$t('sellShares.label.shareType'),
                        loading: _vm.loading
                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('third')}},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.totalSell,"is-currency":"","editable":false}},'EqsNumber',{
                        label: _vm.$t('sellShares.label.total-sell'),
                        loading: _vm.loading
                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('third')}},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.totalFees,"is-currency":"","editable":false}},'EqsNumber',{
                        label: _vm.$t('sellShares.label.total-fees'),
                        loading: _vm.loading
                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('AdditionalDocsCrud',_vm._b({attrs:{"deletable":""}},'AdditionalDocsCrud',{
                        ..._vm.$props,
                        label: _vm.$t(
                            'secondary-market.sell-shares.shares-form.documents-crud-label'
                        )
                    },false))],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }