<template>
    <div class="eqs-avatar-upload" :el-class="elClass">
        <EqsLoader v-bind="{ loading: localLoading, size: getData.size }">
            <div class="d-flex flex-column justify-center align-center">
                <EqsTextDisplay v-if="!!label" eqs-type="label" :value="label" />

                <v-input value="" :error-messages="errorMessages" dense>
                    <v-hover v-slot="{ hover }">
                        <div class="ma-0 pa-0">
                            <EqsAvatar
                                class="eqs-avatar-upload__avatar"
                                v-bind="{
                                    eqsType: 'upload',
                                    user,
                                    loading,
                                    src: valueProxy.upload
                                }"
                                :gradient="
                                    hover
                                        ? 'rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)'
                                        : ''
                                "
                            >
                                <template #default>
                                    <EqsUpload
                                        class="eqs-avatar-upload__input"
                                        :value.sync="valueProxy"
                                        :error-messages.sync="errorMessages"
                                        accept="image/*"
                                        background-color="transparent"
                                        prepend-icon=""
                                        hide-details
                                        v-bind="{
                                            ...$props,
                                            ...$attrs,
                                            label: '',
                                            placeholder: ''
                                        }"
                                        @isLoading="val => (localLoading = val)"
                                    >
                                        <template #selection />
                                    </EqsUpload>
                                </template>
                            </EqsAvatar>
                        </div>
                    </v-hover>
                </v-input>
            </div>
            <!-- </EqsLabel> -->
        </EqsLoader>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsAvatarUpload.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";
import isObject from "lodash/isObject";

export default {
    name: "EqsAvatarUpload",
    components: {
        EqsLoader: () => import("../../atoms/EqsLoader/EqsLoader.vue"),
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue"),
        EqsUpload: () => import("../../atoms/EqsUpload/EqsUpload.vue"),
        EqsAvatar: () => import("../../atoms/EqsAvatar/EqsAvatar.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: "eqs-avatar-upload"
        },
        value: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        label: {
            type: String,
            required: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            localLoading: false,
            errorMessages: ""
        };
    },
    computed: {
        valueProxy: {
            get() {
                return isObject(this.value) ? this.value : {};
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsAvatarUpload.scss";
</style>
