var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('DataTablePaginated',{attrs:{"items":_vm.items,"search":"message","sort-by":"created_at","sort-desc":"","headers":_vm.headers,"manage-route":"","loading":_vm.loading || _vm.localLoading},on:{"update":_vm.fetchNotifs},scopedSlots:_vm._u([{key:`item.shown`,fn:function({ item }){return [(!item.shown)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-badge',{attrs:{"dot":"","inline":"","color":"primary"}})],1):_vm._e()]}},{key:`item.created_at`,fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$time.formated(value))+" ")]}},{key:`item.created_by.name`,fn:function({ item, index }){return [_c('v-list-item',{staticClass:"pa-0",attrs:{"el-class":`eqs-notifications-table-${index}`}},[_c('EqsAvatar',_vm._b({staticClass:"pr-5"},'EqsAvatar',{ user: item.created_by, eqsType: 'list', noBadge: true },false)),_c('v-list-item-content',{staticClass:"pa-0 pl-3"},[_c('v-list-item-title',{staticClass:"pt-3"},[_c('EqsTextDisplay',_vm._b({attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{
                                value: item.created_by ? item.created_by.name : ''
                            },false))],1),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(item.message)+" ")]),_c('v-list-item-subtitle',{staticClass:"pt-3 text-wrap"},[(item.action_txt)?_c('EqsTextDisplay',_vm._b({attrs:{"eqs-type":"caption"}},'EqsTextDisplay',{ value: item.action_txt },false)):_vm._e()],1)],1)],1)]}},{key:`item.actions`,fn:function({ item, index }){return [_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',[(item.cancel)?_c('EqsBtn',_vm._b({attrs:{"color":"primary","outlined":"","small":""}},'EqsBtn',{
                            action: item.cancel.fn,
                            elClass: `eqs-notifications-table-${index}-cancel`
                        },false),[_vm._v(" "+_vm._s(item.cancel.text)+" ")]):_vm._e()],1),_c('v-col',[(item.confirm)?_c('EqsBtn',_vm._b({attrs:{"color":"primary","small":""}},'EqsBtn',{
                            action: item.confirm.fn,
                            elClass: `eqs-notifications-table-${index}-confirm`
                        },false),[_vm._v(" "+_vm._s(item.confirm.text)+" ")]):_vm._e()],1)],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }