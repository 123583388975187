<template>
    <v-container fluid :style="getStyle">
        <v-progress-linear
            ref="progressBar"
            color="#313131"
            :value="progressValue"
            rounded
            height="16"
        >
            <template #default>
                <span class="vertical-hint" />
            </template>
        </v-progress-linear>
        <span class="progress-text">{{ progressText }}</span>
    </v-container>
</template>

<script>
export default {
    name: "MoneyRaisedMini",
    props: {
        value: {
            type: Number,
            default: 0
        },
        currency: {
            type: String,
            default: "EUR"
        },
        hardCap: {
            type: Number,
            default: 100
        },
        softCap: {
            type: Number,
            default: 0
        },
        started: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            softCapOffset: 0
        };
    },
    computed: {
        moneyRaisedTxt() {
            return this.started
                ? `${this.$currency.abreviate(this.value, true, this.currency)} ${this.$t(
                      "molecule.progress.money-raised"
                  )}`
                : this.$t("label.pending");
        },
        getStyle() {
            return {
                "--txt-color": !this.started ? "black" : "#43a047",
                "--soft-bar-offset": this.softCapOffset
            };
        },
        progressValue() {
            return parseInt((this.value / this.hardCap) * 100);
        },
        progressColor() {
            if (this.value < this.softCap) {
                return "warning";
            } else if (this.value >= this.hardCap) {
                return "blue";
            } else {
                return "success";
            }
        },
        progressText() {
            if (!this.started) {
                return this.$t("label.pending");
            } else {
                return `${this.$currency.displayWithCurrency(this.value, this.currency)} ${this.$t(
                    "molecule.progress.money-raised"
                )}`;
            }
        }
    },
    mounted() {
        const progressOffset = this.$refs.progressBar.$el.offsetWidth;
        const percentSoft = this.softCap / this.hardCap;
        const offsetAboslute = progressOffset * percentSoft - progressOffset / 2.0;
        this.softCapOffset = `${parseInt(offsetAboslute)}px`;
    }
};
</script>

<style lang="scss" scoped>
:deep(.v-progress-linear) {
    border-radius: 16px !important;
}
:deep(.v-progress-linear__determinate) {
    background-image: linear-gradient(
        244deg,
        rgb(210, 72, 244) 23.97%,
        rgb(83, 72, 244) 83.49%
    ) !important;
}
.vertical-hint {
    position: relative;
    &::before {
        content: "|";
        position: absolute;
        color: #212121;
        top: -16px;
        height: 16px;
        left: var(--soft-bar-offset);
        font-size: 2.66rem;
        line-height: 16px;
        font-weight: bold;
    }
}
.progress-text {
    float: right;
}

.arthaud-progress-bar {
    position: absolute;
    bottom: 5%;
    background-color: rgba(255, 255, 255, 1);
    padding: 10px;
}
</style>
