import Vue from "vue";
import { i18n } from "@/services/plugins/i18n.js";
import { sleep } from "@equisafe-ui-shared/util";
import store from "@/store/store.js";
import { getRootURL } from "@/util";

let state = Vue.observable({
    socketToClose: {
        socket: {},
        url: ""
    },
    privateSocket: {
        socket: {},
        url: ""
    }
});

const forwardPrivatePayloadFromType = (model, data) => {
    console.log({
        model,
        data
    });
    switch (model) {
        case "djblockchain.transaction":
            store.dispatch("fetchTransaction", data.id, data.value);
        case "eqs.entity":
            switch (data.field) {
                case "blockchainize_state":
                    store.dispatch("getBlockchainInfo");
                    window.app.$role.updateMyCurrentRole(data.id);
                    break;
                case "has_digitalized_shareledger":
                    window.app.$role.updateMyCurrentRole(data.id);
                default:
                    break;
            }
            break;
        case "eqs.notification":
            store.dispatch("fetchNotification", data.id);
            break;
        case "eqs_sft.shareledger":
            store.dispatch("fetchShareledger", data.id);
            break;
        case "eqs_sft.investor":
            store.dispatch("fetchInvestor", data.id);
            break;
        case "eqs_sft.investorshareledger":
            store.dispatch("fetchInvestor", data.id);
            break;
        case "eqs_sign.signatureprocedure":
            switch (data.field) {
                case "delete":
                    store.commit("DELETE_PROCEDURE", data.id);
                    break;
                default:
                    store.dispatch("fetchProcedure", data.id);
                    break;
            }
            break;
        case "eqs_sign.signer":
            store.dispatch("fetchProcedure", data.id);
            break;
        case "eqs_sft.setlimit":
            store.dispatch("getSetlimit", data.id);
            break;
        case "eqs_sft.setcountries":
            store.dispatch("getSetCountries", data.id);
            break;
        case "eqs_sft.removecountries":
            store.dispatch("getRemoveCountries", data.id);
            break;
        case "eqs_sft.issuingentity":
            store.dispatch("getBlockchainInfo");
            break;
        case "eqs_wallet.movement":
            store.dispatch("fetchBalance");
            break;
        case "eqs_sft.cashbalance":
            switch (data.field) {
                case "sequestre":
                    store.commit("SET_SEQUESTRE", { sequestre: parseFloat(data.value) });
                    break;
            }
            break;
        case "eqs_wallet.iban":
            store.dispatch("fetchIban");
            break;
        case "eqs_capinc.capitalincreaseorder":
            switch (data.field) {
                case "delete":
                    // TODO when capinc order will be stored, don't forget to handle delete here
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
};
export class WebSocketService {
    constructor(api) {
        this.api = api;
        this.prependURL = getRootURL().replace("http", "ws");
        if (this.prependURL && this.prependURL.includes("wss")) {
            console.log("prepend url setup for websockets");
            // this.prependURL = this.prependURL.replace("wss://", "wss://wss.");
            // this.prependURL = this.prependURL.substring(0, this.prependURL.length - 1) + ":8001/"
        }
        console.log({ thisprependURL: this.prependURL });
        this.store = store;
        this.reconnectCount = 0;
    }
    initSocket(url) {
        return new Promise(function (resolve, reject) {
            let socket = new WebSocket(url);
            socket.onerror = err => {
                reject(err);
            };
            // when socket ready, send get_data from server in order to receive data (ex all notifications)
            socket.onopen = () => {
                // socket.send("get_data");
                console.log("socket is open");
                resolve(socket);
            };
        });
    }
    async initPrivateSocket(currentRole) {
        try {
            if (this.reconnectCount) console.log(`retrying for ${this.reconnectCount} time`);
            this.reconnectCount++;
            console.log("init new socket");
            if (store.getters.useThroughRoles.useIt) {
                state.privateSocket.url = `${this.prependURL}ws/private/${currentRole.id}/true/`;
            } else {
                state.privateSocket.url = `${this.prependURL}ws/private/${currentRole.id}/`;
            }
            state.privateSocket.socket = await this.initSocket(state.privateSocket.url);
            state.privateSocket.socket.onmessage = this.handlePrivateSocket;
            this.reconnectCount = 0;
            console.log("ended socket init");
        } catch (e) {
            console.warn({
                e
            });
            if (this.reconnectCount > 5) {
                window.app.$ui.error(i18n.t("error.open_websocket_for_role"));
                console.error({
                    e
                });
            } else {
                console.log("initiating new socket after failure");
                // needed to avoid 1006 error from websocket https://github.com/websockets/ws/issues/1598
                await sleep(2000);
                // in case of 1006 or any error fallback to same function until success
                const r = await this.initPrivateSocket(currentRole);
                return r;
            }
        }
    }
    handlePrivateSocket(e) {
        console.log({ e });
        const ret = JSON.parse(e.data);
        console.log({
            privateSocketOn: ret
        });
        const { model, data } = ret;
        forwardPrivatePayloadFromType(model, data);
    }
    async closePrivateSocket() {
        const socketToClose = state.privateSocket.socket;
        console.log({ socketToClose: JSON.parse(JSON.stringify(socketToClose)) });
        if (socketToClose && socketToClose.close && socketToClose.readyState !== 3) {
            console.log("closing socket");
            state.socketToClose.socket = socketToClose;
            return new Promise(function (resolve, reject) {
                state.socketToClose.socket.close(1000);
                state.socketToClose.socket.onerror = err => {
                    reject(err);
                };
                state.socketToClose.socket.onclose = () => {
                    resolve("socket is closed");
                };
            });
        }
    }
    errorHandler(event) {
        console.error({
            event
        });
        window.app.$ui.error(event, "");
    }
    send(type, data, isPrivate = false) {
        const request = {
            type,
            data
        };
        isPrivate
            ? state.privateSocket.socket.send(request)
            : state.socketToClose.socket.send(request);
    }
}
