<template>
    <v-dialog
        :value.sync="dialogProxy"
        max-width="1000px"
        content-class="dialog-on-top"
        @click:outside="closeDialog()"
    >
        <v-card style="min-width: 400px; max-height: 90vh">
            <v-toolbar color="white" flat>
                <v-toolbar-title>
                    <div class="icon-with-text">
                        <v-icon v-if="order.buyer.is_company" class="custom-left-icon">
                            mdi-domain
                        </v-icon>
                        <span>{{
                            `${order.buyer.name}${$entity.isStaff() ? " - " + order.buyer.id : ""}`
                        }}</span>
                    </div>
                </v-toolbar-title>
                <v-spacer />
                <v-btn icon large @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <template #extension>
                    <v-tabs v-model="tabSelected" centered show-arrows grow>
                        <v-tab v-for="(itemTabHeader, i) in tabsHeader" :key="i">
                            {{ itemTabHeader.label }}
                        </v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>
            <v-tabs-items v-model="tabSelected" class="pa-6">
                <v-tab-item v-for="(itemTabItem, i) in tabsHeader" :key="`${order.buyer.id}.${i}`">
                    <template #default>
                        <v-lazy>
                            <keep-alive max="32">
                                <component
                                    :is="itemTabItem.component"
                                    v-bind="{
                                        investor: order.buyer,
                                        order: orderFullData,
                                        loading: loading || localLoading
                                    }"
                                />
                            </keep-alive>
                        </v-lazy>
                    </template>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>

<script>
import { getHttpErrMsg } from "@/log";

export default {
    name: "OrderModal",
    components: {
        InvestorInfoTab: () => import("./tabs/InvestorInfoTab"),
        InvestorDocumentTab: () => import("./tabs/InvestorDocumentTab"),
        InvestorOrderTab: () => import("./tabs/InvestorOrderTab"),
        InvestorOrderDetail: () => import("./tabs/InvestorOrderDetail")
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        open: {
            type: Boolean,
            required: true
        },
        order: {
            type: Object,
            required: true
        },
        showInvestor: {
            type: Boolean,
            required: false,
            default: true
        },
        showOrders: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ["closeDialog"],
    data() {
        return {
            localLoading: false,
            tabSelected: 0,
            orderFullData: null
        };
    },
    computed: {
        tabsHeader() {
            let headers = [
                {
                    label: "Ordre",
                    component: "InvestorOrderDetail"
                }
            ];
            if (this.showInvestor) {
                headers.push({
                    label: "Investisseur",
                    component: "InvestorInfoTab"
                });
                headers.push({
                    label: "Documents",
                    component: "InvestorDocumentTab"
                });
            }
            if (this.showOrders) {
                headers.push({
                    label: "Ordres",
                    component: "InvestorOrderTab"
                });
            }

            return headers;
        },
        dialogProxy: {
            get() {
                return this.open;
            },
            set(val) {
                if (!val) this.closeDialog();
            }
        }
    },
    beforeMount() {
        this.fetchOrderDetails();
    },
    methods: {
        closeDialog() {
            this.tabSelected = 0;
            this.orderFullData = null;
            this.$emit("closeDialog");
        },
        async fetchOrderDetails() {
            if (this.loading || this.localLoading) {
                return;
            }
            this.localLoading = true;
            try {
                const res = await this.$api.request(
                    "get",
                    `api/investors-dashboard-order/${this.order.buyer.id}/orders/${this.order.id}/`
                );
                this.orderFullData = res.data;
            } catch (e) {
                console.error("OrderModal:fetchOrderDetails", e);
                this.$ui.error(
                    getHttpErrMsg(e, "Erreur lors de la récupération des informations de l'ordre")
                );
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>
<style scoped lang="scss">
:deep(.dialog-on-top) {
    align-self: start !important;
}
:deep(.v-toolbar__extension) {
    padding: 0 !important;
    margin: 0 !important;
}
:deep(.v-tab) {
    text-transform: none !important;
}
.icon-with-text {
    > span {
        vertical-align: middle;
    }

    .custom-left-icon {
        vertical-align: middle;
        margin-right: 6px;
    }
}
</style>
