<template>
    <div class="eqs-number-shares">
        <EqsNumber
            v-bind="{ ...$attrs, isFloat: false, isCurrency: false }"
            :value.sync="valueProxy"
            :basic-rules="$options.rules"
            :error-messages="getErrorMessages"
            v-on="$listeners"
        >
            <template #append class="append-shares">
                <span class="body-1 font-weight-light">{{
                    append || $t("molecule.form.shares")
                }}</span>
            </template>
        </EqsNumber>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsNumberShares.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { greaterThanZeroR } from "../../../rules";
import { arrayOrStringEmpty } from "../../../../../shared/util";

export default {
    name: "EqsNumberShares",
    rules: [greaterThanZeroR],
    components: {
        EqsNumber: () => import("../EqsNumber/EqsNumber.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        value: {
            type: [String, Number],
            required: true
        },
        numberOfSharesLeft: {
            type: Number,
            default: Number.MAX_SAFE_INTEGER
        },
        append: {
            type: String,
            default: ""
        },
        errorMessages: {
            type: [Array, String],
            default: ""
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        },
        getErrorMessages() {
            let errors = [];
            if (!arrayOrStringEmpty(this.errorMessages)) {
                if (Array.isArray(this.errorMessages)) errors.push(...this.errorMessages);
                else errors.push(this.errorMessages);
            }
            if (this.checkRemainingShares) errors = [this.checkRemainingShares, ...errors];
            return errors.length ? errors : null;
        },
        checkRemainingShares() {
            if (this.valueProxy > this.numberOfSharesLeft) {
                return this.$t("molecule.form.shares-left", {
                    numberOfShare: this.$currency.display(this.numberOfSharesLeft, "0,0")
                });
            }
            return null;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsNumberShares.scss";
</style>
