<template>
    <div :class="`eqs-checkbox d-flex ${elClass}-container`">
        <EqsLabel
            v-bind="{
                ...$attrs,
                isRequired,
                height: labelHeight,
                editable,
                value: label,
                infoTxt
            }"
        >
            <v-checkbox
                v-model="valueProxy"
                v-bind="{
                    ...$attrs,
                    ...$props,
                    ...getData,
                    rules: rulesRequired,
                    readonly: !editable,
                    label: text,
                    disabled: disabled || loading
                }"
                :class="`ma-0 ${elClass}-input`"
                hide-details="auto"
                v-on="$listeners"
            >
                <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                <template #label>
                    <label v-if="loading" class="v-label">
                        <EqsSkeleton
                            style="min-width: 120px"
                            class="pt-1"
                            v-bind="{ loading, type: 'text' }"
                        />
                    </label>
                    <slot v-else name="label" />
                </template>
            </v-checkbox>
        </EqsLabel>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsCheckbox.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";
import { requiredR } from "../../../rules";

export default {
    name: "EqsCheckbox",
    components: {
        EqsLabel: () => import("../EqsLabel/EqsLabel.vue"),
        EqsSkeleton: () => import("../EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: "eqs-checkbox"
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        value: {
            type: null,
            required: true
        },
        text: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", !!val);
            }
        },
        rulesRequired() {
            return this.isRequired ? [...this.rules, requiredR] : this.rules;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsCheckbox.scss";
</style>
