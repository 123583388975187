import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            avatar: {
                size: getVal(currentSize, {
                    mobile: "2.4em",
                    tablet: "2.5em",
                    desktop: "3em"
                })
            }
        },
        list: {
            avatar: {
                size: getVal(currentSize, {
                    mobile: "2em",
                    tablet: "2.2em",
                    desktop: "2.4em"
                })
            }
        },
        upload: {
            avatar: {
                size: getVal(currentSize, {
                    mobile: "6em",
                    tablet: "7em",
                    desktop: "8em"
                })
            }
        },
        xl: {
            avatar: {
                size: getVal(currentSize, {
                    mobile: "6em",
                    tablet: "7em",
                    desktop: "8em"
                })
            }
        }
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            size: getVal(currentSize, {
                mobile: "2.4em",
                tablet: "2.5em",
                desktop: "3em"
            })
        },
        list: {
            size: getVal(currentSize, {
                mobile: "2 em",
                tablet: "2.2em",
                desktop: "2.4em"
            })
        },
        xl: {
            size: getVal(currentSize, {
                mobile: "6em",
                tablet: "7em",
                desktop: "8em"
            })
        },
        upload: {
            size: getVal(currentSize, {
                mobile: "6em",
                tablet: "7em",
                desktop: "8em"
            }),
            badge: {
                bottom: true,
                icon: "mdi-camera",
                color: "info",
                bordered: true,
                "offset-x": getVal(currentSize, {
                    mobile: "1.5em",
                    tablet: "1.6em",
                    desktop: "1.8em"
                }),
                "offset-y": getVal(currentSize, {
                    mobile: "1.5em",
                    tablet: "1.6em",
                    desktop: "1.8em"
                })
            }
        }
    };
};
