import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            "app-bar": {
                height: getVal(currentSize, {
                    mobile: "56px",
                    tablet: "56px",
                    desktop: "64px"
                })
            }
        },
        "no-nav-bar": {
            "app-bar": {
                heigth: "0px"
            }
        },
        "no-padding": {},
        "no-menu": {},
        "no-overflow": {}
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            container: {
                class: "overflow-y"
            },
            showMenu: true,
            showNavBar: true
        },
        "no-padding": {
            container: {
                class: "no-padding"
            }
        },
        "no-menu": {
            showMenu: false
        },
        "no-nav-bar": {
            showNavBar: false
        },
        "no-overflow": {
            container: {
                class: "no-overflow-y pa-0"
            }
        }
    };
};
