var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-tags",style:(_vm.getStyle),attrs:{"el-class":_vm.elClass}},[(!_vm.display)?_c('EqsLabel',_vm._b({},'EqsLabel',{
            ..._vm.$attrs,
            label: _vm.label,
            height: _vm.labelHeight,
            editable: _vm.editable,
            value: _vm.label,
            infoTxt: _vm.infoTxt,
            isRequired: _vm.isRequired
        },false),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),_c('EqsEditable',_vm._b({},'EqsEditable',{ ..._vm.$attrs, editable: _vm.editable, value: _vm.editableData },false),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),_c('EqsSkeleton',_vm._b({},'EqsSkeleton',{ loading: _vm.loading, type: 'sentences' },false),[_c('v-combobox',_vm._g(_vm._b({attrs:{"multiple":"","no-filter":true,"chips":"","append-icon":"","placeholder":_vm.$t('molecule.tags.placeholder-tag'),"search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"paste":_vm.updateTags,"blur":_vm.updateTags},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},'v-combobox',{
                        ..._vm.$attrs,
                        ..._vm.getData,
                        rules: [],
                        deletableChips: _vm.editable,
                        clearable: _vm.editable,
                        readonly: !_vm.editable,
                        editable: _vm.editable
                    },false),_vm.$listeners))],1)],2)],2):(_vm.tags && _vm.tags.length)?_c('v-row',_vm._l((_vm.tags),function(item,index){return _c('v-col',{key:index},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{ elClass: `${_vm.elClass}-item-${index}` },false),[_vm._t("tag",null,null,{ item, index })],2)],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }