var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',{staticClass:"text-h5 mb-4"},[_vm._v("Simulation de capacité d’investissement")]),(_vm.showUpdateSimulation)?_c('v-alert',{attrs:{"dense":"","text":"","border":"left","type":"info"}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" Vous pouvez mettre à jour votre simulation ")])]):_vm._e(),_c('v-alert',{staticClass:"mt-4",attrs:{"border":"left","prominent":"","text":"","type":"warning"}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" Les données fournies doivent l'être au 31 décembre de l'année précédente, ou une date plus récente si les données au 31 décembre de l'année précédente ne reflètent pas correctement votre situation financière. Si vous choisissez une date plus récente pour l'une des rubriques, vous devrez fournir toutes les informations du questionnaire à cette date. ")])]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Afin de valider votre profil, vous devez remplir la simulation de la capacité à supporter des pertes à l'aide du formulaire ci-dessous ")]),_c('v-row',{attrs:{"align":"center","align-content":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.formData.p4bq1,"is-currency":false,"is-float":false},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p4bq1", $event)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"open-on-hover":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({attrs:{"icon":""}},'span',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}])},[_c('div',{staticClass:"tooltip-cst"},[_vm._v(" Il s'agit du montant total de tous les revenus perçus : salaires, les revenus d'investissement, les revenus locatifs, les allocations, etc. ")])])]},proxy:true}])},'EqsNumber',{
                    label: _vm.labelQ1,
                    editable: true,
                    placeholder: 'Ex: 4500',
                    ..._vm.schemaDouble.p4bq1
                },false)),_c('EqsNumber',_vm._b({attrs:{"value":_vm.formData.p4bq2,"is-currency":false,"is-float":false},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p4bq2", $event)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"open-on-hover":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({attrs:{"icon":""}},'span',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}])},[_c('div',{staticClass:"tooltip-cst"},[_vm._v(" Il s'agit de la valeur totale de tous les biens et ressources financières, (hors immobiliers) : l'argent liquide, des comptes bancaires, des investissements (actions, obligations, etc.). ")])])]},proxy:true}])},'EqsNumber',{
                    label: _vm.labelQ2,
                    editable: true,
                    placeholder: 'Ex: 15000',
                    ..._vm.schemaDouble.p4bq2
                },false)),_c('EqsNumber',_vm._b({attrs:{"value":_vm.formData.p4bq3,"is-currency":false,"is-float":false},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p4bq3", $event)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"open-on-hover":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({attrs:{"icon":""}},'span',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}])},[_c('div',{staticClass:"tooltip-cst"},[_vm._v(" Il s’agit des prêts avec taux d'intérêts, les obligations et les dépenses financières régulières sur l'année. ")])])]},proxy:true}])},'EqsNumber',{
                    label: _vm.labelQ3,
                    editable: true,
                    placeholder: 'Ex: 2000',
                    ..._vm.schemaDouble.p4bq3
                },false))],1),_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","sm":"6"}},[_c('p',[_vm._v("Capacité d'investissement estimée")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency(_vm.investCapacity, "-"))+" / deal ")])])],1),_c('EqsCheckbox',_vm._b({staticClass:"font-weight-bold mt-4 mb-4",attrs:{"value":_vm.formData.confirm_unwarned,"is-required":true},on:{"update:value":function($event){return _vm.$set(_vm.formData, "confirm_unwarned", $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.labelQ4)+" ")]},proxy:true}])},'EqsCheckbox',{
            editable: true,
            ..._vm.schemaDouble.confirm_unwarned
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }