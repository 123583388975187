var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"status-title-icon mb-0"},[_c('div',{staticClass:"ma-0 pa-0 mr-4 icon-bg"},[_c('v-icon',{staticClass:"custom-left-icon",attrs:{"size":"40"}},[_vm._v(" mdi-email-fast-outline ")])],1),_c('span',{staticClass:"text-h5",staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v(_vm._s(_vm.$t("user.buy.send-bank-pea.send-docs-advisor")))])]),(_vm.mailSent)?_c('v-alert',{staticClass:"my-4",attrs:{"border":"left","outlined":"","text":"","type":"success"}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.$t("user.buy.send-bank-pea.already-sent"))+" ")])]):_vm._e(),_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(`👉 ${_vm.$t('user.buy.send-bank-pea.info-1')}`)}}),_c('p',[_vm._v(_vm._s(_vm.$t("user.buy.send-bank-pea.info-2")))]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("📝 "+_vm._s(_vm.$t("user.buy.send-bank-pea.info-3")))])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},_vm._l((_vm.peaDocs),function(doc,index){return _c('EqsFileInput',_vm._b({key:index},'EqsFileInput',{
                    loading: _vm.localLoading,
                    editable: false,
                    ...doc
                },false))}),1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_c('EqsEmail',_vm._b({attrs:{"value":_vm.email,"persistent-hint":""},on:{"update:value":function($event){_vm.email=$event}}},'EqsEmail',{
                        isRequired: false,
                        dataType: 'string',
                        successMessages: _vm.mailSent
                            ? [_vm.$t('user.buy.send-bank-pea.send-docs-advisor-success-hint')]
                            : null,
                        label: _vm.$t('user.buy.send-bank-pea.send-docs-advisor-label'),
                        hint: _vm.$t('user.buy.send-bank-pea.send-docs-advisor-hint')
                    },false)),_c('div',{staticClass:"mt-4 custom-center-button"},[(!_vm.mailSent)?_c('EqsBtn',_vm._b({attrs:{"outlined":""}},'EqsBtn',{
                            action: _vm.sendMailBank,
                            color: 'primary',
                            disabled: _vm.disableSend
                        },false),[_vm._v(" "+_vm._s(_vm.$t("action.send"))+" ")]):_vm._e(),(_vm.mailSent)?_c('EqsBtn',_vm._b({attrs:{"outlined":""}},'EqsBtn',{
                            action: _vm.confirmResend,
                            color: 'primary'
                        },false),[_vm._v(" "+_vm._s(_vm.$t("action.resend"))+" ")]):_vm._e()],1)],1)])],1),_c('EqsDialog',_vm._b({attrs:{"value":_vm.resendDialog,"closable":""},on:{"update:value":function($event){_vm.resendDialog=$event},"click:outside":function($event){return _vm.closeDialog()}}},'EqsDialog',{
            title: _vm.$t('action.confirm-sending')
        },false),[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("user.buy.send-bank-pea.confirm-resend"))+" ")])],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDialolg(false)}}},[_vm._v(_vm._s(_vm.$t("action.cancel")))])],1),_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDialolg(true)}}},[_vm._v(_vm._s(_vm.$t("action.send")))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }