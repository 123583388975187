import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_TRANSACTIONS_START");
    if (state.transactionsInit && !state.transactionsLoading) {
        let transactionsRes = null;
        try {
            commit("SET_TRANSACTIONS_LOADING", true);
            transactionsRes = await window.app.$api.getTransactions({
                sender_only: true,
                ordering: "-created_at"
            });
            DEBUG("GET_TRANSACTIONS_SUCCESS");

            for (const tx of transactionsRes.data.results) {
                if (window.app.$blockchain.txWatching(tx)) {
                    window.app.$blockchain.pollTransactionExplorer(tx.id, false, true);
                }
            }
            commit("SET_TRANSACTIONS", transactionsRes.data);
        } catch (e) {
            DEBUG("GET_TRANSACTIONS_ERROR");
            window.app.$ui.error(e, "my_transactions_movements_transactions");
        } finally {
            commit("SET_TRANSACTIONS_LOADING", false);
            return transactionsRes;
        }
    } else {
        DEBUG("GET_TRANSACTIONS_BYPASSED");
    }
};
