import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_BALANCE_START");
    if (state.balanceInit && !state.balanceLoading) {
        try {
            commit("SET_BALANCE_LOADING", true);
            const balanceRes = await window.app.$api.getMyBalance();
            const balance = balanceRes.data;
            DEBUG("GET_BALANCE_SUCCESS");
            commit("SET_BALANCE", balance);
            return balance;
        } catch (e) {
            DEBUG("GET_BALANCE_ERROR");
            window.app.$ui.error(e, "account_balance");
            throw e;
        } finally {
            commit("SET_BALANCE_LOADING", false);
        }
    } else {
        DEBUG("GET_BALANCE_BYPASSED");
        return state.balance;
    }
};
