import SecondaryMarket from "@/components/views/secondaryMarket/_SecondaryMarket";
import SecondaryMarketDetails from "@/components/views/secondaryMarket/companyDetails/SecondaryMarketDetails";
import SellShares from "@/components/views/secondaryMarket/sellShares/_SellShares";
import BuyStepper from "@/components/views/secondaryMarket/buyStepper/BuyStepper";
import SignStepper from "@/components/views/secondaryMarket/signStepper/SignStepper";
import { i18n } from "@/services/plugins/i18n.js";

import multiguard from "vue-router-multiguard";
import { informUserIfNotRegistered } from "../routeGuards";

export default [
    {
        path: "/secondary-market/:id",
        props: true,
        component: SecondaryMarket,
        name: "secondary-market",
        meta: {
            context: i18n.t("app.navigation-drawer.title.secondary")
        },
        beforeEnter: multiguard([informUserIfNotRegistered])
    },
    {
        path: "/secondary-market/:id/sell-shares/:companyId",
        props: true,
        component: SellShares,
        name: "sell-shares",
        meta: {
            context: i18n.t("app.navigation-drawer.title.secondary-sell-shares")
        },
        beforeEnter: multiguard([informUserIfNotRegistered])
    },
    {
        path: "/secondary-market/:id/sell-shares/:companyId/edit/:sellOrderId",
        props: true,
        component: SellShares,
        name: "sell-shares-edit",
        meta: {
            context: i18n.t("app.navigation-drawer.title.secondary-sell-shares")
        },
        beforeEnter: multiguard([informUserIfNotRegistered])
    },
    {
        path: "/secondary-market/details/:id",
        props: true,
        component: SecondaryMarketDetails,
        name: "secondary-market-details",
        meta: {
            context: i18n.t("app.navigation-drawer.title.secondary")
        },
        beforeEnter: multiguard([informUserIfNotRegistered])
    },
    {
        path: "/secondary-market/:id/buy/:buyType/:orderId",
        props: true,
        component: BuyStepper,
        name: "secondary-market-buy-stepper",
        meta: {
            context: i18n.t("app.navigation-drawer.title.secondary")
        },
        beforeEnter: multiguard([informUserIfNotRegistered])
    },
    {
        path: "/secondary-market/sign-stepper/:orderId",
        props: true,
        component: SignStepper,
        name: "secondary-market-sign-stepper",
        meta: {
            context: i18n.t("app.navigation-drawer.title.secondary")
        },
        beforeEnter: multiguard([informUserIfNotRegistered])
    }
];
