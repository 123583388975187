<template>
    <div class="items">
        <v-list dense>
            <v-list-item-group v-model="selectedIndex" color="primary">
                <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    :class="{ 'is-selected': index === selectedIndex }"
                    @click="() => selectItem(index)"
                >
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">
                            {{ item.txt }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
export default {
    name: "InternalVariableList",
    props: {
        items: {
            type: Array,
            required: true
        },
        command: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            selectedIndex: 0
        };
    },

    watch: {
        items() {
            this.selectedIndex = 0;
        }
    },

    methods: {
        onKeyDown({ event }) {
            if (event.key === "ArrowUp") {
                this.upHandler();
                return true;
            }

            if (event.key === "ArrowDown") {
                this.downHandler();
                return true;
            }

            if (event.key === "Enter") {
                this.enterHandler();
                return true;
            }

            return false;
        },

        upHandler() {
            this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length;
        },

        downHandler() {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
        },

        enterHandler() {
            this.selectItem(this.selectedIndex);
        },

        selectItem(index) {
            const item = this.items[index];
            if (item) {
                this.command({ id: item.txt, eqsvalue: item.value });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.items {
    position: relative;
    border-radius: 0.25rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1);
}
</style>
