<template>
    <div class="flex fill-height">
        <v-row>
            <v-col :cols="$reactive.getCols('half')">
                <v-row>
                    <v-col :cols="$reactive.getCols('half')">
                        <EqsCard>
                            <template #title>
                                <EqsTextDisplay eqs-type="subtitle" class="pt-8">
                                    Valeur du portefeuille
                                </EqsTextDisplay>
                            </template>

                            <template #subtitle>
                                <EqsTextDisplay eqs-type="title" class="pb-8">
                                    {{ $currency.displayWithCurrency(value) }}
                                </EqsTextDisplay>
                            </template>
                        </EqsCard>
                    </v-col>

                    <v-col :cols="$reactive.getCols('half')">
                        <EqsCard>
                            <template #title>
                                <EqsTextDisplay eqs-type="subtitle" class="pt-8">
                                    Rendement cumulé
                                </EqsTextDisplay>
                            </template>

                            <template #subtitle>
                                <EqsTextDisplay eqs-type="title" class="pb-8">
                                    {{ $currency.displayWithCurrency(returns) }}
                                </EqsTextDisplay>
                            </template>
                        </EqsCard>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col :cols="$reactive.getCols('half')">
                        <EqsCard>
                            <template #title>
                                <EqsTextDisplay eqs-type="subtitle" class="pt-8">
                                    Plus-values cumulées
                                </EqsTextDisplay>
                            </template>

                            <template #subtitle>
                                <EqsTextDisplay eqs-type="title" class="pb-8">
                                    {{ $currency.displayWithCurrency(gains) }}
                                </EqsTextDisplay>
                            </template>
                        </EqsCard>
                    </v-col>

                    <v-col :cols="$reactive.getCols('half')">
                        <EqsCard>
                            <template #title>
                                <EqsTextDisplay eqs-type="subtitle" class="pt-8">
                                    Total investis
                                </EqsTextDisplay>
                            </template>

                            <template #subtitle>
                                <EqsTextDisplay eqs-type="title" class="pb-8">
                                    {{ $currency.displayWithCurrency(invested) }}
                                </EqsTextDisplay>
                            </template>
                        </EqsCard>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col :cols="$reactive.getCols('full')">
                        <EqsCard>
                            <template #title>
                                <EqsTextDisplay eqs-type="subtitle">
                                    Historique de valeur du portefeuille
                                </EqsTextDisplay>
                            </template>

                            <div class="ma-3">
                                <EqsLine
                                    :config="valueHistoryChart"
                                    :format-dataset-labels="$currency.displayWithCurrency"
                                />
                            </div>
                        </EqsCard>
                    </v-col>
                </v-row>

                <v-row class="mt-6">
                    <v-col :cols="$reactive.getCols('half')">
                        <EqsTextDisplay eqs-type="subtitle"> Récapitulatif </EqsTextDisplay>

                        <v-divider class="mt-2 mb-4" />

                        <div class="d-flex justify-space-between">
                            <EqsLabel info-txt="..." value="Capital investi" :editable="false" />

                            <EqsTextDisplay eqs-type="bodyBold">
                                {{ $currency.displayWithCurrency(investedCapital) }}
                            </EqsTextDisplay>
                        </div>

                        <div class="d-flex justify-space-between">
                            <EqsLabel info-txt="..." value="Obligations" :editable="false" />

                            <EqsTextDisplay eqs-type="bodyBold">
                                {{ $currency.displayWithCurrency(obligations) }}
                            </EqsTextDisplay>
                        </div>

                        <div class="d-flex justify-space-between">
                            <EqsLabel info-txt="..." value="Compte bancaire" :editable="false" />

                            <EqsTextDisplay eqs-type="bodyBold">
                                {{ $currency.displayWithCurrency(bankAccount) }}
                            </EqsTextDisplay>
                        </div>

                        <div class="d-flex justify-space-between">
                            <EqsLabel info-txt="..." value="Total" />

                            <EqsTextDisplay eqs-type="bodyBold">
                                {{ $currency.displayWithCurrency(total) }}
                            </EqsTextDisplay>
                        </div>
                    </v-col>

                    <v-col :cols="$reactive.getCols('half')">
                        <EqsTextDisplay eqs-type="subtitle"> Indicateurs clés </EqsTextDisplay>

                        <v-divider class="mt-2 mb-4" />

                        <div class="d-flex justify-space-between">
                            <EqsLabel info-txt="..." value="TRI Réel" :editable="false" />

                            <EqsTextDisplay eqs-type="bodyBold">
                                {{ $currency.percent(realTRI) }}
                            </EqsTextDisplay>
                        </div>

                        <div class="d-flex justify-space-between">
                            <EqsLabel info-txt="..." value="Valeur liquidative" :editable="false" />

                            <EqsTextDisplay eqs-type="bodyBold">
                                {{ $currency.displayWithCurrency(liquidativeValue) }}
                            </EqsTextDisplay>
                        </div>
                    </v-col>
                </v-row>
            </v-col>

            <v-col :cols="$reactive.getCols('half')">
                <v-row class="py-6">
                    <v-col :cols="$reactive.getCols('half')">
                        <EqsPie :config="companiesChart" />
                    </v-col>

                    <v-col :cols="$reactive.getCols('half')">
                        <EqsPie :config="companiesChart" />
                    </v-col>
                </v-row>

                <v-row class="pt-6">
                    <EqsCard>
                        <v-row no-gutters class="d-flex align-center justify-space-between pa-6">
                            <EqsTextDisplay eqs-type="subtitle">
                                Dernières activités
                            </EqsTextDisplay>

                            <EqsBtn> Voir historique </EqsBtn>
                        </v-row>

                        <EqsDataTable
                            hide-default-header
                            hide-default-footer
                            :headers="historyHeaders"
                            :items="history"
                        >
                            <template #item.avatar>
                                <EqsAvatar />
                            </template>

                            <template #item.description="{ item }">
                                <v-row>
                                    <EqsTextDisplay eqs-type="caption">
                                        le {{ $time.formated(item.date) }}
                                    </EqsTextDisplay>
                                </v-row>

                                <v-row>
                                    <EqsTextDisplay eqs-type="bodyBold">
                                        {{ item.optype }}
                                    </EqsTextDisplay>
                                    <EqsTextDisplay>
                                        , {{ item.quantity }} {{ item.share_type }},
                                        {{ $currency.displayWithCurrency(item.price) }}
                                    </EqsTextDisplay>
                                </v-row>
                            </template>
                        </EqsDataTable>
                    </EqsCard>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "Summary",
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        const valueHistoryChart = {
            labels: [
                "mai 20",
                "juin 20",
                "juill. 20",
                "août 20",
                "sep. 20",
                "oct. 20",
                "nov. 20",
                "déc. 20"
            ],
            datasets: [
                {
                    label: "value",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderColor: "#007bff",
                    data: [
                        60000.0, 30000.0, 10000.0, 30000.0, 60000.0, 10000.0, 25000.0, 37500.0,
                        40000.0
                    ]
                }
            ]
        };

        const companiesChart = {
            labels: ["Equisafe SAS", "GamersOrigin", "Supertripper", "Fonds Euro"],
            data: [45.0, 22.5, 22.5, 10.0],
            infos: []
        };

        const historyHeaders = [
            {
                text: "",
                value: "avatar"
            },
            {
                text: "",
                value: "description"
            },
            {
                text: "",
                value: "company"
            }
        ];

        const history = [
            {
                avatar: null,
                date: "2020-10-10",
                optype: "Cession de titres",
                quantity: 250,
                share_type: "actions ordinaires",
                price: 2500.0,
                company: "Equisafe"
            },
            {
                avatar: null,
                date: "2020-10-10",
                optype: "Cession de titres",
                quantity: 250,
                share_type: "actions ordinaires",
                price: 2500.0,
                company: "Equisafe"
            },
            {
                avatar: null,
                date: "2020-10-10",
                optype: "Cession de titres",
                quantity: 250,
                share_type: "actions ordinaires",
                price: 2500.0,
                company: "Equisafe"
            },
            {
                avatar: null,
                date: "2020-10-10",
                optype: "Cession de titres",
                quantity: 250,
                share_type: "actions ordinaires",
                price: 2500.0,
                company: "Equisafe"
            }
        ];

        return {
            value: 89543.0,
            returns: 637260.0,
            gains: 637260.0,
            invested: 637260.0,
            valueHistoryChart,
            investedCapital: 500000.0,
            obligations: 500000.0,
            bankAccount: 200000.0,
            total: 80000.0,
            realTRI: 5.81,
            liquidativeValue: 225.0,
            companiesChart,
            historyHeaders,
            history
        };
    }
};
</script>

<style lang="scss" scoped></style>
