<template>
    <v-container class="ma-0 py-0">
        <v-row v-if="!$universe.isKyoseil()" class="mb-4">
            <v-col class="card-style pa-4 elevation-1">
                <span>{{ $t("capital-increase.user.detail.infos.progress") }}</span>
                <DaysLeft
                    class=""
                    v-bind="{
                        startDate: capitalIncrease.start_date,
                        endDate: capitalIncrease.end_date,
                        forceEnded: isCapincEnded(capitalIncrease)
                    }"
                />
                <MoneyRaisedMini
                    v-if="$time.isPast(capitalIncrease.start_date)"
                    class=""
                    v-bind="{
                        value: parseFloat(
                            capitalIncrease.sum_all_deal && capitalIncrease.money_raised_all_deal
                                ? capitalIncrease.money_raised_all_deal
                                : capitalIncrease.money_raised
                        ),
                        currency: capitalIncrease.currency,
                        hardCap: parseFloat(capitalIncrease.hard_cap),
                        softCap: parseFloat(capitalIncrease.soft_cap),
                        started: $time.isPast(capitalIncrease.start_date)
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="mb-4">
            <v-col class="card-style pa-4 elevation-1">
                <v-simple-table>
                    <template #default>
                        <tbody>
                            <tr>
                                <td class="text-left pa-0">
                                    {{
                                        capitalIncrease?.capincpaymentoptions?.fee_options
                                            ?.is_included === true
                                            ? $t(
                                                  "capital-increase.user.detail.investment-term.minimum-investment-tax-included"
                                              )
                                            : $t(
                                                  "capital-increase.user.detail.investment-term.minimum-investment"
                                              )
                                    }}
                                </td>
                                <td class="text-right pa-0">
                                    <NumberDisplayRefont
                                        :value="capitalIncrease.min_investment"
                                        :is-currency="true"
                                        :currency-type="capitalIncrease.currency"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-btn
                    v-if="isAllowedToInvest && isCapincActive"
                    class="invest-btn"
                    outlined
                    x-large
                    @click="
                        () =>
                            $router.push({
                                name: 'buy',
                                query: { capinc_id: capitalIncrease.id, cip }
                            })
                    "
                >
                    {{ $t("capital-increase.user.detail.invest") }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="envList.length > 0" class="mb-4">
            <v-col class="card-style elevation-1 pa-4">
                <div>
                    <p class="tax-text">{{ $t("user.buy.steps.envelope.title") }}</p>
                    <span>
                        <span v-for="(env, index) in envList" :key="index" class="tax-links">
                            <a v-if="env.link" :href="env.link" target="_blank" :title="env.title">
                                {{ env.text }}
                            </a>
                            <span v-else>{{ env.text }}</span>

                            <span v-if="envList.length > 1 && index < envList.length - 1">, </span>
                        </span>
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="taxList.length > 0" class="mb-4">
            <v-col class="card-style elevation-1 pa-4">
                <div>
                    <p class="tax-text">{{ $t("capital-increase.user.detail.infos.tax-list") }}</p>
                    <span>
                        <span v-for="(tax, index) in taxList" :key="index" class="tax-links">
                            <a v-if="tax.link" :href="tax.link" target="_blank" :title="tax.title">
                                {{ tax.text }}
                            </a>
                            <span v-else>{{ tax.text }}</span>
                            <span v-if="taxList.length > 1 && index < taxList.length - 1">, </span>
                        </span>
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="capitalIncrease.presentation_link" class="mb-4">
            <v-col class="card-style elevation-1 pa-0">
                <v-hover v-slot="{ hover }">
                    <v-card
                        :href="capitalIncrease.presentation_link"
                        :elevation="hover ? 8 : 0"
                        outlined
                        target="_blank"
                        flat
                        class="pa-4"
                    >
                        <v-list-item two-line class="ma-0 pa-0">
                            <v-list-item-avatar size="40" color="grey">
                                <img alt="Logo Zoom" src="/images/zoom.png" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="mb-1">
                                    {{
                                        $t(
                                            "capital-increase.user.detail.infos.project-presentation"
                                        )
                                    }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <v-row v-if="capitalIncrease.contact_link">
            <v-col class="card-style pa-0 elevation-1">
                <v-hover v-slot="{ hover }">
                    <v-card
                        :href="capincContactLink"
                        :elevation="hover ? 8 : 0"
                        outlined
                        target="_blank"
                        flat
                        class="pa-4"
                    >
                        <v-list-item two-line class="ma-0 pa-0">
                            <v-list-item-avatar size="40" color="white">
                                <img alt="Avatar" :src="$universe.image('/images/logo.svg')" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="mb-1">
                                    {{ $t("capital-increase.user.detail.infos.be-called") }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="capitalIncrease.contact_phone">
                                    {{
                                        $t("capital-increase.user.detail.infos.or-call") +
                                        " " +
                                        formatPhone
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DaysLeft from "@/components/molecules/progress/DaysLeft.vue";
import MoneyRaisedMini from "@/components/molecules/progress/MoneyRaisedMini.vue";
import {
    getShortTaxLabelFromId,
    getTaxLabelFromId,
    getLinkTaxFromId,
    getShortEnvelopeLabelFromId,
    getEnvelopeLabelFromId,
    getLinkEnvelopeFromId
} from "@/components/views/user/buy/steps/util.js";
import NumberDisplayRefont from "./NumberDisplayRefont.vue";
import { isCapincEnded, isCapincToCome } from "@/components/views/projects/utils";

export default {
    name: "TotalLeverageCard",
    components: {
        NumberDisplayRefont,
        MoneyRaisedMini,
        DaysLeft
    },
    props: {
        capitalIncrease: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cip() {
            return this.$route.name === "corner-project-detail";
        },
        capincContactLink() {
            let link = this.capitalIncrease.contact_link;
            if (link.includes("@")) {
                return `mailto:${link}`;
            } else {
                return link;
            }
        },
        formatPhone() {
            let phone = this.capitalIncrease.contact_phone;
            const reg = /(?:((?:\+|00)\d{2})(\s*[1-9])|(0\s*[1-9]))((?:[\s.-]*\d{2}){4})$/;
            let res = phone
                .match(reg)
                .filter(e => e)
                .slice(1);
            res[res.length - 1] = res[res.length - 1].replace(/(.{2})/g, "$1 ");
            return res.join(" ");
        },
        isCapincActive() {
            return !isCapincEnded(this.capitalIncrease) && !isCapincToCome(this.capitalIncrease);
        },
        isAllowedToInvest() {
            // Les apporteurs d'affaires et cgp n'ont pas le droit d'investir
            return !(this.$entity.isAffiliationCgp() || this.$entity.isAffiliationBc());
        },
        taxList() {
            let taxAndEnvList = [];
            const taxArrangements = this.capitalIncrease?.capincpaymentoptions?.tax_arrangements;
            if (taxArrangements !== null && taxArrangements !== undefined) {
                if (
                    taxArrangements.length === 0 ||
                    (taxArrangements.length === 1 && taxArrangements[0] === "none")
                ) {
                } else {
                    for (const tax of taxArrangements) {
                        if (tax === "none") {
                            continue;
                        }
                        taxAndEnvList.push({
                            text: getShortTaxLabelFromId(this, tax),
                            title: getTaxLabelFromId(this, tax),
                            link: getLinkTaxFromId(tax)
                        });
                    }
                }
            }
            return taxAndEnvList.sort((a, b) => {
                return a.text.localeCompare(b.text);
            });
        },
        envList() {
            let taxAndEnvList = [];
            const envelopes = this.capitalIncrease?.capincpaymentoptions?.envelopes;
            if (envelopes !== null && envelopes !== undefined) {
                if (envelopes.length === 0 || (envelopes.length === 1 && envelopes[0] === "none")) {
                } else {
                    for (const envelope of envelopes) {
                        if (envelope === "none") {
                            continue;
                        }
                        taxAndEnvList.push({
                            text: getShortEnvelopeLabelFromId(this, envelope),
                            title: getEnvelopeLabelFromId(this, envelope),
                            link: getLinkEnvelopeFromId(envelope)
                        });
                    }
                }
            }
            return taxAndEnvList.sort((a, b) => {
                return a.text.localeCompare(b.text);
            });
        }
    },
    methods: {
        isCapincEnded
    }
};
</script>

<style lang="scss" scoped>
.tax-text {
    color: rgb(61, 81, 112);
    font-family: "Montserrat-Medium";
}

.tax-links {
    font-size: 0.9rem;

    a {
        text-decoration: none;
        font-weight: bolder;
    }
}

.invest-btn {
    margin: 1rem 0;
    width: 100% !important;
    font-size: 1.5rem !important;
    font-weight: bolder;
    transition: 0.25s;
    border-radius: 6px;
    border: none;
    text-transform: none !important;
    color: white !important;
    background-color: var(--v-primary-base) !important;

    &:hover,
    &:focus {
        animation: pulse 0.5s;
        box-shadow: 0 0 0 1em transparent;
    }

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 var(--v-primary-base);
        }
    }
}

.title-leverage {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 0.03em;
    color: #000000;
}

.wrapper-leverage-card {
    width: 100%;
    overflow: hidden;
    max-width: 481px;
    background: white;
    border-radius: 8px;
    padding: 52px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
        font-size: 16px;
    }

    #value {
        margin-top: 12px;
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
    }

    @media only screen and (max-width: 1150px) {
        margin-top: 3rem;
        max-width: unset;
        height: 406px;
    }
}

.number-group-wrapper {
    border: 1px solid #17375f;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    padding: 8px;
}

.v-card {
    transition: all 0.3s ease-in-out;
}
</style>
