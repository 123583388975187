var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({attrs:{"title":_vm.$t('portfolio.label-asset')}},'EqsCard',{ loading: _vm.loading },false),[_c('div',{staticClass:"mt-3 ml-4 mb-3"},[_c('GlobalCompanyInviteByInvestorDialog',{attrs:{"open":_vm.openDialog},on:{"update:open":function($event){_vm.openDialog=$event},"closeDialog":() => {
                    _vm.openDialog = false;
                }}})],1),_c('EqsDataTable',_vm._b({attrs:{"add":{
            txt: _vm.$t('portfolio.label-invite-company'),
            fn: () => (_vm.openDialog = true)
        }},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.tri",fn:function(){return [_vm._v(" "+_vm._s("- %")+" ")]},proxy:true},{key:"item.amount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency( Math.abs(item.investment_sum_eur), item.country.alphacode == "US" ? "USD" : "EUR" ))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('EqsBtn',{attrs:{"small":"","text":"","el-class":`${item.name}-select`},on:{"click":function($event){return _vm.selectCompany(item)}}},[_c('v-icon',[_vm._v("mdi-text-box-outline")])],1)]}}])},'EqsDataTable',{
            headers: _vm.headers,
            searchable: {
                placeholder: _vm.$t('label.search-companies')
            },
            selected: _vm.company ? [_vm.company] : [],
            loading: _vm.localLoading,
            items: _vm.companies,
            elClass: 'my-assets',
            sortBy: 'amount',
            sortDesc: [true]
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }