var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.users.length)?_c('v-menu',{staticClass:"eqs-multi-avatars-menu",attrs:{"open-on-click":!_vm.$reactive.isDesktop(),"open-on-hover":_vm.$reactive.isDesktop(),"right":"","rounded":"lg","max-width":"100vw","max-height":"100vh"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({class:`eqs-multi-avatars ${_vm.elClass}-container d-flex no-wrap`},on),[_vm._l((_vm.truncatedUsers),function(user,index){return _c('div',{key:index,class:`eqs-multi-avatars__item ${
                        index ? 'eqs-multi-avatars__next-item' : ''
                    }`},[_c('EqsAvatar',_vm._b({},'EqsAvatar',{ user, ..._vm.$attrs, noBadge: true, loading: _vm.loading },false))],1)}),(_vm.others.length > 1)?_c('div',{staticClass:"eqs-multi-avatars__next-item eqs-multi-avatars__item"},[_c('EqsAvatar',_vm._b({},'EqsAvatar',{
                            text: `+${_vm.others.length}`,
                            ..._vm.$attrs,
                            noBadge: true,
                            loading: _vm.loading
                        },false))],1):_vm._e()],2)]}}],null,false,2648148014)},[_c('EqsCard',{staticClass:"eqs-multi-avatars-list elevation-3",attrs:{"closable":""}},[_c('EqsList',_vm._b({staticClass:"px-2 pt-3",scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('EqsAvatar',_vm._b({staticClass:"mr-3",attrs:{"no-badge":""}},'EqsAvatar',{ user: item, eqsType: 'list' },false)),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}],null,false,3086874873)},'EqsList',{ items: _vm.users, eqsType: 'avatar-two-line', loading: _vm.loading },false))],1)],1):(_vm.loading)?_c('EqsAvatar',_vm._b({},'EqsAvatar',{ loading: true, text: '+0' },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }