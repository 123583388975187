import cloneDeep from "lodash/cloneDeep";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }, cornerId) => {
    DEBUG("GET_CORNER_START");
    if (!cornerId) throw new Error("no cornerId");
    let corner = state.corners.find(_corner => _corner.name === cornerId);
    // Check si on a toutes les données ou juste un tiny
    if (!corner) {
        try {
            const res = await window.app.$api.getCorner(cornerId);
            corner = res.data;
            DEBUG("GET_CORNER_SUCCESS");
            commit("ADD_CORNER", corner);
        } catch (e) {
            DEBUG("GET_CORNER_ERROR");
            throw e;
        }
    }
    return cloneDeep(corner);
};
