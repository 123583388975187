<template>
    <div class="eqs-main-container margin-auto">
        <EqsViewWithImage class="mx-3" v-bind="{ img: $universe.image('/images/Money-out.svg') }">
            <v-row class="px-3">
                <v-col class="eqs-form-field">
                    <EqsTextDisplay eqs-type="subtitle" class="mb-3">
                        {{ $t("user.wallet.money-out.withdraw-confirmation") }}
                    </EqsTextDisplay>
                    <EqsTextDisplay class="mb-3">
                        {{ $t("user.wallet.money-out.withdraw-confirmation-text") }}
                    </EqsTextDisplay>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="eqs-form-field">
                    <slot name="iban" />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col class="eqs-form-field">
                    <WalletBalance class="mb-3" v-bind="{ loading }" />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col class="eqs-form-field">
                    <EqsNumber
                        :label="$t('user.wallet.money-out.withdrawal-amount')"
                        el-class="withdraw-amount"
                        class="mb-3"
                        readonly
                        v-bind="{ editable: false, value: formData.moneyVal, loading }"
                    />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col class="eqs-form-field">
                    <EqsNumber
                        :label="$t('user.wallet.money-out.transaction-fee')"
                        style="width: 60%"
                        el-class="tx-fee"
                        class="pb-3"
                        readonly
                        v-bind="{ editable: false, value: formData.fee, loading }"
                    />
                </v-col>
            </v-row>

            <v-row class="px-3">
                <v-col class="eqs-form-field">
                    <EqsNumber
                        :label="$t('user.wallet.money-out.total-withdrawal-amount')"
                        style="width: 60%"
                        el-class="total-amount"
                        class="pb-3"
                        readonly
                        v-bind="{
                            editable: false,
                            value: formData.totalWithdrawal,
                            loading
                        }"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3">
                <v-col class="eqs-form-field">
                    <EqsAlert
                        class="mb-3 mt-2"
                        v-bind="{ text: $t('user.wallet.money-out.info-txt'), loading }"
                    />
                </v-col>
            </v-row>
        </EqsViewWithImage>
    </div>
</template>

<script>
import WalletBalance from "../WalletBalance";

export default {
    name: "WithdrawSummary",
    components: {
        WalletBalance
    },
    props: {
        formData: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            iban: null
        };
    }
};
</script>
