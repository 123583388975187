<template>
    <div class="d-flex flex-column fill-height">
        <v-row class="flex-grow-0 pa-8">
            <v-col cols="8">
                <EqsTextDisplay eqs-type="title"> Mes investissements </EqsTextDisplay>
            </v-col>
            <v-col cols="2">
                <EqsBtn block color="primary" @click="$router.push({ name: 'projects' })">
                    Financer
                </EqsBtn>
            </v-col>
            <v-col cols="2">
                <EqsBtn block color="primary"> Vendre des titres </EqsBtn>
            </v-col>
        </v-row>

        <v-row no-gutters class="align-self-stretch">
            <EqsTabs v-bind="{ tabs }" :value.sync="tab">
                <template #default="{ item }">
                    <component :is="item.is" class="pa-3" v-bind="{ loading }" />
                </template>
            </EqsTabs>
        </v-row>
    </div>
</template>

<script>
import Companies from "./Companies";
import Summary from "./Summary";

export default {
    name: "Portfolio",
    components: {
        Companies,
        Summary
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tab: null,
            tabs: [
                {
                    id: "summary",
                    title: "Résumé",
                    // title: this.$t("portfolio.summary.title"),
                    item: {
                        is: "Summary"
                    }
                },
                {
                    id: "companies",
                    title: "Sociétés",
                    // title: this.$t("portfolio.companies.title"),
                    item: {
                        is: "Companies"
                    }
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped></style>
