<template>
    <EqsCard height="100%" class="fill-height" :title="$t('payments.title')">
        <template #main>
            <v-row no-gutters class="px-3 d-flex fill-height align-center justify-center">
                <EqsChooseAction v-bind="{ choices }" />
            </v-row>
        </template>
    </EqsCard>
</template>

<script>
export default {
    name: "AddPayment",
    data() {
        return {
            choices: [
                {
                    title: this.$t("payments.to_send.title"),
                    text: this.$t("payments.to_send.subtitle"),
                    img: "/images/versement.svg",
                    action: () => this.add()
                },
                {
                    title: this.$t("payments.ask.title"),
                    text: this.$t("payments.ask.subtitle"),
                    img: "/images/ask_versement.svg",
                    action: () => this.ask()
                },
                {
                    title: this.$t("payments.upload.title"),
                    text: this.$t("payments.upload.subtitle"),
                    img: "/images/download_versement.svg",
                    inProgress: true,
                    action: () => this.download()
                }
            ]
        };
    },
    methods: {
        add() {
            this.$router
                .push({
                    name: "add-new-payment-operation",
                    params: this.$route.params,
                    query: {
                        action: "to_send"
                    }
                })
                .catch(() => {});
        },
        download() {
            this.$router
                .push({
                    name: "add-download-payment-operation",
                    params: this.$route.params,
                    query: {
                        action: "download"
                    }
                })
                .catch(() => {});
        },
        ask() {
            this.$router
                .push({
                    name: "add-new-payment-operation",
                    params: this.$route.params,
                    query: {
                        action: "ask"
                    }
                })
                .catch(() => {});
        }
    }
};
</script>

<style lang="scss" scoped>
@media (min-width: 961px) {
    .card {
        min-height: 350px;
    }
}

.image_svg {
    background-color: "green";
    max-height: 200px;
    max-width: 200px;
    margin: auto;
}
</style>
