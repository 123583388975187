<template>
    <div>
        <EqsAlert v-bind="{ type: 'info' }" class="mt-3">
            <EqsTextDisplay paragraph>
                <span>
                    {{ $t("twofa.scan.first-text") }}
                    <a
                        href="https://apps.apple.com/fr/app/google-authenticator/id388497605"
                        class="font-weight-bold"
                        target="_blank"
                    >
                        {{ $t("twofa.scan.ios") }}</a
                    >
                    {{ $t("twofa.scan.middle-text") }}
                    <a
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                        class="font-weight-bold"
                        target="_blank"
                    >
                        {{ $t("twofa.scan.android") }}</a
                    >
                    {{ $t("twofa.scan.before-last-text") }}
                </span>
                <br />
                {{ $t("twofa.scan.last-text") }}
            </EqsTextDisplay>
        </EqsAlert>
        <div class="d-flex flex-column align-center">
            <div v-if="!localLoading && !loading">
                <VueQrcode id="qrcode" :value="qrcode" :options="{ width: 200 }" />
            </div>
            <EqsTextDisplay class="pb-3">
                <span class="font-weight-bold">
                    {{ otpSecret }}
                </span>
            </EqsTextDisplay>
            <EqsTextDisplay class="pb-1" :value="$t('twofa.enter-code')" />
            <v-input :value="validOtp" :error-messages="validOTPRule">
                <VueOtpInput
                    id="vueotpinput"
                    ref="vueotpinput"
                    el-class="vueotpinput"
                    class="d-flex justify-center"
                    :input-classes="`otpinput${$reactive.isMobile() ? '-mobile' : ''}`"
                    :num-inputs="6"
                    separator
                    :should-auto-focus="true"
                    :is-input-num="true"
                    v-on="$listeners"
                />
            </v-input>
        </div>
    </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueOtpInput from "@bachdgvn/vue-otp-input";
export default {
    name: "TwoFAForm",
    components: { VueQrcode, VueOtpInput },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        validOtp: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            qrcode: "",
            otpSecret: ""
        };
    },
    computed: {
        validOTPRule() {
            return this.validOtp ? "" : this.$t("twofa.invalid-code");
        }
    },
    async beforeMount() {
        await this.set2FACode();
    },
    methods: {
        async set2FACode() {
            this.localLoading = true;
            try {
                const res = await this.$api.getTwoFA();
                this.qrcode = res.data;
                this.otpSecret = this.qrcode.match(/.*secret=([A-Z0-9]*)&/)[1];
            } catch (e) {
                this.$ui.error(e, "twofa_get_secret");
            }
            this.localLoading = false;
        },
        resetOTPInput() {
            this.$refs.vueotpinput.clearInput();
            this.$refs.vueotpinput.$el.focus();
        }
    }
};
</script>

<style lang="scss">
.vue-qr-code {
    width: 100% !important;
    height: auto !important;
}

.otpinput {
    width: 40px;
    height: 40px;
    padding: 6px;
    margin: 0 4px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    color: black !important;
    &.error {
        border: 1px solid red !important;
    }

    -moz-appearance: textfield;
    appearance: textfield;
}

.otpinput-mobile {
    width: 35px;
    height: 25px;
    padding: 4px;
    margin: 0 3px;
    font-size: 15px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    color: black !important;
    &.error {
        border: 1px solid red !important;
    }

    -moz-appearance: textfield;
    appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
