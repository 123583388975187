<template>
    <div
        class="eqs-select d-flex"
        :data-test="`${elClass}-${!loading && !disabled ? 'clickable' : 'not-clickable'}`"
    >
        <EqsLabel
            v-bind="{
                ...$attrs,
                locked,
                isRequired,
                height: labelHeight,
                editable,
                value: label,
                infoTxt
            }"
        >
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <EqsEditable
                v-bind="{
                    ...$attrs,
                    editable: editable && !locked,
                    value: editableData,
                    multiple
                }"
            >
                <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                <EqsSkeleton v-bind="{ loading, type: 'sentences' }">
                    <v-select
                        v-model="valueProxy"
                        dense
                        v-bind="{
                            ...$props,
                            ...$attrs,
                            ...getData,
                            multiple,
                            clearable,
                            loading: loadingItems,
                            label: ''
                        }"
                        :background-color="$color.colors.inputBackground"
                        :el-class="`${elClass}-field`"
                        :menu-props="{
                            auto: true,
                            contentClass: `${elClass}-list-container`
                        }"
                        :class="`rounded-lg ${elClass}-input`"
                        :rules="rulesRequired"
                        :style="getStyle"
                        outlined
                        filled
                        rounded
                        hide-details="auto"
                        v-on="$listeners"
                    >
                        <template
                            v-for="(_, name) in $scopedSlots"
                            :slot="name"
                            slot-scope="slotData"
                        >
                            <slot :name="name" v-bind="slotData" />
                        </template>
                        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                    </v-select>
                </EqsSkeleton>
            </EqsEditable>
        </EqsLabel>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsSelect.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";
import { toType } from "../../../../../shared/util";

export default {
    name: "EqsSelect",
    components: {
        EqsLabel: () => import("../../atoms/EqsLabel/EqsLabel.vue"),
        EqsEditable: () => import("../../atoms/EqsEditable/EqsEditable.vue"),
        EqsSkeleton: () => import("../../atoms/EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: "eqs-select"
        },
        items: {
            type: Array,
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        value: {
            type: null,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: "text"
        },
        itemValue: {
            type: String,
            default: "value"
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        loadingItems: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
                this.$emit("selected", val);
            }
        },
        editableMultiple() {
            return this.valueProxy.map(val => {
                let matchingItem = this.returnObject
                    ? val
                    : this.items.find(item => item[this.itemValue] === val);
                if (!matchingItem) matchingItem = toType(val) === "string" ? val : null;
                const typeOfValue = toType(matchingItem);
                if (typeOfValue === "object") return matchingItem[this.itemText];
                return matchingItem;
            });
        },
        editableData() {
            if (!this.valueProxy) return "";
            if (this.multiple) return this.editableMultiple;
            let matchingItem = this.returnObject
                ? this.valueProxy
                : this.items.find(item => item[this.itemValue] === this.valueProxy);
            if (!matchingItem)
                matchingItem = toType(this.valueProxy) === "string" ? this.valueProxy : null;
            const typeOfValue = toType(matchingItem);
            if (typeOfValue === "object") return matchingItem[this.itemText];
            return matchingItem;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsSelect.scss";
</style>
