import { colors, rand_colors_0, rand_colors_1 } from "../../../shared/variables/colors";

import pastelColors from "../../../shared/variables/pastelColors";

export class ColorService {
    constructor() {
        Object.assign(this, { colors, rand_colors_0, rand_colors_1 });
        this.srand(Date.now());
        this.pastelColors = pastelColors;
        // this.pastelColors = Array.apply("", Array(100)).map(() =>
        //   this.getPastelColor()
        // );
    }
    get() {
        return this.colors;
    }
    srand(seed) {
        this._seed = seed;
    }
    hslToHex(h, s, l) {
        l /= 100;
        const a = (s * Math.min(l, 1 - l)) / 100;
        const f = n => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color)
                .toString(16)
                .padStart(2, "0"); // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }
    getPastelColor() {
        return this.hslToHex(360 * Math.random(), 25 + 70 * Math.random(), 85 + 10 * Math.random());
    }
    rand(min, max) {
        var seed = this._seed;
        min = min === undefined ? 0 : min;
        max = max === undefined ? 1 : max;
        this._seed = (seed * 9301 + 49297) % 233280;
        return min + (this._seed / 233280) * (max - min);
    }

    color(offset) {
        var count = rand_colors_0.length;
        var index = offset === undefined ? ~~this.rand(0, count) : offset;
        return rand_colors_0[index % count];
    }

    colors(config) {
        var cfg = config || {};
        var color = cfg.color || this.color(0);
        var count = cfg.count !== undefined ? cfg.count : 8;
        var method = cfg.mode ? Color.prototype[cfg.mode] : null;
        var values = [];
        var i, f, v;

        for (i = 0; i < count; ++i) {
            f = i / count;
            if (method) {
                v = method.call(Color(color), f).hex();
            } else {
                v = this.color(i);
            }
            values.push(v);
        }
        return values;
    }

    newColor(offset) {
        let count = rand_colors_1.length;
        let index = offset === undefined ? ~~this.rand(0, count) : offset;
        return rand_colors_1[index % count];
    }

    newColors(config) {
        let cfg = config || {};
        let count = cfg.count !== undefined ? cfg.count : 8;
        let values = [];
        let i, v;

        for (i = 0; i < count; ++i) {
            v = this.newColor(i);
            values.push(v);
        }
        return values;
    }

    hashCode(str) {
        var h = 0,
            i = str.length;
        while (i > 0) {
            h = ((h << 5) - h + str.charCodeAt(--i)) | 0;
        }
        return h;
    }

    colorFromHash(data) {
        if (!data) {
            console.error("wrong usage of colorFromHash");
            return "";
        }
        const modulo = this.pastelColors.length;
        return this.pastelColors[Math.abs(this.hashCode(data.toString()) % modulo)];
    }

    colorFromHashAvatar(data) {
        if (!data) {
            console.error("wrong usage of colorFromHash");
            return "";
        }
        const modulo = rand_colors_0.length;
        return rand_colors_0[Math.abs(this.hashCode(data.toString()) % modulo)];
    }

    // colorFromHash(data, color_set = 0) {
    //   if (!data || color_set > 1) {
    //     console.error("wrong usage of colorFromHash");
    //     return "";
    //   }
    //   const modulo = this[`rand_colors_${color_set}`].length;
    //   return this[`rand_colors_${color_set}`][
    //     Math.abs(this.hashCode(data.toString()) % modulo)
    //   ];
    // }

    transparentize(color, opacity) {
        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
        return Color(color).alpha(alpha).hex();
    }

    setThemes(themes) {
        if (themes) {
            for (let [themeKey, themeVal] of Object.entries(themes)) {
                let vuetifyTheme = window.app.$vuetify.theme.themes[themeKey];
                for (let [key, value] of Object.entries(themeVal)) {
                    vuetifyTheme[key] = value;
                }
            }
        }
    }
}
