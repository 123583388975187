<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row justify="center" align="center">
            <v-col>
                <h2 class="text-h5 mb-4">
                    {{ $t("user.payment-confirmation.crypto.recap-title") }}
                </h2>
                <CryptoRecapTable :order="order" class="recap-table" />
            </v-col>
            <v-col>
                <div v-if="!cryptoDone" class="text-center">
                    <v-alert border="left" type="warning" class="mt-9">
                        <span style="color: black">
                            {{ $t("user.buy.steps.payment-data.crypto.warning-use-ledger") }}
                        </span>
                    </v-alert>
                    <div v-if="showCheckStatus">
                        <div>
                            {{ $t("user.payment-confirmation.crypto.btn-check-status") }}
                        </div>
                        <v-btn
                            :disabled="localLoading"
                            class="mt-4 custom-btn"
                            outlined
                            large
                            @click.stop="checkStatus"
                        >
                            {{ $t("user.payment-confirmation.crypto.btn-check-status-btn") }}
                        </v-btn>
                    </div>
                    <div v-else>
                        <v-alert border="left" type="info" outlined>
                            <span style="color: black">
                                {{ $t("user.payment-confirmation.crypto.checking-status") }}
                            </span>
                        </v-alert>
                    </div>
                    <v-alert border="left" type="warning" outlined class="mt-8">
                        <span style="color: black">
                            {{ $t("user.payment-confirmation.crypto.check-wallet") }}
                        </span>
                    </v-alert>
                </div>
                <div v-else>
                    <v-alert border="left" type="success" outlined>
                        <span style="color: black">
                            {{ $t("user.payment-confirmation.crypto.transaction-validated") }}
                        </span>
                    </v-alert>
                    <p class="text-center">
                        <a :href="cryptoLink" target="_blank" class="cl-icon-with-text pa-1">
                            <span>{{
                                $t("user.payment-confirmation.crypto.transaction-etherscan")
                            }}</span>
                            <v-icon dense class="custom-right-icon"> mdi-open-in-new </v-icon>
                        </a>
                    </p>
                </div>
            </v-col>
        </v-row>
        <v-input v-model="cryptoDone" :rules="ruleCryptoDone" type="hidden" />
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import CryptoRecapTable from "../components/CryptoRecapTable.vue";

export default {
    name: "CryptoConfirmation",
    components: { CryptoRecapTable },
    mixins: [formMixin],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            localLoading: false,
            cryptoDone: false,
            cryptoStatus: null,
            cryptoLink: null,
            apiFetched: false
        };
    },
    computed: {
        ruleCryptoDone() {
            return [value => !!value && value === true];
        },
        showCheckStatus() {
            const cryptodata = this.order?.payment_data?.cryptodata;
            return cryptodata.last_api_fetch === null && !this.apiFetched;
        }
    },
    beforeMount() {
        const cryptodata = this.order?.payment_data?.cryptodata;
        this.cryptoDone = this.order.status === "sequestered";
        this.cryptoStatus = this.order.status === "sequestered" ? "sequestered" : "pending";
        if (this.cryptoDone) {
            this.cryptoLink = cryptodata.tx_link;
        }
    },
    methods: {
        async checkStatus() {
            this.localLoading = true;
            try {
                const res = await this.$api.request(
                    "get",
                    `api/capital-increase-order/${this.order.id}/check_crypto_transac/`
                );
                this.apiFetched = true;
                if (res?.data?.status === "sequestered") {
                    this.cryptoDone = true;
                    this.cryptoStatus = "sequestered";
                    this.cryptoLink = res.data.tx_link;
                } else {
                    this.cryptoDone = false;
                    this.cryptoStatus = "pending";
                }
            } catch (e) {
                console.error("Cant check status: ", e);
                this.$ui.error(`Impossible de vérifier le statut (${e?.statusText})`);
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.recap-table {
    max-width: 620px;
}
.custom-btn {
    font-size: 1.2em !important;
    text-transform: none !important;
    color: white !important;
    background-color: var(--v-primary-base) !important;
}
.cl-icon-with-text {
    > span {
        display: inline;
        vertical-align: bottom;
    }

    .custom-right-icon {
        vertical-align: bottom;
        margin-left: 6px;
        transition: transform 0.2s ease;
    }

    .custom-right-icon:hover {
        transform: scale(1.2);
    }
}
</style>
