import getMyRoles from "./actions/get_my_roles";
import getMyRole from "./actions/get_my_role";
import setMyCurrentRole from "./actions/set_my_current_role";
import resetRoleStore from "./actions/reset_role_store";
import cleanImpersonateRoles from "./actions/cleanImpersonateRoles";

export default {
    getMyRoles,
    getMyRole,
    setMyCurrentRole,
    resetRoleStore,
    cleanImpersonateRoles
};
