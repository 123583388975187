var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"closable":""},on:{"close":() => (_vm.dialogProxy = false)},model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},'EqsDialog',{
        closable: true,
        confirm: {
            fn: () => (_vm.dialogProxy = false),
            txt: _vm.$t('btn.close')
        },
        title: _vm.title
    },false),[_c('div',{staticClass:"d-flex flex-column height-fit overflow-y no-overflow-x"},[_c('EqsTextDisplay',_vm._b({staticClass:"mb-3",attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{ value: _vm.$t('user.wallet.limits.money-in-title') },false)),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsNumber',_vm._b({},'EqsNumber',{
                        editable: false,
                        label: this.$t('user.wallet.limits.daily-money'),
                        value: _vm.limits.moneyinDailyMoney
                    },false))],1),_c('v-col',[_c('EqsNumber',_vm._b({},'EqsNumber',{
                        editable: false,
                        isFloat: false,
                        isCurrency: false,
                        label: this.$t('user.wallet.limits.daily-number'),
                        labelHeight: '38px',
                        value: _vm.limits.moneyinDailyNumberOf
                    },false))],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsNumber',_vm._b({},'EqsNumber',{
                        editable: false,
                        label: this.$t('user.wallet.limits.monthly-money'),
                        value: _vm.limits.moneyinMonthlyMoney
                    },false))],1),_c('v-col',[_c('EqsNumber',_vm._b({},'EqsNumber',{
                        editable: false,
                        label: this.$t('user.wallet.limits.annually-money'),
                        value: _vm.limits.moneyinAnnuallyMoney
                    },false))],1)],1),_c('EqsTextDisplay',_vm._b({staticClass:"my-3",attrs:{"eqs-type":"subtitle"}},'EqsTextDisplay',{ value: _vm.$t('user.wallet.limits.money-out-title') },false)),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsNumber',_vm._b({},'EqsNumber',{
                        editable: false,
                        label: this.$t('user.wallet.limits.daily-money'),
                        value: _vm.limits.moneyoutDailyMoney
                    },false))],1),_c('v-col',[_c('EqsNumber',_vm._b({},'EqsNumber',{
                        editable: false,
                        label: this.$t('user.wallet.limits.daily-number'),
                        isFloat: false,
                        isCurrency: false,
                        labelHeight: '38px',
                        value: _vm.limits.moneyoutDailyNumberOf
                    },false))],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsNumber',_vm._b({},'EqsNumber',{
                        editable: false,
                        label: this.$t('user.wallet.limits.monthly-money'),
                        value: _vm.limits.moneyoutMonthlyMoney
                    },false))],1),_c('v-col',[_c('EqsNumber',_vm._b({},'EqsNumber',{
                        editable: false,
                        label: this.$t('user.wallet.limits.annually-money'),
                        value: _vm.limits.moneyoutAnnuallyMoney
                    },false))],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }