import store from "@/store/store.js";
// import { sleep } from "@equisafe-ui-shared/util";
// TODO: remettre dans les mutations
export default async ({ commit, state, dispatch }) => {
    try {
        // here reset store from other modules that are linked to specific role
        // and used by company or not
        store.commit("setLoadingWebSocket", true);
        await window.app.$webSocket.closePrivateSocket();
        // reset all the stores
        commit("RESET_STORE_NOTIFICATIONS");
        commit("RESET_STORE_PROCEDURES");
        commit("RESET_STORE_INVESTORS");
        commit("RESET_STORE_GOVERNANCE");
        commit("RESET_STORE_SHARELEDGERS");
        commit("RESET_STORE_BLOCKCHAIN");
        commit("RESET_STORE_PROJECTS");
        commit("RESET_STORE_WALLET");
        const entity_represented =
            state.currentRole && state.currentRole.entity_represented
                ? state.currentRole.entity_represented
                : null;
        if (entity_represented) {
            if (entity_represented.is_company) await dispatch("getBlockchainInfo");
            window.app.$notifications.getNotifications({ page: 1, page_size: 5 });
            await window.app.$webSocket.initPrivateSocket(state.currentRole);
        }
    } catch (error) {
        console.error(`error in reset role store : ${error}`);
        throw error;
    } finally {
        store.commit("setLoadingWebSocket", false);
    }
};
