import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, dispatch }, representative) => {
    DEBUG("CREATE_REPRESENTATIVE_START");
    try {
        commit("SET_REPRESENTATIVES_LOADING", true);
        const representativeRes = await window.app.$api.createRepresentative(representative);
        let _representative = representativeRes.data;
        const investor = await dispatch("getInvestor", _representative.investor_id);
        Object.assign(_representative.investor, investor);
        DEBUG("CREATE_REPRESENTATIVE_SUCCESS");
        commit("ADD_REPRESENTATIVE", _representative);
    } catch (e) {
        DEBUG("CREATE_REPRESENTATIVE_ERROR");
        throw e;
    } finally {
        commit("SET_REPRESENTATIVES_LOADING", false);
    }
};
