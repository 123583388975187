<template>
    <EqsDataTable
        hide-default-footer
        v-bind="{
            loading: loading || investorsLoading,
            items: voters,
            headers,
            itemsPerPage: -1,
            ...$attrs
        }"
        sort-by="nb_vote"
        :sort-desc="[true]"
    >
        <template #item.investor.entity="{ value }">
            <EqsAvatar no-badge v-bind="{ user: value, eqsType: 'list' }" />
        </template>
        <template #item.matchingLedgers="{ value }">
            <v-chip-group max="0">
                <EqsChip
                    v-for="(share, i) in value"
                    :key="i"
                    label
                    :ripple="false"
                    :color="$color.colorFromHash(share ? share.name : '')"
                    text-color="text"
                >
                    {{ share ? share.name : "" }}
                </EqsChip>
            </v-chip-group>
        </template>
        <template #item.nb_vote="{ value }">
            {{ $currency.display(value, "0,0") }}
        </template>

        <template #item.percent="{ item }">
            {{ item.percent + " %" }}
        </template>
    </EqsDataTable>
</template>

<script>
import { calcPercent } from "@equisafe-ui-shared/util";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "VotingTable",
    props: {
        organ: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        withLedgers: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.getInvestors();
    },
    methods: mapActions(["getInvestors"]),
    computed: {
        ...mapGetters(["investors", "investorsLoading"]),
        headers() {
            let res = [
                {
                    value: "investor.entity",
                    sortable: false
                },
                {
                    text: this.$t("governance.representatives.header.name"),
                    value: "investor.entity.name",
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t("governance.organs.details.voting.headers.number-votes"),
                    value: "nb_vote",
                    sortable: true,
                    align: "end"
                },
                {
                    text: this.$t("governance.organs.details.voting.headers.percent-votes"),
                    value: "percent",
                    sortable: true,
                    align: "end"
                }
            ];
            if (this.withLedgers)
                res.splice(2, 0, {
                    text: this.$t("governance.organs.choose-representatives.ledgers"),
                    value: "matchingLedgers",
                    sortable: false
                });
            return res;
        },
        voters() {
            return this.organ.members
                ? this.organ.members
                      .filter(member => member.is_voter)
                      .map(mb => {
                          let res = {
                              ...mb,
                              percent: calcPercent(mb.nb_vote, this.totalNumberOfVotes)
                          };
                          let matchingLedgers = [];
                          if (this.withLedgers) {
                              const matchingInvestor = this.investors.find(
                                  investor => investor.id === mb.investor_id
                              );
                              if (matchingInvestor) {
                                  for (const share of matchingInvestor.shares) {
                                      const matchingLedger = this.organ.shareledgers.find(
                                          shareledger => shareledger.name == share.name
                                      );
                                      if (matchingLedger) {
                                          matchingLedgers.push(matchingLedger);
                                      }
                                  }
                                  res.matchingLedgers = matchingLedgers;
                              }
                          }
                          return res;
                      })
                : [];
        },
        totalNumberOfVotes() {
            if (this.organ.members)
                return this.organ.members.reduce((acc, mb) => {
                    return acc + mb.nb_vote;
                }, 0);
            return 0;
        }
    }
};
</script>

<style lang="scss" scoped></style>
