<template>
    <EqsApp
        v-bind="{
            eqsType: eqsDataType.includes('no-nav-bar') ? 'no-nav-bar' : 'default',
            eqsDataType,
            context,
            contextFn,
            routes,
            appStates,
            notifications: {
                items: $notifications.lastNotifications(),
                numActive: $notifications.numActive()
            },
            user,
            userMenu,
            loading,
            loadingRoute,
            loadingProfiles: loadingWebSocket,
            loadingNotifications: notificationsLoading,
            bannerLogo: $universe.image('/images/logo.svg'),
            bannerLogoFull: $universe.image('/images/logo_name.svg'),
            bannerLogoRedirect: $universe.getDefaultRoute($entity.represented())
        }"
        @notifications="updateNotifs"
    >
        <transition name="fade" mode="out-in">
            <router-view
                v-bind="{
                    entity,
                    roles: myRoles,
                    loading
                }"
            />
        </transition>
        <template #modal-client>
            <SelectClientDialog
                :open.sync="selectClientDialogState"
                @closeDialog="
                    () => {
                        selectClientDialogState = false;
                    }
                "
            />
        </template>
        <template #navigation-bar-action="{ loading: loadingApp }">
            <v-col
                order="1"
                cols="auto"
                class="eqs-navigation-bar__col d-flex align-center mr-2 px-2"
            >
                <BtnNavBarKyoseil v-if="$universe.isKyoseil() || $entity.isAffiliationInvestor()" />
                <EqsBtn
                    v-else-if="navBarActionBtn"
                    v-bind="{ ...navBarActionBtn, loading: loadingApp }"
                >
                    <template #with-icon>
                        <v-icon v-if="navBarActionBtn.btnIcon">
                            {{ navBarActionBtn.btnIcon }}
                        </v-icon>
                        <v-img
                            v-else
                            width="32px"
                            height="32px"
                            :src="navBarActionBtn.btnImg"
                            contain
                        />
                        {{ navBarActionBtn.text || "" }}
                    </template>
                </EqsBtn>
            </v-col>
        </template>
    </EqsApp>
</template>

<script>
import { appStates, userMenu as _userMenu } from "./App.js";
import { individual, company } from "./menuRoutes";
import { sleep, capitalize } from "@equisafe-ui-shared/util";
import { mapGetters, mapActions } from "vuex";
import "@/assets/css/index.scss";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import throttle from "lodash/throttle";
import { isProd } from "@/assets/constants";
import SelectClientDialog from "@/components/views/client/dialog/SelectClientDialog.vue";
import { computed } from "vue";

import BtnNavBarKyoseil from "@/components/views/user/home/kyoseil/BtnNavBarKyoseil.vue";

export default {
    name: "App",
    components: {
        SelectClientDialog,
        BtnNavBarKyoseil
    },
    data() {
        return {
            selectClientDialogState: false,
            counter: 0,
            appStates,
            loading: false,
            notificationWasOpen: false,
            userMenu: [],
            routes: [],
            updateNotifications: this.updateNotifsShown
        };
    },
    computed: {
        ...mapGetters([
            "currentRole",
            "loadingRoute",
            "loadingWebSocket",
            "notificationsLoading",
            "myRoles",
            "routes_restrictions"
        ]),
        entity() {
            return this.currentRole.entity_represented ? this.currentRole.entity_represented : {};
        },
        user() {
            let _user = this.currentRole.entity_represented;
            if (isEmpty(_user)) return null;
            if (_user.name && _user.name !== _user.email) _user.name = capitalize(_user.name);
            return _user;
        },
        context() {
            return !!this.$route.meta.context ? this.$route.meta.context : "";
        },
        contextFn() {
            return !!this.$route.meta.contextFn ? this.$route.meta.contextFn : null;
        },
        eqsDataType() {
            let res = ["default"];
            const dataType = this.$route.meta.appEqsDataType;
            if (!!dataType) {
                if (isArray(dataType)) res.push(...dataType);
                else res.push(dataType);
            }
            if (this.restrictMenuAccessForEntity(this.entity)) res.push("no-menu");
            return res;
        },
        navBarActionBtn() {
            if (!this.$universe.isEquisafe()) {
                if (this.$universe.scripts().navBarActionBtn) {
                    return this.$universe.scripts().navBarActionBtn();
                }
            }
            if (
                !this.$universe.isKyoseil() &&
                !this.$universe.isNeotrees() &&
                !this.$reactive.isMobile() &&
                !this.user.is_company &&
                !!this.user.lemonway_state &&
                this.user.lemonway_state !== "failure"
            )
                return {
                    color: "primary",
                    eqsType: "block",
                    elClass: "create-company",
                    action: () => this.$router.push({ name: "create-company" }),
                    infoTxt: this.$t("routes.actions.add-company"),
                    // btnIcon: "mdi-account-plus",
                    btnImg: "/icons/add_company.svg"
                };
            return null;
        }
    },
    watch: {
        entity: {
            immediate: true,
            async handler(val) {
                this.getAppData();
                // Config les infos du chatbot
                if (zE && this.entity) {
                    zE("messenger:set", "conversationFields", [
                        { id: "21695710071186", value: this.entity.name || "" },
                        { id: "21695696575890", value: this.entity.safe_email || "" },
                        { id: "21695695935890", value: this.entity.id || "" }
                    ]);
                }
            }
        },
        routes_restrictions(val) {
            if (val && val.length) {
                this.$language.setUniverseLocaleMessages(this.$universe.data().translations);
                this.getAppData();
            }
        }
    },
    async created() {
        try {
            this.loading = true;
            await this.getUniverse();
            this.$universe.setFaviconAndTitle();
            this.$language.setUniverseLocaleMessages(this.$universe.data().translations);
            this.$color.setThemes(this.$universe.data().themes);
        } catch (e) {
            this.$ui.error(e, "get_universe");
        } finally {
            this.loading = false;
        }
    },
    // eslint-disable-next-line vue/order-in-components
    provide() {
        return {
            _entity: computed(() => this.entity)
        };
    },
    methods: {
        ...mapActions(["getUniverse"]),
        updateNotifsShown() {
            if (this.$notifications.lastNotifications().find(notif => !notif.shown)) {
                this.$notifications.updateNotificationsShown(true);
            }
        },
        openDialogClick($event) {
            this.selectClientDialogState = true;
        },
        updateNotifs(menu) {
            if (menu) {
                this.notificationWasOpen = true;
            } else if (!menu && this.notificationWasOpen) {
                // will not be executed more than each 7sec with throttle
                this.updateNotifications();
                this.notificationWasOpen = false;
            }
        },
        getAppData() {
            if (!isEmpty(this.entity)) {
                let newUserMenu = _userMenu(this, this.entity, this.myRoles);
                let newRoutes = this.entity.is_company
                    ? company(this, this.entity)
                    : individual(this, this.entity);
                if (JSON.stringify(newUserMenu) !== JSON.stringify(this.userMenu))
                    this.userMenu = newUserMenu;
                if (JSON.stringify(newRoutes) !== JSON.stringify(this.routes))
                    this.routes = newRoutes;
            } else {
                this.userMenu = [];
                this.routes = [];
            }
        },
        restrictMenuAccessForEntity(entity) {
            let restrictedEntity = entity.lemonway_state !== "success";
            if (
                restrictedEntity &&
                ["pending", "upload", "upload-failure"].includes(entity.lemonway_state)
            )
                restrictedEntity = false;
            return restrictedEntity;
        },
        async logout() {
            try {
                this.loading = true;
                this.$store.commit("setLoadingRoute", true);
                await sleep(1000);
                await this.$api.logout();
                this.$store.commit("setFailedRoute", "");
            } catch (e) {
                console.error({ e });
            } finally {
                this.loading = false;
            }
        },
        async updateMyRole(id) {
            try {
                this.loading = true;
                await this.$role.updateMyCurrentRole(id);
            } catch (e) {
                console.error({ e });
            } finally {
                const routeToGo = this.getPathForEntity(this.entity);
                if (this.$route.fullPath !== routeToGo)
                    await this.$router.push(routeToGo).catch(() => {});
                this.loading = false;
            }
        },
        getPathForEntity(entity) {
            return entity.lemonway_state === "success"
                ? `/profile/${entity.id}`
                : this.$kyc.getRoute(entity);
        }
    }
};
</script>

<style lang="scss">
@import "@/eqsUi/vue/src/css/_all.scss";

.v-application {
    &--wrap {
        max-height: 100vh;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.eqs-navigation-drawer__subheader__item {
    .v-subheader {
        span {
            margin-left: 0 !important;
        }
    }
}

// ############################################################################
// FIX d'equisafe-ui

// Style pour les card qui se selectionne, fix sans toucher a equisafe-ui
.v-card--disabled * {
    opacity: 1 !important;
    background-color: rgba(255, 255, 255, 0);
}

.v-card--disabled + * {
    opacity: 1 !important;
    background-color: rgba(90, 147, 221, 0.2);
}

// Icon info dans les formulaire qui ont une div d'espace inutile
.eqs-form-field .text-end {
    display: none;
}

.add-partner {
    display: flex;

    p {
        margin-bottom: 0 !important;
    }
}

.add-partner-btn {
    border-radius: 0 !important;
    border: 2px solid #153280 !important;
    padding: 16px 24px !important;
}
</style>
