import isEmpty from "lodash/isEmpty";
import { mouseEvent } from "@equisafe-ui-shared/util";
import { i18n } from "@/services/plugins/i18n.js";

export const getColorFromCategory = category => {
    switch (category) {
        case "tech":
            return "#EABF6C";
        case "legal":
            return "#6299B8";
        case "finance":
            return "#62B88A";
        case "marketing":
            return "#9370CD";
        case "other":
            return "#EA7B6C";
        case "rapport":
            return "#8DD8E2";
        case "invitiation":
            return "#9370CD";

        default:
            return "grey";
    }
};
export const documentsGlobalCategories = [
    {
        text: i18n.t("capital-increase.admin.offering.legal-name"),
        value: "legal"
    },
    {
        text: i18n.t("capital-increase.admin.offering.finance"),
        value: "finance"
    },
    {
        text: i18n.t("capital-increase.admin.offering.tech"),
        value: "tech"
    },
    {
        text: i18n.t("capital-increase.admin.offering.marketing"),
        value: "marketing"
    },
    {
        text: i18n.t("label.other"),
        value: "other"
    }
];

export const documentsKYC = ctx => [
    {
        name: "idcard",
        lemonway_type: 0,
        label: ctx.$t("registration.id-card.txt")
    },
    {
        name: "passport",
        lemonway_type: 3,
        label: ctx.$t("registration.passport")
    },
    {
        name: "passport",
        lemonway_type: 4,
        label: ctx.$t("registration.passport")
    },
    {
        name: "license",
        lemonway_type: 11,
        label: ctx.$t("registration.driver-licence.label")
    },
    {
        name: "notice_of_assessment",
        lemonway_type: 6,
        label: ctx.$t("registration.notice-of-assessment.label")
    },
    {
        name: "residence_permit",
        lemonway_type: 5,
        label: ctx.$t("registration.residence-permit.label")
    },
    {
        name: "kbis",
        lemonway_type: 7,
        label: ctx.$t("registration.company.kbis")
    },
    {
        name: "tax_reports",
        label: ctx.$t("registration.company.tax-reports.label")
    },
    {
        name: "company_statutes",
        lemonway_type: 12,
        label: ctx.$t("registration.company.status-of-company")
    },
    {
        name: "declaration_beneficial_owner_registry_commercial_court",
        label: ctx.$t("registration.company.registry-commercial-court.label")
    },
    {
        name: "letter_signed_by_legal_representative",
        label: ctx.$t("registration.company.letter-signed-company.label")
    },
    {
        name: "cerfa_2035_f",
        label: ctx.$t("registration.company.cerfa")
    },
    {
        name: "last_report_general_assembly",
        label: ctx.$t("registration.company.doc-last-report-general-assembly")
    },
    {
        name: "association_statutes",
        label: ctx.$t("registration.company.doc-association-statues")
    },
    {
        name: "official_gazette_publication",
        label: ctx.$t("registration.company.doc-official-gazette")
    },
    {
        name: "remit",
        lemonway_type: 14,
        label: ctx.$t("registration.company.doc-remit")
    },
    {
        name: "proof_of_residence",
        label: ctx.$t("documents.category.proof_of_residence")
    }
];

export const documentBank = ctx => [
    {
        name: "bank",
        label: ctx.$t(
            !!ctx.$entity.represented().is_company
                ? "registration.company.bank-rib-company"
                : "registration.company.bank-rib-individual"
        )
    }
];

export const documentOthers = ctx => [
    {
        name: "bank-rib-company",
        label: ctx.$t("registration.company.bank-rib-company")
    },
    {
        name: "bank-rib-individual",
        label: ctx.$t("registration.company.bank-rib-individual")
    },
    {
        name: "avatar",
        label: ctx.$t("registration.profile-picture")
    },
    {
        name: "capinc_image",
        label: ctx.$t("documents.category.capinc_image")
    },
    {
        name: "capinc_logo",
        label: ctx.$t("documents.category.capinc_logo")
    },
    {
        name: "order_invoice",
        label: ctx.$t("documents.category.capinc_sub_bill")
    },
    {
        name: "capinc_doc",
        label: ctx.$t("documents.category.capinc_doc")
    },
    {
        name: "capinc_member_photo",
        label: ctx.$t("documents.category.capinc_member_photo")
    },
    {
        name: "other",
        label: ctx.$t("documents.category.other")
    },
    {
        name: "selfie",
        label: ctx.$t("documents.category.selfie")
    },
    {
        name: "shareledger_doc",
        label: ctx.$t("documents.category.shareledger_doc")
    },
    {
        name: "tax_reports",
        label: ctx.$t("documents.category.tax_reports")
    },
    {
        name: "odm_doc",
        label: ctx.$t("documents.category.odm_doc")
    },
    {
        name: "movement_doc",
        label: ctx.$t("documents.category.movement_doc")
    },
    {
        name: "versement_doc",
        label: ctx.$t("documents.category.versement_doc")
    },
    {
        name: "statutes",
        label: ctx.$t("documents.category.statues")
    },
    {
        name: "transfer_p2p",
        label: ctx.$t("documents.category.transfert_p2p_doc")
    },
    {
        name: "money_in",
        label: ctx.$t("documents.category.money_in_doc")
    },
    {
        name: "money_out",
        label: ctx.$t("documents.category.money_out_doc")
    },
    {
        name: "capinc_investment_proof",
        label: ctx.$t("documents.category.capinc_investment_proof")
    },
    {
        name: "signed_capinc_document",
        label: ctx.$t("documents.category.signed_capinc_document")
    },
    {
        name: "signed_document",
        label: ctx.$t("documents.category.signed_document")
    },
    {
        name: "secondary_market_member_photo",
        label: ctx.$t("documents.category.secondary_market_member_photo")
    },
    {
        name: "secondary_market_doc",
        label: ctx.$t("documents.category.secondary_market_doc")
    },
    {
        name: "secondary_market_doc_set_by_company",
        label: ctx.$t("documents.category.secondary_market_doc_set_by_company")
    },
    {
        name: "viban",
        label: ctx.$t("documents.category.viban")
    },
    {
        name: "capinc_pv",
        label: ctx.$t("documents.category.capinc_pv")
    },
    {
        name: "capinc_status",
        label: ctx.$t("documents.category.capinc_status")
    },
    {
        name: "capinc_kbis",
        label: ctx.$t("documents.category.capinc_kbis")
    },
    {
        name: "shareholder_id",
        label: ctx.$t("documents.category.shareholder_id")
    }
];

export const documentTypes = [
    // information:
    {
        name: "statutes",
        label: i18n.t("documents.category.statutes")
    },
    {
        name: "kbis",
        label: i18n.t("documents.category.kbis")
    },
    {
        name: "declaration_beneficial_owner_registry_commercial_court",
        label: i18n.t("documents.category.declaration_beneficial_owner_registry_commercial_court")
    },
    {
        name: "idcard",
        label: i18n.t("documents.category.idcard")
    },
    {
        name: "shareledger_doc",
        label: i18n.t("documents.category.shareledger_doc")
    },

    // governance:
    {
        name: "shareholders_agreement",
        label: i18n.t("documents.category.shareholders_agreement")
    },
    {
        name: "transcript",
        label: i18n.t("documents.category.transcript")
    },
    {
        name: "decision",
        label: i18n.t("documents.category.decision")
    },
    {
        name: "president_report",
        label: i18n.t("documents.category.president_report")
    },
    {
        name: "power",
        label: i18n.t("documents.category.power")
    },
    {
        name: "formalities_certificate",
        label: i18n.t("documents.category.formalities_certificate")
    },

    // capital increase:
    {
        name: "signed_capinc_document",
        label: i18n.t("documents.category.signed_capinc_document")
    },
    {
        name: "termsheet",
        label: i18n.t("documents.category.termsheet")
    },
    {
        name: "business_plan",
        label: i18n.t("documents.category.business_plan")
    },
    {
        name: "accounting_doc",
        label: i18n.t("documents.category.accounting_doc")
    },

    // asset movements:
    {
        name: "movement_doc",
        label: i18n.t("documents.category.movement_doc")
    },
    {
        name: "asset_movement_doc",
        label: i18n.t("documents.category.asset_movement_doc")
    },

    // payments:
    {
        name: "transfer_p2p",
        label: i18n.t("documents.category.transfert_p2p_doc")
    },
    {
        name: "money_in",
        label: i18n.t("documents.category.money_in_doc")
    },
    {
        name: "money_out",
        label: i18n.t("documents.category.money_out_doc")
    },

    // others:
    {
        name: "nda",
        label: i18n.t("documents.category.nda")
    },
    {
        name: "health_insurance_card",
        label: i18n.t("documents.category.health_insurance_card")
    },
    {
        name: "incapability_decision",
        label: i18n.t("documents.category.incapability_decision")
    },
    {
        name: "payslip",
        label: i18n.t("documents.category.payslip")
    },
    {
        name: "representative_id",
        label: i18n.t("documents.category.representative_id")
    },
    {
        name: "ir_certificate",
        label: i18n.t("documents.category.ir_certificate")
    },
    {
        name: "capinc_peapme_certificate",
        label: i18n.t("documents.category.capinc_peapme_certificate")
    },
    {
        name: "cgp_doc_rcpro",
        label: i18n.t("documents.category.cgp_rcpro")
    },
    {
        name: "cgp_rcpro",
        label: i18n.t("documents.category.cgp_rcpro")
    },
    {
        name: "cgp_doc_orias",
        label: i18n.t("documents.category.cgp_doc_orias")
    },
    {
        name: "cgp_orias",
        label: i18n.t("documents.category.cgp_doc_orias")
    },
    {
        name: "cgp_doc_convention",
        label: i18n.t("documents.category.cgp_convention")
    },
    {
        name: "cgp_convention",
        label: i18n.t("documents.category.cgp_convention")
    },
    {
        name: "rib_pea",
        label: i18n.t("documents.category.rib_pea")
    },
    {
        name: "rib_peapme",
        label: i18n.t("documents.category.rib_peapme")
    },
    {
        name: "sepa_template",
        label: i18n.t("documents.category.sepa_sepa")
    },
    {
        name: "doc_phebe_nda",
        label: i18n.t("documents.category.phebe-nda")
    },
    {
        name: "custom_sub_doc",
        label: i18n.t("documents.category.signed_capinc_document")
    },
    {
        name: "rib_order",
        label: i18n.t("documents.category.rib_wiretransfert_order")
    },
    {
        name: "rib_wiretransfert_order",
        label: i18n.t("documents.category.rib_wiretransfert_order")
    },
    {
        name: "wire_rib",
        label: i18n.t("documents.category.wire_rib")
    },
    {
        name: "sepa_rib",
        label: i18n.t("documents.category.sepa_rib")
    },
    {
        name: "sepa_order",
        label: i18n.t("documents.category.sepa_order")
    },
    {
        name: "proof_of_address_doc",
        label: i18n.t("documents.category.proof_of_address")
    },
    {
        name: "custom_subscription_doc",
        label: i18n.t("documents.category.signed_capinc_document")
    },
    {
        name: "proof_of_fund",
        label: i18n.t("documents.category.proof_of_fund")
    },
    {
        name: "doc_fiche_connaissance_partenaire_kyoseil",
        label: i18n.t("documents.category.doc_fiche_connaissance_partenaire_kyoseil")
    },
    {
        name: "doc_questionnaire_qualite_kyoseil",
        label: i18n.t("documents.category.doc_questionnaire_qualite_kyoseil")
    },
    {
        name: "doc_procedure_lcbft_kyoseil",
        label: i18n.t("documents.category.doc_procedure_lcbft_kyoseil")
    },
    {
        name: "doc_infos_prealable_kyoseil",
        label: i18n.t("documents.category.doc_infos_prealable_kyoseil")
    },
    {
        name: "ifu_doc",
        label: i18n.t("documents.category.ifu")
    }
];

export const documentTypesObj = documentTypes.reduce((acc, curr) => {
    acc[curr.name] = curr;
    return acc;
}, {});

export const allDocsCategories = ctx => [
    ...documentTypes,
    ...documentsKYC(ctx),
    ...documentBank(ctx),
    ...documentOthers(ctx)
];

export function getLabelFromCategory(category, lemonway_type = null) {
    if (!category && !lemonway_type) return this.$t("label-from-category.file");
    const item = allDocsCategories(window.app).find(
        _item =>
            _item.name.localeCompare(category.toLowerCase()) === 0 ||
            _item.lemonway_type === lemonway_type
    );
    if (!item) {
        console.error(
            `no reference found for document of type ${category}, reference it in documents.js`
        );
        return category;
    }
    return item.label ? item.label : item.name;
}

export function isKycDoc(category) {
    if (!category) return null;
    const iskyc = documentsKYC(window.app).find(_item => _item.name === category.toLowerCase());
    if (!iskyc) return null;
    return iskyc.label ? iskyc.label : iskyc.name;
}

export const deleteDocument = async document => {
    if (!document) console.error("document is empty");
    else {
        try {
            const res = await window.app.$api.deleteDocument(document.id);
            return res.data;
        } catch (e) {
            console.error("error in deleteDocument: ", {
                e
            });
            window.app.$ui.error(e, "document_delete");
        }
    }
};

export const uploadDocument = async (document, others) => {
    if (!document) {
        console.error("document is empty");
    } else {
        const formData = new FormData();
        formData.append("upload", document);
        formData.append("type", document.type);
        formData.append("name", document.name);
        if (!isEmpty(others))
            for (var key in others) {
                formData.append(key, others[key]);
            }
        try {
            const res = await window.app.$api.postDocument(formData);
            return res.data;
        } catch (e) {
            console.error(`error in uploadDocument:`, {
                e
            });
            if ("data" in e && e.data.upload && e.data.upload.length)
                window.app.$ui.error(e.data.upload[0]);
            else if ("data" in e && e.data.name && e.data.name.length)
                window.app.$ui.error(e.data.name[0]);
            else if ("data" in e && e.data.type && e.data.type.length)
                window.app.$ui.error(e.data.type[0]);
        }
    }
};

export const uploadMultiDocument = async (docs, others) => {
    if (!docs || !docs.length) {
        console.error("Documents is empty");
    } else {
        let getDataTransfer = () => new DataTransfer();
        const fileList = getDataTransfer();
        docs.forEach(doc => {
            fileList.items.add(doc.info);
        });
        const files = fileList.files;
        const formData = new FormData();
        // To post all documents and concat them
        // You need to append `files_to_concat` for each different document
        _.forOwn(files, (file, key) => {
            formData.append("files_to_concat", file);
        });
        formData.append("name", files[0].name);
        formData.append("type", files[0].type);
        if (!isEmpty(others))
            for (var key in others) {
                formData.append(key, others[key]);
            }
        try {
            const res = await window.app.$api.postDocument(formData);
            await Promise.all(docs.map(doc => deleteDocument(doc)));
            return res.data;
        } catch (e) {
            console.error(`error in uploadDocument:`, {
                e
            });
            if ("data" in e && e.data.upload && e.data.upload.length)
                window.app.$ui.error(e.data.upload[0]);
            else if ("data" in e && e.data.name && e.data.name.length)
                window.app.$ui.error(e.data.name[0]);
            else if ("data" in e && e.data.type && e.data.type.length)
                window.app.$ui.error(e.data.type[0]);
        }
    }
};

export const signerStatus = {
    SIGNER_STATUS_PENDING: {
        eqsType: "info",
        color: "info",
        text: i18n.t("document-to-sign.procedure-awaiting-signature")
    },
    SIGNER_STATUS_PROCESSING: {
        eqsType: "info",
        color: "info",
        text: i18n.t("document-to-sign.procedure-not-started")
    },
    SIGNER_STATUS_DONE: {
        eqsType: "success",
        color: "success",
        text: i18n.t("document-to-sign.procedure-signature-finished")
    },
    SIGNER_STATUS_REFUSED: {
        eqsType: "warning",
        color: "warning",
        text: i18n.t("document-to-sign.procedure-signature-refused")
    },
    SIGNER_STATUS_ERROR: {
        eqsType: "error",
        color: "error",
        text: i18n.t("document-to-sign.procedure-signature-error")
    }
};

export const procedureStatus = {
    draft: {
        eqsType: "warning",
        color: "warning",
        text: i18n.t("document-to-sign.procedure-not-started")
    },
    active: {
        eqsType: "info",
        color: "info",
        text: i18n.t("document-to-sign.procedure-awaiting-signature")
    },
    finished: {
        eqsType: "success",
        color: "success",
        text: i18n.t("document-to-sign.procedure-signature-finished")
    },
    expired: {
        eqsType: "error",
        color: "error",
        text: i18n.t("document-to-sign.procedure-signature-expired")
    },
    refused: {
        eqsType: "error",
        color: "error",
        text: i18n.t("document-to-sign.procedure-signature-refused")
    },
    cancelled: {
        eqsType: "error",
        color: "error",
        text: i18n.t("document-to-sign.procedure-signature-cancelled")
    },
    error: {
        eqsType: "error",
        color: "error",
        text: i18n.t("document-to-sign.procedure-signature-error")
    }
};

export const downloadFile = className => {
    mouseEvent(document.getElementsByClassName(className)[0], "click");
};

export const downloadFileViaFileObject = file => {
    const url = file.upload.replace("/download/", "/download_file/");
    window.open(url);
};

export const showDocument = file => {
    window.open(file.upload, "_blank");
};

export const getNameWithExtension = (name, doc) => {
    let res = "";
    if (!name) {
        res = doc.name;
    } else {
        const extensions = doc.name.split(".");
        const extension = extensions[extensions.length - 1];
        const output = name.substr(0, name.lastIndexOf(".")) || name;
        res = output + "." + extension;
    }
    return res;
};
