var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsTextDisplay',{attrs:{"eqs-type":"caption","value":_vm.$t('portfolio.financial')}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":_vm.$t('portfolio.totalinvestments')}})],1),_c('v-col',[_c('EqsTextDisplay',{attrs:{"value":_vm.$currency.displayWithCurrency(
                        Math.abs(_vm.data.investment_sum_eur),
                        _vm.getCurrency()
                    ),"is-required":false,"editable":false}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":_vm.$t('portfolio.rendement')}})],1),_c('v-col',[_c('EqsTextDisplay',{attrs:{"value":_vm.$currency.displayWithCurrency(Math.abs(_vm.data.rendement), _vm.getCurrency()),"is-required":false,"editable":false}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":_vm.$t('portfolio.tri'),"info-txt":_vm.$t('user.wallet.info-txt')}},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":"primary"}},[_vm._v(" mdi-information ")])],1)],1),_c('v-col',[_c('EqsTextDisplay',{attrs:{"value":'- %',"is-required":false,"editable":false}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":_vm.$t('portfolio.totalvalue')}})],1),_c('v-col',[_c('EqsTextDisplay',{attrs:{"value":_vm.$currency.displayWithCurrency(Math.abs(_vm.data.value), _vm.getCurrency()),"is-required":false,"editable":false}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsTextDisplay',{staticClass:"mt-4",attrs:{"eqs-type":"caption","value":_vm.$t('portfolio.valorisation')}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":_vm.$t('portfolio.summary.market-value-title')}})],1),_c('v-col',[_c('EqsTextDisplay',{attrs:{"value":_vm.$currency.displayWithCurrency(Math.abs(_vm.data.value), _vm.getCurrency()),"is-required":false,"editable":false}})],1)],1),(_vm.data.venalValue)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":_vm.$t('portfolio.summary.venal-value')}})],1),_c('v-col',[_c('EqsTextDisplay',{attrs:{"value":_vm.$currency.displayWithCurrency(
                        _vm.getQuotePart(_vm.data.venalValue, _vm.data.percent),
                        _vm.getCurrency()
                    ),"is-required":false,"editable":false}})],1)],1):_vm._e(),(_vm.data.nav)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('EqsTextDisplay',{attrs:{"eqs-type":"subtitle","value":_vm.$t('shareledger.valuation.net-asset-value')}})],1),_c('v-col',[_c('EqsTextDisplay',{attrs:{"value":_vm.$currency.displayWithCurrency(
                        _vm.getQuotePart(_vm.data.nav, _vm.data.percent),
                        _vm.getCurrency()
                    ),"is-required":false,"editable":false}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }