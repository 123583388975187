<template>
    <div class="stats-card-wrapper mr-2">
        <div class="d-flex">
            <div class="icon-wrapper mr-4" :style="{ background: colorBackground }">
                <v-icon :color="colorIcon">{{ icon }}</v-icon>
            </div>
            <div>
                <p class="fee-text">{{ mainTitle }}</p>
                <span class="fee-text-sub">{{ $t("universe.kyoseil.stats.week") }}</span>
            </div>
        </div>
        <div class="d-flex mt-4">
            <h1 class="main-stats-fee mr-2">{{ isMoney ? $currency.display(value) : value }}</h1>
            <div
                v-if="isNaN(percentageIncrease) || percentageIncrease === 0"
                class="d-flex align-end"
            >
                <span :style="{ color: '#45CB85', lineHeight: '23px', fontSize: '14px' }">- %</span>
            </div>
            <div v-else-if="trend === 'UP'" class="d-flex align-end">
                <span :style="{ color: '#45CB85', lineHeight: '23px', fontSize: '14px' }"
                    >{{ percentageIncrease == Infinity ? "-" : percentageIncrease }}%</span
                >
                <v-icon color="#45CB85">mdi-arrow-up-thin</v-icon>
            </div>
            <div v-else-if="trend === 'DOWN'" class="d-flex align-end">
                <span :style="{ color: '#DC3545', lineHeight: '23px', fontSize: '14px' }"
                    >{{ percentageIncrease == Infinity ? "-" : percentageIncrease }}%</span
                >
                <v-icon color="#DC3545">mdi-arrow-down-thin</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StatsCardComponent",
    props: {
        mainTitle: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        isMoney: {
            type: Boolean,
            required: true
        },
        value: {
            type: Number,
            required: true
        },
        trend: {
            type: String,
            required: true
        },
        colorBackground: {
            type: String,
            required: true
        },
        colorIcon: {
            type: String,
            required: true
        },
        percentageIncrease: {
            type: Number,
            required: true
        }
    }
};
</script>

<style scoped lang="scss">
.stats-card-wrapper {
    padding: 24px;
    width: 278px;
    height: 140px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}

.icon-wrapper {
    padding: 12px;
    width: 50px;
    height: 50px;
    border-radius: 2px;
}

.fee-text {
    font-family: "Montserrat-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0d0d2b;
    margin: 0 !important;
}

.fee-text-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #17375f;
}

.main-stats-fee {
    font-family: "Montserrat-Medium";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #0d0d2b;
}
</style>
