<template>
    <EqsDataTable
        v-bind="{
            itemKey: 'id',
            items: assets,
            headers,
            column: false
        }"
    >
        <template #item.operation_date="{ item }">
            <td :el-class="`date`">
                {{ formatDate(item.operation_date) }}
            </td>
        </template>
        <template #item.operation_type="{ value }">
            <EqsChip :color="$color.colorFromHash(value)">
                {{ payment_types[value] ? payment_types[value].txt : value }}
            </EqsChip>
        </template>
        <template #item.value="{ item }">
            {{ displayAmount(item) }}
        </template>
    </EqsDataTable>
</template>

<script>
import { payment_types } from "@/util/payment";

export default {
    name: "Payments",
    props: {
        assets: {
            type: Array,
            required: true
        },
        getCurrency: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            payment_types,
            headers: [
                {
                    text: "Date",
                    value: "operation_date"
                },
                {
                    text: "Type",
                    value: "operation_type"
                },
                {
                    text: this.$t("portfolio.holdings.value-mini"),
                    value: "value",
                    align: "right"
                }
            ]
        };
    },
    methods: {
        displayAmount(item) {
            let amount = item.amount;
            if (
                (item.operation_type == "subscription" && amount > 0) ||
                (item.operation_type == "dividend" && amount < 0)
            ) {
                amount = amount * -1;
            }
            return this.$currency.displayWithCurrency(amount, this.getCurrency());
        },
        formatDate(date) {
            let format = this.$time.formated(date);
            return format;
        }
    }
};
</script>

<style lang="scss" scoped></style>
