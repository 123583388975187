export default () => ({
    balance: 0,
    balanceLoading: false,
    balanceInit: true,
    sequestre: 0,
    sequestreLoading: false,
    sequestreInit: true,
    movements: [],
    movementsLoading: false,
    movementsInit: true,
    sequestreMovements: [],
    sequestreMovementsLoading: false,
    sequestreMovementsInit: true,
    iban: null,
    ibanInit: true,
    ibanLoading: false,
    viban: null,
    vibanInit: true,
    vibanLoading: false
});
