import { getVal } from "../../../services/reactive";
import { textSizing } from "../../../templates/text";
import { logoSizing } from "../../../templates/img";
import colorsDict from "../../../../../shared/variables/colors";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            content: {
                "border-radius": "15px"
            }
        },
        "no-padding": {},
        info: {},
        warn: {},
        confirm: {},
        alert: {}
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            maxWidth: getVal(currentSize, {
                xs: "20rem",
                sm: "24rem",
                md: "28rem",
                lg: "30rem",
                xl: "32rem"
            }),
            ...textSizing(currentSize),
            logo: logoSizing(currentSize),
            closeIcon: getVal(currentSize, {
                mobile: {
                    medium: true
                },
                tablet: {
                    medium: true
                },
                desktop: {
                    large: true
                }
            })
        },
        "no-padding": {
            cardTextClass: "pa-0"
        },
        info: {
            loaderType: "green",
            img: "/icons/checkbox-marked-circle-outline.svg",
            textColor: colorsDict.infoGreen,
            color: "success"
        },
        warn: {
            loaderType: "orange",
            img: "/icons/alert-circle-outline.svg",
            textColor: colorsDict.warnOrange,
            color: "warning"
        },
        confirm: {
            loaderType: "blue",
            img: "/icons/information-outline.svg",
            textColor: colorsDict.confirmBlue,
            color: "primary"
        },
        alert: {
            loaderType: "red",
            img: "/icons/close-circle-outline.svg",
            textColor: colorsDict.alertRed,
            color: "error"
        }
    };
};
