<template>
    <EqsCard
        closable
        v-bind="{
            loading: loading || shareledgersLoading,
            progress,
            validForm,
            title: formData && formData.name ? formData.name.toUpperCase() : '',
            elClass: 'shareledger-details-ownership',
            height: '100%'
        }"
        class="fill-height"
        @close="
            () => {
                $emit('close');
            }
        "
    >
        <v-form v-model="validForm" class="d-flex flex-column fill-height overflow-y">
            <EqsPanels v-if="panels && panels.length" type="profile" :value="0" v-bind="{ panels }">
                <template #default="{ item }">
                    <v-flex pl-3 pr-3>
                        <component
                            :is="item.component"
                            v-bind="{
                                specificHeaders,
                                schemaDouble,
                                formData,
                                editable,
                                shareledgerId,
                                shareledgers: shareledgers.filter(
                                    shareledger => shareledger.id !== shareledgerId
                                ),
                                selectedShareType,
                                loading: localLoading,
                                shareledgerId: shareledgerId
                            }"
                            :class="item.class"
                        />
                        <v-row class="px-3">
                            <EqsCrudActionBtns
                                v-if="item.component === 'ShareledgerSpecification'"
                                :class="editable ? 'pt-5 mt-3' : ''"
                                :editable.sync="editable"
                                :has-changed.sync="hasChanged"
                                v-bind="{
                                    save,
                                    cancel: resetFormData,
                                    validForm,
                                    save,
                                    hasChanged
                                }"
                            />
                        </v-row>
                    </v-flex>
                </template>
            </EqsPanels>
        </v-form>
    </EqsCard>
</template>

<script>
import ShareledgerDetailsOwnership from "@/components/views/shareledger/dialogs/details/ShareledgerDetailsOwnership.vue";
import ShareledgerDetailsHistory from "@/components/views/shareledger/dialogs/details/ShareledgerDetailsHistory.vue";
import ShareledgerSpecification from "@/components/views/shareledger/capitalisation/digitalizeLedger/tokenizeYourShares/ShareledgerSpecification.vue";
import Movements from "@/components/views/shareledger/operations/movements/_Movements";

import {
    progressMixin,
    formErrorMixin,
    formChangedMixin
} from "@equisafe-ui-vue/src/mixins/formMixin";
import { getShareledgerFormMixin, formatShareledgerFormMixin } from "@/mixins/shareledgerMixin";
import capitalize from "lodash/capitalize";

export default {
    name: "ShareledgerDetailsDialog",
    components: {
        ShareledgerDetailsOwnership,
        ShareledgerDetailsHistory,
        ShareledgerSpecification,
        Movements
    },
    mixins: [
        progressMixin,
        formErrorMixin,
        formChangedMixin,
        getShareledgerFormMixin,
        formatShareledgerFormMixin
    ],
    props: {
        shareledgerId: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            localLoading: false,
            schemaDouble: {},
            formData: {},
            validForm: true,
            editable: false,
            hasChanged: false,
            selectedShareType: {},
            panels: [
                {
                    title: this.$t("shareledger.dialog.detail.detail"),
                    item: {
                        component: "ShareledgerSpecification",
                        props: {}
                    }
                },
                {
                    title: this.$t("shareledger.dialog.detail.ledger-history"),
                    item: {
                        component: "Movements"
                    }
                }
            ],
            specificHeaders: [
                {
                    text: capitalize(this.$t("movements.operation")),
                    filterable: false,
                    value: "operation"
                },
                {
                    text: capitalize(this.$t("movements.date")),
                    value: "date"
                },

                {
                    text: capitalize(this.$t("movements.quantity")),
                    value: "numberofshares"
                },
                {
                    text: capitalize(this.$t("movements.price")),
                    value: "pricepershare"
                },

                {
                    text: capitalize(this.$t("movements.from")),
                    value: "entity_source.name"
                },
                {
                    text: capitalize(this.$t("movements.to")),
                    value: "entity_destination.name"
                }
            ]
        };
    },
    watch: {
        shareledgerId: {
            immediate: true,
            async handler(val) {
                if (val) {
                    await this.populateShareledgerForm(val);
                    this.saveFormData();
                }
            }
        }
    },
    methods: {
        async save() {
            try {
                this.localLoading = true;
                await this.$api.updateShareledger(
                    this.shareledgerId,
                    this.formatShareledgerDetails(true)
                );
                this.$ui.success(this.$t("shareledger.dialog.detail.update-success"));
                this.saveFormData();
            } catch (e) {
                console.error({ e });
                this.handleError(e, "shareledger_detail_update");
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>
