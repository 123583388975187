var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsStepper',_vm._b({attrs:{"value":_vm.step,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":_vm.comeBackToOrgans},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{class:item.class},[_c(item.is,_vm._b({tag:"component"},'component',{
                    schemaDouble: _vm.schemaDouble,
                    formData: _vm.formData,
                    organ: _vm.organ,
                    loading:
                        _vm.loading ||
                        _vm.localLoading ||
                        _vm.shareledgersLoading ||
                        _vm.representativesLoading ||
                        _vm.investorsLoading,
                    edit: _vm.edit,
                    organId: _vm.organId,
                    shareledgers: _vm.shareledgersWithDetails,
                    gotLedgersSelection: _vm.gotLedgersSelection,
                    members: _vm.members,
                    saveNumberOfVotes: _vm.saveNumberOfVotes,
                    ...item
                },false))],1)]}}])},'EqsStepper',{ steps: _vm.steps, progress: _vm.progress, closeInfoTxt: _vm.closeInfoTxt },false))
}
var staticRenderFns = []

export { render, staticRenderFns }