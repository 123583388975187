<template>
    <div class="d-flex flex-column">
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                <EqsFileInput
                    :value.sync="formData.doc_bank"
                    v-bind="{
                        accept: '.pdf',
                        elClass: 'doc-bank',
                        label: $t('registration.rib'),
                        editable,
                        loading,
                        ...schemaDouble.doc_bank
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('half', formType)" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.holder"
                    v-bind="{
                        elClass: 'bank-holder',
                        placeholder: $t('user.wallet.bank.holder-placeholder'),
                        label: $t('user.wallet.bank.holder'),
                        editable,
                        loading,
                        ...schemaDouble.holder
                    }"
                />
            </v-col>
        </v-row>

        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.iban"
                    v-bind="{
                        elClass: 'bank-iban',
                        label: $t('user.wallet.bank.iban.label'),
                        placeholder: $t('user.wallet.bank.iban-placeholder'),
                        editable,
                        loading,
                        ...schemaDouble.iban
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.bic"
                    v-bind="{
                        elClass: 'bank-bic',
                        placeholder: $t('user.wallet.bank.bic-placeholder'),
                        label: $t('user.wallet.bank.bic'),
                        editable,
                        loading,
                        ...schemaDouble.bic
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.dom1"
                    v-bind="{
                        elClass: 'bank-dom1',
                        placeholder: $t('user.wallet.bank.dom1-placeholder'),
                        label: $t('user.wallet.bank.dom1'),
                        editable,
                        loading,
                        ...schemaDouble.dom1
                    }"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full', formType)" class="eqs-form-field">
                <EqsText
                    :value.sync="formData.dom2"
                    v-bind="{
                        elClass: 'bank-dom2',
                        placeholder: $t('user.wallet.bank.dom2-placeholder'),
                        label: $t('user.wallet.bank.dom2'),
                        editable,
                        loading,
                        ...schemaDouble.dom2
                    }"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "SepaForm",
    mixins: [formMixin]
};
</script>

<style lang="scss" scoped></style>
