<template>
    <v-app id="app" class="eqs-app" :style="getStyle">
        <v-expand-transition>
            <EqsNavigationDrawer
                v-if="getData.showMenu"
                :value.sync="navDrawer"
                :mini.sync="mini"
                v-bind="{
                    items: routes,
                    miniVariantWidth: miniWidth,
                    isMobile,
                    loading,
                    ...$attrs
                }"
                @close="() => (navDrawer = false)"
            />
        </v-expand-transition>
        <v-expand-x-transition>
            <EqsNavigationBar
                v-if="getData.showNavBar"
                v-bind="{
                    mini,
                    value: true,
                    isMobile,
                    notifications,
                    user,
                    userMenu,
                    navDrawer,
                    loading,
                    context,
                    loadingRoute,
                    loadingProfiles,
                    loadingNotifications,
                    showMenu: getData.showMenu === false ? false : true
                }"
                @open="() => (navDrawer = true)"
                @notificationsMenu="val => $emit('notifications', val)"
                @toggleChat="() => $emit('toggleChat')"
            >
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                    <slot :name="name" v-bind="slotData" />
                </template>
                <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            </EqsNavigationBar>
        </v-expand-x-transition>
        <EqsNotify />
        <EqsConfirmDialog />
        <slot name="modal-client" />
        <v-main>
            <div class="bg-blur-shape top-left" :style="cssVars" />
            <div class="bg-blur-shape bottom-right" :style="cssVars" />
            <v-container
                fluid
                :class="`${getData.container.class} eqs-app__container pa-0`"
                :style="`background-color:${$color.colors.backgroundColor}; overflow-x:hidden;`"
            >
                <div
                    :class="`d-flex eqs-app__container__content ${layout}`"
                    style="height: 100%; width: 100%"
                >
                    <slot />
                </div>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsApp.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import EqsNavigationDrawer from "../EqsNavigationDrawer/EqsNavigationDrawer.vue";
import EqsNavigationBar from "../EqsNavigationBar/EqsNavigationBar.vue";

export default {
    name: "EqsApp",
    components: {
        EqsNavigationDrawer,
        EqsNavigationBar,
        EqsNotify: () => import("../../molecules/EqsNotify/EqsNotify.vue"),
        EqsConfirmDialog: () => import("../../organisms/EqsConfirmDialog/EqsConfirmDialog.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        notifications: {
            type: Object,
            required: true
        },
        routes: {
            type: Array,
            required: true
        },
        user: {
            type: Object,
            default: null
        },
        userMenu: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingRoute: {
            type: Boolean,
            default: false
        },
        loadingProfiles: {
            type: Boolean,
            default: false
        },
        loadingNotifications: {
            type: Boolean,
            default: false
        },
        context: {
            type: String,
            default: ""
        },
        layout: {
            type: String,
            default: "justify-center align-start"
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            miniWidth: 76,
            mini: true,
            navDrawer: true
        };
    },
    computed: {
        isMobile() {
            return this.$reactive.isMobile();
        },
        cssVars() {
            return {
                "--bg-blur": `${this.$universe.themes()?.light?.bgBlur || "#d0d2ff"}`
            };
        }
    },
    watch: {
        "getData.showMenu": {
            immediate: true,
            handler(val) {
                if (!val) this.navDrawer = false;
            }
        },
        loading: {
            immediate: true,
            handler(val) {
                if (val) this.mini = true;
            }
        }
    }
};
</script>

<style lang="scss">
html::-webkit-scrollbar {
    display: none;
}

@import "../../../../../shared/styles/components/organisms/EqsApp.scss";
</style>
