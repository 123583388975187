import { isProd, isFormation, isLocal } from "@/assets/constants";

export class DevService {
    constructor() {}
    isProd() {
        return isProd();
    }
    isFormation() {
        return isFormation();
    }
    isLocal() {
        return isLocal();
    }
    isLocalEnv() {
        return this.isLocal();
    }
}
