<template>
    <div class="ma-0 pa-0">
        <div class="text-subtitle">
            {{ $t("projects.reporting.crud.youtube-link.link") }}
            <v-tooltip top color="info" style="max-width: 400px">
                <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" class="close-icon" color="info" size="1.66rem" v-on="on">
                        mdi-information
                    </v-icon>
                </template>
                <v-container fluid style="max-width: 400px">
                    <v-row dense no-gutters class="mb-2">
                        <v-col>
                            {{ $t("projects.reporting.crud.youtube-link.tooltip-tuto") }}
                        </v-col>
                    </v-row>
                    <v-row dense no-gutters>
                        <v-col>
                            <v-img
                                src="/images/projects/reporting_tuto_yt1.png"
                                contain
                                max-height="250"
                            />
                        </v-col>
                        <v-col>
                            <v-img
                                src="/images/projects/reporting_tuto_yt2.png"
                                contain
                                max-height="250"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-tooltip>
        </div>
        <v-text-field
            v-model="linkInput"
            :label="$t('projects.reporting.crud.youtube-link.link')"
            required
            :rules="localFieldYoutubeR"
        />
        <span
            >{{ $t("projects.reporting.crud.youtube-link.extracted-link") }} {{ extractedLink }}
        </span>
    </div>
</template>

<script>
import { fieldYoutubeLinkR } from "../utils";

export default {
    name: "FormYoutubeLink",
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fieldYoutubeLinkR,
            linkInput: null
        };
    },
    computed: {
        localFieldYoutubeR() {
            return [
                ...fieldYoutubeLinkR,
                value =>
                    this.extractedLink.startsWith("https://www.youtube") ||
                    this.$t("projects.reporting.crud.youtube-link.yt-link-required")
            ];
        },
        extractedLink() {
            if (!this.linkInput || this.linkInput.length === 0) {
                return "";
            }
            const urlRegex = /(https?:\/\/[^ "]*)/;
            const urlMatch = this.linkInput.match(urlRegex);
            if (!urlMatch) {
                return this.$t("projects.reporting.crud.youtube-link.invalid-input");
            }
            const url = urlMatch[1];
            if (
                !url.startsWith("https://www.youtube.com/") &&
                !url.startsWith("https://www.youtube-nocookie.com/")
            ) {
                return this.$t("projects.reporting.crud.youtube-link.yt-link-required");
            }
            return url.replace("https://www.youtube.com/", "https://www.youtube-nocookie.com/");
        }
    },
    watch: {
        linkInput: {
            handler: function (val) {
                Object.assign(this.field, { link: this.extractedLink });
            }
        }
    },
    beforeMount() {
        this.linkInput = this.field.link;
    }
};
</script>

<style lang="scss" scoped></style>
