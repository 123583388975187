<template>
    <v-container>
        <v-row align="end">
            <v-col :cols="4">
                <EqsTextDisplay :value="companyName" eqs-type="title" v-bind="{ loading }" />
            </v-col>

            <v-col v-for="stat in stats" :key="stat.text" :cols="2">
                <EqsTextDisplay :value="stat.text" />
            </v-col>
        </v-row>

        <v-row align="center">
            <v-col :cols="4">
                <v-row no-gutters>
                    <EqsTextDisplay
                        v-for="tag in tags"
                        :key="tag"
                        class="px-1"
                        eqs-type="foot"
                        :value="`#${tag}`"
                        v-bind="{ loading }"
                    />
                </v-row>
            </v-col>

            <v-col v-for="stat in stats" :key="stat.text" :cols="2">
                <EqsTextDisplay :value="stat.value" eqs-type="title" v-bind="{ loading }" />
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="12">
                <EqsTextDisplay value="Synthèse" eqs-type="subtitle" />
                <EqsTextDisplay class="mt-3" :value="description" paragraph v-bind="{ loading }" />
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="12">
                <EqsTextDisplay value="Les dirigeants" eqs-type="subtitle" class="mb-6" />

                <div v-if="!loading">
                    <v-row v-for="executive of executives" :key="executive.id">
                        <v-col :cols="2">
                            <EqsAvatar :user="executive" eqs-type="xl" no-badge />
                        </v-col>

                        <v-col :cols="8">
                            <EqsCard>
                                <v-container>
                                    <v-row>
                                        <v-col :cols="12">
                                            <v-row no-gutters>
                                                <EqsTextDisplay
                                                    :value="executive.name"
                                                    eqs-type="bodyBold"
                                                />
                                            </v-row>

                                            <v-row no-gutters>
                                                <EqsTextDisplay
                                                    :value="executive.role"
                                                    class="mr-3"
                                                />
                                                <EqsTextDisplay
                                                    :value="executive.location"
                                                    class="ml-3"
                                                />
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col :cols="5">
                                            <EqsTextDisplay value="top left" />
                                            <EqsTextDisplay value="bottom left" />
                                        </v-col>

                                        <v-col :cols="5">
                                            <EqsTextDisplay value="top right" />
                                            <EqsTextDisplay value="bottom right" />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </EqsCard>
                        </v-col>
                    </v-row>
                </div>

                <v-row v-else>
                    <v-col :cols="2">
                        <EqsAvatar eqs-type="xl" loading />
                    </v-col>

                    <v-col :cols="8">
                        <EqsCard loading />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "CompanyDetailsHeader",
    props: {
        companyName: {
            type: String,
            required: true
        },
        tags: {
            type: Array,
            required: true
        },
        stats: {
            required: true,
            validator: val =>
                Array.isArray(val) && val.length <= 4 && val.every(v => v?.text && v?.value)
        },
        description: {
            type: String,
            required: true
        },
        executives: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
