import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, organ) => {
    DEBUG("DELETE_ORGAN_START");
    try {
        commit("SET_ORGANS_LOADING", true);
        await window.app.$api.deleteOrgan(organ.id);
        commit("DELETE_ORGAN", organ.id);
        DEBUG("DELETE_ORGAN_SUCCESS");
    } catch (e) {
        DEBUG("DELETE_ORGAN_ERROR");
        throw e;
    } finally {
        commit("SET_ORGANS_LOADING", false);
    }
};
