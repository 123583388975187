var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"closable":"","persistent":""},on:{"close":() => (_vm.dialogProxy = false)},model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},'EqsDialog',{
        title: _vm.entityModel.name,
        attachToVApp: true,
        elClass: _vm.elClass,
        confirm: {
            fn: _vm.confirmAdd,
            txt: this.$t('molecule.form.activator-txt', {
                entity: _vm.entityModel.name
            })
        },
        cancel: {
            fn: () => {
                _vm.entityModel.entity = {};
            }
        },
        loading: _vm.loading,
        text: _vm.entityModel.text,
        progress: _vm.progress
    },false),[(_vm.entityModel)?_c('div',{staticClass:"d-flex flex-column"},[(_vm.error)?_c('EqsAlert',_vm._b({staticClass:"mb-3 mt-3 mx-4",attrs:{"el-class":`${_vm.accountType}-error`}},'EqsAlert',{ eqsType: 'error', text: _vm.$t('rules.invite-form-error') },false)):_vm._e(),_c(_vm.entityModel.is,_vm._b({tag:"component",attrs:{"editable":""}},'component',{
                formData: _vm.formData,
                loading: _vm.loading,
                schemaDouble: _vm.schemaDouble
            },false))],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }