var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsStepper',_vm._b({staticStyle:{"height":"100%"},attrs:{"value":_vm.step,"loading":_vm.loading || _vm.localLoading || _vm.shareledgersLoading || _vm.myProjectsLoading,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":_vm.comeBackToLastRoute},scopedSlots:_vm._u([{key:"default",fn:function({ item, loading: isLoading }){return [_c('div',{class:item.class},[_c(item.is,_vm._b({tag:"component",attrs:{"editable":"","value":_vm.formData.proceduredocument_set,"loading":isLoading},on:{"update:value":function($event){return _vm.$set(_vm.formData, "proceduredocument_set", $event)}}},'component',{
                    formData: _vm.formData,
                    capitalIncreaseId: _vm.capitalIncreaseId || _vm.capinc.id,
                    signature_procedure: _vm.signature_procedure,
                    shareledgers: _vm.shareledgers,
                    schemaDouble: _vm.schemaDouble,
                    deleteFunc: _vm.deleteProcedureDoc,
                    isRequired: false
                },false))],1)]}}])},'EqsStepper',{
        closeInfoTxt: _vm.closeInfoTxt,
        steps: _vm.steps,
        progress: _vm.progress
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }