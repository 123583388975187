<template>
    <EqsDialog
        v-model="valueProxy"
        closable
        v-bind="{
            title: $t('equisafe-ui.text-editor.internal-image-selector.title'),
            confirm: null,
            cancel: {}
        }"
    >
        <div class="d-flex flex column">
            <v-row class="px-3">
                <v-col class="eqs-form-field">
                    <EqsFileInput
                        :value.sync="img"
                        :auto-upload="false"
                        accept="image/*"
                        v-bind="{
                            sizeMax,
                            label: $t(
                                'equisafe-ui.text-editor.internal-image-selector.file-input-label'
                            )
                        }"
                        @update:value="addImage"
                    />
                </v-col>
            </v-row>
        </div>
    </EqsDialog>
</template>

<script>
import { MAX_SIZE_IMG } from "@/assets/constants";

export default {
    name: "InternalImageSelector",
    props: {
        value: {
            type: Boolean,
            required: true
        },
        editor: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            img: null,
            sizeMax: MAX_SIZE_IMG
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) this.img = null;
                this.$emit("update:value", val);
            }
        }
    },
    methods: {
        addImage() {
            let reader = new FileReader();
            let ctx = this;
            reader.onload = function () {
                let dataURL = reader.result;
                ctx.editor.chain().focus().setImage({ src: dataURL }).run();
            };
            reader.readAsDataURL(this.img);
            this.$nextTick(() => {
                this.img = null;
                this.valueProxy = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
