<template>
    <div class="ma-0 pa-0">
        <div class="eqs-card-project-details">
            <div class="d-flex flex-column align-start mb-8">
                <div class="document-title" :class="{ 'arthaud-font': $universe.isArthaud() }">
                    {{ $t("capital-increase.user.detail.project") }}
                </div>
                <div class="title-line" />
            </div>
            <GlobalTextEditorDisplay
                v-if="value.type === 'tiptap'"
                v-bind="{ value: value.value }"
            />
            <iframe
                v-else-if="value.type === 'iframe'"
                ref="iframeRef"
                frameborder="0"
                :height="iframeHeight"
                :src="capitalIncrease.notion_description"
                class="fill-width iframe-style"
            />
            <span v-else>{{ value.value }}</span>
        </div>
        <div class="eqs-card-project-details mt-4">
            <h1>{{ $t("capital-increase.user.detail.fundraising-info.section") }}</h1>
            <v-divider />
            <InvestmentTermRefont
                class="my-4 auto-height no-elevation"
                v-bind="{
                    capitalIncrease: capitalIncrease,
                    loading
                }"
            />
        </div>
        <OrderDetailRefont
            v-if="orders.length"
            v-bind="{ orders: orders, loading: loading, capitalIncrease }"
            class="my-4 no-elevation"
        />
        <!-- <LocationInformation /> -->
        <!-- <div class="eqs-card-project-details">
            <h1>{{ $t("capital-increase.user.detail.thecompany") }}</h1>
            <hr >
            <CompanyData />
        </div> -->
        <div />
    </div>
</template>

<script>
import GlobalTextEditorDisplay from "../../../../global/globalTextEditorDisplay/GlobalTextEditorDisplay.vue";
// import CompanyData from "./CompanyData.vue";
import InvestmentTermRefont from "./InvestmentTermRefont.vue";
// import LocationInformation from "./LocationInformation.vue";
import OrderDetailRefont from "./OrderDetailRefont.vue";

export default {
    name: "PresentationRefont",
    components: {
        GlobalTextEditorDisplay,
        InvestmentTermRefont,
        OrderDetailRefont
        // LocationInformation,
        // CompanyData
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        orders: {
            type: Array,
            required: true
        },
        capitalIncrease: {
            type: Object,
            required: true
        },
        restricted: {
            type: Boolean,
            required: true
        },
        userNotLoggedIn: {
            type: Boolean,
            required: true
        },
        failedChecks: {
            type: Array,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            data: "",
            iframeHeight: 300
        };
    },
    mounted() {
        this.iframeHeight = this.capitalIncrease.notion_size || 300;
    }
};
</script>

<style scoped lang="scss">
.arthaud-font {
    font-family: IvyPresto Display !important;
    font-size: 2.5rem !important;
}
.document-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 63px;
    letter-spacing: 0em;
    text-align: left;
}
.title-line {
    width: 70px;
    height: 2px;
    background-color: #b89c62;
    margin-top: 10px;
}
.iframe-style {
    padding: 0px !important;
    border-width: 0px !important;

    min-height: 400px;
}
</style>
