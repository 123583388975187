<template>
    <EqsCard
        class="fill-height"
        v-bind="{ height: '100%', loading: loading || loadingCreated }"
        v-on="$listeners"
    >
        <template #main>
            <EqsStepper
                :value.sync="step"
                closable
                v-bind="{
                    closeInfoTxt,
                    steps,
                    progress
                }"
                @close="comeBackToLastRoute"
            >
                <template #default="{ item }">
                    <div :class="item.class">
                        <component
                            :is="item.is"
                            :final-state="finalState"
                            :state="stepperState"
                            editable
                            :value.sync="formData.proceduredocument_set"
                            v-bind="{
                                formData,
                                order,
                                signature_procedure,
                                shareledgers,
                                sellerFees,
                                schemaDouble,
                                loading: localLoading,
                                isRequired: false
                            }"
                            @error="
                                e => {
                                    catchError();
                                }
                            "
                        />
                    </div>
                </template>
            </EqsStepper>
        </template>
    </EqsCard>
</template>

<script>
import isEmpty from "lodash/isEmpty";

import SharesForm from "./SharesForm";
import Finalize from "./Finalize";
import Success from "./Success";
import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";

import { procedureEditMixin } from "@/mixins/yousignMixin";

export default {
    name: "SellShares",
    components: {
        SharesForm,
        Finalize,
        Success
    },
    mixins: [stepperFormMixins, procedureEditMixin],
    props: {
        companyId: {
            type: String,
            required: true
        },
        sellOrderId: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            shareledgers: [],
            signature_procedure: {},
            sellerFees: 0,
            step: -1,
            stepperState: "success",
            formData: {},
            finalState: "success",
            schemaDouble: {},
            loadingCreated: false,
            localLoading: false,
            order: {},
            company: null
        };
    },
    computed: {
        closeInfoTxt() {
            return this.edit
                ? this.$t("secondary-market.sell-shares.come-back-to-dashboard")
                : this.$t("secondary-market.sell-shares.come-back-to-portfolio");
        },
        steps() {
            const schemaDoubleShareForm = {
                shareledger: {
                    dataType: "object",
                    isRequired: true
                },
                quantity: {
                    dataType: "int",
                    isRequired: true
                },
                min_quantity: {
                    dataType: "int",
                    isRequired: true
                },
                max_quantity: {
                    dataType: "int",
                    isRequired: true
                },
                price: {
                    isRequired: true
                },
                docs: {
                    isRequired: false,
                    dataType: "array"
                }
            };
            let steps = [
                {
                    title: this.$t("secondary-market.sell-shares.first-step", {
                        companyName: this.company ? this.company.name : ""
                    }),
                    item: {
                        is: "SharesForm"
                    },
                    back: {
                        fn: this.comeBackToLastRoute,
                        txt: this.closeInfoTxt
                    },
                    next: {
                        fn: this.order.id ? this.updateSellOrder : this.createSellOrder
                    },
                    schemaDouble: schemaDoubleShareForm
                },
                {
                    title: this.$t("sellShares.docstosign"),
                    item: {
                        is: "GlobalSignatureTemplate",
                        class: "margin-auto fill-height"
                    },
                    next: { fn: this.updateSellSharesProcedure },
                    schemaDouble: {
                        proceduredocument_set: {
                            dataType: "array",
                            isRequired: false
                        }
                    }
                },
                {
                    title: this.$t("secondary-market.sell-shares.last-step"),
                    item: {
                        is: "Finalize"
                    },
                    next: {
                        fn: this.sellShares,
                        txt: this.$t("sellShares.btn-sell")
                    },
                    schemaDouble: {}
                },
                {
                    title: this.$t("secondary-market.sell-shares.last-step-status"),
                    item: {
                        is: "Success"
                    },
                    noBack: true,
                    next: {
                        fn: this.redirection,
                        txt:
                            this.stepperState == "success"
                                ? this.$t("secondary-market.sell-shares.last-step-status-next")
                                : this.$t("secondary-market.sell-shares.last-step-error-next")
                    },
                    schemaDouble: {},
                    noBack: true
                }
            ];
            return steps;
        }
    },
    watch: {
        sellOrderId: {
            immediate: true,
            async handler(val) {
                this.loadingCreated = true;
                if (!!val) {
                    this.order = await this.getSecondaryMarketSellOrder(val);
                    if (this.order.procedure_template)
                        await this.getProcedure(
                            this.getProcedureIdFromURL(this.order.procedure_template)
                        );
                }
                this.checkEntityCanSign({
                    fn: this.comeBackToLastRoute,
                    txt: this.closeInfoTxt
                });
                this.shareledgers = this.$route.params.shareledgers;
                this.company = this.$route.params.companyData;
                const results = await Promise.allSettled(
                    !this.shareledgers || !this.company
                        ? [this.getShareledgerData(), this.getSellerFees(), this.getCompany()]
                        : [this.getSellerFees()]
                );
                results.forEach((res, index) => {
                    if (res.status === "rejected") {
                        console.error({ res });
                    }
                });
                this.step = this.$route.query.created ? 1 : this.getLastFormStep(this.order, 2);
                this.loadingCreated = false;
            }
        },
        step: {
            immediate: true,
            handler(step) {
                if (step < 0) return;
                this.schemaDouble = this.steps[step].schemaDouble;
                if (this.schemaDouble) {
                    this.populateFormData();
                    this.saveFormData();
                }
            }
        }
    },
    // async created() {

    // },
    methods: {
        populateFormData() {
            let data = {};
            this.buildFormData(this.schemaDouble, this.order);
            if (isEmpty(this.formData.shareledger)) data.shareledger = this.shareledgers[0];
            else if (!this.formData.shareledger.shareledgerid)
                data.shareledger = this.shareledgers.find(
                    shareledger => shareledger.shareledgerid === this.order.shareledger.id
                );
            Object.assign(this.formData, data);
        },
        async getSecondaryMarketSellOrder(id) {
            let res = {};
            try {
                res = await this.$api.getSecondaryMarketSellOrder(id);
            } catch (e) {
                //TODO handle error
                console.log({ e });
            } finally {
                return res.data || {};
            }
        },
        async updateSellSharesProcedure() {
            if (this.errorNoFieldsChanged) {
                this.step++;
                return;
            }
            this.localLoading = true;
            try {
                await this.updateProcedureDocs();
                this.step++;
            } catch (e) {
                this.handleError(e, "capital_increate_admin_update_procedure_boxes");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        redirection() {
            if (this.stepperState == "success") {
                this.$router.push({
                    name: "secondary-market",
                    params: { id: this.$entity.represented().id },
                    query: { tab: "sells" }
                });
            } else {
                this.$router.push({
                    name: "portfolio",
                    params: { id: this.$entity.represented().id }
                });
            }
        },
        async getCompany() {
            try {
                const res = await this.$api.getEntity(this.companyId);
                this.company = res.data;
            } catch (e) {
                throw e;
            }
        },
        async getShareledgerData() {
            try {
                const res = await this.$api.getBalancePortfolio({
                    entity_id: this.$entity.represented().id
                });

                const assets = res.data.find(
                    account => account.id == this.$route.params.companyId
                ).assets;

                this.shareledgers = assets.filter(asset => asset.shareledger.share_transfer);
            } catch (e) {
                console.error(e, "sell_shares_read_balance_list");
                this.$ui.error(e, "sell_shares_read_balance_list");
                throw e;
            }
        },
        async getSellerFees() {
            try {
                const res = await this.$api.getSellerFeesSecondaryMarket();
                this.sellerFees = res.data.seller_fees_rate;
            } catch (e) {
                console.error(e, "sell_shares_get_fees");
                this.$ui.error(e, "sell_shares_get_fees");
                throw e;
            }
        },
        comeBackToLastRoute() {
            this.$router.push({
                name: "portfolio",
                params: {
                    id: this.$entity.represented().id
                }
            });
        },
        formatSellOrderParams() {
            return {
                seller: this.$entity.represented().url,
                shareledger: this.formData.shareledger.shareledger.url,
                quantity: this.formData.quantity,
                max_quantity: this.formData.max_quantity,
                min_quantity: this.formData.min_quantity,
                price: this.formData.price,
                status: "Pending",
                docs: this.formData.docs
            };
        },
        async createSellOrder() {
            this.localLoading = true;
            try {
                const resOrder = await this.$api.createSecondaryMarketSellOrder(
                    this.formatSellOrderParams()
                );
                this.order = resOrder.data;
                // if (this.hasDocsToSign) {
                await this.$api.secondaryMarketSetupProcedureTemplate(this.order.id);
                // this.order = resStepTemplate.data;
                // await this.getProcedure(
                //   this.getProcedureIdFromURL(this.order.procedure_template)
                // );
                // }
                this.$router.replace({
                    name: "sell-shares-edit",
                    params: {
                        ...this.$route.$params,
                        sellOrderId: this.order.id
                    },
                    query: {
                        created: true
                    }
                });
            } catch (e) {
                console.error("Sell shares error: ", e);
                this.catchError();
            } finally {
                this.localLoading = false;
            }
        },
        // todo patchSecondaryMarketSellOrder doesnt support on backend update of data, only state
        async updateSellOrder() {
            this.localLoading = true;
            try {
                const resOrder = await this.$api.patchSecondaryMarketSellOrder(
                    this.order.id,
                    this.formatSellOrderParams()
                );
                this.order = resOrder.data;
                this.step++;
            } catch (e) {
                console.error("Sell shares error: ", e);
                this.catchError();
            } finally {
                this.localLoading = false;
            }
        },
        async sellShares() {
            this.localLoading = true;
            try {
                await this.$api.patchSecondaryMarketSellOrder(this.order.id, {
                    status: "Sent"
                });
                this.step++;
            } catch (e) {
                console.error(e);
                this.catchError();
            } finally {
                this.localLoading = false;
            }
        },
        catchError() {
            this.stepperState = "error";
            this.goToLastStep();
        },
        goToLastStep() {
            this.step = this.steps.length - 1;
        }
    }
};
</script>
