var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-main-container margin-auto"},[_c('v-container',{staticClass:"d-flex flex-column pa-0"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsCard',_vm._b({},'EqsCard',{
                        subtitle: _vm.$t('signStepper.buyerInfo.information-purchaser-subtitle'),
                        loading: _vm.loading
                    },false),[_c(_vm.schema.is,_vm._b({tag:"component",attrs:{"profile":"","editable":false}},'component',{
                            ..._vm.$props,
                            ..._vm.$attrs,
                            schemaDouble: _vm.schema.schemaDouble,
                            formData: {
                                ..._vm.buyer,
                                kyc: _vm.kyc
                            },
                            countries: []
                        },false))],1)],1),_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsCard',_vm._b({},'EqsCard',{
                        subtitle: _vm.$t('signStepper.buyerInfo.information-offer-subtitle'),
                        loading: _vm.loading
                    },false),[_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',_vm._b({attrs:{"value":_vm.order.shareledger.name,"editable":false}},'EqsText',{
                                        label: _vm.$t('label.shareledger'),
                                        loading: _vm.loading
                                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',_vm._b({attrs:{"value":_vm.share_types[_vm.order.shareledger.share_type].name,"editable":false}},'EqsText',{
                                        label: _vm.$t('sellShares.label.shareType'),
                                        loading: _vm.loading
                                    },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsNumber',_vm._b({attrs:{"is-currency":false,"is-float":false,"editable":false,"value":_vm.quantity}},'EqsNumber',{
                                        label: _vm.$t('buyStepper.step.pruchase-amount.quantity')
                                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsNumber',_vm._b({attrs:{"editable":false,"value":_vm.order.price,"currency-type":_vm.order.price_currency}},'EqsNumber',{
                                        label: _vm.$t(
                                            'buyStepper.step.pruchase-amount.share-price'
                                        ),
                                        loading: _vm.loading
                                    },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsNumber',_vm._b({attrs:{"editable":false,"value":_vm.total,"currency-type":_vm.order.price_currency}},'EqsNumber',{
                                        label: _vm.$t('buyStepper.step.pruchase-amount.total'),
                                        loading: _vm.loading,
                                        eqsSize: 'lg'
                                    },false))],1)],1)],1)])],1),(_vm.documents.length > 0)?_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsCard',_vm._b({},'EqsCard',{
                        subtitle: _vm.$t('signStepper.buyerInfo.uploaded-documents-subtitle'),
                        loading: _vm.loading
                    },false),[_c('EqsFileInput',{attrs:{"editable":false,"value":_vm.documents,"multiple":true}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }