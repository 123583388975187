<template>
    <v-slide-x-transition class="fill-width fill-height">
        <EqsStepper
            v-if="$route.name === 'registration'"
            :value.sync="step"
            v-bind="{ steps, progress, loading: localLoading }"
        >
            <template #default="{ item }">
                <div
                    :class="
                        item.is != 'GlobalYousignIFrame'
                            ? 'eqs-main-container margin-auto enforce-font'
                            : 'fill-height fill-width pa-0 enforce-font'
                    "
                >
                    <v-row class="px-3">
                        <v-col v-if="error || entity.lemonway_state === 'failure' || lemonwayError">
                            <EqsAlert
                                :el-class="`${entity.account_type}-error`"
                                v-bind="{
                                    eqsType: 'error',
                                    text: getErrorTxt,
                                    loading: localLoading || loading
                                }"
                            />
                        </v-col>
                    </v-row>
                    <component
                        :is="item.is"
                        v-if="item"
                        editable
                        retriable
                        v-bind="{
                            formData,
                            loading: localLoading || loading,
                            schemaDouble,
                            countries,
                            entity,
                            upload,
                            signer,
                            lemonwayError,
                            ...item
                        }"
                    />
                </div>
            </template>
        </EqsStepper>
        <router-view v-else v-bind="{ loading, verifyData }" />
    </v-slide-x-transition>
</template>

<script>
import { PRODUCTION_ENVIRONMENT } from "@/assets/constants";
import { accountTypeItem } from "@/services/entity";
import { procedureSignerMixin } from "@/mixins/yousignMixin";
import { LW_PROCESSING_TIMEOUT, LW_INTERVAL } from "@/assets/constants";
import {
    registrationSteps as individualSteps,
    registrationVerify as individualVerify,
    registrationStepError as individualError
} from "./schemas/individual";

import {
    registrationSteps as companySteps,
    registrationVerify as companyVerify,
    registrationStepError as companyError,
    CguSchemaDouble
} from "./schemas/company";

import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";
import { formKycMixin } from "@/mixins/kycMixin";

const verifyLwStatusPoller = "verify-entity-lw-status";

export default {
    name: "Registration",
    components: {
        ChooseAccountTypeForm: () => import("./neotrees/ChooseAccountTypeForm"),
        IndividualNeotreesForm: () => import("./neotrees/IndividualNeotreesForm"),
        CompanyNeotreesForm: () => import("./neotrees/CompanyNeotreesForm"),
        CgpDocsForm: () => import("./cgp/CgpDocsForm"),
        ProofOfAddressForm: () => import("./cgp/ProofOfAddressForm"),
        IndividualCgu: () => import("./individual/individualCgu/_IndividualCgu"),
        IndividualInfoForm: () => import("./individual/info/_Info.vue"),
        AboutYou: () => import("./individual/aboutYou/_AboutYou.vue"),
        IndividualPublicForm: () => import("./individual/info/PublicForm.vue"),
        IncapabilityDocsForm: () => import("./individual/incapabilityDocs/_IncapabilityDocs.vue"),
        IndividualDocsForm1: () => import("./individual/docs1/_Docs1.vue"),
        IndividualDocsForm2: () => import("./individual/docs2/_Docs2.vue"),
        IndividualErrorForm: () => import("./individual/error/_Error.vue"),
        CompanyInfoForm: () => import("./company/info/_Info.vue"),
        CompanyNonlistedDocs1: () => import("./company/docs1/CompanyNonlistedDocs1.vue"),
        CompanyNonlistedDocs2: () => import("./company/docs2/CompanyNonlistedDocs2.vue"),
        CompanyNonlistedDocs3: () => import("./company/docs3/CompanyNonlistedDocs3.vue"),
        CompanyCustodianDocs1: () => import("./company/docs1/CompanyCustodianDocs1.vue"),
        CompanyAssociationDocs1: () => import("./company/docs1/CompanyAssociationDocs1.vue"),
        CompanyNonlistedErrorForm: () => import("./company/error/CompanyNonlistedErrorForm.vue"),
        CompanyAssociationErrorForm: () =>
            import("./company/error/CompanyAssociationErrorForm.vue"),
        CompanyCustodianErrorForm: () => import("./company/error/CompanyCustodianErrorForm.vue")
    },
    mixins: [stepperFormMixins, formKycMixin, procedureSignerMixin],
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        loading: {
            type: Boolean,
            required: true
        },
        roles: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            proxyId: this.id,
            signatureId: "",
            formData: {},
            schemaDouble: {},
            loadingRegistration: false,
            lemonwayError: false,
            analyzed: false,
            countries: [],
            countryNameToNumCode: {},
            localLoading: false,
            validForm: false,
            schema: [],
            removeItem: [],
            step: -1,
            entity: {},
            kycTuto: "https://www.youtube.com/watch?v=gHbENdE93ZM",
            kybTuto: "https://www.youtube.com/watch?v=J8cVmD2YM38",
            progress: 0,
            registrationSchema: null,
            error: ""
        };
    },
    computed: {
        universe() {
            return this.$store.getters.universe.public_name;
        },
        universeMail() {
            return this.$store.getters.universe.contact_email;
        },
        currStep() {
            return !!this.steps && this.step >= 0 ? this.steps[this.step] : {};
        },
        getErrorTxt() {
            let error = this.$t("rules.registration-form-error");
            if (this.entity.lemonway_reason)
                error += `\n\n${this.$t("rules.lemonway-reason")}: ${this.entity.lemonway_reason}`;
            return error;
        },
        neotreesSteps() {
            const chooseAccountTypeStep = {
                title: this.$t("registration.account-type"),
                item: {
                    is: "ChooseAccountTypeForm"
                },
                next: {
                    fn: this.updateUser
                },
                noBack: true,
                schemaDouble: {
                    account_type: {
                        dataType: "string",
                        isRequired: true
                    },
                    is_company: {
                        dataType: "bool",
                        isRequired: false
                    }
                }
            };
            if (!this.entity.account_type) {
                return [chooseAccountTypeStep];
            } else {
                let neotreesSchemaDouble = {};
                if (this.entity.is_company) {
                    neotreesSchemaDouble = {
                        company_name: {
                            isRequired: true
                        },
                        legal_type: {
                            isRequired: true
                        },
                        company_idcode: {
                            isRequired: true
                        },
                        phone: {
                            isRequired: true
                        },
                        vat_number: {
                            isRequired: true
                        },
                        street: {
                            isRequired: true
                        },
                        zipcode: {
                            isRequired: true
                        },
                        city: {
                            isRequired: true
                        },
                        country: {
                            isRequired: true
                        }
                    };
                } else {
                    neotreesSchemaDouble = {
                        first_name: {
                            isRequired: true
                        },
                        last_name: {
                            isRequired: true
                        },
                        phone: {
                            isRequired: true
                        },
                        street: {
                            isRequired: true
                        },
                        zipcode: {
                            isRequired: true
                        },
                        city: {
                            isRequired: true
                        },
                        country: {
                            isRequired: true
                        }
                    };
                }
                const infosStep = {
                    title: this.$t("registration.general-info"),
                    item: {
                        is: this.entity.is_company
                            ? "CompanyNeotreesForm"
                            : "IndividualNeotreesForm"
                    },
                    next: {
                        fn: this.createNeotrees
                    },
                    schemaDouble: {
                        ...neotreesSchemaDouble
                    }
                };
                const accountFinalize = {
                    title: this.$t("registration.kyc.company.finalize-account"),
                    item: {
                        is: "IndividualCgu"
                    },
                    next: {
                        fn: async () => this.updateUser(this.validateRegistration)
                    },
                    schemaDouble: CguSchemaDouble(this)
                };

                return [chooseAccountTypeStep, infosStep, accountFinalize];
            }
        },
        steps() {
            if (this.$universe.isNeotrees()) {
                return this.neotreesSteps;
            } else {
                if (this.entity.is_company)
                    return this.lemonwayError ? companyError(this) : companySteps(this);
                return this.lemonwayError ? individualError(this) : individualSteps(this);
            }
        },
        verifyData() {
            return this.entity.is_company ? companyVerify(this) : individualVerify(this);
        }
    },
    watch: {
        "$route.name"(name) {
            switch (name) {
                case "registration":
                    this.analyzed = false;
                    this.initRegistration();
                    break;
            }
        },
        step: {
            immediate: true,
            handler(step) {
                if (step < 0) return;
                this.initStep(step);
            }
        },
        steps(val) {
            // if error is detected, steps are now one step and should redirect to step 0 instead of step + 1
            if (val.length === 1) {
                this.step = 0;
            }
        }
    },
    beforeDestroy() {
        this.$poll.stop(verifyLwStatusPoller);
    },
    beforeMount() {
        if (!this.id) {
            this.proxyId = this.$entity?.represented()?.id;
            this.$router.replace({
                params: {
                    id: this.proxyId
                }
            });
        }
    },
    async created() {
        this.entity = this.$entity.represented();
        if (this.$route.name === "registration") await this.initRegistration();
    },
    methods: {
        initStep(step) {
            this.schemaDouble = this.steps[step].schemaDouble;
            this.formData = {};
            if (this.schemaDouble) this.buildFormData(this.schemaDouble);
        },
        async createNeotrees() {
            this.localLoading = true;
            let data = this.formatFormData(this.formData);
            if (!!data.company_idcode) {
                data.company_idcode = data.company_idcode.replace(/\s/g, "");
                data.nationality = data.country;
            }
            try {
                const res = await this.$api.createNeotrees(this.entity.id, data);
                this.entity = res.data;

                this.step++;
                this.error = false;
                this.lemonwayError = false;
            } catch (e) {
                this.handleError(e, "entity_update_individual");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async updateUser(cb) {
            // if (this.errorNoFieldsChanged) {
            //     this.step++;
            //     return;
            // }
            this.localLoading = true;
            let data = this.formatFormData(this.formData);
            if (!!data.company_idcode) {
                data.company_idcode = data.company_idcode.replace(/\s/g, "");
                data.nationality = data.country;
            }
            if (this.entity.is_company) {
                const myEntity = this.$store.getters.myEntity;
                data = {
                    ...data,
                    contact_name: myEntity.name,
                    contact_phone: myEntity.phone,
                    contact_email: myEntity.email
                };
            }
            try {
                const entity = await this.$entity.updateEntity(data, false);
                this.entity = entity;

                if (cb) await cb();
                else this.step++;
                this.error = false;
                this.lemonwayError = false;
            } catch (e) {
                this.handleError(e, "entity_update_individual");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async updateUserBeforeDirectInvestKyoseil() {
            this.localLoading = true;
            let data = this.formatFormData(this.formData);
            try {
                const entity = await this.$api.startKycKyoseilDirectInvestSignature(
                    this.entity.id,
                    data
                );
                this.entity = entity.data;
                this.signatureId = this.entity?.direct_invest_kyoseil_procedure?.id;
                if (this.signatureId) {
                    if (!PRODUCTION_ENVIRONMENT) {
                        await this.fetchProcedureLocal(this.signatureId);
                    } else {
                        await this.fetchProcedure(this.signatureId);
                    }
                } else {
                    this.$ui.error("Erreur lors de la génération de la signature");
                    throw "error";
                }
                this.step++;
                this.error = false;
                this.lemonwayError = false;
            } catch (e) {
                this.handleError(e, "entity_update_individual");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async getUpdatedUser() {
            try {
                this.step++;
            } catch (e) {
                this.$ui.error(e, "entity_update_individual");
            }
        },
        async fetchSignatureState() {
            try {
                if (this.signatureId) {
                    if (!PRODUCTION_ENVIRONMENT) {
                        await this.fetchProcedureLocal(this.signatureId);
                    } else {
                        await this.fetchProcedure(this.signatureId);
                    }
                }
                await this.$nextTick();
                if (!this.signatureDone) {
                    this.$ui.warning(this.$t("user.buy.signature-pending"));
                }
            } catch (e) {
                this.$ui.error(e, "entity_update_individual");
            }
        },
        async updateUserCgp() {
            this.localLoading = true;
            let data = this.formatFormData(this.formData);
            try {
                const entity = await this.$api.updateEntityCgp(this.entity.id, data);
                this.entity = entity.data;
                if (this.$universe.isKyoseil()) {
                    this.signatureId = this.entity?.cgp_convention_procedure?.id;
                    if (this.signatureId) {
                        if (!PRODUCTION_ENVIRONMENT) {
                            await this.fetchProcedureLocal(this.signatureId);
                        } else {
                            await this.fetchProcedure(this.signatureId);
                        }
                    } else {
                        this.$ui.error("Erreur lors de la génération de la signature");
                        throw "error";
                    }
                }
                this.step++;
                this.error = false;
                this.lemonwayError = false;
            } catch (e) {
                this.handleError(e, "entity_update_individual");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        analyzeSteps() {
            if (!this.analyzed) {
                this.step =
                    this.$entity.represented().lemonway_state === "failure"
                        ? 0
                        : this.getRegistrationStepN();
            }
            if (this.$universe.isNeotrees() && this.step < 2) {
                this.step = 0;
            }
            this.analyzed = true;
            if (this.lemonwayError) this.step = 0;
            return null;
        },
        async upload(docFormData, file) {
            const res = await this.$api.postDocument(docFormData);
            let data = {};
            data[`${file.key}_id`] = res.data.id;
            this.$api.updateEntity(this.$entity.represented().id, data);
            this.entity = this.$entity.represented();
            return res;
        },
        kybDirect(entity) {
            this.$dialog.confirm({
                title: this.$t("registration.kycDirectDialog.title"),
                text: this.$t("registration.kycDirectDialog.message", {
                    companyName: entity.name
                }),
                confirm: {
                    fn: async () => {
                        await this.$role.updateMyCurrentRole(entity.id);
                        this.$router.push({ name: "setup-company" });
                    },
                    txt: this.$t("registration.kycDirectDialog.confirm-txt", {
                        companyName: entity.name
                    })
                },
                cancel: {
                    txt: this.$t("registration.kycDirectDialog.cancel-txt")
                }
            });
        },
        getRegistrationStepN() {
            let stepN = this.getLastFormStep({
                ...this.entity,
                signature_done: ["finished", "done"].includes(
                    this.entity?.cgp_convention_procedure?.status ||
                        this.entity?.direct_invest_kyoseil_procedure?.id
                )
            });
            // required to check additional doc when asked by LW
            if (this.entity.doc_address) this.isLWError(this.entity.doc_address);
            if (this.$universe.isNeotrees() && stepN < 2) {
                stepN = 0;
            }
            return stepN;
        },
        async getCountries() {
            try {
                const res = await this.$api.getCountries();
                this.countries = res.data;
            } catch (e) {
                throw e;
            }
        },
        async initRegistration() {
            try {
                this.localLoading = true;
                await this.getCountries();
                this.analyzeSteps(this.steps);
                if (
                    !this.step &&
                    this.entity.lemonway_state !== "failure" &&
                    !this.lemonwayError &&
                    !this.$universe.isNeotrees()
                ) {
                    this.entityDialog();
                }
                this.signatureId =
                    this.entity?.cgp_convention_procedure?.id ||
                    this.entity?.direct_invest_kyoseil_procedure?.id;
            } catch (e) {
                console.error({ e });
            } finally {
                this.localLoading = false;
            }
        },
        entityDialog() {
            const data = accountTypeItem(this.entity.account_type, this);
            this.$dialog.info({
                ...data.registration,
                confirm: {
                    txt: this.$t("btn.next")
                }
            });
        },
        async verifyWallet() {
            try {
                const res = await this.$api.verifyWallet(this.proxyId);
                const { lemonway_state, lemonway_reason } = res.data;
                if (lemonway_state === "failure" || lemonway_reason) throw { status: 500 };
                await this.$poll.asyncCall(
                    verifyLwStatusPoller,
                    this.$api.getEntity,
                    this.proxyId,
                    res => this.verifyEntityWalletStatus(res.data),
                    LW_INTERVAL,
                    LW_PROCESSING_TIMEOUT
                );
            } catch (e) {
                console.error({ e });
                throw e;
            }
        },
        verifyEntityWalletStatus(entity) {
            if (!entity) return false;
            else if (["upload", "upload-failure"].includes(entity.lemonway_state)) return true;
            // here shouldn't arrive to that state but just in case \
            // redirect to verif or profile and avoid infinit polling
            else if (["pending", "success"].includes(entity.lemonway_state)) {
                this.routeToGo();
                return true;
            }
            return false;
        },
        routeToGo() {
            if (
                ["pending", "success", "upload-failure"].includes(
                    this.$entity.represented().lemonway_state
                )
            ) {
                this.$router.push({ name: "verify" });
            }
        },
        async createLWWallet() {
            try {
                // On relance le KYC uniquement si pas deja lancé
                if (
                    !["pending", "success", "upload", "upload-failure"].includes(
                        this.$entity.represented().lemonway_state
                    )
                ) {
                    await this.verifyWallet();
                }
                await this.$role.updateMyCurrentRole(this.entity.id);
                this.step++;
                this.formData = {};
            } catch (e) {
                if (e.status >= 500)
                    this.$ui.error(
                        this.$t("ui-error.cannot-access-next-step-wallet", {
                            universeMail: this.universeMail
                        })
                    );
                this.$set(this, "step", 0);
                await this.$role.updateMyCurrentRole(this.entity.id);
                this.buildFormData(this.schemaDouble);
                return;
            }
        },
        async validateRegistration() {
            try {
                await this.$api.verifyEntity(this.entity.id);
                await this.$role.updateMyCurrentRole(this.entity.id);
                this.$router.push({ name: "verify" });
            } catch (e) {
                await this.$role.updateMyCurrentRole(this.entity.id);
                this.routeToGo();
                this.$ui.error(
                    this.$t("ui-error.cannot-access-registration-verification", {
                        universeMail: this.universeMail
                    })
                );
                this.$set(this, "step", this.steps.length - 1);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.form-container {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
</style>
