var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._g(_vm._b({staticClass:"fill-height",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('EqsStepper',_vm._b({staticStyle:{"height":"100%"},attrs:{"value":_vm.step,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":_vm.backToSignatures},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c(item.is,_vm._b({tag:"component",attrs:{"retriable":""},on:{"signatureRefused":_vm.signatureRefused,"error":e => {
                            _vm.catchError();
                        }}},'component',{
                        stepperState: _vm.stepperState,
                        formData: _vm.formData,
                        schemaDouble: _vm.schemaDouble,
                        signer: _vm.signer
                    },false))]}}])},'EqsStepper',{
                steps: _vm.steps,
                closeInfoTxt: _vm.closeInfoTxt
            },false))]},proxy:true}])},'EqsCard',{ height: '100%', loading: _vm.loading || _vm.proceduresLoading },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }