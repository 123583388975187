import { render, staticRenderFns } from "./CryptoRecapTable.vue?vue&type=template&id=167ef3c7&scoped=true"
import script from "./CryptoRecapTable.vue?vue&type=script&lang=js"
export * from "./CryptoRecapTable.vue?vue&type=script&lang=js"
import style0 from "./CryptoRecapTable.vue?vue&type=style&index=0&id=167ef3c7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "167ef3c7",
  null
  
)

export default component.exports