import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, investor) => {
    DEBUG("CREATE_INVESTOR_START");
    try {
        commit("SET_INVESTORS_LOADING", true);
        const investorRes = await window.app.$api.createInvestor(investor);
        DEBUG("CREATE_INVESTOR_SUCCESS");
        commit("ADD_INVESTOR", investorRes.data);
        return investorRes.data;
    } catch (e) {
        DEBUG("CREATE_INVESTOR_ERROR");
        throw e;
    } finally {
        commit("SET_INVESTORS_LOADING", false);
    }
};
