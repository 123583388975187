<template>
    <div class="eqs-phone" :el-class="elClass">
        <EqsText v-bind="$attrs" :basic-rules="$options.rules" v-on="$listeners" />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsPhone.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { phoneR, max100R } from "../../../rules";

export default {
    name: "EqsPhone",
    components: {
        EqsText: () => import("../EqsText/EqsText.vue")
    },
    rules: [phoneR, max100R],
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-phone"
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsPhone.scss";
</style>
