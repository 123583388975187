<template>
    <div class="eqs-text-hover-tooltip d-flex" :el-class="elClass" :style="getStyle">
        <v-tooltip
            :open-on-hover="value"
            :open-on-focus="value"
            top
            v-bind="{ ...$attrs, ...getData }"
            max-width="18rem"
        >
            <template v-slot:activator="{ on }">
                <div class="d-inline-block text-truncate eqs-text-hover-tooltip__text" v-on="on">
                    {{ text }}
                </div>
            </template>
            {{ text }}
        </v-tooltip>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsTextHoverTooltip.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsTextHoverTooltip",
    mixins: [eqsUITemplate],
    props: {
        value: {
            type: Boolean,
            default: true
        },
        elClass: {
            type: String,
            default: "eqs-text-hover-tooltip"
        },
        text: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsTextHoverTooltip.scss";
</style>
