import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            footer: {
                select: {
                    margin: getVal(currentSize, {
                        mobile: "0.5rem 0 0.5rem 1rem",
                        tablet: "0.8rem 0 0.8rem 1.5rem",
                        desktop: "1rem 0 1rem 2rem"
                    })
                },
                pagination: {
                    margin: getVal(currentSize, {
                        mobile: "0 1rem 0 0.5rem",
                        tablet: "0 1.5rem 0 1rem",
                        desktop: "0 2rem 0 1.5rem"
                    })
                },
                icons: {
                    size: getVal(currentSize, {
                        mobile: "1rem",
                        tablet: "1.5rem",
                        desktop: "2rem"
                    })
                }
            }
        }
    };
};

export const dataTypes = (currentSize = {}, ctx = {}) => {
    return {
        default: {}
    };
};
