var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCrud',_vm._b({attrs:{"items":_vm.docsProxy},on:{"update:items":function($event){_vm.docsProxy=$event}},scopedSlots:_vm._u([{key:"group-tile",fn:function({ item }){return [_c('v-row',{attrs:{"justify":"start","align-content":"start"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{ value: item.document.name },false))],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"auto"}},[_c('EqsTextDisplay',_vm._b({staticClass:"text--secondary"},'EqsTextDisplay',{ value: _vm.getTrad(item) },false))],1)],1)]}},{key:"edit",fn:function(editProps){return [_c('InternalDocForm',_vm._b({},'InternalDocForm',{ ...editProps, categories: _vm.categories, elClass: _vm.elClass },false))]}},{key:"create",fn:function(createProps){return [_c('InternalDocForm',_vm._b({attrs:{"editable":""}},'InternalDocForm',{ ...createProps, categories: _vm.categories, elClass: _vm.elClass },false))]}}])},'EqsCrud',{
        ..._vm.$attrs,
        add: _vm.addDoc,
        update: _vm.updateDoc,
        deleteFunc: _vm.removeDoc,
        formInit: _vm.newDoc,
        loading: _vm.loading,
        elClass: _vm.elClass
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }