import CompanyDetails from "@/components/views/portfolio_v2/company/CompanyDetails";
import Portfolio from "@/components/views/portfolio_v2/_Portfolio";

import OldPortfolio from "@/components/views/portfolio/_Portfolio";

import multiguard from "vue-router-multiguard";
import { informUserIfNotRegistered } from "../routeGuards";
import { i18n } from "@/services/plugins/i18n.js";

export default [
    {
        name: "portfolio",
        path: "/portfolio/:id",
        component: OldPortfolio,
        props: true,
        meta: {
            contextFn: () => i18n.t("app.navigation-drawer.title.portfolio")
        }
    }

    // {
    //     beforeEnter: multiguard([informUserIfNotRegistered]),
    //     name: "portfolio-v2",
    //     path: "/portfolio-v2/:id",
    //     component: Portfolio,
    //     props: true,
    //     meta: {
    //         contextFn: () => i18n.t("app.navigation-drawer.title.portfolio")
    //     }
    // },
    // {
    //     beforeEnter: multiguard([informUserIfNotRegistered]),
    //     name: "company-details",
    //     path: "/portfolio-v2/company/:id",
    //     component: CompanyDetails,
    //     props: true,
    //     meta: {
    //         contextFn: () => i18n.t("app.navigation-drawer.title.portfolio")
    //     }
    // }
];
