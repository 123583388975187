import DEBUG from "@/store/debug.js";

export default async ({ state, dispatch }, data) => {
    DEBUG("GET_PROJECT_START");
    if (!data.projectId) throw new Error("no projectId");
    let project = state.projects.find(_project => _project.id === data.projectId);
    // Check si on a toutes les données ou juste un tiny
    if (!project?.shareledger) {
        try {
            project = await dispatch("addProject", data);
            DEBUG("GET_PROJECT_SUCCESS");
        } catch (e) {
            DEBUG("GET_PROJECT_ERROR");
            throw e;
        }
    }
    return project;
};
