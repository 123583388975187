<template>
    <div>
        <v-row>
            <v-col :cols="12">
                <EqsPie :config="pieConfig" v-bind="{ loading }" />
            </v-col>
        </v-row>

        <v-row>
            <v-col :cols="12">
                <EqsTextDisplay value="Historique de financement" eqs-type="subtitle" />
            </v-col>
        </v-row>

        <v-row class="mt-3" no-gutters>
            <v-col :cols="3">
                <EqsTextDisplay value="Date de closing" eqs-type="bodyBold" />
            </v-col>

            <v-col :cols="2">
                <EqsTextDisplay value="Financement" eqs-type="bodyBold" />
            </v-col>

            <v-col :cols="3">
                <EqsTextDisplay value="Prix par share" eqs-type="bodyBold" />
            </v-col>

            <v-col :cols="2">
                <EqsTextDisplay value="Equity" eqs-type="bodyBold" />
            </v-col>

            <v-col :cols="2">
                <EqsTextDisplay value="Investisseurs" eqs-type="bodyBold" />
            </v-col>
        </v-row>

        <v-divider class="my-1" />

        <div v-if="!loading">
            <v-row v-for="item in table" :key="item.date || 'total'" no-gutters>
                <v-col :cols="3">
                    <EqsTextDisplay v-if="item.total" value="Total" eqs-type="bodyBold" />
                    <EqsLabel
                        v-else
                        style="width: fit-content"
                        :editable="false"
                        :value="item.date"
                        :info-txt="item.dateInfo"
                    />
                </v-col>

                <v-col :cols="2">
                    <EqsTextDisplay :value="item.raised" eqs-type="bodyBold" />
                </v-col>

                <v-col :cols="3">
                    <EqsTextDisplay
                        :value="$currency.displayWithCurrency(item.share_price)"
                        eqs-type="bodyBold"
                    />
                </v-col>

                <v-col :cols="2">
                    <EqsTextDisplay :value="$currency.percent(item.equity)" eqs-type="bodyBold" />
                </v-col>

                <v-col :cols="2">
                    <EqsTextDisplay :value="item.investors.toString()" eqs-type="bodyBold" />
                </v-col>
            </v-row>
        </div>

        <v-row v-else no-gutters>
            <v-col :cols="3">
                <EqsTextDisplay loading />
            </v-col>

            <v-col :cols="2">
                <EqsTextDisplay loading />
            </v-col>

            <v-col :cols="3">
                <EqsTextDisplay loading />
            </v-col>

            <v-col :cols="2">
                <EqsTextDisplay loading />
            </v-col>

            <v-col :cols="2">
                <EqsTextDisplay loading />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "CompanyDetailsFinancingHistory",
    props: {
        pie: {
            type: Array,
            required: true
        },
        table: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        pieConfig() {
            return {
                labels: this.pie.map(({ label }) => label),
                data: this.pie.map(({ value }) => value)
            };
        }
    }
};
</script>

<style lang="scss" scope></style>
