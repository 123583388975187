<template>
    <div class="eqs-number">
        <EqsText
            v-model="displayValue"
            v-bind="{
                ...$attrs,
                ...getData,
                editable,
                disabled,
                readonly
            }"
            :type="isFocused ? 'number' : 'text'"
            @blur="isFocused = false"
            @focus="isFocused = true"
            v-on="$listeners"
        >
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
        </EqsText>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsNumber.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsNumber",
    components: {
        EqsText: () => import("../EqsText/EqsText.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        value: {
            validator: prop =>
                typeof prop === "string" ||
                typeof prop === "number" ||
                prop === null ||
                prop === undefined,
            required: true
        },
        isCurrency: {
            type: Boolean,
            default: true
        },
        currencyType: {
            type: String,
            default: "EUR"
        },
        isPercentage: {
            type: Boolean,
            default: false
        },
        isFloat: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            isFocused: false
        };
    },
    computed: {
        displayValue: {
            get() {
                if (this.isFocused && !this.readonly && !this.disabled && this.editable) {
                    return this.value ? this.value.toString() : "";
                } else if (!this.value && this.value !== 0) {
                    return "";
                } else {
                    const toFormat = `${this.isCurrency ? "$" : ""}${
                        this.isFloat ? "0,0.00" : "0,0"
                    }`;
                    // const val = this.$currency.display(this.value, toFormat);
                    const val = this.$currency.displayWithCurrency(
                        this.value,
                        this.currencyType,
                        toFormat
                    );
                    return !!val ? `${val} ${this.isPercentage ? "%" : ""}` : "";
                }
            },
            set(modifiedValue) {
                let newValue = "";
                if (modifiedValue) {
                    const converted = this.$currency.getValue(modifiedValue);
                    newValue = this.isFloat
                        ? parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
                        : parseInt(converted);
                    if (
                        this.isCurrency &&
                        newValue.toString().includes(".") &&
                        newValue.toString().split(".")[1].length > 1
                    )
                        newValue = newValue.toFixed(2);
                }
                this.$emit(
                    "update:value",
                    this.isFloat ? parseFloat(newValue) : parseInt(newValue)
                );
            }
        }
    },
    methods: {
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (!evt.which || !charCode) evt.preventDefault();
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsNumber.scss";
</style>
