import { render, staticRenderFns } from "./_AddInvestorStepper.vue?vue&type=template&id=ee8a2ea6&scoped=true"
import script from "./_AddInvestorStepper.vue?vue&type=script&lang=js"
export * from "./_AddInvestorStepper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee8a2ea6",
  null
  
)

export default component.exports