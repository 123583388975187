import { APIService } from "@/services/api.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, getters }) => {
    DEBUG("GET_MY_ENTITY_START");
    // exception, reinstantiate class instead of window.app.$api
    // because endpoint is not rightly set, window.location not ready when called
    const api = new APIService();
    try {
        const res = await api.me();
        DEBUG("GET_MY_ENTITY_SUCCESS");
        if (res.headers["through-roles"]) {
            let responseJson = null;
            let header = res.headers["through-roles"];
            header = header.replace(/'/g, '"');
            try {
                responseJson = JSON.parse(header);
            } catch (e) {
                console.error("ROLE - error parse json through: ", e);
                throw e;
            }
            if (responseJson) {
                if (responseJson.length > 0) {
                    // fetch cgp role
                    let currentRoleId = res.headers["current-role-id"];
                    const response = await api.getRole(currentRoleId);
                    commit("ADD_CGP_ROLES", response.data);
                    // Il n'y a qu'un seul through
                    commit("SET_THROUGH_ROLES", {
                        throughRole: responseJson[0],
                        currentRole: currentRoleId
                    });
                }
            }
        }
        commit("SET_MY_ENTITY", res.data);
        // todo activate when public channel ready
        // await window.app.$webSocket.initPublicSocket()
        return res.data;
    } catch (e) {
        commit("SET_IS_AUTHENTICATED", false);
        commit("RESET_STORE_ENTITIES");
        // means that there is no session for user with auto login
        if (!e || e.status === 403) {
            const lastRoute = getters.lastRoute;
            // record failed route that happened because was not logged in.
            if (lastRoute && lastRoute !== "/" && !lastRoute.includes("/auth"))
                commit("setFailedRoute", lastRoute);
            throw e;
        } else {
            DEBUG("GET_MY_ENTITY_ERROR");
            window.app.$ui.error(e, "get_my_entity");
        }
    }
};
