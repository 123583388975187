import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, decision) => {
    DEBUG("CREATE_DECISION_START");
    try {
        commit("SET_DECISIONS_LOADING", true);
        const decisionRes = await window.app.$api.createDecision(decision);
        DEBUG("CREATE_DECISION_SUCCESS");
        commit("ADD_DECISION", decisionRes.data);
        return decisionRes.data;
    } catch (e) {
        DEBUG("CREATE_DECISION_ERROR");
        throw e;
    } finally {
        commit("SET_DECISIONS_LOADING", false);
    }
};
