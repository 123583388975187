import getDecisions from "./actions/get_decisions";
import getDecision from "./actions/get_decision";
import fetchDecision from "./actions/fetch_decision";
import deleteDecision from "./actions/delete_decision";
import createDecision from "./actions/create_decision";
import updateDecision from "./actions/update_decision";

import getRepresentatives from "./actions/get_representatives";
import getRepresentative from "./actions/get_representative";
import deleteRepresentative from "./actions/delete_representative";
import createRepresentative from "./actions/create_representative";
import updateRepresentative from "./actions/update_representative";

import getOrgans from "./actions/get_organs";
import getOrgan from "./actions/get_organ";
import deleteOrgan from "./actions/delete_organ";
import createOrgan from "./actions/create_organ";
import updateOrgan from "./actions/update_organ";

export default {
    getDecision,
    fetchDecision,
    getDecisions,
    deleteDecision,
    createDecision,
    updateDecision,
    getRepresentatives,
    getRepresentative,
    deleteRepresentative,
    createRepresentative,
    updateRepresentative,
    getOrgans,
    getOrgan,
    deleteOrgan,
    createOrgan,
    updateOrgan
};
