import init from "./init";

export default {
    // DECISIONS
    SET_DECISIONS(state, decisions) {
        state.decisions = decisions;
        state.decisionsInit = false;
    },
    ADD_DECISION(state, decision) {
        state.decisions = [decision, ...state.decisions];
    },
    UPDATE_DECISION(state, decision) {
        const index = state.decisions.findIndex(_decision => _decision.id === decision.id);
        Object.assign(state.decisions[index], decision);
    },
    DELETE_DECISION(state, decisionId) {
        const index = state.decisions.findIndex(decision => decision.id === decisionId);
        state.decisions.splice(index, 1);
    },
    SET_DECISIONS_LOADING(state, bool) {
        state.decisionsLoading = bool;
    },
    // REPRESENTATIVES
    SET_REPRESENTATIVES(state, representatives) {
        state.representatives = representatives;
        state.representativesInit = false;
    },
    ADD_REPRESENTATIVE(state, representative) {
        state.representatives = [representative, ...state.representatives];
    },
    UPDATE_REPRESENTATIVE(state, representative) {
        const index = state.representatives.findIndex(
            _representative => _representative.id === representative.id
        );
        Object.assign(state.representatives[index], representative);
    },
    DELETE_REPRESENTATIVE(state, representativeId) {
        const index = state.representatives.findIndex(
            _representative => _representative.id === representativeId
        );
        state.representatives.splice(index, 1);
    },
    SET_REPRESENTATIVES_LOADING(state, bool) {
        state.representativesLoading = bool;
    },
    // ORGANS
    SET_ORGANS(state, organs) {
        state.organs = organs;
        state.organsInit = false;
    },
    ADD_ORGAN(state, organ) {
        state.organs = [organ, ...state.organs];
    },
    UPDATE_ORGAN(state, organ) {
        // console.log({ organ, organs: state.organs });
        const index = state.organs.findIndex(_organ => _organ.id === organ.id);
        Object.assign(state.organs[index], organ);
    },
    DELETE_ORGAN(state, organId) {
        const index = state.organs.findIndex(organ => organ.id === organId);
        state.organs.splice(index, 1);
    },
    SET_ORGANS_LOADING(state, bool) {
        state.organsLoading = bool;
    },
    //
    RESET_STORE_GOVERNANCE(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
