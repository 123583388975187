<template>
    <v-container fluid>
        <v-row align="start" justify="space-between">
            <v-col md="7" cols="12">
                <v-row v-if="showTaxArrangement">
                    <v-col>
                        <p class="text-h4 mb-8">{{ $t("user.buy.steps.tax-arrangement.title") }}</p>
                        <p>
                            <v-radio-group
                                v-model="selectTaxArrangement"
                                :rules="ruleTaxArrangement"
                                class="options-radio"
                                @change="selectTaxArrangementEvent"
                            >
                                <v-radio
                                    v-for="n in taxArrangementList"
                                    :key="n.value"
                                    :label="n.label"
                                    :value="n.value"
                                >
                                    <template #label>
                                        <div>
                                            <span class="font-weight-bold">
                                                {{ getShortTaxLabelFromId(this, n.value) }}
                                            </span>
                                            <span v-if="n.value !== 'none'"> : {{ n.label }} </span>
                                        </div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </p>
                    </v-col>
                </v-row>
                <v-row v-if="showEnvelope">
                    <v-col>
                        <p class="text-h4 mb-8">{{ $t("user.buy.steps.envelope.title") }}</p>
                        <p>
                            <v-radio-group
                                v-model="selectEnvelope"
                                :rules="ruleEnvelope"
                                class="options-radio"
                                @change="selectEnvelopeEvent"
                            >
                                <v-radio
                                    v-for="n in envelopeList"
                                    :key="n.value"
                                    :label="n.label"
                                    :value="n.value"
                                >
                                    <template #label>
                                        <div>
                                            <span class="font-weight-bold">
                                                {{ getShortEnvelopeLabelFromId(this, n.value) }}
                                            </span>
                                            <span> : {{ n.label }} </span>
                                        </div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </p>
                    </v-col>
                </v-row>
                <v-input v-model="formData._local_valid" :rules="ruleLocalValid" type="hidden" />
            </v-col>

            <v-col md="5" cols="12" class="order-first order-md-last">
                <SubscriptionCard :order="order" />
            </v-col>
        </v-row>
        <v-row
            v-if="['pea', 'pea-pme'].includes(selectEnvelope)"
            ref="refPeapmeForm"
            align="center"
            justfiy="start"
        >
            <v-col>
                <v-alert
                    border="left"
                    color="blue"
                    outlined
                    text
                    type="info"
                    prominent
                    class="mt-8 mb-8"
                >
                    <span style="color: black">
                        <p>
                            {{ $t("user.buy.steps.tax-arrangement.alert-info") }}
                        </p>
                    </span>
                </v-alert>
                <div class="pa-0" style="max-width: 60%; margin: 0 auto 60px !important">
                    <p v-if="formData._local_valid !== true" class="red--text font-weight-black">
                        *{{ $t("user.buy.steps.tax-arrangement.required-info") }}
                    </p>
                    <PeapmeForm
                        profile="False"
                        :show-pea="selectEnvelope === 'pea'"
                        :show-peapme="selectEnvelope === 'pea-pme'"
                        :entity="$entity.represented()"
                        :content="content"
                        @update-entity-peapme="entityPeapmeChanged"
                    />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import SubscriptionCard from "../components/SubscriptionCard.vue";
import PeapmeForm from "./PeapmeForm.vue";
import { PeapmeSchemaDouble } from "@/components/views/user/profile/content/peapmeSchemaDouble";
import {
    getShortTaxLabelFromId,
    getTaxLabelFromId,
    getShortEnvelopeLabelFromId,
    getEnvelopeLabelFromId
} from "../util";

export default {
    name: "PaymentData",
    components: { SubscriptionCard, PeapmeForm },
    mixins: [formMixin],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectTaxArrangement: null,
            selectEnvelope: null,
            upToDateEntity: null
        };
    },
    computed: {
        showTaxArrangement() {
            return !(
                this.taxArrangementList.length === 0 ||
                (this.taxArrangementList.length === 1 &&
                    this.taxArrangementList[0].value === "none")
            );
        },
        showEnvelope() {
            return !(
                this.envelopeList.length === 0 ||
                (this.envelopeList.length === 1 && this.envelopeList[0].value === "none")
            );
        },
        ruleTaxArrangement() {
            return [value => (!!value && value.length > 0) || "Valeur requise"];
        },
        ruleEnvelope() {
            return [value => (!!value && value.length > 0) || "Valeur requise"];
        },
        ruleLocalValid() {
            return [value => !!value && value === true];
        },
        content() {
            return {
                schemaDouble: {
                    ...PeapmeSchemaDouble(this)
                },
                subtitle: this.$t("user.profile.peapme-data.subtitle")
            };
        },
        capinc() {
            return this.order.object || this.order.capinc;
        },
        taxArrangementList() {
            // Applique un tri controllé pour plus de clarté
            const taxWeigth = {
                none: -10
            };
            const taxArrangements = this.capinc.capincpaymentoptions.tax_arrangements;
            return taxArrangements
                .map(option => {
                    return {
                        value: option,
                        label: getTaxLabelFromId(this, option)
                    };
                })
                .sort((a, b) => {
                    const aWeight = taxWeigth[a.value] || 0;
                    const bWeight = taxWeigth[b.value] || 0;
                    if (aWeight !== bWeight) {
                        return aWeight - bWeight;
                    } else {
                        return a.value.localeCompare(b.value);
                    }
                });
        },
        envelopeList() {
            // Applique un tri controllé pour plus de clarté
            const envelopeWeigth = {
                none: -10,
                pea: -5,
                "pea-pme": -4,
                "share-account": -3
            };
            const envelopes = this.capinc.capincpaymentoptions.envelopes;
            return envelopes
                .map(option => {
                    return {
                        value: option,
                        label: getEnvelopeLabelFromId(this, option)
                    };
                })
                .sort((a, b) => {
                    const aWeight = envelopeWeigth[a.value] || 0;
                    const bWeight = envelopeWeigth[b.value] || 0;
                    if (aWeight !== bWeight) {
                        return aWeight - bWeight;
                    } else {
                        return a.value.localeCompare(b.value);
                    }
                });
        }
    },
    watch: {
        selectEnvelope: {
            handler(val) {
                this.updateFormData();
            }
        },
        selectTaxArrangement: {
            handler(val) {
                this.updateFormData();
            }
        }
    },
    beforeMount() {
        this.upToDateEntity = this.$entity.represented();

        this.selectTaxArrangement = this.order?.tax_arrangement?.choice;
        this.selectEnvelope = this.order?.envelope?.choice;
        this.$set(this.formData, "_local_valid", null);
    },
    methods: {
        getShortTaxLabelFromId,
        getShortEnvelopeLabelFromId,
        hasEntityPeapmeInfos() {
            const peapmeData = this.upToDateEntity?.peapmedata;
            if (this.selectEnvelope.localeCompare("pea") === 0) {
                if (
                    peapmeData.bank_pea &&
                    peapmeData.bank_pea.length > 0 &&
                    peapmeData.no_pea &&
                    peapmeData.no_pea.length > 0 &&
                    peapmeData.iban_pea &&
                    peapmeData.iban_pea.length > 0 &&
                    peapmeData.bic_pea &&
                    peapmeData.bic_pea.length > 0 &&
                    peapmeData.email_pea &&
                    peapmeData.email_pea.length > 0 &&
                    peapmeData.rib_pea_id &&
                    peapmeData.rib_pea_id.length > 0
                ) {
                    return true;
                }
            } else if (this.selectEnvelope.localeCompare("pea-pme") === 0) {
                if (
                    peapmeData.bank_peapme &&
                    peapmeData.bank_peapme.length > 0 &&
                    peapmeData.no_peapme &&
                    peapmeData.no_peapme.length > 0 &&
                    peapmeData.iban_peapme &&
                    peapmeData.iban_peapme.length > 0 &&
                    peapmeData.bic_peapme &&
                    peapmeData.bic_peapme.length > 0 &&
                    peapmeData.email_peapme &&
                    peapmeData.email_peapme.length > 0 &&
                    peapmeData.rib_peapme_id &&
                    peapmeData.rib_peapme_id.length > 0
                ) {
                    return true;
                }
            } else {
                return true;
            }
        },
        entityPeapmeChanged(entity) {
            if (entity) {
                this.upToDateEntity = entity;
                this.updateFormData();
            }
        },
        updateFormData() {
            if (this.selectEnvelope && this.selectEnvelope.length > 0) {
                this.$set(this.formData, "envelope", this.selectEnvelope);
            } else {
                this.$set(this.formData, "envelope", this.showEnvelope ? null : "none");
            }

            if (this.selectTaxArrangement && this.selectTaxArrangement.length > 0) {
                this.$set(this.formData, "tax_arrangement", this.selectTaxArrangement);
            } else {
                this.$set(
                    this.formData,
                    "tax_arrangement",
                    this.showTaxArrangement ? null : "none"
                );
            }

            if (!["pea", "pea-pme"].includes(this.selectEnvelope) || this.hasEntityPeapmeInfos()) {
                this.$set(this.formData, "_local_valid", true);
            } else {
                this.$set(this.formData, "_local_valid", null);
            }
        },
        selectTaxArrangementEvent() {
            if (!this.selectTaxArrangement) {
                return;
            }
        },
        selectEnvelopeEvent() {
            if (!this.selectEnvelope) {
                return;
            }
            if (this.selectEnvelope.localeCompare("pea") === 0) {
                this.$nextTick(() =>
                    this.$refs["refPeapmeForm"].scrollIntoView({ behavior: "smooth" })
                );
            } else if (this.selectEnvelope.localeCompare("pea-pme") === 0) {
                this.$nextTick(() =>
                    this.$refs["refPeapmeForm"].scrollIntoView({ behavior: "smooth" })
                );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.v-label) {
    padding: 0.33rem 0rem;
}
</style>
