import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_PROCEDURES_START");
    let procedures = [];
    if (state.proceduresInit && !state.proceduresLoading) {
        try {
            commit("SET_PROCEDURES_LOADING", true);
            const proceduresRes = await window.app.$api.getProcedures();
            procedures = proceduresRes.data.results;
            DEBUG("GET_PROCEDURES_SUCCESS");
            commit("SET_PROCEDURES", procedures);
            return procedures;
        } catch (e) {
            DEBUG("GET_PROCEDURES_ERROR");
            window.app.$ui.error(e, "document_to_sign_get_documents");
            throw e;
        } finally {
            commit("SET_PROCEDURES_LOADING", false);
        }
    } else {
        DEBUG("GET_PROCEDURES_BYPASSED");
        return state.procedures;
    }
};
