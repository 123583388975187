<template>
    <div class="eqs-radio d-flex" :el-class="elClass" :style="getStyle">
        <EqsLabel
            v-bind="{
                ...$attrs,
                isRequired,
                height: labelHeight,
                editable,
                value: label,
                infoTxt
            }"
        >
            <EqsSkeleton
                v-if="loading && !options.length"
                v-bind="{ loading, type: 'sentences' }"
            />
            <EqsEditable v-bind="{ ...$attrs, editable, value: editableData }">
                <v-radio-group
                    v-model="valueProxy"
                    v-bind="{
                        ...$attrs,
                        ...$props,
                        ...getData,
                        row,
                        disabled: disabled || loading,
                        rules: rulesRequired,
                        label: ''
                    }"
                    :el-class="`${elClass}-value`"
                    class="ma-0"
                    hide-details="auto"
                    v-on="$listeners"
                >
                    <!-- <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
          >
            <slot :name="name" v-bind="slotData" />
          </template>
          <slot v-for="(_, name) in $slots" :slot="name" :name="name" /> -->
                    <div v-for="(option, index) in options" :key="index">
                        <v-radio
                            v-bind="option"
                            :class="`pb-${!getBorder(index) && !row ? '2' : '0'} pt-${
                                !!index && index < options.length && !row ? '2' : '0'
                            }`"
                            :el-class="`radio-option-${option.label}`"
                        >
                            <template #label>
                                <EqsSkeleton
                                    v-if="loading"
                                    v-bind="{ loading, type: 'sentences' }"
                                />
                                <div v-if="!loading" class=".v-label">
                                    {{ option.label }}
                                </div>
                                <div v-if="!loading" class=".v-label">
                                    <slot name="label" v-bind="{ item: option }" />
                                </div>
                            </template>
                            <!-- <template #message>
                <slot v-if="!loading" name="below" v-bind="{item:option}" />
              </template> -->
                            <!-- <template
                v-for="(_, name) in $scopedSlots"
                :slot="name"
                slot-scope="slotData"
              >
                <slot :name="name" v-bind="slotData" />
              </template>
              <slot v-for="(_, name) in $slots" :slot="name" :name="name" /> -->
                        </v-radio>
                        <v-divider v-if="getBorder(index)" :key="`${index}-separator`" />
                    </div>
                </v-radio-group>
            </EqsEditable>
        </EqsLabel>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsRadio.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";

export default {
    name: "EqsRadio",
    components: {
        EqsLabel: () => import("../../atoms/EqsLabel/EqsLabel.vue"),
        EqsEditable: () => import("../../atoms/EqsEditable/EqsEditable.vue"),
        EqsSkeleton: () => import("../../atoms/EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: "eqs-radio"
        },
        value: {
            type: null,
            default: ""
        },
        row: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            required: true
        },
        border: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                const option = val >= 0 ? this.options[val] : null;
                const res = option ? option.value || val : val;
                this.$emit("update:value", res);
            }
        },
        editableData() {
            if (!this.options || !this.options.length) return "";
            const selectedValue = this.options.find(el => {
                if (
                    this.value &&
                    typeof this.value === "object" &&
                    el.value &&
                    typeof el.value === "object"
                ) {
                    return el.value.id === this.value.id;
                }
                return el.value === this.value;
            });
            return selectedValue ? selectedValue.label : "";
        },
        getLabelSlot() {
            return !!this.$slots && !!this.$slots.label;
        }
    },
    methods: {
        getBorder(index) {
            return this.border && index + 1 < this.options.length;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsRadio.scss";
</style>
