<template>
    <EqsDialog
        v-model="dialogProxy"
        closable
        v-bind="{
            attachToVApp: true,
            title: $t('projecs.dashboard.offerings.import-project'),
            subtitle: $t('projecs.dashboard.offerings.import-project-subtitle'),
            confirm: {
                fn: importOffering,
                txt: $t('capital-increase.user.dashboard.import-btn')
            },
            cancel: {
                fn: () => (dialogProxy = false)
            }
        }"
    >
        <template #default="{ loading: loadingImport }">
            <EqsText
                class="py-3"
                :value.sync="offeringID"
                v-bind="{
                    loading: loadingImport,
                    label: $t('projecs.dashboard.offerings.import-id-label'),
                    errorMessages: offeringDirty ? 'invalid ID' : null
                }"
                @input="() => (offeringDirty = false)"
            />
        </template>
    </EqsDialog>
</template>

<script>
export default {
    name: "ImportDialog",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        fetchOffering: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            offeringID: "",
            validID: false,
            offeringDirty: false
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) {
                    this.$emit("close");
                }
                this.$emit("update:value", val);
            }
        }
    },
    methods: {
        async importOffering() {
            try {
                const status = await this.fetchOffering(this.offeringID.trim());
                if (status !== "ALREADY_IMPORTED")
                    this.$ui.success(
                        this.$t("ui.message.success.imported-offering", {
                            offeringID: this.offeringID
                        })
                    );
                else {
                    this.$ui.info(
                        this.$t("ui.message.info.offering-list-user", {
                            offeringID: this.offeringID
                        })
                    );
                }
                this.offeringID = "";
            } catch (e) {
                this.offeringDirty = true;
                throw e;
            }
        }
    }
};
</script>
