var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"eqs-view-with-image d-flex fill-height overflow-y ma-0",attrs:{"el-class":_vm.elClass}},[_c('v-col',{attrs:{"cols":_vm.$reactive.getCols('half'),"order":_vm.$reactive.isDesktop() ? 0 : 1}},[_vm._t("default",null,null,{ loading: _vm.loading })],2),(_vm.img)?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":_vm.$reactive.getCols('half'),"order":_vm.$reactive.isDesktop() ? 1 : 0}},[_c('EqsImage',_vm._b({attrs:{"contain":""}},'EqsImage',{
                src: _vm.img,
                maxWidth: _vm.$reactive.isDesktop()
                    ? 'inherit'
                    : _vm.$reactive.getElementHeight('main-image'),
                maxHeight: _vm.$reactive.getElementHeight('main-image')
            },false))],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }