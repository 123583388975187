var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"closable":""},on:{"close":() => (_vm.dialogProxy = false)},model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},'EqsDialog',{
        loading: _vm.loading,
        confirm: {
            fn: () => (_vm.dialogProxy = false),
            txt: _vm.$t('btn.close')
        },
        title: _vm.title
    },false),[_c('v-flex',{staticClass:"height-fit overflow-y no-overflow-x"},[_c(_vm.iban.is_sepa ? 'SepaForm' : 'InternationalForm',_vm._b({tag:"component"},'component',{
                editable: false,
                formData: _vm.iban,
                loading: _vm.loading,
                schemaDouble: _vm.schemaDouble,
                formType: 'dialog'
            },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }