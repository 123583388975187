<template>
    <EqsStepper
        class="fill-height"
        height="100%"
        :value.sync="step"
        :steps="steps"
        :loading="loading || localLoading"
    >
        <template #default="{ item }">
            <component
                :is="item.is"
                :order="order"
                :files="files"
                :loading="loading || localLoading"
                :accept="accept"
                :refuse="refuse"
            />
        </template>
    </EqsStepper>
</template>

<script>
import ValidationOrder from "@/components/views/secondaryMarket/validation/ValidationOrder";
import ValidationDone from "@/components/views/secondaryMarket/validation/ValidationDone";

export default {
    name: "ValidationStepper",
    components: {
        ValidationOrder,
        ValidationDone
    },
    props: {
        id: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            order: {},
            files: [],
            step: 0,
            localLoading: false
        };
    },
    computed: {
        steps() {
            return [
                {
                    title: this.$t("validationOrder.title"),
                    item: {
                        is: "ValidationOrder"
                    },
                    noNext: true
                },
                {
                    title: this.$t("validationOrder.done.title"),
                    item: {
                        is: "ValidationDone"
                    },
                    noBack: true,
                    next: {
                        fn: () =>
                            this.$router
                                .push({
                                    name: "profile"
                                })
                                .catch(() => {}),
                        txt: this.$t("validationOrder.done.btn")
                    }
                }
            ];
        }
    },
    watch: {
        id: {
            immediate: true,
            async handler() {
                await this.fetchData();
            }
        },
        step: {
            immediate: true,
            handler(val) {}
        }
    },
    methods: {
        async fetchData() {
            try {
                this.localLoading = true;
                const res = await this.$api.getValidationOrder(this.id);
                this.order = res.data;
                this.files = this.order.docs;
            } catch (e) {
                this.$ui.error(e, "asked_payment_get_transfer");
            } finally {
                this.localLoading = false;
            }
        },
        goToLastStep() {
            this.step = this.steps.length - 1;
        },

        async accept() {
            try {
                await this.$api.validateOrder(this.id);
                this.order.status = "accepted";
                this.goToLastStep();
            } catch (e) {
                this.$ui.error(e, "asked_payment_accept");
            }
        },
        async refuse() {
            try {
                await this.$api.refuseOrder(this.id);
                this.order.status = "refused";
                this.goToLastStep();
            } catch (e) {
                this.$ui.error(e, "asked_payment_refuse");
            }
        }
    }
};
</script>
