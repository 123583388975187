<template>
    <v-container fluid :class="!isRoot ? 'fill-height' : ''" class="align-start">
        <v-row v-if="isRoot && isEntityCompany" class="ma-0 pa-0" justify="end">
            <v-col cols="auto">
                <v-btn class="custom-btn" outlined @click="add">
                    {{ $t("document-to-sign.add") }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="isRoot" class="ma-0 pa-0" dense>
            <v-col v-if="getDocumentListCols" :cols="getDocumentListCols" class="py-0 fill-height">
                <DataTablePaginated
                    :loading="loading || localLoading"
                    :headers="headers"
                    :items="procedures"
                    sort-by="updated_at"
                    sort-desc
                    search="name"
                    show-row-pointer
                    :filter="{ field: 'status', items: statusCategories }"
                    @update="fetchSignatures"
                    @click-row="selectRow"
                >
                    <template #[`item.updated_at`]="{ value }">
                        {{ value ? $time.formated(value) : "-" }}
                    </template>
                    <template #[`item.name`]="{ item }">
                        <span style="max-width: 200px" class="text-truncate">
                            {{ item.name }}
                        </span>
                    </template>
                    <template v-if="isEntityCompany" #[`item.n_of_signers`]="{ item }">
                        {{ item.n_of_signers }}
                    </template>
                    <template #[`item.status`]="{ value }">
                        <EqsChip v-if="value" v-bind="procedureStatus[value]">
                            {{ procedureStatus[value].text }}
                        </EqsChip>
                        <span v-else>-</span>
                    </template>
                    <template #[`item.status_mine`]="{ item, value }">
                        <EqsBtn
                            v-if="
                                (value === 'pending' || value === 'processing') &&
                                signerAndNotSigned(item)
                            "
                            small
                            v-bind="{
                                action: () =>
                                    $router.push({
                                        name: 'simple-sign-stepper',
                                        params: { procedureId: item.id }
                                    }),
                                color: 'primary'
                            }"
                            class="d-inline-block"
                        >
                            <v-icon small class="pr-1"> mdi-pencil-outline </v-icon>
                            {{ $t("document-to-sign.sign-btn") }}
                        </EqsBtn>
                        <EqsChip v-else-if="value" v-bind="getSignerStatus(value)">
                            {{ getSignerStatus(value).text }}
                        </EqsChip>
                        <span v-else>-</span>
                    </template>
                    <template v-if="isEntityCompany" #[`item.action`]="{ item }">
                        <EqsActions v-bind="{ actions: getActions(item) }" />
                    </template>
                </DataTablePaginated>
            </v-col>
            <v-col
                v-if="displayProcedureDetails || displaySignature"
                :cols="$reactive.isDesktop() ? 5 : 12"
                class="py-0 fill-height"
            >
                <ProcedureDetails
                    v-if="displayProcedureDetails"
                    :signature-procedure="procedureDetails"
                    :is-company="isEntityCompany"
                    @close="closeProcedureDetails"
                />
            </v-col>
        </v-row>
        <router-view v-else v-bind="{ loading: loading || localLoading }" />
    </v-container>
</template>

<script>
import DataTablePaginated from "@/components/views/DataTablePaginated";
import ProcedureDetails from "./ProcedureDetails";
import { mapGetters, mapActions } from "vuex";
import { procedureStatus } from "@/util/documents";

export default {
    name: "Signatures",
    components: {
        ProcedureDetails,
        DataTablePaginated
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            procedureStatus,
            selectedEntity: this.$entity.represented(),
            procedureDetails: null,
            displayProcedureDetails: false,
            displaySignature: false,
            procedureToSign: null,
            selected: [],
            localLoading: true,
            procedures: {}
        };
    },
    computed: {
        isRoot() {
            return this.$route.name === "signatures";
        },
        isEntityCompany() {
            return this.$entity.represented().is_company;
        },
        statusCategories() {
            let cats = Object.entries(procedureStatus)
                .map(item => {
                    return { text: item[1].text, value: item[0] };
                })
                .sort((a, b) => a.text.localeCompare(b.text));
            return cats;
        },
        headers() {
            let headers = [];
            headers.push({
                text: "Date",
                value: "updated_at",
                sortable: true
            });
            headers.push({
                text: this.$t("document-to-sign.table.procedure"),
                value: "name",
                sortable: false
            });
            if (this.isEntityCompany) {
                headers.push({
                    text: this.$t("document-to-sign.table.signatory"),
                    value: "n_of_signers",
                    sortable: false,
                    align: "center"
                });
            }
            headers.push({
                text: this.$t("document-to-sign.table.status-proc"),
                value: "status",
                sortable: true,
                align: "center"
            });
            headers.push({
                text: this.$t("document-to-sign.table.status-mine"),
                value: "status_mine",
                sortable: false,
                align: "center"
            });
            if (this.isEntityCompany) {
                headers.push({
                    text: "Action",
                    value: "action",
                    sortable: false
                });
            }
            return headers;
        },
        getDocumentListCols() {
            let cols = 12;
            if (this.displayProcedureDetails || this.displaySignature)
                cols = this.$reactive.isDesktop() ? 7 : 0;
            return cols;
        },
        proceduresWithoutTemplates() {
            return this.procedures.filter(procedure => !procedure.template_type);
        }
    },
    methods: {
        ...mapActions(["deleteProcedure", "getProcedures", "fetchProcedure"]),
        getSignerStatus(item) {
            switch (item) {
                case "pending":
                case "processing":
                    return {
                        eqsType: "info",
                        color: "info",
                        text: this.$t("document-to-sign.procedure-awaiting-signature")
                    };
                case "done":
                    return {
                        eqsType: "success",
                        color: "success",
                        text: this.$t("document-to-sign.procedure-signature-finished")
                    };
                case "error":
                    return {
                        eqsType: "error",
                        color: "error",
                        text: this.$t("document-to-sign.procedure-signature-error")
                    };
                case "refused":
                default:
                    return {
                        eqsType: "error",
                        color: "error",
                        text: this.$t("document-to-sign.procedure-signature-refused")
                    };
            }
        },
        add() {
            this.$router.push({ name: "add-procedure", params: this.$route.params });
        },
        getSigner(signature_procedure) {
            return signature_procedure.signer_set.find(
                signer => signer.entity_id === this.$entity.represented().id
            );
        },
        getActions(item) {
            // TODO edit actions depending of state
            let actions = [];

            if (this.isSigner(item) && item.status === "active") {
                actions.push({
                    txt: this.$t("document-to-sign.documents-list.tooltip.details"),
                    icon: "mdi-border-color",
                    fn: () => this.openProcedure(item),
                    color: "primary"
                });
            }
            if (item.created_by === this.$entity.represented().url) {
                if (item.status === "draft")
                    actions.push({
                        txt: this.$t("document-to-sign.documents-list.tooltip.publish"),
                        icon: "mdi-check-outline",
                        color: "success",
                        fn: () => this.startProcedure(item.id)
                    });
                if (item.status === "draft" || !item.status)
                    actions.push(
                        ...[
                            {
                                txt: this.$t("document-to-sign.documents-list.tooltip.edit"),
                                icon: "mdi-pencil-outline",
                                color: "primary",
                                fn: () =>
                                    this.$router.push({
                                        name: "edit-procedure",
                                        params: {
                                            ...this.$route.params,
                                            procedureId: item.id
                                        }
                                    })
                            },
                            {
                                txt: this.$t("label.delete"),
                                icon: "mdi-delete-outline",
                                color: "error",
                                fn: () => {
                                    this.deleteProcedure(item.id);
                                    this.fetchSignatures({});
                                }
                            }
                        ]
                    );
            }
            return actions;
        },
        async startProcedure(procedureId) {
            try {
                await this.$api.startProcedure(procedureId);
                this.fetchSignatures({});
            } catch (e) {
                //TODO error gestion
                console.error({ e });
                throw e;
            }
        },
        isSigner(signature_procedure) {
            let signer = find(signature_procedure.signer_set, {
                entity_id: this.$entity.represented().id
            });
            return !!signer ? true : false;
        },
        selectRow(item, row, e) {
            if (this.selected.length && item.id === this.selected[0].id) {
                this.closeProcedureDetails();
                return;
            }
            this.selected = [item];
            this.openProcedureDetails(item);
            const query = { ...this.$route.query, procedureId: item.id };
            this.$router.replace({ query }).catch(() => {});
        },
        openProcedureDetails(signature_procedure) {
            if ((this.displaySignature = true)) {
                this.displaySignature = false;
            }
            this.displayProcedureDetails = true;

            this.procedureDetails = signature_procedure;
        },
        closeProcedureDetails() {
            this.displayProcedureDetails = false;
            this.procedureDetails = {};
            this.selected = [];
            const query = { ...this.$route.query, procedureId: undefined };
            this.$router.replace({ query }).catch(() => {});
        },
        openSignature(signature_procedure) {
            // if ((this.displayProcedureDetails = true))
            //   this.displayProcedureDetails = false;
            // this.displaySignature = true;
            this.procedureToSign = signature_procedure;
            this.$router.push({
                name: "simple-sign-stepper",
                params: { procedureId: signature_procedure.id }
            });
        },
        closeSignature() {
            this.displaySignature = false;
            this.procedureToSign = null;
        },
        signerAndNotSigned(item) {
            return item.signer_set.find(
                signer =>
                    signer.entity_id === this.$entity.represented().id && signer.status !== "done"
            );
        },
        async fetchSignatures(queries) {
            this.closeProcedureDetails();
            this.localLoading = true;
            let res = null;
            try {
                res = await window.app.$api.getProcedures({
                    mine: !this.isEntityCompany, // Si personne physique, on fetch que les siennes
                    ...queries
                });
            } catch (e) {
                this.localLoading = false;
                window.app.$ui.error(e, "document_to_sign_get_documents");
                return;
            } finally {
                this.localLoading = false;
            }
            if (!this.isEntityCompany) {
                const entity_id = this.$entity.represented()?.id;
                res.data.results = res.data.results.map(procedure => {
                    return {
                        status_mine: procedure.signer_set.find(
                            signer => signer.entity_id === entity_id
                        )?.status,
                        ...procedure
                    };
                });
                this.procedures = res.data;
            } else {
                const entity_id = this.$entity.represented()?.id;
                res.data.results = res.data.results.map(procedure => {
                    return {
                        n_of_signers: procedure.signer_set.filter(
                            signer => signer.status === "done"
                        ).length,
                        status_mine: procedure.signer_set.find(
                            signer => signer.entity_id === entity_id
                        )?.status,
                        ...procedure
                    };
                });
                this.procedures = res.data;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    text-transform: none !important;
    color: white !important;
    background-color: var(--v-primary-base) !important;
}
</style>
