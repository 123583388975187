<template>
    <EqsCard
        v-bind="{
            loading,
            subtitle: isMainAccount
                ? $t('user.wallet.transactions.main-account-subtitle')
                : $t('user.wallet.transactions.sequestre-subtitle')
        }"
    >
        <EqsDataTable
            sort-by="created_at"
            :sort-desc="[true]"
            v-bind="{
                headers,
                ...$attrs,
                searchable: {
                    placeholder: $t('user.wallet.transactions.search')
                }
            }"
            v-on="$listeners"
        >
            <template #item.created_at="{ value }">
                {{ $time.formated(value) }}
            </template>
            <template #item.amount="{ item, value, index }">
                <div
                    :class="`${item.color}--text font-weight-bold
            wallet-account-history-${index}`"
                >
                    {{ `${item.add ? "+" : "-"} ${$currency.display(value)}` }}
                </div>
            </template>
            <template #item.client="{ item }">
                <div
                    v-if="
                        item.client || (item.entity && item.entity.id !== $entity.represented().id)
                    "
                    class="d-flex align-center"
                >
                    <EqsAvatar
                        class="pr-1"
                        v-bind="{
                            user: item.client || item.entity,
                            noBadge: true
                        }"
                    />
                    {{ item.client ? item.client.name : item.entity.name }}
                </div>
                <div v-else>
                    {{ $t("label.me") }}
                </div>
            </template>
            <template #item.caption="{ item, value }" class="justify-center">
                <div v-if="value === 'MONEYIN_CB'">
                    {{ $t("user.wallet.transactions.table.MONEYIN_CB") }}
                </div>
                <div v-if="value === 'MONEYIN_FEES_CB'">
                    {{ $t("user.wallet.transactions.table.MONEYIN_FEES_CB") }}
                </div>
                <div v-if="value === 'MONEYIN_WIRE_TRANSFERT'">
                    {{ $t("user.wallet.transactions.table.MONEYIN_WIRE_TRANSFERT") }}
                </div>
                <div v-if="value === 'MONEYIN_PRELEVMENT'">
                    {{ $t("user.wallet.transactions.table.MONEYIN_PRELEVMENT") }}
                </div>
                <div v-if="value === 'MONEYOUT_CASH'">
                    {{ $t("user.wallet.transactions.table.MONEYOUT_CASH") }}
                </div>
                <div v-if="value === 'MONEYOUT_FEES'">
                    <span v-if="item.amount === '0.00'" :class="'success--text'">
                        {{ $t("user.wallet.transactions.table.MONEYOUT_FEES_FREE") }}
                    </span>
                    <span v-else>
                        {{ $t("user.wallet.transactions.table.MONEYOUT_FEES") }}
                    </span>
                </div>
                <div v-if="value === 'P2P_CAPINC_MONEY'">
                    <span v-if="item.credited_entity.id !== $entity.represented().id">
                        {{ $t("user.wallet.transactions.table.P2P_CAPINC_MONEY") }}
                        {{ $t("user.wallet.transactions.table.P2P_CAPINC_MONEY_SEND") }}
                    </span>
                    <span v-if="item.credited_entity.id === $entity.represented().id">
                        {{ $t("user.wallet.transactions.table.P2P_CAPINC_MONEY") }}
                        {{ $t("user.wallet.transactions.table.P2P_CAPINC_MONEY_RECEIVED") }}
                    </span>
                </div>
                <div v-if="value === 'P2P_CAPINC_MONEY_RECEIVE_FEES'">
                    {{ $t("user.wallet.transactions.table.P2P_CAPINC_MONEY_RECEIVE_FEES") }}
                </div>
                <div v-if="value === 'P2P_CAPINC_MONEY_EMITTER_FEES'">
                    {{ $t("user.wallet.transactions.table.P2P_CAPINC_MONEY_EMITTER_FEES") }}
                </div>
                <div v-if="value === 'P2P_TO_ESCROW'">
                    {{
                        !!isMainAccount
                            ? $t("user.wallet.transactions.table.P2P_TO_ESCROW")
                            : $t("user.wallet.transactions.table.P2P_TO_ESCROW_FROM_MAIN_WALLET")
                    }}
                </div>
                <div v-if="value === 'P2P_FROM_ESCROW'">
                    {{ $t("user.wallet.transactions.table.P2P_FROM_ESCROW") }}
                </div>
                <div v-if="value === 'P2P_SECONDARY_BUYER_FEES'">
                    {{ $t("user.wallet.transactions.table.P2P_SECONDARY_BUYER_FEES") }}
                </div>
                <div v-if="value === 'P2P_SECONDARY_SELLER_FEES'">
                    {{ $t("user.wallet.transactions.table.P2P_SECONDARY_SELLER_FEES") }}
                </div>
                <div v-if="value === 'P2P_SECONDARY_PAYMENT'">
                    {{ $t("user.wallet.transactions.table.P2P_SECONDARY_PAYMENT") }}
                </div>
                <div
                    v-if="value === 'PARTNER_ACCOUNT_DEPOSIT' || value === 'PARTNER_ACCOUNT_REFUND'"
                >
                    {{
                        item.add
                            ? $t("user.wallet.transactions.table.PARTNER_ACCOUNT_RECEIVE")
                            : $t("user.wallet.transactions.table.PARTNER_ACCOUNT_PAID")
                    }}
                </div>
                <div v-if="value === 'DIVIDEND'">
                    {{
                        item.add
                            ? $t("user.wallet.transactions.table.DIVIDEND_RECEIVED")
                            : $t("user.wallet.transactions.table.DIVIDEND_PAID")
                    }}
                </div>
                <div v-if="value === 'SUBSCRIBTION'">
                    {{
                        item.add
                            ? $t("user.wallet.transactions.table.SUBSCRIBTION_RECEIVED")
                            : $t("user.wallet.transactions.table.SUBSCRIBTION_PAID")
                    }}
                </div>
                <div v-if="value === 'CESSION'">
                    {{
                        item.add
                            ? $t("user.wallet.transactions.table.CESSION_RECEIVED")
                            : $t("user.wallet.transactions.table.CESSION_PAID")
                    }}
                </div>
                <div v-if="value === 'BUYBACK'">
                    {{
                        item.add
                            ? $t("user.wallet.transactions.table.BUYBACK")
                            : $t("user.wallet.transactions.table.BUYBACK")
                    }}
                </div>
                <div v-if="value === 'CASHFLOW'">
                    {{ $t("user.wallet.transactions.table.CASHFLOW") }}
                </div>
                <div v-if="value === 'EXTERN'">
                    {{ $t("user.wallet.transactions.table.EXTERN") }} - {{ item.comment }}
                </div>
                <div v-if="value === 'LEMONWAY_PROVISIONING'">
                    {{ $t("user.wallet.transactions.table.LEMONWAY_PROVISIONING") }}
                </div>
            </template>
            <template #item.actions="{ item, index }">
                <EqsActions
                    v-if="
                        item.color !== 'warning' &&
                        item.lemonway_status === 'success' &&
                        ((item.type === 'in' && item.credited_entity !== null) ||
                            (item.type === 'out' && item.debited_entity !== null) ||
                            (item.type === 'p2p' &&
                                item.debited_entity !== null &&
                                item.credited_entity !== null))
                    "
                    v-bind="{
                        actions: getActions(item),
                        elClass: `item-${index}-actions`
                    }"
                />
            </template>
        </EqsDataTable>
    </EqsCard>
</template>

<script>
import { getRootURL } from "@/util";

export default {
    name: "WalletTransactions",
    props: {
        isMainAccount: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            headers: [
                {
                    value: "created_at",
                    searchable: false,
                    text: this.$t("label.date")
                },
                {
                    value: "caption",
                    sortable: false,
                    text: this.$t("user.wallet.transactions.table.wording")
                },
                {
                    value: "client",
                    sortable: false,
                    text: this.$t("user.wallet.transactions.table.client")
                },
                {
                    value: "amount",
                    text: this.$t("label.amount"),
                    align: "end"
                },
                {
                    text: this.$t("label.actions"),
                    value: "actions",
                    align: "center",
                    sortable: false,
                    class: "file-actions"
                }
            ]
        };
    },
    methods: {
        getActions(item) {
            let res = [
                {
                    txt: this.$t("file-actions.download-payment"),
                    icon: "mdi-download-outline",
                    color: "primary",
                    fn: () => {
                        this.downloadMovementProof(item);
                    }
                }
            ];
            return res;
        },
        async downloadMovementProof(item) {
            this.$ui.info(this.$t("ui.message.info.waiting"));
            try {
                const res = await this.$api.downloadMovement(item.id);
                const url = getRootURL() + "api/document/" + res.data + "/download/";
                window.open(url, "_blank");
            } catch (e) {
                console.log(e);
            }
        }
    }
};
</script>
