<template>
    <v-container fluid>
        <v-row align="start" justify="space-between">
            <v-col md="7" cols="12">
                <h2 class="text-h4 mb-8">{{ $t("user.buy.project-amount") }}</h2>
                <v-container fluid>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="orderAmountProxy"
                                :label="$t('user.buy.steps.sub-card.invested-amount')"
                                type="text"
                                :rules="amountRules"
                                :suffix="order.object.currency === 'USD' ? '$' : '€'"
                            />
                        </v-col>
                        <v-col v-if="isFeesVariable" cols="6">
                            <v-text-field
                                v-model="orderFeePercentageProxy"
                                :label="
                                    capinc.universe === 'kyoseil'
                                        ? $t('user.buy.steps.sub-card.fees')
                                        : $t('user.buy.steps.sub-card.fees-nokyoseil')
                                "
                                type="text"
                                :rules="feePercentageRules"
                                suffix="%"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="isFeesIncluded" no-gutters dense>
                        <v-col>
                            <v-alert color="blue" outlined dense text max-width="200px">
                                <span style="color: black; font-size: 1rem">
                                    *{{ $t("user.buy.steps.sub-card.fees-included") }}
                                </span>
                            </v-alert>
                        </v-col>
                        <v-col />
                    </v-row>
                </v-container>
                <v-alert v-if="catchupFeesPercentage" border="left" type="warning" text outlined>
                    <span style="color: black">
                        {{
                            $t("user.buy.steps.sub-form.catchup-fees-kyoseil", {
                                dateClosing: catchupInitialClosingDate,
                                catchupPercent: catchupFeesPercentage
                            })
                        }}
                    </span>
                </v-alert>
                <v-alert border="left" outlined text type="info">
                    <span style="color: black">
                        <p v-if="isFeesVariable">
                            {{ $t("user.buy.order-amount.alert-1", { feesMin, feesMax }) }}
                        </p>
                        <p v-else>{{ $t("user.buy.order-amount.alert-2") }} {{ feesDefault }}%.</p>
                        <ul>
                            <li>
                                {{ $t("user.buy.order-amount.alert-3") }}
                                {{
                                    $currency.displayWithCurrency(
                                        minInvestment,
                                        order.object.currency
                                    )
                                }}
                            </li>
                            <li>
                                {{ $t("user.buy.order-amount.alert-4") }}
                                {{
                                    $currency.displayWithCurrency(
                                        maxInvestment,
                                        order.object.currency
                                    )
                                }}
                            </li>
                        </ul>
                    </span>
                </v-alert>
            </v-col>
            <v-col md="5" cols="12" class="order-first order-md-last">
                <SubscriptionCard
                    :order="order"
                    :share-amount="shareAmountRounded"
                    :fee-percentage="orderFeePercentageSanitize"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { options as _options, fees as _fees } from "@/util/payment.js";
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import SubscriptionCard from "../components/SubscriptionCard.vue";

import { requiredR } from "@equisafe-ui-vue/src/rules";

const Decimal = require("decimal.js-light");

export default {
    name: "OrderAmount",
    components: { SubscriptionCard },
    mixins: [formMixin],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            orderAmountInput: 0.0,
            orderAmount: 0.0,
            shareAmountRounded: 0,
            orderFeePercentage: 0.0,
            orderFeePercentageSanitize: null,
            feesAmount: 0.0,
            totalAmount: 0.0
        };
    },
    computed: {
        catchupFeesPercentage() {
            if (!this.capinc || this.capinc.universe !== "kyoseil") {
                return null;
            }
            const catchupFees = this.capinc?.capincpaymentoptions?.catchup_fees;
            if (!catchupFees) {
                return null;
            }
            let tmp = parseFloat(catchupFees.amount);
            if (tmp === NaN || tmp === 0.0) {
                return null;
            }
            const dateToday = new Date();
            const dateClosing = new Date(catchupFees.initial_closing_date);
            if (dateClosing.getTime() >= dateToday.getTime()) {
                return null;
            }
            return tmp;
        },
        catchupInitialClosingDate() {
            const catchupFees = this.capinc?.capincpaymentoptions?.catchup_fees;
            if (!catchupFees) {
                return null;
            }
            const dateClosing = new Date(catchupFees.initial_closing_date).toLocaleDateString();
            return dateClosing;
        },
        capinc() {
            return this.order.object || this.order.capinc;
        },
        orderAmountProxy: {
            get() {
                if (this.orderAmountInput <= 0.0) {
                    return null;
                }
                return this.orderAmountInput;
            },
            set(val) {
                this.orderAmountInput = val ? val.replace(",", ".") : null;
                const roundedAmountOfShare = this.getRoundedAmountOfShares(
                    this.orderAmountInput,
                    this.sharePrice,
                    this.orderFeePercentageProxy,
                    this.isFeesIncluded
                );
                this.shareAmountRounded = roundedAmountOfShare;
                this.$set(this.formData, "numberofshares", roundedAmountOfShare);
            }
        },
        orderFeePercentageProxy: {
            get() {
                if (this.isFeesVariable) {
                    if (this.orderFeePercentage <= 0.0) {
                        return null;
                    }
                    return this.orderFeePercentage;
                } else {
                    return this.feesDefault;
                }
            },
            set(val) {
                this.orderFeePercentage = typeof val === "string" ? val.replace(",", ".") : val;
                if (this.orderFeePercentage) {
                    const tmpVal = parseFloat(this.orderFeePercentage);
                    if (!isNaN(tmpVal)) {
                        this.orderFeePercentageSanitize = tmpVal;
                    }
                } else {
                    this.orderFeePercentageSanitize = 0.0;
                }
                this.orderAmountProxy = this.orderAmountProxy;
                this.$set(this.formData, "order_fee_percentage", this.orderFeePercentage);
            }
        },
        isFeesVariable() {
            if (this.order.object.capincpaymentoptions.fee_options.is_fixed) {
                return false;
            }
            return true;
            // if (this.$entity.isAffiliationInvestor()) {
            //     // cgp impersonate un invest
            //     return true;
            // }
            // if (
            //     this.$universe.isKyoseil() &&
            //     !this.$entity.hasAffiliationRole() &&
            //     !this.$entity.isAffiliationInvestor()
            // ) {
            //     // invest direct hors affiliation sur Kyoseil
            //     return true;
            // }
            // return false;
        },
        isFeesIncluded() {
            return this.order.object?.capincpaymentoptions?.fee_options?.is_included === true;
        },
        feesDefault() {
            return parseFloat(this.order.object.capincpaymentoptions.fee_options.default_amount);
        },
        feesMin() {
            return parseFloat(this.order.object.capincpaymentoptions.fee_options.min_amount);
        },
        feesMax() {
            return parseFloat(this.order.object.capincpaymentoptions.fee_options.max_amount);
        },
        maxInvestment() {
            return parseFloat(this.capinc.max_investment);
        },
        minInvestment() {
            return parseFloat(this.capinc.min_investment);
        },
        sharePrice() {
            return parseFloat(this.capinc.share_price);
        },
        amountFinalPrice() {
            const dShareAmount = new Decimal(this.shareAmountRounded);
            const dSharePrice = new Decimal(this.sharePrice);
            const result = dShareAmount.times(dSharePrice).toDecimalPlaces(2, Decimal.ROUND_DOWN);
            return result;
        },
        amountRules() {
            return [
                requiredR,
                value =>
                    /^[0-9]{1,9}([,.][0-9]{0,3})?$/.test(value) ||
                    this.$t("user.buy.order-amount.form.number-required"),
                value =>
                    ((this.isFeesIncluded ? this.orderAmountProxy : this.amountFinalPrice) >=
                        this.minInvestment &&
                        (this.isFeesIncluded ? this.orderAmountProxy : this.amountFinalPrice) <=
                            this.maxInvestment) ||
                    this.$t("user.buy.order-amount.form.check-invest-range")
            ];
        },
        feePercentageRules() {
            return [
                requiredR,
                value =>
                    /^[0-9]{1,2}([,.][0-9]{0,2})?$/.test(value) ||
                    this.$t("user.buy.order-amount.form.percent-required"),
                value =>
                    (value >= this.feesMin && value <= this.feesMax) ||
                    this.$t("user.buy.order-amount.form.fees-range", {
                        feesMin: this.feesMin,
                        feesMax: this.feesMax
                    })
            ];
        }
    },
    beforeMount() {
        if (!this.isFeesVariable) {
            if (this.order.object?.capincpaymentoptions?.fee_options?.default_amount !== null) {
                this.orderFeePercentageProxy =
                    this.order.object.capincpaymentoptions.fee_options.default_amount;
            } else {
                this.orderFeePercentageProxy = 0.0;
            }
        }
        if (this.order.total_share_price) {
            this.orderAmountProxy = this.order.total_share_price;
        }
    },
    methods: {
        getRoundedAmountOfShares(amount, sharePrice, feesPercent, feesIncluded) {
            let amountFloat = parseFloat(amount);
            if (isNaN(amountFloat)) {
                return 0;
            }
            amountFloat = new Decimal(amount);
            let fSharePrice = new Decimal(sharePrice);

            if (!feesIncluded) {
                const roundedNumberOfShares = amountFloat
                    .toDecimalPlaces(2, Decimal.ROUND_DOWN)
                    .dividedBy(fSharePrice)
                    .toDecimalPlaces(0, Decimal.ROUND_UP);
                return parseInt(roundedNumberOfShares);
            } else {
                if (!feesPercent) {
                    feesPercent = 0.0;
                }
                let feesPercentageFloat = new Decimal(feesPercent);
                feesPercentageFloat = feesPercentageFloat.dividedBy(100);

                const realAmount = amountFloat.dividedBy(feesPercentageFloat.add(1));
                const roundedNumberOfShares = realAmount
                    .toDecimalPlaces(2, Decimal.ROUND_DOWN)
                    .dividedBy(fSharePrice)
                    .toDecimalPlaces(0, Decimal.ROUND_DOWN);
                return parseInt(roundedNumberOfShares);
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
