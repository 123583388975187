var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDataTable',_vm._b({ref:"history",attrs:{"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$time.formated(item.confirmed_datetime))+" ")]}},{key:"item.investor_source",fn:function({ item }){return [_vm._v(" "+_vm._s(item.investor_source_name)+" ")]}},{key:"item.investor_destination",fn:function({ item }){return [_vm._v(" "+_vm._s(item.investor_destination_name)+" ")]}},{key:"item.numberofshares",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$currency.display(item.numberofshares, "0,0"))+" ")]}},{key:"item.pricepershare",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$currency.display(item.pricepershare * item.numberofshares))+" ")]}}])},'EqsDataTable',{
        itemKey: _vm.itemKey,
        loading: _vm.loading || _vm.localLoading,
        items: _vm.items,
        headers: _vm.headers,
        column: false
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }