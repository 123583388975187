import { textSizingByType, textCSSByType } from "../../../templates/text";

export const cssTypes = (currentSize = {}, ctx = {}) => {
    return {
        ...textCSSByType(currentSize, ctx)
    };
};

export const dataTypes = (currentSize = {}, ctx = {}) => {
    return {
        ...textSizingByType(currentSize, ctx)
    };
};
