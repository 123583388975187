var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"fill-height",attrs:{"closable":""},on:{"close":() => {
            _vm.$emit('close');
        }}},'EqsCard',{
        loading: _vm.loading || _vm.shareledgersLoading,
        progress: _vm.progress,
        validForm: _vm.validForm,
        title: _vm.formData && _vm.formData.name ? _vm.formData.name.toUpperCase() : '',
        elClass: 'shareledger-details-ownership',
        height: '100%'
    },false),[_c('v-form',{staticClass:"d-flex flex-column fill-height overflow-y",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[(_vm.panels && _vm.panels.length)?_c('EqsPanels',_vm._b({attrs:{"type":"profile","value":0},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-flex',{attrs:{"pl-3":"","pr-3":""}},[_c(item.component,_vm._b({tag:"component",class:item.class},'component',{
                            specificHeaders: _vm.specificHeaders,
                            schemaDouble: _vm.schemaDouble,
                            formData: _vm.formData,
                            editable: _vm.editable,
                            shareledgerId: _vm.shareledgerId,
                            shareledgers: _vm.shareledgers.filter(
                                shareledger => shareledger.id !== _vm.shareledgerId
                            ),
                            selectedShareType: _vm.selectedShareType,
                            loading: _vm.localLoading,
                            shareledgerId: _vm.shareledgerId
                        },false)),_c('v-row',{staticClass:"px-3"},[(item.component === 'ShareledgerSpecification')?_c('EqsCrudActionBtns',_vm._b({class:_vm.editable ? 'pt-5 mt-3' : '',attrs:{"editable":_vm.editable,"has-changed":_vm.hasChanged},on:{"update:editable":function($event){_vm.editable=$event},"update:hasChanged":function($event){_vm.hasChanged=$event},"update:has-changed":function($event){_vm.hasChanged=$event}}},'EqsCrudActionBtns',{
                                save: _vm.save,
                                cancel: _vm.resetFormData,
                                validForm: _vm.validForm,
                                save: _vm.save,
                                hasChanged: _vm.hasChanged
                            },false)):_vm._e()],1)],1)]}}],null,false,824475292)},'EqsPanels',{ panels: _vm.panels },false)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }