<template>
    <EqsDataTable
        v-bind="{
            searchable: {
                placeholder: $t('capitalisation.general-view.search-investor')
            },
            ...$attrs
        }"
        :footer-props="{
            itemsPerPageOptions: [30, 60]
        }"
        :items-per-page="30"
    >
        <template #item.shares="{ value }">
            <div class="d-flex">
                <EqsChip
                    v-for="(share, index) in value"
                    :key="index"
                    text-color="text"
                    :color="$color.colorFromHash(share.name)"
                    class="mx-1"
                >
                    <template #alt>
                        {{
                            `${$currency.display(share.numberofshares, "0,0")} ${
                                share.symbol ? share.symbol.toUpperCase() : ""
                            }`
                        }}
                    </template>
                </EqsChip>
            </div>
        </template>
        <template #item.percent="{ value }">
            {{ !!value ? $currency.percent(value, "0.00%") : "-" }}
        </template>
        <template #item.amount="{ value }">
            {{
                $currency.displayWithCurrency(
                    value ? Math.abs(value) : 0.0,
                    $entity.represented().country.alphacode == "US" ? "USD" : "EUR"
                )
            }}
        </template>
        <template #other>
            <div class="d-flex justify-end" style="margin-right: 5px">
                <EqsBtn
                    text
                    class="pa-0"
                    el-class="download-operations-data"
                    v-bind="{
                        infoTxt: $t('shareledger.asset-movement-history.download-btn'),
                        action: downloadOperationsData
                    }"
                >
                    <v-icon color="primary"> mdi-file-download-outline </v-icon>
                </EqsBtn>
            </div>
        </template>
        <template #item.actions="{ item, index }">
            <EqsActions
                v-bind="{
                    actions: getActions(item),
                    elClass: `item-${index}-actions`
                }"
            />
        </template>
    </EqsDataTable>
</template>

<script>
import { createWorkbook } from "@/services/excelJs";
import { getRootURL } from "@/util";

export default {
    name: "ShareholdersList",
    props: {
        shareledger: {
            type: Object,
            required: false,
            default: null
        }
    },
    methods: {
        getActions(item) {
            let res = [
                {
                    txt: this.$t("file-actions.download-property-proof"),
                    icon: "mdi-download-outline",
                    color: "primary",
                    fn: () => {
                        this.downloadPropertyProof(item);
                    }
                }
            ];
            return res;
        },
        async downloadPropertyProof(item) {
            if (!this.shareledger) {
                return;
            }
            try {
                this.$ui.info(this.$t("ui.message.info.waiting"));
                this.localLoading = true;
                const res = await this.$api.downloadPropertyProof(
                    item.id,
                    this.$props.shareledger.id
                );
                this.localLoading = false;
                const url = getRootURL() + "api/document/" + res.data + "/download/";
                window.open(url, "_blank");
            } catch (e) {
                this.localLoading = false;
                console.log(e);
            }
        },
        async downloadOperationsData() {
            try {
                this.localLoading = true;
                const res = await this.$assetMovement.readAssetMovementList({
                    entity__id: this.$entity.represented().id,
                    with_valid_transaction: true
                });
                await createWorkbook("AssetMovement", res.data.results, this.$entity.represented());
            } catch (e) {
                this.$ui.error(e, "asset_movement_history_download");
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
