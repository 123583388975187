var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-simple-table',{staticClass:"crypto-custom-table mb-6",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("user.buy.steps.status.crypto.text-4")))]),_c('td',[_vm._v(" "+_vm._s(_vm.cryptoData.crypto_selected.crypto.protocol)+" ")])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("user.buy.steps.status.crypto.text-5")))]),_c('td',[_vm._v(" "+_vm._s(_vm.cryptoData.crypto_selected.crypto.symbol.split("-")[0])+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("user.buy.steps.status.crypto.rate"))+" "+_vm._s(_vm.$t("user.buy.steps.status.crypto.text-6"))+" "+_vm._s(_vm.cryptoData.crypto_selected.crypto.symbol.split("-")[0])+" ")]),_c('td',[_vm._v(_vm._s(_vm.getEurToCryptoRate(_vm.cryptoData.rate_eur)))])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("user.buy.steps.status.crypto.rate"))+" "+_vm._s(_vm.cryptoData.crypto_selected.crypto.symbol.split("-")[0])+" "+_vm._s(_vm.$t("user.buy.steps.status.crypto.text-7"))+" ")]),_c('td',[_vm._v(_vm._s(_vm.cryptoData.rate_eur))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("user.buy.steps.status.crypto.text-9")))]),_c('td',[_vm._v("0x"+_vm._s(_vm.cryptoData.address))])])])]},proxy:true}])}),_c('v-simple-table',{staticClass:"crypto-custom-table mb-6",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("user.buy.steps.status.crypto.text-8"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.amountToSend)+" "+_vm._s(_vm.cryptoData.crypto_selected.crypto.symbol.split("-")[0])+" "),_c('v-tooltip',{attrs:{"open-on-click":"","open-on-focus":false,"open-on-hover":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return (() => {
                                                _vm.copyToClipboard(_vm.amountToSend, 'quantity');
                                            }).apply(null, arguments)}}},[_vm._v(" mdi-content-copy ")])],1)]}}]),model:{value:(_vm.showTooltipQuantity),callback:function ($$v) {_vm.showTooltipQuantity=$$v},expression:"showTooltipQuantity"}},[_c('span',[_vm._v(_vm._s(_vm.$t("helpers.copied-to-clipboard")))])])],1)]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("user.buy.steps.status.crypto.text-10"))+" ")]),_c('td',[_vm._v(" 0x"+_vm._s(_vm.cryptoData.crypto_selected.address)+" "),_c('v-tooltip',{attrs:{"open-on-click":"","open-on-focus":false,"open-on-hover":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return (() => {
                                                _vm.copyToClipboard(
                                                    `0x${_vm.cryptoData.crypto_selected.address}`,
                                                    'address'
                                                );
                                            }).apply(null, arguments)}}},[_vm._v(" mdi-content-copy ")])],1)]}}]),model:{value:(_vm.showTooltipAddress),callback:function ($$v) {_vm.showTooltipAddress=$$v},expression:"showTooltipAddress"}},[_c('span',[_vm._v(_vm._s(_vm.$t("helpers.copied-to-clipboard")))])])],1)])])]},proxy:true}])}),(_vm.cryptoLink !== null)?_c('div',[_vm._v(" Transaction: "),_c('a',{attrs:{"href":_vm.cryptoLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.cryptoLink))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }