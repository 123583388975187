import { render, staticRenderFns } from "./InvestorDetailPanels.vue?vue&type=template&id=e399e48c&scoped=true"
import script from "./InvestorDetailPanels.vue?vue&type=script&lang=js"
export * from "./InvestorDetailPanels.vue?vue&type=script&lang=js"
import style0 from "./InvestorDetailPanels.vue?vue&type=style&index=0&id=e399e48c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e399e48c",
  null
  
)

export default component.exports