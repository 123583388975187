import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, myProject) => {
    DEBUG("UPDATE_MY_PROJECT_START");
    try {
        commit("SET_MY_PROJECTS_LOADING", true);
        const res = await window.app.$api.updateCapitalIncrease(myProject.id, myProject);
        const myProjectRes = res.data;
        DEBUG("UPDATE_MY_PROJECT_SUCCESS");
        commit("UPDATE_MY_PROJECT", myProjectRes);
        return myProjectRes;
    } catch (e) {
        DEBUG("UPDATE_MY_PROJECT_ERROR");
        throw e;
    } finally {
        commit("SET_MY_PROJECTS_LOADING", false);
    }
};
