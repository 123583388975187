export class KYCService {
    constructor(api) {
        if (!api) {
            throw new Error("Missing `api` option");
        }
        this.api = api;
    }

    getRoute(entity) {
        const route = `/registration/${entity.id}${
            entity.lemonway_state === "pending" ? "/verify" : ""
        }`;
        return route;
    }

    lemonwayStatus(status, ctx) {
        const LEMONWAY_STATUS = {
            WALLETSC: {
                int: -1,
                color: "error",
                value: ctx.$t("kyc.lemonway-status.walletsc")
            },
            NOT_OPENED: {
                int: 1,
                color: "error",
                value: ctx.$t("kyc.lemonway-status.not-opened")
            },
            KYC_INCOMPLETE: {
                int: 2,
                color: "error",
                value: ctx.$t("kyc.lemonway-status.kyc-incomplete")
            },
            KYC_REJECTED: {
                int: 3,
                color: "error",
                value: ctx.$t("kyc.lemonway-status.kyc-rejected")
            },
            KYC_1: {
                int: 5,
                color: "success",
                value: ctx.$t("kyc.lemonway-status.kyc-1")
            },
            KYC_2: {
                int: 6,
                color: "success",
                value: ctx.$t("kyc.lemonway-status.kyc-2")
            },
            KYC_3: {
                int: 7,
                color: "success",
                value: ctx.$t("kyc.lemonway-status.kyc-3")
            },
            KYC_EXPIRED: {
                int: 8,
                color: "error",
                value: ctx.$t("kyc.lemonway-status.kyc-expired")
            },
            BLOCKED: {
                int: 10,
                color: "error",
                value: ctx.$t("kyc.lemonway-status.blocked")
            },
            CLOSED: {
                int: 12,
                color: "error",
                value: ctx.$t("kyc.lemonway-status.closed")
            },
            KYC_2_TO_KYC_3: {
                int: 13,
                color: "success",
                value: ctx.$t("kyc.lemonway-status.kyc-2-to-kyc-3")
            },
            ONE_TIME_CUSTOMER: {
                int: 14,
                color: "",
                value: ctx.$t("kyc.lemonway-status.one-time-customer")
            },
            CROWDLENDING: {
                int: 15,
                color: "",
                value: ctx.$t("kyc.lemonway-status.crowdlending")
            },
            TECHNIQUE: {
                int: 16,
                color: "",
                value: ctx.$t("kyc.lemonway-status.technique")
            }
        };

        return Object.values(LEMONWAY_STATUS).find(val => val.int == status);
    }
}
