import DEBUG from "@/store/debug.js";

export default async ({ state, commit }, issuingEntityId) => {
    if (!issuingEntityId) throw new Error("no issuingEntityId");
    if (!state.issuingEntityLoading) {
        try {
            commit("SET_ISSUINGENTITY_LOADING", true);
            const res = await window.app.$api.getTransaction(issuingEntityId);
            commit("GET_ISSUINGENTITY", res.data);
        } catch (e) {
            DEBUG("GET_ISSUINGENTITY_ERROR");
            console.error({ e });
            throw e;
        } finally {
            commit("SET_ISSUINGENTITY_LOADING", false);
        }
    }
};
