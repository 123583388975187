<template>
    <div class="d-flex flex-row fill-width fill-height">
        <EqsAvatar
            class="px-3 mr-3"
            v-bind="{ loading, src: !!member.photo ? member.photo.upload : '' }"
        />
        <EqsCard v-bind="{ loading }">
            <v-container class="pa-0 ma-0">
                <v-row class="px-3 justify-space-between">
                    <EqsTextDisplay eqs-type="subtitle" :value="member.name" />
                    <GlobalPlatformsLinks :items="getPlatforms(member)" small size="18px" />
                </v-row>
                <v-row class="px-3">
                    <EqsTextDisplay paragraph :value="member.role" />
                </v-row>
            </v-container>
        </EqsCard>
    </div>
</template>

<script>
export default {
    name: "MemberCard",
    props: {
        member: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        getPlatforms(item) {
            return item.linkedin
                ? [
                      {
                          platform: "linkedin",
                          url: item.linkedin
                      }
                  ]
                : null;
        }
    }
};
</script>
