<template>
    <div class="eqs-main-container margin-auto">
        <GlobalFinalState
            :loading="loading"
            :state="finalState"
            :title="statusText[order.status].title"
            :error-text="errorText"
        >
            <template #content>
                <v-container>
                    <v-col :cols="12">
                        <EqsTextDisplay
                            class="text-center"
                            eqs-type="subtitle"
                            :value="
                                statusText[order.status].subtitle({
                                    buyer: order.buyer_name,
                                    seller: order.seller_name
                                })
                            "
                        />
                    </v-col>

                    <v-col :cols="12">
                        <EqsTextDisplay
                            class="text-center"
                            paragraph
                            :value="
                                statusText[order.status].paragraph({
                                    buyer: order.buyer_name,
                                    seller: order.seller_name,
                                    amount: order.amount,
                                    shareledger: order.shareledger_name
                                })
                            "
                        />
                    </v-col>
                </v-container>
            </template>
        </GlobalFinalState>
    </div>
</template>

<script>
export default {
    name: "ValidationDone",
    props: {
        errorText: {
            type: String,
            default: null
        },
        state: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            statusText: {
                accepted: {
                    title: this.$t("validationOrder.accepted.title"),
                    subtitle: args => this.$t("validationOrder.accepted.subtitle", args),
                    paragraph: args => this.$t("validationOrder.accepted.paragraph", args)
                },
                refused: {
                    title: this.$t("validationOrder.refused.title"),
                    subtitle: args => this.$t("validationOrder.refused.subtitle", args),
                    paragraph: args => this.$t("validationOrder.refused.paragraph", args)
                }
            }
        };
    },
    computed: {
        finalState() {
            return this.state === "refused" ? "success" : this.state;
        }
    }
};
</script>
