<template>
    <div class="ma-0 pa-0">
        <GlobalTextEditor
            :value.sync="field.content"
            class="text-editor-container"
            v-bind="{
                elClass: 'text-editor',
                editable: true,
                required: true,
                loading: false
            }"
        />
        <v-input v-model="shadowTextInput" :rules="fieldTextR" type="hidden" />
    </div>
</template>

<script>
import GlobalTextEditor from "@/components/global/globalTextEditor/GlobalTextEditor";
import { fieldTextR } from "../utils";

export default {
    name: "FormText",
    components: { GlobalTextEditor },
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fieldTextR,
            shadowTextInput: null
        };
    },
    watch: {
        "field.content": {
            handler: function (val) {
                if (val && val.length > 9) {
                    this.shadowTextInput = "ok";
                } else {
                    this.shadowTextInput = null;
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
.text-editor-container {
    height: 350px;
}
</style>
