import getBalance from "./actions/get_balance";
import fetchBalance from "./actions/fetch_balance";
import getIban from "./actions/get_iban";
import getVIban from "./actions/get_viban";
import fetchIban from "./actions/fetch_iban";
import getSequestre from "./actions/get_sequestre";
import getMovements from "./actions/get_movements";
import getSequestreMovements from "./actions/get_sequestre_movements";

export default {
    getBalance,
    fetchBalance,
    getIban,
    getVIban,
    fetchIban,
    getSequestre,
    getMovements,
    getSequestreMovements
};
