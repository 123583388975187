<template>
    <div class="eqs-main-container margin-auto">
        <EqsViewWithImage
            class="mx-3"
            v-bind="{ img: $universe.image('/images/identification.svg') }"
        >
            <v-row class="px-3">
                <v-col>
                    <EqsTextDisplay
                        v-bind="{ eqsType: 'title', value: $t('not-registered.title') }"
                    />
                </v-col>
            </v-row>
            <v-row v-if="failedRoute && failedRoute.meta && failedRoute.meta.context" class="px-3">
                <v-col>
                    <EqsTextDisplay
                        paragraph
                        v-bind="{
                            value: $t('not-registered.subtitle.text-1', {
                                section: failedRoute.meta.context
                            })
                        }"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3">
                <v-col>
                    <EqsTextDisplay paragraph>
                        <span>
                            {{ $t("not-registered.subtitle.text-2") }}
                            <a
                                @click="
                                    () =>
                                        $router.push(
                                            needToCompleteRegistration
                                                ? `/registration/${$entity.represented().id}`
                                                : `/registration/${$entity.represented().id}/verify`
                                        )
                                "
                            >
                                {{ $t("not-registered.subtitle.click-here") }}</a
                            >.
                        </span>
                    </EqsTextDisplay>
                </v-col>
            </v-row>
        </EqsViewWithImage>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "NotRegistered",
    computed: {
        ...mapGetters(["failedRoute"]),
        needToCompleteRegistration() {
            return ["failure", "upload", "upload-failure"].includes(
                this.$entity.represented().lemonway_state
            );
        }
    }
    // beforeRouteEnter(to, from, next) {
    //   next((vm) => {
    //     vm.failedRoute = from;
    //   });
    // },
};
</script>
