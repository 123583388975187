var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('EqsNumber',_vm._b({staticClass:"wallet-balance",attrs:{"label":_vm.$t('user.wallet.wallet-balance'),"el-class":"wallet-balance"}},'EqsNumber',{
            value: _vm.balance,
            loading: _vm.loading || _vm.balanceLoading,
            editable: false,
            eqsSize: 'lg',
            infoTxt: _vm.$t('user.wallet.info-txt', { universe: _vm.universe })
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }