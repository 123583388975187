import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_INVESTORS_START");
    if (state.investorsInit && !state.investorsLoading) {
        try {
            commit("SET_INVESTORS_LOADING", true);
            const investorsRes = await window.app.$api.readInvestorWhiteList({
                company_id: store.getters.currentRole.entity_represented.id,
                page: 1,
                page_size: 10000
            });
            const investors = investorsRes.data.results;
            DEBUG("GET_INVESTORS_SUCCESS");
            commit("SET_INVESTORS", investors);
        } catch (e) {
            DEBUG("GET_INVESTORS_ERROR");
            window.app.$ui.error(e, "investor_registrar_get_investors");
            throw e;
        } finally {
            commit("SET_INVESTORS_LOADING", false);
        }
    } else {
        DEBUG("GET_INVESTORS_BYPASSED");
    }
};
