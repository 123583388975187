var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsStepper',_vm._b({attrs:{"value":0},scopedSlots:_vm._u([{key:"default",fn:function({ item, loading: isLoading }){return [_c('div',{staticClass:"eqs-main-container margin-auto"},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field pa-0",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('CurrentBankAccount',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.ibanLoading || (!_vm.ibanLoading && _vm.iban)),expression:"ibanLoading || (!ibanLoading && iban)"}],staticClass:"mb-3",staticStyle:{"width":"100%"},attrs:{"display":""},on:{"pending":_vm.comeBackToWallet}},'CurrentBankAccount',{ loading: isLoading },false))],1)],1),(_vm.error)?_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsAlert',_vm._b({staticClass:"py-3",attrs:{"el-class":"bank-error"}},'EqsAlert',{
                                eqsType: 'error',
                                text: _vm.$t('user.wallet.bank.error'),
                                loading: isLoading
                            },false))],1)],1):_vm._e(),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsTextDisplay',{attrs:{"value":_vm.$t('user.wallet.bank.account-info')}}),_c('EqsSwitch',_vm._b({staticClass:"max-height-fit",attrs:{"value":_vm.isSepa,"el-class":"is-sepa-switch","text":_vm.isSepa
                                    ? _vm.$t('user.wallet.bank.account-in-sepa')
                                    : _vm.$t('user.wallet.bank.account-international')},on:{"update:value":function($event){_vm.isSepa=$event}}},'EqsSwitch',{ loading: isLoading },false)),_c('a',{attrs:{"href":_vm.$t('user.wallet.bank.sepa-link-url'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("user.wallet.bank.sepa-link"))+" ")])],1)],1)],1),_c(item.is,_vm._b({tag:"component",attrs:{"editable":""}},'component',{
                    formData: _vm.formData,
                    loading: isLoading,
                    countries: _vm.countries,
                    schemaDouble: _vm.schemaDouble
                },false))],1)]}}])},'EqsStepper',{
        steps: _vm.steps,
        progress: _vm.progress,
        loading: _vm.loading || _vm.ibanLoading || _vm.loadingUpdateIban || _vm.countriesLoading
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }