import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, dispatch }, data) => {
    DEBUG("ADD_MY_PROJECT_TO_LIST_START");
    try {
        commit("SET_MY_PROJECTS_LOADING", true);
        const projectRes = await window.app.$api.getCapitalIncrease(data.projectId, {
            entity_id: store.getters.currentRole.entity_represented.id
        });
        DEBUG("ADD_MY_PROJECT_TO_LIST_SUCCESS");
        commit("ADD_MY_PROJECT", projectRes.data);
        return projectRes.data;
    } catch (e) {
        DEBUG("ADD_MY_PROJECT_TO_LIST_ERROR");
        throw e;
    } finally {
        commit("SET_MY_PROJECTS_LOADING", false);
    }
};
