<template>
    <div class="eqs-clickable-card" :style="{ ...getStyle, ...additionalStyle }">
        <EqsSkeleton
            v-bind="{
                loading,
                type: 'image',
                width,
                height
            }"
        >
            <v-hover v-slot="{ hover }" open-delay="100" close-delay="100">
                <div class="ma-0 pa-0">
                    <EqsBadge
                        :value="infoTxt"
                        color="transparent"
                        offset-x="1rem"
                        offset-y="0.35rem"
                    >
                        <template #badge>
                            <v-tooltip
                                open-on-hover
                                open-on-focus
                                :disabled="!infoTxt"
                                top
                                max-width="18rem"
                            >
                                <template #activator="{ on }">
                                    <v-icon size="1.6rem" color="primary" v-on="on">
                                        mdi-information
                                    </v-icon>
                                </template>
                                {{ infoTxt }}
                            </v-tooltip>
                        </template>
                        <v-card
                            :el-class="`${elClass}-card`"
                            :elevation="!hover || isDisabled ? cardElevation : 6"
                            :class="{
                                'on-hover': !getData.notClickable && hover && !isDisabled,
                                'on-selected': !getData.notClickable && value && !isDisabled
                            }"
                            v-bind="{
                                ...$attrs,
                                width,
                                height,
                                loading: localLoading,
                                disabled: isDisabled
                            }"
                            @[clickEvent]="executeAction"
                        >
                            <v-card-title
                                v-if="$slots.title || title"
                                class="eqs-clickable-card__title"
                                :el-class="`${elClass}-card-title`"
                            >
                                <div v-if="$slots.title">
                                    <slot name="title" />
                                </div>
                                <div v-else>
                                    <EqsTextDisplay eqs-type="title" :value="title" />
                                </div>
                            </v-card-title>
                            <v-card-subtitle v-if="$slots.subtitle || subtitle">
                                <div v-if="$slots.subtitle">
                                    <slot name="subtitle" />
                                </div>
                                <div v-else>
                                    <EqsTextDisplay
                                        :eqs-type="subtitle_type ? subtitle_type : 'subtitle'"
                                        :value="subtitle"
                                    />
                                </div>
                            </v-card-subtitle>
                            <v-card-text class="fill-width">
                                <div v-if="$slots.text" class="d-flex fill-height">
                                    <slot name="text" />
                                </div>
                                <div v-else>
                                    <EqsTextDisplay paragraph :value="text" />
                                </div>
                            </v-card-text>
                        </v-card>
                    </EqsBadge>
                </div>
            </v-hover>
        </EqsSkeleton>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsClickableCard.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsClickableCard",
    components: {
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue"),
        EqsBadge: () => import("../../atoms/EqsBadge/EqsBadge.vue"),
        EqsSkeleton: () => import("../../atoms/EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-clickable-card"
        },
        title: {
            type: String,
            default: ""
        },
        subtitle: {
            type: String,
            default: ""
        },
        subtitle_type: {
            type: String
        },
        text: {
            type: String,
            default: ""
        },
        value: {
            type: Boolean,
            default: false
        },
        action: {
            type: Function,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        infoTxt: {
            type: String,
            default: ""
        },
        width: {
            type: String,
            default: ""
        },
        height: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            localLoading: false
        };
    },
    computed: {
        cardElevation() {
            if (this.getData.notClickable) return 1;
            return 3;
        },
        clickEvent() {
            return !this.getData.notClickable ? "click" : null;
        },
        isDisabled() {
            return this.loading || this.localLoading || this.disabled || this.value;
        },
        additionalStyle() {
            let style = {};
            if (this.width) style["--card-width"] = this.width;
            if (this.height) style["--card-height"] = this.height;
            return style;
        }
    },
    methods: {
        async executeAction($event) {
            if (!this.action) return this.$emit("click", $event);
            this.localLoading = true;
            this.$emit("update:loading", true);
            try {
                if (this.action) await this.action();
            } catch (e) {
                this.$emit("error", e);
            } finally {
                this.$emit("update:loading", false);
                this.localLoading = false;
                this.$emit("action:finished");
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsClickableCard.scss";
</style>
