import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {},
        display: {}
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            group: true,
            border: true
        },
        display: {
            editable: false
        }
    };
};
