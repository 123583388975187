import { Position, positionComponent } from "../Position";
import { Style, GREY, BLUE } from "../Style";
import { Xls } from "../Xls";
import { i18n } from "@/services/plugins/i18n.js";

export function companyInformation(ws, dataCompany) {
    const xls = new Xls(ws, positionComponent.companyInformation);

    const companyInformation = [
        {
            key: i18n.t("excel-js.legal-name"),
            value: dataCompany.name
        },
        {
            key: i18n.t("excel-js.company-type"),
            value: dataCompany.legal_type
        },
        {
            key: i18n.t("excel-js.company-id-code"),
            value: dataCompany.company_idcode
        },
        {
            key: i18n.t("excel-js.address"),
            value: `${dataCompany.street} ${dataCompany.zipcode} ${dataCompany.city}`
        },
        {
            key: i18n.t("excel-js.date-of-incorporation"),
            value: dataCompany.incorporation_date
        }
    ];

    companyInformation.forEach((info, iCol) => {
        xls.apply(xls.cellFactory(info.key, Style.info.key), iCol);
        xls.apply(xls.cellFactory(info.value, Style.info.value), iCol, 1);
    });
}
