export const rolesProps = {
    admin: {
        color: "red"
    },
    lawyer: {
        color: "blue"
    },
    custodian: {
        color: "indigo"
    },
    notary: {
        color: "orange"
    },
    accountant: {
        color: "green"
    },
    cgp: {
        color: "purple"
    }
};

export class RoleService {
    constructor(api, store) {
        if (!api) {
            throw new Error("Missing `api` option");
        }
        this.api = api;
        if (!store) {
            throw new Error("Missing `store` option");
        }
        this.store = store;
    }
    async getCgpRole(impersonateId, fetch = true) {
        let newRole = this.store.getters.cgpRoles.find(role => {
            return role.entity_represented.id === impersonateId;
        });
        if (fetch || !newRole || newRole.role_name !== "cgp") {
            // console.warn(
            //     `ROLE - no cgp roles found with idEntity = ${impersonateId}, fetching data...`
            // );
            const res = await this.api.getCgpRoleFromInvestor(impersonateId);
            newRole = res.data;
            this.store.commit("ADD_CGP_ROLES", newRole);
        }
        return this.store.getters.cgpRoles.find(role => role.id === newRole.id);
    }
    // used to either get updated role/entity or change role to an other entity
    async updateMyCurrentRole(entityId, fetch = true) {
        /*
            Quickfix : en cas de reload sur un impersonate, puis retour au cgp
            Il faut delete côté front les rôles d'impersonate pour qu'ils ne s'affichent pas dans les rôles du cgp/admin
        */
        this.store.dispatch("cleanImpersonateRoles");
        const myRoles = this.store.getters.myRoles;
        let currentRole = myRoles.find(role => {
            return role.entity_represented.id === entityId;
        });
        if (!currentRole) {
            // Check in cgp list
            currentRole = await this.getCgpRole(entityId, fetch);
            if (!currentRole) {
                console.warn(`no roles found with entityId = ${entityId}`);
                return;
            }
        }
        const updatedRole = fetch
            ? await this.store.dispatch("getMyRole", currentRole.id)
            : currentRole;
        this.store.commit("SET_MY_CURRENT_ROLE", updatedRole);
        if (updatedRole.entity_represented.id === updatedRole.user.id) {
            this.store.commit("SET_MY_ENTITY", updatedRole.user);
        }
        return updatedRole;
    }

    changeMyCurrentRole(entityId) {
        const myRoles = this.store.getters.myRoles;
        const currentRole = myRoles.find(role => {
            return role.entity_represented.id === entityId;
        });
        if (!currentRole) {
            console.warn(`no roles found with entityId = ${entityId}`);
            return;
        }
        this.store.commit("SET_MY_CURRENT_ROLE", currentRole);
    }
}
