import { parsePhoneNumberFromString } from "libphonenumber-js";
import { arrayOrStringEmpty } from "../../../shared/util";

export const bicR = value => {
    return (
        /^[A-Za-z0-9]{4}[A-Za-z]{2}[A-Za-z0-9]{2}(?:[A-Za-z0-9]{3})?$/.test(value) ||
        window.app.$t("rules.invalid-format")
    );
};

export const ibanR = value => {
    return (
        /[A-Za-z]{2}[0-9]{2}([ ]?[0-9]{4}){4,5}([ ]?[0-9]{1,3})?/.test(value) ||
        window.app.$t("rules.invalid-format")
    );
};

export const bankR = value => {
    return (!!value && value.length > 2) || window.app.$t("rules.invalid-format");
};

export const peaNumberR = value => {
    return (!!value && value.length > 5) || window.app.$t("rules.invalid-format");
};

export const phoneR = value => {
    if (!value) {
        return true;
    }
    const phoneNumber = parsePhoneNumberFromString(value);
    return (
        (!!value &&
            !!phoneNumber &&
            !!phoneNumber.country &&
            !!phoneNumber.number &&
            phoneNumber.isValid()) ||
        window.app.$t("rules.enter-valid-phone-number")
    );
};

export const requiredR = value => {
    return value === 0 || !arrayOrStringEmpty(value) || window.app.$t("rules.required");
};

export const tickerR = value => {
    return /^[0-9A-Z]{1,8}$/.test(value) || window.app.$t("rules.ticker");
};

export const numberR = value => {
    return (!!value && /^[0-9]+$/.test(value)) || window.app.$t("rules.number-required");
};

export const min8R = value =>
    (value && value.length >= 8) || window.app.$t("rules.min-8-characters");

export const protectedCharR = value => {
    if (!value) {
        return true;
    }
    const regex = /^([\u20A0-\u20CF]|[a-zA-ZÀ-ÿ\d ]|['\".,\?\!:@=+,\-_\(\)\[\]]|[\n])+/;
    const res = regex.test(value);

    let error = RegExp.rightContext;
    if (!!error) return window.app.$t("rules.protectedChar", { 0: error[0] });
    return true;
};

export const passwordR = value => {
    if (!value) {
        return true;
    }
    return (
        (!!value &&
            /[a-z]/.test(value) &&
            /[A-Z]/.test(value) &&
            /[0-9]/.test(value) &&
            /[&$^*.[\]{}()?\-"!@#%/,><':;|_~`\u00c0-\uffe0]/.test(value) &&
            /^[a-zA-Z0-9^&$^*.[\]{}()?\-"!@#%/,><':;|_~`\u00c0-\uffe0]{12,100}$/.test(value)) ||
        window.app.$t("rules.password")
    );
};

export const nameR = value => {
    if (!value) {
        return true;
    }
    return !!/^[0-9a-zA-ZÀ-ÿ-' &#.,]*$/.test(value) || window.app.$t("rules.invalid-name");
};

export const zipcodeR = value => {
    if (!value) {
        return true;
    }
    let str = value.toString();
    return (
        (/^[a-zA-Z0-9_ -]*$/.test(str) && str.length >= 4 && str.length <= 10) ||
        window.app.$t("rules.invalid-zipcode")
    );
};

export const emailR = value => {
    if (!value) {
        return true;
    }
    return (
        (!!value &&
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                value
            )) ||
        window.app.$t("rules.invalid-email-format")
    );
};

export const max20R = value => {
    if (!value) {
        return true;
    }
    return (value && value.length <= 20) || window.app.$t("rules.max-20-characters");
};

export const max100R = value => {
    if (!value) {
        return true;
    }
    return value.length <= 100 || window.app.$t("rules.max-100-characters");
};

export const numberOnlyR = value => {
    if (!value) {
        return true;
    }
    return /^[0-9]*$/.test(value) || window.app.$t("rules.only-digits");
};

export const agreeR = value => !!value || window.app.$t("rules.agree-terms");

export const equal6R = value =>
    (value && value.length === 6) || window.app.$t("rules.confirmation-code-equalR");

export const equal6NumbersR = value =>
    (value && /^[0-9]{6}$/.test(value)) || window.app.$t("rules.confirmation-code-equai6-number-r");

export const ethAddressR = value => {
    if (!value) {
        return true;
    }
    return (
        (value && /^0x[0-9A-Fa-f]{40}$/.test(value)) || window.app.$t("rules.invalid-eth-address")
    );
};

export const greaterThanZeroR = value =>
    !!(window.app.$currency.getValue(value) > 0) || window.app.$t("rules.value-greater-than-zero");

export const greaterThanOrEqualZeroR = value =>
    !!(window.app.$currency.getValue(value) >= 0.0) ||
    window.app.$t("rules.value-greater-than-or-equal-zero");

export const differentFromZeroR = value =>
    !!(window.app.$currency.getValue(value) != 0.0) ||
    window.app.$t("rules.value-different-from-zero");

export const integerR = value => /^[0-9]*$/.test(value) || window.app.$t("rules.value-integer");

// ''

export const validUrlR = value => {
    if (!value) return true;
    // var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    var pattern = new RegExp(
        "^(https)://?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return pattern.test(value) || window.app.$t("rules.url-not-valid");
};
