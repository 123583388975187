import {
    tableList,
    shareholderTable,
    headerWithHeaderStyle,
    headerWithContentStyle
} from "./tableConfig";
import { positionComponent } from "../Position";
import { Xls } from "../Xls";
import { assetMovementType } from "../utils";

const assetMovementValue = (str, am) => {
    switch (str) {
        case "date":
            return window.app.$time.formated(am.confirmed_datetime, true);
        case "orderNumber":
            return am.order_number || "N/A";
        case "txHash":
            return am.txhash;
        case "sharledgerName":
            return am.shareledger.name;
        case "shareledgerAddress":
            return am.shareledger.addtoken_tx.contract_address;
        case "nameOwner":
            return am.investor_source && am.investor_source.name;
        case "accountOwner":
            return am.investor_source && am.investor_source.account_address;
        case "orderNature":
            return assetMovementType(am.operation);
        case "numberOfShares":
            return am.numberofshares;
        case "totalPrice":
            return am.numberofshares * am.pricepershare;
        case "nameReceiver":
            return am.investor_destination && am.investor_destination.name;
        case "accountReceiver":
            return am.investor_destination && am.investor_destination.account_address;
        case "observation":
            return am.description;
        default:
            return null;
    }
};

export const ledgerInfoContent = (str, ledger) => {
    switch (str) {
        case "Name":
            return ledger.name;
        case "TotalShares":
            return ledger.numberofshares;
        case "ContractAddress":
            return ledger.contract_address;
        default:
            return null;
    }
};

export function accountabilityShare(type, am, balance, shId) {
    if (
        type == "Credit" &&
        am.investor_destination &&
        am.investor_destination.id &&
        shId == am.investor_destination.id
    ) {
        balance.solde += am.numberofshares;
        return am.numberofshares;
    } else if (
        type == "Debit" &&
        am.investor_source &&
        am.investor_source.id &&
        shId == am.investor_source.id
    ) {
        balance.solde -= am.numberofshares;
        return am.numberofshares;
    }
}

export const contentShareholderValue = (str, am, balance, shId) => {
    switch (str) {
        case "numero":
            return am.order_number || "N/A";
        case "txHash":
            return am.txhash;
        case "Date":
            return window.app.$time.formated(am.confirmed_datetime, true);
        case "Operator":
            return assetMovementType(am.operation);
        case "Credit":
            return accountabilityShare("Credit", am, balance, shId);
        case "Debit":
            return accountabilityShare("Debit", am, balance, shId);
        case "Solde":
            return balance.solde;
        case "observation":
            return am.description;
        default:
            return null;
    }
};

export function createTableContent(ws, shareledgerData) {
    const xls = new Xls(ws, positionComponent.tableContent);
    shareledgerData.forEach((assetMovement, iRow) => {
        headerWithContentStyle(tableList).forEach((header, iCol) => {
            const cell = {
                ...header,
                value: assetMovementValue(header.key, assetMovement)
            };
            xls.apply(cell, iCol, iRow);
        });
    });
}

export function createTableHeader(ws) {
    const xls = new Xls(ws, positionComponent.tableHeader);
    const parseTable = table => {
        for (let i = 0, cell = table[i]; i < table.length; cell = table[++i]) {
            if (cell.children) {
                xls.apply(cell, 0, -1)
                    .area([0, -1], [1, -1])
                    .merge()
                    .apply(cell.children[0])
                    .col(1)
                    .apply(cell.children[1])
                    .col(1);
            } else xls.apply(cell).col(1);
        }
    };
    parseTable(headerWithHeaderStyle(tableList));
    ws.pageSetup.printTitlesRow = "1:8";
}
