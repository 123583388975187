<template>
    <v-container fluid class="root-step-container">
        <h2 class="text-h5 mb-4">Objectifs d’investissement et situation financière</h2>
        <EqsRadio
            :value.sync="formData.p2q1"
            row
            v-bind="{
                label: 'Avez-vous des attentes spécifiques en matière de durabilité de votre investissement ?',
                ...schemaDouble.p2q1
            }"
        />
        <div v-show="formData.p2q1 === 'yes'" class="pa-0 ma-0">
            <EqsSelect
                :value.sync="formData.p2q2"
                multiple
                v-bind="{
                    label: 'Si oui, lesquelles ?',
                    items: q2Items,
                    ...schemaDouble.p2q2
                }"
            />
        </div>
        <EqsSelect
            :value.sync="formData.p2q3"
            v-bind="{
                label: 'Quelle perte de montants investis pouvez-vous supporter financièrement ?',
                items: q3Items,
                ...schemaDouble.p2q3
            }"
        />
        <EqsSelect
            :value.sync="formData.p2q4"
            v-bind="{
                label: 'Quelle est l’origine principale de vos capitaux ?',
                items: q4Items,
                ...schemaDouble.p2q4
            }"
        />
        <EqsSelect
            :value.sync="formData.p2q5"
            v-bind="{
                label: 'Pour quelle raison souhaitez-vous investir sur la plateforme ?',
                items: q5Items,
                ...schemaDouble.p2q5
            }"
        />
        <EqsRadio
            :value.sync="formData.p2q6"
            row
            v-bind="{
                label: 'Votre situation financière actuelle vous permet-elle d’épargner une partie de vos revenus ?',
                ...schemaDouble.p2q6
            }"
        />
        <div v-show="formData.p2q6 === 'yes'" class="pa-0 ma-0">
            <EqsNumber
                :value.sync="formData.p2q7"
                :is-currency="false"
                :is-percentage="true"
                v-bind="{
                    label: 'Quelle quote-part de votre patrimoine financier est liquide et disponible ?',
                    editable: true,
                    placeholder: 'Ex: 5%',
                    ...schemaDouble.p2q7
                }"
            />
        </div>
        <div v-show="formData.p2q6 === 'yes'" class="pa-0 ma-0">
            <EqsNumber
                :value.sync="formData.p2q8"
                :is-currency="false"
                :is-percentage="true"
                v-bind="{
                    label: 'Quelle est la part de vos remboursements d’emprunt dans votre revenu ?',
                    editable: true,
                    placeholder: 'Ex: 10%',
                    ...schemaDouble.p2q8
                }"
            />
        </div>
        <div v-show="formData.p2q6 === 'yes'" class="pa-0 ma-0">
            <EqsNumber
                :value.sync="formData.p2q9"
                :is-currency="false"
                :is-float="false"
                v-bind="{
                    label: 'Quels ont été vos revenus totaux sur l’année N-1?',
                    editable: true,
                    placeholder: 'Ex: 100 000',
                    ...schemaDouble.p2q9
                }"
            />
        </div>
        <EqsSelect
            :value.sync="formData.p2q10"
            v-bind="{
                label: 'Quelle est la durée souhaitée de votre investissement sur notre plateforme ?',
                items: q10Items,
                ...schemaDouble.p2q10
            }"
        />
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { doItems } from "./util";

export default {
    name: "Form2",
    mixins: [formMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            q2Items: doItems("Court terme", "Moyen terme", "Long terme"),
            q3Items: doItems("0%", "Moins de 50%", "100%"),
            q4Items: doItems("Salaire", "Retraite", "Héritage", "Investissements"),
            q5Items: doItems(
                "Diversier mon portefeuille",
                "Financer des projets qui m'impactent",
                "Faire fructifier mon épargne",
                "Etre acteur de la croissance économique"
            ),
            q10Items: doItems("Moins de 1 an", "Entre 1 an et 5 ans", "Plus de 5 ans")
        };
    },
    watch: {
        "formData.p2q1": {
            handler(newVal, oldVal) {
                this.schemaDouble.p2q2.isRequired = newVal === "yes";
            },
            immediate: true
        },
        "formData.p2q6": {
            handler(newVal, oldVal) {
                this.schemaDouble.p2q7.isRequired = newVal === "yes";
                this.schemaDouble.p2q8.isRequired = newVal === "yes";
                this.schemaDouble.p2q9.isRequired = newVal === "yes";
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
