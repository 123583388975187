<template>
    <EqsCard
        v-bind="{
            subtitle: $t('governance.organs.details.voting.title'),
            loading
        }"
    >
        <div class="d-flex flex-column">
            <v-row class="px-3">
                <v-col :cols="$reactive.isMobile() || !(voters && voters.length) ? 12 : 8">
                    <VotingTable v-bind="{ organ, loading }" />
                </v-col>
                <v-col v-if="voters && voters.length" :cols="$reactive.isMobile() ? 12 : 4">
                    <EqsPie
                        style="height: 250px; width: 100%"
                        :config="pieChartConfig()"
                        :display-legend="false"
                    />
                </v-col>
            </v-row>
        </div>
    </EqsCard>
</template>

<script>
import { calcPercent } from "@equisafe-ui-shared/util";
import VotingTable from "./VotingTable.vue";

export default {
    name: "Voting",
    components: {
        VotingTable
    },
    props: {
        organ: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        voters() {
            return this.organ.members
                ? this.organ.members
                      .filter(member => member.is_voter)
                      .map(mb => {
                          return {
                              ...mb,
                              percent: calcPercent(mb.nb_vote, this.totalNumberOfVotes)
                          };
                      })
                : [];
        },
        totalNumberOfVotes() {
            if (this.organ.members)
                return this.organ.members.reduce((acc, mb) => {
                    return acc + mb.nb_vote;
                }, 0);
            return 0;
        }
    },

    methods: {
        pieChartConfig() {
            let labels = this.setPieChartLabels();
            let data = this.setPieChartData();
            let infos = this.setPieChartInfos();
            return {
                defaults: {
                    global: {
                        legend: {
                            display: false
                        }
                    }
                },
                labels,
                data,
                infos,
                name: {
                    formatter: (value, ctx) => this.formatNamePieChart(ctx)
                },
                value: {
                    formatter: (value, ctx) => this.formatValuePieChart(value, ctx)
                },
                datalabels: {
                    display: ctx => this.displayPieChartDataLabels(ctx)
                }
            };
        },
        setPieChartLabels() {
            let labels = [];
            this.voters.forEach(voter => {
                labels.push(voter.investor.name);
            });
            return labels;
        },
        setPieChartInfos() {
            let infos = [];
            this.voters.forEach(voter => {
                let info = {
                    name: voter.investor.name,
                    percentage: voter.percent,
                    nb_votes: `${this.$currency.display(voter.nb_vote, "0,0")} ${this.$t(
                        "label.votes"
                    )}`
                };
                infos.push(info);
            });
            return infos;
        },
        setPieChartData() {
            let data = [];
            this.voters.forEach(voter => {
                data.push(voter.percent);
            });
            return data;
        },
        displayPieChartDataLabels(ctx) {
            return ctx.dataset.data[ctx.dataIndex] >= 5.0;
        },
        formatNamePieChart(ctx) {
            return ctx.dataset.datalabels.infos[ctx.dataIndex].name;
        },
        formatValuePieChart(value, ctx) {
            return ctx.active ? ctx.dataset.datalabels.infos[ctx.dataIndex].nb_votes : value + " %";
        }
    }
};
</script>
