var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({},'EqsCard',{
        subtitle: _vm.$t('governance.organs.details.other-info.title'),
        loading: _vm.loading
    },false),[_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',_vm._b({},'EqsText',{
                        label: _vm.$t('governance.organs.details.other-info.name'),
                        editable: false,
                        value: _vm.organ.name
                    },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',_vm._b({},'EqsText',{
                        label: _vm.$t('governance.organs.details.other-info.initials'),
                        editable: false,
                        value: _vm.organ.initials
                    },false))],1),(_vm.organ.vote_type)?_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',_vm._b({},'EqsText',{
                        label: _vm.$t('governance.organs.details.other-info.vote_type'),
                        editable: false,
                        value: _vm.vote_types[_vm.organ.vote_type].text
                    },false))],1):_vm._e(),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsDate',_vm._b({},'EqsDate',{
                        label: _vm.$t('governance.organs.details.other-info.creation.label'),
                        editable: false,
                        value: _vm.organ.created_at
                    },false))],1),(_vm.organ.shareledgers && _vm.organ.shareledgers.length)?_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                        value: _vm.$t('governance.organs.details.other-info.ledgers'),
                        editable: false
                    },false),[_c('div',{staticClass:"d-flex"},_vm._l((_vm.organ.shareledgers),function(share,index){return _c('EqsChip',{key:index,staticClass:"mx-1",attrs:{"text-color":"text","color":_vm.$color.colorFromHash(share.name)}},[_vm._v(" "+_vm._s(share.name)+" ")])}),1)])],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }