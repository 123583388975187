import { i18n } from "@/services/plugins/i18n.js";

export const statutes = {
    pending: {
        logo: "/images/equisafe-logo-orange.svg",
        title: i18n.t("crud.pending").toUpperCase(),
        display: true,
        color: "warning",
        id: "pending",
        message: i18n.t("user.wallet.pending-msg")
    },
    error: {
        logo: "/images/equisafe-logo-red.svg",
        title: i18n.t("error.error").toUpperCase(),
        color: "error",
        id: "error",
        message: i18n.t("user.wallet.money-out.error-msg")
    },
    cancel: {
        logo: "/images/equisafe-logo-blue.svg",
        title: i18n.t("label.cancelation").toUpperCase(),
        color: "info",
        id: "cancel",
        message: i18n.t("user.buy.cancelation-msg")
    },
    success: {
        logo: "/images/equisafe-logo-green.svg",
        title: i18n.t("label.success").toUpperCase() + " !",
        display: true,
        color: "success",
        id: "success",
        message: i18n.t("user.wallet.money-out.success-msg")
    }
};

export const schemaDoubleStep1 = {
    // totalWithdrawal: {
    moneyVal: {
        isRequired: true
    }
};
