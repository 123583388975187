import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_SHARELEDGERS_START");
    if (state.shareledgersInit && !state.shareledgersLoading) {
        try {
            commit("SET_SHARELEDGERS_LOADING", true);
            const shareledgersRes = await window.app.$api.companyShareledgerList(
                store.getters.currentRole.entity_represented.id
            );
            const shareledgers = shareledgersRes.data;
            DEBUG("GET_SHARELEDGERS_SUCCESS");
            commit("SET_SHARELEDGERS", shareledgers);
        } catch (e) {
            DEBUG("GET_SHARELEDGERS_ERROR");
            window.app.$ui.error(e, "company_get_shareledgers");
            throw e;
        } finally {
            commit("SET_SHARELEDGERS_LOADING", false);
        }
    } else {
        DEBUG("GET_SHARELEDGERS_BYPASSED");
    }
};
