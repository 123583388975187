var Decimal = require("decimal.js-light");

export function getSelectedPayment(order) {
    if (!order || !order.payment_data) return null;
    const paymentSelected = Object.entries(order.payment_data).find(e => {
        if (e[1] !== null) return true;
    });
    return paymentSelected ? { name: paymentSelected[0], data: paymentSelected[1] } : null;
}

export function getPaymentLabelFromId(ctx, id) {
    switch (id) {
        case "walletdata":
            return ctx.$t("user.buy.steps.helper.wallet");
        case "cryptodata":
            return ctx.$t("user.buy.steps.helper.crypto");
        case "wiretransfertdata":
            return ctx.$t("user.buy.steps.helper.wiretransfert");
        case "sepadata":
            return ctx.$t("user.buy.steps.helper.sepa");
        case "sepaautodata":
            return "SEPA GoCardless";

        default:
            return ctx.$t("user.buy.steps.helper.unknown");
    }
}

export function getPaymentInfoFromId(ctx, id) {
    switch (id) {
        case "walletdata":
            return ctx.$t("user.buy.steps.helper.wallet-infos");
        case "cryptodata":
        case "wiretransfertdata":
        case "sepadata":
        case "sepaautodata":
        default:
            return null;
    }
}

export function getTaxLabelFromId(ctx, id) {
    switch (id) {
        case "none":
            return ctx.$t("user.buy.steps.helper.none");
        case "reduc_ir":
            return "Réduction d’Impôt sur le Revenu (article 163 quinquies B du Code général des impôts)";
        case "report_impo":
            return "Absence de Prise en Compte des écarts de valeur liquidative du résultat imposable (article 209-0 A du Code général des impôts)";
        case "absence_ecart":
            return "Report d’imposition de la plus-value d’apport des titres d’une société à une autre société (article 150-0 B ter du Code général des impôts)";

        default:
            return "Inconnu";
    }
}

export function getEnvelopeLabelFromId(ctx, id) {
    switch (id) {
        case "none":
            return "titres conservés par le dépositaire du fonds";
        case "pea":
            return "nominatif administré, titres conservés sur votre compte Plan Epargne Action";
        case "pea-pme":
            return "nominatif administré, titres conservés sur votre compte Plan Epargne Action PME et des ETI";
        case "share-account":
            return "nominatif administré, titres conservés sur votre compte titres";
        default:
            return "Inconnu";
    }
}

export function getShortTaxLabelFromId(ctx, id) {
    switch (id) {
        case "none":
            return "Aucun";
        case "reduc_ir":
            return "IR-PME";
        case "report_impo":
            return "APC";
        case "absence_ecart":
            return "Apport Cession";
        default:
            return "Inconnu";
    }
}

export function getLinkTaxFromId(id) {
    switch (id) {
        case "reduc_ir":
            return "https://equisafe-faq.notion.site/IR-PME-375f7239bf134e49b8a9d92452807e39?pvs=4";
        case "report_impo":
            return "https://equisafe-faq.notion.site/Absence-de-prise-en-compte-des-carts-de-valeur-liquidative-du-r-sultat-imposable-0fff0abd37694bd881bdc966b6dea63d?pvs=4";
        case "absence_ecart":
            return "https://equisafe-faq.notion.site/Optimisation-fiscale-par-le-biais-de-l-apport-cession-Article-150-0-B-ter-du-CGI-f26d6c6f934545a8a2818e74bca132d4?pvs=4";
        default:
            return "";
    }
}

export function getShortEnvelopeLabelFromId(ctx, id) {
    switch (id) {
        case "none":
            return "Nominatif pur";
        case "pea":
            return "PEA";
        case "pea-pme":
            return "PEA-PME";
        case "share-account":
            return "Compte-Titres";
        default:
            return "Inconnu";
    }
}

export function getLinkEnvelopeFromId(id) {
    switch (id) {
        case "pea":
            return "https://equisafe-faq.notion.site/Compte-de-PEA-et-PEA-PME-404513e0b1ae47c99e222fd39bba05e5?pvs=4";
        case "pea-pme":
            return "https://equisafe-faq.notion.site/Compte-de-PEA-et-PEA-PME-404513e0b1ae47c99e222fd39bba05e5?pvs=4";
        case "share-account":
            return "";
        default:
            return "";
    }
}

export function getCryptoAmountStr(orderPrice, cryptoRate, decimals) {
    const rate = new Decimal(cryptoRate);
    const amount = new Decimal(orderPrice);
    let cryptoAmount = amount.dividedBy(rate);
    // Round only for USDT/USDC
    cryptoAmount = cryptoAmount.toDecimalPlaces(2, Decimal.ROUND_DOWN);
    cryptoAmount = cryptoAmount.toString().replace(".", ",");
    if (cryptoAmount.includes(",")) {
        cryptoAmount = cryptoAmount.replace(/^0+/, "").replace(/0+$/, "");
    }
    if (cryptoAmount[cryptoAmount.length - 1] === ",") {
        cryptoAmount = cryptoAmount.slice(0, -1);
    }
    if (cryptoAmount[0] === ",") {
        cryptoAmount = `0${cryptoAmount}`;
    }
    return cryptoAmount.replace(",", ".");
}

export function getEurToCryptoRate(rate) {
    const euroPerCrypto = new Decimal(rate);
    const inversedRate = new Decimal(1).dividedBy(euroPerCrypto);
    return inversedRate;
}

export function getShareledgerTypeStr(ctx, shareledger) {
    switch (shareledger?.share_type) {
        case "shares":
            return ctx.$t("shareledger.dialog.stepper.tokenize.label-type-shares");
        case "cca":
            return ctx.$t("shareledger.dialog.stepper.tokenize.label-type-cca");
        case "units":
            return ctx.$t("shareledger.dialog.stepper.tokenize.label-type-units");
        case "bonds":
            return ctx.$t("shareledger.dialog.stepper.tokenize.label-type-bonds");
        case "bsa":
            return ctx.$t("shareledger.dialog.stepper.tokenize.label-type-bsa");
        case "bspce":
            return ctx.$t("shareledger.dialog.stepper.tokenize.label-type-bspce");
        case "bsa_air":
            return ctx.$t("shareledger.dialog.stepper.tokenize.label-type-bsa_air");
        case "aga":
            return ctx.$t("shareledger.dialog.stepper.tokenize.label-type-aga");
        case "tokens":
            return ctx.$t("shareledger.dialog.stepper.tokenize.label-type-tokens");
        case "carbo":
            return ctx.$t("shareledger.dialog.stepper.tokenize.label-type-carbo");

        default:
            return "-";
    }
}
