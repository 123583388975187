<template>
    <div class="pa-0 ma-0">
        <v-card>
            <v-card-title class="d-flex flex-column align-start mb-4">
                <div class="document-title" :class="{ 'arthaud-font': $universe.isArthaud() }">
                    {{ $t("capital-increase.user.detail.user-members-title") }}
                </div>
                <div class="title-line" />
            </v-card-title>
            <v-card-text>
                <div v-if="team && team.length > 0">
                    <h3 class="text-h5 font-weight-bold">
                        {{ $t("capital-increase.user.detail.user-team-title") }}
                    </h3>
                    <v-row class="px-2">
                        <v-col
                            v-for="member in team"
                            :key="member.id"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                        >
                            <SinglePeopleDisplay :item="member" :loading="loading" />
                        </v-col>
                    </v-row>
                </div>
                <div v-if="advisor && advisor.length > 0">
                    <h3 class="text-h5 font-weight-bold">
                        {{ $t("capital-increase.user.detail.user-advisor-title") }}
                    </h3>
                    <v-row class="px-2">
                        <v-col
                            v-for="member in advisor"
                            :key="member.id"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                        >
                            <SinglePeopleDisplay :item="member" :loading="loading" />
                        </v-col>
                    </v-row>
                </div>
                <div v-if="partner && partner.length > 0">
                    <h3 class="text-h5 font-weight-bold">
                        {{ $t("capital-increase.user.detail.user-partner-title") }}
                    </h3>
                    <v-row class="px-2">
                        <v-col
                            v-for="member in partner"
                            :key="member.id"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                        >
                            <SinglePeopleDisplay :item="member" :loading="loading" />
                        </v-col>
                    </v-row>
                </div>
                <!-- <v-row class="px-2">
                    <v-col v-if="team.length" :cols="$reactive.getCols('half')">
                        <GlobalPeopleList
                            eqs-type="display"
                            v-bind="{
                                peoples: team,
                                label: $t('capital-increase.user.detail.user-team-title')
                            }"
                        />
                    </v-col>
                    <v-col v-if="advisor.length" :cols="$reactive.getCols('half')">
                        <GlobalPeopleList
                            eqs-type="display"
                            v-bind="{
                                peoples: advisor,
                                label: $t('capital-increase.user.detail.user-advisor-title')
                            }"
                        />
                    </v-col>
                    <v-col v-if="partner.length" :cols="$reactive.getCols('half')">
                        <GlobalPeopleList
                            eqs-type="display"
                            v-bind="{
                                peoples: partner,
                                label: $t('capital-increase.user.detail.user-partner-title')
                            }"
                        />
                    </v-col>
                </v-row> -->
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import SinglePeopleDisplay from "./SinglePeopleDisplay.vue";

export default {
    name: "Members",
    components: { SinglePeopleDisplay },
    props: {
        team: {
            type: Array,
            default: () => []
        },
        advisor: {
            type: Array,
            default: () => []
        },
        partner: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            // allMembers: [],
            // advisorFixture: [
            //     {
            //         category: "advisor",
            //         id: "1",
            //         linkedin: "https://linkedin.com/in/AdvisorOne",
            //         name: "Conseiller One",
            //         photo: {
            //             upload: "/images/logo.svg"
            //         },
            //         role: "advisor"
            //     },
            //     {
            //         category: "advisor",
            //         id: "2",
            //         linkedin: "https://linkedin.com/in/AdvisorTwo",
            //         name: "Conseiller Two",
            //         photo: {
            //             upload: "/images/logo.svg"
            //         },
            //         role: "advisor"
            //     }
            // ],
            // partnerFixture: [
            //     {
            //         category: "partner",
            //         id: "3",
            //         linkedin: "https://linkedin.com/in/PartnerOne",
            //         name: "Partenaire One",
            //         photo: {
            //             upload: "/images/logo.svg"
            //         },
            //         role: "particulier"
            //     },
            //     {
            //         category: "partner",
            //         id: "4",
            //         linkedin: "https://linkedin.com/in/PartnerTwo",
            //         name: "Partenaire Two",
            //         photo: {
            //             upload: "/assets/company.svg"
            //         },
            //         role: "particulier"
            //     }
            // ]
        };
    },
    mounted() {
        // this.allMembers = [...this.team, ...this.advisor, ...this.partner];
    }
};
</script>

<style lang="scss" scoped>
.arthaud-font {
    font-family: IvyPresto Display !important;
    font-size: 2.5rem !important;
}
.document-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 63px;
    letter-spacing: 0em;
    text-align: left;
}

.title-line {
    width: 70px;
    height: 2px;
    background-color: #b89c62;
    margin-top: 10px;
}
</style>
