import { intervalToDuration, formatDuration } from "date-fns";
import formatDistance from "date-fns/formatDistance";
import isPast from "date-fns/isPast";
import isBefore from "date-fns/isBefore";
import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import addDays from "date-fns/addDays";
import addYears from "date-fns/addYears";
import subYears from "date-fns/subYears";
import getUnixTime from "date-fns/getUnixTime";
import enGB from "date-fns/locale/en-GB";
import fr from "date-fns/locale/fr";

const locales = { enGB, fr };

export const convertMinToMs = minutes => {
    const res = (parseFloat(minutes) * 60000).toFixed(0);
    return parseInt(res);
};

export class TimeService {
    constructor() {}
    getLocale() {
        return locales[window.app.$language ? window.app.$language.current() : "en"];
    }
    format(date, formatStr = "PP") {
        return (
            date,
            formatStr,
            {
                locale: this.getLocale()
            }
        );
    }
    minutesToMs(minutes) {
        return convertMinToMs(minutes);
    }
    formatISO(date) {
        return formatISO(date);
    }
    getUnixTime(date) {
        return getUnixTime(this.toDate(date));
    }
    nowISO() {
        const newDate = new Date();
        return newDate.toISOString();
    }
    subYears(date, years) {
        return subYears(this.toDate(date), years);
    }
    addDays(date, days) {
        return addDays(this.toDate(date), days);
    }
    addYears(date, years) {
        return addYears(this.toDate(date), years);
    }
    getUTCDate(date) {
        const toDate = new Date(date);

        const UTCdate = new Date(
            toDate.getUTCFullYear(),
            toDate.getUTCMonth(),
            toDate.getUTCDate(),
            toDate.getUTCHours(),
            toDate.getUTCMinutes(),
            toDate.getUTCSeconds()
        );
        return format(UTCdate, "eeee, MMMM do yyyy, h:mm:ss");
    }
    customFormatDuration(start, end) {
        const durations = intervalToDuration({ start, end });
        return formatDuration(durations);
    }
    today() {
        return new Date();
    }
    toDate(date) {
        const res = new Date(date);
        const errors = [
            "Invalid Date",
            "Thu Jan 01 1970 01:00:00 GMT+0100 (Central European Standard Time)",
            "Thu Jan 01 1970 01:00:00 GMT+0100 (heure normale d’Europe centrale)",
            "Thu Jan 01 1970 01:00:00 GMT+0100 (heure normale d'Europe centrale)"
        ];
        return !errors.includes(res.toString()) ? res : new Date(0);
    }
    fromNowHR(date, addSuffix = false) {
        return formatDistance(this.toDate(date), this.today(), {
            addSuffix,
            locale: this.getLocale()
        });
    }
    isPast(date) {
        return isPast(this.toDate(date));
    }
    isBefore(toCmp, date) {
        return isBefore(this.toDate(toCmp), this.toDate(date));
    }
    formated(date, withTime = false) {
        const formatDateStr =
            !window.app.$language || window.app.$language.current() === "en"
                ? "MM/dd/yyyy"
                : "dd/MM/yyyy";
        const _date = this.toDate(date);
        if (!_date) return "-";
        return format(_date, withTime ? `${formatDateStr}, h:mm:ss aa` : formatDateStr, {
            locale: this.getLocale()
        });
    }
    differenceInDays(date1, date2 = null) {
        return differenceInDays(this.toDate(date1), date2 ? this.toDate(date2) : Date.now());
    }
    duration(date1, date2) {
        return formatDistance(this.toDate(date1), this.toDate(date2), {
            locale: this.getLocale()
        });
    }
}

export const timeService = new TimeService();
