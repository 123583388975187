var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-main-container margin-auto"},[_c('GlobalFinalState',{attrs:{"loading":_vm.loading,"state":_vm.finalState,"title":_vm.statusText[_vm.order.status].title,"error-text":_vm.errorText},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-container',[_c('v-col',{attrs:{"cols":12}},[_c('EqsTextDisplay',{staticClass:"text-center",attrs:{"eqs-type":"subtitle","value":_vm.statusText[_vm.order.status].subtitle({
                                buyer: _vm.order.buyer_name,
                                seller: _vm.order.seller_name
                            })}})],1),_c('v-col',{attrs:{"cols":12}},[_c('EqsTextDisplay',{staticClass:"text-center",attrs:{"paragraph":"","value":_vm.statusText[_vm.order.status].paragraph({
                                buyer: _vm.order.buyer_name,
                                seller: _vm.order.seller_name,
                                amount: _vm.order.amount,
                                shareledger: _vm.order.shareledger_name
                            })}})],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }