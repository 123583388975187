var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsPanels',_vm._b({attrs:{"value":_vm.openPanelN},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [(item.is)?_c(item.is,_vm._b({tag:"component",attrs:{"profile":""}},'component',{
                ...item.props,
                schemaDouble: item.schemaDouble,
                formData: _vm.entity,
                loading: _vm.loading || _vm.loadingCountries,
                countries: _vm.countries,
                formType: 'medium',
                registrationInfos: _vm.registrationInfos
            },false)):_vm._e()]}}])},'EqsPanels',{ panels: _vm.panels },false))
}
var staticRenderFns = []

export { render, staticRenderFns }