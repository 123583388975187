var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`eqs-checkbox d-flex ${_vm.elClass}-container`},[_c('EqsLabel',_vm._b({},'EqsLabel',{
            ..._vm.$attrs,
            isRequired: _vm.isRequired,
            height: _vm.labelHeight,
            editable: _vm.editable,
            value: _vm.label,
            infoTxt: _vm.infoTxt
        },false),[_c('v-checkbox',_vm._g(_vm._b({class:`ma-0 ${_vm.elClass}-input`,attrs:{"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.loading)?_c('label',{staticClass:"v-label"},[_c('EqsSkeleton',_vm._b({staticClass:"pt-1",staticStyle:{"min-width":"120px"}},'EqsSkeleton',{ loading: _vm.loading, type: 'text' },false))],1):_vm._t("label")]},proxy:true}]),model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},'v-checkbox',{
                ..._vm.$attrs,
                ..._vm.$props,
                ..._vm.getData,
                rules: _vm.rulesRequired,
                readonly: !_vm.editable,
                label: _vm.text,
                disabled: _vm.disabled || _vm.loading
            },false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }