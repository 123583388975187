<template>
    <div>
        <EqsCrud
            :items.sync="peoplesProxy"
            two-line
            v-bind="{
                ...$attrs,
                eqsListType: 'avatar-two-line',
                add: addPerson,
                update: updatePerson,
                deleteFunc: removePerson,
                formInit: newPerson,
                loading
            }"
        >
            <template #group-tile="{ item }">
                <v-row justify="start" align-content="start">
                    <v-col cols="auto" class="py-0">
                        <EqsAvatar
                            v-bind="{
                                loading,
                                src: !!item.photo ? item.photo.upload : ''
                            }"
                        />
                    </v-col>
                    <v-col cols="auto" class="py-0 d-flex flex-column align-start justify-center">
                        <EqsTextDisplay v-bind="{ value: item.name }" />
                        <EqsTextDisplay class="text--secondary" v-bind="{ value: item.role }" />
                    </v-col>
                    <v-col v-if="item.linkedin" class="d-flex justify-start align-center">
                        <GlobalPlatformsLinks :items="getPlatforms(item)" small size="18px" />
                    </v-col>
                </v-row>
            </template>
            <template #item="{ item }">
                <v-row justify="start" align-content="start">
                    <v-col cols="auto" class="py-0">
                        <EqsAvatar
                            v-bind="{
                                loading,
                                src: !!item.photo ? item.photo.upload : ''
                            }"
                        />
                    </v-col>
                    <v-col cols="auto" class="py-0 d-flex flex-column align-start justify-center">
                        <EqsTextDisplay v-bind="{ value: item.name }" />
                        <EqsTextDisplay class="text--secondary" v-bind="{ value: item.role }" />
                    </v-col>
                    <v-col v-if="item.linkedin" class="d-flex justify-start align-center">
                        <GlobalPlatformsLinks :items="getPlatforms(item)" small size="18px" />
                    </v-col>
                </v-row>
            </template>
            <template #edit="editProps">
                <InternalPeopleForm v-bind="editProps" />
            </template>
            <template #create="createProps">
                <InternalPeopleForm editable v-bind="createProps" />
            </template>
        </EqsCrud>
    </div>
</template>

<script>
import InternalPeopleForm from "./_internal/InternalPeopleForm.vue";

export default {
    name: "GlobalPeopleList",
    components: {
        InternalPeopleForm
    },
    props: {
        peoples: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        display: {
            type: Boolean,
            default: false
        },
        add: {
            type: Function,
            default: null
        },
        update: {
            type: Function,
            default: null
        },
        deleteFunc: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            newPerson: {
                linkedin: "",
                role: "",
                name: "",
                photo: null
            },
            validForm: false,
            newPeopleLoading: false
        };
    },
    computed: {
        peoplesProxy: {
            get() {
                return this.peoples;
            },
            set(val) {
                this.$emit("update:peoples", val);
            }
        }
    },
    methods: {
        async addPerson(item) {
            try {
                this.newPeopleLoading = true;
                if (this.add) {
                    const res = await this.add(item);
                    return res.data;
                }
                return item;
            } catch (e) {
                this.$ui.error(e, "create_member");
                throw e;
            } finally {
                this.newPeopleLoading = false;
            }
        },
        async updatePerson(item) {
            try {
                this.newPeopleLoading = true;
                if (this.update) {
                    const res = await this.update(item);
                    return res.data;
                }
                return item;
            } catch (e) {
                this.$ui.error(e, "update_member");
                throw e;
            } finally {
                this.newPeopleLoading = false;
            }
        },
        async removePerson(item) {
            try {
                this.newPeopleLoading = true;
                if (this.deleteFunc) await this.deleteFunc(item);
            } catch (e) {
                this.$ui.error(e, "remove_member");
                throw e;
            } finally {
                this.newPeopleLoading = false;
            }
        },
        getPlatforms(item) {
            return item.linkedin
                ? [
                      {
                          platform: "linkedin",
                          url: item.linkedin
                      }
                  ]
                : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.people-text {
    font-weight: 600;
}
</style>
