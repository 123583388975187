var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsLabel',_vm._b({staticClass:"global-text-editor",style:(_vm.loading ? 'width:auto;' : '')},'EqsLabel',{
        ..._vm.$attrs,
        eqsSize: _vm.eqsSize,
        isRequired: _vm.isRequired,
        height: _vm.labelHeight,
        editable: _vm.editable,
        value: _vm.label,
        infoTxt: _vm.infoTxt
    },false),[_c('EqsSkeleton',_vm._b({staticClass:"global-text-editor__container",attrs:{"type":"article"}},'EqsSkeleton',{ loading: _vm.loading },false),[_c('v-input',_vm._b({attrs:{"dense":"","hide-details":"auto"}},'v-input',{
                ..._vm.$attrs,
                value: _vm.value,
                rules: _vm.rulesRequired
            },false),[_c('v-card',{staticClass:"global-text-editor__card fill-height",attrs:{"elevation":0,"loading":_vm.loading || !_vm.editor}},[(_vm.editor)?_c('InternalToolbar',_vm._b({scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions",null,null,{ editor: _vm.editor })]},proxy:true}],null,true)},'InternalToolbar',{ editor: _vm.editor, actions: _vm.actions },false)):_vm._e(),_c('editor-content',_vm._g(_vm._b({class:`global-text-editor__card__content ${_vm.elClass}`},'editor-content',{ editor: _vm.editor },false),_vm.$listeners))],1)],1),(_vm.editor)?_c('InternalImageSelector',_vm._b({attrs:{"value":_vm.dialogImg},on:{"update:value":function($event){_vm.dialogImg=$event}}},'InternalImageSelector',{ editor: _vm.editor },false)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }