import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_INVESTOR_OPTIONS_START");
    if (state.investorOptionsInit && !state.investorOptionsLoading) {
        try {
            commit("SET_INVESTOR_OPTIONS_LOADING", true);
            const investorOptionsRes = await window.app.$api.getInvestorOptions();
            const investorOptions = investorOptionsRes.data.actions.POST;
            DEBUG("GET_INVESTOR_OPTIONS_SUCCESS");
            commit("SET_INVESTOR_OPTIONS", investorOptions);
        } catch (e) {
            DEBUG("GET_INVESTOR_OPTIONS_ERROR");
            window.app.$ui.error(e, "investor_registrar_get_investorOptions");
            throw e;
        } finally {
            commit("SET_INVESTOR_OPTIONS_LOADING", false);
        }
    } else {
        DEBUG("GET_INVESTOR_OPTIONS_BYPASSED");
    }
};
