import displayError from "@/store/displayError";
import store from "@/store/store";
import DEBUG from "@/store/debug.js";

export default async ({ commit, dispatch }, data) => {
    DEBUG("PUT_ENTITY_START");
    try {
        const currentRole = await store.getters.currentRole;
        const id = currentRole.entity_represented.id;
        const res = await window.app.$api.updateEntity(id, data);
        DEBUG("PUT_ENTITY_SUCCESS");
        await window.app.$role.updateMyCurrentRole(id);
        return res.data;
    } catch (error) {
        //todo: doesn't know what this mean, maybe use window.app.$ui.error instead ?
        DEBUG("PUT_ENTITY_ERROR");
        displayError(error, true);
    }
};
