import { render, staticRenderFns } from "./AvatarName.vue?vue&type=template&id=52f0490b&scoped=true"
import script from "./AvatarName.vue?vue&type=script&lang=js"
export * from "./AvatarName.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52f0490b",
  null
  
)

export default component.exports