<template>
    <EqsCard
        :title="$t('shareledger.dialog.detail.transaction-detail')"
        class="pl-3 pr-3 pb-3"
        style="height: 100%"
        type="default-actions"
        el-class="transaction-detail"
        closable
        @close="
            () => {
                $emit('update:selectedAssetMovement', null);
                $router.replace(rootURL);
            }
        "
    >
        <v-flex pa-4 full-height>
            <EqsLoader v-if="loading" :size="190" />
            <EqsPanels v-else :value="0" v-bind="{ panels }">
                <template #default="{ panel }">
                    <TransactionDetailsCard v-if="panel.type === 'tx'" :tx="tx" />
                    <v-flex v-if="panel.type === 'flex'">
                        {{ panel.value }}
                    </v-flex>
                    <v-flex
                        v-if="panel.type === 'list' && panel.key === 'documents'"
                        style="padding: 20px 10%"
                    >
                        <v-flex
                            v-for="(doc, index) in panel.value"
                            :key="index"
                            layout
                            justify-start
                            style="width: 100%"
                        >
                            <EqsUpload :value="doc" v-bind="{ editable: false }" />
                        </v-flex>
                    </v-flex>
                </template>
            </EqsPanels>
        </v-flex>
    </EqsCard>
</template>

<script>
import TransactionDetailsCard from "./TransactionDetailsCard.vue";

export default {
    name: "TransactionDetailsDialog",
    components: {
        TransactionDetailsCard
    },
    props: {
        transactionId: {
            type: String,
            required: true
        },
        rootURL: {
            type: String,
            required: true
        },
        selectedAssetMovement: {
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            tx: {},
            docs: [],
            loading: true
        };
    },
    computed: {
        panels() {
            if (!this.tx) return [];
            const panels = [
                {
                    title: this.$t("shareledger.dialog.detail.transaction").toUpperCase(),
                    type: "tx"
                }
            ];
            if (this.tx.description) {
                panels.push({
                    title: this.$t("shareledger.dialog.detail.description").toUpperCase(),
                    type: "flex",
                    key: "description",
                    value: this.tx.description
                });
            }
            if (this.docs && this.docs.length) {
                panels.push({
                    title: this.$t("shareledger.dialog.detail.documents-attached").toUpperCase(),
                    type: "list",
                    key: "documents",
                    value: this.docs
                });
            }
            return panels;
        }
    },
    watch: {
        selectedAssetMovement: {
            async handler(val) {
                await this.fetch(val);
            }
        }
    },
    async created() {
        await this.fetch(this.selectedAssetMovement);
    },
    methods: {
        async fetch(val) {
            this.loading = true;
            if (val) {
                const docs = val.docs;
                await Promise.all(
                    docs.map(async docUrl => {
                        const response = await this.$api.request("get", docUrl);
                        const doc = response.data;
                        this.docs = [...this.docs, doc];
                    })
                );
                this.tx = val;
            }
            this.loading = false;
        }
    }
};
</script>
