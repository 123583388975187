export function getHttpErrMsg(errObj, msg = "") {
    let errMsg = "";
    console.log(errObj);
    if (!errObj) {
        errMsg = "Unknown error, try refresh application";
    } else if (typeof errObj.data === "string") {
        // Erreur 500 non prévue
        if (errObj.data.startsWith("<!DOCTYPE html>")) {
            // On cache le code HTML inutile
            errMsg = `(${errObj.status} - ${errObj.statusText})`;
        } else {
            errMsg = `${errObj.data} (${errObj.status} - ${errObj.statusText})`;
        }
    } else {
        errMsg = `${errObj.data.detail} (${errObj.status} - ${errObj.statusText})`;
    }
    if (msg?.length > 0) {
        return `${msg} -> ${errMsg}`;
    } else {
        return errMsg;
    }
}
