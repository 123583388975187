<template>
    <EqsRadio
        v-bind="{ options, loading }"
        border
        style="min-width: 50%"
        :value.sync="formData.paymentOption"
        :error-messages="walletRule"
        :rules="[paymentOptionRule]"
        el-class="payment-type"
    >
        <template #label="{ item }">
            <v-flex pl-4 layout align-center>
                <img
                    v-for="(img, index) in item.imgs"
                    :key="index"
                    contain
                    class="mr-5"
                    v-bind="img"
                />
            </v-flex>
            <v-row no-gutters class="pl-4">
                <v-flex class="option" pa-2 layout align-center justify-space-between>
                    <div v-if="item.fees">
                        <v-flex
                            v-for="(fee, index) in getFees(item.fees)"
                            :key="index"
                            layout
                            column
                            align-start
                            px-2
                        >
                            <EqsTextDisplay>
                                <div class="option-section pt-1">
                                    {{ fee.section }}
                                </div>
                            </EqsTextDisplay>
                            <EqsTextDisplay>
                                <div class="option-txt">
                                    {{ fee.value }}
                                </div>
                            </EqsTextDisplay>
                        </v-flex>
                    </div>
                </v-flex>
            </v-row>
        </template>
    </EqsRadio>
</template>

<script>
export default {
    name: "PaymentOptions",
    props: {
        order: {
            type: Object,
            default: () => {}
        },
        formData: {
            type: Object,
            default: () => {}
        },
        options: {
            type: Array,
            required: true
        },
        isCapinc: {
            type: Boolean,
            default: false
        },
        walletRule: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        getFeeSpec(item) {
            const coef = `${this.$currency.percent(item.coef, "0.0%")} ${this.$t(
                "user.payment.variable-fee"
            )}`;
            const fixed = item.fixed > 0 ? this.$currency.display(item.fixed) : null;
            return coef && fixed ? `${coef} + ${fixed}` : coef;
        },
        getFees(fees) {
            const eee = {
                section: this.$t("user.payment.eee-fees"),
                value: this.getFeeSpec(fees.eee)
            };
            const world = {
                section: this.$t("user.payment.worldwide-fees"),
                value: this.getFeeSpec(fees.world)
            };
            return [eee, world];
        },
        anotherRule(value) {
            if (value === "transfer") return value;
            return false;
        },
        paymentOptionRule(value) {
            if (this.isCapinc && this.formData && value && value === "transfer") {
                const capincEndDate = this.$time
                    .date(this.order.object.end_date)
                    .subtract(7, "days");
                if (this.$time.isPast(capincEndDate)) {
                    return this.$t("user.payment.wire-transfer-rule");
                }
            }
            // should be false but duno why it stay at error state
            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
.option {
    background: #f8f9fc 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}
.option-txt {
    text-align: left;
    letter-spacing: 0;
    color: #5e6066;
    opacity: 1;
}
.option-section {
    text-align: left;
    letter-spacing: 0;
    color: #f88866;
    opacity: 1;
    // text-transform: uppercase;
}
.price-to-pay {
    border: 2px solid #153055;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    letter-spacing: 0;
    color: #153055;
    opacity: 1;
    padding: 2px 8px 2px 8px;
}
</style>
