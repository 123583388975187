export default {
    setlimit: state => state.setlimit,
    setlimitLoading: state => state.setlimitLoading,
    setcountries: state => state.setcountries,
    removecountries: state => state.removecountries,
    issuingentity: state => state.issuingentity,
    kycissuer: state => state.kycissuer,
    blockchainize_state: state => state.blockchainize_state,
    setcountriesLoading: state => state.setcountriesLoading,
    removeCountriesLoading: state => state.removeCountriesLoading,
    issuingEntityLoading: state => state.issuingEntityLoading,
    kycIssuerLoading: state => state.kycIssuerLoading,
    blockchainInfoLoading: state => state.blockchainInfoLoading,
    transactions: state => state.transactions,
    transactionsLoading: state => state.transactionsLoading
};
