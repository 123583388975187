<template>
    <div pa-2 ma-2>
        <v-layout row class="bank-wire">
            <v-flex v-for="(item, index) in iban" :key="index" pa-3 layout column>
                <EqsTextDisplay v-if="item.section">
                    <div class="bank-wire-section">
                        {{ item.section }}
                    </div>
                </EqsTextDisplay>
                <EqsTextDisplay v-if="item.value && !item.file" v-bind="{ loading }">
                    <div class="bank-wire-txt">
                        {{ item.value || "-" }}
                    </div>
                </EqsTextDisplay>
                <EqsFileInput v-if="item.file" v-bind="{ editable: false, loading, ...item }" />
            </v-flex>
        </v-layout>
        <!-- <v-flex layout row align-start>
      <v-flex class="bank-wire" layout column align-start pa-3 mt-3>
        <div class="bank-wire-section">
          {{ $t("payment.reference") }}
        </div>
        <EqsTextDisplay>
          <div class="bank-wire-txt">
            {{ order.payment_token }}
          </div>
        </EqsTextDisplay>
      </v-flex>
    </v-flex>
    <EqsTextDisplay>
      <div
        class="mt-2 paragraph text-center bank-wire-section font-weight-bold"
      >
        {{ message }}
      </div>
    </EqsTextDisplay>
    <EqsCheckbox
      :value.sync="formData.reference_wire"
      is-required
      style="max-width: 50%"
      class="mt-3"
      el-class="bank-wire-token"
    >
      <template #label>
        <div class="text-normal">
          {{ $t("payment.included-reference") }}
          <span class="text-strong no-wrap">
            {{ `\n${order.payment_token}` }}
          </span>
          {{ $t("payment.in-my-bank-transfer") }}
        </div>
      </template>
    </EqsCheckbox> -->
    </div>
</template>

<script>
export default {
    name: "BankwirePayment",
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        order: {
            type: Object,
            default: () => {}
        },
        iban: {
            type: Array,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            message: this.$t("user.payment.banking-payment-msg")
        };
    }
};
</script>

<style lang="scss" scoped>
.bank-wire {
    background: #f8f9fc 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    height: fit-content;
}
.bank-wire-txt {
    text-align: left;
    letter-spacing: 0;
    color: #5e6066;
    opacity: 1;
    text-transform: uppercase;
}
.bank-wire-section {
    text-align: left;
    letter-spacing: 0;
    color: #f88866;
    padding-bottom: 8px;
    opacity: 1;
    text-transform: uppercase;
}
.price-to-pay {
    border: 2px solid #153055;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    letter-spacing: 0;
    color: #153055;
    opacity: 1;
    padding: 2px 8px 2px 8px;
}
</style>
