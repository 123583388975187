import Vue from "vue";
import { sizes, sizesKeys, sizeTypes } from "../../../shared/constants";
import { toType } from "../../../shared/util";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

var state = Vue.observable({
    width: 0,
    height: 0,
    size: sizes.md
});

export function getCurrentSize(sizes, width) {
    return Object.values(sizes).find(size => width <= size.width);
}

function propsIsRightFormat(sizeKeys, sizeTypes, props) {
    const propsKeys = Object.keys(props);
    let error = false;
    let kindOfProp;
    if (sizeKeys.includes(propsKeys[0])) {
        kindOfProp = "keys";
        if (!isEqual(sizeKeys, propsKeys)) error = true;
    } else if (sizeTypes.includes(propsKeys[0])) {
        kindOfProp = "types";
        if (!isEqual(sizeTypes, propsKeys)) error = true;
    }
    if (!kindOfProp || error) {
        let propsKeysDirective;
        if (!kindOfProp) propsKeysDirective = `"${sizeKeys}" or "${sizeTypes}"`;
        else propsKeysDirective = kindOfProp === "keys" ? `"${sizeKeys}"` : `"${sizeTypes}"`;
        console.error(
            "Error for props:\n",
            props,
            `\nYour props keys are "${propsKeys}"
wether it should be ${propsKeysDirective}`
        );
        return false;
    }
    return true;
}

function propsIsObject(props) {
    const propsType = toType(props);
    if (!props || ["string", "array", "function"].includes(propsType)) {
        console.error(`"getVal" props should be an object and it's a "${propsType}":\n${props}`);
        return false;
    }
    return true;
}

function getError(sizeKeys, sizeTypes, props) {
    if (propsIsObject(props) && propsIsRightFormat(sizeKeys, sizeTypes, props)) {
        return false;
    }
    return true;
}

export function getValForCurrentSize(currentSize, sizeKeys, sizeTypes, props) {
    // avoid error on storybook
    if (isEmpty(currentSize)) currentSize = sizes.md;
    let res;
    if (getError(sizeKeys, sizeTypes, props)) {
        return res;
    } else {
        for (const [key, val] of Object.entries(props)) {
            if (sizeTypes.includes(key) && currentSize.type === key) res = val;
            else if (sizeKeys.includes(key) && currentSize.id === key) res = val;
        }
    }
    if (!res && res !== 0 && res !== "")
        console.error(
            "no result for props:\n",
            props,
            "with sizeKeys:\n",
            sizeKeys,
            "and sizeTypes:\n",
            sizeTypes
        );
    return res;
}

export function getVal(currentSize, props) {
    return getValForCurrentSize(currentSize, sizesKeys, sizeTypes, props);
}

export class ReactiveService {
    constructor() {
        this.sizes = sizes;
        this.sizeKeys = sizesKeys;
        this.sizeTypes = sizeTypes;
        this._windowResize = this._windowResize.bind(this);
        state.width = window.innerWidth;
        state.height = window.innerHeight;
        state.size = getCurrentSize(sizes, window.innerWidth);
        window.addEventListener("resize", this._windowResize);
    }
    _windowResize() {
        state.width = window.innerWidth;
        state.height = window.innerHeight;
        state.size = getCurrentSize(this.sizes, state.width);
    }
    getSize() {
        return state.size;
    }
    getSizes() {
        return this.sizes;
    }
    getSizeKeys() {
        return this.sizeKeys;
    }
    getSizeTypes() {
        return this.sizeTypes;
    }
    getWidth() {
        return state.width;
    }
    getVal(props) {
        return getValForCurrentSize(state.size, this.sizeKeys, this.sizeTypes, props);
    }
    getCols(eqsSize, formType = "main") {
        const aligned_main = {
            fifth: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3,
                xl: 3
            },
            third: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 4,
                xl: 4
            },
            half: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 6
            },
            full: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
            }
        };

        const aligned_medium = {
            fifth: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 3,
                xl: 3
            },
            third: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 4,
                xl: 4
            },
            half: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 6
            },
            full: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
            }
        };

        const aligned_dialog = {
            third: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
            },
            half: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
            },
            full: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
            }
        };

        const cols = {
            main: {
                xs: {
                    xs: 4,
                    sm: 4,
                    md: 3,
                    lg: 2,
                    xl: 2
                },
                sm: {
                    xs: 7,
                    sm: 6,
                    md: 4,
                    lg: 3,
                    xl: 3
                },
                md: {
                    xs: 10,
                    sm: 8,
                    md: 5,
                    lg: 4,
                    xl: 4
                },
                lg: {
                    xs: 11,
                    sm: 10,
                    md: 8,
                    lg: 7,
                    xl: 7
                },
                xl: {
                    xs: 12,
                    sm: 12,
                    md: 11,
                    lg: 10,
                    xl: 10
                },
                ...aligned_main
            },
            medium: {
                xs: {
                    xs: 6,
                    sm: 4,
                    md: 3,
                    lg: 3,
                    xl: 3
                },
                sm: {
                    xs: 7,
                    sm: 6,
                    md: 4,
                    lg: 4,
                    xl: 4
                },
                md: {
                    xs: 10,
                    sm: 8,
                    md: 6,
                    lg: 5,
                    xl: 5
                },
                lg: {
                    xs: 11,
                    sm: 10,
                    md: 9,
                    lg: 8,
                    xl: 8
                },
                xl: {
                    xs: 12,
                    sm: 12,
                    md: 11,
                    lg: 10,
                    xl: 10
                },
                ...aligned_medium
            },
            dialog: {
                xs: {
                    xs: 6,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6
                },
                sm: {
                    xs: 7,
                    sm: 7,
                    md: 7,
                    lg: 7,
                    xl: 7
                },
                md: {
                    xs: 10,
                    sm: 10,
                    md: 10,
                    lg: 10,
                    xl: 10
                },
                lg: {
                    xs: 11,
                    sm: 11,
                    md: 11,
                    lg: 11,
                    xl: 11
                },
                xl: {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12
                },
                ...aligned_dialog
            }
        };

        return this.getVal(cols[formType][eqsSize]);
    }
    getValFromSize(size, props) {
        return getValForCurrentSize(size, this.sizeKeys, this.sizeTypes, props);
    }
    isXs() {
        return state.size.id === "xs";
    }
    isMobile() {
        return state.size.type === "mobile";
    }
    isTablet() {
        return state.size.type === "tablet";
    }
    isDesktop() {
        return state.size.type === "desktop";
    }
    //
    // Update more gemeric with type:
    // main
    // header
    // double ...
    getElementHeight(type = "main") {
        let height = "inherit";
        switch (type) {
            case "main":
                height = this.isDesktop() ? "max-content" : "100px";
                break;
            case "header":
                height = this.isDesktop() ? "200px" : "100px";
                break;
            case "main-image":
                height = this.getVal({
                    mobile: "150px",
                    tablet: "200px",
                    desktop: "max-content"
                });
                break;
            default:
                break;
        }
        return height;
    }
}
