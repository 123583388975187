import { i18n } from "@/services/plugins/i18n.js";
import _ from "lodash";
import * as util from "./index";

/**
 ** Global functions defined here
 */

/*
    window.location.host = [www.]universe.env.equisafe.io
    window.location.hostname = [www.]universe.env.equisafe.io

    api.env.equisafe.io = url de l'api
    app.env.equisafe.io = equisafe sans univers
    xxx.env.equisafe.io = univers xxx

    En local:
    127.0.0.1 ou localhost :8080
 */

const hostname = window.location.hostname;

/*
    null ou "" = pas d'univers
    Utile uniquement en local
*/
let universeJson = null;
if (process.env.NODE_ENV === "development") {
    universeJson = await import("@/universe");
}
export function getUniverseId() {
    if (process.env.NODE_ENV === "development") {
        return universeJson?.universe_id;
    } else {
        let hostSplitted = hostname.split(".");
        let universeIdId = 0;
        if (hostSplitted[universeIdId] === "www") {
            universeIdId++;
        }
        if (hostSplitted[universeIdId] === "app") {
            return null;
        } else {
            let universeId = hostSplitted[universeIdId];
            if (universeId.localeCompare("kyoseil-am") === 0) {
                universeId = "kyoseil";
            } else if (universeId.localeCompare("magnitude-construction") === 0) {
                universeId = "magnitude";
            }
            return universeId;
        }
    }
}

export function getEnvId() {
    if (process.env.NODE_ENV === "development") {
        const nodeEnvMapToProd = {
            developement: "dev",
            production: "prod",
            staging: "staging"
        };
        return nodeEnvMapToProd[process.env.NODE_ENV];
    } else {
        let hostSplitted = hostname.split(".");
        let universeIdId = 1;
        if (hostSplitted[universeIdId] === "www") {
            universeIdId++;
        }
        if (hostSplitted[universeIdId] === "equisafe") {
            return "prod";
        }
        return hostSplitted[universeIdId];
    }
}

export function getRootURL() {
    if (process.env.NODE_ENV === "development") {
        return "http://localhost:8000/";
    } else {
        const envId = getEnvId();
        if (envId === "prod") {
            return "https://api.equisafe.io/";
        } else {
            return `https://api.${envId}.equisafe.io/`;
        }
    }
}

export function getFrontUrl(universe = null) {
    if (process.env.NODE_ENV === "development") {
        return "http://localhost:8080/";
    } else {
        const envId = getEnvId();
        let universePart = "";
        if (universe === null) {
            universePart = "app";
        } else {
            universePart = universe;
        }
        if (envId === "prod") {
            return `https://${universePart}.equisafe.io/`;
        } else {
            return `https://${universePart}.${envId}.equisafe.io/`;
        }
    }
}

export function extractIdFromUrl(url) {
    const splitUrl = url.split("/");
    return splitUrl[splitUrl.length - 2];
}

export function getCookie(name, check = true) {
    var cookies = document.cookie.split("; ");
    if (!cookies) {
        console.error("no cookies");
        return null;
    }
    var cookiesWithName = cookies.filter(str => str.includes(name));
    if (!cookiesWithName) {
        if (check) console.error(`no cookie with name ${name}`);
        return null;
    }
    var latestCookieWithName = cookiesWithName.pop();
    if (!latestCookieWithName) {
        if (check) console.error(`no cookie with name ${name}`);
        return null;
    }
    var cookieValue = latestCookieWithName.split(`${name}=`)[1];
    return cookieValue;
    // var value = "; " + document.cookie;
    // var parts = value.split("; " + name + "=");
    // if (parts.length == 2)
    //   return parts
    //     .pop()
    //     .split(";")
    //     .shift();
    // else if (parts.length > 2) return parts.pop();
    // return null;
}

export function getDateExpires(duration) {
    let res = window.app.$time.formatISO(window.app.$time.addDays(Date.now(), duration));
    res = window.app.$time.getUTCDate(res);
    return res;
}

export function setCookie(name, value, duration = 1) {
    const res = `${name}=${value}; expires= ${util.getDateExpires(duration)}; path=/ `;
    document.cookie = res;
    return res;
}

export function deleteCookie(name, path, domain) {
    if (getCookie(name)) {
        document.cookie =
            name +
            "=" +
            (path ? ";path=" + path : "") +
            (domain ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

/*
 ** https://docs.djangoproject.com/en/2.2/ref/csrf/#setting-the-token-on-the-ajax-request
 */
export function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/i.test(method);
}

// https://markus.oberlehner.net/blog/distributed-vue-applications-loading-components-via-http/
export async function externalComponent(url) {
    const name = url
        .split("/")
        .reverse()[0]
        .match(/^(.*?)\.umd/)[1];
    console.log({
        name
    });
    if (window[name]) return window[name];

    window[name] = new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.async = true;
        script.addEventListener("load", () => {
            resolve(window[name]);
        });
        script.addEventListener("error", () => {
            reject(new Error(`Error loading ${url}`));
        });
        script.src = url;
        document.head.appendChild(script);
    });

    return window[name];
}

export const yesOrNo = ctx => [
    { label: ctx.$t("label.yes"), value: "yes" },
    { label: ctx.$t("label.no"), value: "no" }
];
