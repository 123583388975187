<template>
    <div :class="`eqs-btn ${elClass}`" :style="{ ...getStyle, ...additionalStyle }">
        <EqsSkeleton v-bind="{ elClass, loading, type: 'button', ...skeleton }">
            <v-input v-bind="{ errorMessages }" dense hide-details="auto">
                <v-tooltip open-on-hover open-on-focus top :disabled="!infoTxt" max-width="18rem">
                    <template #activator="{ on }">
                        <v-btn
                            v-bind="{
                                ...$attrs,
                                ...getData,
                                size,
                                block,
                                loading: localLoading,
                                disabled: isDisabled
                            }"
                            :outlined="outlined ? true : false"
                            :raised="outlined ? false : true"
                            :class="`eqs-btn__container ${elClass}-btn ${
                                $color.colors.roundedBtn === 0 && 'no-rounded'
                            }`"
                            v-on="{ ...$listeners, ...on, ...otherEvents }"
                            @click.stop="executeAction"
                        >
                            <EqsTextDisplay eqs-type="btn">
                                <div class="eqs-btn__container__text">
                                    <slot />
                                </div>
                                <div class="eqs-btn__container__text-alt">
                                    <slot name="with-icon" />
                                </div>
                                <div style="display: none" />
                            </EqsTextDisplay>
                        </v-btn>
                    </template>
                    {{ infoTxt }}
                </v-tooltip>
            </v-input>
        </EqsSkeleton>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsBtn.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { arrayOrStringEmpty } from "../../../../../shared/util";

export default {
    name: "EqsBtn",
    components: {
        EqsSkeleton: () => import("../EqsSkeleton/EqsSkeleton.vue"),
        EqsTextDisplay: () => import("../EqsTextDisplay/EqsTextDisplay.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: ""
        },
        action: {
            type: Function,
            default: null
        },
        actionModal: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        infoTxt: {
            type: String,
            default: ""
        },
        errorMessages: {
            type: [String, Array],
            default: ""
        },
        block: {
            type: Boolean,
            default: false
        },
        otherEvents: {
            type: [Function, Object],
            default: () => {}
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            localLoading: false
        };
    },
    computed: {
        isDisabled() {
            return (
                this.loading ||
                this.localLoading ||
                this.disabled ||
                !arrayOrStringEmpty(this.errorMessages)
            );
        },
        skeleton() {
            return this.getData.skeleton || {};
        },
        additionalStyle() {
            let res = {};
            if (this.block) res["--btn-width"] = "100%";
            return res;
        },
        size() {
            const size = { xs: "x-small", sm: "small", lg: "large", xl: "x-large" }[
                this.$vuetify.breakpoint.name
            ];
            return size ? { [size]: true } : {};
        }
    },
    methods: {
        async executeAction($event) {
            if (this.actionModal) {
                return this.$emit("clickModal", $event);
            }
            if (!this.action) return this.$emit("click", $event);
            this.localLoading = true;
            this.$emit("update:loading", true);
            try {
                if (this.action) {
                    await this.action();
                    this.$emit("action:finished");
                }
            } catch (e) {
                this.$emit("error", e);
            } finally {
                this.$emit("update:loading", false);
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsBtn.scss";
</style>
