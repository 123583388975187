var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`eqs-file-input ${_vm.elClass}-container`,style:(_vm.getStyle)},[_c('EqsLabel',_vm._b({attrs:{"el-class":_vm.elClass}},'EqsLabel',{
            value: _vm.label,
            labelHeight: _vm.labelHeight,
            editable: _vm.editable,
            infoTxt: _vm.infoTxt,
            isRequired: _vm.isRequired
        },false),[(_vm.editable && !(!_vm.multiple && _vm.valueProxy))?_c('EqsSkeleton',_vm._b({},'EqsSkeleton',{ loading: _vm.loading, type: 'sentences' },false),[_c('EqsUpload',_vm._b({attrs:{"value":_vm.valueProxy},on:{"update:value":function($event){_vm.valueProxy=$event},"update:errorMessages":val => (_vm.errorText = val),"isLoading":isLoading => (_vm.localLoading = isLoading)},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'EqsUpload',{
                    ..._vm.$attrs,
                    errorMessages: _vm.errorText || _vm.errorMessages,
                    errorCount: _vm.errorCount,
                    accept: _vm.accept,
                    sizeMax: _vm.sizeMax,
                    multiple: _vm.multiple,
                    loading: _vm.localLoading,
                    disabled: _vm.disabled || _vm.localLoading,
                    placeholder: _vm.getPlaceholder,
                    isRequired: _vm.isRequired,
                    others: _vm.others,
                    elClass: _vm.elClass
                },false),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1):_vm._e(),(_vm.valueProxy && _vm.valueProxy.length)?_c('EqsList',_vm._b({class:`eqs-file-input__list ${_vm.elClass}-list`,attrs:{"items":_vm.valueProxy},on:{"update:items":[function($event){_vm.valueProxy=$event},() => (_vm.localLoading = false)]},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('EqsFileActions',_vm._b({on:{"isLoading":isLoading => (_vm.localLoading = isLoading)}},'EqsFileActions',{
                        ...props,
                        elClass: _vm.elClass,
                        editable: _vm.editable,
                        deleteFunc: _vm.deleteFunction,
                        deleteFile: _vm.deleteFile
                    },false))]}},{key:"item-append",fn:function({ item, index }){return [(item.lw && _vm.editable)?_c('EqsAlert',_vm._b({staticClass:"font-weight-bold",attrs:{"outlined":"","small":_vm.$reactive.isMobile(),"label":""}},'EqsAlert',{ ...item.lw, elClass: `${_vm.elClass}-${index}-status` },false)):_vm._e()]}}],null,false,1678969694)},'EqsList',{
                errorMessages: _vm.errorText || _vm.errorMessages,
                errorCount: _vm.errorCount,
                rules: _vm.rulesRequired,
                loading: _vm.localLoading || _vm.loading
            },false)):(!_vm.editable)?_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{ value: '-' },false)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }