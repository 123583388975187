var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-medium-container margin-auto"},[_c('EqsCard',_vm._b({attrs:{"title":_vm.$t('registration.compnay-search-siren.title')}},'EqsCard',{ loading: _vm.loading },false),[_c('v-form',{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsTextDisplay',[_vm._v(" "+_vm._s(_vm.$t("registration.compnay-search-siren.text"))+" ")])],1),_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsText',_vm._b({staticClass:"mt-3 mb-3",attrs:{"value":_vm.siren,"label":_vm.$t('registration.compnay-search-siren.siren-label'),"editable":"","is-required":""},on:{"update:value":function($event){_vm.siren=$event}}},'EqsText',{
                        elClass: 'comp-id-code',
                        placeholder: 'Ex: 845 383 645',
                        loading: _vm.loading,
                        rules: [_vm.companyIdcodeR],
                        error: !!_vm.errorTxt,
                        errorMessages: _vm.errorTxt
                    },false)),_c('v-row',[_c('v-col',[_c('EqsBtn',_vm._b({staticClass:"mt-3 mb-3",attrs:{"el-class":"create-company-by-siren"}},'EqsBtn',{
                                action: _vm.createBySiren,
                                color: 'primary',
                                loading: _vm.localLoading
                            },false),[_vm._v(" "+_vm._s(_vm.$t("registration.compnay-search-siren.search-btn"))+" ")])],1),_c('v-col',[_c('EqsBtn',_vm._b({staticClass:"mt-3 mb-3",attrs:{"el-class":"create-company-manualy","outlined":""}},'EqsBtn',{
                                action: _vm.createManually,
                                color: 'primary',
                                loading: _vm.localLoading
                            },false),[_vm._v(" "+_vm._s(_vm.$t("registration.compnay-search-siren.manual-btn"))+" ")])],1)],1),_c('EqsAlert',_vm._b({staticClass:"mt-3 mb-3"},'EqsAlert',{ loading: _vm.localLoading },false),[_c('EqsTextDisplay',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("registration.compnay-search-siren.info-text"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("registration.compnay-search-siren.info-text-kibs"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("registration.compnay-search-siren.info-text-id"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("registration.compnay-search-siren.info-text-company-status"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t( "registration.compnay-search-siren.info-text-beneficiaires-docs" ))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t( "registration.compnay-search-siren.info-text-beneficiaires-id" ))+" ")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }