import DEBUG from "@/store/debug.js";

export default async ({ commit }, roleId) => {
    DEBUG("GET_MY_ROLE_START");
    try {
        const res = await window.app.$api.getRole(roleId);
        DEBUG("GET_MY_ROLE_SUCCESS");
        commit("SET_MY_ROLE", res.data);
        return res.data;
    } catch (e) {
        DEBUG("GET_MY_ROLE_ERROR");
        window.app.$ui.error(e, "get_my_role");
    }
};
