<template>
    <div class="capital-increase-links d-flex flex-column justify-center">
        <div class="d-flex flex-column justify-end overflow-y eqs-image-bottom-overlay">
            <v-tooltip
                v-for="(item, index) in items"
                :key="index"
                class="d-flex flex-column align-start justify-start"
                :value="show[item.linkId]"
                right
            >
                <template #activator="{ active }">
                    <EqsBtn
                        class="flex text-left my-2"
                        block
                        large
                        outlined
                        v-bind="{ action: item.action, 'el-class': item.elClass }"
                        v-on="active"
                    >
                        <v-row class="align-center" style="height: 42px">
                            <img width="36px" height="36px" :src="item.icon" />
                            <div class="d-flex flex-column pl-3 pr-3">
                                <span v-if="item.tooltip" class="action-txt">
                                    {{ $t("capital-increase.admin.detail.card-span-copy") }}
                                    <span class="text-underline">{{
                                        item.txtunderline.toLowerCase()
                                    }}</span>
                                    {{
                                        $t("capital-increase.admin.detail.card-span-copy-offering")
                                    }}
                                </span>
                                <span v-else class="action-txt">{{ item.label }}</span>
                                <span v-if="item.subtext" class="text-sm">{{ item.subtext }}</span>
                            </div>
                            <input :id="item.linkId" type="hidden" :value="item.link" />
                        </v-row>
                    </EqsBtn>
                </template>
                <span>{{ $t("molecule.badge.copied") }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import { copyToClipboard } from "@equisafe-ui-shared/util";
export default {
    name: "CiCard",
    props: {
        capitalIncrease: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            show: {
                link: false,
                code: false
            }
        };
    },
    computed: {
        getLink() {
            var getUrl = window.location;
            var baseUrl = getUrl.protocol + "//" + getUrl.host + "/";
            return baseUrl + `projects/${this.capitalIncrease.id}`;
        },
        items() {
            let items = [];
            if (this.capitalIncrease.editable) {
                items.push({
                    label: this.$t("capital-increase.admin.detail.parameters"),
                    icon: "/icons/edit_ledger.svg",
                    elClass: "edit-offering",
                    action: () =>
                        this.$router.push({
                            name: "fundraising-edit",
                            params: { capitalIncreaseId: this.capitalIncrease.id }
                        })
                });
            }
            items.push({
                label: this.capitalIncrease.public
                    ? this.$t("capital-increase.admin.detail.view-offering-btn")
                    : this.$t("capital-increase.admin.detail.preview-offering-btn"),
                icon: "/icons/view_preview_eye.svg",
                elClass: "preview-offering",
                action: () =>
                    this.$router.push({
                        name: "project-detail",
                        params: {
                            projectId: this.capitalIncrease.id,
                            id: this.$entity.represented().id,
                            previewMode: true
                        }
                    })
            });
            if (this.capitalIncrease.public) {
                items.push({
                    tooltip: true,
                    txtunderline: "CODE",
                    icon: "/icons/copy.svg",
                    elClass: "code-offering",
                    subtext: this.$t("capital-increase.admin.detail.code-investor-boutique"),
                    link: this.capitalIncrease.id,
                    linkId: "code",
                    action: () => this.copyLinkSharing("code")
                });
            }

            return items;
        }
    },
    methods: {
        async copyLinkSharing(linkId) {
            copyToClipboard(linkId);
            this.show[linkId] = true;
            setTimeout(() => {
                this.show[linkId] = false;
            }, 1000);
        }
    }
};
</script>

<style lang="scss">
.capital-increase-links {
    .v-btn {
        border: 0px;
    }
    .v-card--reveal {
        bottom: 0;
        opacity: 0.5;
        position: absolute;
        width: 100%;
    }
    .title1 {
        letter-spacing: 0;
        color: #ffffff;
    }
    .title2 {
        letter-spacing: 0;
        color: #ffffff;
    }
    .action-txt {
        letter-spacing: 0;
        // text-transform: uppercase;
    }
    .eqs-image-bottom-overlay {
        //background: rgba(255,255,255,0.94);
        //position: absolute;
        //bottom: 0;
        width: 100%;
    }
}
</style>
