import { i18n } from "@/services/plugins/i18n.js";

export const options = ctx => {
    return [
        {
            label: ctx.$t("user.payment.wire-transfer"),
            value: "transfer",
            imgs: [{ width: "180px", height: "100%", src: "/icons/bp-logo.jpg" }],
            fees: ctx.fees["transfer"],
            infos: [
                {
                    section: ctx.$t("user.payment.account-holder"),
                    value: ctx.vibanProxy.holder
                },
                {
                    section: ctx.$t("user.payment.iban-swift"),
                    value: ctx.vibanProxy.iban
                },
                {
                    section: ctx.$t("bic"),
                    value: ctx.vibanProxy.bic
                },
                {
                    section: ctx.$t("user.payment.bank-name"),
                    value: "Banque Populaire"
                },
                {
                    section: ctx.$t("user.payment.dom"),
                    value: ctx.vibanProxy.dom
                },
                {
                    file: true,
                    label: ctx.$t("user.payment.viban-doc"),
                    value: ctx.vibanProxy.doc_viban
                }
            ]
        },

        {
            label: ctx.$t("user.payment.credit-card"),
            value: "card",
            fees: ctx.fees["card"],
            imgs: [
                { width: "80px", height: "100%", src: "/icons/visa-logo.svg" },
                { width: "60px", height: "100%", src: "/icons/mastercard-logo.svg" }
            ]
        }
    ];
};

export const schemaDoubleStep1 = {
    moneyVal: {
        isRequired: true
    },
    paymentOption: {
        isRequired: true
    },
    eee: {
        isRequired: false
    }
};

export const schemaDoubleStep2Transfer = {
    // reference_wire: {
    //   isRequired: true,
    // },
};

export const statutes = {
    pending: {
        logo: "/images/equisafe-logo-orange.svg",
        title: i18n.t("crud.pending").toUpperCase(),
        display: true,
        color: "warning",
        id: "pending",
        message: i18n.t("user.wallet.pending-msg")
    },
    error: {
        logo: "/images/equisafe-logo-red.svg",
        title: i18n.t("error.error").toUpperCase(),
        color: "error",
        id: "error",
        message: i18n.t("user.buy.error-msg")
    },
    cancel: {
        logo: "/images/equisafe-logo-blue.svg",
        title: i18n.t("label.cancelation").toUpperCase(),
        color: "info",
        id: "cancel",
        message: i18n.t("user.buy.cancelation-msg")
    },
    success: {
        logo: "/images/equisafe-logo-green.svg",
        title: i18n.t("label.success").toUpperCase() + " !",
        display: true,
        color: "success",
        id: "success",
        message: i18n.t("user.wallet.success-msg")
    }
};
