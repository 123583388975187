<template>
    <v-skeleton-loader :boilerplate="!loading" type="card" v-bind="{ loading, width, height }">
        <v-card
            v-bind="{ height, width }"
            class="arthaud__card elevation-2"
            @click="() => $emit('open')"
        >
            <div class="ma-0 pa-3" style="height: 75%; position: relative">
                <v-img
                    :src="item.image ? item.image.upload : ''"
                    height="100%"
                    width="100%"
                    @mouseover="hover = true"
                    @mouseleave="hover = false"
                >
                    <template #placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                    <template #default>
                        <div :class="['arthaud__hover-overlay', { show: hover }]">
                            <div class="arthaud__info">
                                <span class="font-weight-bold"
                                    >{{ Math.floor(item.hard_cap).toLocaleString("fr-FR") }}€</span
                                >
                                - Montant recherché
                            </div>
                            <div class="arthaud__info">
                                <span class="font-weight-bold">{{ horizon + " mois" }}</span> -
                                Horizon
                            </div>
                        </div>
                        <div class="arthaud__background-bar">
                            <v-progress-linear
                                v-model="percentage"
                                color="primary"
                                background-color="#ebf6f9"
                                height="20"
                                rounded
                                class="arthaud__progress-bar"
                            >
                                <div :style="{ color: percentage > 50 ? '#ebf6f9' : '#2b2b2b' }">
                                    {{ percentage == 100 ? "Terminé" : percentage + "%" }}
                                </div>
                            </v-progress-linear>
                        </div>
                    </template>
                </v-img>
            </div>
            <div class="ma-0 pa-3" style="height: 25%">
                <v-row no-gutters class="pb-2">
                    <div class="arthaud__title">{{ item.order_title }}</div>
                </v-row>
                <v-row no-gutters class="arthaud__subtitle align-center">
                    <v-col cols="1" class="pr-2">
                        <v-icon>mdi-map-marker-outline</v-icon>
                    </v-col>
                    <v-col>
                        <div>France</div>
                    </v-col>
                </v-row>
                <slot name="actions" />
            </div>
        </v-card>
    </v-skeleton-loader>
</template>

<script>
export default {
    name: "ArthaudCard",
    props: {
        item: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        },
        clickable: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: "auto"
        },
        height: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            hover: false
        };
    },
    computed: {
        horizon() {
            const endDate = new Date(this.item.end_date);
            const startDate = new Date(this.item.start_date);
            const diffInMs = endDate - startDate;
            const diffInMonths = diffInMs / (1000 * 60 * 60 * 24 * 30.44);
            return Math.round(diffInMonths);
        },
        percentage() {
            return Math.round(
                (parseFloat(this.item.money_raised) / parseFloat(this.item.hard_cap)) * 100
            );
        }
    },
    methods: {
        selectCard() {
            this.$emit("openDetail", !this.selected ? this.item.id : "offerings");
        }
    }
};
</script>

<style lang="scss" scoped>
$transition-duration: 0.5s;
$transition-ease: ease;

.arthaud {
    &__card {
        cursor: pointer;
        overflow: hidden;
        border-radius: 9px !important;

        & .v-image {
            border-radius: 10px !important;
        }
    }

    &__title {
        font-family: IvyPresto Display;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        color: #2b2b2b;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__subtitle {
        font-family: Manrope;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.1em;
        color: #787878;
    }

    &__background-bar {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        padding: 5px;
        background-color: #ebf6f9;
        border-radius: 6px;
    }

    &__progress-bar {
        width: 100%;

        div {
            line-height: 12px;
            font-weight: 600;
        }

        :deep(div.v-progress-linear__determinate) {
            border-radius: 6px !important;
        }
    }

    &__hover-overlay {
        opacity: 0;
        transition: opacity $transition-duration $transition-ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(43, 43, 43, 0.9);
        z-index: 1;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;

        &.show {
            opacity: 1;
        }
    }

    &__info {
        margin: 0px;
    }
}
</style>
