<template>
    <v-container v-if="isRoot" fluid class="pa-0 ma-0">
        <v-row style="height: 100%">
            <v-col class="d-flex flex-column fill-height" :cols="getCols()">
                <InvestorWhitelistTable
                    v-bind="{
                        loading
                    }"
                    @invite="() => add()"
                    @open:investorDetails="investor => openInvestorDetails(investor)"
                    @close:investorDetails="() => closeInvestorDetails()"
                />
            </v-col>
            <v-col
                v-if="displayInvestorDetails"
                class="d-flex flex-column fill-height"
                :cols="getCols()"
            >
                <InvestorDetailPanels
                    :investor="investorDetails"
                    @close="
                        () => {
                            closeInvestorDetails();
                        }
                    "
                />
            </v-col>
        </v-row>
    </v-container>
    <router-view v-else v-bind="{ loading }" />
</template>

<script>
import InvestorDetailPanels from "@/components/views/shareledger/investorWhitelist/InvestorDetailPanels";
import InvestorWhitelistTable from "@/components/views/shareledger/investorWhitelist/InvestorWhitelistTable.vue";

export default {
    name: "InvestorWhitelist",
    components: {
        InvestorDetailPanels,
        InvestorWhitelistTable
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            displayInvestorDetails: false,
            investorDetails: null
        };
    },
    computed: {
        isRoot() {
            return this.$route.name === "investors";
        }
    },
    methods: {
        getCols() {
            let cols = 12;
            if (this.displayInvestorDetails) return 6;
            return cols;
        },
        openInvestorDetails(investor) {
            if (investor.id !== this.investorDetails?.id) {
                this.investorDetails = investor;
                this.displayInvestorDetails = true;
            } else {
                this.displayInvestorDetails = !this.displayInvestorDetails;
            }
        },
        closeInvestorDetails() {
            this.displayInvestorDetails = false;
            this.investorDetails = null;
        },
        add() {
            this.$router
                .push({
                    name: "add-new-investor",
                    params: this.$route.params
                })
                .catch(() => {});
        }
    }
};
</script>

<style lang="scss" scoped>
.investor-container-card {
    height: 100%;
}
</style>
