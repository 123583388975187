<template>
    <v-dialog
        :value.sync="isOpenProxy"
        max-width="551px"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        @click:outside="closeDialog('close')"
    >
        <v-card>
            <v-card-title>
                <v-container class="pa-0">
                    <v-row>
                        <v-col />
                        <v-col align-self="center">
                            <div class="d-flex justify-center">
                                <v-icon class="soft-circles" size="24" dense color="#039855"
                                    >mdi-check-circle-outline</v-icon
                                >
                            </div>
                        </v-col>
                        <v-col class="pa-0" align-self="start">
                            <div class="d-flex justify-end">
                                <v-btn small icon @click="closeDialog('close')">
                                    <v-icon size="28">mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <div class="title-card">
                            {{ title }}
                        </div>
                    </v-row>
                </v-container>
            </v-card-title>

            <v-divider />
            <v-container fluid class="ma-0 pa-0">
                <v-row align="start" justify="space-between" dense no-gutters class="ma-4">
                    <v-col
                        v-if="type != 'view_bc'"
                        :class="`text-center card-client-selection
                ${selectClientType === 'physic' && 'cart-client-selection-select'}`"
                        @click="selectClient('physic')"
                    >
                        <v-icon class="">mdi-account</v-icon>
                        <div>
                            {{ $t("universe.kyoseil.adding-modal.individual") }}
                        </div>
                        <!-- <p>
                            {{ $t("universe.kyoseil.adding-modal.individual-text") }}
                        </p> -->
                    </v-col>
                    <v-col
                        :class="`text-center card-client-selection
                ${selectClientType === 'moral' && 'cart-client-selection-select'}`"
                        @click="selectClient('moral')"
                    >
                        <v-icon class="">mdi-briefcase</v-icon>
                        <div>
                            {{ $t("universe.kyoseil.adding-modal.legal-entity") }}
                        </div>
                        <!-- <p>
                            {{ $t("universe.kyoseil.adding-modal.legal-entity-text") }}
                        </p> -->
                    </v-col>
                    <v-col
                        :class="`text-center card-client-selection
                ${selectClientType === 'exists' && 'cart-client-selection-select'}`"
                        @click="selectClient('exists')"
                    >
                        <v-icon class="">mdi-account-convert</v-icon>
                        <div>
                            {{ $t("universe.kyoseil.adding-modal.exists") }}
                        </div>
                        <!-- <p>
                            {{ $t("universe.kyoseil.adding-modal.exists-text") }}
                        </p> -->
                    </v-col>
                </v-row>
            </v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                    <transition name="fade" mode="out-in">
                        <div v-if="selectClientType === 'moral'" key="1">
                            <v-row class="mb-4">
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">
                                        {{ $t("universe.kyoseil.adding-modal.company-name") }}
                                    </p>
                                    <v-text-field
                                        v-model="companyName"
                                        :rules="companyNameRules"
                                        label=""
                                        :placeholder="
                                            $t('universe.kyoseil.adding-modal.company-name')
                                        "
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">
                                        {{ $t("universe.kyoseil.adding-modal.siren") }}
                                    </p>
                                    <v-text-field
                                        v-model="companyId"
                                        label=""
                                        :rules="companyIdRules"
                                        :placeholder="$t('universe.kyoseil.adding-modal.siren')"
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="mb-4">
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">
                                        {{ $t("universe.kyoseil.adding-modal.first-name") }}
                                    </p>
                                    <v-text-field
                                        v-model="lastName"
                                        label=""
                                        :rules="lastNameRules"
                                        :placeholder="
                                            $t(
                                                'universe.kyoseil.adding-modal.placeholder-first-name'
                                            )
                                        "
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">
                                        {{ $t("universe.kyoseil.adding-modal.name") }}
                                    </p>
                                    <v-text-field
                                        v-model="firstName"
                                        label=""
                                        :rules="firstNameRules"
                                        :placeholder="
                                            $t('universe.kyoseil.adding-modal.placeholder-name')
                                        "
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="mb-4">
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">
                                        {{ $t("universe.kyoseil.adding-modal.email") }}
                                    </p>
                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        label=""
                                        placeholder="email@example.com"
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">{{ $t("registration.phone") }}</p>
                                    <v-text-field
                                        v-model="phone"
                                        :rules="phoneRules"
                                        label=""
                                        placeholder="+33645342323"
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="mb-4">
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">{{ $t("registration.birth-date") }}</p>
                                    <v-menu
                                        v-model="datePickerModel"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template #activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="birthDate"
                                                :rules="birthDateRules"
                                                prepend-icon="mdi-calendar"
                                                placeholder="04/06/1990"
                                                hint="format JJ/MM/AAAA"
                                                persistent-hint
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="birthDate"
                                            locale="fr-fr"
                                            :first-day-of-week="1"
                                            no-title
                                            @input="datePickerModel = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">{{ $t("registration.nationality") }}</p>
                                    <v-autocomplete
                                        v-model="nationality"
                                        :rules="nationalityRules"
                                        :loading="countries.length === 0"
                                        :items="countries"
                                        dense
                                        placeholder="France"
                                        item-text="name"
                                        item-value="url"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else-if="selectClientType === 'physic'" key="2">
                            <v-row class="mb-4">
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">
                                        {{
                                            $t(
                                                "universe.kyoseil.adding-modal.placeholder-first-name"
                                            )
                                        }}
                                    </p>
                                    <v-text-field
                                        v-model="lastName"
                                        label=""
                                        :rules="lastNameRules"
                                        :placeholder="
                                            $t(
                                                'universe.kyoseil.adding-modal.placeholder-first-name'
                                            )
                                        "
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">
                                        {{ $t("universe.kyoseil.adding-modal.placeholder-name") }}
                                    </p>
                                    <v-text-field
                                        v-model="firstName"
                                        label=""
                                        :rules="firstNameRules"
                                        :placeholder="
                                            $t('universe.kyoseil.adding-modal.placeholder-name')
                                        "
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="mb-4">
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">
                                        {{ $t("universe.kyoseil.adding-modal.email") }}
                                    </p>
                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        label=""
                                        placeholder="email@example.com"
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="form-input-container">
                                    <p class="mail-header">{{ $t("registration.phone") }}</p>
                                    <v-text-field
                                        v-model="phone"
                                        :rules="phoneRules"
                                        label=""
                                        placeholder="+33645342323"
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else-if="selectClientType === 'exists'" key="3">
                            <v-row class="mb-4">
                                <v-col cols="12" class="form-input-container">
                                    <p class="mail-header">
                                        {{ $t("universe.kyoseil.adding-modal.email-exists") }}
                                    </p>
                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        label=""
                                        placeholder="email@example.com"
                                        required
                                        :disabled="loading"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </transition>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        :disabled="loading"
                        class="card-btn"
                        color="primary"
                        outlined
                        @click.stop="closeDialog('close')"
                        >{{ $t("universe.kyoseil.adding-modal.cancel") }}</v-btn
                    >
                    <v-btn
                        :disabled="!valid"
                        class="card-btn"
                        color="primary"
                        @click.stop="createBusinessPartenaire()"
                    >
                        <template v-if="loading">
                            <v-progress-circular indeterminate color="white" />
                        </template>
                        <template v-else>
                            {{ $t("universe.kyoseil.adding-modal.send") }}
                        </template>
                    </v-btn>
                </v-card-actions>
            </v-form>
            <div
                v-if="error"
                class="white red--text text--darken-2 text-center text-caption font-weight-regular"
            >
                {{ error }}
            </div>
            <div class="pb-8 white" />
            <!-- error doesnt always render, and its easier than selecting v-card with :deep selector -->
        </v-card>
    </v-dialog>
</template>

<script>
import {
    requiredR,
    max100R,
    phoneR,
    emailR,
    numberR,
    nameR,
    protectedCharR
} from "@equisafe-ui-vue/src/rules";

export default {
    name: "AddEntityModal",
    props: {
        isOpen: {
            type: Boolean,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    emits: ["add-modal-close", "add-modal-post"],
    data() {
        return {
            valid: true,
            selectClientType: this.type != "view_bc" ? "physic" : "moral",
            datePickerModel: false,
            nationality: "",
            nationalityRules: [requiredR],
            email: "",
            emailRules: [requiredR, emailR, max100R],
            phone: "",
            phoneRules: [requiredR, phoneR, max100R],
            firstName: "",
            firstNameRules: [requiredR, nameR, max100R],
            lastName: "",
            lastNameRules: [requiredR, nameR, max100R],
            companyId: "",
            companyIdRules: [
                requiredR,
                numberR,
                v =>
                    (v && (v.length === 9 || v.length === 14)) ||
                    this.$t("universe.kyoseil.error.siren-must")
            ],
            companyName: "",
            companyNameRules: [requiredR, protectedCharR, max100R],
            birthDate: "",
            birthDateRules: [requiredR],
            countries: [],
            loading: false,
            error: ""
        };
    },
    computed: {
        isOpenProxy: {
            get() {
                return this.isOpen;
            },
            set(val) {
                if (!val) this.$emit("add-modal-close");
            }
        }
    },
    beforeMount() {
        this.getCountries();
    },
    methods: {
        async getCountries() {
            try {
                const res = await this.$api.getCountries();
                this.countries = res.data;
            } catch (e) {
                throw e;
            }
        },
        async createBusinessPartenaire() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.loading = true;
            try {
                let data = {};
                if (this.selectClientType === "moral") {
                    data = {
                        is_company: true,
                        first_name: this.firstName,
                        last_name: this.lastName,
                        email: this.email,
                        phone: this.phone,
                        birth_date: this.birthDate,
                        nationality: this.nationality,
                        company_name: this.companyName,
                        company_idcode: this.companyId
                    };
                } else if (this.selectClientType === "physic") {
                    data = {
                        first_name: this.firstName,
                        last_name: this.lastName,
                        email: this.email,
                        phone: this.phone
                    };
                } else if (this.selectClientType === "exists") {
                    data = {
                        email: this.email,
                        exists: true
                    };
                } else {
                    this.loading = false;
                    return;
                }

                let resp;
                if (this.type === "view_bc") {
                    resp = await this.$api.createCGP(data);
                } else if (this.type === "view_cgp") {
                    resp = await this.$api.createClient(data);
                } else if (this.type === "view_admin") {
                    resp = await this.$api.createBusinessProvider(data);
                }
                this.$emit("add-modal-post");
                this.closeDialog("success");
            } catch (error) {
                this.error = "Une erreur s'est produite.";
                if (error.data) {
                    const [key, value] = Object.entries(error.data)[0];
                    this.error = `${key}: ${value}`;
                }
                this.$ui.error(this.error);
                throw error;
            } finally {
                this.loading = false;
            }
        },
        selectClient(type) {
            return (this.selectClientType = type);
        },
        closeDialog(origin) {
            this.$emit("add-modal-close", origin);
            this.$refs.form.reset();
            this.error = "";
        }
    }
};
</script>

<style scoped lang="scss">
.title-client-type {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #17375f !important;
}

.card-client-selection {
    border: 2px solid #f5f7fd;
    border-radius: 8px;
    padding: 8px;
    margin-top: 8px;
}

.card-client-selection:not(:last-child) {
    margin-right: 8px;
}

.card-client-selection:hover {
    background: #f5f7fd;
    cursor: pointer;
    border: 2px solid #153580;
}

.card-client-selection p {
    margin-top: 1rem;
    text-align: center;
}

:deep(.v-card__subtitle > .custom-card) {
    padding-top: 24px;
    padding-bottom: 0px;
    text-align: left;
    color: #0d0d2b;
}

.soft-circles {
    width: 48px;
    height: 48px;
    background: #d1fadf;
    border: 8px solid #ecfdf3;
    border-radius: 50%;
}

.title-card {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    width: 100%;
}

.card-btn {
    flex: 1;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-item: center;
    text-transform: none;
    border-radius: 2px;
    border: 2px solid #17375f !important;
}

.mail-desc {
    margin: 16px auto;
    color: #0d0d2b;
}

.mail-header {
    font-weight: 500;
    color: #17375f;
    margin-bottom: 0.5rem;
}

.mail-input {
    background: #ffffff;
    border: 1.5px solid #cbceda;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    height: 54px;
    padding: 6px 22px;
}

.form-input-container {
    width: 100%;
}
</style>
