import { docs_kyc_mandatory } from "./individual_docs";
import { defaultRouteBtn, registrationBtn, CgpSchemaDouble } from "./individual";

export const capitalCompositionDocs = ctx => {
    return [
        {
            key: "doc_tax_reports",
            text: ctx.$t("registration.company.tax-reports.txt"),
            label: ctx.$t("registration.company.tax-reports.label"),
            others: {
                lemonway_type: 19
            }
        },
        {
            key: "doc_letter_signed_by_legal_representative",
            text: ctx.$t("registration.company.letter-signed-company.txt"),
            label: ctx.$t("registration.company.letter-signed-company.label"),
            others: {
                lemonway_type: 19
            }
        },
        {
            key: "doc_cerfa_2035_f",
            text: ctx.$t("registration.company.cerfa"),
            label: ctx.$t("registration.company.cerfa"),
            others: {
                lemonway_type: 19
            }
        }
    ];
};

export const publicInfoSchemaDouble = {
    // avatar: {
    //   isRequired: false,
    //   dataType: "file",
    // },
    vat_number: {
        isRequired: true
    },
    company_name: {
        isRequired: true
    },
    contact_email: {
        isRequired: false
    },
    incorporation_date: {
        isRequired: true
    },
    legal_type: {
        isRequired: true
    },
    company_idcode: {
        isRequired: true
    },
    company_website: {
        isRequired: false
    },
    phone: {
        isRequired: false
    },
    industry_type: {
        isRequired: true
    },
    real_estate_type: {
        isRequired: false
    }
};

export const privateInfoSchemaDouble = {
    street: {
        isRequired: true
    },
    zipcode: {
        isRequired: true
    },
    city: {
        isRequired: true
    },
    country: {
        isRequired: true
    }
};

export const CompanyNonlistedDocs1SchemaDouble = ctx => {
    return {
        doc_statutes: {
            dataType: "file",
            isRequired: true,
            others: {
                key: "doc_statutes",
                lemonway_type: 12
            }
        },
        doc_kbis: {
            dataType: "file",
            isRequired: true,
            others: {
                key: "doc_kbis",
                lemonway_type: 7
            }
        },
        doc_declaration_beneficial_owner_registry_commercial_court: {
            dataType: "file",
            isRequired: true,
            others: {
                key: "doc_dbes1",
                lemonway_type: 19
            }
        },
        docs_2: {
            dataType: "multi-file",
            isRequired: false,
            categories: capitalCompositionDocs(ctx),
            numSelect: 1
        }
        // doc_address: {
        //     dataType: "file",
        //     isRequired: false,
        //     others: {
        //         key: "doc_address",
        //         lemonway_type: 1
        //     }
        // }
        // cgu: {
        //   dataType: "bool",
        //   isRequired: true,
        // },
    };
};

export const CompanyNonlistedDocs2SchemaDouble = ctx => {
    return {
        docs_1: {
            dataType: "multi-file",
            isRequired: true,
            categories: docs_kyc_mandatory(ctx),
            numSelect: 1
        }
    };
};

export const CompanyNonlistedDocs3SchemaDouble = ctx => {
    return {
        doc_idshareholder_1: {
            dataType: "file",
            isRequired: false,
            others: {
                key: "doc_idshareholder_1",
                lemonway_type: 15
            }
        },
        doc_idshareholder_2: {
            dataType: "file",
            isRequired: false,
            others: {
                key: "doc_idshareholder_2",
                lemonway_type: 16
            }
        },
        doc_idshareholder_3: {
            dataType: "file",
            isRequired: false,
            others: {
                key: "doc_idshareholder_3",
                lemonway_type: 17
            }
        },
        doc_idshareholder_4: {
            dataType: "file",
            isRequired: false,
            others: {
                key: "doc_idshareholder_4",
                lemonway_type: 18
            }
        }
    };
};

export const CompanyCustodianDocs1SchemaDouble = ctx => {
    return {
        docs_1: {
            dataType: "multi-file",
            isRequired: true,
            categories: docs_kyc_mandatory(ctx),
            numSelect: 1
        },
        doc_remit: {
            dataType: "file",
            isRequired: true,
            others: {
                key: "doc_remit",
                lemonway_type: 14
            }
        },
        doc_address: {
            dataType: "file",
            isRequired: false,
            others: {
                key: "doc_address",
                lemonway_type: 1
            }
        },
        cgu: {
            dataType: "bool",
            isRequired: true
        }
    };
};
export const CompanyAssociationDocs1SchemaDouble = ctx => {
    return {
        docs_1: {
            dataType: "multi-file",
            isRequired: true,
            categories: docs_kyc_mandatory(ctx),
            numSelect: 1
        },
        doc_last_report_general_assembly: {
            dataType: "file",
            isRequired: true,
            others: {
                key: "doc_last_report_general_assembly",
                lemonway_type: 14
            }
        },
        doc_statutes: {
            dataType: "file",
            isRequired: true,
            others: {
                key: "doc_statutes",
                lemonway_type: 12
            }
        },
        doc_official_gazette_publication: {
            dataType: "file",
            isRequired: true,
            others: {
                key: "doc_official_gazette_publication",
                lemonway_type: 19
            }
        },
        doc_address: {
            dataType: "file",
            isRequired: false,
            others: {
                key: "doc_address",
                lemonway_type: 2
            }
        }
        // cgu: {
        //   dataType: "bool",
        //   isRequired: true,
        // },
    };
};

export const CguSchemaDouble = ctx => {
    return {
        cgu: {
            dataType: "bool",
            isRequired: true
        }
    };
};

export function isSpecialAccount(account) {
    const specialAccountType = ["custodian", "company_administration"];
    return !!specialAccountType.includes(account);
}

const getCompanyRequiredDocs = (ctx, accountType) => {
    switch (accountType) {
        case "company_nonlisted":
            return [
                {
                    title: ctx.$t("registration.kyc.company.company-documents"),
                    item: { is: "CompanyNonlistedDocs1" },
                    next: { fn: ctx.updateUser },
                    schemaDouble: CompanyNonlistedDocs1SchemaDouble(ctx)
                },
                {
                    title: ctx.$t("registration.kyc.company.custodian-documents"),
                    item: { is: "CompanyNonlistedDocs2" },
                    next: { fn: ctx.updateUser },
                    schemaDouble: CompanyNonlistedDocs2SchemaDouble(ctx)
                },
                {
                    title: ctx.$t("registration.kyc.company.shareholders-documents"),
                    item: { is: "CompanyNonlistedDocs3" },
                    next: { fn: ctx.updateUser },
                    schemaDouble: CompanyNonlistedDocs3SchemaDouble(ctx)
                }
            ];
        case "company_association":
            return [
                {
                    title: ctx.$t("registration.kyc.company.required-documents"),
                    item: { is: "CompanyAssociationDocs1" },
                    next: { fn: ctx.updateUser },
                    schemaDouble: CompanyAssociationDocs1SchemaDouble(ctx)
                }
            ];
        case "custodian":
            return [
                {
                    title: ctx.$t("registration.kyc.company.required-documents"),
                    item: { is: "CompanyCustodianDocs1" },
                    next: { fn: ctx.updateUser },
                    schemaDouble: CompanyCustodianDocs1SchemaDouble(ctx)
                }
            ];
    }
};

const getCompanySteps = (ctx, accountType) => {
    const companyGeneralInfo = {
        title: ctx.$t("registration.kyc.company.company-general-info"),
        item: {
            is: "CompanyInfoForm",
            wallet: true,
            specialAccount: isSpecialAccount(accountType)
        },
        noBack: !isSpecialAccount(accountType),
        next: {
            fn: async () => ctx.updateUser(ctx.createLWWallet)
        },
        schemaDouble: {
            ...publicInfoSchemaDouble,
            ...privateInfoSchemaDouble
        }
    };

    const accountFinalize = {
        title: ctx.$t("registration.kyc.company.finalize-account"),
        item: {
            is: "IndividualCgu"
        },
        next: {
            fn: async () => ctx.updateUser(ctx.validateRegistration)
        },
        schemaDouble: CguSchemaDouble(ctx)
    };

    let companyRequiredDocs = {};
    if (!ctx.$universe.isExternKyc()) {
        companyRequiredDocs = getCompanyRequiredDocs(ctx, accountType);
    }
    let steps = [companyGeneralInfo, ...companyRequiredDocs, accountFinalize];
    if (ctx.$entity.isAffiliationCgp()) {
        const cgpDocs = {
            title: ctx.$t("user.profile.cgp-docs.subtitle"),
            item: {
                is: "CgpDocsForm",
                isCgp: true
            },
            next: {
                fn: async () => ctx.updateUserCgp()
            },
            schemaDouble: CgpSchemaDouble(ctx, true)
        };
        // Insert step before finalize
        steps.splice(steps.length - 1, 0, cgpDocs);
        if (ctx.$universe.isKyoseil()) {
            const cgpSign = {
                title: ctx.$t("user.profile.cgp-sign.subtitle"),
                item: {
                    is: "GlobalYousignIFrame"
                },
                noPadding: true,
                next: {
                    fn: () =>
                        ctx.signatureDone ? ctx.getUpdatedUser() : ctx.fetchSignatureState(),
                    txt: ctx.$t("user.wallet.money-in.confirm-payment")
                },
                schemaDouble: {
                    signature_done: {
                        dataType: "bool",
                        isRequired: true
                    }
                }
            };
            steps.splice(steps.length - 1, 0, cgpSign);
        }
    } else if (
        ctx.$universe.isKyoseil() &&
        !ctx.$entity.hasAffiliationRole() &&
        !ctx.$entity.isAffiliationInvestor()
    ) {
        const infoDirectInvestSign = {
            title: ctx.$t("user.profile.cgp-sign.subtitle"),
            item: {
                is: "GlobalYousignIFrame"
            },
            noPadding: true,
            next: {
                fn: () => (ctx.signatureDone ? ctx.getUpdatedUser() : ctx.fetchSignatureState()),
                txt: ctx.$t("user.wallet.money-in.confirm-payment")
            },
            schemaDouble: {
                signature_done: {
                    dataType: "bool",
                    isRequired: true
                }
            }
        };
        // Insert step before finalize
        steps[steps.length - 2].next.fn = ctx.updateUserBeforeDirectInvestKyoseil;
        steps.splice(steps.length - 1, 0, infoDirectInvestSign);
    }
    return steps;
};

export const registrationStepError = ctx => {
    const schemaDoubleSteps = registrationSteps(ctx).map(item => item.schemaDouble);
    const schemaDouble = Object.assign({}, ...schemaDoubleSteps);
    let is = null;
    const accountType = ctx.entity.account_type;
    switch (accountType) {
        case "company_nonlisted":
            is = "CompanyNonlistedErrorForm";
            break;
        case "company_association":
            is = "CompanyAssociationErrorForm";
            break;
        case "custodian":
            is = "CompanyCustodianErrorForm";
            break;
    }
    return [
        {
            title: ctx.$t("registration.error"),
            item: {
                is,
                specialAccount: isSpecialAccount(accountType)
            },
            schemaDouble,
            noBack: true,
            next: {
                fn: async () => ctx.updateUser(ctx.validateRegistration)
            }
        }
    ];
};

export const contactInfos = ctx => {
    return {
        is: "CompanyInfoForm",
        schemaDouble: {
            ...publicInfoSchemaDouble,
            ...privateInfoSchemaDouble
        }
    };
};

export const legalInfos = ctx => {
    const accountType = ctx.entity.account_type;
    // console.log({
    //   accountType,
    // });
    if (isSpecialAccount(accountType)) {
        return {
            is: "CustodianLegalInfosForm",
            schemaDouble: CompanyCustodianDocs1SchemaDouble(ctx)
        };
    }
    return accountType === "company_nonlisted"
        ? {
              is: "NonListedLegalInfosForm",
              schemaDouble: {
                  ...CompanyNonlistedDocs1SchemaDouble(ctx),
                  ...CompanyNonlistedDocs2SchemaDouble(ctx),
                  ...CompanyNonlistedDocs3SchemaDouble(ctx)
              }
          }
        : {
              is: "AssociationLegalInfosForm",
              schemaDouble: CompanyAssociationDocs1SchemaDouble(ctx)
          };
};

export const registrationSteps = ctx => {
    const accountType = ctx.entity.account_type;
    const companySteps = getCompanySteps(ctx, accountType);

    return isSpecialAccount(accountType)
        ? [
              {
                  title: ctx.$t("registration.general-info"),
                  item: {
                      is: "IndividualPublicForm"
                  },
                  noBack: true,
                  next: {
                      fn: ctx.updateUser
                  },
                  schemaDouble: individualPublicInfoSchemaDouble
              },
              ...companySteps
          ]
        : companySteps;
};

export const registrationVerify = ctx => {
    const { account_type } = ctx.entity;
    return {
        pending: {
            msg: ctx.$t("registration.kyc.kyb-provider.pending", {
                name: ctx.$entity.myEntity().name,
                company: ctx.$entity.represented().name
            }),
            continue: defaultRouteBtn(ctx)
        },
        error: {
            msg: ctx.$t("registration.kyc.kyb-provider.error"),
            continue: registrationBtn(ctx)
        },
        success: {
            msg: ctx.$t("registration.kyc.kyb-provider.success-kyc", {
                name: ctx.$entity.myEntity().name,
                company: ctx.$entity.represented().name
            }),
            continue: defaultRouteBtn(ctx)
        }
    };
};

const allLegalForm = {
    FR: [
        "SASU - Société par Actions Simplifiée Unipersonnelle",
        "SARL - Société À Responsabilité Limitée",
        "SAS - Société par Actions Simplifiée",
        "EI/EIRL - Entrepreneur Individuel (auto/micro-entrepreneur, freelance)",
        "EURL - Entreprise Unipersonnelle à Responsabilité Limitée",
        "SCP - Société Civile Professionnelle",
        "SCI - Société Civile Immobilière",
        "SC - Autre Société Civile",
        "SA - Société Anonyme à conseil d’administration",
        "SNC - Société en Nom Collectif",
        "SCA - Société en Commandite",
        "GIE - Groupement d’Intérêt Économique"
    ],
    DE: [
        "GmbH",
        "GmbH in Gründung (i.G.)",
        "gGmbH",
        "UG",
        "UG in Gründung (i.G.)",
        "AG",
        "AG & Co. KG",
        "GmbH & Co. KG",
        "e.K.",
        "oHG",
        "GmbH & Co. oHG",
        "AG & Co. oHG",
        "UG & Co. KG",
        "Kommanditgesellschaft (KG)",
        "KGaA",
        "Einzelunternehmer"
    ],
    IT: [
        "Imprenditore individuale (in possesso di visura camerale)",
        "Libero professionista/Lavoratore autonomo",
        "Società semplice (S.s.)",
        "Società in nome collettivo (S.n.c.)",
        "Società in accomandita semplice (S.a.s.)",
        "Studio associato e società di professionisti",
        "Società per azioni (S.p.a.)",
        "Società in accomandita per azioni (S.a.p.a.)",
        "Società a responsabilità limitata (S.r.l.)",
        "Società a responsabilità limitata con un socio unico",
        "Società a responsabilità limitata semplificata (S.r.l.s)"
    ],
    ES: [
        "Sociedad de Responsabilidad Limitada (SL)",
        "Empresario Individual (Autónomo)",
        "Sociedad Anónima (SA)",
        "Emprendedor de Responsabilidad Limitada",
        "Sociedad Anónima Laboral (SAL)",
        "Sociedad Colectiva",
        "Sociedad Comanditaria por Acciones",
        "Sociedad Comanditaria Simple",
        "Sociedad de Responsabilidad Limitada Laboral (SLL)",
        "Sociedad Limitada de Formación Sucesiva (SLFS)",
        "Sociedad Limitada Nueva Empresa (SLNE)",
        "Sociedad Profesionales (SAP/SAL)"
    ]
};

export function getLegalFormSelect(ctx) {
    let items = [];
    const countries = ["FR", "DE", "IT", "ES"];
    items.push({
        text: ctx.$t("app.country.other"),
        value: ctx.$t("app.country.other")
    });
    for (const country of countries) {
        items.push({
            divider: true,
            header: ctx.$t(`app.country.${country}`)
        });
        for (const form of allLegalForm[country]) {
            items.push({
                text: form,
                value: form
            });
        }
    }

    return items;
}

export function getLegalForm(ctx) {
    const nationality =
        ctx.formData.nationality && ctx.formData.nationality.alphacode
            ? ctx.formData.nationality.alphacode
            : "FR";
    const legalFormList = allLegalForm[_.has(allLegalForm, nationality) ? nationality : "FR"];
    return legalFormList;
}

const allLegalFormCased = {};
for (const country in allLegalForm) {
    allLegalFormCased[country] = allLegalForm[country].map(form => form.toUpperCase());
}

export function getLegalFormComponentType(ctx) {
    const nationality =
        ctx.formData.nationality && ctx.formData.nationality.alphacode
            ? ctx.formData.nationality.alphacode
            : null;
    const inputType = nationality && _.has(allLegalForm, nationality) ? "EqsSelect" : "EqsText";
    if (inputType == "EqsSelect" && ctx.formData.legal_type && ctx.formData.legal_type.length) {
        const idx = _.indexOf(
            allLegalFormCased[nationality],
            ctx.formData.legal_type.toUpperCase()
        );
        if (idx == -1) ctx.$set(ctx.formData, "legal_type", "");
        else ctx.$set(ctx.formData, "legal_type", allLegalForm[nationality][idx]);
    }
    return inputType;
}
