var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsStepper',_vm._b({staticStyle:{"height":"100%"},attrs:{"value":_vm.step,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":() => _vm.$emit('close')},scopedSlots:_vm._u([{key:"default",fn:function({ item, loading: isLoading }){return [_c('div',{staticClass:"eqs-main-container margin-auto"},[_c(item.is,_vm._b({tag:"component",attrs:{"step":_vm.step,"form-data":_vm.formData},on:{"update:step":function($event){_vm.step=$event},"update:formData":function($event){_vm.formData=$event},"update:form-data":function($event){_vm.formData=$event}}},'component',{
                    loading: isLoading,
                    order: _vm.order,
                    status: _vm.status,
                    options: _vm.getOptions,
                    pollOrder: _vm.pollOrder,
                    totalVal: _vm.totalVal,
                    schemaDouble: _vm.schemaDouble
                },false))],1)]}}])},'EqsStepper',{
        steps: _vm.steps,
        loading: _vm.loading || _vm.localLoading || _vm.vibanLoading,
        progress: _vm.progress
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }