import { i18n } from "@/services/plugins/i18n.js";

export const assetMovementType = type => {
    switch (type) {
        case "book_entry":
            return i18n.t("excel-js.asset-movement-type.book-entry");
        case "cession":
            return i18n.t("excel-js.asset-movement-type.cession");
        case "emission":
            return i18n.t("excel-js.asset-movement-type.emission");
        case "increase":
            return i18n.t("excel-js.asset-movement-type.capital-increase");
        case "decrease":
            return i18n.t("excel-js.asset-movement-type.capital-decrease");
        default:
            return null;
    }
};
