export const errorMsg = {
    WRONG_TYPE: "Wrong syntax. Provide a position as following : 'B5'",
    WRONG_VALUE: "Column or Row went under 1 value."
};

export const positionComponent = {
    eqsIcon: "A1:C3",
    titleWs: "F1:H2",
    companyInformation: "E3",
    tableHeader: "A7",
    tableContent: "A8",
    shareholderInfo: "D3"
};

export class Position {
    constructor(_position) {
        if (!_position.match(/^[A-Z]+\d+$/)) throw Error(errorMsg.WRONG_TYPE);
        this._col = this.lettersToNumber(_position.match(/[A-Z]*/)[0]);
        this._row = Number(_position.match(/\d+/g)[0]);
        if (!this._row || !this._col) throw Error(errorMsg.WRONG_TYPE);
        this._position = _position;
        this._positionCol = this._col;
        this._positionRow = this._row;
        this._tmpPosition = _position;
    }

    lettersToNumber(letters) {
        if (!letters) return null;
        for (var p = 0, n = 0; p < letters.length; p++) {
            n = letters[p].charCodeAt() - 64 + n * 26;
        }
        return n;
    }

    numberToLetter(num) {
        if (!num) return null;
        for (var ret = "", a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
            ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
        }
        return ret;
    }

    areaToPoint(area) {
        return area.split(":");
    }

    _checkValue(val) {
        if (val <= 0) throw Error("");
    }

    col(nb) {
        this._col += nb;
        this._checkValue(this._col);
        return this;
    }

    row(nb) {
        this._row += nb;
        this._checkValue(this._row);
        return this;
    }

    _toString(col, row) {
        return `${this.numberToLetter(col)}${String(row)}`;
    }

    toString(col = 0, row = 0) {
        return this._toString(this._col + col, this._row + row);
    }

    area(arr1, arr2) {
        this._tmpPosition = `${this.toString(arr1[0], arr1[1])}:${this.toString(arr2[0], arr2[1])}`;
        return this;
    }
}
