<template>
    <GlobalTextEditorDisplay v-bind="{ value: html, loading: false }" />
</template>

<script>
import GlobalTextEditorDisplay from "./GlobalTextEditorDisplay.vue";
import { html } from "./test.js";
export default {
    name: "TestTextDisplay",
    components: {
        GlobalTextEditorDisplay
    },
    data() {
        return {
            html
        };
    }
};
</script>

<style lang="scss" scoped></style>
