import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {}
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            size: getVal(currentSize, {
                mobile: "4.5em",
                tablet: "5em",
                desktop: "6em"
            })
        }
    };
};
