// import Auth from '@/services/authProvider'
// import _ from 'lodash'
// import router from '@/router/router'
// import DEBUG from '@/store/debug.js'
// import store from '@/store/store'

export default async ({ commit, dispatch }, user) => {
    // DEBUG('LOGIN_START')
    // try {
    //   const res = await Auth.login({
    //     login: user.login,
    //     password: user.password,
    //   })
    //   DEBUG('LOGIN_SUCCESS')
    //   await dispatch('getSession')
    //   const entityRepresentedId = store.getters.currentRole.entity_represented.id
    //   router.push(`/transactions/${entityRepresentedId}`).catch(err => {})
    // } catch (error) {
    //   // DEBUG("LOGIN_ERROR");
    //   console.error({
    //     error
    //   })
    //   if (error && error.message) {
    //     if (error.message === 'User does not exist.') {
    //       error.message = 'User does not exist. Check your email or register.'
    //     }
    //     commit('MSG_ERROR', error.message)
    //   } else {
    //     commit('MSG_ERROR', 'Error whilst login in, try again')
    //   }
    // }
};
