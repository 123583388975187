var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"root-step-container",attrs:{"fluid":""}},[_c('h2',{staticClass:"text-h5 mb-4"},[_vm._v("Connaissances de l’Investisseur")]),_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p1q1,"multiple":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q1", $event)}}},'EqsSelect',{
            label: 'Quels types de services d’investissement connaissez-vous ?',
            items: _vm.q1Items,
            ..._vm.schemaDouble.p1q1
        },false)),_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p1q2,"multiple":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q2", $event)}}},'EqsSelect',{
            label: 'Quels types d’instruments financiers connaissez-vous ?',
            items: _vm.q2Items,
            ..._vm.schemaDouble.p1q2
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p1q3,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q3", $event)}}},'EqsRadio',{
            label: 'Avez-vous déjà investi dans des instruments financiers ?',
            ..._vm.schemaDouble.p1q3
        },false)),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.p1q3 === 'yes'),expression:"formData.p1q3 === 'yes'"}],staticClass:"pa-0 ma-0"},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p1q4,"multiple":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q4", $event)}}},'EqsSelect',{
                label: ' Si oui, sur quels types d’instruments financiers portaient vos investissements ?',
                items: _vm.q4Items,
                ..._vm.schemaDouble.p1q5
            },false))],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.p1q3 === 'yes'),expression:"formData.p1q3 === 'yes'"}],staticClass:"pa-0 ma-0"},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.formData.p1q5,"is-currency":false,"is-float":false},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q5", $event)}}},'EqsNumber',{
                label: 'Quel était le volume de vos investissements ?',
                editable: true,
                placeholder: 'Ex: 20000',
                ..._vm.schemaDouble.p1q5
            },false))],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.p1q3 === 'yes'),expression:"formData.p1q3 === 'yes'"}],staticClass:"pa-0 ma-0"},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p1q6},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q6", $event)}}},'EqsSelect',{
                label: 'Quelle était la fréquence de vos investissements ?',
                items: _vm.q6Items,
                ..._vm.schemaDouble.p1q6
            },false))],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.p1q3 === 'yes'),expression:"formData.p1q3 === 'yes'"}],staticClass:"pa-0 ma-0"},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p1q7},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q7", $event)}}},'EqsSelect',{
                label: 'Sur quelle période vos investissements ont-ils été réalisés ?',
                items: _vm.q7Items,
                ..._vm.schemaDouble.p1q7
            },false))],1),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p1q8,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q8", $event)}}},'EqsRadio',{
            label: 'Avez-vous exercé ou exercez-vous actuellement une profession dans le domaine financier ?',
            ..._vm.schemaDouble.p1q8
        },false)),_c('EqsRadio',_vm._b({attrs:{"value":_vm.formData.p1q9,"row":""},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q9", $event)}}},'EqsRadio',{
            label: 'Vos expériences professionnelles passées vous ont-elles permis d’acquérir des compétences en financement participatif ?',
            ..._vm.schemaDouble.p1q9
        },false)),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.p1q9 === 'yes'),expression:"formData.p1q9 === 'yes'"}],staticClass:"pa-0 ma-0"},[_c('EqsText',_vm._b({attrs:{"value":_vm.formData.p1q10},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q10", $event)}}},'EqsText',{
                label: 'Si oui, lesquelles, et quelles ont été leur durée ?',
                editable: _vm.editable,
                placeholder: 'Ex: 8 moins',
                ..._vm.schemaDouble.p1q10
            },false))],1),_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.p1q11},on:{"update:value":function($event){return _vm.$set(_vm.formData, "p1q11", $event)}}},'EqsSelect',{
            label: 'Quel est votre niveau d’étude maximal atteint dans le domaine financier ?',
            items: _vm.q11Items,
            ..._vm.schemaDouble.p1q11
        },false)),_c('v-dialog',{attrs:{"closable":"","transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',{staticClass:"text-h5",staticStyle:{"word-break":"break-word"}},[_vm._v(" Investisseur non averti")]),_c('v-card-text',[_c('strong',[_vm._v("Vous avez obtenu le statut d’Investisseur non-averti")]),_vm._v(" selon les conditions du Règlement (UE) 2020/1503 relatif aux prestataires européens de services de financement participatif. "),_c('br'),_c('br'),_vm._v(" Nous allons donc vous faire passer un test de connaissance et un simulateur de capacité à subir des pertes afin d’identifié votre profil investisseur. "),_c('br'),_c('br'),_vm._v(" Vous disposerez d’un délai de réflexion de quatre jours (4) au cours duquel vous pourrez retirer une offre d’investissement. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogProxy = false}}},[_vm._v(" J'ai compris ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }