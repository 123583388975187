import events from "@equisafe-ui-vue/src/events";
import init from "./init";

// FIXME ce module devra disparaitre quand tous les services n'utiliseront plus ces mutations
export default {
    MSG_ERROR(state, message) {
        events.$emit("alert", "error", message);
        state.msg = message;
    },
    MSG_SUCCESS(state, message) {
        events.$emit("alert", "success", message);
        state.msg = message;
    },
    MSG_INFO(state, message) {
        events.$emit("alert", "info", message);
        state.msg = message;
    },
    MSG_WARNING(state, message) {
        events.$emit("alert", "warning", message);
        state.msg = message;
    },
    ERROR_INFO(state, message) {
        if (process.env.NODE_ENV === "development") {
            state.errorInfo = message;
        }
    },
    RESET_STORE_MESSAGES(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
