import init from "./init";

export default {
    SET_CORNERS(state, corners) {
        state.corners = [...corners];
        state.cornersInit = false;
    },
    ADD_CORNER(state, corner) {
        const index = state.corners.findIndex(v => v.name === corner.name);
        if (index === -1) {
            state.corners = [corner, ...state.corners];
        } else {
            Object.assign(state.corners[index], corner);
        }
    },
    SET_CORNERS_INIT(state, bool) {
        state.cornersInit = bool;
    },
    SET_PROJECTS(state, projects) {
        if (state.projects.length > 0) {
            // On garde les projets complets déjà fetch
            // En cas de refresh sur une page capinc par exemple, on aura fetch
            // que la capinc en cours, pas les autres
            state.projects.forEach(project => {
                const index = projects.findIndex(v => v.id === project.id);
                if (index !== -1) {
                    projects.splice(index, 1);
                }
            });
        }
        state.projects = [...state.projects, ...projects];
        state.projectsInit = false;
    },
    SET_PROJECTS_CIP(state, projects) {
        if (state.projects.length > 0) {
            // On garde les projets complets déjà fetch
            // En cas de refresh sur une page capinc par exemple, on aura fetch
            // que la capinc en cours, pas les autres
            state.projects.forEach(project => {
                const index = projects.findIndex(v => v.id === project.id);
                if (index !== -1) {
                    projects.splice(index, 1);
                }
            });
        }
        state.projects = [...state.projects, ...projects];
        state.projectsCipInit = false;
    },
    ADD_PROJECT(state, project) {
        const index = state.projects.findIndex(v => v.id === project.id);
        if (index === -1) {
            state.projects = [project, ...state.projects];
        } else {
            Object.assign(state.projects[index], project);
        }
    },
    SET_PROJECTS_CIP_LOADING(state, bool) {
        state.projectsCipLoading = bool;
    },
    SET_PROJECTS_LOADING(state, bool) {
        state.projectsLoading = bool;
    },
    SET_PROJECT_INIT(state, bool) {
        state.projectsInit = bool;
    },

    SET_MY_PROJECTS(state, myProjects) {
        state.myProjects = myProjects;
        state.myProjectsInit = false;
    },
    ADD_MY_PROJECT(state, myProject) {
        const index = state.myProjects.findIndex(v => v.id === myProject.id);
        if (index === -1) {
            state.myProjects = [myProject, ...state.myProjects];
        } else {
            Object.assign(state.myProjects[index], myProject);
        }
    },
    UPDATE_MY_PROJECT(state, myProject) {
        const index = state.myProjects.findIndex(_myProject => _myProject.id === myProject.id);
        Object.assign(state.myProjects[index], myProject);
    },
    DELETE_MY_PROJECT(state, myProjectId) {
        const index = state.myProjects.findIndex(myProject => myProject.id === myProjectId);
        state.myProjects.splice(index, 1);
    },
    SET_MY_PROJECTS_LOADING(state, bool) {
        state.myProjectsLoading = bool;
    },
    SET_MY_PROJECT_INIT(state, bool) {
        state.myProjectsInit = bool;
    },
    RESET_STORE_PROJECTS(state) {
        const s = init();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
