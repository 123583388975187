<template>
    <EqsCard
        class="d-flex flex-column fill-height"
        v-bind="{
            loading,
            height: '100%'
        }"
    >
        <v-row class="fill-heigth">
            <v-col :cols="12">
                <img
                    v-if="state == 'success'"
                    class="align-center"
                    :height="$reactive.getElementHeight('header')"
                    src="/icons/checkbox-marked-circle-outline.svg"
                    style="width: inherit"
                />
                <img
                    v-else-if="state == 'error'"
                    class="align-center"
                    :height="$reactive.getElementHeight('header')"
                    src="/icons/close-circle-outline.svg"
                    style="width: inherit"
                />
            </v-col>
            <v-col :cols="12">
                <EqsTextDisplay
                    class="text-center"
                    eqs-type="title"
                    :value="title || stateTexts[state]"
                />
            </v-col>
            <v-col :cols="12" align="center">
                <slot v-if="state != 'error'" name="content" />
                <EqsTextDisplay
                    v-else
                    class="text-center"
                    :value="
                        !!errorText
                            ? errorText
                            : $t('global-final-state.error-text', { universeMail })
                    "
                    eqs-type="subtitle"
                />
            </v-col>
            <v-col v-if="!!redirection" :cols="12">
                <v-row class="justify-center">
                    <EqsBtn
                        class="justify-center"
                        color="primary"
                        :action="
                            () => {
                                redirection.fn();
                            }
                        "
                    >
                        {{ redirection.text }}
                    </EqsBtn>
                </v-row>
            </v-col>
        </v-row>
    </EqsCard>
</template>

<script>
export default {
    name: "GlobalFinalState",
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        state: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: null
        },
        redirection: {
            type: Object,
            default: null
        },
        errorText: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            stateTexts: {
                success: this.$t("globalFinalState.success"),
                warning: this.$t("globalFinalState.warning"),
                error: this.$t("globalFinalState.error")
            }
        };
    },
    computed: {
        universeMail() {
            return this.$store.getters.universe.contact_email;
        }
    }
};
</script>
