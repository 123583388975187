import { getHttpErrMsg } from "@/log";

export default async ({ commit, state }, investorId) => {
    let investorData = state.diInformations[investorId];
    if (investorData) {
        if (investorData.expire_at > Date.now()) {
            return investorData.data;
        }
    }
    try {
        const res = await window.app.$api.request(
            "get",
            `api/investors-dashboard/${investorId}/informations/`
        );
        commit("SET_DI_INFORMATIONS", res.data);
        return res.data;
    } catch (e) {
        console.log("getDiInformations", e);
        window.app.$ui.error(
            getHttpErrMsg(e, "Erreur lors de la récupération des informations de l'investisseur")
        );
    }
};
