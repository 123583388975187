<template>
    <EqsDialog
        :value.sync="dialogProxy"
        closable
        v-bind="{
            title: 'Ajouter un client'
        }"
    >
        <v-container class="bg-surface-variant">
            <p>Choisissez parmis les options présentées ci-dessous :</p>
            <v-row no-gutters>
                <v-col class="mr-4">
                    <div
                        :class="`card-client-selection ${
                            selectClientType === 'moral' && 'cart-client-selection-select'
                        }`"
                        @click="selectClient('moral')"
                    >
                        <div class="d-flex flex-row justify-center">
                            <v-icon class="mr-4">mdi-briefcase</v-icon>
                            <v-sheet class="title-client-type"> One of three columns </v-sheet>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem
                            consectetur adipiscing elit.
                        </p>
                    </div>
                </v-col>
                <v-col>
                    <div
                        :class="`card-client-selection ${
                            selectClientType === 'physic' && 'cart-client-selection-select'
                        }`"
                        @click="selectClient('physic')"
                    >
                        <div class="d-flex flex-row justify-center">
                            <v-icon class="mr-4">mdi-account</v-icon>
                            <v-sheet class="title-client-type"> One of three columns </v-sheet>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet pretium consectetur adipiscing elit. Lorem
                            consectetur adipiscing elit.
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-6">
                <v-btn
                    class="custom-button-modal mr-4"
                    outlined
                    color="primary"
                    @click="closeDialog()"
                    >Annuler</v-btn
                >
                <v-btn class="custom-button-modal" color="primary" @click="selectKycRoute"
                    >Continuer</v-btn
                >
            </v-row>
        </v-container>
    </EqsDialog>
</template>

<script>
export default {
    name: "SelectClientDialog",
    props: {
        open: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            offeringID: "",
            validID: false,
            offeringDirty: false,
            selectClientType: ""
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.open;
            },
            set(val) {
                if (!val) this.closeDialog();
            }
        }
    },
    methods: {
        closeDialog() {
            this.loading = false;
            this.formData = {};
            this.$emit("closeDialog");
            this.$emit("update:open", false);
        },
        selectClient(type) {
            return (this.selectClientType = type);
        },
        selectKycRoute() {
            if (this.selectClientType === "moral") {
                this.$router.push({ name: "create-company" });
            } else {
                this.$router.push("/auth/register");
            }
            this.closeDialog();
        }
    }
};
</script>
<style>
.title-client-type {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #212b36;
    background-color: transparent !important;
}

.card-client-selection {
    border: 1px solid #f5f7fd;
    border-radius: 8px;
    padding: 12px;
}

.card-client-selection:hover {
    background: #f5f7fd;
    cursor: pointer;
}

.cart-client-selection-select {
    background: #f5f7fd;
    box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.18);
}

.card-client-selection p {
    margin-top: 1rem;
    text-align: center;
}

.custom-button-modal {
    width: 45%;
    height: 44px !important;
}
</style>
