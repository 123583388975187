var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"sendMailForgotPasswordForm",on:{"submit":function($event){$event.preventDefault();return _vm.sendMailForgotPassword.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('EqsEmail',_vm._b({staticClass:"mb-5",attrs:{"id":"email","el-class":"email-forgot","label":_vm.$t('forgot-password.label-email'),"value":_vm.email},on:{"update:value":function($event){_vm.email=$event}}},'EqsEmail',{ loading: _vm.loading },false)),_c('v-col',{staticClass:"ma-auto",attrs:{"cols":_vm.$reactive.getVal({
                    mobile: 10,
                    tablet: 8,
                    desktop: 8
                })}},[_c('EqsBtn',_vm._b({attrs:{"id":"btnSendMailForgotPassword","color":"primary","block":"","el-class":"btn-register"},on:{"update:loading":val => (_vm.loading = val)}},'EqsBtn',{
                    action: _vm.sendMailForgotPassword,
                    disabled: !_vm.validForm || _vm.loading
                },false),[_vm._v(" "+_vm._s(_vm.$t("forgot-password.button"))+" ")])],1)],1),_c('div',{staticClass:"my-5"},[_c('a',{staticClass:"text-link",attrs:{"el-class":"btn-login"},on:{"click":function($event){return _vm.$router.push('/auth')}}},[_vm._v(_vm._s(_vm.$t("auth.register.sign-in")))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }