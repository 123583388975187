<template>
    <EqsCard
        v-bind="{
            eqsType: 'container',
            loading,
            elClass: 'bank-account',
            color: 'transparent'
        }"
    >
        <template #main>
            <v-container ma-0>
                <v-row v-if="!!iban || display">
                    <v-col class="pt-0">
                        <EqsLabel :value="$t('user.wallet.bank-account.label')" :editable="false">
                            <div class="d-flex justify-space-between">
                                <EqsTextDisplay
                                    :el-class="`bank-account-${lastNumbers}`"
                                    v-bind="{ loading: localLoading || ibanLoading }"
                                >
                                    <div class="font-weight-bold">
                                        {{ bankAccountTxt }}
                                    </div>
                                </EqsTextDisplay>
                                <EqsTextDisplay
                                    v-if="!display && iban"
                                    class="px-1"
                                    v-bind="{ loading: localLoading || ibanLoading }"
                                >
                                    <a
                                        el-class="info-bank-dialog"
                                        @click="() => (accountDialog = true)"
                                    >
                                        {{ $t("user.wallet.bank-account.see-details") }}
                                    </a>
                                </EqsTextDisplay>
                                <EqsTextDisplay
                                    v-if="
                                        !display &&
                                        !bankDetailsOnly &&
                                        status &&
                                        ['error', 'success'].includes(status.id)
                                    "
                                    class="px-1"
                                    v-bind="{ loading: localLoading || ibanLoading }"
                                >
                                    <a
                                        el-class="replace-bank"
                                        @click="() => $emit('AddBankAccount')"
                                    >
                                        {{ $t("user.wallet.bank-account.replace") }}
                                    </a>
                                </EqsTextDisplay>
                            </div>
                        </EqsLabel>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <EqsChip
                            v-if="status"
                            class="mb-3"
                            :el-class="`iban-status-${status.id}`"
                            :eqs-type="status.color"
                        >
                            {{ status.txt }}
                        </EqsChip>
                    </v-col>
                </v-row>
                <!-- <v-row v-if="!display && !bankDetailsOnly && iban && isSuccess">
                    <v-col class="py-0">
                        <EqsSwitch
                            :value="iban.moneyout_auto"
                            v-bind="{
                                loading: localLoading || ibanLoading,
                                isRequired: false,
                                label: $t('user.wallet.auto-money-out.label'),
                                infoTxt: !iban.moneyout_auto
                                    ? $t('user.wallet.auto-money-out.checkbox')
                                    : $t('user.wallet.auto-money-out.checkbox-false')
                            }"
                            @update:value="val => optionAutoMoneyOut(val)"
                        >
                            <template #label>
                                <EqsTextDisplay>
                                    <div>
                                        {{ $t("user.wallet.auto-money-out.swith-label") }}
                                    </div>
                                    <div class="font-weight-bold">
                                        {{
                                            !iban.moneyout_auto
                                                ? $t("label.disabled")
                                                : $t("label.enabled")
                                        }}
                                    </div>
                                </EqsTextDisplay>
                            </template>
                        </EqsSwitch>
                    </v-col>
                </v-row> -->
                <EqsBtn
                    v-if="!display && !iban"
                    class="ma-3"
                    :loading="ibanLoading"
                    el-class="link-bank"
                    color="warning"
                    block
                    @click="() => $emit('AddBankAccount')"
                >
                    <template #with-icon>
                        <v-icon left> mdi-bank-plus </v-icon>
                        {{ capitalizeFirstLetter($t("user.wallet.add-bank-account")) }}
                    </template>
                </EqsBtn>

                <BankAccountDialog
                    v-if="!display && iban"
                    :value.sync="accountDialog"
                    v-bind="{ iban, title: $t('user.wallet.bank.detail-form-title') }"
                />
            </v-container>
        </template>
    </EqsCard>
</template>

<script>
import BankAccountDialog from "./bank/BankAccountDialog.vue";
import { capitalizeFirstLetter } from "@equisafe-ui-shared/util";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "CurrentBankAccount",
    components: {
        BankAccountDialog
    },
    props: {
        display: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        bankDetailsOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localLoading: false,
            accountDialog: false,
            capitalizeFirstLetter
        };
    },
    computed: {
        ...mapGetters(["ibanLoading", "iban"]),
        lastNumbers() {
            if (this.iban && this.iban.iban) return this.iban.iban.slice(-4);
            return "";
        },
        bankAccountTxt() {
            const res = !this.iban
                ? this.$t("label.pending")
                : `${this.iban.entity.name} (***************${this.lastNumbers})`;
            return res;
        },
        status() {
            return this.iban ? this.iban.status : null;
        },
        isSuccess() {
            return this.status && this.status.id === "success";
        }
    },
    async created() {
        this.getIban();
    },
    methods: {
        ...mapActions(["getIban"]),
        ...mapMutations(["SET_IBAN"]),
        async optionAutoMoneyOut() {
            return;
            // this.localLoading = true;
            // try {
            //     const res = await this.$api.updateIBAN(this.iban.id, {
            //         moneyout_auto: !this.iban.moneyout_auto
            //     });
            //     this.SET_IBAN(res.data);
            //     this.$ui.success(
            //         !!res.data.moneyout_auto
            //             ? this.$t("user.wallet.auto-money-out.activated")
            //             : this.$t("user.wallet.auto-money-out.de-activated")
            //     );
            // } catch (e) {
            //     console.error({ e });
            //     throw e;
            // } finally {
            //     this.localLoading = false;
            // }
        }
    }
};
</script>

<style lang="scss" scoped></style>
