import store from "@/store/store";

export default (e, displayError, throwing = true) => {
    const err = JSON.parse(JSON.stringify(e));
    console.log(err);
    if (
        typeof err === "object" &&
        typeof err.response === "object" &&
        typeof err.response.data === "object" &&
        typeof err.response.data.message === "string"
    ) {
        if (displayError) {
            store.commit("MSG_ERROR", err.response.data.message);
        } else {
            store.commit("ERROR_INFO", err.response.data.message);
        }
        console.warn(err.response.data.message);
        if (throwing) {
            throw err.response.data.message;
        }
    } else if (e && e.message) {
        if (displayError) {
            store.commit("MSG_ERROR", e.message);
        } else {
            store.commit("ERROR_INFO", e.message);
        }
        console.warn(e.message);
        if (throwing) {
            throw e.message;
        }
    } else {
        let error;
        if (typeof e === "string") {
            error = e;
        } else if (e.statusText) {
            error = e.statusText;
        } else {
            error = "Unknown";
        }
        if (displayError) {
            store.commit("MSG_ERROR", error);
        } else {
            store.commit("ERROR_INFO", error);
        }
        console.warn(e);
        if (throwing) {
            throw e;
        }
    }
};
