export class AssetMovementService {
    constructor(api, store) {
        if (!api) {
            throw new Error("Missing `api` option");
        }
        this.api = api;
        if (!store) {
            throw new Error("Missing `store` option");
        }
        this.store = store;
    }

    readAssetMovementList(data) {
        return this.api.readAssetMovementList(data);
    }

    readAssetMovement(id) {
        return this.api.readAssetMovement(id);
    }

    createCession(data) {
        return this.api.createCession(data).data;
    }

    deleteAssetMovement(id) {
        return this.api.deleteAssetMovement(id);
    }

    blockchainInscriptionOfInvestor(data) {
        return this.api.blockchainInscriptionOfInvestor(data);
    }
}
