<template>
    <div :class="`eqs-card ${elClass}`" :style="{ ...getStyle, ...additionalStyle }">
        <EqsSkeleton
            v-bind="{
                loading,
                type: 'image',
                ...$attrs,
                ...skeletonProps,
                width,
                height
            }"
        >
            <v-card
                v-bind="{ ...$attrs, height, width, ...getData }"
                :class="`${getData.class} eqs-card-container`"
                v-on="$listeners"
            >
                <v-card-title
                    v-if="$slots.title || title"
                    :class="`eqs-card__title ${elClass}-title`"
                >
                    <v-row class="d-flex flex-nowrap align-stretch" no-gutters>
                        <v-col cols="2" />
                        <v-col
                            cols="8"
                            :class="`pa-0 d-flex justify-center align-center text-center ${getData.textTitleClass}`"
                        >
                            <div v-if="$slots.title">
                                <slot name="title" />
                            </div>
                            <div v-else>
                                {{ title }}
                            </div>
                        </v-col>
                        <v-col cols="2" class="pa-0 d-flex justify-end" align-self="center">
                            <EqsBtn
                                v-if="closable && !$slots['header-action']"
                                text
                                :el-class="`${elClass}-close`"
                                v-bind="{ infoTxt: closeInfoTxt }"
                                @click="() => $emit('close')"
                            >
                                <v-icon> mdi-close </v-icon>
                            </EqsBtn>
                            <slot v-else name="header-action" />
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-subtitle v-if="$slots.subtitle || subtitle" class="mt-0">
                    <v-row class="d-flex flex-nowrap align-stretch" no-gutters>
                        <v-col cols="2" />
                        <v-col
                            cols="8"
                            :class="`pa-0 d-flex justify-center align-center ${getData.textSubtitleClass}`"
                        >
                            <div v-if="$slots.subtitle">
                                <slot name="subtitle" />
                            </div>
                            <div v-else>
                                {{ subtitle }}
                            </div>
                        </v-col>
                        <v-col cols="2" class="pa-0 d-flex justify-end" align-self="center">
                            <EqsBtn
                                v-if="closable && !$slots['header-action'] && !title"
                                text
                                :el-class="`${elClass}-close`"
                                v-bind="{ infoTxt: closeInfoTxt }"
                                @click="() => $emit('close')"
                            >
                                <v-icon> mdi-close </v-icon>
                            </EqsBtn>
                            <slot v-else-if="!title" name="header-action" />
                        </v-col>
                    </v-row>
                </v-card-subtitle>

                <v-slide-x-transition>
                    <v-progress-linear v-if="progress" :value="progress" :color="progressColor" />
                </v-slide-x-transition>

                <slot name="main" />
                <v-card-text
                    v-if="$slots.text || txt || !!$slots.default"
                    class="fill-width eqs-card-container__text"
                >
                    <div v-if="$slots.text">
                        <slot name="text" />
                    </div>
                    <div v-else>
                        {{ txt }}
                    </div>
                    <slot name="default" />
                </v-card-text>
                <v-card-actions v-if="$slots.actions">
                    <slot name="actions" />
                </v-card-actions>
            </v-card>
        </EqsSkeleton>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsCard.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsCard",
    components: {
        EqsSkeleton: () => import("../../atoms/EqsSkeleton/EqsSkeleton.vue"),
        EqsBtn: () => import("../../atoms/EqsBtn/EqsBtn.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        subtitle: {
            type: String,
            default: ""
        },
        txt: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: "100%"
        },
        height: {
            type: String,
            default: "auto"
        },
        closable: {
            type: Boolean,
            default: false
        },
        closeInfoTxt: {
            type: String,
            default: () => window.app.$t("molecule.card.close-txt")
        },
        validForm: {
            type: Boolean,
            default: true
        },
        success: {
            type: Boolean,
            default: true
        },
        progress: {
            type: Number,
            default: 0
        },
        color: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        skeletonProps() {
            return this.getData.skeleton || {};
        },
        additionalStyle() {
            let otherStyle = {};
            if (this.color) {
                otherStyle["background-color"] = this.color;
                otherStyle["--card-background-color"] = this.color;
            }
            return {
                ...otherStyle,
                "--card-height": this.height,
                "--card-width": this.width
            };
        },
        progressColor() {
            if (!this.validForm) return "error";
            return this.progress === 100 && this.success ? "success" : "primary";
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsCard.scss";
</style>
