import Buy from "@/components/views/user/buy/_Buy.vue";

import { i18n } from "@/services/plugins/i18n.js";
import multiguard from "vue-router-multiguard";
import {
    requiresInvestorRight,
    requiresMyEntityRegistered,
    informUserIfNotRegistered,
    capincIsAvailable
} from "../routeGuards";

export default [
    {
        name: "buy",
        path: "/buy/:orderId?",
        component: Buy,
        props: true,
        meta: {
            context: i18n.t("user.buy.title")
        },
        beforeEnter: multiguard([
            requiresMyEntityRegistered,
            requiresInvestorRight,
            informUserIfNotRegistered,
            capincIsAvailable
        ])
    }
];
