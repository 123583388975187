var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDataTable',_vm._b({attrs:{"selected":_vm.valueProxy,"single-select":false,"items":_vm.investors,"item-key":"entity.id","selectable":"","searchable":{
        placeholder: _vm.$t('shareledger.white-list.data-table.search-for-investors')
    },"footer-props":{
        itemsPerPageOptions: [30, 60]
    },"items-per-page":30},on:{"update:selected":function($event){_vm.valueProxy=$event}},scopedSlots:_vm._u([{key:"item.entity.name",fn:function({ item }){return [_c('div',{staticClass:"cell d-flex flex-row align-center"},[_c('v-row',{staticClass:"cell d-flex flex-row align-center"},[_c('v-col',{attrs:{"cols":"3"}},[_c('EqsAvatar',{attrs:{"user":item.entity ? item.entity : item,"no-badge":true}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('EqsTextDisplay',{staticClass:"text-left",staticStyle:{"white-space":"nowrap"},attrs:{"value":item.entity ? item.entity.name : item.name,"eqs-type":"subtitle"}}),_c('EqsTextDisplay',{staticClass:"text-left",attrs:{"value":item.type_display,"eqs-type":"caption"}})],1)],1)],1)]}}])},'EqsDataTable',{
        loading: _vm.loading || _vm.investorsLoading,
        headers: _vm.investorsTableHeaders,
        investors: _vm.investors,
        ..._vm.$attrs
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }