import DEBUG from "@/store/debug.js";

export default async ({ state, dispatch }, investorId) => {
    DEBUG("GET_INVESTOR_START");
    if (!investorId) throw new Error("no investorId");
    let investor = state.investors.find(_investor => _investor.id === investorId);
    if (!investor) {
        try {
            await dispatch("getInvestors");
            investor = state.investors.find(_investor => _investor.id === investorId);
        } catch (e) {
            throw e;
        }
    }
    if (investor) DEBUG("GET_INVESTOR_SUCCESS");
    else {
        DEBUG("GET_INVESTOR_ERROR");
        throw new Error("investor not found");
    }
    return investor || {};
};
