var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"fill-height fill-width",attrs:{"flat":""}},'EqsCard',{
        color: 'transparent',
        eqsType: 'no-padding',
        loading: !_vm.signer.member_sign_page_iframe,
        height: '100%',
        elClass: 'yousign-iframe-container'
    },false),[_c('v-input',_vm._b({staticClass:"fill-width pl-3 custom-info",attrs:{"value":_vm.formData.signature_done}},'v-input',_vm.getInputState,false)),_c('v-container',{staticClass:"mt-8 text-center"},[(_vm.formData.signature_done)?_c('span',[_c('v-img',{staticStyle:{"margin":"auto"},attrs:{"contain":"","max-width":"200","keep-ratio":"","src":"/images/sign_done.svg"}}),_c('h3',{staticClass:"text-h5 mb-4"},[_vm._v(_vm._s(_vm.$t("yousign-iframe.btn.title-done")))]),_c('p',{staticClass:"text-body"},[_vm._v(" "+_vm._s(_vm.$t("yousign-iframe.btn.text-done"))+" ")]),_c('v-btn',{staticStyle:{"text-transform":"none !important"},attrs:{"color":"grey","x-large":""},on:{"click":function($event){return _vm.openSignaturePage()}}},[_vm._v(" "+_vm._s(_vm.$t("yousign-iframe.btn.show-yousign"))+" ")])],1):_c('span',[_c('v-img',{staticStyle:{"margin":"auto"},attrs:{"contain":"","max-width":"200","keep-ratio":"","src":"/images/sign_waiting.svg"}}),_c('h3',{staticClass:"text-h5 mb-4"},[_vm._v(_vm._s(_vm.$t("yousign-iframe.btn.title-wait")))]),_c('p',{staticClass:"text-body"},[_vm._v(" "+_vm._s(_vm.$t("yousign-iframe.btn.text-wait"))+" ")]),_c('v-btn',{staticStyle:{"text-transform":"none !important"},attrs:{"color":"primary","x-large":""},on:{"click":function($event){return _vm.openSignaturePage()}}},[_vm._v(" "+_vm._s(_vm.$t("yousign-iframe.btn.go-yousign"))+" ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }