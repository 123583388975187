import {
    shareholderTable,
    ledgerInfo,
    headerWithContentStyle,
    headerWithHeaderStyle
} from "../assetMovement/tableConfig";
import { companyInformation } from "../assetMovement/companyInformation";
import { contentShareholderValue, ledgerInfoContent } from "../assetMovement/createTable";
import { Xls } from "../Xls";
import { Style } from "../Style";

function fillCurrentLine(assetMovement, position, balance, shareholderId) {
    const tmp = position._col;
    headerWithContentStyle(shareholderTable).forEach((header, col) => {
        const cell = {
            ...header,
            value: contentShareholderValue(header.key, assetMovement, balance, shareholderId)
        };
        position.apply(cell, col, 0);
    });
    position._col = tmp;
    position.row(1);
}

function createShareholderTableContent(ws, oneLedger, currentPos, shId) {
    currentPos.row(1);
    const balance = { solde: 0 };
    oneLedger.asset_movements.forEach(asset_movements =>
        fillCurrentLine(asset_movements, currentPos, balance, shId)
    );
}

function createShareholderTableHeader(ws, xls, tableToParse) {
    const parseTable = table => {
        for (let i = 0, cell = table[i]; i < table.length; cell = table[++i]) {
            if (cell.children) {
                xls.apply(cell, 0, -1)
                    .area([0, -1], [cell.children.length - 1, -1])
                    .merge();
                for (const children of cell.children) {
                    xls.apply(children).col(1);
                }
            } else xls.apply(cell).col(1);
        }
    };
    parseTable(headerWithHeaderStyle(tableToParse));
    ws.pageSetup.printTitlesRow = "1:8";
}

function createContentLedgerInfo(ws, position, ledger) {
    position.row(1);
    const tmp = position._col;
    headerWithContentStyle(ledgerInfo).forEach((header, col) => {
        const cell = { ...header, value: ledgerInfoContent(header.key, ledger) };
        position.apply(cell, col, 0);
    });
    position._col = tmp;
    position.row(1);
}

function createHeaderLedgerInfo(ws, position, ledgerBalance, tmp) {
    createShareholderTableHeader(ws, position, ledgerInfo);
    position._col = tmp[1];
    createContentLedgerInfo(ws, position, ledgerBalance);
}

/*
 ** Sending current holder id from here so I have it in accountabilityShare
 */
function fillSheet(startingPoint, worksheet, sheetId, balance) {
    const currentPos = new Xls(worksheet, startingPoint);
    const shareholderId = balance.by_shareholder[sheetId - 1].id;
    worksheet.views = [
        {
            state: "frozen",
            xSplit: 0,
            ySplit: currentPos._row + 5,
            activeCell: currentPos
        }
    ];
    balance.by_shareholder[sheetId - 1].shareledgers
        .filter(elem => elem.balance > 0)
        .forEach(elem => {
            currentPos.row(7);
            const tmp = [currentPos._row, currentPos._col];
            createHeaderLedgerInfo(worksheet, currentPos, elem, tmp);
            currentPos.row(1);
            createShareholderTableHeader(worksheet, currentPos, shareholderTable);
            currentPos._col = tmp[1];
            currentPos.row(1);
            createShareholderTableContent(worksheet, elem, currentPos, shareholderId);
        });
}

/*
 ** index before the name is here in case there is homonyms in the shareholder list
 ** Excel wont let us have two worksheet of the same name otherwise
 */
export function createShareholderWorksheet(workbook, balance, dataCompany) {
    let index = 1;
    balance.by_shareholder = balance.by_shareholder.filter(elem => {
        return elem.asset_movements && elem.asset_movements.length > 0;
    });
    balance.by_shareholder.forEach(elem =>
        workbook.addWorksheet(index++ + ". " + elem.name, Style.worksheet)
    );
    workbook.eachSheet((worksheet, sheetId) => {
        new Xls(worksheet, "A1")
            .componentLogo(workbook)
            .componentTitle(balance.by_shareholder[sheetId - 1].name + "'s ledgers");
        companyInformation(worksheet, dataCompany);
        fillSheet("A1", worksheet, sheetId, balance);
    });
}
