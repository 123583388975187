var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-clickable-card",style:({ ..._vm.getStyle, ..._vm.additionalStyle })},[_c('EqsSkeleton',_vm._b({},'EqsSkeleton',{
            loading: _vm.loading,
            type: 'image',
            width: _vm.width,
            height: _vm.height
        },false),[_c('v-hover',{attrs:{"open-delay":"100","close-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"ma-0 pa-0"},[_c('EqsBadge',{attrs:{"value":_vm.infoTxt,"color":"transparent","offset-x":"1rem","offset-y":"0.35rem"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-tooltip',{attrs:{"open-on-hover":"","open-on-focus":"","disabled":!_vm.infoTxt,"top":"","max-width":"18rem"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"size":"1.6rem","color":"primary"}},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.infoTxt)+" ")])]},proxy:true}],null,true)},[_c('v-card',_vm._b({class:{
                            'on-hover': !_vm.getData.notClickable && hover && !_vm.isDisabled,
                            'on-selected': !_vm.getData.notClickable && _vm.value && !_vm.isDisabled
                        },attrs:{"el-class":`${_vm.elClass}-card`,"elevation":!hover || _vm.isDisabled ? _vm.cardElevation : 6},on:_vm._d({},[_vm.clickEvent,_vm.executeAction])},'v-card',{
                            ..._vm.$attrs,
                            width: _vm.width,
                            height: _vm.height,
                            loading: _vm.localLoading,
                            disabled: _vm.isDisabled
                        },false),[(_vm.$slots.title || _vm.title)?_c('v-card-title',{staticClass:"eqs-clickable-card__title",attrs:{"el-class":`${_vm.elClass}-card-title`}},[(_vm.$slots.title)?_c('div',[_vm._t("title")],2):_c('div',[_c('EqsTextDisplay',{attrs:{"eqs-type":"title","value":_vm.title}})],1)]):_vm._e(),(_vm.$slots.subtitle || _vm.subtitle)?_c('v-card-subtitle',[(_vm.$slots.subtitle)?_c('div',[_vm._t("subtitle")],2):_c('div',[_c('EqsTextDisplay',{attrs:{"eqs-type":_vm.subtitle_type ? _vm.subtitle_type : 'subtitle',"value":_vm.subtitle}})],1)]):_vm._e(),_c('v-card-text',{staticClass:"fill-width"},[(_vm.$slots.text)?_c('div',{staticClass:"d-flex fill-height"},[_vm._t("text")],2):_c('div',[_c('EqsTextDisplay',{attrs:{"paragraph":"","value":_vm.text}})],1)])],1)],1)],1)]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }