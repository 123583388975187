import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, data) => {
    DEBUG("ADD_MY_PROJECT_START");
    try {
        commit("SET_MY_PROJECTS_LOADING", true);
        const projectRes = await window.app.$api.createCapitalIncrease(data);
        DEBUG("ADD_MY_PROJECT_SUCCESS");
        commit("ADD_MY_PROJECT", projectRes.data);
        return projectRes.data;
    } catch (e) {
        DEBUG("ADD_MY_PROJECT_ERROR");
        throw e;
    } finally {
        commit("SET_MY_PROJECTS_LOADING", false);
    }
};
