<template>
    <div>
        <transition name="eqs-slide-from-right">
            <router-view v-bind="{ loading }" @close="closeDialog" />
        </transition>
    </div>
</template>

<script>
export default {
    name: "CapitalIncrease",
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        closeDialog() {
            /**this.$router.push({
        name: "fundraising",
        params: { companyId: this.$entity.represented().id, },
      });*/
            this.$router.push({
                name: "operations",
                query: {
                    tab: "fundraising"
                }
            });
        }
    }
};
</script>
