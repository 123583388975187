<template>
    <div class="d-flex flex-column">
        <EqsLoader
            v-if="localLoading"
            :class="`my-${$reactive.getVal({ mobile: 2, tablet: 3, desktop: 5 })}`"
            el-class="payment-loader"
            v-bind="{
                size: '100%'
            }"
        >
            <template #loader-message>
                <EqsTextDisplay
                    v-bind="{
                        eqsType: 'subtitle',
                        value: $t('user.payment.processing-order')
                    }"
                />
            </template>
        </EqsLoader>
        <v-row class="px-3" justify="center">
            <v-col :cols="$reactive.getCols('md')" class="eqs-form-field">
                <EqsNumberShares
                    v-if="order.numberofshares"
                    el-class="purchased-shares"
                    style="min-width: 40%"
                    :value="order.numberofshares"
                    :editable="false"
                    :is-required="false"
                    :label="$t('user.payment.number-of-shares-purchasing')"
                />
            </v-col>
        </v-row>
        <v-row v-if="order.payment_mode !== 'transfer'" class="px-3" justify="center">
            <v-col
                :cols="$reactive.getCols('md')"
                class="eqs-form-field d-flex flex-column align-center"
            >
                <EqsTextDisplay eqs-type="subtitle">
                    {{
                        order.status === "success"
                            ? $t("user.payment.total-payment-payed")
                            : $t("user.payment.total-payment-to-be-payed")
                    }}
                </EqsTextDisplay>

                <EqsChip
                    color="primary"
                    class="pb-4 pt-2 font-weight-bold"
                    text-color="white"
                    el-class="total-amount"
                >
                    {{ $currency.display(order.total_price) }}
                </EqsChip>
            </v-col>
        </v-row>
        <v-row
            v-if="order.payment_mode === 'transfer' && order.status !== 'success'"
            class="px-3"
            justify="center"
        >
            <v-col
                :cols="$reactive.getCols('md')"
                class="eqs-form-field d-flex flex-column align-center"
            >
                <EqsTextDisplay eqs-type="subtitle">
                    {{ $t("user.payment.total-payment-to-be-transfer") }}
                </EqsTextDisplay>

                <EqsChip
                    color="primary"
                    class="pb-4 pt-2 font-weight-bold"
                    text-color="white"
                    el-class="total-amount"
                >
                    {{ $currency.display(order.total_price) }}
                </EqsChip>
            </v-col>
            <v-col
                :cols="$reactive.getCols('xl')"
                class="eqs-form-field d-flex flex-column align-center"
            >
                <EqsAlert
                    v-bind="{
                        eqsType: 'warning',
                        text: $t('payment.wire-instructions', { universe }),
                        loading
                    }"
                />
            </v-col>
            <v-col
                :cols="$reactive.getCols('xl')"
                class="eqs-form-field d-flex flex-column align-center"
            >
                <BankwirePayment
                    class="my-5"
                    v-bind="{ loading, order, iban: getIban, formData }"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import BankwirePayment from "./BankwirePayment";

export default {
    name: "Payment",
    components: {
        BankwirePayment
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        order: {
            type: Object,
            default: () => {}
        },
        options: {
            type: Array,
            default: () => []
        },
        signAndPurchase: {
            type: Function,
            default: null
        },
        pollOrder: {
            type: Function,
            default: null
        },
        formData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            localLoading: false
        };
    },
    computed: {
        universe() {
            return this.$store.getters.universe.public_name;
        },
        getIban() {
            const iban = this.options.find(item => item.value === "transfer");
            return iban && iban.infos ? iban.infos : [];
        }
    },
    async created() {
        if (this.order.payment_mode !== "transfer" && this.order.status === "pending") {
            this.localLoading = true;
            if (this.order.payment_url) window.location = this.order.payment_url;
            else if (this.signAndPurchase || this.pollOrder) {
                try {
                    (await !!this.signAndPurchase) ? this.signAndPurchase() : this.pollOrder();
                } catch (e) {
                    console.error({ e });
                } finally {
                    this.localLoading = false;
                }
            }
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped></style>
