<template>
    <v-tooltip top :disabled="!kycReason">
        <template #activator="{ on, attrs }">
            <v-chip v-if="kycStatus" outlined v-bind="attrs" v-on="on">
                <v-icon class="mr-2" :color="kycStatus.color">
                    {{ kycStatus.icon }}
                </v-icon>
                <span>{{ kycStatus.text }}</span>
            </v-chip>
        </template>
        <span>{{ entity.lemonway_reason }}</span>
    </v-tooltip>
</template>

<script>
import { validationStatusKyc } from "@/components/views/user/clients/tools.js";

export default {
    name: "KycStatus",
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        entity: {
            type: Object,
            required: true
        }
    },

    computed: {
        kycStatus() {
            return validationStatusKyc(
                this.entity.lemonway_state ? this.entity.lemonway_state : "not_opened"
            );
        },
        kycReason() {
            return !!this.entity.lemonway_reason ?? false;
        }
    }
};
</script>

<style lang="scss" scoped></style>
