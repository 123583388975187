<template>
    <div>
        <div class="title-add-member">
            <p>{{ $t("crud.add") }}</p>
        </div>
        <div class="add-team">
            <v-form id="form-add-member" ref="addTeamMember" v-model="validForm">
                <div class="input-member">
                    <component
                        :is="form.is"
                        editable
                        v-bind="{
                            formData,
                            loading,
                            schemaDouble,
                            uniqueEmailR
                        }"
                    />
                    <div @mouseleave="$emit('update:showHelp', '')">
                        <RoleSelect
                            :disabled="false"
                            :hovered="showHelp"
                            :value.sync="formData.role_name"
                            @update:showHelp="$emit('update:showHelp', $event)"
                            @select:role_name="selectRoleName"
                            @update:hovered="$emit('update:showHelp', $event)"
                        />
                    </div>
                    <div class="input-is-signatory">
                        <EqsCheckbox
                            :is-required="false"
                            :label="$t('crud.is-signatory.label')"
                            :text="$t('crud.is-signatory.text')"
                            :value.sync="formData.is_signatory"
                            v-bind="{ loading }"
                        />
                    </div>
                    <div class="width-fit">
                        <EqsBtn
                            id="btnRoleCreate"
                            color="primary"
                            v-bind="{
                                action: emitCreateRole,
                                disabled: !validForm,
                                loading,
                                elClass: 'manage-create-btn'
                            }"
                        >
                            {{ $t("crud.send") }}
                        </EqsBtn>
                    </div>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import RoleSelect from "./RoleSelect";
import { InviteEntity } from "@/services/entity";
import IndividualInviteForm from "@/components/views/user/forms/IndividualInviteForm.vue";
import CustodianInviteForm from "@/components/views/user/forms/CustodianInviteForm.vue";

import { formErrorMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    components: { RoleSelect, IndividualInviteForm, CustodianInviteForm },
    mixins: [formErrorMixin],
    props: {
        showHelp: {
            type: String,
            required: true
        },
        users: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            formData: {
                role_name: "admin"
            },
            loading: false,
            validForm: false,
            form: {},
            schemaDouble: {}
        };
    },
    computed: {
        userEmails() {
            return this.users.filter(user => user.email).map(user => user.email);
        },
        userCompanyIdCode() {
            return this.users.filter(user => user.company_idcode).map(user => user.company_idcode);
        }
    },
    watch: {
        "formData.role_name": {
            immediate: true,
            handler(val) {
                const type = val !== "custodian" ? "individual" : val;
                this.form = InviteEntity(this)[type];
                this.schemaDouble = this.form.schemaDouble;
            }
        }
    },
    methods: {
        uniqueEmailR(val) {
            if (val && this.userEmails.includes(val)) return this.$t("role.error-unique-email");
            return true;
        },
        selectRoleName(role_name) {
            this.formData.role_name = role_name;
            this.$emit("select:role_name", role_name);
            // this.formData.is_company = this.formData.role_name === "custodian" ? true : false;
        },
        async emitCreateRole() {
            try {
                this.loading = true;
                await this.$api.entityInviteRole(this.formData);
                await this.$role.updateMyCurrentRole(this.$entity.represented().id);
                this.$emit("createdRole", this.formData);
            } catch (e) {
                this.handleError(e, "manage_team_add_member");
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss">
.title-add-member {
    font-size: 12px;
    font-weight: 900;
    border-bottom: 0.5px solid var(--v-border-base);
    height: 63px;
    > p {
        padding-top: 20px;
    }
}

.role-attribute {
    min-width: 180px;
    max-width: 70%;
}

.input-member {
    margin-top: 20px;
}

.input-is-signatory {
    margin-top: 10px;
    margin-bottom: 20px;
}

.cancel-button-invite {
    font-size: 11px;
    border: 1px solid var(--v-border-base);
    border-radius: 6px;
    color: var(--v-red-base);
    text-align: center;
    background: var(--v-border-base);
    transform: scale(1.03);
    width: 130px;
    margin-top: 18px;
    height: 53%;
}

.btn-send {
    margin-top: 14.5px;
    transform: scale(0.8);
}
.btn-wrapper {
    display: grid;
    grid-template-columns: 140px 170px;
}

.button-wrapper {
    display: grid;
    grid-template-columns: 130px 150px;
}
</style>
