import Vue from "vue";

var state = Vue.observable({
    actionBar: false,
    loading: false
});

export class AppBarService {
    constructor() {
        state.actionBar = false;
        this.state = state;
    }
    activateActionBar(val = true) {
        state.actionBar = val;
    }
    loading(val) {
        state.loading = val;
    }
}
