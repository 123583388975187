import { getVal } from "../services/reactive.js";

export const spacingCSS = currentSize => {
    return {
        "main-container": {
            padding: getVal(currentSize, {
                xs: "0.75rem 0.75rem 0 0.75rem",
                sm: "1.25rem 1.25rem 0 1.25rem",
                md: "1.5rem 1.5rem 0 1.5rem",
                lg: "1.75rem 1.75rem 0 1.75rem",
                xl: "2rem 2rem 0 2rem"
            })
        }
    };
};
