import * as individual_docs from "./individual_docs";

const verifySteps = ctx => [
    {
        img: ctx.$universe.image("/images/registration_carousel/individual-registration-time.svg"),
        txt: ctx.$t("registration.kyc.kyc-provider.carousel.validation-duration")
    },
    {
        img: ctx.$universe.image(
            "/images/registration_carousel/individual-registration-digitalize.svg"
        ),
        txt: ctx.$t("registration.kyc.kyc-provider.carousel.digitalize")
    },
    {
        img: ctx.$universe.image(
            "/images/registration_carousel/individual-registration-capinc.svg"
        ),
        txt: ctx.$t("registration.kyc.kyc-provider.carousel.capinc")
    },
    {
        img: ctx.$universe.image(
            "/images/registration_carousel/individual-registration-follow.svg"
        ),
        txt: ctx.$t("registration.kyc.kyc-provider.carousel.follow")
    }
];

export function defaultRouteBtn(ctx) {
    return {
        txt: ctx.$t("registration.kyc.kyc-provider.redirect-default-page-btn"),
        action: async () => {
            await ctx.$router.push(ctx.$universe.getDefaultRoute(ctx.$entity.represented()));
        }
    };
}

export function registrationBtn(ctx) {
    return {
        txt: ctx.$t("registration.kyc.kyc-provider.registration-btn"),
        action: async () => {
            await ctx.$router.push(`/registration/${ctx.$entity.represented().id}`);
        }
    };
}

export const publicInfoSchemaDouble = {
    avatar: {
        isRequired: false,
        dataType: "file"
    },
    gender: {
        isRequired: true
    },
    first_name: {
        isRequired: true
    },
    last_name: {
        isRequired: true
    },
    nationality: {
        isRequired: true
    },
    birth_date: {
        isRequired: true
    },
    birthplace: {
        isRequired: true
    },
    birth_country: {
        isRequired: true
    },
    phone: {
        isRequired: true
    }
};

// Form fiscal
export const privateInfoSchemaDouble = ctx => {
    let schemaDouble = {
        street: {
            isRequired: true
        },
        zipcode: {
            isRequired: true
        },
        city: {
            isRequired: true
        },
        country: {
            isRequired: true
        },
        // fiscal_residency: {
        //     isRequired: true
        // },
        marital_situation: {
            isRequired: true
        }
        // incapability: {
        //   dataType: "bool",
        //   isRequired: false,
        // },
    };

    if (
        !!ctx.formData &&
        (ctx.formData.marital_situation == "maried" ||
            ctx.formData.marital_situation == "pacsed-legal")
    ) {
        schemaDouble.marital_regime = {
            isRequired: false
        };

        if (ctx.formData.marital_regime == "other")
            schemaDouble.marital_regime_other = {
                isRequired: true
            };
    }

    return schemaDouble;
};

export const aboutYouSchemaDouble = ctx => {
    let aboutYou = {};
    if (ctx.$universe.name() === "myclubdeal") {
        if (ctx.$universe.scripts().aboutYouSchemaDouble)
            aboutYou = ctx.$universe.scripts().aboutYouSchemaDouble();
    }
    if (ctx.$universe.isPhebe()) {
        if (ctx.$universe.scripts().aboutYouSchemaDouble)
            aboutYou = ctx.$universe.scripts().aboutYouSchemaDouble();
    }
    return {
        biography: {
            isRequired: false
        },
        ...aboutYou
    };
};

function filteredRequiredDocs(entity, docsToFilter) {
    // hacky solution to resolve issue when got both two id and passport for KYC
    let docsToIgnore = [];
    const doc_idcard = !!entity["doc_idcard"];
    const doc_passport = !!entity["doc_passport"];
    if (doc_idcard) {
        docsToIgnore = ["doc_idcard"];
    } else if (doc_passport) {
        docsToIgnore = ["doc_passport"];
    }
    return docsToFilter.filter(doc => !docsToIgnore.includes(doc.key));
}

export const filteredCategoriesDocs2 = (ctx, entity) => {
    return filteredRequiredDocs(entity, individual_docs["docs_kyc"](ctx));
};

// export const IncapabilityDocsSchemaDouble = ctx => {
//   return {
//     doc_incapability_decision: {
//       dataType: "file",
//       isRequired: true,
//     },

//     doc_representative_id: {
//       dataType: "file",
//       isRequried: true,
//     },
//   };
// };

export const Docs1SchemaDouble = ctx => {
    return {
        docs_1: {
            dataType: "multi-file",
            isRequired: true,
            categories: individual_docs["docs_kyc_mandatory"](ctx),
            numSelect: 1
        }
    };
};

export const Docs2SchemaDouble = ctx => {
    return {
        docs_2: {
            dataType: "multi-file",
            isRequired: true,
            categories: filteredCategoriesDocs2(ctx, ctx.entity),
            numSelect: 1
        },
        doc_address: {
            dataType: "file",
            isRequired: false
        }
    };
};

export const CgpSchemaDouble = (ctx, isCgp) => {
    let schema = {};
    schema.cgp_n_orias = {
        dataType: "string",
        isRequired: true
    };
    schema.cgp_association = {
        dataType: "string",
        isRequired: true
    };
    schema.cgp_doc_orias = {
        dataType: "file",
        others: {
            key: "cgp_doc_orias"
        },
        isRequired: true
    };
    schema.cgp_doc_rcpro = {
        dataType: "file",
        others: {
            key: "cgp_doc_rcpro"
        },
        isRequired: true
    };
    schema.doc_fiche_connaissance_partenaire_kyoseil = {
        dataType: "file",
        others: {
            key: "doc_fiche_connaissance_partenaire_kyoseil"
        },
        isRequired: true
    };
    schema.doc_questionnaire_qualite_kyoseil = {
        dataType: "file",
        others: {
            key: "doc_questionnaire_qualite_kyoseil"
        },
        isRequired: true
    };
    schema.doc_procedure_lcbft_kyoseil = {
        dataType: "file",
        others: {
            key: "doc_procedure_lcbft_kyoseil"
        },
        isRequired: true
    };
    return schema;
};

export const ProofOfAddressSchemaDouble = ctx => {
    return {
        proof_of_address_doc: {
            dataType: "file",
            others: {
                key: "proof_of_address_doc"
            },
            isRequired: true
        }
    };
};

export const CguSchemaDouble = ctx => {
    return {
        cgu: {
            dataType: "bool",
            isRequired: true
        },
        partner_code: {
            isRequired: false
        }
    };
};

export const registrationVerify = ctx => {
    return {
        steps: verifySteps(ctx),
        pending: {
            msg: ctx.$t("registration.kyc.kyc-provider.pending", {
                name: ctx.$entity.represented().first_name,
                universeMail: ctx.universeMail
            }),
            video: ctx.$universe.scripts().registrationPendingVideo
                ? ctx.$universe.scripts().registrationPendingVideo()
                : ""
        },
        error: {
            msg: ctx.$t("registration.kyc.kyc-provider.error"),
            continue: registrationBtn(ctx)
        },
        success: {
            msg: ctx.$t("registration.kyc.kyc-provider.success", {
                name: ctx.$entity.represented().first_name
            }),
            continue: defaultRouteBtn(ctx)
        }
    };
};

export const generalInfos = ctx => {
    return {
        title: ctx.$t("registration.general-info"),
        item: {
            is: "IndividualInfoForm"
        },
        noBack: true,
        next: {
            fn: async () => {
                const getCompany = ctx.roles.find(role => role.entity_represented.is_company);
                if (getCompany && getCompany.entity_represented.lemonway_state !== "success") {
                    await ctx.updateUser(ctx.createLWWallet);
                    ctx.kybDirect(getCompany.entity_represented);
                } else {
                    await ctx.updateUser(ctx.createLWWallet);
                }
            }
        },
        schemaDouble: {
            ...publicInfoSchemaDouble,
            ...privateInfoSchemaDouble(ctx)
        }
    };
};

export const aboutYou = ctx => {
    let res = {
        title: ctx.$t("registration.about-you"),
        item: {
            is: "AboutYou"
        },
        next: {
            fn: ctx.updateUser
        },
        bypass: !ctx.$universe.scripts().aboutYouSchemaDouble,
        schemaDouble: aboutYouSchemaDouble(ctx)
    };
    return res;
};

export const registrationSteps = ctx => {
    let kycDocs = [];

    if (!ctx.$universe.isExternKyc()) {
        kycDocs = [
            {
                title: ctx.$t("registration.first-identity-document"),
                item: {
                    is: "IndividualDocsForm1",
                    docs: true
                },
                next: {
                    fn: ctx.updateUser
                },
                schemaDouble: Docs1SchemaDouble(ctx)
            },
            {
                title: ctx.$t("registration.second-identity-document"),
                item: {
                    is: "IndividualDocsForm2"
                },
                next: {
                    fn: ctx.updateUser
                },
                schemaDouble: Docs2SchemaDouble(ctx)
            }
        ];
    }
    if (
        ctx.$universe.isKyoseil() &&
        !ctx.$entity.isAffiliationAdmin() &&
        !ctx.$entity.isAffiliationBc()
    ) {
        const isKyoseilInvestDirect =
            !ctx.$entity.isAffiliationInvestor() && !ctx.$entity.hasAffiliationRole();
        kycDocs.push({
            title: ctx.$t("documents.category.proof_of_address"),
            item: {
                is: "ProofOfAddressForm"
            },
            next: {
                fn: isKyoseilInvestDirect ? ctx.updateUserBeforeDirectInvestKyoseil : ctx.updateUser
            },
            schemaDouble: ProofOfAddressSchemaDouble(ctx)
        });

        if (isKyoseilInvestDirect) {
            kycDocs.push({
                title: ctx.$t("user.profile.cgp-sign.subtitle"),
                item: {
                    is: "GlobalYousignIFrame"
                },
                noPadding: true,
                next: {
                    fn: () =>
                        ctx.signatureDone ? ctx.getUpdatedUser() : ctx.fetchSignatureState(),
                    txt: ctx.$t("user.wallet.money-in.confirm-payment")
                },
                schemaDouble: {
                    signature_done: {
                        dataType: "bool",
                        isRequired: true
                    }
                }
            });
        }
    }
    return [
        generalInfos(ctx),
        aboutYou(ctx),
        ...kycDocs,
        {
            title: ctx.$t("registration.finalize-account"),
            item: {
                is: "IndividualCgu"
            },
            next: {
                fn: async () => ctx.updateUser(ctx.validateRegistration)
            },
            schemaDouble: CguSchemaDouble(ctx)
        }
    ];
};

export const registrationStepError = ctx => {
    return [
        {
            item: {
                is: "IndividualErrorForm"
            },
            noBack: true,
            next: {
                fn: async () => ctx.updateUser(ctx.validateRegistration)
            },
            schemaDouble: {
                ...publicInfoSchemaDouble,
                ...privateInfoSchemaDouble(ctx),
                ...Docs1SchemaDouble(ctx),
                ...Docs2SchemaDouble(ctx),
                ...CguSchemaDouble(ctx)
            }
        }
    ];
};

export const contactInfos = ctx => {
    return {
        is: "IndividualInfoPublicForm",
        schemaDouble: { ...publicInfoSchemaDouble, ...aboutYouSchemaDouble(ctx) }
    };
};

export const legalInfos = ctx => {
    return {
        is: "IndividualLegalInfosForm",
        schemaDouble: {
            ...privateInfoSchemaDouble(ctx),
            ...Docs1SchemaDouble(ctx),
            ...Docs2SchemaDouble(ctx)
        }
    };
};

export const complianceInfos = ctx => {
    return {
        is: "IndividualComplianceInfosForm",
        schemaDouble: {
            ...Docs1SchemaDouble(ctx),
            ...Docs2SchemaDouble(ctx)
        }
    };
};
