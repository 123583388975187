var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"d-flex flex-column fill-height"},'EqsCard',{
        loading: _vm.loading,
        height: '100%'
    },false),[_c('v-row',{staticClass:"fill-heigth"},[_c('v-col',{attrs:{"cols":12}},[(_vm.state == 'success')?_c('img',{staticClass:"align-center",staticStyle:{"width":"inherit"},attrs:{"height":_vm.$reactive.getElementHeight('header'),"src":"/icons/checkbox-marked-circle-outline.svg"}}):(_vm.state == 'error')?_c('img',{staticClass:"align-center",staticStyle:{"width":"inherit"},attrs:{"height":_vm.$reactive.getElementHeight('header'),"src":"/icons/close-circle-outline.svg"}}):_vm._e()]),_c('v-col',{attrs:{"cols":12}},[_c('EqsTextDisplay',{staticClass:"text-center",attrs:{"eqs-type":"title","value":_vm.title || _vm.stateTexts[_vm.state]}})],1),_c('v-col',{attrs:{"cols":12,"align":"center"}},[(_vm.state != 'error')?_vm._t("content"):_c('EqsTextDisplay',{staticClass:"text-center",attrs:{"value":!!_vm.errorText
                        ? _vm.errorText
                        : _vm.$t('global-final-state.error-text', { universeMail: _vm.universeMail }),"eqs-type":"subtitle"}})],2),(!!_vm.redirection)?_c('v-col',{attrs:{"cols":12}},[_c('v-row',{staticClass:"justify-center"},[_c('EqsBtn',{staticClass:"justify-center",attrs:{"color":"primary","action":() => {
                            _vm.redirection.fn();
                        }}},[_vm._v(" "+_vm._s(_vm.redirection.text)+" ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }