<template>
    <v-container fluid>
        <v-row>
            <v-col class="text-center mt-8">
                <h2 class="text-h3">{{ $t("ui-error.not-found") }}</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mt-8">
                <v-img
                    contain
                    max-width="300"
                    style="margin: auto"
                    keep-ratio
                    src="/images/404.png"
                    alt="404 image"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {}
};
</script>
