<template>
    <v-footer style="background-color: #0d0d2b" class="ma-0">
        <div>
            <div style="margin: 20px; display: flex; color: white">
                <img
                    src="/images/Pastille_cip.png"
                    alt="Logo CIP"
                    style="width: 90px; height: 90px"
                />
                <div style="margin-left: 10px">
                    <EqsTextDisplay eqs-type="p" :value="$t('projects.cip-legend')" />
                    <br />
                    <EqsTextDisplay
                        eqs-type="p"
                        :value="$t('crowdfunding.footer.equisafe-invest-test')"
                    />
                </div>
            </div>
            <div style="margin: 20px; display: flex; color: white">
                <img src="/images/FPF_pastille.png" alt="Logo FPF" />
                <div style="margin-left: 10px; margin-top: 20px">
                    <EqsTextDisplay eqs-type="p" :value="$t('crowdfunding.fpf')" />
                </div>
            </div>
            <v-row class="px-3" style="margin: 20px; color: white">
                <v-col>
                    <img src="/images/Equisafe_Invest_Logo.png" alt="Logo FPF" />
                    <p style="font-size: large">
                        {{ $t("crowdfunding.footer.contact-title") }}
                    </p>
                    <EqsTextDisplay eqs-type="p" :value="$t('crowdfunding.footer.contact')" />
                </v-col>
                <v-col>
                    <p style="font-size: large">
                        {{ $t("crowdfunding.footer.legal-mentions") }}
                    </p>
                    <EqsTextDisplay
                        style="margin: 8px 0px"
                        eqs-type="p"
                        :value="$t('crowdfunding.footer.cgu-equisafe-invest')"
                    />
                    <EqsTextDisplay
                        style="margin: 8px 0px"
                        eqs-type="p"
                        :value="$t('crowdfunding.footer.cgu-equisafe')"
                    />
                    <EqsTextDisplay
                        style="margin: 8px 0px"
                        eqs-type="p"
                        :value="$t('crowdfunding.footer.cgu-lemonway')"
                    />
                    <EqsTextDisplay
                        style="margin: 8px 0px"
                        eqs-type="p"
                        :value="$t('crowdfunding.footer.cookies-politic')"
                    />
                    <EqsTextDisplay
                        style="margin: 8px 0px"
                        eqs-type="p"
                        :value="$t('crowdfunding.footer.privacy-politic')"
                    />
                </v-col>
                <v-col>
                    <EqsTextDisplay
                        style="margin-top: 30px"
                        eqs-type="p"
                        :value="$t('crowdfunding.footer.text-back')"
                    />
                </v-col>
            </v-row>
        </div>
    </v-footer>
</template>

<script>
export default {
    name: "CrowdfundingFooter"
};
</script>

<style></style>
