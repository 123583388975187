var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._g(_vm._b({staticClass:"fill-height",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('EqsStepper',_vm._b({staticStyle:{"height":"100%"},attrs:{"value":_vm.step,"closable":""},on:{"update:value":function($event){_vm.step=$event},"close":_vm.backToSecondaryMarket},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c(item.is,_vm._b({tag:"component",attrs:{"stepper-state":_vm.stepperState},on:{"signatureRefused":_vm.signatureRefused}},'component',{
                        loading: _vm.localLoading || _vm.proceduresLoading || _vm.balanceLoading,
                        schemaDouble: _vm.schemaDouble,
                        balance: _vm.balance,
                        signer: _vm.signer,
                        formData: _vm.formData,
                        getTotal: _vm.getTotal,
                        getFees: _vm.getFees,
                        order: _vm.order,
                        documents: _vm.order.docs,
                        operationId: _vm.operationId
                    },false))]}}])},'EqsStepper',{
                steps: _vm.steps,
                closeInfoTxt: _vm.closeInfoTxt,
                progress: _vm.progress
            },false))]},proxy:true}])},'EqsCard',{
        height: '100%',
        loading: _vm.loading || _vm.loadingInit
    },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }