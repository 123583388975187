export class NotificationsService {
    constructor(api, store, router) {
        if (!api) throw new Error("The api object should be defined");
        if (!store) throw new Error("The store object should be defined");
        this.api = api;
        this.store = store;
        this.router = router;
    }
    async getNotifications(queries = {}) {
        try {
            await this.store.dispatch("getNotifications", queries);
        } catch (e) {
            // if e = undefined, then we haven't received a response from the server when we tried getting
            // the notifications
            if (e !== undefined) {
                window.app.$ui.error(e, "entity_get_notifications");
                throw e;
            }
        }
    }
    setNumberOfNewNotifications(count) {
        this.store.commit("SET_NUMBER_OF_NEW_NOTIFICATIONS", count);
    }
    numberOfNewNotifications() {
        return this.store.getters.numberOfNewNotifications || 0;
    }
    lastNotifications() {
        return this.store.getters.lastNotifications;
    }
    notifications() {
        return this.store.getters.notifications;
    }
    async updateNotificationsShown(isNotifMenu = true) {
        try {
            const toUpdate = {
                notifications: isNotifMenu ? this.lastNotifications() : this.notifications(),
                options: {
                    shown: true
                }
            };
            this.store.dispatch("updateNotifications", toUpdate);
        } catch (e) {
            window.app.$ui.error(e, "entity_get_notifications");
            throw e;
        }
    }
    numActive() {
        return this.store.getters.numActive;
    }
}
