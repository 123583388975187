<template>
    <EqsCard
        v-bind="{
            loading,
            width: '100%',
            height: '100%',
            eqsType: 'container'
        }"
    >
        <v-row class="fill-height d-flex justify-center px-3 overflow-y">
            <v-col :cols="$reactive.isDesktop() ? 6 : 12">
                <!-- <div class="d-flex flex-column justify-center align-center"> -->
                <v-row class="px-3 pb-3">
                    <v-col class="eqs-form-field">
                        <EqsSelectLanguage
                            class="mb-5"
                            v-bind="{
                                loading,
                                value: $language.current(),
                                items: $language.allLanguages()
                            }"
                            @update:value="switchLanguage"
                        />
                        <EqsTextDisplay
                            class="mb-5 pt-3"
                            eqs-type="subtitle"
                            :value="$t('user.profile.preferences.email-notification.label')"
                        />
                        <EqsTextDisplay
                            class="mb-3"
                            :value="$t('user.profile.preferences.email-notification.info')"
                        />
                        <EqsSwitch
                            class="my-3"
                            v-bind="{
                                loading: localLoading,
                                value: !entity.no_email_invitation,
                                isRequired: false,
                                label: $t('user.profile.invitation-no-email-label'),
                                text: $t('user.profile.invitation-no-email-text')
                            }"
                            @change="event => updateEmail(event, 'no_email_invitation')"
                        />
                        <EqsSwitch
                            class="my-3"
                            v-bind="{
                                loading: localLoading,
                                value: !entity.no_email_KYC,
                                isRequired: false,
                                label: $t('user.profile.kyc-no-email-label'),
                                text: $t('user.profile.kyc-no-email-text', { universe })
                            }"
                            @change="event => updateEmail(event, 'no_email_KYC')"
                        />
                        <EqsSwitch
                            class="my-3"
                            v-bind="{
                                loading: localLoading,
                                value: !entity.no_email_share,
                                isRequired: false,
                                label: $t('user.profile.share-no-email-label'),
                                text: $t('user.profile.share-no-email-text')
                            }"
                            @change="event => updateEmail(event, 'no_email_share')"
                        />
                        <div v-if="entity.is_company">
                            <EqsTextDisplay
                                class="my-5"
                                eqs-type="subtitle"
                                :value="$t('user.profile.preferences.communication.label')"
                            />
                            <EqsTextDisplay
                                class="mb-3"
                                :value="$t('user.profile.preferences.communication.info')"
                            />
                            <EqsSwitch
                                class="my-3"
                                v-bind="{
                                    loading: localLoading,
                                    value: allPreferences.corrected_tri_enabled,
                                    isRequired: false,
                                    label: $t('user.profile.preferences.TRI-label'),
                                    text: $t('user.profile.preferences.TRI-text')
                                }"
                                @change="event => updatePreferences(event, 'corrected_tri_enabled')"
                            />
                            <EqsSwitch
                                class="my-3"
                                v-bind="{
                                    loading: localLoading,
                                    value: allPreferences.hide_transfert_type_enabled,
                                    isRequired: false,
                                    label: $t('user.profile.preferences.transfer-label'),
                                    text: $t('user.profile.preferences.transfer-text')
                                }"
                                @change="
                                    event => updatePreferences(event, 'hide_transfert_type_enabled')
                                "
                            />
                        </div>
                        <div v-else class="d-flex flex-column">
                            <EqsTextDisplay
                                class="mb-5 pt-3"
                                eqs-type="subtitle"
                                :value="$t('user.profile.preferences.security.label')"
                            />
                            <EqsTextDisplay
                                class="mb-3"
                                :value="$t('user.profile.preferences.security.info')"
                            />
                            <ChangePassword class="my-5" v-bind="{ entity, loading }" />
                            <TwoFA class="my-5" v-bind="{ entity, loading }" />
                        </div>
                        <DeleteUser class="my-3" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- </div> -->
    </EqsCard>
    <!-- </v-col>
  </v-row> -->
</template>

<script>
import DeleteUser from "./DeleteUser.vue";
export default {
    name: "Preferences",
    components: {
        TwoFA: () => import("./TwoFA.vue"),
        ChangePassword: () => import("./ChangePassword.vue"),
        DeleteUser
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        entity: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            localLoading: false,
            allPreferences: {}
        };
    },
    computed: {
        universe() {
            return this.$store.getters.universe.public_name;
        }
    },
    async created() {
        this.localLoading = true;
        try {
            const res = await this.$api.getPreferences();
            this.cleanData(res.data);
        } catch (e) {
            console.error({ e });
            this.$ui.error(e, "get_preferences");
        }
        this.localLoading = false;
    },
    methods: {
        cleanData(data) {
            this.allPreferences = {};
            if (!data) return;
            data.forEach(element => {
                this.allPreferences[element.name] = element.value;
            });
        },
        async updateEmail(val, key) {
            let data = {};
            data[key] = !val;
            this.localLoading = true;
            try {
                await this.$entity.updateEntity(data);
            } catch (e) {
                console.error({ e });
                this.$ui.error(e, "entity_update_email");
                this.localLoading = false;
            }
            this.localLoading = false;
        },
        async updatePreferences(val, type) {
            let data = {
                name: type,
                value: !!val
            };
            this.localLoading = true;
            try {
                await this.$api.updatePreferences(data);
            } catch (e) {
                this.$ui.error(e, "update_preferences");
                this.localLoading = false;
            }
            this.localLoading = false;
        },
        async switchLanguage(newLanguage) {
            try {
                this.$currency.setLocale(newLanguage);
                await this.$language.changeTo(newLanguage, true);
            } catch (e) {
                console.error({ e });
            }
        }
    }
};
</script>
