import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }, data) => {
    DEBUG("GET_PROJECTS_START");
    if (state.projectsCipInit && !state.projectsCipLoading) {
        try {
            commit("SET_PROJECTS_CIP_LOADING", true);
            const projectsRes = await window.app.$api.getTinyCapitalIncreases({
                public: true,
                cip: true
            });
            DEBUG("GET_PROJECTS_SUCCESS");
            commit("SET_PROJECTS_CIP", projectsRes.data);
        } catch (e) {
            DEBUG("GET_PROJECTS_ERROR");
            window.app.$ui.error(e, "capital_increase_user_get_offerings");
            throw e;
        } finally {
            commit("SET_PROJECTS_CIP_LOADING", false);
        }
    } else {
        DEBUG("GET_PROJECTS_BYPASSED");
    }
};
