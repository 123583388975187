import displayError from "@/store/displayError";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, user) => {
    DEBUG("SEND_MAIL_FORGOT_PASSWORD_START");
    try {
        await window.app.$api.forgotPassword({ email: user.email });
        commit("SEND_MAIL_FORGOT_PASSWORD_SUCCESS");
        commit("SET_ENTITY", user);
    } catch (error) {
        DEBUG("SEND_MAIL_FORGOT_PASSWORD_ERROR");
        displayError(error, true);
    }
};
