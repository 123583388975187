var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"closable":""},scopedSlots:_vm._u([{key:"default",fn:function({ loading: loadingImport }){return [_c('EqsText',_vm._b({staticClass:"py-3",attrs:{"value":_vm.offeringID},on:{"update:value":function($event){_vm.offeringID=$event},"input":() => (_vm.offeringDirty = false)}},'EqsText',{
                loading: loadingImport,
                label: _vm.$t('projecs.dashboard.offerings.import-id-label'),
                errorMessages: _vm.offeringDirty ? 'invalid ID' : null
            },false))]}}]),model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},'EqsDialog',{
        attachToVApp: true,
        title: _vm.$t('projecs.dashboard.offerings.import-project'),
        subtitle: _vm.$t('projecs.dashboard.offerings.import-project-subtitle'),
        confirm: {
            fn: _vm.importOffering,
            txt: _vm.$t('capital-increase.user.dashboard.import-btn')
        },
        cancel: {
            fn: () => (_vm.dialogProxy = false)
        }
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }