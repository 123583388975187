<template>
    <EqsStepper
        :value.sync="step"
        closable
        v-bind="{
            steps,
            loading: loading || localLoading || vibanLoading,
            progress
        }"
        style="height: 100%"
        @close="() => $emit('close')"
    >
        <template #default="{ item, loading: isLoading }">
            <div class="eqs-main-container margin-auto">
                <component
                    :is="item.is"
                    :step.sync="step"
                    :form-data.sync="formData"
                    v-bind="{
                        loading: isLoading,
                        order,
                        status,
                        options: getOptions,
                        pollOrder,
                        totalVal,
                        schemaDouble
                    }"
                />
            </div>
        </template>
    </EqsStepper>
</template>

<script>
import PurchaseAmount from "./PurchaseAmount.vue";
import Payment from "@/components/views/user/buy/payment/_Payment.vue";
import OrderStatus from "./OrderStatus.vue";
import {
    statutes,
    options as _options,
    schemaDoubleStep1,
    schemaDoubleStep2Transfer
} from "./moneyIn.js";

import { LW_PROCESSING_TIMEOUT, LW_INTERVAL } from "@/assets/constants";
import { asyncPoll } from "@equisafe-ui-shared/util";
import { mapActions, mapGetters } from "vuex";

import { progressMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "MoneyIn",
    components: {
        PurchaseAmount,
        Payment,
        OrderStatus
    },
    mixins: [progressMixin],
    props: {
        id: {
            type: String,
            default: "init"
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            statutes,
            localLoading: false,
            step: 0,
            fees: {},
            formData: {
                eee: true
            },
            order: {
                payMoneyIn: true
            },
            schemaDouble: {}
        };
    },
    computed: {
        universe() {
            return this.$store.getters.universe.public_name;
        },
        universeMail() {
            return this.$store.getters.universe.contact_email;
        },
        ...mapGetters(["vibanLoading", "viban"]),
        getOptions() {
            return _options(this);
        },
        vibanProxy() {
            return this.viban || {};
        },
        totalVal() {
            let total = 0;
            if (this.formData && this.formData.moneyVal && this.formData.paymentOption) {
                var fee = this.fees[this.formData.paymentOption];
                const moneyVal = parseFloat(this.formData.moneyVal);
                fee = !!fee ? fee[this.formData.eee ? "eee" : "world"] : null;
                if (fee && moneyVal) {
                    total += fee.fixed + moneyVal;
                    total += moneyVal * fee.coef;
                }
            }
            return Math.round(total * 100) / 100;
        },
        steps() {
            let steps = [
                {
                    title: this.$t("user.buy.purchase-amount"),
                    item: {
                        is: "PurchaseAmount"
                    },
                    schemaDouble: schemaDoubleStep1,
                    back: {
                        fn: () => this.$emit("close")
                    },
                    next: {
                        fn: this.moneyInConfirm,
                        txt: !this.isCard
                            ? this.$t("user.wallet.money-in.confirm-payment")
                            : this.$t("label.confirm")
                    }
                }
            ];
            if (this.order.payment_mode || this.order.type) {
                const paymentProcess = {
                    title: this.isCard
                        ? this.$t("user.buy.payment-process")
                        : this.$t("user.buy.instruction-bank-wire"),
                    item: {
                        is: "Payment"
                    },
                    schemaDouble: !this.isCard ? schemaDoubleStep2Transfer : {},
                    noBack: this.isCard,
                    noNext: this.isCard,
                    next: !this.isCard
                        ? {
                              fn: this.dialogBankWire
                          }
                        : null
                };
                steps.push(paymentProcess);
                if (this.isCard) {
                    const orderStatus = {
                        title: this.$t("user.buy.order-status"),
                        item: {
                            is: "OrderStatus"
                        },
                        schemaDouble: {},
                        next: this.$route.query.route_redirection
                            ? {
                                  fn: () => this.$router.push(this.$route.query.route_redirection),
                                  txt: this.$t("label.back-to-purchase")
                              }
                            : {
                                  fn: () => this.$emit("close"),
                                  txt: this.$t("label.my-wallet")
                              },
                        noBack: true
                    };
                    steps.push(orderStatus);
                }
            }
            return steps;
        },
        status() {
            return this.order && this.order.status
                ? this.statutes[this.order.status]
                : this.statutes["pending"];
        },
        isCard() {
            return this.formData.paymentOption === "card" || this.order.type === "in";
        }
    },
    watch: {
        step: {
            immediate: true,
            handler(val) {
                const currentStep = this.steps[val];
                if (currentStep) {
                    this.schemaDouble = currentStep.schemaDouble;
                }
            }
        },
        "$route.query.moneyVal": {
            immediate: true,
            handler(val) {
                if (val) this.formData.moneyVal = val;
            }
        }
    },
    async created() {
        try {
            this.localLoading = true;
            this.getVIban();
            await this.fetchOrder();
            await this.getFees();
        } catch (e) {
        } finally {
            this.localLoading = false;
        }
    },
    methods: {
        ...mapActions(["getVIban"]),
        dialogBankWire() {
            this.$dialog.info({
                title: this.$t("user.buy.dialog-bank-wire-title"),
                text: this.$t("user.buy.dialog-bank-wire-msg", {
                    universeMail: this.universeMail,
                    universe: this.universe
                }),
                confirm: {
                    fn: () => this.$emit("close"),
                    txt: this.$t("btn.continue")
                }
            });
        },
        async pollOrder() {
            try {
                this.localLoading = true;
                await asyncPoll(
                    this.fetchOrder,
                    null,
                    status => status !== "pending",
                    LW_INTERVAL,
                    LW_PROCESSING_TIMEOUT
                );
            } catch (e) {
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async getFees() {
            try {
                const res = await this.$api.getMoneyInFees();
                this.fees = res.data;
            } catch (e) {
                console.error({ e });
                throw e;
            }
        },
        async fetchOrder() {
            let status = "pending";
            try {
                if (this.id !== "init") {
                    const res = await this.$api.getMovement(this.id);
                    this.$set(this, "order", {
                        ...this.order,
                        ...res.data,
                        total_price: res.data.amount,
                        status: res.data.lemonway_status
                    });
                    this.getStep(res.data.lemonway_status);
                    status = res.data.lemonway_status;
                }
            } catch (e) {
                console.error({ e });
            } finally {
                return status;
            }
        },
        getStep(status) {
            if (!status) this.step = 0;
            if (status !== "pending") this.step = 2;
            else this.step = 1;
        },
        async moneyInConfirm() {
            try {
                this.localLoading = true;
                if (!this.isCard) {
                    this.order.payment_mode = this.formData.paymentOption;
                    this.order.total_price = this.totalVal;
                    this.order.payment_token = ` EQUISAFE-${this.$entity.represented().wallet_id}`;
                    this.$set(this, "order", { ...this.order });
                    this.step++;
                } else {
                    const redirection = this.$route.query.route_redirection;
                    const res = redirection
                        ? await this.$api.createMoneyInRedirection(this.totalVal, redirection)
                        : await this.$api.createMoneyIn(this.totalVal);

                    this.$set(this, "order", {
                        ...this.order,
                        ...res.data,
                        total_price: this.totalVal,
                        status: res.data.lemonway_status
                    });
                    this.getStep(res.data.lemonway_status);
                }
            } catch (e) {
                this.$ui.error(e, "buy_trigger_buy_process");
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>
