var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-lazy',{model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('EqsDataTable',_vm._b({staticClass:"row-pointer",attrs:{"el-class":"capinc-history","searchable":{
            placeholder: _vm.$t('capital-increase.admin.history.search-for-capinc')
        },"hide-default-footer":"","add":{
            txt: _vm.$t('capital-increase.create-card-btn'),
            fn: () =>
                _vm.$router.push({
                    name: 'fundraising-add'
                })
        }},on:{"click:row":item => _vm.goToDetails(item)},scopedSlots:_vm._u([{key:"item.start_date",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$time.formated(value))+" ")]}},{key:"item.end_date",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$time.formated(value))+" ")]}},{key:"item.money_raised",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$currency.display(value))+" ")]}},{key:"item.phase",fn:function({ item }){return [_c('EqsChip',{staticClass:"pa-1",attrs:{"eqs-type":_vm.phaseItem(item).eqsType || ''}},[_vm._v(" "+_vm._s(_vm.phaseItem(item).text || "")+" ")])]}},{key:"item.actions",fn:function({ item, index }){return [_c('EqsActions',_vm._b({},'EqsActions',{
                    elClass: `capinc-actions-${index}`,
                    actions: _vm.getActions(item)
                },false))]}}])},'EqsDataTable',{
            items: _vm.myProjects,
            headers: _vm.headers,
            loading: _vm.loading || _vm.myProjectsLoading
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }