<template>
    <div>
        <table class="ma-auto">
            <tr v-for="(document, index) in field.documents" :key="index">
                <td class="text-subtitle">{{ document.name }}</td>
                <td>
                    <v-icon
                        color="#2b2b2b"
                        size="32"
                        class="ml-2"
                        @click="downloadDocument(document)"
                    >
                        mdi-download-outline
                    </v-icon>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: "FieldDocument",
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {};
    },
    methods: {
        downloadDocument(document) {
            if (document.upload) {
                window.open(document.upload, "_blank");
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
