<template>
    <v-container fluid class="root-step-container">
        <h2 class="text-h5 mb-4">Connaissances de l’Investisseur</h2>
        <EqsSelect
            :value.sync="formData.p1q1"
            multiple
            v-bind="{
                label: 'Quels types de services d’investissement connaissez-vous ?',
                items: q1Items,
                ...schemaDouble.p1q1
            }"
        />
        <EqsSelect
            :value.sync="formData.p1q2"
            multiple
            v-bind="{
                label: 'Quels types d’instruments financiers connaissez-vous ?',
                items: q2Items,
                ...schemaDouble.p1q2
            }"
        />
        <EqsRadio
            :value.sync="formData.p1q3"
            row
            v-bind="{
                label: 'Avez-vous déjà investi dans des instruments financiers ?',
                ...schemaDouble.p1q3
            }"
        />
        <div v-show="formData.p1q3 === 'yes'" class="pa-0 ma-0">
            <EqsSelect
                :value.sync="formData.p1q4"
                multiple
                v-bind="{
                    label: ' Si oui, sur quels types d’instruments financiers portaient vos investissements ?',
                    items: q4Items,
                    ...schemaDouble.p1q5
                }"
            />
        </div>
        <div v-show="formData.p1q3 === 'yes'" class="pa-0 ma-0">
            <EqsNumber
                :value.sync="formData.p1q5"
                :is-currency="false"
                :is-float="false"
                v-bind="{
                    label: 'Quel était le volume de vos investissements ?',
                    editable: true,
                    placeholder: 'Ex: 20000',
                    ...schemaDouble.p1q5
                }"
            />
        </div>
        <div v-show="formData.p1q3 === 'yes'" class="pa-0 ma-0">
            <EqsSelect
                :value.sync="formData.p1q6"
                v-bind="{
                    label: 'Quelle était la fréquence de vos investissements ?',
                    items: q6Items,
                    ...schemaDouble.p1q6
                }"
            />
        </div>
        <div v-show="formData.p1q3 === 'yes'" class="pa-0 ma-0">
            <EqsSelect
                :value.sync="formData.p1q7"
                v-bind="{
                    label: 'Sur quelle période vos investissements ont-ils été réalisés ?',
                    items: q7Items,
                    ...schemaDouble.p1q7
                }"
            />
        </div>

        <EqsRadio
            :value.sync="formData.p1q8"
            row
            v-bind="{
                label: 'Avez-vous exercé ou exercez-vous actuellement une profession dans le domaine financier ?',
                ...schemaDouble.p1q8
            }"
        />
        <EqsRadio
            :value.sync="formData.p1q9"
            row
            v-bind="{
                label: 'Vos expériences professionnelles passées vous ont-elles permis d’acquérir des compétences en financement participatif ?',
                ...schemaDouble.p1q9
            }"
        />
        <div v-show="formData.p1q9 === 'yes'" class="pa-0 ma-0">
            <EqsText
                :value.sync="formData.p1q10"
                v-bind="{
                    label: 'Si oui, lesquelles, et quelles ont été leur durée ?',
                    editable,
                    placeholder: 'Ex: 8 moins',
                    ...schemaDouble.p1q10
                }"
            />
        </div>
        <EqsSelect
            :value.sync="formData.p1q11"
            v-bind="{
                label: 'Quel est votre niveau d’étude maximal atteint dans le domaine financier ?',
                items: q11Items,
                ...schemaDouble.p1q11
            }"
        />
        <v-dialog
            v-model="dialogProxy"
            closable
            transition="dialog-bottom-transition"
            max-width="600"
        >
            <v-card class="pa-4">
                <v-card-title class="text-h5" style="word-break: break-word">
                    Investisseur non averti</v-card-title
                >
                <v-card-text>
                    <strong>Vous avez obtenu le statut d’Investisseur non-averti</strong> selon les
                    conditions du Règlement (UE) 2020/1503 relatif aux prestataires européens de
                    services de financement participatif. <br /><br />
                    Nous allons donc vous faire passer un test de connaissance et un simulateur de
                    capacité à subir des pertes afin d’identifié votre profil investisseur.
                    <br /><br />
                    Vous disposerez d’un délai de réflexion de quatre jours (4) au cours duquel vous
                    pourrez retirer une offre d’investissement.
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="dialogProxy = false"> J'ai compris </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { doItems } from "./util";

export default {
    name: "Form1",
    mixins: [formMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dialogProxy: true,
            q1Items: doItems(
                "PME",
                "Bourse",
                "Immobilier locatif",
                "Produits bancaire",
                "Fonds de pension",
                "Fonds d'investissement"
            ),
            q2Items: doItems(
                "Actions",
                "Obligations",
                "Options",
                "Matières premières",
                "Devises",
                "Produits dérivés",
                "Produits structurés"
            ),
            q4Items: doItems(
                "Actions",
                "Obligations",
                "Options",
                "Matières premières",
                "Devises",
                "Produits dérivés",
                "Produits structurés",
                "Autres"
            ),
            q6Items: doItems("Plusieurs fois par mois", "Une fois par mois", "Une fois par an"),
            q7Items: doItems("< 1 an", "Entre 1 an et 5 ans", "Plus de 5 ans"),
            q11Items: doItems("Aucun", "BTS", "Licence", "Master", "Doctorat")
        };
    },
    watch: {
        "formData.p1q3": {
            handler(newVal, oldVal) {
                this.schemaDouble.p1q4.isRequired = newVal === "yes";
                this.schemaDouble.p1q5.isRequired = newVal === "yes";
                this.schemaDouble.p1q6.isRequired = newVal === "yes";
                this.schemaDouble.p1q7.isRequired = newVal === "yes";
            },
            immediate: true
        },
        "formData.p1q9": {
            handler(newVal, oldVal) {
                this.schemaDouble.p1q10.isRequired = newVal === "yes";
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
