var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-lazy',[_c('v-container',{staticClass:"align-start pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"custom-btn",attrs:{"outlined":""},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.$t("payments.add"))+" ")])],1)],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":_vm.getPaymentsListCols}},[_c('DataTablePaginated',{attrs:{"loading":_vm.loading || _vm.localLoading,"headers":_vm.headers,"items":_vm.items,"sort-desc":"","show-row-pointer":"","sort-by":"operation_date","search":"credited_entity_name","filter":{ field: 'operation_type', items: _vm.paymentCategories }},on:{"update":_vm.fetchData,"click-row":_vm.selectRow},scopedSlots:_vm._u([{key:`item.action`,fn:function({ value }){return [(value)?_c('EqsChip',{attrs:{"color":_vm.$color.colorFromHash(value)}},[_vm._v(" "+_vm._s(_vm.payment_actions[value] ? _vm.payment_actions[value].txt : value)+" ")]):_vm._e()]}},{key:`item.operation_date`,fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$time.formated(value))+" ")]}},{key:`item.amount`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency( item.amount, _vm.$entity.represented().country.alphacode == "US" ? "USD" : "EUR" ))+" ")]}},{key:`item.from`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.from)+" ")]}},{key:`item.credited_entity_name`,fn:function({ item }){return [_c('div',{staticClass:"cell d-flex flex-row align-center"},[(item.credited_entity)?_c('EqsAvatar',_vm._b({staticClass:"pr-1"},'EqsAvatar',{
                                    user: item.credited_entity,
                                    noBadge: true
                                },false)):_vm._e(),_vm._v(" "+_vm._s(item.credited_entity?.name || "-")+" ")],1)]}},{key:`item.debited_entity_name`,fn:function({ item }){return [_c('div',{staticClass:"cell d-flex flex-row align-center"},[(item.debited_entity)?_c('EqsAvatar',_vm._b({staticClass:"pr-1"},'EqsAvatar',{
                                    user: item.debited_entity,
                                    noBadge: true
                                },false)):_vm._e(),_vm._v(" "+_vm._s(item.debited_entity?.name || "-")+" ")],1)]}},{key:`item.operation_type`,fn:function({ value }){return [_c('EqsChip',{attrs:{"color":_vm.$color.colorFromHash(value)}},[_vm._v(" "+_vm._s(_vm.payment_types[value] ? _vm.payment_types[value].txt : value)+" ")])]}},{key:`item.lemonway_status`,fn:function({ value }){return [_c('EqsChip',_vm._b({},'EqsChip',_vm.statutes[value],false),[_vm._v(" "+_vm._s(_vm.statutes[value] ? _vm.statutes[value].txt : value)+" ")])]}}],null,true)})],1),(_vm.selectedPayment)?_c('v-col',{attrs:{"cols":_vm.$reactive.isDesktop() ? 5 : 12}},[_c('PaymentDetail',_vm._b({on:{"close":() => (_vm.selectedPayment = null)}},'PaymentDetail',{ payment: _vm.selectedPayment, loading: _vm.loading },false))],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }