<template>
    <div class="d-flex justify-center align-center fill-height">
        <EqsTabs
            v-bind="{
                tabs
            }"
            :value.sync="tab"
        >
            <template #default="{ item }">
                <EqsDataTable
                    v-bind="{
                        headers: setHeaders(item)
                    }"
                    :items.sync="values[item.is]"
                    :add="{
                        txt: $t('shareledger.valuation.new-valuation'),
                        fn: () => {
                            openDialog('create', item);
                        }
                    }"
                >
                    <template #item.date="{ item }">
                        {{ $time.formated(item.value_datetime) }}
                    </template>
                    <template #item.comment="{ item }">
                        {{ item.comment }}
                    </template>
                    <template #item.value="{ item }">
                        {{
                            $currency.displayWithCurrency(item.value_data, item.value_data_currency)
                        }}
                    </template>
                    <template #item.actions="{ item }">
                        <EqsActions v-bind="{ actions: getActions(item) }" />
                    </template>
                </EqsDataTable>
            </template>
        </EqsTabs>

        <EqsDialog
            persistent
            :value.sync="displayDialog.open"
            v-bind="{
                ...setDialogConfig(itemSelected),
                confirm: {
                    fn: async () => getCRUDfunction(itemSelected)
                },
                cancel: {
                    fn: () => closeDialog()
                }
            }"
        >
            <EqsDate
                id="dialogInputData"
                class="mb-2"
                :value.sync="newData.value_datetime"
                label="Date"
                :editable="!displayDialog.delete"
            />
            <EqsText
                id="dialogInputComment"
                class="mb-2"
                :label="$t('shareledger.valuation.comment')"
                :value.sync="newData.comment"
                :is-required="false"
                :editable="!displayDialog.delete"
            />
            <EqsNumber
                id="dialogInputValue"
                class="mb-2"
                :value.sync="newData.value_data"
                :label="
                    itemSelected.is == 'nav'
                        ? $t('shareledger.valuation.net-asset-value')
                        : $t('shareledger.valuation.market-value')
                "
                :currency-type="currency"
                :editable="!displayDialog.delete"
            />
        </EqsDialog>
    </div>
</template>

<script>
export default {
    name: "Valuation",
    data() {
        return {
            newData: {},
            itemSelected: {},
            displayDialog: {
                open: false,
                update: false,
                create: false,
                delete: false
            },
            tab: "market",
            tabs: [
                {
                    id: "market",
                    title: this.$t("shareledger.valuation.market-value"),
                    item: {
                        is: "marketValue"
                    }
                },
                {
                    id: "nav",
                    title: this.$t("shareledger.valuation.net-asset-value"),
                    item: {
                        is: "nav"
                    }
                }
            ],
            values: {
                marketValue: [],
                nav: []
            },
            currency: "EUR"
        };
    },
    async created() {
        await this.getData();
        this.currency = this.$entity.represented().nationality.is_eu ? "EUR" : "USD";
    },
    methods: {
        openDialog(type, item) {
            this.itemSelected = item;
            if (!this.displayDialog[type]) {
                this.displayDialog[type] = true;
            }
            if (type == "update" || type == "delete") {
                this.setNewData(item);
            }
            this.displayDialog.open = true;
        },
        closeDialog() {
            this.displayDialog.open = false;
            this.displayDialog.update = false;
            this.displayDialog.create = false;
            this.displayDialog.delete = false;
            this.newData = {};
        },
        getCRUDfunction(itemSelected) {
            if (this.displayDialog.create == true) {
                this.add(itemSelected);
            } else if (this.displayDialog.update == true) {
                this.updateItem(itemSelected);
            } else {
                this.deleteItem(itemSelected);
            }
        },
        setNewData(item) {
            this.newData = _.clone(item);
        },
        setDialogConfig(item) {
            let title =
                item.is == "nav"
                    ? this.$t("shareledger.valuation.net-asset-value")
                    : this.$t("shareledger.valuation.market-value");

            let config = {
                title
                // text: this.$t('shareledger.valuation.add-info'),
            };
            return config;
        },
        setHeaders(item) {
            let baseHeaders = [
                {
                    text: "Date",
                    value: "date",
                    align: "center"
                },
                {
                    text: this.$t("shareledger.valuation.comment"),
                    value: "comment",
                    align: "center"
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                    align: "center"
                }
            ];

            const navHeader = {
                text: window.app.$t("shareledger.valuation.net-asset-value"),
                value: "value",
                align: "right"
            };
            const venalHeader = {
                text: window.app.$t("shareledger.valuation.market-value"),
                value: "value",
                align: "right"
            };
            baseHeaders.splice(2, 0, item.is == "nav" ? navHeader : venalHeader);
            return baseHeaders;
        },
        async getData() {
            try {
                const marketValue = await this.$api.getCompanyAnalytics("marketValue");
                this.values.marketValue = marketValue.data;
                const nav = await this.$api.getCompanyAnalytics("nav");
                this.values.nav = nav.data;
            } catch (e) {
                console.error(e);
            }
        },
        async add(item) {
            this.newData.value_data_currency = this.currency;
            const date = this.newData.value_datetime;
            const convert = this.$time.toDate(this.newData.value_datetime);
            this.newData.value_datetime = this.$time.formatISO(convert);
            const dataId = await this.$api.createCompanyAnalytics(this.newData, item.is);
            this.newData.id = dataId.data;
            this.newData.value_datetime = date;
            this.values[item.is].push(this.newData);
            this.closeDialog();
        },
        async updateItem(item) {
            const type = this.tabs[this.tab].item.is;
            let index = this.values[type].indexOf(item);
            this.values[type].splice(index, 1, this.newData);
            await this.$api.updateCompanyAnalytics(this.newData);
            this.closeDialog();
        },
        async deleteItem(item) {
            const type = this.tabs[this.tab].item.is;
            await this.$api.deleteCompanyAnalytics(item.id);
            let index = this.values[type].indexOf(item);
            this.values[type].splice(index, 1);
            this.closeDialog();
        },
        getActions(item) {
            return [
                {
                    txt: this.$t("shareledger.valuation.edit-btn"),
                    icon: "mdi-border-color",
                    fn: () => this.openDialog("update", item),
                    color: "prinary"
                },
                {
                    txt: this.$t("shareledger.valuation.delete-btn"),
                    icon: "mdi-delete-outline",
                    fn: () => this.openDialog("delete", item),
                    color: "error"
                }
            ];
        }
    }
};
</script>
