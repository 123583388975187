<template>
    <v-list-item-action :style="getStyle">
        <v-row
            v-if="item.upload"
            :class="`eqs-file-input-actions ${elClass}-file-actions-container ma-0`"
            no-gutters
            justify="center"
            align="center"
        >
            <a
                :class="`${elClass}-download-file-${item.id}`"
                style="display: none"
                :href="`${item.upload.split('/download/')[0]}/download_file/`"
                download
                >{{ item.name }}</a
            >
            <v-col v-bind="{ cols }">
                <!-- action: () => download(`${elClass}-download-file-${item.id}`), -->
                <EqsBtn
                    small
                    text
                    v-bind="{
                        action: () => showDocument(item),
                        infoTxt: $t('file-actions.download')
                    }"
                >
                    <v-icon> mdi-download-outline </v-icon>
                </EqsBtn>
            </v-col>
            <!-- <v-col v-bind="{ cols }">
        <EqsBtn
          small
          text
          v-bind="{
            action: () => showDocument(item),
            infoTxt: $t('file-actions.open'),
          }"
        >
          <v-icon> mdi-file-outline </v-icon>
        </EqsBtn>
      </v-col> -->
            <v-col v-bind="{ cols }">
                <EqsBtn
                    v-if="item.deletable && editable"
                    :id="`delete-file-${index}`"
                    small
                    text
                    :el-class="`delete-file`"
                    color="error"
                    v-bind="{ action: onDeleteFile, infoTxt: $t('file-actions.delete') }"
                >
                    <v-icon color="error"> mdi-delete-outline </v-icon>
                </EqsBtn>
            </v-col>
        </v-row>
    </v-list-item-action>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsFileActions.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { mouseEvent } from "../../../../../shared/util";

export default {
    name: "EqsFileActions",
    components: {
        EqsBtn: () => import("../../atoms/EqsBtn/EqsBtn.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-file-actions"
        },
        item: {
            type: [Object, File],
            required: true
        },
        index: {
            type: Number,
            default: 0
        },
        removeItem: {
            type: Function,
            default: null
        },
        editable: {
            type: Boolean,
            default: true
        },
        deleteFunc: {
            type: Function,
            default: null
        },
        deleteFile: {
            type: Boolean,
            default: true
        },
        downloadFunc: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        deleteFunction() {
            return this.deleteFunc || this.$api.deleteDocument;
        },
        cols() {
            return "auto";
            // let numOfBtn = 2;
            // if (this.item.deletable && this.editable) numOfBtn++;
            // return 12 / numOfBtn;
        }
    },
    methods: {
        download(className) {
            if (this.downloadFunc) {
                this.downloadFunc();
            } else {
                this.downloadFile(className);
            }
        },
        downloadFile(className) {
            mouseEvent(document.getElementsByClassName(className)[0], "click");
        },
        showDocument(file) {
            window.open(file.upload, "_blank");
        },
        async onDeleteFile() {
            if (!this.deleteFile) {
                if (this.removeItem) this.removeItem(this.index);
            } else {
                try {
                    this.$emit("isLoading", true);
                    const func = this.deleteFunction;
                    await func(this.item.id, this.item);
                } catch (e) {
                    console.error({ e });
                    this.$ui.error(this.$t("file-input.failed-to-delete"));
                } finally {
                    if (this.removeItem) this.removeItem(this.index);
                    this.$emit("isLoading", false);
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsFileActions.scss";
</style>
