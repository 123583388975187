var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"fill-height",attrs:{"closable":""},on:{"close":_vm.backToGovernance}},'EqsCard',{
        loading: _vm.loading || _vm.organsLoading,
        height: '100%',
        title: _vm.$t('governance.organs.details.title', { name: _vm.organ.name })
    },false),[_c('div',{staticClass:"d-flex flex-column fill-height overflow-y",staticStyle:{"overflow-x":"hidden"}},[_c('v-row',{staticClass:"px-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('DecisionsList',_vm._b({},'DecisionsList',{ organ: _vm.organ },false))],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Office',_vm._b({},'Office',{ organ: _vm.organ },false))],1),_c('v-col',[_c('Voting',_vm._b({},'Voting',{
                        organ: _vm.organ
                    },false))],1),(_vm.organ.vote_type !== 'share_to_vote')?_c('v-col',{attrs:{"cols":"12"}},[_c('NonVoting',_vm._b({},'NonVoting',{ organ: _vm.organ },false))],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('OtherInfo',_vm._b({},'OtherInfo',{ organ: _vm.organ },false))],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }