<template>
    <v-form ref="confirmForgotPasswordForm" v-model="validForm" class="d-flex flex-column">
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field px-0">
                <EqsPassword
                    id="password"
                    :label="$t('forgot-password.label-password')"
                    :value.sync="formData.password"
                    el-class="password-forgot-password"
                    v-bind="{ hint: $t('forgot-password.password-hint'), loading }"
                    @keyup.enter="confirmForgotPassword"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field px-0">
                <EqsPassword
                    id="passwordConfirmation"
                    :label="$t('forgot-password.label-password-confirm')"
                    :value.sync="passwordConfirmation"
                    el-class="confirm-forgot-password"
                    v-bind="{
                        rules: [identicalR],
                        hint: $t('forgot-password.password-hint'),
                        loading
                    }"
                    @keyup.enter="confirmForgotPassword"
                />
            </v-col>
            <v-col v-if="twofaInput" :cols="$reactive.getCols('full')" class="eqs-form-field px-0">
                <EqsText
                    id="twoFAToken"
                    name="token"
                    type="number"
                    :value.sync="token"
                    v-bind="{
                        loading,
                        counter: 6,
                        label: $t('login.two-fa.label'),
                        hint: $t('login.two-fa.hint'),
                        placeholder: $t('login.two-fa.placeholder')
                    }"
                    persistent-hint
                    @keyup.enter="confirmForgotPassword"
                />
            </v-col>
            <v-col class="eqs-form-field px-0">
                <EqsBtn
                    id="btnConfirmForgotPassword"
                    color="primary"
                    block
                    v-bind="{
                        action: confirmForgotPassword,
                        loading,
                        disabled: !validForm
                    }"
                    el-class="btn-confirm-forgot-password"
                >
                    {{ $t("forgot-password.create-new") }}
                </EqsBtn>
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field px-0">
                <a class="text-link" el-class="btn-login" @click="$router.push('/auth')">{{
                    $t("auth.register.sign-in")
                }}</a>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
export default {
    name: "ConfirmForgotPassword",
    data() {
        return {
            password: "",
            passwordConfirmation: "",
            token: "",
            twofaInput: false,
            validForm: false,
            loading: false,
            user_id: "",
            timestamp: "",
            signature: "",
            formData: {}
        };
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        identicalR() {
            return (
                this.formData.password === this.passwordConfirmation ||
                this.$t("rules.provide-same-password")
            );
        }
    },
    watch: {
        "$route.query": {
            immediate: true,
            handler(val) {
                let error = true;
                if (val) {
                    const { user_id, timestamp, signature } = val;
                    if (user_id && timestamp && signature) {
                        error = false;
                        Object.assign(this.formData, { user_id, timestamp, signature });
                    }
                }
                if (error) {
                    this.$ui.error(this.$t("forgot-password.error-email-link"));
                    this.$router.push({ name: "forgot-password" });
                }
            }
        }
    },
    methods: {
        async confirmForgotPassword() {
            if (this.$refs.confirmForgotPasswordForm.validate() && !this.loading) {
                try {
                    const requestBody = {
                        ...this.formData
                    };
                    if (this.twofaInput) {
                        requestBody.token = this.token;
                    }
                    this.loading = true;
                    await this.$store.dispatch("confirmForgotPassword", requestBody);
                    this.$ui.success(this.$t("ui.message.success.new-password-changed"));
                    this.$router.push({ name: "auth" });
                } catch (error) {
                    if (error && error.data === "2FA token required") {
                        this.twofaInput = true;
                    } else console.error({ error });
                } finally {
                    this.loading = false;
                }
            }
        }
    }
};
</script>

<style lang="scss">
.link-resend-password {
    font-size: 12px;
    text-decoration: underline;
    margin-right: 30px;
}
</style>
