import MyWallet from "@/components/views/user/myWallet/_MyWallet.vue";
import MoneyIn from "@/components/views/user/myWallet/wallet/moneyIn/_MoneyIn.vue";
import MoneyOut from "@/components/views/user/myWallet/wallet/moneyOut/_MoneyOut.vue";
import Bank from "@/components/views/user/myWallet/wallet/bank/_Bank.vue";

import multiguard from "vue-router-multiguard";
import { informUserIfNotRegistered, requiresCompanyToBeNotFromUSA } from "../routeGuards";
import { i18n } from "@/services/plugins/i18n.js";

export default [
    {
        beforeEnter: multiguard([requiresCompanyToBeNotFromUSA]),
        name: "wallet",
        path: "/wallet/:entity",
        component: MyWallet,
        meta: {
            contextFn: () => i18n.t("app.bar.dropdown-profile.title.wallet")
        },
        children: [
            {
                beforeEnter: multiguard([informUserIfNotRegistered]),
                path: "money-in/:id",
                name: "money-in",
                component: MoneyIn,
                props: true,
                meta: {
                    contextFn: () => i18n.t("app.bar.dropdown-profile.title.money-in")
                }
            },
            {
                beforeEnter: multiguard([informUserIfNotRegistered]),
                path: "money-out/:id",
                name: "money-out",
                component: MoneyOut,
                props: true,
                meta: {
                    contextFn: () => i18n.t("app.bar.dropdown-profile.title.money-out")
                }
            },
            {
                path: "bank/:id",
                name: "bank",
                component: Bank,
                props: true
            }
        ]
    }
];
