var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"end"}},[(_vm.$entity.isAffiliationAccountActive())?_c('v-col',{attrs:{"cols":"auto"}},[(_vm.showAdd)?_c('v-btn',{staticClass:"custom-btn custom-btn-blue",attrs:{"outlined":""},on:{"click":_vm.modalOpen}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.modalTitle)+" ")],1):_vm._e(),(_vm.showMoreActions)?_c('MoreActionsMenu',_vm._b({},'MoreActionsMenu',{
                    type: _vm.type,
                    idEntity: _vm.idEntity,
                    currentInfoEntity: _vm.currentInfoEntity
                },false)):_vm._e()],1):_c('v-col',{attrs:{"cols":"auto"}},[_c('v-alert',{attrs:{"dense":"","border":"left","type":"warning","prominent":""}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.$t("universe.kyoseil.actions-buttons.account-not-active"))+" ")])])],1)],1),_c('AddEntityModal',_vm._b({on:{"add-modal-close":function($event){return _vm.modalClose()},"add-modal-post":function($event){return _vm.modalPost()}}},'AddEntityModal',{
            isOpen: _vm.isModalOpen,
            type: _vm.type,
            title: _vm.modalTitle
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }