import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {},
        success: {},
        error: {},
        warning: {},
        info: {}
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {},
        success: {
            color: "successLight",
            textColor: "text",
            icon: "mdi-check"
        },
        error: {
            color: "errorLight",
            textColor: "text",
            icon: "mdi-close"
        },
        warning: {
            color: "warningLight",
            textColor: "text",
            icon: "mdi-camera-timer"
        },
        info: {
            color: "infoLight",
            textColor: "text",
            icon: "mdi-clipboard-file"
        }
    };
};
