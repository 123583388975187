import DEBUG from "@/store/debug.js";
import store from "@/store/store.js";

export default async ({ commit }) => {
    DEBUG("GET_MY_ROLES_START");
    try {
        const myEntity = store.getters.myEntity;
        const res = await window.app.$api.roleList({ user_id: myEntity.id });
        DEBUG("GET_MY_ROLES_SUCCESS");
        commit("SET_MY_ROLES", res.data);
    } catch (e) {
        DEBUG("GET_MY_ROLES_ERROR");
        window.app.$ui.error(e, "get_my_roles");
    }
};
