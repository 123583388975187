<template>
    <div :class="`eqs-btn-menu ${elClass}-container`" :el-class="elClass">
        <v-menu offset-y>
            <template #activator="{ on: otherEvents, attrs }">
                <EqsBtn
                    v-bind="{
                        ...attrs,
                        ...$attrs,
                        loading: loading || indexLoading >= 0,
                        otherEvents
                    }"
                >
                    <slot name="default" />
                </EqsBtn>
            </template>
            <v-list dense>
                <v-hover v-for="(item, index) in items" :key="index" v-slot="{ hover }">
                    <div class="ma-0 pa-0">
                        <v-list-item
                            :class="`${elClass}-menu-${index}`"
                            link
                            :color="item.color || $color.colors.eqsBlue"
                            active-class="eqs-dropdown-profile-drawer-active"
                            :style="
                                !hover && !!item.bgColor ? `background-color:${item.bgColor}` : ''
                            "
                            :input-value="hover"
                            :disabled="indexLoading >= 0"
                            @click="() => executeAction(item.fn, index, item)"
                        >
                            <v-list-item-icon v-if="item.icon" class="mr-2">
                                <v-icon
                                    v-if="index !== indexLoading"
                                    small
                                    :color="item.color || $color.colors.eqsBlue"
                                >
                                    {{ item.icon }}
                                </v-icon>
                                <v-progress-circular v-else indeterminate />
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">
                                    {{ item.txt }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-hover>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsBtnMenu.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import EqsBtn from "../../atoms/EqsBtn/EqsBtn.vue";

export default {
    name: "EqsBtnMenu",
    components: {
        EqsBtn
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-btn-menu"
        },
        items: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            indexLoading: -1
        };
    },
    methods: {
        async executeAction(action, index, item) {
            this.indexLoading = index;
            this.$emit("update:loading", true);
            try {
                if (action) await action();
                else this.$emit("selected-item", item);
            } catch (e) {
                this.$emit("error", e);
            } finally {
                this.$emit("update:loading", false);
                this.indexLoading = -1;
                this.$emit("action:finished");
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsBtnMenu.scss";
</style>
