import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {}
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            outer: {
                color: "#0e49a1",
                opacity: 0.9
            },
            center: {
                color: "#004eff",
                opacity: 0.8
            }
        },
        white: {
            outer: {
                color: "#EEEEEE",
                opacity: 0.9
            },
            center: {
                color: "#ffffff",
                opacity: 0.8
            }
        },
        blue: {
            outer: {
                color: "#0e49a1",
                opacity: 0.9
            },
            center: {
                color: "#004eff",
                opacity: 0.8
            }
        },
        red: {
            outer: {
                color: "#B71C1C",
                opacity: 0.9
            },
            center: {
                color: "#FF6B6B",
                opacity: 0.8
            }
        },
        green: {
            outer: {
                color: "#38CB70",
                opacity: 0.9
            },
            center: {
                color: "#38CB60",
                opacity: 0.8
            }
        },
        orange: {
            outer: {
                color: "#EF6C00",
                opacity: 0.9
            },
            center: {
                color: "#FB8005",
                opacity: 0.8
            }
        }
    };
};
