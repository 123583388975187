<template>
    <EqsStepper
        :value="0"
        v-bind="{
            steps,
            progress,
            loading: loading || ibanLoading || loadingUpdateIban || countriesLoading
        }"
    >
        <template #default="{ item, loading: isLoading }">
            <div class="eqs-main-container margin-auto">
                <div class="d-flex flex-column">
                    <v-row class="px-3">
                        <v-col :cols="$reactive.getCols('full')" class="eqs-form-field pa-0">
                            <CurrentBankAccount
                                v-show="ibanLoading || (!ibanLoading && iban)"
                                display
                                class="mb-3"
                                style="width: 100%"
                                v-bind="{ loading: isLoading }"
                                @pending="comeBackToWallet"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="error" class="px-3">
                        <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                            <EqsAlert
                                class="py-3"
                                el-class="bank-error"
                                v-bind="{
                                    eqsType: 'error',
                                    text: $t('user.wallet.bank.error'),
                                    loading: isLoading
                                }"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="px-3">
                        <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                            <EqsTextDisplay :value="$t('user.wallet.bank.account-info')" />
                            <EqsSwitch
                                :value.sync="isSepa"
                                class="max-height-fit"
                                el-class="is-sepa-switch"
                                :text="
                                    isSepa
                                        ? $t('user.wallet.bank.account-in-sepa')
                                        : $t('user.wallet.bank.account-international')
                                "
                                v-bind="{ loading: isLoading }"
                            />
                            <a :href="$t('user.wallet.bank.sepa-link-url')" target="_blank"
                                >{{ $t("user.wallet.bank.sepa-link") }}
                            </a>
                        </v-col>
                    </v-row>
                </div>
                <component
                    :is="item.is"
                    editable
                    v-bind="{
                        formData,
                        loading: isLoading,
                        countries,
                        schemaDouble
                    }"
                />
            </div>
        </template>
    </EqsStepper>
</template>

<script>
import CurrentBankAccount from "../CurrentBankAccount.vue";

import {
    formErrorMixin,
    formatFormDataMixin,
    progressMixin
} from "@equisafe-ui-vue/src/mixins/formMixin";

import { sepaDouble, internationalDouble, getState } from "@/util/bankSchema.js";
import { lemonwayStatusItem } from "@equisafe-ui-vue/src/globals/lemonway.js";
import SepaForm from "./SepaForm";
import InternationalForm from "./InternationalForm";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Bank",
    components: {
        CurrentBankAccount,
        SepaForm,
        InternationalForm
    },
    mixins: [formErrorMixin, progressMixin, formatFormDataMixin],
    props: {
        id: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            validForm: false,
            loadingUpdateIban: false,
            schema: [],
            isSepa: true,
            formData: {},
            success: false
        };
    },
    computed: {
        ...mapGetters(["ibanLoading", "iban", "countries", "countriesLoading"]),
        noAccount() {
            return !this.iban;
        },
        state() {
            return getState(this);
        },
        formTitle() {
            return this.noAccount
                ? this.$t("user.wallet.bank.link-my-account")
                : this.$t("user.wallet.bank.update-my-account");
        },
        steps() {
            return [
                {
                    title: this.formTitle,
                    item: {
                        is: this.isSepa ? "SepaForm" : "InternationalForm"
                    },
                    back: {
                        fn: () => this.$emit("close")
                    },
                    next: {
                        fn: this.linkBankAccount,
                        txt: this.noAccount
                            ? this.$t("user.wallet.bank.link-btn")
                            : this.$t("user.wallet.bank.replace-existing-btn")
                    }
                }
            ];
        }
    },
    watch: {
        isSepa: {
            immediate: true,
            handler(val) {
                this.schemaDouble = val ? sepaDouble : internationalDouble;
            }
        },
        iban: {
            immediate: true,
            handler(val) {
                if (val && val.pending) this.comeBackToWallet();
            }
        }
    },
    async beforeMount() {
        this.getCountries();
        this.getIban();
    },
    beforeDestroy() {
        this.$poll.stop("iban-status");
    },
    methods: {
        ...mapActions(["getIban", "getCountries"]),
        comeBackToWallet() {
            this.$ui.error(this.$t("user.wallet.bank.error-bank-account-pending"));
            this.$emit("close");
        },
        goBackPortfolio() {
            this.$router
                .push({
                    name: "wallet",
                    params: { entityId: this.$entity.represented().id }
                })
                .catch(() => {});
        },
        isValidated(doc) {
            const res = lemonwayStatusItem(this, doc.lemonway_status, doc.lemonway_reason);
            if (res.status === "success") {
                this.error = false;
                this.success = true;
                return true;
            }
            return false;
        },
        isError(doc) {
            const res = lemonwayStatusItem(this, doc.lemonway_status, doc.lemonway_reason);
            if (res.status === "error") {
                this.error = true;
                this.success = false;
                return true;
            }
            return false;
        },
        async pollIbanDocStatus(docIbanID, getDocument) {
            try {
                await this.$poll.asyncCall(
                    "iban-status",
                    getDocument,
                    docIbanID,
                    res => {
                        return this.isValidated(res.data) || this.isError(res.data);
                    },
                    2000,
                    150000
                );
            } catch (e) {
                console.error(e);
                throw e;
            } finally {
                // this.$forceUpdate();
            }
        },
        async linkBankAccount() {
            try {
                this.loadingUpdateIban = true;
                await this.$api[`createIBAN${this.isSepa ? "Sepa" : "Ext"}`](
                    this.formatFormData(this.schemaDouble)
                );
                if (!this.$dev.isProd()) {
                    await this.pollIbanDocStatus(this.formData.doc_bank.id, this.$api.getDocument);
                } else this.success = true;
                if (this.success) {
                    // update entity with doc_bank
                    await this.$role.updateMyCurrentRole(this.$entity.represented().id);
                    this.$nextTick(() => {
                        this.$dialog.info({
                            title: this.state.dialog.title,
                            text: this.state.dialog.message,
                            confirm: {
                                txt: this.$t("btn.continue"),
                                fn: this.goBackPortfolio
                            }
                        });
                    });
                }
            } catch (e) {
                this.handleError(e, "register_bank_account");
            } finally {
                this.loadingUpdateIban = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
