import { limitsValues } from "@/components/views/user/myWallet/wallet/transfers_limits.js";

export const limitsMoneyinDaily = value => {
    const limit = limitsValues.moneyinDailyMoney;
    return (
        !!value ||
        value <= limit ||
        window.app.$t("rules.limits-moneyin-daily", {
            limit: window.app.$currency.display(limit)
        })
    );
};

export const limitsMoneyoutDaily = value => {
    const limit = limitsValues.moneyoutDailyMoney;
    return (
        !!value ||
        value <= limit ||
        window.app.$t("rules.limits-moneyout-daily", {
            limit: window.app.$currency.display(limit)
        })
    );
};

export const companyIdcodeR = value =>
    /^[0-9A-Z]+$/.test(value) || window.app.$t("rules.company-idcode");

export const tvaCodeR = value => {
    return (!!value && value.length > 5) || window.app.$t("rules.invalid-format");
};
