var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsDialog',_vm._b({attrs:{"closable":"","persistent":""},on:{"close":() => (_vm.dialogProxy = false)},model:{value:(_vm.dialogProxy),callback:function ($$v) {_vm.dialogProxy=$$v},expression:"dialogProxy"}},'EqsDialog',{
        loading: _vm.loading || _vm.investorsLoading,
        cancel: {},
        progress: _vm.progress,
        confirm: !!_vm.inviteType
            ? _vm.inviteType.confirm
            : {
                  fn: () => (_vm.dialogProxy = false),
                  txt: _vm.$t('label.invite')
              },
        title: _vm.title
    },false),[_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsRadio',_vm._b({attrs:{"value":_vm.inviteType,"el-class":"invite-investor-invite-type"},on:{"update:value":function($event){_vm.inviteType=$event},"change":() => (_vm.formData = {})},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [_c('v-row',{staticClass:"d-flex flex-column",attrs:{"no-gutters":""}},[_c('EqsTextDisplay',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('EqsTextDisplay',{attrs:{"value":item.text}})],1)]}}])},'EqsRadio',{
                        loading: _vm.loading,
                        options: _vm.options,
                        label: _vm.$t('shareledger.white-list.invite.category-select.label')
                    },false))],1)],1),_c('v-expand-transition',[(_vm.inviteType)?_c(_vm.inviteType.is,_vm._b({tag:"component",attrs:{"editable":""}},'component',{
                    formData: _vm.formData,
                    schemaDouble: _vm.schemaDouble,
                    loading: _vm.loading,
                    uniqueEmailR: _vm.uniqueEmailR,
                    uniqueCompanyIdCodeR: _vm.uniqueCompanyIdCodeR
                },false)):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }