<template>
    <EqsCard
        class="fill-height"
        v-bind="{
            height: '100%',
            loading: loading || loadingInit
        }"
        v-on="$listeners"
    >
        <template #main>
            <EqsStepper
                :value.sync="step"
                closable
                v-bind="{
                    steps,
                    closeInfoTxt,
                    progress
                }"
                style="height: 100%"
                @close="backToSecondaryMarket"
            >
                <template #default="{ item }">
                    <component
                        :is="item.is"
                        :stepper-state="stepperState"
                        v-bind="{
                            loading: localLoading || proceduresLoading || balanceLoading,
                            schemaDouble,
                            balance,
                            signer,
                            formData,
                            getTotal,
                            getFees,
                            order,
                            documents: order.docs,
                            operationId
                        }"
                        @signatureRefused="signatureRefused"
                    />
                </template>
            </EqsStepper>
        </template>
    </EqsCard>
</template>

<script>
import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";
import { procedureSignerMixin } from "@/mixins/yousignMixin";
import { mapGetters } from "vuex";

import replace from "lodash/replace";

export default {
    name: "BuyStepper",
    components: {
        ConfirmPayment: () => import("./ConfirmPayment"),
        PurchaseAmount: () => import("./PurchaseAmount"),
        SignDocument: () => import("./SignDocument"),
        SuccessBuy: () => import("./SuccessBuy"),
        UploadDocuments: () => import("./UploadDocuments")
    },
    mixins: [stepperFormMixins, procedureSignerMixin],
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            closeInfoTxt: this.$t("secondary-market.buy-stepper.dialog-signatory.cancel-btn"),
            buyType: "",
            orderId: "",
            order: {},
            formData: {},
            localLoading: false,
            loadingInit: false,
            buyerFees: 0,
            schemaDouble: {},
            step: -1,
            yousignUrl: "",
            signatureId: "",
            stepperState: "success",
            isRecovery: false
        };
    },
    computed: {
        ...mapGetters(["balance", "balanceLoading"]),
        steps() {
            let steps = [];
            if (this.buyType == "secondaryMarket") {
                steps = [
                    {
                        title: this.$t("buyStepper.purchase-amount"),
                        item: {
                            is: "PurchaseAmount"
                        },
                        back: {
                            fn: this.backToSecondaryMarket,
                            txt: this.closeInfoTxt
                        },
                        next: {
                            fn: this.nextStep
                        },
                        schemaDouble: {
                            numberOfShares: {
                                dataType: "int",
                                isRequired: true
                            }
                        }
                    },
                    {
                        title: this.$t("buyStepper.sign-document"),
                        item: {
                            is: "SignDocument"
                        },
                        next: {
                            txt: this.$t("buyStepper.sign"),
                            fn: this.goToSignature
                        },
                        back: {
                            fn: this.backStep
                        },
                        noBack: this.isRecovery,

                        schemaDouble: {}
                    },
                    {
                        title: this.$t("buyStepper.sign"),
                        item: {
                            is: "GlobalYousignIFrame"
                        },
                        next: {
                            fn: this.nextStep
                        },
                        noBack: true,
                        noPadding: true,
                        schemaDouble: {
                            signature_done: {
                                dataType: "bool",
                                isRequired: true
                            }
                        }
                    },
                    {
                        title: this.$t("buyStepper.finalize"),
                        item: {
                            is: "SuccessBuy"
                        },
                        noBack: true,
                        schemaDouble: {},
                        next: {
                            fn: () => {
                                this.$router
                                    .push({
                                        name: "secondary-market",
                                        params: { id: this.$entity.represented().id },
                                        query: { tab: "buys" }
                                    })
                                    .catch(() => {});
                            },
                            txt:
                                this.stepperState == "error"
                                    ? this.$t("buyStepper.finalize-txt-btn-error")
                                    : this.$t("buyStepper.finalize-txt-btn")
                        }
                    }
                ];
                if (!!this.order && this.order.docs && this.order.docs.length > 0) {
                    const docStep = {
                        title: this.$t("buyStepper.upload-document"),
                        item: {
                            is: "UploadDocuments"
                        },
                        next: {
                            fn: this.nextStep
                        },
                        back: {
                            fn: this.backStep
                        },
                        schemaDouble: this.getSchemaDoubleForDoc(this.order.docs),
                        noForm:
                            this.order.docs &&
                            this.order.docs.filter(doc => doc.required).length == 0
                    };
                    steps.splice(1, 0, docStep);
                }
            }
            return steps;
        }
    },
    watch: {
        step: {
            immediate: true,
            handler(val) {
                if (val < 0) return;
                let step = this.steps[val];
                if (step) {
                    this.schemaDouble = step.schemaDouble;
                    if (this.schemaDouble) {
                        this.buildFormData(this.schemaDouble, this.oldFormData);
                    }
                }
            }
        }
    },
    async created() {
        this.loadingInit = true;
        this.getBalance();
        this.checkEntityCanSign({
            fn: this.backToSecondaryMarket,
            txt: this.closeInfoTxt
        });
        this.buyType = this.$route.params.buyType;
        this.orderId = this.$route.params.orderId;
        if (this.$route.params.state == "recovery" && this.$route.params.quantity > 0)
            this.setRecoveryOrder();
        // this.initSteps();
        try {
            await this.getOrder();
            await this.getOperation();
            await this.getBuyerFees();
        } catch (e) {
            this.loadingInit = false;
            this.$ui.error(e, "secondary_market_fetch_sell_offers");
            this.catchError();
        }
        this.getSteps();
        if (!!this.order && this.order.docs && this.order.docs.length > 0)
            this.step = !!this.isRecovery ? 2 : 0;
        else this.step = !!this.isRecovery ? 1 : 0;
        this.loadingInit = false;
    },
    methods: {
        backToSecondaryMarket() {
            this.$router.push({
                name: "secondary-market",
                params: this.$route.$params
            });
        },
        setRecoveryOrder() {
            this.isRecovery = true;
            this.formData.numberOfShares = this.$route.params.quantity;
            this.signatureId = this.$route.params.signatureId;
        },
        // initSteps() {
        //   //Init steppers with last step in case there is an error on created
        //   this.steps = [
        //     {
        //       title: this.$t("buyStepper.finalize"),
        //       item: {
        //         is: "SuccessBuy",
        //         props: {
        //           getTotal: this.getTotal,
        //           order: this.order,
        //         },
        //       },
        //       noBack: true,
        //       schemaDouble: {},
        //       next: {
        //         fn: () => {
        //           this.$router
        //             .push({
        //               name: "secondary-market",
        //               params: { id: this.$entity.represented().id },
        //               query: { tab: "buys" },
        //             })
        //             .catch(() => {});
        //         },
        //         txt:
        //           this.stepperState == "error"
        //             ? this.$t("buyStepper.finalize-txt-btn-error")
        //             : this.$t("buyStepper.finalize-txt-btn"),
        //       },
        //     },
        //   ];
        // },
        getSteps() {},
        getSchemaDoubleForDoc(docs) {
            let schemaDouble = {};
            docs.forEach(doc => {
                schemaDouble[doc.type] = {
                    dataType: "file",
                    isRequired: doc.required
                };
            });
            return schemaDouble;
        },
        nextStep() {
            this.step += 1;
        },
        backStep() {
            this.oldFormData = this.formData;
            this.step -= 1;
        },
        async goToSignature() {
            try {
                this.localLoading = true;
                await this.buy();
                this.localLoading = false;
                this.nextStep();
            } catch (e) {
                this.localLoading = false;
                this.catchError();
            }
        },
        signatureRefused() {
            this.stepperState = "warning";
            this.nextStep();
        },
        async buy() {
            if (!!this.isRecovery) {
                this.yousignUrl = this.$route.params.yousignUrl;
                this.signatureId = this.$route.params.signatureId;
                return;
            }
            let url = replace(this.order.url, "market", "secondaryMarketSellOrder");
            try {
                let data = {
                    sellOrderId: this.orderId,
                    sellOrder: url,
                    quantity: this.formData.numberOfShares,
                    buyer: this.$entity.represented().url,
                    operation: this.operationId
                };
                const result = await this.$api.createAssetMovementBuyOrder(data);
                if (result.error) {
                    this.$ui.error(error);
                } else {
                    this.yousignUrl = result.data.url;
                    this.signatureId = result.data.signature_id;
                }
            } catch (e) {
                console.error(e, "secondary_market_buy");
                throw e;
            }
        },
        async getOperation() {
            if (this.isRecovery) {
                this.operationId = this.$route.params.operation;
                return;
            }
            try {
                const res = await this.$api.createOperation({
                    status: "not_used",
                    type: "buy_order"
                });

                this.operationId = res.data.url;
            } catch (e) {
                throw e;
            }
        },
        async getOrder() {
            try {
                const res = await this.$api.readMarketOrder(this.orderId);
                this.order = res.data;
            } catch (e) {
                throw e;
            }
        },
        getTotal() {
            const total = this.formData.numberOfShares * this.order.price + this.getFees();
            return total || 0;
        },
        getFees() {
            return this.formData.numberOfShares * this.order.price * this.buyerFees;
        },
        async getBuyerFees() {
            try {
                const res = await this.$api.getBuyerFeesSecondaryMarket();
                this.buyerFees = res.data.buyer_fees_rate;
            } catch (e) {
                throw e;
            }
        },
        catchError() {
            this.stepperState = "error";
            this.gotToLastStep();
        },
        gotToLastStep() {
            this.step = this.steps.length - 1;
        }
    }
};
</script>
