var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('EqsTabs',_vm._b({attrs:{"value":_vm.tab},on:{"update:value":function($event){_vm.tab=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('EqsDataTable',_vm._b({attrs:{"items":_vm.values[item.is],"add":{
                    txt: _vm.$t('shareledger.valuation.new-valuation'),
                    fn: () => {
                        _vm.openDialog('create', item);
                    }
                }},on:{"update:items":function($event){return _vm.$set(_vm.values, item.is, $event)}},scopedSlots:_vm._u([{key:"item.date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$time.formated(item.value_datetime))+" ")]}},{key:"item.comment",fn:function({ item }){return [_vm._v(" "+_vm._s(item.comment)+" ")]}},{key:"item.value",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency(item.value_data, item.value_data_currency))+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('EqsActions',_vm._b({},'EqsActions',{ actions: _vm.getActions(item) },false))]}}],null,true)},'EqsDataTable',{
                    headers: _vm.setHeaders(item)
                },false))]}}])},'EqsTabs',{
            tabs: _vm.tabs
        },false)),_c('EqsDialog',_vm._b({attrs:{"persistent":"","value":_vm.displayDialog.open},on:{"update:value":function($event){return _vm.$set(_vm.displayDialog, "open", $event)}}},'EqsDialog',{
            ..._vm.setDialogConfig(_vm.itemSelected),
            confirm: {
                fn: async () => _vm.getCRUDfunction(_vm.itemSelected)
            },
            cancel: {
                fn: () => _vm.closeDialog()
            }
        },false),[_c('EqsDate',{staticClass:"mb-2",attrs:{"id":"dialogInputData","value":_vm.newData.value_datetime,"label":"Date","editable":!_vm.displayDialog.delete},on:{"update:value":function($event){return _vm.$set(_vm.newData, "value_datetime", $event)}}}),_c('EqsText',{staticClass:"mb-2",attrs:{"id":"dialogInputComment","label":_vm.$t('shareledger.valuation.comment'),"value":_vm.newData.comment,"is-required":false,"editable":!_vm.displayDialog.delete},on:{"update:value":function($event){return _vm.$set(_vm.newData, "comment", $event)}}}),_c('EqsNumber',{staticClass:"mb-2",attrs:{"id":"dialogInputValue","value":_vm.newData.value_data,"label":_vm.itemSelected.is == 'nav'
                    ? _vm.$t('shareledger.valuation.net-asset-value')
                    : _vm.$t('shareledger.valuation.market-value'),"currency-type":_vm.currency,"editable":!_vm.displayDialog.delete},on:{"update:value":function($event){return _vm.$set(_vm.newData, "value_data", $event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }