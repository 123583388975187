import { i18n } from "@/services/plugins/i18n.js";

const getPresenceText = presence => {
    switch (presence) {
        case "present":
            return "Vous êtes présent";
        case "absent":
            return "Vous êtes absent";
        case "represented":
            return "Vous êtes représenté";
        case "waiting":
        default:
            return "Envoyer votre formulaire de présence";
    }
};

const getOrganText = organ => {
    switch (organ) {
        case "administration_board":
            return "Conseil d'Administration";
        case "supervisory_board":
            return "Conseil de Surveillance";
        case "general_assembly":
        default:
            return "Assemblée Générale";
    }
};

const getModeText = mode => {
    switch (mode) {
        case "ordinaire":
            return "Ordinaire";
        case "extraordinaire":
            return "Extraordinaire";
        case "dua":
            return "Decision unanime des associes";
        case "Consultation ecrite":
        default:
            return "Consultation ecrite";
    }
};

const isToday = someDate => {
    const today = new Date();
    return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
    );
};

const diffDays = (date1, date2) => {
    /*
        Convert to UTC first, keep date not time
    */
    const date1Utc = Date.UTC(
        date1.getUTCFullYear(),
        date1.getUTCMonth(),
        date1.getUTCDate(),
        0,
        0,
        0
    );
    const date2Utc = Date.UTC(
        date2.getUTCFullYear(),
        date2.getUTCMonth(),
        date2.getUTCDate(),
        0,
        0,
        0
    );
    const diffDate = date1Utc - date2Utc;
    return diffDate / (24 * 3600 * 1000);
};

const timelineStateFromAgState = (timelineState, agState, agDate, presence) => {
    const tmpAgDate = new Date(agDate);
    switch (timelineState) {
        case "convoke":
            return "done";
        case "presence":
            if (presence === "waiting") return "waiting";
            else return "done";
        case "waiting":
            if (isToday(tmpAgDate) && agState === "done") return "done";
            else if (isToday(tmpAgDate) && agState !== "done") return "waiting";
            else return "todo";
        case "done":
            if (agState === "done") return "done";
            else return "todo";
        default:
            return "waiting";
    }
};

const waitingText = agDate => {
    const tmpAgDate = new Date(agDate);
    let daysLeft = diffDays(new Date(), tmpAgDate);
    if (daysLeft > 0) return `L'ag aura lieu dans ${daysLeft} jour${daysLeft === 1 ? "" : "s"}`;
    else if (daysLeft < 0) return "L'ag est terminée";
    else return "C'est aujourd'hui !";
};

export {
    isToday,
    diffDays,
    waitingText,
    timelineStateFromAgState,
    getOrganText,
    getModeText,
    getPresenceText
};
