var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-dropdown-profile",attrs:{"id":"drop-down-menu"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","origin":"top bottom","content-class":`${_vm.elClass}-menu`,"transition":!_vm.opened ? 'scroll-y-reverse-transition' : 'scroll-y-transition',"rounded":"","close-on-content-click":false,"max-height":_vm.isMobile ? '80vh' : '60vh',"min-width":_vm.isMobile ? '100vw' : '',"disabled":_vm.loading,"width":"100%","value":_vm.opened},on:{"input":val => {
                _vm.opened = val;
                if (val) _vm.openRoles = true;
            }},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card',_vm._g({class:`eqs-dropdown-profile__container d-flex flex-row ${_vm.elClass}-activator-btn`,attrs:{"flat":"","color":_vm.$color.colors.menuColor}},on),[_c('v-col',{staticClass:"d-flex pa-0",attrs:{"align":"center","align-self":"center"}},[_c('EqsAvatar',_vm._b({staticClass:"pl-2"},'EqsAvatar',{ user: _vm.user, loading: _vm.loading },false)),_c('v-scroll-x-transition',[(!_vm.isMobile)?_c('div',{staticClass:"d-flex align-center justify-center pl-2"},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{ value: _vm.user.name, loading: _vm.loading },false))],1):_vm._e()]),_c('v-scroll-x-transition',[(!_vm.loading)?_c('div',{staticClass:"eqs-dropdown-profile__container__menu-icon",staticStyle:{"align-self":"center"}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.opened ? "mdi-menu-up" : "mdi-menu-down")+" ")])],1):_vm._e()])],1)],1)]}}])},[_c('v-list',{staticClass:"eqs-dropdown-profile__list",attrs:{"dense":""}},_vm._l((_vm.items),function(item,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"ma-0 pa-0"},[(!item.roles)?_c('v-list-item',{class:`${item.value}-menu`,style:(!item.active && !hover && !!item.bgColor
                                ? `background-color:${item.bgColor}`
                                : ''),attrs:{"link":"","color":item.color || _vm.$color.colors.eqsBlue,"active-class":"eqs-dropdown-profile-drawer-active","input-value":!item.subheader && hover,"to":item.to},on:{"click":() => {
                                if (item.action) item.action();
                                _vm.opened = false;
                            }}},[(item.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":item.active || hover
                                        ? item.color || _vm.$color.colors.eqsBlue
                                        : _vm.$color.colors.lightGrey}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1):_c('v-list-group',{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(item.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":item.active || hover
                                            ? item.color || _vm.$color.colors.eqsBlue
                                            : _vm.$color.colors.lightGrey}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-capitalize font-weight-bold"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]},proxy:true}],null,true),model:{value:(_vm.openRoles),callback:function ($$v) {_vm.openRoles=$$v},expression:"openRoles"}},_vm._l((item.roles.filter(
                                role => role.id !== _vm.user.id
                            )),function(role,subIndex){return _c('v-hover',{key:subIndex,scopedSlots:_vm._u([{key:"default",fn:function({ hover: subHover }){return [_c('div',{staticClass:"ma-0 pa-0"},[_c('v-list-item',{class:`px-5 ${role.name}-menu`,style:(!role.active && !subHover && !!role.bgColor
                                            ? `background-color:${role.bgColor}`
                                            : ''),attrs:{"link":"","two-line":"","color":role.color || _vm.$color.colors.eqsBlue,"active-class":"eqs-dropdown-profile-drawer-active","input-value":!role.subheader && subHover},on:{"click":() => {
                                            role.action();
                                            _vm.opened = false;
                                        }}},[_c('EqsAvatar',_vm._b({staticClass:"mr-5"},'EqsAvatar',{ user: role, eqsType: 'list' },false)),_c('v-list-item-content',[(role.name)?_c('v-list-item-title',{staticClass:"font-weight-bold text-capitalize ml-2"},[_vm._v(" "+_vm._s(role.name)+" ")]):_vm._e(),_c('v-list-item-subtitle',[_c('EqsChip',{attrs:{"small":"","color":role.color,"outlined":""}},[_vm._v(" "+_vm._s(role.role)+" ")])],1)],1)],1)],1)]}}],null,true)})}),1)],1)]}}],null,true)})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }