import { getVal } from "../../../services/reactive";

const common_vars = currentSize => {
    return {
        "min-width": getVal(currentSize, {
            mobile: "120px",
            tablet: "140px",
            desktop: "250px"
        })
    };
};

export const cssTypes = (currentSize = {}) => {
    return {
        default: common_vars(currentSize),
        "two-line": common_vars(currentSize),
        "avatar-two-line": common_vars(currentSize),
        "three-line": common_vars(currentSize),
        "avatar-three-line": common_vars(currentSize)
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {
            skeleton: "list-item",
            skeletonNoData: "list-item-two-line"
        },
        "two-line": {
            skeleton: "list-item-two-line",
            skeletonNoData: "list-item-two-line"
        },
        "avatar-two-line": {
            skeleton: "list-item-avatar-two-line",
            skeletonNoData: "list-item-avatar-two-line"
        },
        "three-line": {
            skeleton: "list-item-three-line",
            skeletonNoData: "list-item-three-line"
        },
        "avatar-three-line": {
            skeleton: "list-item-avatar-three-line",
            skeletonNoData: "list-item-avatar-three-line"
        }
    };
};
