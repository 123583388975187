<template>
    <v-container fluid class="ma-0 pa-0">
        <div class="status-title-icon mb-0">
            <div class="ma-0 pa-0 mr-4 icon-bg colored-bg">
                <v-icon :color="statusIconColor" class="custom-left-icon" size="32">
                    {{ statusIcon }}
                </v-icon>
            </div>
            <span class="text-h4" style="color: #17375f">{{ statusTitle }}</span>
        </div>
        <v-row align="start" justify="space-between">
            <v-col md="7" cols="12">
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <component
                                :is="componentStatus"
                                v-if="componentStatus"
                                :order="order"
                                :capinc="order.capinc"
                                :status="statusWallet"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="showSendPeapmeDocs">
                        <v-col>
                            <p>
                                <v-icon color="warning">mdi-alert-box</v-icon>
                                {{ $t("user.buy.steps.status.warn-peadocs") }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-alert border="left" outlined text type="info">
                                <span style="color: black">
                                    {{ $t("user.buy.steps.status.delay-info") }}
                                </span>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col md="5" cols="12">
                <div class="my-4">
                    <SubscriptionCard :order="order" size="little" show-details />
                </div>
            </v-col>
        </v-row>
        <v-row v-if="showSendPeapmeDocs">
            <v-col>
                <SendBankPeapme :order="order" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { statutes } from "@/util/payment.js";

import SubscriptionCard from "../components/SubscriptionCard";
import SendBankPeapme from "../components/SendBankPeapme";

const CryptoStatus = () => import("./CryptoStatus");
const SepaStatus = () => import("./SepaStatus");
const WalletStatus = () => import("./WalletStatus");
const WiretransfertStatus = () => import("./WiretransfertStatus");
const SepaAutoStatus = () => import("./SepaAutoStatus");

import { getSelectedPayment } from "../util";

export default {
    name: "OrderStatus",
    component: [CryptoStatus, WalletStatus, SepaStatus, WiretransfertStatus, SepaAutoStatus],
    components: { SubscriptionCard, SendBankPeapme },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            componentStatus: null,
            statusTitle: "",
            statusIcon: "",
            statusIconColor: ""
        };
    },
    computed: {
        showSendPeapmeDocs() {
            let envelope;
            if (this.order.envelope) {
                envelope = this.order.envelope.choice;
            } else {
                envelope = null;
            }
            const selectedPayment = getSelectedPayment(this.order);
            return (
                ["pea", "pea-pme"].includes(envelope) &&
                ["walletdata", "wiretransfertdata"].includes(selectedPayment.name)
            );
        },
        statusWallet() {
            return this.order && this.order.status
                ? statutes[this.order.status]
                : statutes["pending"];
        }
    },
    beforeMount() {
        this.order.capinc.custodian.name = this.order.capinc.custodian.name
            .split(" ")
            .map(s => s[0].toUpperCase() + s.slice(1))
            .join(" ");
        this.selectComponentStatus();
    },
    methods: {
        selectComponentStatus() {
            const selectedPayment = getSelectedPayment(this.order);
            if (!selectedPayment) return;
            switch (selectedPayment.name) {
                case "cryptodata":
                    this.componentStatus = CryptoStatus;
                    this.statusTitle = this.$t("user.buy.order-status");
                    this.statusIcon = "mdi-note-text-outline";
                    this.statusIconColor =
                        this.statusWallet.color === "info" ? "black" : this.statusWallet.color;
                    break;
                case "sepadata":
                    this.componentStatus = SepaStatus;
                    this.statusTitle = this.$t("user.buy.steps.status.sepa.title");
                    this.statusIcon = "mdi-camera-timer";
                    this.statusIconColor = "black";
                    break;
                case "sepaautodata":
                    this.componentStatus = SepaAutoStatus;
                    this.statusTitle = this.$t("user.buy.steps.status.sepa.title");
                    this.statusIcon = "mdi-camera-timer";
                    this.statusIconColor = "black";
                    break;
                case "wiretransfertdata":
                    this.componentStatus = WiretransfertStatus;
                    this.statusTitle = this.$t("user.buy.steps.status.wiretransfert.title");
                    this.statusIcon = "mdi-camera-timer";
                    this.statusIconColor = "black";
                    break;
                case "walletdata":
                    this.componentStatus = WalletStatus;
                    this.statusTitle = this.$t("user.buy.order-status");
                    this.statusIcon = "mdi-note-text-outline";
                    this.statusIconColor =
                        this.statusWallet.color === "info" ? "black" : this.statusWallet.color;
                    break;
                default:
                    this.componentStatus = null;
                    break;
            }
        }
    }
};
</script>

<style lang="scss">
.status-title-icon {
    > span {
        vertical-align: middle;
    }

    .custom-left-icon {
        margin: 10px 0 0 10px;
    }

    .icon-bg {
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 50px;
        margin-right: 8px;
        border-radius: 50%;
    }

    .colored-bg {
        background-color: #fef0c7;
    }
}

.status-custom-table {
    max-width: 90% !important;
    margin: 2rem auto;
    text-align: center;
    th,
    td {
        // text-align: left;
        padding: 10px 6px;
        font-weight: bolder;
        font-size: 1rem;
    }

    tr > td:nth-child(odd) {
        min-width: 60%;
    }
}
</style>
