var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{class:`eqs-crud-action-btns ${_vm.elClass}-crud-action-btns`,attrs:{"no-gutters":"","justify":"end","align":"center"}},[(!_vm.editableProxy)?_c('EqsBtn',_vm._b({staticClass:"mx-2",attrs:{"outlined":""}},'EqsBtn',{
            color: 'primary',
            loading: _vm.loading,
            'el-class': `${_vm.elClass}-edit`,
            action: () => {
                _vm.editableProxy = true;
                _vm.$emit('edit');
            }
        },false),[_vm._v(" "+_vm._s(_vm.$t("btn.edit"))+" ")]):_c('v-row',{attrs:{"no-gutters":"","justify":"end","align":"center"}},[_c('EqsBtn',_vm._b({staticClass:"mx-2",attrs:{"outlined":""},on:{"update:loading":val => (_vm.localLoading = val),"action:finished":_vm.reset}},'EqsBtn',{
                color: 'primary',
                loading: _vm.loading || _vm.localLoading,
                'el-class': `${_vm.elClass}-cancel`,
                action: _vm.cancel
            },false),[_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]),_c('EqsBtn',_vm._b({staticClass:"mx-2",on:{"update:loading":val => (_vm.localLoading = val),"action:finished":_vm.reset}},'EqsBtn',{
                color: 'success',
                loading: _vm.loading || _vm.localLoading,
                'el-class': `${_vm.elClass}-save`,
                action: _vm.save,
                disabled: !_vm.hasChanged || !_vm.validForm
            },false),[_vm._v(" "+_vm._s(_vm.$t("btn.save"))+" ")])],1),(_vm.deleteFunc && !_vm.editable)?_c('EqsBtn',_vm._b({staticClass:"mx-2",on:{"action:finished":_vm.reset}},'EqsBtn',{
            color: 'error',
            loading: _vm.loading,
            'el-class': `${_vm.elClass}-delete`,
            action: _vm.deleteFunc
        },false),[_vm._v(" "+_vm._s(_vm.$t("btn.delete"))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }