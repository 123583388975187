import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit, state }) => {
    DEBUG("GET_DECISIONS_START");
    if (state.decisionsInit && !state.decisionsLoading) {
        try {
            commit("SET_DECISIONS_LOADING", true);
            const decisionsRes = await window.app.$api.getDecisions(
                store.getters.currentRole.entity_represented.id
            );
            const decisions = decisionsRes.data;
            DEBUG("GET_DECISIONS_SUCCESS");
            commit("SET_DECISIONS", decisions);
        } catch (e) {
            DEBUG("GET_DECISIONS_ERROR");
            throw e;
        } finally {
            commit("SET_DECISIONS_LOADING", false);
        }
    } else {
        DEBUG("GET_DECISIONS_BYPASSED");
    }
};
