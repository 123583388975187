<template>
    <div v-if="!loading" class="d-flex flex-column align-center justify-center ma-2">
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsNumber
                    :label="$t('payment.label-amount')"
                    el-class="money-in-amount"
                    :rules="[greaterThanZeroR, limitsMoneyinDaily]"
                    :value.sync="formData.moneyVal"
                    v-bind="{ loading }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsTextDisplay :value="$t('payment.info')" v-bind="{ loading }" />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <PaymentOptions v-bind="{ order, formData, options, loading }" />
            </v-col>
        </v-row>
        <v-row v-if="totalVal" class="px-3">
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field d-flex flex-column">
                <EqsTextDisplay eqs-type="subtitle" v-bind="{ loading }">
                    {{ $t("payment.total") }}
                </EqsTextDisplay>
                <EqsChip
                    color="primary"
                    text-color="white"
                    class="pt-2 font-weight-bold"
                    el-class="total-amount"
                    v-bind="{ loading }"
                >
                    {{ $currency.display(totalVal) }}
                </EqsChip>
            </v-col>
        </v-row>
    </div>
    <div v-else class="d-flex flex-column align-center justify-center mt-12">
        <v-progress-circular indeterminate color="primary" class="mb-8" />
        {{ $t("payment.rib-loading") }}
    </div>
</template>

<script>
import PaymentOptions from "./PaymentOptions";

import { greaterThanZeroR } from "@equisafe-ui-vue/src/rules";
import { limitsMoneyinDaily } from "@/rules";

export default {
    name: "PurchaseAmount",
    components: {
        PaymentOptions
    },
    props: {
        formData: {
            type: Object,
            required: true
        },
        order: {
            type: Object,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        totalVal: {
            type: Number,
            default: 0
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            greaterThanZeroR,
            limitsMoneyinDaily
        };
    }
};
</script>
