<template>
    <EqsCard
        v-bind="{
            width: '100%',
            progress: editable ? progress : 0,
            success: hasChanged,
            validForm,
            eqsType: 'container'
        }"
    >
        <div class="d-flex flex-column mb-6">
            <v-row class="px-0">
                <v-col :cols="$reactive.getCols('full')" class="eqs-form-field custom-bigger-title">
                    <EqsTextDisplay
                        v-bind="{
                            value: subtitle,
                            eqsType: 'subtitle'
                        }"
                    />
                </v-col>
            </v-row>
            <v-form v-model="validForm" class="pl-6 ma-0">
                <slot
                    name="content"
                    v-bind="{
                        schemaDouble,
                        isAffiliationInvestor: content.isAffiliationInvestor,
                        editable,
                        formData,
                        loading: loading || localLoading,
                        showPea: content.showPea,
                        showPeapme: content.showPeapme
                    }"
                />
            </v-form>
            <EqsCrudActionBtns
                v-if="entity.lemonway_state && isEditable"
                class="mt-2"
                :editable.sync="editable"
                :has-changed.sync="hasChanged"
                v-bind="{ validForm, cancel, save }"
            />
        </div>
    </EqsCard>
</template>

<script>
import { formKycMixin } from "@/mixins/kycMixin";
import {
    formErrorMixin,
    progressMixin,
    formChangedMixin
} from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "Content",
    mixins: [formKycMixin, formErrorMixin, progressMixin, formChangedMixin],
    props: {
        entity: {
            type: Object,
            required: true
        },
        content: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        isEditable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editable: false,
            validForm: false,
            formData: {},
            subtitle: "",
            localLoading: false,
            hasChanged: true
        };
    },
    watch: {
        entity: {
            immediate: true,
            handler(val) {
                this.formData = {};
                this.buildFormData(this.schemaDouble, val);
                this.saveFormData();
            }
        },
        content: {
            immediate: true,
            handler(val) {
                const { schemaDouble, subtitle } = val;
                this.schemaDouble = schemaDouble;
                this.subtitle = subtitle;
                this.formData = {};
                this.buildFormData(this.schemaDouble, this.entity);
                this.saveFormData();
            }
        }
    },
    methods: {
        cancel() {
            this.resetFormData();
        },
        async save() {
            try {
                this.localLoading = true;
                await this.$entity.updateEntity(this.formatFormData(this.formData));
                this.saveFormData();
            } catch (e) {
                this.handleError(e, "entity_update_individual");
                this.editable = true;
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss">
.custom-bigger-title .text-subtitle {
    font-size: 1.4rem !important;
}
</style>
