<template>
    <v-lazy>
        <v-container fluid class="align-start pa-0 ma-0">
            <v-row class="ma-0 pa-0" dense>
                <v-col>
                    <DataTablePaginated
                        :loading="loading || localLoading"
                        :headers="headers"
                        :items="orders"
                        sort-desc
                        sort-by="created_at"
                        show-row-pointer
                        :filter="orderFilter"
                        @update="fetchOrders"
                        @click-row="selectOrder"
                    >
                        <template #[`item.created_at`]="{ item }">
                            {{ item.date }}
                        </template>
                        <template #[`item.buyer_name`]="{ item }">
                            <div class="d-flex justify-start align-center">
                                <EqsAvatar
                                    class="pr-4"
                                    v-bind="{
                                        noBadge: true,
                                        user: item.buyer
                                    }"
                                />
                                {{ item.buyer?.name }}
                            </div>
                        </template>
                        <template #[`item.numberofshares`]="{ item }">
                            {{ $currency.display(item.numberofshares, "0,0") }}
                        </template>
                        <template #[`item.total_price`]="{ item }">
                            <span :el-class="`subscriber-${item.id}-total-amount`">
                                {{ $currency.display(item.total_price) }}
                            </span>
                        </template>
                        <template #[`item.payment_mode_txt`]="{ item }">
                            {{ item.payment_mode_txt }}
                        </template>
                        <template #[`item.status`]="{ item }">
                            <EqsChip :eqs-type="statutes[item.status].eqsType">
                                {{ statutes[item.status].chip }}
                            </EqsChip>
                        </template>
                        <template #[`item.actions`]="{ item, index }">
                            <EqsActions
                                v-bind="{
                                    elClass: `capinc-actions-${index}`,
                                    actions: getActions(item)
                                }"
                            />
                        </template>
                    </DataTablePaginated>
                </v-col>
            </v-row>
            <OrderModal
                v-if="selectedOrder"
                :loading="loading || localLoading"
                :open.sync="orderModalOpen"
                :order="selectedOrder"
                @closeDialog="closeDialog"
            />
        </v-container>
    </v-lazy>
</template>

<script>
import DataTablePaginated from "@/components/views/DataTablePaginated";
import { getPaymentLabelFromId, getSelectedPayment } from "@/components/views/user/buy/steps/util";
import { statutes, getPaymentModeTxt } from "@/util/payment";
import OrderModal from "@/components/views/user/investorsDashboard/OrderModal";

export default {
    name: "CiSubscribers",
    components: { DataTablePaginated, OrderModal },
    props: {
        capitalIncrease: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedOrder: null,
            orderModalOpen: false,
            localLoading: false,
            orders: {},
            statutes,
            queriesCache: { sort_by: "created_at", sort_desc: true }
        };
    },
    computed: {
        headers() {
            let headers = [
                {
                    text: this.$t("universe.kyoseil.tab.date"),
                    value: "created_at",
                    sortable: true
                },
                {
                    text: this.$t("procedure.role.buyer"),
                    value: "buyer_name",
                    sortable: true
                },
                {
                    text: this.$t("capital-increase.admin.nb-shares"),
                    value: "numberofshares",
                    sortable: true
                },
                {
                    text: this.$t("capital-increase.admin.detail.value-price"),
                    value: "total_price",
                    sortable: true
                },
                {
                    text: this.$t("capital-increase.admin.detail.payment-mode"),
                    value: "payment_mode_txt",
                    sortable: false
                },
                {
                    text: this.$t("capital-increase.admin.detail.payment-status"),
                    value: "status",
                    sortable: true
                }
            ];
            if (this.capitalIncrease.phase === "fundraising") {
                headers.push({
                    text: this.$t("label.actions"),
                    align: "center",
                    value: "actions",
                    sortable: false
                });
            }
            return headers;
        },
        orderFilter() {
            const orderStatusList = [
                "pending",
                "pending_payment",
                "signed",
                "sequestered",
                "validated",
                "success",
                "refused",
                "cancel"
            ];
            const items = orderStatusList.map(e => {
                return { text: statutes[e].chip, value: e };
            });
            return { field: "status", items };
        }
    },
    methods: {
        getActions(item) {
            let actions = [];
            if (["sequestered"].includes(item.status)) {
                actions.push({
                    txt: this.$t("capital-increase.admin.detail.validate-subscriber-btn"),
                    icon: "mdi-check-circle-outline",
                    fn: () => this.validateSubscriber(item),
                    color: "green"
                });
            }
            const payment = getSelectedPayment(item);
            if (
                ["signed", "pending_payment"].includes(item.status) &&
                ["wiretransfertdata", "sepadata", "cryptodata"].includes(payment.name)
            ) {
                actions.push({
                    txt: this.$t("capital-increase.admin.detail.sequestre.validate"),
                    icon: "mdi-bank-check",
                    fn: () => this.sequestreSubscriber(item),
                    color: "primary"
                });
            }
            if (["reserved", "signed", "pending_payment", "sequestered"].includes(item.status)) {
                actions.push({
                    txt: this.$t("capital-increase.admin.detail.remove-subscriber-btn"),
                    icon: "mdi-close-circle-outline",
                    fn: () => this.refuseSubscriber(item),
                    color: "red"
                });
            }
            return actions;
        },
        selectOrder(item, row, e) {
            this.selectedOrder = item;
            this.orderModalOpen = true;
        },
        closeDialog() {
            this.orderModalOpen = false;
            this.selectedOrder = null;
        },
        async sequestreSubscriber(order) {
            try {
                await this.$api.sequestreCapincOrder(order.id, this.capitalIncrease.id);
                this.fetchOrders(this.queriesCache);
                this.$ui.success(this.$t("capital-increase.admin.detail.sequestre.success"));
            } catch (e) {
                this.$ui.error(this.$t("capital-increase.admin.detail.sequestre.error"));
            }
        },
        async refuseSubscriber(order) {
            try {
                await this.$api.refuseCapincOrder(order.id, this.capitalIncrease.id);
                this.fetchOrders(this.queriesCache);
                this.$ui.success(
                    this.$t("capital-increase.admin.detail.remove-subscirber.success")
                );
                // this.changedOrders = true;
            } catch (e) {
                this.$ui.error(e, "capital_increase_refuse_subscriber");
            }
        },
        async validateSubscriber(order) {
            try {
                await this.$api.validateCapincOrder(order.id, this.capitalIncrease.id);
                this.fetchOrders(this.queriesCache);
                this.$ui.success(
                    this.$t("capital-increase.admin.detail.validate-subscirber.success")
                );
            } catch (e) {
                this.$ui.error(e, "capital_increase_accept_subscriber");
            }
        },
        async fetchOrders(queries) {
            this.queriesCache = { ...queries };
            try {
                this.localLoading = true;
                const res = await this.$api.getAdminOrders({
                    capinc_id: this.capitalIncrease.id,
                    ...queries
                });
                this.orders = res.data;
                if (this.orders.results) {
                    for (let order of this.orders.results) {
                        order.date = new Date(order.created_at).toLocaleDateString();
                        order.date_full = new Date(order.created_at).valueOf();
                        const payment = getSelectedPayment(order);
                        order.payment_mode_txt =
                            payment === null ? "-" : getPaymentLabelFromId(this, payment.name);
                    }
                }
            } catch (e) {
                this.$ui.error(e, "capital_increase_admin_get_orders");
                throw e;
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .doc-signed-btn {
    img {
        height: inherit !important;
    }
}
</style>
