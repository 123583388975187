<template>
    <v-container fluid class="eqs-choose-action fill-height fill-width ma-0 pa-0">
        <v-row class="px-3 d-flex fill-height d-flex justify-center align-center">
            <v-col
                v-for="(choice, i) in choices"
                :key="i"
                :cols="$reactive.getCols(ratio)"
                class="d-flex justify-center eqs-choose-action__item mb-3"
            >
                <EqsClickableCard
                    v-bind="{
                        ...choice,
                        elClass: `${elClass}-${i}`,
                        img: '',
                        width: '100%',
                        height: 'inherit',
                        disabled: choice.inProgress
                    }"
                >
                    <template #text>
                        <div
                            class="d-flex fill-height flex-column justify-start align-center"
                            style="width: 100%"
                        >
                            <div class="pa-3 d-flex flex-column">
                                <EqsChip v-if="choice.inProgress" class="py-3" eqs-type="warning">
                                    {{ $t("payments.in-progress") }}
                                </EqsChip>
                                <EqsTextDisplay
                                    class="eqs-choose-action__item__text text-no-wrap"
                                    paragraph
                                    :value="choice.text"
                                />
                            </div>
                            <div class="d-flex justify-center fill-width" style="height: inherit">
                                <img class="eqs-choose-action__item__img pa-3" :src="choice.img" />
                            </div>
                        </div>
                    </template>
                </EqsClickableCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsChooseAction.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsChooseAction",
    components: {
        EqsClickableCard: () => import("../../molecules/EqsClickableCard/EqsClickableCard.vue"),
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue"),
        EqsChip: () => import("../../atoms/EqsChip/EqsChip.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-choose-action"
        },
        choices: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        ratio() {
            const nItem = this.choices.length;
            const ratios = { 1: "full", 2: "half", 3: "third", 4: "fifth" };
            return ratios[nItem];
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsChooseAction.scss";
</style>
