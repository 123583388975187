var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCrud',_vm._b({attrs:{"items":_vm.docsProxy},on:{"update:items":function($event){_vm.docsProxy=$event}},scopedSlots:_vm._u([{key:"edit",fn:function(editProps){return [_c('Form',_vm._b({},'Form',{ ..._vm.$props, ...editProps },false))]}},{key:"create",fn:function(createProps){return [_c('Form',_vm._b({},'Form',{ ..._vm.$props, ...createProps },false))]}}])},'EqsCrud',{
        label: _vm.label,
        add: item => item,
        update: item => item,
        elClass: 'additional-docs',
        deleteFunc: _vm.deletable ? () => {} : null,
        formInit: _vm.formInit,
        loading: _vm.loading,
        editable: _vm.editable
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }