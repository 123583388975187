<template>
    <EqsCard v-bind="{ loading, ...$attrs }">
        <EqsDataTable
            class="fill-width"
            v-bind="{
                headers,
                items: representatives,
                ...$attrs,
                loading: representativesLoading
            }"
            sort-by="investor.entity.name"
            :sort-desc="[true]"
        >
            <template #top>
                <v-col cols="auto">
                    <EqsTextDisplay
                        :value="$t('governance.representatives.title')"
                        v-bind="{ eqsType: 'section' }"
                    />
                </v-col>
            </template>
            <template #item.investor.entity="{ value }">
                <EqsAvatar no-badge v-bind="{ user: value, eqsType: 'list' }" />
            </template>
            <template #item.role="{ item }">
                <EqsChip
                    v-bind="{
                        color: $color.colorFromHash(item.role),
                        textColor: 'text'
                    }"
                >
                    {{ item.role }}
                </EqsChip>
            </template>
            <template #item.actions="{ item }">
                <EqsActions v-bind="{ actions: getActions(item) }" />
            </template>
        </EqsDataTable>
        <RepresentativeDetail
            v-if="editable"
            :value.sync="seeDetail"
            v-bind="{ representative }"
            @close="
                () => {
                    representative = {};
                }
            "
        />
    </EqsCard>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Representatives",
    components: {
        RepresentativeDetail: () => import("./RepresentativeDetail")
    },
    props: {
        editable: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            seeDetail: false,
            representative: {}
        };
    },
    created() {
        this.getRepresentatives();
    },
    computed: {
        ...mapGetters(["representatives", "representativesLoading"]),
        headers() {
            if (!!this.editable) {
                return [
                    {
                        value: "investor.entity",
                        sortable: false
                    },
                    {
                        text: this.$t("governance.representatives.header.name"),
                        value: "investor.entity.name",
                        sortable: true,
                        filterable: true
                    },
                    {
                        text: this.$t("governance.representatives.header.role"),
                        value: "role",
                        sortable: true,
                        filterable: true
                    },
                    {
                        text: this.$t("label.actions"),
                        value: "actions",
                        align: "end",
                        sortable: false,
                        filterable: false
                    }
                ];
            } else {
                return [
                    {
                        value: "investor.entity",
                        sortable: false
                    },
                    {
                        text: this.$t("governance.representatives.header.name"),
                        value: "investor.entity.name",
                        sortable: false
                    },
                    {
                        text: this.$t("governance.representatives.header.role"),
                        value: "role",
                        sortable: true,
                        filterable: true
                    }
                ];
            }
        }
    },
    methods: {
        ...mapActions(["getRepresentatives", "deleteRepresentative"]),
        async deleteItem(item) {
            try {
                await this.deleteRepresentative(item);
            } catch (e) {
                this.$ui.error(e, "governance_representative_delete");
            }
        },
        getActions(item) {
            let actions = [
                {
                    txt: this.$t("governance.representatives.detail-btn"),
                    icon: "mdi-eye-outline",
                    fn: () => {
                        this.representative = item;
                        this.seeDetail = true;
                    },
                    color: "primary"
                },
                {
                    txt: this.$t("governance.representatives.edit-btn"),
                    icon: "mdi-pencil-outline",
                    fn: () =>
                        this.$router.push({
                            name: "governance-representative-edit",
                            params: {
                                representativeId: item.id
                            }
                        }),
                    color: "primary"
                },
                {
                    txt: this.$t("governance.representatives.delete-btn"),
                    icon: "mdi-delete-outline",
                    fn: () => this.deleteItem(item),
                    color: "error"
                }
            ];
            return actions;
        }
    }
};
</script>

<style lang="scss" scoped></style>
