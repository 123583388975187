export default {
    myRoles: state => state.myRoles,
    cgpRoles: state => state.cgpRoles,
    currentRole: state => state.currentRole,
    useThroughRoles: state => state.useThroughRoles,
    isAdmin(state, getters) {
        return getters.currentRole.role_name === "admin";
    },
    isCgp(state, getters) {
        return getters.currentRole.role_name === "cgp";
    },
    isAccountant(state, getters) {
        return getters.currentRole.role_name === "accountant";
    },

    isNotary(state, getters) {
        return getters.currentRole.role_name === "notary";
    },

    isCustodian(state, getters) {
        return getters.currentRole.role_name === "custodian";
    },

    isLawyer(state, getters) {
        return getters.currentRole.role_name === "lawyer";
    },

    canCreateCompany(state, getters) {
        return getters.isAdmin || getters.isLawyer;
    },

    canDeleteCompany(state, getters) {
        return getters.isAdmin;
    },

    canEditInformation(state, getters) {
        return getters.isAdmin || getters.isLawyer;
    },

    canCreateTransaction(state, getters) {
        return getters.isAdmin || getters.isLawyer;
    },

    canDownloadFiles(state) {
        return true; // all roles
    },

    canUploadFiles(state) {
        return true; // all roles
    },

    canDeleteFiles(state) {
        return true; // all roles
    }
};
