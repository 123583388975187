<template>
    <EqsCard
        class="fill-height"
        v-bind="{ height: '100%', loading: loadingOrder || localLoading }"
        v-on="$listeners"
    >
        <template #main>
            <EqsStepper
                :value.sync="step"
                closable
                v-bind="{
                    steps,
                    loading: loading || localLoading || balanceLoading,
                    closeInfoTxt: $t('label.cancel'),
                    progress
                }"
                style="height: 100%; background-color: #f3f2ff"
                @close="goToCapinc"
            >
                <template #default="{ item, loading: isLoading }">
                    <div
                        :class="
                            item.is != 'GlobalYousignIFrame'
                                ? 'eqs-main-container margin-auto enforce-font'
                                : 'fill-height fill-width pa-0 enforce-font'
                        "
                    >
                        <component
                            :is="item.is"
                            retriable
                            :schema-double.sync="schemaDouble"
                            v-bind="{
                                loading: isLoading,
                                order,
                                status,
                                balance,
                                signer,
                                formData
                            }"
                            @signatureRefused="signatureRefused"
                        />
                    </div>
                </template>
            </EqsStepper>
        </template>
        <v-dialog
            v-model="dialogProxy"
            closable
            transition="dialog-bottom-transition"
            max-width="600"
        >
            <v-card class="pa-4">
                <v-card-title class="text-h5"> Capacité à supporter les pertes </v-card-title>
                <v-card-text>
                    Nous avons détecté une volonté d’investir une somme supérieure à 1,000€ ou 5% de
                    votre patrimoine net. Nous vous invitons à refaire le test de capacité à
                    supporter les pertes.
                </v-card-text>
                <v-divider />
                <v-checkbox
                    v-model="dialogCheck"
                    class="font-weight-bold"
                    label="J’ai compris les risques que mon investissement impliquaient et je confirme ma volonté de poursuivre mon investissement."
                />

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        text
                        @click="
                            () => {
                                dialogProxy = false;
                                $router.push({
                                    name: 'risk-aversion',
                                    params: { id: $entity.represented().id }
                                });
                            }
                        "
                    >
                        Repasser le test
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        :disabled="!dialogCheck"
                        @click="
                            () => {
                                dialogProxy = false;
                                createOrder();
                            }
                        "
                    >
                        Ne pas repasser le test
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </EqsCard>
</template>

<script>
import { PRODUCTION_ENVIRONMENT } from "@/assets/constants";

import OrderAmount from "./steps/orderAmount/OrderAmount";
import PaymentData from "./steps/paymentData/PaymentData";
import TaxArrangement from "./steps/taxArrangement/TaxArrangement";
import SendBankDocs from "./steps/sendBankDocs/SendBankDocs";
import Bulletin from "./steps/bulletin/Bulletin";

import OrderStatus from "./steps/recap/OrderStatus";
import WalletConfirmation from "./steps/paymentConfirmation/WalletConfirmation";
import SepaAutoConfirmation from "./steps/paymentConfirmation/SepaAutoConfirmation";
import CryptoConfirmation from "./steps/paymentConfirmation/CryptoConfirmation";
import { statutes } from "@/util/payment.js";

import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";
import { procedureSignerMixin } from "@/mixins/yousignMixin";
import { mapGetters, mapActions } from "vuex";

import { getSelectedPayment } from "./steps/util";

const Decimal = require("decimal.js-light");

export default {
    name: "Buy",
    components: {
        OrderAmount,
        PaymentData,
        TaxArrangement,
        SendBankDocs,
        Bulletin,
        OrderStatus,
        WalletConfirmation,
        SepaAutoConfirmation,
        CryptoConfirmation
    },
    mixins: [stepperFormMixins, procedureSignerMixin],
    props: {
        orderId: {
            type: String,
            required: false,
            default: ""
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dialogCheck: false,
            dialogProxy: false,
            validForm: false,
            localLoading: false,
            loadingOrder: true,
            step: -1,
            steps: [],
            order: {},
            statutes,
            schemaDouble: {},
            formData: {}
        };
    },
    computed: {
        ...mapGetters(["balance", "balanceLoading"]),
        cip() {
            return this.$route.query?.cip === true || false;
        },
        signatureId() {
            if (this.order.signature_procedure) return this.order.signature_procedure.id;
            return "";
        },
        capincId() {
            return this.$route.query?.capinc_id || this.order?.object?.id;
        },
        status() {
            return this.order && this.order.status
                ? this.statutes[this.order.status]
                : this.statutes["pending"];
        },
        isTransfer() {
            return this.order.payment_mode === "transfer";
        },
        isCredit() {
            return this.order.payment_mode === "credit";
        }
    },
    watch: {
        orderId: {
            immediate: true,
            async handler(val) {
                await this.fetchOrder(val);
            }
        },
        step: {
            immediate: true,
            handler(val) {
                if (val < 0) return;
                let step = this.steps[val];
                if (step) {
                    this.schemaDouble = step.schemaDouble;
                    if (this.schemaDouble) this.buildFormData(this.schemaDouble, this.order);
                }
            }
        }
    },
    created() {
        this.getBalance();
    },
    methods: {
        ...mapActions(["getBalance", "getProject"]),
        getSteps() {
            let schemaDoubleAmount = {
                numberofshares: {
                    dataType: "int",
                    isRequired: true
                },
                order_fee_percentage: {
                    dataType: "float",
                    isRequired: !this.order.object.capincpaymentoptions.fee_options.is_fixed
                }
            };
            let schemaDoublePayment = {
                payment_data: {
                    dataType: "object",
                    isRequired: true
                },
                accept_rgpd: {
                    dataType: "bool",
                    isRequired: true
                },
                _local_valid: {
                    dataType: "bool",
                    isRequired: true
                }
            };
            let schemaDoubleTaxArrangement = {
                tax_arrangement: {
                    dataType: "string",
                    isRequired: true
                },
                envelope: {
                    dataType: "string",
                    isRequired: true
                },
                _local_valid: {
                    dataType: "bool",
                    isRequired: true
                }
            };
            let schemaDoubleBulletin = {
                custom_subscription_doc: {
                    dataType: "file",
                    isRequired: true
                },
                proof_of_fund: {
                    dataType: "file",
                    isRequired: true
                }
            };
            let steps = [
                {
                    title: this.$t("user.buy.project-amount"),
                    item: {
                        is: "OrderAmount"
                    },
                    noBack: true,
                    back: {
                        fn: this.goToCapinc,
                        txt: this.$t("label.cancel")
                    },
                    next: {
                        fn: () => {
                            if (this.showUnwarnedPopup()) {
                                this.dialogProxy = true;
                            } else {
                                this.createOrder();
                            }
                        },
                        txt: this.$t("label.confirm")
                    },
                    schemaDouble: schemaDoubleAmount
                },
                {
                    title: this.$t("user.buy.payment-data.title"),
                    item: {
                        is: "PaymentData"
                    },
                    noBack: true,
                    next: {
                        fn: this.setPaymentData,
                        txt: this.$t("label.confirm")
                    },
                    schemaDouble: schemaDoublePayment
                }
            ];
            if (this.showTaxArrangementsStep()) {
                steps.push({
                    title: this.$t("user.buy.tax-data.title"),
                    item: {
                        is: "TaxArrangement"
                    },
                    noBack: true,
                    next: {
                        fn: this.setTaxArrangement,
                        txt: this.$t("label.confirm")
                    },
                    schemaDouble: schemaDoubleTaxArrangement
                });
            }
            if (!this.$universe.isNeotrees()) {
                if (this.showCustomSubDoc()) {
                    steps.push({
                        title: this.$t("user.buy.sub-form.title"),
                        item: {
                            is: "Bulletin"
                        },
                        noBack: true,
                        next: {
                            fn: this.setSubForm,
                            txt: this.$t("user.wallet.money-in.confirm-payment")
                        },
                        schemaDouble: schemaDoubleBulletin
                    });
                }
                if (this.order.getDocsToSign) {
                    steps.push({
                        title: this.$t("user.buy.sign"),
                        item: {
                            is: "GlobalYousignIFrame"
                        },
                        noBack: true,
                        noPadding: true,
                        next: {
                            fn: () =>
                                this.signatureDone
                                    ? this.getUpdatedOrder()
                                    : this.fetchSignatureState(),
                            txt: this.$t("user.wallet.money-in.confirm-payment")
                        },
                        schemaDouble: {
                            signature_done: {
                                dataType: "bool",
                                isRequired: true
                            }
                        }
                    });
                }
            }
            if (this.showSendPeapmeDocs()) {
                steps.push({
                    title: this.$t("user.buy.send-bank-pea.title"),
                    item: {
                        is: "SendBankDocs"
                    },
                    noBack: true,
                    next: {
                        txt: this.$t("user.buy.send-bank-pea.next"),
                        fn: () => this.setMailPeaDocsSent()
                    },
                    schemaDouble: {
                        mail_bank_sent: {
                            dataType: "bool",
                            isRequired: true
                        },
                        _local_valid: {
                            dataType: "bool",
                            isRequired: true
                        }
                    }
                });
            }

            if (this.isWalletPayment()) {
                const walletdata = getSelectedPayment(this.order);
                let schemaDouble = {};
                if (!walletdata.data.confirmed) {
                    schemaDouble.force_show = {
                        dataType: "bool",
                        isRequired: true
                    };
                }
                steps.push({
                    title: this.$t("user.buy.payment-confirmation.title"),
                    item: {
                        is: "WalletConfirmation"
                    },
                    schemaDouble,
                    noBack: false,
                    next: {
                        txt: this.$t("user.buy.payment-confirmation.btn"),
                        fn: () => this.sequestreOrder()
                    }
                });
            } else if (this.isSepaAutoPayment()) {
                const sepaautodata = getSelectedPayment(this.order);
                let schemaDouble = {};
                if (sepaautodata?.billing_request?.status !== "fulfilled") {
                    // Si pas encore validé
                    schemaDouble.done = {
                        dataType: "bool",
                        isRequired: true
                    };
                }

                steps.push({
                    title: this.$t("user.buy.payment-confirmation.title"),
                    item: {
                        is: "SepaAutoConfirmation"
                    },
                    schemaDouble,
                    noBack: true,
                    next: {
                        txt: this.$t("user.buy.payment-confirmation.btn"),
                        fn: () => this.getUpdatedOrder()
                    }
                });
            } else if (this.isCryptoPayment()) {
                let schemaDouble = {};
                if (!["success", "validated", "sequestered"].includes(this.order.status)) {
                    // Si pas encore validé
                    schemaDouble.done = {
                        dataType: "bool",
                        isRequired: true
                    };
                }
                steps.push({
                    title: this.$t("user.buy.payment-confirmation.title"),
                    item: {
                        is: "CryptoConfirmation"
                    },
                    schemaDouble,
                    noBack: true,
                    next: {
                        txt: this.$t("user.wallet.money-in.confirm-payment"),
                        fn: () => this.getUpdatedOrder()
                    }
                });
            }

            steps.push({
                title: this.$t("user.buy.order-status"),
                item: {
                    is: "OrderStatus"
                },
                noBack: false,
                next: {
                    fn: this.goToMyOrders,
                    txt: this.$t("user.buy.finish")
                },
                is: "OrderStatus",
                noForm: true
            });

            return steps;
        },
        showUnwarnedPopup() {
            if (this.order.object.cip) {
                const entity = this.$entity.represented();
                if (!entity.psfp_form || this.$entity.isPsfpWarned()) {
                    return false;
                }

                // Check les montants
                const dShareAmount = new Decimal(this.formData.numberofshares);
                const dSharePrice = new Decimal(this.order.object.share_price);
                const investedAmount = parseInt(
                    dShareAmount.times(dSharePrice).toDecimalPlaces(2, Decimal.ROUND_DOWN)
                );
                const totalAssets = parseInt(
                    Math.ceil(
                        (parseInt(entity.psfp_form.p4bq1) +
                            parseInt(entity.psfp_form.p4bq2) -
                            parseInt(entity.psfp_form.p4bq3)) *
                            0.05
                    )
                );
                if (investedAmount < 1000) {
                    return false;
                } else {
                    if (investedAmount > totalAssets) {
                        return true;
                    } else {
                        if (totalAssets < 1000) {
                            return true;
                        }
                    }
                }
                return false;
            } else {
                return false;
            }
        },
        signatureRefused() {
            this.order.status = "cancel";
            this.$api.cancelOrder(this.order.id, this.order);
            this.step = this.step + 2;
        },
        async goToMyOrders() {
            console.log(this.cip);
            let routeName = this.cip ? "corner-detail" : "projects";
            await this.$router
                .push({
                    name: routeName,
                    params: { id: this.$store.getters.myEntity.id },
                    query: { tab: "orders" }
                })
                .catch();
        },
        async goToCapinc() {
            // Called twice when closing stepper with X button, why ? Use await to quick fix
            let routeName = this.cip ? "crowdfunding" : "projects";
            await this.$router
                .push({
                    path: `/${routeName}/${this.$store.getters.myEntity.id}/${this.capincId}`
                })
                .catch();
        },
        async createOrder() {
            this.localLoading = true;
            try {
                let tmpFormData = this.formatFormData(this.schemaDouble, {
                    capinc_id: this.capincId,
                    payment_mode: "wallet"
                });
                const res = await this.$api.createOrder(tmpFormData);
                this.$set(this, "order", { ...this.order, ...res.data });
                this.step++;
                // Ne pas utilise vue router ici, on change juste l'adresse sans reload
                let pathString = `buy/${res.data.id}?capinc_id=${this.order.object.id}`;
                if (this.cip) {
                    pathString += `&cip=${this.cip}`;
                }
                history.pushState({}, null, pathString);
            } catch (e) {
                this.$ui.error(e, "buy_trigger_buy_process");
            } finally {
                this.localLoading = false;
            }
        },
        async setPaymentData() {
            this.localLoading = true;
            try {
                let tmpFormData = this.formatFormData(this.schemaDouble, {
                    capinc_id: this.capincId
                });
                if (!this.showTaxArrangementsStep() && this.$universe.isNeotrees()) {
                    tmpFormData.no_tax_arrangement = true;
                }
                const res = await this.$api.setCapincOrderPaymentData(this.order.id, tmpFormData);
                this.$set(this, "order", { ...this.order, ...res.data });
                this.step++;
                this.steps = this.getSteps();

                if (
                    !this.showTaxArrangementsStep() &&
                    this.steps[this.step].item.is === "GlobalYousignIFrame"
                ) {
                    const res = await this.$api.createCapincOrderSignatureProcedure(this.order.id, {
                        capinc_id: this.capincId
                    });
                    this.$set(this, "order", { ...this.order, ...res.data });
                    await this.fetchProcedure(this.order.signature_procedure.id);
                }
            } catch (e) {
                this.$ui.error(e);
            } finally {
                this.localLoading = false;
            }
        },
        async setTaxArrangement() {
            this.localLoading = true;
            try {
                let tmpFormData = this.formatFormData(this.schemaDouble, {
                    capinc_id: this.capincId
                });
                const res = await this.$api.setCapincOrderTaxArrangement(
                    this.order.id,
                    tmpFormData
                );
                this.$set(this, "order", { ...this.order, ...res.data });
                this.step++;
                this.steps = this.getSteps();
                if (this.steps[this.step].item.is === "GlobalYousignIFrame") {
                    const res = await this.$api.createCapincOrderSignatureProcedure(this.order.id, {
                        capinc_id: this.capincId
                    });
                    this.$set(this, "order", { ...this.order, ...res.data });
                    await this.fetchProcedure(this.order.signature_procedure.id);
                }
            } catch (e) {
                this.$ui.error(e);
            } finally {
                this.localLoading = false;
            }
        },
        async setSubForm() {
            this.localLoading = true;
            try {
                let tmpFormData = this.formatFormData(this.schemaDouble, {
                    capinc_id: this.capincId
                });
                const res = await this.$api.setCapincOrderSubForm(this.order.id, tmpFormData);
                this.$set(this, "order", { ...this.order, ...res.data });
                await this.fetchProcedure(this.order.signature_procedure.id);
                this.step++;
            } catch (e) {
                this.$ui.error(e);
            } finally {
                this.localLoading = false;
            }
        },
        setMailPeaDocsSent() {
            this.$set(this, "order", {
                ...this.order,
                mail_bank_sent: this.formData.mail_bank_sent
            });
            this.step++;
        },
        async getUpdatedOrder() {
            this.localLoading = true;
            try {
                // only to trigger order_save
                const res = await this.$api.getOrder(this.orderId || this.order.id);
                this.$set(this, "order", { ...this.order, ...res.data });
                this.step++;
            } catch (e) {
                this.$ui.error(e, "buy_trigger_buy_process");
            } finally {
                this.localLoading = false;
            }
        },
        async getOrderObject(existingOrder) {
            this.localLoading = true;
            try {
                const capinc = await this.getProject({
                    projectId: existingOrder?.object ? existingOrder.object.id : this.capincId,
                    isCip: this.cip
                });
                return capinc;
            } catch (e) {
                console.error({ e });
                this.localLoading = false;
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async fetchSignatureState() {
            try {
                if (this.signatureId) {
                    if (!PRODUCTION_ENVIRONMENT) {
                        await this.fetchProcedureLocal(this.signatureId);
                    } else {
                        await this.fetchProcedure(this.signatureId);
                    }
                }
                await this.$nextTick();
                if (!this.signatureDone) {
                    this.$ui.warning(this.$t("user.buy.signature-pending"));
                } else this.step++;
            } catch (e) {
                this.$ui.error(e, "buy_trigger_buy_process");
            }
        },
        async fetchOrder(id) {
            this.loadingOrder = true;
            try {
                let existingOrder = {};
                if (id) {
                    const res = await this.$api.getOrder(id);
                    existingOrder = res.data;
                }

                const object = await this.getOrderObject(existingOrder);
                let getDocsToSign = !!object.signature_procedure;
                if (getDocsToSign) {
                    this.checkEntityCanSign({
                        fn: this.goToMyOrders,
                        txt: this.$t("label.cancel")
                    });
                }
                this.$set(this, "order", {
                    ...existingOrder,
                    getDocsToSign,
                    object
                });
                this.steps = this.getSteps();
                // force signature_done to redirect to following step in case order already signed
                // stop at step send mail to bank for pea in case order not finished and order is pea
                this.step = this.getLastFormStep({
                    ...this.order,
                    signature_done: this.order.status && this.order.status !== "pending"
                });
            } catch (e) {
                this.$ui.error(e, "buy_fetch_order");
                if (e.status == 404)
                    this.$router.push({ name: "projects", query: { tab: "orders" } });
            } finally {
                this.loadingOrder = false;
            }
        },
        async sequestreOrder() {
            this.localLoading = true;
            this.loadingOrder = true;
            try {
                const res = await this.$api.sequestreCapincOrder(
                    this.order && this.order.id ? this.order.id : this.orderId,
                    this.order.capinc_id
                );
                this.order = res.data;
                this.step += 1;
            } catch (e) {
                this.$ui.error(e, "buy_trigger_buy_process");
            } finally {
                this.loadingOrder = false;
                this.localLoading = false;
            }
        },
        showTaxArrangementsStep() {
            // hide when only none
            const taxArrangements = this.order.object?.capincpaymentoptions?.tax_arrangements;
            const envelopes = this.order.object?.capincpaymentoptions?.envelopes;
            if (
                (!taxArrangements ||
                    taxArrangements.length === 0 ||
                    (taxArrangements.length === 1 && taxArrangements[0] === "none")) &&
                (!envelopes ||
                    envelopes.length === 0 ||
                    (envelopes.length === 1 && envelopes[0] === "none"))
            ) {
                return false;
            }
            return true;
        },
        showSendPeapmeDocs() {
            let envelope;
            if (this.order.envelope) {
                envelope = this.order.envelope.choice;
            } else {
                envelope = null;
            }
            const selectedPayment = getSelectedPayment(this.order);
            return (
                ["pea", "pea-pme"].includes(envelope) &&
                ["walletdata", "wiretransfertdata"].includes(selectedPayment.name)
            );
        },
        showCustomSubDoc() {
            return this?.order?.object?.is_custom_bs;
        },

        isWalletPayment() {
            const selectedPayment = getSelectedPayment(this.order);
            return selectedPayment && selectedPayment.name === "walletdata";
        },
        isSepaAutoPayment() {
            const selectedPayment = getSelectedPayment(this.order);
            return selectedPayment && selectedPayment.name === "sepaautodata";
        },
        isCryptoPayment() {
            const selectedPayment = getSelectedPayment(this.order);
            return selectedPayment && selectedPayment.name === "cryptodata";
        }
    }
};
</script>

<style lang="scss">
.enforce-font {
    font-family: "Montserrat", sans-serif !important;
}
</style>
