export default () => ({
    // list of roles for the authenticated user
    myRoles: [],
    cgpRoles: [],
    useThroughRoles: {
        useIt: false,
        throughRole: null,
        currentRole: null
    },

    /*
  Selected role: used to impersonate an entity with specific permissions

  Schema:
  {
    "user": {
      ...
    },
    "entity_represented": {
      ...
    },
    "role_name": "string"
  }
  */
    currentRole: {}
});
