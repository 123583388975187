<template>
    <v-container fluid>
        <Breadcrumbs />
        <v-row>
            <v-col class="col-6 text-left">
                <h3 class="text-h4 primary--text text--darken-4 font-weight-bold">
                    Signer la feuille de présence
                </h3>
            </v-col>
            <v-col class="col-6 text-right">
                <v-btn small height="100%" class="grey lighten-1 elevation-0"
                    >Démarrer l'assemblée générale</v-btn
                >
            </v-col>
        </v-row>
        <p class="mb-10">{{ $t("ag.header-text-1") }}</p>
        <v-data-table :headers="headers" :items="item.members" hide-default-footer>
            <template #[`item.represented_by`]="{ item }">
                {{ representedByText(item) }}
            </template>
            <template #item.signed="{ item }">
                <div style="width: 100px">
                    <v-btn
                        v-if="shouldDisplayButton(item)"
                        small
                        class="primary elevation-0"
                        @click="activateButton(item)"
                    >
                        {{ stateSignature(item) }}
                    </v-btn>
                    <div v-else :class="getSignatureClass(item)">
                        {{ stateSignature(item) }}
                    </div>
                </div>
            </template>
        </v-data-table>
        <v-btn
            small
            style="border: 2px solid #405cff"
            class="rounded elevation-0 primary--text font-weight-bold px-5 py-5 mt-10"
            @click="backToDetails"
            >Retour</v-btn
        >
    </v-container>
</template>

<script>
import Breadcrumbs from "../Breadcrumbs";

export default {
    name: "AgPresenceSheet",
    components: { Breadcrumbs },
    props: {
        item: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            localLoading: true,
            isActive: false,
            headers: [
                {
                    value: "fullname",
                    text: "Associé",
                    sortable: true,
                    align: "left"
                },
                {
                    // TODO : back key to link
                    value: "action_number",
                    text: "Nombre d'action",
                    sortable: true,
                    align: "left"
                },
                {
                    value: "voting_rights",
                    text: "Nombre de voix",
                    sortable: true,
                    align: "left"
                },
                {
                    value: "represented_by",
                    text: "Mandataire",
                    sortable: true,
                    align: "left"
                },
                {
                    value: "signed",
                    text: "Signature",
                    sortable: true,
                    align: "left"
                }
            ]
        };
    },
    computed: {},
    mounted() {
        this.localLoading = false;
        this.item.members[0].signed = false;
    },
    methods: {
        activateButton(item) {
            item.signed = true;
        },
        backToDetails() {
            this.$router.go(-1);
        },
        representedByText(item) {
            if (!item.represented_by) {
                return "-";
            } else {
                return item.represented_by.fullname;
            }
        },
        stateSignature(item) {
            if (item.represented_by) {
                return "Représenté";
            } else if (!item.signed) {
                if (this.item.member.id === item.id) {
                    return "Signer la feuille";
                }
                return "En attente de signature";
            } else {
                return "Signé";
            }
        },
        shouldDisplayButton(item) {
            console.log("shouldDisplayButton = ", item.id === this.item.member.id);
            return item.id === this.item.member.id && !item.signed;
        },
        getSignatureClass(item) {
            const signature = this.stateSignature(item);
            if (signature == "Signé") {
                return "sign-success-wrapper";
            } else if (signature == "En attente de signature") {
                return "sign-waiting-wrapper";
            }
            if (signature == "Représenté") {
                return "sign-success-wrapper";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.sign-success-wrapper {
    color: #3ca745;
    border: 1px solid #3ca745;
    padding: 3px 8px;
    border-radius: 4px;
    width: fit-content;
}

.sign-waiting-wrapper {
    color: blue;
    border: 1px solid #3ca745;
    padding: 3px 8px;
    border-radius: 4px;
    width: fit-content;
}
</style>
