<template>
    <v-container fluid>
        <div class="">
            <div v-if="isRootPage && dashboardTopWording !== ''">
                <span>{{ dashboardTopWording }}</span>
            </div>
            <v-skeleton-loader
                v-if="!isRootPage"
                :loading="mainTitle === ''"
                :boilerplate="mainTitle === ''"
                type="text"
                min-height="32"
                min-width="250"
                class="d-flex align-center"
            >
                <div class="icon-with-text">
                    <v-icon v-if="currentInfoEntity?.is_company" class="custom-left-icon"
                        >mdi-domain</v-icon
                    >
                    <h2 class="primary--text font-weight-bold mr-3 text-md-h5 text-h6">
                        {{ mainTitle }}
                    </h2>
                </div>
                <v-chip v-if="currentStatus">
                    <v-icon class="mr-2" :color="currentStatus.color">
                        {{ currentStatus.icon }}
                    </v-icon>
                    <span>{{ currentStatus.text }}</span>
                </v-chip>
            </v-skeleton-loader>
        </div>
        <v-row>
            <v-col
                v-if="localType === 'view_investor' && currentInfoEntity"
                lg="7"
                md="12"
                sm="12"
                cols="auto"
            >
                <v-simple-table class="custom-table2">
                    <template #default>
                        <tbody>
                            <tr>
                                <td>{{ $t("registration.email") }}</td>
                                <td>
                                    <ContactLink type="email" :data="currentInfoEntity.email" />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t("registration.phone") }}</td>
                                <td>
                                    <ContactLink type="phone" :data="currentInfoEntity.phone" />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t("registration.address") }}</td>
                                <td>
                                    <ContactLink
                                        type="address"
                                        :data="currentInfoEntity.full_address"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t("universe.kyoseil.tab.status-kyc") }}</td>
                                <td>
                                    <KycStatus
                                        :loading="localLoading"
                                        :entity="currentInfoEntity"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col v-else lg="7" md="12" sm="12" cols="auto">
                <PerformanceStats :id="statsId" :loading="localLoading" :type="localType" />
            </v-col>
            <v-col lg="5" md="12" sm="12" cols="auto">
                <ActionsButtons
                    :loading="localLoading"
                    :type="localType"
                    :id-entity="idAction"
                    :current-info-entity="currentInfoEntity"
                    @entity-add="fetchData()"
                />
            </v-col>
        </v-row>
        <DataTable
            :loading="localDataLoading"
            :type="localType"
            :data-table="dataTableData"
            @open-cgp-modal="openCgpModal"
        />
        <InvestorModal
            v-if="showCgpModal && cgpData"
            :loading="false"
            :open.sync="showCgpModal"
            :investor="cgpData"
            :show-orders="false"
            @closeDialog="closeCgpModal"
        />
    </v-container>
</template>

<script>
import PerformanceStats from "./stats/PerformanceStats";
import ActionsButtons from "./actions/ActionsButtons";
import DataTable from "./dataTable/DataTable";
import KycStatus from "./components/KycStatus";
import ContactLink from "./components/ContactLink";
import InvestorModal from "@/components/views/user/investorsDashboard/InvestorModal";

import {
    getViewFromRoute,
    isRootPage,
    validationStatusKyc
} from "@/components/views/user/clients/tools.js";

export default {
    name: "Dashboard",
    components: {
        PerformanceStats,
        ActionsButtons,
        DataTable,
        KycStatus,
        ContactLink,
        InvestorModal
    },
    inject: ["_entity"],
    props: {
        // Computable
        type: {
            type: String,
            default: null
        },
        // Is a root dashboard (from /affiliation) or a child (See more)
        isParent: {
            type: Boolean,
            required: false,
            default: false
        },
        // Cache value to show title
        entityName: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            localLoading: true,
            localDataLoading: true,
            showCgpModal: false,
            cgpData: {},
            localType: "",
            dataTableData: [],
            mainTitle: "",
            showMainTitle: false,
            localEntityName: null,
            idAction: "",
            statsId: "",
            currentInfoEntity: null,
            currentStatus: null
        };
    },
    computed: {
        isRootPage() {
            return isRootPage(this.$route);
        },
        dashboardTopWording() {
            const isAffiliationAdmin = this.$entity.isAffiliationAdmin();
            switch (this.localType) {
                case "view_admin":
                    return isAffiliationAdmin
                        ? this.$t("universe.kyoseil.dashboard-wording.admin-admin")
                        : "";
                case "view_bc":
                    return isAffiliationAdmin
                        ? this.$t("universe.kyoseil.dashboard-wording.admin-bc")
                        : this.$t("universe.kyoseil.dashboard-wording.bc");
                case "view_cgp":
                    return isAffiliationAdmin
                        ? this.$t("universe.kyoseil.dashboard-wording.admin-cgp")
                        : this.$t("universe.kyoseil.dashboard-wording.cgp");
                default:
                    return "";
            }
        }
    },
    beforeMount() {
        this.updateType(); // First mandatory
        // By default, only the logged entity can make an action on HIS view
        this.idAction = this._entity.id;
        this.fetchData(); // async
        if (!isRootPage(this.$route) && !this.isParent) {
            this.updateEntityInfos(); // async
        }
    },
    methods: {
        async openCgpModal(cgpId) {
            const res = await this.$api.getEntityCGPInfo(cgpId);
            this.cgpData = { ...res.data, id: cgpId };
            this.showCgpModal = true;
        },
        async closeCgpModal() {
            this.cgpData = null;
            this.showCgpModal = false;
        },
        async updateEntityInfos() {
            if (this.currentInfoEntity) {
                // Never happens ?
                return;
            }
            const res = this.fetchEntityInfos();
            res.then(res => {
                this.currentInfoEntity = res?.data;
                if (this.currentInfoEntity.is_company) {
                    this.currentInfoEntity.email = this.currentInfoEntity.ceo_email;
                    this.currentInfoEntity.phone = this.currentInfoEntity.ceo_phone;
                }
                if (this.localType !== "view_investor") {
                    this.currentStatus = validationStatusKyc(
                        this.currentInfoEntity?.attributes[0].validation_status
                    );
                }
            });
            if (!this.entityName) {
                // Await data being fetched
                await res;
                this.localEntityName = this.currentInfoEntity?.name;
            } else {
                this.localEntityName = this.entityName;
            }

            let tmpTitle = "";

            if (this.localType === "view_bc") {
                tmpTitle = this.$t("universe.kyoseil.dashboard.bc-singular");
            } else if (this.localType === "view_cgp") {
                tmpTitle = this.$t("universe.kyoseil.dashboard.cgp-singular");
            } else if (this.localType === "view_investor") {
                tmpTitle = this.$t("universe.kyoseil.dashboard.investor-singular");
            }
            if (tmpTitle.length > 0) {
                tmpTitle = tmpTitle.concat(" : ", this.localEntityName || "#");
                this.mainTitle = tmpTitle;
            }
        },
        updateType() {
            if (this.type === "" || this.type === null) {
                if (this.$route.name === "affiliation" || this.isParent) {
                    if (this.$route.query.tab) {
                        this.localType = this.$route.query.tab;
                    } else {
                        // Happens when F5 on children
                        if (this.$entity.isAffiliationAdmin()) {
                            this.localType = "admin";
                        } else if (this.$entity.isAffiliationBc()) {
                            this.localType = "bc";
                        } else if (this.$entity.isAffiliationCgp()) {
                            this.localType = "cgp";
                        } else {
                            this.localType = null;
                        }
                    }
                } else {
                    this.localType = getViewFromRoute(this.$route.name);
                }
            } else {
                this.localType = this.type;
            }
            if (this.localType === null) {
                console.log("TYPE NULL");
                // ERROR should never happens
            }
        },
        async fetchInvestorDeals(cgpId, investorId) {
            let response = null;
            try {
                if (cgpId) {
                    response = await this.$api.getClientDeals(cgpId, investorId);
                } else {
                    response = await this.$api.getDealClientFromAdmin(investorId);
                }
            } catch (e) {
                this.$ui.error(e, "cgp_dashboard");
                throw e;
            }
            return response?.data?.results;
        },
        async fetchEntityInfos() {
            //TODO: toast error
            let res = null;
            if (this.localType === "view_bc") {
                res = await this.$api.getEntityBPInfo(this.$route.params.bc_id);
            } else if (this.localType === "view_cgp") {
                res = await this.$api.getEntityCGPInfo(this.$route.params.cgp_id);
            } else if (this.localType === "view_investor") {
                res = await this.$api.getInvestorInfos(this.$route.params.investor_id);
            }
            return res;
        },
        async fetchInvestors(id) {
            let response = null;
            try {
                if (id) {
                    response = await this.$api.getClients(id);
                } else {
                    response = await this.$api.getAllClients();
                }
            } catch (e) {
                this.$ui.error(e, "cgp_dashboard");
                throw e;
            }
            return response?.data?.results;
        },
        async fetchCgps(id) {
            let response = null;
            try {
                if (id) {
                    response = await this.$api.getBusinessProviderClient(id);
                } else {
                    response = await this.$api.getUniverseCGPs();
                }
            } catch (e) {
                this.$ui.error(e, "cgp_dashboard");
                throw e;
            }
            return response?.data?.results;
        },
        async fetchBusinessContributor() {
            let response = null;
            try {
                response = await this.$api.getBusinessProviders();
            } catch (e) {
                this.$ui.error(e, "business_contributor_dashboard");
                throw e;
            }
            return response?.data?.results;
        },
        async fetchData() {
            this.localDataLoading = true;

            let data = null;
            if (this.localType === "view_investor") {
                const investor_id = this.$route.params.investor_id;
                let cgp_id = null;
                if (this.$entity.isAffiliationCgp()) {
                    cgp_id = this._entity.id;
                } else if (this.$route.params.cgp_id) {
                    cgp_id = this.$route.params.cgp_id;
                } else {
                    // ERROR ?
                }
                this.statsId = "";
                this.idAction = investor_id;
                data = await this.fetchInvestorDeals(cgp_id, investor_id);
            } else if (this.localType === "view_cgp") {
                let cgp_id = null;
                if (this.$entity.isAffiliationCgp()) {
                    cgp_id = this._entity.id;
                } else if (this.$route.params.cgp_id) {
                    cgp_id = this.$route.params.cgp_id;
                } else {
                    // ERROR ?
                }
                if (this.$entity.isAffiliationAdmin() && isRootPage(this.$route)) {
                    this.statsId = "";
                    this.idAction = this._entity.id;
                    data = await this.fetchInvestors(null);
                } else {
                    this.statsId = cgp_id;
                    this.idAction = cgp_id;
                    data = await this.fetchInvestors(cgp_id);
                }
            } else if (this.localType === "view_bc") {
                let bc_id = null;
                if (this.$entity.isAffiliationBc()) {
                    bc_id = this._entity.id;
                    this.statsId = bc_id;
                    this.idAction = bc_id;
                } else if (this.$route.params.bc_id) {
                    bc_id = this.$route.params.bc_id;
                    this.statsId = isRootPage(this.$route) ? "" : bc_id;
                    this.idAction = this.statsId;
                } else if (this.$entity.isAffiliationAdmin()) {
                    // Admin
                    this.statsId = "";
                    this.idAction = this._entity.id;
                } else {
                    // ERROR
                }
                data = await this.fetchCgps(bc_id);
            } else if (this.localType === "view_admin") {
                data = await this.fetchBusinessContributor();
                this.idAction = this._entity.id;
                this.statsId = "";
            } else {
                console.log("NO FETCH", this.localType);
                this.idAction = "";
                this.statsId = "";
            }

            if (!data) {
                this.dataTableData = [];
            } else {
                this.dataTableData = [...data];
            }
            this.localDataLoading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.client-title {
    font-weight: bolder;
}
.custom-table2 :deep(table) {
    width: auto !important;
}

.icon-with-text {
    > h2 {
        display: inline;
        vertical-align: middle;
    }

    .custom-left-icon {
        vertical-align: middle;
        margin-right: 6px;
    }
}
</style>
