<template>
    <v-lazy v-model="isActive">
        <EqsDataTable
            el-class="capinc-history"
            :searchable="{
                placeholder: $t('capital-increase.admin.history.search-for-capinc')
            }"
            hide-default-footer
            :add="{
                txt: $t('capital-increase.create-card-btn'),
                fn: () =>
                    $router.push({
                        name: 'fundraising-add'
                    })
            }"
            v-bind="{
                items: myProjects,
                headers,
                loading: loading || myProjectsLoading
            }"
            class="row-pointer"
            @click:row="item => goToDetails(item)"
        >
            <template #item.start_date="{ value }">
                {{ $time.formated(value) }}
            </template>
            <template #item.end_date="{ value }">
                {{ $time.formated(value) }}
            </template>
            <template #item.money_raised="{ value }">
                {{ $currency.display(value) }}
            </template>
            <template #item.phase="{ item }">
                <EqsChip class="pa-1" :eqs-type="phaseItem(item).eqsType || ''">
                    {{ phaseItem(item).text || "" }}
                </EqsChip>
            </template>
            <template #item.actions="{ item, index }">
                <EqsActions
                    v-bind="{
                        elClass: `capinc-actions-${index}`,
                        actions: getActions(item)
                    }"
                />
            </template>
        </EqsDataTable>
    </v-lazy>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "CapitalIncreaseHistory",
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            isActive: false,
            headers: [
                {
                    text: this.$t("capital-increase.admin.placeholder-name"),
                    value: "order_title"
                },
                {
                    text: this.$t("label.start-date"),
                    value: "start_date"
                },
                {
                    text: this.$t("label.end-date"),
                    value: "end_date"
                },
                {
                    text: this.$t("capital-increase.admin.nb-shares"),
                    value: "reserved_shares",
                    sortable: false
                },
                {
                    text: this.$t("capital-increase.admin.nb-investors"),
                    value: "investor_count"
                },
                {
                    text: this.$t("capital-increase.admin.money-raised"),
                    value: "money_raised",
                    filterable: false
                },
                {
                    text: this.$t("label.phase"),
                    filterable: false,
                    value: "phase"
                },
                {
                    text: this.$t("label.actions"),
                    align: "center",
                    sortable: false,
                    filterable: false,
                    value: "actions"
                }
            ]
        };
    },
    computed: {
        ...mapGetters(["myProjects", "myProjectsLoading"])
    },
    watch: {
        isActive(val) {
            if (val) {
                this.getMyProjects({
                    entity_id: this.$entity.represented().id
                });
            }
        }
    },
    methods: {
        ...mapActions(["getMyProjects", "fetchMyProject", "deleteMyProject"]),
        goToDetails(item) {
            this.$router.push({
                name: "fundraising-details",
                params: { capitalIncreaseId: item.id }
            });
        },
        async nextStep(item) {
            let canFinalizeCapinc = false;
            try {
                const res = await this.$api.request(
                    "get",
                    `api/capital-increase/${item.id}/can_finalize/`
                );
                canFinalizeCapinc = res.data?.can_finalize || false;
            } catch (e) {
                this.$ui.error(this.$t("capital-increase.admin.next.msg-error"));
                return;
            }
            if (canFinalizeCapinc) {
                this.$dialog.warn({
                    title: this.$t("capital-increase.admin.next.title"),
                    text: this.$t("capital-increase.admin.next.msg", {
                        name: item.order_title
                    }),
                    confirm: {
                        fn: async () => {
                            try {
                                await this.$api.request(
                                    "post",
                                    `/api/capital-increase/${item.id}/next/`
                                );
                                await this.fetchMyProject({
                                    projectId: item.id,
                                    entityId: this.$entity.represented().id
                                });
                                this.$ui.success(
                                    this.$t("capital-increase.admin.next.success", {
                                        itemOrder: item.order_title
                                    })
                                );
                            } catch (e) {
                                this.$ui.error(e, "capital_increase_admin_finish");
                                throw e;
                            }
                        },
                        txt: this.$t("capital-increase.admin.next.btn")
                    },
                    cancel: {}
                });
            } else {
                this.$dialog.alert({
                    title: this.$t("capital-increase.admin.next.title"),
                    text: this.$t("capital-increase.admin.next.msg-error"),
                    confirm: {
                        fn: () =>
                            this.$router.push({
                                name: "fundraising-details",
                                params: { capitalIncreaseId: item.id }
                            }),
                        txt: this.$t("capital-increase.admin.next.btn-dashboard")
                    },
                    cancel: {}
                });
            }
        },
        cancelStep(item) {
            this.$dialog.alert({
                title: this.$t("capital-increase.admin.cancel.title"),
                text: this.$t("capital-increase.admin.cancel.msg", {
                    itemOrder: item.order_title
                }),
                confirm: {
                    fn: async () => {
                        try {
                            const url = item.url + "cancel/";
                            await this.$api.request("post", url);
                            await this.fetchMyProject({
                                projectId: item.id,
                                entityId: this.$entity.represented().id
                            });
                            this.$ui.success(
                                this.$t("capital-increase.admin.cancel.success", {
                                    itemOrder: item.order_title
                                })
                            );
                        } catch (e) {
                            this.$ui.error(e, "capital_increase_admin_cancel");
                            throw e;
                        }
                    },
                    txt: this.$t("label.confirm")
                },
                cancel: {}
            });
        },
        removeCapitalIncrease(item) {
            this.$dialog.alert({
                title: this.$t("capital-increase.admin.delete.title"),
                text: this.$t("capital-increase.admin.delete.msg", {
                    itemOrder: item.order_title
                }),
                confirm: {
                    fn: async () => {
                        try {
                            await this.deleteMyProject(item.id);
                            this.$ui.success(
                                this.$t("ui.message.success.removed-order", {
                                    itemOrder: item.order_title
                                })
                            );
                        } catch (e) {
                            this.$ui.error(e, "capital_increase_admin_delete");
                            throw e;
                        }
                    },
                    txt: this.$t("btn.remove")
                },
                cancel: {}
            });
        },
        getActions(item) {
            let actions = [];
            if (item.order_title) {
                actions.push({
                    txt: this.$t("capital-increase.admin.open-btn"),
                    icon: "mdi-eye-outline",
                    fn: () =>
                        this.$router.push({
                            name: "fundraising-details",
                            params: { capitalIncreaseId: item.id }
                        }),
                    color: "primary"
                });
            }

            if (item.editable === true && !["delivery", "cancellation"].includes(item.phase))
                actions.push(
                    ...[
                        {
                            txt: this.$t("capital-increase.admin.edit-btn"),
                            icon: "mdi-pencil-outline",
                            fn: () =>
                                this.$router.push({
                                    name: "fundraising-edit",
                                    params: {
                                        capitalIncreaseId: item.id
                                    }
                                }),
                            color: "primary"
                        },
                        {
                            txt: this.$t("capital-increase.admin.delete-btn"),
                            icon: "mdi-delete-outline",
                            fn: () => this.removeCapitalIncrease(item),
                            color: "error"
                        }
                    ]
                );

            if (["fundraising", "commitment"].includes(item.phase)) {
                if (parseFloat(item.money_raised) >= parseFloat(item.soft_cap))
                    actions.push({
                        txt: this.$t("capital-increase.admin.next.title"),
                        icon: "mdi-arrow-right-circle-outline",
                        fn: () => this.nextStep(item),
                        color: "success"
                    });
                actions.push({
                    txt: this.$t("capital-increase.admin.cancel.title"),
                    icon: "mdi-cancel",
                    fn: () => this.cancelStep(item),
                    color: "error"
                });
            }

            return actions;
        },
        phaseItem(item) {
            switch (item.phase) {
                case "cancellation":
                    return {
                        eqsType: "error",
                        text: this.$t("capital-increase.admin.phase.status.cancellation")
                    };

                case "fundraising":
                    if (item.status !== "over")
                        return {
                            eqsType: "info",
                            text: this.$t("capital-increase.admin.phase.status.fundraising")
                        };
                    else
                        return {
                            eqsType: "warning",
                            text: this.$t("capital-increase.admin.phase.status.pending")
                        };

                case "pending":
                    return {
                        eqsType: "warning",
                        text: this.$t("capital-increase.admin.phase.status.pending")
                    };

                case "delivery":
                    return {
                        eqsType: "success",
                        text: this.$t("capital-increase.admin.phase.status.delivery")
                    };

                default:
                    return {
                        eqsType: item.status === "success" ? "success" : "info",
                        text: item.status === "success" ? this.$t("label.success") : item.phase
                    };
            }
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.row-pointer) {
    tbody {
        tr {
            cursor: pointer;
        }
    }
}
</style>
