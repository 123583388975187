import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, investor) => {
    DEBUG("DELETE_INVESTOR_START");
    try {
        commit("SET_INVESTORS_LOADING", true);
        await window.app.$api.deleteInvestor(investor.id);
        commit("DELETE_INVESTOR", investor.id);
        DEBUG("DELETE_INVESTOR_SUCCESS");
    } catch (e) {
        DEBUG("DELETE_INVESTOR_ERROR");
        throw e;
    } finally {
        commit("SET_INVESTORS_LOADING", false);
    }
};
