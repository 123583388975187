<template>
    <div class="margin-auto">
        <v-col>
            <EqsTextDisplay
                v-if="docs && docs.categories && docs.categories.length"
                eqs-type="subtitle"
                class="mb-3"
            >
                {{ $t("secondary-market-details.documents.available.title") }}
            </EqsTextDisplay>
            <EqsTextDisplay v-else class="mb-3">
                {{ $t("secondary-market-details.documents.not-available.title") }}
            </EqsTextDisplay>

            <EqsTextDisplay v-if="docs && docs.categories && docs.categories.length" class="mb-3">
                {{ $t("secondary-market-details.documents.available.text") }}
            </EqsTextDisplay>

            <v-row v-if="docs && docs.categories && docs.categories.length" class="px-2">
                <v-col
                    v-for="category in docs.categories"
                    :key="category"
                    :cols="$reactive.getCols('half')"
                >
                    <EqsTextDisplay v-bind="{ eqsType: 'label', value: getCategory(category) }" />
                    <EqsFileInput multiple :editable="false" :value="docs[category]" />
                </v-col>
            </v-row>
            <!--div v-for="doc in docs" :key="doc.id">
        <EqsFileInput
          class="mb-3"
          v-bind="{ editable: false, loading: loading, value: doc }"
        />
      </div-->
        </v-col>
    </div>
</template>

<script>
import find from "lodash/find";

export default {
    name: "MarketDocuments",
    components: {},
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            docs: [],
            loading: false,
            categories: [
                {
                    text: this.$t("capital-increase.admin.offering.legal-name"),
                    value: "legal"
                },
                {
                    text: this.$t("capital-increase.admin.offering.finance"),
                    value: "finance"
                },
                {
                    text: this.$t("capital-increase.admin.offering.tech"),
                    value: "tech"
                },
                {
                    text: this.$t("capital-increase.admin.offering.marketing"),
                    value: "marketing"
                },
                {
                    text: this.$t("label.other"),
                    value: "other"
                }
            ]
        };
    },
    async created() {
        this.loading = true;
        try {
            const res_docs = await this.$api.getSecondaryMarketPrefDocs(this.company.id);
            this.docs = this.extractDocs(res_docs.data);
        } catch (e) {
            console.error("GET_DOCUMENTS", e);
            this.$ui.error(this.$t("secondary-market.documents.documents-get-error"));
        }
        this.loading = false;
    },
    methods: {
        extractDocs(in_docs) {
            const out_docs = {
                categories: []
            };
            in_docs.forEach(doc => {
                if (!out_docs.categories.includes(doc.category)) {
                    out_docs.categories.push(doc.category);
                    out_docs[doc.category] = [];
                }
                out_docs[doc.category].push(doc.document);
            });
            return out_docs;
        },
        getCategory(category) {
            const trad = find(this.categories, elem => elem.value == category);
            return trad.text || category;
        }
    }
};
</script>
