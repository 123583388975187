var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',[_c('div',{staticClass:"eqs-switch d-inline-flex",style:(_vm.getStyle),attrs:{"el-class":_vm.elClass}},[_c('EqsLabel',_vm._b({},'EqsLabel',{
                ..._vm.$attrs,
                isRequired: _vm.isRequired,
                height: _vm.labelHeight,
                editable: _vm.editable,
                value: _vm.label,
                infoTxt: _vm.infoTxt
            },false),[_c('div',{staticClass:"centered"},[_c('v-switch',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"el-class":`${_vm.elClass}-value`},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},'v-switch',{
                        ..._vm.$attrs,
                        ..._vm.$props,
                        ..._vm.getData,
                        label: _vm.text,
                        loading: _vm.loading,
                        disabled: _vm.disabled || _vm.loading
                    },false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }