<script>
import { Bar } from "vue-chartjs";
import _ from "lodash";

export default {
    name: "EqsBar",
    extends: Bar,
    props: {
        config: {
            type: Object,
            required: true
        },
        formatDatasetLabels: {
            type: Function,
            default: null
        }
    },
    watch: {
        config: {
            immediate: true,
            handler(val) {
                if (!_.isEmpty(val)) this.render(val);
            }
        }
    },
    mounted() {
        if (!_.isEmpty(this.config)) this.render(this.config);
    },
    methods: {
        async render(config) {
            // Wait until canvas is created to avoid error from getContext
            await this.canvasIsCreated();
            const options = this.setOptions(config);
            this.renderChart(config, options);
        },
        canvasIsCreated() {
            return new Promise((resolve, reject) => {
                if (document.getElementById("bar-chart")) {
                    resolve();
                    return;
                }
            });
        },
        setScales(config) {
            let data = config.datasets[0].data;
            let ticksConfig = {};

            ticksConfig.stepSize =
                _.max(data) != _.min(data)
                    ? parseInt((_.max(data) - _.min(data)) / 10)
                    : parseInt(_.max(data) / 10);
            ticksConfig.min = parseInt(_.min(data) - 2 * ticksConfig.stepSize);
            ticksConfig.min = ticksConfig.min < 0 ? 0 : ticksConfig.min;
            ticksConfig.max = parseInt(_.max(data)) + ticksConfig.stepSize;

            return {
                yAxes: [
                    {
                        ticks: ticksConfig
                    }
                ]
            };
        },
        setOptions(config) {
            const options = {
                responsive: true,
                maintainAspectRatio: true,
                scales: this.setScales(config),
                legend: {
                    display: false
                },
                plugins: {
                    datalabels: {
                        anchor: "end",
                        align: "end",
                        formatter: (value, context) => {
                            if (!!this.formatDatasetLabels) {
                                return this.formatDatasetLabels(value);
                            }
                        }
                    }
                }
            };
            return options;
        }
    }
};
</script>

<style lang="scss" scoped></style>
