<template>
    <EqsLabel
        class="eqs-text"
        :el-class="`${elClass}-label`"
        v-bind="{
            ...$attrs,
            locked,
            eqsSize,
            isRequired,
            height: labelHeight,
            editable,
            value: label,
            infoTxt
        }"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
        <EqsEditable
            v-bind="{
                ...$attrs,
                editable: editable && !locked,
                value: editableData,
                eqsSize
            }"
        >
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <EqsSkeleton v-bind="{ loading, type: 'sentences' }">
                <v-text-field
                    v-bind="{
                        readonly,
                        placeholder,
                        disabled,
                        ...$attrs
                    }"
                    :background-color="$color.colors.inputBackground"
                    :value="valueString"
                    :rules="rulesRequired"
                    :style="getStyle"
                    :class="`${getClassFromState} rounded-lg ${elClass}-field`"
                    hide-details="auto"
                    outlined
                    filled
                    rounded
                    dense
                    v-on="$listeners"
                    @input="handlerForm"
                >
                    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
                </v-text-field>
            </EqsSkeleton>
        </EqsEditable>
        <div v-show="false" :el-class="`${elClass}-value`">
            {{ valueString }}
        </div>
    </EqsLabel>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsText.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import { inputFormMixin } from "../../../mixins/inputMixin";

export default {
    name: "EqsText",
    components: {
        EqsLabel: () => import("../EqsLabel/EqsLabel.vue"),
        EqsEditable: () => import("../EqsEditable/EqsEditable.vue"),
        EqsSkeleton: () => import("../EqsSkeleton/EqsSkeleton.vue")
    },
    mixins: [eqsUITemplate, inputFormMixin],
    props: {
        elClass: {
            type: String,
            default: "eqs-text"
        },
        value: {
            validator: prop => typeof prop === "string" || prop === null || prop === undefined,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        getClassFromState() {
            let res = "eqs-text__input";
            if (this.readonly) res += " read-only";
            return res;
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/atoms/EqsText.scss";
</style>
