<template>
    <v-row class="mb-10">
        <v-col cols="auto">
            <v-avatar rounded size="72">
                <v-img :src="item.photo ? item.photo.upload : '/assets/individual.svg'" />
            </v-avatar>
        </v-col>
        <v-col cols="auto">
            <v-row>
                <div class="font-weight-bold text-subtitle-1">
                    {{ item.name }}
                </div>
            </v-row>
            <v-row>
                <div class="font-weight-medium text-subtitle-2">
                    {{ item.role }}
                </div>
            </v-row>
            <v-row v-if="item.linkedin" class="mt-2">
                <v-col class="pa-0 ma-0">
                    <a :href="item.linkedin" target="_blank">
                        <v-img :src="icon" width="18px" style="cursor: pointer" />
                    </a>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "SinglePeopleDisplay",
    props: {
        item: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            icon: "/icons/linkedin-logo.svg"
        };
    },
    methods: {}
};
</script>

<style lang="scss" scoped></style>
