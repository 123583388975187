<template>
    <transition name="eqs-slide-from-right" class="d-flex fill-width">
        <div v-if="isRoot" class="d-flex flex-column">
            <EqsTabs v-bind="{ tabs }" :value.sync="tab">
                <template #default="{ item }">
                    <component
                        :is="item.is"
                        :selected.sync="selected"
                        v-bind="{
                            loading,
                            ...item
                        }"
                        @open-tx-info="openTxInfo"
                    />
                </template>
            </EqsTabs>
            <GlobalExplorerDialog :value.sync="qrDialog" v-bind="{ link: qrcodeLink, tx: qrTx }" />
        </div>
        <router-view
            v-else
            v-bind="{ loading }"
            @close="
                () =>
                    $router.push({
                        name: 'history',
                        params: { id: $entity.represented().id }
                    })
            "
        />
    </transition>
</template>

<script>
const ContractTransactions = () => import("./ContractTransactions");
const BlockchainTransactions = () => import("./BlockchainTransactions");
const Library = () => import("./library/_Library");

import { getSlBlockchain } from "@/services/blockchain/utils";

export default {
    name: "History",
    components: {
        ContractTransactions,
        BlockchainTransactions,
        Library
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tab: "library",
            selected: null,
            qrcodeLink: "",
            qrTx: "",
            qrDialog: false
        };
    },
    computed: {
        tabs() {
            return [
                {
                    id: "library",
                    title: this.$t("library.title"),
                    item: {
                        is: "Library"
                    }
                },
                {
                    id: "movements",
                    // only asset movements with entity as source / receiver
                    title: this.$t("transaction.blockchain-movements"),
                    item: {
                        is: "ContractTransactions",
                        items: this.shareledgerBlockchainTransactions
                    }
                },
                {
                    id: "blockchain",
                    // all blockchain transactions of user
                    title: this.$t("transaction.blockchain"),
                    item: {
                        is: "BlockchainTransactions"
                    }
                }
            ];
        },
        isRoot() {
            return this.$route.name === "history";
        }
    },
    methods: {
        openTxInfo(item) {
            this.qrDialog = true;
            let blockchain = item.blockchain;
            if (!blockchain) {
                blockchain = getSlBlockchain(item.shareledger);
            }
            const explorer = blockchain.explorer;
            this.qrTx = item.txhash;
            this.qrcodeLink = `${explorer}${item.txhash}`;
        }
    }
};
</script>
