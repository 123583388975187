<template>
    <div class="d-flex flex-column fill-height fill-width overflow-y pa-0">
        <Organs
            editable
            hide-default-footer
            v-bind="{
                height: '100%',
                loading,
                add: {
                    fn: addOrgan,
                    txt: $t('governance.organs.add')
                },
                noDataCustom: {
                    title: $t('governance.organs.no-data-title'),
                    text: $t('governance.organs.no-data-text'),
                    img: '/images/governance/organe.svg'
                },
                ...$attrs
            }"
            v-on="$listeners"
        />
        <Representatives
            editable
            v-bind="{
                height: '100%',
                loading,
                add: {
                    fn: addRepresentative,
                    txt: $t('governance.representatives.add')
                },
                searchable: {
                    placeholder: $t('governance.representatives.search')
                },
                noDataCustom: {
                    title: $t('governance.representatives.no-data-title'),
                    text: $t('governance.representatives.no-data-text'),
                    img: '/images/governance/mandats.svg'
                },
                itemsPerPage: 10,
                ...$attrs
            }"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import Representatives from "@/components/views/governance/representatives/_Representatives";
import Organs from "@/components/views/governance/organs/_Organs";

export default {
    name: "OrgansAndRepresentatives",
    components: {
        Representatives,
        Organs
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        addRepresentative() {
            this.$router.push({
                name: "governance-representative-add",
                params: this.$route.params
            });
        },
        addOrgan() {
            this.$router.push({
                name: "governance-organ-add",
                params: this.$route.params
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
