var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-avatar",style:(_vm.getStyle),attrs:{"el-class":_vm.elClass}},[_c('EqsBadge',_vm._b({class:`d-flex justify-center align-center`,attrs:{"value":!!_vm.user && !_vm.loading && !_vm.noBadge,"overlap":"","avatar":""}},'EqsBadge',_vm.userProps,false),[_c('EqsSkeleton',_vm._b({},'EqsSkeleton',{ loading: _vm.loading, type: 'avatar' },false),[_c('v-avatar',_vm._g(_vm._b({attrs:{"rounded":!!_vm.user && _vm.user.is_company,"color":_vm.avatar ? _vm.$color.colors.lighterGrey : _vm.$color.colorFromHashAvatar(_vm.initials)}},'v-avatar',{ ..._vm.$attrs, ..._vm.getData },false),_vm.$listeners),[_c('v-img',_vm._b({scopedSlots:_vm._u([(!_vm.avatar)?{key:"placeholder",fn:function(){return [_c('span',{staticClass:"white--text",style:(`
              font-size: ${_vm.getFontSize};
              height: 100%;
              width: 100%;
              justify-content: center;
              display: flex;
              align-items: center;
            `),attrs:{"gradient":_vm.gradient}},[_vm._v(" "+_vm._s(_vm.initials)+" ")])]},proxy:true}:null],null,true)},'v-img',{
                        src: _vm.avatar,
                        ..._vm.getData,
                        gradient: _vm.gradient,
                        contain: !!_vm.user && _vm.user.is_company
                    },false),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }