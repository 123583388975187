import DEBUG from "@/store/debug.js";

export default async ({ state, commit, dispatch }, transactionId, value) => {
    DEBUG("FETCH_TRANSACTION_START");
    if (!transactionId) throw new Error("no transactionId");
    if (!state.transactions.length) {
        // todo: not necessary if logic set in History.vue
        try {
            await dispatch("getTransactions");
        } catch (e) {
            throw e;
        }
    }
    try {
        const res = await window.app.$api.getTransaction(transactionId);
        const index = state.transactions.findIndex(_transaction => _transaction.id === res.data.id);
        if (index >= 0) {
            state.transactions[index] = res.data;
            if (value && "confirmation_blocks" in value) {
                console.log(`confirmation blocks !`);
                state.transactions[index].confirmation_blocks = value.confirmation_blocks;
            }
            commit("SET_TRANSACTIONS", state.transactions);
        } else commit("SET_TRANSACTIONS", [res.data, ...state.transactions]);

        if (window.app.$blockchain.txWatching(res.data)) {
            console.log({ txwatching: res.data });
            window.app.$blockchain.pollTransactionExplorer(res.data.id, false, true);
        }
        DEBUG("FETCH_TRANSACTION_SUCCESS");
    } catch (e) {
        console.error({ e });
        DEBUG("FETCH_TRANSACTION_ERROR");
        throw e;
    }
};
