import DEBUG from "@/store/debug.js";

export default async ({ state, commit }, removeCountriesId) => {
    if (!removeCountriesId) throw new Error("no removeCountriesId");
    if (!state.removeCountriesLoading) {
        try {
            commit("SET_SETLIMIT_LOADING", true);
            const res = await window.app.$api.getTransaction(removeCountriesId);
            commit("GET_REMOVECOUNTRIES", res.data);
        } catch (e) {
            DEBUG("GET_REMOVECOUNTRIES_ERROR");
            console.error({ e });
            throw e;
        } finally {
            commit("SET_REMOVECOUNTRIES_LOADING", false);
        }
    }
};
