"use strict";

export const GREY = "FFA9A9A9";
export const BLUE = "FF244284";
export const BLACK = "00000000";
export const RED = "FF0000";
export const WHITE = "FFFFFF";

export const Style = {
    alignment(vertical, horizontal, wrapText) {
        return {
            vertical,
            horizontal,
            wrapText
        };
    },
    borderStyle(style, color) {
        return {
            style,
            color: {
                argb: color
            }
        };
    },
    borderAll(style, color) {
        return {
            top: this.borderStyle(style, color),
            right: this.borderStyle(style, color),
            bottom: this.borderStyle(style, color),
            left: this.borderStyle(style, color)
        };
    },
    font(fontFamily, color, size) {
        return {
            name: fontFamily,
            size,
            color: {
                argb: color
            }
        };
    },
    backgroundColor(bgColor, fgColor) {
        return {
            type: "pattern",
            pattern: "solid",
            fgColor: {
                argb: fgColor
            },
            bgColor: {
                argb: bgColor
            }
        };
    },

    apply(table, style) {
        if (!style) return table;
        return table.map(el => {
            if (el.children) el.children = this.apply(el.children, style);
            el.style = style;
            return el;
        });
    },

    table(color, size) {
        return {
            alignment: this.alignment("middle", "center", true),
            border: this.borderAll("medium", color),
            font: this.font("Poppins", color, size)
        };
    },

    get header() {
        return this.table(BLUE);
    },

    get content() {
        return this.table(GREY);
    },

    get titleWs() {
        return this.table(BLACK, 20);
    },

    get worksheet() {
        return {
            pageSetup: {
                paperSize: 9,
                orientation: "landscape"
            },
            properties: {
                tabColor: {
                    argb: "FF244284"
                }
            }
        };
    },

    get info() {
        return {
            key: {
                font: this.font("Poppins", BLUE, 12),
                alignment: this.alignment("middle", "left", true)
            },
            value: {
                font: this.font("Poppins", GREY, 12),
                alignment: this.alignment("middle", "left", true)
            }
        };
    },

    get bgWarning() {
        return {
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                    argb: RED
                }
            },
            font: this.font("Poppins", WHITE, 12)
        };
    },

    addFooterPrint(ws) {
        ws.headerFooter.oddFooter = "&LEquisafe&C&F&RPage &P";
    }
};
