<template>
    <div v-if="actions.length" class="eqs-actions" :style="getStyle">
        <v-menu offset-y :content-class="`${elClass}-menu`" rounded>
            <template #activator="{ attrs, on }">
                <v-btn
                    small
                    icon
                    v-bind="{
                        ...attrs,
                        loading: loading || indexLoading >= 0,
                        color: indexLoading >= 0 ? actions[indexLoading].color : '',
                        elClass: `${elClass}-activator`
                    }"
                    v-on="on"
                >
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-hover v-for="(item, index) in actions" :key="index" v-slot="{ hover }">
                    <div class="ma-0 pa-0">
                        <v-list-item
                            :class="`${elClass}-menu-${index}`"
                            link
                            :color="item.color || $color.colors.eqsBlue"
                            active-class="eqs-dropdown-profile-drawer-active"
                            :style="
                                !hover && !!item.bgColor ? `background-color:${item.bgColor}` : ''
                            "
                            :input-value="hover"
                            :disabled="indexLoading >= 0"
                            @click="() => executeAction(item.fn, index)"
                        >
                            <v-list-item-icon v-if="item.icon" class="mr-2">
                                <v-icon
                                    v-if="index !== indexLoading"
                                    small
                                    :color="item.color || $color.colors.eqsBlue"
                                >
                                    {{ item.icon }}
                                </v-icon>
                                <v-progress-circular v-else indeterminate />
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">
                                    {{ item.txt }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-hover>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsActions.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsActions",
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: ""
        },
        actions: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            indexLoading: -1
        };
    },
    methods: {
        async executeAction(action, index) {
            this.indexLoading = index;
            this.$emit("update:loading", true);
            try {
                await action();
            } catch (e) {
                this.$emit("error", e);
            } finally {
                this.$emit("update:loading", false);
                this.indexLoading = -1;
                this.$emit("action:finished");
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsActions.scss";
</style>
