var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EqsCard',_vm._b({staticClass:"d-flex flex-column fill-height",attrs:{"title":_vm.company ? _vm.company.name : '',"closable":"","eqs-type":"overflow"},on:{"close":() => _vm.$router.push({ name: 'secondary-market', params: _vm.$route.params })},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',{staticClass:"justify-left d-flex"},[_c('v-col',{attrs:{"cols":_vm.$reactive.isDesktop() ? 6 : 12}},[_c('CompanyCard',_vm._b({},'CompanyCard',{
                        company: _vm.company
                    },false))],1),(_vm.youtubeLink)?_c('v-col',{staticStyle:{"min-height":"420px"},attrs:{"cols":_vm.$reactive.isDesktop() ? 6 : 12}},[_c('iframe',{staticStyle:{"border-radius":"0.625rem","box-shadow":"0 2px 0 rgba(90, 97, 105, 0.11),"},attrs:{"width":"100%","height":"100%","src":_vm.youtubeLink,"frameborder":"0","allow":"autoplay; encrypted-media","allowfullscreen":""}})]):_vm._e()],1),_c('v-row',{staticClass:"px-3"},[_c('EqsTabs',_vm._b({attrs:{"value":_vm.tab},on:{"update:value":function($event){_vm.tab=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c(item.is,_vm._b({tag:"component"},'component',{
                            ...item.props
                        },false))]}}])},'EqsTabs',{ tabs: _vm.tabs },false))],1)]},proxy:true}])},'EqsCard',{
        loading: _vm.loading || _vm.localLoading,
        height: '100%',
        closeInfoTxt: _vm.$t('secondary-market-details.come-back')
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }