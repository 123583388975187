<template>
    <Representatives
        v-if="representativesMembers.length"
        hide-default-footer
        v-bind="{
            items: representativesMembers,
            editable: false,
            subtitle: $t('governance.organs.details.office.title')
        }"
    />
</template>

<script>
import Representatives from "../../representatives/_Representatives";

export default {
    name: "Office",
    components: {
        Representatives
    },
    props: {
        organ: {
            type: Object,
            required: true
        }
    },
    computed: {
        representativesMembers() {
            return this.organ.members
                ? this.organ.members
                      .filter(member => !!member.representative)
                      .map(item => item.representative)
                : [];
        }
    }
};
</script>
