<template>
    <EqsViewWithImage v-bind="{ img: '/images/Vecteur_Vente.svg' }">
        <div class="d-flex flex-column">
            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                    <EqsTextDisplay paragraph v-bind="{ loading }">
                        {{ $t("secondary-market.sell-shares.shares-form.text-1") }}
                    </EqsTextDisplay>
                </v-col>
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <EqsSelect
                        :value.sync="formData.shareledger"
                        :items="shareledgers"
                        item-text="shareledger.name"
                        persistent-hint
                        return-object
                        v-bind="{
                            label: $t('sellShares.label.shareledger'),
                            loading,
                            clearable: false,
                            hint: isOnlyInvestorsTxt(formData.shareledger)
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <EqsNumberShares
                        :value.sync="formData.quantity"
                        :rules="[maxShares]"
                        persistent-hint
                        v-bind="{
                            elClass: 'quantity',
                            label: $t('sellShares.label.numberOfShares'),
                            loading,
                            hint: $t('sellShares.label.hint', {
                                number: formData.shareledger.balance
                            })
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <EqsNumberShares
                        :value.sync="formData.min_quantity"
                        :rules="[minQuantity]"
                        v-bind="{
                            elClass: 'min_quantity',
                            label: $t('sellShares.label.minQuantity'),
                            loading
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <EqsNumberShares
                        :value.sync="formData.max_quantity"
                        :rules="[maxQuantity]"
                        v-bind="{
                            elClass: 'max_quantity',
                            label: $t('sellShares.label.maxQuantity'),
                            loading
                        }"
                    />
                </v-col>

                <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                    <EqsNumber
                        :value.sync="formData.price"
                        is-currency
                        :rules="[greaterThanZeroR]"
                        v-bind="{
                            elClass: 'price',
                            label: $t('sellShares.label.pricePerShare'),
                            loading,
                            hint: $t('sellShares.label.pricehint', {
                                number: this.$currency.display(
                                    this.formData.shareledger.shareledger.lastpricepershare
                                )
                            })
                        }"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3">
                <v-col :cols="$reactive.getCols('third')" class="eqs-form-field">
                    <EqsText
                        :value="share_types[formData.shareledger.share_type].name"
                        :editable="false"
                        return-object
                        v-bind="{
                            label: $t('sellShares.label.shareType'),
                            loading
                        }"
                    />
                </v-col>
                <v-col :cols="$reactive.getCols('third')" class="eqs-form-field">
                    <EqsNumber
                        :value="totalSell"
                        is-currency
                        :editable="false"
                        v-bind="{
                            label: $t('sellShares.label.total-sell'),
                            loading
                        }"
                    />
                </v-col>

                <v-col :cols="$reactive.getCols('third')" class="eqs-form-field">
                    <EqsNumber
                        :value="totalFees"
                        is-currency
                        :editable="false"
                        v-bind="{
                            label: $t('sellShares.label.total-fees'),
                            loading
                        }"
                    />
                </v-col>

                <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                    <AdditionalDocsCrud
                        deletable
                        v-bind="{
                            ...$props,
                            label: $t(
                                'secondary-market.sell-shares.shares-form.documents-crud-label'
                            )
                        }"
                    />
                </v-col>
            </v-row>
        </div>
    </EqsViewWithImage>
</template>

<script>
import AdditionalDocsCrud from "./additionalDocsCrud/_AdditionalDocsCrud";
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";
import { greaterThanZeroR } from "@equisafe-ui-vue/src/rules";
import { share_types } from "@/services/shareledger/shareType";

export default {
    name: "SharesForm",
    components: {
        AdditionalDocsCrud
    },
    mixins: [formMixin],
    props: {
        shareledgers: {
            type: Array,
            required: true
        },
        sellerFees: {
            type: Number,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            greaterThanZeroR,
            displayDocTable: false,
            share_types
        };
    },
    computed: {
        maxShares() {
            return (
                !!(this.formData.quantity <= this.formData.shareledger.balance) ||
                this.$t("sellShares.error.quantity.max-shares", {
                    quantity: this.$currency.display(this.formData.shareledger.balance, "0,0")
                })
            );
        },
        maxQuantity() {
            return (
                !!(this.formData.max_quantity <= this.formData.quantity) ||
                this.$t("sellShares.error.quantity.max-quantity", {
                    quantity: this.$currency.display(this.formData.quantity, "0,0")
                })
            );
        },
        minQuantity() {
            return (
                !!(this.formData.min_quantity <= this.formData.quantity) ||
                this.$t("sellShares.error.quantity.min-quantity", {
                    quantity: this.$currency.display(this.formData.quantity, "0,0")
                })
            );
        },
        totalSell() {
            return !!this.formData.shareledger ? this.calculateTotalSell() : 0;
        },
        totalFees() {
            return !!this.formData.shareledger ? this.calculateTotalSell() * this.sellerFees : 0;
        }
    },
    methods: {
        isOnlyInvestorsTxt(shareledger) {
            const { share_only_investor_transfer } = shareledger.shareledger;
            return share_only_investor_transfer
                ? this.$t("secondary-market.sell-shares.shares-form.only-investors")
                : this.$t("secondary-market.sell-shares.shares-form.any-person");
        },
        addDocuments() {
            this.displayDocTable = true;
        },
        calculateTotalSell() {
            return this.formData.quantity * this.formData.price;
        }
    }
};
</script>
