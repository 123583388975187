var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-main-container margin-auto"},[_c('div',{staticClass:"d-flex flex-column fill-height"},[_c('v-row',{staticClass:"fill-height px-3"},[_c('v-col',{attrs:{"cols":_vm.$reactive.isDesktop() ? 6 : 12,"order":_vm.$reactive.isDesktop() ? 1 : 2}},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field pt-0 d-flex flex-column"},[_c('EqsTextDisplay',_vm._b({},'EqsTextDisplay',{
                                loading: _vm.loading
                            },false),[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.isOnlyInvestorsTxt(_vm.order.shareledger))+" ")])]),_c('EqsTextDisplay',_vm._b({staticClass:"pt-3",attrs:{"paragraph":""}},'EqsTextDisplay',{
                                loading: _vm.loading,
                                value: _vm.$t('secondary-market.sell-shares.finalize.text-1')
                            },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.order.shareledger.name,"items":_vm.shareledgers,"item-text":"shareledger.name","return-object":"","disabled":"","readonly":"","editable":false},on:{"update:value":function($event){return _vm.$set(_vm.order.shareledger, "name", $event)}}},'EqsSelect',{
                                elClass: 'shareledger_name',
                                label: _vm.$t('sellShares.label.shareledger'),
                                loading: _vm.loading
                            },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsNumberShares',_vm._b({attrs:{"value":_vm.order.quantity,"editable":false}},'EqsNumberShares',{
                                elClass: 'quantity',
                                label: _vm.$t('sellShares.label.numberOfShares'),
                                loading: _vm.loading
                            },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsText',_vm._b({attrs:{"value":_vm.share_types[_vm.order.shareledger.share_type].name,"editable":false,"return-object":""}},'EqsText',{
                                elClass: 'shares_type',
                                label: _vm.$t('sellShares.label.shareType'),
                                loading: _vm.loading
                            },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.order.price,"is-currency":true,"editable":false}},'EqsNumber',{
                                elClass: 'price',
                                label: _vm.$t('sellShares.label.pricePerShare'),
                                loading: _vm.loading
                            },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.totalSell,"is-currency":true,"editable":false}},'EqsNumber',{
                                elClass: 'total_sell',
                                label: _vm.$t('sellShares.label.total-sell'),
                                loading: _vm.loading
                            },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsNumber',_vm._b({attrs:{"value":_vm.totalFees,"is-currency":true,"editable":false}},'EqsNumber',{
                                elClass: 'total_fees',
                                label: _vm.$t('sellShares.label.total-fees'),
                                loading: _vm.loading
                            },false))],1)],1),(_vm.order.docs.length)?_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('AdditionalDocsCrud',_vm._b({attrs:{"docs":_vm.order.docs,"editable":false}},'AdditionalDocsCrud',{
                                label: _vm.$t(
                                    'secondary-market.sell-shares.finalize.documents-crud-label'
                                ),
                                loading: _vm.loading,
                                formData: _vm.order,
                                schemaDouble: {}
                            },false))],1)],1):_vm._e(),(
                        _vm.signature_procedure.proceduredocument_set &&
                        _vm.signature_procedure.proceduredocument_set.length
                    )?_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field"},[_c('EqsFileInput',_vm._b({attrs:{"value":_vm.signature_procedure.proceduredocument_set.map(
                                    doc => doc.document
                                ),"editable":false,"multiple":""}},'EqsFileInput',{
                                label: _vm.$t(
                                    'secondary-market.sell-shares.finalize.docstosign-label'
                                ),
                                loading: _vm.loading
                            },false))],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":_vm.$reactive.isDesktop() ? 6 : 12,"order":_vm.$reactive.isDesktop() ? 2 : 1}},[_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"height":_vm.$reactive.getElementHeight(),"src":"/images/Vecteur_Vente.svg"}})])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }