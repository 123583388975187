var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eqs-crud"},[_c('EqsList',_vm._b({style:(_vm.getStyle),attrs:{"items":_vm.itemsProxy},on:{"update:items":function($event){_vm.itemsProxy=$event}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),{key:"group-content",fn:function(props){return [_c('InternalCrudEdit',_vm._b({attrs:{"loading":_vm.localLoading},on:{"update:loading":function($event){_vm.localLoading=$event}},scopedSlots:_vm._u([{key:"edit",fn:function({ formData, loading: editLoading, editable: isEditable }){return [_vm._t("edit",null,null,{
                            formData,
                            index: props.index,
                            loading: editLoading || _vm.loading,
                            editable: isEditable
                        })]}}],null,true)},'InternalCrudEdit',{
                    ...props,
                    editable: _vm.editable,
                    update: _vm.update,
                    deleteFunc: _vm.deleteFunc,
                    elClass: `${_vm.elClass}-edit-${props.index}`,
                    valToCmp: Object.keys(_vm.formInit)
                },false))]}},{key:"add",fn:function(props){return [_c('InternalCrudCreate',_vm._b({attrs:{"loading":_vm.localLoading || _vm.loading},on:{"update:loading":val => (_vm.localLoading = val)},scopedSlots:_vm._u([{key:"create",fn:function({ formData, loading: createLoading }){return [_vm._t("create",null,null,{
                            formData,
                            index: -1,
                            loading: createLoading || _vm.loading
                        })]}}],null,true)},'InternalCrudCreate',{ ...props, add: _vm.add, formInit: _vm.formInit, elClass: `${_vm.elClass}-create` },false))]}}],null,true)},'EqsList',{
            ..._vm.$props,
            ..._vm.$attrs,
            eqsType: _vm.eqsListType,
            editable: _vm.editable,
            loading: _vm.loading,
            elClass: `${_vm.elClass}-list`,
            disabled: _vm.localLoading,
            ..._vm.getData
        },false),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }