<template>
    <v-container fluid>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="searchLabel"
                single-line
                hide-details
            />
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            sort-by="date"
            sort-desc
            :custom-sort="customSort"
            fixed-header
            disable-pagination
            :hide-default-footer="true"
            item-key="id"
            :loading="loading"
            class="row-pointer"
            @click:row="handleRowClick"
        >
            <template #top>
                <v-toolbar v-if="dataTableTitle !== ''" flat>
                    <v-toolbar-title>{{ dataTableTitle }}</v-toolbar-title>
                </v-toolbar>
            </template>
            <template #[`item.date`]="{ item }">
                {{ item.date }}
            </template>
            <template #[`item.deal`]="{ item }">
                {{ item.deal }}
            </template>
            <template #[`item.name`]="{ item }">
                <div class="icon-with-text">
                    <v-icon v-if="item.is_company" class="custom-left-icon">mdi-domain</v-icon>
                    <span>{{ item.name }}</span>
                </div>
            </template>
            <template #[`item.parent`]="{ item }">
                {{ item.parent }}
            </template>
            <template #[`item.statusKyc`]="{ item }">
                <KycStatus :loading="loading" :entity="item.entity" />
            </template>

            <template #[`item.valueProvided`]="{ item }">
                {{
                    item.valueProvided !== null ? $currency.display(item.valueProvided) : "-"
                }}</template
            >
            <template #[`item.nRelatedCustomers`]="{ item }">
                {{ item.nRelatedCustomers }}
            </template>
            <template #[`item.status`]="{ item }">
                <StatusComponent
                    v-bind="{
                        target: item.status.target,
                        isAdmin: item.status.isAdmin
                    }"
                />
            </template>
            <template #[`item.action`]="{ item }">
                <div class="action-text" @click.stop="item.action.action">
                    <span>{{ item.action.name }}</span>
                    <v-icon class="arrow-icon">mdi-arrow-right</v-icon>
                </div>
            </template>
            <template #no-data>{{ $t("universe.kyoseil.tab.search-no-data") }}</template>
            <template #no-results>{{ $t("universe.kyoseil.tab.search-no-data") }}</template>
        </v-data-table>
    </v-container>
</template>

<script>
import StatusComponent from "../components/StatusComponent.vue";
import KycStatus from "../components/KycStatus";

import { getNextRoute, isRootPage } from "@/components/views/user/clients/tools.js";

export default {
    name: "DataTable",
    components: { StatusComponent, KycStatus },
    inject: ["_entity"],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        dataTable: {
            type: Array,
            required: true
        }
    },
    emits: ["open-cgp-modal"],
    data() {
        return {
            headers: [],
            items: [],
            isRowClickable: false,
            dataTableTitle: "",
            search: "",
            searchKey: "name",
            searchLabel: ""
        };
    },
    watch: {
        dataTable: {
            handler: function () {
                let tmpTitle = "";
                switch (this.type) {
                    // TODO design et trads avec le titre de dashboard
                    case "view_admin":
                        tmpTitle = this.$t("universe.kyoseil.tab.title-bc");
                        this.searchKey = "name";
                        this.searchLabel = this.$t("universe.kyoseil.search.by-name");
                        break;
                    case "view_bc":
                        tmpTitle = this.$t("universe.kyoseil.tab.title-cgp");
                        this.searchKey = "name";
                        this.searchLabel = this.$t("universe.kyoseil.search.by-name");
                        break;
                    case "view_cgp":
                        tmpTitle = this.$t("universe.kyoseil.tab.title-investor");
                        this.searchKey = "name";
                        this.searchLabel = this.$t("universe.kyoseil.search.by-name");
                        break;
                    case "view_investor":
                    default:
                        tmpTitle = this.$t("universe.kyoseil.tab.title-deal");
                        this.searchKey = "deal";
                        this.searchLabel = this.$t("universe.kyoseil.search.by-deal");
                        break;
                }
                if (isRootPage(this.$route)) {
                    tmpTitle = "";
                }
                this.dataTableTitle = tmpTitle;
                this.constructTable(this.dataTable);
            },
            immediate: true
        }
    },
    methods: {
        customSort(items, index, isDesc) {
            index = index[0];
            isDesc = isDesc[0];
            let sortFun = null;
            if (index === "date") {
                sortFun = (a, b) => {
                    return a.date_full - b.date_full;
                };
            } else if (index === "name") {
                sortFun = (a, b) => {
                    let textValueA = a[index].text || "-";
                    let textValueB = b[index].text || "-";
                    return textValueA.toLowerCase().localeCompare(textValueB.toLowerCase());
                };
            } else if (index === "deal") {
                sortFun = (a, b) => {
                    let textValueA = a[index] || "-";
                    let textValueB = b[index] || "-";
                    return textValueA.toLowerCase().localeCompare(textValueB.toLowerCase());
                };
            } else if (["statusKyc", "status"].includes(index)) {
                sortFun = (a, b) => {
                    let textValueA = a[index] || "-";
                    let textValueB = b[index] || "-";
                    if (index === "statusKyc") {
                        textValueA = a.entity.lemonway_state ? a.entity.lemonway_state : "waiting";
                        textValueB = b.entity.lemonway_state ? b.entity.lemonway_state : "waiting";
                    } else if (index === "status") {
                        textValueA = a[index].target.attributes[0].validation_status;
                        textValueB = b[index].target.attributes[0].validation_status;
                    }
                    const success = ["success", "Accepted"];
                    const wait = ["upload", "pending", "TBD", "waiting", ""];
                    const error = ["failure", "upload-failure", "Rejected"];
                    // Always first or last
                    if (success.includes(textValueA) && error.includes(textValueB)) return -1;
                    if (success.includes(textValueA) && wait.includes(textValueB)) return -1;
                    // Always opposite of success
                    if (error.includes(textValueA) && success.includes(textValueB)) return 1;
                    if (error.includes(textValueA) && wait.includes(textValueB)) return 1;
                    // Else in middle
                    if (wait.includes(textValueA) && success.includes(textValueB)) return 1;
                    if (wait.includes(textValueA) && error.includes(textValueB)) return -1;
                    return 0;
                };
            } else {
                sortFun = (a, b) => {
                    return a[index] < b[index] ? -1 : 1;
                };
            }
            items.sort((a, b) => {
                const r = sortFun(a, b);
                return !isDesc ? r : -r;
            });
            return items;
        },
        goToSeeMore(type, id, name) {
            // Affiche la modal infos CGP pour un BC
            if (type === "view_bc" && this.$entity.isAffiliationBc()) {
                this.$emit("open-cgp-modal", id);
                return;
            }
            let nextType = "";
            let idObject = {};
            if (type === "view_admin") {
                nextType = "view_bc";
                idObject.bc_id = id;
            } else if (type === "view_bc") {
                nextType = "view_cgp";
                idObject.cgp_id = id;
            } else if (type === "view_cgp") {
                nextType = "view_investor";
                idObject.investor_id = id;
            } else {
                return;
            }
            this.$router.push({
                name: getNextRoute(this.$route, nextType),
                params: {
                    type: nextType,
                    entityName: name,
                    ...idObject
                }
            });
        },
        handleRowClick(item, vRow, e) {
            if (this.isRowClickable) {
                this.goToSeeMore(this.type, item.id, item.name.text);
            }
        },
        constructTable(dataTable) {
            let tmpHeaders = [];
            let tmpItems = [];

            // HEADERS
            tmpHeaders.push({
                text: this.$t("universe.kyoseil.tab.date"),
                value: "date",
                sortable: true,
                filterable: false,
                align: "left"
            });
            if (["view_investor"].includes(this.type)) {
                tmpHeaders.push({
                    text: this.$t("universe.kyoseil.tab.deal"),
                    value: "deal",
                    sortable: true,
                    filterable: this.searchKey === "deal",
                    align: "left"
                });
            }
            if (!["view_investor"].includes(this.type)) {
                tmpHeaders.push({
                    text: this.$t("universe.kyoseil.tab.name"),
                    value: "name",
                    sortable: true,
                    filterable: this.searchKey === "name",
                    align: "left"
                });

                tmpHeaders.push({
                    text: this.$t("universe.kyoseil.tab.status-kyc"),
                    value: "statusKyc",
                    sortable: true,
                    filterable: false,
                    align: "left"
                });
            }
            if (["view_cgp", "view_bc"].includes(this.type) && this.$entity.isAffiliationAdmin()) {
                tmpHeaders.push({
                    text: this.type === "view_cgp" ? "CGP" : "AA",
                    value: "parent",
                    sortable: true,
                    filterable: this.searchKey === "parent",
                    align: "left"
                });
            }
            if (["view_bc", "view_admin"].includes(this.type)) {
                tmpHeaders.push({
                    text: this.$t("universe.kyoseil.tab.link-client"),
                    value: "nRelatedCustomers",
                    sortable: true,
                    filterable: false,
                    align: "left"
                });
            }
            if (["view_investor", "view_cgp", "view_bc", "view_admin"].includes(this.type)) {
                tmpHeaders.push({
                    text: this.$t("universe.kyoseil.tab.value"),
                    value: "valueProvided",
                    sortable: true,
                    filterable: false,
                    align: "left"
                });
            }
            if (["view_bc", "view_admin"].includes(this.type)) {
                tmpHeaders.push({
                    text: this.$t("universe.kyoseil.tab.status"),
                    value: "status",
                    sortable: true,
                    filterable: false,
                    align: "left"
                });
            }
            if (["view_admin", "view_cgp", "view_bc"].includes(this.type)) {
                this.isRowClickable = true;
                tmpHeaders.push({
                    text: this.$t("universe.kyoseil.tab.action"),
                    value: "action",
                    sortable: false,
                    filterable: false,
                    align: "left"
                });
            }

            // ITEMS
            dataTable.forEach(e => {
                let tmpItem = {
                    id: e.id
                };
                if (["view_investor"].includes(this.type)) {
                    tmpItem.date = new Date(e.created_at).toLocaleDateString();
                    tmpItem.date_full = new Date(e.created_at).valueOf();
                } else {
                    tmpItem.date = new Date(e.date_joined).toLocaleDateString();
                    tmpItem.date_full = new Date(e.date_joined).valueOf();
                }
                if (!["view_investor"].includes(this.type)) {
                    tmpItem.name = e.name || "-";
                    tmpItem.is_company = e.is_company || false;
                    tmpItem.entity = e;
                }
                if (
                    ["view_cgp", "view_bc"].includes(this.type) &&
                    this.$entity.isAffiliationAdmin()
                ) {
                    if (this.type === "view_cgp") {
                        tmpItem.parent = e?.parent ?? "nc";
                    } else {
                        tmpItem.parent = e?.attributes[0]?.demanding_entity?.name ?? "nc";
                    }
                }
                if (["view_investor"].includes(this.type)) {
                    tmpItem.deal = e.deal_name;
                }

                if (["view_bc", "view_admin"].includes(this.type)) {
                    tmpItem.nRelatedCustomers = e.n_clients;
                }

                if (["view_investor", "view_cgp", "view_bc", "view_admin"].includes(this.type)) {
                    try {
                        tmpItem.valueProvided = parseFloat(e.total_price);
                    } catch {
                        tmpItem.valueProvided = null;
                    }
                }
                if (["view_bc", "view_admin"].includes(this.type)) {
                    tmpItem.status = {
                        target: e,
                        isAdmin: this.$entity.isAffiliationAdmin()
                    };
                }
                if (
                    ["view_bc", "view_admin"].includes(this.type) ||
                    (this.type === "view_cgp" && !this.$entity.isAffiliationBc())
                ) {
                    tmpItem.action = {
                        name: this.$t("universe.kyoseil.tab.see-more"),
                        action: () => this.goToSeeMore(this.type, e.id, e.name)
                    };
                }
                tmpItems.push(tmpItem);
            });

            this.headers = [...tmpHeaders];
            this.items = [...tmpItems];
        }
    }
};
</script>

<style lang="scss" scoped>
.action-text {
    > span {
        vertical-align: middle;
    }

    .arrow-icon {
        vertical-align: middle;
        margin-left: 4px;
        transition: transform 0.3s ease;
    }

    &:hover .arrow-icon {
        transform: translateX(1rem);
    }
}

.icon-with-text {
    > span {
        vertical-align: middle;
    }

    .custom-left-icon {
        vertical-align: middle;
        margin-right: 6px;
    }
}

:deep(.row-pointer) {
    tbody {
        tr {
            cursor: pointer;
        }
    }
}
</style>
