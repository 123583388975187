<template>
    <div class="d-flex flex-column">
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsSelect
                    :value.sync="formData.category"
                    v-bind="{
                        label: $t('global.global-doc-with-category-list.select-category-label'),
                        elClass: `${elClass}-category-${index}`,
                        loading,
                        editable,
                        items: categories
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('half')" class="eqs-form-field">
                <EqsFileInput
                    :value.sync="formData.document"
                    v-bind="{
                        elClass: `${elClass}-doc-${index}`,
                        label: $t('global.global-doc-with-category-list.select-file-label'),
                        deleteFile: false,
                        editable,
                        loading
                    }"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "InternalDocForm",
    props: {
        categories: {
            type: Array,
            required: true
        },
        elClass: {
            type: String,
            required: true
        },
        formData: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        }
    }
};
</script>
