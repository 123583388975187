<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <EqsAlert
                    v-bind="{
                        eqsType: 'warning',
                        text: $t('capital-increase.user.detail.cip-warning-unsecured-rates')
                    }"
                />
            </v-col>
            <v-col cols="12">
                <EqsAlert
                    v-bind="{
                        eqsType: 'warning',
                        text: $t('capital-increase.user.detail.cip-warning-capital-loss')
                    }"
                />
            </v-col>
        </v-row>
        <CrowdfundingFooter v-if="isCip" class="mt-6" />
    </v-container>
</template>

<script>
import CrowdfundingFooter from "../dashboard/CrowdfundingFooter.vue";
export default {
    name: "CipWarning",
    components: {
        CrowdfundingFooter
    },
    props: {
        isCip: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped></style>
