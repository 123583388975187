import store from "@/store/store.js";
import DEBUG from "@/store/debug.js";

export default async ({ commit }, representative) => {
    DEBUG("DELETE_REPRESENTATIVE_START");
    try {
        commit("SET_REPRESENTATIVES_LOADING", true);
        await window.app.$api.deleteRepresentative(representative.id);
        commit("DELETE_REPRESENTATIVE", representative.id);
        DEBUG("DELETE_REPRESENTATIVE_SUCCESS");
    } catch (e) {
        DEBUG("DELETE_REPRESENTATIVE_ERROR");
        throw e;
    } finally {
        commit("SET_REPRESENTATIVES_LOADING", false);
    }
};
