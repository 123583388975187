import Governance from "@/components/views/governance/_Governance.vue";
import DecisionStepper from "@/components/views/governance/decision/decisionStepper/_DecisionStepper";
import RepresentativeStepper from "@/components/views/governance/representatives/representativeStepper/_RepresentativeStepper";
import OrganStepper from "@/components/views/governance/organs/organStepper/_OrganStepper";
import OrganDetail from "@/components/views/governance/organs/organDetail/_OrganDetail";
import Soon from "@/components/views/Soon";

import multiguard from "vue-router-multiguard";
import { requiresCompany, informUserIfNotRegistered, informUserUpgradePlan } from "../routeGuards";
import { i18n } from "@/services/plugins/i18n.js";

export default [
    {
        name: "governance",
        path: "/governance/:id",
        component: window.location.hostname == "app.equisafe.io" ? Soon : Governance,
        props: true,
        beforeEnter: multiguard([informUserIfNotRegistered, informUserUpgradePlan]),
        meta: {
            context: i18n.t("app.navigation-drawer.title.governance")
        },
        children: [
            // decisions
            {
                beforeEnter: multiguard([
                    informUserIfNotRegistered,
                    requiresCompany,
                    informUserUpgradePlan
                ]),
                name: "governance-decision-add",
                path: "decisions/add",
                props: true,
                component: DecisionStepper,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.governance-decision-add")
                }
            },
            {
                beforeEnter: multiguard([
                    informUserIfNotRegistered,
                    requiresCompany,
                    informUserUpgradePlan
                ]),
                name: "governance-decision-edit",
                path: "decisions/edit/:decisionId",
                props: true,
                component: DecisionStepper,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.governance-decision-edit")
                }
            },
            // representatives
            {
                beforeEnter: multiguard([
                    informUserIfNotRegistered,
                    requiresCompany,
                    informUserUpgradePlan
                ]),
                name: "governance-representative-add",
                path: "representatives/add",
                props: true,
                component: RepresentativeStepper,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.governance-representative-add")
                }
            },
            {
                beforeEnter: multiguard([
                    informUserIfNotRegistered,
                    requiresCompany,
                    informUserUpgradePlan
                ]),
                name: "governance-representative-edit",
                path: "representatives/edit/:representativeId",
                props: true,
                component: RepresentativeStepper,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.governance-representative-edit")
                }
            },
            // organ
            // representatives
            {
                beforeEnter: multiguard([
                    informUserIfNotRegistered,
                    requiresCompany,
                    informUserUpgradePlan
                ]),
                name: "governance-organ-add",
                path: "organs/add",
                props: true,
                component: OrganStepper,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.governance-organ-add")
                }
            },
            {
                beforeEnter: multiguard([
                    informUserIfNotRegistered,
                    requiresCompany,
                    informUserUpgradePlan
                ]),
                name: "governance-organ-edit",
                path: "organs/edit/:organId",
                props: true,
                component: OrganStepper,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.governance-organ-edit")
                }
            },
            {
                beforeEnter: multiguard([
                    informUserIfNotRegistered,
                    requiresCompany,
                    informUserUpgradePlan
                ]),
                name: "governance-organ-detail",
                path: "organs/detail/:organId",
                props: true,
                component: OrganDetail,
                meta: {
                    context: i18n.t("app.navigation-drawer.title.governance-organ-detail")
                }
            }
        ]
    }
];
