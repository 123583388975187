var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`eqs-alert ${_vm.elClass}-container`,style:(_vm.getStyle)},[_c('v-alert',_vm._g(_vm._b({attrs:{"value":_vm.valueProxy},on:{"update:value":function($event){_vm.valueProxy=$event}}},'v-alert',{
            ..._vm.$attrs,
            text: _vm.accent && !_vm.loading,
            outlined: _vm.loading,
            ..._vm.getData,
            disabled: _vm.loading,
            dismissible: _vm.dismissible,
            border: 'left',
            color: _vm.loading ? _vm.$color.colors.lighterGrey : ''
        },false),_vm.$listeners),[_c('EqsSkeleton',_vm._b({},'EqsSkeleton',{ loading: _vm.loading, type: 'paragraph', minWidth: '200px' },false),[(_vm.text)?_c('EqsTextDisplay',_vm._b({attrs:{"paragraph":""}},'EqsTextDisplay',{ value: _vm.text },false)):_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }