<template>
    <v-lazy>
        <v-container fluid>
            <v-row class="px-3 align-start justify-start">
                <v-col v-if="!selected || $reactive.isDesktop()" class="pt-0" :cols="getCols()">
                    <EqsDataTable
                        el-class="shareledgers"
                        class="max-height-fit fill-width"
                        hide-default-footer
                        :add="add"
                        :headers="tableHeaders"
                        :items="dashboardData.shareledgers"
                        :loading="loading"
                        single-select
                        show-select
                        @update:selected="
                            val => {
                                openDetails(val);
                            }
                        "
                    >
                        <template #item.share_type="{ item }">
                            {{ share_types[item.share_type].name }}
                        </template>
                        <template #item.nominal="{ value }">
                            {{
                                $currency.displayWithCurrency(
                                    value,
                                    $entity.represented().country.alphacode == "US" ? "USD" : "EUR"
                                )
                            }}
                        </template>
                        <template #item.blockchain_state="{ value }">
                            <EqsChip
                                :eqs-type="
                                    value !== 'DIGITALIZATION_COMPLETE' ? 'warning' : 'success'
                                "
                            >
                                {{
                                    value !== "DIGITALIZATION_COMPLETE"
                                        ? $t("label.pending")
                                        : $t("label.finished")
                                }}
                            </EqsChip>
                        </template>
                    </EqsDataTable>
                </v-col>
                <v-col v-if="selected && selected.id" :cols="getCols()" class="pt-0">
                    <ShareledgerDetailsDialog
                        :shareledger-id="selected.id"
                        v-bind="{ shareledgers: dashboardData.shareledgers, loading }"
                        @close="() => (selected = null)"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-lazy>
</template>

<script>
import ShareledgerDetailsDialog from "@/components/views/shareledger/dialogs/details/ShareledgerDetailsDialog";
import { share_types } from "@/services/shareledger/shareType";

export default {
    name: "Parameters",
    components: {
        ShareledgerDetailsDialog
    },
    props: {
        dashboardData: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            share_types,
            add: {
                txt: this.$t("shareledger.list.cta"),
                fn: () =>
                    this.$router.push({
                        name: "digitalize-shareledger"
                    })
            },
            selected: null,
            tableHeaders: [
                {
                    text: this.$t("label.name"),
                    value: "name"
                },
                {
                    text: this.$t("capitalisation.parameters.share-type"),
                    value: "share_type"
                },
                {
                    text: this.$t("capitalisation.parameters.nominal-price"),
                    value: "nominal"
                },
                {
                    text: this.$t("label.digitalization"),
                    value: "blockchain_state"
                }
            ]
        };
    },
    methods: {
        openDetails(val) {
            if (val) this.selected = val[0];
            else this.selected = null;
        },
        getCols() {
            let cols = 12;
            if (this.selected && this.selected.id) return this.$reactive.isDesktop() ? 6 : 12;
            return cols;
        },
        viewShareledgerDetails(shareledger) {
            this.$router.push({
                name: "shareledger-details",
                params: {
                    shareledgerId: shareledger.id
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
