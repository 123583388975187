<template>
    <div class="eqs-main-container margin-auto fill-height">
        <v-row class="px-3">
            <v-col :cols="1" class="px-4">
                <EqsAvatar class="mx-4" :user="payment.credited_entity" :loading="loading" />
            </v-col>

            <v-col :cols="$reactive.getCols('full') - 1">
                <v-row class="px-4">
                    <EqsTextDisplay
                        eqs-type="title"
                        :value="payment.credited_entity ? payment.credited_entity.name : ''"
                        :loading="loading"
                    />
                </v-row>

                <v-row class="px-4">
                    <EqsTextDisplay
                        eqs-type="subtitle"
                        :value="
                            $t('payments.asked-txt', {
                                operation_type: payment_types[payment.operation_type]
                                    ? payment_types[payment.operation_type].txt
                                    : payment.operation_type
                            })
                        "
                        :loading="loading"
                    />
                </v-row>
            </v-col>
        </v-row>

        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half')">
                <EqsTextDisplay
                    :label="$t('payments.asked.label-amount')"
                    v-bind="{
                        eqsType: 'subtitle'
                    }"
                    :editable="false"
                    :value="$t('payments.asked.label-amount')"
                />
                <EqsTextDisplay
                    :label="$t('payments.asked.label-amount')"
                    v-bind="{
                        eqsType: 'title'
                    }"
                    :editable="false"
                    :value="$currency.displayWithCurrency(payment.amount, 'EUR')"
                    :loading="loading"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('half')">
                <EqsText
                    :label="$t('payments.asked.label-operation-type')"
                    :editable="false"
                    :value="
                        payment_types[payment.operation_type]
                            ? payment_types[payment.operation_type].txt
                            : payment.operation_type
                    "
                    :loading="loading"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('half')">
                <EqsText
                    :label="$t('payments.asked.label-date')"
                    :editable="false"
                    :value="payment.created_at ? $time.formated(payment.created_at, true) : ''"
                    :loading="loading"
                />
            </v-col>
        </v-row>
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full')">
                <EqsTextArea
                    :label="$t('payments.asked.label-comment')"
                    :editable="false"
                    :value="payment.comment"
                    :loading="loading"
                />
            </v-col>

            <v-col v-if="files && files.length" :cols="$reactive.getCols('full')">
                <EqsFileInput
                    :label="$t('payments.asked.label-docs')"
                    :editable="false"
                    multiple
                    :value.sync="files"
                    :loading="loading"
                />
            </v-col>
        </v-row>

        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('half')" class="d-flex">
                <EqsBtn
                    class="mr-5"
                    :action="accept"
                    color="primary"
                    :loading="loading"
                    el-class="pay"
                >
                    {{ $t("payments.asked.accept.accept-btn") }}
                </EqsBtn>

                <EqsBtn :action="refuse" :loading="loading">
                    {{ $t("payments.asked.accept.refuse-btn") }}
                </EqsBtn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { payment_types } from "@/util/payment.js";

export default {
    name: "AcceptPayment",
    props: {
        accept: {
            type: Function,
            required: true
        },
        refuse: {
            type: Function,
            required: true
        },
        payment: {
            type: Object,
            required: true
        },
        files: {
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            payment_types
        };
    }
};
</script>
