import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store";

import { globalGuards } from "./applyMiddlewares";

import NotFound from "@/components/views/NotFound.vue";
import NotRegistered from "@/components/views/NotRegistered";
import RoleList from "@/components/views/manageTeam/RoleList.vue";
import ProjectDashboard from "@/components/views/projects/dashboard/_Dashboard.vue";
import ProjectDetailRefont from "@/components/views/projects/detail/_DetailsRefont.vue";
import Signatures from "@/components/views/signatures/Signatures.vue";
import Procedure from "@/components/views/signatures/procedure/_Procedure.vue";
import Valuation from "@/components/views/valuation/_Valuation";
import SimpleSignStepper from "@/components/views/signatures/SimpleSignStepper.vue";
import RiskAversion from "@/components/views/user/riskAversion/_RiskAversion.vue";

import Captable from "@/components/views/captable/_Captable";

import devRoutes from "./sections/devRoutes.js";
import registrationRoutes from "./sections/registrationRoutes.js";
import shareledgerRoutes from "./sections/shareledgerRoutes.js";
import buyRoutes from "./sections/buyRoutes.js";
import authRoutes from "./sections/authRoutes.js";
import historyRoutes from "./sections/historyRoutes.js";
import myWalletRoutes from "./sections/myWalletRoutes.js";
import secondaryMarketRoutes from "./sections/secondaryMarketRoutes.js";
import governanceRoutes from "./sections/governanceRoutes.js";
import portfolioRoutes from "./sections/portfolioRoutes.js";

import Home from "@/components/views/user/home/_Home.vue";
import Dashboard from "@/components/views/user/dashboard/_Dashboard.vue";
import Profile from "@/components/views/user/profile/_Profile.vue";
import Settings from "@/components/views/user/settings/_Settings.vue";
import Notifications from "@/components/views/user/notifications/_Notifications.vue";
import AgDashboard from "@/components/views/user/ag/AgDashboard.vue";
import AgDetail from "@/components/views/user/ag/AgDetail/AgDetail.vue";
import AgPresenceSheet from "@/components/views/user/ag/AgDetail/AgPresenceSheet.vue";

// Client dashboard
import Affiliations from "@/components/views/user/clients/Affiliations.vue";
import DashboardAffiliations from "@/components/views/user/clients/dashboard/Dashboard.vue";
import RootDashboardAffiliations from "@/components/views/user/clients/dashboard/RootDashboard.vue";

// Investors dashboard
import InvestorsDashboard from "@/components/views/user/investorsDashboard/InvestorsDashboard.vue";

import { i18n } from "@/services/plugins/i18n.js";
import multiguard from "vue-router-multiguard";
import {
    informUserIfNotRegistered,
    requiresLemonwayProcessingOrSuccess,
    requiresAffiliationRole,
    requiresUniverseAdmin
} from "./routeGuards";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: "/", // Default
    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y: 0
        };
    },
    routes: [
        {
            path: "/",
            redirect: () => {
                return "/auth";
            }
        },
        ...devRoutes,
        ...authRoutes,
        ...registrationRoutes,
        ...shareledgerRoutes,
        ...buyRoutes,
        ...myWalletRoutes,
        ...historyRoutes,
        ...secondaryMarketRoutes,
        ...governanceRoutes,
        ...portfolioRoutes,
        {
            name: "manage-team",
            path: "/manage/team/:id",
            component: RoleList,
            beforeEnter: multiguard([requiresLemonwayProcessingOrSuccess]),
            meta: {
                context: i18n.t("app.bar.dropdown-profile.title.manage-team")
            }
        },
        {
            path: "/investors-dashboard",
            component: InvestorsDashboard,
            beforeEnter: multiguard([requiresUniverseAdmin]),
            meta: {
                context: "Dashboard des investisseurs",
                title: "Dashboard des investisseurs"
            }
        },
        {
            // Root page of affiliation feature
            path: "/affiliation",
            component: Affiliations,
            beforeEnter: multiguard([requiresAffiliationRole]),
            meta: {
                context: i18n.t("universe.kyoseil.navbar.menu"),
                title: i18n.t("universe.kyoseil.navbar.menu")
            },
            // TODO: ajouter des guards ?
            children: [
                // Page for CGP : show an investor
                {
                    name: "affiliation",
                    path: "",
                    props: true,
                    component: RootDashboardAffiliations,
                    meta: {
                        context: i18n.t("universe.kyoseil.navbar.menu"),
                        title: i18n.t("universe.kyoseil.navbar.menu")
                    }
                },
                {
                    name: "cgp_investor",
                    path: "investors/:investor_id",
                    props: true,
                    component: DashboardAffiliations,
                    meta: {
                        context: i18n.t("universe.kyoseil.view.investor"),
                        title: i18n.t("universe.kyoseil.view.investor")
                    }
                },
                // Page for BC and ADMIN: show investors of a cgp
                {
                    name: "bc_cgp",
                    path: "cgps/:cgp_id/investors",
                    props: true,
                    component: DashboardAffiliations,
                    meta: {
                        context: i18n.t("universe.kyoseil.view.cgp"),
                        title: i18n.t("universe.kyoseil.view.cgp")
                    },
                    // ADMIN only, option for BC : show an investor of a cgp
                    children: [
                        {
                            name: "bc_investor_of_cgp",
                            path: ":investor_id",
                            props: true,
                            component: DashboardAffiliations,
                            meta: {
                                context: i18n.t("universe.kyoseil.view.investor"),
                                title: i18n.t("universe.kyoseil.view.investor")
                            }
                        }
                    ]
                },
                // Page for ADMIN: show cgps of a business_contributor
                {
                    name: "admin_bc",
                    path: "business_contributor/:bc_id/cgps",
                    props: true,
                    component: DashboardAffiliations,
                    meta: {
                        context: i18n.t("universe.kyoseil.view.bc"),
                        title: i18n.t("universe.kyoseil.view.bc")
                    },
                    children: [
                        // show investors of a cgp
                        {
                            name: "admin_cgp_of_bc",
                            path: ":cgp_id/investors",
                            props: true,
                            component: DashboardAffiliations,
                            meta: {
                                context: i18n.t("universe.kyoseil.view.cgp"),
                                title: i18n.t("universe.kyoseil.view.cgp")
                            },
                            children: [
                                // show an investor
                                {
                                    name: "admin_investor_of_cgp_of_bc",
                                    path: ":investor_id",
                                    props: true,
                                    component: DashboardAffiliations,
                                    meta: {
                                        context: i18n.t("universe.kyoseil.view.investor"),
                                        title: i18n.t("universe.kyoseil.view.investor")
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        {
            path: "/dashboard/:id",
            props: true,
            component: Dashboard,
            meta: {
                contextFn: () => i18n.t("dashboard.title")
            },
            name: "dashboard"
        },
        {
            path: "/home/:id",
            props: true,
            component: Home,
            meta: {
                contextFn: () => i18n.t("home.title")
            },
            name: "home"
        },
        {
            path: "/profile/:id",
            props: true,
            component: Profile,
            meta: {
                context: i18n.t("profile.title")
            },
            name: "profile"
        },
        {
            path: "/settings/:id",
            props: true,
            component: Settings,
            meta: {
                context: i18n.t("settings.title")
            },
            name: "settings"
        },
        {
            path: "/notifications/:id",
            props: true,
            component: Notifications,
            meta: {
                context: i18n.t("notifications.title")
            },
            name: "notifications"
        },
        {
            name: "ag",
            path: "/:id/ag",
            value: "ag",
            component: AgDashboard,
            props: true,
            meta: {
                breadCrumb: "Liste de vos assemblées",
                noKycNeeded: false,
                context: i18n.t("app.navigation-drawer.title.ag"),
                title: i18n.t("app.navigation-drawer.title.ag")
            },
            children: [
                {
                    meta: {
                        breadCrumb: "Détails",
                        context: i18n.t("app.navigation-drawer.title.ag")
                    },
                    name: "ag-detail",
                    path: ":agId",
                    props: true,
                    component: AgDetail
                },
                {
                    meta: {
                        breadCrumb: "Feuille de présence",
                        context: i18n.t("app.navigation-drawer.title.ag")
                    },
                    name: "ag-presence-sheet",
                    path: ":agId/feuille-de-presence",
                    props: true,
                    component: AgPresenceSheet
                }
            ]
        },
        {
            name: "projects",
            path: "/projects/:id",
            value: "projects",
            component: ProjectDashboard,
            props: true,
            meta: {
                noKycNeeded: true,
                context: i18n.t("app.navigation-drawer.title.projects"),
                title: i18n.t("app.navigation-drawer.title.projects")
            },
            children: [
                {
                    name: "project-detail",
                    path: ":projectId",
                    props: true,
                    component: ProjectDetailRefont,
                    meta: {
                        context: i18n.t("app.navigation-drawer.title.project"),
                        title: i18n.t("app.navigation-drawer.title.project")
                    }
                }
            ]
        },
        {
            name: "corner-detail",
            path: "/crowdfunding/:id",
            value: "corner-detail",
            props: true,
            component: ProjectDashboard,
            meta: {
                noKycNeeded: true,
                context: i18n.t("app.navigation-drawer.title.crowdfunding"),
                title: i18n.t("app.navigation-drawer.title.crowdfunding")
            },
            children: [
                {
                    name: "corner-project-detail",
                    path: ":projectId",
                    value: "corner-project-detail",
                    props: true,
                    component: ProjectDetailRefont,
                    meta: {
                        noKycNeeded: true,
                        context: i18n.t("app.navigation-drawer.title.crowdfunding"),
                        title: i18n.t("app.navigation-drawer.title.crowdfunding")
                    }
                }
            ]
        },
        // remove sondage et consultation présentation Koytcha
        // {
        //   name: "surveys",
        //   path: "/surveys/:id",
        //   component: Surveys,
        //   props: true,
        // },

        {
            name: "valuation",
            path: "/valuation/:id",
            component: Valuation,
            props: true,
            meta: {
                context: i18n.t("app.navigation-drawer.title.valuation")
            }
        },
        {
            path: "/signatures/:id",
            props: true,
            component: Signatures,
            name: "signatures",
            meta: {
                context: i18n.t("app.navigation-drawer.title.signatures"),
                title: i18n.t("app.navigation-drawer.title.signatures")
            },
            beforeEnter: multiguard([informUserIfNotRegistered]),
            children: [
                {
                    name: "add-procedure",
                    path: "add",
                    props: true,
                    component: Procedure,
                    meta: {
                        context: i18n.t("app.navigation-drawer.title.add-procedure"),
                        title: i18n.t("app.navigation-drawer.title.add-procedure")
                    }
                },
                {
                    name: "edit-procedure",
                    path: "edit/:procedureId",
                    props: true,
                    component: Procedure,
                    meta: {
                        context: i18n.t("app.navigation-drawer.title.edit-procedure"),
                        title: i18n.t("app.navigation-drawer.title.edit-procedure")
                    }
                },
                {
                    name: "simple-sign-stepper",
                    path: "sign/:procedureId",
                    props: true,
                    component: SimpleSignStepper,
                    meta: {
                        context: i18n.t("app.navigation-drawer.title.simple-sign-stepper"),
                        title: i18n.t("app.navigation-drawer.title.simple-sign-stepper")
                    }
                }
            ]
        },
        {
            path: "/captable/:id",
            props: true,
            component: Captable,
            name: "captable",
            meta: {
                context: i18n.t("app.navigation-drawer.title.captable")
            }
        },
        {
            path: "/risk-aversion/:id",
            props: true,
            component: RiskAversion,
            name: "risk-aversion",
            meta: {
                context: i18n.t("risk-aversion.context"),
                title: i18n.t("risk-aversion.context")
            }
        },
        // {
        //   path: "/subscribtion/:id",
        //   props: true,
        //   component: Subscription,
        //   name: "subscription",
        //   meta: {
        //     context: i18n.t("app.navigation-drawer.title.subscription"),
        //   },
        // },
        // {
        //   path: "/company-rates/:id",
        //   props: true,
        //   component: CompanyRates,
        //   name: "companyRates",
        //   // meta: {
        //   //   context: i18n.t("app.navigation-drawer.title.subscription"),
        //   // },
        //   beforeEnter: multiguard([informUserIfNotRegistered]),
        //   children: [{
        //     name: "pay-plan",
        //     path: "pay-plan",
        //     props: true,
        //     component: PayPlan,
        //     meta: {
        //       context: i18n.t("app.navigation-drawer.title.pay-plan"),
        //     },
        //   }
        // ],
        // },
        {
            path: "/not-registered/:id",
            props: true,
            component: NotRegistered,
            name: "not-registered",
            meta: {
                context: i18n.t("not-registered.context")
            }
        },

        {
            path: "*",
            component: NotFound,
            meta: {
                context: i18n.t("ui-error.not-found"),
                title: i18n.t("ui-error.not-found")
            }
        }
        // //TODO set back this when tarification feature working
        // {
        //   path: "/upgrade-plan/:id",
        //   props: true,
        //   component: UpgradePlan,
        //   name: "upgradePlan",
        //   meta: {
        //     context: i18n.t("app.navigation-drawer.title.upgrade-plan")
        //   }
        // },
        // {
        //   name: "companyRates",
        //   path: "/company-rates/:entity",
        //   props: true,
        //   component: CompanyRates,
        //   meta: {
        //     context: i18n.t("app.navigation-drawer.title.subscription")
        //   },
        //   //beforeEnter: multiguard([informUserIfNotRegistered]),
        //   children: [
        //     {
        //       name: "pay-plan",
        //       path: "pay-plan/:id",
        //       props: true,
        //       component: PayPlan,
        //       meta: {
        //         context: i18n.t("app.navigation-drawer.title.pay-plan")
        //       }
        //     }
        //   ]
        // },
        // {
        //   path: "/subscribtion/:id",
        //   props: true,
        //   component: Subscription,
        //   name: "subscription",
        //   meta: {
        //     context: i18n.t("app.navigation-drawer.title.subscription")
        //   }
        // }
    ]
});

/*
 ** Verify if access to the route is granted
 */
router.beforeEach(async (to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    }
    if (to.fullPath.split("?")[0] === from.fullPath.split("?")[0]) return next();

    store.commit("setLoadingRoute", true);
    // Try to replace route if from is null and no id in route

    globalGuards(to, from, next);
    if (to.name === "soon") {
        to.meta.context = i18n.t("app.navigation-drawer.title".concat(".").concat(to.params.test));
    }
});

router.afterEach((to, from) => {
    const allowedRedirect = [
        ["/wallet", "entity"],

        ["/history", "id"],
        ["/portfolio", "id"],
        ["/dashboard", "id"],
        ["/home", "id"],
        ["/profile", "id"],
        ["/settings", "id"],
        ["/notifications", "id"],
        ["/projects", "id"],
        ["/crowdfunding", "id"],
        ["/valuation", "id"],
        ["/signatures", "id"],
        ["/captable", "id"],
        ["/risk-aversion", "id"],
        ["/not-registered", "id"],
        ["/manage/team", "id"],
        ["/registration", "id"],

        ["/digitalize", "companyId"],
        ["/capitalisation", "companyId"],
        ["/operations", "companyId"],
        ["/fundraising", "companyId"],
        ["/investors", "companyId"]
    ];
    if (
        to.path !== "/auth" &&
        from.name === null &&
        !!allowedRedirect.find(
            e =>
                (to.path.startsWith(`${e[0]}/`) || to.path.localeCompare(e[0]) === 0) &&
                !Object.prototype.hasOwnProperty.call(to.params, e[1])
        )
    ) {
        let path = to.path;
        let trailingSlash = false;
        if (to.path.slice(-1) !== "/") {
            path += "/";
            trailingSlash = true;
        }
        let entity = store.getters?.currentRole?.entity_represented;
        if (!entity) {
            entity = store.getters?.myEntity;
        }
        if (entity) {
            router.replace(`${path}${entity.id}`);
            return;
        }
        if (trailingSlash) {
            router.replace(`${path}`);
            return;
        }
    }
    store.commit("setLoadingRoute", false);
});

router.onError(err => {
    console.error(`router error : ${err}`);
    store.commit("setLoadingRoute", false);
    throw err;
});

export default router;
