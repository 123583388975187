<template>
    <div>
        <div class="role-update-title" el-class="manage-update-title">
            <p>{{ `${selectedRole.user.name} - ${selectedRole.user.email}` }}</p>
            <IsKycOk :value="selectedRole.user && selectedRole.user.lemonway_state === 'success'" />
            <p v-if="selectedRole.user.is_active" :class="selectedRole.htmlClass">
                {{ rigthsByRole[selectedRole.role_name].role }}
            </p>
            <p v-else :class="selectedRole.htmlClass">
                {{ $t("crud.pending") }}
            </p>
        </div>
        <div class="mt-5">
            {{ $t("crud.change") }}
        </div>
        <div class="update-select" @mouseleave="$emit('update:showHelp', '')">
            <RoleSelect
                :key="renderKey"
                :disabled="!canChangeRole"
                :value="selectedRole.role_name"
                :hovered="showHelp"
                @update:value="dialogChangeRole"
                @update:hovered="$emit('update:showHelp', $event)"
            />
        </div>
        <div class="update-is-signatory">
            <EqsCheckbox
                :key="renderKey + 1"
                el-class="signatory"
                :is-required="false"
                :label="$t('crud.is-signatory.label')"
                :text="$t('crud.is-signatory.text')"
                :value="isSignatory"
                @change="dialogChangeIsSignatory"
            />
        </div>
        <div>
            <EqsBtn
                v-if="selectedRole.role_name !== 'admin' || canChangeRole"
                color="error"
                class="pt-3"
                el-class="crud-delete-btn"
                v-bind="{ action: dialogDeleteMember }"
            >
                {{ $t("crud.delete") }}
            </EqsBtn>
        </div>
    </div>
</template>

<script>
import RoleSelect from "./RoleSelect";
import IsKycOk from "@/components/molecules/IsKycOk.vue";

export default {
    components: { RoleSelect, IsKycOk },
    props: {
        selectedRole: {
            type: Object,
            required: true
        },
        showHelp: {
            type: String,
            default: "admin"
        },
        canChangeRole: {
            type: Boolean,
            default: false
        },
        deleteRole: {
            type: Function,
            required: true
        },
        updateRole: {
            type: Function,
            required: true
        },
        rigthsByRole: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            renderKey: 0,
            isSignatory: false
        };
    },
    watch: {
        "selectedRole.is_signatory": {
            immediate: true,
            handler(val) {
                this.isSignatory = val;
            }
        }
    },
    methods: {
        dialogDeleteMember() {
            this.$dialog.alert({
                title: this.$t("crud.delete").toUpperCase(),
                text: this.$t("crud.change-role-msg", {
                    userName: this.selectedRole.user.name
                }),
                confirm: {
                    fn: this.deleteRole,
                    txt: this.$t("btn.delete")
                },
                cancel: {}
            });
        },
        dialogChangeRole(roleName) {
            this.$dialog.confirm({
                title: this.$t("crud.change-role"),
                text: this.$t("crud.change-role-msg2", {
                    roleUser: this.selectedRole.user.name,
                    roleName: this.selectedRole.role_name,
                    name: roleName
                }),
                confirm: {
                    fn: async () => {
                        this.$set(this.selectedRole, "role_name", roleName);
                        await this.updateRole(this.selectedRole);
                    },
                    txt: this.$t("crud.btn-change")
                },
                cancel: {
                    fn: () => {
                        this.renderKey++;
                    }
                }
            });
        },
        dialogChangeIsSignatory(isSignatory) {
            this.$dialog.confirm({
                title: this.$t("crud.change-is-signatory.title"),
                text: isSignatory
                    ? this.$t("crud.change-is-signatory.on", {
                          roleUser: this.selectedRole.user.name
                      })
                    : this.$t("crud.change-is-signatory.off", {
                          roleUser: this.selectedRole.user.name
                      }),
                confirm: {
                    fn: async () => {
                        await this.updateRole({
                            ...this.selectedRole,
                            is_signatory: isSignatory
                        });
                        await this.$role.updateMyCurrentRole(this.$entity.represented().id);
                        const { signatory_name, id_signatory, signatory_phone } =
                            this.$entity.represented();
                        console.log({ signatory_name, id_signatory, signatory_phone });
                    },
                    txt: this.$t("crud.btn-change")
                },
                cancel: {
                    fn: () => {
                        this.isSignatory = this.selectedRole.is_signatory;
                        this.renderKey++;
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.role-update-title {
    height: 63px;
    border-bottom: 0.5px solid var(--v-border-base);
    padding-top: 20px;
    > * {
        display: inline;
        margin-left: 5px;
    }
    > :first-child {
        padding-left: 10px;
        font-size: 12px;
        font-weight: 900;
    }
}

.update-select {
    border-bottom: 0.5px solid var(--v-border-base);
    width: 100%;
    .text-radio {
        margin-left: 32px !important;
    }
}

.update-is-signatory {
    margin-top: 10px;
    margin-bottom: 10px;
}

.first-title {
    font-size: 12px;
    font-weight: 900;
    float: left;
    margin-top: 20px;
}
</style>
