var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`eqs-data-table ${_vm.elClass}-container`,style:({ ..._vm.getStyle, ..._vm.additionalStyle })},[(!_vm.noData)?_c('v-data-table',_vm._g(_vm._b({attrs:{"fixed-header":""},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),{key:"top",fn:function(){return [(_vm.searchable || _vm.add || _vm.$slots['header'])?_c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"d-flex align-center py-0"},[_vm._t("top"),(_vm.searchable)?_c('v-col',{staticClass:"py-0",attrs:{"cols":_vm.getSearchCols}},[_c('EqsSearchBar',_vm._b({attrs:{"value":_vm.search},on:{"update:value":function($event){_vm.search=$event}}},'EqsSearchBar',{
                                ..._vm.searchable,
                                loading: _vm.loading,
                                elClass: `${_vm.elClass}-filter`
                            },false))],1):_vm._e(),(_vm.searchable && _vm.searchable.filters)?_c('v-col',{class:_vm.$reactive.isXs() ? 'py-2' : 'py-0',attrs:{"cols":_vm.$reactive.isXs() ? 12 : 12 / _vm.numElemHeader}},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.selectedFilters,"multiple":"","hint":(_vm.$reactive.isXs() || _vm.numElemHeader > 2) && _vm.moreThanOneFilterTxt
                                    ? _vm.moreThanOneFilterTxt
                                    : '',"persistent-hint":"","prepend-icon":"mdi-filter","clearable":"","single-line":""},on:{"update:value":function($event){_vm.selectedFilters=$event}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('EqsChip',{staticClass:"pa-1",staticStyle:{"max-width":"inherit"},attrs:{"color":_vm.$color.colorFromHash(item.value),"close":"","text-color":"text"},on:{"click:close":function($event){return _vm.removeFilter(index)}}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(index === 1 && !_vm.$reactive.isXs() && _vm.numElemHeader < 3)?_c('span',{staticClass:"grey--text caption pa-1"},[_vm._v(" "+_vm._s(_vm.moreThanOneFilterTxt)+" ")]):_vm._e()]}}],null,false,2161840491)},'EqsSelect',{
                                items: _vm.filters,
                                placeholder: _vm.$t('data-table.select-filters'),
                                elClass: `${_vm.elClass}-filter`
                            },false))],1):_vm._e(),_c('v-spacer'),(_vm.add || _vm.other)?_c('v-col',{staticClass:"py-2",attrs:{"cols":_vm.$reactive.isXs() && !_vm.searchable && !_vm.$slots['header'] && _vm.add && _vm.other
                                ? 12
                                : 'auto'}},[_c('div',{staticClass:"d-flex justify-end"},[_vm._t("other"),(_vm.add)?_c('EqsBtn',_vm._b({attrs:{"el-class":`${_vm.elClass}-add-item`,"color":"primary","eqs-type":_vm.$reactive.isXs() || !_vm.searchable ? 'default' : 'block'},scopedSlots:_vm._u([{key:"with-icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.add.txt))+" ")]},proxy:true}],null,false,2866420465)},'EqsBtn',{
                                    action: _vm.add.fn,
                                    loading: _vm.loading
                                },false)):_vm._e()],2)]):_vm._e()],2)],1):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selectedProxy),callback:function ($$v) {_vm.selectedProxy=$$v},expression:"selectedProxy"}},'v-data-table',{
            itemsPerPage: -1,
            ..._vm.$attrs,
            ..._vm.getData,
            loading: _vm.loading,
            items: _vm.items,
            height: '100%',
            search: _vm.search,
            headers: _vm.headers,
            hideDefaultFooter: _vm.hideDefaultFooter,
            customFilter: _vm.selectedFilters.length ? _vm.customFilter : _vm.defaultFilter,
            selectable: _vm.selectable,
            itemClass: _vm.getSelectedColor,
            showSelect: _vm.selectable && !_vm.loading
        },false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2):_c('InternalNoData',_vm._b({},'InternalNoData',{ noDataCustom: _vm.noDataCustom, add: _vm.add, elClass: _vm.elClass },false)),_c('v-input',_vm._b({attrs:{"dense":"","hide-details":"auto"}},'v-input',{
            ..._vm.$attrs,
            value: _vm.selectable ? _vm.selectedProxy : _vm.items,
            rules: _vm.rulesRequired
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }