import deleteUser from "./actions/auth/delete_account";
import getSession from "./actions/auth/get_session";
import login from "./actions/auth/login";

import confirmForgotPassword from "./actions/password/confirm_forgot_password";
import sendMailForgotPassword from "./actions/password/send_mail_forgot_password";

import getMyEntity from "./actions/entity/get_my_entity";
import putEntity from "./actions/entity/put_entity";

export default {
    deleteUser,
    getSession,
    login,
    confirmForgotPassword,
    sendMailForgotPassword,
    getMyEntity,
    putEntity
};
