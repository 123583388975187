var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('EqsAlert',_vm._b({},'EqsAlert',{
                    eqsType: 'warning',
                    text: _vm.$t('capital-increase.user.detail.cip-warning-unsecured-rates')
                },false))],1),_c('v-col',{attrs:{"cols":"12"}},[_c('EqsAlert',_vm._b({},'EqsAlert',{
                    eqsType: 'warning',
                    text: _vm.$t('capital-increase.user.detail.cip-warning-capital-loss')
                },false))],1)],1),(_vm.isCip)?_c('CrowdfundingFooter',{staticClass:"mt-6"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }