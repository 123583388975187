<template>
    <EqsDialog
        v-model="dialogProxy"
        closable
        persistent
        v-bind="{
            title: $t('governance.decisions.detail-dialog.title'),
            confirm: {
                txt: $t('btn.close')
            }
        }"
        @close="() => (dialogProxy = false)"
    >
        <v-row class="px-3">
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsTextDisplay
                    v-bind="{
                        value: $t('governance.decisions.detail-dialog.general-parameters'),
                        eqsType: 'subtitle'
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsText
                    v-bind="{
                        label: $t('governance.decisions.detail-dialog.name'),
                        editable: false,
                        value: item.name
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsLabel
                    v-bind="{
                        value: $t('governance.decisions.header.organe'),
                        editable: false
                    }"
                >
                    <EqsChip
                        v-bind="{
                            color: $color.colorFromHash(item.organ.name),
                            textColor: 'text'
                        }"
                    >
                        <div>{{ item.organ.name }}</div>
                    </EqsChip>
                </EqsLabel>
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsText
                    v-bind="{
                        label: $t('governance.decisions.detail-dialog.number'),
                        editable: false,
                        value: !!item.number_globally
                            ? item.number_globally.toString()
                            : $t('label.undefined')
                    }"
                />
            </v-col>

            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsTextDisplay
                    v-bind="{
                        value: $t('governance.decisions.detail-dialog.details'),
                        eqsType: 'subtitle'
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsLabel
                    v-bind="{
                        value: $t('governance.organs.details.activity.headers.state'),
                        editable: false
                    }"
                >
                    <EqsChip v-bind="decisions_state[item.state]">
                        <div>{{ decisions_state[item.state].text }}</div>
                    </EqsChip>
                </EqsLabel>
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsLabel
                    v-bind="{
                        value: $t('governance.decisions.header.mode'),
                        editable: false
                    }"
                >
                    <EqsChip
                        v-bind="{
                            color: $color.colorFromHash(item.type),
                            textColor: 'text'
                        }"
                    >
                        <div>{{ decision_types[item.type].text }}</div>
                    </EqsChip>
                </EqsLabel>
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsDate
                    :value="item.date"
                    :editable="false"
                    :label="$t('governance.decisions.stepper.step.upload-decision.form.date.label')"
                />
            </v-col>
            <v-col
                v-if="item.convocation_documents.length"
                :cols="$reactive.getCols('full')"
                class="eqs-form-field"
            >
                <EqsList
                    v-bind="{
                        editable: false,
                        label: $t(
                            'governance.decisions.stepper.step.upload-decision.form.convocation-docs.title'
                        ),
                        items: item.convocation_documents.map(item => {
                            return {
                                doc: item,
                                subtitle: documentTypesObj[item.category.toLowerCase()].text,
                                title: item.name
                            };
                        })
                    }"
                >
                    <template #item="{ item, index }">
                        <EqsFileActions
                            v-bind="{
                                item: item.doc,
                                index,
                                editable: false
                            }"
                        />
                    </template>
                </EqsList>
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsTextDisplay
                    v-bind="{
                        value: $t('governance.decisions.detail-dialog.anchored'),
                        eqsType: 'subtitle'
                    }"
                />
            </v-col>
        </v-row>
        <v-row v-if="item.proof" class="px-3">
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsDate
                    :value="item.locked_at"
                    :editable="false"
                    :label="$t('governance.decisions.detail-dialog.anchor-date')"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsText
                    :value="item.hash"
                    :editable="false"
                    :label="$t('governance.decisions.detail-dialog.tx-id')"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsFileInput
                    :value="item.proof"
                    :editable="false"
                    :label="$t('governance.decisions.detail-dialog.proof-doc')"
                />
            </v-col>
        </v-row>
        <v-row v-else class="px-3">
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsAlert
                    v-bind="{
                        eqsType: 'warning',
                        text: $t('governance.decisions.lock-dialog.alert')
                    }"
                />
            </v-col>
            <v-col :cols="$reactive.getCols('full')" class="eqs-form-field">
                <EqsBtn color="error" :action="() => lockItem(item)">
                    <v-icon left> mdi-lock-open-variant </v-icon>
                    {{ $t("governance.decision.lock-btn") }}
                </EqsBtn>
            </v-col>
        </v-row>
    </EqsDialog>
</template>

<script>
import { decision_types } from "@/util/governance";
import { decisions_state } from "@/util/governance";
import { documentTypesObj } from "@/util/documents";

export default {
    name: "DetailDialog",
    props: {
        lockItem: {
            type: Function,
            required: true
        },
        value: {
            type: Boolean,
            required: true
        },
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            decision_types,
            documentTypesObj,
            decisions_state
        };
    },
    computed: {
        dialogProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
