<template>
    <EqsStepper
        class="fill-height"
        height="100%"
        :value.sync="step"
        :loading="loading || localLoading"
        v-bind="{ steps, progress, closeInfoTxt }"
        closable
        @close="backToSignatures"
    >
        <template #default="{ item }">
            <component
                :is="item.is"
                editable
                :value.sync="formData.proceduredocument_set"
                :loading="loading || localLoading"
                v-bind="{
                    signature_procedure,
                    schemaDouble,
                    state,
                    formData,
                    deleteFunc: deleteProcedureDoc
                }"
                @signBoxesInitiated="saveFormData"
            >
                <template
                    #actions="{
                        loading: templateDataLoading,
                        variables,
                        signatories,
                        insertContent
                    }"
                >
                    <EqsBtn
                        class="px-2 py-2"
                        v-bind="{
                            color: 'primary lighten-1',
                            loading: templateDataLoading || loading,
                            infoTxt: $t('signatures.procedure.custom-template.info-txt'),
                            action: () => (dialogSigners = true)
                        }"
                    >
                        {{ $t("signatures.procedure.custom-template.action-txt") }}
                    </EqsBtn>
                    <SignersVariablesDialog
                        :value.sync="dialogSigners"
                        v-bind="{
                            items: signatories,
                            variables,
                            insertContent,
                            loading: templateDataLoading || loading
                        }"
                    />
                </template>
            </component>
        </template>
    </EqsStepper>
</template>

<script>
import { stepperFormMixins } from "@equisafe-ui-vue/src/mixins/formMixin";
import { procedureEditMixin } from "@/mixins/yousignMixin";

import { mapActions } from "vuex";
import Parameters from "./Parameters";
import cloneDeep from "lodash/cloneDeep";

export default {
    name: "Procedure",
    components: {
        Parameters,
        Done: () => import("./Done.vue"),
        SignersVariablesDialog: () => import("./SignersVariablesDialog")
    },
    mixins: [stepperFormMixins, procedureEditMixin],
    props: {
        procedureId: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            schemaDouble: {},
            formData: {},
            step: 0,
            state: "success",
            localLoading: false,
            dialogSigners: false
        };
    },
    computed: {
        edit() {
            return this.$route.name === "edit-procedure" || this.signature_procedure.id;
        },
        closeInfoTxt() {
            return this.edit
                ? this.$t("signatures.procedure.back-edit")
                : this.$t("signatures.procedure.back-add");
        },
        steps() {
            return [
                {
                    title: this.$t("signatures.procedure.params"),
                    item: {
                        is: "Parameters"
                    },
                    back: {
                        fn: this.backToSignatures,
                        txt: this.closeInfoTxt
                    },
                    next: { fn: this.edit ? this.updateProcedure : this.createProcedure },
                    schemaDouble: {
                        name: {
                            isRequired: true
                        },
                        description: {
                            isRequired: false
                        },
                        signer_set: {
                            dataType: "array",
                            isRequired: true
                        }
                    }
                },
                {
                    title: this.$t("signatures.procedure.signatures"),
                    item: {
                        is: "GlobalSignatureTemplate"
                    },
                    next: { fn: this.updateProcedureDocsAndSigner },
                    schemaDouble: {
                        proceduredocument_set: {
                            dataType: "array",
                            isRequired: true
                        }
                    }
                },
                {
                    title: this.$t("signatures.procedure.done"),
                    item: {
                        is: "Done"
                    },
                    next: {
                        fn: () =>
                            this.$router.push({
                                name: "signatures",
                                params: this.$router.params,
                                query: {
                                    tab: "my-procedures"
                                }
                            }),
                        txt: this.$t("signatures.procedure.come-back-to-signature_procedure-list")
                    },
                    schemaDouble: {}
                }
            ];
        }
    },
    watch: {
        step: {
            immediate: true,
            handler(step) {
                this.schemaDouble = this.steps[step].schemaDouble;
                this.populateFormData();
            }
        }
    },
    async created() {
        if (this.edit) {
            this.localLoading = true;
            await this.getProcedure(this.procedureId);
            this.populateFormData();
            this.step = this.getLastFormStep(this.signature_procedure, 1);
            this.localLoading = false;
        }
    },
    methods: {
        ...mapActions(["fetchProcedure"]),
        async setProcedureSigners() {
            try {
                if (this.anyFieldChangedForKeys(["signer_set"])) {
                    await this.$api.populateSigners(
                        this.signature_procedure.id,
                        this.formData.signer_set.map(signer => {
                            return {
                                signature_procedure_id: this.signature_procedure.id,
                                entity_id: signer.entity.id
                            };
                        })
                    );
                    await this.getProcedure(this.signature_procedure.id);
                }
            } catch (e) {
                this.$ui.error(e, "set_procedure_signers");
                throw e;
            }
        },
        backToSignatures() {
            this.$router.push({ name: "signatures", params: this.$route.params });
        },
        populateFormData() {
            this.buildFormData(this.schemaDouble, this.signature_procedure || {});
            this.saveFormData();
        },
        async createProcedure() {
            try {
                this.localLoading = true;
                const res = await this.$api.createProcedure(
                    this.formData.name,
                    this.formData.description,
                    false
                );
                this.signature_procedure = res.data;
                // await this.createProcedureDocs();
                await this.setProcedureSigners();
                this.$router.replace({
                    name: "edit-procedure",
                    params: {
                        ...this.$route.params,
                        procedureId: this.signature_procedure.id
                    }
                });
                this.step++;
            } catch (e) {
                this.$ui.error(e, "createProcedure");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async updateProcedure() {
            try {
                if (!this.errorNoFieldsChanged) {
                    this.localLoading = true;
                    if (this.anyFieldChangedForKeys(["name", "description"])) {
                        console.log("field changed");
                        const res = await this.$api.updateProcedure(this.signature_procedure.id, {
                            name: this.formData.name,
                            description: this.formData.description
                        });
                        this.signature_procedure = res.data;
                    }
                    await this.setProcedureSigners();
                }
                this.step++;
            } catch (e) {
                this.$ui.error(e, "updateProcedure");
                throw e;
            } finally {
                this.localLoading = false;
            }
        },
        async updateProcedureDocsAndSigner() {
            try {
                if (!this.errorNoFieldsChanged) {
                    this.localLoading = true;
                    await this.updateProcedureDocs();
                }
                this.step++;
            } catch (e) {
                this.$ui.error(e, "updateProcedureBoxes");
                throw e;
            } finally {
                this.localLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
