<template>
    <v-row no-gutters class="fill-height">
        <transition name="eqs-slide-from-right">
            <div v-if="$route.name === 'wallet'" class="fill-width">
                <Wallet v-bind="{ loading }" />
            </div>
            <router-view
                v-else
                class="eqs-card-1 wallet-side-card pa-0 fill-width"
                v-bind="{ loading }"
                @close="
                    () =>
                        $router
                            .push({
                                name: 'wallet',
                                params: { entityId: $entity.represented().id }
                            })
                            .catch(() => {})
                "
            />
            <!-- <P2PTx v-if="cardOrder.p2p" :class="cardOrder.p2p" /> -->
            <!-- <Crypto v-if="cardOrder.crypto" :class="cardOrder.crypto" /> -->
        </transition>
    </v-row>
</template>

<script>
// import Crypto from "./crypto/_Crypto.vue";
// import P2PTx from "./P2PTx/_P2PTx.vue";
import Wallet from "./wallet/_Wallet.vue";

export default {
    name: "MyWallet",
    components: {
        Wallet
        // P2PTx,
        // Crypto,
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.wallet-side-card {
    width: 100% !important;
    padding: 0 1em 1em 1em;
}
</style>
