var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsSelect',_vm._b({attrs:{"value":_vm.formData.share_type,"items":_vm.share_type,"item-value":"id","item-text":"name"},on:{"update:value":function($event){return _vm.$set(_vm.formData, "share_type", $event)}}},'EqsSelect',{
                    elClass: 'share-type',
                    label: _vm.$t('shareledger.dialog.stepper.tokenize.label-type-selector'),
                    editable: _vm.creation && _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.share_type,
                    placeholder: _vm.share_type[0].name
                },false))],1)],1),_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',_vm._b({attrs:{"label":_vm.$t('shareledger.dialog.stepper.tokenize.label-share'),"placeholder":_vm.$t('shareledger.dialog.stepper.tokenize.label-share-placeholder'),"value":_vm.formData.name},on:{"update:value":function($event){return _vm.$set(_vm.formData, "name", $event)}}},'EqsText',{
                    elClass: 'name-of-the-shares',
                    editable: _vm.editable,
                    loading: _vm.loading,
                    rules: [_vm.checkName, _vm.protectedCharR],
                    ..._vm.schemaDouble.name
                },false))],1),_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('half')}},[_c('EqsText',_vm._b({attrs:{"label":_vm.$t('shareledger.dialog.stepper.tokenize.label-ticker'),"placeholder":"AO","value":_vm.formData.symbol,"rules":[_vm.checkTicker]},on:{"update:value":function($event){return _vm.$set(_vm.formData, "symbol", $event)}}},'EqsText',{
                    elClass: 'alias-ticker',
                    editable: _vm.editable,
                    loading: _vm.loading,
                    counter: 24,
                    ..._vm.schemaDouble.symbol
                },false))],1)],1),(!!_vm.selectedShareType.is)?_c(_vm.selectedShareType.is,_vm._b({tag:"component"},'component',{
            ..._vm.$props,
            ..._vm.$attrs,
            conversionShareledgers: _vm.conversionShareledgers
        },false)):_vm._e(),(!!_vm.selectedShareType.is && (_vm.formData.docshareledger || _vm.editable))?_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"eqs-form-field",attrs:{"cols":_vm.$reactive.getCols('full')}},[_c('EqsFileInput',_vm._b({attrs:{"value":_vm.formData.docshareledger},on:{"update:value":function($event){return _vm.$set(_vm.formData, "docshareledger", $event)}}},'EqsFileInput',{
                    elClass: 'existing-ledger-file',
                    label: _vm.$t('shareledger.dialog.stepper.tokenize.placeholder-scan'),
                    editable: _vm.editable,
                    loading: _vm.loading,
                    ..._vm.schemaDouble.docshareledger
                },false))],1)],1):_vm._e(),_c('v-scroll-y-transition',{attrs:{"hide-on-leave":""}},[(_vm.loading)?_c('GlobalBlockchainLoader',_vm._b({},'GlobalBlockchainLoader',{ height: '24vh' },false)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }