const mandatoryServices = [
    "api",
    "entity",
    "kyc",
    "role",
    "blockchain",
    "notifications",
    "shareledger",
    "assetMovement",
    "currency",
    "time",
    "chart",
    "reactive",
    "appBar",
    "color",
    "language",
    "poll",
    "text",
    "dev",
    "webSocket",
    "universe"
];

export default {
    install(Vue, options = {}) {
        mandatoryServices.forEach(serviceName => {
            const service = options[serviceName];
            if (service) {
                Object.defineProperty(Vue.prototype, `$${serviceName}`, {
                    get() {
                        return service;
                    }
                });
            }
        });
    }
};
