import { getVal } from "../../../services/reactive";

export const cssTypes = (currentSize = {}) => {
    return {
        default: {
            button: {
                width: "64px"
            }
        },
        "btn-block": {
            button: {
                width: "100%"
            }
        }
    };
};

export const dataTypes = (currentSize = {}) => {
    return {
        default: {},
        "btn-block": {}
    };
};
