<template>
    <EqsDialog
        v-model="valueProxy"
        closable
        persistent
        v-bind="{
            title: $t('equisafe-ui.signature-template.internal-signatories-dialog.title'),
            confirm: {
                fn: selectSigners
            },
            cancel: {
                fn: resetFormData
            }
        }"
        @close="resetFormData"
    >
        <EqsAlert>
            {{ $t("equisafe-ui.signature-template.internal-signatories-dialog.info") }}
        </EqsAlert>
        <EqsDataTable
            :selected.sync="formData"
            :single-select="false"
            item-key="txt"
            selectable
            v-bind="{ items, headers, isRequired: true }"
            :items-per-page="-1"
            hide-default-footer
        >
            <template #item.txt="{ value }">
                {{ value }}
            </template>
        </EqsDataTable>
    </EqsDialog>
</template>

<script>
import { formChangedMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "InternalSignersDialog",
    mixins: [formChangedMixin],
    props: {
        value: {
            type: Boolean,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        proceduredocumentsigner_set: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            formData: [],
            headers: [
                {
                    text: this.$t("label.name"),
                    value: "txt"
                }
            ]
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    },
    watch: {
        proceduredocumentsigner_set: {
            immediate: true,
            handler(val) {
                this.formData = val;
                this.saveFormData();
            }
        }
    },
    methods: {
        selectSigners() {
            this.$emit("update:proceduredocumentsigner_set", this.formData);
        }
    }
};
</script>

<style lang="scss" scoped></style>
