var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"space-between"}},[_c('v-col',{attrs:{"md":"7","cols":"12"}},[_c('h2',{staticClass:"text-h4 mb-8"},[_vm._v(_vm._s(_vm.$t("user.buy.steps.sub-form.title")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("user.buy.steps.sub-form.text-1"))+" ")]),_c('p',{staticStyle:{"text-align":"center","font-size":"1.2rem","font-weight":"bolder"}},[_c('EqsFileInput',_vm._b({},'EqsFileInput',{
                        loading: false,
                        editable: false,
                        ..._vm.subDoc
                    },false))],1),(_vm.showWarningFCPRPartA)?_c('v-alert',{attrs:{"border":"left","type":"warning","text":"","outlined":""}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s("Nous avons procédé à l’Appel de Fonds Initial le 21 Juin 2024, à hauteur de 20% du montant de l’Engagement. Toute nouvelle souscription de Parts A doit donc faire l’objet d’un versement immédiat d’un montant équivalent à 20% de l’Engagement de souscription.")+" ")])]):_vm._e(),(_vm.catchupFeesPercentage)?_c('v-alert',{attrs:{"border":"left","type":"warning","text":"","outlined":""}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.$t("user.buy.steps.sub-form.catchup-fees-kyoseil", { dateClosing: _vm.catchupInitialClosingDate, catchupPercent: _vm.catchupFeesPercentage }))+" ")])]):_vm._e(),_c('p',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.$t("user.buy.steps.sub-form.text-3")))]),_c('EqsFileInput',_vm._b({attrs:{"value":_vm.formData.custom_subscription_doc},on:{"update:value":function($event){return _vm.$set(_vm.formData, "custom_subscription_doc", $event)}}},'EqsFileInput',{
                    elClass: 'custom_subscription_doc',
                    label: _vm.$t('documents.category.signed_capinc_document'),
                    deleteFile: true,
                    editable: true,
                    loading: false,
                    upload: _vm.upload,
                    dataType: 'file',
                    others: {
                        key: 'custom_subscription_doc'
                    },
                    isRequired: true
                },false)),(_vm.order?.capinc?.universe === 'kyoseil')?_c('span',[_c('br'),_c('p',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.$t("user.buy.steps.sub-form.text-proof-of-fund"))+" ")]),_c('EqsFileInput',_vm._b({attrs:{"value":_vm.formData.proof_of_fund},on:{"update:value":function($event){return _vm.$set(_vm.formData, "proof_of_fund", $event)}}},'EqsFileInput',{
                        elClass: 'proof_of_fund',
                        label: _vm.$t('documents.category.proof_of_fund'),
                        deleteFile: true,
                        editable: true,
                        loading: false,
                        upload: _vm.upload,
                        dataType: 'file',
                        others: {
                            key: 'proof_of_fund'
                        },
                        isRequired: true
                    },false))],1):_vm._e()],1),_c('v-col',{staticClass:"order-first order-md-last",attrs:{"md":"5","cols":"12"}},[_c('SubscriptionCard',{attrs:{"order":_vm.order}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }