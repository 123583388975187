<template>
    <v-lazy>
        <v-container fluid>
            <v-row class="px-3 d-flex align-start justify-start">
                <v-col :cols="$reactive.getCols('half')">
                    <EqsSelect
                        el-class="share-type"
                        :value.sync="selectedShareType"
                        return-object
                        :clearable="false"
                        :is-required="false"
                        :items="shareTypes"
                        item-value="id"
                        item-text="name"
                        :label="$t('capitalisation.parameters.share-type')"
                        :loading="loading"
                    />
                </v-col>
                <v-col :cols="$reactive.getCols('half')">
                    <EqsSelect
                        el-class="shareledger"
                        :value.sync="selectedShareledger"
                        :clearable="false"
                        :is-required="false"
                        :items="shareledgers"
                        return-object
                        item-text="name"
                        :label="$t('capitalisation.parameters.shareledger')"
                        :loading="loading"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3" style="height: 80%">
                <v-col :cols="$reactive.isDesktop() ? '8' : '12'" class="fill-height">
                    <ShareholdersList
                        v-if="selectedShareledger"
                        :shareledger="selectedShareledger"
                        v-bind="{
                            loading,
                            headers: headersList,
                            items: shareledgerInvestors
                        }"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-lazy>
</template>

<script>
import { share_type as share_types } from "@/services/shareledger";
import ShareholdersList from "../ShareholdersList.vue";

export default {
    name: "DashboardLedgers",
    components: {
        ShareholdersList
    },
    props: {
        dashboardData: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        investorsWithShares: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selectedShareType: null,
            selectedShareledger: null,
            shareTypes: [],
            headersList: [
                {
                    text: this.$t("label.name"),
                    value: "entity.name"
                },
                {
                    text: this.$t("label.number-of-shares"),
                    value: "shares",
                    filterable: false,
                    sortable: false
                },
                {
                    text: this.$t("capitalisation.general-view.interest"),
                    value: "percent",
                    filterable: false
                },
                {
                    text: this.$t("capitalisation.general-view.investment"),
                    value: "amount",
                    filterable: false
                },
                {
                    text: this.$t("label.actions"),
                    value: "actions",
                    filterable: false,
                    sortable: false,
                    align: "center"
                }
            ],
            headers: [
                {
                    text: this.$t("label.name"),
                    value: "name"
                },
                {
                    text: this.$t("label.number-of-shares"),
                    value: "shares"
                }
            ]
        };
    },
    computed: {
        shareledgers() {
            return this.dashboardData.shareledgers.filter(
                ({ share_type }) => share_type == this.selectedShareType.id
            );
        },
        shareledgerInvestors() {
            if (!this.selectedShareledger) return [];
            return this.investorsWithShares.filter(investor =>
                investor.shares.find(share => share.shareledgerid == this.selectedShareledger.id)
            );
        }
    },
    watch: {
        "dashboardData.shareledgers": {
            immediate: true,
            handler(shareledgers) {
                if (shareledgers && shareledgers.length) {
                    this.shareTypes = share_types.filter(({ id }) =>
                        shareledgers.some(({ share_type }) => share_type == id)
                    );
                    this.selectedShareType = this.shareTypes[0];
                }
            }
        },
        shareledgers: {
            immediate: true,
            handler(shareledgers) {
                if (shareledgers && shareledgers.length) this.selectedShareledger = shareledgers[0];
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
