var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-height-fit fill-height"},[_c('EqsDataTable',_vm._b({staticClass:"max-height-fit fill-width",scopedSlots:_vm._u([{key:"item.company",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row align-center"},[_c('EqsAvatar',_vm._b({staticClass:"pr-3"},'EqsAvatar',{
                        user: item,
                        eqsType: 'list',
                        noBadge: true
                    },false)),_vm._v(" "+_vm._s(item.company)+" ")],1)]}},{key:"item.name",fn:function({ item, index }){return [_c('div',{attrs:{"el-class":`purchase-offer-shareledger_name-${index}`}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.quantity",fn:function({ item, index }){return [_c('div',{attrs:{"el-class":`purchase-offer-numberofshares-${index}`}},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.price",fn:function({ item, index }){return [_c('div',{attrs:{"el-class":`purchase-offer-pricepershare-${index}`}},[_vm._v(" "+_vm._s(_vm.$currency.displayWithCurrency(item.price, item.currency))+" ")])]}},{key:"item.total",fn:function({ item, index }){return [_c('div',{attrs:{"el-class":`purchase-offer-total_buy-${index}`}},[_vm._v(" "+_vm._s(_vm.displayTotal(item))+" ")])]}},{key:"item.buyerStatus",fn:function({ item }){return [_c('EqsChip',_vm._b({},'EqsChip',_vm.buy_order_statutes[item.status],false),[_vm._v(" "+_vm._s(_vm.buy_order_statutes[item.status].txt)+" ")])]}},{key:"item.action",fn:function({ item }){return [(_vm.getActions(item).length)?_c('EqsActions',_vm._b({},'EqsActions',{ actions: _vm.getActions(item) },false)):_vm._e()]}}])},'EqsDataTable',{
            headers: _vm.headers,
            items: _vm.orders,
            loading: _vm.localLoading,
            noDataCustom: {
                title: _vm.$t('secondary-market.follow-buys.no-data-title'),
                text: _vm.$t('secondary-market.follow-buys.no-data-text'),
                img: '/images/secondaryMarket/euro.svg'
            }
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }