<template>
    <div class="eqs-main-container margin-auto">
        <GlobalFinalState
            v-bind="{
                loading,
                state,
                errorText
            }"
        >
            <template #content>
                <EqsTextDisplay
                    class="text-center"
                    eqs-type="subtitle"
                    v-bind="{ elClass: 'success-txt' }"
                >
                    <span v-html="successTxt" />
                </EqsTextDisplay>
            </template>
        </GlobalFinalState>
    </div>
</template>

<script>
import { share_types } from "@/services/shareledger/shareType";
export default {
    name: "Success",
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        order: {
            type: Object,
            required: true
        },
        state: {
            type: String,
            required: true
        },
        errorText: {
            type: String,
            default: null
        }
    },
    computed: {
        successTxt() {
            return this.$t("secondary-market.sell-shares.success-txt", {
                numberofshares: this.$currency.display(this.order.quantity, "0,0"),
                shareType: share_types[this.order.shareledger.share_type].name,
                shareledgerName: this.order.shareledger.name,
                pricepershare: this.$currency.display(this.order.price),
                totalSell: this.$currency.display(this.calculateTotalSell())
            });
        }
    },
    methods: {
        calculateTotalSell() {
            return this.order.quantity * this.order.price;
        }
    }
};
</script>
