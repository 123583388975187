<template>
    <div class="eqs-medium-container margin-auto">
        <EqsCard :title="$t('registration.compnay-search-siren.title')" v-bind="{ loading }">
            <v-form ref="form" v-model="validForm">
                <v-col class="eqs-form-field">
                    <EqsTextDisplay>
                        {{ $t("registration.compnay-search-siren.text") }}
                    </EqsTextDisplay>
                </v-col>
                <v-col class="eqs-form-field">
                    <EqsText
                        class="mt-3 mb-3"
                        :value.sync="siren"
                        :label="$t('registration.compnay-search-siren.siren-label')"
                        editable
                        is-required
                        v-bind="{
                            elClass: 'comp-id-code',
                            placeholder: 'Ex: 845 383 645',
                            loading,
                            rules: [companyIdcodeR],
                            error: !!errorTxt,
                            errorMessages: errorTxt
                        }"
                    />
                    <v-row>
                        <v-col>
                            <EqsBtn
                                class="mt-3 mb-3"
                                el-class="create-company-by-siren"
                                v-bind="{
                                    action: createBySiren,
                                    color: 'primary',
                                    loading: localLoading
                                }"
                            >
                                {{ $t("registration.compnay-search-siren.search-btn") }}
                            </EqsBtn>
                        </v-col>
                        <v-col>
                            <EqsBtn
                                class="mt-3 mb-3"
                                el-class="create-company-manualy"
                                outlined
                                v-bind="{
                                    action: createManually,
                                    color: 'primary',
                                    loading: localLoading
                                }"
                            >
                                {{ $t("registration.compnay-search-siren.manual-btn") }}
                            </EqsBtn>
                        </v-col>
                    </v-row>

                    <EqsAlert class="mt-3 mb-3" v-bind="{ loading: localLoading }">
                        <EqsTextDisplay>
                            <span>
                                {{ $t("registration.compnay-search-siren.info-text") }}
                                <br />
                                {{ $t("registration.compnay-search-siren.info-text-kibs") }}
                                <br />
                                {{ $t("registration.compnay-search-siren.info-text-id") }}
                                <br />
                                {{
                                    $t("registration.compnay-search-siren.info-text-company-status")
                                }}
                                <br />
                                {{
                                    $t(
                                        "registration.compnay-search-siren.info-text-beneficiaires-docs"
                                    )
                                }}
                                <br />
                                {{
                                    $t(
                                        "registration.compnay-search-siren.info-text-beneficiaires-id"
                                    )
                                }}
                            </span>
                        </EqsTextDisplay>
                    </EqsAlert>
                </v-col>
            </v-form>
        </EqsCard>
    </div>
</template>

<script>
import { companiesType } from "@/services/entity";
import { mapActions, mapGetters } from "vuex";
import { companyIdcodeR } from "@/rules";

export default {
    name: "CreateCompany",
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            formData: {
                accountType: companiesType[0],
                category: {}
            },
            localLoading: false,
            companiesType,
            siren: null,
            company: null,
            errorTxt: null,
            validForm: false,
            companyIdcodeR
        };
    },
    computed: {
        ...mapGetters(["myRoles"]),
        rolePending() {
            return this.myRoles.find(role => {
                const entity = role.entity_represented;
                return !entity.name || entity.name === "None None" || entity.name === "None";
            });
        }
    },
    watch: {
        siren(val) {
            if (val) this.errorTxt = "";
        }
    },
    mounted() {
        const currentEntity = this.$store.getters.currentRole.entity_represented;
        if (currentEntity.company_idcode) {
            this.siren = currentEntity.company_idcode;
        } else {
            this.siren = "";
        }
    },
    methods: {
        ...mapActions(["resetRoleStore", "getMyRoles"]),
        async createCompany(populate) {
            this.errorTxt = "";
            let shouldUpdateEntity = false;
            try {
                this.localLoading = true;

                const data = {
                    account_type: "company_nonlisted",
                    ...this.formData.category
                };
                let company = null;
                if (this.$entity.isCompanyNotSetted() || this.rolePending) {
                    if (this.rolePending) {
                        const res = await this.$role.updateMyCurrentRole(
                            this.rolePending.entity_represented.id
                        );
                        company = res.entity_represented;
                    } else {
                        shouldUpdateEntity = true;
                        company = this.$store.getters.currentRole.entity_represented;
                    }
                } else if (!this.$entity.isCompany()) {
                    company = await this.$entity.createCompany(data);
                }
                if (populate) {
                    await this.$api.populateEntity(company.id, this.siren);
                }
                if (shouldUpdateEntity) {
                    await this.$entity.updateEntity(data);
                }
                // fetch all roles of user to have new one
                await this.getMyRoles();
                // get updated entity from role list without fetching role as it's done before
                await this.$role.updateMyCurrentRole(company.id, false);
                // reset store to avoid having notification etc from individual
                await this.resetRoleStore();
                await this.$router.push(`/registration/${this.$entity.represented().id}`);
                // this.$ui.success(
                //     this.$t("ui.message.success.set-account", {
                //         accountName: this.formData.accountType.name
                //     })
                // );
            } catch (e) {
                if (!!e.data) {
                    if (e.data === "invalid_siren_siret")
                        this.errorTxt = this.$t("rules.invalid-siren-siret");
                    else if (e.data === "company_not_found")
                        this.errorTxt = this.$t("rules.company_not_found");
                    else this.$ui.error(e, "entity_create_company");
                } else {
                    console.warn("createCompany: ", e);
                }
            } finally {
                this.localLoading = false;
            }
        },
        async createBySiren() {
            if (this.$refs.form.validate() && this.validForm) await this.createCompany(true);
        },
        async createManually() {
            await this.createCompany(false);
        }
    }
};
</script>

<style lang="scss" scoped></style>
