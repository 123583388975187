<template>
    <div class="eqs-main-container margin-auto">
        <EqsViewWithImage class="mx-3" v-bind="{ img: $universe.image('/images/Money-out.svg') }">
            <v-row class="px-3">
                <v-col class="eqs-form-field">
                    <EqsTextDisplay eqs-type="subtitle" class="mb-3">
                        {{ $t("user.wallet.money-out.withdraw-demamd") }}
                    </EqsTextDisplay>
                    <EqsTextDisplay paragraph class="mb-3">
                        {{ $t("user.wallet.money-out.withdraw-demamd-text") }}
                    </EqsTextDisplay>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="eqs-form-field">
                    <slot name="iban" />
                </v-col>
            </v-row>
            <v-row class="px-3">
                <v-col class="eqs-form-field">
                    <WalletBalance v-bind="{ loading }" class="mb-3" />
                </v-col>
            </v-row>
            <v-row class="px-3">
                <v-col class="eqs-form-field">
                    <EqsNumber
                        :label="$t('user.wallet.money-out.amount-to-withdraw')"
                        el-class="money-out-amount"
                        :rules="[greaterThanZeroR, limitsMoneyoutDaily, maxAmount, minAmount]"
                        :value.sync="formData.moneyVal"
                        v-bind="{ loading: isLoading }"
                    />
                </v-col>
            </v-row>
            <v-row v-if="formData.fee" class="px-3">
                <v-col class="eqs-form-field">
                    <EqsNumber
                        :label="$t('user.wallet.money-out.transaction-fee')"
                        el-class="tx-fee"
                        readonly
                        v-bind="{
                            editable: false,
                            value: formData.fee,
                            loading: isLoading
                        }"
                    />
                </v-col>
            </v-row>
            <v-row v-if="!isLoading" class="px-3">
                <v-col class="eqs-form-field">
                    <EqsTextDisplay
                        eqs-type="subtitle"
                        v-bind="{
                            value: $t('user.wallet.money-out.total-withdrawal'),
                            loading
                        }"
                    />
                    <EqsChip
                        color="primary"
                        text-color="white"
                        class="pt-3 font-weight-bold"
                        el-class="total-amount"
                        v-bind="{ loading }"
                    >
                        {{ $currency.display(formData.totalWithdrawal) }}
                    </EqsChip>
                </v-col>
            </v-row>
        </EqsViewWithImage>
    </div>
</template>

<script>
import { greaterThanZeroR } from "@equisafe-ui-vue/src/rules";
import { limitsMoneyoutDaily } from "@/rules";
import WalletBalance from "../WalletBalance";

import { mapGetters } from "vuex";

export default {
    name: "WithdrawAmount",
    components: {
        WalletBalance
    },
    props: {
        formData: {
            type: Object,
            required: true
        },
        order: {
            type: Object,
            required: true
        },
        validForm: {
            type: Boolean,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            greaterThanZeroR,
            limitsMoneyoutDaily
        };
    },
    computed: {
        ...mapGetters(["balance", "balanceLoading"]),
        isLoading() {
            return this.loading || this.balanceLoading;
        }
    },
    methods: {
        minAmount() {
            return !this.formData.moneyVal || this.formData.moneyVal > this.formData.fee
                ? true
                : this.$t("user.wallet.money-out.rule-min-amount", {
                      value: this.$currency.display(this.formData.fee)
                  });
        },
        maxAmount() {
            return !this.formData.moneyVal || this.formData.moneyVal <= this.balance
                ? true
                : this.$t("user.wallet.money-out.rule-max-amount", {
                      value: this.$currency.display(this.balance)
                  });
        }
    }
};
</script>
