<template>
    <v-container fluid>
        <h3 class="text-h4 mb-6">Documentation</h3>
        <p>{{ docs.length }} documents</p>
        <v-row v-for="(item, i) in docs" v-show="!loading" :key="i" dense>
            <v-col cols="9">
                {{ item.name }}
            </v-col>
            <v-col cols="3">
                <v-btn class="ma-2" text icon color="grey">
                    <v-icon large @click="dlDoc(item)">mdi-download</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { downloadFile, showDocument } from "@/util/documents";

export default {
    name: "AgDetailDocument",
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        agItem: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            docs: [],
            localLoading: true
        };
    },
    mounted() {
        this.loadDocs();
    },
    methods: {
        loadDocs() {
            this.docs = [];
            if (this.agItem.convocation) {
                const docConvoc = this.agItem.convocation;
                this.docs.push({
                    name: docConvoc.name,
                    type: docConvoc.type,
                    url: docConvoc.url,
                    upload: docConvoc.upload
                });
            }
            this.localLoading = false;
        },
        dlDoc(item) {
            showDocument(item);
        }
    }
};
</script>

<style lang="scss" scoped></style>
