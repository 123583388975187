import DEBUG from "@/store/debug.js";

export default async ({ state, dispatch }, shareledgerId) => {
    DEBUG("GET_SHARELEDGER_START");
    if (!shareledgerId) throw new Error("no shareledgerId");
    let shareledger = state.shareledgers.find(_shareledger => _shareledger.id === shareledgerId);
    if (!shareledger) {
        try {
            await dispatch("getShareledgers");
            shareledger = state.shareledgers.find(
                _shareledger => _shareledger.id === shareledgerId
            );
        } catch (e) {
            throw e;
        }
    }
    if (shareledger) DEBUG("GET_SHARELEDGER_SUCCESS");
    else {
        DEBUG("GET_SHARELEDGER_ERROR");
        throw new Error("shareledger not found");
    }
    return shareledger || {};
};
