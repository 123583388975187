<template>
    <v-lazy style="min-height: 800px">
        <div class="pa-0 ma-0">
            <v-card>
                <v-card-title class="d-flex flex-column align-start">
                    <div class="document-title" :class="{ 'arthaud-font': $universe.isArthaud() }">
                        {{ $t("projects.detail.tab_reporting") }}
                    </div>
                    <div class="title-line" />
                </v-card-title>
                <v-card-text>
                    <div class="text-subtitle-1">
                        {{ $t("projects.reporting.subtitle") }}
                    </div>
                    <div
                        v-if="isEntityCapincAdmin($entity.represented(), capincId)"
                        class="text-right"
                    >
                        <!-- <v-btn :disabled="localLoading" class="mr-4" @click.stop="fetchReporting()">
                            <template #default>
                                <v-icon class="mr-2" color="success"> mdi-refresh </v-icon>
                            </template>
                        </v-btn> -->
                        <v-btn
                            :disabled="localLoading"
                            style="text-transform: none"
                            elevation="4"
                            color="primary"
                            class="mt-4 pa-5 text-subtitle-1"
                            @click.stop="addPost()"
                        >
                            <template #default>
                                {{ $t("projects.reporting.crud.add-report") }}
                                <v-icon class="ml-2" color="white"> mdi-book-plus </v-icon>
                            </template>
                        </v-btn>
                    </div>
                    <DataTablePaginated
                        :loading="loading || localLoading"
                        :headers="headers"
                        :items="posts"
                        search="title"
                        sort-by="date"
                        :filter="postFilter"
                        sort-desc
                        show-row-pointer
                        @click-row="openPostModal"
                        @update="fetchReporting"
                    >
                        <template #[`item.title`]="{ item }">
                            <div class="action-text" @click.stop="openPostModal(item)">
                                <v-icon v-if="!item.seen" class="arrow-icon mr-2" color="#FFC000">
                                    mdi-circle-medium
                                </v-icon>
                                <span>{{ item.title }}</span>
                            </div>
                        </template>
                        <template #[`item.updated_at`]="{ value }">
                            {{ $time.formated(value) }}
                        </template>
                        <template #[`item.label`]="{ value }">
                            <v-chip :color="getPostLabelColor(value)" text-color="white" label>
                                {{ getPostLabelText(value) }}
                            </v-chip>
                        </template>
                        <template #[`item.action`]="{ item }">
                            <div class="action-text" @click.stop="openPostModal(item)">
                                <v-icon class="arrow-icon"> mdi-eye-outline </v-icon>
                            </div>
                        </template>
                    </DataTablePaginated>
                </v-card-text>
            </v-card>
            <PostModal
                v-if="postModalOpened"
                :open.sync="postModalOpened"
                :post="selectedPost"
                :capinc-id="capincId"
                :edition="postModalEdition"
                :loading="loading || localLoading"
                @close-dialog="closePostModal()"
                @refresh="fetchReporting(queriesCache)"
                @update-post="updatePost"
            />
        </div>
    </v-lazy>
</template>

<script>
import DataTablePaginated from "@/components/views/DataTablePaginated";
import PostModal from "./PostModal";

import { isEntityCapincAdmin, getPostLabelColor, getPostLabelText } from "./utils";

export default {
    name: "Reporting",
    components: { DataTablePaginated, PostModal },
    props: {
        capincId: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            localLoading: false,
            posts: null,
            queriesCache: {},
            selectedPost: null,
            postModalEdition: false,
            postModalOpened: false,
            postFilter: { field: "label", items: [] },
            headers: [
                {
                    text: this.$t("projects.reporting.post-list.title"),
                    value: "title",
                    sortable: true
                },
                {
                    text: this.$t("projects.reporting.post-list.date"),
                    value: "updated_at",
                    sortable: true
                },
                {
                    text: this.$t("projects.reporting.post-list.label"),
                    value: "label",
                    sortable: true
                },
                {
                    text: this.$t("transaction.action"),
                    value: "action",
                    align: "center",
                    sortable: false
                }
            ]
        };
    },
    async beforeMount() {
        this.fetchReporting();
    },
    methods: {
        getPostLabelColor,
        isEntityCapincAdmin,
        getPostLabelText(label) {
            return getPostLabelText(this, label);
        },
        async fetchReporting(queries = {}) {
            this.queriesCache = { ...queries };
            this.localLoading = true;
            try {
                const res = await this.$api.request(
                    "get",
                    `api/reporting/${this.capincId}/post/`,
                    queries
                );
                this.posts = res.data;
                const filterItems =
                    this.posts.count > 0 ? this.posts.results[0]["label_choices"] : null;
                if (filterItems) {
                    this.postFilter.items = filterItems.map(e => {
                        return { text: e[1], value: e[0] };
                    });
                } else {
                    this.postFilter.items = [];
                }
            } catch (e) {
                console.error("fetchReporting", e);
                this.$ui.error(this.$t("projects.reporting.errors.cannot-fetch-posts"));
            } finally {
                this.localLoading = false;
            }
        },
        updatePost(item) {
            const postToUpdate = this.posts.results.find(e => e.id === item.id);
            if (postToUpdate) {
                Object.assign(postToUpdate, item);
            }
        },
        getActions(item) {
            let actions = [];
            actions.push({
                txt: this.$t("transaction.delete-order"),
                icon: "mdi-delete-outline",
                color: "error",
                fn: () => {}
            });
            return actions;
        },
        openPostModal(item) {
            this.selectedPost = item;
            this.postModalOpened = true;
        },
        closePostModal() {
            this.postModalOpened = false;
            this.selectedPost = null;
            this.postModalEdition = false;
        },
        addPost() {
            this.postModalEdition = true;
            this.openPostModal(null);
        }
    }
};
</script>

<style lang="scss" scoped>
.arthaud-font {
    font-family: IvyPresto Display !important;
    font-size: 2.5rem !important;
}
.document-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 63px;
    letter-spacing: 0em;
    text-align: left;
}
.title-line {
    width: 70px;
    height: 2px;
    background-color: #b89c62;
    margin-top: 10px;
}

.action-text {
    > span {
        vertical-align: middle;
    }

    .arrow-icon {
        vertical-align: middle;
        margin-left: 4px;
        transition: transform 0.3s ease;
    }

    &:hover .arrow-icon {
        transform: scale(1.33);
    }
}
</style>
