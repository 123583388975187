<template>
    <div class="d-flex flex-column justify-center align-center">
        <EqsAvatarUpload
            :value="entity.avatar"
            :user="entity"
            v-bind="{ loading: loadingPicture || loading }"
            el-class="entity-avatar-upload"
            @update:value="updateEntityAvatar"
        />
        <div class="d-flex flex-column justify-center align-center">
            <EqsTextDisplay
                class="mb-5"
                v-bind="{
                    elClass: 'profile-name',
                    loading,
                    value: entity.name,
                    eqsType: 'subtitle'
                }"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "AvatarName",
    props: {
        entity: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            loadingPicture: false
        };
    },
    methods: {
        async updateEntityAvatar(avatar) {
            try {
                this.loadingPicture = true;
                await this.$entity.updateEntity({
                    avatar_id: avatar.id
                });
            } catch (e) {
                this.$ui.error(e, "entity_update_profile_picture");
            } finally {
                this.loadingPicture = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
