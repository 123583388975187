<template>
    <div class="eqs-navigation-bar" :el-class="elClass">
        <v-slide-x-reverse-transition>
            <v-app-bar
                v-show="!(isMobile && navDrawer) || !user"
                app
                flat
                :style="getStyle"
                width="auto"
                outlined
                :color="$color.colors.menuColor"
            >
                <v-row no-gutters class="d-flex align-end" style="height: 100%">
                    <v-expand-transition>
                        <v-row
                            v-if="!user && !loadingRoute"
                            align="center"
                            justify="center"
                            style="height: inherit"
                        >
                            <EqsBtn color="primary" to="/auth" v-bind="{ loading }">
                                {{ $t("app.bar.sign-in") }}
                            </EqsBtn>
                        </v-row>
                        <v-row
                            v-if="$appBar.state.actionBar"
                            no-gutters
                            class="fill-height d-flex align-center"
                        >
                            <v-col class="py-0" cols="auto">
                                <v-btn
                                    icon
                                    :color="$color.colors.eqsBlue"
                                    @click="() => $appBar.activateActionBar(false)"
                                >
                                    <v-icon :color="$color.colors.eqsBlue"> mdi-close </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <portal-target name="extended" />
                            </v-col>
                        </v-row>
                        <v-row
                            v-else-if="user"
                            class="eqs-navigation-bar__container d-flex flex-nowrap"
                            no-gutters
                        >
                            <v-col
                                style="overflow: hidden"
                                :class="`eqs-navigation-bar__col d-flex justify-start align-center ${
                                    !isMobile ? 'ml-2 pa-2' : 'pa-0'
                                }`"
                                :order="isMobile ? '2' : 'first'"
                            >
                                <EqsTextDisplay
                                    v-bind="{
                                        value: context,
                                        eqsType: 'subtitle',
                                        loading
                                    }"
                                />
                            </v-col>
                            <v-col
                                v-if="isMobile"
                                class="eqs-navigation-bar__menu d-flex pa-0"
                                order="last"
                                cols="auto"
                                style="align-items: center"
                            >
                                <EqsLoader v-bind="{ loading, size: '48px' }">
                                    <v-app-bar-nav-icon v-if="showMenu" @click="$emit('open')" />
                                    <div v-else />
                                </EqsLoader>
                            </v-col>
                            <slot name="navigation-bar-action" v-bind="{ loading }" />
                            <v-col
                                :order="isMobile ? '3' : 'first'"
                                cols="auto"
                                class="eqs-navigation-bar__col eqs-navigation-bar__menu px-0"
                            >
                                <!-- <InternalHelp v-bind="{ isMobile, loading }" /> -->
                                <!-- TODO : help here -->
                            </v-col>

                            <v-col
                                :order="isMobile ? 'first' : '3'"
                                cols="auto"
                                class="eqs-navigation-bar__col eqs-navigation-bar__menu px-0"
                            >
                                <InternalNotifications
                                    v-bind="{
                                        ...notifications,
                                        isMobile,
                                        loading: loading || loadingNotifications
                                    }"
                                />
                            </v-col>
                            <v-col
                                class="eqs-navigation-bar__col eqs-navigation-bar__menu px-0"
                                :order="isMobile ? '1' : 'last'"
                                cols="auto"
                            >
                                <InternalDropdownProfile
                                    class="d-flex"
                                    v-bind="{
                                        items: userMenu,
                                        user,
                                        isMobile,
                                        loading: loading || loadingProfiles
                                    }"
                                />
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                    <v-expand-transition v-if="loadingRoute || $appBar.state.loading">
                        <v-progress-linear indeterminate />
                    </v-expand-transition>
                </v-row>
            </v-app-bar>
        </v-slide-x-reverse-transition>
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsNavigationBar.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";
import InternalNotifications from "./_internal/InternalNotifications.vue";
import InternalDropdownProfile from "./_internal/InternalDropdownProfile.vue";
// import InternalHelp from "./_internal/InternalHelp.vue";

export default {
    name: "EqsNavigationBar",
    components: {
        InternalDropdownProfile,
        InternalNotifications,
        // InternalHelp,
        EqsBtn: () => import("../../atoms/EqsBtn/EqsBtn.vue"),
        EqsLoader: () => import("../../atoms/EqsLoader/EqsLoader.vue"),
        EqsTextDisplay: () => import("../../atoms/EqsTextDisplay/EqsTextDisplay.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-navigation-bar"
        },
        value: {
            type: Boolean,
            default: true
        },
        mini: {
            type: Boolean,
            default: true
        },
        notifications: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            default: null
        },
        userMenu: {
            type: Array,
            required: true
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        navDrawer: {
            type: Boolean,
            default: false
        },
        showMenu: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingRoute: {
            type: Boolean,
            default: false
        },
        loadingProfiles: {
            type: Boolean,
            default: false
        },
        loadingNotifications: {
            type: Boolean,
            default: false
        },
        context: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/organisms/EqsNavigationBar.scss";
</style>
