<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="16"
        height="15"
        viewBox="0 0 16 15"
    >
        <defs>
            <clipPath id="clip-Artboard_16">
                <rect width="16" height="15" />
            </clipPath>
        </defs>
        <g id="Artboard_16" data-name="Artboard – 16" clip-path="url(#clip-Artboard_16)">
            <path
                id="Path_693"
                data-name="Path 693"
                d="M27,8.5l-15,14"
                transform="translate(-11.5 -8)"
                fill="none"
                stroke="#f84f4f"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-width="1"
            />
            <path
                id="Path_710"
                data-name="Path 710"
                d="M12,8.5l15,14"
                transform="translate(-11.5 -8)"
                fill="none"
                stroke="#f84f4f"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-width="1"
            />
        </g>
    </svg>
</template>

<script>
export default {};
</script>
