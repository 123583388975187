<template>
    <div class="eqs-search-bar">
        <EqsText
            v-model="valueProxy"
            clearable
            hide-details
            append-icon="mdi-magnify"
            :el-class="elClass"
            :is-required="false"
            v-bind="$attrs"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import { cssTypes, dataTypes } from "./EqsSearchBar.js";
import { eqsUITemplate } from "../../../mixins/styleMixin";

export default {
    name: "EqsSearchBar",
    components: {
        EqsText: () => import("../../atoms/EqsText/EqsText.vue")
    },
    mixins: [eqsUITemplate],
    props: {
        elClass: {
            type: String,
            default: "eqs-search-bar"
        },
        value: {
            validator: prop => typeof prop === "string" || prop === null || prop === undefined,
            required: true
        }
    },
    data() {
        return {
            cssTypes,
            dataTypes,
            filtered: []
        };
    },
    computed: {
        valueProxy: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update:value", val);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../shared/styles/components/molecules/EqsSearchBar.scss";
</style>
