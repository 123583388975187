<template>
    <v-container fluid>
        <h3 class="text-h4 mb-6">Invités</h3>
        <p>{{ agMembers.length }} membres</p>

        <v-row v-show="!loading" dense>
            <v-col v-for="(item, i) in agMembers" :key="i" cols="4">
                <v-chip>{{ item.fullname }}</v-chip>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "AgDetailMember",
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        agItem: {
            type: Object,
            required: true
        }
    },
    computed: {
        agMembers() {
            return this.agItem.members;
        }
    }
};
</script>

<style lang="scss" scoped></style>
