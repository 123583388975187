var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showStats)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.localLoading,"boilerplate":_vm.localLoading,"type":"image","min-height":"140","max-height":"140","min-width":"286","max-width":"286"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.stats)?_c('StatsCardComponent',_vm._b({},'StatsCardComponent',{
                        mainTitle: _vm.$t('universe.kyoseil.cgp-dashboard.card-1-title'),
                        icon: 'mdi-safe',
                        isMoney: true,
                        value: _vm.stats.total_price.one_week_ago,
                        trend: _vm.stats.total_price.percentageChange > 0 ? 'UP' : 'DOWN',
                        colorBackground: '#ECFAF7',
                        colorIcon: '#13C296',
                        percentageIncrease: _vm.stats.total_price.percentageChange
                    },false)):_vm._e()]},proxy:true}],null,false,3484266141)})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.localLoading,"boilerplate":_vm.localLoading,"type":"image","min-height":"140","max-height":"140","min-width":"286","max-width":"286"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.stats)?_c('StatsCardComponent',_vm._b({},'StatsCardComponent',{
                        mainTitle: _vm.$t('universe.kyoseil.cgp-dashboard.card-2-title'),
                        icon: 'mdi-account-multiple-outline',
                        isMoney: false,
                        value: _vm.stats.total_clients.one_week_ago,
                        trend: _vm.stats.total_clients.percentageChange > 0 ? 'UP' : 'DOWN',
                        colorBackground: '#EEF1FC',
                        colorIcon: '#3056D3',
                        percentageIncrease: _vm.stats.total_clients.percentageChange
                    },false)):_vm._e()]},proxy:true}],null,false,67586075)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }