<template>
    <div class="eqs-main-container margin-auto">
        <GlobalFinalState
            v-bind="{
                loading,
                state,
                errorText
            }"
        >
            <template #content>
                <EqsTextDisplay
                    class="text-center mb-3"
                    eqs-type="subtitle"
                    v-bind="{ elClass: 'success-txt' }"
                >
                    {{ titleTxt }}
                </EqsTextDisplay>
                <EqsTextDisplay paragraph class="mb-3">
                    {{ contentTxt }}
                </EqsTextDisplay>
                <EqsFileInput
                    v-if="state == 'success' && !!doc"
                    class="mb-3"
                    :value.sync="doc"
                    v-bind="{
                        deleteFile: false,
                        editable: false,
                        loading,
                        label: $t('user.wallet.money-out.success.file-label')
                    }"
                />
            </template>
        </GlobalFinalState>
    </div>
</template>

<script>
export default {
    name: "FinalStep",
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        formData: {
            type: Object,
            required: true
        },
        state: {
            type: String,
            required: true
        },
        errorText: {
            type: String,
            default: null
        },
        doc: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            titleTxt: "",
            contentTxt: ""
        };
    },
    created() {
        this.titleTxt =
            this.state == "success"
                ? this.$t("user.wallet.money-out.success.title")
                : this.$t("user.wallet.money-out.error.title");
        this.contentTxt =
            this.state == "success"
                ? this.$t("user.wallet.money-out.success.content-txt", {
                      AMOUNT: this.$currency.display(this.formData.totalWithdrawal)
                  })
                : this.$t("user.wallet.money-out.error.content-txt");
    }
};
</script>
